import { useStores } from 'containers/App/App';
import { useScope } from 'hooks/useScope';
import { useEffect } from 'react';

const useNotifications = () => {
  const { notificationStore, uiStore } = useStores();
  const [isScope] = useScope();

  useEffect(() => {
    notificationStore.canShowNotifications = true;
  }, []);

  const trigger = uiStore?.appBar.trigger;
  const mobileView = uiStore?.mobileView;
  const numberOfNotifications = notificationStore.numberOfNotifications;
  const handleOpenNotifications = () => {
    notificationStore!.initializeNotificationSystem();
    notificationStore!.setOpenNotification(!!numberOfNotifications);
  };

  return { isScope, numberOfNotifications, handleOpenNotifications, trigger, mobileView };
};

export default useNotifications;
