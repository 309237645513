import React from 'react';
import useStyles from './styles';
import { Typography } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleExclamation } from '@fortawesome/pro-regular-svg-icons';
import { Box, SxProps, Theme } from '@mui/material';
import TextButton from 'components/Button/TextButton/TextButton';

export enum EErrorFallbackVariant {
  DEFAULT = 'default',
  SMALL = 'small',
}

const ErrorFallback = ({
  variant = EErrorFallbackVariant.DEFAULT,
  onRefresh,
  message,
  sx,
}: {
  onRefresh?: (() => void) | undefined;
  variant?: EErrorFallbackVariant;
  message?: string | React.ReactNode;
  sx?: SxProps<Theme>;
}) => {
  const { wrapper, iconWrapper, message: messageStyles, icon, refreshBtn } = useStyles({ variant });

  return (
    <Box className={wrapper} sx={sx}>
      <Box className={iconWrapper}>
        <FontAwesomeIcon className={icon} icon={faCircleExclamation} />
      </Box>

      <Typography className={messageStyles} variant="body2" align="center">
        {message ? (
          message
        ) : (
          <>
            Something went wrong. Try <TextButton label={'refreshing'} onClick={onRefresh} />
            the page.
          </>
        )}
      </Typography>
    </Box>
  );
};

export default ErrorFallback;
