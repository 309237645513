import { createApi } from './utils';
import { IntegrationApp, TalentIntegrationApp } from 'models';
import { ApiResponse } from './interface';
import { AxiosResponse } from 'axios';

import config from 'config';

const api = {
  v1: createApi({ baseURL: `${config.api.developer}/v1` }),
  v2: createApi({ baseURL: `${config.api.developer}/v2` }),
};

/**
 * Returns list of all integration apps and their status relative to a specific account
 */
export const getApps = (
  accountId: number,
): Promise<AxiosResponse<ApiResponse<IntegrationApp[]>>> => {
  return api.v1.get(`apps?accountId=${accountId}`);
};

/**
 * Returns list of all integration apps and their status relative to a specific account
 */
export const getAppsForAccount = (
  accountId: number,
): Promise<AxiosResponse<ApiResponse<IntegrationApp[]>>> => {
  return api.v1.get(`apps/active-integrations?accountId=${accountId}`);
};

/**
 * Returns list of all apps
 */
export const getAllApps = (): Promise<AxiosResponse<ApiResponse<IntegrationApp[]>>> => {
  return api.v1.get(`apps`);
};

/**
 * Creates a new app integration token for a specific account
 */
export const generateToken = (
  appId: number,
  accountId: number,
  properties: any,
): Promise<AxiosResponse<ApiResponse<IntegrationApp>>> => {
  return api.v1.post(`apps/${appId}`, { accountId, ...properties });
};

/**
 * Remove integration by id
 * @param integrationId R
 * @returns
 */

export const removeIntegration = (integrationId: number): Promise<Record<string, unknown>> => {
  return api.v1.delete(`apps/integration/${integrationId}`);
};

/**
 * Remotely force logout devices
 */
export const forceLogoutDevice = (deviceId: number): Promise<AxiosResponse> => {
  return api.v1.post(`display/devices/${deviceId}/logout`);
};

/**
 * Resets the device state remotely
 */
export const resetDeviceState = (deviceId: number): Promise<AxiosResponse> => {
  return api.v1.post(`display/devices/${deviceId}/reset`);
};

/**  */
/**
 * Returns list of non-integrated apps for authenticated user
 */
export const getNonIntegratedApps = (): Promise<
  AxiosResponse<ApiResponse<TalentIntegrationApp[]>>
> => {
  return api.v1.get(`apps/user/auth-info`);
};

/**
 * Login to external app
 */
export const externalAppLogin = (data: {
  accountId: number;
  appId: number;
  processor: string;
  username: string;
  password: string;
}): Promise<AxiosResponse<ApiResponse<void>>> => {
  return api.v1.post(`apps/login`, { ...data });
};

export const getDevelopers = (): Promise<AxiosResponse> => {
  return api.v1.get('/developers');
};

export const mergeUser = (data: {
  accountId: number;
  employeeId: number;
}): Promise<AxiosResponse> => {
  return api.v1.post('/apps/merge-user', { ...data });
};

export const pauseIntegration = (integrationId: number): Promise<void> => {
  return api.v1.post(`/integrations/${integrationId}/pause`);
};

export const resumeIntegration = (integrationId: number): Promise<void> => {
  return api.v1.post(`/integrations/${integrationId}/resume`);
};

/**
 * Force confirms a transaction
 * @returns empty object if ok, otherwise an error message
 */
export const forceConfirm = ({
  processor,
  transactionId,
}: {
  processor: string;
  transactionId: string;
}): Promise<void> => {
  return api.v1.post(`/transactions/force-confirm`, { processor, transactionId });
};
