import React from 'react';
import { Typography } from '@material-ui/core';

const SupportContactInfo = () => {
  return (
    <>
      <Typography variant="body2" align='center'>
        Need assistance or experiencing issues? Contact our support team.
      </Typography>
      <Typography color="primary" variant="body2" align='center'>
        support@meettippy.com{' '}
      </Typography>
    </>
  );
};

export default SupportContactInfo;
