import { Box, IconButton } from '@material-ui/core';
import useStyles from './styles';
import Title from 'components/Title/Title/Title';
import useEditableTitle from './useEditableTitle';
import EditTitleDialog, { IEditTitleDialogProps } from './EditTitleDialog.tsx/EditTitleDialog';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen } from '@fortawesome/pro-regular-svg-icons';

export type IEditableTitleProps =
  | {
      title: string;
      editable?: never;
      submit?: never;
      onSuccess?: never;
    }
  | {
      title: string;
      editable: boolean;
      submit: (title: string) => Promise<any>;
      onSuccess?: IEditTitleDialogProps['onSuccess'];
    };

const EditableTitle = ({ title, editable, ...rest }: IEditableTitleProps) => {
  const { wrapper, button, icon } = useStyles();
  const { openDialog, handleOpenDialog, handleCloseDialog } = useEditableTitle();
  return (
    <Box className={wrapper}>
      <Title>{title}</Title>

      <EditTitleDialog
        open={openDialog}
        title={title}
        loading={false}
        onClose={handleCloseDialog}
        {...rest}
      />
      {editable && (
        <IconButton onClick={handleOpenDialog} className={button}>
          <FontAwesomeIcon className={icon} icon={faPen} />
        </IconButton>
      )}
    </Box>
  );
};

export default EditableTitle;
