import { Location } from './Location';
import { User } from './User';

/** Represents an instance of a user being employed at a location */
export interface LocationUser {
  id: number;
  createdAt: string;
  updatedAt: string;
  locationId: number;
  userId: number;
  status: string;
  isTalent: boolean;
  isVisible: boolean;
  isManager: boolean;
  location?: Location;
  user: User;
}

export enum ELocationUserStatus {
  ALL = 'all',
  ACTIVE = 'active',
  INACTIVE = 'inactive',
  PENDING_APPROVAL = 'pending_approval',
  REJECTED = 'rejected',
  TERMINATED = 'terminated',
}
