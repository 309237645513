/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { observer } from 'mobx-react';
import { WithStyles, withStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import ReactGA from 'react-ga';
import FacebookPixel from 'react-facebook-pixel';

import { WithUserStore } from 'stores';

import styles from './styles';
import { paths } from 'routes';
import { setTitle } from 'services';
import savingsImg from 'images/pig_time_savings.png';
import tippyTitle from 'images/tippy_title_logo.png';
import tippyLogo from 'images/tippy_logo.png';
import MaskedInput from 'react-text-mask';

export const SAVINGS_PERCENTAGE = 3;

type SavingsProps = WithStyles<typeof styles> & WithUserStore;

@observer
class Savings extends React.Component<SavingsProps> {
  state = {
    savings: '',
  };
  componentDidMount() {
    setTitle(`Savings`, { noSuffix: false });
    ReactGA.pageview(window.location.pathname);
    FacebookPixel.pageView();
  }
  setCommas = (value = '') => (+value).toLocaleString('en-US', { useGrouping: true });

  onChange = ({ target: { value = 0 } }) => {
    this.setState({
      savings: Math.ceil(+this.removeCommas(`${value}`) * 0.18 * (SAVINGS_PERCENTAGE / 100)),
    });
  };

  removeCommas = (textCommas: string) => textCommas.replace(/(,)/g, '');

  maskThousand = (rawText = '') => {
    const numberCommas = this.setCommas(this.removeCommas(rawText));
    return numberCommas
      .split('')
      .filter((e) => !!e.match(/[\d(,)]/))
      .map((e) => (e.match(/\d/) ? /\d/ : ','));
  };

  render() {
    const classes = this.props.classes;
    return (
      <div className={classes.root}>
        <div className={classes.main}>
          <div className={classes.call}>
            Call us!
            <div className={classes.phone}>1.855.831.2323</div>
            Mon - Fri 8am - 9pm ET <br />
            Sat - Sun 9am - 6pm ET
          </div>
          <img src={tippyTitle} alt="Tippy" className={classes.logo} />
          <img src={savingsImg} alt="Pig time savings!" className={classes.savingsImg} />
          <div className={classes.tagline}>How much will you save with Tippy?</div>
          <div className={classes.calculatorBox}>
            <div className={classes.amountBox}>
              Enter annual sales $
              <MaskedInput
                className={classes.input}
                guide={false}
                prefix="$"
                autoFocus
                onChange={this.onChange as any}
                maxLength={11}
                mask={this.maskThousand}
              />
            </div>
            <div className={classes.savingsBox}>
              Savings with Tippy
              <div className={classes.savingsAmount}>
                ${this.setCommas(this.state.savings) || 0}
              </div>
            </div>
          </div>
          <div className={classes.extra}>
            Based on industry average 18.0% tip to service and 3% Credit Card processing fee
          </div>
          <Button
            component={RouterLink}
            to={paths.campaignsByCode(`515A02`)}
            variant="contained"
            color="primary"
            className={classes.actionBtn}>
            Start saving
          </Button>
        </div>
        <div className={classes.footer}>
          <img src={tippyLogo} alt="Tippy" className={classes.tippyLogo} />
          <div>© 2021. DirectTIPS Operations, LLC. All Rights Reserved.</div>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(Savings);
