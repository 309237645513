import { Theme, createStyles, makeStyles } from '@material-ui/core';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const styles = (theme: Theme) =>
  createStyles({
    root: {},
    listItemText: {
      position: 'relative',
      // '&::after': {
      //   content: '""',
      //   display: 'block',
      //   position: 'absolute',
      //   height: '1px',
      //   width: '100%',
      //   backgroundColor: 'lightgrey',
      //   bottom: -15,
      //   left: 0,
      // },
      // marginBottom: 20,
    },
    checkIcon: {
      color: theme.palette.primary.main,
    },
    selectedItem: {
      borderRadius: '12px',
      backgroundColor: theme.palette.background.default,
      padding: 0,
      marginBottom: 10,
    },
    unselectedItem: {
      padding: 0,
      marginBottom: 20,
    },
    listItemAvatar: {
      minWidth: 0,
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(2),
    },
    selectedItemText: {
      position: 'relative',
      // '&::after': {
      //   content: '""',
      //   display: 'block',
      //   position: 'absolute',
      //   height: '1px',
      //   width: '100%',
      //   backgroundColor: 'lightgrey',
      //   bottom: -10,
      //   left: 0,
      // },
      '& .MuiTypography-body1': {
        color: theme.palette.primary.main,
        fontSize: '16px',
        fontWeight: 700,
      },
    },
    avatar: {
      width: 24,
      height: 24,
      borderRadius: theme.spacing(0.5),
    },
  });

export default styles;

export const useStyles = makeStyles(styles);
