import theme from 'containers/App/theme';

export const correctionDialogStyles = {
  minWidth: 576,
  '& .dialogActions': {
    padding: theme.spacing(3),
    paddingTop: theme.spacing(5),
  },
  '& .subtitleSmall': {
    marginBottom: theme.spacing(1),
  },
  '& .selectType': {
    flexDirection: 'row',
    gap: theme.spacing(1),
  },
};

export const dialogActionsStyling = {
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'space-between',
  '& .step': {
    color: theme.palette.text.secondary,
    fontSize: 14,
    margin: theme.spacing(1, 3, 0),
  },
};

export const correctionSelectionStyling = {
  display: 'block',
  '& .subtitle': {
    marginBottom: theme.spacing(2),
  },
  '& .locations': {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
  },
  '& .location': {
    backgroundColor: 'rgba(209, 247, 240, 0.5)',
    borderRadius: theme.shape.borderRadius,
    display: 'block',
    padding: theme.spacing(1),
  },
  '& .location-name': {
    fontWeight: 600,
  },
  '& .total-price': {
    marginTop: theme.spacing(2),
  },
  '& .quantity': {
    color: theme.palette.text.secondary,
  },
};

export const correctionSingleStyling = {
  display: 'block',
  '& .quantity-selector': {
    alignItems: 'center',
    display: 'flex',
    gap: theme.spacing(1),
    marginLeft: theme.spacing(3.4),
    position: 'relative',
    top: theme.spacing(-0.5),
  },
};
