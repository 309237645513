import { Theme, createStyles } from '@material-ui/core';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default (theme: Theme) =>
  createStyles({
    root: {},
    cursorPointer: {
      cursor: 'pointer',
    },
    chipCaptured: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
    chipCreated: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
    chipDuplicate: {
      backgroundColor: '#FFBF00',
      color: theme.palette.primary.contrastText,
    },
    chipRefunded: {
      backgroundColor: '#FFBF00',
      color: theme.palette.primary.contrastText,
    },
    chipVerifying: {
      backgroundColor: '#FFBF00',
      color: theme.palette.primary.contrastText,
    },
    chipFailed: {
      backgroundColor: theme.palette.error.main,
      color: theme.palette.primary.contrastText,
    },    
    chipCancelled: {
      backgroundColor: theme.palette.error.main,
      color: theme.palette.primary.contrastText,
    },
    chipVoid: {
      backgroundColor: theme.palette.error.main,
      color: theme.palette.primary.contrastText,
    },
    chipNotFound: {
      backgroundColor: theme.palette.error.main,
      color: theme.palette.primary.contrastText,
    },


  });