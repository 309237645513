import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { observer } from 'mobx-react';
import { observable, action, makeObservable, computed } from 'mobx';
import { WithStyles, withStyles } from '@material-ui/core/styles';
import { inject, WithUserStore, WithToastStore } from 'stores';
import DashboardLayout from 'containers/DashboardLayout';
import TabBar from 'components/TabBar/TabBar';
import Hardware from './Hardware';
import Inventory from './Inventory';
import Kiosks from './Kiosks';
import styles from './styles';

import { setTitle } from 'services';
import Title from 'components/Title';

const PAGE_TITLE = 'Inventory';
interface DevicesProps
  extends WithStyles<typeof styles>,
    RouteComponentProps,
    WithUserStore,
    WithToastStore {}

/**
 * The container for the devices section. Currently displays the inventory and kiosks tabs.
 */
@inject('userStore', 'toastStore')
@observer
class Devices extends React.Component<DevicesProps> {
  constructor(props: DevicesProps) {
    super(props);
    makeObservable(this);
  }

  @observable public selectedTab = 'hardware';
  @observable private activeFilters: Record<string, unknown> = {};

  @action.bound public selectTab(tab: string, filters?: Record<string, unknown>) {
    this.selectedTab = tab;
    filters ? (this.activeFilters = filters) : (this.activeFilters = {});
  }

  @computed public get tabs() {
    const { selectedTab } = this;

    return [
      {
        label: 'Hardware',
        selected: selectedTab === 'hardware',
        onClick: () => this.selectTab('hardware'),
      },
      {
        label: 'Items',
        selected: selectedTab === 'items',
        onClick: () => this.selectTab('items'),
      },
      {
        label: 'Connected Devices',
        selected: selectedTab === 'connectedDevices',
        onClick: () => this.selectTab('connectedDevices'),
      },
    ];
  }

  componentDidMount() {
    setTitle(PAGE_TITLE, { noSuffix: false });
  }
  render() {
    return (
      <DashboardLayout>
        <Title mb={3} size={'large'}>
          {PAGE_TITLE}
        </Title>
        <TabBar tabs={this.tabs} />
        {this.selectedTab === 'hardware' ? (
          <Hardware {...this.props} handleSelectedTab={this.selectTab} />
        ) : this.selectedTab === 'items' ? (
          <Inventory {...this.props} filters={this.activeFilters} />
        ) : (
          <Kiosks {...this.props} />
        )}
      </DashboardLayout>
    );
  }
}

export default withStyles(styles)(Devices);
