import { Box, SxProps, Theme, useTheme } from '@mui/material';
import textButtonStyles from './styles';

export interface ITextButtonProps {
  sx?: SxProps<Theme>;
  label: string;
  disabled?: boolean;
  disableUnderline?: boolean;
  onClick?: VoidFunction;
}

const TextButton = ({ sx = {}, label, disabled, disableUnderline, onClick }: ITextButtonProps) => {
  const theme = useTheme();

  const _sx = { ...sx, ...textButtonStyles({ theme, disabled, disableUnderline }) };

  return (
    <Box component={'span'} sx={_sx} onClick={!disabled ? onClick : undefined}>
      {label}
    </Box>
  );
};

export default TextButton;
