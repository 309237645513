import React from 'react';
import { action, observable, makeObservable } from 'mobx';
import { observer } from 'mobx-react';
import { WithStyles, withStyles } from '@material-ui/core/styles';
import { Box, Typography, IconButton, Menu, MenuItem } from '@material-ui/core';
import { DotsVertical } from 'mdi-material-ui';

import styles from './styles';

import DP from 'components/DashPanel';
import PrimaryBadge from 'components/PrimaryBadge/PrimaryBadge';

enum DebitCardStatus {
  CREATING = 'creating',
  REJECTED = 'rejected',
  APPROVED = 'approved',
  CONDITIONAL = 'conditional',
  UPGRADED = 'upgraded',
}

interface Card {
  id: number;
  accountBrand: string;
  cardBrand: string;
  processor: string;
  status: DebitCardStatus;
  balance: string;
  accountNumber: string
}

interface DebitCardProps extends WithStyles<typeof styles> {
  card: Card;
  isPrimary?: boolean;
  setAsPrimary: () => void;
  onDelete: () => void;
  onContinueEnrollment: () => void;
  branch?: boolean;
}

/** Displays the workspace settings for the user with :userId */
@observer
class DebitCard extends React.Component<DebitCardProps> {
  constructor(props: DebitCardProps) {
    super(props);
    makeObservable(this);
  }

  @observable public anchorEl: null | HTMLElement = null;
  @observable public hideAccount = true;

  @action.bound public toggleAnchorEl(el: null | HTMLElement) {
    this.anchorEl = el;
  }

  @action.bound public handleClick(event: React.MouseEvent<HTMLButtonElement>) {
    event.stopPropagation();
    event.preventDefault();
    this.toggleAnchorEl(event.currentTarget);
  }

  @action.bound public handleClose(event: React.MouseEvent<HTMLButtonElement>) {
    event.stopPropagation();
    event.preventDefault();
    this.toggleAnchorEl(null);
  }

  render() {
    return (
      <DP>
        <DP.Header>
          <Typography noWrap className={this.props.classes.branchHeaderTitle}>
            {this.props.card.accountBrand}
          </Typography>
          <Box display="flex" flexDirection="row">
            {this.props.isPrimary && <PrimaryBadge />}
            <DP.Actions>
              <IconButton size="small" onClick={this.handleClick}>
                <DotsVertical fontSize="small" />
              </IconButton>
            </DP.Actions>
          </Box>
          <Menu anchorEl={this.anchorEl} open={Boolean(this.anchorEl)} onClose={this.handleClose}>
            {this.props.card.status === 'creating' && (
              <MenuItem
                dense
                onClick={() => {
                  this.toggleAnchorEl(null);
                  this.props.onContinueEnrollment();
                }}>
                <Typography>Continue Enrollment</Typography>
              </MenuItem>
            )}
            {!this.props.isPrimary && (
              <MenuItem
                dense
                onClick={() => {
                  this.toggleAnchorEl(null);
                  this.props.setAsPrimary();
                }}>
                <Typography>Set as primary</Typography>
              </MenuItem>
            )}
            <MenuItem
              dense
              onClick={() => {
                this.toggleAnchorEl(null);
                this.props.onDelete();
              }}>
              <Typography color="error">Remove</Typography>
            </MenuItem>
          </Menu>
        </DP.Header>
        <DP.Body>
          <DP.Row>
            <DP.Value>{this.props.card.cardBrand}</DP.Value>
            <DP.Label>Linked on</DP.Label>
          </DP.Row>
          <DP.Row>
            <DP.Value>
              {this.props.card.accountNumber}
            </DP.Value>
            <DP.Label>Account Number</DP.Label>
          </DP.Row>
        </DP.Body>
      </DP>
    );
  }
}

export default withStyles(styles)(DebitCard);