import { Theme, createStyles } from '@material-ui/core';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default (theme: Theme) =>
  createStyles({
    root: {},
    button: {
      marginTop: theme.spacing(5),
      marginBottom: theme.spacing(0),
    },
    campaign: {
      width: `100%`,
      height: `100vh`,
      backgroundSize: `cover`,
      backgroundPositionX: `center`,
    },
    logo: {
      display: 'flex',
      justifyContent: 'center',
      marginTop: '3%',
      marginBottom: '-137px',
      height: '80px',
      '& > img': {
        height: '80px',
      },
      [theme.breakpoints.down('md')]: {
        marginTop: '16px',
        marginBottom: '0',
        '& > img': {
          width: 'initial',
          height: '12vw',
        },
      },
    },
    textAndForm: {
      display: 'flex',
      justifyContent: 'space-between',
      flexDirection: 'row',
      [theme.breakpoints.down('md')]: {
        flexDirection: 'column',
        alignItems: 'center',
      },
    },
    text: {
      display: 'flex',
      flexDirection: 'column',
      width: '31%',
      marginTop: '15%',
      marginLeft: '7%',
      '& > h1': {
        lineHeight: '50pt',
        fontSize: '50px',
        fontWeight: '200',
      },
      '& > p': {
        lineHeight: '25pt',
        fontSize: '22px',
        fontWeight: '300',
      },
      [theme.breakpoints.down('md')]: {
        width: '85%',
        margin: '0',
        '& > h1': {
          lineHeight: '9vw',
          fontSize: '9vw',
          textAlign: 'center',
        },
        '& > p': {
          margin: '0',
          lineHeight: '6vw',
          fontSize: '5vw',
          fontWeight: '200',
          textAlign: 'center',
        },
      },
    },
    form: {
      display: 'flex',
      width: '420px',
      marginTop: '12%',
      marginRight: '3%',
      [theme.breakpoints.down('md')]: {
        position: 'absolute',
        width: '90%',
        margin: '0',
        marginTop: '80%',
      },
    },
    formTitle: {
      fontSize: '16px',
      fontWeight: 'bold',
      color: 'black',
      textAlign: 'left',
    },
    underline: {
      borderBottomColor: 'blue',
      '&:hover': {
        borderBottomColor: 'red',
      },
    },
    input: {
      '&:before': {
        borderBottomColor: theme.palette.grey[500],
      },
      '&:hover:before': {
        borderBottomColor: theme.palette.grey[800],
      },
      '&:hover:not(.Mui-disabled):before': {
        borderBottomColor: theme.palette.grey[800],
      },
      '&:after': {
        display: 'none',
      },
    },
    inputLabel: {
      color: theme.palette.grey[800],
    },
    img: {},
  });
