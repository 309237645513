import { Box, FormControl, FormHelperText, Typography } from '@material-ui/core';
import useMfaComponent from './useMfaComponent';
import { IMFAFactor, MFAChannel } from 'models';
import useStyles from './styles';
import Button from 'components/Button/Button';
import Countdown from './Countdown/Countdown';
import { observer } from 'mobx-react';
import { Skeleton } from '@mui/material';
import { getTokenExpiration } from './helpers';
import useAuthInputStyles from '../Input/AuthInput/styles';
import AuthCode, { AuthCodeRef } from 'react-auth-code-input';
import { useEffect, useRef } from 'react';

interface IMfaComponentProps {
  center?: boolean;
  initialValue?: string;
  onFormValid?: () => void;
}

const MfaComponent = ({ center, ...props }: IMfaComponentProps) => {
  const { form, factors, mfaFieldError, sendMfaCode, onResendMfaCode, loading } =
    useMfaComponent(props);
  const { resendCode, greenFont, textAlign, contentCenter, formHelperText } = useStyles({ center });

  if (!factors?.length || !form) return null;

  const Header = ({ factor }: { factor: IMFAFactor }) => (
    <WithSkeleton loading={loading}>
      <Typography className={textAlign} variant="body2">
        We’ve sent a code to{' '}
        <Typography className={greenFont} component={'span'}>
          {factor.destination}
        </Typography>
        . Please enter the code below.
      </Typography>
    </WithSkeleton>
  );

  const ResendCode = ({
    name,
    expiresAt,
    onResend,
  }: {
    name: MFAChannel;
    expiresAt?: string;
    onResend?: (name: MFAChannel) => void;
  }) => {
    const handleResend = () => {
      sendMfaCode(name);
      onResend && onResend(name);
    };
    return (
      <Box display={'flex'} justifyContent={'space-between'}>
        <WithSkeleton loading={loading}>
          <Countdown time={expiresAt} />
        </WithSkeleton>
        <WithSkeleton loading={loading}>
          <Button className={resendCode} onClick={handleResend} variant="text">
            Resend code
          </Button>
        </WithSkeleton>
      </Box>
    );
  };

  const WithSkeleton = observer(
    ({ children, loading }: { children: JSX.Element; loading: boolean }) => {
      if (loading) {
        return <Skeleton>{children}</Skeleton>;
      }
      return <>{children}</>;
    },
  );

  return (
    <Box>
      {factors.map((factor: IMFAFactor) => {
        const name = factor.channel;
        const { error: hasError, message } = mfaFieldError(factor);
        const className = center ? contentCenter : undefined;
        const expiresAt = getTokenExpiration(name);

        return (
          <Box maxWidth={360} key={name} className={className}>
            <Header key={`${name}_h`} factor={factor} />
            <Box display={'inline-block'} mt={3}>
              <FormControl key={`${name}_fc`}>
                {hasError && (
                  <FormHelperText classes={{ root: formHelperText }} error={hasError}>
                    {message}
                  </FormHelperText>
                )}
                <Box
                  key={`${name}_w`}
                  display="inline-flex"
                  flexDirection={'column'}
                  maxWidth={'max-content'}>
                  <AuthInput hasError={hasError} form={form} name={name} expiresAt={expiresAt} />
                  <ResendCode
                    key={`${name}_rc`}
                    name={name}
                    onResend={onResendMfaCode}
                    expiresAt={expiresAt}
                  />
                </Box>
              </FormControl>
            </Box>
          </Box>
        );
      })}
    </Box>
  );
};

//TODO: Refactor in future
const AuthInput = observer(
  ({
    hasError,
    form,
    name,
    expiresAt,
  }: {
    hasError: boolean;
    form: any;
    name: MFAChannel;
    expiresAt?: string;
  }) => {
    const { input, container } = useAuthInputStyles({ hasError });
    const AuthInputRef = useRef<AuthCodeRef>(null);

    useEffect(() => {
      AuthInputRef.current?.clear();
      AuthInputRef.current?.focus();
    }, [expiresAt]);

    return (
      <AuthCode
        ref={AuthInputRef}
        inputClassName={input}
        containerClassName={container}
        {...form?.$(`${name}`).bind()}
        key={`${name}_ac`}
      />
    );
  },
);

export default observer(MfaComponent);
