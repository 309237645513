/* eslint-disable @typescript-eslint/no-explicit-any */
import axios, { AxiosRequestConfig, AxiosInstance } from 'axios';
import qs from 'qs';
import * as LocalStorage from 'services/localStorage';
import { RequestMetaData } from './interface';
import { refreshJwtToken } from 'utils/refreshTokenHelpers';
import getOrCreateFingerprint from 'components/Fingerprint/bl';
import { rootStore } from 'containers/App/App';

interface AxiosError {
  response: {
    data: {
      error: {
        message: string;
      };
    };
  };
}

/**
 * Creates an axios instance and adds auth functionality to it.
 * @param config Extra config to pass. Normally you just provide a baseURL
 */
export function createApi(config: AxiosRequestConfig): AxiosInstance {
  const api = axios.create(config);
  api.interceptors.request.use(async (c: any) => {
    const jwt = LocalStorage.getJwt();
    if (jwt !== undefined) {
      c.headers['Authorization'] = `Bearer ${jwt}`;
    }
    c.headers['X-Fingerprint'] = await getOrCreateFingerprint();

    return c;
  });

  api.interceptors.response.use(
    (response) => response,
    async (error) => {
      const originalRequest = error.config;
      if (error.response?.status === 901) {
        let { mfa } = error.response.data.error.body;
        rootStore.mfaStore.setMfaResult(mfa);
        rootStore.mfaStore.setConfig(originalRequest);
      }

      if (error.response?.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;

        try {
          // Trigger token refresh
          await refreshJwtToken();

          // Retry the original request with the new token
          const jwt = LocalStorage.getJwt();
          if (jwt) {
            // originalRequest.headers['Authorization'] = `Bearer ${jwt}`;
            return api(originalRequest);
          }
        } catch (error) {
          console.error('Failed to refresh JWT token', error);
        }

        // Logout the user if refresh fails or new token is not available
        rootStore.userStore.logout();
      }

      return Promise.reject(error);
      // return error;
    },
  );

  return api;
}

axios.interceptors.response.use(undefined, (error) => {
  if (error.response?.status === 901) {
    const originalRequest = error.config;
    let { mfa } = error.response.data.error.body;
    rootStore.mfaStore.setMfaResult(mfa);
    rootStore.mfaStore.setConfig(originalRequest);
  }
  return Promise.reject(error);
});

/**
 * Takes some request meta data and some optional extra data and converts
 * it into a querystring for appending to requests.
 * @param requestMeta The request meta data
 * @param extraData Optional extra data to be included in the querystring
 */
export function makeQS(requestMeta?: RequestMetaData, extraData?: Record<string, unknown>): string {
  const { pagination, filters, sort }: Partial<RequestMetaData> = requestMeta || {};
  return qs.stringify(
    { ...filters, ...pagination, ...sort, ...extraData },
    // Skip null values and empty string values
    { skipNulls: true, arrayFormat: 'brackets', filter: (p, v) => (v === '' ? undefined : v) },
  );
}

/** Extracts the error message from an axios response */
export function getErrorMsg<T>(resp: any): string {
  return (
    (resp!.response &&
      resp!.response.data &&
      resp!.response.data.error &&
      resp!.response.data.error.message) ||
    resp!.message ||
    'Oops, something went wrong. Sorry!'
  );
}

/** Create custom error message of axios */
export function createErrorMsg(message: string): AxiosError {
  return {
    response: {
      data: {
        error: {
          message,
        },
      },
    },
  };
}
