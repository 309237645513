import { Theme, createStyles } from '@material-ui/core';
import { scrollbarStyles } from 'utils/commonStyles';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default (theme: Theme) =>
  createStyles({
    root: {
      position: 'relative',
    },
    button: {
      padding: 0,
      color: theme.palette.text.secondary,
      fontSize: theme.typography.body1.fontSize,
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
    skipButton: {
      height: '16px',
      position: 'absolute',
      marginLeft: 'auto',
      marginRight: 'auto',
      color: theme.palette.text.primary,
      left: 0,
      right: 0,
      fontSize: '12px',
      fontWeight: 400,
      letterSpacing: '0.25em',
      width: 50,
      backgroundColor: 'transparent',
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
    email: {
      fontWeight: 400,
      color: theme.palette.primary.main,
    },
    codeInputWrap: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
    },
    list: {
      height: theme.spacing(34),
      overflow: 'scroll',
    },
    listItem: {
      marginBottom: theme.spacing(2),
      height: theme.spacing(7.5),
      paddingTop: theme.spacing(3.75),
      paddingBottom: theme.spacing(2.25),
      paddingLeft: theme.spacing(2.4),
      border: `1px solid ${theme.palette.common.black}`,
      borderRadius: theme.shape.borderRadius,
      fontSize: theme.typography.body1.fontSize,
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      color: theme.palette.text.primary,
      '&.MuiListItemSecondaryAction-root': {
        fontSize: theme.typography.body1.fontSize,
        color: 'inherit',
        top: '25px',
        transform: 'translateY(10%)',
      },
    },
    scrollbar: {
      padding: 0,
      maxHeight: '400px',
      overflowY: 'scroll',
      ...scrollbarStyles,
    },
    codeInput: {
      padding: theme.spacing(3),
      fontSize: theme.typography.h5.fontSize,
      color: theme.palette.grey[600],
      textTransform: 'uppercase',
      '&::placeholder': {
        textTransform: 'none',
      },
    },
    title: {
      fontSize: '40px',
      color: theme.palette.text.primary,
    },
    greyText: {
      color: theme.palette.text.secondary,
    },
    chevronRight: {
      color: 'inherit',
      fontSize: 'inherit',
      transform: 'translateY(30%)',
    },
    searchBox: {
      border: `1px solid`,
      borderColor: theme.palette.common.black,
      borderRadius: theme.spacing(2),
      height: 44,
    },
  });
