import { Theme, createStyles } from '@material-ui/core';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default (theme: Theme) =>
  createStyles({
    root: {},
    dialog: {
      minWidth: '400px',
    },
    dialogContent: {
      padding: theme.spacing(3),
      paddingTop: theme.spacing(4),
    },
    dialogTitle: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      margin: 0,
      padding: theme.spacing(3),
      paddingBottom: 0,
    },
    dialogActions: {
      padding: 0,
      paddingTop: theme.spacing(5),
    },
  });
