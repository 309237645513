import { Box, DialogActions, Typography } from '@material-ui/core';
import { WithStyles, withStyles } from '@material-ui/core/styles';
import Button from 'components/Button/Dialog/Button';
import OutlinedInput from 'components/Input/OutlinedInput';
import RefundReasonSelect from 'components/RefundReasonSelect';
import { observable, makeObservable } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';
import { invoiceRefundReasons } from 'services';
import { inject, WithToastStore } from 'stores';
import { isRequired } from 'utils/formHelpers';
import validatorjs from 'validatorjs';
import styles from './styles';

export type RefundType = 'full' | 'partial';

type MobxForm = any;

const fields = [
  {
    name: 'amount',
    label: 'Amount',
    rules: ['required', 'numeric', 'amount'],
    hooks: {
      onChange: (field: any) => {
        const delimiter = '.';
        const inputString = field.value;
        // Allowed input characters are numbers and the "." char
        const lastChar = inputString[inputString.length - 1];
        const isValidChar = new RegExp(/^[0-9.-]$/).test(lastChar);
        if (!isValidChar) {
          field.set(inputString.slice(0, -1));
        }
        // Dot decimal delimiter cannot appear twice
        const delimiterCount = inputString.split(delimiter).length - 1;
        if (delimiterCount > 1) {
          field.set(inputString.slice(0, -1));
        }
        // There can only be two digits behind a dot decimal delimiter
        const indexOfDelimiter = inputString.indexOf(delimiter);
        if (inputString.includes(delimiter) && indexOfDelimiter < inputString.length - 3) {
          field.set(inputString.slice(0, -1));
        }
        field.validate();
      },
    },
  },
  {
    name: 'reason',
    label: 'Refund Reason ',
    rules: ['required'],
    hooks: {
      onChange: (field: any) => field.validate(),
    },
  },
  {
    name: 'customReason',
    label: 'Reason for refund',
  },
];

// eslint-disable-next-line @typescript-eslint/no-var-requires
const dvr = require('mobx-react-form/lib/validators/DVR');
const MobxReactForm = require('mobx-react-form').default;

interface FormHooks {
  onSuccess: (form: MobxForm) => void;
  onClear: (form: MobxForm) => void;
  onInit: (form: MobxForm) => void;
}

/** Define props for this component */
type RefundFormProps = WithStyles<typeof styles> &
  WithToastStore & {
    gross: string;
    invoiceNumber: string;
    onSubmit: (amount: number, reason: string) => void;
    closeModal: () => void;
  };

/**
 * InvoicesPanel container specific sub component.
 * Used as a child of Dialog component for refunding invoices.
 */
@inject('userStore', 'modalStore', 'toastStore')
@observer
class RefundForm extends React.Component<RefundFormProps> {
  public constructor(props: RefundFormProps) {
    super(props);
    makeObservable(this);
    this.form = new MobxReactForm({ fields }, { plugins: this.plugins, hooks: this.hooks });
  }

  private plugins = {
    dvr: dvr({
      package: validatorjs,
      extend: ({ validator }: { validator: any }) => {
        const messages = validator.getMessages('en');
        messages.accepted = 'Please confirm';
        validator.setMessages('en', messages);
        validator.register(
          'amount',
          (amount: string) => parseFloat(amount) <= parseFloat(this.props.gross),
          `Refund amount cannot greater than ${this.props.gross}`,
        );
      },
    }),
  };

  /** The form object */
  @observable private form: MobxForm;

  private hooks: FormHooks = {
    onInit: (form) => {
      form.$('amount').set(this.props.gross);
      form.$('amount').validate();
    },
    onSuccess: (form) => {
      const amount = form.$('amount').value;
      const predefinedReason = form.$('reason').value;
      const customReason = form.$('customReason').value;
      const reason = predefinedReason === 'OTHER' ? customReason : predefinedReason;
      this.props.onSubmit(amount, reason);
    },
    onClear: (form) => form.clear(),
  };

  render() {
    const { gross } = this.props;
    return (
      <Box>
        <form onSubmit={this.form.onSubmit}>
          <Box mt={4}>
            <OutlinedInput
              {...this.form.$('amount').bind()}
              error={Boolean(this.form.$('amount').error)}
              helperText={
                this.form.$('amount').error || 'Specify net amount. Tax will be included.'
              }
              required={isRequired(this.form.$('amount'))}
              fullWidth
              InputProps={{
                startAdornment: '$',
                endAdornment: (
                  <Typography color="textSecondary" style={{ whiteSpace: 'nowrap' }}>
                    ${gross} Maximum
                  </Typography>
                ),
              }}
            />
          </Box>
          <Box mt={2}>
            <RefundReasonSelect
              selectField={this.form.$('reason')}
              textField={this.form.$('customReason')}
              reasons={invoiceRefundReasons}
            />
          </Box>
          <DialogActions className={this.props.classes.dialogActions}>
            <Button onClick={this.props.closeModal} color="primary">
              Cancel
            </Button>
            <Button type="submit" variant="contained" color="primary" disabled={!this.form.isValid}>
              Refund
            </Button>
          </DialogActions>
        </form>
      </Box>
    );
  }
}

export default withStyles(styles)(RefundForm);
