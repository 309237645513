import React from 'react';
import { observer } from 'mobx-react';
import { computed, observable, action, flow, makeObservable } from 'mobx';

import SettingsStore from './SettingsStore';

import {
  DialogContent,
  DialogTitle,
  IconButton,
  Divider,
  Box,
  Typography,
  Slider,
  DialogContentText,
  DialogActions,
} from '@material-ui/core';

import { WithStyles, withStyles } from '@material-ui/core/styles';

import { ProposalStrategy } from 'models';

import DP from 'components/DashPanel';

import styles from './styles';
import { ChevronLeft } from 'mdi-material-ui';
import Button from 'components/Button/Button';

interface PercentagesProps extends WithStyles<typeof styles> {
  settingsStore: SettingsStore;
  selectedStrategy: ProposalStrategy;
  goBack: () => void;
}

type Range = 'low' | 'medium' | 'high';

/**
 * Simple component that renders three UI sliders that act as inputs for
 * three different tip presets in percentages (low, medium, high).
 *
 * @param settingsStore on demand settings state management store
 * @param selectedStrategy flag for calling API with correct values
 * @param goBack Event handler that gets called when user navigates to previous screen
 */
@observer
class Percentages extends React.Component<PercentagesProps> {
  constructor(props: PercentagesProps) {
    super(props);
    makeObservable(this);
  }
  @observable public settingsStore: SettingsStore = this.props.settingsStore;

  @observable
  public low: number = this.settingsStore.thresholdProposalStrategySettings!.percentLow;

  @observable
  public medium: number = this.settingsStore.thresholdProposalStrategySettings!.percentMedium;

  @observable
  public high: number = this.settingsStore.thresholdProposalStrategySettings!.percentHigh;

  @computed public get updating(): boolean {
    return this.settingsStore.updating;
  }

  @action.bound private handleSliderChange =
    (range: Range) => (event: object, value: number | number[]) => {
      if (Array.isArray(value)) return;
      this[range] = value;
    };

  @action.bound private onSave = () => {
    this.saveSettings();
  };

  @action.bound public saveSettings = flow(function* (this: Percentages) {
    yield this.settingsStore.updateGeneralSettings({ strategy: this.props.selectedStrategy });
    yield this.settingsStore.updateProposalStrategySettings(this.props.selectedStrategy, {
      percentLow: this.low,
      percentMedium: this.medium,
      percentHigh: this.high,
    });
    this.props.goBack();
  });

  render() {
    const { drawerContent, slider, sliderLabels, fontPrimary, fontSecondary, dialogActions } =
      this.props.classes;
    return (
      <>
        <DialogTitle>
          <Box display="flex" alignItems="center" justifyContent="space-start">
            <Box mr={2}>
              <IconButton onClick={this.props.goBack}>
                <ChevronLeft />
              </IconButton>
            </Box>
            <Typography component="h1" style={{ fontSize: 28, fontWeight: 400 }} display="inline">
              Percentages
            </Typography>
            <Box width="40px" ml="auto" display="flex" flexDirection="row" alignItems="center">
              {this.updating && <DP.LoadSpinner />}
            </Box>
          </Box>
        </DialogTitle>
        <Divider />
        <DialogContent className={drawerContent}>
          <Box display="flex" flexDirection="column" height="100%">
            <Box mt={2}>
              <DialogContentText className={fontPrimary}>
                When service amount is greater then the specified threshold, the Tippy Pro kiosk
                will present 3 options in percentages.
              </DialogContentText>
            </Box>
            <Box mt={3}>
              <DialogContentText className={fontSecondary}>
                Percentages to be presented:
              </DialogContentText>
            </Box>
            <Box mt={4}>
              <Box>
                <Typography className={sliderLabels} gutterBottom>
                  low
                </Typography>
                <Slider
                  className={slider}
                  value={this.low}
                  onChange={this.handleSliderChange('low')}
                  color="primary"
                  valueLabelDisplay="on"
                  step={1}
                  min={0}
                  max={100}
                />
              </Box>
              <Box mt={3}>
                <Typography className={sliderLabels} gutterBottom>
                  medium
                </Typography>
                <Slider
                  className={slider}
                  value={this.medium}
                  onChange={this.handleSliderChange('medium')}
                  color="primary"
                  valueLabelDisplay="on"
                  step={1}
                  min={0}
                  max={100}
                />
              </Box>
              <Box mt={3}>
                <Typography className={sliderLabels} gutterBottom>
                  high
                </Typography>
                <Slider
                  className={slider}
                  value={this.high}
                  onChange={this.handleSliderChange('high')}
                  color="primary"
                  valueLabelDisplay="on"
                  step={1}
                  min={0}
                  max={100}
                />
              </Box>
            </Box>
          </Box>
        </DialogContent>
        <DialogActions className={dialogActions}>
          <Button
            // className={submitButton}
            variant="contained"
            color="primary"
            onClick={this.onSave}
            disabled={this.updating}
            fullWidth>
            Save
          </Button>
        </DialogActions>
      </>
    );
  }
}

export default withStyles(styles)(Percentages);
