import BaseStore from './BaseStore';
import { observable, action, autorun, makeObservable, reaction } from 'mobx';
import RootStore from './RootStore';
import { MenuGroup } from 'models/Menu';
import { isValueInArray } from 'utils/helper';

export interface Drawer {
  open: boolean;
  manage: boolean;
  analytics: boolean;
  marketing: boolean;
  tipping: boolean;
  financial: boolean;
  tools: boolean;
}

/**
 * Contains global UI data that should be persisted across
 * page changes.
 */
export default class UiStore extends BaseStore {
  constructor(rootStore: RootStore) {
    super(rootStore);
    makeObservable(this);
  }

  /** The side drawer data */
  @observable public drawer = {
    /** Whether the side drawer is open */
    open: true,
    /** Whether the `manage` section in the side drawer is open */
    manage: false,
    /** Whether the `analytics` section in the side drawer is open */
    analytics: false,
    /** Whether the `marketing` section in the side drawer is open */
    marketing: false,
    /** Whether the `tipping` section in the side drawer is open */
    tipping: false,
    /** Whether the `financial` section in the side drawer is open */
    financial: false,
    /** Whether the `Tools` section in the side drawer is open */
    tools: false,
  } as Drawer;

  /** Whether mobile view requirements have been fulfilled */
  @observable public mobileView = false;
  /** Whether single column page content requirements have been fulfilled */
  @observable public singleColumnPageContent = false;
  /** Whether user mobile menu is rendered */
  @observable public userMobileMenu = false;
  /** Whether DataGrid's TextTooltip component should render the tooltip */
  @observable public showTooltip = false;

  /**
   * Whether appBar was scrolled enough for trigger to be fired.
   * If mobileView is set to true it should override the trigger.
   */
  @observable public appBar = {
    trigger: false,
  };

  /** Opens the side drawer */
  @action.bound public openDrawer() {
    this.drawer.open = true;
  }

  /** Closes the side drawer */
  @action.bound public closeDrawer() {
    this.drawer.open = false;
  }

  /** Set mobileView */
  @action.bound public setMobileView(mobile: boolean) {
    this.mobileView = mobile;
  }

  /** Set singleColumnPageContent */
  @action.bound public setSingleColumnPageContent(threshold: boolean) {
    this.singleColumnPageContent = threshold;
  }

  /** Toggles the side drawer */
  @action.bound public toggleDrawer() {
    this.drawer.open = !this.drawer.open;
  }

  /** Toggles user mobile menu */
  @action.bound public toggleUserMenu() {
    this.userMobileMenu = !this.userMobileMenu;
  }

  @action.bound public toggleDrawerGroup(group: MenuGroup) {
    const key = group.toLowerCase() as unknown as keyof Drawer;
    const drawer = { ...this.drawer };
    if (Object.prototype.hasOwnProperty.call(this.drawer, key)) {
      drawer[key] = !this.drawer[key];
    } else {
      drawer[key] = true;
    }
    // Close all other menu groups
    Object.keys(drawer).forEach((drawerKey) => {
      const _key = drawerKey as keyof Drawer;
      if (!isValueInArray(['open', key], _key) && drawer[_key]) {
        drawer[_key] = false;
      }
    });
    this.drawer = drawer;
  }

  @action.bound public setTrigger(trigger: boolean) {
    this.appBar.trigger = trigger;
  }

  /** Initializes the state from the local storage and sets up autorunners */
  public init() {
    this.initLocalStorage();

    // Whenever anything in `this.drawer` is changed, save `this.drawer`
    // to the local storage.
    autorun(() => {
      localStorage.setItem('drawer', JSON.stringify(this.drawer));
    });
    // We do not want to start with an open toolbar when logging in on a mobile device
    reaction(
      () => this.rootStore.userStore.loggedIn,
      (loggedIn) => {
        if (loggedIn && this.mobileView) {
          this.drawer.open = false;
        }
      },
    );
  }

  /** Check if DataGrid's TextTooltip component should show tooltip */
  handleMouseEnter = (event: React.MouseEvent<HTMLDivElement>) => {
    const targetElement = event.currentTarget;
    this.showTooltip = targetElement.scrollWidth > targetElement.clientWidth;
  };

  /** Initializes the UI data from local storage, if present */
  private initLocalStorage() {
    const drawerJSON = localStorage.getItem('drawer');
    if (drawerJSON) {
      try {
        this.drawer = JSON.parse(drawerJSON);
      } catch (e: any) {
        if (!(e instanceof SyntaxError)) {
          throw e;
        }
      }
    }
  }
}

/** A component's props can extend this interface if it needs the UI store */
export interface WithUiStore {
  uiStore?: UiStore;
}
