import { useCallback, useMemo, useState } from 'react';
import { LocationSelection } from './components/LocationSelection';
import {
  ECorrectionType,
  ICorrection,
  IInvoiceProductData,
  IInvoiceProduct,
  IBillingEntity,
  Location,
  ICreateCorrectionWithInvoiceRequest,
} from 'models';
import { CorrectionSelection } from './components/CorrectionSelection';
import { DialogTitle, Typography, DialogContent, DialogActions, Box } from '@material-ui/core';
import LoadingSpinner from 'components/LoadingSpinner';
import { CorrectionTypeSelector } from './components/CorrectionTypeSelector';
import { useStores } from 'containers/App/App';
import Button from 'components/Button/Dialog/Button';
import { dialogActionsStyling } from '../styles';
import { numericStringToUsd } from 'services';
import { calcLocationPrice, getInvoiceProducts } from '../helpers';
import { CorrectionReview } from './components/CorrectionReview';
import Api, { getErrorMsg } from 'api';

interface WithInvoiceProps {
  accountId: number;
  billingEntityId?: number;
  paymentMethods: IBillingEntity[];
  correctionsList: ICorrection[];
  dialogClose: () => void;
  refreshData: () => void;
  setCorrectionType: (type: ECorrectionType) => void;
}

enum STEPS {
  LOCATIONS = 'locations',
  CORRECTIONS = 'corrections',
  REVIEW = 'review',
}

export const WithInvoice = ({
  accountId,
  paymentMethods,
  billingEntityId,
  correctionsList,
  dialogClose,
  refreshData,
  setCorrectionType,
}: WithInvoiceProps) => {
  const { toastStore } = useStores();

  const [withInvoiceStep, setWithInvoiceStep] = useState<STEPS>(STEPS.LOCATIONS);

  const [selectedBillingEntityId, setSelectedBillingEntityId] = useState(billingEntityId);
  const [selectedLocations, setSelectedLocations] = useState<Location[]>([]);
  const [invoiceProducts, setInvoiceProducts] = useState<IInvoiceProductData[]>([]);

  const [formIsValid, setFormIsValid] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const totalPrice = useMemo((): string => {
    const totalPrice = invoiceProducts.reduce((total, invoice) => {
      const locPrice = calcLocationPrice(invoice, correctionsList);
      return total + locPrice;
    }, 0);

    return numericStringToUsd(String(totalPrice));
  }, [correctionsList, invoiceProducts]);

  /** Submits the correction form */
  const handleSubmit = useCallback(async () => {
    if (!selectedBillingEntityId) {
      return;
    }

    setIsLoading(true);

    const requestData: ICreateCorrectionWithInvoiceRequest = {
      billingEntityId: selectedBillingEntityId,
      invoiceItems: invoiceProducts,
    };

    try {
      await Api.billing.createLedgerCorrectionWithInvoice(accountId, requestData);

      setTimeout(() => {
        toastStore.push({
          type: 'success',
          message: `Correction for ${totalPrice} was successfully applied`,
        });
      }, 1000);
    } catch (e: unknown) {
      toastStore.error(getErrorMsg(e));
    } finally {
      // After success we need some time for DB to be update and to trigger refresh data endpoints
      setTimeout(() => {
        dialogClose();
        setIsLoading(false);
        refreshData();
      }, 1000);
    }
  }, [
    accountId,
    dialogClose,
    invoiceProducts,
    refreshData,
    selectedBillingEntityId,
    toastStore,
    totalPrice,
  ]);

  const getLocationPrice = useCallback(
    (locationId: number) => {
      const thisLoc = invoiceProducts.find((it) => it.locationId === locationId);

      if (!thisLoc) {
        return '$0';
      }

      const locPrice = calcLocationPrice(thisLoc, correctionsList);
      return numericStringToUsd(String(locPrice));
    },
    [correctionsList, invoiceProducts],
  );

  const getInvoiceProduct = useCallback(
    (locationId: number, correctionId: number): IInvoiceProduct | undefined => {
      const thisLoc = invoiceProducts.find((it) => it.locationId === locationId);
      if (!thisLoc) {
        return undefined;
      }

      return thisLoc.products.find((it) => it.productId === correctionId);
    },
    [invoiceProducts],
  );

  const STEP = useMemo(
    () => ({
      [STEPS.LOCATIONS]: {
        component: (
          <>
            <CorrectionTypeSelector
              correctionType={ECorrectionType.WITH_INVOICE}
              setCorrectionType={setCorrectionType}
            />
            <LocationSelection
              accountId={accountId}
              locationsSelected={selectedLocations}
              paymentMethods={paymentMethods}
              billingEntityId={selectedBillingEntityId}
              getFormValues={(billingEntityId, locations) => {
                setSelectedBillingEntityId(billingEntityId);
                setSelectedLocations(locations);
              }}
              validityChanged={setFormIsValid}
            />
          </>
        ),
        btnBack: {
          text: 'Cancel',
          action: dialogClose,
        },
        btnNext: {
          text: 'Next',
          disabled: !formIsValid,
          action: () => setWithInvoiceStep(STEPS.CORRECTIONS),
        },
        step: 1,
      },
      [STEPS.CORRECTIONS]: {
        component: (
          <CorrectionSelection
            locationsSelected={selectedLocations}
            correctionsList={correctionsList}
            invoiceProduct={getInvoiceProduct}
            updateInvoiceProducts={(data) =>
              setInvoiceProducts((prev) => getInvoiceProducts(prev, data))
            }
            totalPrice={totalPrice}
            locationPrice={getLocationPrice}
          />
        ),
        btnBack: {
          text: 'Back',
          action: () => {
            setWithInvoiceStep(STEPS.LOCATIONS);
            setInvoiceProducts([]);
          },
        },
        btnNext: {
          text: 'Next',
          disabled: !invoiceProducts.length,
          action: () => setWithInvoiceStep(STEPS.REVIEW),
        },
        step: 2,
      },
      [STEPS.REVIEW]: {
        component: (
          <CorrectionReview
            locationsSelected={selectedLocations}
            correctionsList={correctionsList}
            invoiceProducts={invoiceProducts}
            totalPrice={totalPrice}
            locationPrice={getLocationPrice}
            selectedCard={paymentMethods.find((it) => it.id === selectedBillingEntityId)}
          />
        ),
        btnBack: {
          text: 'Back',
          action: () => setWithInvoiceStep(STEPS.CORRECTIONS),
        },
        btnNext: {
          text: 'Apply Correction',
          disabled: isLoading,
          action: handleSubmit,
        },
        step: 3,
      },
    }),
    [
      setCorrectionType,
      accountId,
      selectedLocations,
      paymentMethods,
      selectedBillingEntityId,
      dialogClose,
      formIsValid,
      correctionsList,
      getInvoiceProduct,
      totalPrice,
      getLocationPrice,
      invoiceProducts,
      isLoading,
      handleSubmit,
    ],
  );

  return (
    <>
      <DialogTitle>
        <Box display="flex" flexDirection="row" justifyContent="space-between">
          <Typography style={{ fontSize: 28, fontWeight: 400 }}>Create Correction</Typography>
          {isLoading && <LoadingSpinner size={24} />}
        </Box>
      </DialogTitle>

      <DialogContent>{STEP[withInvoiceStep].component}</DialogContent>

      <Box sx={dialogActionsStyling}>
        <Box className="step">Step {STEP[withInvoiceStep].step} of 3</Box>

        <DialogActions className="dialogActions">
          <Button color="primary" onClick={STEP[withInvoiceStep].btnBack.action}>
            {STEP[withInvoiceStep].btnBack.text}
          </Button>
          <Button
            variant="contained"
            color="primary"
            disabled={STEP[withInvoiceStep].btnNext.disabled}
            onClick={STEP[withInvoiceStep].btnNext.action}>
            {STEP[withInvoiceStep].btnNext.text}
          </Button>
        </DialogActions>
      </Box>
    </>
  );
};
