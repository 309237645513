import { Theme, createStyles, makeStyles } from '@material-ui/core';

interface IStyleProps {
  center?: boolean;
}

const useStyles = makeStyles<Theme, IStyleProps>((theme: Theme) =>
  createStyles({
    resendCode: {
      color: `${theme.palette.primary.main}!important`,
      textDecoration: 'underline',
      textTransform: 'none',
    },
    greenFont: {
      color: theme.palette.primary.main,
      fontWeight: 700,
    },
    textAlign: {
      textAlign: ({ center }) => (center ? 'center' : 'left'),
    },
    contentCenter: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    formHelperText: {
      '&.MuiFormHelperText-root': {
        margin: 0,
        marginBottom: theme.spacing(2),
        fontSize: theme.typography.body1.fontSize,
        textAlign: ({ center }) => (center ? 'center' : 'left'),
      },
    },
  }),
);

export default useStyles;
