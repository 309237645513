import { faInfoCircle } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Tooltip } from '@mui/material';
import { useStores } from 'containers/App/App';
import { copyTextToClipboard } from 'services';
import { SALES_TEAM_EMAIL, SALES_TEAM_PHONE } from 'utils/constants';
import { licenseInfoIcon } from './styles';

enum ECopyToClipboad {
  EMAIL = 'email',
  PHONE_NUMBER = 'phone_number',
}

const BillingGeneralInfoIcon = () => {
  const { toastStore } = useStores();

  const copyText = (objectClicked: ECopyToClipboad) => {
    const valueToCopy =
      objectClicked === ECopyToClipboad.EMAIL ? SALES_TEAM_EMAIL : SALES_TEAM_PHONE;

    const message = objectClicked === ECopyToClipboad.EMAIL ? 'Email' : 'Phone number';
    copyTextToClipboard(valueToCopy);

    toastStore!.success(`${message} copied to clipboard`);
  };

  return (
    <Tooltip
      title={
        <Box component={'span'}>
          Wish to add a new license? Contact our sales team at{' '}
          <Box
            component={'span'}
            onClick={() => copyText(ECopyToClipboad.EMAIL)}
            style={{ textDecoration: 'underline' }}>
            {SALES_TEAM_EMAIL + ' or '}
          </Box>
          <Box component={'span'} onClick={() => copyText(ECopyToClipboad.PHONE_NUMBER)}>
            {SALES_TEAM_PHONE}
          </Box>
        </Box>
      }>
      <FontAwesomeIcon
        onMouseDown={(event) => event.stopPropagation()}
        icon={faInfoCircle}
        style={licenseInfoIcon.faInfoCircle}
      />
    </Tooltip>
  );
};

export default BillingGeneralInfoIcon;
