import { useStores } from 'containers/App/App';
import _ from 'lodash';
import { AddStation, IStationGroup, StationItem } from 'models/Station';
import { useEffect, useState } from 'react';
import { useStationNavigation } from '../useStationNavigation';
import { BlPoolsAndGroups, IDisplayPool, IStationDisplayGroup } from './blPoolsAndGroups';

type usePoolsAndGroupsListReturn = [
  (IStationDisplayGroup | IDisplayPool)[] | undefined,
  IStationGroup[] | [],
  (group: IStationDisplayGroup) => void,
  (item: any) => void,
];

export function useStationPoolsAndGroupsList(): usePoolsAndGroupsListReturn {
  const { stationsStore } = useStores();
  const { stationNavigation } = useStationNavigation();
  const [groups, setGroups] = useState<IStationGroup[] | []>([]);
  const [poolsAndGroups, setPoolsAndGroups] = useState<
    Array<IStationDisplayGroup | IDisplayPool> | undefined
  >();
  const { locationPools, station, selectedGroup, setPreviousGroupName, setStateField } =
    stationsStore!;

  useEffect(() => {
    mapPoolsAndGroupsForDisplay();
  }, [station, selectedGroup]);

  function removePoolOrGroup(item: any) {
    if (item.type === StationItem.POOL) {
      item = { poolId: item.id, type: item.type };
    } else {
      item = { groupName: item.name, type: item.type, groupItems: item.groupItems };
    }
    if (stationsStore.addStationState === AddStation.GROUP) {
      let selectedGroup = { ...stationsStore.selectedGroup! };
      _.remove(selectedGroup.groupItems, item);
      setStateField('groupItems', selectedGroup.groupItems);
    } else {
      let station = { ...stationsStore.station! };
      _.remove(station.items, item);
      setStateField('items', station.items);
    }
  }

  function mapPoolsAndGroupsForDisplay() {
    let items = station?.items;
    if (stationsStore.addStationState === AddStation.GROUP) {
      items = stationsStore.selectedGroup!.groupItems;
    }
    const poolsAndGroups = BlPoolsAndGroups.mapPoolsAndGroupsForDisplay(items, locationPools);
    setPoolsAndGroups(poolsAndGroups);
  }

  function editGroup(group: IStationDisplayGroup) {
    const { name, groupItems, type } = group;
    stationsStore!.selectedGroup = { groupName: name, groupItems, type };

    setPreviousGroupName(name);
    stationNavigation.openGroup();
  }

  return [poolsAndGroups, groups, editGroup, removePoolOrGroup];
}
