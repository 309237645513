import { RouteComponentProps, withRouter } from 'react-router-dom';
import React from 'react';
import { ListItem, ListItemText } from '@material-ui/core';

import { paths } from 'routes';
import { Scope } from 'stores/UserStore';

import useStyles from './styles';
import { ListItemIcon } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import theme from 'containers/App/theme';
import clsx from 'clsx';

interface ScopeItemProps extends RouteComponentProps {
  scope: Scope;
  children: React.ReactNode;
  active: boolean;
  setScope: (s: Scope) => void;
  icon?: React.ReactElement<any, string | React.JSXElementConstructor<any>>;
  dataCy?: string;
}

/**
 * A single item on the left-hand side of the scope switcher.
 */
function ScopeItem({ scope, children, active, setScope, history, icon, dataCy }: ScopeItemProps) {
  const classes = useStyles({});
  const setScopeAndNavigate = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    history.push(paths.root());
    setScope(scope);
  };
  const color = active ? theme.palette.primary.main : undefined;
  return (
    <ListItem
      data-cy={dataCy}
      button
      onClick={setScopeAndNavigate}
      disableGutters
      className={clsx(classes.scopeItemRoot, active && classes.scopeItemActive)}
      style={{ color }}>
      <ListItemIcon style={{ color }}>{icon}</ListItemIcon>
      <ListItemText>{children}</ListItemText>
      <ActiveIcon active={active} />
    </ListItem>
  );
}

function ActiveIcon({ active }: { active: boolean }) {
  if (!active) {
    return <></>;
  }
  return <CheckIcon />;
}

export default withRouter(ScopeItem);
