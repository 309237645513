import {
  Box,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Drawer,
  FormControl,
  FormHelperText,
  IconButton,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  Typography,
} from '@material-ui/core';
import { withStyles, WithStyles } from '@material-ui/core/styles';

import { ChevronLeft, Close, Pencil, Plus } from 'mdi-material-ui';
import { observable, makeObservable } from 'mobx';

import { observer } from 'mobx-react';
import React from 'react';
import validatorjs from 'validatorjs';
import styles from './styles';
import Api, { getErrorMsg, RequestMetaData } from 'api';
import { Autocomplete } from '@material-ui/lab';
import { Developer, IntegrationApp, Partner } from 'models';
import { inject, WithToastStore } from 'stores';
import {
  ASSIGN_TO,
  FeeScheduleType,
  IDistributions,
  IFeeScheduleResponse,
} from 'models/FeeSchedule';
import _ from 'lodash';
import { Alert } from 'components/Alert/Alert';
import OutlinedInput from 'components/Input/OutlinedInput';
import Button from 'components/Button/Button';
import { FEE_TYPE_MAPPER } from './FeeScheduleHelper';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const dvr = require('mobx-react-form/lib/validators/DVR');
const MobxReactForm = require('mobx-react-form').default;

type FeeScheduleDrawerProps = WithStyles<typeof styles> &
  WithToastStore & {
    triggerDataRefetch: () => void;
    isScheduleDrawerOpen: boolean;
    openFeeScheduleDrawer: () => void;
    closeFeeScheduleDrawer: () => void;
  };

interface IRules {
  [key: string]: {
    function: (value: any) => boolean;
    message: string;
  };
}

function getDeveloperRule(fieldName: string) {
  return {
    function: (value: Developer) => {
      if (
        !Object.prototype.hasOwnProperty.call(value, 'id') ||
        !Object.prototype.hasOwnProperty.call(value, 'name')
      )
        return false;
      const { id, name } = value;
      if (typeof id === 'number' && typeof name === 'string') return true;
      return false;
    },
    message: `${fieldName} must be valid.`,
  };
}

const rules: IRules = {
  account: getDeveloperRule('Account'),
  location: getDeveloperRule('Location'),
  partner: getDeveloperRule('Partner'),
  app: getDeveloperRule('App'),
  distribution: {
    function: (value: IDistributionRule[]) => {
      if (!value) return false;
      const tippy = value.find((rule: any) => {
        return rule.partner.name === 'Tippy';
      });
      if (tippy) return true;
      return false;
    },
    message: 'Tippy must be one of the partners in distribution rules.',
  },
  decimal: {
    function: (value: any) => {
      const mask = RegExp('^[0-9]*(.(?<!-)[0-9]{1,6})?$');
      if (mask.test(value)) return true;
      return false;
    },
    message: 'Number must be a positive integer or decimal number with 6 decimal places.',
  },
};

const plugins = {
  dvr: dvr({
    package: validatorjs,
    extend: ({ validator, form }: { validator: any; form: any }) => {
      Object.keys(rules).forEach((key) =>
        validator.register(key, rules[key].function, rules[key].message),
      );
    },
  }),
};

const options = {
  showErrorsOnClear: false,
};

interface IDistributionRule {
  partner: { name: string; id: number };
  feeAmount: number;
  feePercent: number;
}

interface IFormState {
  name: string | undefined;
  assignTo?: ASSIGN_TO;
  processor: string | undefined;
  app: { name: string; id: number } | undefined;
  account: { name: string; id: number } | undefined;
  location: { name: string; id: number } | undefined | undefined;
  partner: { name: string; id: number } | undefined;
  feeAmount: number | undefined;
  feePercent: number | undefined;
  distributionRules: IDistributionRule[] | undefined;
  type: FeeScheduleType | undefined;
}

const initialFormState: IFormState = {
  name: undefined,
  assignTo: undefined,
  processor: undefined,
  app: undefined,
  account: undefined,
  location: undefined,
  partner: undefined,
  feeAmount: undefined,
  feePercent: undefined,
  distributionRules: undefined,
  type: FeeScheduleType.DEFAULT,
};

const distributionRuleFields = ['partner', 'feeAmount', 'feePercent'];

@inject('toastStore')
@observer
class FeeScheduleDrawer extends React.Component<FeeScheduleDrawerProps> {
  constructor(props: any) {
    super(props);
    makeObservable(this);

    this.form = new MobxReactForm(
      { fields: this.fields },
      { plugins, hooks: this.hooks },
      { options },
    );
    this.ruleForm = new MobxReactForm({ fields: this.addRuleFields }, { plugins }, { options });
    this.getProcessors();
    this.getApps();
  }

  private form: any;
  private ruleForm: any;

  @observable private addRule = false;
  @observable private editingRule = false;
  @observable private isRuleValid = false;
  @observable private isFormValid = false;
  @observable private feeScheduleExists = false;
  @observable private feeScheduleNameExists = false;
  @observable private showAlert = false;

  @observable updateLocation = Date.now();
  @observable partnerFormKey = Date.now();

  @observable private processorOptions: string[] | undefined = undefined;
  @observable private appOptions: { id: number; name: string }[] | undefined = undefined;
  @observable private accountOptions: { id: number; name: string }[] | undefined = undefined;
  @observable private locationOptions: { id: number; name: string }[] | undefined = undefined;
  @observable private partnerOptions: { id: number; name: string }[] | undefined = undefined;

  @observable formState: IFormState = {
    ...initialFormState,
  };

  componentDidUpdate() {
    if (!this.formState.distributionRules) this.getDefaultPartnerRule();
  }

  getDefaultPartnerRule = async () => {
    const partners = await this.getPartners('Tippy');
    let tippy = partners.find((partner: Partner) => {
      if (partner.name === 'Tippy') return partner;
    });
    if (tippy) {
      const defaultRule = {
        partner: tippy,
        feeAmount: tippy.feeAmount,
        feePercent: tippy.feePercent,
      };
      this.formState.distributionRules = [defaultRule];
      this.form.$('distributionRules').set('value', this.formState.distributionRules);
      this.validateSpecificFields(['distributionRules'], this.form, true);
    }
  };

  openAddRule = async () => {
    this.addRule = true;
    this.resetFields(distributionRuleFields, this.ruleForm);
  };

  editRule = (value: any, form: any) => {
    this.editingRule = true;
    this.openAddRule();
    this.populateRuleFields(value, form);
  };

  closeAddRule = () => {
    this.partnerOptions = undefined;
    this.clearRuleFields();
    this.rerenderPartnerAutocomplete();
    this.editingRule = false;
    this.addRule = false;
    this.validateSpecificFields(['distributionRules'], this.form, true);
  };

  rerenderPartnerAutocomplete = () => {
    this.partnerFormKey = Date.now();
  };

  private closeFeeScheduleDrawer = () => {
    this.accountOptions = undefined;
    this.locationOptions = undefined;
    this.form.clear();
    this.formState = { ...initialFormState };
    this.formState.processor = undefined;
    this.props.closeFeeScheduleDrawer();
    this.checkIfFormValid();
    this.feeScheduleExists = false;
    this.feeScheduleNameExists = false;
    this.form.$('type').set('value', initialFormState.type);
  };

  private getAccounts = async (query: string) => {
    let rmd: RequestMetaData = { filters: { name: query, isActive: true } };
    const { data } = await Api.core.getAccounts(rmd);
    const filteredData = data.data.map(
      (account: { id: string; name: string; address: string; [key: string]: string }) => {
        return { id: account.id, name: account.name, address: account.address };
      },
    );
    return filteredData;
  };

  private getLocations = async (query?: string) => {
    if (!this.formState.account) return;
    const accountId = this.formState.account.id;
    const { data } = await Api.core.searchAccountLocations(accountId, { name: query });
    const filteredData = data.data.map(
      (location: { id: string; name: string; address: string; [key: string]: string }) => {
        return { id: location.id, name: location.name, address: location.address };
      },
    );
    return filteredData;
  };

  private getProcessors = async () => {
    const { data } = await Api.tips.getProcessors();
    this.processorOptions = data.data;
  };

  private getPartners = async (query: string) => {
    const { data } = await Api.tips.searchPartnersByName(query);
    const filteredData = data.data!.map(
      (partner: { id: string; name: string; [key: string]: string }) => {
        return {
          id: partner.id,
          name: partner.name,
          feeAmount: partner.feeAmount,
          feePercent: partner.feePercent,
          type: partner.type,
        };
      },
    );
    return filteredData;
  };

  private getApps = async () => {
    const { data } = await Api.developer.getAllApps();
    const filteredData = data.data!.map((app: IntegrationApp) => {
      return { id: app.id, name: app.name };
    });
    this.appOptions = filteredData;
  };

  populateRuleFields = (value: any, form: any) => {
    const feeAmount = !value.feeAmount ? 0 : value.feeAmount;
    const feePercent = !value.feePercent ? 0 : value.feePercent;
    this.changeSelectedField({ name: 'feeAmount', value: feeAmount });
    form.$('feeAmount').set('value', feeAmount);
    form.validate('feeAmount', { showErrors: true });
    this.changeSelectedField({ name: 'feePercent', value: feePercent });
    form.$('feePercent').set('value', feePercent);
    form.validate('feePercent', { showErrors: true });

    if (this.editingRule) {
      this.formState.partner = value.partner;
      const partner = { name: value.partner.name, id: value.partner.id };
      this.changeSelectedField({ name: 'partner', value: partner });
      form.$('partner').set('value', partner);
      form.validate('partner', { showErrors: true });
    }
  };

  checkIfDuplicatePartner = (partner: Partner) => {
    if (this.formState.distributionRules) {
      const rule = this.formState.distributionRules.find((rule: any) => {
        if (rule.partner.name === partner.name) return true;
      });
      if (rule) return true;
    }
    return false;
  };

  duplicatePartnerError = (duplicatePartner: boolean) => {
    if (duplicatePartner) {
      this.ruleForm.$('partner').invalidate('This partner already exists in distribution rules.');
    }
  };

  clearLocationField = async (name: string, newValue: any) => {
    if (name === 'account' && !_.isEqual(newValue, this.formState.account)) {
      this.changeSelectedField({ name: 'location', value: '' });
      this.form.$('location').set('value', '');
      this.updateLocation = Date.now();
    }
  };

  getNewLocationOptionsForAccount = async (name: string) => {
    if (name !== 'account') return;
    await this.getNewLocationOptions();
    this.updateLocation = Date.now();
  };

  private changeAutocompleteField = (form: any, newValue: any, fieldName: keyof IFormState) => {
    this.clearLocationField(fieldName, newValue);
    this.changeSelectedField({ name: fieldName, value: newValue });
    this.getNewLocationOptionsForAccount(fieldName);
    form.$(fieldName).set('value', newValue);
    if (fieldName === 'partner') {
      this.populateRuleFields(newValue, form);
      const duplicatePartner = this.checkIfDuplicatePartner(newValue);
      this.duplicatePartnerError(duplicatePartner);
    } else {
      form.validate(fieldName, { showErrors: true });
    }
  };

  private getNewAccountOptions = async (e: any, newValue: string) => {
    this.accountOptions = await this.getAccounts(newValue);
  };

  private getNewLocationOptions = async (e?: any, newValue?: string) => {
    this.locationOptions = await this.getLocations(newValue);
  };

  private getNewPartnerOptions = async (e: any, newValue: string) => {
    this.partnerOptions = await this.getPartners(newValue);
  };

  private changeSelectedField = (e: any) => {
    let { name, value } = e;
    value = this.setUndefined(value);
    this.formState[name as keyof IFormState] = value;
    this.checkIfNameExists(name, value);
    this.checkIfFormValid();
    this.checkIfRuleValid(this.ruleForm);
  };

  checkIfNameExists = async (name: string, value: string) => {
    if (name !== 'name') return;
    const { data } = await Api.tips.getFeeSchedules({ filters: { exactName: value } });
    if (data && data.data && data.data.length) this.feeScheduleNameExists = true;
    else this.feeScheduleNameExists = false;
  };

  private setInputValue = (field: keyof IFormState) => {
    if (!this.formState[field]) return undefined;
    const value = this.formState[field] as { name: string; id: number };
    return value.name;
  };

  setUndefined = (value: any) => {
    if (!value && value !== 0) return undefined;
    return value;
  };

  toggleDisabled = (name: string, params: any) => {
    const state = this.formState;
    if (name === 'processor' && state.processor) return this.disableField(params);
    if (name === 'processor' && !state.processor) return this.enableField(params);
    if (name === 'app' && state.app) return this.disableField(params);
    if (name === 'app' && !state.app) return this.enableField(params);
  };

  setCorrectRules = (fieldName: string) => {
    if (fieldName === 'app') {
      this.form.$('processor').set('rules', 'string');
      this.form.$('app').set('rules', 'required|app');
    } else {
      this.form.$('processor').set('required|string');
      this.form.$('app').set('rules', 'app');
    }
  };

  assignToFields = [ASSIGN_TO.PROCESSOR, ASSIGN_TO.INTEGRATION];

  handleAssignTo = () => {
    this.assignToFields.forEach((field) => {
      let name = field === ASSIGN_TO.INTEGRATION ? 'app' : field;
      this.changeSelectedField({ name, value: undefined });
      this.form.$(name).clear();
    });
  };

  @observable private fields = [
    {
      name: 'name',
      label: 'Name',
      value: this.formState.name,
      hooks: { onChange: this.changeSelectedField },
      rules: 'required|string',
    },
    {
      name: 'assignTo',
      label: 'Assign To',
      value: this.formState.assignTo,
      hooks: {
        onChange: (e: any) => {
          this.handleAssignTo();
          this.changeSelectedField(e);
        },
      },
      rules: `required|string`,
    },
    {
      name: 'app',
      label: 'App',
      value: this.formState.app,
      rules: `required|app`,
    },
    {
      name: 'processor',
      label: 'Payment processor',
      type: 'select',
      value: this.formState.processor,
      rules: `required|string`,
    },
    {
      name: 'account',
      label: 'Account',
      value: this.formState.account,
      rules: 'account',
    },
    {
      name: 'location',
      label: 'Location',
      value: this.formState.location,
      rules: 'location',
    },
    {
      name: 'distributionRules',
      label: 'Distribution Rules',
      value: this.formState.distributionRules,
      rules: 'required|distribution',
    },
    {
      name: 'type',
      label: 'Type',
      type: 'select',
      value: this.formState.type,
      rules: `required|string`,
      hooks: {
        onChange: (e: { value: FeeScheduleType }) => (this.formState.type = e.value),
      },
    },
  ];

  @observable private addRuleFields = [
    {
      name: 'partner',
      label: 'Partner',
      value: this.formState.partner,
      rules: 'required|partner',
    },
    {
      name: 'feeAmount',
      label: 'Amount',
      value: this.formState.feeAmount,
      hooks: { onChange: this.changeSelectedField },
      rules: 'required|decimal',
    },
    {
      name: 'feePercent',
      label: 'Percent',
      value: this.formState.feePercent,
      hooks: { onChange: this.changeSelectedField },
      rules: 'required|decimal',
    },
  ];

  checkIfRuleValid = async (form: any) => {
    this.isRuleValid = await this.validateSpecificFields(distributionRuleFields, form, false);
  };

  checkIfFormValid = async () => {
    const hasError = await this.validateFeeScheduleForm(false);
    await this.checkIfFeeScheduleExists(hasError);
    this.enableSave(hasError);
  };

  async checkIfFeeScheduleExists(hasError: false) {
    if (hasError) return;

    let filters: any;
    const { app, location, processor, account, type } = this.formState;
    if (app && app.id) filters = { ...filters, appId: app.id };
    if (location && location.id) filters = { ...filters, locationId: location.id };
    if (processor) filters = { ...filters, processor };
    if (account && account.id) filters = { ...filters, accountId: account.id };
    if (type) filters = { ...filters, type };
    const { data } = await Api.tips.getFeeSchedules({ filters });
    if (!(data && data.data && data.data.length)) return (this.feeScheduleExists = false);

    const feeScheduleExists = data.data!.find((feeSchedule: IFeeScheduleResponse) => {
      const { appId, accountId, locationId, processor, type } = feeSchedule;
      let compare: Partial<IFeeScheduleResponse> = { appId, accountId, locationId, processor, type };
      this.removeEmptyProperties(compare);
      return _.isEqual(filters, compare);
    });
    if (feeScheduleExists) this.feeScheduleExists = true;
    else this.feeScheduleExists = false;
  }

  removeEmptyProperties = (compare: any) => {
    Object.keys(compare).forEach((value: string, index: number, array: string[]) => {
      const key = value as keyof IFeeScheduleResponse;
      if (!compare[key]) delete compare[key];
    });
  };

  enableSave = (hasError: boolean) => {
    if (hasError) return (this.isFormValid = false);
    if (!hasError) return (this.isFormValid = true);
  };

  handleRuleAction = async () => {
    const isValid = await this.validateSpecificFields(distributionRuleFields, this.ruleForm, true);
    if (!isValid) return;
    const { partner, feeAmount, feePercent } = this.formState;
    const newRule = {
      partner: { name: partner!.name, id: partner!.id },
      feeAmount,
      feePercent,
    };
    if (this.editingRule) {
      this.updateRules(newRule as IDistributionRule);
    } else {
      if (!this.formState.distributionRules)
        this.formState.distributionRules = [newRule as IDistributionRule];
      else this.formState.distributionRules!.push(newRule as IDistributionRule);
    }
    this.form.$('distributionRules').set('value', this.formState.distributionRules);
    this.validateSpecificFields(['distributionRules'], this.form, true);
    this.closeAddRule();
  };

  updateRules = (newRule: IDistributionRule) => {
    this.formState.distributionRules = this.formState.distributionRules!.map((rule: any) => {
      if (rule.partner.id === newRule.partner!.id) {
        return newRule;
      }
      return rule;
    });
  };

  setDistributionRule = () => {
    const rules = this.formState.distributionRules;
    const ruleCount = rules === undefined || rules.length === 0 ? '' : rules.length;
    this.form.$('distributionRule').set('value', ruleCount.toString());
    this.validateSpecificFields(['distributionRule'], this.form, true);
  };

  validateSpecificFields = async (fields: string[], form: any, showErrors: boolean) => {
    const validators = fields.map((field: string) => form.validate(field, { showErrors }));
    const fieldResults = await Promise.all(validators);
    return fieldResults.every((field) => field.isValid === true);
  };

  resetFields = async (fields: string[], form: any) => {
    const resets = () => fields.map((field: string) => form.$(field).resetValidation());
    await Promise.all(resets());
  };

  clearRuleFields = () => {
    distributionRuleFields.forEach((field) => {
      this.changeSelectedField({ name: field, value: undefined });
      this.ruleForm.$(field).clear();
    });
  };

  disableField = (params: any) => {
    params.inputProps.disabled = true;
  };

  enableField = (params: any) => {
    params.inputProps.disabled = false;
  };

  removeRule = (index: number) => {
    if (this.formState.distributionRules === undefined) return;
    const newRules = this.formState.distributionRules.filter(
      (rule, ruleIndex) => ruleIndex !== index,
    );
    this.form.$('distributionRules').set('value', newRules);
    if (newRules.length === 0) this.formState.distributionRules = undefined;
    else this.formState.distributionRules = newRules;
    this.checkIfFormValid();
  };

  validateFeeScheduleForm = async (showErrors: boolean) => {
    const isValid = await this.form.validate({ showErrors });
    return isValid.hasError;
  };

  private prepareFeeScheduleData = () => {
    const { name, account, processor, app, location, distributionRules, type } = this.formState;
    const distributions = distributionRules!.map((rule: any) => {
      const { partner, feeAmount, feePercent } = rule;
      return { partnerId: partner.id, feeAmount, feePercent };
    });
    let feeSchedule: any = {
      name,
      type,
    };
    if (account) feeSchedule.accountId = account.id;
    if (location) feeSchedule.locationId = location.id;
    if (processor) feeSchedule.processor = processor;
    if (app) feeSchedule.appId = app.id;
    if (distributions) {
      feeSchedule.distributions = distributions.map((distribution: IDistributions) => {
        const feeAmount = Number(distribution.feeAmount);
        const feePercent = Number(distribution.feePercent);
        return { ...distribution, feeAmount, feePercent };
      });
    }
    return feeSchedule;
  };

  saveFeeSchedule = async () => {
    const feeSchedule = this.prepareFeeScheduleData();
    try {
      await Api.tips.createFeeSchedule(feeSchedule);
      this.closeFeeScheduleDrawer();
      this.props.triggerDataRefetch();
      this.props.toastStore!.push({ type: 'success', message: 'Fee schedule saved sucessfully' });
    } catch (error) {
      this.props.toastStore!.push({ type: 'error', message: getErrorMsg(error) });
    }
  };

  acceptFeeScheduleOverride = () => {
    this.saveFeeSchedule();
    this.showAlert = false;
  };

  rejectFeeScheduleOverride = () => {
    this.showAlert = false;
  };

  private hooks = {
    onSuccess: async () => {
      if (this.feeScheduleExists) this.showAlert = true;
      else this.saveFeeSchedule();
    },
    onSubmit: async () => {
      if (!this.formState.processor && !this.formState.app) {
        this.form.$('processor').set('rules', 'required|string');
        this.form.$('app').set('rules', 'required|app');
      }
      await this.validateFeeScheduleForm(true);
    },
  };

  getOptionWithAddress = (name: string, address?: string) => {
    return (
      <Box flexDirection="column">
        <Box component="span">{name}</Box>
        <Box component="span" className={this.props.classes.address}>
          {address}
        </Box>
      </Box>
    );
  };

  renderOptionWithAdditionalInfo = (name: string, additionalInfo?: string) => {
    return (
      <Box flexDirection="column">
        <Box component="span">{name}</Box>
        <Box component="span" className={this.props.classes.address}>
          {additionalInfo}
        </Box>
      </Box>
    );
  };

  renderFeeSchedule = () => {
    const {
      distributionRule,
      settingListItemKey,
      ruleListItem,
      distributionRules,
      dialogActions,
      plusIcon,
      pencilIcon,
      closeIcon,
      iconButton,
    } = this.props.classes;
    const addRule = this.addRule;
    const form = this.form;
    return (
      <>
        <DialogTitle hidden={addRule}>
          <Box display="flex" alignItems="center" justifyContent="space-between">
            <Typography style={{ fontSize: 28 }} component="h1" display="inline">
              Create Fee Schedule
            </Typography>
            <IconButton onClick={() => this.closeFeeScheduleDrawer()}>
              <Close color="inherit" />
            </IconButton>
          </Box>
        </DialogTitle>
        <Divider />
        <DialogContent hidden={addRule}>
          <form onSubmit={form.onSubmit}>
            <Box mt={2}>
              <OutlinedInput
                fullWidth
                {...form.$('name').bind()}
                required={form.$('name').rules.includes('required')}
                helperText={
                  this.feeScheduleNameExists
                    ? 'Warning: fee schedule with the same name already exists.'
                    : form.$('name').error
                }
                error={form.$('name').hasError || this.feeScheduleNameExists}
              />
            </Box>
            <Box mt={2}>
              <OutlinedInput
                {...this.form.$('assignTo').bind()}
                select
                error={this.form.$('assignTo').error}
                required={this.form.$('assignTo').rules.includes('required')}
                fullWidth>
                {Object.values(ASSIGN_TO).map((assignTo, index) => {
                  return (
                    <MenuItem key={`${assignTo}-${index}`} value={assignTo}>
                      {assignTo}
                    </MenuItem>
                  );
                })}
              </OutlinedInput>
            </Box>
            {this.formState.assignTo === ASSIGN_TO.PROCESSOR && (
              <Box mt={2}>
                <Autocomplete
                  options={this.processorOptions || []}
                  onChange={(e: any, newValue) => {
                    this.setCorrectRules('processor');
                    this.changeAutocompleteField(form, newValue, 'processor');
                  }}
                  inputValue={this.formState.processor}
                  renderInput={(params) => {
                    this.toggleDisabled('app', params);
                    return (
                      <OutlinedInput
                        {...params}
                        ref={params.InputProps.ref}
                        inputProps={params.inputProps}
                        label={form.$('processor').label}
                        error={form.$('processor').error}
                        required={form.$('processor').rules.includes('required')}
                        fullWidth
                      />
                    );
                  }}
                />
              </Box>
            )}
            {this.formState.assignTo === ASSIGN_TO.INTEGRATION && (
              <Box mt={3}>
                <Autocomplete
                  options={this.appOptions || []}
                  onChange={(e: any, newValue) => {
                    this.setCorrectRules('app');
                    this.changeAutocompleteField(form, newValue, 'app');
                  }}
                  getOptionLabel={(options) => options.name}
                  inputValue={this.setInputValue('app')}
                  renderInput={(params) => {
                    this.toggleDisabled('processor', params);
                    return (
                      <OutlinedInput
                        {...params}
                        ref={params.InputProps.ref}
                        inputProps={params.inputProps}
                        label={form.$('app').label}
                        error={form.$('app').error}
                        required={form.$('app').rules.includes('required')}
                      />
                    );
                  }}
                />
              </Box>
            )}
            <Box mt={2}>
              <Autocomplete
                options={this.accountOptions || []}
                onChange={(e: any, newValue) =>
                  this.changeAutocompleteField(form, newValue, 'account')
                }
                onInputChange={this.getNewAccountOptions}
                renderOption={(options: any) =>
                  this.renderOptionWithAdditionalInfo(options.name, options.address)
                }
                getOptionLabel={(options) => options.name}
                inputValue={this.setInputValue('account')}
                renderInput={(params: any) => (
                  <OutlinedInput
                    {...params}
                    ref={params.InputProps.ref}
                    inputProps={params.inputProps}
                    placeholder={form.$('account').label}
                    error={form.$('account').error}
                    required={form.$('account').rules.includes('required')}
                    fullWidth
                  />
                )}
              />
            </Box>
            <Box mt={2}>
              <Autocomplete
                key={this.updateLocation}
                disabled={this.formState.account ? false : true}
                options={this.locationOptions || []}
                onChange={(e: any, newValue) =>
                  this.changeAutocompleteField(form, newValue, 'location')
                }
                onInputChange={this.getNewLocationOptions}
                getOptionLabel={(options) => options.name}
                renderOption={(options: any) =>
                  this.renderOptionWithAdditionalInfo(options.name, options.address)
                }
                inputValue={this.setInputValue('location')}
                renderInput={(params) => (
                  <OutlinedInput
                    {...params}
                    ref={params.InputProps.ref}
                    inputProps={params.inputProps}
                    label={form.$('location').label}
                    error={form.$('location').error}
                    required={form.$('location').rules.includes('required')}
                    fullWidth
                    helperText={
                      this.formState.account
                        ? form.$('location').error
                        : 'Account must be selected before location.'
                    }
                  />
                )}
              />
            </Box>
            <Box mt={2}>
              <OutlinedInput
                {...this.form.$('type').bind()}
                select
                error={this.form.$('type').error}
                required={this.form.$('type').rules.includes('required')}
                fullWidth>
                {Object.entries(FEE_TYPE_MAPPER).map(([val, key], index) => (
                  <MenuItem key={`${val}-${index}`} value={val}>
                    {key}
                  </MenuItem>
                ))}
              </OutlinedInput>
            </Box>
            <Box
              mt={3}
              mb={1.5}
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              className={distributionRules}>
              <Typography variant="body1" component="h1" display="inline">
                Distribution Rules
              </Typography>
              <IconButton style={{ cursor: 'pointer' }} onClick={() => this.openAddRule()}>
                <Plus className={plusIcon} color="primary" />
              </IconButton>
            </Box>
            {this.formState.distributionRules && (
              <List style={{ padding: 0 }}>
                {this.formState.distributionRules.map((rule, index) => {
                  let isTippy = false;
                  if (rule.partner.name === 'Tippy') isTippy = true;
                  return (
                    <ListItem
                      key={`${rule.partner}-${index}`}
                      alignItems="center"
                      className={ruleListItem}>
                      <ListItemText className={settingListItemKey} primary={rule.partner.name} />
                      <Typography
                        className={
                          distributionRule
                        }>{`${rule.feePercent}% + $${rule.feeAmount}`}</Typography>
                      <IconButton
                        classes={{ root: iconButton }}
                        onClick={() => this.editRule(rule, this.ruleForm)}>
                        <Pencil className={pencilIcon} />
                      </IconButton>
                      <IconButton
                        style={{ marginRight: 0 }}
                        classes={{ root: iconButton }}
                        disabled={isTippy}
                        onClick={() => this.removeRule(index)}>
                        <Close className={closeIcon} />
                      </IconButton>
                    </ListItem>
                  );
                })}
              </List>
            )}
            <>
              <FormControl fullWidth key={'distributionRules'}>
                <FormHelperText error={form.$('distributionRules').hasError}>
                  {form.$('distributionRules').error}
                </FormHelperText>
              </FormControl>
            </>
          </form>
        </DialogContent>
        {!addRule && (
          <DialogActions className={dialogActions} hidden={addRule}>
            <Button
              onClick={form.onSubmit}
              size="large"
              type="submit"
              variant="contained"
              color="primary"
              fullWidth
              disabled={!this.isFormValid}>
              SAVE
            </Button>
            <FormHelperText error={this.feeScheduleExists}>
              {this.feeScheduleExists &&
                'Warning: This fee schedule already exists. Saving current fee schedule will override existing one.'}
            </FormHelperText>
          </DialogActions>
        )}
      </>
    );
  };

  private renderAddRule = () => {
    const { chevronLeft, iconButton, dialogActions } = this.props.classes;
    const addRule = this.addRule;
    const form = this.ruleForm;
    return (
      <>
        <DialogTitle hidden={!addRule}>
          <Box display="flex" alignItems="center" justifyContent="flex-start">
            <IconButton onClick={this.closeAddRule} className={iconButton}>
              <ChevronLeft color="inherit" className={chevronLeft} />
            </IconButton>
            <Typography style={{ fontSize: 28 }} component="h1" display="inline">
              {this.editingRule ? 'Edit Rule' : 'Add a Rule'}
            </Typography>
          </Box>
        </DialogTitle>
        <Divider />
        <DialogContent hidden={!addRule}>
          {this.editingRule ? (
            <OutlinedInput
              value={this.formState.partner && this.formState.partner.name}
              placeholder={form.$('partner').label}
              error={form.$('partner').error}
              required={form.$('partner').rules.includes('required')}
              InputProps={{ readOnly: true }}
            />
          ) : (
            <Autocomplete
              key={this.partnerFormKey}
              options={this.partnerOptions || []}
              onChange={(e: any, newValue) =>
                this.changeAutocompleteField(form, newValue, 'partner')
              }
              onInputChange={this.getNewPartnerOptions}
              getOptionLabel={(options) => options.name}
              renderOption={(options: any) =>
                this.renderOptionWithAdditionalInfo(options.name, options.type)
              }
              inputValue={this.setInputValue('partner')}
              renderInput={(params) => (
                <OutlinedInput
                  {...params}
                  ref={params.InputProps.ref}
                  inputProps={params.inputProps}
                  label={form.$('partner').label}
                  error={form.$('partner').error}
                  required={form.$('partner').rules.includes('required')}
                />
              )}
            />
          )}
          <Box mt={2}>
            <OutlinedInput
              {...form.$('feeAmount').bind()}
              label={form.$('feeAmount').label}
              error={form.$('feeAmount').error}
              required={form.$('feeAmount').rules.includes('required')}
              fullWidth
            />
          </Box>
          <Box mt={2}>
            <OutlinedInput
              {...form.$('feePercent').bind()}
              label={form.$('feePercent').label}
              error={form.$('feePercent').error}
              required={form.$('feePercent').rules.includes('required')}
              fullWidth
            />
          </Box>
        </DialogContent>
        {addRule && (
          <DialogActions className={dialogActions}>
            <Button
              onClick={this.handleRuleAction}
              size="large"
              type="submit"
              variant="contained"
              color="primary"
              fullWidth
              disabled={!this.isRuleValid}>
              {this.editingRule ? 'UPDATE' : 'ADD'}
            </Button>
          </DialogActions>
        )}
      </>
    );
  };

  render() {
    return (
      <Drawer
        title="FeeScheduleDrawer"
        className={this.props.classes.root}
        anchor="right"
        open={this.props.isScheduleDrawerOpen}>
        {this.renderFeeSchedule()}
        {this.renderAddRule()}
        <Alert
          open={this.showAlert}
          acceptButton="Save"
          rejectButton="Cancel"
          buttonsPosition="flex-end"
          onAccept={this.acceptFeeScheduleOverride}
          onReject={this.rejectFeeScheduleOverride}
          title="Are you sure you want to override existing fee schedule?"
          message="This will cause the old fee schedule to not be used anymore."
        />
      </Drawer>
    );
  }
}

export default withStyles(styles)(FeeScheduleDrawer);
