import Api, { getErrorMsg } from 'api';
import OutlinedInput from 'components/Input/OutlinedInput';
import { action, flow, observable, makeObservable } from 'mobx';
import { observer } from 'mobx-react';
import { Inventory } from 'models';
import React from 'react';
import { inject, WithToastStore } from 'stores';

interface InventoryItemChooserProps extends WithToastStore {
  onNewInventoryItem: (e: Inventory) => void;
  label?: string;
}

/**
 * A component for entering a serial and getting an inventory item from
 * the API
 */
@inject('toastStore')
@observer
export default class InventoryItemChooser extends React.Component<InventoryItemChooserProps> {
  constructor(props: InventoryItemChooserProps) {
    super(props);
    makeObservable(this);
  }

  static defaultProps = {
    label: 'Serial number',
  };

  @observable public fieldError = false;
  @observable public textError: any = '';

  /** The ref for the field */
  public inputRef: React.RefObject<HTMLInputElement> = React.createRef();

  /** Whether we are currently fetching an inventory item */
  @observable public fetchingInventoryItem = false;

  /** The value of the serial number field */
  @observable public serialNumber = '';

  /** Updates the serial number field */
  @action.bound public updateSerialNumber(e: React.ChangeEvent<HTMLInputElement>) {
    this.fieldError = false;
    this.serialNumber = e.target.value;
  }

  /** Handler for the serial number enter button submit form thing */
  @action.bound public handleSerialNumberSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    this.fetchInventoryItem();
  }

  /** Fetches the inventory item with the currently input serial */
  @action.bound public fetchInventoryItem = flow(function* (this: InventoryItemChooser) {
    let textError = '';
    try {
      this.fetchingInventoryItem = true;
      // Fetch the inventory item
      const resp = yield Api.fulfillment.getInventoryBySerial(this.serialNumber);
      // Call the callback
      if (resp.data.data) {
        this.props.onNewInventoryItem(resp.data.data);
      }
      // Clear the field and focus back on it
      // window.requestAnimationFrame(() => {
      //   this.inputRef.current && this.inputRef.current.focus();
      // });
    } catch (e: any) {
      textError = getErrorMsg(e);
    } finally {
      this.textError = textError;
      this.fetchingInventoryItem = false;
    }
  });

  render() {
    const { label } = this.props;
    return (
      <OutlinedInput
        value={this.serialNumber}
        disabled={this.fetchingInventoryItem}
        inputRef={this.inputRef}
        onChange={this.updateSerialNumber}
        label={label}
        onBlur={(e) => {
          this.fetchInventoryItem();
        }}
        error={this.textError}
        fullWidth
      />
    );
  }
}
