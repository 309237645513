import React from 'react';
import { Drawer } from '@material-ui/core';
import useStyles, { useMobileMenuDrawerStyles, useToolbarStyles } from './styles';
import clsx from 'clsx';
import ScopeSwitcherButton from '../ScopeSwitcherButton/ScopeSwitcherButton';
import useMenuDrawer from './useMenuDrawer';
import { IconDefinition } from '@fortawesome/pro-regular-svg-icons';
import { Box } from '@mui/material';
import UserSection from './UserSection/UserSection';
import { observer } from 'mobx-react';
import TippyLogo from 'components/TippyLogo/TippyLogo';
import Menu from '../Menu/Menu';
import MenuIcon from '../Menu/MenuIcon/MenuIcon';

const MenuDrawer = () => {
  const { open, toolbarProps, userSectionProps, drawerProps, mobileView } = useMenuDrawer();
  const { drawer, drawerOpen, drawerClose, border, menuWrapper } = useStyles({
    mobileView,
    menuOpen: open,
  });

  const className = clsx(drawer, {
    [drawerOpen]: open,
    [drawerClose]: !open,
  });

  const classes = {
    paper: clsx(border, {
      [drawerOpen]: open,
      [drawerClose]: !open,
    }),
  };

  const MenuItems = (
    <>
      {' '}
      <Toolbar {...toolbarProps} />
      <Box className={menuWrapper}>
        <ScopeSwitcherButton />
        <Menu />
      </Box>
      <UserSection {...userSectionProps} />
    </>
  );

  if (mobileView) {
    return <MobileMenuDrawer open={open}>{MenuItems}</MobileMenuDrawer>;
  }
  return (
    <Drawer className={className} classes={classes} {...drawerProps}>
      {MenuItems}
    </Drawer>
  );
};

interface IToolbarProps {
  menuOpen: boolean;
  hide?: boolean;
  icon: IconDefinition;
  toggleDrawer: () => void;
}
const Toolbar = ({ icon, menuOpen, toggleDrawer, hide, ...rest }: IToolbarProps) => {
  const { toolbar } = useToolbarStyles({ menuOpen });
  if (hide) return null;
  return (
    <Box className={toolbar} {...rest}>
      {menuOpen && <TippyLogo link />}
      <Box onClick={toggleDrawer} style={{ cursor: 'pointer' }}>
        <MenuIcon icon={icon} />
      </Box>
    </Box>
  );
};

interface IMobileMenuDrawer {
  open: boolean;
  children: React.ReactNode;
}
const MobileMenuDrawer = ({ open, children }: IMobileMenuDrawer) => {
  const { drawer, drawerClose, drawerOpen } = useMobileMenuDrawerStyles();
  return <Box className={clsx(drawer, open ? drawerOpen : drawerClose)}>{children}</Box>;
};

export default observer(MenuDrawer);
