import { Box, ButtonBase, Paper, Typography, Chip } from '@material-ui/core';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { DateRange as DateRangeIcon } from '@mui/icons-material';
import moment from 'moment';
import React, { useEffect, memo } from 'react';
import styles from './styles';
import CustomPicker, { DateRange, listOptionDate, isoDateFormat } from './CustomPicker';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar, faCaretDown } from '@fortawesome/pro-regular-svg-icons';
import { observer } from 'mobx-react';
import { rootStore } from 'containers/App/App';
import theme from 'containers/App/theme';
import { EDateFormat } from 'utils/helper';

interface DateRangePickerProps {
  onChange: (range: DateRange) => void;
  predefinedRange?: DateRange;
  isFormField?: boolean;
  isIntegrated?: boolean;
  position?: 'left' | 'right';
  classes: any;
}

const DateRangePicker = ({
  onChange,
  predefinedRange,
  isIntegrated,
  isFormField,
  position = 'left',
  classes,
}: DateRangePickerProps) => {
  const [dropdownOpened, setDropdownOpened] = React.useState(false);
  const [value, setValue] = React.useState<DateRange>({});
  const mobileView = rootStore.uiStore.mobileView;

  const handleSetValue = (value: DateRange) => {
    setValue(value);
  };

  const handleOpened = (status: boolean, e?: any) => {
    setDropdownOpened(status);
  };

  const handleClickOutside = (e: React.ChangeEvent<EventTarget>) => {
    const clickedElement = e.target as HTMLInputElement;
    // Check if clicked element is descendant of date-picker dialog ...
    const datePickerDialog = clickedElement.closest('.MuiDialog-root');
    // ... if it is the user is setting the date in one of the DatePickers
    // and we shouldn't close the DateRangePicker dropdown even though the
    // click was made outside the DateRangePicker component.
    if (!datePickerDialog) handleOpened(false);
  };

  const handleOnChange = (range: DateRange) => {
    handleSetValue(range);

    if (range.type == 'date-timer') {
      onChange &&
        onChange({
          fromDate: moment(range.fromDate).format(isoDateFormat),
          toDate: moment(range.toDate).format(isoDateFormat),
          type: range.type,
        });
    } else {
      onChange && onChange(range);
    }
  };

  const typeOption = React.useMemo(
    () => value && listOptionDate.find((option) => option.type === value.type),
    [listOptionDate, value],
  );

  useEffect(() => {
    if (predefinedRange) {
      handleSetValue({
        fromDate: moment(predefinedRange.fromDate, isoDateFormat),
        toDate: moment(predefinedRange.toDate, isoDateFormat),
        type: predefinedRange.type,
      });
    }

    return () => {
      handleSetValue({});
    };
  }, [predefinedRange]);

  const onClose = () => {
    handleOpened(false);
  };

  const {
    root,
    dateDisplay,
    icon,
    typography,
    calendarIcon,
    dropdownLeft,
    dropdownRight,
    dropdownMobile,
    dropdownBase,
  } = classes;
  const dropdown = {
    left: !mobileView ? dropdownLeft : dropdownMobile,
    right: !mobileView ? dropdownRight : dropdownMobile,
  };

  return (
    <ClickAwayListener onClickAway={(e) => handleClickOutside(e)}>
      <Box className={root} mb={isFormField ? 0 : 2}>
        {isIntegrated ? (
          <Box>
            <Chip
              onClick={() => {
                handleOpened(!dropdownOpened);
              }}
              className={clsx(classes.filterChip, classes.addFilterChip)}
              icon={<DateRangeIcon />}
              label={
                <Typography className={classes.selectLabel}>
                  {typeOption &&
                    (!(typeOption.type === 'date-timer') ? (
                      <>{typeOption.label}</>
                    ) : (
                      <>
                        {/* {moment(value.fromDate).format('LLL')} - {moment(value.toDate).format('LLL')} */}
                        {moment(value.fromDate).format('LL')} - {moment(value.toDate).format('LL')}
                      </>
                    ))}
                </Typography>
              }
            />
          </Box>
        ) : (
          <Box
            component={isFormField ? 'div' : Paper}
            className={
              isFormField
                ? dropdownOpened
                  ? classes.activeMinSize
                  : classes.inactiveMinSize
                : classes.minSize
            }>
            <Box p={isFormField ? 0 : '5px'} style={{ display: 'flex', alignItems: 'center' }}>
              <ButtonBase
                data-cy={'datepicker-dropdownopen-button'}
                className={dateDisplay}
                onClick={() => handleOpened(!dropdownOpened)}>
                <FontAwesomeIcon
                  icon={faCalendar}
                  style={{ color: theme.palette.text.primary }}
                  className={calendarIcon}
                />
                <Typography component="span" className={typography}>
                  {typeOption &&
                    (!(typeOption.type === 'date-timer') ? (
                      <>{typeOption.label}</>
                    ) : (
                      <>
                        {moment(value.fromDate).format(EDateFormat.FULL_MONTH_DAY)} -{' '}
                        {moment(value.toDate).format(EDateFormat.FULL_MONTH_DAY)}
                      </>
                    ))}
                </Typography>
                <FontAwesomeIcon icon={faCaretDown} className={icon} />
              </ButtonBase>
            </Box>
          </Box>
        )}
        {dropdownOpened && (
          <Paper className={clsx(dropdownBase, dropdown[position])}>
            <CustomPicker onChange={handleOnChange} handleOpened={handleOpened} selected={value} />
          </Paper>
        )}
      </Box>
    </ClickAwayListener>
  );
};

export default withStyles(styles)(memo(observer(DateRangePicker)));
