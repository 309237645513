import { inject, observer } from 'mobx-react';
import React from 'react';
import DP from 'components/DashPanel';
import {
  Avatar,
  Backdrop,
  Box,
  Button as ButtonM,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Link,
  Typography,
  WithStyles,
} from '@material-ui/core';
import { action, computed, makeObservable, observable } from 'mobx';
import { partnerDetails } from 'routes/paths';
import { Link as RouterLink } from 'react-router-dom';
import { EnrollState, IEnrolmentWizzard } from './EnrollmentWizzard';
import { WithUserStore } from 'stores/UserStore';
import styles from './styles';
import { withStyles } from '@material-ui/styles';
import Api, { getErrorMsg, RequestMetaData } from 'api';
import { adaptForDataGrid } from 'services/datagrid';
import { numericStringToUsd } from 'services';
import { formatLocalDateTimeInUTC } from 'utils/helper';
import { RebateStatus } from 'models/RebatePartners';
import { BankAccount } from 'models';
import logo from 'images/tippy_logo_pig@3x.png';
import theme from 'containers/App/theme';
import Button from 'components/Button/Dialog/Button';
import OutlinedInput from 'components/Input/OutlinedInput';

interface IEnrolledFields {
  [key: string]: {
    label: string;
    name?: string;
    join?: string[];
    action?: { name: string; action: (partner: any) => any };
  };
}

type EnrollRebateProps = WithStyles<typeof styles> &
  WithUserStore & {
    accountId: number;
    fullWidth?: boolean;
    updateRebateBalance: (showBalance: boolean, sumWalletBalance?: number) => void;
    openEnrolmentWizzard: (props: IEnrolmentWizzard) => void;
  };

@inject('userStore')
@observer
class EnrollRebate extends React.Component<EnrollRebateProps> {
  constructor(props: EnrollRebateProps) {
    super(props);
    makeObservable(this);
  }
  @observable cancelInput?: string = undefined;
  @observable isAdmin: boolean = this.props.userStore!.scope.kind === 'admin';
  @observable rebateParters: any = undefined;
  @observable cancelPartner?: { accountId: number; partnerId: number };
  @observable sumWalletBalance = 0;
  @observable selectedPartner?: {
    accountId: number;
    partnerId: number;
    rebateStatus: RebateStatus;
  };

  @observable enrollPartner: any;

  componentDidMount() {
    this.fetchRebatePartners();
  }

  @action.bound async fetchRebatePartners() {
    const { data } = await this.fetch({});
    if (data && data.data.items.length) {
      let partners = data.data.items;
      partners = await Promise.all(
        partners.map(async (partner: any) => {
          return await this.addPartnerBankAccount(partner);
        }),
      );
      this.rebateParters = partners;
      this.isEnrolled(partners);
    }
  }

  isEnrolled = (partners: any) => {
    const partner = partners.find((partner: any) => {
      return partner.rebateStatus === RebateStatus.ACTIVATED;
    });
    if (partner) return this.props.updateRebateBalance(true, this.sumWalletBalance);
    return this.props.updateRebateBalance(false);
  };

  @action.bound addWalletBalance(walletBalance: string) {
    const balance = parseFloat(walletBalance);
    this.sumWalletBalance = this.sumWalletBalance + balance;
  }

  addPartnerBankAccount = async (partner: any) => {
    let _partner = { ...partner };
    try {
      const wallet = await this.getBankAccount(partner.walletId);
      if (wallet) this.addWalletBalance(wallet.balance);
      if (wallet && wallet?.bankAccountId) {
        const { data } = await Api.core.getBankAccount(wallet.bankAccountId);
        const bankAccount = this.formatBankAccountData(data.data);
        _partner = { ...partner, bankAccount };
      }
    } catch (e: any) {
      getErrorMsg(e);
    }
    return _partner;
  };

  getBankAccount = async (walletId: number) => {
    if (!walletId) return;

    let { data } = await Api.tips.getWallet(walletId);
    if (!(data && data.data)) return;
    const wallet = data.data;
    return wallet;
  };

  formatBankAccountData = (bankAccount: BankAccount) => {
    const { institutionName, accountName, accountMask } = bankAccount;

    const bankAccountData = `
      ${institutionName} - ${accountName} - ${accountMask}
    `;
    return bankAccountData;
  };

  fetch = adaptForDataGrid(
    (rmd: RequestMetaData) => Api.tips.getRebatePartners(this.props.accountId),
    (partner: any) => {
      return {
        ...partner,
        rebateFee: this.getFee(partner.feeAmount, partner.feePercent),
        startsAt: formatLocalDateTimeInUTC(partner.rebateStartDate),
        partner: partner.name,
      };
    },
  );

  getFee = (feeAmount: string, feePercent: number | string) => {
    feeAmount = feeAmount !== null ? feeAmount : '0';
    feePercent = feePercent !== null ? feePercent : '0';
    return `${feePercent}% + ${numericStringToUsd(feeAmount)}`;
  };

  enrolledFields: IEnrolledFields = {
    rebateStatus: {
      label: 'Rebate Status',
      action: {
        name: 'Cancel',
        action: (partner: any) => {
          this.cancelPartner = { accountId: partner.accountId, partnerId: partner.id };
        },
      },
    },
    rebateFee: {
      label: 'Rebate Fee',
    },
    startsAt: {
      label: 'Starts At',
      name: 'rebateStartDate',
    },
    bankAccount: {
      label: 'Bank Account',
      action: {
        name: 'Change',
        action: (partner: any) => {
          this.selectedPartner = {
            accountId: partner.accountId,
            partnerId: partner.id,
            rebateStatus: partner.rebateStatus,
          };
        },
      },
    },
    partner: {
      label: 'Partner',
    },
  };

  openEnrollmentWizzard = (partner: any) => {
    this.selectedPartner = {
      accountId: partner.accountId,
      partnerId: partner.id,
      rebateStatus: partner.rebateStatus,
    };
  };

  @computed get shouldDisableCancel() {
    if (this.cancelInput && this.cancelInput === 'cancel rebate') return false;
    return true;
  }

  changeCancelField = (e: any) => {
    e.preventDefault();
    const { name, value } = e.currentTarget;
    this.cancelInput = value;
  };

  handleCancelRebate = async () => {
    if (!this.cancelPartner) return;
    const accountId = this.cancelPartner.accountId;
    const partnerId = this.cancelPartner.partnerId;
    const { data } = await Api.tips.updateRebatePartner(accountId, partnerId, {
      rebateStatus: RebateStatus.DEACTIVATED,
    });
    this.clearCancelRebateState();
    this.updateRebatePartners(data);
  };

  updateRebatePartners = (data: any) => {
    if (!data || !data.data) return;
    this.rebateParters = this.rebateParters.map((partner: any) => {
      if (partner.id === data.data.id) return { ...partner, ...data.data };
      return partner;
    });
    this.isEnrolled(this.rebateParters);
  };

  closeCancelCard = () => {
    this.clearCancelRebateState();
  };

  clearCancelRebateState = () => {
    this.cancelPartner = undefined;
    this.cancelInput = undefined;
  };

  isRebateActivated = (partner: any) => {
    return partner.rebateStatus === RebateStatus.ACTIVATED;
  };

  enrollForRebate = (partner: any) => {
    const classes = this.props.classes;
    return (
      <>
        <DP.Row>
          <DP.Value>{this.isRebateActivated(partner) ? 'ENROLLED' : 'NOT ENROLLED'}</DP.Value>
          <DP.Label>Rebate Status</DP.Label>
        </DP.Row>
        {this.isAdmin && (
          <DP.Row>
            <DP.Value>
              <Link component={RouterLink} to={partnerDetails(partner.id).general()}>
                {partner.name}
              </Link>
            </DP.Value>
            <DP.Label>Partner</DP.Label>
          </DP.Row>
        )}
        {!this.isAdmin && (
          <DP.Row>
            <DP.Value>
              <Box component="span" style={{ maxWidth: '400px' }}>
                <Typography className={classes.marginRightSmall}>
                  Enroll now to earn 2% back on every tip processed through Tippy!{' '}
                </Typography>
              </Box>
            </DP.Value>
            <DP.Actions>
              <Box mt={3} mb={1} display={'flex'} flexDirection={'row-reverse'}>
                <Button
                  onClick={() => this.openEnrollmentWizzard(partner)}
                  color="primary"
                  variant="contained"
                  size="small">
                  ENROLL
                </Button>
              </Box>
            </DP.Actions>
          </DP.Row>
        )}
      </>
    );
  };

  rebateStatus = (status: RebateStatus) => {
    if (status === RebateStatus.ACTIVATED) return 'ENROLLED';
    return 'NOT ENROLLED';
  };

  bankAccount = (bank: BankAccount) => {
    return (
      <span>
        {bank.institutionName} - {bank.accountName} - {bank.accountMask}
      </span>
    );
  };

  enrolledForRebate = (partner: any) => {
    return Object.keys(this.enrolledFields).map((key: any) => {
      const { label, action } = this.enrolledFields[key as keyof IEnrolledFields];
      let value = partner[key];
      if (!value) value = 'N/A';
      if (label === this.enrolledFields.rebateStatus.label) value = this.rebateStatus(value);
      if (label === this.enrolledFields.partner.label) {
        if (!this.isAdmin) return;
        return (
          <DP.Row key={label}>
            <DP.Value>
              <Link component={RouterLink} to={partnerDetails(partner.id).general()}>
                {value}
              </Link>
            </DP.Value>
            <DP.Label>{label}</DP.Label>
          </DP.Row>
        );
      }
      let showAction = true;
      if (label === this.enrolledFields.bankAccount.label && this.isAdmin) showAction = false;
      return (
        <Box
          mb={3}
          key={label}
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between">
          <Box display="flex" flexDirection="column" justifyContent="space-between">
            <DP.Value>{value}</DP.Value>
            <DP.Label>{label}</DP.Label>
          </Box>
          <Box display="flex">
            {action && showAction && (
              <ButtonM
                className={this.props.classes.button}
                onClick={() => action.action(partner)}
                color="primary"
                variant="text"
                size="small">
                {action.name}
              </ButtonM>
            )}
          </Box>
        </Box>
      );
    });
  };

  cancelDialogCard = () => {
    const open = this.cancelPartner ? true : false;
    const { dialogActions, dialogTitle, dialogContent } = this.props.classes;
    return (
      <Dialog open={open}>
        <DialogTitle className={dialogTitle}>
          <Typography style={{ fontSize: 28, fontWeight: 400 }}>Cancel rebate</Typography>
        </DialogTitle>
        <DialogContent className={dialogContent}>
          <Box mt={3}>
            <Typography variant="body1">
              Are you sure you want to cancel the rebate program? If you cancel, you will not be
              charged for the licence on the next billing cycle.
            </Typography>
          </Box>
          <Box mt={3}>
            <Typography variant="body1">
              Type <b>cancel rebate</b> in the input box to cancel.
            </Typography>
          </Box>
          <Box mt={2}>
            <OutlinedInput
              name="cancelRebate"
              value={this.cancelInput}
              fullWidth
              type="text"
              label="Type text..."
              onChange={(e: any) => this.changeCancelField(e)}
            />
          </Box>
        </DialogContent>
        <DialogActions className={dialogActions}>
          {' '}
          <Button
            onClick={() => (this.cancelPartner = undefined)}
            color="primary"
            variant="outlined"
            size="large">
            Cancel
          </Button>
          <Button
            type="submit"
            onClick={this.handleCancelRebate}
            onSubmit={this.handleCancelRebate}
            color="primary"
            variant="contained"
            size="large"
            disabled={this.shouldDisableCancel}>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  getCards = () => {
    const classes = this.props.classes;
    const title = 'Tippy Rebate Program';
    if (!this.rebateParters) return this.loadingCard(title);
    return this.rebateParters.map((partner: any, index: number) => {
      let body;
      if (partner.rebateStatus === RebateStatus.ACTIVATED) body = this.enrolledForRebate(partner);
      else body = this.enrollForRebate(partner);
      return (
        <Grid key={partner.id} item sm={12} style={{ marginTop: index ? 24 : 0, flexGrow: 1 }}>
          <DP fullWidth={this.props.fullWidth} hidePaper={false}>
            <DP.Header>
              <Box display={'flex'} width={'100%'}>
                <Avatar
                  classes={{
                    colorDefault: classes.primaryBackgroundColor,
                    root: classes.enrollAvatar,
                  }}>
                  <img src={logo} alt="Tippy" className={classes.pig} />
                </Avatar>
                <DP.Title panel>{title}</DP.Title>
              </Box>
            </DP.Header>
            <DP.Body>{body}</DP.Body>
          </DP>
        </Grid>
      );
    });
  };

  loadingCard = (title: string) => {
    const classes = this.props.classes;
    const zIndex = theme.zIndex.drawer;
    return (
      <Grid item sm={12} style={{ position: 'relative', height: 300, flexGrow: 1 }}>
        <DP hidePaper={false} fullWidth={this.props.fullWidth}>
          <DP.Header>
            <Box display={'flex'} width={'100%'}>
              <Avatar
                classes={{
                  colorDefault: classes.primaryBackgroundColor,
                  root: classes.enrollAvatar,
                }}>
                <img src={logo} alt="Tippy" className={classes.pig} />
              </Avatar>
              <DP.Title panel>{title}</DP.Title>
            </Box>
          </DP.Header>
          <DP.Body>
            <Box style={{ height: '100px' }}>
              <Backdrop
                style={{ color: '#fff', zIndex }}
                open={true}
                classes={{ root: classes.enrollBackdrop }}>
                <CircularProgress color="primary" />
              </Backdrop>
            </Box>
          </DP.Body>
        </DP>
      </Grid>
    );
  };

  exitEnrollmentWizzard = () => {
    this.selectedPartner = undefined;
  };

  openEnrolmentWizzard() {
    const rebateStatus = this.selectedPartner!.rebateStatus;
    const initState =
      rebateStatus !== RebateStatus.ACTIVATED ? undefined : EnrollState.BANK_ACCOUNT;
    this.props.openEnrolmentWizzard({
      accountId: this.selectedPartner!.accountId,
      partnerId: this.selectedPartner!.partnerId,
      initState,
      exitEnrollmentWizzard: this.exitEnrollmentWizzard,
    });
  }

  render() {
    const Cards = this.getCards();
    this.selectedPartner && this.openEnrolmentWizzard();

    return (
      <>
        <Grid container spacing={0}>
          {Cards}
        </Grid>
        {this.cancelDialogCard()}
      </>
    );
  }
}

export default withStyles(styles)(EnrollRebate);
