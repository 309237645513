import { CartItem } from 'models/Cart';

export function getFullAddress(location?: CartItem['location']) {
  if (!location) return;
  let fullAddress = 'Address Not Available';
  const address = `${location.address ? `${location.address}, ` : ''}`;
  const city = `${location.city ? `${location.city}, ` : ''}`;
  const stateAndZip = `${location.state ? location.state : ''} ${location.zip ? location.zip : ''}${
    location.state || location.zip ? ', ' : ''
  }`;
  const address2 = location.address2 ? `${location.address2}, ` : '';
  const country = `${location.country ? location.country : ''}`;
  fullAddress = address + city + address2 + stateAndZip + country;
  fullAddress = fullAddress.trim();
  fullAddress = fullAddress.endsWith(',') ? fullAddress.slice(0, -1) : fullAddress;
  return fullAddress;
}

export function getFullShippingAddress(cartItem: CartItem) {
  if (!cartItem.shippingAddress) return;
  const { shippingAddress } = cartItem;
  let fullAddress = 'Address Not Available';
  const address = `${shippingAddress.address ? `${shippingAddress.address}, ` : ''}`;
  const city = `${shippingAddress.city ? `${shippingAddress.city}, ` : ''}`;
  const stateAndZip = `${shippingAddress.state ? shippingAddress.state : ''} ${
    shippingAddress.zip ? shippingAddress.zip : ''
  }${shippingAddress.state || shippingAddress.zip ? ', ' : ''}`;
  const address2 = shippingAddress.address2 ? `${shippingAddress.address2}, ` : '';
  const country = `${shippingAddress.country ? shippingAddress.country : ''}`;
  fullAddress = address + city + address2 + stateAndZip + country;
  fullAddress = fullAddress.trim();
  fullAddress = fullAddress.endsWith(',') ? fullAddress.slice(0, -1) : fullAddress;
  return fullAddress;
}
