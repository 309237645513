import Dialog, { IDialogProps } from 'components/Dialog/Dialog';
import useEditTitleDialog from './useEditTitleDialog';
import OutlinedInput from 'components/Input/OutlinedInput';
import { observer } from 'mobx-react';
import SupportText from 'components/SupportText/SupportText';

export interface IEditTitleDialogProps extends Pick<IDialogProps, 'open'> {
  title?: string;
  loading: boolean;
  onClose: () => void;
  submit?: (title: string) => Promise<any>;
  onSuccess?: (response: unknown) => void;
}

const EditTitleDialog = ({
  open,
  title = '',
  loading,
  onClose,
  onSuccess,
  submit,
}: IEditTitleDialogProps) => {
  const { form, disabled, isLoading, handleSubmit, handleCancel } = useEditTitleDialog({
    open,
    title,
    submit,
    onSuccess,
    onClose,
    loading,
  });

  return (
    <Dialog
      open={open}
      title={'Edit title'}
      content={
        <>
          <SupportText mb={2} label={form?.supportError} />
          <OutlinedInput
            {...form.$('title').bind()}
            value={form?.$('title').value}
            error={form.$('title').error}
            dataCy="new-title-input"
            fullWidth
          />
        </>
      }
      onCancel={handleCancel}
      onConfirm={handleSubmit}
      disabled={disabled()}
      loading={isLoading}
    />
  );
};

export default observer(EditTitleDialog);
