import React from 'react';
import { RouteComponentProps, Link as RouterLink, Route } from 'react-router-dom';
import { observable, makeObservable, computed } from 'mobx';
import { observer } from 'mobx-react';
import { WithStyles, withStyles } from '@material-ui/core/styles';

import { inject, WithUserStore } from 'stores';
import { User } from 'models';
import { paths } from 'routes';

import DashboardLayout from 'containers/DashboardLayout';
import Title from 'components/Title';

import styles from './styles';

import Profile from './Profile';
import Workspace from './Workspace';
import Banks from './Deposits';
import TabBar from 'components/TabBar/TabBar';

/** Here we define what kind of props this component takes */
type MyAccountProps = WithStyles<typeof styles> & // Adds the classes prop
  RouteComponentProps & // Adds the router props (history, match, location)
  WithUserStore; // Adds the userStore prop

/**
 * MyAccount top level container. Sub routes are 'PROFILE', 'WORKSPACE', 'BANKS'.
 */
@inject('userStore')
@observer
class MyAccount extends React.Component<MyAccountProps> {
  constructor(props: MyAccountProps) {
    super(props);
    makeObservable(this);
  }
  @observable public user?: User = this.props.userStore!.user;

  /**
   * There is no need to display WORKSPACE tab if user
   * has no locationUsers associated with her current scope
   */
  @observable hasLocationUser = this.props.userStore!.locationUsers.length;

  @computed public get tabs() {
    const myAccountPath = paths.myAccount();
    const { pathname } = this.props.location;
    return [
      {
        label: 'Profile',
        component: RouterLink,
        to: myAccountPath.profile(),
        selected: pathname === myAccountPath.profile(),
      },
      {
        label: 'Workspace',
        component: RouterLink,
        to: myAccountPath.workspace(),
        selected: pathname === myAccountPath.workspace(),
        hide: !this.hasLocationUser,
      },
      {
        label: 'Deposits',
        component: RouterLink,
        to: myAccountPath.deposits(),
        selected: pathname === myAccountPath.deposits(),
      },
    ];
  }

  render() {
    const myAccountPath = paths.myAccount();
    const { pathname } = this.props.location;
    return (
      <DashboardLayout>
        <Title>My Account</Title>
        <TabBar mb={3} tabs={this.tabs} />
        <Route path={myAccountPath.profile()} exact render={() => <Profile />} />
        <Route path={myAccountPath.workspace()} exact render={() => <Workspace />} />
        <Route path={myAccountPath.deposits()} exact component={Banks} />
      </DashboardLayout>
    );
  }
}

export default withStyles(styles)(MyAccount);
