import { makeStyles, createStyles, Theme } from '@material-ui/core';
import theme from 'containers/App/theme';

interface IStyleProps {
  trigger: boolean;
  mobileView?: boolean;
}

const fontSize = ({ mobileView }: IStyleProps) => {
  if (mobileView) return theme.typography.subtitle1.fontSize;
  return theme.typography.body1.fontSize;
};
export const useStyles = makeStyles<Theme, IStyleProps>((theme) =>
  createStyles({
    typography: {
      fontSize,
      paddingLeft: ({ mobileView }) => (!mobileView ? theme.spacing(2) : 0),
      paddingRight: ({ mobileView }) => (!mobileView ? theme.spacing(2) : 0),
      fontColor: theme.palette.text.primary,
    },
    text: {
      display: 'inline-block',
      color: theme.palette.text.primary,
      marginRight: theme.spacing(2),
    },
    code: {
      display: 'inline-block',
      color: theme.palette.primary.main,
      fontWeight: 700,
    },
  }),
);
