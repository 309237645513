/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { observable, action, flow, computed, makeObservable } from 'mobx';
import { observer } from 'mobx-react';

import { Box, Typography, FormHelperText } from '@material-ui/core';
import { WithStyles, withStyles } from '@material-ui/core/styles';

import PasswordField from 'components/form/PasswordField';
import { TalentIntegrationApp } from 'models/Integration';
import validatorjs from 'validatorjs';
import Api, { getErrorMsg } from 'api';
import { WithToastStore, WithUserStore, inject } from 'stores';
import { paths } from 'routes';

import styles from './styles';
import { WithRouterStore } from '../../stores/RouterStore';
import config from '../../config';
import qs from 'qs';
import CarouselScreenWrapper from 'components/CarouselScreenWrapper/CarouselScreenWrapper';
import OutlinedInput from 'components/Input/OutlinedInput';
import SignupLayout, { ISignupLayoutProps } from 'containers/SignupLayout/SignupLayout';
import CircularIconFrame from 'components/CircularIconFrame/CircularIconFrame';
import { faCheck, faFaceThinking, faSquareArrowUpRight } from '@fortawesome/pro-regular-svg-icons';
import theme from 'containers/App/theme';

type MobxForm = any;

interface FormHooks {
  onSuccess: (form: MobxForm) => void;
  onClear: (form: MobxForm) => void;
}

type ExternalAppLoginProps = WithStyles<typeof styles> &
  RouteComponentProps &
  WithToastStore &
  WithUserStore &
  WithRouterStore;

// eslint-disable-next-line @typescript-eslint/no-var-requires
const dvr = require('mobx-react-form/lib/validators/DVR');
const MobxReactForm = require('mobx-react-form').default;
const plugins = {
  dvr: dvr({
    package: validatorjs,
  }),
};

@inject('toastStore', 'userStore', 'routerStore')
@observer
class ExternalAppLogin extends Component<ExternalAppLoginProps> {
  constructor(props: ExternalAppLoginProps) {
    super(props);
    makeObservable(this);
    // Get non-integrated apps for this talent from userStore state or from local storage
    this.apps = this.props.userStore!.integrationApps;
    // Initialize the form
    this.form = new MobxReactForm({ fields: this.fields }, { plugins, hooks: this.hooks });
  }

  public fields = [
    {
      name: 'username',
      label: 'Username',
      rules: 'required|string',
    },
    {
      name: 'password',
      label: 'Password',
      type: 'password',
      rules: 'required|string',
    },
  ];

  /** The mobx-react-form instance */
  @observable private form: MobxForm;

  /** The hooks for the form */
  private hooks: FormHooks = {
    onSuccess: () => {
      this.submitLogin();
    },
    onClear: (form: MobxForm) => {
      form.clear();
    },
  };

  /** List of available apps*/
  @observable public apps: TalentIntegrationApp[] = [];

  /** Submitting state */
  @observable public submitting = false;

  @observable public loading = true;

  @observable public showResponse = false;
  @observable public error?: string;

  @observable private currentAppIndex = 0;

  @observable redirectingToWorkspace = false;

  @observable private showInvalidCredentialsMessage = false;

  @computed get qsObj(): any {
    if (this.props.routerStore && this.props.routerStore.location) {
      return qs.parse(this.props.routerStore!.location.search, { ignoreQueryPrefix: true });
    }

    return undefined;
  }

  @computed get redirectWorkspace(): string | undefined {
    return this.qsObj && this.qsObj.workspace ? this.qsObj.workspace : undefined;
  }

  @computed private get currentApp(): TalentIntegrationApp | undefined {
    if (this.apps) {
      return this.apps[this.currentAppIndex];
    }

    return undefined;
  }

  @action.bound public next() {
    this.form.clear();
    if (this.apps.length <= this.currentAppIndex + 1) {
      // next screen
      this.props.history.push(paths.signUp().profilePicture());
    } else {
      // next app
      this.currentAppIndex = this.currentAppIndex + 1;
      this.showResponse = false;
    }
  }

  @action.bound public onClickExternalLoginRedirect() {
    if (this.currentApp?.loginRedirectUrl) {
      let callbackUrl =
        config.dashboard.baseUrl +
        `/callbacks` +
        `/${this.currentApp!.processor}` +
        `/${this.currentApp!.account.id}`;

      if (this.redirectWorkspace) {
        callbackUrl = callbackUrl + `?workspace=true`;
      }

      const redirectUrl = this.currentApp!.loginRedirectUrl! + '?callbackUrl=' + callbackUrl;

      window.open(redirectUrl, '_self');
    }
  }

  /** Returns user back to integration form and clears is */
  @action.bound public tryAgain() {
    this.form.clear();
    this.showResponse = false;
  }

  @action.bound public submitLogin = flow(function* (this: ExternalAppLogin) {
    const app = this.apps[this.currentAppIndex];
    const username = this.form.$('username').value;
    const password = this.form.$('password').value;
    const accountId = app.account.id;
    const processor = app.processor;
    const appId = app.id;

    try {
      this.loading = true;
      this.showInvalidCredentialsMessage = false;
      yield Api.developer.externalAppLogin({
        username,
        password,
        accountId,
        appId,
        processor,
      });
    } catch (e: any) {
      this.error = getErrorMsg(e);
    } finally {
      this.loading = false;
      if (this.redirectWorkspace && this.error) {
        this.showInvalidCredentialsMessage = true;
      } else {
        this.showResponse = true;
      }
    }
  });

  async handleRedirectToWorkspace() {
    await this.integrationSuccessful();
    await this.redirectToWorkspace();
  }

  @action.bound async integrationSuccessful() {
    return new Promise((resolve) =>
      setTimeout(() => {
        this.redirectingToWorkspace = true;
        resolve(true);
      }, 2000),
    );
  }

  async redirectToWorkspace() {
    return new Promise((resolve) =>
      setTimeout(() => {
        this.props.history.push(paths.myAccount().workspace());
        resolve(true);
      }, 1000),
    );
  }

  componentDidMount() {
    this.loading = false;
  }

  renderSuccess() {
    if (this.redirectWorkspace) {
      this.handleRedirectToWorkspace();
    }

    return (
      <SignupLayout
        title={'Success'}
        action={{
          children: 'Continue',
          onClick: this.next,
          loading: this.redirectingToWorkspace,
          hide: !!this.redirectWorkspace,
        }}>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          gridGap={64}>
          <CircularIconFrame icon={faCheck} iconHeight={88} />
          {this.redirectingToWorkspace ? (
            <Typography variant="body2">Redirecting to workspace</Typography>
          ) : (
            <Typography variant="body2">Login successful</Typography>
          )}
        </Box>
      </SignupLayout>
    );
  }

  renderError() {
    const signupLayoutProps: Omit<ISignupLayoutProps, 'children'> = {
      title: 'Sorry!',
      action: {
        children: 'Try again',
        onClick: this.tryAgain,
      },
      subAction: {
        skip: {
          onClick: this.next,
        },
      },
    };
    return (
      <SignupLayout {...signupLayoutProps}>
        <Box display={'flex'} flexDirection={'column'} alignContent={'center'}>
          <CircularIconFrame icon={faFaceThinking} color="red" />
          <Typography style={{ marginTop: theme.spacing(8) }} variant="body2" align="center">
            {this.error || 'Login failed'}
          </Typography>
        </Box>
      </SignupLayout>
    );
  }

  renderLoginForm(currentAppIndex: number) {
    const usernameField = this.form.$('username');
    const passwordField = this.form.$('password');

    if (!this.currentApp) return;

    const formId = 'login-form-id';
    const signupLayoutProps: Omit<ISignupLayoutProps, 'children'> = {
      title: 'Login with POS',
      action: {
        form: formId,
        type: 'submit',
        children: 'Login',
      },
      subAction: {
        back: {
          onClick: this.props.history.goBack,
        },
      },
    };
    return (
      <SignupLayout {...signupLayoutProps}>
        <>
          <Typography
            style={{ marginBottom: theme.spacing(4) }}
            variant="body2"
            align="center"
            gutterBottom>
            Enter your Point of Sale login credentials below to get paid.
          </Typography>

          <form id={formId} onSubmit={this.form.onSubmit}>
            <OutlinedInput
              {...usernameField.bind()}
              error={usernameField.error}
              fullWidth
              autoFocus
            />
            <Box mt={2}>
              <PasswordField
                id={passwordField.id}
                mobxField={passwordField}
                {...passwordField.bind()}
                error={passwordField.error}
                helperText={passwordField.error}
              />
            </Box>
            {this.redirectWorkspace && this.error && (
              <FormHelperText error={true}>{this.error}</FormHelperText>
            )}
          </form>
        </>
      </SignupLayout>
    );
  }

  renderLoginButton() {
    const logo = this.currentApp?.logo;
    const icon = logo ? { src: logo } : undefined;

    const signupLayoutProps: Omit<ISignupLayoutProps, 'children'> = {
      title: 'Login with POS',
      icon,
      action: {
        type: 'submit',
        children: 'Login with pos',
        onClick: this.onClickExternalLoginRedirect,
      },
      subAction: {
        back: {
          onClick: this.props.history.goBack,
        },
      },
    };
    return (
      <SignupLayout {...signupLayoutProps}>
        <Box display="flex" flexDirection="column" alignItems={'center'} gridGap={theme.spacing(4)}>
          <CircularIconFrame icon={faSquareArrowUpRight} />
          <Typography variant="body2" align="center" gutterBottom>
            Enter your Point of Sale login credentials below to get paid.
          </Typography>
        </Box>
      </SignupLayout>
    );
  }

  renderForm() {
    if (this.currentApp && this.currentApp.externalLoginMode === 'redirect') {
      return this.renderLoginButton();
    }

    return this.renderLoginForm(this.currentAppIndex);
  }

  render() {
    const icon = this.currentApp?.logo ? { src: this.currentApp?.logo, mt: 21.125 } : undefined;

    return (
      <CarouselScreenWrapper submitting={this.loading} anonLayoutIcon={icon}>
        {this.showResponse
          ? this.error
            ? this.renderError()
            : this.renderSuccess()
          : this.renderForm()}
      </CarouselScreenWrapper>
    );
  }
}

export default withRouter(withStyles(styles)(ExternalAppLogin));
