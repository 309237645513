import { Tip } from './Tip';

export interface ICreateRefundRequest {
  accountId: number;
  tipId: number;
  reason: string;
  amount?: number;
}

export type CreateRefundRequestData = ICreateRefundRequest & {
  total?: string;
  name?: string;
};

export enum RefundRequestStatus {
  CREATED = 'created',
  APPROVED = 'approved',
  REJECTED = 'rejected',
  CANCELED = 'canceled',
}

export interface IRefundRequest {
  id: number;
  accountId: number;
  ownerId: number;
  tipId: number;
  tip: Tip;
  amount?: string;
  reason: string;
  status: RefundRequestStatus;
  rejectionReason?: string;
  createdAt: string;
  resolvedAt?: string;
  resolvedBy?: number;
}
