import { Box, IconButton } from '@material-ui/core';
import { ReferralPanel } from '../ReferralPanel/ReferralPanel';
import Button from 'components/Button/Button';
import OutlinedInput from 'components/Input/OutlinedInput';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShareNodes } from '@fortawesome/pro-regular-svg-icons';
import { faCopy } from '@fortawesome/pro-solid-svg-icons';
import { copyToClipboard } from 'services';
import { useCallback } from 'react';
import { useStores } from 'containers/App/App';
import { ShareReferralItems } from '../ShareReferralItems/ShareReferralItems';
import { referralFormStyles } from '../styles';

interface ShareReferralProps {
  url: string;
}

export const ShareReferral = ({ url }: ShareReferralProps) => {
  const { modalStore, toastStore } = useStores();

  const copyHandler = useCallback(() => {
    copyToClipboard(url);
    toastStore.success('Referral link copied to clipboard.');
  }, [url, toastStore]);

  return (
    <ReferralPanel
      title="Share your referral link"
      text="You can also share your referral link by copying and sending it or sharing it on your social media."
      lg={6}>
      <Box sx={referralFormStyles}>
        <OutlinedInput
          value={url}
          fullWidth={true}
          InputProps={{
            startAdornment: (
              <IconButton size="small" onClick={copyHandler}>
                <FontAwesomeIcon icon={faCopy} fontSize={16} />
              </IconButton>
            ),
          }}
        />
        <Button
          startIcon={<FontAwesomeIcon icon={faShareNodes} />}
          className="referral-form-action"
          variant="contained"
          onClick={() =>
            modalStore.confirm('Share On Social Media', <ShareReferralItems url={url} />, {
              cancelLabel: 'Cancel',
              hideConfirm: true,
            })
          }>
          Share
        </Button>
      </Box>
    </ReferralPanel>
  );
};
