import { IBillingEntity, Location } from 'models';
import Api from 'api';
import { useCallback, useEffect, useMemo } from 'react';
import { useForm, FieldValues } from 'react-hook-form';
import {
  IFormFieldsConfig,
  EFormFields,
  EFormFieldLabels,
  FormContainer,
  FORM_TAGS_AUTOCOMPLETE,
} from 'utils/forms';
import { getPaymentMethodField } from '../../helpers';

interface LocationSelectionProps {
  accountId: number;
  locationsSelected: Location[];
  billingEntityId?: number;
  paymentMethods: IBillingEntity[];
  getFormValues: (billingEntityId: number, locations: Location[]) => void;
  validityChanged: (isValid: boolean) => void;
}

export const LocationSelection = ({
  accountId,
  locationsSelected,
  paymentMethods,
  billingEntityId,
  getFormValues,
  validityChanged,
}: LocationSelectionProps) => {
  const getLocationsFiltered = useCallback(async () => {
    const { data } = await Api.core.searchAllLocations({}, { accountIds: [accountId] });
    return data.data.map((location: Location) => {
      return { id: location.id, name: location.name };
    });
  }, [accountId]);

  /** Construct Select box with payment methods as options */
  const paymentMethodField = useMemo(() => getPaymentMethodField(paymentMethods), [paymentMethods]);

  const locationField: IFormFieldsConfig = useMemo(
    (): IFormFieldsConfig => ({
      ...FORM_TAGS_AUTOCOMPLETE({
        name: EFormFields.LOCATION,
        label: EFormFieldLabels.SEARCH_LOCATION,
        fetch: getLocationsFiltered,
        getLabel: (location: Location): string => location?.name || '',
        tagsSelected: locationsSelected,
      }),
    }),
    [getLocationsFiltered, locationsSelected],
  );

  /** Init form with default options */
  const {
    formState: { errors, isValid, isValidating },
    control,
    getValues,
  } = useForm<FieldValues>({
    defaultValues: {
      [paymentMethodField.name]: billingEntityId || '',
      [locationField.name]: locationsSelected,
    },
    mode: 'onChange',
  });

  useEffect(() => {
    validityChanged(isValid);
  }, [isValid, validityChanged]);

  useEffect(() => {
    getFormValues(getValues(EFormFields.BILLING_ENTITY_ID), getValues(EFormFields.LOCATION));
  }, [isValidating]);

  return (
    <FormContainer
      formFieldsConfig={[paymentMethodField, locationField]}
      control={control}
      errors={errors}
      marginBottom={0}
    />
  );
};
