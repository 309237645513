import React from 'react';
import clsx from 'clsx';
import { Hardware, Inventory } from 'models';
import { useStyles } from '../styles';
import {
  Box,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  IconButton,
  ListItemText,
  SvgIcon,
} from '@material-ui/core';
import { FileDrop } from 'react-file-drop';
import { useAddItems } from './useAddItems';
import { ToastStore } from 'stores';
import { Apple, Close, FileDocumentBoxMultipleOutline } from 'mdi-material-ui';
import { observer } from 'mobx-react';
import { ReactComponent as SquareIcon } from 'images/square.svg';
import Button from 'components/Button/Dialog/Button';

export interface IAddItems {
  fields: Array<{ name: string; label: string; rules?: string }>;
  hardware: Hardware | null;
  toastStore: ToastStore;
  setHardwareError: () => void;
  submit: (items: Inventory[]) => void;
}
const AddItems = ({ setHardwareError, fields, hardware, toastStore, submit }: IAddItems) => {
  const classes = useStyles();
  const {
    dragging,
    inventoryItems,
    dragFrameRef,
    setDragging,
    handleCsv,
    deleteItem,
    submitItems,
  } = useAddItems({ fields, hardware, setHardwareError, toastStore, submit });

  return (
    <Box>
      <FileDrop
        className={classes.fileDrop}
        onFrameDragEnter={() => setDragging(true)}
        onFrameDragLeave={() => setDragging(false)}
        onDrop={handleCsv}>
        <div className={clsx(classes.itemsBox, dragging && classes.dragged)} ref={dragFrameRef}>
          {inventoryItems.length === 0 ? (
            <Box
              height="100%"
              display="flex"
              alignItems="center"
              justifyContent="center"
              flexDirection="column">
              <FileDocumentBoxMultipleOutline color="disabled" />
              <Box width="50%" mt={3}>
                <Typography color="textSecondary" align="center">
                  You can drag CSV files with data here. Files should contain these fields: serial,
                  purchasedOn, owner, vendor, color, cost. Note: field names are case sensitive.
                </Typography>
              </Box>
            </Box>
          ) : (
            <List>
              {Array.from(inventoryItems.values()).map((i) => (
                <InventoryItem key={i.serial} onDelete={() => deleteItem(i)}>
                  {i}
                </InventoryItem>
              ))}
            </List>
          )}
        </div>
      </FileDrop>
      <Box mb={3} mt={5}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => submitItems(inventoryItems)}
          disabled={inventoryItems.length ? false : true}>
          {inventoryItems.length <= 1 ? 'Add' : `Add ${inventoryItems.length} items`}
        </Button>
      </Box>
    </Box>
  );
};

type InventoryWithSerial = Partial<Inventory> & Pick<Inventory, 'serial'>;

interface InventoryItemProps {
  children: Inventory;
  onDelete?: (i: InventoryWithSerial) => void;
}

/** Displays a single inventory item in a list of inventory items */
const InventoryItem: React.FC<InventoryItemProps> = observer(({ children, onDelete }) => {
  const manufacturer = children.hardware && children.hardware.manufacturer;
  let icon: React.ReactElement | undefined = undefined;
  if (manufacturer && manufacturer.toLowerCase() === 'apple') {
    icon = <Apple />;
  }
  if (manufacturer && manufacturer.toLowerCase() === 'square') {
    icon = (
      <SvgIcon>
        <SquareIcon />
      </SvgIcon>
    );
  }
  const modelString =
    children &&
    children.hardware &&
    children.hardware.manufacturer &&
    children.hardware.model &&
    `${children.hardware.manufacturer} ${children.hardware.model}`;

  const { hardware, ...rest }: Inventory = children;
  const primary = Object.keys(rest).map((key: any) => {
    return `${key}: ${children[key as keyof Inventory]}`;
  });

  return (
    <ListItem disableGutters dense divider>
      {icon && <ListItemIcon>{icon}</ListItemIcon>}
      <ListItemText primary={primary.join(', ')} secondary={modelString} />
      {onDelete && (
        <ListItemSecondaryAction>
          <IconButton
            edge="end"
            aria-label="delete"
            size="small"
            onClick={() => {
              onDelete && onDelete(children);
            }}>
            <Close fontSize="small" />
          </IconButton>
        </ListItemSecondaryAction>
      )}
    </ListItem>
  );
});

export default AddItems;
