import React from 'react';
import { action, computed, observable, makeObservable, flow } from 'mobx';
import { observer } from 'mobx-react';
import { RouteComponentProps } from 'react-router-dom';
import { WithStyles, withStyles } from '@material-ui/core/styles';
import { Box, FormControlLabel } from '@material-ui/core';

import { inject, WithToastStore, WithUiStore, WithUserStore } from 'stores';
import { paths } from 'routes';

import AffiliateSearch from 'components/AffiliateSearch';

import SignupStore, { SignupStep } from './SignupStore';
import CouponsList from './CouponsList';
import CartPreview from 'components/CartPreview';

import styles from './styles';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import * as date from '@material-ui/pickers/typings/date';
import MomentUtils from '@date-io/moment';
import moment from 'moment';
import Api, { getErrorMsg } from 'api';
import theme from 'containers/App/theme';
import { Checkbox } from 'components/Checkbox/Checkbox';
import Button from 'components/Button/Button';
import AccountSignupLayout from './AccountSignupLayout/AccountSignupLayout';
import { AxiosResponse } from 'axios';
import { Affiliate, Lead } from 'models';

/** Here we define what kind of props this component takes */
interface CouponsStepProps
  extends WithStyles<typeof styles>, // Adds the classes prop
    WithUserStore, // Adds the userStore prop
    RouteComponentProps<{ accountId: string }>,
    WithToastStore,
    WithUiStore {
  signupStore: SignupStore;
  hideAffilliateSelection?: boolean;
  nextRoute: (accountId: string | number) => string;
}

/**
 * The coupons step of the account signup
 */
@inject('userStore', 'toastStore', 'uiStore')
@observer
class CouponsStep extends React.Component<CouponsStepProps> {
  constructor(props: CouponsStepProps) {
    super(props);
    makeObservable(this);
  }

  @observable public submitting = false;
  @observable public affiliateSearchDisabled = false;
  @action.bound public handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    this.props.signupStore.createConversions();
    this.props.history.push(this.props.nextRoute(this.accountId));
  }
  @computed public get accountId(): number {
    return parseInt(this.props.match.params.accountId);
  }

  @observable public limitedOffer = false;
  @observable public date?: string | null;

  @action.bound private onLimitedOfferChange(checked: boolean) {
    this.limitedOffer = checked;
    if (!checked) {
      this.date = null;
      this.updateExpirationDate();
    }
  }

  @action.bound public checkIfAffiliate = flow(function* (this: CouponsStep) {
    const ownerEmail = this.props.signupStore.owner?.email;
    if (!ownerEmail) {
      return;
    }

    try {
      const resp: AxiosResponse = yield Api.marketing.checkIfAffiliate(ownerEmail);
      const hasAffiliate: Affiliate | undefined = resp.data.data;

      if (hasAffiliate) {
        this.props.signupStore.setAccountAffiliate(hasAffiliate);
        this.affiliateSearchDisabled = true;
      }
    } catch (e: unknown) {
      this.props.toastStore!.error(getErrorMsg(e));
    }
  });

  private async initAccount() {
    await this.props.signupStore.initAccount(this.accountId);

    const lead: Lead | undefined = this.props.signupStore.lead;
    if (lead) {
      this.props.signupStore.setAccountAffiliate(lead.affiliate || null);
    } else {
      this.checkIfAffiliate();
    }

    this.date = this.props.signupStore!.cart?.expiresAt;
    this.limitedOffer = this.date !== undefined && this.date !== null;
  }

  @action.bound private updateExpirationDate(date?: string) {
    Api.billing.updateCart(this.accountId, { expiresAt: date });
  }

  @action.bound private handleExpirationDatePick(muiDate: date.MaterialUiPickersDate) {
    this.date = moment(muiDate!).endOf('day').toISOString();
    this.updateExpirationDate(this.date);
  }

  componentDidMount() {
    this.props.signupStore.setStep(SignupStep.Coupons);
    this.initAccount();
  }

  render() {
    const { classes, signupStore, hideAffilliateSelection } = this.props;
    if (!signupStore.accountReady) {
      return null;
    }
    return (
      <AccountSignupLayout
        title={{
          name: 'Coupons & Discounts',
          backPath: paths.adminAccountSignup().locations(this.accountId),
        }}
        subtitle={{
          name: 'Add any applicable coupons and discounts',
        }}
        contentLeft={
          <CartPreview cart={signupStore.cart!} cartInProgress={signupStore.cartInProgress} />
        }
        contentRight={{
          children: (
            <form
              id="coupons-step-form"
              onSubmit={this.handleSubmit}
              className={classes.accountStepForm}>
              {!hideAffilliateSelection && (
                <Box pb={2}>
                  <AffiliateSearch
                    onChange={signupStore.setAccountAffiliate}
                    label="Enter name or code"
                    value={signupStore.accountAffiliate}
                    InputProps={{ disableUnderline: true }}
                    helperText={'Is there an affiliate associated with this sale?'}
                    disabled={this.affiliateSearchDisabled}
                  />
                </Box>
              )}
              <Box mt={2} mb={2}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={this.limitedOffer}
                      onChange={(e, checked) => this.onLimitedOfferChange(checked)}
                      name="requiresCustomerName"
                    />
                  }
                  label="Limited offer"
                />
              </Box>
              {this.limitedOffer && (
                <Box display="flex" flexDirection="row" mt={2} mb={0} justifyContent="center">
                  <MuiPickersUtilsProvider utils={MomentUtils}>
                    <DatePicker
                      className={classes.datePicker}
                      autoOk
                      value={this.date}
                      onChange={this.handleExpirationDatePick}
                      format={'MMM D YYYY'}
                      disablePast
                      fullWidth
                      placeholder="Offer expires on"
                      InputProps={{ disableUnderline: true }}
                    />
                  </MuiPickersUtilsProvider>
                </Box>
              )}
              <CouponsList signupStore={signupStore} />
            </form>
          ),
        }}
        actionsRight={
          <Button
            data-cy="submit-button"
            className={classes.nextStepButton}
            style={{ marginTop: theme.spacing(0) }}
            fullWidth
            variant="contained"
            color="primary"
            form="coupons-step-form"
            type="submit"
            disabled={this.submitting}>
            Next
          </Button>
        }
      />
    );
  }
}

export default withStyles(styles)(CouponsStep);
