import { ReactNode, useCallback, useMemo } from 'react';
import DP from 'components/DashPanel';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen, faRotateLeft } from '@fortawesome/pro-regular-svg-icons';
import { observer } from 'mobx-react';
import usePhoneNumberPanel from './usePhoneNumberPanel';
import useStyles from './styles';
import OutlinedInput from 'components/Input/OutlinedInput/OutlinedInput';
import Dialog from 'components/Dialog/Dialog';
import { formatPhoneNumber, valueOrNA } from 'utils/helper';
import { User } from 'models';
import { Box, Tooltip } from '@material-ui/core';
import { useStores } from 'containers/App/App';
import Api from 'api';
import { ACL } from 'types';

interface PhoneNumberPanelProps {
  // We can pass user to show their phone, else we will show admin's phone
  user?: User;
  customUser?: boolean;
  fullWidth?: boolean;
  handleUserChanged?: (user: User) => void;
  userStore?: boolean;
}

const PhoneNumberPanel = observer(
  ({ user, customUser, fullWidth = false, handleUserChanged }: PhoneNumberPanelProps) => {
    const {
      phoneField,
      submit,
      onExited,
      loading,
      error,
      phone,
      editPhone,
      openPhoneDialog,
      closePhoneDialog,
      phoneIndicatorProps,
    } = usePhoneNumberPanel();
    const { actionIcon, boxMessage } = useStyles();
    const { modalStore, toastStore, userStore } = useStores();
    const hasResetPermission = userStore?.hasPermission(ACL.RESET_USER_PHONE);

    const renderPhoneNumber = useMemo((): ReactNode => {
      const label = <DP.Label>Phone</DP.Label>;

      // User is not passed - show Admin
      if (!customUser) {
        return (
          <DP.Row>
            <DP.Value>
              {valueOrNA(formatPhoneNumber(phone))} <DP.Indicator {...phoneIndicatorProps} />
            </DP.Value>
            {label}
          </DP.Row>
        );
      }

      // User is passed but not loaded yet
      if (!user) {
        return (
          <>
            <DP.Loading items={1} />
            {label}
          </>
        );
      }

      // User is passed and loaded
      return (
        <DP.Row>
          <DP.Value>
            {valueOrNA(formatPhoneNumber(user.phone))}{' '}
            {user.phone && (
              <DP.Indicator
                color={user.isPhoneConfirmed ? 'green' : 'red'}
                tooltip={user.isPhoneConfirmed ? 'Phone confirmed' : 'Phone unconfirmed'}
              />
            )}
          </DP.Value>
          {label}
        </DP.Row>
      );
    }, [phone, phoneIndicatorProps, user, customUser]);

    const resetUserPhoneNumber = useCallback(async () => {
      if (!user) {
        return;
      }

      const modalTitle = 'Reset Phone Number';
      const modalMessage = (
        <Box className={boxMessage}>
          <Box>Are you sure you want to reset user's phone number?</Box>
          <Box>After you reset it, user will be able to add a new phone number.</Box>
        </Box>
      );
      // Request additional action confirmation via confirmation dialog
      const confirmed: boolean = await modalStore.confirm(modalTitle, modalMessage, {
        confirmLabel: 'Reset',
      });

      if (!confirmed) {
        return;
      }

      try {
        const { data } = await Api.core.reseteUserPhone(user.id);
        // Update user on success
        if (handleUserChanged && data.data) {
          handleUserChanged(data.data);
        }

        toastStore.push({
          type: 'success',
          message: 'User phone number has been successfully resetted',
        });
      } catch (e: any) {
        const error = e.response?.data?.error;
        if (error) {
          toastStore.push({ type: 'error', message: error?.message });
        } else {
          throw e;
        }
      }
    }, [handleUserChanged, modalStore, toastStore, user]);

    const renderActionButton = useMemo(() => {
      if (!customUser) {
        return (
          <DP.Actions>
            <FontAwesomeIcon onClick={openPhoneDialog} className={actionIcon} icon={faPen} />
          </DP.Actions>
        );
      }
      if (!hasResetPermission) {
        return null;
      }

      return (
        userStore.isAdmin && (
          <DP.Actions>
            <Tooltip title="Reset">
              <FontAwesomeIcon
                onClick={resetUserPhoneNumber}
                className={actionIcon}
                icon={faRotateLeft}
              />
            </Tooltip>
          </DP.Actions>
        )
      );
    }, [actionIcon, openPhoneDialog, resetUserPhoneNumber, customUser, hasResetPermission]);

    return (
      <>
        <DP fullWidth={fullWidth}>
          <DP.Header>
            <DP.Title panel size="small">
              Phone
            </DP.Title>
            {renderActionButton}
          </DP.Header>
          <DP.Body>{renderPhoneNumber}</DP.Body>
        </DP>

        {!customUser && (
          <Dialog
            open={editPhone}
            title={'Phone number'}
            content={
              <OutlinedInput
                variant="phone"
                fullWidth
                error={error}
                {...(phoneField && phoneField.bind())}
              />
            }
            confirmActionName="Continue"
            onCancel={closePhoneDialog}
            onConfirm={submit}
            loading={loading}
            TransitionProps={{
              onExited: onExited,
            }}
            disabled={!!error}
          />
        )}
      </>
    );
  },
);

export default PhoneNumberPanel;
