import { observer } from 'mobx-react';
import { Box, Typography } from '@material-ui/core';
import MfaComponent from 'components/MfaComponent/MfaComponent';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft } from '@fortawesome/pro-regular-svg-icons';
import useMFAForm from './useMFAForm';
import theme from 'containers/App/theme';
import Button from 'components/Button/Button';

interface IContinueButton {
  continueButton: string;
  onContinue?: () => void;
}

interface IBackButton {
  backButton: string;
  onBack: () => void;
}

type WithBackButton = IContinueButton & IBackButton;
type WithoutBackButton = IContinueButton;
type MFAFormProps = (WithBackButton | WithoutBackButton) & { title: string; initialValue?: string };
export type DestructuredProps = MFAFormProps & {
  [key: string]: any;
};

const MFAForm = observer((props: MFAFormProps) => {
  const { title, backButton, continueButton } = props as DestructuredProps;
  const {
    verified,
    loading,
    renderBackButton,
    padding,
    paddingButton,
    handleBack,
    handleContinue,
  } = useMFAForm(props);

  return (
    <>
      <Typography
        variant="body1"
        component="h1"
        style={{ fontSize: 40 }}
        align="center"
        gutterBottom>
        {title}
      </Typography>
      <Box mt={padding}>
        <MfaComponent center />
      </Box>
      <Box mt={paddingButton}>
        <Button
          disabled={!verified}
          loading={loading}
          onClick={handleContinue}
          variant="contained"
          fullWidth>
          {continueButton}
        </Button>
      </Box>
      {renderBackButton && (
        <Box mt={2.125} ml={0.5}>
          <Button
            style={{ ...theme.typography.body2 }}
            textTransform="none"
            variant="text"
            onClick={handleBack}
            startIcon={<FontAwesomeIcon icon={faAngleLeft} />}>
            {backButton}
          </Button>
        </Box>
      )}
    </>
  );
});

export default MFAForm;
