import React from 'react';
import { Box, CircularProgress, Typography } from '@material-ui/core';
import FormControl from '@mui/material/FormControl/FormControl';
import FormHelperText from '@mui/material/FormHelperText/FormHelperText';
import useStyles from './styles';
import { rootStore } from 'containers/App/App';
import { observer } from 'mobx-react';
import Button from 'components/Button/Button';
import { AuthInputProps } from 'components/Input/AuthInput/AuthInput';
import AuthCode from 'react-auth-code-input';
import useAuthInputStyles from '../Input/AuthInput/styles';

export interface IEmailConfirmProps extends AuthInputProps<string> {
  center?: boolean;
  email?: string;
  error?: React.ReactNode;
  isResendingCode?: boolean;
  resendCode: () => void;

  onChange: (value: string) => void;
}

const EmailConfirm = (props: IEmailConfirmProps) => {
  const { center, email, error, isResendingCode, resendCode, ...rest } = props;
  const { contentCenter, formHelperText } = useStyles({ ...props });
  const { container, input } = useAuthInputStyles({ hasError: !!error });
  const className = center ? contentCenter : undefined;
  return (
    <>
      <Header email={email} center={center} />
      <Box display={'inline-block'} mt={3}>
        <FormControl>
          <FormHelperText classes={{ root: formHelperText }} error={rest?.hasError}>
            {error}
          </FormHelperText>
          <Box display="inline-flex" flexDirection={'column'}>
            <AuthCode
              containerClassName={container}
              inputClassName={input}
              length={6}
              onChange={rest.onChange}
            />
            <ResendCode isResendingCode={isResendingCode} resendCode={resendCode} />
          </Box>
        </FormControl>
      </Box>
    </>
  );
};

export default EmailConfirm;

const Header = observer(({ center = false, email }: { center?: boolean; email?: string }) => {
  const { greenFont, textAlign } = useStyles({ center });
  email = email || rootStore.userStore.user?.newEmail || 'your email';
  return (
    <Typography className={textAlign} variant="body2">
      We’ve sent a code to{' '}
      <Typography className={greenFont} component={'span'}>
        {email}
      </Typography>
      . Please enter the code below.
    </Typography>
  );
});

const ResendCode = ({
  resendCode,
  isResendingCode,
}: Pick<IEmailConfirmProps, 'resendCode' | 'isResendingCode'>) => {
  const { resendCode: resendCodeStyles } = useStyles({});
  return (
    <Box mb={2} display={'flex'} justifyContent={'flex-end'} alignItems={'center'}>
      {isResendingCode && <CircularProgress color="primary" size={'15px'} />}
      <Box mr={1} />
      <Button
        disabled={isResendingCode}
        variant="text"
        className={resendCodeStyles}
        onClick={resendCode}>
        Resend code
      </Button>
    </Box>
  );
};
