import { rootStore } from 'containers/App/App';

const useTippyMasonry = () => {
  const mobilePageContent = rootStore?.uiStore?.singleColumnPageContent;
  const columns = mobilePageContent ? 1 : 2;

  return { columns };
};

export default useTippyMasonry;
