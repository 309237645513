const manageLicenseBillingGroupDialogStyles = {
  paymentMethod: {
    py: 1,
    px: 2,
  },
  creditCardIcon: {
    height: 30,
    width: 47,
    borderRadius: 4,
  },
};

export default manageLicenseBillingGroupDialogStyles;
