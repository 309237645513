import React from 'react';
import { Link as RouterLink, Route, RouteComponentProps, Switch } from 'react-router-dom';
import { observer } from 'mobx-react';
import { withStyles, WithStyles } from '@material-ui/core';
import { inject, WithModalStore, WithToastStore, WithUserStore } from 'stores';
import styles from './styles';
import DashboardLayout from 'containers/DashboardLayout';
import { setTitle } from 'services';
import TabBar from 'components/TabBar/TabBar';
import Cards from './Cards';
import { paths } from 'routes';
import UserPayouts from './UserPayouts';
import PartnerPayouts from './PartnerPayouts';
import Title from 'components/Title/Title';
import { computed } from 'mobx';

/**
 * Payout initiation types
 */
export enum PayoutInitiationType {
  USER_WALLETS = 'user_wallets',
  PARTNER_WALLETS = 'partner_wallets',
}

const PAGE_TITLE = {
  DEPOSITS: 'Deposits',
};
/** Define props for this component */
type DepositsProps = WithStyles<typeof styles> &
  WithUserStore &
  WithToastStore &
  WithModalStore &
  RouteComponentProps;

@inject('userStore', 'toastStore', 'modalStore')
@observer
class Deposits extends React.Component<DepositsProps> {
  componentDidMount() {
    setTitle(PAGE_TITLE.DEPOSITS, { noSuffix: false });
  }

  @computed public get tabs() {
    const conversionPaths = paths.deposits();
    const { pathname } = this.props.location;

    return [
      {
        label: 'User Payouts',
        selected: pathname === conversionPaths.userPayouts(),
        component: RouterLink,
        to: conversionPaths.userPayouts(),
      },
      {
        label: 'Partner Payouts',
        selected: pathname === conversionPaths.partnerPayouts(),
        component: RouterLink,
        to: conversionPaths.partnerPayouts(),
      },
      {
        label: 'Card Transfers',
        selected: pathname === conversionPaths.cards(),
        component: RouterLink,
        to: conversionPaths.cards(),
      },
    ];
  }

  render() {
    const conversionPaths = paths.deposits();
    const { pathname } = this.props.location;
    return (
      <DashboardLayout>
        <Title mb={3}>{PAGE_TITLE.DEPOSITS}</Title>
        <TabBar mb={3} tabs={this.tabs} />
        <Switch>
          <Route path={conversionPaths.userPayouts()} component={UserPayouts} />
          <Route path={conversionPaths.partnerPayouts()} component={PartnerPayouts} />
          <Route path={conversionPaths.cards()} component={Cards} />
        </Switch>
      </DashboardLayout>
    );
  }
}

export default withStyles(styles)(Deposits);
