import React, { useContext } from 'react';
import { useState } from 'react';

interface IDialogContextProps {
  open: boolean;
  setOpenDialog?: (open: boolean) => void;
}
const DialogContext = React.createContext<IDialogContextProps>({
  open: false,
});

const WorkspaceDialogProvider = ({ children }: { children: JSX.Element }) => {
  const [open, setOpenDialog] = useState(false);

  return (
    <DialogContext.Provider value={{ open, setOpenDialog }}>{children}</DialogContext.Provider>
  );
};

export const useDialogContext = () => {
  return useContext(DialogContext);
};

export default WorkspaceDialogProvider;
