import React from 'react';
import { observer } from 'mobx-react';
import { computed, observable, action, flow, makeObservable } from 'mobx';

import MaskedInput from 'react-text-mask';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';

import SettingsStore from './SettingsStore';
import { ProposalStrategy } from 'models';

import {
  DialogContent,
  DialogTitle,
  IconButton,
  Divider,
  Box,
  Typography,
  DialogContentText,
  DialogActions,
} from '@material-ui/core';

import { WithStyles, withStyles } from '@material-ui/core/styles';

import DP from 'components/DashPanel';

import styles from './styles';
import { ChevronLeft } from 'mdi-material-ui';
import UnderlineInput from 'components/Input/UnderlineInput/UnderlineInput';
import Button from 'components/Button/Button';

interface ThresholdValueProps extends WithStyles<typeof styles> {
  settingsStore: SettingsStore;
  selectedStrategy: ProposalStrategy;
  goBack: () => void;
}

/**
 * Simple component with a single input field that shows and sets current threshold
 * value for selected strategy (basic & dynamic).
 *
 * @param settingsStore on demand settings state management store
 * @param selectedStrategy flag for calling API with correct values
 * @param goBack Event handler that gets called when user navigates to previous screen
 */
@observer
class ThresholdValue extends React.Component<ThresholdValueProps> {
  constructor(props: ThresholdValueProps) {
    super(props);
    makeObservable(this);
  }
  @observable public settingsStore: SettingsStore = this.props.settingsStore;

  /** Grab the current threshold from SettingsStore */
  @observable
  public threshold?: string =
    this.settingsStore.thresholdProposalStrategySettings!.threshold.toString();

  /** Is component updating? */
  @computed public get updating(): boolean {
    return this.settingsStore.updating;
  }

  /** Is input value valid? */
  @computed public get inputError(): string | undefined {
    if (this.threshold === '0') {
      return 'Threshold has to be set at least 1$';
    }
    return undefined;
  }

  @action.bound private handleThresholdChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    // remove commas and dollar sign from masked input
    this.threshold = event.target.value.replace(/[,|$]+/g, '');
  };

  @action.bound private onSave = () => {
    this.saveSettings();
  };

  @action.bound public saveSettings = flow(function* (this: ThresholdValue) {
    yield this.settingsStore.updateGeneralSettings({ strategy: this.props.selectedStrategy });
    yield this.settingsStore.updateProposalStrategySettings(this.props.selectedStrategy, {
      threshold: this.threshold,
    });
    this.props.goBack();
  });

  renderMaskedCurrencyInput(props: any) {
    const { inputRef, ...other } = props;
    return (
      <MaskedInput
        {...other}
        ref={(ref) => inputRef(ref ? ref.inputElement : null)}
        mask={createNumberMask({ prefix: '', suffix: '' })}
        placeholderChar={'\u2000'}
        showMask={false}
      />
    );
  }

  render() {
    const { drawerContent, fontPrimary, fontSecondary, dialogActions } = this.props.classes;
    return (
      <>
        <DialogTitle>
          <Box display="flex" alignItems="center" justifyContent="space-start">
            <Box mr={2}>
              <IconButton onClick={this.props.goBack}>
                <ChevronLeft />
              </IconButton>
            </Box>
            <Typography component="h1" style={{ fontSize: 28, fontWeight: 400 }} display="inline">
              Threshold
            </Typography>
            <Box width="40px" ml="auto" display="flex" flexDirection="row" alignItems="center">
              {this.updating && <DP.LoadSpinner />}
            </Box>
          </Box>
        </DialogTitle>
        <Divider />
        <DialogContent className={drawerContent}>
          <Box display="flex" flexDirection="column" height="100%">
            <Box mt={3}>
              <DialogContentText className={fontPrimary}>
                Threshold will define at what service amount will your kiosk start displaying
                percentages instead of fixed amounts to your clients.
              </DialogContentText>
            </Box>
            <Box mt={3}>
              <DialogContentText className={fontSecondary}>
                Display percentages when service amount is above:
              </DialogContentText>
            </Box>
            <Box mt={3}>
              <UnderlineInput
                value={this.threshold}
                variant="large"
                onChange={this.handleThresholdChange}
                error={Boolean(this.inputError)}
                helperText={this.inputError}
                inputComponent={this.renderMaskedCurrencyInput}
                prefix={'$'}
              />
            </Box>
          </Box>
        </DialogContent>
        <DialogActions className={dialogActions}>
          <Button
            variant="contained"
            color="primary"
            onClick={this.onSave}
            disabled={this.updating}
            fullWidth>
            SAVE
          </Button>
        </DialogActions>
      </>
    );
  }
}

export default withStyles(styles)(ThresholdValue);
