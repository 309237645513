import { Theme, createStyles, makeStyles } from '@material-ui/core';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const styles = (theme: Theme) =>
  createStyles({
    root: {},
    positive: {
      color: theme.palette.primary.main,
    },
    negative: {
      color: theme.palette.error.main,
    },
  });

export default styles;

export const useStyles = makeStyles(styles);
