import React from 'react';
import { action, computed, flow, makeObservable } from 'mobx';
import { observer } from 'mobx-react';
import { RouteComponentProps, Link as RouterLink } from 'react-router-dom';
import { WithStyles, withStyles } from '@material-ui/core/styles';
import { Grid, Box, Paper, Button } from '@material-ui/core';

import { inject, WithUserStore, WithToastStore } from 'stores';
import { paths } from 'routes';
import { setTitle } from 'services/title';

import CartPreview from 'components/CartPreview';
import GC from '../GraphicContainer';
import SignupStore, { SignupStep } from '../SignupStore';
import CouponsList from '../CouponsList';
import SubmitButton from '../SubmitButton';
import PromoCodeEntry from './PromoCodeEntry';

import styles from './styles';

/** Here we define what kind of props this component takes */
interface PromoCodesProps
  extends WithStyles<typeof styles>, // Adds the classes prop
    WithUserStore, // Adds the userStore prop
    RouteComponentProps<{ accountId: string }>,
    WithToastStore {
  signupStore: SignupStore;
}

/**
 * The locations screen of the account signup for admins
 */
@inject('userStore', 'toastStore')
@observer
class PromoCodes extends React.Component<PromoCodesProps> {
  constructor(props: PromoCodesProps) {
    super(props);
    makeObservable(this);
  }
  public signupStore = this.props.signupStore;
  @action.bound public next = flow(function* (this: PromoCodes) {
    yield this.signupStore.finalizeSelfSignup();
    this.props.history.push(paths.checkout(this.signupStore.cart!.uid), {
      accountCode: this.signupStore.cart!.account && this.signupStore.cart!.account.code,
    });
  });
  @computed public get accountId(): number {
    return parseInt(this.props.match.params.accountId);
  }

  componentDidMount() {
    setTitle(`Affiliate & Promo Codes`);
    this.props.signupStore.setStep(SignupStep.Coupons);
    this.props.signupStore.initAccount(this.accountId);
  }

  render() {
    const { classes } = this.props;
    const signupStore = this.props.signupStore;
    if (!this.props.signupStore.accountReady) {
      return null;
    }
    return (
      <Paper>
        <Box p={6}>
          <Grid container spacing={6}>
            <Grid item xs={12} md={6}>
              <GC>
                <GC.Title>{`Affiliate & Promo Code`}</GC.Title>
                <GC.Subtitle>
                  If you have an affiliate code or promo code, enter it here
                </GC.Subtitle>
                <CartPreview cart={signupStore.cart!} cartInProgress={signupStore.cartInProgress} />
              </GC>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box mb={2}>
                <PromoCodeEntry
                  onAffiliate={signupStore.setAccountAffiliate}
                  onPromotion={signupStore.addPromotion}
                  autoFocus
                />
              </Box>
              {signupStore.accountAffiliate && (
                <Box className={classes.affiliateThanks}>
                  {signupStore.accountAffiliate.user!.firstName}{' '}
                  {signupStore.accountAffiliate.user!.lastName} is thankful for your credit 🙏🏾
                </Box>
              )}
              <CouponsList signupStore={signupStore} />
            </Grid>
            <Grid item xs={12} md={6}>
              <Button
                fullWidth
                variant="outlined"
                color="primary"
                component={RouterLink}
                to={paths.register().locations(this.accountId)}>
                Back
              </Button>
            </Grid>
            <Grid item xs={12} md={6}>
              <SubmitButton
                fullWidth
                variant="contained"
                color="primary"
                type="submit"
                onClick={this.next}
                inProgress={signupStore.finalizingOrder}>
                Proceed to checkout
              </SubmitButton>
            </Grid>
          </Grid>
        </Box>
      </Paper>
    );
  }
}

export default withStyles(styles)(PromoCodes);
