import Title from 'components/Title';
import DashboardLayout from 'containers/DashboardLayout';
import { RefundTab, useRefunds } from './useRefunds';
import RefundsHistory from './RefundsHistory';
import { RefundRequestsTable } from 'components/RefundRequests/RefundRequestsTable';
import { RouteComponentProps } from 'react-router-dom';
import TabBar from 'components/TabBar/TabBar';

export default function Refunds(props: RouteComponentProps) {
  const [tab, tabs] = useRefunds();

  return (
    <DashboardLayout>
      <Title mb={3} size={'large'}>
        Refunds
      </Title>
      <TabBar mb={3} tabs={tabs} />

      {tab === RefundTab.REFUNDS && <RefundsHistory {...props} />}
      {tab === RefundTab.REFUND_REQUESTS && <RefundRequestsTable {...props} />}
    </DashboardLayout>
  );
}
