/* eslint-disable @typescript-eslint/no-explicit-any */
import { createApi, makeQS } from './utils';

import { ChartData } from 'models';

import { RequestMetaData, ApiResponse, PagedApiResponse } from './interface';
import { AxiosResponse } from 'axios';

import config from 'config';
import { IRefundRequest } from 'models/Refunds';

const api = {
  v1: createApi({ baseURL: `${config.api.analytics}/v1` }),
  v2: createApi({ baseURL: `${config.api.analytics}/v2` }),
};

/**
 * Gets the revenues as list of locations or states with corresponding revenue values
 */
export const getRevenues = (
  by: 'state' | 'location',
  rmd?: RequestMetaData,
  extraData?: Record<string, unknown>,
) => {
  return api.v1.get(`revenues/by-${by}?${makeQS(rmd, extraData)}`);
};

// Revenues report download link
export const revenues = {
  reportByLocation(rmd: RequestMetaData, extraData: any) {
    return api.v1.get(`revenues/reports/by-location/download?${makeQS(rmd, extraData)}`);
  },
};
/**
 * Gets revenue values for revenue based chart representation
 */
export function getRevenuesChartData(
  extraData?: Record<string, unknown>,
): Promise<AxiosResponse<ApiResponse<ChartData>>> {
  return api.v1.get(`revenues?${makeQS({}, extraData)}`);
}

export const activity = {
  getAccountsChart(extraData: Record<string, unknown>) {
    return api.v1.get(`activity/accounts?${makeQS({}, extraData)}`);
  },
  getNumberOfActiveSPs(extraData: Record<string, unknown>) {
    return api.v1.get(`activity/number-of-active-sps?${makeQS({}, extraData)}`);
  },
  getAvgNumberOfTips(extraData: Record<string, unknown>) {
    return api.v1.get(`activity/avg-number-of-tips?${makeQS({}, extraData)}`);
  },
  getAvgTipsPerPayout(extraData: Record<string, unknown>) {
    return api.v1.get(`activity/avg-tips-per-payout?${makeQS({}, extraData)}`);
  },
  getAvgAmountCharged(extraData: Record<string, unknown>) {
    return api.v1.get(`activity/avg-amount-charged?${makeQS({}, extraData)}`);
  },
  getActiveAccounts(rmd: RequestMetaData, extraData?: Record<string, unknown>) {
    return api.v1.get(`activity/accounts/active?${makeQS(rmd, extraData)}`);
  },
  getActiveAccountsReport(rmd: RequestMetaData, extraData?: Record<string, unknown>) {
    return api.v1.get(`activity/active-accounts-report/download?${makeQS(rmd, extraData)}`);
  },
};

export const performance = {
  getUnderperformingLocations(rmd: RequestMetaData) {
    return api.v1.get(`performance/locations?${makeQS(rmd)}`);
  },
  getUnderperformingTalent(rmd: RequestMetaData) {
    return api.v1.get(`performance/talent?${makeQS(rmd)}`);
  },
  getUnderperformingLocationsReport(rmd: RequestMetaData) {
    return api.v1.get(`performance/locations-report/download?${makeQS(rmd)}`);
  },
  getUnderperformingTalentReport(rmd: RequestMetaData) {
    return api.v1.get(`performance/talent-report/download?${makeQS(rmd)}`);
  },
};

export const payouts = {
  getPayoutChart(fromDate: string, toDate: string) {
    return api.v1.get(`payouts/chart?${makeQS(undefined, { fromDate, toDate })}`);
  },
};

export const devices = {
  getDevicesCount(fromDate: string | undefined, toDate: string | undefined) {
    return api.v1.get(`devices/count?${makeQS(undefined, { fromDate, toDate })}`);
  },
  getDevicesCountByLocation(rmd: RequestMetaData, extraData?: Record<string, unknown>) {
    return api.v1.get(`devices/count-by-location?${makeQS(rmd, extraData)}`);
  },
};

export const conversion = {
  byAffiliates: {
    tableData(rmd: RequestMetaData, extraData?: Record<string, unknown>) {
      return api.v1.get(`/conversions/affiliates?${makeQS(rmd, extraData)}`);
    },
    statsData(fromDate: string | undefined, toDate: string | undefined) {
      return api.v1.get(
        `conversions/stats?source=affiliate&${makeQS(undefined, { fromDate, toDate })}`,
      );
    },
  },
  byEmployees: {
    tableData(rmd: RequestMetaData, extraData?: Record<string, unknown>) {
      return api.v1.get(`/conversions/employees?${makeQS(rmd, extraData)}`);
    },
    statsData(fromDate: string | undefined, toDate: string | undefined) {
      return api.v1.get(
        `conversions/stats?source=employee&${makeQS(undefined, { fromDate, toDate })}`,
      );
    },
  },
  byCampaigns: {
    tableData(rmd: RequestMetaData, extraData?: Record<string, unknown>) {
      return api.v1.get(`/conversions/campaigns?${makeQS(rmd, extraData)}`);
    },
    statsData(fromDate: string | undefined, toDate: string | undefined) {
      return api.v1.get(
        `conversions/stats?source=campaign&${makeQS(undefined, { fromDate, toDate })}`,
      );
    },
  },
  byDeals: {
    tableData(rmd: RequestMetaData, extraData?: Record<string, unknown>) {
      return api.v1.get(`/conversions/deals?${makeQS(rmd, extraData)}`);
    },
  },
};

export const reports = {
  payrollRun: (rmd: RequestMetaData, extraData?: Record<string, unknown>) =>
    api.v1.get(`reports/payroll-run/download?${makeQS(rmd, extraData)}`),
  tippySalonList: (rmd: RequestMetaData, extraData?: Record<string, unknown>) =>
    api.v1.get(`reports/salon-list/download?${makeQS(rmd, extraData)}`),
  devicesInventoryList: (rmd: RequestMetaData, extraData?: Record<string, unknown>) =>
    api.v1.get(`reports/inventory-list/download?${makeQS(rmd, extraData)}`),
  getInitiationPayoutsReport: () => api.v1.get(`initiations/initiations-report/download`),
  downloadReconciliationByDateCsv: (rmd: RequestMetaData, extraData?: Record<string, unknown>) =>
    api.v1.get(`reports/reconciliate-bydate/download?${makeQS(rmd, extraData)}`),
  downloadReconciliationByEmployeeCsv: (
    rmd: RequestMetaData,
    extraData?: Record<string, unknown>,
  ) => api.v1.get(`reports/reconciliate-byemployee/download?${makeQS(rmd, extraData)}`),
  downloadReconciliationByTransactionCsv: (
    rmd: RequestMetaData,
    extraData?: Record<string, unknown>,
  ) => api.v1.get(`reports/reconciliate-bytransaction/download?${makeQS(rmd, extraData)}`),
};

export const liability = {
  getSummary(fromDate: string | undefined, toDate: string | undefined) {
    return api.v1.get(`liability/summary?${makeQS({}, { fromDate, toDate })}`);
  },
  getLedger(fromDate: string | undefined, toDate: string | undefined) {
    return api.v1.get(`liability?${makeQS({}, { fromDate, toDate })}`);
  },
};

export const payment = {
  getPaymentStats: (rmd?: RequestMetaData) => api.v1.get(`/payments/stats?${makeQS(rmd)}`),
};

export const getLiabilityLedger = (rmd?: RequestMetaData, extraData?: Record<string, unknown>) => {
  return api.v1.get(`liability?${makeQS(rmd, extraData)}`);
};

export const ledger = {
  downloadRebateReportCSV: (rmd?: RequestMetaData, extraData?: any) => {
    return api.v1.get(`/ledger/rebate-report/download?${makeQS(rmd, extraData)}`);
  },
};

export const global = {
  tipsStats: {
    byAccount: (rmd?: RequestMetaData, extraData?: Record<string, unknown>) =>
      api.v1.get(`/stats/tips/global/by-account?${makeQS(rmd, extraData)}`),
    byLocation: (rmd?: RequestMetaData, extraData?: Record<string, unknown>) =>
      api.v1.get(`/stats/tips/global/by-location?${makeQS(rmd, extraData)}`),
    byTalent: (rmd?: RequestMetaData, extraData?: Record<string, unknown>) =>
      api.v1.get(`/stats/tips/global/by-talent?${makeQS(rmd, extraData)}`),
  },
  tipsCsvReport: {
    byAccount: (rmd?: RequestMetaData, extraData?: Record<string, unknown>) =>
      api.v1.get(`/reports/tips/global/by-account/download?${makeQS(rmd, extraData)}`),
    byLocation: (rmd?: RequestMetaData, extraData?: Record<string, unknown>) =>
      api.v1.get(`/reports/tips/global/by-location/download?${makeQS(rmd, extraData)}`),
    byTalent: (rmd?: RequestMetaData, extraData?: Record<string, unknown>) =>
      api.v1.get(`/reports/tips/global/by-talent/download?${makeQS(rmd, extraData)}`),
  },
};

export const stats = {
  tipsStats: {
    byAccount: (rmd?: RequestMetaData, extraData?: Record<string, unknown>) =>
      api.v1.get(`/stats/tips/by-account?${makeQS(rmd, extraData)}`),
    byLocation: (rmd?: RequestMetaData, extraData?: Record<string, unknown>) =>
      api.v1.get(`/stats/tips/by-location?${makeQS(rmd, extraData)}`),
    byTalent: (rmd?: RequestMetaData, extraData?: Record<string, unknown>) =>
      api.v1.get(`/stats/tips/by-talent?${makeQS(rmd, extraData)}`),
  },
  tipsCsvReport: {
    byAccount: (rmd?: RequestMetaData, extraData?: Record<string, unknown>) =>
      api.v1.get(`/reports/tips/by-account/download?${makeQS(rmd, extraData)}`),
    byLocation: (rmd?: RequestMetaData, extraData?: Record<string, unknown>) =>
      api.v1.get(`/reports/tips/by-location/download?${makeQS(rmd, extraData)}`),
    byTalent: (rmd?: RequestMetaData, extraData?: Record<string, unknown>) =>
      api.v1.get(`/reports/tips/by-talent/download?${makeQS(rmd, extraData)}`),
  },
  chartReport: {
    getTipsChartData: (
      rmd?: RequestMetaData,
      extraData?: Record<string, unknown>,
    ): Promise<AxiosResponse<ApiResponse<ChartData>>> =>
      api.v1.get(`/tips/chart-report?${makeQS(rmd, extraData)}`),
  },
  cashStats: {
    getCashStats: (userId: number) => api.v1.get(`users/${userId}/cash-stats`),
  },
};

/** Get Reconciliation data by date */
export const getReconciliationByDate = (
  accountId?: number,
  rmd?: RequestMetaData,
  extraData?: any,
) => {
  const filters = { ...rmd?.filters, accountId };
  rmd = { ...rmd, filters };
  return api.v1.get(`reconciliation/by-date?${makeQS(rmd, extraData)}`);
};

/** Get Reconciliation data by employee */
export const getReconciliationByEmployee = (
  accountId?: number,
  rmd?: RequestMetaData,
  extraData?: any,
) => {
  const filters = { ...rmd?.filters, accountId };
  rmd = { ...rmd, filters };
  return api.v1.get(`reconciliation/by-employee?${makeQS(rmd, extraData)}`);
};

/** Get Reconciliation data by transaction */
export const getReconciliationByTransaction = (
  accountId?: number,
  rmd?: RequestMetaData,
  extraData?: any,
) => {
  const filters = { ...rmd?.filters, accountId };
  rmd = { ...rmd, filters };
  return api.v1.get(`reconciliation/by-transaction?${makeQS(rmd, extraData)}`);
};

/** Get Reconciliation data by employee stats*/
export const getReconciliationByEmployeeStats = (
  accountId?: number,
  rmd?: RequestMetaData,
  extraData?: any,
) => {
  const filters = { ...rmd?.filters, accountId };
  rmd = { ...rmd, filters };
  return api.v1.get(`/stats/reconciliation/by-employee?${makeQS(rmd, extraData)}`);
};

/** Get Reconciliation data by transaction stats */
export const getReconciliationByTransactionStats = (
  accountId?: number,
  rmd?: RequestMetaData,
  extraData?: any,
) => {
  const filters = { ...rmd?.filters, accountId };
  rmd = { ...rmd, filters };
  return api.v1.get(`/stats/reconciliation/by-transaction?${makeQS(rmd, extraData)}`);
};

export const talentTipsSummary = {
  /** Talents tips summary report for admins; gets all */
  getReport: (rmd: RequestMetaData, extraData: any) =>
    api.v1.get(`talent-summary-report?${makeQS(rmd, extraData)}`),
  downloadReport: (rmd: RequestMetaData, extraData: any) =>
    api.v1.get(`talent-summary-report/download?${makeQS(rmd, extraData)}`),
};

export const getAccountsLastActivity = (
  rmd?: RequestMetaData,
  extraData?: Record<string, unknown>,
) => {
  return api.v1.get(`tips/last-activity?${makeQS(rmd, extraData)}`);
};

export const getLiveLocationsCount = (
  rmd?: RequestMetaData,
): Promise<AxiosResponse<ApiResponse<number>>> =>
  api.v1.get(`locations/active-locations-count?${makeQS(rmd)}`);

export const getRefundsForOwnerOrManager = (
  rmd?: RequestMetaData,
  extraData?: Record<string, unknown>,
): Promise<AxiosResponse<PagedApiResponse<IRefundRequest>>> => {
  return api.v1.get(`refunds?${makeQS(rmd, extraData)}`);
};
