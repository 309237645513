import { DestructuredProps } from './MFAForm';
import { rootStore, useStores } from 'containers/App/App';
import { verifyMfaData } from 'components/MfaComponent/helpers';

type IUseMFAFormProps = Partial<DestructuredProps>;

const useMFAForm = (props: IUseMFAFormProps) => {
  const { mfaStore } = useStores();
  const { mfaBodyValid, loading } = mfaStore;
  const { onBack, backButton, onContinue } = props;

  const { mobileView } = rootStore.uiStore;
  const numFActors = rootStore.mfaStore.factors?.length || 0;
  const padding = mobileView ? (numFActors > 1 ? 4 : 15.875) : 15.875;
  const paddingButton = mobileView ? 4 : 5.125;
  const renderBackButton = !!backButton;
  const verified = mfaBodyValid;

  const handleBack = () => {
    if (!onBack) return;
    onBack();
    mfaStore.resetStore();
  };

  const handleContinue = async () => {
    const response = await verifyMfaData();
    if (onContinue && response?.data?.data) {
      onContinue();
    }
  };

  return {
    verified,
    loading,
    renderBackButton,
    handleBack,
    handleContinue,
    padding,
    paddingButton,
  };
};

export default useMFAForm;
