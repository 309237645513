import React from 'react';
import { Box, CircularProgress, IconButton, List, ListItem, Typography } from '@material-ui/core';
import { withStyles, WithStyles } from '@material-ui/styles';
import Api, { getErrorMsg } from 'api';
import clsx from 'clsx';
import Overlay from 'components/Overlay';
import clone from 'lodash/clone';
import reverse from 'lodash/reverse';
import { Close } from 'mdi-material-ui';
import { action, computed, flow, observable, makeObservable } from 'mobx';
import { observer } from 'mobx-react';
import { BankAccountType, BankEvent } from 'models';
import moment from 'moment';
import { inject, WithToastStore } from 'stores';
import styles, { useStyles } from './styles';

/**
 * Groups a list of items by consecutive streaks as defined by a comparator function
 */
function groupStreaksBy<T>(ls: T[], comparator: (a: T, b: T) => boolean): T[][] {
  return ls.reduce((acc: T[][], l: T) => {
    if (acc.length === 0) {
      return [[l]];
    } else {
      const [last] = acc.slice(-1);
      if (comparator(last[0], l)) {
        return acc.slice(0, -1).concat([acc.slice(-1)[0].concat(l)]);
      } else {
        return acc.concat([[l]]);
      }
    }
  }, []);
}

/** A property of an event */
function EventProperty({ name, value }: { name: string; value: React.ReactNode }) {
  const classes = useStyles();
  return (
    <Box className={classes.eventProperty}>
      {name}: {value}
    </Box>
  );
}

/** A single event */
function Event({ children: event, number }: { children: BankEvent; number: number }) {
  const classes = useStyles();
  const isError = event.name === 'ERROR';
  return (
    <ListItem className={classes.event} divider>
      <Box className={classes.headWrapper}>
        <Box className={clsx(classes.eventName, isError && classes.error)}>
          {number + 1}. {event.name}
        </Box>
        <Box className={classes.createdAt} color="text.secondary">
          {moment(event.createdAt).format(`hh:mm A`)}
        </Box>
      </Box>
      <Box mt={2}>
        {event.accountId && <EventProperty name="Account ID" value={event.accountId} />}
        {event.itemId && <EventProperty name="Item ID" value={event.itemId} />}
        {event.metadata.request_id && (
          <EventProperty name="Request ID" value={event.metadata.request_id} />
        )}
        {event.metadata.status && <EventProperty name="Status" value={event.metadata.status} />}
        {event.metadata.institution_name && (
          <EventProperty name="Institution name" value={event.metadata.institution_name} />
        )}
        {event.metadata.error_message && (
          <EventProperty name="Error message" value={event.metadata.error_message} />
        )}
        {event.metadata.exit_status && (
          <EventProperty name="Exit status" value={event.metadata.exit_status} />
        )}
        {event.metadata.view_name && (
          <EventProperty name="View name" value={event.metadata.view_name} />
        )}
        {event.metadata.mfa_type && (
          <EventProperty name="MFA type" value={event.metadata.mfa_type} />
        )}
      </Box>
    </ListItem>
  );
}

/** Displays a single session of a plaid interaction */
function LinkSession({ children }: { children: BankEvent[] }) {
  const classes = useStyles();
  const first = children[0];
  if (!first) {
    return null;
  }
  const sessionTitle = first.metadata.link_session_id ? first.metadata.link_session_id : '';
  return (
    <>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        mt={3}>
        <Box className={classes.linkSessionId}>Session {sessionTitle}</Box>
        <Box className={classes.bigDate}>{moment(first.createdAt).format('ll')}</Box>
      </Box>
      <List>
        {children.map((event, i) => (
          <Event key={event.id} number={i}>
            {event}
          </Event>
        ))}
      </List>
    </>
  );
}

/**
 * @userId use this prop for personal bankAccountType
 * @businessAccountId use this prop for business bankAccountType
 */
interface BankEventsProps extends WithStyles<typeof styles>, WithToastStore {
  userId?: number;
  businessAccountId?: number;
  bankAccountType: BankAccountType;
  onClose?: () => void;
}
/** Displays a list of bank events for a user */
@inject('toastStore')
@observer
class BankEvents extends React.Component<BankEventsProps> {
  constructor(props: BankEventsProps) {
    super(props);
    makeObservable(this);
  }

  @observable public events?: BankEvent[];
  @action.bound public getEvents = flow(function* (this: BankEvents) {
    let fetchBankEvents = () => Api.core.getBankEvents(this.props.userId!);
    if (this.props.bankAccountType === BankAccountType.BUSINESS) {
      fetchBankEvents = () => Api.core.getAccountBankEvents(this.props.businessAccountId!);
    }
    try {
      const resp = yield fetchBankEvents();
      yield new Promise((r) => setTimeout(r, 500));
      this.events = resp.data.data;
    } catch (e: any) {
      this.props.toastStore!.error(getErrorMsg(e));
    }
  });

  /** Events grouped by session */
  @computed public get groupedEvents(): BankEvent[][] | undefined {
    if (!this.events) {
      return undefined;
    }
    // Group by streaks of the same link session id, so that
    // each group represents one session
    return (
      groupStreaksBy(
        this.events,
        (a, b) => a.metadata.link_session_id === b.metadata.link_session_id,
      )
        // Reverse each event inside a group so that we can read them
        // from top to bottom
        .map((group) => reverse(clone(group)))
    );
  }
  componentDidMount() {
    this.getEvents();
  }
  render() {
    const { classes } = this.props;
    return (
      <Box className={classes.root}>
        <Overlay display={!this.events}>
          <CircularProgress />
        </Overlay>
        <Box className={classes.inner}>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
            mb={2}>
            <Typography variant="h4" component="h4">
              Bank events
            </Typography>
            {this.props.onClose && (
              <IconButton onClick={this.props.onClose}>
                <Close />
              </IconButton>
            )}
          </Box>
          {this.groupedEvents && this.groupedEvents.length ? (
            <>
              {this.groupedEvents.map((events, i) => (
                <LinkSession key={i}>{events}</LinkSession>
              ))}
            </>
          ) : (
            <Box> No events to display</Box>
          )}
        </Box>
      </Box>
    );
  }
}

export default withStyles(styles)(BankEvents);
