import React from 'react';
import { Typography } from '@material-ui/core';
import useCountdown from './useCountdown';
import { Skeleton } from '@mui/material';

export interface ICountdownProps {
  time?: string;
}

const Countdown = (props: ICountdownProps) => {
  const { time: initialTime } = props;
  const { time } = useCountdown({ initialTime });
  if (!time) {
    return <Skeleton variant="text" width={40} height={30} />;
  }
  return (
    <Typography variant="subtitle1" component={'span'}>
      {time.minutes}:{time.seconds}
    </Typography>
  );
};

export default Countdown;
