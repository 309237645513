/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { observer } from 'mobx-react';

import { Link } from '@material-ui/core';
import { WithStyles, withStyles } from '@material-ui/core/styles';
import { Link as RouterLink, Route, RouteComponentProps, Redirect } from 'react-router-dom';

import { inject, WithUserStore, WithToastStore, WithAnalyticsStore } from 'stores';

import DashboardLayout from 'containers/DashboardLayout';

import Title from 'components/Title';

import { paths } from 'routes';

import styles from './styles';
import UnderperformingLocations from './UnderperformingLocations';
import UnderperformingTalent from './UnderperformingTalent';

import { setTitle } from '../../services';
import { computed } from 'mobx';
import TabBar from 'components/TabBar/TabBar';

const PAGE_TITLE = 'Performance';

// TODO: Remove this component? We might not be using it anymore

/** Here we define what kind of props this component takes */
type PerformanceProps = WithStyles<typeof styles> & // Adds the classes prop
  WithUserStore &
  WithAnalyticsStore &
  RouteComponentProps &
  WithToastStore; // Adds the userStore prop

/**
 * Container for displaying performance analytics
 */
@inject('userStore', 'toastStore', 'analyticsStore')
@observer
class Performance extends React.Component<PerformanceProps> {
  @computed public get tabs() {
    const { pathname } = this.props.location;
    const performance = paths.analytics().performance();
    const locationsSelected = pathname === performance.locations();
    const talentSelected = pathname === performance.talent();

    return [
      {
        label: 'Locations',
        component: RouterLink,
        to: performance.locations(),
        selected: locationsSelected,
      },
      {
        label: 'Employees',
        component: RouterLink,
        to: performance.talent(),
        selected: talentSelected,
      },
    ];
  }

  /** Render account as links to accounts */
  renderAccount = (rowIndex: any, name: any, value: any, row: any) => {
    return (
      <Link component={RouterLink} to={paths.accountDetails(row.accountId).root()}>
        {row.accountName}
      </Link>
    );
  };
  componentDidMount() {
    setTitle(PAGE_TITLE, { noSuffix: false });
  }
  render() {
    const performance = paths.analytics().performance();
    const { pathname } = this.props.location;
    if (pathname === performance.root()) {
      return <Redirect to={performance.locations()} />;
    }

    return (
      <DashboardLayout>
        <Title mb={3}>{PAGE_TITLE}</Title>
        <TabBar mb={3} tabs={this.tabs} />
        <Route path={performance.locations()} exact component={UnderperformingLocations} />
        <Route path={performance.talent()} exact component={UnderperformingTalent} />
      </DashboardLayout>
    );
  }
}

export default withStyles(styles)(Performance);
