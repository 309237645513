import { RequestMetaData } from 'api';
import { ChipStatusColors, IBadgeConfig } from 'components/ChipStatusTag';
import { Filter, FilterBarProps } from 'components/FilterBar/FilterBar';
import { rootStore } from 'containers/App/App';
import useExternalDateRange from 'hooks/useExternalDateRange';
import * as models from 'models';
import { useEffect, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { adaptForDataGridPro } from 'services/datagrid';
import { ACL } from 'types';
import FILTERS from 'utils/configs/FILTERS';
import useTransferDialogs from './useTransferDialogs';
import Api from 'api';
import { getFullName } from 'utils/helper';
import qs from 'qs';

const badgeConfig: IBadgeConfig = {
  CREATED: ChipStatusColors.PURPLE,
  APPROVED: ChipStatusColors.GREEN,
  REJECTED: ChipStatusColors.RED,
};

let abortController = new AbortController();

const useTransfers = () => {
  const history = useHistory();

  const location = useLocation();

  const [activeFilters, setActiveFilters] = useState({});

  const [refetchKey, setRefetchKey] = useState(Date.now());

  const [tip, setTip] = useState<models.Tip | undefined>(undefined);

  const action = useRef<models.ETransferRequestAction | undefined>(undefined);

  const { userStore, notificationStore } = rootStore;

  const search = qs.parse(location?.search, { ignoreQueryPrefix: true });

  const showCreated = search?.showCreated === 'true';

  const hasTransferTipPermission = userStore.hasPermission(ACL.TRANSFER_TIP);

  const { actionDialogProps, rejectionReasonProps, openDialog, setTransferTipRequest } =
    useTransferDialogs({
      action: action.current,
      onConfirm: () => {
        setRefetchKey(Date.now());
        resetSearchParams();
        notificationStore.getCreatedTransferTipRequests();
      },
      approveRequest: Api.tips.approveTransferTipRequest,
      rejectRequest: Api.tips.rejectTransferTipRequest,
    });

  const { dateRange, onDateRangeChange } = useExternalDateRange({
    identifier: history.location.pathname,
    defaultValue: showCreated ? { type: 'all' } : undefined,
  });

  useEffect(() => {
    setRefetchKey(Date.now());
  }, [activeFilters, dateRange]);

  useEffect(() => {
    if (showCreated) {
      setActiveFilters({ status: models.ETransferRequestStatus.CREATED });
    }
  }, [location, setActiveFilters]);

  const { fromDate, toDate } = dateRange;

  const filters = { ...activeFilters, fromDate, toDate };

  const onClose = () => {
    setTip(undefined);
  };

  const handleOpenDialog = (
    _action: models.ETransferRequestAction,
    request?: models.ITransferRequest,
  ) => {
    action.current = _action;
    setTransferTipRequest(request);
  };

  const actionMenuItems = (request: models.ITransferRequest) => [
    {
      label: 'Approve',
      disabled: !hasTransferTipPermission,
      tooltipTitle: 'Missing permissions to perform this action',
      onClick: () => handleOpenDialog(models.ETransferRequestAction.APPROVE, request),
    },
    {
      label: 'Reject',
      disabled: !hasTransferTipPermission,
      tooltipTitle: 'Missing permissions to perform this action',
      onClick: () => {
        handleOpenDialog(models.ETransferRequestAction.REJECT, request);
      },
    },
  ];

  const abortApiCallAndSetUpNewController = () => {
    abortController.abort();
    abortController = new AbortController();
  };

  const getTransferTipRequests = async (rmd: RequestMetaData) => {
    abortApiCallAndSetUpNewController();
    return await Api.tips.getTransferTipRequests(
      {
        ...rmd,
        filters,
      },
      abortController,
    );
  };

  const mapFn = (transfer: models.ITransferRequest) => {
    const { account, location, toUser, fromUser, requestedBy, resolvedBy } = transfer;
    return {
      ...transfer,
      accountId: account.id,
      locationId: location.id,
      accountName: account.name,
      locationName: location.name,
      from: getFullName(fromUser?.firstName, fromUser?.lastName),
      to: getFullName(toUser?.firstName, toUser?.lastName),
      requestedByUser: getFullName(requestedBy?.firstName, requestedBy?.lastName),
      resolvedByUser: getFullName(resolvedBy?.firstName, resolvedBy?.lastName),
      reference: transfer?.tip?.payment?.reference,
      paymentId: transfer?.tip?.payment?.id,
    };
  };

  const fetch = adaptForDataGridPro(getTransferTipRequests, mapFn);

  const filtersDefinition: Filter[] = [
    FILTERS.accountId({}),
    FILTERS.locationId({}),
    FILTERS.userId({ id: 'toUserId' }),
    FILTERS.status({
      items: [
        { value: models.ETransferRequestStatus.CREATED, label: 'CREATED' },
        { value: models.ETransferRequestStatus.APPROVED, label: 'APPROVED' },
        { value: models.ETransferRequestStatus.REJECTED, label: 'REJECTED' },
      ],
    }),
  ];

  const resetSearchParams = () => {
    if (showCreated) {
      history.replace({ search: '' });
      setActiveFilters({ ...filters, status: undefined });
    }
  };

  const handleSetActiveFilters = (_filters: Record<string, unknown>) => {
    resetSearchParams();
    setActiveFilters(_filters);
  };

  const filterBarProps: FilterBarProps = {
    filters: filtersDefinition,
    onChange: (_filters: Record<string, unknown>) => handleSetActiveFilters(_filters),
    externalDateRange: {
      predefined: dateRange,
      onChange: onDateRangeChange,
    },
  };

  const drawerProps = {
    tip,
    onClose,
  };

  return {
    filterBarProps,
    action: action.current,
    actionDialogProps,
    rejectionReasonProps,
    refetchKey,
    drawerProps,
    fetch,
    setTip,
    actionMenuItems,
    resetSearchParams,
  };
};

export default useTransfers;
