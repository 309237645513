import React, { useEffect, useState } from 'react';
import { observable, action, computed, makeObservable, IReactionDisposer, reaction } from 'mobx';
import { inject, observer } from 'mobx-react';
import { Link as RouterLink } from 'react-router-dom';
import { Map, FormatListBulleted } from 'mdi-material-ui';
import * as paths from 'routes/paths';
import clsx from 'clsx';
import styles from './styles';

import {
  IconButton,
  Box,
  FormControl,
  Select,
  MenuItem,
  Paper,
  RadioGroup,
  FormControlLabel,
  Radio,
  Chip,
  Typography,
  Tooltip,
  ClickAwayListener,
  ButtonBase,
} from '@material-ui/core';
import { Close } from 'mdi-material-ui';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { omit } from 'lodash';
import moment from 'moment-timezone';
import CustomPicker, {
  DateRange,
  ECustomPickerViewMode,
  isoDateFormat,
  listOptionDate,
} from '../DateRangeExternalPicker/CustomPicker';
import TagInputComponent from '../TagInputComponent';
import DateRangePicker from 'components/DateRangeExternalPicker';
import OutlinedInput from 'components/Input/OutlinedInput';
import { default as DialogButton } from 'components/Button/Dialog/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCalendar,
  faCaretDown,
  faChartLine,
  faChevronRight,
  faPlus,
} from '@fortawesome/pro-regular-svg-icons';
import MobileViewWrapperDialog, {
  EMobileWrapperDialogStep,
} from './MobileViewWrapperDialog/MobileViewWrapperDialog';
import { rootStore } from 'containers/App/App';
import { List } from '@mui/material';
import Button from 'components/Button/Button';
import { WithUiStore } from 'stores';
import ListButton from 'components/Button/ListButton/ListButton';
import { faCircle } from '@fortawesome/pro-solid-svg-icons';
import { EDateFormat } from 'utils/helper';

const findOptionDateByType = (typeName: string) => {
  return listOptionDate.find(({ type }) => type === typeName);
};

/** Raw filter for backend API consumption */
export interface RawFilter {
  id: string;
  value: string;
}

/** Filter types */
export type FilterType =
  | 'text'
  | 'number'
  | 'tags'
  | 'select'
  | 'range'
  | 'autocomplete'
  | 'date'
  | 'datePicker'
  | 'fields';

export type IntervalType = 'number' | 'date';

export type FieldType = 'text' | 'number' | 'select' | 'autocomplete' | 'tags';

/** Filter items when using 'select' filter type */
export interface FilterItem {
  label: string;
  value: string;
}

export interface Fields {
  id: string;
  label: string;
  type: FieldType;
}

export interface IntervalOptions {
  from: FilterItem;
  to: FilterItem;
  type: IntervalType;
}

export interface AdditionalValue {
  value: string | string[];
  separator?: string;
}

export interface SearchKey {
  name: string;
  phrase?: string;
}

export interface ValueOptions {
  value: string;
  additional?: AdditionalValue;
  keySearch: SearchKey;
}

export interface FilterOptions {
  fetch: (value: any) => unknown;
  displayField: ValueOptions;
  multipleChoice?: boolean;
}

/** Filter and it's attributes  */
export interface Filter {
  display: string;
  id: string;
  label: string;
  type: FilterType;
  items?: FilterItem[];
  interval?: IntervalOptions;
  options?: FilterOptions;
  value?: any;
  fields?: Fields[];
}

interface FieldRangeProps {
  interval: IntervalOptions;
  onChange: (value: Record<string, any>) => void;
  setFilterValue?: () => void;
  value: Record<string, any>;
  label?: string;
  fullWidth?: boolean;
}

const FieldRangeComponent = ({ interval, onChange, value, ...other }: FieldRangeProps) => {
  const [rangeValue, setValue] = React.useState<any>(value || {});

  const handleOnchange = ({ target }: any) => {
    const val =
      target.value === ''
        ? omit(rangeValue, target.name)
        : { ...rangeValue, [target.name]: target.value };

    setValue(val);
    onChange(val);
  };

  const updateDateRangeValue = ({ fromDate, toDate, type }: DateRange) => {
    setValue({ fromDate, toDate, type });
    const val = {
      [interval.from.value]: fromDate ? moment(fromDate).format() : fromDate,
      [interval.to.value]: toDate ? moment(toDate).format() : toDate,
      type,
    };

    onChange(val);
    //setFilterValue && setFilterValue();
  };

  return (
    <>
      {interval.type == 'date' && (
        <CustomPicker
          onChange={updateDateRangeValue}
          interval={interval}
          selected={
            Object.keys(rangeValue).length === 3
              ? {
                  fromDate: value[interval.from.value],
                  toDate: value[interval.to.value],
                  type: value && value.type,
                }
              : {}
          }
        />
      )}
      {interval.type == 'number' && (
        <Box
          style={{
            display: 'flex',
            justifyContent: 'space-between',
          }}>
          <Box mr={2}>
            <OutlinedInput
              value={rangeValue[interval.from.value]}
              onChange={handleOnchange}
              label={interval.from.label}
              name={interval.from.value}
              fullWidth
            />
          </Box>
          <Box>
            <OutlinedInput
              value={rangeValue[interval.to.value]}
              onChange={handleOnchange}
              label={interval.to.label}
              name={interval.to.value}
              fullWidth
            />
          </Box>
        </Box>
      )}
    </>
  );
};

interface ExternalDateRangeProps {
  onChange: (range: DateRange) => void;
  predefined: DateRange;
}

interface ShowButton {
  isActive: boolean | null;
  onActive: () => void;
}

export type FilterBarProps = {
  filters: Filter[];
  onChange: (filters: Record<string, unknown>) => void;
  externalDateRange?: ExternalDateRangeProps;
  locationMap?: boolean | null;
  graph?: ShowButton;
  defaultValue?: Record<string, unknown>;
} & WithUiStore;

/**
 * Standalone filter bar for containers/screens inspired by Google's Admin interface.
 * Accepts list of available filters and gives the user ability to set those filters.
 * When any filter value changes, 'onChange' callback returns record of all filters
 * that have values set.
 * @param filters list of all available filters
 * @param onChange callback that returns list of all filters that have values set
 */

@inject('uiStore')
@observer
class FilterBar extends React.Component<FilterBarProps & { classes: any }> {
  constructor(props: FilterBarProps & { classes: any }) {
    super(props);
    makeObservable(this);
    this.disposers.push(
      reaction(
        () => rootStore?.uiStore?.mobileView,
        (mobileView?: boolean) => {
          if (this.mobileWrapperDialogStep && !mobileView) this.setMobileWrapperDialogStep();
        },
      ),
    );
    this.disposers.push(
      reaction(
        () =>
          rootStore?.uiStore?.mobileView ? this.props?.externalDateRange?.predefined : undefined,
        (range?: DateRange) => {
          if (range) this.onDateRangeValueChange(range);
        },
        { fireImmediately: true },
      ),
    );
  }

  private disposers: IReactionDisposer[] = [];

  /** List of available filters */
  @observable private filters: Filter[] = this.props.filters;

  /** Filter that is currently being edited */
  @observable private selectedFilter?: Filter;

  /** Value for controlled input. Final value gets set on 'Apply' button click */
  @observable private filterValue?: any;
  @observable private tempValue?: any;

  @observable dateRangeValue = {} as DateRange;

  @observable mobileWrapperDialogStep?: EMobileWrapperDialogStep = undefined;

  /** List of filters that have values set */
  @computed private get activeFilters(): Pick<Filter, 'id' | 'value' | 'options'>[] {
    return this.filters
      .filter((f) => f.value)
      .map((f) => ({
        id: f.id,
        value: f.value,
        options: f.options,
      }));
  }

  /**
   * Filters parsed as {filterName: 'filterValue', ... }
   * record to be returned with onChange callback
   */
  @computed private get rawFilters(): Record<string, unknown> {
    return this.activeFilters.reduce((data: any, activeFilter) => {
      let extraValue;

      if (Array.isArray(activeFilter.value)) {
        const isMore = [...activeFilter.value].length > 1;
        const keyName = isMore ? `${activeFilter.id}s` : activeFilter.id;

        if (activeFilter.options) {
          const keySerach: SearchKey = activeFilter.options.displayField.keySearch;
          const mapValue = [...activeFilter.value].map((item) => item[keySerach.name]);
          extraValue = { [keyName]: isMore ? mapValue : mapValue[0] };
        } else {
          extraValue = { [keyName]: isMore ? activeFilter.value : activeFilter.value[0] };
        }
      } else if (typeof activeFilter.value === 'object') {
        extraValue = activeFilter.value;
      } else {
        extraValue = { [activeFilter.id]: activeFilter.value };
      }

      return { ...data, ...extraValue };
    }, {});
  }

  /** If any filter has value set, we show 'Clear all filters' button */
  @computed private get showClearFilters(): boolean {
    return this.activeFilters.length > 0;
  }

  /** On mobile phone we always display all filters, while on desktop we display only unselected filters */
  @computed get visibleFilters() {
    const { mobileView } = rootStore.uiStore;
    return mobileView ? this.filters : this.filters?.filter((filter) => !filter.value);
  }

  /** Mobile wrapper dialog props based on the current step */
  @computed private get mobileWrapperDialogProps() {
    let title = '';
    let back;
    switch (this.mobileWrapperDialogStep) {
      case EMobileWrapperDialogStep.FILTERS:
        title = 'Filters';
        break;
      case EMobileWrapperDialogStep.ADD_FILTER:
        title = 'Add a Filter';
        back = this.goBack;
        break;
      case EMobileWrapperDialogStep.ADD_DATE_FILTER:
      case EMobileWrapperDialogStep.DATE_FILTER:
        title = 'Date Picker';
        back = this.goBack;
        break;
      case EMobileWrapperDialogStep.FILTER:
        title = this.selectedFilter?.display || '';
        back = this.goBack;
        break;
      default:
        title = this.selectedFilter?.display || '';
    }
    const onClose = () => {
      this.clearSelectedFilter();
      this.closeFiltersMenu();
    };

    return {
      open: this.openMobileWrapperDialog,
      title,
      back,
      onClose,
    };
  }

  /** Whether the MobileWrapperDialog should be open or not */
  @computed private get openMobileWrapperDialog() {
    return !!this.mobileWrapperDialogStep;
  }

  /** Range type of currently selected date range value eg. 'lastWeek' */
  @computed get rangeType() {
    return (
      this.dateRangeValue &&
      listOptionDate.find((option) => option.type === this.dateRangeValue.type)
    );
  }

  /** onChange callback for controlled input */
  @action.bound private updateInputValue(e: React.ChangeEvent<HTMLInputElement>) {
    this.filterValue = e.target.value;
  }

  @action.bound private updateFildsValue(e: React.ChangeEvent<HTMLInputElement>) {
    e.preventDefault();
    if (e.target.value === '') {
      delete this.tempValue[e.target.name];
      delete this.filterValue[e.target.name];
    } else {
      this.tempValue = { ...this.tempValue, [e.target.name]: e.target.value };
    }

    if (Object.keys(this.tempValue).length === this.selectedFilter?.fields?.length) {
      this.filterValue = this.tempValue;
    } else {
      this.filterValue = undefined;
    }
  }

  /** onChange callback for controlled input array */
  @action.bound private updateInputItems(items: any) {
    if (Array.isArray(items) && !items.length) {
      this.filterValue = undefined;
    } else {
      this.filterValue = items;
    }
  }

  /** onChange callback for controlled input */

  @action.bound private updateRangeValue(range: Record<string, unknown>) {
    if (Object.keys(range).length > 0) {
      this.filterValue = range;
    } else {
      this.filterValue = undefined;
    }
  }

  /** Sets selectedFilter and shows filter panel */
  @action.bound private selectFilter(e: React.ChangeEvent<{ value: unknown }>) {
    e.preventDefault();
    const filterId = e.target.value;
    this.setFilter(filterId);
  }

  @action.bound private setFilter(filterId: unknown) {
    const filter = this.filters.find((f) => f.id === filterId);
    this.selectedFilter = filter;
    this.filterValue = filter?.value;
  }

  @action.bound private openFiltersMenu(e?: any) {
    e?.stopPropagation();
    this.setMobileWrapperDialogStep(EMobileWrapperDialogStep.FILTERS);
  }

  @action.bound private closeFiltersMenu() {
    this.setMobileWrapperDialogStep(undefined);
  }

  @action.bound setMobileWrapperDialogStep(step?: EMobileWrapperDialogStep) {
    if (step === EMobileWrapperDialogStep.FILTERS) this.clearSelectedFilter();
    this.mobileWrapperDialogStep = step;
  }

  @action.bound goBack() {
    switch (this.mobileWrapperDialogStep) {
      case EMobileWrapperDialogStep.FILTER:
        this.setMobileWrapperDialogStep(EMobileWrapperDialogStep.ADD_FILTER);
        this.clearSelectedFilter();
        break;
      case EMobileWrapperDialogStep.ADD_FILTER:
      case EMobileWrapperDialogStep.ADD_DATE_FILTER:
        this.setMobileWrapperDialogStep(EMobileWrapperDialogStep.FILTERS);
        break;
      case EMobileWrapperDialogStep.DATE_FILTER:
        this.setMobileWrapperDialogStep(EMobileWrapperDialogStep.ADD_DATE_FILTER);
        break;
      default:
        this.setMobileWrapperDialogStep(EMobileWrapperDialogStep.FILTERS);
    }
  }

  @action.bound onDateRangeValueChange(range: unknown) {
    this.dateRangeValue = range as DateRange;
  }

  @action.bound public handleOpenMobileDateRangePicker(open: boolean) {
    if (open) return this.setMobileWrapperDialogStep(EMobileWrapperDialogStep.DATE_FILTER);
    this.closeFiltersMenu();
  }

  /** Clears selected filter and input value */
  @action.bound private clearSelectedFilter() {
    this.selectedFilter = undefined;
    this.filterValue = undefined;
  }

  /** Apply filter and trigger callback with new filter values */
  @action.bound public setFilterValue() {
    this.filters = this.filters.map((filter) =>
      this.selectedFilter && filter.id === this.selectedFilter.id
        ? { ...filter, value: this.filterValue }
        : filter,
    );
    this.clearSelectedFilter();
    this.props.onChange(this.rawFilters);

    if (rootStore?.uiStore.mobileView) this.closeFiltersMenu();
  }

  /** Apply filter and trigger callback with new filter values */
  @action.bound public setDefaultValue(defaultValue: Record<string, unknown>) {
    defaultValue &&
      Object.keys(defaultValue).forEach((e) => {
        this.filters = this.filters.map((filter) =>
          filter.id === e ? { ...filter, value: defaultValue[e] } : filter,
        );
      });

    this.props.onChange(this.rawFilters);
  }

  @action.bound public removeFilter(filterId: string) {
    this.filters = this.filters.map((filter) =>
      filter.id === filterId ? { ...filter, value: undefined } : filter,
    );
    this.props.onChange(this.rawFilters);
  }

  @action.bound public clearAllFilters() {
    this.filters = this.filters.map((f) => ({ ...f, value: undefined }));
    this.props.onChange(this.rawFilters);
  }

  @action.bound public onKeyPressEnter(event: any) {
    event && event.keyCode === 13 && this.setFilterValue();
  }

  getFilterDisplayValue(filter: Filter) {
    let value = filter?.value;
    if (!value) return '';

    if (value.constructor === Array) return `(${value.length})`;
    return value;
  }

  componentDidUpdate() {
    if (JSON.stringify(`${this.filters}`) !== JSON.stringify(`${this.props.filters}`)) {
      this.filters = this.props.filters;
    }
  }

  componentDidMount() {
    this.props.defaultValue && this.setDefaultValue(this.props.defaultValue);
  }

  componentWillUnmount(): void {
    this.disposers.map((disposer) => disposer());
  }

  render() {
    const { classes } = this.props;
    const mobileView = rootStore.uiStore.mobileView;
    const hasFilters = !!this.filters.length;
    const renderFilters = hasFilters || (mobileView && this.props.externalDateRange);
    const showActiveFiltersIcon = !!this.activeFilters.length || this.dateRangeValue.type !== 'all';

    return (
      <Box display="flex" flexDirection="row" className={classes.boxPaper}>
        {renderFilters && (
          <Paper className={classes.paperBoxShadow} style={{ width: '100%' }}>
            <Box className={classes.root}>
              {/* Filter select, lists only non-active filter: */}
              {hasFilters && !mobileView && (
                <Select
                  disabled={this.filters.length === 0}
                  className={classes.select}
                  displayEmpty
                  native={false}
                  onChange={this.selectFilter}
                  disableUnderline
                  MenuProps={{
                    anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
                    transformOrigin: { vertical: 'top', horizontal: 'left' },
                    getContentAnchorEl: null,
                    style: {
                      visibility: mobileView ? 'hidden' : 'visible',
                    },
                  }}
                  value=""
                  renderValue={() => (
                    <Chip
                      clickable={false}
                      className={clsx(classes.filterChip, classes.addFilterChip)}
                      icon={<FontAwesomeIcon icon={faPlus} fontSize={18} />}
                      label={<Typography className={classes.selectLabel}>Add a filter</Typography>}
                    />
                  )}>
                  {this.visibleFilters.map((filter) => (
                    <MenuItem key={filter.id} value={filter.id}>
                      {filter.display}
                    </MenuItem>
                  ))}
                </Select>
              )}

              {mobileView && (
                <Chip
                  clickable={false}
                  className={clsx(classes.filterChip, classes.addFilterChip)}
                  onClick={this.openFiltersMenu}
                  label={<Typography className={classes.selectLabel}>Filters</Typography>}
                />
              )}

              <MobileViewWrapperDialog {...this.mobileWrapperDialogProps}>
                <>
                  {this.mobileWrapperDialogStep === EMobileWrapperDialogStep.FILTERS && (
                    <List
                      sx={{
                        listStyleType: 'none',
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 2,
                      }}>
                      {hasFilters && (
                        <ListButton
                          onClick={() =>
                            this.setMobileWrapperDialogStep(EMobileWrapperDialogStep.ADD_FILTER)
                          }
                          secondaryAction={{
                            children: this.showClearFilters && (
                              <Button
                                variant="text"
                                size="small"
                                color="primary"
                                style={{ float: 'right' }}
                                className={classes.clearFilters}
                                onClick={this.clearAllFilters}>
                                Clear All ({this.activeFilters?.length})
                              </Button>
                            ),
                          }}
                          startIcon={{ icon: faPlus }}
                          endIcon={{ hide: true }}>
                          Add a filter
                        </ListButton>
                      )}

                      {this.props.externalDateRange && (
                        <ListButton
                          onClick={() =>
                            this.setMobileWrapperDialogStep(
                              EMobileWrapperDialogStep.ADD_DATE_FILTER,
                            )
                          }
                          startIcon={{ icon: faCalendar }}
                          endIcon={{ icon: faCaretDown, dark: true }}>
                          {this.rangeType &&
                            (!(this.rangeType.type === 'date-timer') ? (
                              <>{this.rangeType.label}</>
                            ) : (
                              <>
                                {moment(this.dateRangeValue.fromDate).format(
                                  EDateFormat.FULL_MONTH_DAY,
                                )}{' '}
                                -{' '}
                                {moment(this.dateRangeValue.toDate).format(
                                  EDateFormat.FULL_MONTH_DAY,
                                )}
                              </>
                            ))}
                        </ListButton>
                      )}
                    </List>
                  )}

                  {this.mobileWrapperDialogStep === EMobileWrapperDialogStep.ADD_FILTER && (
                    <>
                      <List sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                        {this.visibleFilters.map((filter) => {
                          const selectFilter = () => {
                            this.setFilter(filter.id);
                            this.setMobileWrapperDialogStep(EMobileWrapperDialogStep.FILTER);
                          };
                          return (
                            <ListButton
                              key={filter.id}
                              onClick={selectFilter}
                              secondaryAction={{
                                children: this.getFilterDisplayValue(filter),
                              }}>
                              {filter.display}
                            </ListButton>
                          );
                        })}
                      </List>
                      {this.showClearFilters && (
                        <Button
                          variant="text"
                          size="small"
                          color="primary"
                          style={{ float: 'right' }}
                          className={classes.clearFilters}
                          onClick={this.clearAllFilters}>
                          Clear All ({this.activeFilters?.length})
                        </Button>
                      )}
                    </>
                  )}

                  {this.mobileWrapperDialogStep &&
                    [
                      EMobileWrapperDialogStep.ADD_DATE_FILTER,
                      EMobileWrapperDialogStep.DATE_FILTER,
                    ].includes(this.mobileWrapperDialogStep) && (
                      <MobileDateRangePicker
                        value={this.dateRangeValue}
                        onChange={this.onDateRangeValueChange}
                        handleOpen={this.handleOpenMobileDateRangePicker}
                        externalDateRange={this.props.externalDateRange}
                        mobileWrapperDialogStep={this.mobileWrapperDialogStep}
                      />
                    )}

                  {/* Active filters: */}
                  {!mobileView &&
                    this.filters
                      .filter((f) => f.value)
                      .map((f) => {
                        let value = '';
                        if (f.items) {
                          value = f.items.filter((i: FilterItem) => f.value === i.value)[0].label;
                        } else if (f.fields) {
                          value = Object.values(f.value).join(' - ');
                        } else if (Array.isArray(f.value)) {
                          value = `"${f.value.length} item${f.value.length > 1 ? 's' : ''}"`;
                        } else if (f.interval) {
                          if (f.value.type && f.value.type !== 'date-timer') {
                            const option = findOptionDateByType(f.value.type);
                            value = option
                              ? option?.label
                              : `"${moment(f.value[f.interval.from.value]).format(
                                  'LLL',
                                )} > ${moment(f.value[f.interval.to.value]).format('LLL')}"`;
                          } else {
                            value =
                              f.interval.type === 'date'
                                ? `"${moment(f.value[f.interval.from.value]).format(
                                    'LLL',
                                  )} > ${moment(f.value[f.interval.to.value]).format('LLL')}"`
                                : `"${f.value[f.interval.from.value]} ${
                                    f.value[f.interval.to.value]
                                      ? ` > ${f.value[f.interval.to.value]}`
                                      : ''
                                  }"`;
                          }
                        } else if (
                          typeof f.value === 'object' &&
                          Object.keys(f.value).length === 1
                        ) {
                          const val = Object.entries(f.value);
                          value = `${val[0][1]}`;
                        } else {
                          value = `"${f.value}"`;
                        }

                        const open = () => {
                          this.selectedFilter = f;
                          this.filterValue = f.value;
                        };
                        const remove = () => this.removeFilter(f.id);
                        return (
                          <Chip
                            key={f.id}
                            className={classes.filterChip}
                            label={`${f.display}: ${value}`}
                            color="primary"
                            onClick={open}
                            onDelete={remove}
                            deleteIcon={<Close className={classes.closeIconStyles} />}
                          />
                        );
                      })}

                  {/* Filter details panel: */}
                  {this.selectedFilter && (
                    <FilterItemsWrapper
                      classes={classes}
                      filterValue={this.filterValue}
                      selectedFilter={this.selectedFilter}
                      setFilterValue={this.setFilterValue}
                      mobileView={mobileView}
                      clearSelectedFilter={this.clearSelectedFilter}>
                      <Box>
                        {this.selectedFilter.type === 'text' && (
                          <OutlinedInput
                            value={this.filterValue || ''}
                            onChange={this.updateInputValue}
                            label={this.selectedFilter.label}
                            fullWidth
                            inputRef={(input) => {
                              setTimeout(() => {
                                input && input.focus();
                              }, 100);
                            }}
                            onKeyDown={(event) => this.onKeyPressEnter(event)}
                          />
                        )}

                        {this.selectedFilter.type === 'fields' && this.selectedFilter.fields && (
                          <Box
                            style={{
                              display: 'flex',
                              flexDirection: 'column',
                              gap: '2rem',
                            }}>
                            {this.selectedFilter.fields.map((field) => {
                              let fieldValue = '';

                              if (this.filterValue && this.filterValue[field.id]) {
                                fieldValue = this.filterValue[field.id];
                              } else if (this.tempValue && this.tempValue[field.id]) {
                                fieldValue = this.tempValue[field.id];
                              }

                              return (
                                <OutlinedInput
                                  value={fieldValue}
                                  onChange={this.updateFildsValue}
                                  label={field.label}
                                  name={field.id}
                                  fullWidth
                                  key={field.id}
                                />
                              );
                            })}
                          </Box>
                        )}

                        {this.selectedFilter.type === 'tags' && (
                          <TagInputComponent
                            options={this.selectedFilter.options}
                            selectedTags={this.updateInputItems}
                            tags={this.filterValue}
                            inputRef={(input: any) => {
                              setTimeout(() => {
                                input && input.focus();
                              }, 100);
                            }}
                            placeholder={'add'}
                            label={this.selectedFilter.label}
                            onKeyDown={(event: React.KeyboardEvent<HTMLDivElement>) =>
                              this.onKeyPressEnter(event)
                            }
                          />
                        )}

                        {this.selectedFilter.type === 'select' && (
                          <FormControl component="fieldset">
                            <RadioGroup
                              value={this.filterValue || ' '}
                              onChange={this.updateInputValue}
                              onKeyDown={(event) => this.onKeyPressEnter(event)}>
                              {this.selectedFilter.items &&
                                this.selectedFilter.items.map((item: FilterItem) => (
                                  <FormControlLabel
                                    className={classes.formControlLabel}
                                    key={item.label}
                                    value={item.value}
                                    control={<Radio className={classes.radio} color="primary" />}
                                    label={item.label}
                                  />
                                ))}
                            </RadioGroup>
                          </FormControl>
                        )}

                        {this.selectedFilter.type === 'autocomplete' && (
                          <Autocomplete
                            options={this.selectedFilter.items || []}
                            getOptionSelected={(option, value) => option.value === value.label}
                            getOptionLabel={(o) => o.label || ''}
                            onChange={(e, i: FilterItem | null) => {
                              if (i) {
                                this.filterValue = i.value;
                              } else {
                                this.filterValue = undefined;
                              }
                            }}
                            onKeyDown={(event) => this.onKeyPressEnter(event)}
                            renderInput={(params) => (
                              <OutlinedInput
                                {...params}
                                fullWidth
                                label={this.selectedFilter!.value}
                              />
                            )}
                          />
                        )}

                        {this.selectedFilter.interval && this.selectedFilter.type === 'range' && (
                          <FieldRangeComponent
                            interval={this.selectedFilter.interval}
                            value={this.filterValue}
                            onChange={this.updateRangeValue}
                            setFilterValue={this.setFilterValue}
                            label={this.selectedFilter.label}
                            fullWidth
                          />
                        )}

                        {this.selectedFilter.type === 'date' && (
                          <> {this.selectedFilter.type} :: Cooming soon</>
                        )}
                      </Box>
                    </FilterItemsWrapper>
                  )}
                </>
              </MobileViewWrapperDialog>

              {/* Clear all filters button: */}
              {!mobileView && this.showClearFilters && (
                <Button
                  variant="text"
                  size="small"
                  color="primary"
                  className={classes.clearFilters}
                  onClick={this.clearAllFilters}>
                  Clear all filters
                </Button>
              )}

              {mobileView && (
                <Box display={'flex'} alignItems={'center'} gridGap={8}>
                  {showActiveFiltersIcon && (
                    <FontAwesomeIcon icon={faCircle} className={classes.circleIcon} />
                  )}
                  <ButtonBase onClick={this.openFiltersMenu}>
                    <FontAwesomeIcon icon={faChevronRight} fontSize={18} />
                  </ButtonBase>
                </Box>
              )}
            </Box>
          </Paper>
        )}

        <ExternalDateRangePicker
          classes={classes}
          externalDateRange={this.props.externalDateRange}
          hide={mobileView || !this.props.externalDateRange}
        />

        {(this.props.locationMap || this.props.locationMap === null) && (
          <Paper className={classes.paperBoxShadow} classes={{ root: classes.paperRoot }}>
            <Box className={classes.root}>
              <Tooltip
                title={this.props.locationMap === null ? 'Locations list' : 'Locations map'}
                placement="top"
                enterDelay={500}>
                <IconButton
                  color="default"
                  size="small"
                  component={RouterLink}
                  to={this.props.locationMap === null ? paths.locations() : paths.locationMap()}>
                  {this.props.locationMap === null ? (
                    <FormatListBulleted fontSize="small" />
                  ) : (
                    <Map fontSize="small" />
                  )}
                </IconButton>
              </Tooltip>
            </Box>
          </Paper>
        )}

        {this.props.graph && (this.props.graph.isActive || this.props.graph.isActive === null) && (
          <Paper
            className={clsx(
              this.props.graph.isActive ? classes.paperBoxShadowActive : classes.paperBoxShadow,
              classes.graphButton,
              classes.root,
            )}
            classes={{ root: classes.paperRoot }}
            onClick={() => {
              this.props.graph?.onActive();
            }}>
            <Tooltip
              title={this.props.graph.isActive ? 'Hide graph' : 'Show graph'}
              placement="top"
              enterDelay={500}>
              <FontAwesomeIcon icon={faChartLine} fontSize={23} />
            </Tooltip>
          </Paper>
        )}
      </Box>
    );
  }
}

interface IExternalDateRangePickerProps {
  classes: any;
  externalDateRange: FilterBarProps['externalDateRange'];
  hide?: boolean;
}
const ExternalDateRangePicker = ({
  classes,
  externalDateRange,
  hide,
}: IExternalDateRangePickerProps) => {
  if (!externalDateRange || hide) return null;
  return (
    <Paper
      className={clsx(classes.externalDateRange, classes.paperBoxShadow)}
      classes={{ root: classes.paperRoot }}>
      <Box className={classes.root}>
        <DateRangePicker
          onChange={externalDateRange.onChange}
          predefinedRange={externalDateRange.predefined}
          // isIntegrated
          isFormField
        />
      </Box>
    </Paper>
  );
};

interface IFilterItemsWrapperProps {
  children: React.ReactNode;
  mobileView?: boolean;
  classes: any;
  filterValue: FilterBar['filterValue'];
  selectedFilter: FilterBar['selectedFilter'];
  setFilterValue: FilterBar['setFilterValue'];
  clearSelectedFilter: FilterBar['clearSelectedFilter'];
}

const FilterItemsWrapper = ({
  children,
  classes,
  filterValue,
  selectedFilter,
  setFilterValue,
  clearSelectedFilter,
  mobileView,
}: IFilterItemsWrapperProps) => {
  if (!selectedFilter) return null;
  if (mobileView) {
    return (
      <Box display={'flex'} flexDirection={'column'} height={'100%'}>
        {children}
        <Button
          style={{ marginTop: 'auto' }}
          disabled={!filterValue}
          onClick={setFilterValue}
          variant="contained">
          Apply
        </Button>
      </Box>
    );
  } else {
    return (
      <ClickAwayListener onClickAway={clearSelectedFilter}>
        <Paper className={classes.filterPanel}>
          <Box
            p={2}
            className={classes.filterPanelHeader}
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between">
            <Typography className={classes.headerTitle}>{selectedFilter.display}</Typography>
            <IconButton className={classes.closeIcon} size="small" onClick={clearSelectedFilter}>
              <Close className={classes.closeIconStyles} />
            </IconButton>
          </Box>
          <Box p={2} pt={3}>
            {children}
            <Box mt={4} display="flex" flexDirection="row" justifyContent="flex-end">
              <DialogButton
                type="submit"
                variant="contained"
                disabled={!filterValue}
                onClick={setFilterValue}>
                APPLY
              </DialogButton>
            </Box>
          </Box>
        </Paper>
      </ClickAwayListener>
    );
  }
};

interface IMobileDateRangePickerProps {
  externalDateRange?: FilterBarProps['externalDateRange'];
  value: DateRange;
  onChange: (range: DateRange) => void;
  handleOpen: (open: boolean) => void;
  mobileWrapperDialogStep: EMobileWrapperDialogStep;
}
const MobileDateRangePicker = ({
  externalDateRange,
  value,
  mobileWrapperDialogStep,
  onChange,
  handleOpen,
}: IMobileDateRangePickerProps) => {
  const [viewMode, setViewMode] = useState(ECustomPickerViewMode.MENU);
  useEffect(() => {
    if (mobileWrapperDialogStep === EMobileWrapperDialogStep.ADD_DATE_FILTER)
      setViewMode(ECustomPickerViewMode.MENU);
    else if (mobileWrapperDialogStep === EMobileWrapperDialogStep.DATE_FILTER)
      setViewMode(ECustomPickerViewMode.DATE_TIMER);
  }, [mobileWrapperDialogStep]);

  const handleOnChange = (range: DateRange) => {
    const _onChange = externalDateRange?.onChange;
    if (range.type == 'date-timer') {
      const value = {
        fromDate: moment(range.fromDate).format(isoDateFormat),
        toDate: moment(range.toDate).format(isoDateFormat),
        type: range.type,
      };
      _onChange && _onChange(value);
      onChange(value);
    } else {
      _onChange && _onChange(range);
      onChange(range);
    }
  };

  if (!externalDateRange) return null;

  return (
    <CustomPicker
      onChange={handleOnChange}
      handleOpened={handleOpen}
      selected={value}
      viewMode={viewMode}
    />
  );
};

const _FilterBar = observer((props: FilterBarProps) => {
  const mobileView = rootStore?.uiStore.mobileView;
  return <FilterBar classes={styles({ mobileView })} {...props} />;
});

export default _FilterBar;
