import { Theme, makeStyles } from '@material-ui/core';
import { mobileSize } from 'components/MobileView/useMobileView';

interface StyleProps {
  mobileView?: boolean;
}
export default makeStyles<Theme, StyleProps>((theme) => ({
  root: {
    cursor: 'pointer',
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    flexWrap: 'wrap',
    gap: theme.spacing(2),
    justifyContent: ({ mobileView }) => (mobileView ? 'space-between' : 'flex-start'),
    padding: ({ mobileView }) => theme.spacing(1.5, mobileView ? 2 : 3),
  },
  boxPaper: {
    marginBottom: theme.spacing(3),
    gap: theme.spacing(2),
  },
  paperRoot: {
    minHeight: 56,
    alignSelf: 'flex-start',
  },
  paperBoxShadow: {
    boxShadow: 'none',
  },
  select: {
    border: 'none',
    height: 'auto',
    padding: 0,
    borderRadius: 0,
    '& .MuiSelect-icon': {
      display: 'none',
    },
    '& .MuiSelect-root': {
      backgroundColor: 'transparent !important',
      padding: 0,
    },
    '&.MuiInput-root': {
      paddingBottom: '0 !important',
    },
  },
  selectLabel: {
    color: theme.palette.text.primary,
  },
  filterPanel: {
    position: 'absolute',
    top: 52,
    left: 18,
    zIndex: 10,
    overflow: 'hidden',
    minWidth: theme.spacing(40),
    maxWidth: theme.spacing(87.5),
    filter: 'drop-shadow(0px 6px 24px #DDDDDD)',
  },
  filterPanelHeader: {
    backgroundColor: theme.palette.primary.main,
  },
  headerTitle: {
    color: theme.palette.text.primary,
    fontSize: 24,
  },
  closeIconStyles: {
    width: 22,
    height: 22,
    paddingRight: theme.spacing(0.5),
  },
  closeIcon: {
    color: theme.palette.text.primary,
  },
  addFilterChip: {
    backgroundColor: 'transparent',
    '& .MuiChip-icon': {
      marginLeft: 0,
      color: theme.palette.text.primary,
    },
  },
  filterChip: {
    cursor: 'pointer',
    '& .MuiChip-label': {
      fontSize: '14px',
      fontWeight: 400,
      color: theme.palette.text.primary,
      [theme.breakpoints.down(mobileSize)]: {
        paddingLeft: 0,
      },
    },
    '& .MuiChip-deleteIconColorPrimary': {
      color: theme.palette.text.primary,
    },
  },
  clearFilters: {
    textTransform: 'initial',
    fontSize: theme.typography.body1.fontSize,
  },
  paperBoxShadowActive: {
    boxShadow: 'none',
    backgroundColor: theme.palette.primary.main,
    color: 'white',
  },
  graphButton: {
    color: theme.palette.text.primary,
    justifyContent: 'center !important',
  },
  radio: {
    paddingLeft: theme.spacing(3),
  },
  formControlLabel: {
    '& .MuiFormControlLabel-label': {
      color: theme.menu.main,
    },
  },
  externalDateRange: {
    marginLeft: 'auto',
    display: 'flex',
  },
  circleIcon: {
    fontSize: 8,
    color: theme.palette.primary.main,
  },
}));
