import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Link,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { WithStyles, withStyles } from '@material-ui/core/styles';
import Api, { ApiResponse, getErrorMsg } from 'api';
import { AxiosResponse } from 'axios';
import DP from 'components/DashPanel';
import theme, { colors } from 'containers/App/theme';
import { Cancel, Close, CreditCardRefund, History } from 'mdi-material-ui';
import { action, computed, flow, observable, makeObservable } from 'mobx';
import { observer } from 'mobx-react';
import * as models from 'models';
import moment from 'moment';
import React from 'react';
import { inject, WithModalStore, WithToastStore, WithUserStore } from 'stores';
import RefundForm from './RefundForm';
import styles, { invoiceStyling } from './styles';
import { EmptyPanelMessage } from 'components/EmptyPanelMessage/EmptyPanelMessage';
import { ACL } from 'types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowsRotate, faCheckCircle } from '@fortawesome/pro-regular-svg-icons';
import { faEye } from '@fortawesome/pro-regular-svg-icons';
import { paths } from 'routes';
import { Checkbox } from 'components/Checkbox/Checkbox';
import { default as TippyDialog } from 'components/Dialog/Dialog';
import api from 'api';

interface RefundingInvoice {
  id: number;
  gross: string;
  invoiceNumber: string;
}

interface RefundResponse {
  invoiceId: number;
  gross: string;
  net: string;
  status: 'completed' | 'failed';
  tax: string;
}

interface InvoicesPanelProps
  extends WithStyles<typeof styles>,
    WithUserStore,
    WithToastStore,
    WithModalStore {
  accountId: number;
  invoices: models.Invoice[];
  fetchingInvoices: boolean;
  getInvoices: VoidFunction;
  onVoidSuccess?: VoidFunction;
}

/**
 * A panel displaying all the invoices for an account.
 */
@inject('toastStore', 'modalStore', 'userStore')
@observer
class InvoicesPanel extends React.Component<InvoicesPanelProps> {
  constructor(props: InvoicesPanelProps) {
    super(props);
    makeObservable(this);
  }

  private panelName = 'Invoices';

  /** The invoices are stored here */
  @observable public invoices?: models.Invoice[];

  @observable public inProgress = false;

  @observable public openVoidInvoiceDialog = false;

  @observable public selectedInvoice: models.Invoice | undefined;

  @observable public revertBillingCycles = true;

  @observable public regenerating = false;

  @observable public openRegenerateInvoiceDialog = false;

  /**
   * Invoice object that holds data for refund invoice modal.
   * If object is not null we show refund modal.
   */
  @observable public refundingInvoice: RefundingInvoice | null = null;
  @observable public refundResponse: RefundResponse | null = null;

  /** Open refund modal by setting refunding tip */
  @action.bound public setRefundingInvoice = (invoice: models.Invoice) => {
    this.refundingInvoice = {
      id: invoice.id,
      gross: invoice.availableRefundAmountValue,
      invoiceNumber: invoice.number,
    };
  };

  @action.bound public unsetRefundingInvoice = () => {
    this.refundingInvoice = null;
    this.refundResponse = null;
  };

  @action.bound setRevertBillingCycles(e: React.ChangeEvent<HTMLInputElement>) {
    this.revertBillingCycles = e.target.checked;
  }

  /** Void an invoice. This operation requires `void_invoice` ACL permission */
  @action.bound public voidInvoice = async () => {
    if (!this.selectedInvoice) {
      this.props.toastStore?.error('Seems like we could not obtain invoice data');
      return;
    }

    try {
      this.inProgress = true;
      await Api.billing.voidInvoice(this.selectedInvoice.id, {
        revertBillingCycles:
          this.selectedInvoice.creationSource === models.EInvoiceSource.INACTIVITY
            ? false
            : this.revertBillingCycles,
      });
      this.props.getInvoices();
      this.props.onVoidSuccess && this.props.onVoidSuccess();
      this.props.toastStore!.success(`Invoice was successfully voided`);
    } catch (e: any) {
      this.props.toastStore!.error(getErrorMsg(e));
    } finally {
      this.inProgress = false;
      this.setCloseVoidInvoice();
    }
  };

  /** Refund an invoice. This operation requires `issue_invoice_refund` ACL permission */
  @action.bound public refundInvoice = flow(function* (
    this: InvoicesPanel,
    amount: number,
    reason: string,
  ) {
    try {
      if (this.refundingInvoice) {
        this.inProgress = true;
        const resp: AxiosResponse<ApiResponse<RefundResponse>> = yield Api.billing.refundInvoice(
          this.refundingInvoice.id,
          amount,
          reason,
        );
        if (resp.data.data) {
          this.refundResponse = resp.data.data;
        }
        this.props.getInvoices();
      }
    } catch (e: any) {
      this.props.toastStore!.error(getErrorMsg(e));
    } finally {
      this.inProgress = false;
    }
  });

  @action.bound setOpenVoidInvoice(invoice: models.Invoice) {
    this.selectedInvoice = invoice;
    this.openVoidInvoiceDialog = true;
    this.revertBillingCycles = true;
  }

  @action.bound setCloseVoidInvoice() {
    this.selectedInvoice = undefined;
    this.revertBillingCycles = true;
    this.openVoidInvoiceDialog = false;
  }

  /** The invoice count  */
  @computed public get invoiceCount(): number | undefined {
    return this.props.invoices && this.props.invoices.length;
  }

  renderStatusIcon(i: models.Invoice) {
    const classes = this.props.classes;
    return (
      <Tooltip title={i.status.replace(/_/g, ' ')} classes={{ tooltip: classes.tooltip }}>
        {(() => {
          switch (i.status) {
            case 'open':
              return <History style={{ color: colors.amber }} />;
            case 'captured':
              return (
                <FontAwesomeIcon
                  icon={faCheckCircle}
                  fontSize={24}
                  style={{ color: theme.palette.primary.main }}
                />
              );
            case 'failed':
              return <Close style={{ color: theme.palette.error.main }} />;
            case 'void':
              return <Cancel style={{ color: theme.palette.error.main }} />;
            case 'refunded':
              return <CreditCardRefund style={{ color: colors.amber }} />;
            case 'partially_refunded':
              return <CreditCardRefund style={{ color: colors.amber }} />;
            default:
              return <History style={{ color: colors.amber }} />;
          }
        })()}
      </Tooltip>
    );
  }

  @action.bound setOpenRegenerateInvoiceDialog() {
    this.openRegenerateInvoiceDialog = true;
  }

  @action.bound setCloseRegenerateInvoiceDialog() {
    this.openRegenerateInvoiceDialog = false;
  }

  @action.bound regenerateInvoice = async () => {
    try {
      this.regenerating = true;
      await api.billing.regenerateInvoices(this.props.accountId);
      this.props.getInvoices();
      this.props.onVoidSuccess && this.props.onVoidSuccess();
      this.props.toastStore?.success('Regenereate invoices request successful');
    } catch (error) {
      this.props.toastStore?.error(getErrorMsg(error));
    } finally {
      this.regenerating = false;
      this.setCloseRegenerateInvoiceDialog();
    }
  };

  @computed get numberOfInvoices() {
    if (this.props.invoices) {
      return this.props.invoices.length;
    } else {
      return 0;
    }
  }

  getSecondaryText = (canRefund: boolean, invoice: models.Invoice) => {
    const showRefundAmount = canRefund && invoice.refundedAmountValue !== '0';
    const secodaryText = `${invoice.gross} ${
      showRefundAmount ? `| Refunded: ${invoice.refundedAmount}` : ''
    } | Created on ${moment(invoice.createdAt).format('ll')}`;
    return secodaryText;
  };

  @computed get voidModalContent() {
    if (this.selectedInvoice?.creationSource === models.EInvoiceSource.INACTIVITY) {
      return {
        title: 'Confirmation',
        content: <Box>Are you sure you want to void invoice "{this.selectedInvoice.number}"?</Box>,
      };
    } else {
      return {
        title: 'Void Invoice',
        content: (
          <>
            <Typography variant={'body2'}>
              Voiding this invoice will cancel the current billing cycles. If you select ‘Revert
              billing cycle’, the cycles will be reset to the last renewal date, and any charges or
              credits applied during this period will be reversed. If you want to remove or add a
              license, or change the payment method for the billing group to which the licenses are
              attached, you should do so before tomorrow, when the invoice will be recreated for the
              period starting from the renewal date to which the billing cycles were reverted.
            </Typography>
            <Box mt={3} display={'flex'} alignItems={'center'}>
              <Checkbox
                onChange={this.setRevertBillingCycles}
                disabled={this.inProgress}
                disablePadding
                checked={this.revertBillingCycles}
              />
              <Box ml={1} component={'span'}>
                Revert billing cycles
              </Box>
            </Box>
          </>
        ),
      };
    }
  }

  /** Fetch the invoices on mount */
  componentDidMount() {
    this.props.getInvoices();
  }

  render() {
    const canRegenerate = this.props.userStore!.hasPermission(ACL.REGENERATE_INVOICES);

    return (
      <DP>
        <DP.Header>
          <DP.Title count={this.invoiceCount} light>
            {this.panelName}
          </DP.Title>
          {canRegenerate && (
            <DP.Actions>
              <Tooltip title="Regenerate Invoices">
                <Box component={'span'}>
                  <DP.IconButton
                    primary
                    loading={this.regenerating}
                    fontAwesomeIcon={{ icon: faArrowsRotate }}
                    onClick={this.setOpenRegenerateInvoiceDialog}
                  />
                </Box>
              </Tooltip>
            </DP.Actions>
          )}
        </DP.Header>

        {this.inProgress || this.props.fetchingInvoices ? (
          <DP.List>
            <DP.Loading items={5} variant="circleWithTwoLines" />
          </DP.List>
        ) : this.props.invoices && this.props.invoices.length > 0 ? (
          <DP.List height={this.numberOfInvoices < 5 ? 'short' : 'normal'}>
            {this.props.invoices.map((invoice) => {
              // Invoice can be either voided OR refunded
              const canVoid =
                invoice.status === 'open' && this.props.userStore!.hasPermission(ACL.VOID_INVOICE);
              const canRefund =
                (invoice.status === 'captured' || invoice.status === 'partially_refunded') &&
                this.props.userStore!.hasPermission(ACL.ISSUE_INVOICE_REFUND);

              let menu;
              if (canVoid) {
                menu = [
                  {
                    label: 'Void',
                    onClick: () => this.setOpenVoidInvoice(invoice),
                  },
                ];
              } else if (canRefund) {
                menu = [
                  {
                    label: 'Refund',
                    onClick: () => this.setRefundingInvoice(invoice),
                  },
                ];
              }

              const secondaryText = (
                <Typography variant="subtitle1">
                  {this.getSecondaryText(canRefund, invoice)}
                </Typography>
              );

              return (
                <DP.ListItem
                  icon={this.renderStatusIcon(invoice)}
                  key={invoice.number}
                  primary={
                    <Box sx={invoiceStyling}>
                      <Box component="span" className="invoice-number">
                        {invoice.number}
                      </Box>
                      <Link
                        component="a"
                        href={paths.sales().cartsCompletedExternal(String(invoice.identifier))}>
                        <IconButton size="small">
                          <FontAwesomeIcon icon={faEye} className="invoice-icon" />
                        </IconButton>
                      </Link>
                    </Box>
                  }
                  secondary={secondaryText}
                  menu={menu}
                />
              );
            })}
          </DP.List>
        ) : (
          <DP.Body>
            <Box display={'flex'} alignContent={'center'}>
              <EmptyPanelMessage panelTitle={this.panelName} />
            </Box>
          </DP.Body>
        )}

        {this.refundingInvoice && (
          <Dialog open={Boolean(this.refundingInvoice)} onClose={this.unsetRefundingInvoice}>
            <Box minWidth={380}>
              <DialogTitle>
                <Typography style={{ fontSize: 28, fontWeight: 400 }}>
                  Issuing refund for invoice number {this.refundingInvoice.invoiceNumber}
                </Typography>
              </DialogTitle>
              {this.inProgress ? (
                <Box display="flex" alignItems="center" justifyContent="center" minHeight="240px">
                  <CircularProgress />
                </Box>
              ) : this.refundResponse ? (
                <DialogContent>
                  <Typography>
                    Refund request successful.
                    <br /> <br />
                    Net: <b>{this.refundResponse.net}</b>
                    <br />
                    Tax: <b>{this.refundResponse.tax}</b>
                    <br />
                    Gross: <b>{this.refundResponse.gross}</b>
                  </Typography>
                  <DialogActions>
                    <Button onClick={this.unsetRefundingInvoice} color="primary">
                      Close
                    </Button>
                  </DialogActions>
                </DialogContent>
              ) : (
                <DialogContent>
                  <RefundForm
                    {...this.refundingInvoice}
                    onSubmit={this.refundInvoice}
                    closeModal={this.unsetRefundingInvoice}
                  />
                </DialogContent>
              )}
            </Box>
          </Dialog>
        )}

        <TippyDialog
          open={this.openVoidInvoiceDialog}
          loading={this.inProgress}
          title={this.voidModalContent.title}
          content={this.voidModalContent.content}
          confirmActionName={'Void'}
          onConfirm={this.voidInvoice}
          onCancel={this.setCloseVoidInvoice}
          onClose={this.setCloseVoidInvoice}
        />

        <TippyDialog
          open={this.openRegenerateInvoiceDialog}
          title={'Regenerate Invoices'}
          loading={this.regenerating}
          content={
            <Box>
              <Typography variant="body2">
                Are you sure you want to regenerate all invoices?
              </Typography>
              <br />
              <Typography variant="body2">
                This action will recalculate all outstanding charges and create updated invoices
                based on the current billing information.
              </Typography>
            </Box>
          }
          confirmActionName="Regenerate"
          onConfirm={this.regenerateInvoice}
          onCancel={this.setCloseRegenerateInvoiceDialog}
        />
      </DP>
    );
  }
}

export default withStyles(styles)(InvoicesPanel);
