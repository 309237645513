import React from 'react';
import { Route, RouteProps, Redirect } from 'react-router-dom';
import { Observer } from 'mobx-react';
import { inject } from 'stores';

import UserStore from 'stores/UserStore';
import qs from 'qs';
import { Location } from 'history';

interface AnonRouteProps extends RouteProps {
  userStore?: UserStore;
  location?: Location<any>;
}

/**
 * Same as Route, only makes sure that the current user isn't logged in.
 * Useful for routes that make sense only for non logged-in users.
 */
@inject('userStore')
export default class AnonRoute extends React.Component<AnonRouteProps> {
  render() {
    const { component: Component, userStore, render, location, ...rest } = this.props;
    return (
      <Observer>
        {() => {
          let redirectedFrom = location?.state?.from?.pathname;
          let queryString = location?.state?.from?.search;
          if (userStore!.loggedIn) {
            if (location?.search) {
              const { redirect } = qs.parse(location?.search, {
                ignoreQueryPrefix: true,
              });
              const canRedirect = userStore?.user?.isPhoneConfirmed;
              if (canRedirect && redirect) {
                return <Redirect push to={{ pathname: redirect as unknown as string }} />;
              }
            }
            return (
              <Redirect
                to={{
                  pathname: redirectedFrom || '/',
                  search: queryString || '',
                  state: { from: location },
                }}
              />
            );
          }
          return (
            <Route
              {...rest}
              render={(props: any) => (Component ? <Component {...props} /> : render!(props))}
            />
          );
        }}
      </Observer>
    );
  }
}
