import React from 'react';
import { observer } from 'mobx-react';
import { WithStyles, withStyles } from '@material-ui/core/styles';

import { inject, WithUserStore } from 'stores';

import DashboardLayout from 'containers/DashboardLayout';
import AffiliatesTable from 'components/AffiliatesTable';
import styles from './styles';

import { setTitle } from 'services';
import Title from 'components/Title';
import { RouteComponentProps } from 'react-router-dom';

const PAGE_TITLE = 'Affiliates';

/** Define props for this component */
type AffiliatesProps = WithStyles<typeof styles> & WithUserStore & RouteComponentProps;

/**
 * The Affiliates container, restricted to admin users.
 */
@inject('userStore')
@observer
class Affiliates extends React.Component<AffiliatesProps> {
  public constructor(props: AffiliatesProps) {
    super(props);
  }
  componentDidMount() {
    setTitle(PAGE_TITLE, { noSuffix: false });
  }
  render() {
    return (
      <DashboardLayout>
        <Title mb={3}>{PAGE_TITLE}</Title>
        <AffiliatesTable {...this.props} />
      </DashboardLayout>
    );
  }
}

export default withStyles(styles)(Affiliates);
