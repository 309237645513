export enum DrawerType {
  ADD = 'add',
  QR = 'qr',
  EDIT = 'edit',
}

export enum optionSearchFilter {
  NAME = 'name',
  EMAIL = 'email',
  PHONE = 'phone',
}
/**
 * Tip intent processor (pos)
 */
export enum TipProcessor {
  STX = 'stx',
  ENVISION = 'envision',
  SALON_ULTIMATE = 'salon-ultimate',
  ROSY = 'rosy',
  MEEVO = 'meevo',
  DINGG = 'dingg',
}
