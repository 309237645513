import React from 'react';
import { NavLink as RouterLink, useRouteMatch } from 'react-router-dom';
import { Box, ListItem, Typography } from '@material-ui/core';

import { useStores } from 'containers/App/App';
import useStyles from './styles';
import MenuIcon from './MenuIcon/MenuIcon';
import { IconDefinition } from '@fortawesome/pro-regular-svg-icons';
import clsx from 'clsx';

interface MenuItemProps {
  icon: IconDefinition;
  to: string;
  label: string;
  showIcon?: boolean;
  paths?: Array<string>;
}

/**
 * A single menu item on the sidebar.
 * @param icon The material-ui string icon
 * @param to Where the menu item should link to
 * @param label The label on the menu item
 */
const MenuItem = ({ icon, to, paths = [], label, showIcon = true }: MenuItemProps) => {
  const { uiStore } = useStores();
  const isMatch = useRouteMatch({ path: [to, ...paths], exact: true });
  const menuOpen = uiStore.drawer.open;
  const mobileView = uiStore.mobileView;
  const hide = !mobileView && !menuOpen;
  const { item, activeItem, listItem } = useStyles({ menuOpen, mobileView });
  const dataCy = `page-${label.toLowerCase().replaceAll(' ', '-')}`;
  const closeMenuForMobile = () => mobileView && uiStore?.toggleDrawer();

  return (
    <ListItem className={listItem} onClick={closeMenuForMobile}>
      <RouterLink data-cy={dataCy} className={clsx(item, { [activeItem]: isMatch })} to={to}>
        <MenuIcon showIcon={showIcon} icon={icon} />
        <MenuLabel hide={hide}>{label}</MenuLabel>
      </RouterLink>
    </ListItem>
  );
};

export const MenuLabel = ({ hide, children }: { hide: boolean; children: string }) => {
  if (hide) return null;
  return (
    <Box ml={hide ? 0 : 1} flexGrow={1}>
      <Typography align="left" color="inherit">
        {children}
      </Typography>{' '}
    </Box>
  );
};

export default MenuItem;
