import { observer } from 'mobx-react';
import useFingerprint from './useFingerprint';

const Fingerprint = observer(() => {
  useFingerprint();

  return null;
});

export default Fingerprint;
