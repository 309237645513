import React from 'react';
import { Box } from '@material-ui/core';
import OutlinedInput from 'components/Input/OutlinedInput/OutlinedInput';
import { IntegrationApp } from 'models/Integration';

interface LabelInputProps {
  labels: IntegrationApp['labels'];
  setIntegrationProperties: (key: string, integrationProperties: string) => void;
  setFieldError: (error: boolean) => void;
  fieldError: boolean;
}

function LabelInputs(props: LabelInputProps) {
  const { fieldError, labels, setFieldError, setIntegrationProperties } = props;
  return (
    <Box>
      {labels &&
        Object.entries(labels!).map(([key, value]) => (
          <Box key={key} mt={2}>
            <OutlinedInput
              label={value}
              onChange={(e) => {
                setIntegrationProperties(key, e.target.value);
                e.target.value !== '' && setFieldError(false);
              }}
              error={fieldError}
              helperText={fieldError && 'This field is required'} // TODO: add errorText prop?
              fullWidth
            />
          </Box>
        ))}
    </Box>
  );
}

export default LabelInputs;
