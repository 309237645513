import { AccountUser } from './AccountUser';
import { LocationUser } from './LocationUser';
import { Location } from './Location';
import { Device } from './Device';
import { BankAccount } from './BankAccount';
import { Account } from './Account';

export enum UserTypes {
  TALENT = 'talent',
  MANAGER = 'manager',
  OWNER = 'owner',
}

export enum Profession {
  ASSISTANT = 'Assistant',
  STYLIST = 'Stylist',
  ESTHETICIAN = 'Esthetician',
  NAIL_TECH = 'Nail Technician',
  MASSAGE_THERAPIST = 'Massage Therapist',
  BARBER = 'Barber',
  MAKEUP_ARTIST = 'Makeup Artist',
  COLORIST = 'Colorist',
  OTHER = 'Other',
}

/** Represents a user */
export interface User {
  id: number;
  identifier?: string;
  firstName?: string;
  lastName?: string;
  email: string;
  newEmail?: string;
  phone?: string;
  isAdmin?: boolean;
  isEmailConfirmed: boolean;
  isPhoneConfirmed: boolean;
  address?: string;
  address2?: string;
  city?: string;
  state?: string;
  zip?: string;
  country?: string;
  dob?: string;
  nickname?: string;
  profession?: Profession;
  avatar?: string;
  accountUsers?: AccountUser[];
  locationUsers?: LocationUser[];
  isActive?: boolean;
  accounts?: Account[];
  locations?: Location[];
  bankAccount?: BankAccount;
  permissions?: string[];
  device?: Device;
}

export interface UserLoginResponseV2 {
  /** Authenticated user object */
  user: User;
  /** JWT token field */
  jwt: string;
  /** JWT token field */
  exp: number;
  /** JWT token field */
  iat: number;
  /** refresh token */
  refreshToken: string;
  /** Refresh token expiration time */
  refreshTokenExp: number;
}

export interface ISsoProviderResponse {
  ssoProvider: string;
  loginRedirectUrl: string;
}
