import React from 'react';
import { Box, IconButton, Typography } from '@material-ui/core';
import { useStores } from 'containers/App/App';
import { useStyles } from 'containers/LocationDetails/styles';
import { observer } from 'mobx-react';
import { AddStation } from 'models/Station';
import DD from 'components/DashDrawer';
import { KeyboardArrowRight } from '@mui/icons-material';
import { Skeleton } from '@mui/material';

interface IAddStationUsersSection {
  isLoading?: boolean;
  countUsers: () => number;
}
export const AddStationUsersSection = observer(
  ({ isLoading, countUsers }: IAddStationUsersSection) => {
    const classes = useStyles();
    const { stationsStore } = useStores();
    const { setAddStationState, addStationState } = stationsStore!;

    let open = AddStation.GROUP_USERS;
    if (addStationState === AddStation.ADD) {
      open = AddStation.USERS;
    }

    if (isLoading) {
      return (
        <Box mt={3}>
          <DD.Subtitle>
            {' '}
            <Box component={'span'} style={{ color: 'black' }}>
              Add Users
            </Box>
          </DD.Subtitle>
          <Box
            display={'flex'}
            flexDirection={'row'}
            justifyContent={'space-between'}
            alignItems={'center'}>
            <Skeleton variant="text" width={'150px'} height={'30px'} />
            <Skeleton variant="rectangular" width={'15px'} height={'15px'} />
          </Box>
        </Box>
      );
    }

    return (
      <Box mt={3}>
        <DD.Subtitle>
          {' '}
          <Box component={'span'} style={{ color: 'black' }}>
          <Typography variant="body2">Add Users</Typography>
          </Box>
        </DD.Subtitle>
        <IconButton classes={{ root: classes.editButton }} onClick={() => setAddStationState(open)}>
          <Box style={{fontSize: '16px'}}>{`Users (${countUsers()})`}</Box>
          <KeyboardArrowRight />
        </IconButton>
      </Box>
    );
  },
);
