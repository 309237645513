import theme from 'containers/App/theme';

export const liabilityContainerStyling = {
  marginBottom: theme.spacing(4),
  '& .title': {
    fontSize: 28,
    marginBottom: theme.spacing(3),
  },
};

export const liabilityBoxStyling = {
  display: 'flex',
  gap: theme.spacing(2),
  flexWrap: 'wrap',
  '& .item': {
    backgroundColor: '#fff',
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(2.5, 3),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: 'calc(50% - 8px)',
    },
    [theme.breakpoints.up('md')]: {
      width: 'calc(100% / 3 - 11px)',
    },
    [theme.breakpoints.up('xl')]: {
      width: 'calc(20% - 13px)',
    },
  },
  '& .value': {
    '&.bold': {
      fontWeight: 'bold',
    },
    '&.positive': {
      color: theme.palette.primary.main,
    },
    '&.negative': {
      color: theme.palette.error.main,
    },
  },
  '& .label': {
    color: theme.palette.text.secondary,
    fontSize: 14,
    '&.bold': {
      fontWeight: 'bold',
    },
  },
};
