import { FacebookShareButton, FacebookIcon, LinkedinShareButton, LinkedinIcon } from 'react-share';
import { Box } from '@material-ui/core';
import { Typography } from '@mui/material';
import { referralShareStyles } from './styles';

interface ShareReferralItemsProps {
  url: string;
}

export const ShareReferralItems = ({ url }: ShareReferralItemsProps) => (
  <Box sx={referralShareStyles}>
    <Typography className="share-subtitle">Share your referral link on social media:</Typography>
    <Box className="share-content">
      {/* Facebook */}
      <FacebookShareButton url={url} className="share-btn">
        <FacebookIcon size={34} round />
      </FacebookShareButton>
      {/* Linkedin */}
      <LinkedinShareButton url={url} className="share-btn">
        <LinkedinIcon size={34} round />
      </LinkedinShareButton>
    </Box>
  </Box>
);
