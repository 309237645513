import { SxProps, Theme } from '@mui/material';
import { ITextButtonProps } from './TextButton';

interface ITextButtonStyles extends Pick<ITextButtonProps, 'disableUnderline' | 'disabled'> {
  theme: Theme;
}

const underline = (theme: Theme, disableUnderline?: boolean) => {
  if (disableUnderline) return null;

  return {
    '&::after': {
      content: '""',
      position: 'absolute',
      width: '100%',
      height: 2,
      backgroundColor: theme.palette.primary.main,
      top: `calc(100% + 2px)`,
      left: 0,
      right: 0,
    },
  };
};

const textButtonStyles = ({
  theme,
  disabled,
  disableUnderline,
}: ITextButtonStyles): SxProps<Theme> => {
  return {
    position: 'relative',
    color: disabled ? theme.palette.disabled : theme.palette.primary.main,
    whiteSpace: 'nowrap',
    cursor: disabled ? 'auto' : 'pointer',
    ...underline(theme, disableUnderline),
  };
};

export default textButtonStyles;
