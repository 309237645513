import { IBillingEntity } from 'models';
import { useEffect, useMemo } from 'react';
import { useForm, FieldValues } from 'react-hook-form';
import {
  EFormFields,
  IFormFieldsConfig,
  FORM_TEXT,
  EFormFieldLabels,
  FormContainer,
  FORM_AMOUNT,
} from 'utils/forms';
import { getPaymentMethodField } from '../helpers';

interface WithoutInvoiceFormProps {
  paymentMethods: IBillingEntity[];
  billingEntityId?: number;
  getFormValues: (amount: number, reason: string, billingEntityId: number) => void;
  validityChanged: (isValid: boolean) => void;
}

// Create fields config
const amountField: IFormFieldsConfig = FORM_AMOUNT({
  name: EFormFields.AMOUNT,
  label: EFormFieldLabels.CORRECTION_AMOUNT,
  note: 'To create a negative wallet correction, use a minus sign ("-") before the amount owed. For a positive correction, simply enter the credit amount.',
});
const noteField: IFormFieldsConfig = FORM_TEXT(
  true,
  false,
  EFormFieldLabels.CORRECTION_NOTE,
  EFormFields.NOTE,
);

export const WithoutInvoiceForm = ({
  getFormValues,
  validityChanged,
  billingEntityId,
  paymentMethods,
}: WithoutInvoiceFormProps) => {
  /** Construct Select box with payment methods as options */
  const paymentMethodField = useMemo(() => getPaymentMethodField(paymentMethods), [paymentMethods]);

  /** Init form with default options */
  const {
    formState: { errors, isValid, isValidating },
    control,
    getValues,
  } = useForm<FieldValues>({
    defaultValues: {
      [amountField.name]: '',
      [noteField.name]: '',
      [paymentMethodField.name]: billingEntityId,
    },
    mode: 'onChange',
  });

  useEffect(() => {
    validityChanged(isValid);
  }, [isValid, validityChanged]);

  useEffect(() => {
    getFormValues(
      getValues(EFormFields.AMOUNT),
      getValues(EFormFields.NOTE),
      getValues(EFormFields.BILLING_ENTITY_ID),
    );
  }, [isValidating]);

  return (
    <FormContainer
      formFieldsConfig={[amountField, noteField, paymentMethodField]}
      control={control}
      errors={errors}
      marginBottom={0}
    />
  );
};
