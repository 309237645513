/**
 * Represents the draw status.
 */
export enum DrawStatus {
  CREATED = 'created',
  PENDING = 'pending',
  PROCESSED = 'processed',
  FAILED = 'failed',
  CANCELLED = 'canceled',
  VOIDED = 'voided',
}
