import { Theme, createStyles } from '@material-ui/core';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default (theme: Theme) =>
  createStyles({
    root: {
      '& .MuiPaper-root': {
        width: '546px',
      },
    },
    fabButton: {
      position: 'fixed',
      bottom: theme.spacing(2),
      right: theme.spacing(4),
    },
    datePicker: {
      width: '100%',
    },
    positive: {
      color: theme.palette.primary.main,
    },
    negative: {
      color: theme.palette.error.main,
    },
    title: {
      width: theme.spacing(100),
      fontSize: theme.typography.h4.fontSize,
      fontFamily: theme.typography.h4.fontFamily,
    },
    dialogTitle: { paddingTop: 24, paddingBottom: 16 },
    partnerDrawerDialogActions: {
      padding: theme.spacing(3),
      paddingBottom: theme.spacing(2),
    },
    dialogActions: {
      display: 'flex',
      flexDirection: 'column',
      marginBottom: theme.spacing(1),
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
    },
    formHelperText: {
      marginLeft: theme.spacing(2.625),
    },
  });
