import { Theme, createStyles } from '@material-ui/core';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default (theme: Theme) =>
  createStyles({
    root: {},
    checkContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      gap: theme.spacing(8),
    },
  });

export const checkContainerStyles = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
};
