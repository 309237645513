export enum AccountType {
  CHECKING = 'Checking',
  SAVINGS = 'Savings',
}

/** Defines BankInfo object for creating Dwolla funding source */
export interface BankInfo {
  routingNumber: string;
  accountNumber: string;
  type: AccountType;
  name: string;
}
