import React from 'react';
import { action, computed, makeObservable, observable } from 'mobx';
import { observer } from 'mobx-react';
import { Box } from '@material-ui/core';

import Api, { getErrorMsg } from 'api';
import { inject, WithToastStore } from 'stores';
import { Account } from 'models';

import AutocompleteSearch from 'components/AutocompleteSearch';
import Button from 'components/Button/Button';

interface AccountSearchProps extends WithToastStore {
  onChange: (a: Account | null) => void;
  fetch?: (rmd: string) => Promise<any>;
  label?: string;
  value?: Account;
  placeholder?: string;
  clearSelection?: { onClear?: () => void; clearDelay: number };
  renderOption?: (account: Account) => React.ReactNode;
  selectedAccounts?: Account[]; // Prevent showing the same accounts after they were selected
  className?: string;
  InputProps?: { disableUnderline?: boolean; className?: string };
}

/** A component that displays a field for searching for accounts */
@inject('toastStore')
@observer
class AccountSearch extends React.Component<AccountSearchProps> {
  constructor(props: AccountSearchProps) {
    super(props);
    makeObservable(this);
  }

  /** Gets the label for an account in the autocomplete serach */
  private getAccountOptionLabel(acc?: Account): string {
    if (!acc) {
      // Sometimes we get undefined here, not sure why
      return '';
    } else {
      return acc.name || '';
    }
  }

  /** The user filters */
  @observable public searchByCode = false;

  /** Renders the option for accounts */
  @action.bound private renderAccountOption(acc: Account): React.ReactNode {
    if (this.props.renderOption) {
      return this.props.renderOption(acc);
    }
    return (
      <Box display="flex" flexDirection="row" justifyContent="space-between" width="100%">
        <Box>{acc.name}</Box>
        <Box>{acc.code}</Box>
      </Box>
    );
  }

  /** Fetch the accounts by search string */
  @action.bound public async searchAccounts(search: string): Promise<Account[]> {
    if (search.length === 0) {
      return [];
    }
    try {
      const params = this.searchByCode ? { code: search } : { name: search };

      const searchAccounts = this.getFetchMethod();
      const resp = await searchAccounts({ filters: params });
      if (resp.data) {
        return this.removeSelectedAccounts(resp.data.data);
      } else {
        return [];
      }
    } catch (e: any) {
      this.props.toastStore!.push({ type: 'error', message: getErrorMsg(e) });
      return [];
    }
  }

  @computed public get renderLabel() {
    return this.searchByCode ? 'Company Code' : 'Account Name';
  }

  @computed public get renderButtonLabel() {
    return this.searchByCode ? 'Or search with account name' : 'Or search with company code';
  }

  @action.bound private setSearchBy(): void {
    this.searchByCode = !this.searchByCode;
  }

  isSelected(account: Account) {
    return this.props.selectedAccounts!.find(
      (selectedAccount: Account) => account.id === selectedAccount.id,
    );
  }

  removeSelectedAccounts(data: any) {
    const selectedAccounts = this.props.selectedAccounts;
    if (!selectedAccounts || !selectedAccounts.length || !data.length) return data;
    const filteredData = data.filter((account: Account) => !this.isSelected(account));
    return filteredData;
  }

  getFetchMethod(): (search?: any) => Promise<any> {
    if (this.props.fetch) {
      return this.props.fetch;
    }
    return Api.core.searchAccountsNameOrCode;
  }
  render() {
    const { onChange, label, placeholder, value, clearSelection, InputProps, className } =
      this.props;
    return (
      <Box textAlign={'right'}>
        <AutocompleteSearch
          className={className}
          fetch={this.searchAccounts}
          onChange={onChange}
          label={label ? label : this.renderLabel}
          value={value}
          getOptionLabel={this.getAccountOptionLabel}
          getOptionSelected={(option, value) => option.id === value.id}
          renderOption={this.renderAccountOption}
          placeholder={placeholder}
          clearSelection={clearSelection}
          InputProps={InputProps}
        />
        <Box mt={2}>
          <Button textTransform="capitalize" variant="text" onClick={() => this.setSearchBy()}>
            {this.renderButtonLabel}
          </Button>
        </Box>
      </Box>
    );
  }
}

export default AccountSearch;
