import {
  FormControl,
  InputAdornment,
  InputLabel,
  FormHelperText,
  Box,
  Input,
  InputProps,
} from '@material-ui/core';
import theme from 'containers/App/theme';
import React from 'react';
import useStyles from './styles';

interface UnderlineInputFlexProps {
  prefix?: string;
  helperText?: React.ReactNode;
  label?: string;
  width?: number;
}

function UnderlineInputFlex(props: UnderlineInputFlexProps & Omit<InputProps, 'startAdornment'>) {
  const { error, prefix, helperText, label, width, ...rest } = props;
  const { inputAdornment, fixedAmountLabels, input } = useStyles();
  return (
    <Box width={width}>
      <FormControl style={{ marginBottom: theme.spacing(1) }}>
        <Input
          fullWidth
          startAdornment={
            prefix && (
              <InputAdornment className={inputAdornment} position="start">
                {prefix}
              </InputAdornment>
            )
          }
          {...rest}
          className={input}
        />
      </FormControl>
      <InputLabel className={fixedAmountLabels}>{label}</InputLabel>
      <FormHelperText error={error}>{helperText}</FormHelperText>
    </Box>
  );
}

export default UnderlineInputFlex;
