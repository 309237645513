import { makeStyles, createStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      padding: theme.spacing(0, 2, 0, 0),
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
  }),
);

export default useStyles;
