import React from 'react';
import { observer } from 'mobx-react';
import { computed, observable, action, flow, makeObservable } from 'mobx';

import SettingsStore from './SettingsStore';
import { ProposalStrategy } from 'models';

import MaskedInput from 'react-text-mask';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';

import {
  DialogContent,
  DialogTitle,
  IconButton,
  Divider,
  Box,
  Typography,
  DialogContentText,
  FormHelperText,
  DialogActions,
} from '@material-ui/core';

import { WithStyles, withStyles } from '@material-ui/core/styles';

import DP from 'components/DashPanel';

import styles from './styles';
import { ChevronLeft } from 'mdi-material-ui';
import UnderlineInput from 'components/Input/UnderlineInput/UnderlineInput';
import Button from 'components/Button/Button';

interface FixedAmountsProps extends WithStyles<typeof styles> {
  selectedStrategy: ProposalStrategy;
  settingsStore: SettingsStore;
  goBack: () => void;
}

type Range = 'low' | 'medium' | 'high';

/**
 * Simple component that renders three input fields that act as inputs for
 * three different tip presets in fixed amounts (low, medium, high).
 *
 * @param settingsStore on demand settings state management store
 * @param selectedStrategy flag for calling API with correct values
 * @param goBack Event handler that gets called when user navigates to previous screen
 */
@observer
class FixedAmounts extends React.Component<FixedAmountsProps> {
  constructor(props: FixedAmountsProps) {
    super(props);
    makeObservable(this);
  }
  @observable public settingsStore: SettingsStore = this.props.settingsStore;

  /** Fixed amounts are cast to string for optimal controlled input behavior */
  @observable
  public low: string = this.settingsStore.thresholdProposalStrategySettings!.amountLow.toString();
  @observable
  public medium: string =
    this.settingsStore.thresholdProposalStrategySettings!.amountMedium.toString();
  @observable
  public high: string = this.settingsStore.thresholdProposalStrategySettings!.amountHigh.toString();

  @computed public get updating(): boolean {
    return this.settingsStore.updating;
  }

  @action.bound private handleInputChange =
    (range: Range) => (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      // Remove commas and dollar sign from currency masked input
      this[range] = event.target.value.replace(/[,|$]+/g, '');
    };

  @action.bound private onSave = () => {
    this.saveSettings();
  };

  @action.bound private validateInput = (inputValue: string) => {
    return !inputValue || inputValue === '0';
  };

  @computed private get isFormValid(): boolean {
    if (
      this.validateInput(this.low) ||
      this.validateInput(this.medium) ||
      this.validateInput(this.high)
    ) {
      return false;
    }
    return true;
  }

  @action.bound public saveSettings = flow(function* (this: FixedAmounts) {
    yield this.settingsStore.updateGeneralSettings({ strategy: this.props.selectedStrategy });
    yield this.settingsStore.updateProposalStrategySettings(this.props.selectedStrategy, {
      amountLow: this.low.toString(),
      amountMedium: this.medium.toString(),
      amountHigh: this.high.toString(),
    });
    this.props.goBack();
  });

  renderMaskedCurrencyInput(props: any) {
    const { inputRef, ...other } = props;
    return (
      <MaskedInput
        {...other}
        ref={(ref) => inputRef(ref ? ref.inputElement : null)}
        mask={createNumberMask({ prefix: '', suffix: '' })}
        showMask={false}
      />
    );
  }

  renderError() {
    return <FormHelperText error>Preset value must be set at least 1$</FormHelperText>;
  }

  render() {
    const { drawerContent, fontPrimary, fontSecondary, inputSettings, dialogActions } =
      this.props.classes;
    return (
      <>
        <DialogTitle>
          <Box display="flex" alignItems="center" justifyContent="space-start">
            <Box mr={2}>
              <IconButton onClick={this.props.goBack}>
                <ChevronLeft />
              </IconButton>
            </Box>
            <Typography component="h1" style={{ fontSize: 28, fontWeight: 400 }} display="inline">
              Fixed Amounts
            </Typography>
            <Box width="40px" ml="auto" display="flex" flexDirection="row" alignItems="center">
              {this.updating && <DP.LoadSpinner />}
            </Box>
          </Box>
        </DialogTitle>
        <Divider />
        <DialogContent className={drawerContent}>
          <Box mt={2}>
            <DialogContentText className={fontPrimary}>
              In a basic Proposal Strategy, any service amount below the specified threshold will
              display fixed amounts as tip suggestions.
            </DialogContentText>
          </Box>
          <Box mt={3}>
            <DialogContentText className={fontSecondary}>
              Fixed amounts to be presented:
            </DialogContentText>
          </Box>
          <Box mt={3}>
            <Box className={this.props.classes.fixedAmountBox}>
              <UnderlineInput
                value={this.low.toString()}
                onChange={this.handleInputChange('low')}
                error={this.validateInput(this.low.toString())}
                inputComponent={this.renderMaskedCurrencyInput}
                className={inputSettings}
                label="low"
                prefix={'$'}
                variant="large"
              />
            </Box>
            <Box className={this.props.classes.fixedAmountBox}>
              <UnderlineInput
                value={this.medium.toString()}
                onChange={this.handleInputChange('medium')}
                error={this.validateInput(this.medium.toString())}
                inputComponent={this.renderMaskedCurrencyInput}
                className={inputSettings}
                prefix={'$'}
                label="medium"
                variant="large"
              />
            </Box>
            <Box className={this.props.classes.fixedAmountBox}>
              <UnderlineInput
                value={this.high.toString()}
                onChange={this.handleInputChange('high')}
                error={this.validateInput(this.high.toString())}
                inputComponent={this.renderMaskedCurrencyInput}
                className={inputSettings}
                prefix={'$'}
                label="high"
                variant="large"
              />
            </Box>
          </Box>
        </DialogContent>
        <DialogActions className={dialogActions}>
          <Button
            variant="contained"
            color="primary"
            onClick={this.onSave}
            disabled={this.updating || !this.isFormValid}
            fullWidth>
            Save
          </Button>
        </DialogActions>
      </>
    );
  }
}

export default withStyles(styles)(FixedAmounts);
