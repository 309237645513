import React from 'react';
import { Box, InputAdornment, TextField, TextFieldProps } from '@material-ui/core';
import useStyles from './styles';
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import PhoneInput from 'components/form/PhoneInput';
import { Control, FieldValues } from 'react-hook-form';
import _ from 'lodash';
import { MenuItem } from '@mui/material';
import { ISelectOptions } from 'utils/forms';

export type OutlinedInputVariant = 'normal' | 'small' | 'phone';

export type OutlinedInputProps = Omit<TextFieldProps, 'margin' | 'variant'> & {
  variant?: OutlinedInputVariant;
  dataCy?: string;
  control?: Control<FieldValues, unknown>;
  options?: ISelectOptions[];
};

/**
 * Input component with solid border and border radius of 16. This component is built with MUI TextField component.
 * @param variant User for rendering small and normal size of the input. When using 'small' variant, label prop should be replaced with placeholder prop.
 */
const OutlinedInput = observer(
  React.forwardRef(function OutlinedInputComponent(
    {
      variant = 'normal',
      label,
      error,
      helperText,
      style,
      fullWidth,
      className,
      dataCy,
      InputProps,
      options,
      ...rest
    }: OutlinedInputProps,
    ref,
  ) {
    const startAdornment = InputProps?.startAdornment;
    let startAdornmentObj = startAdornment && typeof startAdornment === 'object' ? true : false;
    const _startAdornment = Array.isArray(startAdornment)
      ? startAdornment.length > 0
      : !!startAdornment;
    const {
      textField,
      textFieldWrapper,
      muiHelperText,
      muiHelperTextWrapper,
      startAdornmentWrapper,
      adornment,
    } = useStyles({
      startAdornment: _startAdornment,
      startAdornmentObj,
      error: Boolean(error),
      disabled: rest.disabled,
      variant,
      multiline: rest.multiline && ((rest.value ?? '') as string).length > 53,
    });

    if (variant === 'phone') {
      InputProps = {
        ...InputProps,
        inputComponent: PhoneInput,
      };
    }

    if (rest.inputProps) {
      rest.inputProps['data-cy'] = dataCy;
    }

    const inputProps = { ...InputProps, startAdornment: undefined };
    const maxLength = rest?.inputProps?.maxLength;
    const valueLength = _.isString(rest?.value) && rest.value.length;

    // This function prevents the default paste behavior, normalizes the pasted text
    const handlePaste = (event: React.ClipboardEvent<HTMLInputElement>) => {
      event.preventDefault();
      const target = event.target as HTMLInputElement;
      target.value = event.clipboardData.getData('text/plain').normalize('NFKD');

      if (rest?.onChange) {
        const syntheticEvent = {
          ...event,
          target,
        } as React.ChangeEvent<HTMLInputElement>;

        rest.onChange(syntheticEvent);
      }
    };

    return (
      <Box width={fullWidth ? '100%' : '222px'}>
        <Box className={textFieldWrapper} style={style}>
          <Box className={startAdornmentWrapper}>
            {startAdornment && (
              <InputAdornment className={adornment} position="start">
                {startAdornment}
              </InputAdornment>
            )}
          </Box>
          <TextField
            inputRef={ref}
            label={variant === 'small' ? undefined : label}
            className={clsx(textField, className)}
            InputProps={inputProps}
            inputProps={{
              'data-cy': `${dataCy}`,
              onPaste: handlePaste,
            }}
            {...rest}
            fullWidth>
            {options
              ? options.map((item) => (
                  <MenuItem key={item.value} value={item.value}>
                    {item.label}
                  </MenuItem>
                ))
              : rest.children}
          </TextField>
        </Box>
        <Box className={clsx(muiHelperTextWrapper)}>
          <Box className={clsx(muiHelperText, 'MuiFormHelperText-root')}>{helperText || error}</Box>
          {rest?.multiline && maxLength && (
            <Box>
              {valueLength}/{maxLength}
            </Box>
          )}
        </Box>
      </Box>
    );
  }),
);

OutlinedInput.displayName = 'OutlinedInput';

export default OutlinedInput;
