import React from 'react';
import { observer } from 'mobx-react';
import { computed, observable, action, flow, makeObservable } from 'mobx';

import SettingsStore from './SettingsStore';
import { WayToTip } from 'models';

import MaskedInput from 'react-text-mask';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';

import {
  DialogContent,
  DialogTitle,
  IconButton,
  Divider,
  Box,
  Typography,
  InputAdornment,
  FormControl,
  Input,
  FormHelperText,
  InputLabel,
} from '@material-ui/core';

import { WithStyles, withStyles } from '@material-ui/core/styles';

import DP from 'components/DashPanel';

import styles from './styles';
import { ChevronLeft } from 'mdi-material-ui';
import { DialogActions } from '@mui/material';
import Button from 'components/Button/Button';

interface TippyGoSuggestedAmountProps extends WithStyles<typeof styles> {
  settingsStore: SettingsStore;
  wayToTip: WayToTip;
  goBack: () => void;
}

/**
 * Simple component that renders three input fields that act as inputs for
 * three different tip presets in fixed amounts (low, medium, high).
 *
 * @param settingsStore on demand settings state management store
 * @param goBack Event handler that gets called when user navigates to previous screen
 */
@observer
class TippyGoSuggestedAmount extends React.Component<TippyGoSuggestedAmountProps> {
  constructor(props: TippyGoSuggestedAmountProps) {
    super(props);
    makeObservable(this);
  }
  @observable public settingsStore: SettingsStore = this.props.settingsStore;

  /** Fixed amounts are cast to string for optimal controlled input behavior */
  @observable
  public suggestedAmount: string = this.settingsStore.tippyGoSettings!.suggestedAmount.toString();

  @computed public get updating(): boolean {
    return this.settingsStore.updating;
  }

  @action.bound private handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    // Remove commas and dollar sign from currency masked input
    this.suggestedAmount = event.target.value.replace(/[,|$]+/g, '');
  };

  @action.bound private onSave = () => {
    this.saveSettings();
  };

  @action.bound private validateInput = (inputValue: string) => {
    return !inputValue || inputValue === '0';
  };

  @computed private get isFormValid(): boolean {
    if (this.validateInput(this.suggestedAmount)) {
      return false;
    }
    return true;
  }

  @action.bound public saveSettings = flow(function* (this: TippyGoSuggestedAmount) {
    yield this.props.settingsStore.updateGeneralSettings({
      suggestedAmount: this.suggestedAmount.toString(),
      wayToTip: this.props.wayToTip,
    });

    this.props.goBack();
  });

  renderMaskedCurrencyInput(props: any) {
    const { inputRef, ...other } = props;
    return (
      <MaskedInput
        {...other}
        ref={(ref) => inputRef(ref ? ref.inputElement : null)}
        mask={createNumberMask({ prefix: '', suffix: '' })}
        showMask={false}
      />
    );
  }

  renderError() {
    return <FormHelperText error>Preset value must be set at least 1$</FormHelperText>;
  }

  render() {
    const { drawerContent, inputBig, fixedAmountLabels } = this.props.classes;
    return (
      <>
        <DialogTitle>
          <Box display="flex" alignItems="center" justifyContent="space-start">
            <Box mr={2}>
              <IconButton onClick={this.props.goBack}>
                <ChevronLeft />
              </IconButton>
            </Box>
            <Typography variant="h4" component="h1" display="inline">
              Suggested Amount
            </Typography>
            <Box width="40px" ml="auto" display="flex" flexDirection="row" alignItems="center">
              {this.updating && <DP.LoadSpinner />}
            </Box>
          </Box>
        </DialogTitle>
        <Divider />
        <DialogContent className={drawerContent}>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent={'center'}
            alignItems={'center'}
            height="100%">
            <Box display="flex" flexDirection="column" alignItems="center">
              <Box mb={3}>
                <FormControl className={inputBig} margin="normal">
                  <InputLabel className={fixedAmountLabels}>Suggested Amount</InputLabel>
                  <Input
                    value={this.suggestedAmount.toString()}
                    onChange={this.handleInputChange}
                    error={this.validateInput(this.suggestedAmount.toString())}
                    inputComponent={this.renderMaskedCurrencyInput}
                    startAdornment={<InputAdornment position="start">$</InputAdornment>}
                  />
                  {this.validateInput(this.suggestedAmount.toString()) && this.renderError()}
                </FormControl>
              </Box>
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            onClick={this.onSave}
            disabled={this.updating || !this.isFormValid}
            fullWidth>
            Save
          </Button>
        </DialogActions>
      </>
    );
  }
}

export default withStyles(styles)(TippyGoSuggestedAmount);
