import React from 'react';
import { observable, action, flow, computed, reaction, IReactionDisposer, makeObservable } from 'mobx';
import { observer } from 'mobx-react';
import { WithStyles, withStyles } from '@material-ui/core/styles';
import { now } from 'mobx-utils';
import { Box, Typography, CircularProgress } from '@material-ui/core';
import clsx from 'clsx';

import Api from 'api';

import { inject, WithUserStore, WithModalStore, WithToastStore } from 'stores';

import styles from './styles';

const Countup = require('react-countup').default;

type DashboardHomeProps = WithStyles<typeof styles> &
  WithUserStore &
  WithModalStore &
  WithToastStore;

interface InfoStats {
  todayAmount: string;
  todayCount: string;
  totalAmount: string;
  totalCount: string;
}

const duration = 4;

/**
 * The info display page for admins, displayed when you log in.
 */
@inject('userStore', 'modalStore', 'toastStore')
@observer
class InfoDisplay extends React.Component<DashboardHomeProps> {
  public constructor(props: DashboardHomeProps) {
    super(props);
    makeObservable(this);
    this.disposers.push(
      reaction(
        () => now(5000),
        () => this.getInfoDisplay(),
      ),
    );
  }
  /** It's good practice to dispose of any autoruns that we set up during */
  private disposers: IReactionDisposer[] = [];
  @observable public infoStatsStrings?: InfoStats;
  @observable public now = now(5000);
  @action.bound public getInfoDisplay = flow(function* (this: InfoDisplay) {
    const resp = yield Api.tips.getInfoDisplay();
    this.infoStatsStrings = resp.data.data;
  });
  @computed public get infoStats() {
    return {
      todayAmount: this.infoStatsStrings && parseFloat(this.infoStatsStrings.todayAmount),
      todayCount: this.infoStatsStrings && parseInt(this.infoStatsStrings.todayCount),
      totalAmount: this.infoStatsStrings && parseFloat(this.infoStatsStrings.totalAmount),
      totalCount: this.infoStatsStrings && parseInt(this.infoStatsStrings.totalCount),
    };
  }
  componentDidMount() {
    this.getInfoDisplay();
  }

  componentWillUnmount() {
    this.disposers.map((disposer) => disposer());
  }

  render() {
    const { classes } = this.props;
    if (!this.infoStatsStrings) {
      return (
        <Box className={classes.root} display="flex" justifyContent="center" alignItems="center">
          <CircularProgress color="inherit" size={64} />
        </Box>
      );
    }
    return (
      <Box className={classes.root} onClick={this.getInfoDisplay}>
        <Box className={clsx(classes.section, classes.section1)}>
          <Box className={classes.sectionSub}>
            <Typography className={classes.title}>Total Tips</Typography>
            <Typography className={classes.number}>
              {this.infoStats.totalCount && (
                <Countup
                  end={this.infoStats.totalCount}
                  duration={duration}
                  separator=","
                  preserveValue
                />
              )}
            </Typography>
          </Box>
        </Box>
        <Box className={classes.section}>
          <Box className={classes.sectionSub}>
            <Typography className={classes.title}>Total amount</Typography>
            <Typography className={classes.number}>
              {this.infoStats.totalAmount && (
                <Countup
                  key="totalAmount"
                  end={this.infoStats.totalAmount}
                  duration={duration}
                  decimals={2}
                  prefix="$"
                  separator=","
                  preserveValue
                />
              )}
            </Typography>
          </Box>
        </Box>
        <Box className={classes.section}>
          <Box className={classes.sectionSub}>
            <Typography className={classes.title}>{`Today's tips`}</Typography>
            <Typography className={classes.number}>
              {this.infoStats.todayAmount && (
                <Countup
                  end={this.infoStats.todayCount}
                  duration={duration}
                  separator=","
                  preserveValue
                />
              )}
            </Typography>
          </Box>
        </Box>
        <Box className={clsx(classes.section, classes.section4)}>
          <Box className={classes.sectionSub}>
            <Typography className={classes.title}>{`Today's amount`}</Typography>
            <Typography className={classes.number}>
              {this.infoStats.todayAmount && (
                <Countup
                  end={this.infoStats.todayAmount}
                  duration={duration}
                  decimals={2}
                  preserveValue
                  prefix="$"
                  separator=","
                />
              )}
            </Typography>
          </Box>
        </Box>
      </Box>
    );
  }
}

export default withStyles(styles)(InfoDisplay);
