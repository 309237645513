import { Theme } from '@material-ui/core/styles';
import { createStyles, makeStyles } from '@material-ui/styles';

interface IStyleProps {
  width?: React.CSSProperties['width'];
  disableBodyScroll?: boolean;
}

const useStyles = makeStyles<Theme, IStyleProps>((theme: Theme) => {
  const paddingBase = {
    padding: 0,
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  };
  return createStyles({
    root: {
      maxHeight: 600,
      width: ({ width }: IStyleProps) => (width ? width : 570),
      [theme.breakpoints.down(600)]: {
        width: 300,
      },
      overflowY: 'auto',
    },
    dialogTitle: {
      ...paddingBase,
      paddingTop: theme.spacing(3),
      fontSize: 28,
    },
    dialogContent: {
      ...paddingBase,
      overflowY: ({ disableBodyScroll }) => (disableBodyScroll ? 'hidden' : 'auto'),
      paddingTop: theme.spacing(4),
    },
    dialogActions: {
      ...paddingBase,
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(3),
    },
  });
});

export default useStyles;
