import { makeStyles, createStyles, Theme } from '@material-ui/core';
import { lighten } from '@material-ui/core/styles/colorManipulator';

interface IStyleProps {
  displaySmall?: boolean;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default makeStyles<Theme, IStyleProps>((theme) =>
  createStyles({
    balancePaper: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-around',
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
      alignItems: 'center',
      color: theme.palette.text.secondary,
      fontWeight: 100,
      height: theme.spacing(32),
    },
    balancePrimaryBg: {
      background: `linear-gradient(180deg, ${lighten(theme.palette.primary.main, 0.2)} 0%, ${
        theme.palette.primary.main
      } 100%)`,
    },
    balanceWhite: {
      background: theme.palette.common.white,
      color: theme.palette.primary.main,
    },
    balanceTitle: {
      textTransform: 'uppercase',
      fontWeight: 'bold',
    },
    stat: {
      height: theme.spacing(14.5),
      color: theme.palette.secondary.contrastText,
      borderRadius: theme.shape.borderRadius,
    },
    statItemTop: {
      borderBottom: `1px solid white`,
      padding: theme.spacing(1),
      fontWeight: 'bold',
      textTransform: 'uppercase',
    },
    statItemBottom: {
      display: 'flex',
      flexGrow: 1,
      justifyContent: 'center',
      alignItems: 'center',
    },
    top: {
      background: `linear-gradient(180deg, #66B6E4 0%, ${theme.palette.secondary.main} 200%)`,
    },
    bottom: {
      background: `linear-gradient(180deg, #66B6E4 -100%, ${theme.palette.secondary.main} 100%)`,
    },
    //TODO: Remove statsContainer after BigStat component is removed?
    statsContainer: {
      display: 'flex',
      background: theme.palette.common.white,
      borderRadius: '16px',
      height: 120,
      flexDirection: 'column',
      minWidth: '250px',
    },
    statsContainerBase: {
      position: 'relative',
      background: theme.palette.common.white,
      borderRadius: theme.shape.borderRadius,
    },
    bigStatsContainer: {
      display: 'flex',
      height: (props) => (props?.displaySmall ? '92px' : '120px'),
      paddingLeft: 20,
      paddingRight: 16,
      minWidth: '250px',
    },
    statsDenseContainer: {
      minWidth: 246,
      height: 182,
      paddingTop: 23,
      paddingRight: 16,
      paddingLeft: 21,
    },
    denseStatCardAdditionalData: {
      position: 'absolute',
      top: 0,
      right: 0,
      color: theme.typography.subtitle1.color,
      fontSize: theme.typography.subtitle1.fontSize,
    },
    iconContainer: {
      display: 'flex',
      alignItems: 'center',
      gap: '0.75rem',
    },
    iconWrapperBase: {
      borderRadius: '50%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    iconWrapperBig: {
      padding: 0,
      width: '67px',
      height: '67px',
    },
    iconWrapperSmall: {
      width: '50px',
      height: '50px',
    },
    bigStatVerticalCounter: {
      overflow: 'scroll',
      '-ms-overflow-style': 'none', // IE and Edge
      'scrollbar-width': 'none', // Firefox
      '&::-webkit-scrollbar': {
        display: 'none',
      },
      height: 36,
      position: 'relative',
      width: '100%',
      textAlign: 'center',
    },
    bigStatHorizontalCounter: {
      overflow: 'scroll',
      '-ms-overflow-style': 'none', // IE and Edge
      'scrollbar-width': 'none', // Firefox
      '&::-webkit-scrollbar': {
        display: 'none',
      },
      height: 36,
      position: 'relative',
      paddingRight: ({ displaySmall }) => (displaySmall ? 0 : theme.spacing(0)),
      marginTop: ({ displaySmall }) => (displaySmall ? theme.spacing(2.75) : theme.spacing(5)),
    },
    bigStatInfoCounter: {
      overflow: 'scroll',
      '-ms-overflow-style': 'none', // IE and Edge
      'scrollbar-width': 'none', // Firefox
      '&::-webkit-scrollbar': {
        display: 'none',
      },
      minHeight: 36,
      position: 'relative',
      marginTop: theme.spacing(3.375),
      maxHeight: 48,
    },
    bigStatActionFont: {
      color: `${theme.palette.primary.main} !important`,
      textDecoration: 'underline',
      fontWeight: 700,
    },
    bigStatActionFontDisabled: {
      color: `${theme.palette.text.disabled} !important`,
    },
    dynamicFontWrapper: {
      display: 'grid',
      height: '100%',
      paddingBottom: 24,
      flexDirection: 'column',
      position: 'relative',
      flexGrow: 1,
    },
    dynamicFontInnerWrapper: {
      height: 36,
      overflow: 'scroll',
      '-ms-overflow-style': 'none', // IE and Edge
      'scrollbar-width': 'none', // Firefox
      '&::-webkit-scrollbar': {
        display: 'none',
      },
      whiteSpace: 'nowrap',
      marginTop: ({ displaySmall }) => (displaySmall ? theme.spacing(2.75) : theme.spacing(5)), //todo remove?
    },
    bigStatInfoDynamicFont: { whiteSpace: `normal`, fontWeight: 700 },
    bigStatDenseCounter: {
      width: '100%',
    },
    flexCounterBase: {
      flexDirection: 'column',
      justifyContent: 'center',
      display: 'flex',
      '& > div': {
        overflow: 'hidden !important',
      },
    },
    loadingSpinner: {
      alignSelf: 'center',
    },
    bigStatHorizontalTitle: {
      marginTop: 'auto',
      paddingLeft: 20,
      fontWeight: 400,
      color: `${theme.palette.text.secondary} !important`,
    },
    bigStatActionTitle: {
      marginTop: 'auto',
      paddingLeft: 20,
      fontWeight: 400,
      color: `${theme.palette.text.secondary} !important`,
    },
    iconSmallTransform: {
      scale: 0.75,
    },
    countUp: {
      '-ms-overflow-style': 'none', // IE and Edge
      'scrollbar-width': 'none', // Firefox
      '&::-webkit-scrollbar': {
        display: 'none',
      },
    },
    iconGreen: {
      color: theme.palette.primary.main,
      backgroundColor: theme.palette.primary.light,
    },
    iconYellow: {
      color: theme.palette.warning.main,
      backgroundColor: theme.palette.warning.light,
    },
    iconPurple: {
      color: theme.palette.secondary.main,
      backgroundColor: theme.palette.secondary.light,
    },

    textValue: {
      color: theme.palette.text.primary,
      fontWeight: 700,
      fontSize: '32px',
    },
    text: {
      fontWeight: 400,
      fontSize: '14px',
      color: theme.palette.secondary.main,
    },
    textDate: {
      alignSelf: 'flex-end',
    },
    bigStatTextDate: {
      position: 'absolute',
      whiteSpace: 'nowrap',
      top: 16,
      right: 16,
    },
    statsVerticalContainer: {
      display: 'flex',
      background: theme.palette.common.white,
      borderRadius: theme.shape.borderRadius,
      height: '100%',
      padding: 17,
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      boxSizing: 'border-box',
    },
    cardItem: {
      [theme.breakpoints.up(905)]: {
        width: '50%',
      },
      width: '100%',
      minWidth: '259px',
    },
  }),
);
