import { Wallet, Processor, User, Partner } from 'models';

type Talent = Pick<User, 'id' | 'firstName' | 'lastName' | 'email'>;

export type InitiationStatus = 'completed' | 'running' | 'stopped';

export interface Payout {
  id: number;
  initiationId: number;
  talent: Talent;
  talentName?: string;
  wallet?: Wallet;
  processor: Processor | string;
  processorResponse: any;
  transactionId?: string;
  amount?: string;
  status: string;
  createdAt: string;
  sentAt?: string;
}

export interface UpcomingPayout {
  talent?: Talent;
  partner?: Partner;
  walletId: number;
  processor: Processor | string;
  amount: string;
}

export interface Initiation {
  id: number;
  date: string;
  status: InitiationStatus;
  count: number;
  amount: string;
  createdAt: string;
  finishedAt: string;
  stats?: InitiationStats;
}

export interface InitiationStats {
  totalCount: string;
  totalSum: string;
  processedCount: string;
  processedSum: string;
  pendingCount: string;
  pendingSum: string;
  failedCount: string;
  failedSum: string;
}

export interface Chunk {
  id: number;
  amount: number;
  count: number;
  createdAt: string;
  updatedAt: string;
  identifier: string;
  initiationId: number;
  processorFee: string;
  processorResponse: any;
  processorId: string;
  transactionId: string;
  status: string;
}

export enum PAYOUT_PROCESSOR {
  DWOLLA = 'dwolla',
}
