import * as React from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisVertical } from '@fortawesome/pro-regular-svg-icons';
import { itemStyles, menuStyles } from './styles';
import { Box, Tooltip, TooltipProps } from '@mui/material';
import theme from 'containers/App/theme';
import { IStatusOptions } from 'theme';

export type TOptionsMenuItemColor = 'error';

interface IOptionsMenuItem {
  label: string | React.ReactNode;
  color?: keyof IStatusOptions;
  disabled?: boolean;
  tooltipTitle?: TooltipProps['title'];
  onClick: (e?: any) => void;
}

export interface IOptionsMenuProps {
  disabled?: boolean;
  tooltipTitle?: TooltipProps['title'];
  items: IOptionsMenuItem[];
}

/**
 * Component for creating a menu with a list of options. Each
 * option may be passed a label and onClick event.
 * @param items Array of item objects, accepts 'label', 'onClick', 'disabled' and 'tooltipTitle' params
 */
const OptionsMenu = ({ items, disabled, tooltipTitle }: IOptionsMenuProps) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Tooltip title={(disabled && tooltipTitle) || ''}>
        <Box component={'span'}>
          <IconButton disabled={disabled} size="small" onClick={handleClick}>
            <FontAwesomeIcon icon={faEllipsisVertical} />
          </IconButton>
        </Box>
      </Tooltip>
      <Menu sx={menuStyles} anchorEl={anchorEl} open={open} onClose={handleClose}>
        {items.map((menuItem, i) => {
          const { label, disabled, tooltipTitle: title, onClick } = menuItem;
          const borderBottom =
            i < items.length - 1 ? `1px solid ${theme.palette.divider} ` : 'none';
          const handleClick = () => {
            onClick();
            handleClose();
          };
          const color = itemStyles(menuItem?.color);

          return (
            <Tooltip key={i} title={(disabled && title) || ''}>
              <Box component={'span'}>
                <MenuItem
                  dense
                  key={`menu-item-${i}`}
                  {...{ disabled, onClick: handleClick }}
                  sx={{
                    borderBottom,
                    color,
                  }}>
                  {label}
                </MenuItem>
              </Box>
            </Tooltip>
          );
        })}
      </Menu>
    </>
  );
};

export default OptionsMenu;
