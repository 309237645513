import { Theme, createStyles, makeStyles } from '@material-ui/core';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const styles = (theme: Theme) =>
  createStyles({
    root: {
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      paddingTop: theme.spacing(0.375),
      paddingBottom: theme.spacing(2),
    },
    itemsBox: {
      flexGrow: 1,
      height: 300,
      overflow: 'auto',
      marginBottom: theme.spacing(2),
    },
    dragged: {
      outline: `1px dashed ${theme.palette.grey[500]}`,
    },
    fileDrop: {
      borderRadius: theme.shape.borderRadius,
      backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='16' ry='16' stroke='rgba(0, 0, 0, 0.4)' stroke-width='4' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e")`,
    },
  });

export default styles;

export const useStyles = makeStyles(styles);
