import { Theme, createStyles } from '@material-ui/core';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default (theme: Theme) =>
  createStyles({
    root: {},
    infoIconButton: {
      padding: 0,
      height: 'auto',
      width: 'auto',
      '&:hover': {
        background: 'transparent',
      },
    },
  });
