import { useTheme } from '@mui/material';
import * as models from 'models';
import QRCode from 'qrcode.react';
import TabBar from 'components/TabBar/TabBar';

interface IStoreQRCodesProps {
  osType: models.EOsType;
  storeLink: string;
  additionalInfo?: React.ReactNode;
  onOsTypeChange: React.Dispatch<React.SetStateAction<models.EOsType>>;
}
const StoreQRCodes = ({
  osType,
  storeLink,
  additionalInfo,
  onOsTypeChange,
}: IStoreQRCodesProps) => {
  const theme = useTheme();

  const iOS = osType === models.EOsType.MAC_OS;
  const android = !iOS;

  return (
    <>
      <QRCode value={storeLink} size={122} style={{ marginTop: theme.spacing(5) }} />

      <TabBar
        style={{ backgroundColor: theme.palette.background.default, marginTop: theme.spacing(4) }}
        tabs={[
          {
            label: 'App Store',
            selected: iOS,
            onClick: () => onOsTypeChange(models.EOsType.MAC_OS),
          },
          {
            label: 'Google Play',
            selected: android,
            onClick: () => onOsTypeChange(models.EOsType.OTHER),
          },
        ]}
      />

      {additionalInfo}
    </>
  );
};

export default StoreQRCodes;
