import { useEffect, useState } from 'react';
import {
  ANDROID_TIPPY_MOBILE_INTENT,
  LINK_APP_APPSTORE,
  LINK_APP_GOOGLE_PLAY,
} from 'utils/constants';
import * as models from 'models';
import { initializeOS } from 'utils/operatingSystem';
import { useStores } from 'containers/App/App';

const useHome = () => {
  const [osType, setOsType] = useState<models.EOsType>(() => initializeOS());
  const { uiStore } = useStores();

  const { mobileView } = uiStore;

  useEffect(() => {
    // Try to redirect to Tippy Mobile app, iOS does this automatically
    if (osType === models.EOsType.ANDROID) {
      window.location.assign(ANDROID_TIPPY_MOBILE_INTENT);
      return;
    }
  }, [osType]);

  const isAndroid = osType === models.EOsType.ANDROID;

  const isOther = osType === models.EOsType.OTHER;

  const isDesktop = osType === models.EOsType.MAC_OS || isOther;

  const showQrCodes = isDesktop;

  const showStoreLink = !showQrCodes;

  const storeLink = isAndroid || isOther ? LINK_APP_GOOGLE_PLAY : LINK_APP_APPSTORE;

  const goToStore = () => (window.location.href = storeLink);

  const storeQrCodesProps = {
    osType,
    storeLink,
    onOsTypeChange: setOsType,
  };

  const storeButtonProps = {
    osType,
    goToStore,
  };

  return {
    os: osType,
    mobileView,
    showQrCodes,
    showStoreLink,
    storeQrCodesProps,
    storeButtonProps,
  };
};

export default useHome;
