import { Theme, createStyles } from '@material-ui/core';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default (theme: Theme) =>
  createStyles({
    root: {},
    fieldLabel: {
      textTransform: 'capitalize',
    },
    emailField: {
      margin: '0',
      maxWidth: '100%',
      msFlex: '1 0 auto',
      flex: '1 0 auto',
      outline: '0',
      textAlign: 'left',
      lineHeight: '1.21428571em',
      padding: '3px 16px 1px 6px',
      borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
      WebkitTransition: 'box-shadow 0.1s ease, border-color 0.1s ease',
      transition: 'box-shadow 0.1s ease, border-color 0.1s ease',
      fontSize: '18px',
      position: 'relative',
      display: 'flex',
      flexWrap: 'wrap',
      alignItems: 'center',
      alignContent: 'flex-start',
      '& input': {
        width: 'auto !important',
        outline: 'none !important',
        border: '0 none !important',
        display: 'inline-block !important',
        lineHeight: 1,
        verticalAlign: 'baseline !important',
        padding: '0.4em 0.1em !important',
        fontSize: '18px',
      },
      '& span[data-placeholder]': {
        display: 'none',
        position: 'absolute',
        left: '12px',
        top: '12px',
        paddingBottom: '6px',
        lineHeight: '1.21428571em',
        color: '#999',
      },
      '&.focused': {
        borderColor: `${theme.palette.primary.main} !important`,
        borderWidth: '2px',
        '& span[data-placeholder]': {
          display: 'none',
        },
      },
      '&.empty': {
        '& span[data-placeholder]': {
          display: 'inline',
        },
      },
      '&:hover': {
        borderColor: 'rgba(0, 0, 0, 1)',
        borderWidth: '2px',
      },
    },
    emailLabel: {
      margin: '4px 2px',
      color: theme.palette.text.primary,
      WebkitTransition: 'background 0.1s ease',
      OTransition: 'background 0.1s ease',
      transition: 'background 0.1s ease',
    },
    emailRemove: {
      marginLeft: '6px',
      marginRight: '4px',
      fontSize: '22px',
      fontWeight: 600,
      cursor: 'pointer',
    },
  });
