/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { Link as RouterLink, Route, Switch, Redirect, RouteComponentProps } from 'react-router-dom';
import { observable, computed, makeObservable } from 'mobx';
import { observer } from 'mobx-react';

import * as models from 'models';

import { paths } from 'routes';
import { inject, WithUserStore, WithToastStore, WithAnalyticsStore } from 'stores';
import { WithStyles, withStyles } from '@material-ui/core/styles';

import { setTitle } from 'services';

import DashboardLayout from 'containers/DashboardLayout';

import ByDate from './ByDate';
import ByEmployee from './ByEmployee';
import ByTransaction from './ByTransaction';

import styles from './styles';
import Title from 'components/Title';
import TabBar from 'components/TabBar/TabBar';

const PAGE_TITLE = 'Reconciliation';

/** Here we define what kind of props this component takes */
interface ReconciliateProps
  extends WithStyles<typeof styles>,
    RouteComponentProps,
    WithUserStore,
    WithToastStore,
    WithAnalyticsStore {}

/**
 * Container for displaying and downloading tip reports.
 * Accessible to admin, owner and manager scope.
 */
@inject('userStore', 'toastStore', 'analyticsStore')
@observer
class Reconciliation extends React.Component<ReconciliateProps> {
  userStore = this.props.userStore!;
  constructor(props: ReconciliateProps) {
    super(props);
    makeObservable(this);
  }

  /** Chart data */
  @observable private chartData?: models.ChartData;

  @computed public get dateRange() {
    return this.props.analyticsStore!.dateRange;
  }

  @computed public get dateRangeKey() {
    return JSON.stringify(this.dateRange && `${this.dateRange.fromDate}-${this.dateRange.toDate}`);
  }

  @computed public get tabs() {
    const conversionPaths = paths.reconciliation();
    const { pathname } = this.props.location;

    return [
      {
        label: 'By Date',
        component: RouterLink,
        to: conversionPaths.byDate(),
        selected: pathname === conversionPaths.byDate(),
      },
      {
        label: 'By Employees',
        component: RouterLink,
        to: conversionPaths.byEmployee(),
        selected: pathname === conversionPaths.byEmployee(),
      },
      {
        label: 'By Transaction',
        component: RouterLink,
        to: conversionPaths.byTransaction(),
        selected: pathname === conversionPaths.byTransaction(),
      },
    ];
  }

  componentDidMount() {
    setTitle(PAGE_TITLE, { noSuffix: false });
  }

  render() {
    const conversionPaths = paths.reconciliation();
    const { pathname } = this.props.location;
    if (pathname === conversionPaths.root()) {
      return <Redirect to={conversionPaths.byDate()} />;
    }
    return (
      <DashboardLayout>
        <Title mb={3}>{PAGE_TITLE}</Title>
        <TabBar mb={3} tabs={this.tabs} />
        <Switch>
          <Route path={conversionPaths.byDate()} component={ByDate} />
          <Route path={conversionPaths.byEmployee()} component={ByEmployee} />
          <Route path={conversionPaths.byTransaction()} component={ByTransaction} />
        </Switch>
      </DashboardLayout>
    );
  }
}

export default withStyles(styles)(Reconciliation);
