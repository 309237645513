import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import styles from './styles';
import { Link as RouterLink, Route, RouteComponentProps, Switch } from 'react-router-dom';
import DashboardLayout from '../../DashboardLayout/DashboardLayout';
import { inject, WithModalStore, WithToastStore, WithUserStore } from '../../../stores';
import { observer } from 'mobx-react';
import { Box, WithStyles } from '@material-ui/core';
import { paths } from '../../../routes';
import PartnerDetailsGeneral from './PartnerDetails/PartnerDetailsGeneral';
import PartnerDetailsWallet from './PartnerDetails/PartnerDetailsWallet';
import { computed, makeObservable, observable } from 'mobx';
import Api from '../../../api';
import Title from 'components/Title/Title';
import TabBar from 'components/TabBar/TabBar';

interface PartnerDetailsMatchParams {
  partnerId: string;
}

type PartnerDetailsProps = WithStyles<typeof styles> &
  WithUserStore &
  WithToastStore &
  WithModalStore &
  RouteComponentProps<PartnerDetailsMatchParams>;

@inject('userStore', 'toastStore', 'modalStore')
@observer
class PartnerDetails extends React.Component<PartnerDetailsProps> {
  constructor(props: PartnerDetailsProps) {
    super(props);
    makeObservable(this);
  }

  @observable private partner?: any;

  @computed public get partnerId(): number {
    return parseInt(this.props.match.params.partnerId);
  }

  @computed public get tabs() {
    const { pathname } = this.props.location;
    const { partnerId } = this.props.match.params;
    const partnerDetails = paths.partnerDetails(partnerId);

    return [
      {
        label: 'General',
        component: RouterLink,
        to: partnerDetails.general(),
        selected: pathname === partnerDetails.general(),
      },
      {
        label: 'Wallet',
        component: RouterLink,
        to: partnerDetails.wallet(),
        selected: pathname === partnerDetails.wallet(),
      },
    ];
  }

  private async fetchPartner() {
    try {
      const resp = await Api.tips.getPartner(this.partnerId);
      this.partner = resp.data.data;
    } catch (e) {
      console.log(e);
    }
  }

  componentDidMount() {
    this.fetchPartner();
  }

  render() {
    return (
      <DashboardLayout>
        <Box display="flex" flexDirection="row" alignItems="center" justifyContent="space-between">
          {this.partner && <Title mb={3}>{this.partner.name}</Title>}
        </Box>
        <TabBar mb={3} tabs={this.tabs} />
        <Switch>
          <Route
            path={paths.partnerDetails(':partnerId').general()}
            component={PartnerDetailsGeneral}
          />
          <Route
            path={paths.partnerDetails(':partnerId').wallet()}
            component={PartnerDetailsWallet}
          />
        </Switch>
      </DashboardLayout>
    );
  }
}

export default withStyles(styles)(PartnerDetails);
