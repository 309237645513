/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { observable, action, computed, makeObservable } from 'mobx';
import { observer } from 'mobx-react';
import { WithStyles, withStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';

import { paths } from 'routes';
import { inject, WithToastStore, WithUserStore } from 'stores';

import PictureUpload from './PictureUpload/PictureUpload';
import styles from './styles';
import CarouselScreenWrapper from 'components/CarouselScreenWrapper/CarouselScreenWrapper';
import SignupLayout, {
  TSignupLayoutContentWrapperStyles,
  TSignupLayoutSubAction,
} from 'containers/SignupLayout/SignupLayout';
import theme from 'containers/App/theme';

type ProfilePictureProps = WithStyles<typeof styles> &
  RouteComponentProps &
  WithToastStore &
  WithUserStore;

/**
 * Displays the upload zone for user's profile picture
 */
@inject('toastStore', 'userStore')
@observer
class ProfilePicture extends React.Component<ProfilePictureProps> {
  constructor(props: ProfilePictureProps) {
    super(props);
    makeObservable(this);
  }

  /** Whether the info is currently being submitted */
  @observable public submitting = false;

  /** For rendering switch and upload progress bar value */
  @observable private uploading = false;
  @observable private imageUrl?: string;

  /** Is this a manager signup process */
  @computed get managerOnboarding(): boolean {
    const state: any = this.props.history.location.state;
    if (state && state.managerOnboarding) return state.managerOnboarding;
    else return false;
  }

  @action.bound onUpload = (success: boolean) => {
    if (success) {
      this.goToNextScreen();
    }
  };

  @action.bound onUploading = (uploading: boolean) => {
    this.uploading = uploading;
  };

  @action.bound public goToNextScreen = () => {
    // If the user is being onboarded as a manager, finish the signup and show the success screen
    if (this.managerOnboarding) {
      this.props.history.push(paths.signUp().success());
    }
    this.props.history.push(paths.signUp().success());

    // Else go to the bank step
    // TODO: reimplement branch step when ready
    // else {
    //   this.props.history.push(paths.signUp().bank());
    // }
  };

  @action.bound public onNewImage = (imageUrl?: string) => {
    this.imageUrl = imageUrl;
  };

  @computed public get signupLayoutProps() {
    const title = 'Profile Picture';
    const subtitle = !this.imageUrl ? 'Give us your best look!' : undefined;
    const contentWrapperStyles: TSignupLayoutContentWrapperStyles = {
      marginTop: theme.spacing(4),
    };
    const subAction: TSignupLayoutSubAction = {
      back: {
        onClick: this.props.history.goBack,
      },
      skip: {
        onClick: this.goToNextScreen,
      },
    };
    return {
      title,
      subtitle,
      subAction,
      contentWrapperStyles,
    };
  }

  componentDidMount(): void {
    this.imageUrl = this.props.userStore?.user?.avatar;
  }

  render() {
    const user = this.props.userStore!.user!;
    const { classes } = this.props;
    const mb = this.imageUrl ? 8.5 : 5;

    return (
      <CarouselScreenWrapper>
        <SignupLayout {...this.signupLayoutProps}>
          <Box display="flex" flexDirection="column" alignItems="center" className={classes.root}>
            <PictureUpload
              profilePictureUrl={user.avatar}
              onNewImage={this.onNewImage}
              onUpload={this.onUpload}
              onUploading={this.onUploading}
            />
          </Box>
        </SignupLayout>
      </CarouselScreenWrapper>
    );
  }
}

export default withStyles(styles)(ProfilePicture);
