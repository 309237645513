import { Location } from './Location';

/* Shipment status */
export enum ShipmentStatus {
  CREATED = 'created',
  SHIPPED = 'shipped',
  IN_TRANSIT = 'in_transit',
  PICKUP = 'pickup',
  EXCEPTION = 'exception',
  RETURNED = 'returned',
  NOT_AVAILABLE = 'not_available',
  BILLING_INFO_RECEIVED = 'billing_information_received',
  BILLING_INFO_VOIDED = 'billing_information_voided',
  OUT_FOR_DELIVERY = 'out_for_delivery',
  DELIVERED = 'delivered',
  DELETED = 'deleted',
  UNKNOWN = 'unknown',
}

/** Represents a shipment */
export interface Shipment {
  id: number;
  locationId: number;
  fulfillmentId: number;
  address: string;
  address2: string;
  city?: string;
  state?: string;
  zip?: string;
  country?: string;
  trackingNumber: string;
  status: ShipmentStatus;
  createdAt: string;
  location?: Location;
}

/** Represents the progress of a shipment */
export interface ShipmentProgress {
  trackingNumber: string;
  status: string;
  description: string;
  activity: {
    status: string;
    description: string;
    changedAt: string;
    location: {
      description: string;
      city: string;
      zip: string;
      country: string;
    };
  }[];
}
