import {
  Box,
  CircularProgress,
  DialogTitle,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Typography,
} from '@material-ui/core';
import { withStyles, WithStyles } from '@material-ui/core/styles';
import { Check, Close } from 'mdi-material-ui';
import { observer } from 'mobx-react';
import React, { ChangeEvent } from 'react';
import styles from './styles';
import Api, { getErrorMsg } from 'api';
import { makeObservable, observable } from 'mobx';
import * as FeeSchedule from 'models/FeeSchedule';
import { inject, WithToastStore } from 'stores';
import { EditOutlined } from '@mui/icons-material';
import DD from 'components/DashDrawer';
import theme from 'containers/App/theme';
import OutlinedInput from 'components/Input/OutlinedInput';
import { FEE_TYPE_MAPPER } from './FeeScheduleHelper';

type FeeScheduleDetailsDrawerProps = WithStyles<typeof styles> & {
  triggerDataRefetch: () => void;
  feeScheduleId: number;
  isDetailsOpen: boolean;
  closeDrawer: () => void;
  canPerformActions: boolean;
} & WithToastStore;

@inject('toastStore')
@observer
class FeeScheduleDetailsDrawer extends React.Component<FeeScheduleDetailsDrawerProps> {
  constructor(props: any) {
    super(props);
    makeObservable(this);
  }

  @observable feeSchedule?: FeeSchedule.IFeeScheduleResponse = undefined;
  @observable name?: string = undefined;
  @observable color = '';
  @observable editName = false;

  componentDidUpdate() {
    if (this.props.isDetailsOpen && !this.feeSchedule) this.getFeeScheduleDetails();
  }

  getFeeScheduleDetails = async () => {
    const { data } = await Api.tips.getFeeScheduleDetails(this.props.feeScheduleId);
    this.feeSchedule = data.data;
    this.name = data.data.name;
  };

  getColor = () => {
    if (!this.feeSchedule) return '';
    if (this.feeSchedule.status === FeeSchedule.FeeScheduleStatus.PUBLISHED)
      return theme.palette.primary.main;
    else return theme.palette.status.yellow;
  };

  closeDrawer = () => {
    this.feeSchedule = undefined;
    this.editName = false;
    this.props.closeDrawer();
  };

  toggleEditName = () => {
    this.editName = !this.editName;
    if (!this.editName && this.feeSchedule) this.name = this.feeSchedule.name;
  };

  handleNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    this.name = e.currentTarget.value;
  };

  updateName = async () => {
    const id = this.props.feeScheduleId;
    try {
      const { data } = await Api.tips.updateFeeSchedule(id, { name: this.name });
      this.feeSchedule!.name = data.data.name;
      this.editName = false;
      this.props.triggerDataRefetch();
      this.props.toastStore!.push({ type: 'success', message: 'Fee schedule updated sucessfully' });
    } catch (error) {
      this.props.toastStore!.push({ type: 'error', message: getErrorMsg(error) });
    }
  };

  getFieldError = () => {
    const hasError = !this.name;
    const message = this.name ? '' : 'This field is required';
    return { hasError, message };
  };

  renderName = () => {
    if (this.editName) {
      const { hasError, message } = this.getFieldError();
      return (
        <Box className={this.props.classes.nameInput}>
          <OutlinedInput
            fullWidth
            placeholder="Name"
            value={this.name}
            onChange={this.handleNameChange}
            error={hasError}
            helperText={message}
          />
        </Box>
      );
    }
    return this.feeSchedule!.name;
  };

  render() {
    const {
      detailsDrawer,
      settingListItemKey,
      settingListItemValue,
      listItemUnderline,
      distributionRules,
      firstItem,
    } = this.props.classes;
    const feeSchedule = this.feeSchedule;
    const color = this.getColor();
    return (
      <Drawer
        title="Settings"
        className={detailsDrawer}
        anchor="right"
        open={this.props.isDetailsOpen}>
        <DialogTitle>
          <Box className={this.props.classes.titleWrapper} style={{ flexWrap: 'wrap-reverse' }}>
            <Typography style={{ fontSize: 28 }} component="h1" display="inline">
              {feeSchedule ? feeSchedule.name : ''}
            </Typography>
            <Box className={this.props.classes.iconsWrapper}>
              {this.editName ? (
                <>
                  <IconButton onClick={this.toggleEditName}>
                    <Close color="error" />
                  </IconButton>
                  <IconButton disabled={this.getFieldError().hasError} onClick={this.updateName}>
                    <Check color="primary" />
                  </IconButton>
                </>
              ) : (
                this.props.canPerformActions && (
                  <IconButton onClick={this.toggleEditName}>
                    <EditOutlined style={{ color: theme.palette.text.secondary }} />
                  </IconButton>
                )
              )}
              <IconButton onClick={this.closeDrawer}>
                <Close style={{ color: theme.palette.text.secondary }} />
              </IconButton>
            </Box>
          </Box>
        </DialogTitle>
        {feeSchedule ? (
          <>
            <Divider />
            <DD.Content>
              <DD.Row>
                <DD.Item fullWidth>
                  <DD.Value> {this.renderName()}</DD.Value>
                  {!this.editName && <DD.Label>Name</DD.Label>}
                </DD.Item>
              </DD.Row>
              <DD.Row>
                <DD.Item className={firstItem}>
                  <DD.Value>${feeSchedule.feeAmount}</DD.Value>
                  <DD.Label>Amount</DD.Label>
                </DD.Item>
                <DD.Item>
                  <DD.Value>{feeSchedule.feePercent}%</DD.Value>
                  <DD.Label>Percent</DD.Label>
                </DD.Item>
              </DD.Row>
              <DD.Row>
                <DD.Item className={firstItem}>
                  <DD.Value color={color}> {feeSchedule.status.toUpperCase()}</DD.Value>
                  <DD.Label>Status</DD.Label>
                </DD.Item>
                <DD.Item>
                  <DD.Value>
                    {feeSchedule.processor ? feeSchedule.processor : feeSchedule.app.name}
                  </DD.Value>
                  <DD.Label>{feeSchedule.processor ? 'Processor' : 'App'}</DD.Label>
                </DD.Item>
              </DD.Row>
              <DD.Row>
                {feeSchedule.account && (
                  <DD.Item className={firstItem}>
                    <DD.Value> {feeSchedule.account.name}</DD.Value>
                    <DD.Label>Account</DD.Label>
                  </DD.Item>
                )}
                <DD.Item>
                  <DD.Value>{FEE_TYPE_MAPPER[feeSchedule.type]}</DD.Value>
                  <DD.Label>Type</DD.Label>
                </DD.Item>
              </DD.Row>
              {feeSchedule.location && (
                <DD.Row>
                  <DD.Item className={firstItem}>
                    <DD.Value> {feeSchedule.location.name}</DD.Value>
                    <DD.Label>Location</DD.Label>
                  </DD.Item>
                </DD.Row>
              )}

              <Box mt={4} className={distributionRules}>
                <Typography variant="h6" component="h4" display="inline">
                  Distribution Rules
                </Typography>
              </Box>
              <List>
                {feeSchedule.rules.map((rule: FeeSchedule.IDistributions) => {
                  const fee = `${rule.feePercent}% + $${rule.feeAmount}`;
                  const name = rule.partner!.name;
                  return (
                    <ListItem key={rule.id} className={listItemUnderline}>
                      <ListItemText className={settingListItemKey} primary={name} />
                      <Typography className={settingListItemValue}>{fee}</Typography>
                    </ListItem>
                  );
                })}
              </List>
            </DD.Content>
          </>
        ) : (
          <Box className={this.props.classes.loadBar}>
            <CircularProgress />
          </Box>
        )}
      </Drawer>
    );
  }
}

export default withStyles(styles)(FeeScheduleDetailsDrawer);
