import React from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import RouterStore from 'stores/RouterStore';
import { inject } from 'mobx-react';

interface ScrollToTopProps extends RouteComponentProps {
  children: React.ReactNode;
  routerStore?: RouterStore;
  location: any;
  history: any;
}

/**
 * Resets the scroll position on every mount. Use this inside a Router by
 * making it the first child of the Router component, and then place the routes
 * as children of this component.
 * This component is also connected to the router store and calls its `setRoute`
 * method so that the router store knows the current route.
 */
@inject('routerStore')
class ScrollToTop extends React.Component<ScrollToTopProps> {
  componentDidMount() {
    this.props.routerStore!.setRoute(this.props.location, this.props.history);
  }
  componentDidUpdate(prevProps: ScrollToTopProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      // Set the route in the router store
      this.props.routerStore!.setRoute(this.props.location, this.props.history);
      // Reset the scroll position
      window.scrollTo(0, 0);
    }
  }

  render() {
    return this.props.children;
  }
}

export default withRouter(ScrollToTop);
