import React from 'react';
import { Box } from '@material-ui/core';
import { observer } from 'mobx-react';
import { Hardware, Inventory } from 'models';
import { useAddItem } from './useAddItem';
import Button from 'components/Button/Dialog/Button';
import OutlinedInput from 'components/Input/OutlinedInput/OutlinedInput';

export interface IAddItem {
  hardware: Hardware | null;
  submit: (items: Inventory[]) => void;
  fields: Array<{ name: string; label: string; rules?: string }>;
}
const AddItem = observer(({ submit, hardware, fields }: IAddItem) => {
  const { form } = useAddItem({ fields, hardware, submit });

  return (
    <Box>
      <form onSubmit={form.onSubmit}>
        <Box mb={2}>
          {fields.map((field: any) => (
            <Box key={field.name} display={'flex'} alignItems={'center'} mb={2}>
              <Box flexGrow={1}>
                {' '}
                <OutlinedInput
                  fullWidth
                  {...form.$(field.name).bind()}
                  required={field.required}
                  error={form.$(field.name).error}
                />
              </Box>
            </Box>
          ))}
        </Box>
        <Box mb={2} mt={4}>
          <Button variant="contained" color="primary" type="submit" disabled={form.hasError}>
            Add
          </Button>
        </Box>
      </form>
    </Box>
  );
});

export default AddItem;
