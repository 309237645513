import { FormControlLabel, Box, Link, Typography } from '@material-ui/core';
import { ModalStore } from 'stores';
import { Checkbox } from 'components/Checkbox/Checkbox';
import { ReferralPanel } from '../ReferralPanel/ReferralPanel';
import { useState } from 'react';
import { referralTermsBtnStyles } from './styles';
import moment from 'moment';
import { EDateFormat } from 'utils/helper';

interface BecomeAffiliateProps {
  modalStore: ModalStore;
  updateAffiliate: () => void;
}

const TERMS_MSG = (
  <Box>
    <em>{moment(new Date()).format(EDateFormat.DEFAULT)}</em>
    <br />
    <br />
    <Typography>
      These terms and conditions (“Terms”) govern your participation in the referral program
      (“Referral Program”) offered by Tippy (“Company”). By participating in the Referral Program,
      you agree to abide by these Terms.
    </Typography>
    <br />
    <b>Referral Process:</b>
    <br />
    <ol>
      <li>
        To initiate the referral process, the referrer must have an active Tippy user account.
      </li>
      <li>
        The referrer must refer individuals (“Referee(s)”) who do not currently have an active Tippy
        user account.
      </li>
      <li>
        Referees must sign up for a Tippy account and complete a minimum of 60 tips within 30 days
        of sign-up to qualify for the Referral Program.
      </li>
    </ol>
    <b>Eligibility:</b>
    <ol>
      <li>
        Referees must not have been a Tippy customer within the past 12 months of sign-up to qualify
        for the Referral Program.
      </li>
      <li>
        The referrer must be the first person to refer the referee to Tippy to qualify for the
        Referral Program.
      </li>
    </ol>
    <b>Reward Structure:</b>
    <ol>
      <li>
        Within thirty (30) calendar days of the referee meeting the benchmark (completing 60 tips
        within 30 days of sign-up), the referrer will receive compensation of $100 loaded to their
        Tippy wallet.
      </li>
      <li>
        Referees who qualify for the Referral Program will receive fifty-percent off their initial
        subscription year with Tippy.
      </li>
    </ol>
    <b>Claiming Rewards:</b>
    <ol>
      <li>
        Rewards earned through the Referral Program are not automatically paid. The referrer must
        claim their compensation by following the instructions provided by Tippy.
      </li>
      <li>Rewards are subject to verification and approval by Tippy.</li>
    </ol>
    <b>General Terms:</b>
    <ol>
      <li>Participation in the Referral Program is subject to review and approval by Tippy.</li>
      <li>
        Tippy reserves the right to modify, suspend, or terminate the Referral Program at any time
        without prior notice.
      </li>
      <li>
        Tippy reserves the right to disqualify any participant who violates these Terms or engages
        in fraudulent or abusive behavior.
      </li>
      <li>
        These Terms are governed by the laws of [Your Jurisdiction]. Any disputes arising under or
        in connection with these Terms shall be subject to the exclusive jurisdiction of the courts
        of [Your Jurisdiction].
      </li>
    </ol>
    <Typography>
      By participating in the Referral Program, you acknowledge that you have read, understood, and
      agreed to these Terms.
      <br />
      <br />
      If you have any questions about these Terms or the Services, please contact Tippy at{' '}
      <Link href="mailto:support@meettippy.com" color="primary">
        support@meettippy.com
      </Link>
      .
    </Typography>
  </Box>
);

export const BecomeAffiliate = ({ modalStore, updateAffiliate }: BecomeAffiliateProps) => {
  const [termsChecked, setTermsChecked] = useState(false);

  return (
    <ReferralPanel
      title="Become an affiliate"
      text="You can become an affiliate by generating your link for referrals."
      actionBtn={{
        disabled: !termsChecked,
        text: 'Generate',
        action: updateAffiliate,
      }}>
      <Box>
        <FormControlLabel
          control={
            <Checkbox
              checked={termsChecked}
              dataCy="set-affiliate-check"
              onChange={() => {
                if (modalStore.confirmDialog) {
                  return;
                }
                setTermsChecked(!termsChecked);
              }}
            />
          }
          label={
            <Box sx={referralTermsBtnStyles}>
              I agree to the{' '}
              <Box
                className="terms"
                component="span"
                onClick={() => {
                  modalStore.confirm('Referral Program Terms of Service', TERMS_MSG, {
                    cancelLabel: 'Close',
                    hideConfirm: true,
                  });
                }}>
                Terms and Conditions
              </Box>
            </Box>
          }
        />
      </Box>
    </ReferralPanel>
  );
};
