import { Typography } from '@material-ui/core';
import theme from 'containers/App/theme';

const Subtitle = ({ children, mt = 2 }: { children: React.ReactNode; mt?: number }) => {
  if (!children) return null;
  return (
    <Typography variant={'body2'} align="center" style={{ marginTop: theme.spacing(mt) }}>
      {children}
    </Typography>
  );
};

export default Subtitle;
