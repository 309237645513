import { CreateRefundRequestData, ICreateRefundRequest } from 'models/Refunds';
import React, { useEffect, useState } from 'react';
import Api, { getErrorMsg } from 'api';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const dvr = require('mobx-react-form/lib/validators/DVR');
const MobxReactForm = require('mobx-react-form').default;
import validatorjs from 'validatorjs';
import { useStores } from 'containers/App/App';

const hooks = {
  onSuccess: (form: any) => {},
};

type UseRefundForm = [
  any,
  boolean,
  boolean,
  (request: Partial<CreateRefundRequestData>) => void,
  (event: React.ChangeEvent<HTMLTextAreaElement>) => void,
];
export function useRefundForm(
  total: string,
  closeModal: () => void,
  onRefund?: (status: string) => void,
): UseRefundForm {
  const rules: any = {
    amount: {
      function: (value: string) => {
        if (parseFloat(value) > parseFloat(total)) return false;
        return true;
      },
      message: 'Amount cannot be higher than maximum amount',
    },
    partialRefund: {
      function: (value: string) => {
        const amount = parseFloat(value);
        const _total = parseFloat(total);
        const diff = _total - amount;
        if (diff < 1 && diff !== 0) return false;
        return true;
      },
      message: 'For partial refunds at least $1 of tip must remain',
    },
    reason: {
      function: (value: any) => {
        if (form.$('reason').value.toString() === 'OTHER' && !value) {
          return false;
        }
        return true;
      },
      message: 'This field is required',
    },
  };

  const plugins = {
    dvr: dvr({
      package: validatorjs,
      extend: ({ validator, form }: { validator: any; form: any }) => {
        Object.keys(rules).forEach((key) =>
          validator.register(key, rules[key].function, rules[key].message),
        );
      },
    }),
  };

  const fields = [
    {
      name: 'customer',
      label: 'Refund To',
    },
    {
      name: 'amount',
      label: 'Amount',
      rules: 'amount|partialRefund|required',
    },
    {
      name: 'reason',
      label: 'Refund Reason ',
      rules: 'required',
      hooks: {
        onChange: (field: any) => {
          if (form.$(field.name).value === 'OTHER') {
            form.add({
              name: 'customReason',
              label: 'Reason for refund',
              rules: 'required',
              hooks: {
                onChange: (field: any) => {
                  validateForm(field.name);
                },
              },
            });
            form.$('customReason').validate({ showErrors: true });
          } else {
            if (form.has('customReason')) {
              form.del('customReason');
            }
          }
          validateForm(field.name);
        },
      },
    },
  ];

  const [form, setForm] = useState(new MobxReactForm({ fields }, { plugins, hooks }));
  const [submitting, setSubmitting] = useState(false);
  const [refundDisabled, setRefundDisabled] = useState(true);
  const { toastStore } = useStores();

  useEffect(() => {
    form.$('amount').set(total);
  }, []);

  async function validateForm(field: string) {
    const validatedForm = await form.validate();
    form.$(field).validate({ showErrors: true });
    if (!validatedForm.isValid || submitting) return setRefundDisabled(true);
    setRefundDisabled(false);
  }

  function updateAmount(event: React.ChangeEvent<HTMLTextAreaElement>) {
    let amount = event.target.value.trim();
    if (isNaN(+amount)) return;
    const indexOfDecimal = amount.indexOf('.');
    const keepDecimals = 2;
    if (~indexOfDecimal) amount = amount.slice(0, indexOfDecimal + keepDecimals + 1);
    form.$('amount').set(amount);
    validateForm('amount');
  }

  async function createRequest(refundRequest: Partial<CreateRefundRequestData>) {
    let amount = form.$('amount').value;
    amount = amount ? amount : undefined;
    let reason = form.$('reason').value;
    reason = reason === 'OTHER' ? form.$('customReason').value : reason;
    const { tipId, accountId } = refundRequest!;

    const refundRequestBody = { tipId, accountId, amount, reason } as ICreateRefundRequest;

    try {
      setSubmitting(true);
      const { data } = await Api.tips.createRefundRequest(refundRequestBody);
      if (data && data.data && data.data.status) {
        onRefund && onRefund(data.data.status);
      }
      toastStore.success('Request created successfully');
      closeModal();
    } catch (error: any) {
      toastStore.error(getErrorMsg(error));
    } finally {
      setSubmitting(false);
    }
  }

  return [form, refundDisabled, submitting, createRequest, updateAmount];
}
