import { useStores } from 'containers/App/App';
import { ChangeEvent, useState } from 'react';
import { BlStations } from '../../containers/LocationDetails/QrCodes/blStations';
import { AddStation, IStation } from 'models/Station';
import { BlAddStation } from './blAddStation';
import { useStationNavigation } from 'containers/LocationDetails/QrCodes/useStationNavigation';
import { getErrorMsg } from 'api';

type UseAddStationReturn = [
  boolean,
  boolean,
  any,
  IStation | undefined,
  boolean,
  (e: any, name: string) => void,
  (e: any) => Promise<void>,
  (state?: AddStation | undefined) => void,
  (e: ChangeEvent<HTMLInputElement>) => void,
  (
    event: React.ChangeEvent<{
      value: unknown;
    }>,
  ) => void,
  () => number,
  () => void,
  () => void,
];

export function useAddStation(): UseAddStationReturn {
  const { stationsStore, toastStore } = useStores();
  const [savingStation, setSavingStation ] = useState(false);
  const { stationNavigation } = useStationNavigation();
  const {
    isPoolsEnabled,
    loadingStation,
    stationForm,
    station,
    previousStationName,
    setAddStationState,
    setStateField,
    validateStationFormField,
  } = stationsStore!;

  function setStationName(e: ChangeEvent<HTMLInputElement>) {
    const { value } = e.target;
    setStateField('name', value);
  }

  function setWayToTip(event: React.ChangeEvent<{ value: unknown }>) {
    const value = event.target.value as unknown as number;
    setStateField('wayToTip', value);
  }

  async function onSubmit(e: any) {
    e.preventDefault();
    if (!stationForm) return;
    const formValidation = await stationForm.validate({ showErrors: true });
    if (BlStations.isFormValid(formValidation)) {
      setSavingStation(true);
      if (previousStationName) {
        await updateStation(station!);
      } else {
        await createStation(station!);
      }
      setSavingStation(false);
    }
  }

  async function createStation(station: IStation) {
    try {
      await BlStations.createStation(station);
      stationNavigation.resetStationState();
      toastStore.success('Station created successfully');
    } catch (e: any) {
      toastStore.error(getErrorMsg(e));
    }
  }

  async function updateStation(station: IStation) {
    try {
      await BlStations.updateStation(station!);
      stationNavigation.resetStationState();
      toastStore.success('Station Updated successfully');
    } catch (e: any) {
      toastStore.error(getErrorMsg(e));
    }
  }

  function validateWayToTip(e: any, name: string) {
    if (e.target.value !== 0) validateStationFormField(name);
  }

  function countStationUsers() {
    if (!stationsStore.station) return 0;
    return BlAddStation.countStationUsers(stationsStore.station!.items);
  }

  function openAddStationUsers() {
    stationNavigation.openUsers();
  }

  function openAddStationPoolsAndGroups() {
    stationNavigation.openPoolsOrGroups();
  }

  function closeStationDrawer() {
    stationNavigation.resetStationState();
  }

  return [
    loadingStation,
    savingStation,
    stationForm,
    station,
    isPoolsEnabled,
    validateWayToTip,
    onSubmit,
    closeStationDrawer,
    setStationName,
    setWayToTip,
    countStationUsers,
    openAddStationUsers,
    openAddStationPoolsAndGroups,
  ];
}
