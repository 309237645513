import { User } from './User';

export enum Topic {
  ACCOUNTING_PREFERENCES = 'accounting_preferences',
  BILLING = 'billing',
  DEVICES = 'devices',
  OTHER = 'other',
}

/** Represents an account note */
export interface Note {
  id: number;
  topic: Topic;
  createdAt: string;
  note: string;
  reminders: Reminder[];
  createdBy?: Pick<User, 'firstName' | 'lastName' | 'id'>;
  updatedBy?: Pick<User, 'firstName' | 'lastName' | 'id'>;
}

/** Represents a reminder for a note */
export interface Reminder {
  id: number;
  dateTime: string;
  status: string;
  createdById: number;
}

/** Represents an account note in note listing */
export type ReminderListItem = Pick<Reminder, 'id' | 'dateTime'>;
