import { Box, IconButton, Paper, Tooltip, Typography } from '@material-ui/core';
import { WithStyles, withStyles } from '@material-ui/styles';
import Api, { getErrorMsg } from 'api';
import { Cached } from 'mdi-material-ui';
import { action, computed, flow, makeObservable } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';
import { inject, WithModalStore, WithToastStore } from 'stores';
import styles from './styles';

interface CodePanelProps extends WithStyles<typeof styles>, WithToastStore, WithModalStore {
  title: string;
  accountId?: number;
  canRegenerateCode?: boolean;
  code: string;
  onCodeUpdate?: () => void;
}

@inject('toastStore', 'modalStore')
@observer
class CodePanel extends React.Component<CodePanelProps> {
  public constructor(props: CodePanelProps) {
    super(props);
    makeObservable(this);
  }

  /** Account code */
  @computed public get code() {
    return this.props.code;
  }

  /** Can this code be updated */
  @computed public get canRegenerateCode() {
    return this.props.canRegenerateCode;
  }

  /** Calls the API to generate new location code */
  @action.bound public generateNewLocationCode = flow(function* (this: CodePanel) {
    try {
      const confirm = yield this.props.modalStore!.confirm(
        'Generate new Account Code',
        `Are you sure you want to generate a new code for this account?`,
        { confirmLabel: 'Yes', cancelLabel: 'No' },
      );
      if (confirm && this.props.accountId) {
        yield Api.core.generateNewAccountCode(this.props.accountId);
        this.props.onCodeUpdate && this.props.onCodeUpdate();
      }
    } catch (e: any) {
      this.props.toastStore!.error(getErrorMsg(e));
    }
  });

  render() {
    return (
      <Paper elevation={0} style={{ height: '100%' }}>
        <Box
          p={2}
          pb={3}
          display="flex"
          flexDirection="column"
          alignItems="center"
          style={{ minHeight: '171px', minWidth: '194px' }}>
          {this.canRegenerateCode && (
            <Box display="flex" alignSelf={'flex-end'}>
              <Tooltip placement="right-end" title="Generate new account code">
                <IconButton size="small" onClick={this.generateNewLocationCode}>
                  <Cached color="primary" fontSize="small" />
                </IconButton>
              </Tooltip>
            </Box>
          )}
          <Box display="flex" flexGrow={1} alignItems="flex-end">
            <Typography variant="h6" color="primary">
              {this.code}
            </Typography>
          </Box>
          <Box display="flex" flexGrow={1} alignItems="flex-end">
            <Typography color="textSecondary">{this.props.title}</Typography>
          </Box>
        </Box>
      </Paper>
    );
  }
}

export default withStyles(styles)(CodePanel);
