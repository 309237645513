import React from 'react';

import { observable, action, makeObservable } from 'mobx';
import { observer } from 'mobx-react';

import Api from 'api';
import { inject, WithUserStore } from 'stores';

import {
  PlaidLink,
  PlaidLinkOnEventMetadata,
  PlaidLinkStableEvent,
  PlaidLinkError,
  PlaidLinkOnExitMetadata,
  PlaidLinkOnSuccessMetadata
} from 'react-plaid-link';

interface PlaidLinkProps extends WithUserStore {
  onExit?: (error?: string) => void;
  onSuccess: (publicToken: string, metadata: PlaidLinkOnSuccessMetadata) => void;
  token?: string;
  publicToken?: string;
  children: React.ReactNode;
  fallback?: React.ReactNode; // What to display while we're fetching the link token
  className?: string;
}

/**
 * A utility wrapper for integrating Plaid Link with most props
 * already pre-filled from config.
 */
@inject('userStore')
@observer
class TippyPlaidLink extends React.Component<PlaidLinkProps> {
  constructor(props: PlaidLinkProps){
    super(props);
    makeObservable(this);
  }
  /** The plaid link token */
  @observable public linkToken?: string = this.props.token;

  /** The current error message */
  @observable public errorMsg?: string;

  /** Fetches the link token from the server */
  @action.bound public async fetchLinkToken() {
    try {
      const resp = await Api.core.getLinkToken();
      const { linkToken } = resp.data.data;
      this.linkToken = linkToken;
      window.localStorage.setItem('plaidLinkToken', linkToken);
    } catch (e: any) {
      this.errorMsg = e.toString();
    }
  }

  /** Logs each Plaid event to the backend */
  @action.bound handlePlaidEvent(name: PlaidLinkStableEvent | string , metadata: PlaidLinkOnEventMetadata) {
    const userId = this.props.userStore!.user!.id;
    Api.core.createBankEvent(userId, name, metadata);
  }

  /** Handler for handling plaid errors */
  @action.bound handlePlaidExit(error: null | PlaidLinkError, metadata: PlaidLinkOnExitMetadata) {
    if (error && error.display_message) {
      this.errorMsg = error.display_message;
      const userId = this.props.userStore!.user!.id;
      Api.core.createBankEvent(userId, error.error_code, metadata);
      this.props.onExit && this.props.onExit(this.errorMsg);
    }
    this.props.onExit && this.props.onExit();
  }

  componentDidMount() {
    // Only fetch the token if it's not already passed via props
    if (!this.props.token) {
      this.fetchLinkToken();
    }
  }

  render() {
    if (!this.linkToken) {
      return this.props.fallback || null;
    }
    return (
      <PlaidLink
        token={this.linkToken}
        publicKey={this.props.publicToken}
        className={this.props.className}
        onEvent={this.handlePlaidEvent}
        onExit={this.handlePlaidExit}
        onSuccess={this.props.onSuccess}
        style={{
          padding: '0',
          outline: 'none',
          border: '0px solid #000',
          width: '100%',
        }}>
        {this.props.children}
      </PlaidLink>
    );
  }
}

export default TippyPlaidLink;
