import axios from 'axios';
import qs from 'qs';
import config from 'config';

/**
 * Takes an object with lat and long fields and uses the Google timezone
 * API to look up the timezone and returns it.
 * @param lat The latitude
 * @param long The longitude
 */
export async function getTimezone({ lat, long }: { lat?: number; long?: number }): Promise<string> {
  if (!lat || !long) {
    return 'Unknown';
  }
  const queryString = qs.stringify({
    location: `${lat},${long}`,
    key: config.google.apiKey,
    timestamp: Math.floor(Date.now() / 1000),
  });
  const resp = await axios.get(`https://maps.googleapis.com/maps/api/timezone/json?${queryString}`);
  if (resp.data && resp.data.timeZoneId) {
    return resp.data.timeZoneId;
  } else {
    return 'Unknown';
  }
}
