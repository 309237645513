import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { observable, action, flow, computed, makeObservable } from 'mobx';
import { observer } from 'mobx-react';
import { WithStyles, withStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';

import Api, { getErrorMsg } from 'api';
import { inject, WithUserStore, WithToastStore } from 'stores';
import * as models from 'models';

import TipSettingsDrawer from 'components/TipSettingsDrawer';

import DashboardLayout from 'containers/DashboardLayout';

import DP from 'components/DashPanel';

import styles from './styles';
import LocationInfo from './LocationInfo';
import TabBar from 'components/TabBar/TabBar';
import { LocationQrCodes } from './QrCodes/LocationQrCodes';
import AccountLogo from 'components/AccountLogo/AccountLogo';
import EditableTitle from 'components/EditableTitle/EditableTitle/EditableTitle';
import { faGearComplex } from '@fortawesome/pro-regular-svg-icons';

/** The variables that are matched in the URL (match.params) */
interface LocationDetailsMatchParams {
  locationId: string;
}

/** Here we define what kind of props this component takes */
type LocationDetailsProps = WithStyles<typeof styles> & // Adds the classes prop
  RouteComponentProps<LocationDetailsMatchParams> & // Adds the router props (history, match, location)
  WithUserStore & // Adds the userStore prop
  WithToastStore; // Adds the toastStore prop

/**
 * Container for displaying single location related panels
 */

enum Tab {
  INFO = 'info',
  QR_CODES = 'qr_codes',
}

@inject('userStore', 'toastStore')
@observer
class LocationDetails extends React.Component<LocationDetailsProps> {
  public constructor(props: LocationDetailsProps) {
    super(props);
    makeObservable(this);
    // Make the params from the URL match observable. We do that so that
    // we can reference the accountId from compted values. We do this in
    // the constructor so that it's available before the first render.
    this.matchParams = this.props.match.params;
  }

  /** We store the account id from the router into this observable */
  @observable public matchParams: LocationDetailsMatchParams;

  /** Location based on locationId prop passed as match param */
  @observable public location?: models.Location;

  @observable public shippingAddress?: models.ShippingAddress;

  /** Account for this location */
  @observable public account?: models.Account;

  @observable public isDormant?: boolean;

  @observable public poolsEnabled?: boolean;

  /** Is settings drawer open? */
  @observable public settingsOpen = false;

  @computed public get locationId(): number {
    return parseInt(this.matchParams.locationId);
  }

  /** Is auth user an admin? */
  @computed public get isAdmin() {
    return this.props.userStore && this.props.userStore.isAdmin;
  }

  @observable selectedTab: Tab = Tab.INFO;

  @action.bound private openSettingsModal() {
    this.settingsOpen = true;
  }

  @action.bound private closeSettingsModal() {
    this.settingsOpen = false;
  }

  @action.bound public getLocation = flow(function* (this: LocationDetails) {
    const resp = yield Api.core.getLocation(this.locationId);
    this.location = resp.data.data;
  });

  @action.bound public getLocationAndAccount = flow(function* (this: LocationDetails) {
    yield this.getLocation();
    // After we get the location, we can also fetch its corresponding account
    this.getAccount();
  });

  @action.bound public getShippingAddress = flow(function* (this: LocationDetails) {
    const resp = yield Api.core.getShippingAddress(this.locationId);
    const shippingAddress = resp?.data?.data.filter((e: models.ShippingAddress) => e.default);
    this.shippingAddress = shippingAddress[0];
  });

  @action.bound public getAccount = flow(function* (this: LocationDetails) {
    if (!this.location) return;
    const resp = yield Api.core.getAccount(this.location.accountId);
    this.account = resp.data.data;
  });

  @action.bound public onLocationNameChange = (response: any) => {
    const newLocationName = response?.data?.data;
    if (newLocationName) {
      this.location = newLocationName;
      this.props.toastStore?.success('Location name updated successfully');
    }
  };

  /** Change location name */
  public changeLocationName = async (newTitle: string) => {
    return await Api.core.updateLocation(this.locationId, { name: newTitle });
  };

  async componentDidMount() {
    this.getLocationAndAccount();

    if (this.isAdmin) {
      this.getDormant();
    }
  }

  @action.bound public getDormant = flow(function* (this: LocationDetails) {
    try {
      const resp = yield Api.core.getLocationDormant(this.locationId);
      const data = resp && resp.data && resp.data.data;
      this.isDormant = data.isDormant;
    } catch (e) {
      this.props.toastStore && this.props.toastStore.error(getErrorMsg(e));
      this.isDormant = false;
      try {
        yield Api.core.setLocationDormant(this.locationId, false);
      } catch (e) {
        this.props.toastStore && this.props.toastStore.error(getErrorMsg(e));
      }
    }
  });

  @action.bound public setDormant = flow(function* (this: LocationDetails, isDormant: boolean) {
    try {
      const resp = yield Api.core.setLocationDormant(this.locationId, isDormant);
      this.isDormant = isDormant;
    } catch (e) {
      this.props.toastStore && this.props.toastStore.error(getErrorMsg(e));
    }
  });

  @action.bound public setPoolsEnabled = (poolsEnabled: boolean) => {
    this.poolsEnabled = poolsEnabled;
  };

  @action.bound selectTab(tab: Tab) {
    this.selectedTab = tab;
  }

  @computed private get pageTitle(): string {
    if (!this.location) {
      return '';
    }

    const { locationName, name } = this.location;
    return this.isAdmin ? locationName || name : name;
  }

  @computed public get tabs() {
    return [
      {
        label: 'Info',
        onClick: () => this.selectTab(Tab.INFO),
        selected: this.selectedTab === Tab.INFO,
      },
      {
        label: 'Qr Codes',
        onClick: () => this.selectTab(Tab.QR_CODES),
        selected: this.selectedTab === Tab.QR_CODES,
      },
    ];
  }

  render() {
    return (
      <DashboardLayout>
        <Box
          mb={3}
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between">
          {this.location && (
            <Box width={1} display="flex" flexDirection="row" gridGap={2} mr={2}>
              {this.account?.logo && <AccountLogo account={this.account} />}
              <EditableTitle
                title={this.pageTitle}
                // Only Admin can change Location name
                editable={!!this.isAdmin}
                submit={this.changeLocationName}
                onSuccess={this.onLocationNameChange}
              />
            </Box>
          )}
          <Box ml={'auto'}>
            <DP.IconButton
              primary
              onClick={this.openSettingsModal}
              fontAwesomeIcon={{ icon: faGearComplex, height: 24 }}
              tooltip="Location settings"
            />
          </Box>
        </Box>
        <TabBar mb={3} tabs={this.tabs} />
        {this.location && (
          <TipSettingsDrawer
            entity="location"
            id={this.location.id}
            isOpen={this.settingsOpen}
            onClose={() => this.closeSettingsModal()}
            isDormant={this.isDormant}
            setDormant={(isDormant: boolean) => this.setDormant(isDormant)}
            reinitializeOnOpen={true}
          />
        )}
        {this.selectedTab === Tab.INFO && (
          <LocationInfo
            location={this.location}
            account={this.account}
            updateLocation={this.getLocation}
          />
        )}
        {this.selectedTab === Tab.QR_CODES && (
          <LocationQrCodes accountId={this.account?.id} locationId={this.locationId} />
        )}
      </DashboardLayout>
    );
  }
}

export default withStyles(styles)(LocationDetails);
