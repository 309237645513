import { Theme, createStyles } from '@material-ui/core';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default (theme: Theme) =>
  createStyles({
    root: {
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
    },
    itemsBox: {
      flexGrow: 1,
      marginBottom: theme.spacing(1),
    },
    dragged: {
      outline: `1px dashed ${theme.palette.grey[500]}`,
    },
  });
