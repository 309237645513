import { IconDefinition, faChevronRight, faPlus } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon, FontAwesomeIconProps } from '@fortawesome/react-fontawesome';
import {
  Box,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemSecondaryActionProps,
  ListItemText,
  Typography,
} from '@mui/material';
import useStyles, { styles } from './styles';
import React from 'react';
import theme from 'containers/App/theme';
import clsx from 'clsx';

interface IListButtonProps {
  onClick: () => void;
  startIcon?: {
    className?: string;
    icon: IconDefinition;
  };
  endIcon?: {
    className?: string;
    dark?: boolean;
    hide?: boolean;
  } & Partial<FontAwesomeIconProps>;
  secondaryAction?: ListItemSecondaryActionProps;
  children?: string | React.ReactNode;
}
const ListButton = ({
  onClick,
  startIcon,
  endIcon,
  secondaryAction,
  children,
}: IListButtonProps) => {
  const { endIconStyles, darkIcon } = useStyles();
  const childIsString = secondaryAction?.children?.constructor === String;

  return (
    <ListItem onClick={onClick} sx={styles.listItemStyles}>
      {startIcon && (
        <ListItemIcon sx={styles.startIconStyles}>
          <FontAwesomeIcon icon={startIcon?.icon || faPlus} fontSize={24} />
        </ListItemIcon>
      )}
      <ListItemText sx={styles.listItemTextStyles}>{children}</ListItemText>
      <ListItemSecondaryAction
        className={secondaryAction?.className}
        {...secondaryAction}
        sx={{ ...styles.secondaryActionStyles, ...secondaryAction?.sx }}>
        {secondaryAction?.children && childIsString ? (
          <Typography
            sx={{
              marginRight: 2,
              ...theme.typography.body2,
            }}
            component={'span'}>
            {secondaryAction?.children}
          </Typography>
        ) : (
          <Box mr={2}>{secondaryAction?.children}</Box>
        )}
        {!endIcon?.hide && (
          <FontAwesomeIcon
            icon={endIcon?.icon || faChevronRight}
            fontSize={endIcon?.fontSize || 18}
            className={clsx(endIcon?.className || endIconStyles, endIcon?.dark && darkIcon)}
          />
        )}
      </ListItemSecondaryAction>
    </ListItem>
  );
};

export default ListButton;
