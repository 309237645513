export default interface IMFAResult {
  /**
   * MFA type
   */
  type: MFAType;
  /**
   * MFA token expiration date
   */
  expiresAt?: string;
  /**
   * MFA factors definition
   */
  factors: IMFAFactor[];
}

export interface IMFARequest {
  type: MFAType;
  factors: IMFAFactorRequest[];
}

export interface IMFAFactorRequest {
  channel: MFAChannel;
  code: string;
}

export interface IMFAFactor {
  /**
   * MFA Factor channel
   */
  channel: MFAChannel;
  /**
   * MFA Factor destination that code has been or will be sent to
   */
  destination?: string;
  /**
   * Represents if the MFA code has been sent during the request to endpoint
   */
  sent: boolean;
  /**
   * Error message if mfa code validation failed when MFA object was provided in request
   */
  error?: string;

  /**
   * MFA token expiration date
   */
  expiresAt?: string;
}

/**
 * MFA Types
 */
export enum MFAType {
  USER_LOGIN = 'user_login',
  CONFIRM_EMAIL = 'confirm_email',
  CHANGE_PASSWORD = 'change_password',
  CONFIRM_PHONE = 'confirm_phone',
}

/**
 * MFA Channels
 */
export enum MFAChannel {
  PHONE = 'phone',
  EMAIL = 'email',
}

export interface IMFASendRequest {
  type: MFAType;
  channels: MFAChannel[];
}
