import React from 'react';
import { computed, observable, action, makeObservable } from 'mobx';
import { observer } from 'mobx-react';
import { WithStyles, withStyles } from '@material-ui/core/styles';
import { Box, Divider } from '@material-ui/core';
import { Note } from 'models';

import NoteStore from './NoteStore';

import DD from 'components/DashDrawer';

import NoteList from './NoteList';
import NoteDetails from './NoteDetails';
import EditNote from './EditNote';
import ReminderList from './ReminderList';
import AddReminder from './AddReminder';

import styles from './styles';
import Skeleton from '@material-ui/lab/Skeleton';

/** Props for this component */
interface NoteDrawerProps extends WithStyles<typeof styles> {
  accountId: number;
  onClose: () => void;
}

/**
 * This is a top level container for account notes. By default it displays list of all
 * notes for a given account that is passed via props. It utilizes NoteStore for routing,
 * ui state management and api communication.
 */
@observer
class NoteDrawer extends React.Component<NoteDrawerProps> {
  constructor(props: NoteDrawerProps) {
    super(props);
    makeObservable(this);
    this.noteStore = new NoteStore(this.props.accountId);
  }

  /** Note store */
  @observable public noteStore: NoteStore;

  /** List of notes */
  @computed public get notes(): Note[] | undefined {
    return this.noteStore.notes;
  }

  /** if users clicks new note, clear all references to previously selected note if any */
  @action.bound public createNote() {
    this.noteStore.clearCurrentNote();
    this.noteStore.routeTo('/editNote');
  }

  /** Initialize noteStore */
  componentDidMount() {
    this.noteStore.init();
  }

  /** Run noteStore cleanup job */
  componentWillUnmount() {
    this.noteStore.clean();
  }

  renderLoadingSkeleton = () => (
    <>
      <Box mt={1} mb={1} display="flex" flexDirection="row" justifyContent="space-between">
        <Skeleton variant="text" width={180} height={40} />
        <Skeleton variant="text" width={100} height={40} />
      </Box>
      <Divider />
    </>
  );

  render() {
    const location = this.noteStore.location;
    return (
      <DD>
        <Box>
          {/* Add/edit a note: */}
          <DD.SubDrawer display={location === '/editNote'}>
            <EditNote noteStore={this.noteStore} />
          </DD.SubDrawer>
          {/* Show currently selected note: */}
          <DD.SubDrawer display={location === '/noteDetails'}>
            <NoteDetails noteStore={this.noteStore} />
          </DD.SubDrawer>
          {/* List of note reminders: */}
          <DD.SubDrawer display={location === '/reminderList'}>
            <ReminderList noteStore={this.noteStore} />
          </DD.SubDrawer>
          {/* Add a reminder: */}
          <DD.SubDrawer display={location === '/addReminder'} hiddienBoxShadow>
            <AddReminder noteStore={this.noteStore} />
          </DD.SubDrawer>
          {/* By default show list of notes: */}
          <NoteList noteStore={this.noteStore} onClose={this.props.onClose} />
          <DD.FabButton right={4} onClick={this.createNote} />
        </Box>
      </DD>
    );
  }
}

export default withStyles(styles)(NoteDrawer);
