import { useEffect, useState } from 'react';
import { getErrorMsg } from 'api';
import { useStores } from 'containers/App/App';
import { AddStation, IStation } from 'models/Station';
import { BlStations } from '../blStations';
import { ModalStore } from 'stores';
import { useStationNavigation } from '../useStationNavigation';

type UseStationsPanelReturn = [
  Array<IStation> | undefined,
  ModalStore,
  string | undefined,
  (state: AddStation, station?: IStation) => void,
  (station: IStation) => void,
  (station: IStation) => void,
  (id?: string)=>void
];

export function useStationsPanel(): UseStationsPanelReturn {
  const { stationsStore, toastStore, modalStore } = useStores();
  const [stationId, setStationId] = useState<string|undefined>(undefined);
  const { stationNavigation } = useStationNavigation();
  const { stations, locationId, addStationState, setStations } = stationsStore!;
  const { openQrCode } = stationNavigation;

  useEffect(() => {
    fetchStations();
  }, [locationId]);

  useEffect(() => {
    if (addStationState === undefined) {
      setStations(undefined);
      fetchStations();
    }
  }, [addStationState]);

  async function fetchStations() {
    if (!locationId) return;
    try {
      const stations = await BlStations.getStations(locationId);
      setStations(stations);
    } catch (e: any) {
      toastStore.error(getErrorMsg(e));
    }
  }

  async function setAddStationStateAndInitialize(state: AddStation, station?: IStation) {
    if (!station) {
      stationsStore.initializeNewStation();
    } else if (state === AddStation.QR_CODE) {
      stationsStore.initializeStationForQrCode(station);
    } else {
      await stationsStore.initializeEditStation(station);
    }
    stationsStore.setAddStationState(state);
  }

  async function deleteStation(station: IStation) {
    const deleteStation = await modalStore.confirm(
      'Delete Station',
      `Are you sure you want to delete station: ${station.name}?`,
    );
    if (!deleteStation) return;
    try {
      await BlStations.deleteStation(station);
      toastStore.success('Station deleted successfully');
      fetchStations();
    } catch (e: any) {
      toastStore.error(getErrorMsg(e));
    }
  }

  return [stations, modalStore, stationId, setAddStationStateAndInitialize, deleteStation, openQrCode, setStationId];
}
