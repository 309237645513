import { Theme, createStyles, makeStyles } from '@material-ui/core';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    isSelected: {
      height: 'auto',
      whiteSpace: 'unset',
      '& .MuiChip-label': {
        whiteSpace: 'unset',
        paddingTop: theme.spacing(0.58),
        paddingBottom: theme.spacing(0.58),
      },
    },
    chip: {
      margin: theme.spacing(0.5, 0.5),
      backgroundColor: theme.palette.primary.light,
      fontSize: '16px',
      color: theme.palette.text.primary,
      maxWidth: `calc(100vw - ${theme.spacing(2)}px - ${theme.spacing(2)}px)`,
      overflow: 'hidden',
      '&:focus': {
        backgroundColor: theme.palette.primary.light,
      },
    },
    chipDeleteIcon: {
      marginRight: theme.spacing(0.75),
      width: theme.spacing(2),
      height: theme.spacing(2),
      color: theme.palette.common.black,
    },
    field: {
      width: '100%',
      '& .MuiInputBase-root': {
        display: 'flex',
        flexWrap: 'wrap',
        paddingBottom: '0',
        paddingRight: theme.spacing(0.5),
      },
    },
    popper: {
      marginLeft: theme.spacing(-2),
      marginTop: theme.spacing(2),
      paddingRight: theme.spacing(10),
      zIndex: 1400,
    },
    popperFullWidth: {
      paddingRight: theme.spacing(0),
    },
  }),
);
