import { Box, DialogActions, DialogContent, DialogTitle, Typography } from '@material-ui/core';
import Api, { getErrorMsg } from 'api';
import AffiliateSearch from 'components/AffiliateSearch';
import Button from 'components/Button/Dialog/Button';
import LocationSearch from 'components/LocationSearch';
import theme from 'containers/App/theme';
import { action, computed, flow, observable, makeObservable } from 'mobx';
import { observer } from 'mobx-react';
import { Affiliate, Location } from 'models';
import moment from 'moment';
import React from 'react';
import { inject, WithToastStore } from 'stores';

interface CreateConversionModalProps extends WithToastStore {
  closeModal: () => void;
}

/** A component that displays a field for searching for affiliates */
@inject('toastStore')
@observer
class CreateConversionModal extends React.Component<CreateConversionModalProps> {
  constructor(props: CreateConversionModalProps) {
    super(props);
    makeObservable(this);
  }

  /** Selected location and affiliate */
  @observable public location: Location | null = null;
  @observable public affiliate: Affiliate | null = null;

  @observable public submitting = false;

  @computed get isFormValid() {
    return Boolean(this.location && this.affiliate);
  }

  /** We only allow an affiliate to be assigned to locations that are not older than one month */
  @computed get oneMonthAgo() {
    return moment().subtract(31, 'days').format('YYYY-MM-DD');
  }

  @action.bound private setLocation(location: Location | null) {
    this.location = location;
  }

  @action.bound private setAffiliate(affiliate: Affiliate | null) {
    this.affiliate = affiliate;
  }

  @action.bound public createConversion = flow(function* (this: CreateConversionModal) {
    try {
      this.submitting = true;
      yield Api.marketing.createConversion({
        locationId: this.location!.id,
        affiliateId: this.affiliate!.id,
      });
      yield Api.marketing.setConversionsStatusToStartCountingTips([this.location!.id]);
      this.props.toastStore!.push({
        type: 'success',
        message: `Affiliate was successfully assigned to the location`,
      });
      this.props.closeModal();
    } catch (e: any) {
      this.props.toastStore!.push({ type: 'error', message: getErrorMsg(e) });
    } finally {
      this.submitting = false;
    }
  });

  render() {
    return (
      <Box minWidth={380} pb={3}>
        <DialogTitle disableTypography>
          <Typography style={{ fontSize: 28 }}>Assign an affiliate to a location</Typography>
        </DialogTitle>
        <DialogContent>
          <LocationSearch label="Location name" onlyUnconverted onChange={this.setLocation} />
          <Box mt={2} mb={4}>
            <AffiliateSearch onChange={this.setAffiliate} label="Affiliate name or code" />
          </Box>
        </DialogContent>
        <DialogActions style={{ padding: theme.spacing(3), paddingTop: 0, paddingBottom: 0 }}>
          <Button onClick={this.props.closeModal}>Cancel</Button>
          <Button
            variant="contained"
            onClick={this.createConversion}
            disabled={!this.isFormValid || this.submitting}>
            Assign
          </Button>
        </DialogActions>
      </Box>
    );
  }
}

export default CreateConversionModal;
