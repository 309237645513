import React from 'react';
import { observer } from 'mobx-react';
import { action, flow, computed, observable, makeObservable } from 'mobx';

import SettingsStore from './SettingsStore';
import { KioskMode, Processor, TipSettings, AccountTransparency, Industry } from 'models';
import { KIOSK_TEXT } from './KioskModeText';

import {
  DialogContent,
  DialogTitle,
  IconButton,
  Box,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  DialogContentText,
  Divider,
  DialogActions,
} from '@material-ui/core';

import { Check } from 'mdi-material-ui';

import { WithStyles, withStyles } from '@material-ui/core/styles';

import styles from './styles';
import { ChevronLeft } from 'mdi-material-ui';

import DP from 'components/DashPanel';
import Button from 'components/Button/Button';

interface SettingSelectProps extends WithStyles<typeof styles> {
  setting: keyof TipSettings;
  settingsStore: SettingsStore;
  goBack: (event?: React.MouseEvent<HTMLElement, MouseEvent>) => void;
}

type Option = KioskMode | Processor | AccountTransparency;

/**
 * This is a container for settings options that can be set by toggling between
 * multiple select values. Settings that fall in this category are `Kiosk Mode`,
 * `Processor` & `POS Integration`.
 *
 * @param settingsStore on demand settings state management store
 * @param setting which setting and its values are being rendered
 * @param goBack Event handler that gets called when user navigates to previous screen
 */
@observer
class SettingSelect extends React.Component<SettingSelectProps> {
  constructor(props: SettingSelectProps) {
    super(props);
    makeObservable(this);
  }
  @observable public settingsStore: SettingsStore = this.props.settingsStore;

  @observable public kioskModes = KioskMode;
  @observable public processors = Processor;
  @observable public transparency = AccountTransparency;
  @observable public industry = Industry;

  @observable public selectedSetting: keyof TipSettings = this.props.setting;

  @observable public currentOption =
    this.props.settingsStore.generalSettings![this.selectedSetting];

  @observable public selectedOption = this.currentOption;

  @computed public get updating(): boolean {
    return this.settingsStore.updating;
  }

  @computed private get settingOptions() {
    if (this.selectedSetting === 'mode') {
      return this.kioskModes;
    }
    if (this.selectedSetting === 'processor') {
      return this.processors;
    }
    if (this.selectedSetting === 'transparency') {
      return this.transparency;
    }
    return [];
  }

  @action.bound private onSave = () => {
    this.saveSettings();
  };

  @action.bound public saveSettings = flow(function* (this: SettingSelect) {
    let settings = {};
    settings = { [this.selectedSetting]: this.selectedOption };

    yield this.props.settingsStore.updateGeneralSettings(settings);

    this.props.goBack();
  });

  @action.bound private handleOptionSelect = (option: Option) => () => {
    this.selectedOption = option;
  };

  renderKioskText() {
    const KioskText = KIOSK_TEXT[this.selectedOption as keyof typeof KIOSK_TEXT];
    return <DialogContentText>{KioskText}</DialogContentText>;
  }

  renderProcessorText() {
    return (
      <DialogContentText>
        Processor defines which payment processor the Kiosk is using.
      </DialogContentText>
    );
  }

  renderTransparencyText() {
    return <DialogContentText>Set transparency level.</DialogContentText>;
  }

  renderIndustryText() {
    return <DialogContentText>Select industry.</DialogContentText>;
  }

  render() {
    const { drawerContent, settingListItemKey, settingListItemKeyPrimary, dialogActions } =
      this.props.classes;
    return (
      <>
        <DialogTitle>
          <Box display="flex" alignItems="center" justifyContent="space-start">
            <Box mr={2}>
              <IconButton onClick={this.props.goBack}>
                <ChevronLeft />
              </IconButton>
            </Box>
            <Typography component="h1" style={{ fontSize: 28, fontWeight: 400 }} display="inline">
              {this.selectedSetting === 'mode' && 'Kiosk Mode'}
              {this.selectedSetting === 'processor' && 'Processor'}
              {this.selectedSetting === 'transparency' && 'Transparency'}
            </Typography>
            <Box width="40px" ml="auto" display="flex" flexDirection="row" alignItems="center">
              {this.updating && <DP.LoadSpinner />}
            </Box>
          </Box>
        </DialogTitle>
        <Divider />
        <DialogContent className={drawerContent}>
          <Box display="flex" flexDirection="column" height="100%">
            <Box mt={2}>
              {this.selectedSetting === 'mode' && this.renderKioskText()}
              {this.selectedSetting === 'processor' && this.renderProcessorText()}
              {this.selectedSetting === 'transparency' && this.renderTransparencyText()}
            </Box>
            <List component="nav" aria-label="contacts">
              {/* If options for this settings are strings, list them */}
              {typeof this.selectedOption === 'string' &&
                Object.values(this.settingOptions).map((option: any) => (
                  <ListItem
                    key={option}
                    onClick={this.handleOptionSelect(option)}
                    button
                    // Options are disabled for Kiosk Mode === 'integrated'
                    disabled={
                      this.selectedSetting === 'mode' &&
                      (this.selectedOption as string) === 'integrated'
                    }>
                    <ListItemText
                      className={
                        option === this.selectedOption
                          ? settingListItemKeyPrimary
                          : settingListItemKey
                      }
                      primary={option}
                    />
                    {/* TODO: check options after BE implementation, for now Industry options are not displayed */}
                    {option === this.selectedOption && (
                      <ListItemIcon>
                        <Check color="primary" />
                      </ListItemIcon>
                    )}
                  </ListItem>
                ))}
              {/* if options for this settings are boolean values, list both as 'on'/'off values: */}
              {typeof this.selectedOption === 'boolean' &&
                Object.values(this.settingOptions).map((option: any) => (
                  <ListItem key={option} onClick={this.handleOptionSelect(option)} button>
                    <ListItemText
                      className={
                        option === this.selectedOption
                          ? settingListItemKeyPrimary
                          : settingListItemKey
                      }
                      primary={option}
                    />
                    {this.selectedOption && option === 'on' && (
                      <ListItemIcon>
                        <Check color="primary" />
                      </ListItemIcon>
                    )}
                    {!this.selectedOption && option === 'off' && (
                      <ListItemIcon>
                        <Check color="primary" />
                      </ListItemIcon>
                    )}
                  </ListItem>
                ))}
            </List>
          </Box>
        </DialogContent>
        <DialogActions className={dialogActions}>
          <Button
            variant="contained"
            color="primary"
            onClick={this.onSave}
            disabled={this.selectedOption === this.currentOption}
            fullWidth>
            Save
          </Button>
        </DialogActions>
      </>
    );
  }
}

export default withStyles(styles)(SettingSelect);
