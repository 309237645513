import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  WithStyles,
  withStyles,
} from '@material-ui/core';
import { getErrorMsg } from 'api';
import DP from 'components/DashPanel';
import {
  action,
  computed,
  flow,
  IReactionDisposer,
  observable,
  reaction,
  makeObservable,
} from 'mobx';
import { inject, observer } from 'mobx-react';
import React from 'react';
import { WithToastStore, WithUserStore } from 'stores';
import styles from './styles';
import Title from 'components/Title/Dialog/Title';
import OutlinedInput from 'components/Input/OutlinedInput';
import Button from 'components/Button/Dialog/Button';
import Api from 'api';
import { faCircleInfo, faPen } from '@fortawesome/pro-regular-svg-icons';

const MobxReactForm = require('mobx-react-form').default;

interface DisplayNamePanelProps extends WithStyles<typeof styles>, WithToastStore, WithUserStore {
  displayName: string;
  locationId: number;
  updateLocation: () => void;
}

@inject('toastStore', 'userStore')
@observer
class DisplayNamePanel extends React.Component<DisplayNamePanelProps> {
  public constructor(props: DisplayNamePanelProps) {
    super(props);
    makeObservable(this);

    this.disposers.push(
      reaction(
        () => this.props.displayName,
        (displayName: string) => (this.displayName = displayName),
      ),
    );

    // Define mobx-react-form hooks
    this.hooks = {
      onSuccess: (form: any) => {
        const { displayName } = form.values();
        this.updateLocationDisplayName(displayName);
      },
      onClear: () => {
        this.editing = false;
      },
    };
  }

  @computed private get form() {
    /* Create mobx-react-form instance using 'field[]', 'plugins' and event 'hooks' */
    return new MobxReactForm({ fields: this.fields }, { hooks: this.hooks });
  }

  /** Who can edit Display Name */
  @computed public get canEdit() {
    return (
      this.props.userStore &&
      (this.props.userStore.isAdmin ||
        this.props.userStore.isOwner ||
        this.props.userStore.isGlobalOwner)
    );
  }

  // Define mobx-react-form fields:
  @computed public get fields() {
    return [
      {
        name: 'displayName',
        label: 'Display Name',
        type: 'string',
        value: this.displayName,
      },
    ];
  }

  @observable public disposers: IReactionDisposer[] = [];
  @observable public displayName = this.props.displayName;
  @observable public locationId = this.props.locationId;
  @observable public updateLocation = this.props.updateLocation;

  /** Starts editing */
  @action.bound private edit() {
    this.editing = true;
  }

  /** Edit is activated */
  @observable private editing = false;

  /** Is component updating API */
  @observable private updating = false;

  /** Form and form dependencies */
  private hooks: any;

  /**
   * Upate Location with API call and notify parent component to fetch new Location,
   */
  @action.bound public updateLocationDisplayName = flow(function* (
    this: DisplayNamePanel,
    displayName,
  ) {
    try {
      this.updating = true;
      yield Api.core.updateLocation(this.locationId!, { displayName });
      this.updateLocation();
      this.props.toastStore!.push({
        message: 'Display Name successfully updated',
        type: 'success',
      });
    } catch (e: any) {
      this.props.toastStore!.error(getErrorMsg(e));
    } finally {
      this.updating = false;
      this.editing = false;
    }
  });

  /** Before unmounting the component, dispose of all auto-runs and reactions */
  componentWillUnmount() {
    this.disposers.map((disposer) => disposer());
  }

  render() {
    const { classes } = this.props;

    return (
      <>
        <DP>
          <DP.Header>
            <DP.Title panel>Display Name</DP.Title>
            <DP.Actions>
              {this.canEdit ? (
                <DP.IconButton
                  primary
                  onClick={this.edit}
                  fontAwesomeIcon={{ icon: faPen }}
                  tooltip="Edit"
                />
              ) : null}
            </DP.Actions>
          </DP.Header>
          <DP.Body>
            <DP.Row>
              <DP.Value>
                <Box>{this.displayName}</Box>
              </DP.Value>
              <DP.Label>
                Display Name
                <DP.IconButton
                  primary
                  fontAwesomeIcon={{ icon: faCircleInfo, height: 12 }}
                  tooltip="Display Name is a feature that allows you to name your location(s) by your own preferences according to how it is known to your customers. 
                    If you wish to change the location name, click on the pencil button and your chosen Display Name will be shown on Tippy Pro X and Tippy Go."
                />
              </DP.Label>
            </DP.Row>
          </DP.Body>
        </DP>

        <Dialog classes={{ paper: classes.dialog }} open={this.editing}>
          {this.editing && (
            <>
              <DialogTitle className={classes.dialogTitle}>
                <Box
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                  justifyContent="space-between">
                  <Title>Display Name</Title>
                  {this.updating && <DP.LoadSpinner />}
                </Box>
              </DialogTitle>
              <form onSubmit={this.form.onSubmit}>
                <DialogContent className={classes.dialogContent}>
                  {Array.from(this.form.fields).map(([name, field]: any) => {
                    return (
                      <OutlinedInput key={name} {...field.bind()} error={field.error} fullWidth />
                    );
                  })}
                  <p>{this.form.error}</p>
                  <DialogActions className={classes.dialogActions}>
                    <Button onClick={this.form.onClear} color="primary" variant="outlined">
                      Cancel
                    </Button>
                    <Button
                      type="submit"
                      onClick={this.form.onSubmit}
                      color="primary"
                      variant="contained"
                      disabled={this.form.fields.get('displayName').$value === this.displayName}
                      autoFocus>
                      Save
                    </Button>
                  </DialogActions>
                </DialogContent>
              </form>
            </>
          )}
        </Dialog>
      </>
    );
  }
}

export default withStyles(styles)(DisplayNamePanel);
