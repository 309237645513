import React from 'react';
import MfaComponent from 'components/MfaComponent/MfaComponent';
import { observer } from 'mobx-react';
import useMfaDialog from './useMfaDialog';
import Dialog from 'components/Dialog/Dialog';

const MfaDialog = observer(() => {
  const { open, disabled, loading, title, confirm, cancel, resetStore } = useMfaDialog();
  return (
    <Dialog
      open={open}
      scroll="paper"
      title={title}
      width={'auto'}
      content={<MfaComponent />}
      confirmActionName="Verify"
      onCancel={cancel}
      onConfirm={confirm}
      loading={loading}
      TransitionProps={{
        onExited: resetStore,
      }}
      disabled={disabled}
    />
  );
});

export default MfaDialog;
