import { Theme, createStyles } from '@material-ui/core';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default (theme: Theme) =>
  createStyles({
    root: {
      '& .MuiPaper-root': {
        width: 546,
      },
    },
    detailsDrawer: {
      '& .MuiPaper-root': {
        width: 439,
      },
      '& .MuiDialogContent-root': {
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4),
      },
    },
    nameInput: {
      width: '345px',
      marginLeft: `-${theme.spacing(2.125)}px`,
      marginRight: `-${theme.spacing(2.125)}px`,
    },
    firstItem: {
      marginRight: 44,
    },
    fabButton: {
      position: 'fixed',
      bottom: theme.spacing(2),
      right: theme.spacing(4),
    },
    settingListItemKey: {
      margin: 0,
      wordBreak: 'break-word',
      '& .MuiTypography-body1': {
        color: theme.palette.text.secondary,
        fontSize: theme.typography.subtitle1.fontSize,
      },
    },
    settingListItemValue: {
      whiteSpace: 'nowrap',
    },
    distributionRule: {
      marginRight: theme.spacing(2.375),
      color: theme.menu.main,
      fontSize: theme.typography.body1.fontSize,
    },
    ruleListItem: {
      paddingRight: 0,
      paddingLeft: 0,
      paddingBottom: theme.spacing(1.375),
      paddingTop: theme.spacing(1.5),
      borderBottomColor: theme.palette.divider,
      borderBottom: '1px solid',
    },
    listItemUnderline: {
      borderBottomColor: theme.palette.divider,
      borderBottom: '1px solid',
      paddingLeft: 0,
      paddingRight: 0,
      gap: theme.spacing(3),
      '&:first-of-type': {
        borderTop: `1px solid ${theme.palette.divider}`,
      },
    },
    distributionRules: {
      '& .MuiTypography-root': {
        fontWeight: 400,
      },
    },
    chevronLeft: {
      fontSize: theme.typography.h4.fontSize,
    },
    iconButton: {
      padding: 0,
      marginRight: theme.spacing(2.375),
    },
    assignedTo: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    loadBar: {
      display: 'flex',
      marginTop: theme.spacing(5),
      flexDirection: 'row',
      justifyContent: 'center',
    },
    titleWrapper: {
      flexWrap: 'wrap-reverse',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    iconsWrapper: {
      marginLeft: 'auto',
      marginTop: 'auto',
    },
    address: {
      display: 'block',
      opacity: '50%',
      fontSize: '0.8em',
    },
    dialogActions: {
      display: 'flex',
      flexDirection: 'column',
      marginBottom: theme.spacing(1),
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
    },
    plusIcon: {
      width: 24,
      height: 24,
    },
    pencilIcon: {
      color: theme.menu.main,
      width: 20,
      height: 20,
    },
    closeIcon: {
      width: 24,
      height: 24,
    },
    ruleContent: {
      float: 'right',
    },
  });
