import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { observable, action, computed, makeObservable } from 'mobx';
import { observer } from 'mobx-react';
import { WithStyles, withStyles } from '@material-ui/core/styles';
import { Box, Grid } from '@material-ui/core';
import { v4 } from 'uuid';
import Api, { RequestMetaData } from 'api';
import { inject, WithUserStore, WithToastStore, WithSettingStore } from 'stores';
import * as models from 'models';
import { TrendingUp } from 'mdi-material-ui';
import PriceChangeIcon from '@mui/icons-material/PriceChange';
import { HorizontalStatCard } from 'containers/UserDetails/Stats';
import DashboardLayout from 'containers/DashboardLayout';
import PaymentsTable from 'components/PaymentsTable';
import Title from 'components/Title';
import * as DateRangeExternalPicker from 'components/DateRangeExternalPicker';
import styles from './styles';
import { setTitle } from 'services';
import FilterBar, { Filter } from 'components/FilterBar';
import { Account } from 'models';
import { AttachMoneyRounded, CreditCardRounded } from '@material-ui/icons';

const PAGE_TITLE = 'Payments';

enum PAYMENT_SOURCE_FILTERS {
  ALL = 'All',
  WEB = 'Web',
  KIOSK = 'Kiosk',
}

const capitalizeFirstLetter = (str: string, separator: string) => {
  if (separator) {
    const arr = `${str}`
      .split(separator)
      .map((text) => text.charAt(0).toUpperCase() + text.slice(1));
    return arr.join(' ');
  }
  return str.charAt(0).toUpperCase() + str.slice(1);
};

let abortController = new AbortController();

interface PaymentsParams {
  accountId: string;
}

/** Define props for this component */
type CustomerPaymentsProps = WithStyles<typeof styles> & // Adds the classes prop
  RouteComponentProps<PaymentsParams> & // Adds the router props (history, match, location)
  WithUserStore & // Adds the userStore prop
  WithToastStore &
  WithSettingStore;

/**
 * The payments container, restricted to admin users.
 */
@inject('userStore', 'toastStore', 'settingStore')
@observer
class Payments extends React.Component<CustomerPaymentsProps> {
  constructor(props: CustomerPaymentsProps) {
    super(props);
    makeObservable(this);
    this.matchParams = this.props.match.params;
  }

  /** We store the account id from the router into this observable */
  @observable public matchParams: PaymentsParams;
  /** Active filters as returned by FilterBar */
  @observable private activeFilters: Record<string, unknown> = {};

  /** The selected date range */
  @observable public dateRange: DateRangeExternalPicker.DateRange =
    this.props.settingStore!.getDate(this.props.location.pathname);

  /** Source of payments, default is "All"*/
  @observable public selectedPaymentSource = 0;

  /** Source of payments, default is "All"*/
  @observable public account: Account | undefined = undefined;
  /** The stats */
  @observable public tipStatsLoading = false;
  @observable public tipStats?: models.DashboardTipStats;

  /** Sets the date range */
  @action.bound private updateDateRangeValue(range: DateRangeExternalPicker.DateRange) {
    this.props.settingStore!.setDate(this.props.location.pathname, range);
    this.dateRange = range;
    this.activeFilters = { ...this.activeFilters };
    this.fetchStats();
  }

  /** On datagridRefetchKey change datagrid will refetch the data */
  @observable private datagridRefetchKey: number = Date.now();

  /** The account id as captured by the URL params */
  @computed public get accountId(): number {
    return parseInt(this.matchParams.accountId);
  }

  // check for other names as well
  @computed public get paymentProcessorName(): string {
    // for now we have two possible options (web, kiosk)
    if (this.selectedPaymentSource === 1) {
      return PAYMENT_SOURCE_FILTERS.WEB.toLowerCase();
    } else return PAYMENT_SOURCE_FILTERS.KIOSK.toLowerCase();
  }

  public fetchPayments = async (rmd: RequestMetaData) => {
    return await Api.tips.getPayments({
      ...rmd,
      filters: {
        fromDate: this.dateRange.fromDate,
        toDate: this.dateRange.toDate,
        ...this.activeFilters,
        ...(this.selectedPaymentSource > 0 && { origin: this.paymentProcessorName }),
      },
    });
  };

  @action.bound public fetchStats = () => {
    this.tipStatsLoading = true;
    Api.analytics.payment
      .getPaymentStats({
        filters: {
          fromDate: this.dateRange.fromDate,
          toDate: this.dateRange.toDate,
          ...this.activeFilters,
        },
      })
      .then(({ data }) => {
        this.tipStats = data.data;
      })
      .catch((err: any) => {
        console.log(err);
      })
      .finally(() => {
        this.tipStatsLoading = false;
      });
  };

  @action.bound handleRefetch() {
    this.activeFilters = { ...this.activeFilters };
  }

  /** The stats converted to floats for countup display */
  @computed public get tipStatsComputed() {
    return {
      paymentsCount: this.tipStats && parseFloat(this.tipStats.paymentsCount),
      paymentsTotalCharged: this.tipStats && parseFloat(this.tipStats.paymentsTotalCharged),
      paymentsTotalFees: this.tipStats && parseFloat(this.tipStats.paymentsTotalFees),
      paymentsTotalRevenue: this.tipStats && parseFloat(this.tipStats.paymentsTotalRevenue),
    };
  }
  /**
   * Since MUI Select in not a real select element
   * we need to cast e.target.value using as Type and type the handler as React.ChangeEvent<{ value: unknown }>
   */

  private getLocations = async (query?: string) => {
    try {
      const { data } = await Api.core.searchAllLocations({}, { name: query }, abortController);
      const filteredData = data.data.map(
        (location: { id: string; name: string; address: string; [key: string]: string }) => {
          return { id: location.id, name: location.name, address: location.address };
        },
      );
      return filteredData;
    } catch (e: any) {
      return [];
    }
  };

  abortApiCallAndSetUpNewController(): void {
    abortController.abort();
    abortController = new AbortController();
  }

  private getAccounts = async (query: string) => {
    const { data } = await Api.core.getAccounts({}, { name: query });
    const filteredData = data.data.map(
      (account: { id: string; name: string; address: string; [key: string]: string }) => {
        return { id: account.id, name: account.name, address: account.address };
      },
    );
    return filteredData;
  };

  private getAccount = async (accountId: number) => {
    if (this.accountId) {
      const { data } = await Api.core.getAccount(accountId);
      this.account = data;
    }
  };

  private getPaymentSource = async (query: string) => {
    try {
      return await Promise.all([Api.tips.getProcessors(), Api.developer.getAllApps()]).then(
        (data) => {
          const dataProcessors = [...(data[0].data.data || [])].map((item) => ({
            id: v4(),
            name: capitalizeFirstLetter(item, '-'),
            value: item,
          }));

          const dataApps = [...(data[1].data.data || [])].map(({ id, name }) => ({
            id,
            name,
            value: `developer_app_${id}`,
          }));

          return [...dataProcessors, ...dataApps].filter(({ name }) =>
            name.toLowerCase().includes(query.toLowerCase()),
          );
        },
      );
    } catch (error) {
      console.error(error);
    }
  };

  componentDidMount() {
    setTitle(PAGE_TITLE, { noSuffix: false });
    this.fetchStats();
    this.getAccount(this.accountId);
  }

  /** List of available filters for FilterBar component */
  filters: Filter[] = [
    { display: 'Reference #', id: 'reference', label: 'Contains', type: 'tags' },
    {
      display: 'Amount',
      id: 'chargeAmount',
      label: 'Contains',
      type: 'range',
      interval: {
        from: { label: 'From Amount', value: 'fromAmount' },
        to: { label: 'To Amount', value: 'toAmount' },
        type: 'number',
      },
    },
    { display: 'Card', id: 'lastFour', label: 'Contains', type: 'tags' },
    {
      display: 'Location',
      id: 'locationId',
      label: 'Contains',
      type: 'tags',
      options: {
        fetch: this.getLocations,
        displayField: {
          value: 'name',
          additional: {
            value: 'address',
          },
          keySearch: { name: 'id' },
        },
      },
    },
    {
      display: 'Account',
      id: 'accountId',
      label: 'Contains',
      type: 'tags',
      options: {
        fetch: this.getAccounts,
        displayField: {
          value: 'name',
          additional: {
            value: 'address',
          },
          keySearch: { name: 'id' },
        },
      },
      //value: [{id: 1 , address: "2755 E Oakland Park Blvd Suite 300", name: "DirectTIPS"}]
    },
    {
      display: 'Payment Source',
      id: 'processors',
      label: 'Contains',
      type: 'tags',
      options: {
        fetch: this.getPaymentSource,
        displayField: {
          value: 'name',
          keySearch: { name: 'value' },
        },
      },
    },
    {
      display: 'Payment Type',
      id: 'type',
      label: 'One of',
      type: 'select',
      items: [
        { label: 'ALL', value: 'all' },
        { label: 'DIRECT', value: 'direct' },
        { label: 'INTEGRATED', value: 'integrated' },
      ],
    },
    { display: 'Transaction Id', id: 'transactionId', label: 'Contains', type: 'tags' },
    {
      display: 'Status',
      id: 'status',
      label: 'One of',
      type: 'select',
      items: [
        { label: 'CAPTURED', value: 'captured' },
        { label: 'CREATED', value: 'created' },
        { label: 'DUPLICATE', value: 'duplicate' },
        { label: 'REFUNDED', value: 'refunded' },
        { label: 'VERIFYING', value: 'verifying' },
        { label: 'FAILED', value: 'failed' },
        { label: 'CANCELLED', value: 'cancelled' },
        { label: 'VOID', value: 'void' },
        { label: 'NOT FOUND', value: 'not_found' },
      ],
    },
  ];

  render() {
    const { classes } = this.props;
    return (
      <DashboardLayout>
        <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
          <Box display="flex" justifyContent="flex-start">
            <Title mb={3}>{PAGE_TITLE}</Title>
          </Box>
        </Box>
        <Box mb={3}>
          <FilterBar
            filters={this.filters}
            onChange={(filters: Record<string, unknown>) => {
              this.activeFilters = filters;
              this.fetchStats();
            }}
            defaultValue={
              this.accountId
                ? {
                    accountId: [
                      {
                        address: this.account?.address,
                        name: this.account?.name,
                        id: this.accountId,
                      },
                    ],
                  }
                : undefined
            }
            externalDateRange={{
              predefined: this.dateRange,
              onChange: this.updateDateRangeValue,
            }}
          />
        </Box>
        <Box mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} md={3}>
              <HorizontalStatCard
                icon={CreditCardRounded}
                color="primary"
                duration={1}
                title="Swipes"
                loading={this.tipStatsLoading}>
                {this.tipStatsComputed.paymentsCount}
              </HorizontalStatCard>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <HorizontalStatCard
                icon={AttachMoneyRounded}
                duration={1}
                title="Total amount"
                prefix="$"
                separator=","
                decimals={2}
                loading={this.tipStatsLoading}
                color="yellow">
                {this.tipStatsComputed.paymentsTotalCharged}
              </HorizontalStatCard>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <HorizontalStatCard
                icon={TrendingUp}
                color="secondary"
                duration={1}
                prefix="$"
                separator=","
                decimals={2}
                title="Revenue"
                loading={this.tipStatsLoading}>
                {this.tipStatsComputed.paymentsTotalRevenue}
              </HorizontalStatCard>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <HorizontalStatCard
                color="primary"
                icon={PriceChangeIcon}
                duration={1}
                prefix="$"
                separator=","
                decimals={2}
                title="Processor fees"
                loading={this.tipStatsLoading}>
                {this.tipStatsComputed.paymentsTotalFees}
              </HorizontalStatCard>
            </Grid>
          </Grid>
        </Box>
        <PaymentsTable
          fetch={this.fetchPayments}
          refetch={this.handleRefetch}
          refetchKey={this.activeFilters}
          {...this.props}
        />
      </DashboardLayout>
    );
  }
}

export default withStyles(styles)(Payments);
