/* eslint-disable @typescript-eslint/no-explicit-any */
const win = window as any;
const googleMaps = win.google.maps;
const autocompleteService = new googleMaps.places.AutocompleteService();

export interface AutocompleteResult {
  id: string;
  value: string;
}

export class GoogleApiHelper {
  /**
   * This function will search and return addresses at google for the given search query term.
   * @param query search query
   */
  public static search(query: string): Promise<AutocompleteResult[]> {
    return new Promise(function (resolve, reject) {
      autocompleteService.getPlacePredictions(
        { input: query, types: ['address'], componentRestrictions: {country: 'us'} },
        (predictions: any, status: any) => {
          if (status !== 'OK') {
            reject(status);
            return;
          }
          // Get only relevant data from response
          const results = predictions.map((p: any) => ({
            id: p.place_id,
            value: p.description,
          }));
          resolve(results);
        },
      );
    });
  }

  /**
   * This function asks Google's API for detailed information on a specific place via place_id
   * @param id place id
   * @param htmlNode mandatory html node for Google's placesService constructor
   */
  public static getPlaceDetails(id: string, htmlNode: HTMLDivElement): Promise<any> {
    return new Promise(function (resolve, reject) {
      const placesService = new googleMaps.places.PlacesService(htmlNode);
      placesService.getDetails({ placeId: id }, (placeResult: any, status: any) => {
        if (status !== 'OK') {
          reject(status);
          return;
        }
        resolve(placeResult);
      });
    });
  }
}
