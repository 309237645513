import React from 'react';
import { RouteComponentProps, Link as RouterLink, useHistory } from 'react-router-dom';
import { observable, action, flow, makeObservable, computed } from 'mobx';
import { observer } from 'mobx-react';
import { WithStyles, withStyles } from '@material-ui/core/styles';
import { Box, Link, Typography } from '@material-ui/core';

import { inject, WithUserStore, WithToastStore, WithUiStore } from 'stores';
import { paths } from 'routes';

import PasswordField from 'components/form/PasswordField';

import styles from './styles';
import { getErrorMsg, makeQS } from 'api';
import theme from 'containers/App/theme';
import OutlinedInput from 'components/Input/OutlinedInput';
import { WithRouterStore } from 'stores/RouterStore';
import { LocationState } from './Login';
import { FormHelperText } from '@mui/material';
import config from 'config';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import SignupLayout, {
  TSignupLayoutAction,
  TSignupLayoutSubAction,
} from 'containers/SignupLayout/SignupLayout';

interface ILoginProps {
  title?: string;
  onStatusSubmit?: (status: boolean) => void;
}

enum LoginStep {
  EMAIL = 'email',
  PASSWORD = 'password',
}

type LoginProps = ILoginProps &
  WithStyles<typeof styles> &
  RouteComponentProps &
  WithUiStore &
  WithRouterStore &
  WithUserStore &
  WithToastStore;

const formId = 'login-form';

/**
 * The login screen container component.
 */
@inject('userStore', 'toastStore', 'uiStore')
@observer
class LoginForm extends React.Component<LoginProps> {
  constructor(props: LoginProps) {
    super(props);
    makeObservable(this);
  }

  /** The email input value */
  @observable public email = '';
  /** The password input value */
  @observable public password = '';
  /** The error string, if present */
  @observable public error?: string;
  /** Whether we're currently logging in */
  @observable public loading = false;
  /** Current login step */
  @observable public step = LoginStep.EMAIL;

  /**
   * Handles the email change from an input component
   * @param e The onChange event
   */
  @action.bound public handleEmailChange(e: React.ChangeEvent<HTMLInputElement>) {
    this.email = e.target.value;
  }

  /**
   * Handles the password change from an input component
   * @param e The onChange event
   */
  @action.bound public handlePasswordChange(e: React.ChangeEvent<HTMLInputElement>) {
    this.password = e.target.value;
  }

  /** Logs the user in */
  @action.bound public login = flow(function* (this: LoginForm) {
    try {
      this.loading = true;
      let props: any = { email: this.email };
      props.password = this.password;
      yield this.props.userStore!.login({ email: this.email, password: this.password });
    } catch (error: any) {
      this.handleError(error);
    } finally {
      this.loading = false;
    }
  });

  @action.bound public async getSsoProvider() {
    try {
      this.loading = true;

      await this.props.userStore?.getSsoProvider(this.email);
      const { ssoProvider } = this.props.userStore!;
      if (ssoProvider?.name === 'tippy' || !ssoProvider) {
        this.loading = false;
      }
      return ssoProvider;
    } catch (error) {
      this.error = getErrorMsg(error);
      this.loading = false;
    }
  }

  /**
   * Handles the main form submission.
   * @param e The form submitted event
   */
  @action.bound public handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    if (this.step === LoginStep.EMAIL) {
      this.getSsoAndRedirect();
    } else {
      this.login();
    }
  }

  @action.bound handleError(error: any) {
    if (error.response) {
      this.error = error.response.data.error.message;
    } else if (error.message) {
      this.error = error.message;
    }
  }

  public async getSsoAndRedirect() {
    const ssoProvider = await this.getSsoProvider();
    this.error = undefined;
    if (!ssoProvider || (ssoProvider.name !== 'tippy' && !ssoProvider.loginRedirectUrl)) {
      this.loading = false;
      this.error = 'Something went wrong, please try again';
      return;
    }

    const { name, loginRedirectUrl } = ssoProvider;
    // If sso provider is not tippy, we should redirect to sso provider login page
    if (name !== 'tippy') {
      return window.location.replace(
        `${loginRedirectUrl}?${makeQS(undefined, {
          redirect: `${config.dashboard.baseUrl}${this.redirectedFrom}`,
          email: this.email,
        })}`,
      );
    }
    this.step = LoginStep.PASSWORD;
  }

  public resetValues = () => {
    this.step = LoginStep.EMAIL;
    this.password = '';
    this.error = '';
  };

  @computed public get redirectedFrom() {
    return (this.props.location?.state as unknown as LocationState).from?.pathname;
  }

  @computed public get signupLayoutProps() {
    const { loggingIn } = this.props.userStore!;

    const title = this.props.title ? this.props.title : 'Sign In';

    const action: TSignupLayoutAction =
      this.step === LoginStep.EMAIL
        ? {
            type: 'submit',
            form: formId,
            children: 'Continue',
            disabled: !this.email,
            loading: this.loading,
            subText: (
              <>
                {' '}
                {`Don't have an account? `}
                <Link
                  data-cy={'signup-link'}
                  component={RouterLink}
                  to={paths.signUp().root()}
                  underline="always">
                  Sign up!
                </Link>
              </>
            ),
          }
        : {
            type: 'submit',
            form: formId,
            children: 'Continue',
            disabled: !this.password,
            loading: loggingIn,
          };

    const subAction: TSignupLayoutSubAction =
      this.step === LoginStep.PASSWORD
        ? {
            back: { onClick: this.resetValues, label: 'Back' },
          }
        : {};

    return { title, action, subAction };
  }

  render() {
    const { loggingIn } = this.props.userStore!;
    const mobileView = this.props.uiStore?.mobileView;
    const { classes } = this.props;

    return (
      <Box width={'100%'}>
        <SignupLayout {...this.signupLayoutProps}>
          <form id={formId} style={{ marginTop: theme.spacing(5) }} onSubmit={this.handleSubmit}>
            {this.step === LoginStep.EMAIL ? (
              <EmailStep
                email={this.email}
                loading={this.loading}
                error={this.error}
                onChange={this.handleEmailChange}
                mobileView={mobileView}
              />
            ) : (
              <PasswordStep
                value={this.password}
                email={this.email}
                loading={loggingIn}
                error={this.error}
                onChange={this.handlePasswordChange}
                onBack={this.resetValues}
                classes={classes}
              />
            )}
          </form>
        </SignupLayout>
      </Box>
    );
  }
}

export default withStyles(styles)(LoginForm);

interface IEmailStepProps {
  email: string;
  loading: boolean;
  error?: string;
  onChange: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  mobileView?: boolean;
}
const EmailStep = (props: IEmailStepProps) => {
  const { loading, email, error, mobileView, onChange } = props;
  return (
    <Box mb={mobileView ? 4 : 6} mt={mobileView ? 11 : 13.625}>
      <OutlinedInput
        label="Email"
        fullWidth
        autoFocus
        required
        type="email"
        id="email-input"
        dataCy="email-input"
        disabled={loading}
        value={email}
        onChange={onChange}
      />
      <FormHelperText
        style={{
          color: theme.palette.error.main,
          fontSize: theme.typography.subtitle1.fontSize,
        }}
        error={!!error}>
        {error}
      </FormHelperText>
    </Box>
  );
};

interface IPasswordStepProps {
  value: string;
  email: string;
  loading: boolean;
  error?: string;
  onBack: () => void;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  classes: ClassNameMap<'backButton'>;
  mobileView?: boolean;
}
const PasswordStep = (props: IPasswordStepProps) => {
  const { value, email, loading, error, mobileView, onChange, onBack, classes } = props;
  const history = useHistory();
  const handleClick = () => {
    history.push({
      pathname: paths.forgotPassword(),
      search: email && `?email=${email}`,
    });
  };

  return (
    <Box mb={mobileView ? 4 : 3} mt={mobileView ? 11 : 13.625}>
      <PasswordField
        dataCy="password-input"
        value={value}
        disabled={loading}
        onChange={onChange}
        required
        id="password-input"
      />
      <FormHelperText
        style={{
          color: theme.palette.error.main,
          fontSize: theme.typography.subtitle1.fontSize,
        }}
        error={!!error}>
        {error}
      </FormHelperText>
      <Box mt={1}>
        <Typography align="right">
          <Link onClick={handleClick} underline="always">
            Forgot password
          </Link>
        </Typography>
      </Box>
    </Box>
  );
};
