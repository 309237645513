import { Box, Typography } from '@material-ui/core';
import OutlinedInput, { OutlinedInputProps } from 'components/Input/OutlinedInput/OutlinedInput';
import theme from 'containers/App/theme';
import * as models from 'models';

const ConfirmContent = () => {
  return (
    <Typography variant="body2">Are you sure you want to approve this transfer request?</Typography>
  );
};

const RejectContent = (props: OutlinedInputProps) => {
  return (
    <Box display={'flex'} flexDirection={'column'} gridGap={theme.spacing(3)}>
      <Typography variant="body2">Please provide rejection reason</Typography>
      <OutlinedInput label={'Reason'} fullWidth multiline {...props} />
    </Box>
  );
};

const ActionDialogContent = ({
  action,
  rejectionReasonProps,
}: {
  action?: models.ETransferRequestAction;
  rejectionReasonProps?: Partial<OutlinedInputProps>;
}) => {
  if (action === models.ETransferRequestAction.APPROVE) return <ConfirmContent />;
  return <RejectContent {...rejectionReasonProps} />;
};

export default ActionDialogContent;
