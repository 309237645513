import { Theme, createStyles } from '@material-ui/core';
import { lighten } from '@material-ui/core/styles/colorManipulator';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default (theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: theme.palette.common.white,
      display: 'flex',
      flexDirection: 'column',
      minHeight: '100vh',
    },
    main: {
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      alignItems: 'center',
      position: 'relative',
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      paddingTop: theme.spacing(6),
      paddingBottom: theme.spacing(6),
    },
    savingsImg: {
      display: 'block',
      maxHeight: '200px',
      marginBottom: theme.spacing(3),
    },
    logo: {
      display: 'block',
      maxHeight: '40px',
      marginBottom: theme.spacing(3),
    },
    tagline: {
      fontSize: theme.typography.h4.fontSize,
      textAlign: 'center',
      marginBottom: theme.spacing(3),
    },
    calculatorBox: {
      display: 'flex',
      flexDirection: 'row',
      fontSize: '18px',
      color: theme.palette.grey[600],
      marginBottom: theme.spacing(3),
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
      },
    },
    extra: {
      color: '#888',
      marginBottom: theme.spacing(3),
      textAlign: 'center',
    },
    amountBox: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      borderRight: '1px solid #eee',
      flexDirection: 'column',
      textAlign: 'center',
      display: 'flex',
      alignItems: 'center',
      paddingRight: theme.spacing(3),
      [theme.breakpoints.down('sm')]: {
        borderRight: '0px solid black',
        paddingRight: 0,
      },
    },
    savingsAmount: {
      color: theme.palette.primary.main,
      marginTop: theme.spacing(3),
      fontSize: '38px',
    },
    savingsBox: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      display: 'flex',
      flexDirection: 'column',
      textAlign: 'center',
      marginLeft: theme.spacing(3),
      [theme.breakpoints.down('sm')]: {
        marginLeft: 0,
      },
    },
    input: {
      marginTop: theme.spacing(3),
      fontSize: '24px',
      outline: 'none',
      maxWidth: '200px',
      color: theme.palette.primary.main,
      borderWidth: 0,
      textAlign: 'center',
      backgroundColor: lighten(theme.palette.primary.main, 0.9),
      borderRadius: theme.shape.borderRadius,
      padding: theme.spacing(2),
    },
    tippyLogo: {
      display: 'block',
      maxHeight: '50px',
      marginBottom: theme.spacing(2),
    },
    call: {
      color: '#888',
      position: 'absolute',
      top: theme.spacing(4),
      right: theme.spacing(4),
      zIndex: 500,
      textAlign: 'right',
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
    phone: {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      fontSize: '18px',
      color: theme.palette.primary.main,
    },
    actionBtn: {
      fontSize: '24px',
    },
    footer: {
      padding: theme.spacing(3),
      display: 'flex',
      flexDirection: 'column',
      textAlign: 'center',
      color: '#444',
      alignItems: 'center',
      backgroundColor: theme.palette.grey[100],
    },
  });
