import Api from 'api';
import { Pool } from 'models';
import {
  IFetchStationItem,
  IFetchStationPool,
  IFetchStationUser,
  IGroupItem,
  IStation,
  IStationItem,
} from 'models/Station';
import { ICustomLocationUser } from 'stores';

import { IStationGroup, IStationPool, IStationUser } from 'models/Station';

import { RequestMetaData } from 'components/DataGridInfiniteScroll/InterfaceDataGridPro';

enum StationItem {
  USER = 'user',
  POOL = 'pool',
  GROUP = 'group',
}

interface ICountedUsersAndPools {
  countUsers: number;
  countPools: number;
}

interface IFormattedStationUser {
  id: number;
  type: StationItem;
  name: string;
}

export interface IFormattedStationPool {
  id: number;
  type: StationItem;
  name: string;
}

interface IFormattedStationGroup {
  name: string;
  type: StationItem;
  countUsers: number;
  countPools: number;
  items: IFormattedStationItems;
}

export type IFormattedStationItem =
  | IFormattedStationPool
  | IFormattedStationUser
  | IFormattedStationGroup;

export type IFormattedStationItems =
  | [IFormattedStationPool | IFormattedStationUser | IFormattedStationGroup]
  | undefined;

export interface IFormattedStation {
  name: string;
  items: IFormattedStationItems;
}

export class BlStations {
  static isFormValid(formValidation: any) {
    if (formValidation.hasError) {
      return false;
    }
    return true;
  }

  static async getStations(locationId: number, rmd?: RequestMetaData) {
    const { data } = await Api.core.getStations(locationId, rmd);
    return data.data;
  }

  static async getStation(stationId: string) {
    const { data } = await Api.core.getStation(stationId);
    return data.data;
  }

  static async updateStation(station: IStation) {
    const { data } = await Api.core.updateStation(station);
    return data.data;
  }

  static async createStation(station: IStation) {
    await Api.core.createStation(station);
  }

  static async deleteStation(station: IStation) {
    await Api.core.deleteStation(station.id!);
  }

  static formatStationsStationsForDisplay(
    station: IStation,
    pools: Pool[],
    locationUsers: ICustomLocationUser[],
  ): IFormattedStation {
    const { name, items } = station;
    let formattedItems;
    if (items && items.length) {
      formattedItems = this.formatItems(items, pools, locationUsers) as IFormattedStationItems;
    }
    return { name, items: formattedItems };
  }

  /**
   * Maps fetched items from IFetchedStationItems to IStationItems
   */
  static mapFetchedStationItems(items: Array<IFetchStationItem>): Array<IStationItem> {
    if (!items) return [];
    return items.map((fetchItem: IFetchStationItem): IStationItem => {
      let item: IStationItem;
      switch (fetchItem.type) {
        case StationItem.POOL:
          item = this.mapPoolItem(fetchItem);
          break;
        case StationItem.USER:
          item = this.mapUserItem(fetchItem);
          break;
        case StationItem.GROUP:
          item = this.mapGroupItem(fetchItem);
          break;
        default:
          return {} as IStationItem;
      }
      return item;
    });
  }

  private static mapGroupItem(fetchItem: IFetchStationItem) {
    const { name, group, type } = fetchItem as any;
    const groupItems = group.items.map((groupItem: IGroupItem) => {
      const { pool, user, type } = groupItem as any;
      if (type === StationItem.POOL) {
        return { poolId: pool.id, type };
      } else {
        return { userId: user.id, type };
      }
    });
    return { groupName: name, groupItems, type };
  }

  private static mapUserItem(fetchItem: IFetchStationItem): IStationUser {
    const { user, type } = fetchItem as IFetchStationUser;
    return { userId: user.id, type };
  }

  private static mapPoolItem(fetchItem: IFetchStationItem): IStationPool {
    const { pool, type } = fetchItem as IFetchStationPool;
    return { poolId: pool.id, type };
  }

  private static formatItems(
    items: (IStationUser | IStationPool | IStationGroup)[],
    locationPools: Pool[],
    locationUsers: ICustomLocationUser[],
  ): any {
    return items.map((item: IStationUser | IStationPool | IStationGroup) => {
      if (item.type === StationItem.USER) {
        const { userId, type } = item;
        const user = this.findLocationUserById(locationUsers, userId);

        const name = user ? user.fullName : 'N/A';
        return { id: userId, type, name } as IFormattedStationUser;
      }
      if (item.type === StationItem.POOL) {
        const { poolId, type } = item;
        const pool = this.findLocationPoolById(locationPools, poolId);
        const name = pool ? pool.name : 'N/A';
        return { id: poolId, type, name } as IFormattedStationPool; // removed selected, should be done by individual components
      }
      if (item.type === StationItem.GROUP) {
        const { type, groupName, groupItems } = item;
        const { countUsers, countPools } = this.countGroupUsersAndPools(groupItems);
        const items = this.formatItems(groupItems, locationPools, locationUsers);
        return { name: groupName, type, countUsers, countPools, items } as IFormattedStationGroup;
      }
    });
  }

  private static formatGroups(items: (IStationUser | IStationPool | IStationGroup)[]) {
    items.map((item: IStationUser | IStationPool | IStationGroup) => {
      if (item.type === StationItem.USER) {
        // const user = this.findLocationUserById()
        const { userId, type } = item;
        return { id: userId, type };
      }
    });
  }

  private static findLocationUserById(locationUsers: ICustomLocationUser[], id: number) {
    return locationUsers.find((user: ICustomLocationUser) => user.id === id);
  }

  private static findLocationPoolById(locationPools: Pool[], id: number) {
    return locationPools.find((pool: Pool) => pool.id === id);
  }

  private static countGroupUsersAndPools(
    groupItems: IStationGroup['groupItems'],
  ): ICountedUsersAndPools {
    if (!groupItems || !groupItems.length) return { countUsers: 0, countPools: 0 };
    return groupItems.reduce(
      (
        countedItems: { countUsers: number; countPools: number },
        groupItem: IStationPool | IStationUser,
      ) => {
        if (groupItem.type === StationItem.POOL) {
          return { ...countedItems, countPools: countedItems.countPools + 1 };
        }
        if (groupItem.type === StationItem.USER) {
          return { ...countedItems, countUsers: countedItems.countUsers + 1 };
        }
        return countedItems;
      },
      { countUsers: 0, countPools: 0 },
    );
  }
}
