import { useStores } from 'containers/App/App';

const useMfaDialog = () => {
  const { mfaDialogStore, mfaStore, globalDialogStore } = useStores();

  const cancel = () => {
    globalDialogStore.setCloseMfaDialog();
  };

  const confirm = () => {
    mfaDialogStore.verify();
  };

  const resetStore = () => {
    mfaStore.resetStore();
  };

  const open = globalDialogStore.openMfaDialog;
  const title = mfaDialogStore.dialogData.title;
  const loading = mfaStore.verifyingMfaData;
  const disabled = !mfaStore.mfaBodyValid;

  return { open, disabled, loading, title, confirm, cancel, resetStore };
};

export default useMfaDialog;
