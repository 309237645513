import { Box, Typography } from '@material-ui/core';
import Button from 'components/Button/Button';
import DP from 'components/DashPanel';
import { referralPanelStyling } from './styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMedal } from '@fortawesome/pro-regular-svg-icons';
import { useHistory } from 'react-router-dom';
import { paths } from 'routes';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { IFeatureFlags } from 'models';
import { useStores } from 'containers/App/App';

const ReferralPanel = () => {
  const history = useHistory();
  const { userStore } = useStores();
  const { tip3925ReferAppHomescreen } = useFlags<IFeatureFlags>();

  /**
   * If flag is set to false, we do not show this component
   */
  if (tip3925ReferAppHomescreen === false) {
    return null;
  }

  /**
   * Do not show the panel to users who are not managers or service providers
   */
  if (!userStore.isSp && !userStore.isManager) {
    return null;
  }

  return (
    <Box sx={referralPanelStyling}>
      <DP>
        <DP.Body>
          <Box className="card">
            <Box className="info">
              <Box className="info-icon-box">
                <FontAwesomeIcon icon={faMedal} className="info-icon" fontSize={24} />
              </Box>
              <Box>
                <Typography className="subtitle">Refer & Earn</Typography>
                <Typography className="text">Your personal tools for success.</Typography>
              </Box>
            </Box>
            <Button
              onClick={() =>
                history.push({
                  pathname: paths.referrals().root(),
                })
              }
              className="refer-link">
              Get Started!
            </Button>
          </Box>
        </DP.Body>
      </DP>
    </Box>
  );
};

export default ReferralPanel;
