import React from 'react';
import { observable, action, flow, makeObservable } from 'mobx';
import { observer } from 'mobx-react';

import { inject, WithToastStore } from 'stores';
import Api, { getErrorMsg } from 'api';

import validatorjs from 'validatorjs';
import { v4 as uuidv4 } from 'uuid';

import { WithStyles, withStyles } from '@material-ui/core/styles';
import { Typography, Box, InputAdornment, DialogActions } from '@material-ui/core';

import RefundReasonSelect from 'components/RefundReasonSelect';
import CreditCardIcon from 'components/CreditCardIcon';
import styles from './styles';

import { tipRefundReasons } from 'services';
import Button from 'components/Button/Dialog/Button';
import OutlinedInput from 'components/Input/OutlinedInput';

export type RefundType = 'full' | 'partial';

type MobxForm = any;

const fields = [
  {
    name: 'customer',
    placeholder: 'Refund To',
  },
  {
    name: 'amount',
    placeholder: 'Amount',
  },
  {
    name: 'reason',
    placeholder: 'Refund Reason ',
    rules: ['required'],
    hooks: {
      onChange: (field: any) => field.validate(),
    },
  },
  {
    name: 'customReason',
    placeholder: 'Reason for refund',
  },
];

// eslint-disable-next-line @typescript-eslint/no-var-requires
const dvr = require('mobx-react-form/lib/validators/DVR');
const MobxReactForm = require('mobx-react-form').default;

const plugins = {
  dvr: dvr({
    package: validatorjs,
    extend: ({ validator }: { validator: any }) => {
      const messages = validator.getMessages('en');
      messages.accepted = 'Please confirm';
      validator.setMessages('en', messages);
    },
  }),
};

interface FormHooks {
  onSuccess: (form: MobxForm) => void;
  onClear: (form: MobxForm) => void;
}

/** Define props for this component */
type RefundFormProps = WithStyles<typeof styles> &
  WithToastStore & {
    tipId: number;
    total: string;
    name?: string;
    lastFour?: string;
    brand?: string;
    closeModal: () => void;
  };

/**
 * PaymentTip container specific sub component.
 * Used as a child of Dialog component for refunding tip.
 */
@inject('userStore', 'modalStore', 'toastStore')
@observer
class RefundForm extends React.Component<RefundFormProps> {
  public constructor(props: RefundFormProps) {
    super(props);
    makeObservable(this);
    this.form = new MobxReactForm({ fields }, { plugins, hooks: this.hooks });
  }

  /** The form object */
  @observable private form: MobxForm;

  private hooks: FormHooks = {
    onSuccess: () => this.submitRefund(),
    onClear: () => this.form.clear(),
  };

  /** Whether the form is currently being submitted */
  @observable public submitting = false;

  @action.bound amountInputError = (value: string) => {
    if (value === '') {
      return 'Amount cannot be empty';
    } else if (+value === 0) {
      return 'Amount cannot be zero';
    } else if (+value > +this.props.total) {
      return `Amount cannot be greater than ${this.props.total}`;
    } else {
      return '';
    }
  };

  @action public updateAmount = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    let amount = event.target.value.trim();
    if (isNaN(+amount)) return;
    const indexOfDecimal = amount.indexOf('.');
    const keepDecimals = 2;
    if (~indexOfDecimal) amount = amount.slice(0, indexOfDecimal + keepDecimals + 1);
    this.form.$('amount').set(amount);
    // Check if amount input is valid:
    const error = this.amountInputError(amount);
    error ? this.form.$('amount').invalidate(error) : this.form.$('amount').validate();
  };

  /** Submits the form */
  @action.bound public submitRefund = flow(function* (this: RefundForm) {
    try {
      this.submitting = true;

      // if user chose OTHER as predefined refund reason use her custom reason text as refund reason
      const predefinedReason = this.form.$('reason').value;
      const customReason = this.form.$('customReason').value;
      const reason = predefinedReason === 'OTHER' ? customReason : predefinedReason;

      // only add refund amount to request if refund is partial because
      // if it is a full refund backend is aware of the total tip amount
      const amountInputValue = this.form.$('amount').value;
      const amount = amountInputValue !== this.props.total ? amountInputValue : undefined;

      yield Api.tips.requestTipRefund(this.props.tipId, uuidv4(), amount, reason);

      this.props.toastStore!.push({
        type: 'success',
        message: 'Tip refunded successfully',
      });
      this.props.closeModal();
    } catch (e: any) {
      this.props.toastStore!.error(getErrorMsg(e));
    } finally {
      this.submitting = false;
    }
  });

  componentDidMount() {
    this.form.$('amount').set(this.props.total);
  }

  isRequired(name: string) {
    if (!this.form.$(name).rules) return false;

    return this.form.$(name).rules.includes('required');
  }

  render() {
    const { total, brand, name, lastFour, classes } = this.props;
    const { dialogActions } = classes;
    return (
      <Box>
        <form onSubmit={this.form.onSubmit}>
          <Box>
            {/* <TextField
              {...this.form.$('customer').bind()}
              value={`****${lastFour || 'XXXX'} - ${name || 'Cardholder'}`}
              disabled
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <CreditCardIcon brand={brand || ''} />
                  </InputAdornment>
                ),
              }}
            /> */}
            <OutlinedInput
              {...this.form.$('customer').bind()}
              value={`****${lastFour || 'XXXX'} - ${name || 'Cardholder'}`}
              required={this.isRequired('customer')}
              disabled
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <CreditCardIcon brand={brand || ''} />
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          <Box mt={2}>
            {/* <TextField
              {...this.form.$('amount').bind()}
              onChange={this.updateAmount}
              error={Boolean(this.form.$('amount').error)}
              helperText={this.form.$('amount').error}
              fullWidth
              InputProps={{
                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                endAdornment: (
                  <InputAdornment position="end">
                    <Typography color="textSecondary">${total} Maximum</Typography>
                  </InputAdornment>
                ),
              }}
            /> */}
            <OutlinedInput
              {...this.form.$('amount').bind()}
              onChange={this.updateAmount}
              error={Boolean(this.form.$('amount').error)}
              // helperText={this.form.$('amount').error}
              required={this.isRequired('amount')}
              fullWidth
              InputProps={{
                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                endAdornment: (
                  <InputAdornment position="end">
                    <Typography color="textSecondary">${total} Maximum</Typography>
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          <Box mt={2}>
            <RefundReasonSelect
              selectField={this.form.$('reason')}
              textField={this.form.$('customReason')}
              reasons={tipRefundReasons}
            />
          </Box>

          <DialogActions className={dialogActions}>
            <Button variant="outlined" onClick={this.props.closeModal}>
              Cancel
            </Button>
            <Button
              variant="contained"
              type="submit"
              disabled={!this.form.isValid || this.submitting}>
              Refund
            </Button>
          </DialogActions>
        </form>
      </Box>
    );
  }
}

export default withStyles(styles)(RefundForm);
