import React from 'react';
import { observer } from 'mobx-react';
import { Paper, Box, Typography } from '@material-ui/core';
import useStyles from './styles';

const Countup = require('react-countup').default;
const countupDuration = 2;

/**
 * Horizontal container for StaticCell and CountupCell.
 */
const StatsPanel = ({ children }: { children: React.ReactNode; hidePaper?: boolean }) => {
  const styles = useStyles();

  return (
    <Paper className={styles.root}>
      <Box padding={2} display="flex" flexDirection="row" justifyContent="space-around">
        {children}
      </Box>
    </Paper>
  );
};

/**
 * A cell that displays main and auxiliary text. Aux text is placed on top of main
 * text by default, but we can change that by using 'auxOnBottom' prop.
 */
StatsPanel.StaticCell = observer(
  ({
    mainText,
    auxText,
    auxOnBottom = false,
  }: {
    mainText: string | React.ReactNode;
    auxText: string | React.ReactNode;
    auxOnBottom?: boolean;
  }) => {
    const { secondaryFont, statusFont } = useStyles();
    const renderAuxText = () => (
      <Typography className={secondaryFont} variant="h5">
        {auxText}
      </Typography>
    );
    return (
      <Box padding={2} textAlign="center">
        {!auxOnBottom && renderAuxText()}
        <Typography className={statusFont} variant="h4">
          {mainText}
        </Typography>
        {auxOnBottom && renderAuxText()}
      </Box>
    );
  },
);

/**
 * A cell that displays a value and auxiliary text. Aux text is placed on top
 * of the value by default, but we can change that by using 'auxOnBottom' prop.
 * Value is a numeric variable that utilizes Countup effect. By default passed
 * value gets formatted as currency, but if we do not want any formatting on the
 * value use isCurrency prop.
 */
StatsPanel.CountupCell = observer(
  ({
    value,
    isCurrency = true,
    auxText,
    auxOnBottom = false,
    iconButton,
  }: {
    value: number;
    isCurrency?: boolean;
    auxText: string | React.ReactNode;
    auxOnBottom?: boolean;
    iconButton?: React.ReactNode;
  }) => {
    const { mainFont, secondaryFont } = useStyles();
    const renderAuxText = () => (
      <Typography className={secondaryFont} variant="h5">
        {auxText}
      </Typography>
    );
    return (
      <Box padding={2} textAlign="center">
        {!auxOnBottom && renderAuxText()}
        <Box display="flex" flexDirection="row" justifyContent="center" alignItems="center">
          <Typography className={mainFont} variant="h4" component={Box}>
            {isCurrency ? (
              <Countup
                end={value}
                duration={countupDuration}
                decimals={2}
                preserveValue
                prefix="$"
                separator=","
              />
            ) : (
              <Countup end={value} duration={countupDuration} decimals={0} preserveValue />
            )}
          </Typography>
          <Box ml={1}>{iconButton}</Box>
        </Box>
        {auxOnBottom && renderAuxText()}
      </Box>
    );
  },
);

export default StatsPanel;
