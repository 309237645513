import theme from 'containers/App/theme';

export const cardMenuItemStyling = {
  alignItems: 'center',
  display: 'flex',
  '& .card-icon': {
    marginRight: theme.spacing(1),
  },
  '& .card-info > *': {
    lineHeight: '20px',
  },
  '& .sub-text': {
    color: theme.palette.text.secondary,
  },
};
