import { Theme, createStyles, makeStyles } from '@material-ui/core';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const styles = (theme: Theme) =>
  createStyles({
    root: {},
    button: {
      textTransform: 'none',
      "&.MuiButton-root:hover": {
        backgroundColor: "transparent"
      },
      padding: '15px 14px',
      borderRadius: '16px',
      borderStyle: 'solid',
      borderColor: theme.palette.text.secondary,
      borderWidth: '1px',
      color: theme.palette.text.primary,
      '&:hover':{
        borderColor: theme.palette.text.primary,
      },
    },
    textButton: {
      fontWeight: 400,
      fontSize: '16px'
    }
  });

export default styles;

export const useStyles = makeStyles(styles);
