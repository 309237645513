import { Theme, createStyles } from '@material-ui/core';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default (theme: Theme) =>
  createStyles({
    root: {},
    backLink: {
      display: 'flex',
      alignitems: 'center',
    },
    button: {
      margin: theme.spacing(1),
    },
    wrapper: {
      '@media print': {
        '& button': {
          display: 'none',
        },
      },
    },
  });
