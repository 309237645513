import { createStyles, makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    creditCardIcon: {
      borderRadius: 4,
      marginRight: theme.spacing(2),
    },
  }),
);

export default useStyles;
