import React from 'react';
import { RouteComponentProps } from 'react-router-dom';

import { action, computed, flow, observable, makeObservable } from 'mobx';
import { observer } from 'mobx-react';

import { AxiosResponse } from 'axios';
import Api, { ApiResponse, getErrorMsg } from 'api';
import { inject, WithUserStore, WithToastStore } from 'stores';
import { BankAccount, IntegrationApp } from 'models';

import { setTitle, copyTextToClipboard } from 'services';

import { WithStyles, withStyles } from '@material-ui/core/styles';
import { Grid, Drawer, Box, Typography } from '@material-ui/core';

import DashboardLayout from 'containers/DashboardLayout';
import Title from 'components/Title';
import AppCard from './AppCard';

import clsx from 'clsx';
import qs from 'qs';
import { WithRouterStore } from '../../stores/RouterStore';
import styles from './styles';
import { paths } from '../../routes';
import { clearPendingIntegrations } from 'services/localStorage';
import Button from 'components/Button/Button';
import DD from 'components/DashDrawer';
import Description from './Description/Description';
import AccountToken from './AccountToken/AccountToken';
import LabelInputs from './LabelInputs/LabelInputs';
import UninstallIntegration from './UninstallIntegration/UninstallIntegration';

const PAGE_TITLE = 'Integrations';

type IntegrationProps = WithStyles<typeof styles> & // Adds the classes prop
  RouteComponentProps & // Adds the router props (history, match, location)
  WithToastStore &
  WithRouterStore &
  WithUserStore; // Adds the userStore prop

@inject('userStore', 'toastStore', 'routerStore')
@observer
class Integrations extends React.Component<IntegrationProps> {
  constructor(props: IntegrationProps) {
    super(props);
    makeObservable(this);
  }
  @observable private autoOpenedPlaid = false;
  @observable private apps?: IntegrationApp[];
  @observable private app!: IntegrationApp;
  @observable public openInstallationDrawer = false;
  @observable public maskToken = true;
  @observable public fieldError = false;
  @observable public fieldUninstallError = true;
  @observable private integrationProperties: Record<string, string> = {};
  @observable private bankAccounts?: null | BankAccount[];
  @observable private hasVerifiedBusinessBankAccount = false;
  @observable private installingIntegration = false;
  @observable public blockIntegrationActions = true;

  @computed get qsObj(): any {
    if (this.props.routerStore && this.props.routerStore.location) {
      return qs.parse(this.props.routerStore!.location.search, { ignoreQueryPrefix: true });
    }

    return undefined;
  }

  @computed get openplaid(): string | undefined {
    return this.qsObj && this.qsObj.openplaid ? this.qsObj.openplaid : undefined;
  }

  /** The accountId of the current account in scope */
  @computed public get accountId(): number {
    return this.props.userStore!.currentAccount! && this.props.userStore!.currentAccount!.id;
  }

  @computed get banksMissing(): boolean {
    if (this.bankAccounts && this.bankAccounts.length > 0) {
      return false;
    }

    return true;
  }

  isIntegrationDisabled(app: IntegrationApp): boolean {
    if (this.banksMissing && app.integrationDirection === 'inbound' && app.type === 'pos') {
      return true;
    }

    return false;
  }

  @action.bound public openDrawer() {
    this.openInstallationDrawer = true;
  }

  @action.bound public closeDrawer() {
    this.removePlaidQueryFromPath();
    this.openInstallationDrawer = false;
  }

  @action.bound public copy(accountToken: string | undefined) {
    if (accountToken) {
      if (copyTextToClipboard(accountToken)) {
        this.props.toastStore!.success('Token copied to clipboard');
      } else {
        this.props.toastStore!.error('Copying failed!');
      }
    }
  }

  @action.bound private toggleTokenMask() {
    this.maskToken = !this.maskToken;
  }

  getMaskedToken(accountToken: string | undefined) {
    if (accountToken) {
      const token = accountToken;
      const firstFour = token.substring(0, 4);
      const mask = new Array(token.length - 3).join('*');
      return `${firstFour}${mask}`;
    }
    return accountToken;
  }

  @action.bound public fetchApps = flow(function* (this: Integrations) {
    try {
      const resp: AxiosResponse<ApiResponse<IntegrationApp[]>> = yield Api.developer.getApps(
        this.accountId,
      );
      if (resp.data.data) {
        this.apps = resp.data.data as IntegrationApp[];
        const hasPausedIntegrations = this.checkForPausedIntegrations();
        this.setBlockIntegrationActions(hasPausedIntegrations);
      }
    } catch (e: any) {
      this.props.toastStore!.error(getErrorMsg(e));
    }
  });

  @action.bound public fetchBanks = flow(function* (this: Integrations) {
    try {
      const resp: AxiosResponse<ApiResponse<BankAccount[]>> = yield Api.core.getAccountBanks(
        this.accountId,
      );
      const bankAccounts = resp.data.data;
      if (bankAccounts) this.bankAccounts = bankAccounts.length === 0 ? null : bankAccounts;
    } catch (e) {
      this.props.toastStore!.push({ type: 'error', message: getErrorMsg(e) });
    }
  });

  @action.bound public async getHasVerifiedBusinessBankAccount() {
    const { data } = await Api.core.getHasVerifiedBusinessBankAccount(this.accountId);
    if (data && data.data) {
      this.hasVerifiedBusinessBankAccount = data.data.hasVerifiedBusinessBankAccount;
    }
  }

  @action.bound public async generateToken(appId: number, properties: any) {
    const appIntegrate: any = await Api.developer
      .generateToken(appId, this.accountId, properties)
      .finally(() => {
        this.fetchApps();
      });
    this.app = appIntegrate.data.data;
  }

  @action.bound private async handleGenerateToken() {
    await this.getHasVerifiedBusinessBankAccount();
    if (!this.hasVerifiedBusinessBankAccount) return;

    this.installingIntegration = true;
    try {
      await this.generateToken(this.app.id, this.integrationProperties);
      this.props.toastStore!.success('Token generated successfully');
      this.fieldError = false;
      clearPendingIntegrations();
    } catch (e) {
      this.props.toastStore!.error(getErrorMsg(e));
    } finally {
      this.installingIntegration = false;
      this.removePlaidQueryFromPath();
    }
  }

  @action.bound public async removeIntegrationById(integrationId: number) {
    await Api.developer.removeIntegration(integrationId).finally(() => {
      this.fetchApps();
      this.closeDrawer();
    });
  }

  @action.bound private async removeIntegration(integrationId: number) {
    try {
      await this.removeIntegrationById(integrationId);
      this.props.toastStore!.success('The integration has been removed successfully');
    } catch (e) {
      this.props.toastStore!.error(getErrorMsg(e));
    }
    this.fieldUninstallError = true;
  }

  @action.bound public handleOpen = (app: IntegrationApp) => {
    this.openDrawer();
    this.app = app;
  };

  @action.bound public installPendingIntegration = async () => {
    this.autoOpenedPlaid = true;
    const app: IntegrationApp | undefined =
      this.apps && this.apps.find((app) => app.id === Number(this.openplaid));
    if (app && !app.integrationId) {
      this.app = app;
      this.handleGenerateToken();
      this.openDrawer();
    }
  };

  @action.bound setBlockIntegrationActions(pausedIntegrations: boolean) {
    if (pausedIntegrations) {
      this.blockIntegrationActions = true;
    } else {
      this.blockIntegrationActions = false;
    }
  }

  @action.bound setFieldError(error: boolean) {
    this.fieldError = error;
  }

  @action.bound setIntegrationProperties(key: string, integrationProperty: string) {
    this.integrationProperties = { ...this.integrationProperties, [key]: integrationProperty };
    // this.integrationProperties[key] = integrationProperty;
  }

  checkForPausedIntegrations() {
    if (!this.apps) return false;

    const pausedApps = this.apps.filter((app: IntegrationApp) => app.status === 'paused');
    return pausedApps && pausedApps.length ? true : false;
  }

  removePlaidQueryFromPath() {
    this.props.history.replace(`${paths.integrations()}`);
  }

  componentDidMount() {
    setTitle(PAGE_TITLE, { noSuffix: false });
    this.getHasVerifiedBusinessBankAccount();
    this.fetchApps().finally(() => {
      if (this.openplaid && !this.autoOpenedPlaid) {
        this.installPendingIntegration();
      }
    });
    this.fetchBanks();
  }

  renderIsIntegrationDisabledMessage() {
    let warningMessage = '';

    if (this.isIntegrationDisabled(this.app)) {
      warningMessage = 'Add at least one bank account to enable integration';
    } else if (!this.hasVerifiedBusinessBankAccount) {
      warningMessage = 'At least one bank account must be verified to enable integration';
    }

    return warningMessage;
  }

  renderIsIntegrationDisabled() {
    let warningMessage = '';
    let buttonName = '';
    let redirect = '';
    if (this.isIntegrationDisabled(this.app)) {
      warningMessage = 'Add at least one bank account to enable integration';
      buttonName = 'Add bank account';
      redirect = `${paths.accountDetails(this.accountId).billing()}?openplaid=${this.app.id}`;
    } else if (!this.hasVerifiedBusinessBankAccount) {
      warningMessage = 'At least one bank account must be verified to enable integration';
      buttonName = 'Verify bank accounts';
      redirect = `${paths.accountDetails(this.accountId).billing()}`;
    }

    if (warningMessage && buttonName && redirect) {
      return (
        <>
          <Button
            onClick={() => this.props.history.push(redirect)}
            variant="contained"
            color="primary"
            fullWidth>
            {buttonName}{' '}
          </Button>
          {/* <Typography color="error">{warningMessage} </Typography> */}
        </>
      );
    } else {
      return (
        <Button
          color="primary"
          variant="contained"
          loading={this.installingIntegration}
          disabled={this.isIntegrationDisabled(this.app)}
          onClick={this.handleGenerateToken}
          fullWidth>
          Install Integration
        </Button>
      );
    }
  }

  render() {
    const { classes } = this.props;
    return (
      <DashboardLayout>
        <Title mb={3}>Integrations</Title>
        <Grid container spacing={3}>
          {this.apps &&
            this.apps.map((a) => (
              <Grid key={a.id} item xs={12} sm={6} md={6} lg={4} xl={3}>
                <AppCard
                  app={a}
                  blockIntegrationActions={this.blockIntegrationActions}
                  onGenerateToken={this.generateToken}
                  openDrawer={this.handleOpen}
                />
              </Grid>
            ))}
        </Grid>
        <Drawer
          open={this.openInstallationDrawer}
          onClose={this.closeDrawer}
          anchor="right"
          variant="temporary"
          classes={{ paper: clsx(classes.drawerPaper) }}>
          {this.app && (
            <DD>
              <DD.Title showDivider padding>
                {this.app.name}
              </DD.Title>
              <DD.Content>
                <Box display={'flex'} flexDirection={'column'}>
                  <Box display={'flex'} flexDirection={'colmn'} justifyContent={'center'} mb={2}>
                    <Description
                      active={this.app.status === 'active'}
                      // title={'Description:'}
                      // body={this.app.description}
                    />
                  </Box>
                  <img src={this.app.logo} alt="app_logo" className={classes.logo} />

                  {this.app.description && (
                    <Box mt={4}>
                      <Description
                        // active={this.app.status === 'active'}
                        title={'Description:'}
                        body={this.app.description}
                      />
                    </Box>
                  )}
                  {this.app.tagline && (
                    <Box mt={3}>
                      <Typography>{this.app.tagline}</Typography>
                    </Box>
                  )}
                  {this.app.status === 'active' ? (
                    <Box mt={this.app.tagline ? 0 : 3}>
                      <AccountToken app={this.app} />
                    </Box>
                  ) : null}
                  {this.app.instructions && (
                    <Box mt={3}>
                      <Description title={'Instructions:'} body={this.app.instructions} />
                    </Box>
                  )}
                </Box>
                <Box mt={3}>
                  <Typography color="error">
                    {this.renderIsIntegrationDisabledMessage()}{' '}
                  </Typography>
                </Box>
                <Box display={'flex'} flexDirection={'row'}>
                  {this.app.status !== 'active' && (
                    <Box marginTop={1} width={'50%'} mr={1}>
                      <LabelInputs
                        setFieldError={this.setFieldError}
                        setIntegrationProperties={this.setIntegrationProperties}
                        fieldError={this.fieldError}
                        labels={this.app.labels}
                      />
                    </Box>
                  )}
                  <Box marginTop={3} width={'50%'} ml={1}>
                    {this.app.status !== 'active' && this.renderIsIntegrationDisabled()}
                  </Box>
                </Box>

                {this.app.status === 'active' && (
                  <UninstallIntegration
                    app={this.app}
                    fetchApps={this.fetchApps}
                    closeDrawer={this.closeDrawer}
                  />
                )}
              </DD.Content>
            </DD>
          )}
        </Drawer>
      </DashboardLayout>
    );
  }
}

export default withStyles(styles)(Integrations);
