import { IBillingEntity } from './Billing';
import { Location } from './Location';
import { PaymentMethod } from './PaymentMethod';
import { Product } from './Product';

export type LicenseStatus = 'active' | 'inactive' | 'canceled';

export enum CancellationReason {
  BRANCH = 'Branch',
  CLIENT_FEES = 'Client Fees',
  CONVERTED_TO_BOOTH_SUITE_RENTAL = 'Converted to Booth/Suite Rental',
  EQUIPMENT_CHALLENGES = 'Equipment Challenges',
  FINAL_ATTEMPT = 'Final Attempt',
  LOCATION_CLOSED = 'Location Closed',
  NON_PROCESSING = 'Non Processing',
  NO_RESPONSE = 'No Response',
  NOT_THE_RIGHT_TIMING = 'Not The Right Timing',
  PAYROLL_TAXES_REPORTING = 'Payroll Taxes/Reporting',
  RECONCILIATION_CHALLENGES = 'Reconciliation Challenges',
  SECOND_SWIPE_NOT_INTEGRATED = 'Second Swipe/Not Integrated',
  STAFF_RESISTANCE = 'Staff Resistance',
  SUBSCRIPTION_RENEWAL = 'Subscription Renewal',
  SWITCHED_SOFTWARE = 'Switched Software',
  TRANSFER_OF_OWNERSHIP = 'Transfer Of Ownership',
  WIFI_CHALLENGES = 'WiFi Challenges',
  OTHER = 'Other',
}

export interface License {
  /**
   * Represents license id
   */
  id: number;
  /**
   * Represents license status
   */
  status: LicenseStatus;
  /**
   * Represents Location object that this license is coupled to
   */
  location: Partial<Location>;
  /**
   * Represents license description
   */
  product: Partial<Product>;
  /**
   * Represents license expiry date
   */
  expiresAt: string;
  /**
   * Represents id of payment method for this license
   */
  paymentMethod?: Partial<PaymentMethod>;
  /**
   * Whether license can be transferred
   */
  canTransfer: boolean;
  /**
   * Is license billable (based on product)
   */
  isBillable: boolean;
  /**
   * Billing entity to which license belongs to
   */
  billingEntity?: Pick<IBillingEntity, 'id' | 'name' | 'code'>;
}
