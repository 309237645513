import BaseStore from './BaseStore';
import { observable, action, computed, runInAction, makeObservable } from 'mobx';
import RootStore from './RootStore';

type ToastMessageType = 'warning' | 'info' | 'success' | 'error';

export interface ToastMessage {
  id: number;
  type: ToastMessageType;
  message: string;
  undo?: () => void;
}

// Like ToastMessage, but without `id` and with `type` being optional
type ToastMessageParam = Omit<ToastMessage, 'id' | 'type'> & Partial<Pick<ToastMessage, 'type'>>;

/**
 * The store for displaying toast messages in the snackbar (yum).
 */
export default class ToastStore extends BaseStore {
  constructor(rootStore: RootStore) {
    super(rootStore);
    makeObservable(this);
  }
  /** The timeout object to measure when a message should be hidden */
  private timeout?: NodeJS.Timeout;
  /** The duration of a single message */
  public messageDuration = 6000;
  /** The duration between messages */
  public spacing = 500;
  /** The messages go here and are displayed one by one */
  @observable public messageQueue: ToastMessage[] = [];
  /** Whether we are currently in between two messages being displayed */
  @observable public inBetweenMessages = false;
  /** Push a message onto the queue */
  @action.bound public push(toastMessage: ToastMessageParam) {
    const id = Date.now();
    const type = toastMessage.type || 'info';
    const msg = { id, type, ...toastMessage };
    // Push the message onto the queue
    this.messageQueue.push(msg);
    // If a timer isn't currently running, create one to clear
    // the current message after a set interval.
    if (!this.timeout) {
      this.timeout = setTimeout(this.dismiss, this.messageDuration);
    }
    return msg;
  }

  /** Create an error notification */
  @action.bound public error(message: string, undo?: () => void) {
    this.push({ type: 'error', message, undo });
  }

  /** Create a succes notification */
  @action.bound public success(message: string, undo?: () => void) {
    this.push({ type: 'success', message, undo });
  }

  /** Clear the currently displayed message */
  @action.bound public dismiss() {
    // Delete the message from the array
    this.messageQueue.shift();
    // Clear the current timeout
    if (this.timeout) {
      clearTimeout(this.timeout);
      this.timeout = undefined;
    }
    // If there are still messages in the queue, set up a new timer
    // to clear the next message.
    if (this.messageQueue.length > 0) {
      // First make a short interval between two messages where the messages
      // aren't shown
      this.inBetweenMessages = true;
      setTimeout(
        () =>
          // Then after around 500ms, show the new message
          runInAction(() => {
            // By setting inBetweenMessages to false
            this.inBetweenMessages = false;
            // Now we're showing the new message, set up a new interval
            // for how long we're showing it.
            this.timeout = setTimeout(this.dismiss, this.messageDuration);
          }),
        this.spacing,
      );
      // Otherwise, set the timeout to undefined
    } else {
      this.timeout = undefined;
    }
  }

  /** Calls undo on the latest message if it exists and closes the message */
  @action.bound public undo() {
    if (this.current && this.current.undo) {
      this.current.undo();
    }
    this.dismiss();
  }

  /** The current message to display */
  @computed public get current() {
    return this.messageQueue.length > 0 && !this.inBetweenMessages
      ? this.messageQueue[0]
      : undefined;
  }

  public init() {}
}

export interface WithToastStore {
  toastStore?: ToastStore;
}
