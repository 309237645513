import React from 'react';

import Skeleton from '@material-ui/lab/Skeleton';

import { CSSProperties } from '@material-ui/core/styles/withStyles';

interface SkeletonOrComponentProps {
  children: React.ReactNode;
  className?: string;
  style?: CSSProperties;
  width?: number;
  height?: number;
  rangeWidth?: number[];
}

/**
 * Display a skeleton component if the property "isLoading" is true, if not show the children component
 */
class SkeletonOrComponent extends React.Component<SkeletonOrComponentProps> {
  // Inclusive
  private randomRange(min?: number, max?: number) {
    if (!min || !max) {
      return undefined;
    }
    return Math.floor(Math.random() * (max - min + 1) + min);
  }

  render() {
    const { style, className, rangeWidth, children, width, height } = this.props;

    const [min = undefined, max = undefined] = rangeWidth || [];

    const finalWidth = this.randomRange(min, max) || width || 100;
    const finalHeight = height || 24;

    if (!children) {
      return (
        <Skeleton
          variant="text"
          width={finalWidth}
          height={finalHeight}
          style={style}
          className={className}
        />
      );
    } else {
      return children;
    }
  }
}

export default SkeletonOrComponent;
