import { Box, Typography } from '@material-ui/core';
import { ICorrection, IInvoiceUpdate, IInvoiceProduct, Location } from 'models';
import { CorrectionSingle } from './CorrectionSingle';
import { correctionSelectionStyling } from '../../styles';

interface CorrectionSelectionProps {
  locationsSelected: Location[];
  correctionsList: ICorrection[];
  invoiceProduct: (locationId: number, correctionId: number) => IInvoiceProduct | undefined;
  updateInvoiceProducts: (data: IInvoiceUpdate) => void;
  totalPrice: string;
  locationPrice: (locationId: number) => string;
}

export const CorrectionSelection = ({
  locationsSelected,
  correctionsList,
  invoiceProduct,
  updateInvoiceProducts,
  totalPrice,
  locationPrice,
}: CorrectionSelectionProps) => (
  <Box sx={correctionSelectionStyling}>
    <Typography variant="body2" className="subtitle">
      Select one or two options from below:
    </Typography>

    <Box className="locations">
      {locationsSelected.map((location) => (
        <Box className="location" key={`location-${location.id}`}>
          <Typography className="location-name">{location.name}</Typography>
          {correctionsList.map((it: ICorrection) => (
            <CorrectionSingle
              key={`correction-${it.id}`}
              correction={it}
              invoiceProduct={invoiceProduct(location.id, it.id)}
              updateCorrection={(isActive, quantity) =>
                updateInvoiceProducts({
                  locationId: location.id,
                  correctionId: it.id,
                  isActive,
                  quantity,
                })
              }
            />
          ))}
          <Typography variant="body2">Amount: {locationPrice(location.id)}</Typography>
        </Box>
      ))}
    </Box>

    <Typography variant="body2" className="total-price">
      Total amount: {totalPrice}
    </Typography>
  </Box>
);
