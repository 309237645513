import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Typography,
  WithStyles,
  withStyles,
} from '@material-ui/core';
import Api, { getErrorMsg, PagedApiResponse, RequestMetaData } from 'api';
import { AxiosResponse } from 'axios';
import DP from 'components/DashPanel';
import { debounce } from 'lodash';
import { Circle } from 'mdi-material-ui';
import {
  action,
  computed,
  flow,
  IReactionDisposer,
  observable,
  reaction,
  makeObservable,
} from 'mobx';
import { inject, observer } from 'mobx-react';
import { Device } from 'models';
import React from 'react';
import { WithToastStore } from 'stores';
import styles from './styles';
import { EmptyPanelMessage } from 'components/EmptyPanelMessage/EmptyPanelMessage';
import { useStores } from 'containers/App/App';

/** Utility function for determining the device icon */
function getIndicator(d: Device): JSX.Element {
  if (d.isActive) {
    return <Circle color="primary" fontSize="small" />;
  } else {
    return <Circle color="disabled" fontSize="small" />;
  }
}

/** Represents a single device in the list */
const DeviceListItem = observer(
  ({
    children,
    showLocation,
    forceLogout,
    resetDevice,
  }: {
    children: Device;
    showLocation?: boolean;
    forceLogout: Function;
    resetDevice: Function;
  }) => {
    const { userStore } = useStores();
    const device = children;
    let secondaryText = `${String(device.os).toUpperCase()} | ${device.app} v${device.appVersion}`;

    if (device.externalDeviceId) {
      secondaryText = secondaryText.concat(` | ${device.externalDeviceId}`);
    }

    if (showLocation && device.location) {
      secondaryText = secondaryText.concat(` | ${device.location.name}`);
    }

    const menu =
      device.app === 'tippy_pro_x' && userStore.isAdmin
        ? [
            {
              label: 'Force Logout',
              onClick: () => forceLogout(device.id),
            },
            {
              label: 'Reset Device State',
              onClick: () => resetDevice(device.id),
            },
          ]
        : undefined;

    return (
      <DP.ListItem
        key={device.identifier}
        icon={getIndicator(device)}
        primary={device.identifier}
        secondary={secondaryText}
        menu={menu}
      />
    );
  },
);

interface DevicesPanelProps extends WithStyles<typeof styles>, WithToastStore {
  devices?: Device[];
  fetch?: (meta?: RequestMetaData) => Promise<AxiosResponse<PagedApiResponse<Device>>>;
  fullHeight?: boolean;
  fullWidth?: boolean;
}
@inject('toastStore')
@observer
class DevicesPanel extends React.Component<DevicesPanelProps> {
  public constructor(props: DevicesPanelProps) {
    super(props);
    makeObservable(this);

    this.disposers.push(
      reaction(
        () => this.props.devices,
        (devices?: Device[]) => (this.devices = devices || []),
      ),
    );
  }

  private panelName = 'Devices';

  @observable public disposers: IReactionDisposer[] = [];

  /** Paginated response page size */
  @observable public pageSize = 7;

  @observable public devices: Device[] = [];

  @observable public searchString = '';

  @observable public loading = false;

  /** The total number of results, in all pages */
  @observable public count?: number;

  /** How many items to skip on the next request */
  @observable public skip = 0;

  /** How many times we've fetched the devices */
  @observable public fetchCount = 0;

  /** Whether we're loading more */
  @computed public get loadingMore() {
    return this.loading && this.fetchCount > 0;
  }

  /** Whether to display the load more button */
  @computed public get showLoadMore() {
    return Boolean(this.count && this.count > this.devices.length);
  }

  @action.bound public handleSearchOnChange(e: React.ChangeEvent<HTMLInputElement>) {
    this.searchString = e.target.value;
    this.fetchDevicesDebounced();
  }

  /** Loads the next page of devices */
  @action.bound public loadMore() {
    this.skip = this.skip + this.pageSize;
    this.fetchDevices();
  }

  /** Resets the current data, used when updating the search terms with server-side fetching */
  @action.bound public reset() {
    this.skip = 0;
    this.fetchCount = 0;
    this.devices = [];
    this.count = undefined;
  }

  @action.bound public forceLogout = flow(function* (this: DevicesPanel, deviceId: number) {
    try {
      yield Api.developer.forceLogoutDevice(deviceId);
      this.props.toastStore!.success('Device successfully disconnected');
    } catch (e: any) {
      this.props.toastStore!.error(getErrorMsg(e));
    }
  });

  @action.bound public resetDevice = flow(function* (this: DevicesPanel, deviceId: number) {
    try {
      yield Api.developer.resetDeviceState(deviceId);
      this.props.toastStore!.success('Device state successfully reset');
    } catch (e) {
      this.props.toastStore!.error(getErrorMsg(e));
    }
  });

  /** Fetches the devices with the current paging data */
  @action.bound public fetchDevices = flow(function* (this: DevicesPanel) {
    if (!this.props.fetch) return;
    try {
      this.loading = true;
      // Pass the pagination data and the search string
      // to the fetch function and get the response
      const resp: AxiosResponse<PagedApiResponse<Device>> = yield this.props.fetch({
        filters: {
          locationName: this.searchString,
        },
        pagination: {
          take: this.pageSize,
          skip: this.skip,
        },
      });
      // If the response has data, add the new set of devices to
      // the current devices array and update the total count
      if (resp.data) {
        const devices = resp.data.data || [];
        this.devices.push(...devices);
        this.count = resp.data.count;
      }

      this.loading = false;
      // We count how many times we've fetched so we know whether the
      // user is loading more data or loading the initial set of data
      this.fetchCount = this.fetchCount + 1;
    } catch (e: any) {
      this.props.toastStore!.push({
        type: 'error',
        message: 'Failed to load devices',
      });
    } finally {
      this.loading = false;
    }
  });

  private fetchDevicesDebounced = debounce(() => {
    this.reset();
    this.fetchDevices();
  }, 500);

  @computed get numberOfDevices() {
    if (this.devices) {
      return this.devices.length;
    } else {
      return 0;
    }
  }

  componentDidMount() {
    this.props.fetch && this.fetchDevices();
  }

  /** Before unmounting the component, dispose of all auto-runs and reactions */
  componentWillUnmount() {
    this.disposers.map((disposer) => disposer());
  }

  renderWithFetch() {
    return this.loading ? (
      <DP.List>
        <DP.Loading items={5} variant="circleWithTwoLines" />
      </DP.List>
    ) : this.devices && this.devices.length > 0 ? (
      <Box>
        <DP.SearchInput
          value={this.searchString}
          onChange={this.handleSearchOnChange}
          placeholder={'Search by devices'}
        />
        <DP.List height={this.numberOfDevices < 5 ? 'short' : 'normal'}>
          {this.devices.map((device) => (
            <DeviceListItem
              key={device.identifier}
              showLocation
              forceLogout={this.forceLogout}
              resetDevice={this.resetDevice}>
              {device}
            </DeviceListItem>
          ))}
          {this.showLoadMore && (
            <Grid item xs={12}>
              <Box position="relative" display="flex" justifyContent="center" mt={1}>
                <Box position="absolute" top={8} left={16}>
                  <Typography variant="subtitle2">
                    Loaded {this.devices.length} out of {this.count} kiosks
                  </Typography>
                </Box>
                {this.loadingMore ? (
                  <CircularProgress />
                ) : (
                  <Button color="primary" size="large" onClick={this.loadMore}>
                    Load more
                  </Button>
                )}
              </Box>
            </Grid>
          )}
        </DP.List>
      </Box>
    ) : (
      <DP.Body>
        <Box display={'flex'} alignContent={'center'}>
          <EmptyPanelMessage panelTitle={this.panelName} />
        </Box>
      </DP.Body>
    );
  }

  renderLocal() {
    return this.loading ? (
      <DP.List>
        <DP.Loading items={5} variant="circleWithTwoLines" />
      </DP.List>
    ) : this.devices && this.devices.length > 0 ? (
      <Box>
        <DP.List height={this.numberOfDevices < 5 ? 'short' : 'normal'}>
          {this.devices.map((device) => (
            <DeviceListItem
              key={device.identifier}
              forceLogout={this.forceLogout}
              resetDevice={this.resetDevice}>
              {device}
            </DeviceListItem>
          ))}
        </DP.List>
      </Box>
    ) : (
      <DP.Body>
        <Box display={'flex'} alignContent={'center'}>
          <EmptyPanelMessage panelTitle={this.panelName} />
        </Box>
      </DP.Body>
    );
  }

  render() {
    const { fullHeight, fullWidth } = this.props;

    return (
      <DP fullHeight={fullHeight} fullWidth={fullWidth}>
        <DP.Header>
          <DP.Title panel>{this.panelName.toLowerCase()}</DP.Title>
        </DP.Header>
        {!this.props.fetch ? this.renderLocal() : this.renderWithFetch()}
      </DP>
    );
  }
}

export default withStyles(styles)(DevicesPanel);
