import { Theme, alpha, createStyles } from '@material-ui/core';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default (theme: Theme) =>
  createStyles({
    root: {
      position: 'relative',
    },
    leftButton: {
      position: 'absolute',
      left: 0,
    },
    stepList: {
      paddingLeft: theme.spacing(2),
    },
    debitCardContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      marginRight: theme.spacing(3),
      textAlign: 'center',
    },
    debitCardText: {},
    orContainer: {
      width: '100%',
      height: theme.spacing(3),
      position: 'relative',
      display: 'flex',
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(2),
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center',
    },
    orInner: {
      borderTop: `1px solid ${theme.palette.secondary.main}`,
      position: 'absolute',
      top: theme.spacing(1.5),
      zIndex: 100,
      left: 0,
      right: 0,
    },
    orText: {
      backgroundColor: 'white',
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      color: theme.palette.secondary.main,
      zIndex: 101,
    },
    img: {
      width: theme.spacing(14),
      height: 'auto',
      display: 'block',
    },
    achCard: {
      background: `linear-gradient(180deg, #66B6E4 0%, ${theme.palette.secondary.main} 200%)`,
      height: theme.spacing(9),
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      color: theme.palette.common.white,
      fontSize: theme.typography.h5.fontSize,
      width: theme.spacing(14),
      borderRadius: theme.spacing(1),
    },
    backdrop: {
      backgroundColor: theme.palette.primary.main,
      zIndex: theme.zIndex.drawer + 1,
    },
    wrapper: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyItems: 'center',
    },
    iconWrapper: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: 172,
      height: 172,
      borderRadius: '50%',
      backgroundColor: alpha(theme.palette.status.red, 0.2),
    },
    icon: {
      width: 72,
      height: 72,
      color: theme.palette.status.red,
    },
  });
