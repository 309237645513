import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { observer } from 'mobx-react';
import { Theme, createStyles, makeStyles } from '@material-ui/core';

import ErrorCatcher from '../../utils/errorBoundaries';
import theme from 'containers/App/theme';
import useDashboardLayout from './useDashboardLayout';
import MenuDrawer from './MenuDrawer/MenuDrawer';
import ElevatedAppBar from './ElevatedAppBar.tsx/ElevatedAppBar';
import { dynamicPadding } from 'utils/commonStyles';

interface IStyleProps {
  mobileView: boolean;
  mobileMenuOpen: boolean;
}

const paddingTop = ({ mobileView }: IStyleProps) => {
  if (mobileView) return theme.appBar.mobile.height + theme.spacing(3);
  return theme.appBar.height;
};
const contentHeight = ({ mobileView }: IStyleProps) => {
  if (mobileView) return `calc(100dvh - ${theme.appBar.mobile.height + theme.spacing(3)}px)`;
  return `calc(100dvh - ${theme.appBar.mobile.height}px)`;
};
const useStyles = makeStyles<Theme, IStyleProps>((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      height: '100dvh',
      overflow: 'hidden',
    },
    menuButton: {
      marginRight: 36,
    },
    content: {
      flexGrow: 1,
      marginTop: paddingTop,
      height: contentHeight,
      display: 'flex',
      overflowY: 'auto',
      flexDirection: 'column',
      justifyContent: 'space-between',
      ...dynamicPadding,
    },
  }),
);

const DashboardLayout = ({ children }: { children: React.ReactNode }) => {
  const { mobileView, mobileMenuOpen, contentRef } = useDashboardLayout();
  const classes = useStyles({ mobileView, mobileMenuOpen });

  return (
    <div className={classes.root}>
      <ElevatedAppBar />
      <MenuDrawer />
      <main ref={contentRef} className={classes.content}>
        <Box>
          <ErrorCatcher>{children}</ErrorCatcher>
        </Box>
        <Footer />
      </main>
    </div>
  );
};

export default observer(DashboardLayout);

const Footer = () => {
  return (
    <Box pb={3} pt={3} style={{ display: 'block', textAlign: 'center' }}>
      <Typography variant="body2">Dashboard version: 2.14.0</Typography>
    </Box>
  );
};
