import {
  Box,
  FormControl,
  FormHelperText,
  Input,
  InputAdornment,
  InputLabel,
} from '@material-ui/core';
import React from 'react';
import useStyles from './styles';
import useUnderlineInput, { UnderlineInputProps } from './useUnderlineInput';

export default function UnderlineInput({
  error,
  variant = 'normal',
  label,
  prefix,
  width,
  fontSize,
  helperText,
  ...rest
}: Omit<UnderlineInputProps, 'startAdornment'>) {
  const [_width, _fontSize] = useUnderlineInput({ variant, width, fontSize });
  const { input, fixedAmountLabels, inputAdornment } = useStyles({
    width: _width,
    fontSize: _fontSize,
    variant,
  });
  helperText = helperText ? `Field ${label?.toUpperCase()} field is required.` : helperText;

  return (
    <Box>
      <FormControl margin="normal">
        <Input
          startAdornment={
            prefix && (
              <InputAdornment className={inputAdornment} position="start">
                {prefix}
              </InputAdornment>
            )
          }
          {...rest}
          className={input}
        />
      </FormControl>
      <InputLabel className={fixedAmountLabels}>{label}</InputLabel>
      <FormHelperText error={error}>{helperText}</FormHelperText>
    </Box>
  );
}
