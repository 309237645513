export enum AlertType {
  ISSUE = 'issue',
  GENERAL = 'general',
  PROMOTION = 'promotion',
  INFO = 'info',
}
export enum AlertTargetType {
  ALL = 'all',
  CUSTOM = 'custom',
}

export enum AlertStepsDialog {
  UNDEFINED = 'undefined',
  INIT = 'init',
  FORM = 'form',
  TARGET = 'target',
  SUMMARY = 'summary',
}

export enum AlertSeverity {
  LOW = 'low',
  MEDIUM = 'medium',
  HIGH = 'high',
}

export interface AlertTarget {
  targetType: AlertTargetType;
  kioskMode?: unknown[];
  app?: unknown[];
  processor?: unknown[];
  accountId?: unknown[];
  locationId?: unknown[];
}

export interface Alert {
  id: number;
  isActive?: boolean;
  message: string;
  type: AlertType;
  severity?: AlertSeverity;
  createdAt: string;
  updatedAt?: string;
  deactivatedAt?: string;
  cause?: string;
  createdById: number;
  createdBy: {
    id: number;
    firstName: string;
    lastName: string;
  };
  link?: string;
  dismissible?: boolean;
  target?: AlertTarget;
}

export type NewAlertRequest = Pick<
  Alert,
  'message' | 'type' | 'severity' | 'cause' | 'isActive' | 'link' | 'dismissible' | 'target'
>;
