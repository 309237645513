import { Drawer } from '@material-ui/core';
import { inject, observer } from 'mobx-react';
import React from 'react';
import { WithToastStore, WithStationsStore } from 'stores';
import { AddStationUsers } from '../AddStationUsers/AddStationUsers';
import { AddStation as AddStationState } from 'models/Station';
import { AddStation } from '../AddStation/AddStation';
import { AddStationPoolsOrGroups } from '../AddStationPoolsOrGroups/AddStationPoolsOrGroups';
import { AddStationPools } from '../AddStationPools/AddStationPools';
import PoolsDrawer from 'components/PoolsPanel/PoolsDrawer';
import { DrawerType } from 'types/enums';
import { useStationDrawer } from './useStationDrawer';
import { AddStationGroup } from '../AddStationGroup/AddStationGroup';
import { StationQrCode } from 'components/StationQrCode/StationQrCode';

type StationDrawerProps = {
  locationId?: number;
  accountId?: number;
} & WithToastStore &
  WithStationsStore;

export const StationDrawer = inject(
  'notificationStore',
  'toastStore',
  'stationsStore',
)(
  observer((props: StationDrawerProps) => {
    const { stationsStore, locationId, accountId } = props;
    const [
      openAddStation,
      addStationState,
      createPool,
      updatePool,
      goBack,
      setLocationId,
      setAccountId,
    ] = useStationDrawer();

    if (locationId) {
      setLocationId(locationId);
    }
    if (accountId) {
      setAccountId(accountId);
    }

    let view;
    switch (addStationState) {
      case AddStationState.QR_CODE:
        view = <StationQrCode station={stationsStore!.station!} isOpen={addStationState === AddStationState.QR_CODE} />;
        break;
      case AddStationState.ADD:
        view = <AddStation />;
        break;
      case AddStationState.GROUP_USERS:
      case AddStationState.USERS:
        view = <AddStationUsers />;
        break;
      case AddStationState.POOLS_GROUPS:
        view = <AddStationPoolsOrGroups />;
        break;
      case AddStationState.GROUP_POOLS:
      case AddStationState.POOLS:
        view = <AddStationPools />;
        break;
      case AddStationState.GROUP:
        view = <AddStationGroup />;
        break;
      case AddStationState.GROUP_POOL:
        view = (
          <PoolsDrawer
            isOpen={true}
            onClose={goBack}
            createPool={createPool}
            updatePool={updatePool}
            type={DrawerType.ADD}
          />
        );
        break;
      case AddStationState.POOL:
        view = (
          <PoolsDrawer
            isOpen={true}
            onClose={goBack}
            createPool={createPool}
            updatePool={updatePool}
            type={DrawerType.ADD}
          />
        );
        break;
      default:
        view = <AddStation />;
        break;
    }
    return (
      <Drawer open={openAddStation} anchor="right" variant="temporary">
        {view}
      </Drawer>
    );
  }),
);
