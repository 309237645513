import React from 'react';
import { observable, action, flow, makeObservable } from 'mobx';
import { observer } from 'mobx-react';
import { WithStyles, withStyles } from '@material-ui/core/styles';
import {
  ReactStripeElements,
  injectStripe,
  CardElement,
  Elements,
  StripeProvider,
} from 'react-stripe-elements';
import config from 'config';

import { getErrorMsg } from 'api';

import {
  DialogActions,
  DialogContent,
  CircularProgress,
  DialogTitle,
  Box,
  Divider,
  // Button,
  DialogContentText,
} from '@material-ui/core';

import { inject, WithToastStore } from 'stores';
import Overlay from 'components/Overlay';

import styles from './styles';
import { PaymentMethodAction } from '../../models';
import Title from 'components/Title/Dialog/Title';
import Button from 'components/Button/Dialog/Button';

interface AddPaymentMethodProps
  extends WithStyles<typeof styles>,
    WithToastStore,
    ReactStripeElements.InjectedStripeProps {
  // Called with the source id once it's successfully captured
  onSourceId: (s: string) => unknown;
  // Called when the user cancels the payment adding process
  onCancel: () => void;
  method: PaymentMethodAction;
}

/**
 * We wrap this component in AddPaymentMethodWrapper because stripe elements
 * requires that the component that has stripe injected is wrapped with
 * the <Elements> and <StripeProvider> tags.
 */
@inject('toastStore')
@observer
class AddPaymentMethodBase extends React.Component<AddPaymentMethodProps> {
  constructor(props: AddPaymentMethodProps) {
    super(props);
    makeObservable(this);
  }
  /** Whether the payment method is in the process of being added */
  @observable public addingPaymentMethod = false;

  /** Submits the payment method */
  @action.bound public submit = flow(function* (this: AddPaymentMethodBase) {
    try {
      const resp = yield this.props.stripe!.createSource({ type: 'card', currency: 'USD' });
      if (resp.source && resp.source.id) {
        this.addingPaymentMethod = true;
        yield this.props.onSourceId(resp.source.id);
      }
    } catch (e: any) {
      this.props.toastStore!.error(getErrorMsg(e));
    }
  });

  /** The form submit handler */
  handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    this.submit();
  };

  render() {
    const { classes, method } = this.props;
    return (
      <form onSubmit={this.handleSubmit} className={classes.addPaymentMethodForm}>
        <Overlay display={this.addingPaymentMethod}>
          <CircularProgress />
        </Overlay>
        {/* <DialogTitle><Typography>{method} payment method</Typography></DialogTitle> */}
        <DialogTitle className={this.props.classes.dialogTitle}>
          <Title size="medium">{method} payment method</Title>
        </DialogTitle>

        <DialogContent>
          <DialogContentText>
            {`Once you ${
              method === PaymentMethodAction.ADD ? 'add' : 'edit'
            } a payment method, you can use it to pay for licenses.`}
          </DialogContentText>
          <Divider />
          <Box mt={3} mb={3}>
            <CardElement />
          </Box>
          <Divider />
        </DialogContent>
        <DialogActions style={{ padding: '20px' }}>
          <Button onClick={this.props.onCancel}>Cancel</Button>
          <Button color="primary" type="submit" variant="contained">
            {method === PaymentMethodAction.ADD ? 'Add' : 'Save'}
          </Button>
        </DialogActions>
      </form>
    );
  }
}

const AddPaymentMethod: any = injectStripe(withStyles(styles)(AddPaymentMethodBase));

/**
 * Allows the user to add a payment method to an account. When mounting
 * this component, make sure that the stripe.js script is included
 * in the head and loaded.
 */
@observer
class AddPaymentMethodWrapper extends React.Component<
  Pick<AddPaymentMethodProps, 'onCancel' | 'onSourceId' | 'method'>
> {
  render() {
    return (
      <StripeProvider apiKey={config.stripe.publicKey}>
        <Elements>
          <AddPaymentMethod
            onSourceId={this.props.onSourceId}
            onCancel={this.props.onCancel}
            method={this.props.method}
          />
        </Elements>
      </StripeProvider>
    );
  }
}

export default AddPaymentMethodWrapper;
