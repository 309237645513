import logo from 'images/pig_green_white.svg';
import { TSignupLayoutIcon } from '../SignupLayout';
import theme from 'containers/App/theme';

interface IIcon {
  mb: number;
  icon?: TSignupLayoutIcon;
  height?: number;
}
function Icon({ icon, mb, height = 100 }: IIcon): any {
  if (icon && !('src' in icon)) return icon;
  return (
    <img
      src={icon?.src || logo}
      alt="Tippy"
      height={height}
      style={{ marginBottom: theme.spacing(mb) }}
    />
  );
}

export default Icon;
