import { useEffect, useRef, useState } from 'react';
import { getSecondsUntilExpiration } from 'utils/helper';
import { formatTime, getMinutesAndSeconds } from '../helpers';

interface IUseCountDownProps {
  initialTime?: string;
}

/**
 *
 * @param props initialTime takes in UTC string
 * @returns minutes and seconds left until expiration in 1 seconds intervals
 */
const useCountdown = (props: IUseCountDownProps) => {
  const time = useRef(0);
  const [formattedTime, setFormattedTime] = useState<{ minutes: string; seconds: string }>({
    minutes: '0',
    seconds: '00',
  });

  useEffect(() => {
    updateTime(props?.initialTime);
    handleTimeUpdate(time.current);

    const timer = setInterval(() => {
      let _time = time.current;
      if (!_time) {
        clearInterval(timer);
      } else {
        _time = _time - 1;
      }
      handleTimeUpdate(_time);
      time.current = _time;
    }, 1000);
    return () => clearInterval(timer);
  }, [props.initialTime]);

  const updateTime = (utcTime?: string) => {
    const seconds = getSecondsUntilExpiration(utcTime);
    time.current = seconds;
  };

  const handleTimeUpdate = (time: number) => {
    const minutesAndSeconds = getMinutesAndSeconds(time);
    const formattedTime = formatTime(minutesAndSeconds);
    setFormattedTime(formattedTime);
  };

  return {
    time: formattedTime,
  };
};

export default useCountdown;
