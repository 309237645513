/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box, IconButton, InputAdornment, MenuItem, Tooltip } from '@material-ui/core';
import { WithStyles, withStyles } from '@material-ui/core/styles';
import Api, { ApiResponse, getErrorMsg } from 'api';
import { AxiosResponse } from 'axios';
import DP from 'components/DashPanel';
import OutlinedInput from 'components/Input/OutlinedInput';
import { isEmpty } from 'lodash';
import { AccountConvert, Check, Close, Pencil } from 'mdi-material-ui';
import { action, computed, flow, observable, makeObservable } from 'mobx';
import { observer } from 'mobx-react';
import { Affiliate, AffiliateOrganization, AffiliateRegion, User } from 'models';
import React from 'react';
import { numericStringToUsd } from 'services';
import { inject, WithModalStore, WithToastStore, WithUserStore } from 'stores';
import validatorjs from 'validatorjs';
import styles from './styles';
import Dialog from 'components/Dialog/Dialog';
import Typography from '@mui/material/Typography';
import { Checkbox } from 'components/Checkbox/Checkbox';

type MobxForm = any;

// eslint-disable-next-line @typescript-eslint/no-var-requires
const dvr = require('mobx-react-form/lib/validators/DVR');
const MobxReactForm = require('mobx-react-form').default;

const plugins = {
  dvr: dvr({
    package: validatorjs,
  }),
};

interface FormHooks {
  onInit?: (form: MobxForm) => void;
  onSuccess?: (form: MobxForm) => void;
  onClear?: (form: MobxForm) => void;
}

interface AffiliatePanelProps
  extends WithStyles<typeof styles>,
    WithToastStore,
    WithUserStore,
    WithModalStore {
  affiliate: Affiliate;
  onUpdateAffiliate: () => Promise<unknown>;
  user?: User;
}

/**
 * Displays affiliate's details.
 */
@inject('toastStore', 'userStore', 'modalStore')
@observer
class AffiliatePanel extends React.Component<AffiliatePanelProps> {
  constructor(props: AffiliatePanelProps) {
    super(props);
    makeObservable(this);
    this.form = new MobxReactForm({ fields: this.fields }, { plugins, hooks: this.hooks });
  }

  /** The form object */
  @observable private form: MobxForm;

  /** Form hooks */
  @observable private hooks: FormHooks = {
    onSuccess: () => {
      this.editing = false;
      this.updateAffiliate();
    },
    onClear: () => {
      this.editing = false;
    },
  };

  /** Array of options for organization select dropdown */
  @observable private organizations?: AffiliateOrganization[];

  /** Array of options for region select dropdown */
  @observable private regions?: AffiliateRegion[];

  /** Is panel in edit mode? */
  @observable private editing = false;

  /** Is panel data being updated? */
  @observable private updating = false;

  @observable private openDialog = false;

  /** Is authenticated user an admin? */
  @computed public get isAdmin(): boolean {
    return this.props.userStore!.isAdmin;
  }
  /** The affiliate user object */
  @computed public get affiliate(): Affiliate {
    return this.props.affiliate;
  }

  /** Is region select visible? */
  @computed public get showRegionSelect(): boolean {
    // If affiliates current or selected organization
    // has a single region we want to hide region select
    // and set its value to that single region
    return !!this.regions && this.regions.length > 1;
  }

  /** Update affiliate */
  @action.bound public updateAffiliate = flow(function* (this: AffiliatePanel) {
    try {
      this.updating = true;
      const affiliateId = this.affiliate.id;
      const regionId = this.form.$('region').value;
      const commissionFee = this.form.$('commission').value;
      const isW9Received = this.form.$('isW9Received').value;
      const affiliateData = {
        id: affiliateId,
        regionId: regionId ? regionId : undefined,
        commissionFee,
        isW9Received,
      };
      yield Api.marketing.updateAffiliate(affiliateData);
      this.setOpenDialog(false);
      this.props.toastStore!.push({
        message: 'Affiliate successfully updated',
        type: 'success',
      });
      if (this.props.onUpdateAffiliate) {
        yield this.props.onUpdateAffiliate();
      }
    } catch (e: unknown) {
      this.props.toastStore!.error(getErrorMsg(e));
    } finally {
      this.updating = false;
    }
  });

  /**
   * Fetch all affiliate organizations including their regions which are used
   * as predefined values for organization and region select options.
   */
  @action.bound public getOrganizations = flow(function* (this: AffiliatePanel) {
    try {
      const resp: AxiosResponse<ApiResponse<AffiliateOrganization[]>> =
        yield Api.marketing.getAffiliateOrganizations();
      if (resp.data.data) {
        this.organizations = resp.data.data;
      }
      // If props passed affiliate object has region and organization set,
      // use that organization to find and set its regions as this.regions
      if (this.affiliate.region && this.organizations) {
        const id = this.affiliate.region.organization.id;
        const organization = this.organizations.find((o) => o.id === id);
        if (organization) {
          this.regions = organization.regions;
        }
      }
    } catch (e: unknown) {
      this.props.toastStore!.error(getErrorMsg(e));
    }
  });

  @action.bound public convertToAffiliate = flow(function* (this: AffiliatePanel) {
    if (this.props.user) {
      const userText = `${this.props.user.firstName} ${this.props.user.lastName}` || 'this user';
      const modalTitle = 'Convert user to affilate';
      const modalMessage = `Are you sure you want to convert ${userText} to affilate?`;
      // Request additional action confirmation via confirmation dialog
      const confirmed: boolean = yield this.props.modalStore!.confirm(modalTitle, modalMessage, {
        confirmLabel: 'Convert',
      });
      if (confirmed) {
        try {
          // create affiliate
          const resp: AxiosResponse<ApiResponse<Affiliate>> = yield Api.marketing.createAffiliate(
            this.props.user.id,
          );
          // approve affiliate
          if (resp && resp.data && resp.data.data) {
            yield Api.marketing.approveAffiliate(resp.data.data);
          }
          this.props.toastStore!.push({
            type: 'success',
            message: `User successfully converted and approved`,
          });
          this.props.onUpdateAffiliate();
        } catch (e: any) {
          if (e.response && e.response.data && e.response.data.error) {
            const message = e.response.data.error.message;
            this.props.toastStore!.push({ type: 'error', message });
          } else {
            throw e;
          }
        }
      }
    }
  });

  @action.bound setOpenDialog(open: boolean) {
    this.openDialog = open;
  }

  componentDidMount() {
    this.getOrganizations();
  }

  isRequired(name: string) {
    if (!this.form.$(name).rules) return false;

    return this.form.$(name).rules.includes('required');
  }

  @observable private fields = [
    {
      name: 'organization',
      label: 'Organization',
      type: 'select',
      value: this.affiliate.region ? this.affiliate.region.organization.id : '',
      hooks: {
        onChange: (field: any) => {
          // Set new options for region select when organization select value changes
          if (this.organizations) {
            const selectedOrganization = this.organizations.find((o) => o.id === field.value);
            if (selectedOrganization) {
              this.regions = selectedOrganization.regions;
              this.form.$('region').set(this.regions[0].id);
            }
          }
        },
      },
    },
    {
      name: 'region',
      label: 'Region',
      type: 'select',
      value: this.affiliate.region ? this.affiliate.region.id : '',
    },
    {
      name: 'commission',
      label: 'Commission',
      value: this.affiliate.commissionFee,
      rules: ['required'],
      hooks: {
        onChange: (field: any) => {
          const delimiter = '.';
          const inputString = field.value;

          // Allowed input characters are numbers, minus sign, and the dot
          const lastChar = inputString[inputString.length - 1];
          const isValidChar = new RegExp(/^[0-9.]$/).test(lastChar);
          if (!isValidChar) {
            field.set(inputString.slice(0, -1));
          }
          // Dot decimal delimiter cannot appear twice
          const delimiterCount = inputString.split(delimiter).length - 1;
          if (delimiterCount > 1) {
            field.set(inputString.slice(0, -1));
          }
          // There can only be two digits behind a dot decimal delimiter
          const indexOfDelimiter = inputString.indexOf(delimiter);
          if (inputString.includes(delimiter) && indexOfDelimiter < inputString.length - 3) {
            field.set(inputString.slice(0, -1));
          }
        },
      },
    },
    {
      name: 'isW9Received',
      label: 'W-9 Form Received',
      type: 'checkbox',
      value: this.affiliate.isW9Received,
    },
  ];

  render() {
    const { updating, editing, showRegionSelect } = this;
    const noValue = 'N/A';
    return (
      <>
        <DP>
          <form onSubmit={this.form.onSubmit}>
            <DP.Header>
              <Box display="flex" flexDirection="row" alignItems="center">
                <DP.Title panel>Affiliate Info</DP.Title>
              </Box>
              {isEmpty(this.affiliate) && this.isAdmin && (
                <DP.Actions>
                  <IconButton color="primary" onClick={this.convertToAffiliate}>
                    <Tooltip title="Convert to Affiliate">
                      <AccountConvert fontSize="small" />
                    </Tooltip>
                  </IconButton>
                </DP.Actions>
              )}
              {!isEmpty(this.affiliate) && (
                <DP.Actions>
                  {updating ? (
                    <DP.LoadSpinner />
                  ) : (
                    <>
                      {editing ? (
                        <>
                          <DP.IconButton
                            onClick={() => this.setOpenDialog(false)}
                            icon={Close}
                            tooltip="Discard"
                          />
                          <DP.IconButton
                            primary
                            submit
                            onClick={this.form.onSubmit}
                            icon={Check}
                            tooltip="Save"
                          />
                        </>
                      ) : (
                        <DP.IconButton
                          primary
                          onClick={() => this.setOpenDialog(true)}
                          icon={Pencil}
                          tooltip="Edit"
                        />
                      )}
                    </>
                  )}
                </DP.Actions>
              )}
            </DP.Header>
            {!isEmpty(this.affiliate) && (
              <DP.Body>
                <DP.Row>
                  <DP.Value>
                    {this.affiliate.region ? this.affiliate.region.organization.name : noValue}
                  </DP.Value>

                  <DP.Label>Organization</DP.Label>
                </DP.Row>
                {showRegionSelect && (
                  <DP.Row>
                    <DP.Value>
                      {this.affiliate.region ? this.affiliate.region.name : noValue}
                    </DP.Value>
                    <DP.Label>Region</DP.Label>
                  </DP.Row>
                )}
                <DP.Row>
                  <DP.Value>{numericStringToUsd(this.affiliate.commissionFee) || noValue}</DP.Value>
                  <DP.Label>Commission</DP.Label>
                </DP.Row>
              </DP.Body>
            )}
          </form>
        </DP>
        <Dialog
          open={this.openDialog}
          title={'Affiliate Info'}
          content={
            <>
              <Box mb={2}>
                <OutlinedInput
                  {...this.form.$('organization').bind()}
                  required={this.isRequired('organization')}
                  error={this.form.$('organization').error}
                  select
                  fullWidth>
                  {this.organizations &&
                    this.organizations.map((o) => (
                      <MenuItem key={o.name} value={o.id}>
                        {o.name}
                      </MenuItem>
                    ))}
                </OutlinedInput>
              </Box>

              {showRegionSelect && (
                <Box mb={2}>
                  <OutlinedInput
                    {...this.form.$('region').bind()}
                    required={this.isRequired('region')}
                    error={this.form.$('region').error}
                    select
                    fullWidth>
                    {this.regions &&
                      this.regions.map((r) => (
                        <MenuItem key={r.name} value={r.id}>
                          {r.name}
                        </MenuItem>
                      ))}
                  </OutlinedInput>
                </Box>
              )}

              <Box mb={2}>
                <OutlinedInput
                  {...this.form.$('commission').bind()}
                  value={this.form.$('commission').value}
                  error={Boolean(this.form.$('commission').error)}
                  helperText={this.form.$('commission').error}
                  startAdornment={<InputAdornment position="start">$</InputAdornment>}
                  required={this.isRequired('commission')}
                  fullWidth
                />
              </Box>

              <Box display={'flex'} alignContent={'flex-start'} justifyContent={'flex-start'}>
                <Checkbox
                  checked={this.form.$('isW9Received').value}
                  {...this.form.$('isW9Received').bind({ type: 'checkbox' })}
                  className={this.props.classes.checkbox}
                />
                <Box>
                  <Typography variant="body1">{this.form.$('isW9Received').label}</Typography>
                  <Typography variant="subtitle1">
                    Check this box if the user has submitted a W-9 form
                  </Typography>
                </Box>
              </Box>
            </>
          }
          disabled={!!this.form.error}
          loading={this.updating}
          onCancel={() => this.setOpenDialog(false)}
          onClose={() => this.setOpenDialog(false)}
          TransitionProps={{
            onExited: () => this.form.reset(),
          }}
          onConfirm={this.form.onSubmit}
        />
      </>
    );
  }
}

export default withStyles(styles)(AffiliatePanel);
