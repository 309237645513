import { IconButton } from '@material-ui/core';
import ImageIcon from 'components/ImageIcon';
import useStyles from './styles';
import LogoUploadDialog, {
  LogoUploadDialogProps,
} from 'components/LogoUploadDialog/LogoUploadDialog';
import useAccountLogo from './useAccountLogo';
import { observer } from 'mobx-react';

export interface IAccountLogo extends Pick<LogoUploadDialogProps, 'account' | 'refreshAccount'> {
  logoEditable?: boolean;
}

const AccountLogo = ({ logoEditable, ...rest }: IAccountLogo) => {
  const { button } = useStyles();
  const { logo, logoDialogProps, handleDialogOpen } = useAccountLogo(rest);

  return (
    <>
      <IconButton className={button} disabled={!logoEditable} onClick={handleDialogOpen}>
        <ImageIcon src={logo} size={42} radius={12} type="logo" />
      </IconButton>

      <LogoUploadDialog {...logoDialogProps} />
    </>
  );
};

export default observer(AccountLogo);
