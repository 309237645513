/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { observer } from 'mobx-react';
import { action, observable, makeObservable } from 'mobx';

import { Box, Link, Tooltip } from '@material-ui/core';
import { ThumbUpOutline, ThumbDownOutline } from 'mdi-material-ui';
import { WithStyles, withStyles } from '@material-ui/core/styles';
import { Link as RouterLink, RouteComponentProps } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { inject, WithUserStore, WithToastStore, WithAnalyticsStore } from 'stores';

import Api, { RequestMetaData } from 'api';
import { adaptForDataGridPro } from 'services';
import { downloadCsvFile } from '../../utils/helper';
import { paths } from 'routes';
import styles from './styles';
import * as DateRangeExternalPicker from 'components/DateRangeExternalPicker';
import { Filter } from 'components/FilterBar/FilterBar';
import FilterBar from 'components/FilterBar';
import DataGridInfiniteScroll from 'components/DataGridInfiniteScroll';

/** Here we define what kind of props this component takes */
type UnderperformingLocationsProps = WithStyles<typeof styles> & // Adds the classes prop
  WithUserStore &
  WithAnalyticsStore &
  RouteComponentProps &
  WithToastStore; // Adds the userStore prop

function round(x: number | string) {
  const n = typeof x === 'string' ? parseFloat(x) : x;
  const res = Math.round((n + Number.EPSILON) * 100) / 100;
  return res;
}

/**
 * Container for displaying underperforming locations
 */
@inject('userStore', 'toastStore', 'analyticsStore')
@observer
class UnderperformingLocations extends React.Component<UnderperformingLocationsProps> {
  constructor(props: UnderperformingLocationsProps) {
    super(props);
    makeObservable(this);
  }

  /** Active filters as returned by FilterBar */
  @observable private activeFilters: Record<string, unknown> = {};

  /** The selected date range */
  @observable public dateRange: DateRangeExternalPicker.DateRange = DateRangeExternalPicker.getDateRange();

  /** Watch for change on table filters and build req meta data */
  @observable private dataGridFilters = {};

  @action.bound private downloadCsvReport = async () => {
    try {
      const res = await Api.analytics.performance.getUnderperformingLocationsReport({
        filters: { ...this.dataGridFilters },
      });
      if (res && res.data) {
        downloadCsvFile(res.data, 'locations_performance_report.csv');
      }
    } catch (error: any) {
      this.props.toastStore!.push({
        message: 'Unable to download requested report',
        type: 'error',
      });
    }
  };

  @action.bound private setFilters = (filters: any) => {
    this.dataGridFilters = filters.reduce((data: any, dataGridFilter: any) => {
      return { ...data, [dataGridFilter.key]: dataGridFilter.value };
    }, {});
  };
  /** Render account as links to accounts */
  renderCellAccount = ({ row }: any) => {
    return (
      <Link component={RouterLink} to={paths.accountDetails(row.accountId).root()}>
        {row.accountName}
      </Link>
    );
  };

  /** Render account as links to location */
  renderCellLocation = ({ row }: any) => {
    return (
      <Link component={RouterLink} to={paths.locationDetails(row.locationId)}>
        {row.locationName}
      </Link>
    );
  };

  renderCellRounded = ({ value }: any) => {
    const isUnderPerformed = round(value) < 2;
    const statusIcon = isUnderPerformed ? (
      <Tooltip title="Less than 2 tips per employee per day">
        <ThumbDownOutline fontSize="small" color="error" />
      </Tooltip>
    ) : (
      <ThumbUpOutline fontSize="small" color="primary" />
    );

    return (
      <span className={this.props.classes.underPerformed}>
        <span className={this.props.classes.underPerformedIcon}>{statusIcon}</span>
        <span>{round(value)}</span>
      </span>
    );
  };

  renderCellPercent = ({ value }: any) => {
    return <span>{round(value)}%</span>;
  };

  exportElements = [
    {
      name: 'Download as CSV',
      action: () => {
        this.downloadCsvReport();
      },
    },
  ];

  gridColumns = [
    {
      headerName: 'Location',
      field: 'locationName',
      minWidth: 200,
      flex: 1,
      renderCell: this.renderCellLocation,
    },
    {
      headerName: 'Account',
      field: 'accountName',
      minWidth: 200,
      flex: 1,
      renderCell: this.renderCellAccount,
    },
    {
      headerName: 'Avg tips per talent',
      field: 'avgTipsPerTalent',
      minWidth: 200,
      flex: 1,
      renderCell: this.renderCellRounded,
    },
    { headerName: 'Total tips', field: 'tipCount', minWidth: 150, flex: 1 },
    { headerName: 'Total employees', field: 'talentCount', minWidth: 150, flex: 1 },
    {
      headerName: 'Underperforming',
      field: 'underperfPct',
      minWidth: 200,
      flex: 1,
      renderCell: this.renderCellPercent,
    },
  ];

  filters: Filter[] = [
    { display: 'Location', id: 'locationName', label: 'Contains', type: 'text' },
    { display: 'Account', id: 'accountName', label: 'Contains', type: 'text' },
  ];

  @action.bound private annotateUnderperformingLocations = (underperformingLocations: any) => {
    return {
      id: uuidv4(),
      ...underperformingLocations,
    };
  };

  @action.bound public fetchUnderperformingLocations = adaptForDataGridPro(
    async (rmd: RequestMetaData) => {
      return await Api.analytics.performance.getUnderperformingLocations({
        ...rmd,
        filters: {
          // fromDate: this.dateRange.fromDate,
          // toDate: this.dateRange.toDate,
          ...this.activeFilters,
        },
      });
    },
    this.annotateUnderperformingLocations,
  );

  /** Sets the date range */
  @action.bound private updateDateRangeValue(range: DateRangeExternalPicker.DateRange) {
    this.dateRange = range;
    this.activeFilters = { ...this.activeFilters };
  }

  render() {
    return (
      <Box>
        <Box mt={3}>
          <FilterBar
            filters={this.filters}
            onChange={(filters: Record<string, unknown>) => {
              this.activeFilters = filters;
            }}
            // externalDateRange={{
            //   predefined: this.dateRange.type || 'all',
            //   onChange: this.updateDateRangeValue,
            // }}
          />
        </Box>
        <DataGridInfiniteScroll
          columns={this.gridColumns}
          fetch={this.fetchUnderperformingLocations}
          refetchKey={this.activeFilters}
          sortByField="avgTipsPerTalent"
          disableColumnMenu
          actions={{
            onExport: this.exportElements
          }}
          pathname={this.props.location.pathname}
        />        
      </Box>
    );
  }
}

export default withStyles(styles)(UnderperformingLocations);
