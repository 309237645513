import { Theme, createStyles } from '@material-ui/core';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default (theme: Theme) =>
  createStyles({
    root: {},
    drawer: {
      height: '100%',
      width: '100%',
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
    },
    drawerInner: {
      padding: theme.spacing(3),
      width: theme.spacing(50),
      flex: 1,
      overflow: 'auto',
    },
    actions: {
      padding: theme.spacing(3),
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-end',
    },
    skeleton: {
      width: 100,
      height: 24,
      margin: 0,
    },
    label: {
      color: `rgba(0, 0, 0, 0.38)`,
    },
    feeLabel: {
      color: `rgba(0, 0, 0, 0.38)`,
      display: 'list-item',
      listStyle: 'circle',
      marginLeft: theme.spacing(3),
    },
    value: {
      fontSize: 18,
    },
    circleDot: {
      fontSize: 10,
      marginLeft: theme.spacing(4),
    },
    marginLeft: {
      marginLeft: 3,
    },
    tooltip: {
      color: 'black',
      border: '1px solid rgba(224, 224, 224, 1)',
      fontSize: '1rem',
      padding: '1rem',
      backgroundColor: 'white',
    },
    secondItem: {
      marginLeft: 44,
    },
    fullWidth: {
      width: '100%',
    },
    button: {
      height: 32,
      paddingLeft: theme.spacing(1.5),
      paddingRight: theme.spacing(1.5),
      paddingTop: theme.spacing(0.75),
      paddingBottom: theme.spacing(0.75),
      textTransform: 'none',
      borderRadius: 8,
      fontWeight: 500,
      fontSize: 14,

      '&:hover': {
        backgroundColor: theme.palette.primary.hover,
      },
    },
    disabledButton: {
      backgroundColor: theme.palette.divider,
      color: theme.palette.text.secondary,
    },
    confirmButton: {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.primary.main,
      '& .Mui-disabled': {
        color: 'red',
        backgroundColor: 'red',
      },
    },
    cancelButton: {
      color: theme.palette.primary.main,
      border: `1px solid ${theme.palette.primary.main}`,

      '&:hover': {
        backgroundColor: theme.palette.common.white,
        border: `1px solid ${theme.palette.primary.hover}`,
        color: theme.palette.primary.hover,
      },
    },
  });
