import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { observable, action, flow, makeObservable } from 'mobx';
import { observer } from 'mobx-react';
import { WithStyles, withStyles } from '@material-ui/core/styles';
import { Box, Typography, Paper, Dialog, DialogTitle, DialogContent } from '@material-ui/core';

import { AxiosResponse } from 'axios';

import moment from 'moment-timezone';

import { adaptForDataGrid, EllipsizedValue, adaptForDataGridPro } from 'services/datagrid';

import Api, { RequestMetaData, ApiResponse, getErrorMsg } from 'api';
import { inject, WithUserStore, WithToastStore, WithSettingStore } from 'stores';

import * as models from 'models';

import DashboardLayout from 'containers/DashboardLayout';
import styles from './styles';
import clsx from 'clsx';

import { setTitle } from 'services';

import { Filter } from 'components/FilterBar/FilterBar';
import FilterBar from 'components/FilterBar';
import DataGridInfiniteScroll from 'components/DataGridInfiniteScroll';
import * as DateRangeExternalPicker from 'components/DateRangeExternalPicker';
import Title from 'components/Title/Title';
import ChipStatusTag, { ChipStatusColors } from 'components/ChipStatusTag';
import PlusFabButton from 'components/PlusFabButton/PlusFabButton';
import Button from 'components/Button/Button';
import TargetDialogForm from './TargetDialogForm';
import TemplateAlert from './TemplateAlert';
import AlertForm from './AlertForm';
import SummaryAlert from './SummaryAlert';

interface IBadgeConfig {
  LOW: string;
  MEDIUM: string;
  HIGH: string;
}

const PAGE_TITLE = 'Alerts';

type MobxForm = any;

/** Define props for this component */
type AlertsProps = WithStyles<typeof styles> & // Adds the classes prop
  RouteComponentProps & // Adds the router props (history, match, location)
  WithUserStore & // Adds the userStore prop
  WithToastStore &
  WithSettingStore;

/**
 * Container displaying active kiosk alert message if present and list of past alerts.
 */
@inject('userStore', 'toastStore', 'settingStore')
@observer
class Alerts extends React.Component<AlertsProps> {
  public constructor(props: AlertsProps) {
    super(props);
    makeObservable(this);
  }

  /** The form object */
  @observable private form: MobxForm;

  /** The form object */
  @observable private sending = false;

  /** Used to display ActiveAlert panel */
  @observable public activeAlerts?: models.Alert[];
  /** Used to display ActiveAlert panel */
  @observable public activeAlert: models.Alert | undefined;

  /** Used to display ActiveAlert panel */
  @observable public newAlert?: models.Alert | models.NewAlertRequest;

  /** Used to store target alert object */
  @observable public target?: models.AlertTarget;

  /** Active filters as returned by FilterBar */
  @observable private activeFilters: Record<string, unknown> = {};

  /** The selected date range */
  @observable public dateRange: DateRangeExternalPicker.DateRange =
    this.props.settingStore!.getDate(this.props.location.pathname);

  /** State of dialogs */
  @observable public alertDialogOpen = false;

  /** Is user creating a new alert or updating an existing */
  @observable private editAction?: 'create' | 'update';

  /** Used to control section inside DialogForm */
  @observable public alertStepDialog: models.AlertStepsDialog = models.AlertStepsDialog.UNDEFINED;

  /**
   * Toggle initial dialog and set editAction to
   * 'create' if dialog is opened after the toggle
   */
  @action.bound public toggleNewAlertInitialDialog() {
    this.alertStepDialog = models.AlertStepsDialog.INIT;
    this.alertDialogOpen = !this.alertDialogOpen;
    if (this.alertDialogOpen) this.editAction = 'create';
  }

  /**
   * Cancel process dialog
   */
  @action.bound public cancelAlertDialog() {
    if (
      this.alertStepDialog === models.AlertStepsDialog.FORM ||
      this.alertStepDialog === models.AlertStepsDialog.INIT
    ) {
      this.alertDialogOpen = false;
      this.newAlert = undefined;
    }
  }

  /**
   * Update alert dialog steap process
   */
  @action.bound public onUpdateAlertStepsDialog(step: models.AlertStepsDialog) {
    this.alertStepDialog = step;
  }

  /** Create a NewAlertRequest object from values */
  @action.bound public formToAlertRequestObj(): models.NewAlertRequest {
    return {
      message: this.newAlert?.message || '',
      type: this.newAlert?.type || models.AlertType.GENERAL,
      cause: this.newAlert?.cause || undefined,
      severity: this.newAlert?.severity || undefined,
      link: this.newAlert?.link,
      dismissible: this.newAlert?.dismissible,
      target: this.newAlert?.target,
    };
  }

  /** Init update active alert process */
  @action.bound public editAlert() {
    this.editAction = 'update';
    this.newAlert = this.activeAlert;
    this.alertStepDialog = models.AlertStepsDialog.FORM;
    this.alertDialogOpen = !this.alertDialogOpen;
  }

  /** Set alert form fields to the alert template with the given template */
  @action.bound public alertFromTemplate(alert: models.Alert | models.NewAlertRequest) {
    this.editAction = 'create';
    this.newAlert = alert;
    // this.alertStepDialog = models.AlertStepsDialog.FORM;
    this.alertDialogOpen = false;

    setTimeout(() => {
      this.alertStepDialog = models.AlertStepsDialog.FORM;
      this.alertDialogOpen = true;
    }, 300);
  }

  /** Set alert fields to the alert template with the given template */
  @action.bound public createCustomAlert() {
    this.editAction = 'create';
    this.newAlert = undefined;
    this.alertStepDialog = models.AlertStepsDialog.FORM;
  }

  @action.bound public onUpdateNewAlert(alert: models.NewAlertRequest) {
    this.newAlert = { ...this.newAlert, ...alert };
  }

  /** Sets the date range */
  @action.bound private updateDateRangeValue(range: DateRangeExternalPicker.DateRange) {
    this.props.settingStore!.setDate(this.props.location.pathname, range);
    this.dateRange = range;
    this.activeFilters = { ...this.activeFilters };
  }

  /** Fetch active alert */
  @action.bound public fetchActiveAlert = flow(function* (this: Alerts) {
    try {
      const resp: AxiosResponse<ApiResponse<models.Alert[]>> = yield Api.core.getActiveAlerts();
      if (resp?.data?.data) {
        this.activeAlerts = resp.data.data;
      }
    } catch (e: any) {
      this.props.toastStore!.error(getErrorMsg(e));
    }
  });

  /** Hide active alert */
  @action.bound public hideAlert = flow(function* (this: Alerts) {
    try {
      if (this.activeAlert) {
        this.sending = true;
        yield Api.core.updateAlert(this.activeAlert.id, { isActive: false });
        this.activeAlert = undefined;
        this.activeFilters = { ...this.activeFilters };
        this.fetchActiveAlert();
        this.props.toastStore!.success(`Issue closed`);
        this.sending = false;
      }
    } catch (e: any) {
      this.props.toastStore!.error(getErrorMsg(e));
    }
  });

  /** Create or update alert */
  @action.bound public upsertAlert = flow(function* (this: Alerts, action: 'create' | 'update') {
    try {
      this.sending = true;
      const alert = this.formToAlertRequestObj();
      const resp: AxiosResponse<ApiResponse<models.Alert>> =
        action === 'create'
          ? yield Api.core.createAlert(alert)
          : yield Api.core.updateAlert(this.activeAlert!.id, alert);
      this.activeAlert = resp.data.data;
      this.editAction = undefined;
      this.activeFilters = { ...this.activeFilters };
      this.alertDialogOpen = false;
      this.newAlert = undefined;
      this.fetchActiveAlert();
      this.props.toastStore!.success(`Alert successfully updated`);
      this.sending = false;
    } catch (e: any) {
      this.sending = false;
      this.props.toastStore!.error(getErrorMsg(e));
      this.alertDialogOpen = false;
      this.newAlert = undefined;
    }
  });

  /** Fetch alert list */
  public getAlerts = (rmd: RequestMetaData) => {
    const fetch = adaptForDataGrid(Api.core.getAlerts, (alert: models.Alert) => ({
      ...alert,
      createdBy: `${alert.createdBy.firstName} ${alert.createdBy.lastName}`,
      type: alert.type.toUpperCase(),
      cause: alert.cause ? alert.cause.toUpperCase() : undefined,
      severity: alert.severity ? alert.severity.toUpperCase() : undefined,
      duration: alert.deactivatedAt
        ? moment.utc(moment(alert.deactivatedAt).diff(moment(alert.createdAt))).format('HH:mm:ss')
        : '',
    }));

    const order: 'ASC' | 'DESC' = 'DESC';
    const extendedRmd = {
      ...rmd,
      filters: { isActive: false },
      sort: { sortBy: 'createdAt', sortOrder: order },
    };
    return fetch(extendedRmd);
  };

  public getAlertsData = adaptForDataGridPro(
    async (rmd: RequestMetaData) => {
      const order: 'ASC' | 'DESC' = 'DESC';

      return await Api.core.getAlerts({
        ...rmd,
        filters: {
          isActive: false,
          fromDate: this.dateRange.fromDate,
          toDate: this.dateRange.toDate,
          ...this.activeFilters,
        },
        sort: { sortBy: 'createdAt', sortOrder: order },
      });
    },

    (alert: models.Alert) => ({
      ...alert,
      createdBy: `${alert.createdBy.firstName} ${alert.createdBy.lastName}`,
      type: alert.type.toUpperCase(),
      cause: alert.cause ? alert.cause.toUpperCase() : undefined,
      severity: alert.severity ? alert.severity.toUpperCase() : undefined,
      duration: alert.deactivatedAt
        ? moment.utc(moment(alert.deactivatedAt).diff(moment(alert.createdAt))).format('HH:mm:ss')
        : '',
    }),
  );

  renderAlertMessage = (rowIndex: any, name: any, value: any, row: any) => (
    <EllipsizedValue value={value} max={44} />
  );

  renderSeverity = ({ value, row }: any) => {
    const background = this.badgeConfig[value as keyof IBadgeConfig] as ChipStatusColors;
    return <ChipStatusTag label={value} color={background} />;
  };

  renderActiveAlert = () => {
    const classes = this.props.classes;

    return this.activeAlerts?.map((alert, index) => {
      return (
        <Paper key={alert.id}>
          <Box mb={3} p={2}>
            <Typography variant="h5">Active Alert</Typography>
            <Box className={clsx(classes.alertMessageBox, alert?.severity || 'low')}>
              <Typography variant="h6">{alert?.message}</Typography>
            </Box>
            <Box display="flex" flexDirection="row" justifyContent="space-between">
              <Box>
                <Box className={classes.label}>Type</Box>
                <Typography>{alert?.type.toUpperCase()}</Typography>
              </Box>
              <Box>
                <Box className={classes.label}>Severity</Box>
                <Typography>{alert?.severity ? alert?.severity.toUpperCase() : 'N/A'}</Typography>
              </Box>
              <Box>
                <Box className={classes.label}>Created At</Box>
                <Typography>
                  {moment(alert?.createdAt).tz('America/New_York').format('MMM D, YYYY h:mm A')}{' '}
                  (EST/EDT)
                </Typography>
              </Box>
              <Box>
                <Box className={classes.label}>Duration</Box>
                <Typography>
                  {moment.utc(moment().diff(moment(alert?.createdAt))).format('HH:mm:ss')}
                </Typography>
              </Box>
              <Box>
                <Box className={classes.label}>Cause</Box>
                <Typography>{alert?.cause ? alert?.cause.toUpperCase() : 'N/A'}</Typography>
              </Box>
              <Box>
                <Box className={classes.label}>Created By</Box>
                <Typography>{`${alert?.createdBy.firstName} ${alert?.createdBy.lastName}`}</Typography>
              </Box>
            </Box>
            <Box mt={3} display="flex" justifyContent="flex-end">
              <Box mr={2}>
                <Button
                  onClick={() => {
                    this.activeAlert = this.activeAlerts![index];
                    this.editAlert();
                  }}
                  color="primary">
                  edit
                </Button>
              </Box>
              <Box>
                <Button
                  onClick={() => {
                    this.activeAlert = this.activeAlerts![index];
                    this.hideAlert();
                  }}
                  variant="contained"
                  color="primary">
                  {alert?.type === 'issue' ? 'close issue' : 'hide alert'}
                </Button>
              </Box>
            </Box>
          </Box>
        </Paper>
      );
    });
  };

  @action.bound public init() {
    this.fetchActiveAlert();
  }

  componentDidMount() {
    this.init();
    setTitle(PAGE_TITLE, { noSuffix: false });
  }

  private badgeConfig: IBadgeConfig = {
    LOW: ChipStatusColors.YELLOW,
    MEDIUM: ChipStatusColors.ORANGE,
    HIGH: ChipStatusColors.RED,
  };

  renderCellAlertMessage = ({ value }: any) => <EllipsizedValue value={value} max={44} />;

  gridColumns = [
    { headerName: 'Type', field: 'type', minWidth: 100, flex: 1 },
    {
      headerName: 'Message',
      field: 'message',
      minWidth: 200,
      flex: 1,
      renderCell: this.renderCellAlertMessage,
    },
    {
      headerName: 'Severity',
      field: 'severity',
      minWidth: 200,
      flex: 1,
      renderCell: this.renderSeverity,
    },
    {
      headerName: 'Created At',
      field: 'createdAt',
      minWidth: 200,
      flex: 1,
      valueGetter: ({ value }: any) =>
        value && moment(new Date(value)).format('MMM DD, YYYY h:mm A'),
    },
    { headerName: 'Cause', field: 'cause', minWidth: 200, flex: 1 },
    { headerName: 'Created By', field: 'createdBy', minWidth: 200, flex: 1 },
    { headerName: 'Duration', field: 'duration', minWidth: 200, flex: 1 },
  ];

  filters: Filter[] = [
    { display: 'Type', id: 'type', label: 'Contains', type: 'text' },
    { display: 'Severity', id: 'severity', label: 'Contains', type: 'text' },
  ];

  render() {
    return (
      <DashboardLayout>
        <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
          <Title mb={3}>{PAGE_TITLE}</Title>
        </Box>
        {this.activeAlerts?.length ? this.renderActiveAlert() : null}
        <FilterBar
          filters={this.filters}
          onChange={(filters: Record<string, unknown>) => {
            this.activeFilters = filters;
          }}
          externalDateRange={{
            predefined: this.dateRange,
            onChange: this.updateDateRangeValue,
          }}
        />
        <DataGridInfiniteScroll
          columns={this.gridColumns}
          fetch={this.getAlertsData}
          refetchKey={this.activeFilters}
          disableColumnMenu
          pathname={this.props.location.pathname}
        />

        <PlusFabButton onClick={this.toggleNewAlertInitialDialog} />

        {/* form dialog */}
        <Dialog
          PaperProps={{ elevation: 0 }}
          open={this.alertDialogOpen}
          onClose={this.cancelAlertDialog}>
          {this.alertStepDialog !== models.AlertStepsDialog.INIT && (
            <DialogTitle disableTypography>
              <Typography style={{ fontSize: 28, marginTop: '8px' }}>
                {this.editAction === 'update' ? 'Edit' : 'Create'} Alert
              </Typography>
            </DialogTitle>
          )}
          {/* Choose alert template dialog */}
          {this.alertStepDialog === models.AlertStepsDialog.INIT && (
            <TemplateAlert
              alertFromTemplate={this.alertFromTemplate}
              createCustomAlert={this.createCustomAlert}
              props={this.props}
            />
          )}
          {/* form dialog section */}
          <DialogContent style={{ minWidth: '576px' }}>
            {this.alertStepDialog === models.AlertStepsDialog.FORM && (
              <AlertForm
                onUpdateNewAlert={this.onUpdateNewAlert}
                onCancelAlertDialog={this.cancelAlertDialog}
                onUpdateAlertStepsDialog={this.onUpdateAlertStepsDialog}
                classes={this.props.classes}
                currentAlert={this.newAlert}
                editAction={this.editAction}
              />
            )}
            {/* target dialog section */}
            {this.alertStepDialog === models.AlertStepsDialog.TARGET && (
              <TargetDialogForm
                onUpdateNewAlert={this.onUpdateNewAlert}
                onUpdateAlertStepsDialog={this.onUpdateAlertStepsDialog}
                classes={this.props.classes}
                currentAlert={this.newAlert}
                editAction={this.editAction}
              />
            )}
            {/* summary dialog section */}
            {this.alertStepDialog === models.AlertStepsDialog.SUMMARY && (
              <SummaryAlert
                sending={this.sending}
                badgeConfig={this.badgeConfig}
                onUpdateAlertStepsDialog={this.onUpdateAlertStepsDialog}
                onSubmit={this.upsertAlert}
                classes={this.props.classes}
                currentAlert={this.newAlert}
                editAction={this.editAction}
              />
            )}
          </DialogContent>
        </Dialog>
      </DashboardLayout>
    );
  }
}

export default withStyles(styles)(Alerts);
