import { makeStyles } from '@material-ui/core';

export const avatarSize = 16;
export const borderWidth = 0.5;
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default makeStyles((theme) => ({
  oink: {
    color: '#f79a95',
    fontSize: 16,
    marginTop: -20,
    fontWeight: 400,
    lineHeight: 1.5,
    position: 'absolute',
    left: 0,
    right: 0,
  },
  leftPeak: {
    right: '50%',
    height: 20,
    zIndex: 999,
    position: 'relative',
    backgroundColor: '#F2F2F2',
    borderBottomRightRadius: 15,
  },
  rightPeak: {
    top: -20,
    right: 0,
    height: 20,
    zIndex: 999,
    position: 'relative',
    backgroundColor: '#F2F2F2',
    borderBottomLeftRadius: 15,
    marginBottom: -150,
  },
  footer: {
    paddingTop: 20,
    backgroundColor: '#F2F2F2',
  },
  backgroundColor: {
    height: 165,
    backgroundColor: '#0000000a',
    textAlign: 'center',
  },
  tippyLogo: {
    width: 83,
    height: 76,
    margin: 'auto',
    marginTop: 140,
  },
  copy: {
    fontSize: 13,
    marginTop: 10,
  },
}));
