import { Theme, alpha, createStyles, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      padding: theme.spacing(1, 5, 1, 5),
      display: 'flex',
      alignItems: 'center',
      gap: theme.spacing(1),
      fontSize: 12,
      borderRadius: theme.spacing(1.5),
      color: theme.palette.status.red,
      backgroundColor: alpha(theme.palette.status.red, 0.2),
    },
    icon: {
      height: 16,
      width: 16,
    },
  }),
);

export default useStyles;
