import React from 'react';
import { action, flow, observable, makeObservable } from 'mobx';
import { observer } from 'mobx-react';
import { Box } from '@material-ui/core';

import Api, { getErrorMsg, ApiResponse } from 'api';
import { inject, WithToastStore } from 'stores';

import { AxiosResponse } from 'axios';

import AutocompleteSearch from 'components/AutocompleteSearch';
import { Pos } from 'models';

interface PoSSearchProps extends WithToastStore {
  onChange: (a: Pos | null) => void;
  onInput: (i: string) => void;
  label?: string;
  value?: Pos | null;
  hideIcon?: boolean;
  placeholder?: string;
  autoFocus?: boolean;
  InputProps?: { disableUnderline?: boolean; className?: string };
  className?: string;
}

/** A component that displays a field for searching PoS systems but also allows custom inputs */
@inject('toastStore')
@observer
class PoSSearch extends React.Component<PoSSearchProps> {
  constructor(props: PoSSearchProps) {
    super(props);
    makeObservable(this);
  }
  /** List of predefined PoS systems */
  @observable public posSystems: Pos[] = [];

  /** Gets the label for an location in the autocomplete search */
  @action.bound private getPosOptionLabel(pos?: Pos): string {
    if (!pos) {
      // Sometimes we get undefined here, not sure why
      return '';
    } else {
      return pos.name || '';
    }
  }

  /** Filter PoS list by search string */
  @action.bound public async filterSearchResults(search: string): Promise<Pos[]> {
    return this.posSystems.filter((i) => i.name.toLowerCase().includes(search.toLowerCase()));
  }

  @action.bound public getPosSystems = flow(function* (this: PoSSearch) {
    try {
      const resp: AxiosResponse<ApiResponse<Pos[]>> = yield Api.core.getPosSystems();
      if (resp!.data!.data) {
        this.posSystems = resp!.data!.data;
      }
    } catch (e: any) {
      this.props.toastStore!.error(getErrorMsg(e));
    }
  });

  componentDidMount() {
    this.getPosSystems();
  }

  /** Renders the option for a single PoS system item */
  @action.bound private renderPosOption(pos: Pos): React.ReactNode {
    return (
      <Box display="flex" flexDirection="row" justifyContent="space-between" width="100%">
        <Box>{pos.name}</Box>
      </Box>
    );
  }

  render() {
    const {
      onChange,
      onInput,
      hideIcon,
      label,
      placeholder,
      value,
      autoFocus,
      InputProps,
      className,
    } = this.props;
    return (
      <AutocompleteSearch
        className={className}
        freeSolo
        autoFocus={autoFocus}
        fetch={this.filterSearchResults}
        onChange={onChange}
        onInput={onInput}
        label={label}
        hideIcon={hideIcon}
        value={value}
        getOptionLabel={this.getPosOptionLabel}
        getOptionSelected={(option, value) => option.id === value.id}
        renderOption={this.renderPosOption}
        placeholder={placeholder}
        debounceTime={200}
        InputProps={InputProps}
      />
    );
  }
}

export default PoSSearch;
