/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { action, reaction, observable, IReactionDisposer, makeObservable } from 'mobx';
import { observer } from 'mobx-react';

import { WithStyles, withStyles } from '@material-ui/core/styles';

import { inject, WithToastStore, WithAnalyticsStore, WithSettingStore } from 'stores';

import Api, { RequestMetaData } from 'api';
import { adaptForDataGridPro, setTitle } from 'services';

import { Box } from '@material-ui/core';

import { Filter } from 'components/FilterBar/FilterBar';
import FilterBar from 'components/FilterBar';
import DataGridInfiniteScroll from 'components/DataGridInfiniteScroll';
import * as DateRangeExternalPicker from 'components/DateRangeExternalPicker';

import styles from '../styles';
import { RouteComponentProps } from 'react-router-dom';

const PAGE_TITLE = 'Conversions by Deals';

/** Single deal conversion row */
interface DealConversion {
  id: number;
  description: string;
  type: string;
  discount: string;
  count: string;
  percentTotal: string;
  percentDeals: string;
  totalDiscounts: string;
}

function annotateConversions(c: DealConversion) {
  return {
    ...c,
    count: parseInt(c.count),
    type: c.type.replace('_', ' ').toUpperCase(),
    percentTotal:
      parseFloat(c.percentTotal) !== 0 ? `${parseFloat(c.percentTotal).toFixed(2)}%` : '',
    percentDeals:
      parseFloat(c.percentDeals) !== 0 ? `${parseFloat(c.percentDeals).toFixed(2)}%` : '',
  };
}

/** Here we define what kind of props this component takes */
interface ByDealProps extends WithStyles<typeof styles>, WithToastStore, WithAnalyticsStore, WithSettingStore, RouteComponentProps {}

@inject('toastStore', 'analyticsStore','settingStore')
@observer
class ByDeal extends React.Component<ByDealProps> {
  constructor(props: ByDealProps) {
    super(props);
    makeObservable(this);

    this.disposers.push(
      reaction(
        () => this.dateRange,
        () => {
          this.activeFilters = { ...this.activeFilters };
        },
      ),
    );
  }

  /** Active filters as returned by FilterBar */
  @observable private activeFilters: Record<string, unknown> = {};

  /** It's good practice to dispose of any autoruns that we set up during */
  private disposers: IReactionDisposer[] = [];

  /** The selected date range */
  @observable public dateRange: DateRangeExternalPicker.DateRange = this.props.settingStore!.getDate(this.props.location.pathname);

  @action.bound public fetchEmployeeConversions = adaptForDataGridPro(
    async (rmd: RequestMetaData) => {
      return await Api.analytics.conversion.byDeals.tableData({
        ...rmd,
        filters: {
          fromDate: this.dateRange && this.dateRange.fromDate,
          toDate: this.dateRange && this.dateRange.toDate,
        },
      });
    },
    annotateConversions,
  );

  /** Sets the date range */
  @action.bound private updateDateRangeValue(range: DateRangeExternalPicker.DateRange) {
    this.props.settingStore!.setDate(this.props.location.pathname, range);
    this.dateRange = range;
  }

  gridColumns = [
    { headerName: 'Description', field: 'description', minWidth: 200, flex: 1 },
    { headerName: 'Type', field: 'type', minWidth: 200, flex: 1 },
    { headerName: 'Discount', field: 'discount', minWidth: 150, flex: 1 },

    { headerName: 'Count', field: 'count', minWidth: 150, flex: 1 },
    {
      headerName: '% Deals',
      field: 'percentDeals',
      minWidth: 150,
      flex: 1,
    },
    {
      headerName: '% Total',
      field: 'percentTotal',
      minWidth: 150,
      flex: 1,
    },
    {
      headerName: 'Total Discounts',
      field: 'totalDiscounts',
      minWidth: 200,
      flex: 1,
    },
  ];

  filters: Filter[] = [
    { display: 'Description', id: 'description', label: 'Contains', type: 'text' },
  ];

  componentDidMount() {
    setTitle(PAGE_TITLE, { noSuffix: false });
  }

  /** Before unmounting the component, dispose of all reactions created */
  componentWillUnmount() {
    this.disposers.map((disposer) => disposer());
  }

  render() {
    return (
      <Box>
        <Box mt={3}>
          <FilterBar
            filters={this.filters}
            onChange={(filters: Record<string, unknown>) => {
              this.activeFilters = filters;
            }}
            externalDateRange={{
              predefined: this.dateRange,
              onChange: this.updateDateRangeValue,
            }}
          />
        </Box>
        <DataGridInfiniteScroll
          columns={this.gridColumns}
          fetch={this.fetchEmployeeConversions}
          refetchKey={this.activeFilters}
          disableColumnMenu
          pathname={this.props.location.pathname}
        />        
      </Box>
    );
  }
}

export default withStyles(styles)(ByDeal);
