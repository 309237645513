import { Theme, createStyles } from '@material-ui/core';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default (theme: Theme) =>
  createStyles({
    root: {},
    dialog: {
      width: '600px',
      overflow: 'visible',
      '& .MuiDialogContent-root': {
        overflow: 'visible',
      },
    },
    emptyLabel: {
      color: theme.palette.text.secondary,
    },
    secondaryText: {
      color: theme.palette.text.secondary,
      fontStyle: 'italic',
    },
  });
