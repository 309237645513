/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { observable, action, flow, reaction, makeObservable } from 'mobx';
import { RouteComponentProps, Link as RouterLink } from 'react-router-dom';
import { observer } from 'mobx-react';
import { WithStyles, withStyles } from '@material-ui/core/styles';
import { Box, InputAdornment, CircularProgress, LinearProgress } from '@material-ui/core';
import validatorjs from 'validatorjs';
import { v4 as uuidv4 } from 'uuid';
import debounce from 'lodash/debounce';

import Api, { getErrorMsg } from 'api';
import { inject, WithUserStore, WithToastStore, WithUiStore } from 'stores';
import { User, Lead } from 'models';
import { paths } from 'routes';
import { isPhone } from 'services/validators';

import PhoneInput from 'components/form/PhoneInput';
import UserCard from 'components/UserCard';

import { ReactComponent as OwnerImg } from './owner_new.svg';
import SignupStore, { SignupStep } from './SignupStore';

import styles from './styles';
import Button from 'components/Button/Button';
import OutlinedInput from 'components/Input/OutlinedInput';
import AccountSignupLayout from './AccountSignupLayout/AccountSignupLayout';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const dvr = require('mobx-react-form/lib/validators/DVR');
const MobxReactForm = require('mobx-react-form').default;

interface OwnerFormHooks {
  onSuccess: (form: any) => void;
  onClear: (form: any) => void;
  onError: (form: any) => void;
}

/** Here we define what kind of props this component takes */
interface OwnerProps
  extends WithStyles<typeof styles>, // Adds the classes prop
    RouteComponentProps,
    WithUserStore, // Adds the userStore prop
    WithToastStore,
    WithUiStore {
  signupStore: SignupStore;
  disableExistingUserLookup?: boolean;
  nextRoute: () => string;
}

export type CancellablePromise<T> = Promise<T> & { cancel(): void };

const plugins = {
  dvr: dvr({
    package: validatorjs,
    extend: ({ validator }: { validator: any; form: any }) => {
      /* Add custom rule for validating US phone numbers */
      const phoneFieldRule = {
        function: isPhone,
        message: 'The phone number is not a valid format.',
      };
      validator.register('phone', phoneFieldRule.function, phoneFieldRule.message);
      validator.setMessages('en', {
        ...validator.getMessages('en'),
        firstName: 'Please input a valid first name',
        lastName: 'Please input a valid last name',
      });
    },
  }),
};

/**
 * The owner screen of the account signup for admins
 */
@inject('userStore', 'toastStore', 'uiStore')
@observer
class Owner extends React.Component<OwnerProps> {
  public constructor(props: OwnerProps) {
    super(props);
    makeObservable(this);

    const fields = [
      {
        name: 'email',
        label: 'Email',
        rules: 'required|email',
      },
      {
        name: 'firstName',
        label: 'First Name',
        rules: 'required|min:2',
      },
      {
        name: 'lastName',
        label: 'Last Name',
        rules: 'required|min:2',
      },
      {
        name: 'phone',
        label: 'Phone',
        type: 'tel',
        rules: 'required|string|phone',
        extra: {
          editable: false,
        },
      },
    ];
    this.form = new MobxReactForm({ fields }, { plugins, hooks: this.hooks });

    this.clearReaction1 =
      !this.props.disableExistingUserLookup &&
      reaction(
        () => this.form && this.form.$('email').value,
        (val) => {
          this.existingUser = undefined;
          // If the value is non-empty, fetch it right away
          if (val) {
            this.fetchUserDebounced(val);
          } else {
            this.fetchUser(val);
          }
        },
      );

    this.clearReaction2 = reaction(
      () => this.existingUser && this.existingUser.email,
      (email) => {
        if (email) {
          this.form.$('firstName').set(this.existingUser!.firstName);
          this.form.$('lastName').set(this.existingUser!.lastName);
          this.form.$('phone').set(this.existingUser!.phone);
        } else {
          this.form.$('firstName').set('');
          this.form.$('lastName').set('');
          this.form.$('phone').set('');
        }
      },
    );
  }
  /** The exist user, if any */
  @observable public existingUser?: User;
  /** Whether the existing user is currently being fetched */
  @observable public fetchingExistingUser = false;
  /** Whether we are currently submitting */
  @observable public submitting = false;
  /** Submits the form, creating a user if they don't exist */
  @action.bound public submit = flow(function* (this: Owner) {
    try {
      let user: User;
      this.submitting = true;
      // If we already have an existing user, use that user.
      if (this.existingUser) {
        user = this.existingUser;
        // If not, create a user
      } else {
        // Create the user
        const resp = yield Api.core.createUser(this.form.$('email').value, uuidv4(), {
          firstName: this.form.$('firstName').value,
          lastName: this.form.$('lastName').value,
          phone: this.form.$('phone').value,
        });
        if (!resp.data.data) {
          throw new Error(`Something went wrong`);
        }
        user = resp.data.data;
        // Password reset was removed due to push users first through persona validation
        // TODO: Remove in future
        //yield Api.core.createPasswordResetToken(this.form.$('email').value);
      }
      // Fire off some notifications
      if (!this.existingUser) {
        this.props.toastStore!.push({ type: 'success', message: `User successfully created!` });
      }
      // Set the owner in the parent container
      this.props.signupStore.setOwner(user);
      // Go to the next step
      this.props.history.push(this.props.nextRoute());
    } catch (e: any) {
      this.props.toastStore!.push({ type: 'error', message: getErrorMsg(e) });
    } finally {
      this.submitting = false;
    }
  });

  /** The promise for fetching the user, we store it here so we can cancel it */
  public fetchPromise?: CancellablePromise<void>;

  /** Fetch the user by email, we wrap this in a flow just so we have a cancellable promise */
  @action.bound public fetchUserByEmail = flow(function* (this: Owner, email: string) {
    try {
      this.fetchingExistingUser = true;
      return yield Api.core.getUserByEmail(email);
    } finally {
      this.fetchingExistingUser = false;
    }
  });

  /** Fetch the user by email */
  @action.bound public fetchUser = flow(function* (this: Owner, email: string) {
    // If the email is an empty string, to nothing
    if (email === '') {
      return;
    }
    // Cancel any pending promises for fetching users
    if (this.fetchPromise) {
      this.fetchPromise.cancel();
    }
    try {
      this.fetchPromise = this.fetchUserByEmail(email);
      const resp = yield this.fetchPromise;
      this.existingUser = resp.data.data;
    } catch (e: any) {
      // If we caught with a 404, clear the existing user
      if (e.message !== 'FLOW_CANCELLED') {
        if (e.response && e.response.status === 404) {
          this.existingUser = undefined;
        }
      }
    } finally {
      this.fetchPromise = undefined;
    }
  });
  /** The debounced verision of fetchUser */
  public fetchUserDebounced = debounce(this.fetchUser, 1000);

  /**
   * Whenever the email in the form changes, we want to clear the
   * existing user and fetch the new one with the email. Do this
   * only if the disableExistingUserLookup prop isn't true.
   */
  clearReaction1;

  // Whenever the existing user changes, update the form
  // values to that user's data
  clearReaction2;

  private hooks: OwnerFormHooks = {
    onSuccess: () => {
      this.submit();
    },
    onClear: (form: any) => {
      form.clear();
    },
    onError: () => {
      if (this.existingUser) {
        this.submit();
      }
    },
  };
  @observable private form: any;

  @action private setLeadData = (lead: Lead) => {
    this.props.signupStore.lead = lead;

    this.form.$('email').set(lead.email);
    this.form.$('firstName').set(lead.firstName);
    this.form.$('lastName').set(lead.lastName);
    this.form.$('phone').set(lead.phone);

    this.props.signupStore.accountAffiliate = lead.affiliate;
  };

  getProps(field: string) {
    const { name, value, disabled, onChange, error, label, onFocus, onBlur } = this.form.$(field);
    let props = {
      name,
      value,
      disabled,
      onChange,
      onFocus,
      onBlur,
      error: Boolean(error),
      helperText: error,
      placeholder: label,
    } as { [key: string]: any };
    if (field !== 'email') {
      delete props.error;
      delete props.helperText;
    }
    return props;
  }

  componentDidMount() {
    this.props.signupStore!.setStep(SignupStep.Owner);

    const location = this.props.location;
    const locationState: any = location.state || {};

    const leadData = locationState.leadData || undefined;

    if (leadData) {
      this.setLeadData(leadData);
    }
  }

  componentWillUnmount() {
    this.clearReaction1 && this.clearReaction1();
    this.clearReaction2();
  }
  renderExistingOwner() {
    const { classes } = this.props;
    return (
      <Box height="100%" display="flex" flexDirection="column">
        <Box pb={2} flexGrow={1}>
          <UserCard className={classes.userCard}>{this.props.signupStore!.owner!}</UserCard>
        </Box>
        <Button
          disableElevation
          fullWidth
          component={RouterLink}
          to={paths.adminAccountSignup().account()}
          variant="contained">
          Next
        </Button>
      </Box>
    );
  }
  renderOwnerForm() {
    const { classes } = this.props;
    return (
      <form
        id="owner-step-form"
        onSubmit={this.form.onSubmit}
        style={{ padding: 0, position: 'relative' }}>
        <Box pb={2}>
          <OutlinedInput
            style={{ marginTop: 16 }}
            fullWidth
            {...this.form.$('email').bind()}
            error={Boolean(this.form.$('email').error)}
            helperText={this.form.$('email').error || ''}
            InputProps={{
              disableUnderline: true,
              classes: { input: classes.resize },
              endAdornment: (
                <InputAdornment position="end">
                  {this.fetchingExistingUser && <CircularProgress size={20} />}
                </InputAdornment>
              ),
            }}
            dataCy="email-input"
          />
        </Box>
        <Box pb={2}>
          <OutlinedInput
            {...this.form.$('firstName').bind()}
            error={Boolean(this.form.$('firstName').error && !this.existingUser)}
            helperText={!this.existingUser ? this.form.$('firstName').error : ''}
            disabled={this.existingUser ? true : false}
            fullWidth
            InputProps={{
              disableUnderline: true,
              classes: { input: classes.resize },
            }}
            dataCy="first-name-input"
          />
        </Box>
        <Box pb={2}>
          <OutlinedInput
            {...this.form.$('lastName').bind()}
            error={Boolean(this.form.$('lastName').error && !this.existingUser)}
            helperText={!this.existingUser ? this.form.$('lastName').error : ''}
            disabled={!!this.existingUser}
            fullWidth
            InputProps={{
              disableUnderline: true,
              classes: { input: classes.resize },
            }}
            dataCy="last-name-input"
          />
        </Box>
        <Box pb={2}>
          <OutlinedInput
            {...this.form.$('phone').bind()}
            error={this.form.$('phone').error}
            helperText={this.form.$('phone').error}
            disabled={!!this.existingUser}
            fullWidth
            InputProps={{
              inputComponent: PhoneInput,
              disableUnderline: true,
              classes: { input: classes.resize },
            }}
            dataCy="phone-input"
          />
        </Box>
      </form>
    );
  }
  render() {
    const { signupStore } = this.props;
    const content = signupStore.owner ? this.renderExistingOwner() : this.renderOwnerForm();
    return (
      <AccountSignupLayout
        title={{
          name: `Let's start with the owner's info`,
        }}
        subtitle={{
          name: 'The owner will receive a confirmation email and be prompted to create a password',
        }}
        image={{ svg: <OwnerImg /> }}
        contentRight={{
          children: content,
          mb: 'auto',
        }}
        actionsRight={
          !signupStore.owner && (
            <>
              <Button
                disableElevation
                fullWidth
                form={'owner-step-form'}
                variant="contained"
                type="submit"
                disabled={this.submitting}
                data-cy={'next-button'}>
                Next
              </Button>
              {this.submitting && (
                <Box pt={2}>
                  <LinearProgress />
                </Box>
              )}
            </>
          )
        }
      />
    );
  }
}

export default withStyles(styles)(Owner);
