/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import {
  observable,
  computed,
  action,
  reaction,
  flow,
  IReactionDisposer,
  makeObservable,
} from 'mobx';
import { observer } from 'mobx-react';

import { AxiosResponse } from 'axios';
import Api, { ApiResponse, getErrorMsg, RequestMetaData } from 'api';
import { adaptForDataGridPro } from 'services';

import { Box, Grid } from '@material-ui/core';
import { Coins } from 'mdi-material-ui';

import { WithStyles, withStyles } from '@material-ui/core/styles';

import {
  inject,
  WithToastStore,
  WithAnalyticsStore,
  WithUserStore,
  WithSettingStore,
} from 'stores';

import { Filter } from 'components/FilterBar/FilterBar';
import FilterBar from 'components/FilterBar';
import DataGridInfiniteScroll from 'components/DataGridInfiniteScroll';
import * as DateRangeExternalPicker from 'components/DateRangeExternalPicker';
import moment from 'moment-timezone';

import styles from '../styles';
import { HorizontalStatCard } from 'containers/UserDetails';
import { Pound, CurrencyUsd } from 'mdi-material-ui';
import * as models from 'models';
import { downloadCsvFile } from 'utils/helper';
import { Reconciliation } from 'models';
import { v4 as uuidv4 } from 'uuid';
import { RouteComponentProps } from 'react-router-dom';

/** Here we define what kind of props this component takes */
interface ByTransactionProps
  extends WithStyles<typeof styles>,
    WithToastStore,
    WithAnalyticsStore,
    RouteComponentProps,
    WithSettingStore,
    WithUserStore {}

@inject('toastStore', 'analyticsStore', 'userStore', 'settingStore')
@observer
class ByTransaction extends React.Component<ByTransactionProps> {
  constructor(props: ByTransactionProps) {
    super(props);
    makeObservable(this);

    this.disposers = [reaction(() => this.dateRange, this.fetchStats)];
  }

  /** It's good practice to dispose of any autoruns that we set up during */
  private disposers: IReactionDisposer[] = [];

  @observable public accountId = this.props.userStore!.currentAccount!.id!;

  /** The stats object */
  @observable public stats?: models.ReconciliateStats;

  /** Active filters as returned by FilterBar */
  @observable private activeFilters: Record<string, unknown> = {};

  // /** The selected date range */
  // @observable public dateRange: DateRangeExternalPicker.DateRange =
  //   DateRangeExternalPicker.getDateRange('thisMonth');

  /** The selected date range */
  @observable public dateRange: DateRangeExternalPicker.DateRange =
    this.props.settingStore!.getDate(this.props.location.pathname, 'thisMonth');

  @computed public get statsComputed() {
    return {
      tipsSum: this.stats && parseFloat(this.stats.tipsSum),
      feesSum: this.stats && parseFloat(this.stats.feesSum),
      paymentTotal: this.stats && parseFloat(this.stats.paymentTotal),
    };
  }

  @computed get gridColumns() {
    const gridColumns = [
      {
        headerName: 'Date',
        field: 'createdAt',
        minWidth: 120,
        flex: 1,
        valueGetter: ({ value }: any) => value && moment(new Date(value)).format('MMM DD, YYYY'),
      },
      {
        headerName: 'Employee',
        field: 'employee',
        minWidth: 120,
        flex: 1,
      },
      {
        headerName: 'Total Tips',
        field: 'tipsSum',
        minWidth: 150,
        flex: 1,
      },
      {
        headerName: 'Total Fees',
        field: 'feesSum',
        minWidth: 150,
        flex: 1,
      },
      {
        headerName: 'Total Payment',
        field: 'paymentTotal',
        minWidth: 150,
        flex: 1,
      },
    ];

    return gridColumns;
  }

  @action.bound
  public fetchStats = flow(function* (this: ByTransaction) {
    const extraReqData = {
      fromDate: this.dateRange && this.dateRange.fromDate,
      toDate: this.dateRange && this.dateRange.toDate,
      ...this.activeFilters,
    };
    const accountId = this.props.userStore!.currentAccount!.id;
    this.stats = undefined;
    const resp: AxiosResponse<ApiResponse<models.ReconciliateStats>> =
      yield Api.analytics.getReconciliationByTransactionStats(accountId, undefined, extraReqData);
    this.stats = resp.data.data;
  });

  @action.bound downloadCSV = async (
    request: (rmd: RequestMetaData, extraData?: any) => any,
    name: string,
  ) => {
    const userStore = this.props.userStore;
    try {
      const accountId = userStore!.currentAccount && userStore!.currentAccount.id;
      const rmd = {
        filters: {
          fromDate: this.dateRange && this.dateRange.fromDate,
          toDate: this.dateRange && this.dateRange.toDate,
        },
      };
      const extraData = {
        accountId,
      };
      const resp: AxiosResponse<Blob> = await request(rmd, extraData);
      if (resp && resp.data) {
        downloadCsvFile(resp.data, name);
      }
    } catch (e) {
      this.props.toastStore!.push({
        message: getErrorMsg(e), //'Unable to download requested report',
        type: 'error',
      });
    }
  };

  /** Annotates with extra data */
  @action.bound private annotate = (reconciliate: Reconciliation) => {
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    });

    return {
      id: uuidv4(),
      createdAt: reconciliate.createdAt,
      employee: reconciliate.employee,
      tipsSum: formatter.format(parseFloat(reconciliate.tipsSum)),
      feesSum: formatter.format(parseFloat(reconciliate.feesSum)),
      paymentTotal: formatter.format(parseFloat(reconciliate.paymentTotal)),
    };
  };

  @action.bound public fetchData = adaptForDataGridPro(async (rmd: RequestMetaData) => {
    const userStore = this.props.userStore;
    const accountId = userStore!.currentAccount && userStore!.currentAccount.id;
    const extraReqData = {
      fromDate: this.dateRange && this.dateRange.fromDate,
      toDate: this.dateRange && this.dateRange.toDate,
      ...this.activeFilters,
    };

    return await Api.analytics.getReconciliationByEmployee(accountId, rmd, extraReqData);
  }, this.annotate);

  /** Sets the date range */
  @action.bound private updateDateRangeValue(range: DateRangeExternalPicker.DateRange) {
    this.props.settingStore!.setDate(this.props.location.pathname, range);
    this.dateRange = range;
    this.activeFilters = { ...this.activeFilters };
  }

  filters: Filter[] = [];

  exportElements = [
    {
      name: 'Download as CSV',
      action: async () => {
        await this.downloadCSV(
          Api.analytics.reports.downloadReconciliationByTransactionCsv,
          'reconciliation-by-transaction.csv',
        );
      },
    },
  ];

  async componentDidMount() {
    this.fetchStats();
  }

  render() {
    return (
      <>
        <Box display="flex" flexDirection="row" mb={3}>
          <Grid container spacing={3}>
            <Grid item xs>
              <HorizontalStatCard
                icon={Pound}
                duration={1}
                title="Tips"
                prefix="$"
                separator=","
                decimals={2}>
                {this.statsComputed.tipsSum}
              </HorizontalStatCard>
            </Grid>
            <Grid item xs>
              <HorizontalStatCard
                color="yellow"
                icon={CurrencyUsd}
                duration={1}
                title="Fees"
                prefix="$"
                separator=","
                decimals={2}>
                {this.statsComputed.feesSum}
              </HorizontalStatCard>
            </Grid>
            <Grid item xs>
              <HorizontalStatCard
                color="purple"
                icon={Coins}
                duration={1}
                prefix="$"
                separator=","
                decimals={2}
                title="Total Payments">
                {this.statsComputed.paymentTotal}
              </HorizontalStatCard>
            </Grid>
          </Grid>
        </Box>
        <Box>
          <FilterBar
            filters={this.filters}
            onChange={(filters: Record<string, unknown>) => {
              this.activeFilters = filters;
            }}
            externalDateRange={{
              predefined: this.dateRange,
              onChange: this.updateDateRangeValue,
            }}
          />
          <DataGridInfiniteScroll
            columns={this.gridColumns}
            fetch={this.fetchData}
            refetchKey={this.activeFilters}
            disableColumnMenu
            sortDirection="DESC"
            sortByField="createdAt"
            actions={{
              onExport: this.exportElements,
            }}
            pathname={this.props.location.pathname}
          />
        </Box>
      </>
    );
  }
}

export default withStyles(styles)(ByTransaction);
