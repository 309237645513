import _ from 'lodash';
import { IPendingIntegration } from 'models/Integration';

interface ILocalStorage {
  key: string;
  value: string | null;
}

export enum ELocalStorageItem {
  FINGERPRINT = 'fingerprint',
  REFRESH_TOKEN = 'refreshToken',
}

/**
 * Gets the JWT token from local storage.
 */
export function getJwt(): string | undefined {
  return localStorage.getItem('jwt') || undefined;
}

/**
 * Sets the JWT token, writing it to local storage.
 * @param jwt The JWT token to save
 */
export function setJwt(jwt: string | undefined) {
  if (jwt) {
    localStorage.setItem('jwt', jwt);
  } else {
    localStorage.removeItem('jwt');
  }
}

/**
 * Sets the JWT token, writing it to local storage.
 * @param refreshToken The JWT token to save
 */
export function setRefreshToken(refreshToken: string) {
  localStorage.setItem(ELocalStorageItem.REFRESH_TOKEN, refreshToken);
}

export function getExp(): number | undefined {
  const exp = parseInt(localStorage.getItem('exp') || '');
  return isNaN(exp) ? undefined : exp;
}

export function setExp(exp: number | undefined) {
  if (exp) {
    localStorage.setItem('exp', `${exp}`);
  } else {
    localStorage.removeItem('exp');
  }
}

export function setUser(user: object) {
  if (user) {
    localStorage.setItem('userStore/user', JSON.stringify(user));
  }
}

export function getUser(): object | undefined {
  const user = localStorage.getItem('user');
  return user !== null && JSON.parse(user);
}

export function get(key: string): object | undefined {
  const value = localStorage.getItem(key);
  if (value !== null) {
    return JSON.parse(value);
  }
}

export function set<T>(key: string, value: T | undefined) {
  if (value !== undefined) {
    localStorage.setItem(key, JSON.stringify(value));
  } else {
    localStorage.removeItem(key);
  }
}

export function remove(key: string) {
  localStorage.removeItem(key);
}

export function clear() {
  localStorage.clear();
}

export function setPendingIntegrations(value: IPendingIntegration) {
  let pendingIntegrations: string | null | Array<IPendingIntegration> =
    localStorage.getItem('pendingIntegrations');
  if (!pendingIntegrations) {
    pendingIntegrations = JSON.stringify([value]);
    localStorage.setItem('pendingIntegrations', pendingIntegrations);
  } else {
    pendingIntegrations = JSON.parse(pendingIntegrations) as Array<IPendingIntegration>;
    const duplicateIntegration = pendingIntegrations.find(
      (pendingIntegration: IPendingIntegration) => _.isMatch(value, pendingIntegration),
    );
    if (duplicateIntegration) return;
    pendingIntegrations.push(value);
    pendingIntegrations = JSON.stringify(pendingIntegrations);
    localStorage.setItem('pendingIntegrations', pendingIntegrations);
  }
}

export function clearPendingIntegrations() {
  localStorage.removeItem('pendingIntegrations');
}

export function getPendingIntegrations() {
  let pendingIntegrations = localStorage.getItem('pendingIntegrations');
  if (pendingIntegrations) {
    return JSON.parse(pendingIntegrations);
  }
}

/**
 * Searches the localstorage for properties which include given search string
 * */
export function getProperties(search: string) {
  const keys = Object.keys(localStorage).filter((v) => v.includes(search));
  if (keys.length > 0) {
    return keys.map((key: string) => {
      return { key, value: localStorage.getItem(key) };
    });
  }
  return;
}

export function setProperties(properties: ILocalStorage[]) {
  properties.forEach(({ key, value }) => {
    if (value) {
      localStorage.setItem(key, value);
    }
  });
}
