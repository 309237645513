import React, { Fragment } from 'react';
import { observer } from 'mobx-react';
import { action, makeObservable } from 'mobx';
import { Profession } from 'models';
import { WithStyles, withStyles } from '@material-ui/core/styles';
import { List, ListItem, Icon } from '@material-ui/core';
import clsx from 'clsx';

import styles from './styles';

interface ProfessionListProps extends WithStyles<typeof styles> {
  selected?: Profession;
  onChange?: (profession: Profession) => void;
}

/**
 * Simple and reusable component that displays list of professions defined in User model.
 * @param selected Optional String representation of selected/active profession
 * @param onChange Optional onChange handler that takes selected profession
 */
@observer
class ProfessionList extends React.Component<ProfessionListProps> {
  constructor(props: ProfessionListProps) {
    super(props);
    makeObservable(this);
  }
  @action private onSelect = (profession: Profession) => (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    this.props.onChange && this.props.onChange(profession);
  };

  render() {
    const { professionListRoot, listItem, active } = this.props.classes;
    const { selected } = this.props;

    return (
      <List className={professionListRoot}>
        {Object.values(Profession).map((profession) => (
          <Fragment key={profession}>
            <ListItem
              button
              className={clsx(listItem, {
                [active]: profession === selected,
              })}
              onClick={this.onSelect(profession)}
              key={profession}>
              <span>{profession}</span>
              <Icon>keyboard_arrow_right</Icon>
            </ListItem>
          </Fragment>
        ))}
      </List>
    );
  }
}

export default withStyles(styles)(ProfessionList);
