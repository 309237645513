import CarouselScreenWrapper from 'components/CarouselScreenWrapper/CarouselScreenWrapper';
import OutlinedInput from 'components/Input/OutlinedInput/OutlinedInput';
import usePhoneNumber from './usePhoneNumber';
import { observer } from 'mobx-react';
import { Box, Typography } from '@material-ui/core';
import MFAForm from 'components/MFAForm/MFAForm';
import SignupLayout from 'containers/SignupLayout/SignupLayout';

const PhoneNumber = observer(() => {
  const {
    submit,
    helperText,
    phoneField,
    error,
    showMfaStep,
    formId,
    signupLayoutProps,
    handleContinue,
    handleChangePhoneNumber,
  } = usePhoneNumber();
  return (
    <CarouselScreenWrapper>
      <SignupLayout {...signupLayoutProps}>
        {showMfaStep ? (
          <MFAForm
            title={''}
            continueButton="Continue"
            onContinue={handleContinue}
            backButton="Change phone number"
            onBack={handleChangePhoneNumber}
          />
        ) : (
          <>
            <Box mt={14.125}>
              <Typography variant="body2" align="center">
                {helperText}
              </Typography>
            </Box>
            <Box mt={4}>
              <form id={formId} onSubmit={submit}>
                <OutlinedInput
                  {...(phoneField && phoneField.bind())}
                  variant="phone"
                  error={error}
                  fullWidth
                  autoFocus
                />
              </form>
            </Box>
          </>
        )}
      </SignupLayout>
    </CarouselScreenWrapper>
  );
});

export default PhoneNumber;
