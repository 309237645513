import { Theme, createStyles } from '@material-ui/core';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default (theme: Theme) =>
  createStyles({
    root: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    tooltip: {
      color: 'black',
      border: '1px solid rgba(224, 224, 224, 1)',
      fontSize: '1rem',
      padding: '1rem',
      backgroundColor: 'white',
    },
  });
