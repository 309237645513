import React from 'react';
import { observer } from 'mobx-react';
import { computed, observable, action, makeObservable } from 'mobx';
import { ITipOptionLabels } from 'models';
import { WithStyles, withStyles } from '@material-ui/core/styles';
import styles from './styles';
import {
  DialogContent,
  DialogTitle,
  IconButton,
  Divider,
  Box,
  Typography,
  DialogContentText,
  DialogActions,
} from '@material-ui/core';
import ChevronLeft from 'mdi-material-ui/ChevronLeft';
import SettingsStore from './SettingsStore';
import UnderlineInput from 'components/Input/UnderlineInput/UnderlineInput';
import Button from 'components/Button/Button';

interface TipOptionLabelsProps extends WithStyles<typeof styles> {
  settingsStore?: SettingsStore;
  goBack: () => void;
}

interface IOptionErrors {
  labelLow: boolean;
  labelMedium: boolean;
  labelHigh: boolean;
}

const initialOptionLabelErrors: IOptionErrors = {
  labelLow: false,
  labelMedium: false,
  labelHigh: false,
};

@observer
class TipOptionLabels extends React.Component<TipOptionLabelsProps> {
  constructor(props: TipOptionLabelsProps) {
    super(props);
    makeObservable(this);
  }

  @observable private loading = true;
  @observable private optionLabels?: ITipOptionLabels;
  @observable private optionLabelsErrors = initialOptionLabelErrors;

  componentDidMount() {
    if (this.props.settingsStore?.generalSettings) {
      const { labelLow, labelMedium, labelHigh } = this.props.settingsStore
        .generalSettings as unknown as ITipOptionLabels;
      this.optionLabels = { labelLow, labelMedium, labelHigh };
      this.loading = false;
    }
  }

  @action.bound changeSelectedField = (e: any) => {
    const { name, value } = e.target;
    this.optionLabels![name as keyof ITipOptionLabels] = value;
    this.validateInput(value, name);
  };

  @action.bound validateInput = (value: string, field: string) => {
    let invalid = false;
    if (!value) {
      invalid = true;
    }
    this.optionLabelsErrors[field as keyof IOptionErrors] = invalid;
  };

  @computed get isFormValid() {
    const { labelLow, labelMedium, labelHigh } = this.optionLabelsErrors;
    return labelLow || labelMedium || labelHigh;
  }

  saveOptionLabels = () => {
    const settings = this.optionLabels!;
    this.props.settingsStore?.updateGeneralSettings(settings);
  };

  renderOptionLabelFields = () => {
    return Object.keys(this.optionLabels!).map((key: string) => {
      const value = this.optionLabels![key as keyof ITipOptionLabels];
      const error = this.optionLabelsErrors[key as keyof IOptionErrors];
      let label = 'low';
      if (key === 'labelMedium') label = 'medium';
      if (key === 'labelHigh') label = 'high';
      return (
        <Box mt={5} key={key}>
          <UnderlineInput
            key={key}
            name={key}
            label={label}
            value={value}
            error={error}
            width={320}
            fontSize={56}
            onChange={this.changeSelectedField}
          />
        </Box>
      );
    });
  };

  render() {
    const { drawerContent, fontSecondary, dialogActions } = this.props.classes;

    return (
      <>
        <DialogTitle>
          <Box display="flex" alignItems="center" justifyContent="space-start">
            <Box mr={2}>
              <IconButton onClick={this.props.goBack}>
                <ChevronLeft />
              </IconButton>
            </Box>
            <Typography variant="h4" component="h1" display="inline">
              Tip Option Labels{' '}
            </Typography>
          </Box>
        </DialogTitle>
        <Divider />
        <DialogContent className={drawerContent}>
          <Box mt={3}>
            <DialogContentText className={fontSecondary}>
              Tip option labels to be presented:{' '}
            </DialogContentText>
          </Box>
          {!this.loading && this.renderOptionLabelFields()}
        </DialogContent>
        <DialogActions className={dialogActions}>
          <Button
            variant="contained"
            color="primary"
            onClick={this.saveOptionLabels}
            disabled={this.isFormValid}
            fullWidth>
            Save
          </Button>
        </DialogActions>
      </>
    );
  }
}

export default withStyles(styles)(TipOptionLabels);
