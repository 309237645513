import React from 'react';
import { action, computed, makeObservable, IReactionDisposer, reaction } from 'mobx';
import { observer } from 'mobx-react';
import { WithStyles, withStyles } from '@material-ui/core/styles';
import { RouteComponentProps } from 'react-router-dom';

import { inject, WithUserStore, WithToastStore, WithUiStore } from 'stores';
import { paths } from 'routes';

import CartPreview from 'components/CartPreview';

import { ReactComponent as ReviewImg } from './review_order.svg';
import SignupStore, { SignupStep } from './SignupStore';

import styles from './styles';
import theme from 'containers/App/theme';
import Button from 'components/Button/Button';
import AccountSignupLayout from './AccountSignupLayout/AccountSignupLayout';

/** Here we define what kind of props this component takes */
type ReviewStepProps = { signupStore: SignupStore } & WithStyles<typeof styles> & // Adds the classes prop
  RouteComponentProps<{ accountId: string }> &
  WithUserStore & // Adds the userStore prop
  WithUiStore &
  WithToastStore;

/**
 * The review order screen of the account signup for admins
 */
@inject('userStore', 'toastStore', 'uiStore')
@observer
class ReviewStep extends React.Component<ReviewStepProps> {
  constructor(props: ReviewStepProps) {
    super(props);
    makeObservable(this);
    this.disposers.push(
      reaction(
        () => this.props.signupStore.orderFinalized,
        () => {
          this.redirectToHome();
        },
      ),
    );
  }

  /**
   * It's good practice to dispose of any autoruns that we set up during
   */
  private disposers: IReactionDisposer[] = [];
  @action.bound public handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    this.props.signupStore.finalizeOrder();
  }
  @computed public get accountId(): number {
    return parseInt(this.props.match.params.accountId);
  }
  @computed public get requiresCreditCard(): boolean {
    return Boolean(this.props.signupStore.cart && this.props.signupStore.cart.requiresCreditCard);
  }

  async redirectToHome() {
    return new Promise((resolve) =>
      setTimeout(() => {
        this.props.history.push(paths.root());
        resolve(true);
      }, 5000),
    );
  }

  componentDidMount() {
    this.props.signupStore.setStep(SignupStep.Review);
    this.props.signupStore.initAccount(this.accountId);
  }

  /** Before unmounting the component, dispose of all autoruns created */
  componentWillUnmount() {
    this.disposers.map((disposer) => disposer());
  }
  render() {
    const { classes } = this.props;
    const signupStore = this.props.signupStore;
    if (!signupStore.accountReady) {
      return null;
    }
    // TODO: Refactor in future, for now we manually send the Persona link to the owner
    const submitButtonLabel = 'Send Persona Link to Owner now';
    // this.requiresCreditCard
    //   ? `Send purchase order to customer`
    //   : `Finish account creation`;
    return (
      <AccountSignupLayout
        title={{
          name: 'Review Order',
          backPath: paths.adminAccountSignup().coupons(this.accountId),
        }}
        subtitle={{
          name: 'Due to PCI compliance it is strongly recommended that customers enter their own credit card information.',
        }}
        image={{ svg: <ReviewImg /> }}
        contentRight={{
          children: (
            <form
              id="review-step-form"
              onSubmit={this.handleSubmit}
              className={classes.accountStepForm}>
              <CartPreview cart={signupStore.cart!} cartInProgress={signupStore.cartInProgress} />
            </form>
          ),
          mb: 'auto',
        }}
        actionsRight={
          <Button
            className={classes.nextStepButton}
            style={{ marginTop: theme.spacing(2) }}
            fullWidth
            form="review-step-form"
            variant="contained"
            color="primary"
            type="submit"
            disabled={true}>
            {submitButtonLabel}
          </Button>
        }
      />
    );
  }
}

export default withStyles(styles)(ReviewStep);
