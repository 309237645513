import { Box, Button, Divider, IconButton, List, ListItem } from '@material-ui/core';
import { KeyboardArrowRight } from '@mui/icons-material';
import { ListItemText } from '@mui/material';
import { useStyles } from './styles';
import { inject, observer } from 'mobx-react';
import React from 'react';
import { createKey } from 'utils/helper';
import { IDisplayPool, IStationDisplayGroup } from './blPoolsAndGroups';
import { useStationPoolsAndGroupsList } from './useStationPoolsAndGroupsList';
import { StationItem } from 'models/Station';

export const StationPoolsAndGroupsList = inject()(
  observer(() => {
    const [poolsAndGroups, groups, editGroup, removePoolOrGroup] = useStationPoolsAndGroupsList();
    const classes = useStyles();
    return (
      <List>
        {poolsAndGroups &&
          poolsAndGroups.map((item: IDisplayPool | IStationDisplayGroup) => {
            return (
              <React.Fragment key={createKey('Fragment', item.id)}>
                <ListItem key={createKey('ListItem', item.id)} style={{ margin: 0, padding: 0 }}>
                  <ListItemText
                    primary={item.name}
                    secondary={
                      <SecondaryItems>
                        <Item item={item} />
                        <RemoveButton onClick={() => removePoolOrGroup(item)} />
                      </SecondaryItems>
                    }
                  />
                  {item.type === StationItem.GROUP && (
                    <IconButton
                      classes={{ root: classes.editButton }}
                      onClick={() => editGroup(item)}>
                      <KeyboardArrowRight />
                    </IconButton>
                  )}
                </ListItem>
                <Divider />
              </React.Fragment>
            );
          })}
      </List>
    );
  }),
);

const SecondaryItems = ({ children }: { children: any }) => {
  const classes = useStyles();
  return (
    <Box component={'span'} className={classes.secondaryItemsWrapper}>
      {children}
    </Box>
  );
};

const Item = (props: { item: IDisplayPool | IStationDisplayGroup }) => {
  const { item } = props;
  let secondary = item.type as string;
  if (item.type === StationItem.GROUP) {
    secondary = `${secondary} (${item.countUsers} users, ${item.countPools} pools)`;
  }
  return <Box component={'span'}>{secondary}</Box>;
};

const RemoveButton = ({ onClick }: { onClick: (item: any) => void }) => {
  const classes = useStyles();
  return (
    <Button
      classes={{ root: classes.removeButton }}
      size="small"
      variant="text"
      color="primary"
      onClick={onClick}>
      remove
    </Button>
  );
};
