import React from 'react';
import { action, computed, observable, makeObservable, reaction } from 'mobx';
import { observer } from 'mobx-react';
import * as stores from 'stores';

import Api, { getErrorMsg, PagedApiResponse } from 'api';
import { Location, Invitation, Role, UserInvitationType } from 'models';

import { WithStyles, withStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';

import DashboardLayout from 'containers/DashboardLayout';
import InvitePanel from 'components/InvitePanel';
import InvitationsListPanel from 'components/InvitationsListPanel';

import styles from './styles';

import { RouteComponentProps } from 'react-router-dom';
import { AxiosResponse } from 'axios';
import Title from 'components/Title/Title';

type InviteUsersProps = WithStyles<typeof styles> &
  RouteComponentProps &
  stores.WithToastStore &
  stores.WithUserStore;

/**
 * Container that encapsulates invitations sending workflow and
 * display. Navigation to this screen is possible from 1) users
 * list screen or 2) users panel in location details screen.
 */
@stores.inject('userStore', 'toastStore')
@observer
class InviteUsers extends React.Component<InviteUsersProps> {
  constructor(props: InviteUsersProps) {
    super(props);
    makeObservable(this);
    this.r = reaction(() => [this.skip, this.invitationsType], this.getInvitations);
  }

  /** List of invitations */
  @observable private invitations: Invitation[] = [];

  /** Total count invitations */
  @observable private invitationsCount = 0;

  /** Invitations type */
  @observable private invitationsType: UserInvitationType = UserInvitationType.SENT;

  /** Rows per page query invitations list */
  @observable private pageSize = 25;

  /** How many items to skip on the next request */
  @observable public skip = 0;

  /** Add more invitations to the previous list to merge previous and new data, used with Load More, */
  @observable public addInvitationsToExistingList = false;

  @observable public loadingInvitations = false;

  r;

  @computed public get scope(): stores.ScopeType {
    return this.props.userStore!.scope.kind;
  }

  /** Calculate account id based on scope and route data */
  @computed public get accountId(): null | number {
    if (this.scope === 'admin' && this.location) {
      return this.location.accountId;
    }
    if (this.scope === 'manager' && this.props.userStore!.currentManagedLocation!.account) {
      return this.props.userStore!.currentManagedLocation!.account.id;
    }
    if (this.scope === 'owner' && this.props.userStore!.currentAccount) {
      return this.props.userStore!.currentAccount.id;
    }
    return null;
  }

  /**
   * Location is set 1) if user came from location details screen
   * and that location is stored in routers state or 2) if current
   * user is manager which means his scope is set to a single location
   * and we can use that locations id.
   */
  @computed public get location(): null | Location {
    const routerState: any = this.props.location.state;
    const managedLocation = this.props.userStore!.currentManagedLocation;
    return managedLocation ? managedLocation : routerState ? routerState.location : null;
  }

  @action.bound public getInvitations = async () => {
    try {
      this.loadingInvitations = true;
      let res: AxiosResponse<PagedApiResponse<Invitation>> | undefined;
      if (this.accountId && (this.scope === 'admin' || this.scope === 'owner')) {
        res = await Api.core.getAccountInvitations(this.accountId, {
          pagination: {
            take: this.pageSize,
            skip: this.skip,
          },
          filters: { status: this.invitationsType },
        });
      } else if (this.location) {
        res = await Api.core.getLocationInvitations(this.location.id, {
          pagination: {
            take: this.pageSize,
            skip: this.skip,
          },
          filters: { status: this.invitationsType },
        });
      }
      if (res) {
        if (this.addInvitationsToExistingList) {
          this.invitations = [...this.invitations, ...res.data.data!];
          this.addInvitationsToExistingList = false;
        } else {
          this.invitations = res.data.data || [];
        }
        this.invitationsCount = res.data.count;
      }
    } catch (e: any) {
      this.props.toastStore!.error(getErrorMsg(e));
    } finally {
      this.loadingInvitations = false;
    }
  };

  @action.bound public loadMore = () => {
    this.addInvitationsToExistingList = true;
    this.skip = this.skip + this.pageSize;
  };

  /** Whether to display the load more button */
  @computed public get showLoadMore(): boolean {
    return this.invitationsCount > this.invitations!.length;
  }

  /** Show different type of invitations */
  @action.bound public changeInvitationsType(status: UserInvitationType): void {
    this.addInvitationsToExistingList = false;
    this.invitationsType = status;
    this.skip === 0 ? this.getInvitations() : (this.skip = 0);
  }

  /** Update invitations after action button is clicked (revoke/reject) */
  @action.bound public updateInvitations = () => {
    this.addInvitationsToExistingList = false;
    this.skip === 0 ? this.getInvitations() : (this.skip = 0);
  };

  @action.bound public invite = async (
    emails: string[],
    selectedRoles: Role[],
    location: Location | null,
  ) => {
    try {
      const invitations = emails.map((email) => ({
        email: email.toLowerCase(),
        accountId: this.accountId!,
        locationId: location ? location.id : undefined,
        isManager: selectedRoles.includes('manager'),
        isTalent: selectedRoles.includes('talent'),
      }));
      if (this.accountId) {
        await Api.core.inviteUsers(invitations);
        this.skip === 0 ? this.getInvitations() : (this.skip = 0);
        this.props.toastStore!.push({
          type: 'success',
          message: `Invitations sent successfully`,
        });
      } else {
        throw new Error('Invitation is missing account destination');
      }

      return { error: false };
    } catch (e: any) {
      this.props.toastStore!.error(getErrorMsg(e));
      return { error: true };
    }
  };

  componentDidMount() {
    this.getInvitations();
  }

  render() {
    return (
      <DashboardLayout>
        <Title mb={3}>
          {this.location && this.props.userStore!.scope.kind === 'manager'
            ? `Invite talents to join your collective at ${this.location.name}`
            : 'Invite users'}
        </Title>
        <Box mb={3} mt={3}>
          <InvitePanel
            scope={this.props.userStore!.scope}
            location={this.location}
            onSubmit={this.invite}
          />
        </Box>
        <Box mb={3} mt={3}>
          <InvitationsListPanel
            invitations={this.invitations}
            invitationsCount={this.invitationsCount}
            onUpdate={this.updateInvitations}
            onLoadMore={this.loadMore}
            showLoadMore={this.showLoadMore}
            loadingInvitations={this.loadingInvitations}
            onChangeInvitationsType={this.changeInvitationsType}
          />
        </Box>
      </DashboardLayout>
    );
  }
}

export default withStyles(styles)(InviteUsers);
