export const calculateAndSetMinWidth = (
  itemRefs: React.MutableRefObject<React.RefObject<HTMLSpanElement>[]>,
) => {
  const gap = 16;
  const minItemWidth = 80;
  itemRefs.current.forEach((ref) => {
    if (ref.current) {
      const childSpan = ref.current?.querySelector('span.label') as HTMLSpanElement | null;
      const itemWidth = (childSpan?.offsetWidth || 0) + gap;
      const minWidth = Math.max(minItemWidth, itemWidth);
      ref.current.style.minWidth = `${minWidth}px`;
    }
  });
};

export const slideIntoView = (
  ref: React.RefObject<HTMLSpanElement>,
  buttonGroupRef: React.MutableRefObject<any>,
) => {
  const childRect = ref?.current?.getBoundingClientRect();
  const parentRect = buttonGroupRef.current && buttonGroupRef?.current.getBoundingClientRect();

  if (childRect && parentRect) {
    let scroll = 0;
    const leftOutside = childRect.left < parentRect.left;
    if (leftOutside) {
      scroll = childRect?.left - parentRect.left;
    }
    const rightOutside = childRect.right > parentRect.right;
    if (rightOutside) {
      scroll = childRect.right - parentRect.right;
    }

    buttonGroupRef.current.scrollTo({
      left: buttonGroupRef.current.scrollLeft + scroll,
      behavior: 'smooth',
    });
  }
};

let mouseDown = false;
let startX = 0,
  scrollLeft = 0;
const dragThreshold = 5;

export const startDragging = (e: any, slider: HTMLElement) => {
  e.preventDefault();
  mouseDown = true;
  startX = e.pageX - slider.offsetLeft;
  scrollLeft = slider.scrollLeft;
};

export const stopDragging = (e: any, buttonGroupRef: React.MutableRefObject<any>) => {
  e.preventDefault();
  setPointerEvents('auto', buttonGroupRef);

  mouseDown = false;
};

export const move = (
  e: React.MouseEvent<HTMLDivElement, MouseEvent>,
  slider: HTMLElement,
  buttonGroupRef: React.MutableRefObject<any>,
) => {
  e.preventDefault();
  if (!mouseDown) {
    return;
  }

  const x = e.pageX - slider.offsetLeft;
  const scroll = x - startX;

  if (Math.abs(scroll) > dragThreshold) {
    setPointerEvents('none', buttonGroupRef);
  }

  slider.scrollLeft = scrollLeft - scroll;
};

const setPointerEvents = (event: 'auto' | 'none', buttonGroupRef: React.MutableRefObject<any>) => {
  const firstLevelChildren = buttonGroupRef.current.children;
  for (let i = 0; i < firstLevelChildren.length; i++) {
    const child = firstLevelChildren[i] as HTMLElement;
    child.style.pointerEvents = event;
  }
};
