import Api from 'api';
import { Pool } from 'models/Pool';
import { IStationPool, IStationItem, ISelectedLocationPool } from 'models/Station';

// type StationItemType = IStationUser | IStationPool | IStationGroup;

// interface ILocationPoolItem {
//   id: number;
//   name: string;
// }

// export interface ISelectedLocationPool {
//   id: number;
//   name: string;
//   selected: boolean;
//   type: StationItem;
// }

enum StationItem {
  USER = 'user',
  POOL = 'pool',
  GROUP = 'group',
}

export class BlPools {
  static async getPools(accountId: number, locationId: number): Promise<Pool[] | undefined> {
    if (!accountId || !locationId) return;

    const { data } = await Api.core.getPoolsList({ accountId, locationId });
    if (data && data.data) {
      return data.data;
    }
  }

  static async createPool(pool: Pool) {
    const { data } = await Api.core.createPool(pool);

    if (data && data.data) {
      return data.data;
    }
  }

  static getStationPools(items: Array<Partial<IStationItem>>): Array<IStationPool> | [] {
    if (items.length) {
      return items.filter(
        (item: Partial<IStationItem>) => item.type === StationItem.POOL,
      ) as Array<IStationPool>;
    }
    return [];
  }

  static getSelectedLocationPools(
    locationPools: Array<Pool>,
    stationItems: Array<Partial<IStationItem>>,
  ): Array<ISelectedLocationPool> | [] {
    if (!locationPools) return [];
    const stationPools = this.getStationPools(stationItems);
    if (!stationPools || !stationPools.length) {
      return this.markPoolsAsUnselected(locationPools);
    }

    return locationPools.map((locationPool: Pool) => {
      const stationPool = stationPools.find(
        (stationPool: IStationPool) => stationPool.poolId === locationPool.id,
      );
      const type = StationItem.POOL;
      const selected = stationPool ? true : false;
      return { ...locationPool, selected, type };
    });
  }

  /**
   * This method will add a new pool to the list of possible pools to add to group or station.
   * If some pools were selected before adding new pool, these pools will stay selected.
   */
  static getUpdatedSelectedLocationPools(
    selectedPools: Array<ISelectedLocationPool>,
    locationPools: Array<Pool>,
  ): Array<ISelectedLocationPool> | [] {
    if (!selectedPools) return [];
    if (!locationPools) return selectedPools;

    return locationPools.map((pool: Pool): ISelectedLocationPool => {
      const _pool = selectedPools.find(
        (selectedPool: ISelectedLocationPool) => selectedPool.id === pool.id,
      );
      if (_pool) {
        return _pool;
      }
      return { name: pool.name, id: pool.id, selected: false, type: StationItem.POOL } as ISelectedLocationPool;
    });
  }

  private static markPoolsAsUnselected(pools: Pool[]) {
    return pools.map((locationPool: any) => ({
      ...locationPool,
      type: StationItem.POOL,
      selected: false,
    }));
  }

}
