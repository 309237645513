import React, { useState } from 'react';
import { observer } from 'mobx-react';
import Dialog from 'components/Dialog/Dialog';
import * as models from 'models';
import { MenuItem, Typography } from '@material-ui/core';
import OutlinedInput from 'components/Input/OutlinedInput/OutlinedInput';
import theme from 'containers/App/theme';

export interface ILocationInvitationDialogProps {
  locations: Pick<models.Location, 'id' | 'name'>[];
  locationId: string;
  confirmLocation: (locationId?: string) => void;
  closeDialog: () => void;
}
const LocationInvitationDialog = ({
  dialogProps,
}: {
  dialogProps: ILocationInvitationDialogProps | null;
}) => {
  const [locationId, setLocationId] = useState('');
  const [loading, setLoading] = useState(false);

  const handleConfirm = () => {
    setLoading(true);
    dialogProps?.confirmLocation(locationId);
  };

  const open = !!dialogProps;

  return (
    <Dialog
      open={open}
      onClose={() => setLoading(false)}
      title="Choose location"
      content={
        <>
          <Typography style={{ marginBottom: theme.spacing(3) }} variant="body2">
            Select location you are currently working on.
          </Typography>
          <OutlinedInput
            select
            fullWidth
            value={locationId}
            onChange={(event) => {
              setLocationId(event.target.value);
            }}>
            {dialogProps?.locations.map((l) => (
              <MenuItem key={l.id} value={l.id}>
                {l.name}
              </MenuItem>
            ))}
          </OutlinedInput>
        </>
      }
      loading={loading}
      onCancel={dialogProps?.closeDialog}
      onConfirm={handleConfirm}
    />
  );
};

export default observer(LocationInvitationDialog);
