import React from 'react';
import { faCircleExclamation } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box } from '@material-ui/core';
import useStyles from './styles';

enum SupportTextType {
  ERROR = 'error',
}

interface ISupportTextProps {
  label?: string | React.ReactNode;
  type?: SupportTextType;
  mb?: number;
}
const SupportText = ({ label, mb, type = SupportTextType.ERROR }: ISupportTextProps) => {
  const classes = useStyles();

  if (!label) return null;

  return (
    <Box mb={mb} className={classes.root}>
      <FontAwesomeIcon className={classes.icon} icon={faCircleExclamation} />
      {label}
    </Box>
  );
};

export default SupportText;
