import axios from 'axios';
import UAParser from 'ua-parser-js';
import * as LocalStorage from 'services/localStorage';

const { ELocalStorageItem } = LocalStorage;

const uaString = navigator.userAgent;
let parser = new UAParser(uaString);

let fingerprint: string | undefined;

export async function createFingerprint() {
  const os = parser.getOS();
  const device = parser.getDevice();
  const browser = parser.getBrowser();
  const engine = parser.getEngine();
  const ip = await getIp();

  let fingerprintData = {
    ip,
    os: os.name,
    osVersion: os.version,
    vendor: device.vendor,
    browser: browser.name,
    browserVersion: browser.version,
    engine: engine.name,
    engineVersion: engine.version,
    timezoneOffset: new Date().getTimezoneOffset(),
  };
  fingerprint = btoa(JSON.stringify(fingerprintData));
  localStorage.setItem(ELocalStorageItem.FINGERPRINT, fingerprint);
  return fingerprint;
}

async function getIp() {
  let ip;
  try {
    const { data } = await axios.get('https://api.ipify.org/?format=json');
    ip = data.ip;
  } catch (e: any) {
    console.error('Could not obtain IP address');
  }
  return ip;
}

export async function checkFingerprintAndCreate(): Promise<void> {
  let fingerprint = localStorage.getItem(ELocalStorageItem.FINGERPRINT);
  if (!fingerprint) {
    await createFingerprint();
  }
}

function getFingerprint(): string | null {
  return localStorage.getItem(ELocalStorageItem.FINGERPRINT);
}

let isCreatingFingerprint = false;
let fingerprintPromise: Promise<string> | null = null;
async function getOrCreateFingerprint() {
  let fingerprint = getFingerprint();
  if (fingerprint) return fingerprint;
  if (isCreatingFingerprint) {
    return fingerprintPromise;
  }

  isCreatingFingerprint = true;
  fingerprintPromise = createFingerprint();
  try {
    const fingerprint = await fingerprintPromise;
    return fingerprint;
  } finally {
    isCreatingFingerprint = false;
  }
}

export default getOrCreateFingerprint;
