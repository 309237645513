import React from 'react';
import { Box, CircularProgress, Theme, createStyles, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      height: '100dvh',
      width: '100dvw',
      display: 'flex',
      position: 'absolute',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: theme.palette.common.white,
    },
  }),
);

const LoadingPage = () => {
  const { wrapper } = useStyles();
  return (
    <Box className={wrapper}>
      <Box mt={10.25} mb={8}>
        <CircularProgress color="primary" size={'87px'} />
      </Box>
    </Box>
  );
};

export default LoadingPage;
