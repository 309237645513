import React from 'react';
import { observable, action, flow, computed, makeObservable } from 'mobx';
import { observer } from 'mobx-react';
import { WithStyles, withStyles } from '@material-ui/core/styles';
import {
  Box,
  Typography,
  Link,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Tooltip,
} from '@material-ui/core';
import { Circle } from 'mdi-material-ui';
import { Link as RouterLink } from 'react-router-dom';
import { paths } from 'routes';

import Api, { getErrorMsg } from 'api';

import styles from './styles';
import { inject, WithModalStore, WithToastStore, WithUserStore } from 'stores';
import validatorjs from 'validatorjs';
import moment from 'moment-timezone';
import { Payment, PaymentFee, PaymentStatus } from 'models';

import RefundReasonSelect from 'components/RefundReasonSelect';
import CreditCardIcon from 'components/CreditCardIcon';
import SkeletonOrComponent from 'components/SkeletonOrComponent';
import DD from 'components/DashDrawer';
import { v4 as uuidv4 } from 'uuid';
import { numericStringToUsd, paymentRefundReasons } from 'services';
import clsx from 'clsx';
import theme from 'containers/App/theme';
import { ACL } from 'types';
import { formatDateTimeInEST, getFullName } from 'utils/helper';

type MobxForm = any;

const fields = [
  {
    name: 'reason',
    label: 'Refund Reason',
    rules: ['required'],
    hooks: {
      onChange: (field: any) => field.validate(),
    },
  },
  {
    name: 'customReason',
    label: 'Your Reason for Refund',
  },
];

// eslint-disable-next-line @typescript-eslint/no-var-requires
const dvr = require('mobx-react-form/lib/validators/DVR');
const MobxReactForm = require('mobx-react-form').default;

const plugins = {
  dvr: dvr({
    package: validatorjs,
    extend: ({ validator }: { validator: any }) => {
      const messages = validator.getMessages('en');
      messages.accepted = 'Please confirm';
      validator.setMessages('en', messages);
    },
  }),
};

interface FormHooks {
  onSuccess: (form: MobxForm) => void;
  onClear: (form: MobxForm) => void;
}

interface PaymentDetailsDrawerProps
  extends WithStyles<typeof styles>,
    WithToastStore,
    WithModalStore,
    WithUserStore {
  paymentReference?: string;
  onClose: () => void;
  onCloseAction?: () => void;
  linkTipCount?: boolean;
  hideActions?: boolean;
}

/**
 * Displays a filter drawer for filtering user search results
 */
@inject('modalStore', 'toastStore', 'userStore')
@observer
class PaymentDetailsDrawer extends React.Component<PaymentDetailsDrawerProps> {
  constructor(props: PaymentDetailsDrawerProps) {
    super(props);
    makeObservable(this);
    this.form = new MobxReactForm({ fields }, { plugins, hooks: this.hooks });
  }

  /** The form object for payment refund */
  @observable private form: MobxForm;

  private hooks: FormHooks = {
    onSuccess: () => this.submitRefund(),
    onClear: () => {
      this.form.clear();
      this.toggleRefundModal();
    },
  };

  @observable private paymentDetails?: Payment;

  /** Whether the form is currently being submitted */
  @observable public submitting = false;

  /** Is user revalidating payment? */
  @observable public verifyingPayment = false;

  /** Is refund modal open?  */
  @observable public refundModalOpen = false;

  /** Observable content input value */
  @observable public customReason?: string = '';

  @observable renderDistributions = false;

  @computed get customerData() {
    const paymentDetails = this.paymentDetails;

    const customer = paymentDetails && paymentDetails.customer;
    const customerCard = paymentDetails && paymentDetails.customerCard;

    return {
      firstName: customer && customer.firstName,
      lastName: customer && customer.lastName,
      lastFour: customerCard && customerCard.lastFour,
      brand: customerCard && customerCard.brand,
    };
  }

  @computed get canForceConfirm() {
    return (
      this.paymentDetails?.processor.includes('developer_app') &&
      this.props.userStore?.hasPermission(ACL.MODIFY_POS_TRANSACTIONS) &&
      this.paymentDetails.status !== PaymentStatus.CAPTURED
    );
  }

  @action.bound public getPaymentDetails = flow(function* (this: PaymentDetailsDrawer) {
    if (this.props.paymentReference) {
      try {
        const resp = yield Api.tips.getPaymentDetails(this.props.paymentReference);
        this.paymentDetails = resp.data && resp.data.data;

        if (!this.paymentDetails) return (this.renderDistributions = false);
        if (this.paymentDetails.fees!.length) this.renderDistributions = true;
      } catch (e: any) {
        this.props.toastStore!.error(getErrorMsg(e));
      }
    }
  });

  @action.bound public forceVerification = flow(function* (this: PaymentDetailsDrawer) {
    if (this.props.paymentReference) {
      try {
        this.verifyingPayment = true;
        const resp = yield Api.tips.forcePaymentVerification(this.props.paymentReference);
        const updatedPayment = resp.data && resp.data.data;
        this.paymentDetails = updatedPayment;
        this.props.toastStore!.push({
          type: 'success',
          message: `Payment revalidation status: ${updatedPayment.status.toUpperCase()}`,
        });
      } catch (e: any) {
        this.props.toastStore!.error(getErrorMsg(e));
      } finally {
        this.verifyingPayment = false;
      }
    }
  });

  @action.bound private toggleRefundModal() {
    this.refundModalOpen = !this.refundModalOpen;
  }

  /** Submits the form */
  @action.bound public submitRefund = flow(function* (this: PaymentDetailsDrawer) {
    try {
      const predefinedReason = this.form.$('reason').value;
      const customReason = this.form.$('customReason').value;
      const reason = predefinedReason === 'OTHER' ? customReason : predefinedReason;
      this.submitting = true;
      if (this.paymentDetails) {
        yield Api.tips.requestRefund(
          this.paymentDetails.reference || '',
          this.paymentDetails.transactionId || '',
          this.paymentDetails.chargeAmount || '',
          uuidv4(),
          reason,
        );
        this.props.toastStore!.push({
          type: 'success',
          message: 'Payment refunded successfully',
        });
      }
    } catch (e: any) {
      this.props.toastStore!.error(getErrorMsg(e));
    } finally {
      this.toggleRefundModal();
      this.submitting = false;
    }
  });

  /** Update content input value */
  @action.bound public updateContent = (event: React.ChangeEvent<{ value: unknown }>) => {
    this.customReason = event.target.value as string;
  };

  forceConfirm = async () => {
    const dialogTitle = 'Force confirm';
    const dialogBody =
      'Are you sure you want to force confirm this payment, money will be sent to employees and action is irreversible?';
    const options = {
      confirmLabel: 'Confirm',
      cancelLabel: 'Cancel',
    };
    const confirmed = await this.props.modalStore!.confirm(dialogTitle, dialogBody, options);
    if (confirmed) {
      const body = {
        processor: this.paymentDetails!.processor,
        transactionId: this.paymentDetails!.transactionId as string,
      };
      try {
        await Api.developer.forceConfirm(body);
        this.props.onClose();
        this.props.onCloseAction && this.props.onCloseAction();
      } catch (e: any) {
        this.props.toastStore?.error(getErrorMsg(e));
      }
    }
  };

  componentDidMount() {
    this.getPaymentDetails();
  }

  getFee(amount: string, percent: string) {
    return `(${percent}% + ${numericStringToUsd(amount)})`;
  }

  /** Render refund modal form */
  renderRefundModalForm = () => {
    const { button, cancelButton, confirmButton, disabledButton } = this.props.classes;
    const paymentDetails = this.paymentDetails;
    if (!paymentDetails) return null;
    const customerName = `${this.customerData.firstName} ${this.customerData.lastName}`;
    return (
      <Dialog open={this.refundModalOpen} onClose={this.toggleRefundModal}>
        <form onSubmit={this.form.onSubmit}>
          <Box minWidth={380} style={{ padding: theme.spacing(2) }}>
            <DialogTitle>
              You will issue refund for ${paymentDetails.chargeAmount} to {customerName}
            </DialogTitle>
            <DialogContent>
              <p>{this.form.error}</p>
              <RefundReasonSelect
                selectField={this.form.$('reason')}
                textField={this.form.$('customReason')}
                reasons={paymentRefundReasons}
              />
            </DialogContent>
            <DialogActions>
              <Box mt={3}>
                <Button
                  className={clsx(button, cancelButton)}
                  onClick={this.form.onClear}
                  color="primary">
                  Cancel
                </Button>
                <Button
                  style={{ marginLeft: theme.spacing(1) }}
                  className={clsx(button, !this.form.isValid ? disabledButton : confirmButton)}
                  type="submit"
                  disabled={!this.form.isValid}>
                  Refund
                </Button>
              </Box>
            </DialogActions>
          </Box>
        </form>
      </Dialog>
    );
  };

  getTotalFee = () => {
    const totalFee = this.paymentDetails!.fees!.reduce(
      (sumFee: number, fee: PaymentFee): number => {
        return (sumFee += parseFloat(fee.fee));
      },
      0,
    );
    return totalFee.toString();
  };

  render() {
    const { classes, onClose, hideActions } = this.props;
    const { secondItem, skeleton, circleDot, value, fullWidth } = classes;
    const paymentDetails = this.paymentDetails;
    const canForceConfirm = this.canForceConfirm;
    return (
      <DD>
        <DD.Title padding showDivider onClose={onClose}>
          <SkeletonOrComponent className={skeleton} width={200} height={50}>
            {paymentDetails && paymentDetails.reference && paymentDetails.reference}
          </SkeletonOrComponent>
        </DD.Title>
        <DD.Content>
          <DD.Row>
            <DD.Item>
              <DD.Value>
                <SkeletonOrComponent className={skeleton} rangeWidth={[80, 120]}>
                  {paymentDetails && formatDateTimeInEST(paymentDetails.createdAt)}
                </SkeletonOrComponent>
              </DD.Value>
              <DD.Label>Date</DD.Label>
            </DD.Item>
            <DD.Item className={secondItem}>
              <DD.Value>
                <SkeletonOrComponent className={skeleton} rangeWidth={[80, 120]}>
                  {paymentDetails?.deviceTime &&
                    moment(paymentDetails.deviceTime).tz('America/New_York').format('H:mm A z')}
                </SkeletonOrComponent>
              </DD.Value>
              <DD.Label>Time</DD.Label>
            </DD.Item>
          </DD.Row>
          <DD.Row>
            <DD.Item>
              <DD.Value>
                <SkeletonOrComponent className={skeleton} rangeWidth={[80, 120]}>
                  {paymentDetails?.localTime &&
                    moment(paymentDetails.localTime).tz(moment.tz.guess(true)).format('H:mm A z')}
                </SkeletonOrComponent>
              </DD.Value>
              <DD.Label>Local time</DD.Label>
            </DD.Item>
            <DD.Item className={secondItem}>
              <DD.Value>
                <SkeletonOrComponent className={skeleton} rangeWidth={[80, 120]}>
                  {paymentDetails && paymentDetails.tippyFee
                    ? numericStringToUsd(paymentDetails.chargeAmount)
                    : 'N/A'}
                </SkeletonOrComponent>
              </DD.Value>
              <DD.Label>Amount</DD.Label>
            </DD.Item>
          </DD.Row>
          <DD.Row>
            <DD.Item>
              <DD.Value>
                <SkeletonOrComponent className={skeleton} rangeWidth={[80, 120]}>
                  {paymentDetails?.customerCard && (
                    <>
                      <CreditCardIcon
                        style={{ marginRight: 5, marginTop: 4 }}
                        brand={paymentDetails.customerCard.brand || ''}
                      />
                      ****{paymentDetails.customerCard.lastFour}
                    </>
                  )}
                </SkeletonOrComponent>
              </DD.Value>
              <DD.Label>Payment method</DD.Label>
            </DD.Item>
            <DD.Item className={secondItem}>
              <DD.Value>
                <SkeletonOrComponent className={skeleton} rangeWidth={[80, 120]}>
                  {getFullName(
                    paymentDetails?.customer?.firstName,
                    paymentDetails?.customer?.lastName,
                    'N/A',
                  )}
                </SkeletonOrComponent>
              </DD.Value>
              <DD.Label>Customer</DD.Label>
            </DD.Item>
          </DD.Row>
          <DD.Row>
            <DD.Item>
              <DD.Value>
                <SkeletonOrComponent className={skeleton} rangeWidth={[80, 120]}>
                  {(paymentDetails?.processor || '').toUpperCase()}
                </SkeletonOrComponent>
              </DD.Value>
              <DD.Label>Processor</DD.Label>
            </DD.Item>
            <DD.Item className={secondItem}>
              <DD.Value>
                <SkeletonOrComponent className={skeleton} rangeWidth={[80, 120]}>
                  {paymentDetails?.reference &&
                    (this.props.linkTipCount === false ? (
                      <Typography> {paymentDetails?.tipsCount}</Typography>
                    ) : (
                      <Link
                        component={RouterLink}
                        to={{
                          pathname: paths.paymentTips(paymentDetails.reference),
                          state: { ...this.customerData },
                        }}>
                        SHOW ({paymentDetails?.tipsCount})
                      </Link>
                    ))}
                </SkeletonOrComponent>
              </DD.Value>
              <DD.Label>Tip count</DD.Label>
            </DD.Item>
          </DD.Row>
          {!this.renderDistributions && (
            <DD.Row>
              <DD.Item>
                <DD.Value>
                  <SkeletonOrComponent className={skeleton} rangeWidth={[80, 120]}>
                    {paymentDetails?.tippyFee
                      ? numericStringToUsd(paymentDetails?.processorFee)
                      : 'N/A'}
                  </SkeletonOrComponent>
                </DD.Value>
                <DD.Label>Processor fee</DD.Label>
              </DD.Item>
              <DD.Item className={secondItem}>
                <DD.Value>
                  <SkeletonOrComponent className={skeleton} rangeWidth={[80, 120]}>
                    {paymentDetails?.tippyFee
                      ? numericStringToUsd(paymentDetails?.tippyFee)
                      : 'N/A'}{' '}
                  </SkeletonOrComponent>
                </DD.Value>
                <DD.Label>Tippy fee</DD.Label>
              </DD.Item>
            </DD.Row>
          )}
          <DD.Row>
            <DD.Item>
              <DD.Value>
                <SkeletonOrComponent className={skeleton} rangeWidth={[80, 120]}>
                  {paymentDetails && !this.verifyingPayment && (
                    <>
                      <Typography className={value}>
                        {paymentDetails.status.charAt(0).toUpperCase() +
                          paymentDetails.status.slice(1) || ''}
                      </Typography>
                      <Circle className={circleDot} color="primary" />
                    </>
                  )}
                </SkeletonOrComponent>
              </DD.Value>
              <DD.Label>Status</DD.Label>
            </DD.Item>
          </DD.Row>
          <DD.Row>
            <DD.Item className={fullWidth}>
              <DD.Value>
                <SkeletonOrComponent className={skeleton} rangeWidth={[80, 120]}>
                  {paymentDetails?.transactionId || 'N/A'}
                </SkeletonOrComponent>
              </DD.Value>
              <DD.Label>Transaction ID</DD.Label>
            </DD.Item>
          </DD.Row>
          <DD.Row>
            <DD.Value>
              <Box width={'100%'}>
                {this.renderDistributions && this.paymentDetails && (
                  <Box display="flex" flexDirection="row" justifyContent="space-between">
                    <Box display="flex" justifyContent="flex-start">
                      <Typography className={classes.label}>Total fee</Typography>
                    </Box>
                    <Box display="flex" justifyContent="flex-end" alignSelf="flex-end">
                      <SkeletonOrComponent className={classes.skeleton} rangeWidth={[80, 120]}>
                        {paymentDetails && (
                          <Typography className={classes.value}>
                            {this.paymentDetails.fees
                              ? numericStringToUsd(this.getTotalFee())
                              : 'N/A'}
                          </Typography>
                        )}
                      </SkeletonOrComponent>
                    </Box>
                  </Box>
                )}
                {this.renderDistributions &&
                  this.paymentDetails?.fees &&
                  this.paymentDetails.fees!.map((fee: PaymentFee) => {
                    const distributionRule = fee.distributionRule;
                    if (distributionRule)
                      return (
                        <Box
                          display="flex"
                          flexDirection="row"
                          justifyContent="space-between"
                          mt={2}>
                          <Box display="flex" justifyContent="flex-start">
                            <Typography className={classes.feeLabel}>
                              {`${distributionRule.partner!.name} fee`}
                            </Typography>
                          </Box>
                          <Box display="flex" justifyContent="flex-end" alignSelf="flex-end">
                            <SkeletonOrComponent
                              className={classes.skeleton}
                              rangeWidth={[80, 120]}>
                              {paymentDetails && (
                                <>
                                  <Tooltip
                                    classes={{ tooltip: this.props.classes.tooltip }}
                                    interactive={true}
                                    title={this.getFee(
                                      distributionRule!.feeAmount,
                                      distributionRule!.feePercent,
                                    )}>
                                    <Typography className={classes.value}>
                                      {fee.fee ? numericStringToUsd(fee.fee) : 'N/A'}
                                    </Typography>
                                  </Tooltip>
                                </>
                              )}
                            </SkeletonOrComponent>
                          </Box>
                        </Box>
                      );
                  })}
              </Box>
            </DD.Value>
          </DD.Row>
        </DD.Content>
        {!hideActions && (
          <DD.Actions>
            {paymentDetails && (
              <>
                {canForceConfirm && (
                  <Box mb={2}>
                    <DD.ApplyButton onClick={this.forceConfirm}>force confirm</DD.ApplyButton>
                  </Box>
                )}
                {paymentDetails.status !== PaymentStatus.CAPTURED && (
                  <Box mb={1}>
                    <DD.ApplyButton onClick={this.forceVerification}>
                      revalidate payment
                    </DD.ApplyButton>
                  </Box>
                )}
                <Box mt={1}>
                  <DD.ApplyButton
                    disabled={this.paymentDetails?.processor.includes('developer_app')}
                    onClick={this.toggleRefundModal}>
                    issue refund for {numericStringToUsd(paymentDetails.chargeAmount)}
                  </DD.ApplyButton>
                </Box>
              </>
            )}
          </DD.Actions>
        )}
        {this.renderRefundModalForm()}
      </DD>
    );
  }
}

export default withStyles(styles)(PaymentDetailsDrawer);
