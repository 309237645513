import { Box, Theme, Typography, useTheme } from '@mui/material';
import Dialog from 'components/Dialog/Dialog';
import DP from 'components/DashPanel';
import * as models from 'models';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/pro-solid-svg-icons';
import { ELicenseBillingGeneralDialog, ILicenseBillingGeneralDialogProps } from '../models';

interface ILicenseDialog extends ILicenseBillingGeneralDialogProps {
  billingEntity: models.IBillingEntity | null;
}

const BillingGroupLicensesDialog = ({
  open,
  type,
  billingEntity,
  onCancel,
  TransitionProps,
}: ILicenseDialog) => {
  const theme = useTheme();

  if (!billingEntity) return null;

  const { name, code } = billingEntity;

  const title = (
    <>
      {name || code}&nbsp;
      {name && (
        <Typography
          component={'span'}
          fontSize={'inherit'}
          sx={{ color: theme.typography.body2.color }}>
          ({code})
        </Typography>
      )}
    </>
  );

  const _open = open && type === ELicenseBillingGeneralDialog.BILLING_GROUP_LICENSES;

  return (
    <Dialog
      open={_open}
      title={title}
      content={
        <Box>
          <Typography variant="body2">Licenses:</Typography>

          <DP.List>
            {billingEntity.licenses?.map((license, index) => {
              const {
                location: { name },
                product: { name: pName },
              } = license;
              return (
                <DP.ListItem
                  key={index}
                  icon={<LicenseIcon theme={theme} license={license} />}
                  primary={name}
                  secondary={pName}
                  disableDivider
                />
              );
            })}
          </DP.List>
        </Box>
      }
      cancelActionName="Close"
      onCancel={onCancel}
      onClose={onCancel}
      TransitionProps={{
        onExited: (node) => TransitionProps?.onExited && TransitionProps.onExited(node),
      }}
    />
  );
};

const LicenseIcon = ({ theme, license }: { theme: Theme; license: models.License }) => {
  const { status } = license;
  let color: 'green' | 'red' | 'grey' = 'green';
  if (status === 'inactive') {
    color = 'red';
  } else if (status === 'canceled') {
    color = 'grey';
  }

  const colors = {
    green: theme.palette.status.green,
    red: theme.palette.status.red,
    grey: theme.palette.status.grey,
  };

  return (
    <FontAwesomeIcon
      icon={faCircle}
      fontSize={18}
      style={{ color: colors[color as keyof typeof colors] }}
    />
  );
};

export default BillingGroupLicensesDialog;
