import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from '@material-ui/core';
import Button from 'components/Button/Dialog/Button';
import React from 'react';
import useStyles from './styles';

export interface IAlertProps {
  open: boolean;
  acceptButton: string;
  rejectButton: string;
  onAccept: () => void;
  onReject: () => void;
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false;
  title?: string;
  message?: string;
  buttonsPosition?: 'flex-start' | 'center' | 'flex-end' | 'space-between' | 'space-evenly';
}

export function Alert(props: IAlertProps) {
  const {
    open,
    acceptButton,
    rejectButton,
    title,
    message,
    buttonsPosition,
    maxWidth,
    onAccept,
    onReject,
  } = props;

  const { dialogActions, dialogContent, dialogTitle } = useStyles({
    buttonsPosition,
  });

  return (
    <Dialog
      open={open}
      maxWidth={maxWidth ? maxWidth : 'lg'}
      style={{
        textAlign: 'center',
      }}>
      {title && (
        <DialogTitle className={dialogTitle} disableTypography id="alert-dialog-title">
          <Typography align="left" style={{ fontSize: 28 }}>
            {title}
          </Typography>
        </DialogTitle>
      )}
      {message && (
        <DialogContent className={dialogContent}>
          <DialogContentText align="left" id="alert-dialog-description">
            {message}
          </DialogContentText>
        </DialogContent>
      )}
      <DialogActions className={dialogActions}>
        <Button variant="outlined" onClick={onReject}>
          {rejectButton}
        </Button>
        <Button variant="contained" onClick={onAccept} autoFocus>
          {acceptButton}
        </Button>
      </DialogActions>{' '}
    </Dialog>
  );
}
