import { action, computed, makeObservable, observable } from 'mobx';
import BaseStore from './BaseStore';
import RootStore from './RootStore';

export default class GlobalDialogStore extends BaseStore {
  constructor(props: RootStore) {
    super(props);
    makeObservable(this);
  }

  @observable showMfaDialog = false;

  @computed public get openMfaDialog() {
    const { showMfaStep } = this.rootStore.mfaStore;

    return showMfaStep && this.showMfaDialog;
  }

  @action.bound setOpenMfaDialog() {
    this.showMfaDialog = true;
  }

  @action.bound setCloseMfaDialog() {
    this.showMfaDialog = false;
  }

  public init(): void {}
}

export interface WithGlobalDialogStore {
  globalDialogStore?: GlobalDialogStore;
}
