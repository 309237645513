import { action, autorun, makeObservable, observable, reaction } from 'mobx';
import BaseStore from './BaseStore';
import RootStore from './RootStore';
import { EManagerPermission } from 'types';
import { Scope } from 'stores';
import Api, { getErrorMsg } from 'api';
import * as LocalStorage from 'services/localStorage';

const STORE_NAME = 'managerPermissions';

export default class ManagerPermissionsStore extends BaseStore {
  constructor(props: RootStore) {
    super(props);
    makeObservable(this);
  }

  @observable managerPermissions: string[] = [];

  // accountId of currently selected location in scope switcher
  @observable accountId?: number;

  /** Checks whether the user has the provided permission */
  public hasPermission(p: EManagerPermission) {
    return Boolean(this.managerPermissions?.includes(p));
  }

  @action.bound async getManagerPermissions(locationId: number) {
    const currentLocation = this.rootStore.userStore.managedLocations.find((location) => {
      return location.id === locationId;
    });

    //TODO: handle this better
    const userId = this.rootStore.userStore.user?.id;
    const accountId = currentLocation?.accountId;
    if (!accountId || !userId) {
      this.rootStore.toastStore.error('Could not get permissions');
      return;
    }

    this.accountId = accountId;

    try {
      const { data } = await Api.core.getManagerPermissionsForAccountUser(userId, accountId);
      this.managerPermissions = data?.data || [];
    } catch (error) {
      this.rootStore.toastStore.error(getErrorMsg(error));
    }
  }

  public init() {
    const managerPermissionsStore = LocalStorage.get(`${STORE_NAME}`) as {
      accountId?: number;
      managerPermissions?: string[];
    };
    this.accountId = managerPermissionsStore?.accountId;
    this.managerPermissions = managerPermissionsStore?.managerPermissions || [];

    reaction(
      () => this.rootStore.userStore.scope,
      (scope: Scope) => {
        if (scope.kind === 'manager') {
          this.getManagerPermissions(scope.locationId);
        }
      },
    );

    autorun(() =>
      LocalStorage.set(`${STORE_NAME}`, {
        accountId: this.accountId,
        managerPermissions: this.managerPermissions,
      }),
    );

    const scope = this.rootStore.userStore.scope;
    if (scope.kind === 'manager') {
      this.getManagerPermissions(scope.locationId);
    }
  }
}

export interface WithManagerPermissionsStore {
  managerPermissionsStore?: ManagerPermissionsStore;
}
