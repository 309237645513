import { IconDefinition } from '@fortawesome/pro-regular-svg-icons';
import { UserScopes } from 'stores';
import { ACL, EManagerPermission } from 'types/permissions';

export enum MenuGroup {
  MANAGE = 'Manage',
  MARKETING = 'Marketing',
  TIPPING = 'Tipping',
  FINANCIAL = 'Financial',
  ANALYTICS = 'Analytics',
  TOOLS = 'Tools',
}

export enum MenuItemType {
  GROUP = 'group',
  PAGE = 'page',
}

export interface IMenuItem {
  type: MenuItemType.PAGE;
  scopes?: Array<UserScopes>;
  roles?: Array<ACL>;
  permissions?: Array<EManagerPermission>;
  hide?: boolean;
  icon: IconDefinition;
  to: string;
  paths?: Array<string>; // paths for which the menu item should stay active
  label: string;
}

export interface IMenuGroup {
  type: MenuItemType.GROUP;
  hide?: boolean;
  scopes?: Array<UserScopes>;
  label: MenuGroup;
  icon: IconDefinition;
  items: Array<IMenuItem>;
}

export type MenuItems = Array<IMenuItem | IMenuGroup>;
