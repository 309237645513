import { Location } from './Location';
import { Account } from './Account';

/** Roles that can be invited */
export type Role = 'talent' | 'manager';

/** Represents a single Invitation item */
export interface Invitation {
  id: number;
  email: string;
  status: UserInvitationType;
  isManager: boolean;
  isTalent: boolean;
  expiresAt: string;
  location?: Location;
  account: Account;
  locations?: Pick<Location, 'id' | 'name'>[];
}

export enum UserInvitationType {
  SENT = 'sent',
  REJECTED = 'rejected',
  ACCEPTED = 'accepted',
  REVOKED = 'revoked'
}
