import { Theme, createStyles } from '@material-ui/core';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default (theme: Theme) =>
  createStyles({
    root: {},
    center: {
      justifyContent: 'center',
      textAlign: 'center',
    },
    row: {
      flexDirection: 'row',
    },
    dialogWidth: {
      [theme.breakpoints.up('sm')]: {
        minWidth: '576px',
      },
    },
    dialogBalanceDetail: {
      display: 'flex',
      '& span:first-of-type': {
        marginLeft: theme.spacing(1),
      },
      '& span:nth-of-type(2)': {
        marginLeft: 'auto',
      },
    },
    positiveBalance: {
      color: theme.palette.primary.main,
    },
    negativeBalance: {
      color: theme.palette.error.main,
    },
    dialogActions: {
      padding: 0,
      marginTop: theme.spacing(4),
    },
    selectTitle: {
      color: theme.palette.common.black,
    },
    tipAmount: {
      fontWeight: 700,
    },
  });
