import React from 'react';
import { observable, action, computed, makeObservable } from 'mobx';
import { observer } from 'mobx-react';
import { WithStyles, withStyles } from '@material-ui/core/styles';
import { Box, Popover } from '@material-ui/core';

import styles from './styles';

/** Here we define what kind of props this component takes */
type CustomMarkerProps = WithStyles<typeof styles> & {
  tooltip?: React.ReactNode;
  fixedPos?: {
    width: number | string;
    height: number | string;
  };
};

export interface MarkerProps {
  lat?: number;
  lng?: number;
}

@observer
class CustomMarker extends React.Component<CustomMarkerProps> {
  constructor(props: CustomMarkerProps) {
    super(props);
    makeObservable(this);
  }

  @observable public mouseIsOver = false;
  @observable public anchorEl: Element | null = null;

  @observable public canHide = true;

  @computed get isOpen(): boolean {
    return Boolean(this.anchorEl);
  }

  @action handlePopoverOpen = (event: React.MouseEvent<Element, MouseEvent>) => {
    this.anchorEl = event.currentTarget;
    this.canHide = false;
  };

  @action handlePopoverClose = () => {
    this.anchorEl = null;
  };

  propToCss = (t: number | string) => {
    if (typeof t === 'number') {
      return `${t}px`;
    } else {
      return t;
    }
  };

  getFixedStyle = () => {
    const { fixedPos } = this.props;

    const fixedStyle = fixedPos
      ? {
          transform: `translate(${this.propToCss(fixedPos.width)}, ${this.propToCss(
            fixedPos.height,
          )})`,
        }
      : {};

    return fixedStyle;
  };

  render() {
    const { classes, tooltip, children } = this.props;
    const id = this.isOpen ? 'mouse-over-popover' : undefined;

    return (
      <Box style={this.getFixedStyle()}>
        <Box
          onMouseLeave={this.handlePopoverClose}
          onMouseEnter={this.handlePopoverOpen}
          aria-describedby={id}>
          {children}
        </Box>

        {tooltip && (
          <Popover
            id="mouse-over-popover"
            open={this.isOpen}
            anchorEl={this.anchorEl}
            className={classes.popover}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            onClose={this.handlePopoverClose}
            disableRestoreFocus>
            <Box onMouseLeave={this.handlePopoverClose}>{tooltip}</Box>
          </Popover>
        )}
      </Box>
    );
  }
}

export default withStyles(styles)(CustomMarker);
