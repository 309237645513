export enum EmployeeStatus {
  // active = 'active',
  inactive = 'inactive',
}

export interface EmployeeInfo {
  accountId: number;
  employeeId: string;
  id: number;
  status: EmployeeStatus;
  userId: number;
}
