import { Box, Tooltip } from '@material-ui/core';
import { observable, makeObservable } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';
import { WithStyles, withStyles } from '@material-ui/core/styles';
import styles from './styles';

type TooltipProps = WithStyles<typeof styles> & { id: any };

@observer
class OwerflowTooltip extends React.Component<TooltipProps> {
  constructor(props: any) {
    super(props);
    makeObservable(this);
    window.addEventListener('resize', this.handleResize);
  }


  @observable isOverflown = false;
  @observable element: HTMLElement | null = null;

  componentDidMount() {
    this.element = document.getElementById(`${this.props.id}`);
    if (this.checkIfOverflown()) this.isOverflown = true;
  }

  handleResize = () => {
    this.element = document.getElementById(`${this.props.id}`);
    this.isOverflown = this.checkIfOverflown();
  };

  checkIfOverflown() {
    if (this.element) {
      return (
        this.element.scrollHeight > this.element.clientHeight ||
        this.element.scrollWidth > this.element.clientWidth
      );
    }
    return false;
  }

  render() {
    return this.isOverflown ? (
      <Tooltip
        title={this.props.children || ''}
        interactive
        className={this.props.classes.root}
        classes={{ tooltip: this.props.classes.tooltip }}
      >
        <Box id={this.props.id}>{this.props.children}</Box>
      </Tooltip>
    ) : (
      <Box id={this.props.id}>{this.props.children}</Box>
    );
  }
}

export default withStyles(styles)(OwerflowTooltip);
