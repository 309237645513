import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { observer } from 'mobx-react';
import { observable, action, makeObservable, computed, flow } from 'mobx';
import { Box, Grid, Link, Typography } from '@material-ui/core';
import { WithStyles, withStyles } from '@material-ui/core/styles';
import { Link as RouterLink } from 'react-router-dom';
import { inject, WithUserStore, WithToastStore } from 'stores';
import DashboardLayout from 'containers/DashboardLayout';
import styles from './styles';
import { adaptForDataGridPro, setTitle } from 'services';
import FilterBar, { Filter } from 'components/FilterBar';
import DataGridInfiniteScroll from 'components/DataGridInfiniteScroll';
import Api, { getErrorMsg, RequestMetaData } from 'api';
import { paths } from 'routes';
import Title from 'components/Title';
import * as models from 'models';
import { HorizontalStatCard } from 'containers/UserDetails';
import {
  LocalShippingOutlined,
  MarkunreadMailboxOutlined,
  InventoryOutlined,
  ErrorOutlineOutlined,
} from '@mui/icons-material';
import ChipStatusTag, { ChipStatusColors } from 'components/ChipStatusTag';

const PAGE_TITLE = 'Shipments For';
interface DevicesShipmentsProps
  extends WithStyles<typeof styles>,
    WithUserStore,
    RouteComponentProps<{ hardwareId: string }>,
    WithToastStore {}

interface IBadgeConfig {
  [key: string]: string;
}

const badgeConfig: IBadgeConfig = {
  CAPTURED: ChipStatusColors.GREEN,
  CREATED: ChipStatusColors.GREEN,
  DUPLICATE: ChipStatusColors.YELLOW,
  REFUNDED: ChipStatusColors.YELLOW,
  VERIFYING: ChipStatusColors.YELLOW,
  FAILED: ChipStatusColors.RED,
  CANCELLED: ChipStatusColors.RED,
  VOID: ChipStatusColors.GREY,
  NOT_FOUND: ChipStatusColors.GREY,
};

/**
 * The container for the inventory shipments section.
 */
@inject('userStore', 'toastStore')
@observer
class DevicesShipments extends React.Component<DevicesShipmentsProps> {
  constructor(props: DevicesShipmentsProps) {
    super(props);
    makeObservable(this);
  }

  /** Actives filters */
  @observable private activeFilters: Record<string, unknown> = {};
  /** Whether the current hardware are loading */
  @observable public loading = true;
  /** Hardware item */
  @observable public hardware?: models.Hardware;
  /** Shipments status stats*/
  @observable public shipmentStatusStats?: models.ShipmentsStatusStats;
  /** Payout stats if there is no running initiation */
  @observable public totalSum = 0;
  /** Get hardware id from props */
  @computed public get hardwareId(): number {
    return parseInt(this.props.match.params.hardwareId);
  }

  @action.bound private annotateInventoryShipments = (
    inventoryShipment: models.InventoryShipment,
  ) => {
    return {
      id: inventoryShipment.id,
      trackingNumber: inventoryShipment.shipment?.trackingNumber,
      locationName: inventoryShipment.shipment?.location?.name,
      locationId: inventoryShipment.shipment?.location?.id,
      address: inventoryShipment.shipment?.address,
      city: inventoryShipment.shipment?.city,
      zipState: `${inventoryShipment.shipment?.zip} / ${inventoryShipment.shipment?.state}`,
      status: inventoryShipment.shipment?.status.toUpperCase(),
    };
  };

  @action.bound public fetchInventoryShipments = adaptForDataGridPro(
    async (rmd: RequestMetaData) => {
      const fetchGetInventoryShipments = () =>
        Api.fulfillment.getHardwareShipments({
          ...rmd,
          filters: {
            hardwareId: this.hardwareId,
            ...this.activeFilters,
          },
        });

      return await fetchGetInventoryShipments();
    },
    this.annotateInventoryShipments,
  );

  @action.bound public getHardware = flow(function* (this: DevicesShipments) {
    try {
      this.loading = true;
      const resp = yield Api.fulfillment.getHardware(this.hardwareId);
      this.hardware = resp.data.data;
    } catch (e: unknown) {
      this.props.toastStore!.error(getErrorMsg(e));
    } finally {
      this.loading = false;
    }
  });

  @action.bound public getShipmentsStatusStats = flow(function* (this: DevicesShipments) {
    try {
      this.loading = true;
      const resp = yield Api.fulfillment.getShipmentsStatusStats({
        hardwareId: this.hardwareId,
      });

      this.shipmentStatusStats = {
        created: resp.data.data.find((e: { status: string }) => e.status === 'created')
          ? resp.data.data.find((e: { status: string }) => e.status === 'created')['count']
          : '0',
        inTransit: resp.data.data.find((e: { status: string }) => e.status === 'in_transit')
          ? resp.data.data.find((e: { status: string }) => e.status === 'in_transit')['count']
          : '0',
        delivered: resp.data.data.find((e: { status: string }) => e.status === 'delivered')
          ? resp.data.data.find((e: { status: string }) => e.status === 'delivered')['count']
          : '0',
        exeption: resp.data.data.find((e: { status: string }) => e.status === 'exeption')
          ? resp.data.data.find((e: { status: string }) => e.status === 'exeption')['count']
          : '0',
      };
    } catch (e: unknown) {
      this.props.toastStore!.error(getErrorMsg(e));
    } finally {
      this.loading = false;
    }
  });

  /** Renders if last initiation is completed or stopped */
  renderStatsPanel() {
    const data = {
      created: this.shipmentStatusStats ? this.shipmentStatusStats.created : 0,
      inTransit: this.shipmentStatusStats ? this.shipmentStatusStats.inTransit : 0,
      delivered: this.shipmentStatusStats ? this.shipmentStatusStats.delivered : 0,
      exeption: this.shipmentStatusStats ? this.shipmentStatusStats.exeption : 0,
    };
    return (
      <Grid container spacing={3}>
        <Grid item xs={12} md={6} lg={3}>
          <HorizontalStatCard duration={1} icon={InventoryOutlined} title={'Created'}>
            {data.created ? data.created : 0}
          </HorizontalStatCard>
        </Grid>
        <Grid item xs={12} md={6} lg={3}>
          <HorizontalStatCard
            color="yellow"
            duration={1}
            icon={LocalShippingOutlined}
            title={'In Transit'}>
            {data.inTransit ? data.inTransit : 0}
          </HorizontalStatCard>
        </Grid>
        <Grid item xs={12} md={6} lg={3}>
          <HorizontalStatCard
            color="secondary"
            duration={1}
            icon={MarkunreadMailboxOutlined}
            title={'Delivered'}>
            {data.delivered ? data.delivered : 0}
          </HorizontalStatCard>
        </Grid>
        <Grid item xs={12} md={6} lg={3}>
          <HorizontalStatCard duration={1} icon={ErrorOutlineOutlined} title={'Exception'}>
            {data.exeption ? data.exeption : 0}
          </HorizontalStatCard>
        </Grid>
      </Grid>
    );
  }

  renderCellLocation = ({ row, value }: any) => {
    return (
      <Link component={RouterLink} to={paths.locationDetails(row.locationId)}>
        {value}
      </Link>
    );
  };

  renderCellTrackingNumber = ({ row, value }: any) => {
    return (
      <Link component={RouterLink} to={paths.devicesShipments(row.id).root()}>
        {value}
      </Link>
    );
  };

  renderCellStatus = ({ value, row }: any) => {
    const background = badgeConfig[value.toUpperCase() as keyof IBadgeConfig] as ChipStatusColors;
    return <ChipStatusTag label={value} color={background} />;
  };

  enumKeys<O extends object, K extends keyof O = keyof O>(obj: O): K[] {
    return Object.keys(obj).filter((k) => Number.isNaN(+k)) as K[];
  }

  gridColumns = [
    { headerName: 'Id', field: 'id', maxWidth: 10 },
    {
      headerName: 'Tracking',
      field: 'trackingNumber',
      minWidth: 200,
      flex: 1,
      renderCell: this.renderCellTrackingNumber,
    },
    {
      headerName: 'Location',
      field: 'locationName',
      minWidth: 200,
      flex: 1,
      renderCell: this.renderCellLocation,
    },
    { headerName: 'Address', field: 'address', minWidth: 50, flex: 1 },
    { headerName: 'City', field: 'city', minWidth: 50, flex: 1 },
    { headerName: 'Zip / State', field: 'zipState', minWidth: 50, flex: 1 },
    {
      headerName: 'Status',
      field: 'status',
      minWidth: 50,
      flex: 1,
      renderCell: this.renderCellStatus,
    },
  ];

  filters: Filter[] = [
    { display: 'Tracking', id: 'trackingNumber', label: 'Contains', type: 'text' },
    { display: 'Location', id: 'location', label: 'Contains', type: 'text' },
    { display: 'Address', id: 'address', label: 'Contains', type: 'text' },
    { display: 'City', id: 'city', label: 'Contains', type: 'text' },
    {
      display: 'Status',
      id: 'status',
      label: 'Status',
      type: 'select',
      items: [
        { label: 'CREATED', value: 'created' },
        { label: 'IN TRANSIT', value: 'in_transit' },
        { label: 'DELIVERED', value: 'delivered' },
        { label: 'EXEPTION', value: 'exception' },
      ],
    },
  ];

  async componentDidMount() {
    setTitle(PAGE_TITLE, { noSuffix: false });
    this.getHardware();
    this.fetchInventoryShipments({ filters: this.activeFilters });
    this.getShipmentsStatusStats();
  }

  render() {
    return (
      <DashboardLayout>
        <Title
          mb={3}
          size={'large'}
          subtitle={`${this.hardware?.manufacturer} - ${this.hardware?.model}`}>
          Shipments For:
        </Title>
        {this.renderStatsPanel()}
        <Box mt={3}>
          <FilterBar
            filters={this.filters}
            onChange={(filters: Record<string, unknown>) => {
              this.activeFilters = filters;
            }}
          />
          <Box mb={3} mt={1}>
            <Typography style={{ fontSize: 28 }} variant={'body1'}>
              Shipments ({this.totalSum})
            </Typography>
          </Box>
          <DataGridInfiniteScroll
            columns={this.gridColumns}
            fetch={this.fetchInventoryShipments}
            refetchKey={this.activeFilters}
            pathname={this.props.location.pathname}
          />
        </Box>
      </DashboardLayout>
    );
  }
}

export default withStyles(styles)(DevicesShipments);
