import Dialog from 'components/Dialog/Dialog';
import * as models from 'models';
import { ELicenseBillingGeneralDialog, ILicenseBillingGeneralDialogProps } from '../models';
import { Typography } from '@material-ui/core';
import { useStores } from 'containers/App/App';
import { getErrorMsg } from 'api';
import Api from 'api';
import { useState } from 'react';

interface ActivateLicenseDialogProps extends ILicenseBillingGeneralDialogProps {
  license: models.License | null;
  onActivateLicense: VoidFunction;
}

const ActivateLicenseDialog = ({
  open,
  title,
  type,
  license,
  onCancel,
  onActivateLicense,
}: ActivateLicenseDialogProps) => {
  const { toastStore } = useStores();
  const [loading, setLoading] = useState(false);
  const _open = open && type === ELicenseBillingGeneralDialog.ACTIVATE_LICENSE;

  const confirmActivateLicense = async () => {
    if (!license) return toastStore.error('License not found');

    try {
      setLoading(true);
      await Api.billing.activateLicense(license.id);
      onActivateLicense();
      onCancel();
      toastStore.success('License successfully reactivated');
    } catch (err: any) {
      toastStore!.error(getErrorMsg(err));
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog
      open={_open}
      loading={loading}
      title={title}
      content={
        <Typography variant="body2">{`Are you sure you want to activate the license for ${
          license?.location?.name || 'location'
        }?`}</Typography>
      }
      onConfirm={confirmActivateLicense}
      onCancel={onCancel}
      onClose={onCancel}
    />
  );
};

export default ActivateLicenseDialog;
