import React from 'react';
import { observer } from 'mobx-react';
import { action, computed, observable, makeObservable } from 'mobx';

import { inject, WithToastStore, WithUserStore } from 'stores';

import {
  Box,
  Button,
  Checkbox,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Drawer,
  FormControlLabel,
  FormGroup,
  IconButton,
  MenuItem,
  Typography,
} from '@material-ui/core';

import { WithStyles, withStyles } from '@material-ui/core/styles';

import styles from './styles';
import { Close, ContentCopy, InformationOutline, Printer } from 'mdi-material-ui';
import { DrawerType } from 'types';
import DP from 'components/DashPanel';
import QRCode from 'qrcode.react';

import { Industry, Pool, TippingMode } from 'models';
import { humanize } from '../../utils/helper';
import MaskedInput from 'react-text-mask';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import config from 'config';
import { paths } from 'routes';
import { WithRouterStore } from '../../stores/RouterStore';
import OutlinedInput from 'components/Input/OutlinedInput';

type ObservableVariables =
  | 'lowAmount'
  | 'mediumAmount'
  | 'highAmount'
  | 'name'
  | 'customerInstructions';

interface PoolsDrawerProps
  extends WithStyles<typeof styles>,
    WithRouterStore,
    WithToastStore,
    WithUserStore {
  isOpen: boolean;
  onClose: () => void;
  createPool: (pool: any) => void;
  updatePool: (pid: number, pool: any) => void;
  type: DrawerType;
  pool?: Pool;
  industry?: Industry;
}

@inject('userStore', 'toastStore', 'routerStore')
@observer
class PoolsDrawer extends React.Component<PoolsDrawerProps> {
  constructor(props: PoolsDrawerProps) {
    super(props);
    makeObservable(this);
  }
  /** Some settings are only editable by admins */
  @observable private isAdmin = this.props.userStore!.isAdmin;
  @observable private name = '';
  @observable private tippingMode?: number | string = '';
  @observable private customerInstructions = '';
  @observable private lowAmount = '';
  @observable private mediumAmount = '';
  @observable private highAmount = '';

  @observable private mandatoryInfo: Record<string, boolean> = {
    requiresCustomerName: false,
    requiresNote: false,
    requiresReview: false,
    requiresRoomNumber: false,
  };

  @observable private pool?: Pool;

  @computed get isFormValid(): boolean {
    let formValid = false;

    if (
      this.name &&
      this.name.length > 0 &&
      typeof this.tippingMode === 'number' &&
      parseInt(this.lowAmount) > 0 &&
      parseInt(this.mediumAmount) > 0 &&
      parseInt(this.highAmount) > 0
    ) {
      formValid = true;
    }
    return formValid;
  }

  @computed get amountValid(): boolean {
    return (
      parseInt(this.lowAmount) <= 0 ||
      parseInt(this.mediumAmount) <= 0 ||
      parseInt(this.highAmount) <= 0
    );
  }

  @computed get tippingModeIndex(): number | string {
    let index: number | string = '';
    if (this.props.pool) {
      const {
        pool: { tippingMode },
      } = this.props;
      index = Object.values(TippingMode).findIndex((el) => el === tippingMode);
    }
    return index;
  }

  @computed get tippingModeText(): string {
    return `
    ONCE (customer will tip once for a single service).
    PER_NIGHT (customer will be asked to enter a number of nights and tip amounts will be multiplied). 
    `;
  }

  @computed get additionalInfoText(): string {
    return `Text that will be presented to the customers when they select this pool.`;
  }

  @computed get qrLink(): string {
    return `${config.tippyGo.baseUrl}/P${this.props.pool!.uid}`;
  }

  @action.bound private updatePool(key: any, value: unknown) {
    if (!this.pool) return;
    // @ts-ignore
    this.pool[key] = value;
  }

  @action.bound private init() {
    if (!this.props.pool) return;
    const { pool } = this.props;
    this.pool = { ...pool };
    this.name = pool.name;
    this.tippingMode = this.tippingModeIndex;
    this.customerInstructions = pool.customerInstructions || '';
    this.lowAmount = pool.lowAmount.toString();
    this.mediumAmount = pool.mediumAmount.toString();
    this.highAmount = pool.highAmount.toString();
    this.mandatoryInfo = {
      requiresCustomerName: pool.requiresCustomerName,
      requiresNote: pool.requiresNote,
      requiresReview: pool.requiresReview,
      requiresRoomNumber: pool.requiresRoomNumber,
    };
  }

  @action.bound private resetData() {
    this.pool = undefined;
    this.name = '';
    this.customerInstructions = '';
    this.tippingMode = '';
    this.lowAmount = '';
    this.mediumAmount = '';
    this.highAmount = '';
    this.mandatoryInfo = {
      requiresCustomerName: false,
      requiresNote: false,
      requiresReview: false,
      requiresRoomNumber: false,
    };
  }
  @action.bound private handleInputChange =
    (ov: ObservableVariables) =>
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      let value = event.target.value;
      if (ov === 'lowAmount' || ov === 'mediumAmount' || ov === 'highAmount') {
        // Remove commas and dollar sign from currency masked input
        value = value.replace(/[,|$]+/g, '');
        this[ov] = value;
        this.updatePool(ov, parseInt(value));
      } else {
        this[ov] = value;
        this.updatePool(ov, value);
      }
    };

  @action.bound private onSubmit = () => {
    const { type } = this.props;

    if (type === DrawerType.ADD) {
      const { requiresCustomerName, requiresNote, requiresReview, requiresRoomNumber } =
        this.mandatoryInfo;

      const pool = {
        name: this.name,
        tippingMode:
          typeof this.tippingMode === 'number' && Object.values(TippingMode)[this.tippingMode],
        requiresCustomerName,
        requiresNote,
        requiresReview,
        requiresRoomNumber,
        lowAmount: parseInt(this.lowAmount),
        mediumAmount: parseInt(this.mediumAmount),
        highAmount: parseInt(this.highAmount),
        customerInstructions: this.customerInstructions,
      };
      this.props.createPool(pool);
    }

    if (type === DrawerType.EDIT && this.pool) {
      this.props.updatePool(this.pool.id, this.pool);
    }
    this.resetData();
  };

  componentDidMount() {
    if (this.props.pool) {
      this.init();
    }
  }

  componentDidUpdate() {
    if (this.props.pool && !this.pool) {
      this.init();
    }
  }

  closeDrawer = () => {
    this.resetData();
    this.props.onClose();
  };

  onSelectChangeHandler = (event: React.ChangeEvent<{ value: unknown }>) => {
    const value = event.target.value as unknown as number;
    this.tippingMode = value;
    this.updatePool('tippingMode', Object.values(TippingMode)[value]);
  };

  onClickHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.mandatoryInfo[event.target.name] = event.target.checked;
    this.updatePool(event.target.name, event.target.checked);
  };

  @computed get link(): string {
    const code = this.props.pool!.uid;
    const typeAnnotation = 'P';
    const link = `${typeAnnotation}${code}`;
    return `${config.tippyGo.baseUrl}/${link}`;
  }

  handleCopyClick = () => {
    navigator.clipboard.writeText(this.link).then();
    this.props.toastStore!.push({ type: 'info', message: 'Copied' });
  };

  handlePrintClick = () => {
    this.props.routerStore!.history.push(this.printPageLink());
  };

  renderTitle(type: DrawerType): string {
    if (type === DrawerType.ADD) {
      return 'Add Pool';
    } else if (type === DrawerType.EDIT) {
      return 'Edit Pool';
    } else return 'Pool QR Code';
  }

  renderMaskedCurrencyInput(props: any) {
    const { inputRef, ...other } = props;
    return (
      <MaskedInput
        {...other}
        ref={(ref) => inputRef(ref ? ref.inputElement : null)}
        mask={createNumberMask({ prefix: '', suffix: '' })}
        showMask={false}
      />
    );
  }

  renderPoolForm() {
    const { classes } = this.props;
    return (
      <>
        <OutlinedInput
          label={'Name'}
          fullWidth
          value={this.name}
          onChange={this.handleInputChange('name')}
        />
        <Box display="flex" alignItems="center" mt={3} mb={3}>
          {/* <FormControl fullWidth>
            <InputLabel id="way-to-tip-label">Way to tip</InputLabel>
            <Select
              labelId="way-to-tip-label"
              id="way-to-tip-select"
              value={this.tippingMode && this.tippingMode}
              onChange={this.onSelectChangeHandler}>
              {Object.entries(TippingMode).map(([key, value], i) => (
                <MenuItem key={`${value}-${i}`} value={i}>
                  {humanize(key)}
                </MenuItem>
              ))}
            </Select>
          </FormControl> */}
          <OutlinedInput
            fullWidth
            id="way-to-tip-select"
            label={'Way to tip'}
            value={this.tippingMode && this.tippingMode}
            onChange={this.onSelectChangeHandler}
            select>
            {Object.entries(TippingMode).map(([key, value], i) => (
              <MenuItem key={`${value}-${i}`} value={i}>
                {humanize(key)}
              </MenuItem>
            ))}
          </OutlinedInput>
          <Box className={classes.buttonMargin}>
            <DP.IconButton
              secondary
              submit
              icon={InformationOutline}
              tooltip={this.tippingModeText}
            />
          </Box>
        </Box>
        <Typography variant="body2">Mandatory customer input</Typography>
        <Box mb={2} mt={1} display="flex" style={{ gap: '1.5rem' }}>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={this.mandatoryInfo.requiresCustomerName}
                  onChange={this.onClickHandler}
                  name="requiresCustomerName"
                  color="primary"
                />
              }
              label={<Typography variant="body2">Customer name</Typography>}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={this.mandatoryInfo.requiresRoomNumber}
                  onChange={this.onClickHandler}
                  name="requiresRoomNumber"
                  color="primary"
                />
              }
              label={<Typography variant="body2">Room number</Typography>}
            />
          </FormGroup>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={this.mandatoryInfo.requiresReview}
                  onChange={this.onClickHandler}
                  name="requiresReview"
                  color="primary"
                />
              }
              label={<Typography variant="body2">Review</Typography>}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={this.mandatoryInfo.requiresNote}
                  onChange={this.onClickHandler}
                  name="requiresNote"
                  color="primary"
                />
              }
              label={<Typography variant="body2">Note</Typography>}
            />
          </FormGroup>
        </Box>
        <Typography variant="body2" color={this.amountValid ? 'error' : 'initial'}>
          Fixed amount to be presented
        </Typography>
        <Box display="flex" justifyContent="space-between" mt={1} mb={3}>
          <Box display="flex" flexDirection="column" mr={1} width={120}>
            <OutlinedInput
              name="lowAmount"
              label={'$ LOW'}
              error={parseInt(this.lowAmount) <= 0}
              value={this.lowAmount}
              onChange={this.handleInputChange('lowAmount')}
              fullWidth
              InputProps={{
                inputComponent: this.renderMaskedCurrencyInput,
              }}
            />
          </Box>
          <Box display="flex" flexDirection="column" mr={1} width={120}>
            <OutlinedInput
              name="mediumAmount"
              label={'$ MID'}
              error={parseInt(this.mediumAmount) <= 0}
              value={this.mediumAmount}
              onChange={this.handleInputChange('mediumAmount')}
              fullWidth
              InputProps={{
                inputComponent: this.renderMaskedCurrencyInput,
              }}
            />
          </Box>
          <Box display="flex" flexDirection="column" width={120}>
            <OutlinedInput
              name="highAmount"
              label={'$ HIGH'}
              error={parseInt(this.highAmount) <= 0}
              value={this.highAmount}
              onChange={this.handleInputChange('highAmount')}
              fullWidth
              InputProps={{
                inputComponent: this.renderMaskedCurrencyInput,
              }}
            />
          </Box>
        </Box>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <OutlinedInput
            fullWidth
            multiline
            label={'Aditional instructions for customer'}
            name="customerInstructions"
            onChange={this.handleInputChange('customerInstructions')}
            value={this.customerInstructions}
          />
          <Box className={classes.buttonMargin}>
            <DP.IconButton
              secondary
              submit
              icon={InformationOutline}
              tooltip={this.additionalInfoText}
            />
          </Box>
        </Box>
      </>
    );
  }

  printPageLink = () =>
    `${paths.QRCodeForPrint()}?type=pool&code=${this.props.pool!.uid}&industry=${
      this.props.industry
    }`;

  renderPoolQRContent() {
    const { pool, classes } = this.props;
    return (
      pool && (
        <>
          <Box mt={3} display="flex" justifyContent="center">
            <QRCode style={{ height: '208px', width: '208px' }} value={this.qrLink} />
          </Box>
          <Box display="flex" alignItems="center" justifyContent="center" mt={4} style={{ gap: '24px'}} >
            <Button
              variant="contained"
              startIcon={<ContentCopy />}
              onClick={this.handleCopyClick}
              color="primary"
              className={classes.button}>
              Copy
            </Button>
            <Button
              variant="contained"
              startIcon={<Printer />}
              onClick={this.handlePrintClick}
              color="primary"
              className={classes.button}>
              Print
            </Button>
          </Box>
          <Box mt={4} display="flex" flexDirection="column">
            <Typography variant="body2" className={classes.text}>{pool.name}</Typography>
            <Typography variant="subtitle1" className={classes.label}>Pool name</Typography>
          </Box>
          <Box mt={4} display="flex" flexDirection="column">
            <Typography variant="body2" className={classes.text}>{pool.uid}</Typography>
            <Typography variant="subtitle1" className={classes.label}>Pool code</Typography>
          </Box>
        </>
      )
    );
  }

  render() {
    const { classes, type } = this.props;
    return (
      <Drawer anchor="right" open={this.props.isOpen}>
        <DialogTitle>
          <Box display="flex" alignItems="center" justifyContent="space-between">
            <Typography style={{ fontSize: '28px' }} component="h1" display="inline">
              {this.renderTitle(type)}
            </Typography>
            <IconButton onClick={this.closeDrawer}>
              <Close color="inherit" />
            </IconButton>
          </Box>
        </DialogTitle>
        <Divider />
        <DialogContent className={classes.dialogWrapper}>
          <Box mt={3}>
            {type === DrawerType.QR ? this.renderPoolQRContent() : this.renderPoolForm()}
          </Box>
        </DialogContent>
        {/* Don't display for QR */}
        {type !== DrawerType.QR && (
          <DialogActions className={classes.buttonMargin}>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              onClick={this.onSubmit}
              disabled={!this.isFormValid}>
              {type === DrawerType.ADD ? 'Add' : 'Update'}
            </Button>
          </DialogActions>
        )}
      </Drawer>
    );
  }
}

export default withStyles(styles)(PoolsDrawer);
