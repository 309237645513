import React from 'react';
import { observable, makeObservable } from 'mobx';
import { observer } from 'mobx-react';
import { WithStyles, withStyles } from '@material-ui/core/styles';
import { RouteComponentProps } from 'react-router-dom';

import { inject, WithUserStore } from 'stores';
import { Location } from 'models';

import DashboardLayout from 'containers/DashboardLayout';
import LocationsMap from 'containers/LocationsMap';
import LocationsTable from 'components/LocationsTable';
import styles from './styles';

import { setTitle } from 'services';
import Title from 'components/Title/Title/Title';

const PAGE_TITLE = 'Locations';
/** Here we define what kind of props this component takes */
type LocationsProps = WithStyles<typeof styles> & // Adds the classes prop
  WithUserStore &
  RouteComponentProps; // Adds the userStore prop

/**
 * The Locations container. Only users with admin and owner scope can access this view.
 * It renders LocationsTable component and passes it locations for rendering
 * locations table if scope is admin. If scope is owner we send nonpaginated
 * prefetched list of locations for owners account.
 */
@inject('userStore')
@observer
class Locations extends React.Component<LocationsProps> {
  public constructor(props: LocationsProps) {
    super(props);
    makeObservable(this);
  }

  @observable public isOwner: boolean = this.props.userStore!.scope.kind === 'owner';

  /** The locations for this account */
  @observable public locations: Location[] = [];
  /** The status of the map */
  @observable public mapIsActive = false;

  componentDidMount() {
    //this.isOwner && this.getLocations();
    setTitle(PAGE_TITLE, { noSuffix: false });
  }

  render() {
    const { classes } = this.props;
    return (
      <DashboardLayout>
        <Title mb={3}>{PAGE_TITLE}</Title>
        {!this.mapIsActive && <LocationsTable locations={this.locations} {...this.props} />}
        {this.mapIsActive && <LocationsMap />}
      </DashboardLayout>
    );
  }
}

export default withStyles(styles)(Locations);
