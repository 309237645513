import {
  Box,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from '@material-ui/core';
import { ECorrectionType } from 'models';

interface CorrectionTypeSelectorProps {
  correctionType: ECorrectionType;
  setCorrectionType: (type: ECorrectionType) => void;
}

export const CorrectionTypeSelector = ({
  correctionType,
  setCorrectionType,
}: CorrectionTypeSelectorProps) => (
  <Box>
    <Typography variant="body2" className="subtitleSmall">
      Type
    </Typography>
    <FormControl component="fieldset">
      <RadioGroup
        name="correctionType"
        className="selectType"
        row
        value={correctionType}
        onChange={(e) => {
          setCorrectionType(e.target.value as ECorrectionType);
        }}>
        <FormControlLabel
          value={ECorrectionType.WITHOUT_INVOICE}
          control={<Radio color="primary" />}
          label={<Typography>Without Invoice</Typography>}
        />
        <FormControlLabel
          value={ECorrectionType.WITH_INVOICE}
          control={<Radio color="primary" />}
          label={<Typography component="span">With Invoice</Typography>}
        />
      </RadioGroup>
    </FormControl>
  </Box>
);
