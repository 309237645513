import { Box, IconButton, Tooltip, Typography } from '@material-ui/core';
import {
  GridColDef,
  GridRenderCellParams,
  GridRowModel,
  useGridApiRef,
} from '@mui/x-data-grid-pro';
import DataGridInfiniteScroll from 'components/DataGridInfiniteScroll';
import { adaptForDataGridPro, numericStringToUsd, renderConversionCellStatus } from 'services';
import Api, { RequestMetaData, getErrorMsg } from 'api';
import { Conversion, ConversionStatus } from 'models';
import moment from 'moment';
import { EDateFormat } from 'utils/helper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHandHoldingDollar } from '@fortawesome/pro-regular-svg-icons';
import theme from 'containers/App/theme';
import { referralsTableActionStyles } from '../styles';
import { useStores } from 'containers/App/App';
import clsx from 'clsx';
import { useCallback, useState } from 'react';

interface ReferralsTableProps {
  affiliateId: number;
}

const conversionMapper = (conversion: Conversion) => {
  const { id, location, paidAt, amount, status, message, isClaimable } = conversion;
  const conversionStatus: string = status.replace(/_/gi, ' ').toUpperCase();

  return {
    id,
    accountName: location?.account?.name,
    locationName: location?.name,
    paidAt: paidAt ? moment(paidAt).format(EDateFormat.DEFAULT) : '',
    amount: amount ? numericStringToUsd(amount) : '',
    status: conversionStatus,
    message,
    isClaimable,
  };
};

export const ReferralsTable = ({ affiliateId }: ReferralsTableProps) => {
  const { modalStore, toastStore } = useStores();

  const apiRef = useGridApiRef();

  const [refetchKey, setRefetchKey] = useState(Date.now());

  const fetchConversionsData = adaptForDataGridPro(
    async (rmd: RequestMetaData) =>
      await Api.marketing.getAffiliateConversions(affiliateId, {
        ...rmd,
      }),
    conversionMapper,
  );

  const claimRewardActionHandler = useCallback(
    async (row: GridRowModel): Promise<void> => {
      const { isClaimable, id, amount } = row;

      if (!isClaimable) {
        return;
      }

      // Open Modal
      const confirmed = await modalStore.confirm(
        'Claim Reward',
        `You will claim ${amount} on your Branch account.`,
        {
          confirmLabel: 'Claim',
        },
      );

      if (!confirmed) {
        return;
      }

      try {
        // Claim Reward Api call
        const { data } = await Api.marketing.claimReward(id);
        toastStore.success('Reward claimed successfully.');

        // Refetch Table data
        setRefetchKey(Date.now());
      } catch (error) {
        toastStore.error(getErrorMsg(error));
        return;
      }
    },
    [apiRef, modalStore, toastStore],
  );

  const renderCellActions = ({ row }: GridRenderCellParams) => {
    const { isClaimable, status, message } = row;
    const tooltipText = isClaimable ? '' : message;

    if (status.toLowerCase() === ConversionStatus.PAID) {
      return <></>;
    }

    return (
      <Box sx={referralsTableActionStyles}>
        <Tooltip title={tooltipText}>
          <IconButton
            onClick={() => claimRewardActionHandler(row)}
            className={clsx(!isClaimable && 'btn-disabled')}
            disableRipple={!isClaimable}>
            <FontAwesomeIcon
              icon={faHandHoldingDollar}
              color={!isClaimable ? theme.palette.text.disabled : theme.palette.primary.main}
              fontSize={24}
            />
          </IconButton>
        </Tooltip>
      </Box>
    );
  };

  const gridColumns: GridColDef[] = [
    {
      headerName: 'Account',
      field: 'accountName',
      minWidth: 150,
      flex: 1,
    },
    { headerName: 'Location', field: 'locationName', minWidth: 150, flex: 1 },
    {
      headerName: 'Paid At',
      field: 'paidAt',
      minWidth: 150,
      flex: 1,
    },
    { headerName: 'Reward', field: 'amount', minWidth: 150, flex: 1 },
    {
      headerName: 'Status',
      field: 'status',
      minWidth: 280,
      flex: 1,
      renderCell: renderConversionCellStatus,
    },
    {
      headerName: 'Actions',
      field: 'actions',
      type: 'actions',
      minWidth: 120,
      filterable: false,
      renderCell: renderCellActions,
    },
  ];

  return (
    <>
      <Box mt={6} mb={3}>
        <Typography variant="h5">My Referrals</Typography>
      </Box>
      <DataGridInfiniteScroll
        refetchKey={refetchKey}
        columns={gridColumns}
        fetch={fetchConversionsData}
        disableColumnMenu
        apiRef={apiRef}
      />
    </>
  );
};
