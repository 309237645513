import { Box } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { correctionDialogStyles } from './styles';
import Api, { getErrorMsg } from 'api';
import { IBillingEntity, ECorrectionType, ICorrection } from 'models';
import { useStores } from 'containers/App/App';
import { WithoutInvoice } from './WithoutInvoice/WithoutInvoice';
import { WithInvoice } from './WithInvoice/WithInvoice';

interface CreateCorrectionDialogProps {
  accountId: number;
  billingEntityId?: number;
  paymentMethods: IBillingEntity[];
  dialogClose: () => void;
  refreshData: () => void;
}

export const CreateCorrectionDialog = ({
  accountId,
  billingEntityId,
  paymentMethods,
  dialogClose,
  refreshData,
}: CreateCorrectionDialogProps) => {
  const { toastStore } = useStores();

  const [correctionType, setCorrectionType] = useState(ECorrectionType.WITHOUT_INVOICE);
  const [correctionsList, setCorrectionsList] = useState<ICorrection[]>([]);

  useEffect(() => {
    async function fetchData() {
      try {
        const { data } = await Api.billing.getCorrectionsList();
        data.data && setCorrectionsList(data.data);
      } catch (error: unknown) {
        toastStore.error(getErrorMsg(error));
      }
    }

    fetchData();
  }, [toastStore]);

  return (
    <Box sx={correctionDialogStyles}>
      {correctionType === ECorrectionType.WITHOUT_INVOICE ? (
        <WithoutInvoice
          accountId={accountId}
          billingEntityId={billingEntityId}
          paymentMethods={paymentMethods}
          dialogClose={dialogClose}
          refreshData={refreshData}
          setCorrectionType={setCorrectionType}
        />
      ) : (
        <WithInvoice
          accountId={accountId}
          correctionsList={correctionsList}
          billingEntityId={billingEntityId}
          paymentMethods={paymentMethods}
          dialogClose={dialogClose}
          refreshData={refreshData}
          setCorrectionType={setCorrectionType}
        />
      )}
    </Box>
  );
};
