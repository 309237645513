import React from 'react';
import { Box } from '@material-ui/core';
import { CardIconColor, useIconWrapper } from './useIconWrapper';
import clsx from 'clsx';
import useStyles from './styles';
import { IconDefinition } from '@fortawesome/pro-regular-svg-icons';
import WrappedFontAwesomeIconWrapper from 'components/WrappedFontAwesomeIconWrapper/WrappedFontAwesomeIconWrapper';

export enum ECircularIconFrameVariant {
  SMALL = 'small',
  MEDIUM = 'medium',
  LARGE = 'large',
}

export type TCircularIconFrameIcon = IconDefinition;

export interface ICircularIconFrame {
  variant?: ECircularIconFrameVariant;
  icon: TCircularIconFrameIcon;
  iconHeight?: React.CSSProperties['height'];
  color?: CardIconColor;
}

const CircularIconFrame = ({
  variant = ECircularIconFrameVariant.LARGE,
  ...props
}: ICircularIconFrame) => {
  const getColorClass = useIconWrapper();
  const { iconWrapperSmall, iconWrapperMedium, iconWrapperBase, iconWrapperLarge } = useStyles();
  let { icon, iconHeight, color } = props;

  let iconWrapper = iconWrapperSmall;
  let height: string | number = 24;
  switch (variant) {
    case ECircularIconFrameVariant.MEDIUM:
      iconWrapper = iconWrapperMedium;
      height = 32;
      break;
    case ECircularIconFrameVariant.LARGE:
      iconWrapper = iconWrapperLarge;
      height = 71;
      break;
    default: //Adjust
      iconWrapper = iconWrapperSmall;
      height = 24;
      break;
  }

  height = iconHeight || height;

  return (
    <Box className={clsx(getColorClass(color), iconWrapper, iconWrapperBase)} alignSelf={'center'}>
      <WrappedFontAwesomeIconWrapper icon={icon} height={height} alignSelf={'center'} />
    </Box>
  );
};

export default CircularIconFrame;
