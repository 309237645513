import { Theme, createStyles } from '@material-ui/core';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default (theme: Theme) =>
  createStyles({
    root: {},
    drawerPaper: {
      width: '60%',
    },
    logo: {
      objectFit: 'contain',
      maxWidth: '240px',
      maxHeight: '150px',
      alignSelf: 'center',
    },
    title: {
      marginBottom: '0px',
      fontSize: 'larger',
      fontWeight: 'bold',
    },
    titleContainer: {
      display: 'flex',
      flexDirection: 'column',
      marginLeft: '2rem',
      borderLeft: '1px solid #8080807d',
      padding: '1rem',
      width: '100%',
    },
    tagContainer: {
      color: 'white',
      borderRadius: '5px',
      fontWeight: 'bold',
      paddingLeft: '5px',
      marginLeft: '1rem',
      // position: 'relative',
      // top: '-15px',
      paddingRight: '5px',
      fontSize: 'small',
    },
    bottonUninstall: {
      fontSize: '12px',
      backgroundColor: '#d42222d4',
      color: 'white',
    },
    row: {
      color: '#55BF9B',
      maxWidth: '150px',
      textAlign: 'right',
      flexShrink: 0,
      marginRight: '16px',
      textTransform: 'uppercase',
    },
    textToken: {
      marginBottom: 0,
      fontSize: 'small',
    },
    Uninstall: {
      fontSize: 'small',
      color: 'gray',
    },
    btnUninstall: {
      backgroundColor: '#c51717a8',
      fontSize: 'small',
      '&:hover': {
        backgroundColor: '#c51717f5',
      },
    },
    textMask: {
      margin: 0,
      fontSize: 'medium',
      color: '#55BF9B',
    },
    btnMask: {
      color: '#55BF9B',
      fontSize: 'unset',
    },
    descriptionPadding: {
      paddingBottom: theme.spacing(5),
    },
  });
