import { Box } from '@material-ui/core';
import Icon from './Icon/Icon';
import useSignupLayout from './useSignupLayout';
import Title, { ETitleVariant } from 'components/Title/Title/Title';
import Subtitle from './Subtitle/Subtitle';
import { observer } from 'mobx-react';
import { TButtonProps } from 'components/Button/Button';
import Action from './Action/Action';
import SubAction from './SubAction/SubAction';
import React from 'react';
import { hideScrollbar } from 'utils/commonStyles';

export type TSignupLayoutIcon = { src: string; mt?: number } | JSX.Element | undefined;
export type TSignupLayoutAction = {
  preText?: React.ReactNode;
  subText?: React.ReactNode;
  hide?: boolean;
} & (
  | Pick<TButtonProps, 'children' | 'onClick' | 'disabled' | 'loading'>
  | Pick<TButtonProps, 'children' | 'form' | 'disabled' | 'loading' | 'type'>
);
export type TSignupLayoutContentWrapperStyles = {
  marginTop?: React.CSSProperties['marginTop'];
  marginBottom?: React.CSSProperties['marginBottom'];
};
export type TSignupLayoutSubAction =
  | {
      back?: {
        onClick?: TButtonProps['onClick'];
        label?: string;
      };
      skip?: {
        onClick?: TButtonProps['onClick'];
        label?: string;
      };
      center?: {
        onClick?: TButtonProps['onClick'];
        label: string;
      };
    }
  | undefined;
export type TSignupLayoutSubtitle = string | JSX.Element;
export interface ISignupLayoutProps {
  icon?: TSignupLayoutIcon;
  title?: string;
  children: JSX.Element;
  subtitle?: TSignupLayoutSubtitle;
  action?: TSignupLayoutAction;
  subAction?: TSignupLayoutSubAction;
  contentWrapperStyles?: TSignupLayoutContentWrapperStyles;
}

const SignupLayout = ({
  icon,
  title,
  subtitle,
  children,
  action,
  subAction,
  contentWrapperStyles,
}: ISignupLayoutProps) => {
  const { iconHeight, padding, subtitleMt, iconMb, contentStyles } = useSignupLayout({
    action,
    icon,
    subtitle,
    contentWrapperStyles,
  });
  const style = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding,
    ...hideScrollbar,
    overflowY: 'auto',
  } as React.CSSProperties;
  return (
    <Box style={style}>
      <Icon icon={icon} height={iconHeight} mb={iconMb} />
      <Title variant={ETitleVariant.SIGNUP}>{title}</Title>
      <Subtitle mt={subtitleMt}>{subtitle}</Subtitle>
      <Box minWidth={330} maxWidth={360}>
        <Box style={contentStyles}>{children}</Box>
        <Action {...action} />
        <SubAction {...subAction} />
      </Box>
    </Box>
  );
};

export default observer(SignupLayout);
