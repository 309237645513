import React from 'react';
import {
  observable,
  action,
  flow,
  computed,
  reaction,
  IReactionDisposer,
  makeObservable,
} from 'mobx';
import { observer } from 'mobx-react';
import { WithStyles, withStyles } from '@material-ui/core/styles';
import { Box, Grid } from '@material-ui/core';

// api import for tips report email and download for QA testing:
import Api, { ApiResponse } from 'api';
import { DashboardTipStats, DashboardCoreStats, PayoutStats, User } from 'models';

import {
  inject,
  WithUserStore,
  WithModalStore,
  WithToastStore,
  WithAnalyticsStore,
  WithSettingStore,
} from 'stores';

import { HorizontalStatCard } from 'containers/UserDetails/Stats';

import styles from './styles';
import * as DateRangeExternalPicker from 'components/DateRangeExternalPicker';

import { AxiosResponse } from 'axios';
import Title from 'components/Title';
import FilterBar from 'components/FilterBar';
import { RouteComponentProps } from 'react-router-dom';
import {
  faBuildings,
  faChartMixedUpCircleDollar,
  faCoin,
  faCoins,
  faCreditCard,
  faDisplayChartUpCircleDollar,
  faHashtag,
  faMobile,
  faMoneyBillTransfer,
  faMoneyBillTrendUp,
  faMoneyBillWave,
  faMoneyCheckDollar,
  faShop,
  faUser,
} from '@fortawesome/pro-regular-svg-icons';

interface DashboardHomeProps
  extends WithStyles<typeof styles>,
    WithUserStore,
    WithModalStore,
    WithToastStore,
    WithAnalyticsStore,
    WithSettingStore,
    RouteComponentProps {}

/**
 * The dashboard home page, displayed when you log in.
 */
@inject('userStore', 'modalStore', 'toastStore', 'analyticsStore', 'settingStore')
@observer
class AdminHome extends React.Component<DashboardHomeProps> {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  constructor(props: DashboardHomeProps) {
    super(props);
    makeObservable(this);

    this.disposers.push(
      reaction(
        () => this.dateRange,
        () => {
          this.fetchStats();
          this.fetchLiveLocations();
        },
      ),
    );
  }

  /** It's good practice to dispose of any autoruns that we set up during */
  private disposers: IReactionDisposer[] = [];

  @observable public tipStats?: DashboardTipStats;
  @observable public coreStats?: DashboardCoreStats;
  @observable public payoutStats?: PayoutStats;
  @observable public liveLocationsCount?: number;

  @action.bound
  public fetchStats = flow(function* (this: AdminHome) {
    this.tipStats = undefined;
    const resp: AxiosResponse<ApiResponse<DashboardTipStats>> = yield Api.tips.getDashboardStats({
      from: this.dateRange.fromDate,
      to: this.dateRange.toDate,
    });
    this.tipStats = resp.data.data;
  });

  @action.bound public fetchCoreStats = flow(function* (this: AdminHome) {
    const resp: AxiosResponse<ApiResponse<DashboardCoreStats>> = yield Api.core.getDashboardStats();
    this.coreStats = resp.data.data;
  });

  @action.bound public fetchPayoutStats = flow(function* (this: AdminHome) {
    const resp: AxiosResponse<ApiResponse<PayoutStats>> = yield Api.tips.getPayoutStats();
    this.payoutStats = resp.data.data;
  });

  @action.bound public fetchLiveLocations = async () => {
    try {
      const data = await Api.analytics.getLiveLocationsCount({
        filters: { fromDate: this.dateRange.fromDate, toDate: this.dateRange.toDate },
      });
      this.liveLocationsCount = data?.data.data;
    } catch (error) {
      console.error(error);
    }
  };

  @computed public get tipStatsComputed() {
    return {
      paymentsCount: this.tipStats && parseFloat(this.tipStats.paymentsCount),
      paymentsTotalCharged: this.tipStats && parseFloat(this.tipStats.paymentsTotalCharged),
      paymentsTotalFees: this.tipStats && parseFloat(this.tipStats.paymentsTotalFees),
      paymentsTotalRevenue: this.tipStats && parseFloat(this.tipStats.paymentsTotalRevenue),
      tipsAvgNet: this.tipStats && parseFloat(this.tipStats.tipsAvgNet),
      tipsCount: this.tipStats && parseFloat(this.tipStats.tipsCount),
      tipsTotalNet: this.tipStats && parseFloat(this.tipStats.tipsTotalNet),
      paymentsAvgCharged: this.tipStats && parseFloat(this.tipStats.paymentsAvgCharged),
      tipsAvgCardNet: this.tipStats && parseFloat(this.tipStats.tipsAvgCardNet),
      tipsAvgCashNet: this.tipStats && parseFloat(this.tipStats.tipsAvgCashNet),
      tipsCountCard: this.tipStats && parseFloat(this.tipStats.tipsCountCard),
      tipsCountCash: this.tipStats && parseFloat(this.tipStats.tipsCountCash),
      tipsTotalCardNet: this.tipStats && parseFloat(this.tipStats.tipsTotalCardNet),
      tipsTotalCashNet: this.tipStats && parseFloat(this.tipStats.tipsTotalCashNet),
      tipsTotalServiceAmount: this.tipStats && parseFloat(this.tipStats.tipsTotalServiceAmount),
      totalRebate: this.tipStats && parseFloat(this.tipStats.totalRebate),
      grossRevenue:
        this.tipStats &&
        parseFloat(this.tipStats.paymentsTotalRevenue) +
          parseFloat(this.tipStats.paymentsTotalFees),
    };
  }

  @computed public get coreStatsComputed() {
    return {
      accountsCount: this.coreStats && parseFloat(this.coreStats.accountsCount),
      locationsCount: this.coreStats && parseFloat(this.coreStats.locationsCount),
      usersCount: this.coreStats && parseFloat(this.coreStats.usersCount),
      devicesCount: this.coreStats && parseFloat(this.coreStats.devicesCount),
    };
  }

  /** The selected date range */
  @observable public dateRange: DateRangeExternalPicker.DateRange =
    this.props.settingStore!.getDate(this.props.location.pathname);

  /** Sets the date range */
  @action.bound private onDateRangeChange(range: DateRangeExternalPicker.DateRange) {
    this.props.settingStore!.setDate(this.props.location.pathname, range);
    this.dateRange = range;
  }

  componentDidMount() {
    this.fetchPayoutStats();
    this.fetchCoreStats();
    this.fetchStats();
    this.fetchLiveLocations();
  }

  /** Before unmounting the component, dispose of all reactions created */
  componentWillUnmount() {
    this.disposers.map((disposer) => disposer());
  }

  @observable public u: User | null = null;

  @action.bound public setUser(u: User | null) {
    this.u = u;
  }

  render() {
    const { classes } = this.props;
    return (
      <>
        <Title mb={3}>Dashboard</Title>
        <Box display="flex" justifyContent="flex-end">
          <FilterBar
            filters={[]}
            onChange={(filters: Record<string, unknown>) => {}}
            externalDateRange={{
              predefined: this.dateRange,
              onChange: this.onDateRangeChange,
            }}
          />
        </Box>
        <Box>
          <Grid container spacing={3} justifyContent="center">
            <Grid item className={classes.cardItem}>
              <HorizontalStatCard
                fontAwesomeIcon={faHashtag}
                duration={1}
                title="Total number of tips">
                {this.tipStatsComputed.tipsCountCard}
              </HorizontalStatCard>
            </Grid>
            {/**
             * TODO: use CircularIconFrame in stat cards components when we migrate from MUI icons to Font awesome icons
             * */}
            <Grid item className={classes.cardItem}>
              <HorizontalStatCard
                fontAwesomeIcon={faCoin}
                duration={1}
                title="Total amount"
                prefix="$"
                color={'yellow'}
                separator=","
                decimals={2}>
                {this.tipStatsComputed.paymentsTotalCharged}
              </HorizontalStatCard>
            </Grid>
            <Grid item className={classes.cardItem}>
              <HorizontalStatCard
                fontAwesomeIcon={faMoneyBillTrendUp}
                duration={1}
                prefix="$"
                separator=","
                decimals={2}
                title="Average tip"
                color="purple">
                {this.tipStatsComputed.tipsAvgCardNet}
              </HorizontalStatCard>
            </Grid>
            <Grid item className={classes.cardItem}>
              <HorizontalStatCard
                fontAwesomeIcon={faCoins}
                duration={1}
                prefix="$"
                separator=","
                decimals={2}
                title="Gross revenue">
                {this.tipStatsComputed.grossRevenue}
              </HorizontalStatCard>
            </Grid>
            <Grid item className={classes.cardItem}>
              <HorizontalStatCard
                fontAwesomeIcon={faMoneyBillWave}
                duration={1}
                prefix="$"
                separator=","
                decimals={2}
                title="Total payouts"
                color="yellow">
                {this.tipStatsComputed.tipsTotalCardNet}
              </HorizontalStatCard>
            </Grid>
            <Grid item className={classes.cardItem}>
              <HorizontalStatCard
                fontAwesomeIcon={faMoneyCheckDollar}
                duration={1}
                prefix="$"
                separator=","
                decimals={2}
                title="Processor fees"
                color="purple">
                {this.tipStatsComputed.paymentsTotalFees}
              </HorizontalStatCard>
            </Grid>
            <Grid item className={classes.cardItem}>
              <HorizontalStatCard
                fontAwesomeIcon={faChartMixedUpCircleDollar}
                duration={1}
                prefix="$"
                separator=","
                decimals={2}
                title="Revenue">
                {this.tipStatsComputed.paymentsTotalRevenue}
              </HorizontalStatCard>
            </Grid>
            <Grid item className={classes.cardItem}>
              <HorizontalStatCard
                fontAwesomeIcon={faCreditCard}
                duration={1}
                title="Swipes"
                color="yellow">
                {this.tipStatsComputed.paymentsCount}
              </HorizontalStatCard>
            </Grid>
            <Grid item className={classes.cardItem}>
              <HorizontalStatCard
                fontAwesomeIcon={faBuildings}
                duration={1}
                title="Accounts"
                color="purple">
                {this.coreStatsComputed.accountsCount}
              </HorizontalStatCard>
            </Grid>
            <Grid item className={classes.cardItem}>
              <HorizontalStatCard fontAwesomeIcon={faShop} duration={1} title="Locations">
                {this.coreStatsComputed.locationsCount}
              </HorizontalStatCard>
            </Grid>
            <Grid item className={classes.cardItem}>
              <HorizontalStatCard
                fontAwesomeIcon={faUser}
                duration={1}
                title="Users"
                color="yellow">
                {this.coreStatsComputed.usersCount}
              </HorizontalStatCard>
            </Grid>
            <Grid item className={classes.cardItem}>
              <HorizontalStatCard
                fontAwesomeIcon={faMobile}
                duration={1}
                title="Devices"
                color="purple">
                {this.coreStatsComputed.devicesCount}
              </HorizontalStatCard>
            </Grid>
            <Grid item className={classes.cardItem}>
              <HorizontalStatCard
                fontAwesomeIcon={faMoneyBillTransfer}
                duration={1}
                title="Total Rebate"
                color="green">
                {this.tipStatsComputed.totalRebate}
              </HorizontalStatCard>
            </Grid>
            <Grid item className={classes.cardItem}>
              <HorizontalStatCard
                fontAwesomeIcon={faDisplayChartUpCircleDollar}
                duration={1}
                title="Live Locations"
                color="yellow">
                {this.liveLocationsCount}
              </HorizontalStatCard>
            </Grid>
            <Grid item className={classes.cardItem} />
            <Grid item xs={12}>
              <Box pt={4} />
            </Grid>
          </Grid>
        </Box>
      </>
    );
  }
}

export default withStyles(styles)(AdminHome);
