import { useStores } from 'containers/App/App';
import { useEffect, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { paths } from 'routes';
import { LoginAction } from 'routes/helpers';
import { createNewForm } from './helpers';
import { TSignupLayoutAction, TSignupLayoutSubAction } from 'containers/SignupLayout/SignupLayout';

const formId = 'phone-number-form';

const usePhoneNumber = () => {
  const { userStore, mfaStore } = useStores();
  const phone = useRef(userStore.user?.phone || null);
  const [action, setAction] = useState<LoginAction | null>(null);
  const [form] = useState<any>(() => createNewForm(phone));
  const location = useLocation();
  const history = useHistory();
  const { showMfaStep } = mfaStore;

  useEffect(() => {
    const loginAction = (location?.state as any)?.loginAction;
    if (loginAction) {
      setAction(loginAction);
    }
  }, []);

  const loading = form?.submitting;
  const submit = form?.onSubmit;
  const phoneField = form?.$('phone');
  const error = phoneField?.error || '';
  const errorMessage = phoneField?.error || '';

  const handleContinue = async () => {
    const { managerOnboarding, loginAction } = location.state as any;
    if (loginAction) {
      return history.push({
        pathname: paths.signIn(),
      });
    }
    history.push({
      pathname: paths.signUp().personalInfo2(),
      state: {
        // Make the next step component aware of the manager
        // onboarding process so it can route accordingly:
        managerOnboarding,
      },
    });
  };

  const handleChangePhoneNumber = () => {
    mfaStore.resetMfaResult();
  };

  const logout = () => {
    userStore.logout();
  };

  let helperText = 'Choose a phone number to receive your verification code.';
  if (action) {
    helperText =
      action === LoginAction.ADD_PHONE
        ? `We noticed you haven’t added your phone number yet. To help secure your account please add your phone number.`
        : 'We noticed your phone number hasn’t been verified yet! To help secure your account please verify your phone number.';
  }

  let title = 'Add Phone Number';
  if (action === LoginAction.VERIFY_PHONE || showMfaStep) {
    title = 'Verify Phone Number';
  }

  const showBackButton = !!action && !showMfaStep;
  const showSignupAction = !showMfaStep;

  const signupAction: TSignupLayoutAction | undefined = showSignupAction
    ? {
        disabled: !!error,
        loading,
        form: formId,
        type: 'submit',
        children: 'Continue',
      }
    : undefined;

  const subAction: TSignupLayoutSubAction = showBackButton
    ? {
        back: {
          onClick: logout,
          label: 'Back to Sign In',
        },
      }
    : undefined;

  const signupLayoutProps = {
    title,
    action: signupAction,
    subAction,
  };

  return {
    submit,
    error,
    helperText,
    phoneField,
    errorMessage,
    showMfaStep,
    signupLayoutProps,
    formId,
    handleContinue,
    handleChangePhoneNumber,
  };
};

export default usePhoneNumber;
