import React from 'react';
import useStyles from './styles';
import { Box, Tooltip, CardMedia } from '@material-ui/core';
import { Bank, Domain, Store, Android, AppleIos, CellphoneOff } from 'mdi-material-ui';
import { BankAccount } from 'models';

type Device = 'android' | 'ios' | string;

interface UserIconDetailsProps {
  accountName?: string;
  locationName?: string;
  device?: Device;
  bank?: BankAccount;
}

/** Displays a card for a single user */
function UserIconDetails({ accountName, locationName, bank, device }: UserIconDetailsProps) {
  const classes = useStyles();

  const getAccountIcon = (account: string | undefined) => {
    return (
      <Tooltip title={account || 'Not associated'}>
        <Box display="inline">
          <Domain className={account ? classes.darkGrey : classes.lightGrey} />
        </Box>
      </Tooltip>
    );
  };

  const getLocationIcon = (location: string | undefined) => {
    return (
      <Tooltip title={location || 'Not associated'}>
        <Box display="inline">
          <Store className={location ? classes.darkGrey : classes.lightGrey} />
        </Box>
      </Tooltip>
    );
  };

  const isBase64 = (data: string): boolean => {
    try {
      if (btoa(atob(data)) === data) {
        return true;
      } else {
        return false;
      }
    } catch (e: any) {
      return false;
    }
  };

  const getBankIcon = (bank: BankAccount | undefined) => {
    if (!bank || !bank.id) {
      return (
        <Tooltip title={'Not associated'}>
          <Box display="inline">
            <Bank color={'error'} />
          </Box>
        </Tooltip>
      );
    }
    let src = bank.logo || '';
    // If is base 64
    if (isBase64(src)) {
      src = `data:image/png;base64,${src}`;
    }
    return (
      <Tooltip title={bank.name || 'Unknown bank'}>
        <Box display="inline">
          {bank.logo ? (
            <CardMedia className={classes.bankLogo} image={src} />
          ) : (
            <Bank className={classes.darkGrey} />
          )}
        </Box>
      </Tooltip>
    );
  };

  const getDeviceIcon = (device: Device | undefined) => {
    switch (device) {
      case 'android':
        return (
          <Tooltip title="Android">
            <Box display="inline">
              <Android className={classes.darkGrey} />
            </Box>
          </Tooltip>
        );
      case 'ios':
        return (
          <Tooltip title="IOS">
            <Box display="inline">
              <AppleIos className={classes.darkGrey} />
            </Box>
          </Tooltip>
        );
      default:
        return (
          <Tooltip title="Not associated">
            <Box display="inline">
              <CellphoneOff color={'error'} />
            </Box>
          </Tooltip>
        );
    }
  };

  return (
    <Box pt={2} pb={2}>
      <Box px={1} display="inline">
        {getAccountIcon(accountName)}
      </Box>
      <Box px={1} display="inline">
        {getLocationIcon(locationName)}
      </Box>
      <Box px={1} display="inline">
        {getBankIcon(bank)}
      </Box>
      <Box px={1} display="inline">
        {getDeviceIcon(device)}
      </Box>
    </Box>
  );
}

export default UserIconDetails;
