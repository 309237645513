import { makeStyles, createStyles, Theme } from '@material-ui/core';
import { dynamicPadding } from 'utils/commonStyles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      height: '100dvh',
      width: '100dvw',
      display: 'flex',
      position: 'absolute',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      gap: theme.spacing(3),
      ...dynamicPadding,
      backgroundColor: theme.palette.common.white,
    },
    title: {
      fontSize: 28,
      color: theme.palette.text.primary,
    },
    footer: {
      position: 'absolute',
      bottom: theme.spacing(4),
    },
    avatar: {
      width: 112,
      height: 112,
      borderRadius: 28,
    },
    button: {
      maxWidth: 378,
    },
  }),
);

export default useStyles;
