import React from 'react';
import styles from '../styles';
import { WithStyles, withStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';
import { computed, observable, action, makeObservable } from 'mobx';
import Api, { RequestMetaData } from '../../../../api';
import { v4 as uuidv4 } from 'uuid';
import { adaptForDataGridPro, setTitle } from '../../../../services';
import { RouteComponentProps } from 'react-router-dom';
import { observer } from 'mobx-react';
import DataGridInfiniteScroll from 'components/DataGridInfiniteScroll';

import { Transaction } from '../../../../models';
import moment from 'moment';
import { useStyles } from '../../../UserDetails/styles';
import clsx from 'clsx';
import _ from 'lodash';
import PartnerWalletCorrectionModal from './PartnerWalletCorrectionModal';

interface WalletMatchParams {
  partnerId: string;
}

type WalletProps = WithStyles<typeof styles> & RouteComponentProps<WalletMatchParams>;

function Amount({ children }: { children?: string }) {
  const classes = useStyles();
  const number = parseFloat(children || '0');
  const d = number.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
  return <span className={clsx(number >= 0 ? classes.positive : classes.negative)}>{d}</span>;
}

const renderTime = (
  rowId: unknown,
  id: unknown,
  value: unknown,
  row: Transaction & { type: string },
) => {
  const date = moment(row.date);
  if (date) {
    return <div>{date.format('h:mm A')}</div>;
  }

  return <div></div>;
};

/** Passed to datagrid for rendering an amount */
const renderAmount = (rowId: unknown, id: unknown, value: unknown, row: Transaction) => {
  return <Amount>{row.amount}</Amount>;
};

const renderBalance = (rowId: unknown, id: unknown, value: unknown, row: Transaction) => {
  return <Amount>{row.balance}</Amount>;
};

const renderReason = (
  rowId: unknown,
  id: unknown,
  value: unknown,
  row: Transaction & { type: string },
) => {
  return <div>{_.startCase(row.reason)}</div>;
};
@observer
class PartnerDetailsWallet extends React.Component<WalletProps> {
  constructor(props: WalletProps) {
    super(props);
    makeObservable(this);
  }

  @observable private refetchKey = Date.now();
  @observable private partner?: any;
  @observable public openCorrectionModal = false;

  @computed public get partnerId(): number {
    return parseInt(this.props.match.params.partnerId);
  }

  @computed public get walletId(): number | undefined {
    if (this.canMakeCorrection) return this.partner.walletId;
    return undefined;
  }

  @computed get canMakeCorrection() {
    if (!this.partner || !this.partner.walletId) return false;
    return true;
  }

  componentDidMount() {
    this.fetchPartner().then(() => {
      this.refetchKey = Date.now();
      if (this.partner) {
        setTitle(this.partner.name, { noSuffix: false });
      }
    });
  }

  @action.bound public getTransactions = adaptForDataGridPro(
    async (rmd: RequestMetaData) => {
      return await Api.tips.getWalletTransactions(this.partner.walletId, rmd);
    },
    (walletTransaction: any) => ({
      id: uuidv4(),
      ...walletTransaction,
    }),
  );

  private async fetchPartner() {
    const resp = await Api.tips.getPartner(this.partnerId);
    this.partner = resp.data.data;
  }

  gridColumns = [
    { headerName: 'Date', field: 'date', minWidth: 150, flex: 1 },
    { headerName: 'Time', field: 'time', minWidth: 150, flex: 1 },
    { headerName: 'Amount', field: 'amount', minWidth: 150, flex: 1 },
    { headerName: 'Reason', field: 'reason', minWidth: 150, flex: 1 },
    { headerName: 'Balance', field: 'balance', minWidth: 150, flex: 1 },
  ];

  setOpenCorrectionModal = (): any => {
    this.openCorrectionModal = true;
  };

  setCloseCorrectionModal = () => {
    this.openCorrectionModal = false;
  };

  refetchTransactions = () => {
    this.refetchKey = Date.now();
  };

  render() {
    return (
      <div>
        <Box mt={3}>
          {this.partner && (
            <DataGridInfiniteScroll
              columns={this.gridColumns}
              fetch={this.getTransactions}
              refetchKey={this.refetchKey}
              disableColumnMenu
              actions={{
                onAdd: {
                  name: 'Add New',
                  action: this.canMakeCorrection ? this.setOpenCorrectionModal : undefined,
                },
              }}
              pathname={this.props.location.pathname}
            />
          )}
          <PartnerWalletCorrectionModal
            open={this.openCorrectionModal}
            close={this.setCloseCorrectionModal}
            onCorrectionSuccess={this.refetchTransactions}
            walletId={this.walletId}
          />
        </Box>
      </div>
    );
  }
}

export default withStyles(styles)(PartnerDetailsWallet);
