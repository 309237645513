import Api, { getErrorMsg } from 'api';
import { useStores } from 'containers/App/App';
import { useState } from 'react';

interface UninstallIntegrationProps {
  closeDrawer: () => void;
  fetchApps: () => void;
}

type UninstallIntegration = [
  (integrationId: number) => Promise<void>,
  boolean,
  React.Dispatch<React.SetStateAction<boolean>>,
];

function useUninstallIntegration(props: UninstallIntegrationProps): UninstallIntegration {
  const [fieldUninstallError, setFieldUninstallError] = useState(true);
  const { toastStore } = useStores();

  const { fetchApps, closeDrawer } = props;

  async function removeIntegrationById(integrationId: number) {
    await Api.developer.removeIntegration(integrationId).finally(() => {
      fetchApps();
      closeDrawer();
    });
  }

  async function removeIntegration(integrationId: number) {
    try {
      await removeIntegrationById(integrationId);
      toastStore!.success('The integration has been removed successfully');
    } catch (e) {
      toastStore!.error(getErrorMsg(e));
    }
    setFieldUninstallError(true);
  }

  return [removeIntegration, fieldUninstallError, setFieldUninstallError];
}

export default useUninstallIntegration;
