import BaseStore from './BaseStore';
import { observable, action, makeObservable } from 'mobx';
import RootStore from './RootStore';

export default class EnrollStore extends BaseStore {
  constructor(props: RootStore) {
    super(props);
    makeObservable(this)
  }
  @observable public updateEnrollRebate: number = Date.now();
  @action.bound public updateEnrollRebateKey() {
    this.updateEnrollRebate = Date.now();
  }
  public init() {}
}

export interface WithEnrollStore {
  enrollStore?: EnrollStore;
}
