import { Theme } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: '16px',
      position: 'absolute',
      marginLeft: 'auto',
      marginRight: 'auto',
      color: theme.palette.text.primary,
      left: 0,
      right: 0,
      fontSize: '12px',
      fontWeight: 400,
      letterSpacing: '0.25em',
      width: 50,
      backgroundColor: 'transparent',
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
  }),
);

export default useStyles;
