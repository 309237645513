import clsx from 'clsx';
import React from 'react';
import { useStyles } from './styles';

export const Amount = ({ children }: { children?: string }) => {
  const classes = useStyles();
  const number = parseFloat(children || '0');
  const d = number.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
  return <span className={clsx(number >= 0 ? classes.positive : classes.negative)}>{d}</span>;
};
