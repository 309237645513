import { Box, CircularProgress, FormControlLabel, Typography } from '@material-ui/core';
import { WithStyles, withStyles } from '@material-ui/core/styles';
import { Autocomplete } from '@material-ui/lab';
import Api from 'api';
import Button from 'components/Button/Dialog/Button';
import { Checkbox } from 'components/Checkbox/Checkbox';
import OutlinedInput from 'components/Input/OutlinedInput';
import Overlay from 'components/Overlay';
import { action, computed, flow, observable, makeObservable } from 'mobx';
import { observer } from 'mobx-react';
import { Hardware, HardwareType } from 'models';
import React from 'react';
import { inject, WithToastStore } from 'stores';
import styles from './styles';

interface HardwareEntryProps extends WithStyles<typeof styles>, WithToastStore {
  onComplete?: () => void;
  title?: string;
  data?: Hardware;
}

/**
 * The component for the Hardware Entry item.
 */
@inject('userStore', 'toastStore')
@observer
class HardwareEntry extends React.Component<HardwareEntryProps> {
  constructor(props: HardwareEntryProps) {
    super(props);
    makeObservable(this);
  }

  /** The hardware currently chosen to edit */
  @observable public hardware: Hardware | undefined = undefined;

  /** The value for the manufacturer text field */
  @observable public manufacturer?: string = this.props.data ? this.props.data.manufacturer : '';

  /** The value for the model text field */
  @observable public model?: string = this.props.data ? this.props.data.model : '';

  /** The value for the hardwareType text field */
  @observable private hardwareType?: HardwareType = this.props.data
    ? this.props.data.type
    : undefined;

  /** The value for the requiresInventory checkbox field */
  @observable public requiresInventory: boolean | undefined = this.props.data
    ? this.props.data.requiresInventory
    : false;

  /** Updates the manufacturer text field */
  @action.bound public updateManufacturer(e: React.ChangeEvent<HTMLInputElement>) {
    this.manufacturer = e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1);
  }

  /** Updates the model text field */
  @action.bound public updateModel(e: React.ChangeEvent<HTMLInputElement>) {
    this.model = e.target.value;
  }

  /** Updates the hardwareType text field */
  @action.bound public updateHardwareType(e: React.ChangeEvent<HTMLInputElement>) {
    if (Object.values(HardwareType).includes(e.target.value as unknown as HardwareType)) {
      this.hardwareType = e.target.value as HardwareType;
    } else {
      this.hardwareType = undefined;
    }
  }

  /** Updates the requiresInventory checkbox field */
  @action.bound public updateRequiresInventory() {
    this.requiresInventory = !this.requiresInventory;
  }

  /** Whether the main button for submitting is disabled */
  @computed private get submitDisabled() {
    return !(this.manufacturer && this.model && this.hardwareType);
  }

  /** Get selected hardware item values */
  @computed private get getHardwareTypeValues() {
    return Object.values(HardwareType);
  }

  /** Whether the submission is currenlty in progress */
  @observable public submitting = false;

  /** Sends hardware items to the API */
  @action.bound public submit = flow(function* (this: HardwareEntry) {
    this.submitting = true;
    const hardwareData = {
      manufacturer: this.manufacturer,
      model: this.model,
      type: this.hardwareType as HardwareType,
      requiresInventory: this.requiresInventory,
    };

    try {
      this.props.data
        ? yield Api.fulfillment.updateHardware(this.props.data.id, hardwareData)
        : yield Api.fulfillment.addHardware(hardwareData);
      this.props.toastStore!.success(
        this.props.data ? 'Hardware item updated!' : 'Hardware item added!',
      );
      this.props.onComplete && this.props.onComplete();
    } catch (e: any) {
      this.props.toastStore!.error('Error submitting hardware info!');
    } finally {
      this.submitting = false;
    }
  });

  render() {
    const { classes } = this.props;
    return (
      <Box className={classes.root}>
        <Overlay display={this.submitting}>
          <CircularProgress />
        </Overlay>
        <Box>
          <Typography style={{ fontSize: 28 }} variant="h4" component="h1">
            {this.props.data ? 'Edit Hardware' : 'Add Hardware'}
          </Typography>
          <form onSubmit={this.submit}>
            <Box mb={2} mt={4}>
              <OutlinedInput
                value={this.manufacturer}
                onChange={this.updateManufacturer}
                label="Manufacturer"
                fullWidth
              />
            </Box>
            <Box mb={2}>
              <OutlinedInput
                value={this.model}
                onChange={this.updateModel}
                label="Model"
                fullWidth
              />
            </Box>
            <Box mb={2}>
              <Autocomplete
                disablePortal
                disableClearable={true}
                value={this.hardwareType ? this.hardwareType : ''}
                id="hardwareTypeSelect"
                onSelect={this.updateHardwareType}
                options={this.getHardwareTypeValues}
                renderInput={(params) => <OutlinedInput {...params} label="Type" />}
              />
            </Box>
            <Box mb={2.375}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={this.requiresInventory}
                    onChange={this.updateRequiresInventory}
                  />
                }
                label="Requires items with serial number"
              />
            </Box>
          </form>
        </Box>
        <Box mb={2} flex>
          <Button
            variant="contained"
            color="primary"
            onClick={this.submit}
            disabled={this.submitDisabled}>
            {this.props.data ? 'Save' : `Add`}
          </Button>
        </Box>
      </Box>
    );
  }
}

export default withStyles(styles)(HardwareEntry);
