import { Link } from '@material-ui/core';
import ActionsMenu, { PositionMenu } from 'components/ActionsMenu';
import { observer } from 'mobx-react';
import React from 'react';
import { WithStyles, withStyles } from '@material-ui/core/styles';
import styles from './styles';
import { observable, makeObservable, computed } from 'mobx';
import Api, { getErrorMsg, RequestMetaData } from 'api';
import FeeScheduleDetailsDrawer from './FeeScheduleDetailsDrawer';
import FeeScheduleDrawer from './FeeScheduleDrawer';
import { FeeScheduleStatus, FeeScheduleType, IFeeScheduleResponse } from 'models/FeeSchedule';
import { inject, WithToastStore } from 'stores';

import { adaptForDataGridPro } from 'services';
import { Filter } from 'components/FilterBar/FilterBar';
import FilterBar from 'components/FilterBar';
import DataGridInfiniteScroll from 'components/DataGridInfiniteScroll';
import { v4 as uuidv4 } from 'uuid';
import OwerflowTooltip from '../../../components/OverflowTooltip';
import * as DateRangeExternalPicker from 'components/DateRangeExternalPicker';
import { Box } from '@mui/material';
import PlusFabButton from 'components/PlusFabButton/PlusFabButton';
import ChipStatusTag, { ChipStatusColors } from 'components/ChipStatusTag';
import { RouteComponentProps } from 'react-router-dom';
import { FEE_TYPE_MAPPER } from './FeeScheduleHelper';
import { GridColDef, GridRenderCellParams, GridRowModel } from '@mui/x-data-grid-pro';

interface IBadgeConfig {
  PUBLISHED: string;
  DRAFT: string;
  DEACTIVATED: string;
  DELETED: string;
}

const badgeConfig: IBadgeConfig = {
  PUBLISHED: ChipStatusColors.GREEN,
  DRAFT: ChipStatusColors.YELLOW,
  DEACTIVATED: ChipStatusColors.GREY,
  DELETED: ChipStatusColors.RED,
};

type FeeScheduleProps = WithStyles<typeof styles> &
  WithToastStore &
  RouteComponentProps & { canPerformActions: boolean };

interface AssignedTo {
  processor?: string;
  app?: { name: string };
  account?: { name: string };
  location?: { name: string };
}

interface IFeeActionsOptions {
  label: string;
  action: () => Promise<void>;
}

@inject('toastStore')
@observer
class FeeSchedule extends React.Component<FeeScheduleProps> {
  constructor(props: any) {
    super(props);
    makeObservable(this);
  }

  @observable isDetailsOpen = false;
  @observable isScheduleDrawerOpen = false;
  @observable feeScheduleId: number | undefined = undefined;
  @observable public dataGridKey = Date.now();

  /** Active filters as returned by FilterBar */
  @observable private activeFilters: Record<string, unknown> = {};

  /** The selected date range */
  @observable public dateRange: DateRangeExternalPicker.DateRange =
    DateRangeExternalPicker.getDateRange();

  private createFeeString = ({ feePercent, feeAmount }: IFeeScheduleResponse): string =>
    `${feePercent}% + $${feeAmount}`;

  private createAssignedToString = (feeSchedule: AssignedTo): string => {
    const { processor, app, account, location } = { ...feeSchedule };
    const u = '';
    const d = ' - ';
    let assignedTo = `
    ${processor ? processor + d : u}${app ? app.name + d : u}${account ? account.name + d : u}${
      location ? location.name : u
    }`;
    if (assignedTo.slice(-3) === d) assignedTo = assignedTo.slice(0, assignedTo.length - 3);
    if (assignedTo.slice(3) === d) assignedTo = assignedTo.slice(3);
    return assignedTo;
  };

  /**
   * Adapts the api fetch function for datagrid consumption.
   */

  private getFeeScheduleData = adaptForDataGridPro(
    async (rmd: RequestMetaData) =>
      await Api.tips.getFeeSchedules({
        ...rmd,
        filters: this.activeFilters,
        sort: { sortBy: 'id', sortOrder: 'DESC' },
      }),
    (feeSchedule: IFeeScheduleResponse) => ({
      ...feeSchedule,
      partners: feeSchedule.rules?.length ?? 0,
      fee: this.createFeeString(feeSchedule),
      assignedTo: this.createAssignedToString(feeSchedule),
      status: feeSchedule.status.toUpperCase(),
      type: feeSchedule.type,
    }),
  );

  getAction = async (row: GridRowModel, status: FeeScheduleStatus): Promise<void> => {
    try {
      if (status === FeeScheduleStatus.DELETED) await Api.tips.deleteFeeSchedule(row.id);
      else await Api.tips.updateFeeSchedule(row.id, { status });
      this.props.toastStore!.push({ type: 'success', message: 'Status updated successfully' });
      this.dataGridKey = Date.now();
    } catch (error) {
      this.props.toastStore!.push({ type: 'error', message: getErrorMsg(error) });
    }
  };

  getOptions = (row: GridRowModel): IFeeActionsOptions[] => {
    const publish = {
      label: 'Publish',
      action: async () => this.getAction(row, FeeScheduleStatus.PUBLISHED),
    };
    const unpublish = {
      label: 'Unpublish',
      action: async () => this.getAction(row, FeeScheduleStatus.DRAFT),
    };
    const _delete = {
      label: 'Delete',
      action: async () => this.getAction(row, FeeScheduleStatus.DELETED),
    };

    switch (row.status.toLocaleLowerCase()) {
      case FeeScheduleStatus.DRAFT:
        return [publish, _delete];
      case FeeScheduleStatus.PUBLISHED:
        return [unpublish, _delete];
      default:
        return [publish, unpublish, _delete];
    }
  };

  renderName = ({ row }: GridRenderCellParams): JSX.Element => (
    <OwerflowTooltip id={`${row.name}-${uuidv4()}`}>
      <Link
        onClick={() => this.openDetailsDrawer(row.id)}
        underline="none"
        style={{ cursor: 'pointer' }}>
        {row.name}
      </Link>
    </OwerflowTooltip>
  );

  renderActionsColumn = ({ row }: GridRenderCellParams) => (
    <ActionsMenu options={this.getOptions(row)} position={PositionMenu.VERTICAL} />
  );

  renderCellName = ({ row }: GridRenderCellParams): JSX.Element => (
    <OwerflowTooltip id={`${row.name}-${uuidv4()}`}>
      <Link
        onClick={() => this.openDetailsDrawer(row.id)}
        underline="none"
        style={{ cursor: 'pointer' }}>
        {row.name}
      </Link>
    </OwerflowTooltip>
  );

  renderCellType = ({ row }: GridRenderCellParams): string =>
    FEE_TYPE_MAPPER[row.type as FeeScheduleType];

  renderCellAssignedTo = ({ row: { assignedTo } }: GridRenderCellParams): JSX.Element => (
    <OwerflowTooltip className={this.props.classes.assignedTo} title={assignedTo} interactive>
      <Box>{assignedTo}</Box>
    </OwerflowTooltip>
  );

  renderCellStatus = ({ row }: GridRenderCellParams): JSX.Element => {
    const background = badgeConfig[row.status as keyof IBadgeConfig] as ChipStatusColors;
    return <ChipStatusTag label={row.status} color={background} />;
  };

  @computed get gridColumns(): GridColDef[] {
    const gridColumns: GridColDef[] = [
      {
        headerName: 'Name',
        field: 'name',
        minWidth: 250,
        flex: 1,
        renderCell: this.renderCellName,
      },
      {
        headerName: 'Type',
        field: 'type',
        minWidth: 250,
        flex: 1,
        renderCell: this.renderCellType,
      },
      { headerName: 'Partners', field: 'partners', minWidth: 150 },
      { headerName: 'Fee', field: 'fee', minWidth: 250, flex: 1 },
      {
        headerName: 'Assigned To',
        field: 'assignedTo',
        minWidth: 300,
        flex: 1,
        renderCell: this.renderCellAssignedTo,
      },
      {
        headerName: 'Status',
        field: 'status',
        width: 150,
        minWidth: 200,
        renderCell: this.renderCellStatus,
      },
      {
        renderHeader: () => <></>,
        field: 'render',
        renderCell: this.renderActionsColumn,
      },
    ];

    if (this.props.canPerformActions) {
      return gridColumns;
    }
    return gridColumns.filter((column) => column.field !== 'render');
  }

  filters: Filter[] = [
    { display: 'Name', id: 'name', label: 'Contains', type: 'text' },
    {
      display: 'Type',
      id: 'type',
      label: 'Type',
      type: 'select',
      items: Object.entries(FEE_TYPE_MAPPER).map(([val, key]) => ({ label: key, value: val })),
    },
    {
      display: 'Status',
      id: 'status',
      label: 'One of',
      type: 'select',
      items: [
        { label: 'PUBLISHED', value: FeeScheduleStatus.PUBLISHED },
        { label: 'DELETED', value: FeeScheduleStatus.DELETED },
        { label: 'DRAFT', value: FeeScheduleStatus.DRAFT },
        { label: 'DEACTIVATED', value: FeeScheduleStatus.DEACTIVATED },
      ],
    },
  ];

  openDetailsDrawer = (id: number): void => {
    this.feeScheduleId = id;
    this.isDetailsOpen = true;
  };

  closeDetailsDrawer = (): void => {
    this.isDetailsOpen = false;
  };

  openFeeScheduleDrawer = (): void => {
    this.isScheduleDrawerOpen = true;
  };

  closeFeeScheduleDrawer = (): void => {
    this.isScheduleDrawerOpen = false;
  };

  triggerDataRefetch = (): void => {
    this.dataGridKey = Date.now();
  };

  render() {
    return (
      <>
        <Box mt={3}>
          <FilterBar
            filters={this.filters}
            onChange={(filters: Record<string, unknown>) => {
              this.activeFilters = filters;
            }}
          />
          <DataGridInfiniteScroll
            key={this.dataGridKey}
            columns={this.gridColumns}
            fetch={this.getFeeScheduleData}
            refetchKey={this.activeFilters}
            disableColumnMenu
            pathname={this.props.location.pathname}
          />
        </Box>
        <PlusFabButton hide={!this.props.canPerformActions} onClick={this.openFeeScheduleDrawer} />
        <FeeScheduleDrawer
          triggerDataRefetch={this.triggerDataRefetch}
          openFeeScheduleDrawer={this.openFeeScheduleDrawer}
          closeFeeScheduleDrawer={this.closeFeeScheduleDrawer}
          isScheduleDrawerOpen={this.isScheduleDrawerOpen}
        />
        <FeeScheduleDetailsDrawer
          canPerformActions={this.props.canPerformActions}
          triggerDataRefetch={this.triggerDataRefetch}
          feeScheduleId={this.feeScheduleId as number}
          closeDrawer={this.closeDetailsDrawer}
          isDetailsOpen={this.isDetailsOpen}
        />
      </>
    );
  }
}

export default withStyles(styles)(FeeSchedule);
