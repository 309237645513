import React from 'react';
import useStyles from './styles';
import { AppBar, Toolbar, CssBaseline, CardMedia } from '@material-ui/core';

import ElevationScroll from 'components/ElevationScroll';
import TippyLogo from '../../images/tippy_logo_pig@3x.png';

interface InquiryHeaderProps {
  page?: string;
  headerLogo?: string;
  headerColor?: string;
  headerHeight?: number;
  headerLogoWidth?: string | number;
  headerLogoHeight?: string | number;
  upperBarColor?: string;
  upperBarHeight?: number;
}

/** Displays a card for a single user */
function InquiryHeader(props: InquiryHeaderProps) {
  const classes = useStyles();

  const {
    page = 'Tippy',
    headerLogo = TippyLogo,
    headerColor = 'green',
    headerHeight = 88,
    headerLogoWidth = '100%',
    headerLogoHeight = '100%',
    upperBarColor = 'green',
    upperBarHeight = 6,
  } = props;

  const appBarStyle = {
    backgroundColor: headerColor,
    borderTop: `${upperBarHeight}px solid ${upperBarColor ? upperBarColor : headerColor}`,
    height: headerHeight,
  };

  const cardMediaStyle = {
    width: headerLogoWidth,
    height: headerLogoHeight,
  };

  return (
    <>
      <CssBaseline />
      <ElevationScroll {...props}>
        <AppBar position="fixed" style={appBarStyle}>
          <Toolbar className={classes.toolbar}>
            <CardMedia
              className={classes.logo}
              image={headerLogo}
              title={`${page} logo`}
              style={cardMediaStyle}
            />
          </Toolbar>
        </AppBar>
      </ElevationScroll>
      <Toolbar />
    </>
  );
}

export default InquiryHeader;
