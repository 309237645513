import { Box } from '@material-ui/core';
import { ReferralPanel } from '../ReferralPanel/ReferralPanel';
import Button from 'components/Button/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane } from '@fortawesome/pro-regular-svg-icons';
import { formSwitchStyles, referralFormStyles } from '../styles';
import { useState } from 'react';
import { useForm, FieldValues } from 'react-hook-form';
import {
  EFormFields,
  IFormFieldsConfig,
  FORM_EMAIL,
  FORM_PHONE,
  FormContainer,
  FORM_TEXT,
  EFormFieldLabels,
} from '../../../utils/forms';
import { IReferralCreate } from 'models';

enum EInviteByType {
  EMAIL = 'email',
  PHONE = 'phone',
}

interface IReferralForm {
  [EFormFields.NAME]: string;
  [EFormFields.EMAIL]: string;
  [EFormFields.PHONE]: string;
}

// Create fields config
const nameField: IFormFieldsConfig = FORM_TEXT(true, false, EFormFieldLabels.FIRST_NAME);
const emailField: IFormFieldsConfig = FORM_EMAIL();
const phoneField: IFormFieldsConfig = FORM_PHONE();

const introText = (type: EInviteByType, changeTypeHandler: () => void) => {
  const labelsObj =
    type === EInviteByType.EMAIL
      ? { text1: 'email addresses', text2: 'Phone' }
      : { text1: 'phone numbers', text2: 'Email' };
  return (
    <Box>
      Insert your friends {labelsObj.text1} to send them invitations to join Tippy!{' '}
      <Box component="span" sx={formSwitchStyles} onClick={changeTypeHandler}>
        Invite by {labelsObj.text2}
      </Box>
    </Box>
  );
};

interface InviteFriendProps {
  submitReferral: (val: IReferralCreate) => void;
}

export const InviteFriend = ({ submitReferral }: InviteFriendProps) => {
  const [inviteType, setInviteType] = useState(EInviteByType.EMAIL);
  const [formField, setFormField] = useState(emailField);

  const {
    handleSubmit,
    formState: { errors, isValid },
    control,
  } = useForm<FieldValues>({
    defaultValues: {
      [nameField.name]: '',
      [emailField.name]: '',
      [phoneField.name]: '',
    },
    mode: 'onChange',
  });

  const onFormSubmit = ({ name, email, phone }: IReferralForm) => {
    inviteType === EInviteByType.EMAIL
      ? submitReferral({ invitedName: name, email, channel: 'email' })
      : submitReferral({ invitedName: name, phone, channel: 'phone' });
  };

  const changeTypeHandler = () => {
    if (inviteType === EInviteByType.PHONE) {
      setInviteType(EInviteByType.EMAIL);
      setFormField(emailField);
    } else {
      setInviteType(EInviteByType.PHONE);
      setFormField(phoneField);
    }
  };

  return (
    <ReferralPanel title="Invite with email or SMS" lg={6}>
      <Box>
        {introText(inviteType, changeTypeHandler)}
        <Box sx={referralFormStyles}>
          <FormContainer
            formFieldsConfig={[nameField, formField]}
            control={control}
            errors={errors}
            marginBottom={0}
            handleSubmit={handleSubmit}
            onFormSubmit={(data) => onFormSubmit(data as IReferralForm)}
          />
          <Button
            startIcon={<FontAwesomeIcon icon={faPaperPlane} />}
            disabled={!isValid}
            className="referral-form-action"
            variant="contained"
            onClick={handleSubmit((data) => onFormSubmit(data as IReferralForm))}>
            Invite
          </Button>
        </Box>
      </Box>
    </ReferralPanel>
  );
};
