import { useState } from 'react';
import { rootStore } from 'containers/App/App';
import { useWorkspaceContext } from './WorkspaceContext';

const useWorkspace = () => {
  const { error, locationUsers, accountSections, filteredLocationUsers, getUserLocations } =
    useWorkspaceContext();
  const [open, setOpenDialog] = useState(false);

  const { userStore } = rootStore;
  const { isAdmin, isManagerScope, isOwnerScope, isGlobalOwnerScope } = userStore;
  const currentLocationUser = filteredLocationUsers && filteredLocationUsers[0];
  const isLocationManager = locationUsers?.some((user) => user.isManager);
  const showLoader =
    ((isAdmin || isOwnerScope || isGlobalOwnerScope) && !accountSections) ||
    (isManagerScope && !currentLocationUser);

  return {
    isManagerScope,
    isLocationManager,
    currentLocationUser,
    accountSections,
    locationUsers,
    error,
    open,
    showLoader,
    setOpenDialog,
    getUserLocations,
  };
};

export default useWorkspace;
