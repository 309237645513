import * as models from '.';

export enum ETransferRequestAction {
  APPROVE = 'approve',
  REJECT = 'reject',
}

export enum ETransferRequestStatus {
  CREATED = 'created',
  APPROVED = 'approved',
  REJECTED = 'rejected',
}

export interface ITransferRequest {
  /**
   * Represents transfer request id
   */
  id: number;

  /**
   * Represents a reference to the tip for which this transfer request is created.
   */
  tipId: number;

  /**
   * Represents tip for which transfer request was created
   */
  tip: models.Tip;

  /**
   * ID of the wallet from which funds are being transferred.
   */
  fromWalletId: number;

  /**
   * ID of the user who owns the fromWallet.
   */
  fromUserId: number;

  /**
   * User entity who owns the fromWallet.
   */
  fromUser: models.User;

  /**
   * ID of the wallet to which funds are being transferred.
   */
  toWalletId: number;

  /**
   * Wallet entity from which funds are being transferred.
   */
  fromWallet: models.Wallet;

  /**
   * Wallet entity to which funds are being transferred.
   */
  toWallet: models.Wallet;

  /**
   * ID of the user who owns the toWallet.
   */
  toUserId: number;

  /**
   * User entity who owns the toWallet.
   */
  toUser: models.User;

  /**
   * Amount being transferred.
   */
  amount: string;

  /**
   * Current status of the transfer request.
   */
  status: ETransferRequestStatus;

  /**
   * Reason for the transfer.
   */
  reason: string;

  /**
   * Rejection reason for the transfer.
   */
  rejectionReason: string;

  /**
   * Notes from the system regarding the transfer.
   */
  systemNote: string;

  /**
   * ID of the user who requested the transfer.
   */
  requestedById: number;

  /**
   * User entity who requested the transfer.
   */
  requestedBy: models.User;

  /**
   * ID of the user who resolved the transfer request.
   */
  resolvedById: number;

  /**
   * User entity who resolved the transfer request.
   */
  resolvedBy: models.User;

  /**
   * Timestamp when the transfer was resolved.
   */
  resolvedAt: Date;

  /**
   * ID of the account associated with this transfer request.
   */
  accountId: number;

  /**
   * Account entity associated with this transfer request.
   */
  account: models.Account;

  /**
   * ID of the location associated with this transfer request.
   */
  locationId: number;

  /**
   * Location entity associated with this transfer request.
   */
  location: models.Location;
}
