import React, { useState, useCallback, useEffect } from 'react';
import {
  List,
  ListItem,
  Box,
  ListItemText,
  ListItemSecondaryAction,
  Typography,
} from '@material-ui/core';
import { filter } from 'lodash';
import moment from 'moment';
import clsx from 'clsx';
import TextField from '@mui/material/TextField';
import { v4 as uuidv4 } from 'uuid';
import { WithStyles, withStyles } from '@material-ui/core/styles';
import { DateRange as MuiDateRange } from '@mui/x-date-pickers-pro/DateRangePicker';
import { StaticDateRangePicker } from '@mui/x-date-pickers-pro/StaticDateRangePicker';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import styles, { useCustomPickerStyles } from './styles';
import { IntervalOptions } from '../FilterBar';
import TimerPicker from './TimerPicker';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { rootStore } from 'containers/App/App';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faChevronRight } from '@fortawesome/pro-regular-svg-icons';
import { EDateFormat } from 'utils/helper';

// export const isoDateFormat = 'YYYY-MM-DD, H:mm A';
export const isoDateFormat = 'YYYY-MM-DD';

interface OptionDate {
  label: string;
  type: string;
  extend?: boolean;
}

// const startTimer = { hour: 0, minute: 0 };
// const endTimer = { hour: 0, minute: 0 };

export const listOptionDate: OptionDate[] = [
  { label: 'Custom', type: 'date-timer', extend: true },
  { label: 'Today', type: 'today' },
  { label: 'Yesterday', type: 'yesterday' },
  { label: 'This Week', type: 'thisWeek' },
  { label: 'Last Week', type: 'lastWeek' },
  { label: 'This Month', type: 'thisMonth' },
  { label: 'Last Month', type: 'lastMonth' },
  { label: 'This Year', type: 'thisYear' },
  { label: 'Last Year', type: 'lastYear' },
  { label: 'Last Thirty Days', type: 'lastThirtyDays' },
  { label: 'All', type: 'all' },
];

export interface DateRange {
  fromDate?: any;
  toDate?: any;
  type?: string;
}

export enum ECustomPickerViewMode {
  DATE_TIMER = 'date-timer',
  MENU = 'menu',
}

interface CustomPickerProps {
  onChange: (range: DateRange) => void;
  handleOpened?: (status: boolean) => void;
  selected: DateRange;
  interval?: IntervalOptions;
  //This was added to accomodate back functionality for date picker on mobile views
  viewMode?: ECustomPickerViewMode;
}

const getViewMode = (selectedRange: DateRange) => {
  if (rootStore.uiStore.mobileView) return 'menu';
  return selectedRange.type === 'date-timer' ? 'date-timer' : 'menu';
};

export const getDateRange = (type = 'lastWeek'): DateRange => {
  switch (type) {
    case 'today':
      return {
        // fromDate: moment().set(startTimer).format(),
        // toDate: moment().set(endTimer).format(),
        fromDate: moment().format(isoDateFormat),
        toDate: moment().format(isoDateFormat),
        type: 'today',
      };
    case 'yesterday':
      return {
        // fromDate: moment().set(startTimer).subtract(1, 'days').format(),
        // toDate: moment().set(endTimer).subtract(1, 'days').format(),
        fromDate: moment().subtract(1, 'days').format(isoDateFormat),
        toDate: moment().subtract(1, 'days').format(isoDateFormat),
        type: 'yesterday',
      };
    case 'thisWeek':
      return {
        // fromDate: moment().set(startTimer).startOf('isoWeek').format(),
        // toDate: moment().set(endTimer).format(),
        fromDate: moment().startOf('isoWeek').format(isoDateFormat),
        toDate: moment().format(isoDateFormat),
        type: 'thisWeek',
      };
    case 'lastWeek':
      return {
        // fromDate: moment()
        //   .set(startTimer)
        //   .startOf('isoWeek')
        //   .subtract(1, 'weeks')
        //   .format(),
        // toDate: moment().set(endTimer).endOf('isoWeek').subtract(1, 'weeks').format(),
        fromDate: moment().startOf('isoWeek').subtract(1, 'weeks').format(isoDateFormat),
        toDate: moment().endOf('isoWeek').subtract(1, 'weeks').format(isoDateFormat),
        type: 'lastWeek',
      };
    case 'thisMonth':
      return {
        // fromDate: moment().set(startTimer).startOf('month').format(),
        // toDate: moment().set(endTimer).format(),
        fromDate: moment().startOf('month').format(isoDateFormat),
        toDate: moment().format(isoDateFormat),
        type: 'thisMonth',
      };
    case 'lastMonth':
      return {
        // fromDate: moment()
        //   .set(startTimer)
        //   .subtract(1, 'month')
        //   .startOf('month')
        //   .format(),
        // toDate: moment().set(endTimer).subtract(1, 'month').endOf('month').format(),
        fromDate: moment().subtract(1, 'month').startOf('month').format(isoDateFormat),
        toDate: moment().subtract(1, 'month').endOf('month').format(isoDateFormat),
        type: 'lastMonth',
      };
    case 'thisYear':
      return {
        fromDate: moment().startOf('year').format(isoDateFormat),
        toDate: moment().format(isoDateFormat),
        // fromDate: moment().set(startTimer).startOf('year').format(),
        // toDate: moment().set(endTimer).format(),
        type: 'thisYear',
      };

    case 'lastYear':
      return {
        fromDate: moment().subtract(1, 'year').startOf('year').format(isoDateFormat),
        toDate: moment().subtract(1, 'year').endOf('year').format(isoDateFormat),
        // fromDate: moment()
        //   .set(startTimer)
        //   .subtract(1, 'year')
        //   .startOf('year')
        //   .format(),
        // toDate: moment().set(endTimer).subtract(1, 'year').endOf('year').format(),
        type: 'lastYear',
      };

    case 'lastThirtyDays':
      return {
        fromDate: moment().subtract(30, 'days').format(isoDateFormat),
        toDate: moment().format(isoDateFormat),
        // fromDate: moment().set(startTimer).subtract(30, 'days').format(),
        // toDate: moment().set(endTimer).format(),
        type: 'lastThirtyDays',
      };
    default:
      return {
        fromDate: undefined,
        toDate: undefined,
        type: 'all',
      };
  }
};

function CustomPicker({
  onChange,
  handleOpened,
  selected,
  interval,
  classes,
  viewMode: _viewMode,
}: CustomPickerProps & WithStyles<typeof styles>) {
  const [viewMode, setViewMode] = useState(() => getViewMode(selected));
  const { listItemStyles, text, checkIcon, chevronIcon, dateRange } = useCustomPickerStyles();

  const [custom, setCustom] = useState<MuiDateRange<Date>>(
    selected.type === 'date-timer' ? [selected.fromDate, selected.toDate] : [null, null],
  );

  const mobileView = rootStore.uiStore?.mobileView;
  const displayStaticWrapperAs = mobileView ? 'mobile' : 'desktop';

  const handleSetMode = useCallback(
    (mode) => {
      setViewMode(mode);
    },
    [setViewMode],
  );

  const handleSetCustom = useCallback(
    (date: MuiDateRange<Date>) => {
      setCustom(date);
    },
    [setCustom],
  );

  const handleCustomDateRange = useCallback(
    (value: MuiDateRange<Date>) => {
      let isValid = true;
      let startValue = value[0];
      let endValue = value[1];

      if (startValue !== null) {
        // const newStartDate = moment(startValue).set(startTimer).format();
        const newStartDate = moment(startValue).format();
        startValue = new Date(newStartDate);
      }

      if (endValue !== null) {
        // const newEndDate = moment(endValue).set(endTimer).format();
        const newEndDate = moment(endValue).format();
        endValue = new Date(newEndDate);
      }

      // if (!custom.includes(null) && endValue !== null) {
      //   isValid = false;
      //   handleSetCustom([startValue, null]);
      // } else {
      handleSetCustom(value);
      // }

      if (startValue !== null && endValue !== null && isValid) {
        // handleOpened && handleOpened(false);
        // onChange &&
        //   onChange({
        //     fromDate: startValue,
        //     toDate: endValue,
        //     type: 'date-timer',
        //   });
      }
    },
    [custom, handleOpened, handleSetCustom, onChange],
  );

  const handleDateRange = useCallback(
    (type) => {
      const value = getDateRange(type);
      handleOpened && handleOpened(false);
      onChange && onChange(value);
    },
    [handleOpened, onChange],
  );

  useEffect(() => {
    return () => {
      handleSetCustom([null, null]);
    };
  }, []);

  //This was added to accomodate back functionality for date picker on mobile views
  useEffect(() => {
    if (!_viewMode || _viewMode !== ECustomPickerViewMode.MENU || _viewMode === viewMode) return;
    setViewMode(_viewMode);
  }, [_viewMode]);

  const isDateTimerType = selected?.type === 'date-timer';

  return (
    <List disablePadding style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
      {viewMode === 'menu' &&
        listOptionDate &&
        filter(listOptionDate, ({ type }) => type).map(({ label, type, extend }: any) => (
          <Box key={uuidv4()}>
            <ListItem
              button
              disableRipple={mobileView}
              classes={{ root: clsx(mobileView && listItemStyles) }}
              selected={selected?.type === type}
              onClick={() => (extend ? handleSetMode(type) : handleDateRange(type))}
              data-cy={`${label}-date-range-option`.toLowerCase().replaceAll(' ', '-')}>
              <ListItemText
                disableTypography={selected?.type === type && !isDateTimerType}
                className={clsx(
                  { textTransform: 'capitalize', marginRight: '48px' },
                  mobileView && text,
                )}
                primary={label}
              />
              {extend && (
                <>
                  {mobileView && selected && isDateTimerType && (
                    <Typography component="span" className={dateRange}>
                      {moment(selected.fromDate).format(EDateFormat.FULL_MONTH_DAY)} -{' '}
                      {moment(selected.toDate).format(EDateFormat.FULL_MONTH_DAY)}
                    </Typography>
                  )}
                  <FontAwesomeIcon icon={faChevronRight} className={chevronIcon} />
                </>
              )}
              {selected && selected.type === type && !isDateTimerType && mobileView && (
                <ListItemSecondaryAction>
                  <FontAwesomeIcon icon={faCheck} className={checkIcon} />
                </ListItemSecondaryAction>
              )}
            </ListItem>
          </Box>
        ))}
      {viewMode === 'date-timer' && (
        <TimerPicker
          classes={{ root: classes.timerPicker }}
          isMobile={mobileView}
          onChangeMode={handleSetMode}
          onChangeValue={handleSetCustom}
          onChange={onChange}
          onOpen={handleOpened}
          interval={interval}
          value={custom}>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <StaticDateRangePicker
              displayStaticWrapperAs={displayStaticWrapperAs}
              className={classes.dateRange}
              value={custom}
              inputFormat={isoDateFormat}
              onChange={handleCustomDateRange}
              renderInput={(startProps, endProps) => (
                <React.Fragment>
                  <TextField {...startProps} />
                  <Box sx={{ mx: 2 }}> to </Box>
                  <TextField {...endProps} />
                </React.Fragment>
              )}
            />
          </LocalizationProvider>
        </TimerPicker>
      )}
    </List>
  );
}

export default withStyles(styles)(CustomPicker);
