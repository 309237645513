import { List, ListItem, ListItemText, Typography, Divider, Box } from '@material-ui/core';
import clsx from 'clsx';
import { CompletedCarts } from 'models';
import { getShippingItems, getOtherItems, getShippingCost } from '../bl';
import { useStyles } from '../styles';
import { InvoiceItem } from '../InvoiceItem/InvoiceItem';
import { useMemo } from 'react';

interface InvoiceComponentProps {
  invoice: CompletedCarts;
}

export const InvoiceData = ({ invoice }: InvoiceComponentProps) => {
  const classes = useStyles();

  const { invoiceItems } = invoice;

  const { otherItems, shippingCost } = useMemo(() => {
    const shippingItems = getShippingItems(invoiceItems);
    const otherItems = getOtherItems(invoiceItems);
    const shippingCost = getShippingCost(shippingItems);

    return { otherItems, shippingCost };
  }, [invoiceItems]);

  return (
    <List>
      {otherItems?.map((item) => (
        <InvoiceItem key={item.id} invoiceItem={item} />
      ))}
      <ListItem disableGutters>
        <ListItemText
          primary={
            <Typography component="span" style={{ textTransform: 'uppercase' }}>
              Shipping
            </Typography>
          }
        />
        <Box justifyContent="center" alignItems="center" flexBasis="1">
          <Typography component="div">{shippingCost ? `$${shippingCost}` : '$0'}</Typography>
        </Box>
      </ListItem>
      {invoice.discount !== '$0.00' && (
        <ListItem disableGutters>
          <ListItemText
            primary={
              <Typography component="span" style={{ textTransform: 'uppercase' }}>
                Discount
              </Typography>
            }
          />
          <Box justifyContent="center" alignItems="center" flexBasis="1">
            <Typography component="div">-{invoice.discount}</Typography>
          </Box>
        </ListItem>
      )}
      {invoice.tax !== '$0.00' && (
        <ListItem disableGutters>
          <ListItemText
            primary={
              <Typography component="span" style={{ textTransform: 'uppercase' }}>
                Tax
              </Typography>
            }
          />
          <Box justifyContent="center" alignItems="center" flexBasis="1">
            <Typography component="div">{invoice.tax}</Typography>
          </Box>
        </ListItem>
      )}
      <Divider />
      {invoice.gross && (
        <ListItem divider disableGutters>
          <ListItemText
            primary={
              <Typography className={clsx(classes.itemAmount)} component="span">
                Total Paid
              </Typography>
            }
          />
          <Box justifyContent="center" alignItems="center" flexBasis="1">
            <Typography className={clsx(classes.itemAmount)} component="div">
              {invoice.gross}
            </Typography>
          </Box>
        </ListItem>
      )}
    </List>
  );
};
