import React from 'react';
import { observer } from 'mobx-react';
import { CircularProgress } from '@material-ui/core';
import { ButtonProps } from '@material-ui/core/Button';

import { useStyles } from './styles';
import Button from 'components/Button/Button';

interface SubmitButtonProps extends ButtonProps {
  inProgress?: boolean;
}
/**
 * A submit button that supports progress indication
 */
const SubmitButton: React.FC<SubmitButtonProps> = observer(
  ({ children, inProgress = false, ...rest }) => {
    const classes = useStyles();
    return (
      <div className={classes.buttonWrapper}>
        <Button
          disableElevation
          fullWidth
          variant="contained"
          color="primary"
          type="submit"
          disabled={inProgress}
          {...rest}>
          {children}
        </Button>
        {inProgress && <CircularProgress size={24} className={classes.buttonProgress} />}
      </div>
    );
  },
);

export default SubmitButton;
