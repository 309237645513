import { Theme, createStyles } from '@material-ui/core';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default (theme: Theme) =>
  createStyles({
    root: {},
    titleInput: {
      // width: theme.spacing(100),
      fontSize: theme.typography.h4.fontSize,
      fontFamily: theme.typography.h4.fontFamily,
      color: theme.palette.text.primary,
      fontWeight: 600,
      lineHeight: '43px',
    },
    editIcon: {
      color: theme.palette.primary.main,
      padding: 0,
    },
    titleLight: {
      color: theme.palette.text.primary,
      fontWeight: 600,
    },
    button: {
      padding: 0,
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
  });
