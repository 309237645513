import React from 'react';
import {
  observable,
  action,
  flow,
  computed,
  makeObservable,
  IReactionDisposer,
  reaction,
} from 'mobx';
import { observer } from 'mobx-react';
import { Link as RLink } from 'react-router-dom';
import {
  Box,
  Typography,
  CircularProgress,
  Link,
  IconButton,
  Dialog,
  DialogTitle,
  Tooltip,
} from '@material-ui/core';
import { WithStyles, withStyles } from '@material-ui/core/styles';

import { getErrorMsg } from 'api';
import {
  inject,
  WithToastStore,
  WithModalStore,
  WithUserStore,
  WithManagerPermissionsStore,
} from 'stores';
import DP from 'components/DashPanel';
import { User } from 'models';
import ImageIcon from 'components/ImageIcon';
import { paths } from 'routes';
import { Close, Check } from 'mdi-material-ui';
import Overlay from 'components/Overlay';
import styles from './styles';
import { EManagerPermission } from 'types';
import Button from 'components/Button/Dialog/Button';

/**
 * Displays a single user waiting for approval in the approval panel
 * @param children The user to display
 * @param locationId The location id that the user is waiting for approval on
 * @param locationId The location name that the user is waiting for approval on, optiona
 * @param approve The function to approve the user
 * @param reject The function to reject the user
 * @param canApprove Whether the user can approve or reject talents
 * @param approveText The text to display for the approve button. If undefined, check is displayed
 */
function UserItem({
  children: user,
  locationId,
  locationName,
  approve,
  reject,
  canApprove,
  approveText,
}: {
  approve?: (u: User, locationId: number) => Promise<void>;
  reject?: (u: User, locationId: number) => Promise<void>;
  canApprove: boolean;
  children: User;
  locationId: number;
  locationName?: string;
  approveText?: string;
  rejectOnUndo?: boolean;
}) {
  const fullName = `${user.firstName} ${user.lastName}`;
  const userLink = !locationName ? (
    <Link component={RLink} to={paths.userDetails(user.id).root()}>
      {fullName}
    </Link>
  ) : (
    <>
      <Link component={RLink} to={paths.userDetails(user.id).root()}>
        {fullName}
      </Link>{' '}
      wants to join{' '}
      <Link component={RLink} to={paths.locationDetails(locationId)}>
        {locationName}
      </Link>
    </>
  );
  const approveButton = approveText ? (
    <Button onClick={() => approve!(user, locationId)} variant="outlined" color="primary">
      {approveText}
    </Button>
  ) : (
    <Tooltip title="Approve" placement="top">
      <IconButton onClick={() => approve!(user, locationId)}>
        <Check color="primary" />
      </IconButton>
    </Tooltip>
  );
  const actions = canApprove ? (
    <Box display="flex" flexDirection="row" alignItems="center">
      {reject && (
        <Tooltip title="Reject" placement="top">
          <IconButton onClick={() => reject(user, locationId)}>
            <Close color="error" />
          </IconButton>
        </Tooltip>
      )}
      {approve && approveButton}
    </Box>
  ) : null;

  return (
    <DP.ListItem
      key={user.id}
      primary={userLink}
      secondary={user.email}
      icon={<ImageIcon src={user.avatar} />}
      rightContent={actions}
    />
  );
}

type UserWithLocations = Omit<User, 'locations'> & Required<Pick<User, 'locations'>>;

/**
 * The panel that displays users waiting for approval for managers
 * and owners.
 */
interface TalenApprovalPanelProps
  extends WithToastStore,
    WithUserStore,
    WithManagerPermissionsStore,
    WithModalStore,
    WithStyles<typeof styles> {
  fetch: () => Promise<UserWithLocations[]>; // The function that fetches users that are waiting for approval. The fetched user must have the `locations` field set
  fetchRejected?: () => Promise<UserWithLocations[]>; // The function that fetches users that have been rejected. The fetched user must have the `locations` field set
  showLocation?: boolean; // Whether to show location next to email
  approveAll?: () => Promise<unknown>; // The function to approve all users
  approve?: (userId: number, locationId: number) => Promise<unknown>; // The function to approve one user
  reject?: (userId: number, locationId: number) => Promise<unknown>; // The function to reject one user
  undo?: (userId: number, locationId: number) => Promise<unknown>; // The function to set a user back to pending_approval
  title?: string; // If provided, will override the default title (Waiting for approval)
  key?: string;
}

/** A component that displays talent waiting for approval and allows the owner/manager to approve or reject them */
@inject('toastStore', 'modalStore', 'userStore', 'managerPermissionsStore')
@observer
class TalentApprovalPanel extends React.Component<TalenApprovalPanelProps> {
  constructor(props: TalenApprovalPanelProps) {
    super(props);
    makeObservable(this);

    this.disposers.push(
      reaction(
        () => this.props.userStore!.scope,
        () => {
          if (this.props.userStore!.loggedIn) {
            this.getInvitations();
          }
        },
      ),
    );
  }

  private disposers: IReactionDisposer[] = [];

  /** The map holding the fetched users */
  @observable public users?: Map<number, UserWithLocations>;

  /** The map holding the fetched rejected users */
  @observable public rejectedUsers?: Map<number, UserWithLocations>;

  /** Whether something is currently in progress */
  @observable public inProgress = false;

  /** Whether the rejected users modal is visible */
  @observable public rejectedUsersModalVisible = false;

  /** Fetches users pending for approval */
  @action.bound public fetchPendingApproval = flow(function* (this: TalentApprovalPanel) {
    try {
      const users: UserWithLocations[] = yield this.props.fetch();
      this.users = new Map();
      users.forEach((user) => {
        this.users!.set(user.id, user);
      });
    } catch (e: any) {
      this.props.toastStore!.error(getErrorMsg(e));
    }
  });

  /** Fetches rejected users */
  @action.bound public fetchRejected = flow(function* (this: TalentApprovalPanel) {
    try {
      if (!this.props.fetchRejected) {
        return;
      }
      const users: UserWithLocations[] = yield this.props.fetchRejected!();
      this.rejectedUsers = new Map();
      users.forEach((user) => {
        this.rejectedUsers!.set(user.id, user);
      });
    } catch (e: any) {
      this.props.toastStore!.error(getErrorMsg(e));
    }
  });

  @action.bound public fetchAll() {
    return Promise.all([this.fetchPendingApproval(), this.fetchRejected()]);
  }

  /** Approves a single user */
  @action.bound public approveUser = flow(function* (
    this: TalentApprovalPanel,
    user: User,
    locationId: number,
  ) {
    try {
      this.inProgress = true;
      // Remember the status of this user before
      yield this.props.approve!(user.id, locationId);
      yield this.fetchAll();
      // If undo param has been passed, give a way to undo
      const undo = this.props.undo ? () => this.undoRejectOrApprove(user, locationId) : undefined;
      this.props.toastStore!.push({
        message: `${user.firstName} ${user.lastName} has been approved!`,
        type: 'success',
        undo,
      });
    } catch (e: any) {
      this.props.toastStore!.error(getErrorMsg(e));
    } finally {
      this.inProgress = false;
    }
  });

  @action.bound public approveRejectedUser = flow(function* (
    this: TalentApprovalPanel,
    user: User,
    locationId: number,
  ) {
    try {
      this.inProgress = true;
      // Remember the status of this user before
      yield this.props.approve!(user.id, locationId);
      yield this.fetchAll();
      // If undo param has been passed, give a way to undo
      const undo = this.props.undo ? () => this.rejectUser(user, locationId, true) : undefined;
      this.props.toastStore!.push({
        message: `${user.firstName} ${user.lastName} has been approved!`,
        type: 'success',
        undo,
      });
    } catch (e: any) {
      this.props.toastStore!.error(getErrorMsg(e));
    } finally {
      this.inProgress = false;
    }
  });

  /** Rejects a single user */
  @action.bound public rejectUser = flow(function* (
    this: TalentApprovalPanel,
    user: User,
    locationId: number,
    hideNotification?: boolean,
  ) {
    try {
      this.inProgress = true;
      yield this.props.reject!(user.id, locationId);
      yield this.fetchAll();
      const undo = this.props.undo
        ? () => {
            this.undoRejectOrApprove(user, locationId);
          }
        : undefined;
      if (!hideNotification) {
        this.props.toastStore!.push({
          message: `${user.firstName} ${user.lastName} has been rejected!`,
          type: 'success',
          undo,
        });
      }
    } catch (e: any) {
      this.props.toastStore!.error(getErrorMsg(e));
    } finally {
      this.inProgress = false;
    }
  });

  /** Undo essentially sets a user back to pending_approval */
  @action.bound public undoRejectOrApprove = flow(function* (
    this: TalentApprovalPanel,
    user: User,
    locationId: number,
  ) {
    try {
      this.inProgress = true;
      yield this.props.undo!(user.id, locationId);
      yield this.fetchAll();
    } catch (e: any) {
      this.props.toastStore!.error(getErrorMsg(e));
    } finally {
      this.inProgress = false;
    }
  });

  /** Approves all pending users */
  @action.bound public approveAll = flow(function* (this: TalentApprovalPanel) {
    try {
      const resp = yield this.props.modalStore!.confirm(
        `Approve all users?`,
        `Are you sure you want to approve all ${this.pendingUsersList!.length} users?`,
      );
      if (!resp) {
        return;
      }
      this.inProgress = true;
      yield this.props.approveAll!();
      yield this.fetchAll();
      this.props.toastStore!.success(`Approval successful!`);
    } catch (e: any) {
      this.props.toastStore!.error(getErrorMsg(e));
    } finally {
      this.inProgress = false;
    }
  });

  @action.bound public openRejectedUsersModal() {
    this.rejectedUsersModalVisible = true;
  }

  @action.bound public closeRejectedUsersModal() {
    this.rejectedUsersModalVisible = false;
  }

  /** The pending users in list format */
  @computed public get pendingUsersList(): User[] | undefined {
    if (!this.users) {
      return undefined;
    }
    const ret = [...this.users.values()].map((user) => ({
      ...user,
      locations:
        user.locations && user.locations.filter((l) => l.locationUserStatus === 'pending_approval'),
    }));
    return ret;
  }

  /** The rejected users in list format */
  @computed public get rejectedUsersList(): User[] | undefined {
    if (!this.rejectedUsers) {
      return undefined;
    }
    const ret = [...this.rejectedUsers.values()].map((user) => ({
      ...user,
      locations:
        user.locations && user.locations.filter((l) => l.locationUserStatus === 'rejected'),
    }));
    return ret;
  }

  @computed public get loading() {
    return !this.users;
  }

  @computed public get showApproveAllButton() {
    return this.props.approveAll && this.pendingUsersList && this.pendingUsersList.length > 0;
  }

  @computed public get canApprove() {
    const isManager = this.props.userStore!.isManagerScope;
    if (isManager) {
      return this.props.managerPermissionsStore!.hasPermission(EManagerPermission.APPROVE_TALENTS);
    }
    return true;
  }

  @computed public get showRejectedUsers() {
    return this.rejectedUsersList && this.rejectedUsersList.length > 0;
  }

  @computed public get rejectedUsersCountText() {
    if (!this.rejectedUsersList) {
      return undefined;
    }
    return this.rejectedUsersList.length === 1
      ? `1 user has been rejected`
      : `${this.rejectedUsersList.length} users have been rejected`;
  }

  @action.bound getInvitations() {
    this.fetchPendingApproval();
    if (this.props.fetchRejected) {
      this.fetchRejected();
    }
  }

  componentDidMount() {
    this.getInvitations();
  }

  /** Before unmounting the component, dispose of all autoruns created */
  componentWillUnmount() {
    this.disposers.map((disposer) => disposer());
  }

  renderLoading() {
    return (
      <DP.List>
        <Box flex={1} display="flex" alignItems="center" justifyContent="center" height="100%">
          <CircularProgress />
        </Box>
      </DP.List>
    );
  }

  renderPendingUsersList() {
    const { showLocation, classes } = this.props;
    if (!this.pendingUsersList) {
      return null;
    }
    const zeroItems = (
      <DP.Body>
        <Typography color="textSecondary">There are currently no users to display</Typography>
      </DP.Body>
    );
    const list = this.pendingUsersList.flatMap((user) =>
      user.locations
        ? user.locations.map((location) => (
            <UserItem
              key={user.id}
              approve={this.approveUser}
              canApprove={this.canApprove}
              reject={this.rejectUser}
              locationName={showLocation ? location.name : undefined}
              locationId={location.id}>
              {user}
            </UserItem>
          ))
        : [],
    );
    const content = this.pendingUsersList.length === 0 ? zeroItems : list;
    return (
      <>
        <DP.List>{content}</DP.List>
        {this.showRejectedUsers && (
          <Box
            pt={2}
            pr={3}
            pb={2}
            pl={3}
            display="flex"
            flexDirection="row"
            justifyContent="flex-end"
            alignItems="center">
            <Link className={classes.withPointer} onClick={this.openRejectedUsersModal}>
              {this.rejectedUsersCountText}
            </Link>
          </Box>
        )}
      </>
    );
  }

  renderRejectedUsersModal() {
    if (!this.rejectedUsersList) {
      return null;
    }
    const { showLocation } = this.props;
    const listItems = this.rejectedUsersList.flatMap((user) =>
      user.locations
        ? user.locations.map((location) => (
            <UserItem
              key={user.id}
              canApprove={this.canApprove}
              approve={this.approveRejectedUser}
              approveText="Approve"
              locationName={showLocation ? location.name : undefined}
              locationId={location.id}
              rejectOnUndo>
              {user}
            </UserItem>
          ))
        : [],
    );
    return (
      <Dialog
        fullWidth
        maxWidth="lg"
        open={this.rejectedUsersModalVisible}
        onClose={this.closeRejectedUsersModal}>
        <DialogTitle>Rejected users</DialogTitle>
        <DP.List>
          {this.rejectedUsersList.length > 0 ? (
            listItems
          ) : (
            <Box p={3}>
              <Typography color="textSecondary">There are no users to display</Typography>
            </Box>
          )}
        </DP.List>
      </Dialog>
    );
  }

  render() {
    const { classes, title } = this.props;
    const canApproveAll = this.showApproveAllButton && this.canApprove;

    return (
      <React.Fragment>
        {this.renderRejectedUsersModal()}
        <Box className={classes.root}>
          <Overlay display={this.inProgress} transparent={true}>
            <CircularProgress />
          </Overlay>
          <DP>
            <DP.Header>
              <DP.Title count={this.pendingUsersList && this.pendingUsersList.length}>
                {title || `Waiting for approval`}
              </DP.Title>
              {canApproveAll && (
                <DP.Actions>
                  <Button color="primary" variant="outlined" onClick={this.approveAll}>
                    Approve all
                  </Button>
                </DP.Actions>
              )}
            </DP.Header>
            {this.loading ? this.renderLoading() : this.renderPendingUsersList()}
          </DP>
        </Box>
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(TalentApprovalPanel);
