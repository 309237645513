import { withStyles, WithStyles } from '@material-ui/core/styles';
import styles from './styles';
import { observer } from 'mobx-react';
import React from 'react';
import { Avatar, Box, Divider, Typography } from '@material-ui/core';
import { numericStringToUsd } from '../../services';

interface PendingTipsReviewProps extends WithStyles<typeof styles> {
  users: {
    userId: number;
    name: string;
    avatar: string;
    tipValue: number;
    tipPercent: number;
    touched: boolean;
  }[];
  totalAmount: number;
  totalDistributed: number;
}
@observer
class PendingTipsReview extends React.Component<PendingTipsReviewProps> {
  renderRecipientBox(u: Record<string, any>, index: number) {
    const { classes } = this.props;
    const avatarSrc = u.avatar;
    const name = u.name;
    const tipValue = u.tipValue;
    return (
      <div key={`${u.id}-${index}`}>
        <Box mt={2} mb={2} display="flex" justifyContent="space-between" alignItems="center">
          <Avatar alt={'dfdf'} src={`${avatarSrc}`} />
          <Typography className={classes.boxLabel} align="left">
            {`${name}`}
          </Typography>
          <Typography className={classes.boxLabel} align="right">
            {numericStringToUsd(`${tipValue}`)}
          </Typography>
        </Box>
        <Divider />
      </div>
    );
  }

  render() {
    const { users, totalAmount, totalDistributed } = this.props;
    return (
      <>
        <Box mt={3}>{users && users.map((user, idx) => this.renderRecipientBox(user, idx))}</Box>
        <Box mt={3} display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h5" gutterBottom>
            Total
          </Typography>
          <Typography
            variant="h5"
            gutterBottom
            color={totalDistributed == totalAmount ? 'primary' : 'error'}>
            {numericStringToUsd(`${totalDistributed}`)}
          </Typography>
        </Box>
      </>
    );
  }
}

export default withStyles(styles)(PendingTipsReview);
