import { createTheme } from '@material-ui/core/styles';
import { Shadows } from '@material-ui/core/styles/shadows';

const defaultTheme = createTheme();

export const _colors = {
  tippyGreen: {
    dark: '#21807B',
    light: '#1AD9B633', // 0.2 opacity
    main: '#1AD9B6',
    hover: '#15AE92',
  },
  tippyPurple: {
    light: '#6A62F633',
    main: '#6A62F6',
  },
  tippyYellow: {
    main: '#FFC633',
    light: '#FFC63333',
  },
  tippyRed: {
    main: '#E54174',
    light: '#e5417433',
  },
  tippyOrange: {
    main: '#EE7B30',
    light: '#EE7B3033',
  },
  tippyBlue: {
    main: '#15ABFF',
    light: '#15ABFF33',
  },
  tippyMenu: {
    main: '#4C4C4C',
  },
  tippyDeepPurple: {
    main: '#200E32',
  },
  tippyBlack: {
    main: '#00000066', // rgba(0, 0, 0, 0.4)
    light: '#00000033', // rgba(0, 0, 0, 0.2)
    veryLight: '#0000001A', // rgba(0, 0, 0, 0.1)
  },
  tippyBackground: {
    main: '#F2F2F2',
  },
  tippyShadow: {
    main: '#0000003d', // rgba(0, 0, 0, 0.24)
    light: '#0000001a', // rgba(0, 0, 0, 0.1)
  },
};

/**
 * Defines the default theme to be used throughout the project.
 */
export const theme = {
  palette: {
    common: {
      black: _colors.tippyBlack.main,
    },
    primary: {
      dark: _colors.tippyGreen.dark,
      light: _colors.tippyGreen.light,
      main: _colors.tippyGreen.main,
      hover: _colors.tippyGreen.hover,
      contrastText: '#fff',
      50: '#8CECDB',
      100: '#76E8D3',
      200: '#5FE4CC',
      300: '#48E1C5',
      400: '#31DDBD',
      500: '#1AD9B6',
      600: '#17C3A4',
      700: '#15AE92',
      800: '#14A388',
      900: '#118D76',
    },
    secondary: {
      light: _colors.tippyPurple.light,
      main: _colors.tippyPurple.main,
      contrastText: '#fff',
      50: '#AAADF1',
      100: '#9C9FEF',
      200: '#8E91ED',
      300: '#8084EB',
      400: '#7276E9',
      500: '#6469E7',
      600: '#5B60D2',
      700: '#5256BE',
      800: '#494DA9',
      900: '#404393',
    },
    error: { main: _colors.tippyRed.main, light: _colors.tippyRed.light },
    warning: { main: _colors.tippyYellow.main, light: _colors.tippyYellow.light },
    background: {
      default: _colors.tippyBackground.main,
    },
    text: {
      primary: _colors.tippyDeepPurple.main,
      secondary: _colors.tippyBlack.main,
      disabled: _colors.tippyBlack.light,
    },
    button: {
      disabled: _colors.tippyBlack.light,
    },
    status: {
      red: _colors.tippyRed.main,
      green: _colors.tippyGreen.main,
      yellow: _colors.tippyYellow.main,
      orange: _colors.tippyOrange.main,
      blue: _colors.tippyBlue.main,
      grey: _colors.tippyBlack.main,
      purple: _colors.tippyPurple.main,
    },
    divider: _colors.tippyBlack.veryLight,
  },
  typography: {
    fontFamily: 'EuclidCircularB',
    fontSize: 16,
    h1: {
      fontSize: 96,
    },
    h2: {
      fontSize: 60,
    },
    h3: {
      fontSize: 48,
    },
    h4: {
      fontSize: 34,
    },
    h5: {
      fontSize: 26,
    },
    h6: {
      fontSize: 18,
    },
    body1: {
      fontSize: 16,
      color: _colors.tippyDeepPurple.main,
    },
    body2: {
      fontSize: 16,
      color: _colors.tippyBlack.main,
    },
    subtitle1: {
      fontSize: 14,
      color: _colors.tippyBlack.main,
    },
    subtitle2: {
      fontSize: 14,
      color: _colors.tippyBlack.veryLight,
    },
    subtitle3: {
      fontSize: 14,
      color: _colors.tippyDeepPurple.main,
    },
  },
  spacing: 8,
  breakpoints: {
    values: {
      xs: 0,
      sm: 576,
      md: 768,
      lg: 1024,
      xl: 1500,
    },
  },
  shape: {
    borderRadius: 16,
  },
  shadows: [
    'none',
    _colors.tippyShadow.light,
    _colors.tippyShadow.main,
    ...Array(22).fill('none'),
  ] as Shadows,
  sizing: {
    dialog: 576,
  },
  menu: {
    main: _colors.tippyMenu.main,
    drawer: {
      width: {
        open: 266,
        closed: 64,
      },
    },
  },
  appBar: {
    height: 80,
    mobile: {
      height: 72,
    },
  },
  props: {
    MuiButton: {
      disableElevation: true,
      disableRipple: true,
    },
    MuiPaper: {
      elevation: 0,
    },
    MuiInputUnderline: {
      disableUnderline: true,
    },
    MuiInput: {
      disableUnderline: true,
    },
    MuiDrawer: {
      elevation: 0,
    },
    MuiDialog: {
      PaperProps: {
        elevation: 0,
      },
    },
  },
  overrides: {
    MuiAutocomplete: {
      padding: 0,
      '&.MuiInput-root': {
        padding: 0,
      },
      '&.*': {
        padding: 0,
      },
      root: {
        padding: 0,
      },
      inputRoot: {
        flexWrap: 'no-wrap',
        padding: '0!important',
        '&.*': {
          padding: 0,
        },
      },
      input: {
        padding: '0!important',
      },
    },
    MuiOutlinedInput: {
      input: {
        '&:-webkit-autofill': {
          '-webkit-box-shadow': '0 0 0 100px #FFF inset',
          backgroundColor: 'transparent',
          '-webkit-text-fill-color': _colors.tippyDeepPurple.main,
        },
      },
    },
    MuiInput: {
      input: {
        '&:-webkit-autofill': {
          '-webkit-box-shadow': '0 0 0 100px #FFF inset',
          backgroundColor: 'transparent',
          '-webkit-text-fill-color': _colors.tippyDeepPurple.main,
          '&:disabled': {
            '-webkit-text-fill-color': _colors.tippyBlack.light,
          },
        },
        '&:-internal-autofill-selected': {
          backgroundColor: 'white',
        },
        '&:selected': {
          backgroundColor: 'white',
        },
        '&:autofill': {
          backgroundColor: 'white',
        },
      },
    },
    MuiDialog: {
      paper: {
        margin: 0,
      },
    },
    MuiBackdrop: {
      root: {
        backgroundColor: _colors.tippyBlack.main,
        backdropFilter: 'blur(3px)',
      },
    },
    MuiButton: {
      root: {
        height: 60,
        color: _colors.tippyPurple.main,
      },
      containedPrimary: {
        '&:hover': {
          backgroundColor: _colors.tippyGreen.hover,
        },
      },
    },
    MuiDrawer: {
      paperAnchorRight: {
        borderTopLeftRadius: 16,
        borderBottomLeftRadius: 16,
        [defaultTheme.breakpoints.down(439)]: {
          width: '100%',
          borderRadius: 0,
        },
      },
    },
  },
};

export const colors = {
  amber: theme.palette.warning.main,
};

export const professionColors = {
  inactive: theme.palette.error.main,
  affiliate: 'limegreen',
  assistant: 'yellow',
  barber: 'saddlebrown',
  colorist: 'mediumslateblue',
  esthetician: 'lightblue',
  makeup: 'navy',
  massage: 'aquamarine',
  nail: 'pink',
  stylist: theme.palette.primary.main,
  other: 'orange',
};

export const alertColors = {
  low: '#00C1FC',
  medium: theme.palette.warning.main,
  high: '#F39993',
};

export const statusColors = {
  green: theme.palette.primary.light,
  yellow: theme.palette.warning.light,
  red: theme.palette.error.light,
};

export default createTheme(theme);
