import { alpha, Theme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    changedCell: {
      backgroundColor: alpha(theme.palette.secondary.main, 0.05),
    },
  }),
);
