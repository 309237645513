import { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { PaymentItem } from './PaymentItem/PaymentItem';
import { useStores } from 'containers/App/App';
import { ENotificationType, OwnerScope, UserScopes } from 'stores';
import Dialog from 'components/Dialog/Dialog';
import RefundRequestItem from './RefundRequestItem/RefundRequestItem';
import TransferTipRequestItem from './TransferRequestItem/TransferRequestItem';
import InvitationItem from './InvitationItem/InvitationItem';
import LocationInvitationDialog, {
  ILocationInvitationDialogProps,
} from './InvitationItem/LocationInvitationDialog/LocationInvitationDialog';
import { paths } from 'routes';

export const NotificationSystem = observer(() => {
  const { userStore, notificationStore, routerStore } = useStores();
  const [locationDialogProps, setLocationDialogProps] =
    useState<ILocationInvitationDialogProps | null>(null);

  const { isOwnerScope, isAdmin, loggedIn } = userStore;

  useEffect(() => {
    if (!loggedIn || !notificationStore.canShowNotifications) return;
    notificationStore.initializeNotificationSystem();
  }, [
    notificationStore.login,
    loggedIn,
    isOwnerScope,
    isAdmin,
    notificationStore.canShowNotifications,
    notificationStore.initializeNotificationSystem,
    notificationStore,
  ]);

  const onManage = (accountId: number) => {
    const { kind, accountId: _accountId } = userStore.scope as OwnerScope;

    // Only update scope if necessary
    if (kind !== UserScopes.OWNER || _accountId !== accountId) {
      userStore.setScope({ kind: UserScopes.OWNER, accountId });
    }

    routerStore.history.push(
      `${paths.accountDetails(accountId).billing()}?redirectTo=${paths
        .accountDetails(accountId)
        .billing()}&focusPayments`,
    );

    notificationStore.setForceClose(true);
    notificationStore.setOpenNotification(false);
  };

  return (
    <>
      <Dialog
        open={notificationStore.openNotificationsDialog && !locationDialogProps}
        title={'Notifications'}
        content={
          <>
            {notificationStore.notifications?.map((notification, index) => {
              if (notification.type === ENotificationType.EXPIRED_PAYMENT_METHODS) {
                return (
                  <>
                    <PaymentItem
                      key={index}
                      paymentMethod={notification.item}
                      onManage={onManage}
                    />
                  </>
                );
              }
              if (notification.type === ENotificationType.REFUND_REQUEST) {
                return <RefundRequestItem key={index} refundRequests={notification.item} />;
              }
              if (notification.type === ENotificationType.TRANSFER_TIP_REQUEST) {
                return <TransferTipRequestItem key={index} transferRequests={notification.item} />;
              }
              if (notification.type === ENotificationType.INVITATION) {
                return (
                  <InvitationItem
                    key={index}
                    invitation={notification.item}
                    setDialogProps={setLocationDialogProps}
                  />
                );
              }
            })}{' '}
          </>
        }
        onConfirm={notificationStore.notifyLater}
        confirmActionVariant="outlined"
        confirmActionName="Remind later"
      />
      <LocationInvitationDialog dialogProps={locationDialogProps} />
    </>
  );
});
