import { Theme, createStyles, alpha } from '@material-ui/core';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default (theme: Theme) =>
  createStyles({
    root: {
      height: 283,
      minWidth: 250,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      overflow: 'auto',
    },
    logoContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      height: theme.spacing(10),
    },
    logo: {
      width: theme.spacing(16),
    },
    appTitle: {
      marginTop: 'auto',
      marginBottom: theme.spacing(5.25),
      textAlign: 'center',
      fontSize: 14,
      fontWeight: 600,
    },
    dialogActions: {
      padding: 0,
      margin: theme.spacing(3),
      marginTop: theme.spacing(5),
    },
    button: {
      fontSize: 14,
      border: 'none',
      width: 109,
      height: 42,
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
      fontWeight: 400,
      '&:hover': {
        border: 'none',
      },
    },
    manageButton: {
      backgroundColor: alpha(theme.palette.status.green, 0.2),
      '&:hover': {
        backgroundColor: theme.palette.primary.main,
      },
    },
    installButton: {
      '&:hover': {
        backgroundColor: theme.palette.primary.hover,
      },
    },
  });
