import { Box, IconButton } from '@material-ui/core';
import DP from 'components/DashPanel';
import React from 'react';
import { Plus, Qrcode } from 'mdi-material-ui';
import { AddStation, IStation } from 'models/Station';
import { useStationsPanel } from './useStationsPanel';
import { Skeleton, Stack } from '@mui/material';
import ConfirmDialog from 'components/ConfirmDialog';
import { useStationNavigation } from '../useStationNavigation';
import { observer } from 'mobx-react';
import TipSettingsDrawer from 'components/TipSettingsDrawer';
import { EmptyPanelMessage } from 'components/EmptyPanelMessage/EmptyPanelMessage';

export const StationsPanel = observer(() => {
  const [
    stations,
    modalStore,
    stationId,
    setAddStationStateAndInitialize,
    deleteStation,
    openQrCode,
    setStationId,
  ] = useStationsPanel();
  const isOpen = stationId ? true : false;
  return (
    <>
      <DP>
        <DP.Header>
          <DP.Title panel>Stations</DP.Title>
          <DP.IconButton
            icon={Plus}
            primary
            tooltip="Add station"
            onClick={() => setAddStationStateAndInitialize(AddStation.ADD)}
          />
        </DP.Header>
        <StationsList
          stations={stations}
          setStationId={setStationId}
          deleteStation={deleteStation}
        />
      </DP>
      <ConfirmDialog modalStore={modalStore}></ConfirmDialog>
      <TipSettingsDrawer
        entity="station"
        id={parseInt(stationId!)}
        isOpen={isOpen}
        onClose={() => setStationId(undefined)}
        reinitializeOnOpen={true}
      />
    </>
  );
});

interface IListItem {
  station: IStation;
  setStationId: (id?: string) => void;
  deleteStation: (station: IStation) => void;
}

const ListItem = ({ station, setStationId, deleteStation }: IListItem) => {
  const { stationNavigation } = useStationNavigation();
  const { openStationAndInitialize } = stationNavigation;
  return (
    <DP.ListItem
      key={station.name}
      primary={station.name}
      secondary={station.name}
      indicator={{ color: 'green', tooltip: 'Active' }}
      menu={[
        {
          label: 'Edit',
          onClick: (event: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
            event.stopPropagation();
            event.preventDefault();
            openStationAndInitialize(station);
          },
        },
        {
          label: 'Delete',
          onClick: (event: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
            event.stopPropagation();
            event.preventDefault();
            deleteStation(station);
          },
        },
        {
          label: 'Settings',
          onClick: (event: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
            event.stopPropagation();
            event.preventDefault();
            setStationId(station.id!);
          },
        },
      ]}
      rightContent={<Actions station={station} />}
    />
  );
};

const ListItemSkeleton = () => {
  return (
    <DP.ListItem
      primary={
        <Box display={'flex'} flexDirection={'row'} justifyContent={'space-between'}>
          <Stack spacing={1} flexGrow={1}>
            <Skeleton variant="text" width="30%" height="20px" />
            <Skeleton variant="text" width={'50%'} height="20px" />
          </Stack>
          <Box width={50} display={'flex'} flexDirection={'row'} justifyContent={'space-between'}>
            <Skeleton variant="rectangular" width="30px" height="30px" />
            <Skeleton variant="rectangular" width="10px" height="30px" />
          </Box>
        </Box>
      }
    />
  );
};

interface IStationsList {
  stations: Array<IStation> | undefined;
  setStationId: (id?: string) => void;
  deleteStation: (station: IStation) => void;
}

const StationsList = ({ stations, setStationId, deleteStation }: IStationsList) => {
  if (!stations) {
    return (
      <DP.List height="short">
        <ListItemSkeleton />
        <ListItemSkeleton />
        <ListItemSkeleton />
      </DP.List>
    );
  }
  return (
    <>
      {stations.length > 0 ? (
        <DP.List height="short">
          {stations.map((station: IStation) => (
            <ListItem
              key={station.id!}
              setStationId={setStationId}
              station={station}
              deleteStation={deleteStation}
            />
          ))}
        </DP.List>
      ) : (
        <DP.Body>
          <EmptyPanelMessage panelTitle={'Stations'} />
        </DP.Body>
      )}
    </>
  );
};

const Actions = ({ station }: { station: IStation }) => {
  const { stationNavigation } = useStationNavigation();
  const { openQrCode } = stationNavigation;
  return (
    <IconButton onClick={() => openQrCode(station)}>
      <Qrcode />
    </IconButton>
  );
};
