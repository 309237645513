import React from 'react';
import { Box, Divider, Typography } from '@material-ui/core';
import Button from 'components/Button/Button';
import OutlinedInput from 'components/Input/OutlinedInput';
import { IntegrationApp } from 'models';
import useUninstallIntegration from './useUninstallIntegration';

interface UninstallIntegrationProps {
  app: IntegrationApp;
  closeDrawer: () => void;
  fetchApps: () => void;
}

function UninstallIntegration(props: UninstallIntegrationProps) {
  const [removeIntegration, fieldUninstallError, setFieldUninstallError] = useUninstallIntegration({
    ...props,
  });

  const { app } = props;

  return (
    <Box style={{ marginTop: '24px' }}>
      <Divider style={{ marginBottom: '24px' }} />
      <Typography variant="body2">
        To uninstall this integration, type "uninstall" in the text field and click the button
      </Typography>
      <Box style={{ display: 'flex' }} mt={3}>
        <Box mr={1} style={{ width: '50%' }}>
          <OutlinedInput
            label="Uninstall"
            fullWidth
            onChange={(e) => {
              e.target.value === 'uninstall' || e.target.value === 'Uninstall'
                ? setFieldUninstallError(false)
                : setFieldUninstallError(true);
            }}
          />
        </Box>
        <Box ml={1} style={{ width: '50%' }}>
          <Button
            variant="contained"
            color="primary"
            disabled={fieldUninstallError}
            fullWidth
            onClick={() => {
              app.integrationId && removeIntegration(app.integrationId);
            }}>
            Uninstall Integration
          </Button>
        </Box>
      </Box>
    </Box>
  );
}

export default UninstallIntegration;
