import { Theme, createStyles, makeStyles } from '@material-ui/core';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const styles = (theme: Theme) =>
  createStyles({
    root: {},
    buttonFont: {
      textTransform: 'none',
      '&.MuiButton-root:hover': {
        backgroundColor: 'transparent',
      },
    },
    editButton: {
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%',
      textTransform: 'none',
      '&.MuiIconButton-root:hover': {
        backgroundColor: 'transparent',
      },
      padding: '15px 14px',
      borderRadius: '16px',
      borderStyle: 'solid',
      borderColor: theme.palette.text.secondary,
      borderWidth: '1px',
      color: theme.palette.text.primary,
      '&:hover': {
        borderColor: theme.palette.text.primary,
      },
    },
    mainContainer: {
      display: 'flex',
      gap: theme.spacing(3),
      paddingBottom: theme.spacing(3),
    },
    gridContainer: {
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
      // gridTemplateRows: '1fr',
      gridAutoFlow: 'row dense',
      gridAutoRows: 'auto',
      minWidth: '50%',
      gap: theme.spacing(3),
      height: 'max-content',
    },
    gridItemSpan: {
      gridColumn: 'span 2',
    },
    secondaryItem: {
      minWidth: '45%',
    },
  });

export default styles;
export const useStyles = makeStyles(styles);
