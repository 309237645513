import React from 'react';
import { observer } from 'mobx-react';
import { WithStyles, withStyles } from '@material-ui/core/styles';
import { observable, makeObservable, action } from 'mobx';
import moment from 'moment-timezone';
import { Lead } from 'models';
import Api, { RequestMetaData } from 'api';

import styles from './styles';

import { adaptForDataGridPro, EllipsizedValue } from 'services/datagrid';
import { IconButton, Tooltip, Link, Box } from '@material-ui/core';
import { Link as RouterLink, RouteComponentProps } from 'react-router-dom';
import { AccountConvert } from 'mdi-material-ui';
import { paths } from 'routes';
import { inject, WithSettingStore } from 'stores';
import * as DateRangeExternalPicker from 'components/DateRangeExternalPicker';
import { Filter } from 'components/FilterBar/FilterBar';
import FilterBar from 'components/FilterBar';
import DataGridInfiniteScroll from 'components/DataGridInfiniteScroll';

interface LeadsTableProps extends WithStyles<typeof styles>, WithSettingStore, RouteComponentProps {
  leads?: Lead[];
}

/** Annotates lead with extra data */
function annotateLead(lead: Lead) {
  const affiliateName = lead.affiliate
    ? `${lead.affiliate.user.firstName}  ${lead.affiliate.user.lastName}`
    : '';
  const campaignName = lead.campaign && lead.campaign.name ? lead.campaign.name : '';
  return {
    ...lead,
    campaignName,
    affiliateName,
  };
}

/**
 * Fetches paginated leads list
 */
async function fetchLeads(rmd: RequestMetaData) {
  const resp = await Api.marketing.getLeads(rmd);
  const { data, sortable, count } = resp.data;
  return {
    data: {
      data: {
        items: data.map(annotateLead),
        sortable,
        count,
      },
    },
  };
}

/**
 * Displays a paginated, searchable list of leads
 */
@inject('settingStore')
@observer
class LeadsTable extends React.Component<LeadsTableProps> {
  constructor(props: LeadsTableProps) {
    super(props);
    makeObservable(this);
  }

  /** Active filters as returned by FilterBar */
  @observable private activeFilters: Record<string, unknown> = {};

  /** The selected date range */
  @observable public dateRange: DateRangeExternalPicker.DateRange =
    this.props.settingStore!.getDate(this.props.location.pathname);

  public fetchLeadsData = adaptForDataGridPro(
    async (rmd: RequestMetaData) =>
      await Api.marketing.getLeads({
        ...rmd,
        filters: {
          fromDate: this.dateRange.fromDate,
          toDate: this.dateRange.toDate,
          ...this.activeFilters,
        },
      }),
    annotateLead,
  );

  /** Sets the date range */
  @action.bound private updateDateRangeValue(range: DateRangeExternalPicker.DateRange) {
    this.props.settingStore!.setDate(this.props.location.pathname, range);
    this.dateRange = range;
    this.activeFilters = { ...this.activeFilters };
  }

  renderName = (rowIndex: any, name: any, value: any, row: any) => {
    return row.affiliate ? (
      <Link component={RouterLink} to={paths.userDetails(row.affiliate.user.id).root()}>
        {value}
      </Link>
    ) : (
      value
    );
  };

  renderActions = (rowIndex: any, name: any, value: any, row: any) => {
    return (
      <>
        {!row.hasConversions ? (
          <IconButton
            color="primary"
            component={RouterLink}
            to={{ pathname: paths.adminAccountSignup().root(), state: { leadData: row } }}>
            <Tooltip title="Convert">
              <AccountConvert fontSize="small" />
            </Tooltip>
          </IconButton>
        ) : (
          <IconButton disabled>
            <AccountConvert fontSize="small" visibility="hidden" />
          </IconButton>
        )}
      </>
    );
  };

  renderCellAffiliate = ({ row, value }: any) => {
    return row.affiliate ? (
      <Link component={RouterLink} to={paths.userDetails(row.affiliate.user.id).root()}>
        {value}
      </Link>
    ) : (
      value
    );
  };

  renderCellActions = ({ row }: any) => {
    return (
      <>
        {!row.hasConversions ? (
          <IconButton
            color="primary"
            component={RouterLink}
            to={{ pathname: paths.adminAccountSignup().root(), state: { leadData: row } }}>
            <Tooltip title="Convert">
              <AccountConvert fontSize="small" />
            </Tooltip>
          </IconButton>
        ) : (
          <IconButton disabled>
            <AccountConvert fontSize="small" visibility="hidden" />
          </IconButton>
        )}
      </>
    );
  };

  renderCellEmailAddress = ({ value }: any) => <EllipsizedValue value={value} />;

  gridColumns = [
    {
      headerName: 'Created On',
      field: 'createdAt',
      width: 150,
      valueGetter: ({ value }: any) => value && moment(new Date(value)).format('MMM DD, YYYY'),
    },
    { headerName: 'Account', field: 'accountName', minWidth: 250, flex: 1 },
    { headerName: 'First Name', field: 'firstName', minWidth: 150, flex: 1 },
    { headerName: 'Last Name', field: 'lastName', minWidth: 150, flex: 1 },
    {
      headerName: 'Email',
      field: 'email',
      minWidth: 200,
      flex: 1,
      renderCell: this.renderCellEmailAddress,
    },
    { headerName: 'Phone', field: 'phone', minWidth: 200, flex: 1 },
    {
      headerName: 'Affiliate',
      field: 'affiliateName',
      minWidth: 200,
      flex: 1,
      renderCell: this.renderCellAffiliate,
    },
    { headerName: 'Campaign', field: 'campaignName', minWidth: 200, flex: 1 },
    {
      headerName: 'Actions',
      field: 'actions',
      type: 'actions',
      minWidth: 90,
      filterable: false,
      renderCell: this.renderCellActions,
    },
  ];

  filters: Filter[] = [
    { display: 'First Name', id: 'firstName', label: 'Contains', type: 'text' },
    { display: 'Last Name', id: 'lastName', label: 'Contains', type: 'text' },
    { display: 'Campaign', id: 'campaignName', label: 'Contains', type: 'text' },
  ];

  render() {
    return (
      <Box mb={3}>
        <FilterBar
          filters={this.filters}
          onChange={(filters: Record<string, unknown>) => {
            this.activeFilters = filters;
          }}
          externalDateRange={{
            predefined: this.dateRange,
            onChange: this.updateDateRangeValue,
          }}
        />
        <DataGridInfiniteScroll
          columns={this.gridColumns}
          fetch={this.fetchLeadsData}
          refetchKey={this.activeFilters}
          sortByField="createdAt"
          sortDirection="DESC"
          disableColumnMenu
          pathname={this.props.location.pathname}
        />
      </Box>
    );
  }
}

export default withStyles(styles)(LeadsTable);
