import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { computed, action, flow, observable, makeObservable } from 'mobx';
import { observer } from 'mobx-react';

import { debounce } from 'lodash';

import Api, { getErrorMsg, PagedApiResponse, RequestMetaData } from 'api';
import { inject, WithToastStore } from 'stores';
import { UpcomingPayout } from 'models';
import { paths } from 'routes';

import { Box, Button, CircularProgress, IconButton, Link, Typography } from '@material-ui/core';
import { Close } from 'mdi-material-ui';

import DP from 'components/DashPanel';
import { AxiosResponse } from 'axios';

// Number of items in a paginated fetch
const pageSize = 100;

const UserPayoutsListItem = observer(({ children }: { children: UpcomingPayout }) => {
  const payout = children;

  return (
    <DP.ListItem
      rightContent={`$${payout.amount}`}
      primary={
        <Link component={RouterLink} to={paths.userDetails(payout.talent!.id).root()}>
          {payout.talent!.firstName} {payout.talent!.lastName}
        </Link>
      }
    />
  );
});

const PartnerPayoutsListItem = observer(({ children }: { children: UpcomingPayout }) => {
  const payout = children;

  return (
    <DP.ListItem
      rightContent={`$${payout.amount}`}
      primary={
        <Link component={RouterLink} to={paths.partnerDetails(payout.partner!.accountId!).root()}>
          {payout.partner!.name}
        </Link>
      }
    />
  );
});

interface ScheduledPayoutsModalProps extends WithToastStore {
  isPartner: boolean;
  closeModal: () => void;
}

/**
 * Component for listing all talents awaiting their payout in the upcoming payout
 */
@inject('toastStore')
@observer
class ScheduledPayoutsModal extends React.Component<ScheduledPayoutsModalProps> {
  constructor(props: ScheduledPayoutsModalProps) {
    super(props);
    makeObservable(this);
  }
  /** Is component in loading state */
  @observable public loading = false;

  /** Array of payouts */
  @observable public payouts: UpcomingPayout[] = [];

  /** Count of all payouts */
  @observable public count?: number;

  /** How many times we've fetched the payouts */
  @observable public fetchCount = 0;

  /** How many items to skip on the next request */
  @observable public skip = 0;

  /** The search text */
  @observable private searchText = '';

  /** The search term being filtered currently */
  @observable private filteringBy = '';

  /** Whether to display the load more button */
  @computed public get showLoadMore() {
    return (
      this.payouts && Boolean(this.count && this.count > this.payouts.length) && !this.filteringBy
    );
  }

  @action.bound private fetchData() {
    if (this.props.isPartner) {
      this.fetchPartners();
    } else {
      this.fetchTalents();
    }
  }

  /** Updates the search text */
  @action.bound private handleSearchOnChange(e: React.ChangeEvent<HTMLInputElement>) {
    this.searchText = e.target.value;
    this.updateFilterDebounced(e.target.value);
    if (e.target.value === '') {
      this.updateFilterDebounced &&
        this.updateFilterDebounced.flush &&
        this.updateFilterDebounced.flush();
    }
  }

  @action.bound private updateFilter(s: string) {
    this.filteringBy = s;
    this.fetchData();
  }

  @action.bound private updateFilterDebounced = debounce((value) => {
    this.reset();
    this.updateFilter(value);
  }, 500);

  /** Loads the next page of payouts */
  @action.bound public loadMore() {
    this.skip = this.skip + pageSize;
    this.fetchData();
  }

  /**
   * Resets the current data
   * Used when updating the search terms with server-side fetching
   */
  @action.bound public reset() {
    this.skip = 0;
    this.fetchCount = 0;
    this.payouts = [];
    this.count = undefined;
  }

  @action.bound private fetchTalents = flow(function* (this: ScheduledPayoutsModal) {
    try {
      this.loading = true;
      const rmd: RequestMetaData = {
        filters: {
          search: this.filteringBy,
        },
        pagination: {
          take: pageSize,
          skip: this.skip,
        },
        sort: {
          sortOrder: 'ASC',
          sortBy: 'lastName',
        },
      };

      const resp: AxiosResponse<PagedApiResponse<UpcomingPayout>> =
        yield Api.tips.getTalentsInUpcomingPayout(rmd);

      if (resp && resp.data && resp.data.data) {
        this.payouts.push(...resp.data.data);
        this.count = resp.data.count;
      }
    } catch (e: any) {
      this.props.toastStore!.push({ type: 'error', message: getErrorMsg(e) });
    } finally {
      this.loading = false;
    }
  });

  @action.bound private fetchPartners = flow(function* (this: ScheduledPayoutsModal) {
    try {
      this.loading = true;
      const rmd: RequestMetaData = {
        filters: {
          search: this.filteringBy,
        },
        pagination: {
          take: pageSize,
          skip: this.skip,
        },
        sort: {
          sortOrder: 'ASC',
          sortBy: 'lastName',
        },
      };

      const resp: AxiosResponse<PagedApiResponse<UpcomingPayout>> =
        yield Api.tips.getPartnersInUpcomingPayout();

      if (resp && resp.data && resp.data.data) {
        this.payouts.push(...resp.data.data);
        this.count = resp.data.count;
      }
    } catch (e) {
      this.props.toastStore!.push({ type: 'error', message: getErrorMsg(e) });
    } finally {
      this.loading = false;
    }
  });

  componentDidMount() {
    this.fetchData();
  }

  render() {
    return (
      <Box minWidth={380}>
        <DP.Header>
          <DP.Title count={this.count}>Scheduled payouts</DP.Title>
          <DP.Actions>
            <IconButton onClick={this.props.closeModal}>
              <Close fontSize="small" />
            </IconButton>
          </DP.Actions>
        </DP.Header>
        <DP.SearchInput
          value={this.searchText}
          onChange={this.handleSearchOnChange}
          placeholder={this.props.isPartner ? 'Search by partner name' : 'Search by employee name'}
        />
        <DP.List>
          {this.payouts &&
            this.payouts.map((p, i) => {
              if (this.props.isPartner) {
                return <PartnerPayoutsListItem key={i}>{p}</PartnerPayoutsListItem>;
              } else {
                return <UserPayoutsListItem key={i}>{p}</UserPayoutsListItem>;
              }
            })}
          {this.showLoadMore && (
            <Box mt={1} display="flex" justifyContent="center">
              <Button color="primary" onClick={this.loadMore} disabled={this.loading}>
                Load more
              </Button>
            </Box>
          )}
        </DP.List>
        <Box mb={2} mt={2} display="flex" justifyContent="center">
          {this.loading ? (
            <CircularProgress size={26} />
          ) : (
            <Typography variant="subtitle2" align={'center'}>
              {this.filteringBy
                ? `Found ${this.payouts.length} payouts`
                : `Loaded ${this.payouts.length} out of ${this.count} payouts`}
            </Typography>
          )}
        </Box>
      </Box>
    );
  }
}

export default ScheduledPayoutsModal;
