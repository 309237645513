/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'

interface ErrorBoundaryProps {
    children?: React.ReactNode | undefined
}

export default class ErrorBoundary extends React.Component<ErrorBoundaryProps> {
    state = { hasError: false };

    static getDerivedStateFromError(error: any) {
      // Update state so the next render will show the fallback UI.
      console.log('getDerivedStateFromError_error :::', error);
      return { hasError: true };
    }  
    componentDidCatch(error: any, errorInfo: any) {
      // You can also log the error to an error reporting service
      this.logErrorToMyService(error, errorInfo);
    }

    logErrorToMyService(error: any, errorInfo: any){
        console.log('componentDidCatch_error :::', error);
        console.log('componentDidCatch_errorInfo :::', errorInfo);
    }
  
    render() {
      if (this.state.hasError) {
        // You can render any custom fallback UI
        return <h1>Something went wrong.</h1>;
      }
  
      return this.props.children; 
    }
  }