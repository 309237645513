/* eslint-disable @typescript-eslint/no-explicit-any */
import MaskedInput from 'react-text-mask';

const USPhoneMask = [
  '(',
  /[1-9]/,
  /\d/,
  /\d/,
  ')',
  ' ',
  /\d/,
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
];
const strictUSPhoneMask = [
  '+',
  /[1]/,
  ' ',
  '(',
  /[1-9]/,
  /\d/,
  /\d/,
  ')',
  ' ',
  /\d/,
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
];
const strictSIPhoneMask = [
  '+',
  /[3]/,
  /[8]/,
  /[6]/,
  ' ',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
];

/** A masked input for inputting US phone numbers */
function PhoneInput(props: any) {
  const { inputRef, value, ...other } = props;

  return (
    <MaskedInput
      {...other}
      value={value}
      ref={(ref) => inputRef(ref ? ref.inputElement : null)}
      mask={(value) => renderMask(value)}
      guide
      placeholderChar={'\u2000'}
    />
  );
}

const renderMask = (value: string) => {
  if (!value || value.length < 2) return [/[+\d]/, /d/];

  const isSIPrefix = (value as string).startsWith('+3');
  if (isSIPrefix) return strictSIPhoneMask;
  const isUSPrefix = (value as string).startsWith('+1');
  if (isUSPrefix) return strictUSPhoneMask;
  return USPhoneMask;
};

export default PhoneInput;
