import React, { Component } from 'react';
import RootStore from 'stores/RootStore';
import { Provider } from 'mobx-react';
import { ThemeProvider } from '@material-ui/styles';
import { LicenseInfo } from '@mui/x-data-grid-pro';

import theme from './theme';
import Layout from 'components/Layout';
import Routes from 'routes/Routes';
import { NotificationSystem } from 'components/NotificationSystem/NotificationSystem';
import Fingerprint from 'components/Fingerprint/Fingerprint';
import MobileView from 'components/MobileView/MobileView';
import IntercomWidgetInitializer from 'components/IntercomWidget/IntercomWidgetInitializer';
import { ThemeProvider as MuiV5ThemeProvider } from '@mui/material';
import themeNew from './theme_new';
import { withLDProvider } from 'launchdarkly-react-client-sdk';

LicenseInfo.setLicenseKey(
  '6e5df2674df280ba8a8083f75defd85eT1JERVI6MzE1MjksRVhQSVJZPTE2NjY4ODYxMjcwMDAsS0VZVkVSU0lPTj0x',
);

declare global {
  interface Window {
    INTERCOM_APP_ID: any;
  }
}

// Initialize the root store
export const rootStore = new RootStore();
const StoresContext = React.createContext(rootStore);
export const useStores = () => React.useContext(StoresContext);
// eslint-disable-next-line @typescript-eslint/no-explicit-any
(window as any).rootStore = rootStore;

/**
 * This is the main application component, which is mounted to
 * the root element in index.tsx.
 */
class App extends Component {
  render() {
    return (
      <Provider {...rootStore}>
        <ThemeProvider theme={theme}>
          <MuiV5ThemeProvider theme={themeNew}>
            <Fingerprint />
            <MobileView />
            <IntercomWidgetInitializer />
            <Layout>
              <Routes />
              <NotificationSystem />
            </Layout>
          </MuiV5ThemeProvider>
        </ThemeProvider>
      </Provider>
    );
  }
}

/**
 * Wrap App with Launch Darkly provider
 */
export default withLDProvider({
  clientSideID: process.env.REACT_APP_LC_CLIENT_KEY || '',
  context: {
    kind: 'user',
    key: process.env.REACT_APP_LC_REFER_CONTEXT_KEY,
  },
})(App);
