import { Location } from './Location';
import { User } from './User';
import { Customer } from './Customer';
import { Payment } from './Payment';
import { TipAmountMode } from './TipFees';
import { KioskUser } from './KioskUser';

export enum Source {
  CARD = 'card',
  SPLIT = 'split',
  CASH = 'cash',
  REFUND = 'refund',
  CORRECTION = 'correction',
}

export enum TipLevel {
  LOW = 'low',
  MEDIUM = 'medium',
  MEDIAN = 'median',
  HIGH = 'high',
  CUSTOM = 'custom',
  UNKNOWN = 'unknown',
}

export declare enum TipStatus {
  CONFIRMED = 'confirmed',
  PENDING = 'pending',
  VOID = 'void',
}

export interface Tip {
  id: number;
  userId: number;
  locationId: number;
  location?: Location;
  serviceAmount: string;
  gross: string;
  net: string;
  level: TipLevel;
  source: Source;
  status: TipStatus;
  deviceTime: string;
  localTime: string;
  createdAt: string;
  user: User;
  payment?: Payment;
  customer?: Customer;
  canRefund?: boolean;
  canSplit?: boolean;
}

export interface TipReport {
  userId: number;
  firstName: string;
  lastName: string;
  employeeId?: number;
  location?: {
    id: string;
    name: string;
  };
  account?: {
    id: string;
    name: string;
  };
  count: string;
  countCard: string;
  countCash: string;
  netSum: string;
  netSumCard: string;
  netSumCash: string;
  netAvg: string;
  netAvgCard: string;
  netAvgCash: string;
  percentAvg: string;
  serviceAmountAvg: string;
  serviceAmountSum: string;
}

export interface TipDetailsResponseRefunds {
  id: number;
  poolId?: string;
  poolTipId?: string;
  userId?: number;
  locationId?: number;
  serviceAmount?: number;
  tipAmount?: number;
  gross?: number;
  net?: number;
  level?: TipLevel;
  source?: Source;
  parentId?: number;
  status?: TipStatus;
  deviceTime?: Date;
  localTime?: Date;
  createdAt?: Date;
  included?: boolean;
  canRefund?: boolean;
  canSplit?: boolean;
  user?: User;
  parent?: Tip;
  payment?: PaymentResponse;
  customerId?: number;
  customer?: Customer;
  location?: Location;
  servicePercent?: string;
}

export interface ChartDataset {
  label: string; // The label for a certain dataset, e.g. `number of tips`
  total?: string; // An optional total, should be the sum of all values in `values`
  values: number[]; // The actual values, each value corresponds to a date point in `dates`
  yAxisLabel?: string; // Optional y axis label, if we want to display multiple datasets on one y axis
  meta?: {
    // Optional metadata for each type of dataset
    currency?: boolean;
  };
}

export interface ChartData {
  dates: string[]; // An array of dates for the x-axis points
  periods?: string[]; // An optional array of labels for each date in `dates`
  datasets: ChartDataset[];
}

export interface Proposal {
  percent: string;
  amount: string;
  type: string;
  fee: string;
  total: string;
  mode: string;
}

export interface KioskTip {
  userUid: string;
  amount: string;
  locationUid?: string;
  level?: TipLevel;
  note?: string;
}

export interface PaymentIntentTip {
  userUid: string;
  serviceAmount: string;
  level: TipLevel;
  tipAmount?: string;
  amount?: string;
  fee?: string;
  total?: string;
  proposals: {
    serviceAmount: string;
    low: Proposal;
    medium: Proposal;
    high: Proposal;
  };
}

export interface KioskTipWithKioskUser extends KioskTip {
  talent: KioskUser;
}

export interface TipIntentTotal {
  tipAmount: string;
  fee: string;
  total: string;
}

export interface PaymentIntent {
  processor: string;
  mode: TipAmountMode;
  tips: PaymentIntentTip[];
  total: {
    tipAmount: string;
    fee: string;
    total: string;
  };
}
export interface UndistributedPoolTip {
  id: number;
  poolId: number;
  tipId: number;
  roomNumber: string;
  customerName: string;
  review: number;
  note: string;
  createdAt: string;
  poolName: string;
  locationId: number;
  locationName: string;
  amount: string;
}

export interface IUserTip {
  id: number;
  name: string;
  amount: string;
  avatar?: string;
}

export interface IUserTipList {
  users: {
    targetSpId: number;
    amount: string;
  }[];
}

export interface IUserTipRequest extends IUserTipList {
  deviceTime?: string;
}
