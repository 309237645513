import React, { RefObject, createRef } from 'react';
import { observer } from 'mobx-react';
import { action, observable, makeObservable } from 'mobx';

import { FileDrop } from 'react-file-drop';
import Webcam from 'react-webcam';

import clsx from 'clsx';
import { WithStyles, withStyles } from '@material-ui/core/styles';
import {
  Avatar,
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  LinearProgress,
  Typography,
} from '@material-ui/core';

import { WithToastStore, inject } from 'stores';

import { resizeImage, dataURLtoFile } from '../../../components/ProfilePicturePanel/utils';

import styles from './styles';
import Button from 'components/Button/Button';
import DialogButton from 'components/Button/Dialog/Button';
import Api, { getErrorMsg } from 'api';
import { rootStore } from 'containers/App/App';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCamera, faDownToLine, faImage, faXmark } from '@fortawesome/pro-regular-svg-icons';
import Spacer from 'components/Spacer/Spacer';
import SupportText from 'components/SupportText/SupportText';
import theme from 'containers/App/theme';

export enum PictureUploadErrorType {
  TYPE = 'file-type',
  FILE = 'file-processing',
}

export interface PictureUploadError {
  type: PictureUploadErrorType | undefined;
  message: string;
}

let abortController = new AbortController();

interface PictureUploadProps extends WithStyles<typeof styles>, WithToastStore {
  profilePictureUrl?: string;
  onNewImage?: (imgUrl?: string) => void;
  onUpload?: (success: boolean) => void;
  onUploading?: (uploading: boolean) => void;
  onError?: (error?: PictureUploadError) => void;
}
/**
 * This component displays users profile image and it can also be used to update
 * it. It supports three different ways of uploading an image; 1) drag'n'drop,
 * 2) from computer or 3) via webcam. It also has three different render states
 * based on upload process progress. Initial render shows instructions for drag
 * and drop and select menu options for other two upload methods. Dragging mouse
 * with file over the component will render it into active drop zone. Pending
 * state asks user to confirm her choice of image or reset the state.
 *
 * @param profilePictureUrl User's current profile picture url
 * @param layout Optional Used to control components layout, 'horizontal' by default
 * @param onUpload Optional callback with uploaded file as a parameter
 */
@inject('toastStore')
@observer
class PictureUpload extends React.Component<PictureUploadProps> {
  constructor(props: PictureUploadProps) {
    super(props);
    makeObservable(this);
  }

  /** File is set when user chooses a file for upload */
  @observable private file: null | File = null;

  /** Url reference to image pending for upload */
  @observable private imageUrl: undefined | string = this.props.profilePictureUrl;

  /** For rendering switch and upload progress bar value */
  @observable private uploading = false;
  @observable private uploadProgress = 0;

  @observable public preparingImage = false;

  /** Is webcam dialog open? */
  @observable private cameraDialog = false;

  /** Ref for Webcam component, so we can use its methods */
  @observable public webcamRef: RefObject<Webcam> = createRef();

  /** Temporary Webcam image, stored before user confirms the photo she took */
  @observable public tmpWebcamImage: null | HTMLImageElement = null;

  @observable public loadingWebcam = false;

  /** Is user dragging file over drop zone - render switch */
  @observable private dragging = false;

  /** Material-ui anchor for select upload method menu */
  @observable private menuAnchorEl: null | HTMLElement = null;

  @observable private error: PictureUploadError = {
    type: undefined,
    message: '',
  };

  /** Validate and and resize the image */
  @action.bound public prepareImage = (file: File) => {
    const reader = new FileReader();
    this.resetError();
    this.preparingImage = true;
    if (!file.type.match(/image.*/)) {
      this.dragging = false;
      this.props.onError && this.props.onError(this.error);
      this.error = { type: PictureUploadErrorType.TYPE, message: `File type not permitted.` };
      this.preparingImage = false;

      return;
    }

    reader.onloadend = async () => {
      this.dragging = false;
      let resizedImage;
      try {
        resizedImage = await resizeImage(file, 1000);
      } catch (error) {
        this.error = {
          type: PictureUploadErrorType.FILE,
          message: `There was an error processing your file.
          Please try again.`,
        };
      } finally {
        this.preparingImage = false;
      }
      if (!resizedImage) return;
      const newUrl = window.URL.createObjectURL(resizedImage);
      this.file = resizedImage;
      this.imageUrl = newUrl;
      this.props.onNewImage && this.props.onNewImage(newUrl);
    };
    reader.readAsDataURL(file);
  };

  @action.bound public handleMenuOpen(event: React.MouseEvent<HTMLButtonElement>) {
    this.menuAnchorEl = event.currentTarget;
  }

  @action.bound public handleMenuClose() {
    this.menuAnchorEl = null;
  }

  /** On select image from system's select file prompt window */
  @action.bound public handleImageChange = (e: any) => {
    e.preventDefault();
    const file = e.target.files[0];
    this.prepareImage(file);
  };

  /** Render logic reacts to mouse drag */
  @action.bound public onDragOver = (e: any) => {
    this.dragging = true;
  };

  /** Render logic reacts to mouse drag */
  @action.bound public onDragLeave = () => {
    this.dragging = false;
  };

  /** Called when user drops a file inside the drop zone */
  @action.bound public handleDrop = (files: any) => {
    const file = files[0];
    this.prepareImage(file);
  };

  /** Called when user takes a photo of himself using Webcam component */
  @action.bound public onScreenshot = () => {
    if (this.webcamRef.current) {
      const cameraElement = this.webcamRef.current;
      const imageSrc = cameraElement.getScreenshot();
      this.tmpWebcamImage = new Image();
      if (imageSrc) {
        this.tmpWebcamImage.src = imageSrc;
      }
    }
  };

  /** Called when user confirms the photo he took of herself in the Webcam dialog */
  @action.bound public onChooseScreenshot = () => {
    this.cameraDialog = false;
    const file = this.tmpWebcamImage && dataURLtoFile(this.tmpWebcamImage.src);
    file && this.prepareImage(file);
    this.tmpWebcamImage = null;
  };

  @action.bound public openWebcamDialog = () => {
    this.menuAnchorEl = null;
    this.cameraDialog = true;
    this.loadingWebcam = true;
  };

  @action.bound public closeWebcamDialog = () => {
    this.cameraDialog = false;
    this.loadingWebcam = false;
  };

  /** If user cancels upload process, we set initial component state */
  @action.bound public reset = () => {
    this.file = null;
    this.tmpWebcamImage = null;
    this.imageUrl = undefined;
    this.uploadProgress = 0;
    this.props.onNewImage && this.props.onNewImage(undefined);
  };

  @action.bound private resetError = () => {
    this.error = { type: undefined, message: '' };
  };

  @action.bound public handleUpload = async () => {
    if (!this.file) {
      return this.props.toastStore?.error('Cannot upload an empty image');
    }

    const { userStore } = rootStore;
    const { onUploading } = this.props;
    onUploading && onUploading(true);
    try {
      this.uploading = true;
      this.uploadProgress = 0;

      const resp = await Api.core.uploadProfilePicture(
        userStore.user!.id,
        this.file,
        this.onUploadProgress,
        abortController,
      );
      this.props.onUpload && this.props.onUpload(true);
      const user = resp?.data?.data;
      user && userStore.setUserData(user);
    } catch (error: any) {
      this.props.toastStore!.push({
        type: 'error',
        message: getErrorMsg(error) || 'An error has occurred',
      });
    } finally {
      onUploading && onUploading(false);
      this.uploading = false;
      this.tmpWebcamImage = null;
      this.file = null;
    }
  };

  @action.bound public onUploadProgress = (progressEvent: any) => {
    const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
    this.uploadProgress = percentCompleted;
  };

  @action.bound abortApiCallAndSetUpNewController(): void {
    this.reset();
    abortController.abort();
    abortController = new AbortController();
  }

  @action.bound handleUserMedia(): void {
    this.loadingWebcam = false;
  }

  getAnchorPosition() {
    let top = 0;
    let left = 0;
    const boundingClientRect = this.menuAnchorEl?.getBoundingClientRect();
    if (boundingClientRect) {
      top = boundingClientRect.top;
      left = boundingClientRect.left + 220;
    }
    return { top, left };
  }

  handleTargetClick = () => {
    document.getElementById('avatar')?.click();
  };

  render() {
    const { classes } = this.props;
    const canFileDrop = !this.imageUrl;
    const space = this.imageUrl ? 8 : 5;
    const fileTypeError = this.error.type === PictureUploadErrorType.TYPE;
    const processingError = this.error.type === PictureUploadErrorType.FILE;
    const mt = this.error?.type === PictureUploadErrorType.TYPE ? 2 : 4;

    const actions1 = !this.tmpWebcamImage ? 'block' : 'none';
    const actions2 = this.tmpWebcamImage ? 'block' : 'none';

    const { mobileView } = rootStore.uiStore;
    const width = mobileView ? 300 : 600;

    return (
      <>
        {canFileDrop ? (
          <Box mt={mt} width={'100%'}>
            {processingError && (
              <Box mb={2} width={'100%'}>
                <SupportText label={this.error.message} />
              </Box>
            )}
            <FileDrop
              className={clsx(classes.fileDrop, { [classes.fileDropAvatar]: this.imageUrl })}
              onTargetClick={this.handleTargetClick}
              onDrop={this.handleDrop}
              onDragOver={this.onDragOver}
              onDragLeave={this.onDragLeave}>
              <AvatarInput
                src={this.imageUrl}
                reset={this.reset}
                classes={classes}
                preparing={this.preparingImage}
                dragging={this.dragging}
              />
              {fileTypeError && (
                <Typography
                  style={{ position: 'absolute' }}
                  variant="subtitle1"
                  color="error"
                  align="left">
                  {this.error.message}
                </Typography>
              )}
            </FileDrop>
          </Box>
        ) : (
          <AvatarInput
            dragging={this.dragging}
            preparing={this.preparingImage}
            src={this.imageUrl}
            reset={this.reset}
            classes={classes}
            uploading={this.uploading}
            progress={this.uploadProgress}
          />
        )}

        <Spacer space={space} />

        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          width={'100%'}>
          {/* --------------- Initial state: -------------- */}
          {!this.file ? (
            <>
              <form style={{ display: 'none' }}>
                <input type="file" id="avatar" value="" onChange={this.handleImageChange} />
              </form>

              <Button fullWidth variant="contained" onClick={this.openWebcamDialog}>
                Take a selfie
              </Button>
            </>
          ) : (
            <>
              {this.uploading ? (
                <Button
                  fullWidth
                  onClick={this.abortApiCallAndSetUpNewController}
                  variant="outlined"
                  color="primary">
                  Cancel upload
                </Button>
              ) : (
                <Button variant="contained" color="primary" fullWidth onClick={this.handleUpload}>
                  Upload & Continue
                </Button>
              )}
            </>
          )}
        </Box>
        {/* --------------- Webcam dialog : -------------- */}
        <Dialog
          classes={{ paper: classes.dialog }}
          maxWidth={false}
          open={this.cameraDialog}
          onClose={this.closeWebcamDialog}>
          {this.cameraDialog && (
            <>
              <DialogContent
                style={{
                  width,
                }}>
                <Box>
                  {this.loadingWebcam ? (
                    <Box
                      display={'flex'}
                      alignItems={'center'}
                      justifyContent={'center'}
                      height={(width * 3) / 4}>
                      <CircularProgress />
                    </Box>
                  ) : (
                    this.tmpWebcamImage && (
                      <img alt="Profile" src={this.tmpWebcamImage.src as string} />
                    )
                  )}

                  <Webcam
                    width={width}
                    onUserMedia={this.handleUserMedia}
                    hidden={this.loadingWebcam || !!this.tmpWebcamImage}
                    ref={this.webcamRef}
                    audio={false}
                  />
                </Box>
                <Box
                  p={2}
                  display={'flex'}
                  alignContent={'center'}
                  justifyContent={'space-between'}>
                  <Box display={actions1}>
                    {' '}
                    <DialogButton onClick={this.closeWebcamDialog} color="primary">
                      Cancel
                    </DialogButton>
                  </Box>
                  <Box display={actions1}>
                    <DialogButton variant="contained" color="primary" onClick={this.onScreenshot}>
                      <FontAwesomeIcon style={{ marginRight: theme.spacing(1) }} icon={faCamera} />{' '}
                      Take Photo
                    </DialogButton>
                  </Box>
                  <Box display={actions2}>
                    {' '}
                    <DialogButton color="primary" onClick={this.reset}>
                      Retake
                    </DialogButton>
                  </Box>

                  <Box display={actions2}>
                    {' '}
                    <DialogButton
                      variant="contained"
                      color="primary"
                      onClick={this.onChooseScreenshot}>
                      Use Photo
                    </DialogButton>
                  </Box>
                </Box>
              </DialogContent>
            </>
          )}
        </Dialog>
      </>
    );
  }
}

export default withStyles(styles)(PictureUpload);

interface IAvatarInput {
  src?: string;
  classes: any;
  dragging: boolean;
  preparing: boolean;
  uploading?: boolean;
  progress?: number;
  reset: () => void;
}

function AvatarInput(props: IAvatarInput) {
  const { src, classes, preparing, dragging, uploading, progress, reset } = props;

  const withWrapper = (children: React.ReactNode) => (
    <Box
      className={clsx(classes.photoIconWrapper, {
        [classes.avatarHover]: dragging,
        [classes.avatarWrapper]: src,
      })}>
      {children}
    </Box>
  );

  if (src) {
    const showProgress = uploading || !!progress;
    return withWrapper(
      <Box width={187} borderRadius={16} position={'relative'}>
        <Box className={classes.cancelIconWrapper} onClick={reset}>
          <FontAwesomeIcon height={10} icon={faXmark} />
        </Box>
        <Avatar src={src} className={classes.avatar} variant="rounded" alt="Profile Picture" />
        {showProgress && (
          <LinearProgress className={classes.progress} variant="determinate" value={progress} />
        )}
      </Box>,
    );
  }

  if (dragging) {
    return withWrapper(
      <>
        <FontAwesomeIcon className={classes.imageIcon} icon={faDownToLine} />
        <Spacer space={4} />
        <Typography variant="body2">Drop to use</Typography>
        <Typography variant="subtitle1">Supports: JPG, JPEG, PNG</Typography>
      </>,
    );
  }

  if (preparing) {
    return withWrapper(<CircularProgress color="primary" />);
  }

  return withWrapper(
    <>
      <FontAwesomeIcon className={classes.imageIcon} icon={faImage} />
      <Spacer space={4} />
      <Typography variant="body2">Upload photo or drag and drop it.</Typography>
      <Typography variant="subtitle1">Supports: JPG, JPEG, PNG</Typography>
    </>,
  );
}
