/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { Link as RouterLink, Route, Switch, Redirect, RouteComponentProps } from 'react-router-dom';
import { observable, computed, makeObservable } from 'mobx';
import { observer } from 'mobx-react';

import { ChartData } from 'models';

import { paths } from 'routes';
import { inject, WithUserStore, WithToastStore, WithAnalyticsStore } from 'stores';
import { WithStyles, withStyles } from '@material-ui/core/styles';

import { setTitle } from 'services';

import DashboardLayout from 'containers/DashboardLayout';

import TabBar from 'components/TabBar/TabBar';
import ByAffiliate from './ByAffiliate';
import ByEmployee from './ByEmployee';
import ByDeal from './ByDeal';
import ByCampaign from './ByCampaign';

import styles from './styles';
import Title from 'components/Title/Title';

const PAGE_TITLE = 'Conversion';

/** Here we define what kind of props this component takes */
interface ConversionProps
  extends WithStyles<typeof styles>,
    RouteComponentProps,
    WithUserStore,
    WithToastStore,
    WithAnalyticsStore {}

/**
 * Container for displaying and downloading tip reports.
 * Accessible to admin, owner and manager scope.
 */
@inject('userStore', 'toastStore', 'analyticsStore')
@observer
class Conversion extends React.Component<ConversionProps> {
  constructor(props: ConversionProps) {
    super(props);
    makeObservable(this);
  }

  /** Chart data */
  @observable private chartData?: ChartData;

  @computed public get dateRange() {
    return this.props.analyticsStore!.dateRange;
  }

  @computed public get dateRangeKey() {
    return JSON.stringify(this.dateRange && `${this.dateRange.fromDate}-${this.dateRange.toDate}`);
  }

  @computed public get tabs() {
    const conversionPaths = paths.analytics().conversion();
    const { pathname } = this.props.location;

    const conversionTabs = [
      { path: conversionPaths.byAffiliates(), label: 'By Affiliates' },
      { path: conversionPaths.byEmployee(), label: 'By Employees' },
      { path: conversionPaths.byCampaign(), label: 'By Campaigns' },
      { path: conversionPaths.byDeal(), label: 'By Deals' },
    ];

    const tabs = conversionTabs.map((tab) => ({
      label: tab.label,
      selected: pathname === tab.path,
      component: RouterLink,
      to: tab.path,
    }));

    return tabs;
  }

  componentDidMount() {
    setTitle(PAGE_TITLE, { noSuffix: false });
  }

  render() {
    const conversionPaths = paths.analytics().conversion();
    const { pathname } = this.props.location;
    if (pathname === conversionPaths.root()) {
      return <Redirect to={conversionPaths.byAffiliates()} />;
    }
    return (
      <DashboardLayout>
        <Title mb={3}>{PAGE_TITLE}</Title>
        <>
          <TabBar mb={3} tabs={this.tabs} />
          <Switch>
            <Route path={conversionPaths.byAffiliates()} component={ByAffiliate} />
            <Route path={conversionPaths.byEmployee()} component={ByEmployee} />
            <Route path={conversionPaths.byCampaign()} component={ByCampaign} />
            <Route path={conversionPaths.byDeal()} component={ByDeal} />
          </Switch>
        </>
      </DashboardLayout>
    );
  }
}

export default withStyles(styles)(Conversion);
