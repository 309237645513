import { Pool } from 'models';
import { IStationGroup, IStationPool, IStationUser, StationItem } from 'models/Station';

export type IDisplayPool = IStationPool & { name: string; id: number };

export type IStationDisplayGroup = IStationGroup &
  ICountedUsersAndPools & { id: string; name: string };

interface ICountedUsersAndPools {
  countUsers: number;
  countPools: number;
}

export class BlPoolsAndGroups {
  static mapPoolsAndGroupsForDisplay(
    items?: any,
    locationPools?: Array<Pool>,
  ): Array<IDisplayPool | IStationDisplayGroup> | undefined {
    if (!items || !items.length ) return undefined;

    return items.reduce(
      (
        poolsAndGroups: Array<IDisplayPool | IStationDisplayGroup>,
        item: IDisplayPool | IStationDisplayGroup | IStationUser,
      ) => {
        if (!Array.isArray(poolsAndGroups)) return [];
        if (item.type === StationItem.POOL) {
          if(!locationPools) return [...poolsAndGroups];
          const pool = locationPools.find(
            (pool: Pool) => pool.id === (item as IStationPool).poolId,
          );
          return [...poolsAndGroups, { ...item, name: pool!.name, id: item.poolId }];
        }
        if (item.type === StationItem.GROUP) {
          const { countUsers, countPools } = this.countGroupUsersAndPools(item.groupItems);
          return [
            ...poolsAndGroups,
            { ...item, countPools, countUsers, name: item.groupName, id: item.groupName },
          ];
        }
        return poolsAndGroups;
      },
      [],
    );
  }

  private static countGroupUsersAndPools(
    groupItems: IStationGroup['groupItems'],
  ): ICountedUsersAndPools {
    if (!groupItems || !groupItems.length) return { countUsers: 0, countPools: 0 };
    return groupItems.reduce(
      (
        countedItems: { countUsers: number; countPools: number },
        groupItem: IStationPool | IStationUser,
      ) => {
        if (groupItem.type === StationItem.POOL) {
          return { ...countedItems, countPools: countedItems.countPools + 1 };
        }
        if (groupItem.type === StationItem.USER) {
          return { ...countedItems, countUsers: countedItems.countUsers + 1 };
        }
        return countedItems;
      },
      { countUsers: 0, countPools: 0 },
    );
  }
}
