/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { observable, action, flow, computed, makeObservable } from 'mobx';
import { observer } from 'mobx-react';

import Api, { getErrorMsg } from 'api';
import { inject, WithToastStore, WithUserStore, WithModalStore } from 'stores';
import validatorjs from 'validatorjs';
import { Account, IncorporationResponse, IncorporationRequest } from 'models';
import { states } from 'services';

import { WithStyles, withStyles } from '@material-ui/core/styles';
import {
  Box,
  Button,
  MenuItem,
  TextField,
  Grid,
  Typography,
  InputAdornment,
} from '@material-ui/core';
import DP from 'components/DashPanel';

import styles from './styles';


import LoadingSpinner from 'components/LoadingSpinner';

enum LegalEntity {
  LLC = 'Limited Liability Company',
  C = 'Corporation',
  SP = 'Sole Proprietorship',
  PRT = 'Partnership',
}

type MobxForm = any;

const fields = [
  {
    name: 'name',
    label: 'Official Name of Entity with Secretary of State',
    extra: {
      helperText: 'Must be complete and accurate legal name',
    },
    rules: ['required'],
  },
  {
    name: 'incorporationState',
    label: 'State of Incorporation',
  },
  {
    name: 'year',
    label: 'Year of Incorporation',
    rules: ['digits:4'],
    hooks: {
      onChange: (field: any) => {
        const year = field.value;
        if (isNaN(+year) || year.length > 4) field.set(year.slice(0, -1));
      },
    },
  },
  {
    name: 'address',
    label: 'Address',
    extra: {
      helperText: 'This must be the physical business address',
    },
  },
  {
    name: 'address2',
    label: 'Address 2',
    extra: {
      helperText: 'Apt., Ste., etc.',
    },
  },
  {
    name: 'city',
    label: 'City',
  },
  {
    name: 'state',
    label: 'State',
  },
  {
    name: 'zip',
    label: 'ZIP Code',
    rules: ['digits:5'],
    hooks: {
      onChange: (field: any) => {
        const zipCode = field.value;
        if (isNaN(+zipCode) || zipCode.length > 5) field.set(zipCode.slice(0, -1));
      },
    },
  },
  {
    name: 'type',
    label: 'Type of Entity',
  },
  {
    name: 'ein',
    label: 'FEIN# / EIN',
    rules: ['required', 'digits:9'],
    hooks: {
      onChange: (field: any) => {
        const ein = field.value;
        if (isNaN(+ein) || ein.length > 9) field.set(ein.slice(0, -1));
      },
    },
  },
  {
    name: 'employeeCount',
    label: '# of Employees',
    hooks: {
      onChange: (field: any) => {
        const employeeCount = field.value;
        if (isNaN(+employeeCount)) field.set(employeeCount.slice(0, -1));
      },
    },
  },
  {
    name: 'paymentAmountHigh',
    label: 'Highest Payment Amount',
    rules: ['integer'],
    hooks: {
      onChange: (field: any) => {
        const inputString = field.value;
        // Allowed input characters are numbers only
        const lastChar = inputString[inputString.length - 1];
        const isValidChar = new RegExp(/^[0-9]$/).test(lastChar);
        if (!isValidChar) field.set(inputString.slice(0, -1));
      },
    },
  },
  {
    name: 'paymentAmountLow',
    label: 'Lowest Payment Amount',
    hooks: {
      onChange: (field: any) => {
        const inputString = field.value;
        // Allowed input characters are numbers only
        const lastChar = inputString[inputString.length - 1];
        const isValidChar = new RegExp(/^[0-9]$/).test(lastChar);
        if (!isValidChar) field.set(inputString.slice(0, -1));
      },
    },
  },
  {
    name: 'paymentAmountAvg',
    label: 'Average Payment Amount',
    hooks: {
      onChange: (field: any) => {
        const inputString = field.value;
        // Allowed input characters are numbers only
        const lastChar = inputString[inputString.length - 1];
        const isValidChar = new RegExp(/^[0-9]$/).test(lastChar);
        if (!isValidChar) field.set(inputString.slice(0, -1));
      },
    },
  },
  {
    name: 'signorTitle',
    label: 'TItle of Signor',
    extra: {
      helperText: 'e.g.: President, CEO, ...',
    },
  },
  {
    name: 'signorFirstName',
    label: 'Signor First Name',
  },
  {
    name: 'signorLastName',
    label: 'Signor Last Name',
  },
];

// eslint-disable-next-line @typescript-eslint/no-var-requires
const dvr = require('mobx-react-form/lib/validators/DVR');
const MobxReactForm = require('mobx-react-form').default;

const plugins = {
  dvr: dvr({
    package: validatorjs,
    extend: ({ validator }: { validator: any }) => {
      const messages = validator.getMessages('en');
      messages.accepted = 'Please confirm';
      messages.required = 'This field is required';
      validator.setMessages('en', messages);
    },
  }),
};

interface FormHooks {
  onSuccess: (form: MobxForm) => void;
}

type CardInfoProps = WithStyles<typeof styles> &
  RouteComponentProps<{ accountId: string }> &
  WithToastStore &
  WithUserStore &
  WithModalStore & { account?: Account; accountId: number };

/**
 * Displays the prompt for entering the existing card info
 */
@inject('toastStore', 'userStore', 'modalStore')
@observer
class IncorporationData extends React.Component<CardInfoProps> {
  componentDidMount() {
    this.getPayoutMethods();
    this.getIncorporationData();
  }

  /** The form object */
  @observable private form: MobxForm;

  /** Whether the form is currently being submitted */
  @observable public submitting = false;

  /** What's currently being loaded */
  @observable public loading = {
    // The programs part, where we check if the account already has netspend set up
    payoutMethods: true,
    // The incorporation dat
    incorporationData: true,
  };

  /** Whether netspend is already set up */
  @observable public netspendSetUp = false;

  /** Whether the email has already been sent */
  @observable public isEmailSent?: boolean;

  /** Fetch whether this account already has netspend set up */
  @action.bound public getPayoutMethods = flow(function* (this: IncorporationData) {
    try {
      this.loading.payoutMethods = true;
      const resp = yield Api.core.getAccountPayoutMethods(this.props.accountId);
      this.netspendSetUp = resp.data.data.instant;
    } catch (e: any) {
      this.props.toastStore!.error(getErrorMsg(e));
    } finally {
      this.loading.payoutMethods = false;
    }
  });

  /** Utility method for saving the response from the incorporation endpoint */
  @action.bound public saveIncorporationResponse(data: IncorporationResponse) {
    // For each field, copy the value from the response data
    for (const { name } of fields) {
      this.form.$(name).value = (data as any)[name];
    }
    this.isEmailSent = data.isEmailSent;
  }

  /** Gets the details about the incorporation data */
  @action.bound public getIncorporationData = flow(function* (this: IncorporationData) {
    try {
      this.loading.incorporationData = true;
      const resp = yield Api.core.getAccountIncorporationData(this.props.accountId);
      const incorpData = resp.data.data;
      this.saveIncorporationResponse(incorpData);
    } catch (e: any) {
      this.props.toastStore!.error(getErrorMsg(e));
    } finally {
      this.loading.incorporationData = false;
    }
  });

  /** Saves the data to the API and triggers sending the email to netspend */
  @action.bound public applyNetspend() {
    this.form.validate({ showErrors: true }).then(({ isValid }: { isValid: boolean }) => {
      if (isValid) {
        this.submit({ sendEmail: true });
      }
    });
  }

  /** Whether the initial data is loading */
  @computed public get loadingData() {
    return this.loading.incorporationData || this.loading.payoutMethods;
  }

  /** Is the form currently disabled */
  @computed public get formDisabled() {
    return Boolean(this.isEmailSent);
  }

  /**
   * The current form data as an IncorporationRequest, minus the sendEmail field
   */
  @computed public get incorporationFormData(): Omit<IncorporationRequest, 'sendEmail'> {
    const data: Record<string, string | null> = {};
    for (const { name } of fields) {
      if (this.form.$(name).value) {
        data[name] = this.form.$(name).value;
      } else {
        data[name] = null;
      }
    }
    return data;
  }

  public constructor(props: CardInfoProps) {
    super(props);
    makeObservable(this);
    this.form = new MobxReactForm({ fields }, { plugins, hooks: this.hooks });
  }

  private hooks: FormHooks = {
    onSuccess: () => this.submit({ sendEmail: false }),
  };

  /** Submits the form */
  @action.bound public submit = flow(function* (
    this: IncorporationData,
    { sendEmail }: { sendEmail: boolean },
  ) {
    try {
      this.submitting = true;
      const resp = yield Api.core.updateAccountIncorporationData(this.props.accountId, {
        ...this.incorporationFormData,
        sendEmail,
      });
      this.saveIncorporationResponse(resp.data.data);
      if (!sendEmail) {
        this.props.toastStore!.success('Incorporation data saved');
      }
    } catch (e: any) {
      this.props.toastStore!.error(getErrorMsg(e));
    } finally {
      this.submitting = false;
    }
  });

  render() {
    if (this.loadingData) {
      return (
        <Box justifyContent="center" alignItems="center" display="flex">
          <LoadingSpinner />
        </Box>
      );
    }
    return (
      <>
        <Grid container spacing={3}>
          <Grid item xl={12} lg={12}>
            <DP>
              <DP.Header>
                <DP.Title>Incorporation Data</DP.Title>
              </DP.Header>
              <DP.Header>
                <Typography color="textSecondary">
                  If you want to take advantage of certain programs offered by Tippy, fill out the
                  following data. With that, you can enroll in programs that take your tips to the
                  next level!
                </Typography>
              </DP.Header>
              <DP.Body>
                <form onSubmit={this.form.onSubmit}>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <TextField
                        {...this.form.$('name').bind()}
                        error={Boolean(this.form.$('name').error)}
                        helperText={
                          this.form.$('name').error || this.form.$('name').extra.helperText
                        }
                        disabled={this.isEmailSent}
                        fullWidth
                        autoFocus
                      />
                    </Grid>
                    <Grid item lg={6} xs={12}>
                      <TextField
                        {...this.form.$('incorporationState').bind()}
                        select
                        error={Boolean(this.form.$('incorporationState').error)}
                        disabled={this.isEmailSent}
                        helperText={this.form.$('incorporationState').error}
                        fullWidth>
                        {Object.entries(states).map(([key, label]) => (
                          <MenuItem key={key} value={key}>
                            {key} - {label}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                    <Grid item lg={6} xs={12}>
                      <TextField
                        {...this.form.$('year').bind()}
                        error={Boolean(this.form.$('year').error)}
                        disabled={this.isEmailSent}
                        helperText={this.form.$('year').error}
                        fullWidth
                      />
                    </Grid>
                    <Grid item lg={12} xs={12}>
                      <TextField
                        {...this.form.$('address').bind()}
                        error={Boolean(this.form.$('address').error)}
                        disabled={this.isEmailSent}
                        helperText={
                          this.form.$('address').error || this.form.$('address').extra.helperText
                        }
                        fullWidth
                      />
                    </Grid>
                    <Grid item lg={6} xs={12}>
                      <TextField
                        {...this.form.$('address2').bind()}
                        error={Boolean(this.form.$('address2').error)}
                        disabled={this.isEmailSent}
                        helperText={
                          this.form.$('address2').error || this.form.$('address2').extra.helperText
                        }
                        fullWidth
                      />
                    </Grid>
                    <Grid item lg={6} xs={12}>
                      <TextField
                        {...this.form.$('city').bind()}
                        error={Boolean(this.form.$('city').error)}
                        disabled={this.isEmailSent}
                        helperText={this.form.$('city').error}
                        fullWidth
                      />
                    </Grid>
                    <Grid item lg={6} xs={12}>
                      <TextField
                        {...this.form.$('state').bind()}
                        select
                        error={Boolean(this.form.$('state').error)}
                        disabled={this.isEmailSent}
                        helperText={this.form.$('state').error}
                        fullWidth>
                        {Object.entries(states).map(([key, label]) => (
                          <MenuItem key={key} value={key}>
                            {key} - {label}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                    <Grid item lg={6} xs={12}>
                      <TextField
                        {...this.form.$('zip').bind()}
                        error={Boolean(this.form.$('zip').error)}
                        disabled={this.isEmailSent}
                        helperText={this.form.$('zip').error}
                        fullWidth
                      />
                    </Grid>
                    <Grid item lg={4} xs={12}>
                      <TextField
                        {...this.form.$('ein').bind()}
                        error={Boolean(this.form.$('ein').error)}
                        disabled={this.isEmailSent}
                        helperText={this.form.$('ein').error}
                        fullWidth
                      />
                    </Grid>
                    <Grid item lg={5} xs={12}>
                      <TextField
                        {...this.form.$('type').bind()}
                        select
                        error={Boolean(this.form.$('type').error)}
                        disabled={this.isEmailSent}
                        helperText={this.form.$('type').error}
                        fullWidth>
                        {Object.values(LegalEntity).map((entity) => (
                          <MenuItem key={entity} value={entity}>
                            {entity}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                    <Grid item lg={3} xs={12}>
                      <TextField
                        {...this.form.$('employeeCount').bind()}
                        error={Boolean(this.form.$('employeeCount').error)}
                        disabled={this.isEmailSent}
                        helperText={this.form.$('employeeCount').error}
                        fullWidth
                      />
                    </Grid>
                    <Grid item lg={4} xs={12}>
                      <TextField
                        {...this.form.$('paymentAmountHigh').bind()}
                        error={Boolean(this.form.$('paymentAmountHigh').error)}
                        disabled={this.isEmailSent}
                        helperText={this.form.$('paymentAmountHigh').error}
                        fullWidth
                        InputProps={{
                          startAdornment: <InputAdornment position="start">$</InputAdornment>,
                        }}
                      />
                    </Grid>
                    <Grid item lg={4} xs={12}>
                      <TextField
                        {...this.form.$('paymentAmountLow').bind()}
                        error={Boolean(this.form.$('paymentAmountLow').error)}
                        disabled={this.isEmailSent}
                        helperText={this.form.$('paymentAmountLow').error}
                        fullWidth
                        InputProps={{
                          startAdornment: <InputAdornment position="start">$</InputAdornment>,
                        }}
                      />
                    </Grid>
                    <Grid item lg={4} xs={12}>
                      <TextField
                        {...this.form.$('paymentAmountAvg').bind()}
                        error={Boolean(this.form.$('paymentAmountAvg').error)}
                        disabled={this.isEmailSent}
                        helperText={this.form.$('paymentAmountAvg').error}
                        fullWidth
                        InputProps={{
                          startAdornment: <InputAdornment position="start">$</InputAdornment>,
                        }}
                      />
                    </Grid>
                    <Grid item lg={12} xs={12}>
                      <TextField
                        {...this.form.$('signorTitle').bind()}
                        error={Boolean(this.form.$('signorTitle').error)}
                        disabled={this.isEmailSent}
                        helperText={
                          this.form.$('signorTitle').error ||
                          this.form.$('signorTitle').extra.helperText
                        }
                        fullWidth
                      />
                    </Grid>
                    <Grid item lg={6} xs={12}>
                      <TextField
                        {...this.form.$('signorFirstName').bind()}
                        error={Boolean(this.form.$('signorFirstName').error)}
                        disabled={this.isEmailSent}
                        helperText={this.form.$('signorFirstName').error}
                        fullWidth
                      />
                    </Grid>
                    <Grid item lg={6} xs={12}>
                      <TextField
                        {...this.form.$('signorLastName').bind()}
                        error={Boolean(this.form.$('signorLastName').error)}
                        disabled={this.isEmailSent}
                        helperText={this.form.$('signorLastName').error}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12}>
                      {!this.isEmailSent && (
                        <Grid item xs={12}>
                          <Button
                            variant="outlined"
                            color="primary"
                            type="submit"
                            disabled={this.submitting}
                            fullWidth>
                            Save
                          </Button>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                </form>
              </DP.Body>
            </DP>
          </Grid>          
        </Grid>
      </>
    );
  }
}

export default withStyles(styles)(IncorporationData);
