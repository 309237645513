/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { observable, action, flow, computed, makeObservable } from 'mobx';
import { observer } from 'mobx-react';
import { WithStyles, withStyles } from '@material-ui/core/styles';
import { Box, Grid } from '@material-ui/core';
import Api, * as api from 'api';
import { inject, WithUserStore, WithToastStore } from 'stores';
import type { Location, Owner, Account, User, Device, Campaign, IntegrationApp } from 'models';
import { Address } from 'types';
import LocationsPanel from 'components/LocationsPanel';
import UsersPanel from 'components/UsersPanel';
import CodePanel from 'components/CodePanel';
import IntegrationPanel from 'components/IntegrationPanel';
import CampaignPanel from 'components/CampaignPanel';
import styles from './styles';
import DevicesPanel from 'components/DevicesPanel';
import KioskLinkPanel from 'components/KioskLinkPanel/KioskLinkPanel';
import AddressPanel from 'components/AddressPanel';
import UserDetailsPanel from 'components/UserDetailsPanel';
import TippyMasonry from 'components/TippyMasonry/TippyMasonry';

/** Here we define what kind of props this component takes */
interface InfoProps
  extends WithStyles<typeof styles>,
    WithUserStore,
    RouteComponentProps,
    WithToastStore {
  accountId: number;
  account?: Account;
  refetchAccount: () => void;
}
/**
 * Container for account address, primary owner, locations and users panels.
 */
@inject('userStore', 'toastStore')
@observer
class Info extends React.Component<InfoProps> {
  constructor(props: InfoProps) {
    super(props);
    makeObservable(this);
  }
  /** The locations for this account */
  @observable public locations?: Location[];

  /** The owners for this account */
  @observable public owners?: Owner[];

  /** The managers for this account */
  @observable public managers?: any;

  /** Account devices */
  @observable devices?: Device[];

  /** The campaign for this account */
  @observable public campaign?: Campaign;

  /** The App Integrations for this account */
  @observable public integrationApp?: IntegrationApp;

  /** Controlled field props for UserDetailsPanel */
  private primaryOwnerDataFields: { name: keyof User; editable: boolean }[] = [
    { name: 'firstName', editable: true },
    { name: 'lastName', editable: true },
    { name: 'email', editable: true },
    { name: 'phone', editable: true },
  ];

  /** The primary owner of the account */
  @computed public get primaryOwner(): Owner | undefined {
    if (!this.owners) {
      return undefined;
    }
    // Since the owner only contains accountUsers entries for the current account,
    // we know there will be exactly one entry, so we can index it at [0].
    return this.owners.filter((owner) => owner.owners[0]!.type === 'primary')[0];
  }

  /** Account for this location */
  @observable public onlinePaymentEnabled?: boolean;

  /** Fetches the locations for this account */
  @action.bound public getLocations = flow(function* (this: Info) {
    const resp = yield Api.core.getAccountLocations(this.props.accountId);
    this.locations = resp.data && resp.data.data;
  });

  /** Fetches the owners for this account */
  @action.bound public getOwners = flow(function* (this: Info) {
    const resp = yield Api.core.getAccountOwners(this.props.accountId);
    this.owners = resp.data && resp.data.data;
  });

  @action.bound public getManagers = flow(function* (this: Info) {
    const resp = yield Api.core.getUsersForAccount(this.props.accountId, {}, '', 'managers');
    this.managers = resp.data && resp.data.data;
  });

  @action.bound private fetchTalents(req?: api.RequestMetaData, search?: any) {
    return Api.core.getTalent(req, search, this.props.accountId);
  }

  @action.bound public getDevices(meta?: api.RequestMetaData) {
    return Api.core.getAccountDevices(this.props.accountId, meta);
  }

  /** Fetches campaign by accountId for this account */
  @action.bound public getCampaign = async () => {
    if (!this.props.userStore?.isAdmin) return;
    try {
      const resp = await Api.marketing.getCampaignByAccountId(this.props.accountId);
      this.campaign = resp?.data?.data;
    } catch (error) {
      this.props.toastStore?.error(api.getErrorMsg(error));
    }
  };

  /** Fetches Apps Integrations for this account */
  @action.bound public getIntegrations = async () => {
    try {
      const resp = await Api.developer.getAppsForAccount(this.props.accountId);
      this.integrationApp = resp?.data?.data && resp?.data?.data[0];
    } catch (error) {
      this.props.toastStore?.error(api.getErrorMsg(error));
    }
  };

  @computed get accountId(): undefined | number {
    return this.props.account ? this.props.account.id : undefined;
  }

  @computed get accountCode(): string {
    const account = this.props.account;
    if (account && account.code) return account.code;
    if (account) return 'N/A';
    return '';
  }

  @computed get canRegenerateCode(): boolean {
    return this.props.account ? this.props.account.canRegenerateCode : false;
  }

  @computed get accountUid(): string {
    return this.props.account && this.props.account.uid ? this.props.account.uid : '';
  }

  @computed get loading(): boolean {
    return !this.props.account;
  }

  @computed public get primaryOwnerEditable() {
    return this.props.userStore!.isAdmin;
  }

  @computed public get addressEditable() {
    return this.props.userStore!.isAdmin;
  }

  @action.bound public getSettings = flow(function* (this: Info) {
    const resp = yield Api.core.getSettings('account', this.props.accountId);
    const settings = resp?.data?.data;
    if (settings) {
      this.onlinePaymentEnabled = settings.onlinePaymentsEnabled;
    }
  });

  @action.bound public updateAddress = flow(function* (this: Info, addr: Address) {
    try {
      yield Api.core.updateAccount(this.props.accountId, addr);
      this.props.toastStore!.success('Address updated!');
      this.props.refetchAccount();
    } catch (e: any) {
      this.props.toastStore!.error(api.getErrorMsg(e));
    }
  });

  componentDidMount() {
    // Fetch the locations for this account
    this.getLocations();
    // Fetch the managers
    this.getManagers();
    // Fetch the owners
    this.getOwners();
    // Fetch settings
    this.getSettings();
    // Fetch Campaign
    this.getCampaign();
    // Fetch Apps Integrations
    this.getIntegrations();
  }

  render() {
    const { account, accountId } = this.props;
    const isAdmin = this.props.userStore?.isAdmin;
    const gridSize = isAdmin ? 3 : 6;

    return (
      <Grid container direction={'row'} spacing={3}>
        <Grid item xs={12} md={6} lg={3} style={{ flexGrow: 1 }}>
          <CodePanel
            title="Account Code"
            accountId={this.accountId}
            canRegenerateCode={this.canRegenerateCode}
            code={this.accountCode}
            onCodeUpdate={this.props.refetchAccount}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={3} style={{ flexGrow: 1 }}>
          <KioskLinkPanel
            title="Kiosk Link"
            code={this.accountUid}
            type="account"
            disabled={!this.onlinePaymentEnabled}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={gridSize} style={{ flexGrow: 1 }}>
          <IntegrationPanel
            account={account!}
            title="Integration Info"
            integration={this.integrationApp}
          />
        </Grid>
        {isAdmin && (
          <Grid item xs={12} md={6} lg={gridSize} style={{ flexGrow: 1 }}>
            <CampaignPanel title="Campaign Info" campaign={this.campaign} />
          </Grid>
        )}
        <TippyMasonry>
          <Box display={'flex'} flexWrap={'wrap'} style={{ gap: '24px' }}>
            <Box className={this.props.classes.gridItem}>
              <AddressPanel
                addressEditable={this.addressEditable}
                fullWidth
                fullHeight
                title="Address"
                onUpdateAddress={this.addressEditable ? this.updateAddress : undefined}>
                {account}
              </AddressPanel>
            </Box>

            <Box className={this.props.classes.gridItem}>
              <UserDetailsPanel
                fullWidth
                fullHeight
                handleUserChanged={(u) => (this.primaryOwner!.email = u.email)}
                editable={false}
                fields={this.primaryOwnerDataFields}
                title="Primary Owner">
                {this.primaryOwner}
              </UserDetailsPanel>
            </Box>
          </Box>

          <UsersPanel
            fullWidth
            fetchTalents={this.fetchTalents}
            managers={this.managers}
            owners={this.owners}
            managerPermissions
            account={account}
          />
          <LocationsPanel
            fullWidth
            locations={this.locations}
            showAddButton={this.props.userStore!.isAdmin}
            onLocationAdded={this.getLocations}
            accountId={accountId}
          />
          <DevicesPanel fullWidth fetch={this.getDevices} />
        </TippyMasonry>
      </Grid>
    );
  }
}

export default withStyles(styles)(Info);
