/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { Link as RouterLink, RouteComponentProps } from 'react-router-dom';
import { observable, computed, action, flow, makeObservable } from 'mobx';
import { observer } from 'mobx-react';
import { v4 as uuidv4 } from 'uuid';
import {
  Box,
  Link,
  Typography,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@material-ui/core';
import { WithStyles, withStyles } from '@material-ui/core/styles';

import {
  inject,
  WithUserStore,
  WithToastStore,
  WithAnalyticsStore,
  WithSettingStore,
} from 'stores';
import { ManagerScope } from 'stores/UserStore';

import { paths } from 'routes';

import { AxiosResponse } from 'axios';
import Api, { ApiResponse, RequestMetaData } from 'api';
import { adaptForDataGridPro, setTitle, EllipsizedValue } from 'services';

import { TipReport, ChartData } from 'models';

import DashboardLayout from 'containers/DashboardLayout';

import * as DateRangeExternalPicker from 'components/DateRangeExternalPicker';

import DP from 'components/DashPanel';
import Chart from 'components/Chart';

import styles from './styles';

import { downloadCsvFile } from '../../utils/helper';

import { Filter } from 'components/FilterBar/FilterBar';
import FilterBar from 'components/FilterBar';
import DataGridInfiniteScroll from 'components/DataGridInfiniteScroll';
import Title from 'components/Title';
import Button from 'components/Button/Dialog/Button';
import TabBar from 'components/TabBar/TabBar';

const PAGE_TITLE = 'Stats';

enum Tab {
  TALENT = 'talent',
  LOCATION = 'location',
  ACCOUNT = 'account',
}

/** Here we define what kind of props this component takes */
type TipsReportProps = WithStyles<typeof styles> & // Adds the classes prop
  WithUserStore &
  WithToastStore &
  WithAnalyticsStore &
  RouteComponentProps &
  WithSettingStore;

/**
 * Container for displaying and downloading tip reports.
 * Accessible to admin, owner and manager scope.
 */
@inject('userStore', 'toastStore', 'analyticsStore', 'settingStore')
@observer
class TipsReport extends React.Component<TipsReportProps> {
  constructor(props: TipsReportProps) {
    super(props);
    makeObservable(this);
  }

  /** Chart data */
  @observable private chartData?: ChartData;

  @observable private reportDownloadWarningModalOpen = false;

  @observable public downloading = false;

  /** Active filters as returned by FilterBar */
  @observable private activeFilters: Record<string, unknown> = {};

  @observable private isMobile = window.matchMedia('(max-width:680px)').matches;

  @observable public selectedTab: 'talent' | 'location' | 'account' =
    this.isGlobalOwner || this.isAdmin ? 'account' : 'talent';

  /** The selected date range */
  @observable public dateRange: DateRangeExternalPicker.DateRange =
    this.props.settingStore!.getDate(`${this.props.location.pathname}/${this.selectedTab}`);

  @observable public chartActive: boolean | null = null;

  @action.bound public selectTab(tab: Tab) {
    this.dateRange = this.props.settingStore!.getDate(`${this.props.location.pathname}/${tab}`);
    this.selectedTab = tab;
    this.activeFilters = {};

    if (this.isAdmin) {
      this.initChart();
    }
  }

  @action.bound public toggleReportDownloadWarningModal() {
    this.reportDownloadWarningModalOpen = !this.reportDownloadWarningModalOpen;
  }

  /** Is user scope admin? */
  @computed private get isAdmin() {
    return this.props.userStore!.authUser.isAdmin;
  }

  /** Is user scope owner? */
  @computed private get isOwner() {
    return this.props.userStore!.scope.kind === 'owner';
  }

  /** Is user scope owner? */
  @computed private get isGlobalOwner() {
    return this.props.userStore!.scope.kind === 'global_owner';
  }

  /** Is user scope manager? */
  @computed private get isManager() {
    return this.props.userStore!.scope.kind === 'manager';
  }

  /** Watch for change on table filters and build req meta data */
  @observable private dataGridFilters: Record<string, unknown> = {};

  /** Account Id if current user is owner or undefined */
  @computed private get accountId() {
    return this.isOwner ? this.props.userStore!.currentAccount!.id : undefined;
  }

  /** Location Id if current user is manager or undefined */
  @computed private get locationId() {
    const scope = this.props.userStore!.scope as ManagerScope;
    return this.isManager ? scope.locationId : undefined;
  }

  @computed public get tabs() {
    const { selectedTab } = this;

    return [
      {
        label: 'By Account',
        selected: selectedTab === Tab.ACCOUNT,
        onClick: () => this.selectTab(Tab.ACCOUNT),
      },
      {
        label: 'By Location',
        selected: selectedTab === Tab.LOCATION,
        onClick: () => this.selectTab(Tab.LOCATION),
      },
      {
        label: 'By Employees',
        selected: selectedTab === Tab.TALENT,
        onClick: () => this.selectTab(Tab.TALENT),
      },
    ];
  }

  /** Annotates tip report fields with extra data based on selected tab */
  @action.bound private annotateTips = (tip: TipReport) => {
    if (this.selectedTab === 'talent') {
      return {
        ...tip,
        id: uuidv4(),
        name: `${tip.firstName} ${tip.lastName}`,
        accountName: tip.account ? tip.account.name : '',
        accountId: tip.account ? tip.account.id : null,
        locationName: tip.location ? tip.location.name : '',
        locationId: tip.location ? tip.location.id : null,
      };
    }
    if (this.selectedTab === 'location') {
      return {
        ...tip,
        id: uuidv4(),
        accountName: tip.account ? tip.account.name : '',
        accountId: tip.account ? tip.account.id : null,
        locationName: tip.location ? tip.location.name : '',
        locationId: tip.location ? tip.location.id : null,
      };
    }
    if (this.selectedTab === 'account') {
      return {
        ...tip,
        id: uuidv4(),
        accountName: tip.account ? tip.account.name : '',
        accountId: tip.account ? tip.account.id : null,
      };
    }
    return tip;
  };

  @action.bound public fetchTips = adaptForDataGridPro(async (rmd: RequestMetaData) => {
    const locationIds =
      rmd.filters && rmd.filters.locationId ? rmd.filters.locationId : this.locationId;
    const accountIds =
      rmd.filters && rmd.filters.accountId ? rmd.filters.accountId : this.accountId;

    let fetch = this.getFetch();

    return await fetch({
      ...rmd,
      filters: {
        fromDate: this.dateRange.fromDate,
        toDate: this.dateRange.toDate,
        ...this.activeFilters,
        locationIds,
        accountIds,
      },
    });
  }, this.annotateTips);

  getFetch(): (
    rmd?: RequestMetaData,
    extraData?: Record<string, unknown>,
  ) => Promise<AxiosResponse<any, any>> {
    let fetch = Api.analytics.stats.tipsStats.byTalent;
    if (this.isGlobalOwner) {
      fetch = this.getGlobalOwnerFetch();
    } else if (this.isOwner) {
      fetch = Api.analytics.stats.tipsStats.byTalent;
    } else if (this.isAdmin) {
      fetch = this.getAdminFetch();
    }
    return fetch;
  }

  getGlobalOwnerFetch() {
    switch (this.selectedTab) {
      case 'account':
        return Api.analytics.global.tipsStats.byAccount;
      case 'location':
        return Api.analytics.global.tipsStats.byLocation;
      case 'talent':
        return Api.analytics.global.tipsStats.byTalent;
    }
  }

  getGlobalOwnerCsvFetch() {
    switch (this.selectedTab) {
      case 'account':
        return Api.analytics.global.tipsCsvReport.byAccount;
      case 'location':
        return Api.analytics.global.tipsCsvReport.byLocation;
      case 'talent':
        return Api.analytics.global.tipsCsvReport.byTalent;
    }
  }

  getAdminFetch() {
    switch (this.selectedTab) {
      case 'account':
        return Api.analytics.stats.tipsStats.byAccount;
      case 'location':
        return Api.analytics.stats.tipsStats.byLocation;
      case 'talent':
        return Api.analytics.stats.tipsStats.byTalent;
    }
  }

  getAdminCsvFetch() {
    switch (this.selectedTab) {
      case 'account':
        return Api.analytics.stats.tipsCsvReport.byAccount;
      case 'location':
        return Api.analytics.stats.tipsCsvReport.byLocation;
      case 'talent':
        return Api.analytics.stats.tipsCsvReport.byTalent;
    }
  }

  /** Fetch aggregated data for tip analytics chart */
  @action.bound public fetchChartData = flow(function* (this: TipsReport) {
    yield Api.analytics.stats.chartReport
      .getTipsChartData({
        filters: {
          fromDate: this.dateRange && this.dateRange.fromDate,
          toDate: this.dateRange && this.dateRange.toDate,
          ...this.activeFilters,
        },
      })
      .then((resp: AxiosResponse<ApiResponse<ChartData>>) => {
        this.chartData = resp.data.data;
      });
  });

  initChart() {
    this.chartData = undefined;
    this.fetchChartData();
  }

  /** Download payroll run csv report for selected date range */
  @action.bound public downloadTipsSummaryReport = flow(function* (this: TipsReport) {
    let fetch = Api.analytics.stats.tipsCsvReport.byTalent;
    this.downloading = true;
    let extraReqData: any = {
      fromDate: this.dateRange && this.dateRange.fromDate,
      toDate: this.dateRange && this.dateRange.toDate,
      locationIds: this.locationId,
      accountIds: this.accountId,
      ...this.activeFilters,
    };
    if (this.isGlobalOwner) {
      extraReqData = { ...extraReqData, locationIds: undefined, accountIds: undefined };
      fetch = Api.analytics.global.tipsCsvReport.byTalent;
    }
    try {
      const resp: AxiosResponse<Blob> = yield fetch(
        { filters: { ...this.dataGridFilters } },
        extraReqData,
      );
      if (resp && resp.data) {
        downloadCsvFile(resp.data, 'talent_tips_report.csv');
      }
    } catch (e: any) {
      this.props.toastStore!.push({
        message: 'Unable to download requested report',
        type: 'error',
      });
    } finally {
      this.downloading = false;
      this.toggleReportDownloadWarningModal();
    }
  });

  /** This endpoint is for locations and accounts report download */
  @action.bound private downloadCsvReport(reportFor: string) {
    const extraReqData = {
      fromDate: this.dateRange && this.dateRange.fromDate,
      toDate: this.dateRange && this.dateRange.toDate,
      ...this.activeFilters,
    };

    let reportFunction = Api.analytics.stats.tipsStats.byTalent;
    if (this.isGlobalOwner) {
      reportFunction = this.getGlobalOwnerCsvFetch();
    } else if (this.isAdmin) {
      reportFunction = this.getAdminCsvFetch();
    }

    return async () => {
      try {
        const resp: AxiosResponse<Blob> = await reportFunction(
          { filters: { ...this.dataGridFilters } },
          extraReqData,
        );
        if (resp && resp.data) {
          downloadCsvFile(resp.data, `${reportFor}_tips_report.csv`);
        }
      } catch (e: any) {
        this.props.toastStore!.push({
          message: 'Unable to download requested report',
          type: 'error',
        });
      }
    };
  }

  /** Sets the date range */
  @action.bound private updateDateRangeValue(range: DateRangeExternalPicker.DateRange) {
    this.props.settingStore!.setDate(`${this.props.location.pathname}/${this.selectedTab}`, range);
    this.dateRange = range;
    this.activeFilters = { ...this.activeFilters };

    if (this.isAdmin) {
      this.initChart();
    }
  }

  @action.bound private async toggleChart() {
    this.chartActive = this.chartActive ? null : true;
    this.initChart();
  }

  componentDidMount() {
    setTitle(PAGE_TITLE, { noSuffix: false });
    this.isAdmin && this.initChart();
    window.matchMedia('(max-width:680px)').addEventListener('change', (e) => {
      this.isMobile = e.matches;
    });
  }

  /** Render's the location name */
  renderCellLocation = ({ row }: any) => {
    if (row.locationId) {
      return (
        <Link
          component={RouterLink}
          to={paths.locationDetails(row.locationId)}
          style={{ width: '100%' }}>
          <EllipsizedValue value={row.locationName} max={20} />
        </Link>
      );
    }
    return <></>;
  };

  /** Renders the user's name */
  renderCellName = ({ row }: any) => {
    const label =
      row.firstName && row.lastName ? `${row.firstName} ${row.lastName}` : 'Undistributed';
    return (
      <Link component={RouterLink} to={paths.userDetails(row.userId).info()}>
        {label}
      </Link>
    );
  };

  renderCellAccount = ({ row }: any) => {
    if (row.accountId) {
      return (
        <Link component={RouterLink} to={paths.accountDetails(row.accountId).root()}>
          {row.accountName}
        </Link>
      );
    }
    return <></>;
  };

  renderCellNetTip = ({ row }: any) => {
    return `${row.percentAvg}%`;
  };

  commonColumns = [
    { headerName: 'Count', field: 'count', minWidth: 150, flex: 1 },
    { headerName: 'Tip Avg', field: 'netAvg', minWidth: 150, flex: 1 },
    { headerName: 'Net Tip', field: 'netSum', minWidth: 150, flex: 1 },
    { headerName: 'Service', field: 'serviceAmountSum', minWidth: 150, flex: 1 },
    {
      headerName: 'Net Tip',
      field: 'percentAvg',
      minWidth: 150,
      flex: 1,
      renderCell: this.renderCellNetTip,
    },
    { headerName: 'Svc Avg', field: 'serviceAmountAvg', minWidth: 150, flex: 1 },
  ];

  byTalentColumns = [
    { headerName: 'Name', field: 'name', minWidth: 150, flex: 1, renderCell: this.renderCellName },
  ];

  byLocationColumns = [
    {
      headerName: 'Location',
      field: 'location',
      minWidth: 150,
      flex: 1,
      renderCell: this.renderCellLocation,
      filterable: this.isAdmin || this.isOwner || this.isGlobalOwner,
    },
  ];

  byAccountColumns = [
    { headerName: 'Account', field: 'account', width: 150, renderCell: this.renderCellAccount },
  ];

  ownerColumns = [
    { headerName: 'Low (%)', field: 'lowPercentage', width: 150 },
    { headerName: 'Medium (%)', field: 'mediumPercentage', width: 150 },
    { headerName: 'High (%)', field: 'highPercentage', width: 150 },
    { headerName: 'Custom (%)', field: 'customPercentage', width: 150 },
  ];

  exportElements = [
    {
      name: 'Download as CSV',
      action: () => {
        this.selectedTab === 'talent'
          ? this.toggleReportDownloadWarningModal()
          : this.downloadCsvReport(this.selectedTab)();
      },
    },
  ];

  @action.bound public getColumnsOptions(key: string) {
    let columns = [];
    switch (key) {
      case 'talent':
        columns = [
          ...this.byTalentColumns,
          ...this.byAccountColumns,
          ...this.byLocationColumns,
          ...this.commonColumns,
        ];
        break;
      case 'location':
        columns = [...this.byAccountColumns, ...this.byLocationColumns, ...this.commonColumns];
        break;
      default:
        columns = [...this.byAccountColumns, ...this.commonColumns];
    }

    if (this.isGlobalOwner || this.isOwner) {
      columns = [...columns, ...this.ownerColumns];
    }
    return columns;
  }

  getOptions(fields: string[]) {
    const options: Filter[] = [
      { display: 'Name', id: 'name', label: 'Contains', type: 'text' },
      { display: 'Account', id: 'account', label: 'Contains', type: 'text' },
      { display: 'Location', id: 'location', label: 'Contains', type: 'text' },
    ];

    return fields.reduce((acc: Filter[], field: string) => {
      const option = options.find((f) => f.id == field);
      if (option) {
        acc.push(option);
      }
      return acc;
    }, []);
  }

  /** List of available filters for FilterBar component */
  @action.bound public getFilterOptions(key: string): Filter[] {
    switch (key) {
      case 'talent':
        if (this.isManager) return [];
        return this.getOptions(
          this.isAdmin || this.isGlobalOwner ? ['name', 'account', 'location'] : ['location'],
        );
      case 'location':
        return this.getOptions(['account', 'location']);
      default:
        return this.getOptions(['account']);
    }
  }

  render() {
    const showChart = this.isAdmin && this.chartActive;
    const showChartButton = this.isAdmin ? this.chartActive : false;
    return (
      <DashboardLayout>
        <>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center">
            <Box display="flex" justifyContent="flex-start">
              <Title mb={3}>{PAGE_TITLE}</Title>
            </Box>
          </Box>
          <TabBar mb={3} hide={!this.isAdmin && !this.isGlobalOwner} tabs={this.tabs} />
          <FilterBar
            filters={this.getFilterOptions(this.selectedTab)}
            onChange={(filters: Record<string, unknown>) => {
              this.activeFilters = filters;
              this.isAdmin && this.initChart();
            }}
            graph={
              !this.isMobile
                ? {
                    isActive: showChartButton,
                    onActive: this.toggleChart,
                  }
                : undefined
            }
            externalDateRange={{
              predefined: this.dateRange,
              onChange: this.updateDateRangeValue,
            }}
          />
          {showChart && !this.isMobile && (
            <Box mt={3}>
              <Chart data={this.chartData} />
            </Box>
          )}
          <Box mt={3}>
            <Paper>
              <Box mt={3}>
                <DataGridInfiniteScroll
                  columns={this.getColumnsOptions(this.selectedTab)}
                  fetch={this.fetchTips}
                  refetchKey={this.activeFilters}
                  disableColumnMenu
                  actions={{
                    onExport: this.exportElements,
                  }}
                  pathname={this.props.location.pathname}
                />
              </Box>
            </Paper>
          </Box>
        </>
        <Dialog
          open={this.reportDownloadWarningModalOpen}
          onClose={this.toggleReportDownloadWarningModal}
          fullWidth
          maxWidth={'sm'}>
          <DialogTitle>
            <Box display="flex" justifyContent="space-between">
              <Typography variant="h4" component="h1" display="inline">
                Download employee tip report
              </Typography>
              {this.downloading && <DP.LoadSpinner />}
            </Box>
          </DialogTitle>
          <DialogContent>
            This report is not a payroll report. Payroll reports can be found by clicking{` `}
            <Link component={RouterLink} to={paths.reports()}>
              here
            </Link>
            .
          </DialogContent>
          <DialogActions>
            <Button
              variant="outlined"
              onClick={this.toggleReportDownloadWarningModal}
              color="primary">
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={this.downloadTipsSummaryReport}
              disabled={this.downloading}>
              Download
            </Button>
          </DialogActions>
        </Dialog>
      </DashboardLayout>
    );
  }
}

export default withStyles(styles)(TipsReport);
