import { Theme, createStyles, makeStyles } from '@material-ui/core';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const styles = (theme: Theme) =>
  createStyles({
    root: {},
    addPoolButton: {
      textTransform: 'none',
      color: theme.palette.primary.main,
      '&.MuiButton-root:hover': {
        backgroundColor: 'transparent',
      },
    },
    listItemText: {
      position: 'relative',
      // '&::after': {
      //   content: '""',
      //   display: 'block',
      //   position: 'absolute',
      //   height: '1px',
      //   width: '100%',
      //   backgroundColor: 'lightgrey',
      //   bottom: -10,
      //   left: 0,
      // },
      // marginBottom: 20,
    },
    checkIcon: {
      color: theme.palette.primary.main,
    },
    selectedItemText: {
      position: 'relative',
      // '&::after': {
      //   content: '""',
      //   display: 'block',
      //   position: 'absolute',
      //   height: '1px',
      //   width: '100%',
      //   backgroundColor: 'lightgrey',
      //   bottom: -10,
      //   left: 0,
      // },
      '& .MuiTypography-body1': {
        color: theme.palette.primary.main,
        fontSize: '16px',
        fontWeight: 700,
      },
    },
  });

export default styles;

export const useStyles = makeStyles(styles);
