import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { ButtonProps } from '@mui/material';
import { underline } from 'utils/commonStyles';

interface IStyleProps {
  variant?: ButtonProps['variant'];
  disabled?: ButtonProps['disabled'];
}

export const useStyles = makeStyles<Theme, IStyleProps>((theme: Theme) =>
  createStyles({
    font: {
      color: ({ disabled, variant }) =>
        disabled
          ? theme.palette.button.disabled
          : variant === 'text'
          ? theme.palette.primary.main
          : theme.palette.text.primary,
      fontWeight: 400,
    },
    button: {
      height: ({ variant }) => (variant === 'text' ? 'auto' : theme.spacing(7.5)),
      backgroundColor: ({ variant, disabled }) =>
        variant === 'text'
          ? 'transparent'
          : disabled
          ? theme.palette.button.disabled
          : theme.palette.primary.main,
      padding: ({ variant }) => (variant === 'text' ? 0 : 'auto'),
      border: 'none',
      '&:hover': {
        backgroundColor: ({ variant = 'contained' }) =>
          variant === 'text' ? 'transparent' : theme.palette.primary.hover,
      },
    },
    progress: {
      color: theme.palette.text.primary,
    },
    outlinedButton: {
      fontWeight: 400,
      backgroundColor: theme.palette.common.white,
      border: ({ disabled }) =>
        disabled
          ? `1px solid ${theme.palette.button.disabled}`
          : `1px solid ${theme.palette.primary.main}`,
      color: ({ disabled }) =>
        disabled ? theme.palette.button.disabled : theme.palette.primary.main,
    },
    textButton: {
      padding: 0,
      height: 'auto',
      textTransform: 'none',
      textDecoration: 'none',
      underline,
      position: 'relative',
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
    underlineStyles: {
      ...underline,
    },
  }),
);
