import { createStyles, makeStyles } from '@material-ui/styles';
import theme from 'containers/App/theme';

const useStyles = makeStyles(() =>
  createStyles({
    upDownCounter: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    upDownCounterVertical: {
      flexDirection: 'column',
      alignItems: 'flex-start',
      justifyContent: '',
    },
    icon: {
      color: theme.palette.common.black,
      height: 18,
    },
  }),
);

export default useStyles;
