import React from 'react';
import { action, makeObservable } from 'mobx';
import { observer } from 'mobx-react';
import { Box, Typography } from '@material-ui/core';

import Api, { getErrorMsg } from 'api';
import { inject, WithToastStore } from 'stores';
import * as models from 'models';

import AutocompleteSearch from 'components/AutocompleteSearch';

interface AffiliateSearchProps extends WithToastStore {
  onChange: (a: models.Affiliate | null) => void;
  value?: models.Affiliate | null;
  label?: string;
  placeholder?: string;
  disabled?: boolean;
  InputProps?: { disableUnderline?: boolean; className?: string };
  className?: string;
  helperText?: string;
}

/** A component that displays a field for searching for affiliates */
@inject('toastStore')
@observer
class AffiliateSearch extends React.Component<AffiliateSearchProps> {
  constructor(props: AffiliateSearchProps) {
    super(props);
    makeObservable(this);
  }

  /** Gets the label for an affiliate in the autocomplete search */
  private getAffiliateOptionLabel(aff?: models.Affiliate): string {
    if (!aff) {
      // Sometimes we get undefined here, not sure why
      return '';
    } else {
      if (aff.code) {
        return `${aff.user!.firstName} ${aff.user!.lastName} (${aff.code})`;
      } else {
        return `${aff.user!.firstName} ${aff.user!.lastName}`;
      }
    }
  }

  /** Renders the option for affiliates */
  @action.bound private renderAffiliateOption(aff: models.Affiliate): React.ReactNode {
    return (
      <Box display="flex" flexDirection="row" justifyContent="space-between" width="100%">
        <Box>
          {aff.user!.firstName} {aff.user!.lastName}
        </Box>
        <Box>
          <Typography color="primary">{aff.code}</Typography>
        </Box>
      </Box>
    );
  }

  /** Fetch the affiliates by search string */
  @action.bound public async searchAffiliates(search: string): Promise<models.Affiliate[]> {
    // If there is no search term, don't do request
    if (!search) {
      return [];
    }
    try {
      const resp = await Api.marketing.affiliateSearch(search);
      if (resp.data) {
        return resp.data.data;
      } else {
        return [];
      }
    } catch (e: any) {
      this.props.toastStore!.push({ type: 'error', message: getErrorMsg(e) });
      return [];
    }
  }
  render() {
    const { onChange, label, placeholder, value, disabled, className, InputProps, helperText } =
      this.props;
    return (
      <AutocompleteSearch
        className={className}
        fetch={this.searchAffiliates}
        onChange={onChange}
        label={label}
        value={value}
        helperText={helperText}
        disabled={disabled}
        getOptionLabel={this.getAffiliateOptionLabel}
        getOptionSelected={(o, v) => o.id === v.id}
        renderOption={this.renderAffiliateOption}
        placeholder={placeholder}
        InputProps={InputProps}
      />
    );
  }
}

export default AffiliateSearch;
