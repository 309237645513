import { useCallback, useEffect, useMemo, useState } from 'react';
import Api, { getErrorMsg } from 'api';
import * as models from 'models/OsType';
import { LINK_APP_APPSTORE, LINK_APP_GOOGLE_PLAY } from 'utils/constants';
import { useStores } from 'containers/App/App';
import theme from 'containers/App/theme';
import { paths } from 'routes';
import { TSignupLayoutAction } from 'containers/SignupLayout/SignupLayout';
import { useHistory } from 'react-router-dom';
import { initializeOS } from 'utils/operatingSystem';

export type TOsType = models.EOsType;

const useSignupSuccess = () => {
  const history = useHistory();

  const { userStore, toastStore } = useStores();

  const [osType, setOsType] = useState<TOsType>(() => initializeOS());

  const isManager = userStore?.isManager;

  const isSp = userStore?.isSp;

  const isManagerOnly = isManager && !isSp;

  const goToDashboard = useCallback(() => history.push(paths.root()), [history]);

  /** Send request for welcome email dispatch  */
  const sendWelcomeEmail = useCallback(async () => {
    try {
      await Api.core.sendWelcomeEmail(userStore!.user!.id);
    } catch (error) {
      toastStore.error(getErrorMsg(error));
    }
  }, [userStore, toastStore]);

  const signupLayoutProps = useMemo(() => {
    const title = 'Success';
    const action: TSignupLayoutAction = {
      onClick: goToDashboard,
      children: isManagerOnly && 'Go to dashboard',
    };
    const contentWrapperStyles = {
      marginTop: theme.spacing(4),
    };
    return {
      title,
      action,
      contentWrapperStyles,
    };
  }, [goToDashboard, isManagerOnly]);

  useEffect(() => {
    setOsType(initializeOS());
    sendWelcomeEmail();
  }, [sendWelcomeEmail]);

  const isAndroid = osType === models.EOsType.ANDROID;

  const isOther = osType === models.EOsType.OTHER;

  const isDesktop = osType === models.EOsType.MAC_OS || isOther;

  const showDefaultSuccessScreen = isManagerOnly;

  const showQrCodes = !showDefaultSuccessScreen && isDesktop;

  const showStoreLink = !showDefaultSuccessScreen && !showQrCodes;

  const storeLink = isAndroid || isOther ? LINK_APP_GOOGLE_PLAY : LINK_APP_APPSTORE;

  const goToStore = () => (window.location.href = storeLink);

  const storeQrCodesProps = {
    osType,
    storeLink,
    onOsTypeChange: setOsType,
  };

  const storeButtonProps = {
    osType,
    goToStore,
  };

  return {
    signupLayoutProps,
    showDefaultSuccessScreen,
    showQrCodes,
    showStoreLink,
    goToStore,
    storeQrCodesProps,
    storeButtonProps,
  };
};

export default useSignupSuccess;
