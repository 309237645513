import { Box, CircularProgress, List, Typography } from '@material-ui/core';
import { WithStyles, withStyles } from '@material-ui/core/styles';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Api, { getErrorMsg } from 'api';
import Button from 'components/Button/Button';
import DD from 'components/DashDrawer';
import OutlinedInput from 'components/Input/OutlinedInput';
import InventoryItem from 'components/InventoryItem';
import InventoryItemChooser from 'components/InventoryItemChooser';
import Overlay from 'components/Overlay';
import { action, computed, flow, observable, makeObservable } from 'mobx';
import { observer } from 'mobx-react';
import { Inventory, Location } from 'models';
import React from 'react';
import { inject, WithToastStore } from 'stores';
import styles from './styles';

interface CreateShipmentProps extends WithStyles<typeof styles>, WithToastStore {
  fulfillmentId: number;
  accountId: number;
  onBack: () => void;
  onComplete?: () => void;
}

/**
 * The component for creating a shipment.
 */
@inject('userStore', 'toastStore')
@observer
class CreateShipment extends React.Component<CreateShipmentProps> {
  constructor(props: CreateShipmentProps) {
    super(props);
    makeObservable(this);
  }

  /** The value of the tracking number field */
  @observable public trackingNumber = '';

  /** The current inventory items */
  @observable public inventoryItems: Map<number, Inventory> = new Map();

  /** The currently selected location */
  @observable public location: Location | null = null;

  /** The location suggestions */
  @observable public locations: Location[] = [];

  /** Whether the locations dropdown is open */
  @observable public locationsDropdownOpen = false;

  /** Fetches the locations */
  @action.bound public fetchLocations = flow(function* (this: CreateShipment) {
    const resp = yield Api.core.getAccountLocations(this.props.accountId);
    this.locations = resp.data.data;
  });

  /** Opens the locations dropdown */
  @action.bound public openLocationsDropdown() {
    this.locationsDropdownOpen = true;
    this.fetchLocations();
  }

  /** Closes the locations dropdown */
  @action.bound public closeLocationsDropdown() {
    this.locationsDropdownOpen = false;
  }

  /** Whether the locations are being loaded */
  @computed public get loadingLocations() {
    return this.locations.length === 0 && this.locationsDropdownOpen;
  }

  /** Whether we are currently submitting the whole form */
  @observable public submitting = false;

  /** Inserts an inventory item into the inventoryItems map */
  @action.bound public addInventoryItem(i: Inventory) {
    // Delete it first if present so that it's bumped to the top
    this.inventoryItems.delete(i.id);
    // Insert it into the map
    this.inventoryItems.set(i.id, i);
  }

  /** Delets an inventory item */
  @action.bound public deleteInventoryItem(i: Partial<Inventory>) {
    if (i.id) {
      this.inventoryItems.delete(i.id);
    }
  }

  /** Updates the tracking number field */
  @action.bound public updateTrackingNumber(e: React.ChangeEvent<HTMLInputElement>) {
    this.trackingNumber = e.target.value;
  }

  /** Sets the location */
  @action.bound public setLocation(l: Location | null) {
    this.location = l;
  }

  /** Creates the shipment */
  @action.bound public createShipment = flow(function* (this: CreateShipment) {
    try {
      this.submitting = true;
      if (!this.location) {
        throw new Error('Please choose a location');
      }
      // Create a new shipment
      yield Api.fulfillment.createShipment({
        fulfillmentId: this.props.fulfillmentId,
        locationId: this.location.id,
        trackingNumber: this.trackingNumber,
        inventoryIds: this.inventoryItemsList.map((i) => i.id),
      });
      this.props.toastStore!.success(`Shipment created!`);
      this.props.onComplete && this.props.onComplete();
    } catch (e: any) {
      this.props.toastStore!.error(getErrorMsg(e));
    } finally {
      this.submitting = false;
    }
  });

  /** The list of current inventory items */
  @computed public get inventoryItemsList(): Inventory[] {
    return Array.from(this.inventoryItems.values()).reverse();
  }

  /** Whether the create shipment button is disabled */
  @computed public get submitDisabled(): boolean {
    return !this.location || !this.trackingNumber || this.inventoryItemsList.length === 0;
  }

  render() {
    return (
      <DD>
        <DD.Content>
          <Overlay display={this.submitting}>
            <CircularProgress />
          </Overlay>
          <DD.Title onBack={this.props.onBack}>Create shipment</DD.Title>
          <Box pb={2}>
            <Autocomplete
              open={this.locationsDropdownOpen}
              onOpen={this.openLocationsDropdown}
              options={this.locations}
              getOptionSelected={(option, value) => option.id === value.id}
              getOptionLabel={(option) => option.name}
              style={{ width: '100%' }}
              onChange={(_event, l: Location | null) => {
                this.setLocation(l);
              }}
              loading={this.loadingLocations}
              onClose={this.closeLocationsDropdown}
              renderInput={(params) => (
                <OutlinedInput
                  {...params}
                  fullWidth
                  label="Select location"
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {this.loadingLocations ? (
                          <CircularProgress color="inherit" size={20} />
                        ) : null}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                  }}
                />
              )}
            />
          </Box>
          <Box pb={3}>
            <OutlinedInput
              value={this.trackingNumber}
              fullWidth
              onChange={this.updateTrackingNumber}
              label="Tracking number"
            />
          </Box>
          <Box pb={3}>
            <Box mb={2}>
              <Typography variant={'body1'}>
                You can add multiple devices under one tracking number
              </Typography>
            </Box>
            <InventoryItemChooser onNewInventoryItem={this.addInventoryItem} />
          </Box>
          <List>
            {this.inventoryItemsList.map((i) => (
              <InventoryItem key={i.id} onDelete={this.deleteInventoryItem}>
                {i}
              </InventoryItem>
            ))}
          </List>
        </DD.Content>
        <DD.Actions>
          <Button
            fullWidth
            variant="contained"
            color="primary"
            disabled={this.submitDisabled}
            onClick={this.createShipment}>
            Create shipment
          </Button>
        </DD.Actions>
      </DD>
    );
  }
}

export default withStyles(styles)(CreateShipment);
