import { Theme, createStyles, makeStyles } from '@material-ui/core';
//import { fade } from '@material-ui/core/styles/colorManipulator';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const styles = (theme: Theme) =>
  createStyles({
    root: {
      height: '100%',
      width: '100%',
      position: 'relative',
    },
    inner: {
      padding: theme.spacing(3),
    },
    linkSessionId: {
      color: theme.palette.primary.main,
      fontWeight: 600,
    },
    bigDate: {
      fontSize: theme.typography.h5.fontSize,
    },
    lightText: {
      color: theme.palette.text.secondary,
    },
    headWrapper: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      gap: theme.spacing(2),
      justifyContent: 'space-between',
      width: '100%',
      alignItems: 'center',
    },
    event: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
    eventName: {
      fontFamily: 'monospace',
      wordBreak: 'break-all',
      fontWeight: 600,
    },
    eventProperty: {
      fontFamily: 'monospace',
      color: theme.palette.text.secondary,
      wordBreak: 'break-all',
    },
    error: {
      color: theme.palette.error.main,
    },
    createdAt: {
      fontSize: theme.typography.body2.fontSize,
      fontWeight: 400,
    },
  });

export default styles;

export const useStyles = makeStyles(styles);
