import Dialog, { IDialogProps } from 'components/Dialog/Dialog';
import { Box, CircularProgress, Grid } from '@mui/material';
import { FormContainer } from 'utils/forms';
import { IAddLicenseForm } from './useAddLicenseDialogForm';
import { IBillingGeneralPanelProps } from '../BillingGeneralPanel';
import useAddLicenseDialog from './useAddLicenseDialog';
import UpDownCounter from 'components/UpDownCounter';
import Button from 'components/Button/Dialog/Button';
import CartPreview from 'components/CartPreview';
import ErrorFallback, { EErrorFallbackVariant } from 'components/ErrorFallback/ErrorFallback';
import TextButton from 'components/Button/TextButton/TextButton';
import { ELicenseBillingGeneralDialog, ILicenseBillingGeneralDialogProps } from '../models';

const AddLicenseDialog = ({
  accountId,
  sendPurchaseOrder: canSendPurchaseOrder,
  ...dialogProps
}: ILicenseBillingGeneralDialogProps &
  Pick<IBillingGeneralPanelProps, 'accountId'> &
  Pick<IDialogProps, 'onCancel'> & { sendPurchaseOrder: boolean }) => {
  const {
    form,
    fields,
    cart,
    loadingCart,
    cartInProgress,
    confirmButtonLabel,
    canSubmitLicenses,
    numberOfKiosks,
    initCart,
    deleteCartItem,
    addLicenseToCart,
    handleFormReset,
    sendPurchaseOrder,
    sendingPurchaseOrder,
    addKiosk,
    removeKiosk,
  } = useAddLicenseDialog({
    accountId,
    onClose: dialogProps.onCancel,
    sendPurchaseOrder: canSendPurchaseOrder,
  });

  const _open = dialogProps.open && dialogProps.type === ELicenseBillingGeneralDialog.ADD_LICENSE;

  return (
    <Dialog
      {...dialogProps}
      open={_open}
      disabled={!canSubmitLicenses}
      loading={sendingPurchaseOrder}
      fullWidth
      maxWidth={'lg'}
      content={
        <Grid container spacing={3}>
          <Grid item xs={12} lg={6}>
            <FormContainer
              formFieldsConfig={[...fields]}
              control={form.control}
              errors={form.formState.errors}
              marginBottom={0}
              handleSubmit={form.handleSubmit}
              spacing={1.5}
              onFormSubmit={(data) => addLicenseToCart(data as IAddLicenseForm)}
            />
            <Box pb={5}>
              <UpDownCounter
                label="Number of kiosks"
                value={numberOfKiosks}
                inc={addKiosk}
                dec={removeKiosk}
              />
            </Box>
            <Box display="flex" flexDirection="row" justifyContent="flex-end">
              <Button
                variant="text"
                color="secondary"
                loading={cartInProgress}
                disabled={!form.formState.isValid}
                onClick={form.handleSubmit((data) => addLicenseToCart(data as IAddLicenseForm))}>
                Add
              </Button>
            </Box>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Box height={'100%'} display={'flex'} justifyContent={'center'} minWidth={300}>
              {cart ? (
                <Box sx={{ maxHeight: 300, overflowY: 'auto', pr: 1 }}>
                  <CartPreview
                    cart={cart}
                    deleteCartItem={deleteCartItem}
                    cartInProgress={cartInProgress}
                  />
                </Box>
              ) : loadingCart ? (
                <CircularProgress sx={{ alignSelf: 'center' }} />
              ) : (
                <ErrorFallback
                  sx={{ alignSelf: 'center' }}
                  variant={EErrorFallbackVariant.SMALL}
                  message={
                    <>
                      Could not get cart details.&nbsp;
                      <TextButton label={'Try again'} onClick={initCart} />.
                    </>
                  }
                />
              )}
            </Box>
          </Grid>
        </Grid>
      }
      confirmActionName={confirmButtonLabel}
      onConfirm={sendPurchaseOrder}
      onClose={dialogProps.onCancel}
      TransitionProps={{
        onExited: (node) => {
          dialogProps?.TransitionProps?.onExited && dialogProps.TransitionProps.onExited(node);
          handleFormReset();
        },
      }}
    />
  );
};

export default AddLicenseDialog;
