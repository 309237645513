import React from 'react';
import { observable, action, computed, flow, makeObservable } from 'mobx';
import { observer } from 'mobx-react';
import { WithStyles, withStyles } from '@material-ui/core/styles';
import { Box, TextField, Button, CircularProgress } from '@material-ui/core';
import { StandardTextFieldProps } from '@material-ui/core/TextField';

import { inject, WithToastStore } from 'stores';
import Api, { ApiResponse, getErrorMsg } from 'api';
import { AxiosResponse } from 'axios';
import { Affiliate, Promotion } from 'models';

import styles from './styles';

interface PromoCodeEntryProps
  extends WithStyles<typeof styles>,
    WithToastStore,
    Omit<StandardTextFieldProps, 'classes'> {
  onPromotion: (p: Promotion) => void;
  onAffiliate: (a: Affiliate) => void;
}

/**
 * Allows entering a promo code or an affiliate code.
 */
@inject('toastStore')
@observer
class PromoCodeEntry extends React.Component<PromoCodeEntryProps> {
  constructor(props: PromoCodeEntryProps){
    super(props);
    makeObservable(this);
  }
  
  /** The code that the user is entering */
  @observable public code = '';

  /** Whether this component is fetching affiliates and promotions */
  @observable public inProgress = false;

  /** This is set to true if the code wasn't found */
  @observable public notFound = false;

  /** Handler for updating the code */
  @action.bound public updateCode(e: React.ChangeEvent<HTMLInputElement>) {
    this.notFound = false;
    this.code = e.target.value;
  }

  /** The code in upper case */
  @computed public get codeUpper() {
    return this.code.toUpperCase();
  }

  /** Fetches the promotions or affiliate for the current code */
  @action.bound submit = flow(function* (this: PromoCodeEntry) {
    try {
      this.inProgress = true;
      // Fetch both the promotion and the affiliate
      const [p, a] = yield Promise.all([this.fetchPromotion(), this.fetchAffiliate()]);
      const promotion: Promotion | null = p;
      const affiliate: Affiliate | null = a;
      // If the promotion is not null, call the onPromotion callback and return
      if (promotion) {
        this.code = '';
        this.props.onPromotion(promotion);
        return;
      }
      // If the affiliate is not null, call the onPromotion callback and return
      if (affiliate) {
        this.code = '';
        this.props.onAffiliate(affiliate);
        return;
      }
      // If both are null, then the component hasn't found anything
      this.notFound = true;
    } catch (e: any) {
      this.props.toastStore!.error(getErrorMsg(e));
    } finally {
      this.inProgress = false;
    }
  });

  /** Fetch the promotion by current code. If the endpoint throws, return null */
  @action.bound public fetchPromotion: () => Promise<Promotion | null> = async () => {
    try {
      const resp: AxiosResponse<ApiResponse<Promotion>> = await Api.billing.getPromotionByCode(
        this.codeUpper,
      );
      return resp.data.data || null;
    } catch (e: any) {
      return null;
    }
  };

  /** Fetch the affiliate by current code. If the endpoint throws, return null */
  @action.bound public fetchAffiliate: () => Promise<Affiliate | null> = async () => {
    try {
      const resp: AxiosResponse<ApiResponse<Affiliate>> = await Api.marketing.getAffiliateByCode(
        this.codeUpper,
      );
      return resp.data.data || null;
    } catch (e: any) {
      return null;
    }
  };

  /** The submit handler */
  @action.bound public handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    this.submit();
  }

  render() {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { classes, onPromotion, onAffiliate, ...rest } = this.props;
    return (
      <form onSubmit={this.handleSubmit}>
        <Box display="flex" flexDirection="row">
          <TextField
            value={this.codeUpper}
            onChange={this.updateCode}
            label="Affiliate or promo code"
            disabled={this.inProgress}
            error={this.notFound}
            helperText={this.notFound ? 'Code not found' : undefined}
            autoFocus
            fullWidth
            {...rest}
          />
          <Box className={classes.promoCodeApplyBtn}>
            {!this.inProgress ? (
              <Button
                color="primary"
                variant="text"
                type="submit"
                fullWidth
                style={{ height: '100%' }}>
                Apply
              </Button>
            ) : (
              <CircularProgress size={24} />
            )}
          </Box>
        </Box>
      </form>
    );
  }
}

export default withStyles(styles)(PromoCodeEntry);
