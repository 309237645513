import React from 'react';
import { computed, observable } from 'mobx';
import { observer } from 'mobx-react';
import { inject, WithToastStore, WithModalStore, WithUserStore } from 'stores';
import {
  Paper,
  Box,
  Typography,
  CardMedia,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from '@material-ui/core';
import { WithStyles, withStyles } from '@material-ui/styles';
import styles from './styles';
import clsx from 'clsx';
import Title from 'components/Title';
import { Account, IntegrationApp } from 'models';
import { ToggleIntegrationButton } from 'containers/Integrations/AppCard/ToggleIntegrationButton/ToggleIntegrationButton';

interface IntegrationPanelProps
  extends WithStyles<typeof styles>,
    WithToastStore,
    WithModalStore,
    WithUserStore {
  title: string;
  account: Account;
  integration?: IntegrationApp;
}

@inject('toastStore', 'modalStore', 'userStore')
@observer
class IntegrationPanel extends React.Component<IntegrationPanelProps> {
  public constructor(props: IntegrationPanelProps) {
    super(props);
  }

  /** Is authenticated user an admin? */
  @computed public get isAdmin(): boolean {
    return this.props.userStore!.isAdmin;
  }

  /** For show confirm dialog change [pause/rrsume] integrations app */
  @observable showManageIntegrationStatus = false;

  render() {
    const { account } = this.props;
    if (!this.props.integration) {
      return (
        <Paper elevation={0}>
          <Box
            p={2}
            pb={3}
            display="flex"
            flexDirection="column"
            alignItems="center"
            style={{ minHeight: '171px', minWidth: '194px' }}>
            <Box display="flex" flexGrow={1} alignItems="flex-end">
              <Typography variant="h6" className={this.props.classes.notAvailableLabel}>
                Not available
              </Typography>
            </Box>
            <Box display="flex" flexGrow={1} alignItems="flex-end">
              <Typography color="textSecondary">{this.props.title}</Typography>
            </Box>
          </Box>
        </Paper>
      );
    } else {
      return (
        <Paper elevation={0} style={{ height: '100%' }}>
          <Box
            p={2}
            pb={3}
            display="flex"
            flexDirection="column"
            alignItems="center"
            style={{ minHeight: '171px' }}>
            <Box>
              <ToggleIntegrationButton app={this.props.integration} account={account} />
            </Box>
            <Box display="flex" alignItems="center" flexDirection={'column'}>
              <CardMedia
                component="img"
                style={{ objectFit: 'contain' }}
                width="41"
                height="45"
                image={this.props.integration.logo}
              />
              <Box
                pb={0}
                pt={0}
                display="flex"
                flexDirection="row"
                alignItems="center"
                justifyContent="center">
                <Typography> {this.props.integration.name}</Typography>
              </Box>
            </Box>
            <Box style={{ marginTop: 'auto' }} display="flex" alignItems="flex-end">
              <Typography color="textSecondary">{this.props.title}</Typography>
            </Box>
          </Box>
          <Dialog
            className={clsx(this.props.classes.dialog)}
            maxWidth={false}
            open={this.showManageIntegrationStatus}
            onClose={() => null}>
            <DialogTitle>
              <Title size="medium">
                {this.props.integration ? 'Pause integration' : 'Resume integration'}
              </Title>
            </DialogTitle>
            <DialogContent>
              <Box pb={1} pt={1}>
                <Typography className={this.props.classes.dialogMessage}>
                  {this.props.integration
                    ? `Are you sure you want to pause the integration for account ${this.props.integration.name}?`
                    : `Are you sure you want to resume the integration for account ${this.props.integration}?`}
                </Typography>
              </Box>
            </DialogContent>
            <DialogActions>
              <Button
                variant="outlined"
                color="primary"
                className={clsx(this.props.classes.dialogButton)}
                onClick={() => (this.showManageIntegrationStatus = false)}>
                Cancel
              </Button>
              <Button
                variant="contained"
                color="primary"
                className={clsx(this.props.classes.dialogButton)}
                onClick={() => null}>
                {this.props.integration ? 'Pause' : 'Resume'}
              </Button>
            </DialogActions>
          </Dialog>
        </Paper>
      );
    }
  }
}

export default withStyles(styles)(IntegrationPanel);
