import { Location } from './Location';
import { AccountUser } from './AccountUser';
import { User } from './User';

export enum Industry {
  HOSPITALITY = 'hospitality',
  BEAUTY = 'beauty',
  NONE = 'none',
}

/** Represents an owner's account */
export interface Account {
  id: number;
  uid?: string;
  name: string;
  logo?: string;
  phone?: string;
  address?: string;
  address2?: string;
  city?: string;
  state?: string;
  zip?: string;
  country?: string;
  code?: string;
  canRegenerateCode: boolean;
  userCount?: string;
  locationCount?: string;
  accountUsers?: AccountUser[];
  locations?: Location[];
  primaryOwner?: User;
  createdAt: string;
  creationSource?: string;
  industry?: Industry;
  isActive?: boolean;
}
