import { makeStyles } from '@material-ui/core';

export const avatarSize = 16;
export const borderWidth = 0.5;
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default makeStyles((theme) => ({
  text: {
    textAlign: 'center',
  },
  tippy: {
    margin: 'auto',
    width: 200,
    height: 200,
  },
}));
