import React from 'react';
import { observer } from 'mobx-react';
import { WithStyles, withStyles } from '@material-ui/core/styles';
import { Box, MenuItem } from '@material-ui/core';

import styles from './styles';
import OutlinedInput from 'components/Input/OutlinedInput';
import { isRequired } from 'utils/formHelpers';

interface RefundReasonSelectProps extends WithStyles<typeof styles> {
  selectField: any; // mobx-react-form field object
  textField?: any; // mobx-react-form field object
  reasons: Record<string, string>;
}

/**
 * Dropdown select of predefined refund reasons.
 */
@observer
class RefundReasonSelect extends React.Component<RefundReasonSelectProps> {
  render() {
    const { textField, selectField, reasons } = this.props;
    return (
      <Box>
        <OutlinedInput
          {...selectField.bind()}
          label={'Refund reason'}
          select
          error={selectField.error}
          required={selectField}
          fullWidth>
          {Object.entries(reasons).map(([key, label]) => (
            <MenuItem key={key} value={key}>
              {label}
            </MenuItem>
          ))}
        </OutlinedInput>
        {textField && selectField.value === 'OTHER' && (
          <Box mt={2}>
            <OutlinedInput
              label="Reason for refund"
              {...textField.bind()}
              error={textField.error}
              required={isRequired(textField)}
              multiline
              rows={1}
              rowsMax={1000}
              fullWidth
            />
          </Box>
        )}
      </Box>
    );
  }
}

export default withStyles(styles)(RefundReasonSelect);
