import { Theme, createStyles } from '@material-ui/core';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default (theme: Theme) =>
  createStyles({
    root: { position: 'relative' },
    backLink: {
      display: 'flex',
      alignitems: 'center',
      textDecoration: 'none',
      color: theme.palette.common.white,
    },
    done: {
      backgroundColor: theme.palette.common.white,
      position: 'absolute',
      padding: theme.spacing(2),
      top: -theme.spacing(2),
      left: -theme.spacing(2),
      right: -theme.spacing(2),
      bottom: -theme.spacing(2),
      zIndex: 200,
    },
    successIcon: {
      fontSize: '96px',
      marginBottom: theme.spacing(2),
    },
    checkContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    greyText: {
      color: theme.palette.text.secondary,
    },
    circle: {
      margin: '0 auto',
      position: 'relative',
      width: '280px',
      height: '280px',
      lineHeight: '280px',
      borderRadius: '50%',
      backgroundColor: theme.palette.primary.main,
      '&:before': {
        content: '"Oops!"',
        position: 'absolute',
        color: theme.palette.text.primary,
        fontSize: '104px',
        top: 0,
        left: '50%',
        transform: 'translate(-50%, 0)',
        display: 'inline-block',
      },
    },
    button: {
      color: theme.palette.text.primary,
      backgroundColor: theme.palette.primary.main,
      '&:hover': {
        backgroundColor: theme.palette.primary.hover,
      },
    },
  });
