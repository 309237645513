import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import * as models from 'models';
import { isPaymentMethodExpired } from 'utils/helper';
import { IManageLicenseBillingGroupDialog } from './ManageLicenseBillingGroupDialog';
import { IDialogProps } from 'components/Dialog/Dialog';
import { ELicenseBillingGeneralDialog } from '../models';
import Api, { getErrorMsg } from 'api';
import { useStores } from 'containers/App/App';

enum EDialogType {
  ADD = 'add',
  UPDATE = 'update',
}

enum EDialogStep {
  PAYMENT_METHOD = 'payment_method',
  LICENSES = 'licenses',
}

type TUseManageLicenseBillingGroupDialog = IManageLicenseBillingGroupDialog;

const initializeFormState = (billingEntity: models.IBillingEntity | null) => {
  if (!billingEntity)
    return { name: '', paymentMethod: { id: undefined }, licenses: [] as number[] };

  const {
    name,
    paymentMethod: { id },
    licenses,
  } = billingEntity;
  return {
    name: name,
    paymentMethod: { id },
    licenses: licenses?.map(({ id }) => id) || [],
  };
};

const useManageLicenseBillingGroupDialog = ({
  open,
  title,
  type,
  accountId,
  licenses: _licenses,
  billingEntity,
  paymentMethods,
  getBillingEntities,
  getLicensesForAccount,
  setBillingEntity,
  onCancel,
  TransitionProps,
}: TUseManageLicenseBillingGroupDialog) => {
  const [step, setStep] = useState(EDialogStep.PAYMENT_METHOD);

  const [loading, setLoading] = useState(false);

  const [formState, setFormState] = useState(() => initializeFormState(billingEntity));

  const [shouldPatch, setShouldPatch] = useState(false);

  const { toastStore, modalStore } = useStores();

  const _open = useMemo(
    () =>
      open &&
      [
        ELicenseBillingGeneralDialog.ADD_BILLING_GROUP,
        ELicenseBillingGeneralDialog.UPDATE_BILLING_GROUP,
      ].includes(type as ELicenseBillingGeneralDialog),
    [type, open],
  );

  const handleSetBillingEntity = (billingEntity: models.IBillingEntity | null) => {
    setBillingEntity(billingEntity);
    if (billingEntity) {
      setShouldPatch(true);
    }
  };

  const handleChangeName = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormState({ ...formState, name: e.currentTarget.value });
  };

  const handleChangePaymentMethod = useCallback(
    (id: number) => {
      setFormState({ ...formState, paymentMethod: { id } });
    },
    [setFormState, formState],
  );

  const handleUpdateLicense = async (e: ChangeEvent<HTMLInputElement>) => {
    const licenseId = parseInt(e.target.value);
    const { checked } = e.target;

    const license = _licenses.find((license) => license.id === licenseId);
    const locationName = license?.location.name || '';

    const confirmed = await modalStore.confirm(
      'Move License',
      `Are you sure you want to ${
        !checked ? 'remove' : 'move'
      } license ${locationName} to this billing group?`,
      {
        maxWidth: 'xs',
      },
    );

    if (!confirmed) {
      return;
    }

    try {
      await Api.billing.patchLicense(licenseId!, {
        billingEntityId: billingEntity?.id,
      });
      toastStore.success('License transferred successfully');
      await getLicensesForAccount();
      getBillingEntities();
    } catch (error) {
      toastStore.error(getErrorMsg(error));
    }
  };

  useEffect(() => {
    if (!_open || !billingEntity || formState.paymentMethod.id) return;
    setFormState(initializeFormState(billingEntity));
  }, [_open, billingEntity, setFormState, formState]);

  const manageBillingEntity = async () => {
    const {
      paymentMethod: { id },
      name,
    } = formState;
    if (!id || !accountId) {
      toastStore.error('Something went wrong. Please try again');
      return;
    }

    try {
      setLoading(true);
      if (type === ELicenseBillingGeneralDialog.ADD_BILLING_GROUP && !shouldPatch) {
        const { data } = await Api.billing.addBillingEntity(id, accountId, name || '');
        handleSetBillingEntity(data.data ? { ...data.data, licenses: [] } : null);
      } else {
        await Api.billing.updateBillingEntity(accountId, billingEntity!.id, {
          paymentMethodId: id,
          name: name || '',
        });
      }
      getBillingEntities();
      getLicensesForAccount();
      setStep(EDialogStep.LICENSES);
    } catch (error) {
      toastStore.error(getErrorMsg(error));
    } finally {
      setLoading(false);
    }
  };

  const _onCancel = () => {
    if (step === EDialogStep.PAYMENT_METHOD) {
      onCancel();
    } else {
      setStep(EDialogStep.PAYMENT_METHOD);
    }
  };

  const onConfirm = () => {
    if (step === EDialogStep.PAYMENT_METHOD) {
      manageBillingEntity();
    } else {
      onCancel();
    }
  };

  const dialogType = billingEntity ? EDialogType.UPDATE : EDialogType.ADD;

  const isValid = !!formState.paymentMethod.id;

  const options = paymentMethods.map((paymentMethod) => {
    const { brand, lastFour, validThru } = paymentMethod;
    const isExpired = isPaymentMethodExpired(paymentMethod);
    const expirationText = `${isExpired ? 'Expired' : 'Expires'} on ${validThru}`;
    return {
      ...paymentMethod,
      label: `${brand || ''} ${lastFour}`,
      brand: brand || '',
      expirationText,
    };
  });

  const {
    name,
    paymentMethod: { id },
    licenses,
  } = formState;

  const confirmActionName = step === EDialogStep.PAYMENT_METHOD ? 'Next' : 'Done';

  const cancelActionName = step === EDialogStep.PAYMENT_METHOD ? 'Cancel' : 'Back';

  const dialogProps: Omit<IDialogProps, 'content'> = {
    title,
    open: _open,
    loading,
    disabled: !isValid,
    onConfirm,
    onCancel: _onCancel,
    TransitionProps: {
      onExited: (node) => {
        TransitionProps?.onExited && TransitionProps.onExited(node);
        setStep(EDialogStep.PAYMENT_METHOD);
        setFormState(initializeFormState(null));
      },
    },
    confirmActionName,
    cancelActionName,
  };

  const isPaymentMethodStep = step === EDialogStep.PAYMENT_METHOD;

  const selectedPaymentMethod = options.find((option) => option.id.toString() === id?.toString());

  const selectedPaymentMethodLabel = `${selectedPaymentMethod?.label} (${selectedPaymentMethod?.validThru})`;

  return {
    id,
    name,
    isValid,
    isPaymentMethodStep,
    selectedPaymentMethod,
    selectedPaymentMethodLabel,
    dialogType,
    options,
    dialogProps,
    onConfirm,
    onCancel: _onCancel,
    handleChangeName,
    handleUpdateLicense,
    handleChangePaymentMethod,
  };
};

export default useManageLicenseBillingGroupDialog;
