import { Theme, createStyles } from '@material-ui/core';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default (theme: Theme) =>
  createStyles({
    root: {},
    dialog: {
      width: '600px',
      overflow: 'visible',
      '& .MuiDialogContent-root': {
        overflow: 'visible',
      },
    },
    textField: {
      '& .MuiFormHelperText-root': {
        color: theme.palette.error.dark,
      },
    },
    textPurple: {
     color: theme.palette.secondary.main
    },
    textCapitalize: {
      textTransform: 'capitalize'
    }
  });
