import { Theme, createStyles } from '@material-ui/core';
import transitions from '@material-ui/core/styles/transitions';

import { alertColors } from 'containers/App/theme';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default (theme: Theme) =>
  createStyles({
    root: {},
    fab: {
      position: 'fixed',
      bottom: theme.spacing(3),
      right: theme.spacing(2),
    },
    alertMessageBox: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
      padding: theme.spacing(2),
      borderRadius: 5,
      color: 'white',
      '&.low': { backgroundColor: alertColors.low },
      '&.medium': { backgroundColor: alertColors.medium },
      '&.high': { backgroundColor: alertColors.high },
    },
    label: {
      color: theme.palette.text.secondary,
      fontSize: 16,
      fontWeight: 400,
    },
    alertType: {
      color: theme.palette.text.primary,
      fontSize: 16,
      fontWeight: 700,
      lineHeight: '20.29px',
      marginTop: '3px',
      marginRight: theme.spacing(2),
    },
    leyendLabel: {
      color: theme.palette.text.secondary,
      fontSize: 12,
      fontWeight: 400,
      lineHeight: '15.22px',
      marginTop: theme.spacing(1),
    },
    templateMessage: {
      textTransform: 'none',
      display: 'flex',
      height: 'auto',
      justifyContent: 'flex-start',
      backgroundColor: 'transparent',
      '&:hover': {
        backgroundColor: theme.palette.background.default,
        transitions: theme.transitions.easing.easeIn,
      },
    },
    alertIcon: {
      width: '21px',
      height: '24px',
      marginRight: theme.spacing(1),
      '&.low': { color: theme.palette.status.blue },
      '&.medium': { color: theme.palette.status.yellow },
      '&.high': { color: theme.palette.status.red },
    },
    customAlertButton: {
      fontWeight: 600,
      fontSize: 16,
    },
    formLabel: {
      marginBottom: theme.spacing(1),
      color: theme.palette.text.secondary,
      '&.cause': { fontStyle: 'italic', marginLeft: theme.spacing(1) },
    },
    causeText: {
      fontStyle: 'italic',
      marginLeft: theme.spacing(1),
    },
    textField: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
    },
    dialogActions: {
      paddingTop: theme.spacing(5),
      padding: theme.spacing(3),
    },
    customDialogActions: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      padding: '24px 0px 16px 24px',
      gap: theme.spacing(1),
    },
    chip: {
      backgroundColor: '#D1F7F0',
      color: theme.palette.text.primary,
      fontSize: '16px',
      margin: theme.spacing(0.5, 0.25),
    },
    summaryChip: {
      backgroundColor: '#D1F7F0',
      '&.low': { backgroundColor: theme.palette.status.blue },
      '&.medium': { backgroundColor: theme.palette.status.yellow },
      '&.high': { backgroundColor: theme.palette.status.red },
      fontSize: '12px',
      fontWeight: 700,
      lineHeight: '15.22px',
      height: '23px',
      textTransform: 'capitalize',
    },
  });
