import { Account, Location, User } from 'models';
import { TFilterMapperFunction } from './adaptForFilter';
import { getFullName } from 'utils/helper';

interface IFilterMappers {
  accountId: TFilterMapperFunction<Account>;
  locationId: TFilterMapperFunction<Location>;
  userId: TFilterMapperFunction<User>;
}

const FILTER_MAPPERS: IFilterMappers = {
  accountId: (account) => {
    return { id: account.id, name: account.name, address: account.address };
  },
  locationId: (location) => {
    return { id: location.id, name: location.name, address: location.address };
  },
  userId: (user) => {
    return { id: user.id, name: getFullName(user.firstName, user.lastName), email: user.email };
  },
};

export default FILTER_MAPPERS;
