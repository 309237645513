import Api, { getErrorMsg } from 'api';
import { useStores } from 'containers/App/App';
import { useCallback, useEffect, useState } from 'react';
import { IBillingGeneralPanelProps } from './BillingGeneralPanel';
import * as models from 'models';
import { ACL } from 'types';

type TUseAccountLicensesProps = Omit<
  IBillingGeneralPanelProps,
  'refreshNextPayments' | 'paymentMethods' | 'loadingBillingEntities'
>;

const useAccountLicenses = ({ accountId, refetchLicensesKey }: TUseAccountLicensesProps) => {
  const { toastStore, userStore } = useStores();
  const [licenses, setLicenses] = useState<models.License[]>([]);
  const [loadingLicenses, setLoadingLicenses] = useState(false);

  const getLicensesForAccount = useCallback(async () => {
    try {
      setLoadingLicenses(true);
      const { data } = await Api.billing.getLicensesForAccount(accountId);
      setLicenses(data?.data || []);
    } catch (e: unknown) {
      toastStore.error(getErrorMsg(e));
    } finally {
      setLoadingLicenses(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toastStore, accountId, refetchLicensesKey, setLicenses, setLoadingLicenses]);

  useEffect(() => {
    getLicensesForAccount();
  }, [getLicensesForAccount]);

  const numLicenses = licenses.length;

  const listHeight =
    numLicenses < 4 ? 'short' : numLicenses >= 4 && numLicenses <= 10 ? 'normal' : 'tall';

  const isAdmin = userStore!.isAdmin;

  const canExtendLicenses = isAdmin && userStore!.hasPermission(ACL.MANAGE_LICENSES);

  return {
    licenses,
    loadingLicenses,
    canExtendLicenses,
    listHeight,
    getLicensesForAccount,
  };
};

export default useAccountLicenses;
