import { Theme } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    textMask: {
      margin: 0,
      fontSize: 'medium',
      color: theme.palette.primary.main,
    },
    btnMask: {
      color: theme.palette.primary.main,
      fontSize: 'unset',
    },
    textToken: {
      marginBottom: 0,
      fontSize: 'small',
    },
  }),
);

export default useStyles;
