import { useStores } from 'containers/App/App';
import { IUserProfileProps } from './UserProfile';
import { valueOrNA } from 'utils/helper';

const useUserProfile = ({ onClick }: IUserProfileProps) => {
  const { uiStore, userStore } = useStores();
  const isMenuOpen = uiStore?.drawer.open;
  const fullName = valueOrNA(userStore?.fullName);
  const email = valueOrNA(userStore?.user?.email);

  const userDetailsProps = {
    email,
    fullName,
    menuDrawerOpen: isMenuOpen,
  };

  const avatarProps = userStore?.loggedIn ? { src: userStore?.authUser?.avatar } : {};

  const handleOnClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    onClick && onClick(event);
  };

  return {
    userDetailsProps,
    avatarProps,
    handleOnClick,
  };
};

export default useUserProfile;
