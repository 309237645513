import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { observable, action, computed, flow, reaction, toJS, makeObservable } from 'mobx';
import { observer } from 'mobx-react';
import { WithStyles, withStyles } from '@material-ui/core/styles';
import {
  Grid,
  Button,
  Box,
  CircularProgress,
  Drawer,
  IconButton,
  Tooltip,
} from '@material-ui/core';
import axios, { AxiosResponse } from 'axios';

import { ELocationUserStatus, User } from 'models';
import { PagedApiResponse, RequestMetaData } from 'api';
import { inject, WithToastStore, WithUserStore } from 'stores';
import { paths } from 'routes';

import TextAndPhoneSearch from 'components/TextAndPhoneSearch';
import UserCard from 'components/UserCard';
import UserFilterDrawer, * as UserFilterDrawerTypes from './UserFilterDrawer';

import styles from './styles';
import Title from 'components/Title';
import { SourceMerge } from 'mdi-material-ui';
import { optionSearchFilter } from 'types/enums';
import { ACL } from 'types';

interface UserCardListProps extends WithStyles<typeof styles>, WithToastStore, WithUserStore {
  fetch?: (
    meta?: RequestMetaData,
    search?: Record<string, string>,
  ) => Promise<AxiosResponse<PagedApiResponse<User>>>;
  ownerView?: boolean;
  children?: User[];
  pageSize?: number;
}

const initialFilters: UserFilterDrawerTypes.UserFilters = {
  roles: {
    owners: true,
    managers: true,
    talent: true,
    basic: true,
  },
  isActive: true,
  locationUserStatus: ELocationUserStatus.ACTIVE,
};

/**
 * Renders a list of users. The list of users can either be
 * passed via the children prop, or provided via a fetch function,
 * so that the component can work with sources that return paginated
 * data.
 */
@inject('toastStore', 'userStore')
@observer
class UserCardList extends React.Component<UserCardListProps> {
  constructor(props: UserCardListProps) {
    super(props);
    makeObservable(this);
    this.r = reaction(
      () => [this.skip, this.search, this.phoneSearch],
      () => this.fetchUsers(),
    );

    this.r = reaction(
      () => [this.optionSearch],
      () => this.fetchUsers(this.optionSearch),
    );
  }
  /** The page size */
  public pageSize = this.props.pageSize || 20;

  /** The current search term */
  @observable public search = '';

  @observable public optionSearch: optionSearchFilter = optionSearchFilter.NAME;

  /** The current search term */
  @observable public phoneSearch = '';

  /** Whether the current users are loading */
  @observable public loading = true;

  /** The total number of results, in all pages */
  @observable public count?: number;

  /** How many items to skip on the next request */
  @observable public skip = 0;

  /** How many times we've fetched the users */
  @observable public fetchCount = 0;

  /** The list of users, if we're fetching from the server */
  @observable public users: User[] = [];

  /** Whether the filters are shown */
  @observable public showFilterDrawer = false;

  /** The current filters */
  @observable public filters: UserFilterDrawerTypes.UserFilters = initialFilters;

  /** Is authenticated user an admin? */
  @computed public get isAdmin(): boolean {
    return this.props.userStore!.isAdmin;
  }

  @action.bound public updateOptionSearch(o: optionSearchFilter) {
    // If we're doing server side fetching, reset all the
    // data since we're going to be getting a new data set
    // instead of just loading more from the same data set.
    this.optionSearch = o;

    if (this.props.fetch && this.search !== '') {
      this.reset();
    }
  }

  @action.bound public updateSearch(s: string) {
    // If we're doing server side fetching, reset all the
    // data since we're going to be getting a new data set
    // instead of just loading more from the same data set.
    if (this.props.fetch) {
      this.reset();
    }
    this.search = s;
  }

  @action.bound public updatePhoneSearch(s: string) {
    // same as updateSearch
    if (this.props.fetch) {
      this.reset();
    }
    this.search = s;
  }
  /** Resets the current data, used when updating the search terms with server-side fetching */
  @action.bound public reset() {
    this.skip = 0;
    this.fetchCount = 0;
    this.users = [];
    this.count = undefined;
  }
  /** Fetches the users with the current paging data */
  @action.bound public fetchUsers = flow(function* (
    this: UserCardList,
    option?: optionSearchFilter,
  ) {
    if (!this.props.fetch) {
      return;
    }

    if (option && this.search === '') {
      return;
    }

    try {
      this.loading = true;

      let searchParam = {};

      if (this.search !== '') {
        searchParam = { [this.optionSearch]: this.search };
      }

      // Pass the pagination data and the search string (if it's not empty)
      // to the fetch function and get the response
      const resp: AxiosResponse<PagedApiResponse<User>> = yield this.props.fetch(
        {
          pagination: {
            take: this.pageSize,
            skip: this.skip,
          },
          filters: {
            ...this.filters.roles,
            basic: this.isAdmin ? this.filters.roles.basic : false,
            isActive: this.filters.isActive,
            accountId: this.accountId,
            locationId: this.locationId,
            locationUserStatus: this.filters.locationUserStatus,
          },
        },
        searchParam,
      );
      // If the response has data, add the new set of users to the current users
      // array and update the total count
      if (resp.data) {
        const user: User | any = resp.data.data;
        this.users.push(...user);
        this.count = resp.data.count;
      }

      this.loading = false;
      // We count how many times we've fetched so we know whether
      // the user is loading more data or loading the initial set of
      // data
      this.fetchCount = this.fetchCount + 1;
    } catch (e: unknown) {
      // If we cancel the request, don't show the error
      if (axios.isCancel(e)) {
        return;
      }

      this.props.toastStore!.push({
        type: 'error',
        message: 'Failed to load users',
      });
    }
  });

  /**
   * Loads the next page of users. There's a reaction on
   * this.skip that reruns fetchUsers every time this.skip
   * changes
   */
  @action.bound public loadMore() {
    this.skip = this.skip + this.pageSize;
  }

  /** Shows the filters drawer */
  @action.bound public showFilters() {
    this.showFilterDrawer = true;
  }

  /** Hides the filters drawer */
  @action.bound public hideFilters() {
    this.showFilterDrawer = false;
  }

  @action.bound public updateFilters(filters: UserFilterDrawerTypes.UserFilters) {
    this.filters = toJS(filters);
    this.hideFilters();
    this.reset();
    this.fetchUsers();
  }

  @action.bound public resetFilters() {
    this.filters = toJS(initialFilters);
    this.hideFilters();
    this.reset();
    this.fetchUsers();
  }

  /** Whether the link to merge users is visible */
  @computed public get mergeLinkVisible(): boolean {
    return this.props.userStore!.hasPermission(ACL.MERGE_USERS);
  }

  /** Whether the initial loading is happening */
  @computed public get initialLoading() {
    return this.loading && this.fetchCount === 0;
  }

  /** Whether we're loading more */
  @computed public get loadingMore() {
    return this.loading && this.fetchCount > 0;
  }

  /** The users passed via props filtered by the search */
  @computed public get filteredUsers() {
    if (this.props.children === undefined) {
      return undefined;
    }
    return this.props.children.filter(
      (user) =>
        `${user.firstName} ${user.lastName}`.toLowerCase().includes(this.search.toLowerCase()) ||
        (user.email && user.email.toLowerCase().includes(this.search.toLowerCase())) ||
        (user.nickname && user.nickname.toLowerCase().includes(this.search.toLowerCase())),
    );
  }

  /** Whether to display the load more button */
  @computed public get showLoadMore() {
    return this.count && this.count > this.users.length;
  }

  /** Whether some filters have been modified */
  @computed public get filtersModified() {
    const { managers, owners, basic, talent } = this.filters.roles;
    return Boolean(
      !managers ||
        !talent ||
        !basic ||
        !owners ||
        !this.filters.isActive ||
        this.filters.account ||
        this.filters.location ||
        this.filters.locationUserStatus !== ELocationUserStatus.ACTIVE,
    );
  }

  /**
   * The accountId that will be sent along with the fetch users request.
   */
  @computed public get accountId(): number | undefined {
    const userStore = this.props.userStore!;
    const currentAccountId = userStore.currentAccount && userStore.currentAccount.id;
    const filtersAccountId = this.filters.account && this.filters.account.id;
    return currentAccountId || filtersAccountId;
  }

  /**
   * The locationId the will be sent along with the fetch users request.
   */
  @computed public get locationId(): number | undefined {
    const userStore = this.props.userStore!;
    const currentLocationId =
      userStore.currentManagedLocation && userStore.currentManagedLocation.id;
    const filtersLocationId = this.filters.location && this.filters.location.id;
    return currentLocationId || filtersLocationId;
  }

  /** Whenever this.skip or this.search changes, fetch the users */
  r;

  /** Do the fetch on mount */
  componentDidMount() {
    this.fetchUsers();
  }

  /** Dispose of reactions on unmount */
  componentWillUnmount() {
    this.r();
  }

  /** Renders a card for a user */
  renderUser(user: User) {
    return (
      <Grid
        key={user.id}
        className={this.props.classes.userItem}
        item
        xs={12}
        sm={12}
        md={6}
        lg={4}
        xl={3}>
        <UserCard>{user}</UserCard>
      </Grid>
    );
  }

  /** Renders the loading skeleton */
  renderLoadingSkeleton() {
    return Array.from(Array(this.pageSize).keys()).map((i) => (
      <Grid item xs={12} sm={6} md={6} lg={4} xl={3} key={i}>
        <UserCard>{undefined}</UserCard>
      </Grid>
    ));
  }

  /** Renders the message that there are no users to display */
  renderNoUsers() {
    const { classes } = this.props;
    return (
      <Grid className={classes.message} item xs={12}>
        There are no users to display
      </Grid>
    );
  }

  /** Renders the message that there are no users matching search */
  renderNoMatch() {
    const { classes } = this.props;
    return (
      <Grid className={classes.message} item xs={12}>
        There are no users matching your search
      </Grid>
    );
  }

  /** Renders the list of users for fetch mode */
  renderWithFetch() {
    if (this.initialLoading) {
      return this.renderLoadingSkeleton();
    }
    if (this.users.length === 0) {
      return this.renderNoUsers();
    }
    return (
      <>
        {this.users.map((user) => this.renderUser(user))}
        {this.showLoadMore && (
          <Grid item xs={12}>
            <Box display="flex" justifyContent="center">
              {this.loadingMore ? (
                <CircularProgress />
              ) : (
                <Button color="primary" size="large" onClick={this.loadMore}>
                  Load more
                </Button>
              )}
            </Box>
          </Grid>
        )}
      </>
    );
  }

  /** Renders the list of users that's been passed via the children prop */
  renderLocal() {
    // Display the loading state if the users are still loading
    if (this.filteredUsers === undefined) {
      return this.renderLoadingSkeleton();
    }
    // Display a text showing no search matches if there are users
    // but no filtered users
    const noSearchMatch = this.props.children!.length > 0 && this.filteredUsers.length === 0;
    if (noSearchMatch) {
      return this.renderNoMatch();
    }
    // If there are no users, display a message
    if (this.filteredUsers.length === 0) {
      return this.renderNoUsers();
    }
    // Otherwise, display the users
    return this.filteredUsers.map(this.renderUser);
  }

  render() {
    const { fetch } = this.props;
    const { classes } = this.props;
    const phoneInput = this.optionSearch === optionSearchFilter.PHONE;

    return (
      <>
        <Box display="flex" justifyContent="space-between" flexDirection="row" alignItems="center">
          <Title mb={3} count={this.loading ? undefined : `${this.users.length}/${this.count}`}>
            Users
          </Title>
          <Box>
            {this.mergeLinkVisible && (
              <Tooltip title="Merge users">
                <IconButton size="small" component={RouterLink} to={paths.users().merge()}>
                  <SourceMerge color="primary" fontSize="small" />
                </IconButton>
              </Tooltip>
            )}
          </Box>
        </Box>
        <Box mb={3} className={classes.searchBox}>
          <TextAndPhoneSearch
            initialValue={this.search}
            onTextChange={this.updateSearch}
            onOptionSearchChange={this.updateOptionSearch}
            onPhoneChange={this.updatePhoneSearch}
            phoneInput={phoneInput}
            autoFocus
            debounce={500}
            toggleFilters={this.showFilters}
            filtersActive={this.filtersModified}
          />
        </Box>

        <Grid container spacing={3}>
          {!fetch ? this.renderLocal() : this.renderWithFetch()}
        </Grid>
        <Drawer
          style={{ width: '439px' }}
          open={this.showFilterDrawer}
          onClose={this.hideFilters}
          anchor="right"
          variant="temporary">
          <UserFilterDrawer
            onClose={this.hideFilters}
            onReset={this.resetFilters}
            filters={toJS(this.filters)}
            hideOwnersCheckbox={this.props.ownerView}
            showInvitationStatuses={this.props.ownerView}
            showAccountSearch={this.props.userStore!.isAdmin}
            showUserProfileActivity={this.props.userStore!.isAdmin}
            showReset={this.filtersModified}
            onChange={this.updateFilters}
          />
        </Drawer>
      </>
    );
  }
}

export default withStyles(styles)(UserCardList);
