import { useStores } from 'containers/App/App';
import { useStationNavigation } from 'containers/LocationDetails/QrCodes/useStationNavigation';
import { IStationGroup, IStationItem, StationItem } from 'models/Station';
import { ChangeEvent, useEffect, useState } from 'react';
import { BlAddStation } from '../AddStation/blAddStation';

type IUseAddGroupReturn = [
  any,
  boolean,
  IStationGroup | undefined,
  boolean,
  (e: ChangeEvent<HTMLInputElement>) => void,
  () => number,
  () => void,
  () => void,
  () => void,
  () => void,
];

export function useAddStationGroup(): IUseAddGroupReturn {
  const { stationsStore } = useStores();
  const { stationNavigation } = useStationNavigation();
  const {
    groupForm,
    isPoolsEnabled,
    selectedGroup,
    resetSelectedPools,
    resetSelectedGroup,
    setAddStationState,
    setStateField,
    updateFormFieldAndValidate,
  } = stationsStore!;
  const [group, setGroup] = useState(stationsStore.selectedGroup);
  const [hasError, setHasError] = useState(true);
  const [previousGroupName, setPreviousGroupName] = useState<string | undefined>();

  function setGroupName(e: ChangeEvent<HTMLInputElement>) {
    const { name, value } = e.target;
    setStateField('groupName', value);
  }

  useEffect(() => {
    if (groupForm.hasError) {
      setHasError(true);
    } else {
      setHasError(false);
    }
  }, [groupForm.hasError]);

  useEffect(() => {
    if (stationsStore.previousGroupName) {
      setPreviousGroupName(stationsStore.previousGroupName);
    }
  }, []);

  useEffect(() => {
    setGroup(stationsStore.selectedGroup);
  }, [stationsStore.selectedGroup]);

  function goBack() {
    if (stationsStore.previousGroupName) {
      stationNavigation.openStation();
    } else {
      stationNavigation.openPoolsOrGroups();
    }
    resetSelectedGroup();
    resetSelectedPools();
  }

  function addPools() {
    stationNavigation.openGroupPools();
  }

  function addUsers() {
    stationNavigation.openGroupUsers();
  }

  function countGroupUsers() {
    const groupItems = stationsStore.selectedGroup!.groupItems;
    if (!groupItems || !groupItems.length) return 0;
    return BlAddStation.countStationUsers(groupItems);
  }

  function addGroupToStation() {
    if (!stationsStore.station!.items) {
      stationsStore.station!.items = [selectedGroup!];
    } else {
      stationNavigation.openStation();
      if (previousGroupName) {
        updateStationGroup();
      } else {
        addNewStationGroup();
      }
    }
  }

  function addNewStationGroup() {
    setStateField('items', [...stationsStore.station!.items, selectedGroup!]);
  }

  function updateStationGroup() {
    const stationItems = stationsStore.station!.items.map((item: IStationItem): IStationItem => {
      if (item.type === StationItem.GROUP) {
        if (item.groupName === previousGroupName) {
          return { ...selectedGroup! };
        }
      }
      return item;
    });
    setStateField('items', stationItems);
  }

  return [
    groupForm,
    hasError,
    group,
    isPoolsEnabled,
    setGroupName,
    countGroupUsers,
    addGroupToStation,
    goBack,
    addPools,
    addUsers,
  ];
}
