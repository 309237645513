import { rootStore } from 'containers/App/App';
import { IAccountSignupLayoutProps } from './AccountSignupLayout';

const useAccountSignunpLayout = ({ image, contentRight, subtitle }: IAccountSignupLayoutProps) => {
  const { uiStore } = rootStore;
  const mobileView = uiStore?.mobileView;
  const contentRightMarginBottom = mobileView ? 0 : contentRight?.mb;
  const showImage = image?.svg && !image?.hide && !mobileView;
  const showSubtitle = !subtitle.hide;
  const subtitleMarginBottom = 3;
  return {
    mobileView,
    contentRightMarginBottom,
    showImage,
    showSubtitle,
    subtitleMarginBottom,
  };
};

export default useAccountSignunpLayout;
