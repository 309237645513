/**
 * This file contains various validation functions used in the dashboard
 */

import {
  US_PHONE_NUMBER_PATTERN,
  SI_PHONE_NUMBER_PATTERN,
  ZIP_REGEX,
  US_CURRENCY_REGEX,
  EMAIL_REGEX,
} from 'utils';

/** Tests whether a given string is a valid US phone number */
export const isPhone = (s: string) =>
  US_PHONE_NUMBER_PATTERN.test(s) || SI_PHONE_NUMBER_PATTERN.test(s);

/** Tests whether a given string is a valid US zip code */
export const isZip = (s: string) => new RegExp(ZIP_REGEX).test(s);

/** Tests whether a given string is a valid US currency format */
export const isCurrency = (s: string) => new RegExp(US_CURRENCY_REGEX).test(s);

/** Tests whether a given string is a valid email format */
export const isEmail = (s: string) => new RegExp(EMAIL_REGEX).test(s);

// Number with max 2 decimals
export const isAmount = (s: string) => {
  return new RegExp(/^\s*-?\d+(\.\d{1,2})?\s*$/).test(s);
};
