import React from 'react';
import {
  observable,
  action,
  flow,
  computed,
  makeObservable,
  reaction,
  IReactionDisposer,
} from 'mobx';
import { observer } from 'mobx-react';
import { WithStyles, withStyles } from '@material-ui/core/styles';
import { Box, Grid } from '@material-ui/core';
import TalentApprovalPanel from 'components/TalentApprovalPanel';

// api import for tips report email and download for QA testing:
import Api, { ApiResponse } from 'api';
import type { DashboardTipStats } from 'models';

import { inject, WithUserStore, WithModalStore, WithToastStore, WithAnalyticsStore } from 'stores';

import styles from './styles';

import { AxiosResponse } from 'axios';
import { HorizontalStatCard } from 'containers/UserDetails/Stats';
import Title from 'components/Title/Title';
import { RouteComponentProps } from 'react-router-dom';
import {
  faCoin,
  faHashtag,
  faMoneyBillTransfer,
  faMoneyBillTrendUp,
} from '@fortawesome/pro-regular-svg-icons';
import ReferralPanel from 'components/ReferralPanel';

type OwnerHomeProps = WithStyles<typeof styles> &
  WithUserStore &
  WithModalStore &
  WithToastStore &
  WithAnalyticsStore &
  RouteComponentProps;

/**
 * The owner dashboard, displayed when they log in.
 */
@inject('userStore', 'modalStore', 'toastStore', 'analyticsStore')
@observer
class OwnerHome extends React.Component<OwnerHomeProps> {
  constructor(props: OwnerHomeProps) {
    super(props);
    makeObservable(this);

    this.disposers.push(
      reaction(
        () => this.dateRange,
        () => {
          this.fetchStats();
        },
      ),
      reaction(
        () => props.userStore?.currentAccount,
        (currentAccount) => {
          if (currentAccount) {
            this.fetchStats();
            this.talentApprovalPanelKey = Date.now();
          }
        },
      ),
    );
  }

  /** It's good practice to dispose of any autoruns that we set up during */
  private disposers: IReactionDisposer[] = [];

  @observable public accountId = this.props.userStore!.currentAccount!.id!;
  /** The tips stats object */
  @observable public tipStats?: DashboardTipStats;

  @observable public talentApprovalPanelKey = Date.now();

  @action.bound
  public fetchStats = flow(function* (this: OwnerHome) {
    const accountId = this.props.userStore?.currentAccount?.id;
    this.tipStats = undefined;
    const resp: AxiosResponse<ApiResponse<DashboardTipStats>> = yield Api.tips.getDashboardStats({
      accountId,
    });
    this.tipStats = resp?.data?.data;
  });

  @computed public get tipStatsComputed() {
    return {
      tipsTotalCardNet: this.tipStats && parseFloat(this.tipStats.tipsTotalCardNet),
      tipsAvgCardNet: this.tipStats && parseFloat(this.tipStats.tipsAvgCardNet),
      tipsCountCard: this.tipStats && parseFloat(this.tipStats.tipsCountCard),
      totalRebate: this.tipStats && parseFloat(this.tipStats.totalRebate),
    };
  }

  @action.bound public fetchPendingApproval = flow(function* (this: OwnerHome) {
    const resp = yield Api.core.getAccountTalent(this.accountId, {
      filters: { locationUserStatus: 'pending_approval' },
      pagination: { take: 1000, skip: 0 },
    });
    return resp.data.data;
  });

  @action.bound public fetchRejected = flow(function* (this: OwnerHome) {
    const resp = yield Api.core.getAccountTalent(this.accountId, {
      filters: { locationUserStatus: 'rejected' },
      pagination: { take: 1000, skip: 0 },
    });
    return resp.data.data;
  });

  @action.bound public approveUser(userId: number, locationId: number) {
    return Api.core.updateLocationUser(locationId, { userId, status: 'active' });
  }

  @action.bound public rejectUser(userId: number, locationId: number) {
    return Api.core.updateLocationUser(locationId, { userId, status: 'rejected' });
  }

  @action.bound public undoApproveOrReject(userId: number, locationId: number) {
    return Api.core.updateLocationUser(locationId, { userId, status: 'pending_approval' });
  }

  @action.bound public approveAll() {
    return Api.core.approveAll.onAccount(this.accountId);
  }

  @computed public get dateRange() {
    return this.props.analyticsStore!.dateRange;
  }

  componentDidMount() {
    this.fetchStats();
  }

  render() {
    const { classes } = this.props;
    return (
      <>
        <Title mb={3}>Dashboard</Title>
        <Box display="flex" flexDirection="row">
          <Grid container spacing={3} justifyContent="center">
            <Grid item xs className={classes.cardItem}>
              <HorizontalStatCard
                fontAwesomeIcon={faHashtag}
                duration={1}
                title="Total number of tips">
                {this.tipStatsComputed.tipsCountCard}
              </HorizontalStatCard>
            </Grid>
            <Grid item xs className={classes.cardItem}>
              <HorizontalStatCard
                color={'yellow'}
                prefix="$"
                duration={1}
                fontAwesomeIcon={faCoin}
                separator=","
                decimals={2}
                title="Total amount">
                {this.tipStatsComputed.tipsTotalCardNet}
              </HorizontalStatCard>
            </Grid>
            <Grid item xs className={classes.cardItem}>
              <HorizontalStatCard
                color={'purple'}
                fontAwesomeIcon={faMoneyBillTrendUp}
                duration={1}
                prefix="$"
                separator=","
                decimals={2}
                title="Average tip">
                {this.tipStatsComputed.tipsAvgCardNet}
              </HorizontalStatCard>
            </Grid>
            <Grid item xs className={classes.cardItem}>
              <HorizontalStatCard
                color={'green'}
                fontAwesomeIcon={faMoneyBillTransfer}
                duration={1}
                prefix="$"
                separator=","
                decimals={2}
                title="Total Rebate">
                {this.tipStatsComputed.totalRebate}
              </HorizontalStatCard>
            </Grid>
          </Grid>
        </Box>
        <Box pt={3}>
          <TalentApprovalPanel
            key={this.talentApprovalPanelKey.toString()}
            fetch={this.fetchPendingApproval}
            fetchRejected={this.fetchRejected}
            approveAll={this.approveAll}
            approve={this.approveUser}
            reject={this.rejectUser}
            undo={this.undoApproveOrReject}
            showLocation
          />
        </Box>
        {/* Referral Panel (LD flags) */}
        <ReferralPanel />
      </>
    );
  }
}

export default withStyles(styles)(OwnerHome);
