import { BankAccountWizzardProps } from 'components/BankAccountWizzard/BankAccountWizzard';
import { action, makeObservable, observable } from 'mobx';
import BaseStore from './BaseStore';
import RootStore from './RootStore';

export default class BankAccountWizzardStore extends BaseStore {
  constructor(rootStore: RootStore) {
    super(rootStore);
    makeObservable(this);
  }

  @observable public openBankAccountWizzard = false;

  @observable public props?: BankAccountWizzardProps;

  @action.bound setOpenBankAccountWizzard() {
    this.openBankAccountWizzard = true;
  }

  @action.bound setCloseBankAccountWizzard() {
    this.openBankAccountWizzard = false;
  }

  @action.bound setProps(props: BankAccountWizzardProps) {
    const _props = { ...props, onClose: () => this.onClose(props.onClose) };
    this.props = _props;
    document.body.style.overflow = props ? 'hidden' : 'scroll';
  }

  private onClose(close: BankAccountWizzardProps['onClose']) {
    this.props = undefined;
    close();
  }

  public init() {}
}

/**
 * A component's props can extend this interface
 * if it needs payout source functionality and data
 */
export interface WithBankAccountWizzardStore {
  bankAccountWizzardStore?: BankAccountWizzardStore;
}
