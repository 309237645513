import { Box, CircularProgress, Typography } from '@material-ui/core';
import { WithStyles, withStyles } from '@material-ui/core/styles';
import Api, { getErrorMsg } from 'api';
import Button from 'components/Button/Dialog/Button';
import OutlinedInput from 'components/Input/OutlinedInput';
import Overlay from 'components/Overlay';
import { action, computed, flow, observable, makeObservable } from 'mobx';
import { observer } from 'mobx-react';
import type { Hardware } from 'models';
import React from 'react';
import { inject, WithToastStore } from 'stores';
import styles from './styles';

interface InventoryEntryProps extends WithStyles<typeof styles>, WithToastStore {
  onComplete?: () => void;
  data?: Hardware;
}

/**
 * The component for the Inventory quantity Entry in the devices section.
 */
@inject('userStore', 'toastStore')
@observer
class InventoryEntry extends React.Component<InventoryEntryProps> {
  constructor(props: InventoryEntryProps) {
    super(props);
    makeObservable(this);
  }

  /** The hardware currently chosen */
  @observable public hardware: Hardware | null = this.props.data ? this.props.data : null;

  /** Whether the submission is currenlty in progress */
  @observable public submitting = false;

  /** The value for the quantity text field */
  @observable public quantity = 0;

  /** Updates the quantity number text field */
  @action.bound public updateQty(e: React.ChangeEvent<HTMLInputElement>) {
    this.quantity = Number(e.target.value);
  }

  /** Handles the submit for update a quantities hardware item */
  @action.bound public handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
  }

  /** Whether the main button for submitting is disabled */
  @computed private get submitDisabled() {
    return this.quantity <= 0 && !this.hardware;
  }

  /** Sends Update a quantities hardware itemsto the API */
  @action.bound public submit = flow(function* (this: InventoryEntry) {
    try {
      this.submitting = true;
      if (this.hardware) {
        yield Api.fulfillment.updateHardwareQuantities(this.hardware.id, this.quantity);
      } else {
        this.props.toastStore!.error('Hardware not selected!');
      }
      this.props.toastStore!.success('Inventory items added!');
      this.props.onComplete && this.props.onComplete();
    } catch (e: any) {
      this.props.toastStore!.error(getErrorMsg(e));
    } finally {
      this.submitting = false;
    }
  });

  render() {
    const { classes } = this.props;
    return (
      <Box className={classes.root}>
        <Overlay display={this.submitting}>
          <CircularProgress />
        </Overlay>
        <Box>
          <Typography style={{ fontSize: 28 }} variant="h4" component="h1">
            Add Items
          </Typography>
          <form onSubmit={this.handleSubmit}>
            <Box mb={4} mt={4}>
              <OutlinedInput
                type="number"
                value={this.quantity}
                onChange={this.updateQty}
                label="Enter Quantity"
                fullWidth
              />
            </Box>
            <Box display="none">
              <Button type="submit">submit</Button>
            </Box>
          </form>
        </Box>
        <Box mb={2}>
          <Button
            variant="contained"
            color="primary"
            onClick={this.submit}
            disabled={this.submitDisabled}>
            Add
          </Button>
        </Box>
      </Box>
    );
  }
}

export default withStyles(styles)(InventoryEntry);
