import React from 'react';
import { Box, Checkbox, Divider, Paper, Typography } from '@material-ui/core';
import { WithStyles, withStyles } from '@material-ui/core/styles';
import LoadingSpinner from 'components/LoadingSpinner';
import { action, computed, IReactionDisposer, observable, reaction, makeObservable } from 'mobx';
import { observer } from 'mobx-react';
import { ChartData } from 'models';
import moment from 'moment';
import { Area, AreaChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

import styles from './styles';

type AnnotatedChartData = ChartData & {
  timeStamps: number[]; // An array of unix timestamp dates for the x-axis numeric points
  config?: {
    // paddings expressed as percentage of range between lowest and highest point
    // e.g.: 100 will push the highest point to the middle of y axis
    // paddings are applied to datasets by array indices
    // e.g.: paddings[0] applies to the first dataset
    paddings?: number[];
  };
};

/** Annotate chart data for Recharts  */
function annotateChartData(data: ChartData) {
  return {
    ...data,
    timeStamps: data.dates.map((date) => moment(date).unix()),
    config: {
      // paddings expressed as percentage of range between lowest and highest point
      // e.g.: 100 will push the highest point to the middle of y axis
      paddings: [100],
    },
  };
}

interface ChartProps extends WithStyles<typeof styles> {
  data?: ChartData;
  singleYAxis?: boolean;
  singleYAxisFormat?: 'currency';
  compact?: boolean;
  hideTotals?: boolean;
}

const colors = ['#7365A4', '#55BF9B', '#4795D1'];

/**
 * Reusable component for creating and displaying line charts. It uses Recharts
 * library and supports multiple datasets that translate to own chart lines.
 */
@observer
class Chart extends React.Component<ChartProps> {
  constructor(props: ChartProps) {
    super(props);
    makeObservable(this);
    /** Annotate data when props update */
    this.disposers.push(
      reaction(
        () => this.props.data,
        (data?: ChartData) => {
          this.chartData = data ? annotateChartData(data) : undefined;
        },
      ),
    );
  }

  private disposers: IReactionDisposer[] = [];

  /** Used when dynamically rendering Recharts components and setting their state */
  @observable private chartData?: AnnotatedChartData;

  @observable private hiddenDatasets: number[] = [];

  @action.bound private toggleDataset = (d: number) => () => {
    this.hiddenDatasets = this.hiddenDatasets.includes(d)
      ? this.hiddenDatasets.filter((set) => set !== d)
      : [...this.hiddenDatasets, d];
  };

  /**
   * Array of flat objects with properties representing all values for all
   * steps on x axis. This structure is used by Rechart's main Chart component
   * and is then referenced by label in Rechart's cartesian sub components.
   * See: https://recharts.org/en-US/api#data
   */
  @computed private get rawData() {
    const data = this.chartData;
    if (data) {
      return data.timeStamps.map((stamp, index) => {
        const xAxisValues: Record<string, string | number> = { timeStamp: stamp };
        xAxisValues.date = data.dates[index];
        data.datasets.map((set) => (xAxisValues[set.label] = set.values[index]));
        if (data.periods) xAxisValues.period = data.periods[index];
        return xAxisValues;
      });
    }
    return undefined;
  }

  /** Calculate the range between start and end date for time axis */
  @computed private get timeDomain(): [number, number] {
    if (this.chartData) {
      const start = this.chartData.timeStamps[0];
      const end = this.chartData.timeStamps[this.chartData.timeStamps.length - 1];
      return [start, end];
    }
    return [0, 1];
  }

  /**
   * Calculate Y axis domain max value. This helps set the correct
   * domain range when user toggles Area char components (hide/show
   * lines on multiple line chart with a single yAxis)
   */
  @computed private get getDomainMax() {
    if (!this.chartData) return 0;
    const highestValue = this.chartData.datasets
      // Filter hidden datasets ...
      .filter((set, index) => !this.hiddenDatasets.includes(index))
      // ... then map over visible datasets and get their max values ...
      .map((set) => set.values.reduce((a, b) => Math.max(a, b), 0))
      // ... find the highest of those values
      .reduce((a, b) => Math.max(a, b), 0);
    // Add some padding for nicer looking graph
    const withPadding = highestValue + highestValue * (0.01 * 25);
    return withPadding;
  }

  /**
   * If there are not enough values in any of the datasets
   * for selected range inform the user about the missing
   * data, rather than displaying an empty chart container
   */
  @computed private get emptyDatasets() {
    // If all datasets have less than two values, we cannot render the chart:
    return this.chartData
      ? this.chartData.datasets.every((dataset) => dataset.values.length < 2)
      : true;
  }

  /** Format time axis labels based on selected date range length */
  @action.bound private formatTime = (tick: number) => {
    const halfYear = 6 * 30.5 * 24 * 3600;
    const dateRange = this.timeDomain[1] - this.timeDomain[0];
    const format = dateRange > halfYear ? 'MMM YYYY' : 'MMM D';
    return moment.unix(tick).format(format);
  };

  @action.bound private formatNumber = (tick: number) => new Intl.NumberFormat().format(tick);

  @action.bound private formatCurrency = (tick: number) => {
    const si = [
      { value: 1, symbol: '' },
      { value: 1e3, symbol: 'k' },
      { value: 1e6, symbol: 'M' },
      { value: 1e9, symbol: 'G' },
      { value: 1e12, symbol: 'T' },
      { value: 1e15, symbol: 'P' },
      { value: 1e18, symbol: 'E' },
    ];
    const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    let i;
    for (i = si.length - 1; i > 0; i--) {
      if (tick >= si[i].value) break;
    }
    return '$' + (tick / si[i].value).toFixed(2).replace(rx, '$1') + si[i].symbol;
  };

  /** Before unmounting the component, dispose of all autoruns created */
  componentWillUnmount() {
    this.disposers.map((disposer) => disposer());
  }

  /** Render a custom tippy suited tooltip on mouse hover */
  renderCustomTooltip = ({ active, payload, label }: any) => {
    const classes = this.props.classes;
    if (active && payload && payload.length > 0) {
      return (
        <Box className={classes.tooltip}>
          <Typography className={classes.tooltipLabel}>{`${payload[0].payload.period}`}</Typography>
          <Divider />
          {payload.map((dataset: any) => {
            const target = this.chartData!.datasets.filter((ds) => ds.label === dataset.name)[0];
            let value: string | number = dataset.value as number;
            if (target.meta && target.meta.currency) {
              value = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(
                value,
              );
            } else {
              value = this.formatNumber(value);
            }

            return (
              <Box key={dataset.name} display="flex" flexDirection="row" alignItems="center">
                <Box
                  className={classes.tooltipDot}
                  style={{ backgroundColor: dataset.color }}
                  mr={1}
                />
                <Typography className={classes.tooltipValue} style={{ color: dataset.color }}>
                  {`${value} ${dataset.dataKey}`}
                </Typography>
              </Box>
            );
          })}
        </Box>
      );
    }
    return null;
  };

  render() {
    const { classes, singleYAxis, compact, hideTotals } = this.props;
    const height = compact ? 250 : 450;
    const padding = compact ? 2 : 1;
    return (
      <Paper>
        <Box height={height} p={padding}>
          <ResponsiveContainer height="100%" width="100%">
            {this.chartData ? (
              this.emptyDatasets ? (
                <Box display="flex" alignItems="center" justifyContent="center">
                  <Typography variant="h4">
                    Unfortunately there is not enough data to draw a chart
                  </Typography>
                </Box>
              ) : (
                <AreaChart data={this.rawData}>
                  <defs>
                    {this.chartData.datasets.map((set, index) => (
                      <linearGradient
                        key={set.label}
                        id={`color-${index}`}
                        x1="0"
                        y1="0"
                        x2="0"
                        y2="1">
                        <stop offset="5%" stopColor={colors[index]} stopOpacity={0.3} />
                        <stop offset="75%" stopColor={colors[index]} stopOpacity={0} />
                      </linearGradient>
                    ))}
                  </defs>
                  {this.chartData.datasets.map((set, index) => (
                    <Area
                      key={`${set.label}Area`}
                      type="monotone"
                      yAxisId={singleYAxis ? 'yAxis' : set.label}
                      dataKey={set.label}
                      stroke={colors[index]}
                      strokeWidth="3"
                      fill={`url(#color-${index})`}
                      activeDot={{ stroke: colors[index], strokeWidth: 3, r: 7, fill: 'white' }}
                      hide={this.hiddenDatasets.includes(index)}
                    />
                  ))}
                  <XAxis
                    dy={10}
                    dataKey="timeStamp"
                    type="number"
                    scale="time"
                    axisLine={false}
                    tickLine={false}
                    domain={this.timeDomain}
                    interval={1}
                    tickFormatter={this.formatTime}
                  />
                  {!singleYAxis &&
                    this.chartData.datasets.map((set, index) => {
                      const defaultPadding = 5;
                      const customPadding = this.chartData!.config!.paddings![index];

                      // Paddings expressed as percentage of range between lowest and highest point
                      // e.g.: 100 will push the highest point to the middle of y axis
                      const padding = customPadding || defaultPadding;

                      // Calculate dataset's highest value for selected range ...
                      const highestValue = set.values.reduce((a, b) => Math.max(a, b), 0);
                      // ... add 20%, and set this number as domain's max value:
                      // NOTE: this results in a nice top padding for all dataset lines
                      const domain: [number, number] = [
                        0,
                        highestValue + highestValue * (0.01 * padding),
                      ];

                      // Format value with comma delimiter by default ...
                      let formatter = (tick: number) => new Intl.NumberFormat().format(tick);
                      // ... but if dataset is flagged as currency use currency formatter instead
                      if (set.meta && set.meta.currency) formatter = this.formatCurrency;

                      // Position of the y axis ticks and labels, first one
                      // goes to the left, all others go to the right
                      const orientation: 'left' | 'right' = index === 0 ? 'left' : 'right';

                      return (
                        <YAxis
                          key={`${set.label}YAxis`}
                          yAxisId={set.label}
                          tickFormatter={formatter}
                          axisLine={false}
                          tickLine={false}
                          domain={domain}
                          interval="preserveStart"
                          tickCount={10}
                          dataKey={set.label}
                          orientation={orientation}
                          hide={this.hiddenDatasets.includes(index)}
                          tick={{ fill: colors[index] }}
                        />
                      );
                    })}
                  {singleYAxis && (
                    <YAxis
                      key={`YAxis`}
                      yAxisId="yAxis"
                      tickFormatter={
                        this.props.singleYAxisFormat === 'currency'
                          ? this.formatCurrency
                          : undefined
                      }
                      domain={[0, this.getDomainMax]}
                      tickCount={10}
                      axisLine={false}
                      tickLine={false}
                      interval="preserveStart"
                      orientation="left"
                    />
                  )}
                  <Tooltip
                    content={this.renderCustomTooltip}
                    cursor={{ stroke: '#777', strokeWidth: 2 }}
                  />
                </AreaChart>
              )
            ) : (
              <Box
                display="flex"
                height="100%"
                width="100%"
                justifyContent="center"
                alignItems="center">
                <LoadingSpinner className={classes.loadingSpinner} color="primary" />
              </Box>
            )}
          </ResponsiveContainer>
        </Box>
        {!hideTotals && (
          <>
            <Divider />
            <Box display="flex" flexDirection="row" p={padding * 2} minHeight={120}>
              {this.chartData &&
                this.chartData.datasets.map((set, index) => {
                  const color = colors[index];
                  let formattedTotal;
                  if (set.total && set.meta && set.meta.currency) {
                    formattedTotal = new Intl.NumberFormat('en-US', {
                      style: 'currency',
                      currency: 'USD',
                    }).format(parseFloat(set.total));
                  } else if (set.total) {
                    formattedTotal = this.formatNumber(parseInt(set.total));
                  }
                  return (
                    <Box key={set.label} className={classes.chartLegendItem}>
                      <Box>
                        <Typography className={classes.chartLineLabel}>{set.label}</Typography>
                      </Box>
                      <Box display="flex" flexDirection="row" alignItems="center">
                        <Box>
                          <Checkbox
                            checked={!this.hiddenDatasets.includes(index)}
                            style={{ color: color }}
                            onChange={this.toggleDataset(index)}
                          />
                        </Box>
                        {set.total && (
                          <Typography component="span" className={classes.totalValue}>
                            {formattedTotal}
                          </Typography>
                        )}
                      </Box>
                    </Box>
                  );
                })}
            </Box>
          </>
        )}
      </Paper>
    );
  }
}

export default withStyles(styles)(Chart);
