import { useEffect, useState } from 'react';
import Api, { getErrorMsg } from 'api';
import { BlPools } from './blPools';
import { Industry, Pool } from 'models';
import { DrawerType } from 'types';
import { createPool } from 'api/core';
import { BlSettings } from './blSettings';
import { useStores } from 'containers/App/App';

enum StationItem {
  USER = 'user',
  POOL = 'pool',
  GROUP = 'group',
}

interface IStationUser {
  type: StationItem.USER;
  userId: number;
}

interface IStationPool {
  type: StationItem.POOL;
  poolId: number;
}

interface IStationGroup {
  type: StationItem.GROUP;
  groupName: string;
  groupItems: Array<IStationUser | IStationPool>;
}

type updatePool = (poolId: number, pool: Pool) => Promise<void>;
type createPool = (pool: any) => Promise<void>;
type closePoolsModal = () => void;
type openPoolsDrawer = (type: DrawerType, poolId?: number | undefined) => void;
type getPools = () => Promise<void>;

type UsePools = [
  boolean,
  Pool | undefined,
  Array<Pool> | undefined,
  boolean,
  DrawerType | undefined,
  Industry | undefined,
  boolean,
  (open: boolean) => void,
  updatePool,
  createPool,
  closePoolsModal,
  openPoolsDrawer,
  getPools,
];

export function usePoolsDashPanel(accountId?: number, locationId?: number): UsePools {
  const [pool, setPool] = useState<Pool | undefined>();
  const [pools, setPools] = useState<Array<Pool> | undefined>(undefined); //Change to my pool after object defined??
  const [poolsOpen, setPoolsOpen] = useState(false);
  const [poolsDrawerType, setPoolsDrawerType] = useState<DrawerType | undefined>();
  const [openStationQrCodeDrawer, setOpenStationQrCodeDrawer] = useState<boolean>(false);
  const [industry, setIndustry] = useState<Industry | undefined>();
  const { stationsStore, toastStore } = useStores();
  const { isPoolsEnabled } = stationsStore!;

  useEffect(() => {
    getSettings();
  }, [accountId, locationId]);

  async function createPool(pool: any) {
    try {
      closePoolsModal();
      const createdPool = {
        accountId: accountId,
        locationId: locationId,
        ...pool,
      };
      await Api.core.createPool(createdPool);
      getPools();
      toastStore!.success('Pool created!');
    } catch (e: any) {
      toastStore!.error(getErrorMsg(e));
    }
  }

  async function updatePool(poolId: number, pool: Pool) {
    try {
      closePoolsModal();
      await Api.core.updatePool(poolId, pool);
      getPools();
      toastStore!.success('Pool Updated!');
    } catch (e: any) {
      toastStore!.error(getErrorMsg(e));
    }
  }

  function openPoolsDrawer(type: DrawerType, poolId?: number) {
    setPool(undefined);
    if (poolId) {
      setPool(pools!.find((p) => p.id === poolId));
    }
    setPoolsOpen(true);
    setPoolsDrawerType(type);
  }

  function closePoolsModal() {
    setPoolsOpen(false);
    setPool(undefined);
  }

  async function getPools() {
    try {
      const pools = await BlPools.getPools(accountId!, locationId!);
      setPools(pools);
      stationsStore!.locationPools = pools;
    } catch (e: any) {
      toastStore!.error('Error');
    }
  }

  async function getSettings() {
    try {
      const settings = await BlSettings.getSettings(locationId!, 'location');
      if (settings) {
        setIndustry(settings.industry);
      }
    } catch (e: any) {
      toastStore.error(getErrorMsg(e));
    }
  }

  return [
    isPoolsEnabled,
    pool,
    pools,
    poolsOpen,
    poolsDrawerType,
    industry,
    openStationQrCodeDrawer,
    setOpenStationQrCodeDrawer,
    updatePool,
    createPool,
    closePoolsModal,
    openPoolsDrawer,
    getPools,
  ];
}
