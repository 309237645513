import React from 'react';
import { AppBar, Toolbar } from '@material-ui/core';
import clsx from 'clsx';
import useStyles from './styles';
import useElevatedAppBar from './useElevatedAppBar';
import { observer } from 'mobx-react';
import MobileToolbar from './MobileToolbar/MobileToolbar';
import WebToolbar from './WebToolbar/WebToolbar';

const ElevatedAppBar = () => {
  const { menuOpen, scrollTrigger, mobileView } = useElevatedAppBar();
  const { appBar, appBarShift, toolbar, toolbarScroll } = useStyles({
    mobileView,
    menuOpen,
  });
  return (
    <AppBar
      elevation={0}
      position="fixed"
      className={clsx(appBar, {
        [appBarShift]: menuOpen && !mobileView,
      })}>
      <Toolbar
        className={clsx(toolbar, {
          [toolbarScroll]: scrollTrigger || mobileView,
        })}>
        {mobileView ? <MobileToolbar /> : <WebToolbar />}
      </Toolbar>
    </AppBar>
  );
};

export default observer(ElevatedAppBar);
