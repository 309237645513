import React, { Component } from 'react';
import { RouteComponentProps, Link as RouterLink } from 'react-router-dom';
import { observable, action, flow, makeObservable } from 'mobx';
import { observer } from 'mobx-react';
import { WithStyles, withStyles } from '@material-ui/core/styles';
import { Box, Link, Button, Typography, Icon } from '@material-ui/core';

import { paths } from 'routes';
import { inject, WithUserStore } from 'stores';
import Api from 'api';
import AnonLayout from 'components/AnonLayout';

import LoadingSpinner from 'components/LoadingSpinner';

import styles from './styles';

type ChangeEmailState = 'START' | 'ERROR' | 'SUCCESS';

/**
 * The router's match params should adhere to this interface
 */
interface MatchParams {
  token: string;
}

type ChangeEmailProps = WithStyles<typeof styles> &
  RouteComponentProps<MatchParams> &
  WithUserStore;
/**
 * Confirm email change screen container.
 */
@inject('userStore')
@observer
class ChangeEmail extends Component<ChangeEmailProps> {
  constructor(props: ChangeEmailProps){
    super(props);
    makeObservable(this);
  }
  /** The password reset token provided in the URL */
  @observable public token: string = this.props.match.params.token;

  /** Whether the reset password API call is in progress */
  @observable public inProgress = false;

  /** The current state of the screen */
  @observable public currentState: ChangeEmailState = 'START';

  /** The current error */
  @observable public errorMsg = '';

  /**
   * Confirm email using token from url 'token' param
   */
  @action.bound public confirmEmail = flow(function* (this: ChangeEmail, token: string) {
    const userStore = this.props.userStore!;
    try {
      this.inProgress = true;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const resp: any = yield Api.core.confirmEmail(token);
      if (resp.data && resp.data.data) {
        yield userStore.loginWithData(resp.data.data);
      }
      this.currentState = 'SUCCESS';
    } catch (e: any) {
      this.currentState = 'ERROR';
      this.errorMsg = 'The token is either invalid or expired!';
    } finally {
      this.inProgress = false;
    }
  });

  componentDidMount() {
    this.confirmEmail(this.token);
  }

  render() {
    const { classes } = this.props;
    return (
      <AnonLayout>
        <Box height="100px">
          <Typography variant="h3" component="h1" align="center">
            {this.currentState === 'SUCCESS' && 'Success'}
            {this.currentState === 'ERROR' && 'Whoops'}
          </Typography>
        </Box>
        <Box
          height="100px"
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center">
          {this.currentState === 'START' ? (
            <LoadingSpinner size={64} />
          ) : (
            <Icon
              color={this.currentState === 'ERROR' ? 'error' : 'primary'}
              className={classes.statusIcon}>
              {this.currentState === 'SUCCESS' && 'check'}
              {this.currentState === 'ERROR' && 'close'}
            </Icon>
          )}
        </Box>
        <Box height="100px" display="flex" flexDirection="column" justifyContent="space-around">
          {this.currentState === 'SUCCESS' && (
            <>
              <Typography align="center">Email changed successfully!</Typography>{' '}
              <Button
                component={RouterLink}
                size="large"
                type="submit"
                variant="contained"
                color="primary"
                to={paths.root()}
                fullWidth>
                To dashboard
              </Button>
            </>
          )}
          {this.currentState === 'ERROR' && (
            <>
              <Typography align="center" className={classes.errorMsg}>
                {this.errorMsg}
              </Typography>
              <Typography align="left" component="div">
                <Link component={RouterLink} to={paths.signIn()}>
                  ← Back to sign-in
                </Link>
              </Typography>
            </>
          )}
        </Box>
      </AnonLayout>
    );
  }
}

export default withStyles(styles)(ChangeEmail);
