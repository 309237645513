import { IDialogProps } from 'components/Dialog/Dialog';

export enum ELicenseBillingGeneralDialog {
  ADD_LICENSE = 'add_license',
  ADD_BILLING_GROUP = 'add_billing_group',
  UPDATE_BILLING_GROUP = 'update_billing_group',
  DELETE_BILLING_GROUP = 'delete_billing_group',
  BILLING_GROUP_LICENSES = 'list_billing_group_licenses',

  CHANGE_PAYMENT_METHOD = 'change_payment_method',
  CANCEL_LICENSE = 'cancel_license',
  ACTIVATE_LICENSE = 'activate_license',
  DEVICE_INVENTORY = 'device_inventory',
  EXTEND_LICENSE = 'extend_license',
  CHANGE_PRODUCT = 'change_product',
}

export enum EManageLicenseDialog {
  CHANGE_PAYMENT_METHOD = 'change_payment_method',
  CANCEL_LICENSE = 'cancel_license',
  DEVICE_INVENTORY = 'device_inventory',
  EXTEND_LICENSE = 'extend_license',
  CHANGE_PRODUCT = 'change_product',
}

export interface ILicenseBillingGeneralDialogProps
  extends Omit<IDialogProps, 'content' | 'onCancel' | 'onClose'> {
  type: ELicenseBillingGeneralDialog | null;
  onCancel: VoidFunction;
  onClose?: VoidFunction;
}
