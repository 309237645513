import { Fab, FabProps, Zoom } from '@material-ui/core';
import { Plus } from 'mdi-material-ui';
import React from 'react';
import useStyles from './styles';

type IPlusFabButton = {
  to?: string | Location;
  children?: FabProps['children'];
  right?: number;
  dataCy?: string;
  hide?: boolean;
} & Omit<FabProps, 'children'> & { component?: React.ElementType };

function PlusFabButton(props: IPlusFabButton) {
  let _right = 6.75;
  if (typeof props?.right !== 'undefined') {
    _right = props.right;
  }
  const classes = useStyles({ right: _right });
  const { onClick, right, dataCy, hide, ...fabProps } = props;
  if (hide) return null;
  return (
    <Zoom in>
      <Fab
        {...fabProps}
        color="secondary"
        onClick={onClick}
        className={classes.fabButton}
        data-cy={dataCy}>
        <Plus />
      </Fab>
    </Zoom>
  );
}
export default PlusFabButton;
