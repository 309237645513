import { Theme, createStyles } from '@material-ui/core';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default (theme: Theme) =>
  createStyles({
    dialogTitle: {
      paddingBottom: 0,
    },
    dialogActions: {
      padding: theme.spacing(3),
      marginTop: theme.spacing(2),
    },
    dialogContent: {
      padding: 0,
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
      marginTop: theme.spacing(4),
    },
  });
