import { Theme } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    inputAdornment: {
      marginRight: theme.spacing(0.25),
      color: theme.palette.primary.main,
    },
    fixedAmountLabels: {
      textTransform: 'uppercase',
      fontSize: 12,
      fontWeight: 400,
      color: theme.palette.text.primary,
    },
    input: {
      border: `0px`,
      borderRadius: 0,
      width: '100%',
      fontSize: 16,

      borderBottom: `1px solid ${theme.palette.primary.main}`,
      '& .MuiInputAdornment-root': {
        '& .MuiTypography-root': {
          fontSize: 14,
          fontWeight: 400,
          color: theme.palette.primary.main,
        },
      },
    },
  }),
);

export default useStyles;
