import React from 'react';
import { observer } from 'mobx-react';
import {
  action,
  observable,
  reaction,
  computed,
  IReactionDisposer,
  flow,
  makeObservable,
} from 'mobx';

import SettingsStore from './SettingsStore';
import * as models from 'models';

import _ from 'lodash';

import { ChevronLeft, PlusCircle, MinusCircle } from 'mdi-material-ui';

import {
  DialogContent,
  DialogTitle,
  IconButton,
  Divider,
  Box,
  Typography,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  MenuItem,
  Tooltip,
  DialogContentText,
} from '@material-ui/core';

import DP from 'components/DashPanel';

import { WithStyles, withStyles } from '@material-ui/core/styles';

import styles from './styles';
import { default as DD } from 'components/DashDrawer';
import Button from 'components/Button/Button';
import UnderlineInputFlex from 'components/Input/UnderlineInputFlex/UnderlineInputFlex';
import UnderlineSelectFlex from 'components/Input/UnderlineSelectFlex/UnderlineSelectFlex';

interface BreakpointListProps extends WithStyles<typeof styles> {
  selectedStrategy: models.ProposalStrategy;
  settingsStore: SettingsStore;
  goBack: () => void;
}

/**
 * CRUD component for breakpoints. By interacting with this component user can
 * manage tip breakpoints for either location or account.
 *
 * @param settingsStore on demand settings state management store
 * @param selectedStrategy flag for calling API with correct values
 * @param goBack Event handler that gets called when user navigates to previous screen
 */
@observer
class BreakpointList extends React.Component<BreakpointListProps> {
  constructor(props: BreakpointListProps) {
    super(props);
    makeObservable(this);
    this.disposers.push(
      reaction(
        () => this.hasZeroBreakpoint,
        (hasZeroBreakpoint) => {
          this.newBreakpointObj.breakpoint = hasZeroBreakpoint ? '' : '0';
        },
      ),
    );
  }

  /** It's good practice to dispose of any autoruns that we set up during */
  private disposers: IReactionDisposer[] = [];

  @observable public settingsStore = this.props.settingsStore;

  @observable public breakpoints = this.settingsStore.breakpointProposalStrategySettings;

  @observable public newBreakpointObj: models.Breakpoint = {
    breakpoint: '',
    low: '',
    medium: '',
    high: '',
    type: 'amount',
  };

  @observable public breakpointValid = true;

  @computed public get updating(): boolean {
    return this.settingsStore.updating;
  }

  @computed private get sortedBreakpoints() {
    return _.orderBy(this.breakpoints, (entry) => Number(entry.breakpoint), ['asc']);
  }

  /** Check if mandatory zero breakpoint exists in breakpoints list */
  @computed private get hasZeroBreakpoint() {
    return this.sortedBreakpoints.filter((breakpointObj: any) => breakpointObj.breakpoint === '0')
      .length;
  }

  @action.bound private addNewBreakpoint = () => {
    /**
     * Map over newBreakpoint values and check if all are set
     * Form is invalid if:
     * Object has properties without values
     * Object has properties with value that is < 0
     */
    // Return length of key properties
    const invalid = Object.keys(this.newBreakpointObj).filter((key: string) => {
      // Key value
      const value = this.newBreakpointObj[key as keyof models.Breakpoint];
      const noValueOrLessThanZero = !value || (value && Number(value) < 0);
      // Ignore "type" property
      return key === 'type' ? false : noValueOrLessThanZero;
    }).length;
    this.breakpointValid = Boolean(!invalid);
    if (invalid) return;

    this.breakpoints = [
      ...this.breakpoints!.filter(
        // Check if breakpoint with this value already exists and replace it
        (breakpointObj: models.Breakpoint) =>
          breakpointObj.breakpoint !== this.newBreakpointObj.breakpoint,
      ),
      this.newBreakpointObj,
    ];
    this.newBreakpointObj = { breakpoint: '', low: '', medium: '', high: '', type: 'amount' };
  };

  @action.bound private removeBreakpoint = (breakpoint: string | number) => () => {
    this.breakpoints = [
      ...this.breakpoints!.filter(
        (breakpointObj: models.Breakpoint) => breakpointObj.breakpoint !== breakpoint,
      ),
    ];
  };

  @action.bound private handleInputChange =
    (key: keyof models.Breakpoint) =>
    (
      event: React.ChangeEvent<HTMLInputElement | { name?: string | undefined; value: unknown }>,
    ) => {
      let value = event.target.value as string;
      // Set values to 100 if type percent and field value over 100
      if (key !== 'breakpoint' && Number(value) > 100 && this.newBreakpointObj.type === 'percent') {
        value = '100';
      }
      if (key !== 'type') this.newBreakpointObj[key] = value;
    };

  @action.bound private handleTypeChange = (
    event: React.ChangeEvent<HTMLInputElement | { name?: string | undefined; value: unknown }>,
  ) => {
    const type = event.target.value as 'amount' | 'percent';
    // If user switched to percent then validate his preset values:
    // If value higher than 100 set to 100
    if (type === 'percent') {
      this.newBreakpointObj.low =
        Number(this.newBreakpointObj.low) > 100 ? '100' : this.newBreakpointObj.low;
      this.newBreakpointObj.medium =
        Number(this.newBreakpointObj.medium) > 100 ? '100' : this.newBreakpointObj.medium;
      this.newBreakpointObj.high =
        Number(this.newBreakpointObj.high) > 100 ? '100' : this.newBreakpointObj.high;
    }
    this.newBreakpointObj.type = type;
  };

  @action.bound private onSave = () => {
    this.saveSettings();
  };

  @action.bound public saveSettings = flow(function* (this: BreakpointList) {
    const strategy = this.props.selectedStrategy;
    const breakpoints = this.breakpoints;
    yield this.settingsStore.updateGeneralSettings({ strategy });
    yield this.settingsStore.updateProposalStrategySettings(strategy, { breakpoints });
    this.props.goBack();
  });

  componentDidMount() {
    this.newBreakpointObj.breakpoint = this.hasZeroBreakpoint ? '' : '0';
  }

  /** Before unmounting the component, dispose of all autoruns created */
  componentWillUnmount() {
    this.disposers.map((disposer) => disposer());
  }

  render() {
    const { drawerContent, tableHead, plusButton } = this.props.classes;

    return (
      <>
        <DialogTitle>
          <Box display="flex" justifyContent="space-between">
            <IconButton onClick={this.props.goBack}>
              <ChevronLeft />
            </IconButton>
            <Typography style={{ fontSize: 28, fontWeight: 400 }} display="inline">
              Breakpoints
            </Typography>
            <Box
              width="40px"
              marginLeft="20px"
              display="flex"
              flexDirection="row"
              alignItems="center">
              {this.updating && <DP.LoadSpinner />}
            </Box>
          </Box>
        </DialogTitle>
        <Divider />
        <DialogContent className={drawerContent}>
          <Box display="flex" flexDirection="column" height="100%">
            <Table size="small">
              <TableHead>
                <TableRow className={tableHead}>
                  <TableCell align="left">starts at</TableCell>
                  <TableCell align="left">low</TableCell>
                  <TableCell align="left">medium</TableCell>
                  <TableCell align="left">high</TableCell>
                  <TableCell align="left">type</TableCell>
                  <TableCell align="left"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.sortedBreakpoints.map((breakpointSettings) => {
                  const { breakpoint, low, medium, high, type } = breakpointSettings;
                  return (
                    <TableRow key={breakpoint}>
                      <TableCell component="th" scope="row">
                        {breakpoint}
                      </TableCell>
                      <TableCell align="left">{low}</TableCell>
                      <TableCell align="left">{medium}</TableCell>
                      <TableCell align="left">{high}</TableCell>
                      <TableCell align="left">
                        {type === 'percent' && '%'}
                        {type === 'amount' && '$'}
                      </TableCell>
                      <TableCell align="left">
                        <Tooltip title={'Remove breakpoint'} placement="top" enterDelay={500}>
                          <IconButton onClick={this.removeBreakpoint(breakpoint)}>
                            <MinusCircle fontSize="small" color="error" />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
            {!this.hasZeroBreakpoint && (
              <Box mt={1}>
                <Typography align="center" color="error">
                  Breakpoint that starts at $0 has to be set
                </Typography>
              </Box>
            )}
            <Box marginTop={4} marginBottom={3}>
              <DialogContentText variant="body1">
                When adding a breakpoint you can either override an existing breakpoint if its
                breakpoint value matches the one you added or add a new one if there is no
                breakpoint set at that value yet.
              </DialogContentText>
            </Box>
            <Box mb={3} display="flex" alignItems="flex-start" justifyContent="space-between">
              <UnderlineInputFlex
                width={56}
                value={this.newBreakpointObj.breakpoint}
                disabled={!this.hasZeroBreakpoint}
                label="starts at"
                prefix="$"
                onChange={this.handleInputChange('breakpoint')}
                type="number"
                margin="none"
              />
              <UnderlineInputFlex
                width={56}
                value={this.newBreakpointObj.low.toString()}
                onChange={this.handleInputChange('low')}
                label="low"
                prefix="$"
                type="number"
                margin="none"
              />
              <UnderlineInputFlex
                width={56}
                value={this.newBreakpointObj.medium}
                onChange={this.handleInputChange('medium')}
                label="medium"
                prefix="$"
                type="number"
                margin="none"
              />
              <UnderlineInputFlex
                width={56}
                value={this.newBreakpointObj.high}
                onChange={this.handleInputChange('high')}
                label="high"
                prefix="$"
                type="number"
                margin="none"
              />
              <UnderlineSelectFlex
                value={this.newBreakpointObj.type}
                onChange={this.handleTypeChange}
                label={'Type'}>
                <MenuItem value={'percent'}>%</MenuItem>
                <MenuItem value={'amount'}>$</MenuItem>
              </UnderlineSelectFlex>
              <Box height={'100%'} display={'flex'} alignItems={'center'}>
                <Tooltip title={'Add a new breakpoint'} placement="top" enterDelay={500}>
                  <IconButton classes={{ root: plusButton }} onClick={this.addNewBreakpoint}>
                    <PlusCircle fontSize="small" color="primary" />
                  </IconButton>
                </Tooltip>
              </Box>
            </Box>
            {!this.breakpointValid && (
              <Box mb={3}>
                <Typography align="center" color="error">
                  Please fill out all non-negative values before adding a new breakpoint
                </Typography>
              </Box>
            )}
          </Box>
        </DialogContent>
        <DD.Actions>
          <Button
            onClick={this.onSave}
            disabled={!this.hasZeroBreakpoint || this.updating}
            fullWidth>
            Save
          </Button>
        </DD.Actions>
      </>
    );
  }
}

export default withStyles(styles)(BreakpointList);
