import Api, { getErrorMsg } from 'api';
import { rootStore } from 'containers/App/App';
import { IInvitationItemProps } from './InvitationItem';
import moment from 'moment';
import * as models from 'models';
import { useState } from 'react';

const useInvitationItem = ({
  invitation,
  setDialogProps,
}: Omit<IInvitationItemProps, 'dialogProps'>) => {
  const { toastStore, notificationStore } = rootStore;
  const [loading, setLoading] = useState(false);

  const expiryDate = moment(invitation.expiresAt).format('MMM, DD');
  const message = `Invited to join ${invitation.location ? invitation.location.name : ''} ${
    invitation.account ? `${invitation.account.name}` : ''
  }`;
  const expiration = `Expires on ${expiryDate}`;

  const reject = async () => {
    try {
      await Api.core.rejectInvitation(invitation.id);
      toastStore!.push({
        type: 'success',
        message: 'Invitation was rejected',
      });
      notificationStore.getInvitations();
    } catch (e: any) {
      toastStore!.error(getErrorMsg(e));
    }
  };

  /**
   * Prompt the user to select a location by initiating location dialog object
   * and setting dialogs event handlers as a resolution to the promise.
   */
  const locationPrompt = (
    locations: Pick<models.Location, 'id' | 'name'>[],
  ): Promise<number | void> => {
    return new Promise((resolve) => {
      setDialogProps({
        locations,
        locationId: '',
        confirmLocation: (locationId?: string) => {
          if (locationId) resolve(parseInt(locationId));
        },
        closeDialog: () => resolve(),
      });
    });
  };

  const accept = async () => {
    try {
      // Check if user is accepting account based invitation (invitation does not have
      // locationId). If locationId is missing, we need to prompt user to select a location
      let locationId;
      if (!invitation.location) {
        if (invitation.locations) {
          locationId = await locationPrompt(invitation.locations);
          // Close the dialog by un-setting the dialog object:
          // If we don't have locationId, user closed the dialog; don't do anything else
          if (!locationId) return;
        } else {
          throw { name: 'locations', message: 'Invitation is missing locations' };
        }
      }

      setLoading(true);
      await Api.core.acceptInvitation(invitation.id, locationId);
      toastStore!.push({
        type: 'success',
        message: `Invitation accepted`,
      });
      notificationStore.getInvitations();
      setDialogProps(null);

      // Accepting the invitation adds user to a location. This action might
      // change her scope, dashboard appearance and available functionality.
      // To reflect the changes we wait a bit and reload the dashboard:
      setTimeout(() => {
        window.location.reload();
      }, 1500);
    } catch (error: any) {
      if (error?.name === 'locations') {
        toastStore!.error(getErrorMsg(error));
      } else {
        toastStore!.error(getErrorMsg(error?.message));
      }
    } finally {
      setLoading(false);
      setDialogProps(null);
    }
  };

  return { accept, reject, message, expiration, loading };
};

export default useInvitationItem;
