import { Theme, createStyles } from '@material-ui/core';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default (theme: Theme) =>
  createStyles({
    root: {},
    chartLineLabel: {
      textTransform: 'uppercase',
      fontSize: 24,
      color: '#777',
    },
    chartLegendItem: {
      minWidth: 280,
      marginRight: 12,
    },
    totalValue: {
      textTransform: 'uppercase',
      fontSize: 42,
      color: '#444',
    },
    loadingSpinner: {
      marginLeft: 'auto',
      marginRight: 'auto',
      textAlign: 'center',
    },
    tooltip: {
      padding: '8px',
      margin: '24px',
      minWidth: '200px',
      backgroundColor: 'white',
      border: 'solid',
      borderWidth: '2px',
      borderRadius: '8px',
      borderColor: theme.palette.primary.main,
    },
    tooltipLabel: {
      fontWeight: 'bold',
    },
    tooltipValue: {
      fontWeight: 'bold',
      textTransform: 'capitalize',
    },
    tooltipDot: {
      width: 10,
      height: 10,
      borderRadius: '50%',
    },
  });
