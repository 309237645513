import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { observable, action, flow, computed, makeObservable } from 'mobx';
import { observer } from 'mobx-react';
import { WithStyles, withStyles } from '@material-ui/core/styles';

import { inject, WithToastStore, WithUserStore } from 'stores';
import Api from 'api';
import { setTitle } from 'services/title';
import qs from 'qs';

import styles from './styles';
import CarouselScreenWrapper from 'components/CarouselScreenWrapper/CarouselScreenWrapper';
import withAndroidRedirect from 'components/WithAndroidRedirect/withAndroidRedirect';
import EmailConfirm from 'components/EmailConfirm/EmailConfirm';
import newEmailConfirmForm from './helpers';
import SignupLayout, { TSignupLayoutAction } from 'containers/SignupLayout/SignupLayout';

interface ConfirmEmailProps
  extends WithStyles<typeof styles>,
    RouteComponentProps<{ code?: string }>,
    WithToastStore,
    WithUserStore {
  nextRoute?: () => string;
  prevRoute: () => string;
}

interface ConfirmEmailRouterState {
  email?: string;
}

/**
 * Displays the prompt to enter an email confirmation code
 */
@inject('toastStore', 'userStore')
@observer
class ConfirmEmail extends React.Component<ConfirmEmailProps> {
  constructor(props: ConfirmEmailProps) {
    super(props);
    makeObservable(this);
  }
  /** Set the title on mount */
  componentDidMount() {
    setTitle(`Confirm Email`);
    let code = this.enterCodeFromQs();

    // If this sign up started with an invitation link emailConfirmToken will be
    // included in route params. If that is the case we can confirm email right away
    // if something goes wrong user can still manually request confirm email token
    if (this.emailConfirmToken) {
      code = this.emailConfirmToken;
      this.code = code;
      this.confirmEmail();
    }

    this.form = newEmailConfirmForm(code);
  }

  /** The confirmation code */
  @observable code = '';

  /** The email that came from the query params */
  @observable public qsEmail?: string;

  /** Whether resending the code is currently in progress */
  @observable public resendingCode = false;

  /** Whether confirming the code is currently in progress */
  @observable public confirming = false;

  @observable public form: any;

  /** Sign up email confirm token. Included with invitation links */
  @computed public get emailConfirmToken(): string | undefined {
    const routerState: any = this.props.location.state;
    return routerState && routerState.emailConfirmToken ? routerState.emailConfirmToken : undefined;
  }

  /** Event handler to update the code */
  @action.bound public updateCode(e: React.ChangeEvent<HTMLInputElement>) {
    this.code = e.target.value;
  }

  /** Action to resend the code */
  @action.bound public resendCode = flow(function* (this: ConfirmEmail) {
    // Get the email from the router state. When the previous screen navigates
    // to this one, it sends state along with the route change, and the state contains
    // the email.
    const routerState: ConfirmEmailRouterState | undefined = this.props.location.state as any;
    const email = (routerState && routerState.email) || this.qsEmail;
    try {
      this.resendingCode = true;
      yield Api.core.resendEmailChangeConfirmationEmail(email, this.props.location.pathname);
      this.resendingCode = false;
      this.props.toastStore!.success('Confirmation email resent!');
    } catch (e: any) {
      this.props.toastStore!.push({
        type: 'error',
        message: 'An error occurred while resending the confirmation email',
      });
    }
  });

  /** Confirm the email */
  @action.bound public confirmEmail = flow(function* (this: ConfirmEmail) {
    try {
      this.confirming = true;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const resp: any = yield Api.core.confirmEmail(this.code.toUpperCase());
      this.props.userStore!.loginWithData(resp.data.data);
      // If we got an affiliate organization code from the URL, store it in the user
      // store after logging in
      const { org } = qs.parse(this.props.location.search, { ignoreQueryPrefix: true });
      if (org) {
        // Use atob to decode it from base64
        this.props.userStore!.setAffiliateOrgCode(atob(typeof org === 'string' ? org : ''));
      }
      // If this is the account signup process, take the user to personal info
      // step.
      if (this.props.nextRoute) {
        this.props.history.replace(this.props.nextRoute());
      }
    } catch (e: any) {
      const error = e.response && e.response.data && e.response.data.error;
      let message = error && error.message;
      if (error.code === 404) {
        message = 'Incorrect code, try resending it';
      }
      this.props.toastStore!.push({
        type: 'error',
        message,
      });
    } finally {
      this.confirming = false;
    }
  });

  /** The form submit handler */
  @action.bound public handleSubmit() {
    this.code = this.form?.$('token').value;
    this.confirmEmail();
  }

  @action.bound onInputValid = (form: any) => {
    this.form = form;
  };

  @action.bound onInputInvalid = () => {
    this.form = null;
  };

  @computed get error() {
    return this.form?.$('token').error;
  }

  @computed public get email() {
    const routerState: ConfirmEmailRouterState | undefined = this.props.location.state as any;
    return routerState?.email || this.qsEmail;
  }

  @computed public get signupLayoutProps() {
    const hasError = this.form?.$('token')?.hasError;

    const title = 'Confirm your Email';
    const action: TSignupLayoutAction = {
      onClick: this.handleSubmit,
      children: 'Continue',
      disabled: hasError || this.resendingCode,
      loading: this.confirming,
    };

    return { title, action };
  }

  /** Enters the code in the URL params if they are present and submits it */
  @action.bound public enterCodeFromQs() {
    const queryString = this.props.location.search;
    const { email, code }: { email?: string; code?: string } = qs.parse(queryString, {
      ignoreQueryPrefix: true,
    });
    if (email) {
      this.qsEmail = email;
    }
    if (code) {
      this.code = code;
      this.confirmEmail();
    }
    return code;
  }

  render() {
    const emailField = this.form?.$('token');
    const hasError = emailField?.hasError;
    const canShowError = !emailField?.isPristine;
    return (
      <CarouselScreenWrapper>
        <SignupLayout {...this.signupLayoutProps}>
          <EmailConfirm
            center
            email={this.email}
            initialValue={this.code}
            loading={!this.form}
            hasError={canShowError && hasError}
            error={emailField?.error}
            resendCode={this.resendCode}
            isResendingCode={this.resendingCode}
            {...(emailField && emailField.bind())}
          />
        </SignupLayout>
      </CarouselScreenWrapper>
    );
  }
}

export default withAndroidRedirect(withStyles(styles)(ConfirmEmail));
