export enum EInput {
  EMAIL = 'email',
  NUMBER = 'number',
  PASSWORD = 'password',
  SELECT = 'select',
  PHONE = 'phone',
  TEXT = 'text',
  AUTOCOMPLETE = 'autocomplete',
  TAGS_AUTOCOMPLETE = 'tags_autocomplete',
  DATE_PICKER = 'date_picker',
}
