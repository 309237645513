import { Theme } from '@mui/material';

const listItemStyles = (theme: Theme) => {
  return {
    addLicensesDialog: {
      width: 800,
    },
    textFirst: {
      fontSize: '16px',
      fontWeight: 400,
      lineHeight: '20,29',
    },
    textSecondary: {
      color: theme.palette.grey[600],
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: '17,75',
    },
    textSecondaryHighlight: {
      color: theme.palette.status.yellow,
      cursor: 'pointer',
    },
    textTertirayHighlight: {
      color: theme.palette.status.red,
    },
    textTertiary: {
      color: theme.palette.grey[400],
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: '17,75',
      fontStyle: 'italic',
    },
  };
};

export default listItemStyles;
