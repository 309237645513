import { faFaceThinking } from '@fortawesome/pro-regular-svg-icons';
import { Box, Typography } from '@material-ui/core';
import CircularIconFrame from 'components/CircularIconFrame/CircularIconFrame';
import { useStyles } from '../styles';

interface InvoiceErrorProps {
  error?: string;
}

export const InvoiceError = ({ error }: InvoiceErrorProps) => {
  const classes = useStyles();
  if (!error) {
    return <></>;
  }
  return (
    <Box className={classes.errorBox}>
      <CircularIconFrame icon={faFaceThinking} color="red" />
      <Typography align="center" className={classes.errorMsg}>
        Error occured. {error}.
      </Typography>
    </Box>
  );
};
