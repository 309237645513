import React from 'mdi-material-ui';
import { Amount } from 'components/Amount/Amount';
import moment from 'moment';
import { Box } from '@material-ui/core';
import { EDateFormat } from 'utils/helper';

const renderCellAmount = ({ row }: any) => {
  return <Amount>{row.amount}</Amount>;
};

const renderCellBalance = ({ row }: any) => {
  return <Amount>{row.balance}</Amount>;
};

const renderCellNote = ({ row }: any) => {
  return <Box style={{ textTransform: 'capitalize' }}>{row.note}</Box>;
};

export const gridColumns = [
  {
    headerName: 'Date',
    field: 'date',
    minWidth: 200,
    flex: 1,
    valueGetter: ({ value }: any) => value && moment(new Date(value)).format(EDateFormat.DEFAULT),
  },
  {
    headerName: 'Time',
    field: 'time',
    minWidth: 200,
    flex: 1,
    valueGetter: ({ value }: any) => value && moment(new Date(value)).format('h:mm A'),
  },
  {
    headerName: 'Type',
    field: 'type',
    minWidth: 120,
    flex: 1,
  },
  {
    headerName: 'Amount',
    field: 'amount',
    minWidth: 120,
    flex: 1,
    renderCell: renderCellAmount,
  },
  { headerName: 'Note', field: 'note', minWidth: 150, flex: 1, renderCell: renderCellNote },
  {
    headerName: 'Balance',
    field: 'balance',
    minWidth: 180,
    flex: 1,
    renderCell: renderCellBalance,
  },
];
