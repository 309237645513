import { useEffect, useState } from 'react';
import validatorjs from 'validatorjs';
import { IEditTitleDialogProps } from './EditTitleDialog';
import { getErrorMsg } from 'api';

const dvr = require('mobx-react-form/lib/validators/DVR');
const MobxReactForm = require('mobx-react-form').default;

type MobxForm = any;

interface IRules {
  [key: string]: {
    function: (value: any) => boolean;
    message: string;
  };
}

let rules: IRules = {};
const plugins = {
  dvr: dvr({
    package: validatorjs,
    extend: ({ validator }: { validator: validatorjs.ValidatorStatic; form: MobxForm }) => {
      Object.keys(rules).forEach((key) => {
        validator.register(key, rules[key].function, rules[key].message);
      });
    },
  }),
};

const fields = [
  {
    name: 'title',
    label: 'New title',
    rules: 'required|string|min:3',
    value: '',
    hooks: {
      onChange: (field: any) => {
        field.validate();
      },
    },
  },
];

const newForm = (
  submit: TUseEditTitleDialog['submit'],
  closeDialog: TUseEditTitleDialog['onClose'],
  onSuccess?: TUseEditTitleDialog['onSuccess'],
) => {
  const hooks = {
    onSuccess: async (form: MobxForm) => {
      if (!submit) return (form.supportError = 'Ooops, something went wrong');
      const newTitle = form.$('title').value;
      try {
        const resp = await submit(newTitle);
        onSuccess && onSuccess(resp);
        closeDialog();
      } catch (error) {
        form.supportError = getErrorMsg(error);
      }
    },
  };
  const form = new MobxReactForm({ fields }, { plugins, hooks });
  form.supportError = '';
  return form;
};

type TUseEditTitleDialog = Pick<
  IEditTitleDialogProps,
  'submit' | 'loading' | 'onClose' | 'title' | 'open' | 'onSuccess'
>;

const useEditTitleDialog = ({
  loading,
  title,
  open,
  submit,
  onClose,
  onSuccess,
}: TUseEditTitleDialog) => {
  const [form, setForm] = useState(() => newForm(submit, onClose, onSuccess));
  const disabled = () => form && !form.isValid;
  const isLoading = loading || !!form?.submitting;

  useEffect(() => {
    form?.$('title').set(title);
    form?.validate({ showErrors: true });
  }, [open]);

  const handleSubmit = () => {
    form && form.submit();
  };

  const handleCancel = () => {
    form.supportError = '';
    onClose();
  };

  return {
    form,
    isLoading,
    disabled,
    handleCancel,
    handleSubmit,
  };
};
export default useEditTitleDialog;
