import { useAddStationPools } from './useAddStationPools';
import { observer } from 'mobx-react-lite';
import { ISelectedLocationPool } from 'models/Station';
import DD from 'components/DashDrawer';
import { Box, IconButton, List, ListItem } from '@material-ui/core';
import { Add, Check } from '@mui/icons-material';
import React from 'react';
import { ListItemText, Skeleton } from '@mui/material';
import { useStyles } from './styles';
import Button from 'components/Button/Button';

export const AddStationPools = observer(() => {
  const classes = useStyles();
  const [pools, addPool, goBack, toggleSelectedPool, addSelectedPools] = useAddStationPools();
  const isSelectedPoll = pools ? pools.some((pool) => pool.selected) : false;
  return (
    <DD overflow="hidden">
      <DD.Content>
        <DD.Title fontSize={28} onBack={goBack}>
          Add Pools
        </DD.Title>
        <Box
          minWidth={450}
          maxHeight={'70vh'}
          style={{ display: 'flex', flexDirection: 'column', overflowY: 'auto' }}>
          <PoolsList pools={pools} toggleSelectedPool={toggleSelectedPool} />
        </Box>
        <Box display={'flex'} mt={3.375} mb={0}>
          <Button
            onClick={addPool}
            classes={{ root: classes.addPoolButton }}
            variant="text"
            startIcon={<Add />}
            style={{ alignSelf: 'center', height: 20 }}>
            Create New Pool
          </Button>
        </Box>
      </DD.Content>
      <DD.Actions>
        <Button
          onClick={() => addSelectedPools()}
          fullWidth
          color={'primary'}
          variant="contained"
          disabled={!isSelectedPoll}>
          ADD{' '}
        </Button>
      </DD.Actions>
    </DD>
  );
});

const ListItemSkeleton = () => {
  const classes = useStyles();
  return (
    <ListItem>
      <Skeleton
        variant="circular"
        width={20}
        height={20}
        style={{ marginRight: '10px', marginBottom: '20px' }}
      />
      <Box className={classes.listItemText} width={'100%'}>
        <Skeleton variant="text" width="30%" height="20px" />
      </Box>
    </ListItem>
  );
};

interface IPoolsList {
  pools?: Array<ISelectedLocationPool>;
  toggleSelectedPool: (pool: ISelectedLocationPool) => void;
}
const PoolsList = ({ pools, toggleSelectedPool }: IPoolsList) => {
  const classes = useStyles();
  if (!pools) {
    const numberOfSkeletons = 10;
    let skeletons = [];
    for (let i = 0; i < numberOfSkeletons; i++) {
      skeletons.push(<ListItemSkeleton key={i} />);
    }
    return <List style={{ padding: 0, marginBottom: 10 }}>{skeletons}</List>;
  }
  return (
    <List style={{ padding: 0, marginLeft: '24px' }}>
      {pools.map((pool: ISelectedLocationPool) => (
        <ListItem
          key={pool.id}
          style={{ padding: 0, marginBottom: 10 }}
          onClick={() => toggleSelectedPool(pool)}>
          <ListItemText
            className={pool.selected ? classes.selectedItemText : classes.listItemText}
            primary={pool.name}
          />
          <IconButton className={classes.checkIcon}>
            {pool.selected && <Check fontSize="small" />}
          </IconButton>
        </ListItem>
      ))}
    </List>
  );
  // return (
  //   <List style={{ padding: 0}}>
  //     {pools.map((pool: ISelectedLocationPool) => (
  //       <ListItem key={pool.id} style={{ padding: 0, marginBottom: 10 }}>
  //         <Radio
  //           onClick={() => toggleSelectedPool(pool)}
  //           checked={pool.selected}
  //           color="primary"
  //           style={{ marginRight: 20 }}
  //         />
  //         <ListItemText className={classes.listItemText} primary={pool.name} />
  //       </ListItem>
  //     ))}
  //   </List>
  // );
};
