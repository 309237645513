import React from 'react';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { RouteComponentProps, Redirect } from 'react-router-dom';
import { observable, action, flow, makeObservable } from 'mobx';
import { observer } from 'mobx-react';

import { WithStyles, withStyles } from '@material-ui/core/styles';

import Api from 'api';
import qs from 'qs';

import { inject, WithUserStore, WithToastStore } from 'stores';
import { paths } from 'routes';

import styles from './styles';
import { AxiosResponse } from 'axios';

/** The variables that are matched in the URL (match.params) */
interface ReportDownloadQsParams {
  fromDate: string;
  toDate: string;
}
/** Here we define what kind of props this component takes */
type ReportDownloadProps = WithStyles<typeof styles> & // Adds the classes prop
  RouteComponentProps & // Adds the router props (history, match, location)
  WithUserStore &
  WithToastStore; // Adds the userStore prop

/**
 * Container for handling tips report download process
 */
@inject('toastStore', 'userStore')
@observer
class ReportDownload extends React.Component<ReportDownloadProps> {
  public constructor(props: ReportDownloadProps) {
    super(props);
    makeObservable(this);
    // Parse query string parameters
    this.qsParams = qs.parse(this.props.location.search, { ignoreQueryPrefix: true });
  }

  @observable public qsParams: ReportDownloadQsParams | any;

  @action.bound public downloadCsv = flow(function* (this: ReportDownload) {
    try {
      const { fromDate, toDate } = this.qsParams;
      const userId = this.props.userStore!.user!.id;
      const resp: AxiosResponse<Blob> = yield Api.tips.downloadTipsReport(userId, fromDate, toDate);
      if (resp && resp.data) {
        const url = window.URL.createObjectURL(new Blob([resp.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `talent_${userId}_report.csv`);
        document.body.appendChild(link);
        link.click();
      }
    } catch (e: any) {
      this.props.toastStore!.push({
        message: 'Unable to download requested report',
        type: 'error',
      });
    }
  });

  componentDidMount() {
    this.qsParams && this.downloadCsv();
  }

  render() {
    return <Redirect to={paths.root()} />;
  }
}

export default withStyles(styles)(ReportDownload);
