import React from 'react';
import { observer } from 'mobx-react';
import { observable, action, flow, computed, makeObservable } from 'mobx';

import SettingsStore from './SettingsStore';

import { Check } from 'mdi-material-ui';
import { Threshold, Breakpoint, ProposalStrategy as ProposalStrategyType } from 'models';

import {
  DialogContent,
  DialogTitle,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Divider,
  Typography,
  Icon,
  Box,
  DialogActions,
} from '@material-ui/core';

import { ChevronLeft } from 'mdi-material-ui';
import { WithStyles, withStyles } from '@material-ui/core/styles';

import ThresholdValue from './ThresholdValue';
import FixedAmounts from './FixedAmounts';
import Percentages from './Percentages';
import BreakpointList from './BreakpointList';

import DP from 'components/DashPanel';

import styles from './styles';
import Button from 'components/Button/Button';

interface ProposalStrategyProps extends WithStyles<typeof styles> {
  settingsStore: SettingsStore;
  goBack: () => void;
}

type SettingsNavItem = undefined | 'threshold' | 'amounts' | 'percentages' | 'breakpoints';

/**
 * Gives the user ability to switch between different proposal strategies and
 * see their current values or edit them by clicking on each of the possible options for
 * that strategy.
 *
 * @param settingsStore on demand settings state management store
 * @param goBack Event handler that gets called when user navigates to previous screen
 */
@observer
class ProposalStrategy extends React.Component<ProposalStrategyProps> {
  constructor(props: ProposalStrategyProps) {
    super(props);
    makeObservable(this);
  }

  /** Track users location inside proposal strategy settings modal */
  @observable public currentSetting: SettingsNavItem;

  /** Settings store. Gets passed down to sub-components */
  @observable public settingsStore: SettingsStore = this.props.settingsStore;

  /** Currently selected proposal strategy */
  @observable public proposalStrategy?: ProposalStrategyType =
    this.settingsStore.generalSettings!.strategy;

  @computed public get updating(): boolean {
    return this.settingsStore.updating;
  }

  /** Settings for threshold based strategies (basic, dynamic) */
  @computed public get thresholdProposalStrategySettings(): Threshold | undefined {
    if (this.settingsStore.generalSettings) {
      return this.settingsStore.thresholdProposalStrategySettings;
    }
    return undefined;
  }

  /** Settings for breakpoint strategy */
  @computed public get breakpointProposalStrategySettings(): Breakpoint[] | undefined {
    if (this.settingsStore.generalSettings) {
      return this.settingsStore.breakpointProposalStrategySettings;
    }
    return undefined;
  }

  /** Handle proposal strategy switch */
  @action.bound private switchProposalStrategy = (strategy: ProposalStrategyType) => () => {
    this.proposalStrategy = strategy;
    this.settingsStore.getProposalStrategySettings(strategy);
  };

  @action.bound private onSave = () => {
    this.saveSettings();
  };

  @action.bound public saveSettings = flow(function* (this: ProposalStrategy) {
    yield this.settingsStore.updateGeneralSettings({ strategy: this.proposalStrategy });
    this.props.goBack();
  });

  /** Handle modal navigation */
  @action.bound private handleSettingChange = (setting: SettingsNavItem) => () => {
    this.currentSetting = setting;
  };

  componentDidMount() {
    this.proposalStrategy = this.settingsStore.generalSettings!.strategy;
    this.settingsStore.getProposalStrategySettings(this.proposalStrategy);
  }

  render() {
    const {
      drawerContent,
      settingListItemKey,
      settingListItemKeyPrimary,
      settingListItemValue,
      settingListItemIcon,
      dialogActions,
    } = this.props.classes;

    // If 'currentSetting' is set render corresponding settings menu:
    if (this.proposalStrategy && this.currentSetting === 'threshold') {
      return (
        <ThresholdValue
          settingsStore={this.settingsStore}
          selectedStrategy={this.proposalStrategy}
          goBack={this.handleSettingChange(undefined)}
        />
      );
    }
    if (this.proposalStrategy && this.currentSetting === 'percentages') {
      return (
        <Percentages
          settingsStore={this.settingsStore}
          selectedStrategy={this.proposalStrategy}
          goBack={this.handleSettingChange(undefined)}
        />
      );
    }
    if (this.proposalStrategy && this.currentSetting === 'amounts') {
      return (
        <FixedAmounts
          settingsStore={this.settingsStore}
          selectedStrategy={this.proposalStrategy}
          goBack={this.handleSettingChange(undefined)}
        />
      );
    }
    if (this.proposalStrategy && this.currentSetting === 'breakpoints') {
      return (
        <BreakpointList
          settingsStore={this.settingsStore}
          selectedStrategy={this.proposalStrategy}
          goBack={this.handleSettingChange(undefined)}
        />
      );
    }
    // If 'currentSetting' is undefined render main proposal strategy menu:
    return (
      <>
        <DialogTitle>
          <Box display="flex" alignItems="center" justifyContent="space-start">
            <Box mr={2}>
              <IconButton onClick={this.props.goBack}>
                <ChevronLeft />
              </IconButton>
            </Box>
            <Typography variant="h5" component="h1" display="inline">
              Proposal Strategy
            </Typography>
            <Box width="40px" ml="auto" display="flex" flexDirection="row" alignItems="center">
              {this.updating && <DP.LoadSpinner />}
            </Box>
          </Box>
        </DialogTitle>
        <Divider />
        <DialogContent className={drawerContent}>
          <Box display="flex" flexDirection="column" height="100%">
            <List component="nav" aria-label="contacts">
              {Object.values(ProposalStrategyType).map((strategy) => (
                <ListItem key={strategy} onClick={this.switchProposalStrategy(strategy)} button>
                  <ListItemText
                    className={
                      strategy === this.proposalStrategy
                        ? settingListItemKeyPrimary
                        : settingListItemKey
                    }
                    primary={strategy}
                  />
                  {strategy === this.proposalStrategy && <Check color="primary" />}
                </ListItem>
              ))}
            </List>
            <Divider />
            <List>
              {/* IF (proposal strategy === threshold based) */}
              {this.thresholdProposalStrategySettings && (
                <>
                  <ListItem
                    button
                    onClick={this.handleSettingChange('threshold')}
                    className={this.props.classes.settingList}>
                    <ListItemText className={settingListItemKey} primary={`threshold`} />
                    <Typography className={settingListItemValue}>
                      {`$${this.thresholdProposalStrategySettings.threshold}`}
                    </Typography>
                    <Icon className={settingListItemIcon}>keyboard_arrow_right</Icon>
                  </ListItem>
                  <ListItem
                    button
                    onClick={this.handleSettingChange('percentages')}
                    className={this.props.classes.settingList}>
                    <ListItemText className={settingListItemKey} primary={`percentages`} />
                    <Typography className={settingListItemValue}>
                      {`${this.thresholdProposalStrategySettings.percentLow}% `}
                      {`${this.thresholdProposalStrategySettings.percentMedium}% `}
                      {`${this.thresholdProposalStrategySettings.percentHigh}% `}
                    </Typography>
                    <Icon className={settingListItemIcon}>keyboard_arrow_right</Icon>
                  </ListItem>
                  <ListItem
                    button
                    onClick={this.handleSettingChange('amounts')}
                    className={this.props.classes.settingList}>
                    <ListItemText className={settingListItemKey} primary={`fixed amounts`} />
                    <Typography className={settingListItemValue}>
                      {`$${this.thresholdProposalStrategySettings.amountLow} `}
                      {`$${this.thresholdProposalStrategySettings.amountMedium} `}
                      {`$${this.thresholdProposalStrategySettings.amountHigh} `}
                    </Typography>
                    <Icon className={settingListItemIcon}>keyboard_arrow_right</Icon>
                  </ListItem>
                </>
              )}
              {/* IF ('proposal strategy === breakpoints based') */}
              {this.breakpointProposalStrategySettings && (
                <ListItem
                  button
                  onClick={this.handleSettingChange('breakpoints')}
                  className={this.props.classes.settingList}>
                  <ListItemText className={settingListItemKey} primary={`breakpoints`} />
                  <Icon className={settingListItemIcon}>keyboard_arrow_right</Icon>
                </ListItem>
              )}
            </List>
          </Box>
        </DialogContent>
        <DialogActions className={dialogActions}>
          <Button
            variant="contained"
            color="primary"
            onClick={this.onSave}
            disabled={this.updating}
            fullWidth>
            Save
          </Button>
        </DialogActions>
      </>
    );
  }
}

export default withStyles(styles)(ProposalStrategy);
