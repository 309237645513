import { Box, MenuItem } from '@mui/material';
import Dialog from 'components/Dialog/Dialog';
import MomentUtils from '@date-io/moment';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import * as models from 'models';
import { EDateFormat } from 'utils/helper';
import { Moment } from 'moment';
import { getErrorMsg } from 'api';
import { useStores } from 'containers/App/App';
import Api from 'api';
import { useEffect, useRef, useState } from 'react';
import { ELicenseBillingGeneralDialog, ILicenseBillingGeneralDialogProps } from '../models';
import moment from 'moment';
import OutlinedInput from 'components/Input/OutlinedInput';
import { EXTEND_LICENSE_REASON } from 'utils/constants';

interface IExtendLicenseDialog extends ILicenseBillingGeneralDialogProps {
  license: models.License | null;
  onExtendLicense: VoidFunction;
}

const ExtendLicenseDialog = ({
  open,
  type,
  title,
  license,
  TransitionProps,
  onCancel,
  onExtendLicense,
}: IExtendLicenseDialog) => {
  const { toastStore } = useStores();
  //TODO: implement useForm hook
  const [expiresAt, setExpiresAt] = useState<string>(license?.expiresAt || '');
  const [reason, setReason] = useState<string>('');
  const [customReason, setCustomReason] = useState<string>('');
  const [extendingLicense, setExtendingLicense] = useState(false);
  const customReasonRef = useRef<HTMLInputElement>(null);

  const showCustom = reason === 'Custom';

  useEffect(() => {
    if (showCustom) {
      setCustomReason('');
      scrollIntoView();
    }
  }, [reason]);

  const updateLicenseExpiresAt = (moment: Moment | null) => {
    if (!license || !moment) return;
    setExpiresAt(moment.toISOString());
  };

  const extendLicense = async () => {
    setExtendingLicense(true);
    try {
      await Api.billing.extendLicense(license!.id, {
        expiresAt,
        expirationChangeReason: showCustom ? customReason : reason,
      });
      onExtendLicense();
      toastStore!.success(`License expiration date updated!`);
      onCancel();
    } catch (e: any) {
      toastStore!.error(getErrorMsg(e));
    } finally {
      setExtendingLicense(false);
    }
  };

  const onExited = () => {
    setReason('');
    setCustomReason('');
    setExpiresAt(license?.expiresAt || '');
  };

  const handleReasonChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setReason(e.target.value);
  };

  const handleCustomReasonChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCustomReason(e.target.value);
  };

  const scrollIntoView = () => customReasonRef?.current?.scrollIntoView();

  const _open = open && type === ELicenseBillingGeneralDialog.EXTEND_LICENSE;

  let disabled = !expiresAt || moment(expiresAt).isBefore(moment(), 'day');

  if (!disabled) {
    disabled = !reason?.length;
  }

  if (showCustom && !disabled) {
    disabled = !(customReason.length >= 5);
  }

  return (
    <Dialog
      open={_open}
      title={title}
      loading={extendingLicense}
      disabled={disabled}
      content={
        <Box display={'flex'} flexDirection={'column'} alignItems={'center'} gap={2}>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <DatePicker
              variant="static"
              value={expiresAt}
              onChange={updateLicenseExpiresAt}
              format={EDateFormat.DATE_LONG}
              openTo="date"
              views={['year', 'month', 'date']}
              label="Expires at"
              shouldDisableDate={(date) => !!date?.isBefore(moment(), 'day')}
            />
          </MuiPickersUtilsProvider>
          <OutlinedInput fullWidth label={'Select Reason'} onChange={handleReasonChange} select>
            {Object.values(EXTEND_LICENSE_REASON).map((reason) => (
              <MenuItem key={reason} value={reason}>
                {reason}
              </MenuItem>
            ))}
          </OutlinedInput>

          {showCustom && (
            <OutlinedInput
              ref={customReasonRef}
              onChange={handleCustomReasonChange}
              fullWidth
              label={'Reason'}
              value={customReason}
              multiline
              inputProps={{ maxLength: 100 }}
            />
          )}
        </Box>
      }
      onConfirm={extendLicense}
      onCancel={onCancel}
      onClose={onCancel}
      TransitionProps={{
        onExited: (node) => {
          TransitionProps?.onExited && TransitionProps.onExited(node);
          onExited();
        },
      }}
    />
  );
};

export default ExtendLicenseDialog;
