import { createStyles, makeStyles, Theme } from '@material-ui/core';

interface IStyleProps {
  disablePadding?: boolean;
}

const useStyles = makeStyles<Theme, IStyleProps>((theme: Theme) =>
  createStyles({
    icon: {
      width: 19,
    },
    unchecked: {
      color: theme.palette.common.black,
    },
    checkbox: {
      '&:hover': {
        backgroundColor: 'none',
      },
      '&.Mui-disabled > span > svg': {
        color: theme.palette.divider,
      },
    },
    padding: {
      padding: ({ disablePadding }) => (disablePadding ? 0 : 'auto'),
    },
  }),
);

export default useStyles;
