import React from 'react';
import { Link as RouterLink, Route, Switch, Redirect, RouteComponentProps } from 'react-router-dom';
import { observer } from 'mobx-react';
import { WithStyles, withStyles } from '@material-ui/core/styles';

import { inject, WithUserStore, WithToastStore } from 'stores';
import { paths } from 'routes';

import DashboardLayout from 'containers/DashboardLayout';

import Pending from './Pending';
import Completed from './Completed';
import styles from './styles';

import { setTitle } from 'services';
import Title from 'components/Title/Title';
import TabBar from 'components/TabBar/TabBar';
import { computed } from 'mobx';

const PAGE_TITLE = 'Sales';
interface CartsProps
  extends WithStyles<typeof styles>,
    RouteComponentProps,
    WithUserStore,
    WithToastStore {}

/**
 * The container for the sales page, displaying pending and completed sales tabs.
 */
@inject('userStore', 'toastStore')
@observer
class Carts extends React.Component<CartsProps> {
  @computed public get tabs() {
    const { pathname } = this.props.location;
    const pathPending = paths.sales().pendingCarts();
    const pathCompleted = paths.sales().completedCarts();
    return [
      {
        label: 'Pending',
        component: RouterLink,
        to: pathPending,
        selected: pathname === pathPending,
      },
      {
        label: 'Completed',
        component: RouterLink,
        to: pathCompleted,
        selected: pathname === pathCompleted,
      },
    ];
  }

  componentDidMount() {
    setTitle(PAGE_TITLE, { noSuffix: false });
  }

  render() {
    const pathPending = paths.sales().pendingCarts();
    const pathCompleted = paths.sales().completedCarts();

    return (
      <DashboardLayout>
        <Title mb={3}>{PAGE_TITLE}</Title>
        <TabBar tabs={this.tabs} />
        <Switch>
          <Route exact path={pathPending} component={Pending} />
          <Route exact path={pathCompleted} component={Completed} />
          <Route>
            <Redirect to={pathPending} />
          </Route>
        </Switch>
      </DashboardLayout>
    );
  }
}

export default withStyles(styles)(Carts);
