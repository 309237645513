/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { Link as RouterLink, RouteComponentProps } from 'react-router-dom';
import { observable, computed, action, flow, IReactionDisposer, makeObservable } from 'mobx';
import { observer } from 'mobx-react';
import { v4 as uuidv4 } from 'uuid';

import { Box, Link } from '@material-ui/core';
import { WithStyles, withStyles } from '@material-ui/core/styles';

import {
  inject,
  WithUserStore,
  WithToastStore,
  WithAnalyticsStore,
  WithSettingStore,
} from 'stores';

import { paths } from 'routes';

import { Parser } from 'json2csv';
import { AxiosResponse } from 'axios';
import Api, { ApiResponse, RequestMetaData } from 'api';
import { downloadCsvFile } from '../../../utils/helper';

import {
  states,
  adaptForDataGridPro,
  usdToNumericString,
  numericStringToUsd,
  setTitle,
} from 'services';

import { ChartData, StateRevenue } from 'models';

import DashboardLayout from 'containers/DashboardLayout';

import Chart from 'components/Chart';
import styles from '../styles';

import { Filter } from 'components/FilterBar/FilterBar';
import FilterBar from 'components/FilterBar';
import DataGridInfiniteScroll from 'components/DataGridInfiniteScroll';

import * as DateRangeExternalPicker from 'components/DateRangeExternalPicker';
import _ from 'lodash';
import Title from 'components/Title/Title';
import TabBar from 'components/TabBar/TabBar';

const PAGE_TITLE = 'Revenue';

enum Tab {
  STATE = 'state',
  LOCATION = 'location',
}

type RevenueOverviewProps = WithStyles<typeof styles> &
  WithUserStore &
  WithSettingStore &
  WithAnalyticsStore &
  RouteComponentProps &
  WithToastStore;

/**
 * Container that displays overall Tippy revenue data for a selected date
 * range using a chart and two tables, each under its own tab (states, locations).
 * Chart shows overall Tippy revenue. States table is a list of all states that
 * have any revenue for the selected date range. Locations table is a list of
 * all registered Tippy salons and their respective revenue values.
 */
@inject('userStore', 'analyticsStore', 'settingStore')
@observer
class RevenueOverview extends React.Component<RevenueOverviewProps> {
  constructor(props: RevenueOverviewProps) {
    super(props);
    makeObservable(this);

    // States revenues are not passed as a fetch function to datagrid,
    // because that data is not paginated and has no sorting or filtering
    // functionality tied to it - it has to be done on the frontend.
    // We also have to take care of the fetching logic thus we want to
    // have a reaction set up, so that when the tab changes to 'state'
    // component fetches state revenues and we pass them to states revenue table
    // this.disposers.push(
    //   reaction(
    //     () => this.selectedTab,
    //     (tab) => tab === 'state' && this.fetchRevenuesByState(),
    //   ),
    // );
  }

  /** It's good practice to dispose of any autoruns that we set up during */
  private disposers: IReactionDisposer[] = [];

  @observable private chartActive: boolean | null = null;

  /** Chart data */
  @observable private chartData?: ChartData;

  /** Indicates which tab is currently selected */
  @observable private selectedTab: 'state' | 'location' = 'state';

  /** State revenues passed to datagrid as data prop */
  @observable private revenuesByStates?: StateRevenue[] = [];

  /** The selected date range */
  @observable public dateRange: DateRangeExternalPicker.DateRange =
    this.props.settingStore!.getDate(`${this.props.location.pathname}/${this.selectedTab}`);

  /** Active filters as returned by FilterBar */
  @observable private activeFilters: Record<string, unknown> = {};

  @observable private isMobile = window.matchMedia('(max-width:680px)').matches;

  /** Watch for change on table filters and build req meta data */
  @observable private dataGridFilters = {};
  /**
   * Changes on date range value update and is used to signal
   * datagrid component to refetch the data using new date range
   */
  @computed public get dateRangeKey() {
    return JSON.stringify(this.dateRange && `${this.dateRange.fromDate}-${this.dateRange.toDate}`);
  }

  @action.bound public selectTab(tab: Tab) {
    this.dateRange = this.props.settingStore!.getDate(`${this.props.location.pathname}/${tab}`);
    this.selectedTab = tab;
    this.activeFilters = {};
    this.initChart();
  }

  @action.bound private annotateRevenues = (revenues: any) => {
    return {
      id: uuidv4(),
      ...(this.selectedTab === 'state'
        ? {
            state: states[revenues.label],
            label: revenues.label,
          }
        : {
            account: revenues.account ? revenues.account.name : '',
            name: revenues.location ? revenues.location.name : '',
            locationId: revenues.location ? revenues.location.id : null,
          }),
      gross: usdToNumericString(revenues.gross),
      net: usdToNumericString(revenues.net),
      shipping: usdToNumericString(revenues.shipping),
      subscription: usdToNumericString(revenues.subscription),
    };
  };

  @action.bound public fetchRevenues = adaptForDataGridPro(async (rmd: RequestMetaData) => {
    const data = await Api.analytics.getRevenues(this.selectedTab, {
      ...rmd,
      filters: {
        fromDate: this.dateRange.fromDate,
        toDate: this.dateRange.toDate,
        ...this.activeFilters,
      },
    });

    this.revenuesByStates = data.data.data;
    return data;
  }, this.annotateRevenues);

  /** Fetch aggregated data for tip analytics chart */
  @action.bound public fetchChartData = flow(function* (this: RevenueOverview) {
    this.chartData = undefined;
    const extraReqData = {
      fromDate: this.dateRange.fromDate,
      toDate: this.dateRange.toDate,
      ...this.activeFilters,
    };
    const resp: AxiosResponse<ApiResponse<ChartData>> = yield Api.analytics.getRevenuesChartData(
      extraReqData,
    );
    if (resp && resp.data && resp.data.data) {
      this.chartData = resp.data.data;
    }
  });

  initChart() {
    this.chartData = undefined;
    this.fetchChartData();
  }

  /** Sets the date range */
  @action.bound private updateDateRangeValue(range: DateRangeExternalPicker.DateRange) {
    this.props.settingStore!.setDate(`${this.props.location.pathname}/${this.selectedTab}`, range);
    this.dateRange = range;
    this.activeFilters = { ...this.activeFilters };
    this.initChart();
  }
  @action.bound private onChartActive() {
    this.chartActive = this.chartActive ? null : true;
  }

  /**
   * Parse filters from datagrid so we can define our request meta data
   * from: [ {key: "name", value: "digna"}, {key: "count", value: "3"}, ... ]
   * to:   [ {name: "digna", count: "3", ...} ]
   */
  @action.bound private setFilters = (filters: any) => {
    this.dataGridFilters = filters.reduce((data: any, dataGridFilter: any) => {
      return { ...data, [dataGridFilter.key]: dataGridFilter.value };
    }, {});
  };
  componentDidMount() {
    this.initChart();
    // this.fetchRevenuesByState();
    setTitle(PAGE_TITLE, { noSuffix: false });
    window.matchMedia('(max-width:680px)').addEventListener('change', (e) => {
      this.isMobile = e.matches;
    });
  }

  /** Before unmounting the component, dispose of all autoruns created */
  componentWillUnmount() {
    this.disposers.map((disposer) => disposer());
  }

  getCountryCode = (filters: Record<any, any>) => {
    if (filters.state) {
      const selectedState = _.startCase(_.toLower(filters.state as string));
      return Object.keys(states).find((key) => {
        if (states[key] === selectedState) return key;
      });
    }
  };

  /** Render state row items as links to state specific revenue */
  renderCellState = ({ row }: any) => {
    return (
      <Link component={RouterLink} to={paths.analytics().stateRevenue(row.label)}>
        {row.state}
      </Link>
    );
  };

  /** Render location row items as links to location specific revenue */
  renderCellLocation = ({ row }: any) => {
    return (
      <Link component={RouterLink} to={paths.analytics().locationRevenue(row.locationId)}>
        {row.name}
      </Link>
    );
  };

  /** Render numeric values as USD currency string representations */
  renderCellCurrency = ({ name, row }: any) => {
    return <>{numericStringToUsd(row[name])}</>;
  };

  /** Download CSV report */
  @action
  downloadCsvReport = async (reportFor?: string) => {
    const extraReqData = {
      fromDate: this.dateRange && this.dateRange.fromDate,
      toDate: this.dateRange && this.dateRange.toDate,
    };
    try {
      if (reportFor === 'state') {
        const csvFields = [
          { label: 'State', value: 'label' },
          { label: 'Gross', value: 'gross' },
          { label: 'Net', value: 'net' },
          { label: 'Subscription', value: 'subscription' },
          { label: 'Shipping', value: 'shipping' },
        ];
        const parser = new Parser({ fields: csvFields });
        //@ts-ignore
        let csv = parser.parse(this.revenuesByStates);
        const text = extraReqData.fromDate
          ? `from ${extraReqData.fromDate} to ${extraReqData.toDate}`
          : '';
        csv = `Revenue by state report ${text} \n\n${csv}`;
        downloadCsvFile(csv, 'revenue_by_state.csv');
        return;
      }
      // otherwise a report is for location
      const resp = await Api.analytics.revenues.reportByLocation(
        { filters: { ...this.activeFilters } },
        extraReqData,
      );

      if (resp && resp.data) {
        const { data: responseData, fileName } = resp.data.data;
        downloadCsvFile(responseData, fileName);
      }
    } catch (error: any) {
      this.props.toastStore!.push({
        message: 'Unable to download requested report',
        type: 'error',
      });
    }
  };

  exportElements = [
    {
      name: 'Download as CSV',
      action: () => {
        this.downloadCsvReport(this.selectedTab);
      },
    },
  ];

  commonColumns = [
    { headerName: 'Gross', field: 'gross', minWidth: 150, flex: 1 },
    { headerName: 'Net', field: 'net', minWidth: 150, flex: 1 },
    {
      headerName: 'Subscription',
      field: 'subscription',
      minWidth: 200,
      flex: 1,
    },
    { headerName: 'Shipping', field: 'shipping', minWidth: 150, flex: 1 },
  ];

  byStateColumns = [
    {
      headerName: 'State',
      field: 'state',
      minWidth: 150,
      flex: 1,
      renderCell: this.renderCellState,
    },
  ];

  byLocationColumns = [
    {
      headerName: 'Location',
      field: 'name',
      minWidth: 150,
      flex: 1,
      renderCell: this.renderCellLocation,
    },
    { headerName: 'Account', field: 'account', minWidth: 150, flex: 1 },
  ];

  @action.bound public getColumnsOptions(key: string) {
    switch (key) {
      case 'location':
        return [...this.byLocationColumns, ...this.commonColumns];
      default:
        return [...this.byStateColumns, ...this.commonColumns];
    }
  }

  /** List of available filters for FilterBar component */
  @action.bound public getFilterOptions(key: string): Filter[] {
    switch (key) {
      case 'location':
        return [
          { display: 'Account', id: 'account', label: 'Contains', type: 'text' },
          { display: 'Location', id: 'location', label: 'Contains', type: 'text' },
        ];
      default:
        return [{ display: 'State', id: 'state', label: 'Contains', type: 'text' }];
    }
  }

  @computed public get tabs() {
    return [
      {
        label: 'States',
        selected: this.selectedTab === 'state',
        onClick: () => this.selectTab(Tab.STATE),
      },
      {
        label: 'Locations',
        selected: this.selectedTab === 'location',
        onClick: () => this.selectTab(Tab.LOCATION),
      },
    ];
  }

  render() {
    return (
      <DashboardLayout>
        <>
          <Title mb={3}> {PAGE_TITLE} </Title>
          <TabBar mb={3} tabs={this.tabs} />
          <Box mt={3}>
            <FilterBar
              filters={this.getFilterOptions(this.selectedTab)}
              onChange={(filters: Record<string, unknown>) => {
                const countryCode = this.getCountryCode(filters);
                if (countryCode) {
                  filters = { ...filters, state: countryCode };
                }
                this.activeFilters = filters;
                this.initChart();
              }}
              graph={
                !this.isMobile
                  ? {
                      isActive: this.chartActive,
                      onActive: this.onChartActive,
                    }
                  : undefined
              }
              externalDateRange={{
                predefined: this.dateRange,
                onChange: this.updateDateRangeValue,
              }}
            />
          </Box>
          {this.chartActive && !this.isMobile && (
            <Chart data={this.chartData} singleYAxis singleYAxisFormat="currency" />
          )}
          <Box mt={3}>
            <DataGridInfiniteScroll
              columns={this.getColumnsOptions(this.selectedTab)}
              fetch={this.fetchRevenues}
              refetchKey={this.activeFilters}
              disableColumnMenu
              actions={{
                onExport: this.exportElements,
              }}
              pathname={this.props.location.pathname}
            />
          </Box>
        </>
      </DashboardLayout>
    );
  }
}

export default withStyles(styles)(RevenueOverview);
