import { Box, Typography } from '@material-ui/core';
import {
  IBillingEntity,
  ICorrection,
  IInvoiceProduct,
  IInvoiceProductData,
  Location,
} from 'models';
import { correctionSelectionStyling } from '../../styles';
import BillingCard from 'components/BillingCard';

interface CorrectionReviewProps {
  locationsSelected: Location[];
  correctionsList: ICorrection[];
  invoiceProducts: IInvoiceProductData[];
  totalPrice: string;
  locationPrice: (locationId: number) => string;
  selectedCard?: IBillingEntity;
}

export const CorrectionReview = ({
  locationsSelected,
  correctionsList,
  invoiceProducts,
  totalPrice,
  locationPrice,
  selectedCard,
}: CorrectionReviewProps) => {
  const getCorrection = (product: IInvoiceProduct): JSX.Element => {
    const correction = correctionsList.find((it) => it.id === product.productId);
    if (!correction) {
      return <></>;
    }

    return (
      <Typography key={`product-${product.productId}`}>
        {correction.name} {correction.price} <span className="quantity">({product.quantity}x)</span>
      </Typography>
    );
  };

  return (
    <Box sx={correctionSelectionStyling}>
      <Typography variant="body2" className="subtitle">
        Review
      </Typography>

      <Box className="locations">
        {invoiceProducts.map(({ locationId, products }) => (
          <Box className="location" key={`location-${locationId}`}>
            <Typography className="location-name">
              {locationsSelected.find((it) => it.id === locationId)?.name}
            </Typography>
            {products.map(getCorrection)}
            <Typography variant="body2">Amount: {locationPrice(locationId)}</Typography>
          </Box>
        ))}
      </Box>

      <Typography variant="body2" className="total-price">
        An amount of {totalPrice} will be charged to the billing group:
      </Typography>

      <Box marginTop={2}>{selectedCard && <BillingCard billingEntity={selectedCard} />}</Box>
    </Box>
  );
};
