import React from 'react';
import { observable, action, flow, computed, makeObservable } from 'mobx';
import { observer } from 'mobx-react';

import { inject, WithToastStore } from 'stores';
import { TextField, ThemeProvider as MuiThemeProvider } from '@mui/material';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { StaticTimePicker } from '@mui/x-date-pickers/StaticTimePicker';

import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import type {} from '@mui/x-date-pickers/themeAugmentation';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import MomentUtils from '@date-io/moment'; // Material-ui date-picker dependency
import moment from 'moment-timezone';

import NoteStore from './NoteStore';

import { WithStyles, withStyles } from '@material-ui/core/styles';
import { Box, Paper } from '@material-ui/core';

import DD from 'components/DashDrawer';

import styles, { themeTimePicker } from './styles';
import Button from 'components/Button/Button';

/** Props for this component */
interface AddReminderProps extends WithStyles<typeof styles>, WithToastStore {
  noteStore: NoteStore;
}

/**
 * Displays add reminder view.
 */
@inject('toastStore')
@observer
class AddReminder extends React.Component<AddReminderProps> {
  constructor(props: AddReminderProps) {
    super(props);
    makeObservable(this);
  }
  /** Whether the submission is currently in progress */
  @observable public submitting = false;

  /**
   * Because we're using half hour increments for reminders, default
   * date for time input is rounded up to the next full hour
   */
  @observable public selectedTime: Date = moment().add(1, 'hours').startOf('hour').toDate();

  /** Today is the default value for day input field */
  @observable public selectedDay: Date = new Date();

  /**
   * Formatted date time for api consumption.
   * It joins day and time inputs and adds EST timezone.
   */
  @computed get dateTime(): string {
    const parseDay = `${moment(this.selectedDay).format('YYYY-MM-DD')}`;
    const parseTime = `${moment(this.selectedTime).format('HH:mm:ss.SSSSSS')}`;
    const date = `${parseDay} ${parseTime}`;
    const estDate = moment.tz(date, 'America/New_York').format();
    return estDate;
  }

  /** Drawer title */
  @computed public get drawerTitle(): string {
    const day = moment.tz(this.dateTime, 'America/New_York').format('MMM DD, YYYY');
    const time = moment.tz(this.dateTime, 'America/New_York').format('h:mmA');
    return `${day} @ ${time}`;
  }

  @action.bound public handleTimeChange(date: MaterialUiPickersDate) {
    this.selectedTime = date!.toDate();
  }

  @action.bound public handleDayChange(date: MaterialUiPickersDate) {
    this.selectedDay = date!.toDate();
  }

  @action.bound public saveReminder = flow(function* (this: AddReminder) {
    try {
      this.submitting = true;
      yield this.props.noteStore.saveReminder(this.dateTime);
      this.props.toastStore!.success('Reminder set successfully');
    } finally {
      this.submitting = false;
    }
  });

  render() {
    return (
      <DD>
        <DD.Content>
          <DD.Title onBack={this.props.noteStore.goBack} loading={this.submitting}>
            {this.drawerTitle}
          </DD.Title>
          <Box display="flex" flexDirection="row" mt={2} justifyContent="center">
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <Paper>
                <DatePicker
                  autoOk
                  label="Day"
                  value={this.selectedDay}
                  onChange={this.handleDayChange}
                  format={'MMM D YYYY'}
                  variant="static"
                  disableToolbar
                  disablePast
                />
              </Paper>
            </MuiPickersUtilsProvider>
          </Box>
          <Box display="flex" flexDirection="row" justifyContent="center">
            <MuiThemeProvider theme={themeTimePicker}>
              <LocalizationProvider dateAdapter={AdapterMoment}>
              <Paper>
                <StaticTimePicker
                  value={this.selectedTime}
                  onChange={this.handleTimeChange}
                  renderInput={(props) => <TextField {...props} />}
                  toolbarTitle
                />
              </Paper>
              </LocalizationProvider>
            </MuiThemeProvider>
          </Box>
        </DD.Content>
        <DD.Actions>
          <Box>
            <Button fullWidth variant="contained" color="primary" onClick={this.saveReminder}>
              Save
            </Button>
          </Box>
        </DD.Actions>
      </DD>
    );
  }
}

export default withStyles(styles)(AddReminder);
