import { useCallback, useEffect, useMemo, useState } from 'react';
import { IBillingGeneralPanelProps } from './BillingGeneralPanel';
import { ELicenseBillingGeneralDialog, ILicenseBillingGeneralDialogProps } from './models';
import * as models from 'models';
import useAccountLicenses from './useAccountLicenses';
import { IOptionsMenuProps } from 'components/OptionsMenu/OptionsMenu';
import { useStores } from 'containers/App/App';
import _ from 'lodash';

export enum EBillingGeneralTab {
  LICENSES = 'licenses',
  BILLING_GROUPS = 'billing_groups',
}

const getDialogTitle = (type: ELicenseBillingGeneralDialog | null) => {
  switch (type) {
    case ELicenseBillingGeneralDialog.ADD_LICENSE:
      return 'Add License';
    case ELicenseBillingGeneralDialog.CANCEL_LICENSE:
      return 'Cancel License';
    case ELicenseBillingGeneralDialog.ACTIVATE_LICENSE:
      return 'Activate License';
    case ELicenseBillingGeneralDialog.CHANGE_PRODUCT:
      return 'Change Product';
    case ELicenseBillingGeneralDialog.EXTEND_LICENSE:
      return 'Extend License';
    case ELicenseBillingGeneralDialog.BILLING_GROUP_LICENSES:
      return '';
    case ELicenseBillingGeneralDialog.DELETE_BILLING_GROUP:
      return 'Delete Billing Group';
    case ELicenseBillingGeneralDialog.ADD_BILLING_GROUP:
      return 'Add License Billing Group';
    case ELicenseBillingGeneralDialog.UPDATE_BILLING_GROUP:
      return 'Update License Billing Group';
    default:
      return '';
  }
};

const useBillingGeneralPanel = ({
  accountId,
  billingEntities,
  loadingBillingEntities,
  refetchLicensesKey,
  getBillingEntities,
  refreshNextPayments,
}: Omit<IBillingGeneralPanelProps, 'sendPurchaseOrder' | 'paymentMethods'>) => {
  const { userStore } = useStores();

  const [tab, setTab] = useState<EBillingGeneralTab>(EBillingGeneralTab.LICENSES);

  const [dialogType, setDialogType] = useState<ELicenseBillingGeneralDialog | null>(null);

  const [license, setLicense] = useState<models.License | null>(null);

  const [billingEntity, setBillingEntity] = useState<models.IBillingEntity | null>(null);

  const [openDialog, setOpenDialog] = useState(false);

  const [search, setSearch] = useState('');

  useEffect(() => {
    setSearch('');
  }, [tab]);

  useEffect(() => {
    // If billing entity is set and billing entities change, re-set the existing billing entity
    if (!billingEntity) return;

    const newBillingEntity = billingEntities?.find((be) => be.id === billingEntity.id);
    if (!_.isEqual(billingEntity, newBillingEntity)) {
      newBillingEntity && setBillingEntity(newBillingEntity);
    }
  }, [billingEntity, billingEntities, setBillingEntity]);

  const { licenses, loadingLicenses, canExtendLicenses, listHeight, getLicensesForAccount } =
    useAccountLicenses({
      accountId,
      billingEntities,
      refetchLicensesKey,
      getBillingEntities,
    });

  const handleLicenseActionDialog = (
    license: models.License,
    type: ELicenseBillingGeneralDialog,
  ) => {
    setLicense(license);
    handleOpenDialogNew(type);
  };

  const handleProductChange = () => {
    getLicensesForAccount();
    refreshNextPayments();
  };

  const handleCancelLicense = () => {
    getLicensesForAccount();
    refreshNextPayments();
  };

  const handleExtendLicense = () => {
    getLicensesForAccount();
    refreshNextPayments();
  };

  const handleActivateLicense = () => {
    getLicensesForAccount();
    refreshNextPayments();
  };

  const filteredLicenses = useMemo(() => {
    return licenses.filter(({ location }) =>
      `${location.locationName || ''} | ${location?.displayName || ''}`
        .toLowerCase()
        .includes(search.toLowerCase()),
    );
  }, [search, licenses]);

  const filteredBillingGroups = useMemo(() => {
    return billingEntities.filter((billingGroup) =>
      `${billingGroup.name || ''} (${billingGroup.code})`
        .toLowerCase()
        .includes(search.toLowerCase()),
    );
  }, [search, billingEntities]);

  const billingGroupMenu: (billingEntity: models.IBillingEntity) => IOptionsMenuProps['items'] = (
    billingEntity,
  ) => {
    return [
      {
        label: 'Update',
        onClick: () =>
          handleOpenDialogNew(ELicenseBillingGeneralDialog.UPDATE_BILLING_GROUP, billingEntity),
      },
      {
        label: 'Delete',
        onClick: () =>
          handleOpenDialogNew(ELicenseBillingGeneralDialog.DELETE_BILLING_GROUP, billingEntity),
        color: 'red',
        disabled: !billingEntity.canDelete,
        tooltipTitle: 'Move all licenses to different a billing group before deleting',
      },
    ];
  };

  const handleCloseDialogLatest = () => {
    setOpenDialog(false);
  };

  const handleOpenDialogNew = (
    type: ELicenseBillingGeneralDialog,
    billingEntity?: models.IBillingEntity,
  ) => {
    setOpenDialog(true);
    setDialogType(type);
    billingEntity && setBillingEntity(billingEntity);
  };

  const handleExitDialog = useCallback(() => {
    setDialogType(null);
    billingEntity && setBillingEntity(null);
    license && setLicense(null);
  }, [billingEntity, license, setDialogType, setBillingEntity, setLicense]);

  const displayLicenses = search ? filteredLicenses : licenses;

  const displayBillingGroups = search ? filteredBillingGroups : billingEntities;

  const tabs = [
    {
      label: 'Licenses',
      onClick: () => setTab(EBillingGeneralTab.LICENSES),
      selected: tab === EBillingGeneralTab.LICENSES,
    },
    {
      label: 'Billing Groups',
      onClick: () => setTab(EBillingGeneralTab.BILLING_GROUPS),
      selected: tab === EBillingGeneralTab.BILLING_GROUPS,
    },
  ];

  const billingGeneralPanelItems = [
    {
      label: 'Add License',
      onClick: () => handleOpenDialogNew(ELicenseBillingGeneralDialog.ADD_LICENSE),
    },
    {
      label: 'Add License Billing Group',
      onClick: () => handleOpenDialogNew(ELicenseBillingGeneralDialog.ADD_BILLING_GROUP),
    },
  ];

  const renderLoader =
    (tab === EBillingGeneralTab.LICENSES && loadingLicenses) ||
    (tab === EBillingGeneralTab.BILLING_GROUPS && loadingBillingEntities);

  const renderLicenses = !renderLoader && tab === EBillingGeneralTab.LICENSES;

  const renderBillingGroups = !renderLoader && tab === EBillingGeneralTab.BILLING_GROUPS;

  const showSearch =
    (renderLicenses && !!licenses.length) || (renderBillingGroups && !!billingEntities.length);

  const dialogProps: ILicenseBillingGeneralDialogProps = useMemo(() => {
    return {
      open: openDialog,
      type: dialogType,
      title: getDialogTitle(dialogType),
      onCancel: handleCloseDialogLatest,
      TransitionProps: {
        onExited: handleExitDialog,
      },
    };
  }, [openDialog, dialogType, handleExitDialog]);

  const isAdmin = userStore.isAdmin;

  return {
    tabs,
    search,
    isAdmin,
    showSearch,
    dialogProps,
    license,
    licenses,
    displayBillingGroups,
    renderLicenses,
    renderBillingGroups,
    renderLoader,
    listHeight,
    displayLicenses,
    canExtendLicenses,
    billingEntity,
    billingGeneralPanelItems,
    setSearch,
    setBillingEntity,
    handleOpenDialogNew,
    handleCloseDialog: handleCloseDialogLatest,
    handleProductChange,
    getLicensesForAccount,
    handleLicenseActionDialog,
    billingGroupMenu,
    handleCancelLicense,
    handleExtendLicense,
    handleActivateLicense,
  };
};

export default useBillingGeneralPanel;
