import { FontAwesomeIcon, FontAwesomeIconProps } from '@fortawesome/react-fontawesome';
import { Box, BoxProps } from '@material-ui/core';

type TWrappedFontAwesomeIconWrapper = Pick<React.CSSProperties, 'width' | 'height'> &
  BoxProps & {
    icon: FontAwesomeIconProps['icon'];
  };

const WrappedFontAwesomeIconWrapper = ({ icon, ...boxProps }: TWrappedFontAwesomeIconWrapper) => {
  return (
    <Box {...boxProps}>
      <FontAwesomeIcon icon={icon} style={{ height: '100%' }} />
    </Box>
  );
};

export default WrappedFontAwesomeIconWrapper;
