import React from 'react';
import { observable, action, computed, flow, makeObservable } from 'mobx';
import { observer } from 'mobx-react';
import { WithStyles, withStyles } from '@material-ui/core/styles';

import Api, { getErrorMsg } from 'api';

import { Box, Dialog, Tooltip, Typography } from '@material-ui/core';
import { AlertOutline, CheckCircleOutline, CircleOutline } from 'mdi-material-ui';

import DP from 'components/DashPanel';
import { PaymentMethod, IBillingEntity, PaymentMethodAction } from 'models';
import { inject, WithToastStore, WithModalStore } from 'stores';
import { createStripe } from 'services/stripe';

import styles, { useStyles } from './styles';

import AddPaymentMethod from './AddPaymentMethod';
import CreditCardIcon from 'components/CreditCardIcon';
import moment from 'moment';
import { EmptyPanelMessage } from 'components/EmptyPanelMessage/EmptyPanelMessage';
import { faPlus } from '@fortawesome/pro-regular-svg-icons';
import { EDateFormat, isPaymentMethodExpired } from 'utils/helper';
import theme from 'containers/App/theme';
import { IOptionsMenuProps } from 'components/OptionsMenu/OptionsMenu';

/** Represents a payment method in the list */
export const PaymentMethodItem = observer(
  ({
    children,
    itemProps,
    removePaymentMethod,
    sendUpdateRequest,
    isAdmin,
    flexSecondary,
  }: {
    flexSecondary?: boolean;
    children: PaymentMethod;
    itemProps?: ItemProps;
    isAdmin?: boolean;
    removePaymentMethod?: (paymentMethodId: number) => void;
    sendUpdateRequest?: (paymentMethodId: number) => void;
  }) => {
    const classes = useStyles();

    const paymentMethod = children;

    const needsUpdate = !!paymentMethod?.update?.needsUpdate;
    const updateMessage = paymentMethod?.update?.message || '';
    const canDelete = !!paymentMethod?.delete?.canDelete;
    const message = paymentMethod?.delete?.message || '';

    const menu: IOptionsMenuProps['items'] = [
      {
        label: 'Remove',
        color: 'red',
        disabled: !canDelete,
        tooltipTitle: message || '',
        onClick: () => removePaymentMethod && removePaymentMethod(paymentMethod.id),
      },
    ];

    if (isAdmin && needsUpdate && sendUpdateRequest) {
      menu.push({
        label: 'Send update request',
        onClick: () => sendUpdateRequest(paymentMethod.id),
      });
    }

    const brand = paymentMethod.brand || 'Other';
    const lastFour = paymentMethod.lastFour;
    const validThru = paymentMethod.validThru;
    const formattedAddDate = moment(paymentMethod.createdAt).format(EDateFormat.DATE_LONG);
    const isExpired = isPaymentMethodExpired(paymentMethod);
    const secondaryText = `${isExpired ? 'Expired' : 'Expires'} on ${validThru}`;
    const addDate = `Added on ${formattedAddDate}`;

    return (
      <DP.ListItem
        key={paymentMethod.id}
        icon={
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            style={{ fontSize: '21px' }}>
            <CreditCardIcon brand={brand.toLowerCase()} />
          </Box>
        }
        primary={
          <Box display="flex" flexDirection="row">
            <Typography>
              {brand} {lastFour}
            </Typography>
            {needsUpdate && (
              <Box ml={1} mr={1} display="flex" flexDirection="row" alignItems="center">
                <Tooltip title={updateMessage || ''}>
                  <AlertOutline fontSize="small" color="error" />
                </Tooltip>
              </Box>
            )}
          </Box>
        }
        secondary={
          <Typography variant="subtitle1" color={isExpired ? 'error' : undefined}>
            {secondaryText}
          </Typography>
        }
        rightContent={
          <Typography
            variant="subtitle1"
            style={{ fontStyle: 'italic', paddingRight: theme.spacing(2) }}>
            {addDate}
          </Typography>
        }
        className={
          flexSecondary ? { containerSecondary: classes.listTextSecondaryFlex } : undefined
        }
        menu={itemProps && itemProps?.hideMenu !== true ? menu : undefined}
        rightIcon={
          itemProps &&
          itemProps!.selected &&
          (itemProps!.selected === paymentMethod.id ? (
            <CheckCircleOutline color="primary" />
          ) : (
            <CircleOutline color="primary" />
          ))
        }
        onClick={
          itemProps && itemProps!.onItemClick
            ? () => {
                itemProps!.onItemClick!(paymentMethod);
              }
            : undefined
        }
      />
    );
  },
);

interface ItemProps {
  selected?: number;
  hideMenu?: boolean;
  rightIcon?: JSX.Element;
  onItemClick?(paymentMethod: PaymentMethod): void;
}

interface PaymentMethodsPanelProps
  extends WithStyles<typeof styles>,
    WithToastStore,
    WithModalStore {
  accountId: number;
  panelName?: string;
  hideCount?: boolean;
  hidePaper?: boolean;
  itemProps?: ItemProps;
  onAdd?: (sourceId: string) => void;
  paymentMethods: PaymentMethod[];
  isAdmin?: boolean;
  refetchPaymentMethods?: VoidFunction;
}

@inject('toastStore', 'modalStore')
@observer
class PaymentMethodsPanel extends React.Component<PaymentMethodsPanelProps> {
  constructor(props: PaymentMethodsPanelProps) {
    super(props);
    makeObservable(this);
  }
  private panelName = 'Payment Methods';

  @observable public inProgress = false;

  @observable public billingEntitiesResp?: IBillingEntity[];

  @computed private get paymentMethods() {
    return this.props.paymentMethods || [];
  }

  @observable public stripeLoaded = false;

  @observable public showingAddPaymentMethodDialog = false;

  @action.bound public showAddPaymentMethod() {
    this.showingAddPaymentMethodDialog = true;
  }

  @action.bound public hideAddPaymentMethod() {
    this.showingAddPaymentMethodDialog = false;
  }

  @action.bound public setStripeLoaded() {
    this.stripeLoaded = true;
  }

  @action.bound public addPaymentMethod = flow(function* (
    this: PaymentMethodsPanel,
    sourceId: string,
  ) {
    const accountId = this.props.accountId;

    try {
      yield Api.billing.addPaymentMethod(sourceId, accountId);
      this.props.onAdd && this.props.onAdd(sourceId);
      this.hideAddPaymentMethod();
      this.props.toastStore!.success('Payment method successfully added');
    } catch (e: any) {
      this.props.toastStore!.error(getErrorMsg(e));
    } finally {
      this.hideAddPaymentMethod();
    }
  });

  @action.bound public removePaymentMethod = flow(function* (
    this: PaymentMethodsPanel,
    billingEntityId: number,
  ) {
    const accountId = this.props.accountId;
    try {
      const confirm = yield this.props.modalStore!.confirm(
        'Remove Payment Method',
        `Are you sure you want to remove this payment method?`,
        { confirmLabel: 'Yes', cancelLabel: 'No' },
      );
      if (confirm) {
        yield Api.billing.removePaymentMethod(accountId, billingEntityId);
        this.props.refetchPaymentMethods && this.props.refetchPaymentMethods();
        this.billingEntitiesResp = this.billingEntitiesResp!.filter(
          (method) => method.id !== billingEntityId,
        );
        this.props.toastStore!.success('Payment method successfully removed');
      }
    } catch (e: any) {
      this.props.toastStore!.error(getErrorMsg(e));
    }
  });

  @action.bound public sendUpdateRequest = flow(function* (
    this: PaymentMethodsPanel,
    billingEntityId: number,
  ) {
    const accountId = this.props.accountId;
    try {
      const confirm = yield this.props.modalStore!.confirm(
        'Send an email to owner',
        `Are you sure you want to send an email to edit this payment method?`,
        { confirmLabel: 'Send', cancelLabel: 'Cancel' },
      );
      if (confirm) {
        yield Api.billing.updateCreditCard(accountId, billingEntityId);
        this.props.refetchPaymentMethods && this.props.refetchPaymentMethods();
        this.props.toastStore!.success('Email successfully sent');
      }
    } catch (e: any) {
      this.props.toastStore!.error(getErrorMsg(e));
    }
  });

  @computed private get count() {
    const hideCount = this.props.hideCount || false;
    if (hideCount) {
      return undefined;
    }

    return this.paymentMethods.length;
  }

  componentDidMount() {
    this.panelName = this.props.panelName || this.panelName;

    createStripe().then(this.setStripeLoaded);
  }

  render() {
    const { hidePaper = false, itemProps } = this.props;
    const height = this.paymentMethods.length < 5 ? 'short' : 'normal';
    return (
      <>
        <Dialog open={this.showingAddPaymentMethodDialog} onClose={this.hideAddPaymentMethod}>
          {this.stripeLoaded && (
            <AddPaymentMethod
              onSourceId={this.addPaymentMethod}
              onCancel={this.hideAddPaymentMethod}
              method={PaymentMethodAction.ADD}
            />
          )}
        </Dialog>
        <DP hidePaper={hidePaper}>
          <DP.Header>
            <DP.Title count={this.count} light>
              {this.panelName}
            </DP.Title>
            <DP.Actions>
              {this.props.onAdd && (
                <DP.IconButton
                  fontAwesomeIcon={{
                    icon: faPlus,
                    fontSize: 20,
                  }}
                  primary
                  tooltip="Add payment method"
                  onClick={this.showAddPaymentMethod}
                />
              )}
            </DP.Actions>
          </DP.Header>

          {this.inProgress ? (
            <DP.List>
              <DP.Loading items={3} variant="circleWithTwoLines" />
            </DP.List>
          ) : this.paymentMethods?.length > 0 ? (
            <Box>
              <DP.List height={height}>
                {this.paymentMethods.map((paymentMethod) => (
                  <PaymentMethodItem
                    key={paymentMethod.id}
                    itemProps={itemProps || {}}
                    removePaymentMethod={this.removePaymentMethod}
                    sendUpdateRequest={this.sendUpdateRequest}
                    isAdmin={this.props.isAdmin}>
                    {paymentMethod}
                  </PaymentMethodItem>
                ))}
              </DP.List>
            </Box>
          ) : (
            <DP.Body>
              <Box display={'flex'} alignContent={'center'}>
                <EmptyPanelMessage panelTitle={this.panelName} />
              </Box>
            </DP.Body>
          )}
        </DP>
      </>
    );
  }
}

export default withStyles(styles)(PaymentMethodsPanel);
