import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
  Typography,
  WithStyles,
} from '@material-ui/core';
import { getErrorMsg } from 'api';
import LoadingSpinner from 'components/LoadingSpinner';
import { inject } from 'mobx-react';
import React from 'react';
import { WithToastStore } from 'stores';
import Api from 'api';
import validatorjs from 'validatorjs';
import { isCurrency } from 'services/validators';
import { makeObservable, observable } from 'mobx';
import { observer } from 'mobx-react';
import OutlinedInput from 'components/Input/OutlinedInput';
import Button from 'components/Button/Dialog/Button';
import { withStyles } from '@material-ui/styles';
import styles from './styles';

type MobxForm = any;

// eslint-disable-next-line @typescript-eslint/no-var-requires
const dvr = require('mobx-react-form/lib/validators/DVR');
const MobxReactForm = require('mobx-react-form').default;

/** Definition of form fields for create correction modal form */
const fields = [
  {
    name: 'amount',
    label: 'Correction Amount',
    rules: ['required', 'currency'],
    hooks: {
      onChange: (field: any) => {
        const prefix = '-';
        const delimiter = '.';
        const inputString = field.value;

        // Allowed input characters are numbers, minus sign, and the dot
        const lastChar = inputString[inputString.length - 1];
        const isValidChar = new RegExp(/^[0-9.-]$/).test(lastChar);
        if (!isValidChar) {
          field.set(inputString.slice(0, -1));
        }
        // Negative prefix can only appear as the first character and only once
        const indexOfPrefix = inputString.indexOf(prefix);
        const prefixCount = inputString.split(prefix).length - 1;
        if ((inputString.includes(prefix) && indexOfPrefix !== 0) || prefixCount > 1) {
          field.set(inputString.slice(0, -1));
        }
        // Dot decimal delimiter cannot appear twice
        const delimiterCount = inputString.split(delimiter).length - 1;
        if (delimiterCount > 1) {
          field.set(inputString.slice(0, -1));
        }
        // There can only be two digits behind a dot decimal delimiter
        const indexOfDelimiter = inputString.indexOf(delimiter);
        if (inputString.includes(delimiter) && indexOfDelimiter < inputString.length - 3) {
          field.set(inputString.slice(0, -1));
        }
      },
    },
  },
  {
    name: 'customReason',
    label: 'Your Note for Correction',
    rules: ['required'],
    hooks: {
      onChange: (field: any) => field.validate(),
    },
  },
];

const plugins = {
  dvr: dvr({
    package: validatorjs,
    extend: ({ validator }: { validator: any }) => {
      validator.register('currency', isCurrency, 'Please enter a valid dollar amount (e.g. -9.32)');
    },
  }),
};

interface FormHooks {
  onSuccess: (form: MobxForm) => void;
  onClear: (form: MobxForm) => void;
}

type PartnerWalletCorrectionModalProps = WithToastStore &
  WithStyles & {
    open: boolean;
    close: () => void;
    onCorrectionSuccess: () => void;
    walletId?: number;
  };

@inject('toastStore')
@observer
class PartnerWalletCorrectionModal extends React.Component<PartnerWalletCorrectionModalProps> {
  constructor(props: PartnerWalletCorrectionModalProps) {
    super(props);
    makeObservable(this);
    this.form = new MobxReactForm({ fields }, { plugins, hooks: this.hooks });
  }

  @observable private form: MobxForm;
  @observable private submitting = false;

  private hooks: FormHooks = {
    onSuccess: () => this.submitCorrection(),
    onClear: () => {
      this.form.reset();
      this.props.close();
    },
  };

  /** Submits the correction form */
  async submitCorrection() {
    try {
      const walletId = this.props.walletId;
      if (!walletId) return;
      this.submitting = true;
      const amount = this.form.$('amount').value;
      const reason = this.form.$('customReason').value;
      await Api.tips.createWalletCorrection(walletId!, amount, reason);
      this.props.toastStore!.push({
        type: 'success',
        message: `Correction for ${amount} dollars was successfully applied`,
      });
      this.props.onCorrectionSuccess();
    } catch (e: any) {
      this.props.toastStore!.error(getErrorMsg(e));
    } finally {
      this.form.reset();
      this.props.close();
      this.submitting = false;
    }
  }

  render() {
    const fields = {
      amount: this.form.$('amount'),
      customReason: this.form.$('customReason'),
    };
    const { dialogActions, dialogContent, dialogTitle } = this.props.classes;
    return (
      <Dialog open={this.props.open}>
        <form onSubmit={this.form.onSubmit}>
          <Box minWidth={380}>
            <DialogTitle className={dialogTitle}>
              <Box display="flex" flexDirection="row" justifyContent="space-between">
                <Typography style={{ fontSize: 28, fontWeight: 400 }}>Create Correction</Typography>
                {this.submitting && <LoadingSpinner size={24} />}
              </Box>
            </DialogTitle>
            <DialogContent className={dialogContent}>
              <Box>
                <OutlinedInput
                  {...fields.amount.bind()}
                  error={fields.amount.error}
                  fullWidth
                  autoFocus
                  InputProps={{
                    startAdornment: '$',
                  }}
                />
                <Box mt={2}>
                  <OutlinedInput
                    placeholder="Reason for refund"
                    {...fields.customReason.bind()}
                    error={fields.customReason.error}
                    multiline
                    rows={1}
                    rowsMax={1000}
                    fullWidth
                  />
                </Box>
              </Box>
            </DialogContent>
            <DialogActions className={dialogActions}>
              <Button onClick={this.form.onClear}>Cancel</Button>
              <Button
                type="submit"
                variant={'contained'}
                disabled={!this.form.isValid}>
                Create
              </Button>
            </DialogActions>
          </Box>
        </form>
      </Dialog>
    );
  }
}

export default withStyles(styles)(PartnerWalletCorrectionModal);
