import { Theme, createStyles } from '@material-ui/core';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default (theme: Theme) =>
  createStyles({
    brandLogo: {
      maxHeight: '30px',
    },
    statusIcon: {
      fontSize: '200px',
    },
    button: {
      padding: 0,
      color: theme.palette.text.secondary,
      fontSize: theme.typography.body1.fontSize,
      '&:hover': {
        backgroundColor: 'transparent'
      }
    },
    title: {
      fontSize: theme.spacing(5),
      color: theme.palette.text.primary,
    },
  });
