import { Drawer } from '@material-ui/core';
import * as models from 'models';
import DD from 'components/DashDrawer';
import SkeletonOrComponent from 'components/SkeletonOrComponent';
import { formatDateTimeInEST, humanize } from 'utils/helper';
import useStyles from './styles';

interface ITipDetailsDrawer {
  tip?: models.Tip;
  onClose: () => void;
}

const TipDetailsDrawer = ({ tip, onClose }: ITipDetailsDrawer) => {
  const { skeleton } = useStyles();

  const labels = ['gross', 'net', 'serviceAmount', 'level', 'source', 'createdAt'];

  let values: Array<{ label: string; value: string }> = Object.entries(tip || {})?.map(
    ([key, value]) => {
      if (key === 'createdAt') return { label: key, value: formatDateTimeInEST(value) };

      if (['gross', 'net', 'serviceAmount'].includes(key))
        return { label: key, value: `$${value}` };

      return { label: key, value };
    },
  );
  values = values?.filter(({ label, value }) => labels.includes(label));

  const formatLabel = (label: string) => {
    return humanize(label.split(/(?=[A-Z])/).join(' '));
  };

  return (
    <Drawer open={Boolean(tip)} onClose={onClose} anchor="right" variant="temporary">
      <DD>
        <DD.Title padding showDivider onClose={onClose}>
          Tip details
        </DD.Title>
        <DD.Content>
          {labels.map((label, index) => {
            const value1 = values[index * 2];
            const value2 = values[index * 2 + 1];
            return (
              <DD.Row key={index}>
                {value1 && (
                  <DD.Item>
                    <DD.Value>
                      <SkeletonOrComponent className={skeleton} rangeWidth={[80, 120]}>
                        {value1.value}
                      </SkeletonOrComponent>
                    </DD.Value>
                    <DD.Label>{formatLabel(value1.label)}</DD.Label>
                  </DD.Item>
                )}

                {value2 && (
                  <DD.Item>
                    <DD.Value>
                      <SkeletonOrComponent className={skeleton} rangeWidth={[80, 120]}>
                        {value2.value}
                      </SkeletonOrComponent>
                    </DD.Value>
                    <DD.Label>{formatLabel(value2.label)}</DD.Label>
                  </DD.Item>
                )}
              </DD.Row>
            );
          })}
        </DD.Content>
      </DD>
    </Drawer>
  );
};

export default TipDetailsDrawer;
