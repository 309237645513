import { createTheme } from '@mui/material';
import { _colors } from './theme';
import { mobileSize } from 'components/MobileView/useMobileView';

const defaultTheme = createTheme({ spacing: 8 });
const themeNew = createTheme({
  spacing: 8,
  palette: {
    common: {
      black: _colors.tippyBlack.main,
    },
    primary: {
      dark: _colors.tippyGreen.dark,
      light: _colors.tippyGreen.light,
      main: _colors.tippyGreen.main,
      contrastText: '#fff',
      50: '#8CECDB',
      100: '#76E8D3',
      200: '#5FE4CC',
      300: '#48E1C5',
      400: '#31DDBD',
      500: '#1AD9B6',
      600: '#17C3A4',
      700: '#15AE92',
      800: '#14A388',
      900: '#118D76',
    },
    text: {
      primary: _colors.tippyDeepPurple.main,
      secondary: _colors.tippyBlack.main,
      disabled: _colors.tippyBlack.light,
    },
    status: {
      red: _colors.tippyRed.main,
      green: _colors.tippyGreen.main,
      yellow: _colors.tippyYellow.main,
      orange: _colors.tippyOrange.main,
      blue: _colors.tippyBlue.main,
      grey: _colors.tippyBlack.main,
      purple: _colors.tippyPurple.main,
    },

    disabled: _colors.tippyBlack.light,
  },
  typography: {
    fontFamily: 'EuclidCircularB',
    fontSize: 16,
    body1: {
      fontSize: 16,
      color: _colors.tippyDeepPurple.main,
    },
    body2: {
      fontSize: 16,
      color: _colors.tippyBlack.main,
    },
    body3: {
      fontSize: 16,
      color: _colors.tippyBlack.light,
    },
    subtitle1: {
      fontSize: 14,
      color: _colors.tippyBlack.main,
    },
    subtitle2: {
      fontSize: 14,
      color: _colors.tippyBlack.veryLight,
    },
    table: {
      fontWeight: 600,
      color: _colors.tippyDeepPurple.main,
    },
    page: {
      fontSize: 34,
      fontWeight: 600,
      color: _colors.tippyDeepPurple.main,
      [defaultTheme.breakpoints.down(mobileSize)]: {
        fontSize: 24,
      },
    },
    dialog: {
      fontSize: 28,
      color: _colors.tippyDeepPurple.main,
    },
    panel: {
      fontSize: 20,
      color: _colors.tippyBlack.main,
    },
    signup: {
      fontSize: 40,
      color: _colors.tippyDeepPurple.main,
      [defaultTheme.breakpoints.down(mobileSize)]: {
        fontSize: 32,
      },
    },
  },
  components: {
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          page: 'h1',
          body3: 'p',
        },
      },
    },
    MuiPaper: {
      defaultProps: {
        elevation: 0,

        style: {
          borderRadius: 16,
        },
      },
    },
  },
});

export default themeNew;
