import { Dialog, Link, Grid } from '@material-ui/core';
import { WithStyles, withStyles } from '@material-ui/core/styles';
import Api, { RequestMetaData } from 'api';
import { action, observable, makeObservable } from 'mobx';
import { observer } from 'mobx-react';
import { Account, Conversion, ConversionStatus } from 'models';
import moment from 'moment';
import React from 'react';
import { Link as RouterLink, RouteComponentProps } from 'react-router-dom';
import { paths } from 'routes';
import { numericStringToUsd } from 'services/currency';
import { adaptForDataGridPro } from 'services/datagrid';
import { inject, WithModalStore, WithToastStore, WithUserStore, WithSettingStore } from 'stores';
import CreateConversionModal from './CreateConversionModal';
import styles from './styles';
import * as DateRangeExternalPicker from 'components/DateRangeExternalPicker';
import { Filter } from 'components/FilterBar/FilterBar';
import FilterBar from 'components/FilterBar';
import DataGridInfiniteScroll from 'components/DataGridInfiniteScroll';
import PlusFabButton from 'components/PlusFabButton/PlusFabButton';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid-pro';
import { DATE_TYPE, EDateFormat, formatDateTimeInEST } from 'utils/helper';
import { renderConversionCellStatus } from 'services';

interface ConversionsTableProps
  extends WithStyles<typeof styles>,
    WithModalStore,
    WithToastStore,
    WithSettingStore,
    WithUserStore,
    RouteComponentProps {
  conversions?: Conversion[];
}

/**
 * Annotates conversion with extra data
 * */
function annotateConversion(conversion: Conversion) {
  const { affiliate, location } = conversion;

  let affiliateName = undefined;
  let organizationName = undefined;

  if (affiliate) {
    affiliateName = `${affiliate.user.firstName}  ${affiliate.user.lastName}`;
    const region = affiliate.region;
    if (region) {
      const organization = region.organization;
      if (organization) {
        organizationName = organization.name;
      }
    }
  }

  const locationName: string = location?.name;
  const status: string = conversion.status.replace(/_/gi, ' ').toUpperCase();
  const createdAt: string = moment(conversion.createdAt).format(EDateFormat.DEFAULT);
  const account: Account | undefined = conversion.location?.account;

  return {
    ...conversion,
    affiliateName,
    organizationName,
    locationName,
    accountName: account?.name,
    accountId: account?.id,
    status,
    statusType: conversion.status,
    amount: conversion.amount ? numericStringToUsd(conversion.amount) : '',
    createdAt,
  };
}

/**
 * Displays a paginated, searchable list of conversions
 */
@inject('modalStore', 'toastStore', 'userStore', 'settingStore')
@observer
class ConversionsTable extends React.Component<ConversionsTableProps> {
  constructor(props: ConversionsTableProps) {
    super(props);
    makeObservable(this);
  }

  /** Active filters as returned by FilterBar */
  @observable private activeFilters: Record<string, unknown> = {};

  /** The selected date range */
  @observable public dateRange: DateRangeExternalPicker.DateRange =
    this.props.settingStore!.getDate(this.props.location.pathname);

  /** Is refund modal open?  */
  @observable public createConversionModalOpen = false;

  @action.bound private toggleCreateConversionModal() {
    this.createConversionModalOpen = !this.createConversionModalOpen;
  }

  public fetchConversionsData = adaptForDataGridPro(
    async (rmd: RequestMetaData) =>
      await Api.marketing.getConversions({
        ...rmd,
        filters: {
          fromDate: this.dateRange.fromDate,
          toDate: this.dateRange.toDate,
          ...this.activeFilters,
        },
      }),
    annotateConversion,
  );

  /** Sets the date range */
  @action.bound private updateDateRangeValue(range: DateRangeExternalPicker.DateRange) {
    this.props.settingStore!.setDate(this.props.location.pathname, range);
    this.dateRange = range;
    this.activeFilters = { ...this.activeFilters };
  }

  renderCellAffiliate = ({ value, row }: GridRenderCellParams) => (
    <Link component={RouterLink} to={paths.userDetails(row.affiliate?.userId).root()}>
      {value}
    </Link>
  );

  renderCellAccount = ({ value, row }: GridRenderCellParams) => (
    <Link component={RouterLink} to={paths.accountDetails(row.accountId).root()}>
      {value}
    </Link>
  );

  gridColumns: GridColDef[] = [
    {
      headerName: 'Created On',
      field: 'createdAt',
      minWidth: 150,
      flex: 1,
      valueGetter: ({ value }) => formatDateTimeInEST(value, DATE_TYPE.DATE),
    },
    { headerName: 'Amount', field: 'amount', minWidth: 120, flex: 1 },
    {
      headerName: 'Affiliate',
      field: 'affiliateName',
      minWidth: 150,
      flex: 1,
      renderCell: this.renderCellAffiliate,
    },
    { headerName: 'Organization', field: 'organizationName', minWidth: 180, flex: 1 },
    {
      headerName: 'Account',
      field: 'accountName',
      minWidth: 200,
      flex: 1,
      renderCell: this.renderCellAccount,
    },
    { headerName: 'Location', field: 'locationName', minWidth: 200, flex: 1 },
    {
      headerName: 'Status',
      field: 'status',
      minWidth: 200,
      flex: 1,
      renderCell: renderConversionCellStatus,
    },
  ];

  filters: Filter[] = [
    { display: 'Affiliate', id: 'affiliateName', label: 'Contains', type: 'text' },
    { display: 'Organization', id: 'organizationName', label: 'Contains', type: 'text' },
    { display: 'Account', id: 'accountName', label: 'Contains', type: 'text' },
    { display: 'Location', id: 'locationName', label: 'Contains', type: 'text' },
    {
      display: 'Status',
      id: 'status',
      label: 'One of',
      type: 'select',
      items: [
        { label: 'PENDING LICENSE PAYMENT', value: ConversionStatus.PENDING_LICENSE_PAYMENT },
        { label: 'COUNTING TIPS', value: ConversionStatus.COUNTING_TIPS },
        { label: 'READY FOR PAYMENT', value: ConversionStatus.READY_FOR_PAYMENT },
        { label: 'PAID', value: ConversionStatus.PAID },
        { label: 'THRESHOLD REACHED', value: ConversionStatus.THRESHOLD_REACHED },
        { label: 'THRESHOLD NOT REACHED', value: ConversionStatus.THRESHOLD_NOT_REACHED },
      ],
    },
  ];

  render() {
    return (
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Dialog open={this.createConversionModalOpen} onClose={this.toggleCreateConversionModal}>
            <CreateConversionModal closeModal={this.toggleCreateConversionModal} />
          </Dialog>
          <FilterBar
            filters={this.filters}
            onChange={(filters: Record<string, unknown>) => {
              this.activeFilters = filters;
            }}
            externalDateRange={{
              predefined: this.dateRange,
              onChange: this.updateDateRangeValue,
            }}
          />
          <DataGridInfiniteScroll
            columns={this.gridColumns}
            fetch={this.fetchConversionsData}
            refetchKey={this.activeFilters}
            sortByField="createdAt"
            sortDirection="DESC"
            disableColumnMenu
            pathname={this.props.location.pathname}
          />
          <PlusFabButton onClick={this.toggleCreateConversionModal} />
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(ConversionsTable);
