import React from 'react';
import { Box, Switch } from '@material-ui/core';
import theme from 'containers/App/theme';
import useToggleIntegrationButton from './useToggleIntegrationButton';
import { Account, IntegrationApp } from 'models';

interface IToggleIntegrationButton {
  app: IntegrationApp;
  account: Account;
}

export const ToggleIntegrationButton = ({ app, account }: IToggleIntegrationButton) => {
  const [integrationActive, buttonText, displayButton, handleIntegrationButtonChange] =
    useToggleIntegrationButton(app, account);

  return (
    <Box
      pb={displayButton ? 0 : 2}
      style={{ color: theme.palette.grey[500] }}
      component={'span'}
      display={'flex'}
      alignItems="center"
      justifyContent="center">
      {displayButton && <Switch
        color="primary"
        onChange={handleIntegrationButtonChange}
        checked={integrationActive}
        inputProps={{ 'aria-label': 'secondary checkbox' }}
      />}
      {buttonText}
    </Box>
  );
};
