import { Theme, createStyles } from '@material-ui/core';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default (theme: Theme) =>
  createStyles({
    root: {},
    institutionLogo: {
      width: theme.spacing(3),
      height: theme.spacing(3),
      marginRight: theme.spacing(2),
    },
    status: {
      textTransform: 'capitalize',
      color: theme.palette.primary.main,
    },
    headerTitle: {
      fontSize: theme.typography.h6.fontSize,
      fontWeight: 400,
      lineHeight: '20.29px',
    },
    primaryChip: {},
    verifyButton: {
      width: 'auto !important',
      marginRight: theme.spacing(2),
    },
  });
