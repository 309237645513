/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { observer } from 'mobx-react';
import { action, observable, computed, makeObservable } from 'mobx';

import { Location, Role } from 'models';
import * as UserStore from 'stores/UserStore';

import { WithStyles, withStyles } from '@material-ui/core/styles';

import { ReactMultiEmail } from 'react-multi-email';
import { Box, FormGroup, FormControlLabel, Typography, Chip } from '@material-ui/core';

import DP from 'components/DashPanel';
import LocationSearch from 'components/LocationSearch';

import styles from './styles';
import { Checkbox } from 'components/Checkbox/Checkbox';
import Button from 'components/Button/Button';
import { faXmarkCircle } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface InvitePanelProps extends WithStyles<typeof styles> {
  scope: UserStore.Scope;
  location: Location | null;
  onSubmit: (
    emails: string[],
    selectedRoles: Role[],
    location: Location | null,
  ) => Promise<{ error: boolean }>;
}

/** Role checkboxes that will be selected by default */
const selectedRolesInitial: Role[] = ['talent'];

/**
 * Component for sending email invitation to one or more potential Tippy users.
 * Available to admin, owner or manager scope.
 * Includes three form fields: (1) Role checkbox set (2) Location and (3) Emails.
 * Managers don’t see the Role checkboxes, their invites can only create talent roles.
 * For owners, the Talent role checkbox is checked by default. At least one role must be selected.
 * Location select field is also only available to owners. Managers have the location
 * pre-selected to their managed location and can’t change it.
 * The email field accepts multiple email address strings. Paste functionality for this field
 * works as expected and will remove any unwanted characters like comma delimiters.
 *
 * @param scope scope object needed for render logic and accountId if scope kind is 'owner'
 * @param location Optional needed for render logic and locationId if scope kind is 'manager'
 * @param onSubmit form submit callback function, accepts locationId and array of email strings
 */
@observer
class InvitePanel extends React.Component<InvitePanelProps> {
  constructor(props: InvitePanelProps) {
    super(props);
    makeObservable(this);
  }
  /** Is component waiting for onSubmit response? */
  @observable private inProgress = false;

  /** Array of invitation destination emails */
  @observable private emails: string[] = [];

  /** Array of invitation destination emails */
  @observable private selectedRoles: Role[] = selectedRolesInitial;

  /** To which location should these invitation apply? */
  @observable private location: Location | null = this.props.location;

  /** Users scope type */
  @computed private get scopeType(): UserStore.ScopeType {
    return this.props.scope.kind;
  }

  /**
   * If users scope is 'owner', this gets passed to LocationSearch component.
   * Admins can fetch all locations and managers don't see LocationSearch component.
   */
  @computed private get accountId(): number | undefined {
    if (this.props.scope.kind === 'owner') {
      return this.props.scope.accountId;
    }
    return undefined;
  }

  /** Is submit button enabled?  */
  @computed private get formValid(): boolean {
    // Admins need to have a location selected for form to be valid
    if (this.scopeType === 'admin' && Boolean(this.location) && this.emails.length > 0) {
      return true;
    }
    // Owners do not and invitations are account based (owned account)
    // if no location is selected. Managers can only send location
    // based (their managed location) invitations
    if (this.scopeType !== 'admin' && this.emails.length > 0) {
      return true;
    }
    return false;
  }

  /** LocationSearch components onChange handler */
  @action.bound private handleLocationChange(l: Location | null) {
    this.location = l;
  }

  /** ReactMultiEmail components onChange handler */
  @action.bound private updateEmailList(emails: string[]) {
    this.emails = emails;
  }

  /** Role checkbox set onChange handler */
  @action.bound private toggleRole(e: any, checked: boolean) {
    const role = e.target.name;
    if (checked) {
      this.selectedRoles.push(role);
    } else if (!checked && this.selectedRoles.length > 1) {
      this.selectedRoles = this.selectedRoles.filter((r: Role) => r !== role);
    }
  }

  /** Set form fields to initial state */
  @action.bound private resetForm() {
    this.selectedRoles = selectedRolesInitial;
    this.location = this.props.location;
    this.emails = [];
  }

  /** Invitation submit handler, calls onSubmit callback prop function */
  @action.bound public submit = async () => {
    try {
      this.inProgress = true;
      const resp = await this.props.onSubmit(this.emails, this.selectedRoles, this.location);
      if (resp && !resp.error) this.resetForm();
    } finally {
      this.inProgress = false;
    }
  };

  render() {
    const { classes } = this.props;
    return (
      <DP>
        <Box p={2}>
          {['admin', 'owner'].includes(this.scopeType) && (
            <>
              <Box>
                <Typography variant="h5" className={classes.fieldLabel}>
                  role
                </Typography>
                <FormGroup row>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={this.selectedRoles.includes('talent')}
                        onChange={this.toggleRole}
                        name="talent"
                        dataCy="talent-role-check"
                      />
                    }
                    label="Employee"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={this.selectedRoles.includes('manager')}
                        onChange={this.toggleRole}
                        name="manager"
                        dataCy="manager-role-check"
                      />
                    }
                    label="Manager"
                  />
                </FormGroup>
              </Box>
              <Box mt={2}>
                <Typography variant="h5" className={classes.fieldLabel}>
                  location
                </Typography>
                <Box mt={1}>
                  <LocationSearch
                    dataCy="location-search-input"
                    value={this.location}
                    accountId={this.accountId}
                    onChange={this.handleLocationChange}
                    placeholder={
                      ['owner'].includes(this.scopeType)
                        ? 'If location is not selected users will have the option to select the location themselves'
                        : 'Select a location'
                    }
                  />
                </Box>
              </Box>
            </>
          )}
          <Box mt={3}>
            <Typography variant="h5" className={classes.fieldLabel}>
              emails
            </Typography>
            <ReactMultiEmail
              className={classes.emailField}
              placeholder="Enter one or more employee emails"
              emails={this.emails}
              onChange={this.updateEmailList}
              data-cy="multi-email-input"
              getLabel={(email: string, index: number, removeEmail: (index: number) => void) => (
                <Chip
                  key={index}
                  className={classes.emailLabel}
                  label={email}
                  onDelete={() => removeEmail(index)}
                  deleteIcon={<FontAwesomeIcon icon={faXmarkCircle} fontSize={22} />}
                  color="primary"
                />
              )}
            />
          </Box>
          <Box mt={3}>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={this.submit}
              disabled={!this.formValid || this.inProgress}
              data-cy="invite-button"
              loading={this.inProgress}>
              invite
            </Button>
          </Box>
        </Box>
      </DP>
    );
  }
}

export default withStyles(styles)(InvitePanel);
