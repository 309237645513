import { rootStore } from 'containers/App/App';
import * as paths from './paths';
import { Location } from 'history';

export enum LoginAction {
  ADD_PHONE = 'add_phone',
  VERIFY_PHONE = 'verify_phone',
}

export interface LocationState {
  from: {
    pathname: string;
  };
}

export const getPhoneActionNeeded = (
  isSsoLogin: boolean,
  location?: Location<LocationState>,
): LoginAction | null => {
  const to = location?.pathname;
  const from = location?.state?.from?.pathname;
  const signup = to?.includes(paths.signUp().root());
  if (signup) return null;
  if (from === to) return null;
  const { user } = rootStore.userStore;
  const shouldAddPhone = !isSsoLogin && !user?.phone;
  const shouldVerifyPhone = !isSsoLogin && !user?.isPhoneConfirmed;
  const phoneActionNeeded = user && (shouldAddPhone || shouldVerifyPhone);

  if (!phoneActionNeeded) return null;
  return shouldAddPhone ? LoginAction.ADD_PHONE : LoginAction.VERIFY_PHONE;
};
