import {
  faBuildings,
  faCashRegister,
  faChartLine,
  faChartMixed,
  faChartMixedUpCircleDollar,
  faChartNetwork,
  faChartPieSimpleCircleDollar,
  faChartTreeMap,
  faClipboardCheck,
  faCoin,
  faCreditCard,
  faDisplayChartUp,
  faDollarSign,
  faGaugeHigh,
  faGlobe,
  faHandBackPointUp,
  faHandHoldingDollar,
  faLandmark,
  faLayerGroup,
  faListCheck,
  faMessageExclamation,
  faMoneyBillTransfer,
  faMoneyBillWave,
  faMoneyCheckDollarPen,
  faPeopleArrows,
  faPeopleLine,
  faPersonCircleCheck,
  faPersonCirclePlus,
  faShieldCheck,
  faStore,
  faToolbox,
  faUsers,
} from '@fortawesome/pro-regular-svg-icons';
import { MenuGroup, MenuItemType, MenuItems } from 'models/Menu';
import { paths } from 'routes';
import { UserScopes } from 'stores';
import { ACL, EManagerPermission } from 'types/permissions';
export interface IPathProps {
  accountId?: number;
}
interface IHideProps {
  hidePendingTips?: boolean;
  tip3925ReferAppHomescreen?: boolean;
  isSp?: boolean;
  isManager?: boolean;
}
type MenuItemsFunc = ({
  accountId,
  hidePendingTips,
  tip3925ReferAppHomescreen,
  isSp,
  isManager,
}: IPathProps & IHideProps) => MenuItems;
const menuItems: MenuItemsFunc = ({
  accountId,
  hidePendingTips,
  tip3925ReferAppHomescreen,
  isSp,
  isManager,
}) => {
  return [
    {
      type: MenuItemType.PAGE,
      label: 'Dashboard',
      icon: faChartTreeMap,
      to: paths.root(),
      scopes: [
        UserScopes.ADMIN,
        UserScopes.GLOBAL_OWNER,
        UserScopes.OWNER,
        UserScopes.MANAGER,
        UserScopes.TALENT,
      ],
    },
    {
      type: MenuItemType.PAGE,
      label: 'Account Info',
      icon: faBuildings,
      to: paths.accountDetails(accountId || '').root(),
      paths: [paths.accountDetails(accountId || '').permissions()],
      scopes: [UserScopes.OWNER],
    },
    {
      type: MenuItemType.GROUP,
      label: MenuGroup.MANAGE,
      icon: faHandBackPointUp,
      items: [
        {
          type: MenuItemType.PAGE,
          label: 'Accounts',
          to: paths.accounts(),
          icon: faBuildings,
          scopes: [UserScopes.ADMIN],
        },
        {
          type: MenuItemType.PAGE,
          label: 'Locations',
          to: paths.locations(),
          icon: faStore,
          scopes: [UserScopes.ADMIN, UserScopes.GLOBAL_OWNER, UserScopes.OWNER],
        },
        {
          type: MenuItemType.PAGE,
          label: 'Users',
          to: paths.users().root(),
          icon: faUsers,
          scopes: [UserScopes.ADMIN, UserScopes.GLOBAL_OWNER, UserScopes.OWNER, UserScopes.MANAGER],
        },
        {
          type: MenuItemType.PAGE,
          label: 'Sales',
          to: paths.sales().pendingCarts(),
          icon: faCashRegister,
          scopes: [UserScopes.ADMIN],
        },
        {
          type: MenuItemType.PAGE,
          label: 'Inventory',
          to: paths.devices().root(),
          icon: faClipboardCheck,
          scopes: [UserScopes.ADMIN],
        },
        {
          type: MenuItemType.PAGE,
          label: 'Integrations',
          to: paths.integrations(),
          icon: faLayerGroup,
          scopes: [UserScopes.OWNER],
        },
        {
          type: MenuItemType.PAGE,
          label: 'Alerts',
          to: paths.alerts(),
          icon: faMessageExclamation,
          hide: false,
          scopes: [UserScopes.ADMIN],
        },
        {
          type: MenuItemType.PAGE,
          label: 'RevShare',
          to: paths.revshare().feeSchedule(),
          paths: [paths.revshare().partners()],
          icon: faChartPieSimpleCircleDollar,
          scopes: [UserScopes.ADMIN],
          roles: [ACL.REV_SHARE, ACL.VIEW_REV_SHARE],
        },
      ],
    },
    {
      type: MenuItemType.GROUP,
      label: MenuGroup.MARKETING,
      icon: faGlobe,
      items: [
        {
          type: MenuItemType.PAGE,
          label: 'Affiliates',
          to: paths.affiliates(),
          icon: faPeopleArrows,
          scopes: [UserScopes.ADMIN],
        },
        {
          type: MenuItemType.PAGE,
          label: 'Leads',
          to: paths.leads(),
          icon: faPersonCirclePlus,
          scopes: [UserScopes.ADMIN],
        },
        {
          type: MenuItemType.PAGE,
          label: 'Conversions',
          to: paths.conversions(),
          icon: faPersonCircleCheck,
          scopes: [UserScopes.ADMIN],
        },
      ],
    },
    {
      type: MenuItemType.GROUP,
      label: MenuGroup.TIPPING,
      icon: faDollarSign,
      items: [
        {
          type: MenuItemType.PAGE,
          label: 'Payments',
          to: paths.payments(),
          icon: faCreditCard,
          scopes: [UserScopes.ADMIN],
        },
        {
          type: MenuItemType.PAGE,
          label: 'Customers',
          to: paths.customers(),
          icon: faPeopleLine,
          scopes: [UserScopes.ADMIN],
        },
        {
          type: MenuItemType.PAGE,
          label: 'Tips',
          to: paths.tips(),
          icon: faCoin,
          permissions: [EManagerPermission.VIEW_LOCATION_TIPS],
          scopes: [UserScopes.ADMIN, UserScopes.GLOBAL_OWNER, UserScopes.OWNER, UserScopes.MANAGER],
        },
        {
          type: MenuItemType.PAGE,
          label: 'Tips',
          to: paths.myTips(),
          icon: faCoin,
          scopes: [UserScopes.TALENT],
        },
        {
          type: MenuItemType.PAGE,
          label: 'Pending Tips',
          to: paths.pendingTips().undistributed(),
          icon: faMoneyBillWave,
          hide: hidePendingTips,
          scopes: [UserScopes.OWNER, UserScopes.MANAGER],
        },
        {
          type: MenuItemType.PAGE,
          label: 'Deposits',
          to: paths.deposits().userPayouts(),
          paths: [paths.deposits().partnerPayouts(), paths.deposits().cards()],
          icon: faLandmark,
          scopes: [UserScopes.ADMIN],
        },
        {
          type: MenuItemType.PAGE,
          label: 'Refunds',
          to: paths.refunds().root(),
          icon: faMoneyBillTransfer,
          permissions: [EManagerPermission.VIEW_REFUNDS],
          scopes: [UserScopes.ADMIN, UserScopes.OWNER, UserScopes.GLOBAL_OWNER, UserScopes.MANAGER],
        },
        {
          type: MenuItemType.PAGE,
          label: 'Transfers',
          to: paths.tipTransfers().root(),
          icon: faMoneyCheckDollarPen,
          scopes: [UserScopes.ADMIN],
        },
      ],
    },
    {
      type: MenuItemType.GROUP,
      label: MenuGroup.FINANCIAL,
      icon: faMoneyBillWave,
      items: [
        {
          type: MenuItemType.PAGE,
          label: 'Draw',
          to: paths.draw().balances(),
          icon: faHandHoldingDollar,
          scopes: [UserScopes.ADMIN, UserScopes.OWNER],
        },
        {
          type: MenuItemType.PAGE,
          label: 'Liability',
          to: paths.liability(),
          icon: faShieldCheck,
          scopes: [UserScopes.ADMIN],
          roles: [ACL.VIEW_LIABILITY],
        },
      ],
    },
    {
      type: MenuItemType.GROUP,
      label: MenuGroup.ANALYTICS,
      icon: faDisplayChartUp,
      items: [
        {
          type: MenuItemType.PAGE,
          label: 'Stats',
          to: paths.analytics().tipsReport(),
          icon: faChartMixed,
          permissions: [EManagerPermission.VIEW_STATS],
          scopes: [UserScopes.ADMIN, UserScopes.GLOBAL_OWNER, UserScopes.OWNER, UserScopes.MANAGER],
        },
        {
          type: MenuItemType.PAGE,
          label: 'Revenue',
          to: paths.analytics().revenueOverview(),
          icon: faChartMixedUpCircleDollar,
          scopes: [UserScopes.ADMIN],
        },
        {
          type: MenuItemType.PAGE,
          label: 'Activity',
          to: paths.analytics().tips(),
          icon: faChartLine,
          scopes: [UserScopes.ADMIN],
        },
        {
          type: MenuItemType.PAGE,
          label: 'Performance',
          to: paths.analytics().performance().root(),
          icon: faGaugeHigh,
          scopes: [UserScopes.ADMIN],
        },
        {
          type: MenuItemType.PAGE,
          label: 'Conversion',
          to: paths.analytics().conversion().root(),
          icon: faListCheck,
          scopes: [UserScopes.ADMIN],
        },
      ],
    },
    {
      type: MenuItemType.GROUP,
      label: MenuGroup.TOOLS,
      icon: faToolbox,
      items: [
        {
          type: MenuItemType.PAGE,
          label: 'Reconciliation',
          to: paths.reconciliation().root(),
          icon: faListCheck,
          scopes: [UserScopes.OWNER],
        },
        {
          type: MenuItemType.PAGE,
          label: 'Referrals',
          to: paths.referrals().root(),
          icon: faChartNetwork,
          hide: tip3925ReferAppHomescreen === false || (!isSp && !isManager),
          scopes: [
            UserScopes.GLOBAL_OWNER,
            UserScopes.OWNER,
            UserScopes.MANAGER,
            UserScopes.TALENT,
          ],
        },
        {
          type: MenuItemType.PAGE,
          label: 'Reports',
          to: paths.reports(),
          icon: faShieldCheck,
          permissions: [EManagerPermission.VIEW_REPORTS],
          scopes: [UserScopes.ADMIN, UserScopes.OWNER, UserScopes.MANAGER],
        },
      ],
    },
  ] as MenuItems;
};

export default menuItems;
