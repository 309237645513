import Api, { ApiResponse } from 'api';
import { AxiosResponse } from 'axios';
import { action, computed, flow, observable, makeObservable } from 'mobx';
import { BankAccount, Wallet } from 'models';
import { PlaidBankAccountResponse } from 'types/plaid';

import BaseStore from './BaseStore';
import UserStore from './UserStore';
import { PlaidLinkOnSuccessMetadata } from 'react-plaid-link';
import RootStore from './RootStore';
import { PlaidAuthLinks } from '../containers/AccountDetails/BankAttachPanel';
/**
 */
export default class PayoutSourceStore extends BaseStore {
  constructor(rootStore: RootStore){
    super(rootStore);
    makeObservable(this);
  }
  public userStore: UserStore = this.rootStore.userStore;

  /** The identity verification code */
  @observable public identityVerificationCode?: string;

  /** loading || user does not have it yet || users wallet  */
  @observable public primaryWallet?: null | Wallet;

  /** loading || user does not have any bank accounts || users bank accounts  */
  @observable public bankAccounts?: null | BankAccount[];

  /** The user's wallet */
  @observable public wallet?: Wallet;

  /**
   * When user has wallet and bank account id or debit
   * card id (primary payout source) and did not provide
   * identity verification code she needs to bo verified
   */
  @computed get requiresIdentityVerification(): boolean {
    return !!(
      this.wallet &&
      (this.wallet.bankAccountId || this.wallet.debitCardId) &&
      !this.identityVerificationCode
    );
  }

  @action.bound public getWallet = flow(function* (this: PayoutSourceStore) {
    try {
      const resp = yield Api.tips.getPrimaryWallet(this.userStore!.user!.id);
      this.wallet = resp.data.data;
    } catch (e: any) {
      return e;
    }
  });

  @action.bound public verifyIdentity = flow(function* (this: PayoutSourceStore) {
    const codeResp: {
      code: string | undefined;
      ok: boolean;
    } = yield this.rootStore.modalStore!.identityConfirm();
    // If the modal did not complete with the user successfully
    // entering an iv code, don't do anything more
    if (!codeResp || !codeResp.ok) {
      return;
    }
    this.identityVerificationCode = codeResp.code;
  });

  /** plaidLink success event handler for creating a new bank account */
  @action.bound createBankAccount = flow(function* (
    this: PayoutSourceStore,
    publicToken: string,
    metadata: PlaidLinkOnSuccessMetadata,
  ) {
    // Get the success metadata from Plaid Link
    const meta = metadata as PlaidLinkOnSuccessMetadata & {
      account_id: string;
      account: PlaidBankAccountResponse;
    };
    // We need the account id and institution id
    const accountId = meta.account_id;
    const institutionId = meta.institution ? meta.institution.institution_id : undefined;
    try {
      // Create a bank account on our API
      const resp: AxiosResponse<ApiResponse<BankAccount>> = yield Api.core.createBankAccount(
        publicToken,
        accountId,
        institutionId,
        // NOTE: verification code can be undefined; if user is creating
        // her first bank account, she does not need to be explicitly verified
        this.identityVerificationCode,
      );
      if (resp.data.error) {
        throw new Error(resp.data.error.message);
      }
      if (!resp.data.data) {
        throw new Error('An unknown error has occurred');
      }
      const bankAccount = resp.data.data;
      return { bankAccount };
    } catch (e: any) {
      return { error: e };
    }
  });

  /** plaidLink success event handler for attaching a bank account in accounts / billing */
  @action.bound attachV3BankAccount = flow(function*(
    this: PayoutSourceStore,
    publicToken: string,
    metadata: PlaidLinkOnSuccessMetadata,
    _links: PlaidAuthLinks,
    businessAccountId: number,
  ) {
    // Get the success metadata from Plaid Link
    const meta = metadata as PlaidLinkOnSuccessMetadata & {
      account_id: string;
      account: PlaidBankAccountResponse;
    };

    try {
      const accountId = meta.account_id;
      const institutionId = meta?.institution?.institution_id;

      const resp: AxiosResponse<ApiResponse<BankAccount>> = yield Api.core.createV3BankAccount(
        publicToken,
        accountId,
        institutionId,
        this.identityVerificationCode,
        _links,
        businessAccountId,
      );
      if (resp.data.error) {
        throw new Error(resp.data.error.message);
      }
      if (!resp.data.data) {
        throw new Error('An unknown error has occurred');
      }

      return resp.data.data;
    } catch (e) {
      return { error: e };
    }
  });

  /** Initializes the state from the local storage and sets up autorunners */
  public init() {
    this.getWallet();
  }

  /** Reset the store */
  public clear() {
    this.identityVerificationCode = undefined;
    this.wallet = undefined;
  }
}

/**
 * A component's props can extend this interface
 * if it needs payout source functionality and data
 */
export interface WithPayoutSourceStore {
  payoutSourceStore?: PayoutSourceStore;
}
