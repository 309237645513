import { Hardware } from './Hardware';
export enum InventoryStatus {
  AVAILABLE = 'available',
  RETURNED = 'returned',
  LOST = 'lost',
  BROKEN = 'broken',
  IN_USE = 'in_use',
  RETURNING = 'returning',
}

/** Represents a single inventory item */
export interface Inventory {
  id: number;
  number: string;
  serial: string;
  status: InventoryStatus;
  hardwareId: number;
  hardware?: Hardware;
  lastUpdate?: string;
  cost?: string;
  color?: string;
  vendor?: string;
  purchasedOn?: string;
}

export interface InventoryStatusReq {
  id: number;
  status: InventoryStatus;
}
