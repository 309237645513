import { FormControlLabel, Box, IconButton, Typography } from '@material-ui/core';
import { Checkbox } from 'components/Checkbox/Checkbox';
import { Minus, Plus } from 'mdi-material-ui';
import { ICorrection, IInvoiceProduct } from 'models';
import { useCallback } from 'react';
import { correctionSingleStyling } from '../../styles';

interface CorrectionSingleProps {
  correction: ICorrection;
  invoiceProduct?: IInvoiceProduct;
  updateCorrection: (isActive: boolean, quantity: number) => void;
}

export const CorrectionSingle = ({
  correction: { id, name, price },
  invoiceProduct,
  updateCorrection,
}: CorrectionSingleProps) => {
  const minusHandler = useCallback(() => {
    if (!invoiceProduct || invoiceProduct.quantity === 1) {
      return;
    }
    updateCorrection(true, invoiceProduct.quantity - 1);
  }, [invoiceProduct, updateCorrection]);

  const plusHandler = useCallback(() => {
    if (!invoiceProduct) {
      return;
    }
    updateCorrection(true, invoiceProduct.quantity + 1);
  }, [invoiceProduct, updateCorrection]);

  return (
    <Box sx={correctionSingleStyling}>
      <FormControlLabel
        key={`correction-${id}`}
        control={
          <Checkbox
            name={String(id)}
            checked={!!invoiceProduct}
            onChange={() => updateCorrection(!invoiceProduct, 1)}
          />
        }
        label={`${name} ${price}`}
      />

      {/* Quantity selector */}
      {invoiceProduct && (
        <Box className="quantity-selector">
          <Typography>Quantity:</Typography>
          <IconButton
            color="primary"
            size="small"
            onClick={minusHandler}
            disabled={invoiceProduct.quantity === 1}>
            <Minus fontSize="small" />
          </IconButton>
          <Box>{invoiceProduct.quantity}</Box>
          <IconButton color="primary" size="small" onClick={plusHandler}>
            <Plus fontSize="small" />
          </IconButton>
        </Box>
      )}
    </Box>
  );
};
