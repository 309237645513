import { Theme, createStyles } from '@material-ui/core';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default (theme: Theme) =>
  createStyles({
    root: {},
    // TODO: copied from UserCardList styles, consolidate
    message: {
      fontSize: theme.typography.subtitle1.fontSize,
      color: theme.palette.grey[600],
    },
    drawer: {
      '& .MuiDrawer-paperAnchorRight': {
        overflowY: 'visible',
      },
    },
    textFieldInput: {
      height: theme.spacing(5.375),
      padding: '13px',
      border: 'none',
      '& .MuiInputBase-input': {
        fontSize: 14,
      },
      '& .MuiSvgIcon-root': {
        marginRight: 10,
        height: 24,
        width: 24,
      },
    },
  });
