import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import logo from 'images/Tippy_Logo_Green_2022.svg';
import { Box, Link } from '@material-ui/core';
import { paths } from 'routes';
import useStyles from './styles';

interface ITippyLogoProps {
  link?: boolean;
  width?: number;
}
const TippyLogo = ({ link, width }: ITippyLogoProps) => {
  const { link: linkStyles } = useStyles({ width });
  const redirect = paths.root();
  if (link)
    return (
      <Link component={RouterLink} to={redirect} className={linkStyles}>
        <img alt="logo" src={logo} />{' '}
      </Link>
    );
  return (
    <Box className={linkStyles}>
      {' '}
      <img alt="logo" src={logo} />{' '}
    </Box>
  );
};

export default TippyLogo;
