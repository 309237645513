import React from 'react';
import { observable, action, flow, computed, makeObservable } from 'mobx';
import { observer } from 'mobx-react';

import Api from 'api';
import { Shipment, Inventory, ShipmentProgress } from 'models';
import moment from 'moment-timezone';

import { WithStyles, withStyles } from '@material-ui/core/styles';
import {
  Box,
  List,
  CircularProgress,
  Stepper,
  Step,
  StepLabel,
  Typography,
} from '@material-ui/core';

import DD from 'components/DashDrawer';
import InventoryItem from 'components/InventoryItem';

import styles from './styles';

/** A helper component for displaying dates inside the shipment stepper */
const ShortDate: React.FC<{ children: string | null }> = observer(({ children }) => {
  if (!children) {
    return null;
  }
  return <Typography color="textSecondary">{moment(children).format('MMM DD')}</Typography>;
});

/** Props for this component */
interface ShipmentDetailsProps extends WithStyles<typeof styles> {
  shipmentId: number;
  onBack?: () => void;
  onOpen?: () => void;
  onProgressFetched?: () => void;
}

/**
 * Displays a shipment drawer for a single sale
 */
@observer
class ShipmentDetails extends React.Component<ShipmentDetailsProps> {
  constructor(props: ShipmentDetailsProps) {
    super(props);
    makeObservable(this);
  }
  /** The inventory for this shipment */
  @observable public inventory?: Inventory[];

  /** The current shipment */
  @observable public shipment?: Shipment;

  /** The progress object for this shipment, null if not available, undefined if loading */
  @observable public progress?: ShipmentProgress | null;

  /** Fetches the shipment details */
  @action.bound public getShipment = flow(function* (this: ShipmentDetails) {
    const resp = yield Api.fulfillment.getShipment(this.props.shipmentId);
    this.shipment = resp.data.data;
  });

  /** Fetches the inventory items for this shipment */
  @action.bound public getInventory = flow(function* (this: ShipmentDetails) {
    const resp = yield Api.fulfillment.getInventoryForShipment(this.props.shipmentId);
    this.inventory = resp.data.data;
  });

  /** Fetches the progress for this shipment */
  @action.bound public getProgress = flow(function* (this: ShipmentDetails) {
    try {
      const resp = yield Api.fulfillment.getShipmentProgress(this.props.shipmentId);
      this.progress = resp.data.data;
      this.props.onProgressFetched && this.props.onProgressFetched();
    } catch (e: any) {
      this.progress = null;
    }
  });

  /** Gets the current step for the stepper based on the shipment progress */
  @computed public get currentStep(): number {
    // No progress, is just created
    if (!this.progress) {
      return 0;
    }
    if (this.progress.status === 'billing_information_received') {
      return 1;
    }
    if (this.progress.status === 'delivered') {
      return 3;
    }
    // If it's not processed or delivered, then we're somewhere in the middle
    return 2;
  }

  /** The date when this was processed, if any  */
  @computed public get processedDate(): string | null {
    if (!this.progress) {
      return null;
    }
    const processedActivity = this.progress.activity.find(
      (ac) => ac.status === 'billing_information_received',
    );
    if (!processedActivity) {
      return null;
    }
    return processedActivity.changedAt;
  }

  /** The date when this was delivered */
  @computed public get deliveredDate(): string | null {
    if (!this.progress) {
      return null;
    }
    const processedActivity = this.progress.activity.find((ac) => ac.status === 'delivered');
    if (!processedActivity) {
      return null;
    }
    return processedActivity.changedAt;
  }

  /** Gets the label for the third step, will usually be in transit, but can change */
  @computed public get thirdStepLabel(): string {
    // If the currentStep is 2, that means we're somewhere in the middle of the process,
    // so we return the current status
    if (this.currentStep === 2 && this.progress) {
      return this.progress.description;
      // Otherwise, the third step is "In transit"
    } else {
      return 'In transit';
    }
  }

  /** Gets the date for the third step */
  @computed public get thirdStepDate(): string | null {
    if (!this.progress) {
      return null;
    }
    // Get the status of the third step. If we're somewhere in the middle of the shipping process,
    // then the status is whatever the current status is of the progress. If not, then the third
    // step status is 'in_transit'
    const status = this.currentStep === 2 && this.progress ? this.progress.status : 'in_transit';
    // Find the first activity matching the status. If more activities with one status are in the
    // array, the latest one is returned
    const processedActivity = this.progress.activity.find((ac) => status && ac.status === status);
    if (!processedActivity) {
      return null;
    }
    return processedActivity.changedAt;
  }

  componentDidMount() {
    this.getShipment();
    this.getInventory();
    this.getProgress();
    this.props.onOpen && this.props.onOpen();
  }

  render() {
    const { onBack, classes } = this.props;
    const shipment = this.shipment;
    if (!shipment || this.progress === undefined || !this.inventory) {
      return (
        <DD>
          <DD.Content>
            <Box display="flex" justifyContent="center" alignItems="center" height="100%">
              <CircularProgress color="primary" />
            </Box>
          </DD.Content>
        </DD>
      );
    }
    return (
      <DD>
        <DD.Content>
          <DD.Title onBack={onBack}>{shipment.trackingNumber}</DD.Title>
          <DD.Subtitle>Address</DD.Subtitle>
          <Box mb={3}>
            <div>{shipment.address}</div>
            <div>{shipment.city}</div>
            <div>
              {shipment.zip} {shipment.state}
            </div>
          </Box>
          <DD.Subtitle>Shipment Progress</DD.Subtitle>
          {this.progress === undefined && (
            <Box display="flex" justifyContent="center" alignItems="center">
              <CircularProgress color="primary" />
            </Box>
          )}
          {this.progress !== undefined && (
            <Stepper
              activeStep={this.currentStep}
              orientation="vertical"
              classes={{ root: classes.stepper }}>
              <Step key="created">
                <StepLabel>
                  Created <ShortDate>{shipment.createdAt}</ShortDate>
                </StepLabel>
              </Step>
              <Step key="processed">
                <StepLabel>
                  Processed <ShortDate>{this.processedDate}</ShortDate>
                </StepLabel>
              </Step>
              <Step key="in_transit">
                <StepLabel>
                  {this.thirdStepLabel} <ShortDate>{this.thirdStepDate}</ShortDate>
                </StepLabel>
              </Step>
              <Step key="delivered">
                <StepLabel>
                  Delivered <ShortDate>{this.deliveredDate}</ShortDate>
                </StepLabel>
              </Step>
            </Stepper>
          )}
          <DD.Subtitle>Contents</DD.Subtitle>
          {this.inventory ? (
            <List>
              {this.inventory.map((i) => (
                <InventoryItem key={i.id}>{i}</InventoryItem>
              ))}
            </List>
          ) : (
            <Box display="flex" justifyContent="center" alignItems="center">
              <CircularProgress color="primary" />
            </Box>
          )}
        </DD.Content>
      </DD>
    );
  }
}

export default withStyles(styles)(ShipmentDetails);
