import { InputProps } from '@material-ui/core';
import { ReactNode } from 'react';

type variant = 'normal' | 'large';

export type UnderlineInputProps = InputProps & {
  variant?: variant;
  width?: number;
  fontSize?: number;
  label?: string;
  helperText?: string | ReactNode;
  prefix?: string;
};

type UseUnderlineInput = [number, number];

function useUnderlineInput({
  variant,
  width,
  fontSize,
}: Partial<UnderlineInputProps>): UseUnderlineInput {
  let _fontSize = 24;
  let _width = 120;
  if (variant === 'large') {
    _fontSize = 100;
    _width = 351;
  }
  if (fontSize) {
    _fontSize = fontSize;
  }
  if (width) {
    _width = width;
  }

  return [_width, _fontSize];
}

export default useUnderlineInput;
