import React from 'react';
import styles from './styles';
import { Box, WithStyles, withStyles } from '@material-ui/core';
import DashboardLayout from 'containers/DashboardLayout';
import TabBar from 'components/TabBar/TabBar';
import { action, observable, makeObservable, computed } from 'mobx';
import { inject, WithUserStore } from 'stores';
import { Link as RouterLink, RouteComponentProps } from 'react-router-dom';
import { draw } from 'routes/paths';
import Balances from './Balances/Balances';
import Draws from './Draws/Draws';
import { observer } from 'mobx-react';
import Title from 'components/Title/Title';

const PAGE_TITLE = 'Draws';

type DrawProps = WithStyles<typeof styles> & WithUserStore & RouteComponentProps;

@inject('userStore')
@observer
class Draw extends React.Component<DrawProps> {
  constructor(props: any) {
    super(props);
    makeObservable(this);
  }

  @observable isAdmin: boolean = this.props.userStore!.scope.kind === 'admin';

  @observable pathname: string = draw().balances();

  @action.bound private setFilters(pathname?: string) {
    let _pathname = pathname;
    if (!_pathname) _pathname = this.props.location.pathname;
    this.pathname = _pathname;
  }

  @computed get tabs() {
    const { pathname } = this.props.location;
    const conversionPaths = draw();
    return [
      {
        label: 'Balances',
        selected: pathname === conversionPaths.balances(),
        component: RouterLink,
        to: conversionPaths.balances(),
      },
      {
        label: 'Draws',
        selected: pathname === conversionPaths.draws(),
        component: RouterLink,
        to: conversionPaths.draws(),
      },
    ];
  }

  render() {
    const { pathname } = this.props.location;
    return (
      <DashboardLayout>
        <Title size="large" mb={3}>
          {PAGE_TITLE}
        </Title>
        <TabBar hide={!this.isAdmin} tabs={this.tabs} />
        <Box>
          {pathname === draw().balances() && <Balances {...this.props} />}
          {pathname === draw().draws() && this.isAdmin && <Draws {...this.props} />}
        </Box>
      </DashboardLayout>
    );
  }
}

export default withStyles(styles)(Draw);
