import { Theme, createStyles } from '@material-ui/core';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default (theme: Theme) =>
  createStyles({
    root: {},
    buttonLink: {
      border: 'none',
      backgroundColor: 'rgba(102,171,229,.5)',
    },
    filterBox: {
      display: 'flex',
      '& div': {
        marginLeft: 'auto',
        minWidth: '250px',
      },
    },
    greyText: {
      color: theme.palette.grey[500],
    },
    institutionLogo: {
      width: theme.spacing(4),
      height: theme.spacing(4),
      marginRight: theme.spacing(2),
    },
    alignRight: {
      marginLeft: 'auto',
    },
    chargeLink: {
      color: theme.palette.primary.main,
      textDecoration: 'none',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
    chargeButton: {
      marginLeft: theme.spacing(4),
    },
    spinner: {
      textAlign: 'center',
    },
    selectInput: {
      fontSize: '16px',
      '& .MuiSelect-selectMenu': {
        display: 'flex',
        '& span:nth-of-type(2)': {
          marginLeft: 'auto',
        },
      },
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      '& .MuiBackdrop-root': {
        backgroundFilter: 'none !important',
      },
    },
    noBlur: {
      backgroundFilter: 'none !important',
      backgroundColor: theme.palette.background.default,
    },
    stepList: {
      paddingLeft: theme.spacing(2),
    },
    workspaceRoot: {},
    divider: {
      margin: '24px 0',
    },
    label: {
      backgroundColor: 'lightgray',
      padding: '8px 24px',
      borderRadius: '18px',
      textTransform: 'uppercase',
    },
    containerBox: {
      minWidth: '20vw',
      minHeight: '20vw',
      display: 'flex',
      justifyContent: 'center',
      alignContent: 'center',
      alignItems: 'center',
    },
    rebateCancelText: {
      marginTop: theme.spacing(2),
    },
    rebateTextSpacingSmall: {
      marginTop: theme.spacing(3),
    },
    rebateSpacingMedium: {
      marginTop: theme.spacing(6),
    },
    rebateSpacingBig: {
      marginTop: theme.spacing(8),
    },
    rebateCancelInput: {
      marginTop: theme.spacing(4),
    },
  });
