import Api, { getErrorMsg } from 'api';
import _ from 'lodash';
import { action, flow, observable, makeObservable } from 'mobx';
import * as models from 'models';
import { ToastStore } from 'stores';
import RootStore from 'stores/RootStore';

export interface SettingsObject {
  kind: 'account' | 'location' | 'station';
  id: number;
}

/**
 * On demand store that is used when editing tip related settings for either account or location.
 * It calls either account or location settings API endpoints based on the 'entity' and 'id'
 * parameters.
 *
 * @param entity Used to call correct settings API hooks
 * @param id Used to call correct settings API hooks
 */
export default class SettingsStore {
  // @ts-ignore
  private rootStore: RootStore = window.rootStore;
  private toastStore: ToastStore;
  constructor(entity: SettingsObject['kind'], id: SettingsObject['id']) {
    this.settingsObject = { kind: entity, id: id };
    this.toastStore = this.rootStore.toastStore;
    makeObservable(this);
  }

  /** Used to build dynamic API endpoint URL strings */
  @observable public settingsObject: SettingsObject;

  @observable public account?: models.Account;

  /** First level of settings */
  @observable public generalSettings?: models.TipSettings;

  /** Basic or dynamic proposal strategy settings */
  @observable public thresholdProposalStrategySettings?: models.Threshold;

  /** Breakpoint proposal strategy settings */
  @observable public breakpointProposalStrategySettings?: models.Breakpoint[];

  /** Are we updating anything? */
  @observable public updating = false;

  /** Is Store being initialized? */
  @observable public initializing = false;

  @observable public tippyGoSettings?: models.TippyGoSettings;

  /** Get general settings and more specific settings based on first response */
  @action.bound public fetchSettings = flow(function* (this: SettingsStore) {
    const { kind, id } = this.settingsObject;
    this.initializing = true;

    if (id && kind) {
      yield this.getGeneralSettings();

      if (kind !== 'station') {
        yield this.getProposalStrategySettings(this.generalSettings?.strategy);
      }
    }
    this.initializing = false;
  });

  /** Get general settings and more specific settings based on first response */
  @action.bound public getAccount = flow(function* (this: SettingsStore) {
    const { kind, id } = this.settingsObject;
    this.updating = true;

    if (id && kind) {
      if (kind === 'location') {
        const response = yield Api.core.getLocation(id);
        const location = response && response.data && response.data.data;
        const responseAcc = yield Api.core.getAccount(location.accountId);
        this.account = responseAcc && responseAcc.data && (responseAcc.data.data as models.Account);
      } else if (kind === 'account') {
        const responseAcc = yield Api.core.getAccount(id);
        this.account = responseAcc && responseAcc.data && (responseAcc.data.data as models.Account);
      }
    }
    this.updating = false;
  });

  /** Get general settings */
  @action.bound public getGeneralSettings = flow(function* (this: SettingsStore) {
    this.updating = true;
    const { kind, id } = this.settingsObject;
    try {
      if (!id || !kind) {
        throw new Error('Missing id or kind parameter');
      }
      const resp = yield Api.core.getSettings(kind, id);
      yield this.getAccount();

      const industry = this.account?.industry;

      this.generalSettings = resp &&
        resp.data &&
        resp.data.data &&
        this.account && { ...resp.data.data, ...{ industry } };
      this.tippyGoSettings = { ...this.generalSettings } as models.TippyGoSettings;
    } catch (e: any) {
      if (id && kind) {
        this.toastStore.error(getErrorMsg(e));
      }
    }

    this.updating = false;
  });

  /** Get proposal settings for passed strategy */
  @action.bound public getProposalStrategySettings = flow(function* (
    this: SettingsStore,
    strategy,
  ) {
    const { kind, id } = this.settingsObject!;
    this.updating = true;
    try {
      if (!id || !kind) {
        throw new Error('Missing id or kind parameter');
      }
      const resp = yield Api.tips.getProposalStrategySettings(kind, id, strategy);
      if (strategy === 'basic' || strategy === 'dynamic') {
        this.breakpointProposalStrategySettings = undefined;
        this.thresholdProposalStrategySettings = resp && resp.data && resp.data.data;
      }
      if (strategy === 'breakpoint') {
        this.thresholdProposalStrategySettings = undefined;
        this.breakpointProposalStrategySettings = resp && resp.data && resp.data.data.breakpoints;
      }
    } catch (e: any) {
      if (id && kind) {
        this.toastStore.error(getErrorMsg(e));
      }
    }

    this.updating = false;
  });

  /** Update general settings */
  @action.bound public updateGeneralSettings = flow(function* (this: SettingsStore, settings) {
    const { kind, id } = this.settingsObject!;
    this.updating = true;
    try {
      const resp = yield Api.core.updateSettings(kind, id, settings);
      this.generalSettings = resp &&
        resp.data &&
        resp.data.data && { ...this.generalSettings, ...resp.data.data };
      this.tippyGoSettings = { ...this.generalSettings } as models.TippyGoSettings;
      this.updating = false;
      this.toastStore!.success('Settings successfully updated');
    } catch (e: any) {
      this.toastStore!.error(getErrorMsg(e));
      this.init();
      this.updating = false;
    }
  });

  /** Update industry settings */
  @action.bound public updateIndustrySettings = flow(function* (this: SettingsStore, settings) {
    const { kind, id } = this.settingsObject!;
    this.updating = true;
    try {
      yield this.getAccount();
      const accountId = this.account?.id;
      const resp = yield Api.core.updateAccount(accountId!, settings);
      this.generalSettings = resp &&
        resp.data &&
        resp.data.data && { ...this.generalSettings, ...settings };
      this.tippyGoSettings = { ...this.generalSettings } as models.TippyGoSettings;
      this.updating = false;
      this.toastStore!.success('Settings successfully updated');
    } catch (e: any) {
      this.toastStore!.error(getErrorMsg(e));
      this.init();
      this.updating = false;
    }
  });

  /** Update proposal strategy settings */
  @action.bound public updateProposalStrategySettings = flow(function* (
    this: SettingsStore,
    strategy: models.ProposalStrategy,
    settings, // TODO: define type partial Threshold || Breakpoints[]
    // settings: Partial<Threshold> | Breakpoint[],
  ) {
    this.updating = true;
    const { kind, id } = this.settingsObject!;
    const resp = yield Api.tips.updateProposalStrategySettings(kind, id, strategy, settings);
    if (strategy === 'basic' || strategy === 'dynamic') {
      this.breakpointProposalStrategySettings = undefined;
      this.thresholdProposalStrategySettings = resp && resp.data && resp.data.data;
    }
    if (strategy === 'breakpoint') {
      this.thresholdProposalStrategySettings = undefined;
      this.breakpointProposalStrategySettings = resp && resp.data && resp.data.data.breakpoints;
    }
    this.updating = false;
  });

  @action.bound shouldReinitialize(newSettingsObject: SettingsObject) {
    if (_.isEqual(this.settingsObject, newSettingsObject)) {
      return false;
    }
    return true;
  }

  /** Initialize the store by fetching all the relevant data */
  @action.bound public init() {
    this.fetchSettings();
  }
}
