/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { Link as RouterLink, RouteComponentProps } from 'react-router-dom';
import {
  observable,
  action,
  flow,
  computed,
  reaction,
  IReactionDisposer,
  makeObservable,
} from 'mobx';
import { now } from 'mobx-utils';
import { observer } from 'mobx-react';

import Api, { getErrorMsg, RequestMetaData, ApiResponse } from 'api';
import { AxiosResponse } from 'axios';
import { inject, WithUserStore, WithToastStore, WithModalStore, WithSettingStore } from 'stores';
import { paths } from 'routes';

import { Initiation, InitiationStats, PayoutStats, InitiationStatus } from 'models';

import moment from 'moment-timezone';

import { WithStyles, withStyles } from '@material-ui/core/styles';
import { Typography, Box, Link, Tooltip, Dialog, Grid } from '@material-ui/core';
import { Send, CalendarMonth, CurrencyUsd, AccountSupervisorCircle } from 'mdi-material-ui';
import StatsPanel from 'components/StatsPanel';
import ScheduledPayoutsModal from './ScheduledPayoutsModal';
import styles from './styles';

import { downloadCsvFile, getFileNameFromResponseHeaders } from '../../utils/helper';
import { PayoutInitiationType } from './Deposits';

import { adaptForDataGridPro } from 'services';
import FilterBar from 'components/FilterBar';
import { Filter } from 'components/FilterBar/FilterBar';
import * as DateRangeExternalPicker from 'components/DateRangeExternalPicker';
import DataGridInfiniteScroll from 'components/DataGridInfiniteScroll';
import { ActionCard, HorizontalStatCard, InfoCard } from 'containers/UserDetails/Stats';
import { ACL } from 'types';

/** Define props for this component */
type PayoutInitiationsProps = WithStyles<typeof styles> &
  WithUserStore &
  WithToastStore &
  WithSettingStore &
  RouteComponentProps &
  WithModalStore;

function annotateInitiations(initiation: Initiation) {
  const formattedAmount = parseFloat(initiation.amount || '0').toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
  });
  return {
    ...initiation,
    date: moment(initiation.date).tz('America/New_York'),
    createdAt: moment(initiation.createdAt).tz('America/New_York'),
    finishedAt: moment(initiation.finishedAt).tz('America/New_York'),
    amount: formattedAmount,
  };
}

/** polling rate in milliseconds */
const pollingRate = 10000;

/**
 * Renders 1) a payout panel that shows status of ongoing payout initiation if
 * in progress or when the next one in going to happen and its stats if last
 * one is completed or stopped and 2) initiations table.
 */
@inject('userStore', 'toastStore', 'modalStore', 'settingStore')
@observer
class PartnerPayouts extends React.Component<PayoutInitiationsProps> {
  public constructor(props: PayoutInitiationsProps) {
    super(props);
    makeObservable(this);
    this.disposers.push(
      reaction(
        () => this.lastInitiation && now(pollingRate),
        (x) => x && this.pollStats(),
      ),
    );
  }

  private disposers: IReactionDisposer[] = [];

  /** Active filters as returned by FilterBar */
  @observable private activeFilters: Record<string, unknown> = {};

  /** The selected date range */
  @observable public dateRange: DateRangeExternalPicker.DateRange =
    this.props.settingStore!.getDate(this.props.location.pathname);

  /** Last initiation needed for payout panel but only if running */
  @observable public lastInitiation?: Initiation;

  /** Stats for last initiation if it's running */
  @observable public initiationStats?: InitiationStats;

  /** Payout stats if there is no running initiation */
  @observable public nextPayoutStats?: PayoutStats;

  /** List of initiations for initiations table */
  @observable public initiations?: Initiation[];

  /** Is scheduled payouts modal open?  */
  @observable public scheduledPayoutsModalOpen = false;

  /** Is manual payout initiation in progress? */
  @observable public manualInitiationInProgress = false;

  /**
   * Payout initiation can only be triggered if 1) admin has the right
   * permission for this actions and 2) there is no currently running initiation
   */
  @computed private get canInitiatePayouts() {
    return this.props.userStore!.hasPermission(ACL.INITIATE_PAYOUTS);
  }

  /**
   * Used for forcing datagrid to reload and display last initiation when
   * its status changes from 'running' to 'stopped' or 'completed'
   */
  @observable datagridKey = Date.now();

  /** Compute last initiation's status so we know how to render payout stats panel */
  @computed public get initiationStatus(): InitiationStatus | undefined {
    return this.lastInitiation ? this.lastInitiation.status : undefined;
  }

  @computed public get nextPayoutDate(): string {
    const date = new Date(Date.now());
    const day = date.getDate();
    const hour = date.getHours();
    const minutes = date.getMinutes();

    let past5th = false;
    if (day > 5) {
      past5th = true;
    } else if (day === 5) {
      if (hour > 11) {
        past5th = true;
      } else if (hour === 11) {
        if (minutes > 30) {
          past5th = true;
        }
      }
    }

    if (past5th) {
      date.setMonth(date.getMonth() + 1);
      date.setDate(5);
    }

    return date.toDateString();
  }

  @action.bound private toggleScheduledPayoutsModal() {
    this.scheduledPayoutsModalOpen = !this.scheduledPayoutsModalOpen;
  }

  /** Get last initiation */
  @action.bound public getLastInitiation = flow(function* (this: PartnerPayouts) {
    try {
      const resp = yield Api.tips.getInitiations(
        { pagination: { take: 1, skip: 0 } },
        { type: PayoutInitiationType.PARTNER_WALLETS },
      );
      const initiations = resp.data && resp.data.data;
      this.lastInitiation = initiations && initiations[0];
    } catch (e) {
      this.props.toastStore!.push({ type: 'error', message: getErrorMsg(e) });
    }
  });

  /** Get stats for last initiation. This is used for polling real time data if initiation is running */
  @action.bound public getInitiationStats = flow(function* (this: PartnerPayouts) {
    if (this.lastInitiation) {
      const resp = yield Api.tips.getInitiationStats(this.lastInitiation.id);
      this.initiationStats = resp.data.data;
      // While polling stats for a running initiations we have to check if current
      // count reached that initiation's total count and fetch last initiation again
      // to check if it was really completed (via this.initiationStatus() computed)
      // also refresh the initiations table to get the now completed initiation
      if (parseInt(this.initiationStats!.totalCount) === this.lastInitiation.count) {
        // Delay UI update for the duration of polling rate for a smoother transition
        setTimeout(() => {
          this.getLastInitiation();
          this.datagridKey = Date.now();
        }, pollingRate);
      }
    }
  });

  /** Get stats for next payout. This is used for polling real time data if there is no initiation running */
  @action.bound public getNextPayoutStats = flow(function* (this: PartnerPayouts) {
    const resp: AxiosResponse<ApiResponse<PayoutStats>> = yield Api.tips.getPartnerPayoutStats();
    this.nextPayoutStats = resp.data.data;
  });

  /** Polling initiation stats if initation is running or next payout stats if there is no initiation running */
  @action.bound private pollStats() {
    this.initiationStatus === `running` ? this.getInitiationStats() : this.getNextPayoutStats();
  }

  /** Pagination ready fetch initiations function passed to datagrid */
  @action.bound public fetchInitiations = adaptForDataGridPro(async (rmd: RequestMetaData) => {
    return await Api.tips.getInitiations(
      {
        ...rmd,
        filters: {
          fromDate: this.dateRange.fromDate,
          toDate: this.dateRange.toDate,
          ...this.activeFilters,
        },
      },
      {
        type: PayoutInitiationType.PARTNER_WALLETS,
      },
    );
  }, annotateInitiations);

  /** Sets the date range */
  @action.bound private updateDateRangeValue(range: DateRangeExternalPicker.DateRange) {
    this.props.settingStore!.setDate(this.props.location.pathname, range);
    this.dateRange = range;
    this.activeFilters = { ...this.activeFilters };
  }

  /** Request manual payouts initiation */
  @action.bound public manuallyInitiatePayouts = flow(function* (this: PartnerPayouts) {
    const confirmed = yield this.confirmManualInitiation();
    if (!confirmed) return;
    try {
      this.manualInitiationInProgress = true;
      yield Api.tips.initiatePayouts();
      this.props.toastStore!.push({
        type: 'success',
        message: 'Payout initiation request sent successfully.',
      });
      // Wait 3 seconds to request last initiation, which should now be running ...
      setTimeout(() => {
        this.getLastInitiation();
        // ... and additional second to enable the button
        setTimeout(() => (this.manualInitiationInProgress = false), 1000);
      }, 3000);
    } catch (e) {
      this.props.toastStore!.push({ type: 'error', message: getErrorMsg(e) });
    }
  });

  /** Triggers manual payout initiation confirmation dialog */
  @action.bound public confirmManualInitiation = flow(function* (this: PartnerPayouts) {
    const dialogTitle = 'Initiate ACH payouts manually?';
    const walletCount = this.nextPayoutStats ? parseFloat(this.nextPayoutStats.totalCount) : 0;
    const totalSum = this.nextPayoutStats ? parseFloat(this.nextPayoutStats.totalSum) : 0;
    const dialogBody = (
      <>
        You are about to manually initiate ACH payouts for <b>{walletCount} wallets</b> and{' '}
        <b>total amount of ${totalSum}</b>.<br />
        <br /> Please click <b>confirm</b> to proceed.
      </>
    );
    return yield this.props.modalStore!.confirm(dialogTitle, dialogBody);
  });

  /** Download Csv report */
  @action.bound public async downloadCsvReport() {
    try {
      const res = await Api.analytics.reports.getInitiationPayoutsReport();
      if (res && res.data) {
        const filename = getFileNameFromResponseHeaders(res);
        downloadCsvFile(res.data, filename);
      }
    } catch (error) {
      this.props.toastStore!.push({
        message: 'Unable to download requested report',
        type: 'error',
      });
    }
  }

  componentDidMount() {
    this.getLastInitiation();
  }

  /** Before unmounting the component, dispose of all autoruns created */
  componentWillUnmount() {
    this.disposers.map((disposer) => disposer());
  }

  /** Renders if last initiation is running */
  renderPayoutProcessingPanel() {
    const processed = {
      sum: this.initiationStats ? parseFloat(this.initiationStats.totalSum) : 0,
      count: this.initiationStats ? parseFloat(this.initiationStats.totalCount) : 0,
    };
    const total = {
      sum: this.lastInitiation ? parseFloat(this.lastInitiation.amount) : 0,
      count: this.lastInitiation ? this.lastInitiation.count : 0,
    };
    const failed = {
      sum: this.initiationStats ? parseFloat(this.initiationStats.failedSum) : 0,
      count: this.initiationStats ? parseFloat(this.initiationStats.failedCount) : 0,
    };
    return (
      <StatsPanel>
        <StatsPanel.StaticCell mainText={'in progress'} auxText={'started at 11:30 am'} />
        <StatsPanel.CountupCell value={total.sum} auxText={`${total.count} total`} />
        <StatsPanel.CountupCell value={processed.sum} auxText={`${processed.count} sent`} />
        <StatsPanel.CountupCell value={failed.sum} auxText={`${failed.count} failed`} />
      </StatsPanel>
    );
  }

  /*renderPayoutNextCards() {
    const { classes } = this.props;

    const nextPayout = {
      totalCount: this.nextPayoutStats ? parseFloat(this.nextPayoutStats.totalCount) : 0,
      totalSum: this.nextPayoutStats ? parseFloat(this.nextPayoutStats.totalSum) : 0,
    };
    return (
      <Grid container spacing={1}>
        <Grid item xs={3}>
          <Card className={classes.explanation}>
            <Typography variant="h6" gutterBottom align="left">
              Total
            </Typography>
            <Typography variant="body1" gutterBottom align="left">
              $0
            </Typography>
          </Card>
        </Grid>
        <Grid item xs={3}>
          <Card className={classes.explanation}>
            <Typography variant="h6" gutterBottom align="left">
              Tomorrow
            </Typography>
            <Typography variant="body1" gutterBottom align="left">
              $0
            </Typography>
          </Card>
        </Grid>
        <Grid item xs={3}>
          <Card className={classes.explanation}>
            <Typography variant="h6" gutterBottom align="left">
              Next week
            </Typography>
            <Typography variant="body1" gutterBottom align="left">
              $0
            </Typography>
          </Card>
        </Grid>
        <Grid item xs={3}>
          <Card className={classes.explanation}>
            <Typography variant="h6" gutterBottom align="left">
              Next month
            </Typography>
            <Typography variant="body1" gutterBottom align="left">
              $0
            </Typography>
          </Card>
        </Grid>
      </Grid>
    );
  }*/

  renderPartnerPayoutNextPanel() {
    // const nextPayout = {
    //   totalCount: this.nextPayoutStats ? parseFloat(this.nextPayoutStats.totalCount) : 0,
    //   totalSum: this.nextPayoutStats ? parseFloat(this.nextPayoutStats.totalSum) : 0,
    // };

    return (
      <Grid container spacing={3}>
        <Grid item sm={12} md={6} lg={3}>
          <InfoCard icon={CalendarMonth} title="Next Payout" loading={!this.nextPayoutStats}>
            {`${this.nextPayoutDate} at 11:30am`}
          </InfoCard>
        </Grid>
        <Grid item sm={12} md={6} lg={3}>
          <HorizontalStatCard
            icon={CurrencyUsd}
            color="yellow"
            duration={1}
            title="To be paid out"
            prefix="$"
            separator=","
            decimals={2}
            loading={!this.nextPayoutStats}>
            {this.nextPayoutStats ? parseFloat(this.nextPayoutStats.totalSum) : 0}
          </HorizontalStatCard>
        </Grid>
        <Grid item sm={12} md={6} lg={3}>
          <HorizontalStatCard
            icon={AccountSupervisorCircle}
            color="purple"
            duration={1}
            separator=""
            title="People to pay out"
            loading={!this.nextPayoutStats}>
            {this.nextPayoutStats ? parseFloat(this.nextPayoutStats.totalCount) : 0}
          </HorizontalStatCard>
        </Grid>
        <Grid item sm={12} md={6} lg={3}>
          <ActionCard
            icon={Send}
            disabled={this.manualInitiationInProgress || this.initiationStatus === 'running'}
            onClick={this.manuallyInitiatePayouts}>
            Initiate Payouts
          </ActionCard>
        </Grid>
      </Grid>
    );

    // return (
    //   <StatsPanel>
    //     <StatsPanel.StaticCell
    //       mainText={'next payout'}
    //       auxText={`${this.nextPayoutDate} @ 11:30am`}
    //       auxOnBottom={true}
    //     />
    //     <StatsPanel.CountupCell
    //       value={nextPayout.totalSum}
    //       auxText={'to be paid out'}
    //       auxOnBottom={true}
    //     />
    //     <StatsPanel.CountupCell
    //       value={nextPayout.totalCount}
    //       isCurrency={false}
    //       auxText={'partners to pay out'}
    //       auxOnBottom={true}
    //       iconButton={
    //         <IconButton color="primary" onClick={this.toggleScheduledPayoutsModal}>
    //           <AccountGroup />
    //         </IconButton>
    //       }
    //     />
    //     {this.canInitiatePayouts && (
    //       <StatsPanel.StaticCell
    //         mainText={
    //           <IconButton
    //             color="primary"
    //             onClick={this.manuallyInitiatePayouts}
    //             disabled={this.manualInitiationInProgress || this.initiationStatus === 'running'}>
    //             <Send />
    //           </IconButton>
    //         }
    //         auxText={'Initiate Payouts'}
    //         auxOnBottom={true}
    //       />
    //     )}
    //   </StatsPanel>
    // );
  }

  getPercentageValue = (baseValue: number, value: number): number =>
    Math.round((value / baseValue) * 100);

  exportElements = [
    {
      name: 'Download as CSV',
      action: () => {
        this.downloadCsvReport();
      },
    },
  ];

  renderCellAmount = ({ row }: any) => {
    return (
      <Link component={RouterLink} to={paths.deposits().initiationDetails(row.id)}>
        {row.amount}
      </Link>
    );
  };

  renderCellStatus = ({ row }: any) => {
    const { stats } = row;
    const { classes } = this.props;
    if (stats) {
      const totalCountNumber = parseFloat(stats.totalCount);
      const processedCountNumber = parseFloat(stats.processedCount);
      const failedCountNumber = parseFloat(stats.failedCount);
      const pendingCountNumber = parseFloat(stats.pendingCount);
      return (
        <Box className={classes.progressBarWrapper}>
          {processedCountNumber > 0 && (
            <Tooltip title="Processed">
              <span
                className={classes.processedBar}
                style={{
                  flexBasis: `${this.getPercentageValue(totalCountNumber, processedCountNumber)}%`,
                }}>
                {processedCountNumber}
              </span>
            </Tooltip>
          )}

          {pendingCountNumber > 0 && (
            <Tooltip title="Pending">
              <span
                className={classes.pendingBar}
                style={{
                  flexBasis: `${this.getPercentageValue(totalCountNumber, pendingCountNumber)}%`,
                }}>
                {pendingCountNumber}
              </span>
            </Tooltip>
          )}

          {failedCountNumber > 0 && (
            <Tooltip title="Failed">
              <span
                className={classes.failedBar}
                style={{
                  flexBasis: `${this.getPercentageValue(totalCountNumber, failedCountNumber)}%`,
                }}>
                {failedCountNumber}
              </span>
            </Tooltip>
          )}
        </Box>
      );
    } else return <span>No status</span>;
  };

  gridColumns = [
    {
      headerName: 'Date',
      field: 'date',
      minWidth: 150,
      flex: 1,
      valueGetter: ({ value }: any) => value && moment(new Date(value)).format('MMM DD, YYYY'),
    },
    {
      headerName: 'Started At (EST)',
      field: 'createdAt',
      minWidth: 180,
      flex: 1,
      valueGetter: ({ value }: any) => value && moment(new Date(value)).format('hh:mm A'),
    },
    {
      headerName: 'Ended At (EST)',
      field: 'finishedAt',
      minWidth: 180,
      flex: 1,
      valueGetter: ({ value }: any) => value && moment(new Date(value)).format('hh:mm A'),
    },
    {
      headerName: 'Amount',
      field: 'amount',
      minWidth: 130,
      flex: 1,
      renderCell: this.renderCellAmount,
    },
    { headerName: 'Count', field: 'count', minWidth: 120, flex: 1 },
    {
      headerName: 'Status',
      field: 'status',
      minWidth: 200,
      flex: 1,
      filterable: false,
      renderCell: this.renderCellStatus,
    },
  ];

  filters: Filter[] = [
    {
      display: 'Amount',
      id: 'amount',
      label: 'Contents',
      type: 'range',
      interval: {
        from: { label: 'From Amount', value: 'fromAmount' },
        to: { label: 'To Amount', value: 'toAmount' },
        type: 'number',
      },
    },
  ];

  render() {
    return (
      <>
        <Box mt={3}>
          <FilterBar
            filters={this.filters}
            onChange={(filters: Record<string, unknown>) => {
              this.activeFilters = filters;
            }}
            externalDateRange={{
              predefined: this.dateRange,
              onChange: this.updateDateRangeValue,
            }}
          />
        </Box>
        {this.initiationStatus === 'running'
          ? this.renderPayoutProcessingPanel()
          : this.renderPartnerPayoutNextPanel()}
        <Box mt={3}>
          <DataGridInfiniteScroll
            columns={this.gridColumns}
            fetch={this.fetchInitiations}
            refetchKey={this.activeFilters}
            sortDirection={'DESC'}
            disableColumnMenu
            actions={{
              onExport: this.exportElements,
            }}
            pathname={this.props.location.pathname}
          />
        </Box>
        <Box mt={1} ml={2}>
          <Typography variant="subtitle2">All times are displayed in EST/DST timezone</Typography>
        </Box>
        <Dialog open={this.scheduledPayoutsModalOpen} onClose={this.toggleScheduledPayoutsModal}>
          <ScheduledPayoutsModal closeModal={this.toggleScheduledPayoutsModal} isPartner={true} />
        </Dialog>
      </>
    );
  }
}

export default withStyles(styles)(PartnerPayouts);
