import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import dvr from 'mobx-react-form/lib/validators/DVR';
import validatorjs from 'validatorjs';
import {
  Box,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Typography,
} from '@material-ui/core';
import { Switch } from 'components/Switch/Switch';
import { default as DialogButton } from 'components/Button/Dialog/Button';
import MobxReactForm from 'mobx-react-form';

type MobxForm = any;

const plugins = {
  dvr: dvr(validatorjs),
};

const hooks = {
  onSuccess(form: MobxForm) {},
  onError(form: MobxForm) {},
};

const fields = [
  {
    name: 'message',
    label: 'Alert message',
    rules: ['required'],
  },
  {
    name: 'type',
    label: 'Type',
    type: 'radio',
    rules: ['required'],
  },
  {
    name: 'cause',
    label: 'Cause text',
  },
  {
    name: 'link',
    label: 'Link',
  },
  {
    name: 'severity',
    label: 'Severity',
    type: 'radio',
  },
  {
    name: 'dismissible',
    label: 'dismissible Alert',
    type: 'checkbox',
    rules: 'boolean',
  },
  {
    name: 'alertTargetType',
    label: 'Target Type',
    type: 'radio',
  },
];

const form = new MobxReactForm({ fields }, { plugins, hooks });

import OutlinedInput from 'components/Input/OutlinedInput';
import { Alert, AlertSeverity, AlertStepsDialog, AlertType, NewAlertRequest } from 'models';

interface Props {
  classes: any;
  onCancelAlertDialog: Function;
  onUpdateAlertStepsDialog: Function;
  onUpdateNewAlert: Function;
  currentAlert?: Partial<Alert> | Partial<NewAlertRequest>;
  editAction?: string;
}

const AlertForm: React.FC<Props> = ({
  classes,
  onCancelAlertDialog,
  onUpdateAlertStepsDialog,
  currentAlert,
  onUpdateNewAlert,
}) => {
  useEffect(() => {
    if (currentAlert) {
      form.$('type').set('value', currentAlert.type);
      form.$('severity').set('value', currentAlert?.severity || '');
      form.$('message').set('value', currentAlert?.message || '');
      form.$('cause').set('value', currentAlert?.cause || '');
      form.$('link').set('value', currentAlert?.link || '');
      form
        .$('dismissible')
        .set('value', currentAlert?.dismissible ? currentAlert?.dismissible : false);
    } else {
      form.clear();
    }
  }, [currentAlert]);

  const handleCancelAlertDialog = () => {
    onCancelAlertDialog();
  };

  const handleOnUpdateNewAlert = () => {
    const obj = {
      message: form.select('message').value,
      type: form.select('type').value,
      cause: form.select('cause')?.value || undefined,
      severity: form.select('severity').value || undefined,
      link: form.select('link').value,
      dismissible: form.select('dismissible').value,
    };
    onUpdateNewAlert(obj);
    onUpdateAlertStepsDialog(AlertStepsDialog.TARGET);
  };

  form.$hooks.onSuccess = handleOnUpdateNewAlert;

  return (
    <form onSubmit={form.onSubmit}>
      <Box minWidth={460} display="flex" flexDirection="column">
        {/*Type Control */}
        <Box mb={3}>
          <FormControl margin="none">
            <FormLabel className={classes.formLabel} component="legend">
              Type
            </FormLabel>
            <RadioGroup row>
              <FormControlLabel
                value="issue"
                control={
                  <Radio
                    onClick={(e) => form.$('dismissible').set('value', false)}
                    size="small"
                    color="primary"
                    {...form.$('type').bind({
                      value: AlertType.ISSUE,
                      checked: AlertType.ISSUE === form.$('type').value,
                    })}
                  />
                }
                label="Issue"
              />
              <FormControlLabel
                value="general"
                control={
                  <Radio
                    onClick={(e) => form.$('dismissible').set('value', true)}
                    size="small"
                    color="primary"
                    {...form.$('type').bind({
                      value: AlertType.GENERAL,
                      checked: AlertType.GENERAL === form.$('type').value,
                    })}
                  />
                }
                label="General"
              />
              <FormControlLabel
                value="promotion"
                control={
                  <Radio
                    onClick={(e) => form.$('dismissible').set('value', true)}
                    size="small"
                    color="primary"
                    {...form.$('type').bind({
                      value: AlertType.PROMOTION,
                      checked: AlertType.PROMOTION === form.$('type').value,
                    })}
                  />
                }
                label="Promotion"
              />
              <FormControlLabel
                value="info"
                control={
                  <Radio
                    onClick={(e) => form.$('dismissible').set('value', true)}
                    size="small"
                    color="primary"
                    {...form.$('type').bind({
                      value: AlertType.INFO,
                      checked: AlertType.INFO === form.$('type').value,
                    })}
                  />
                }
                label="Info"
              />
            </RadioGroup>
          </FormControl>
        </Box>
        {(!form.$('type').value || form.$('type').value === AlertType.ISSUE) && (
          <Box mb={3}>
            <FormControl component="fieldset" margin="none">
              <FormLabel className={classes.formLabel} component="legend">
                Severity
              </FormLabel>
              <RadioGroup name="severity" row>
                <FormControlLabel
                  disabled={!form.$('type').value}
                  value="low"
                  control={
                    <Radio
                      size="small"
                      color="primary"
                      {...form.$('severity').bind({
                        value: AlertSeverity.LOW,
                        checked: AlertSeverity.LOW === form.$('severity').value,
                      })}
                    />
                  }
                  label="Low"
                />
                <FormControlLabel
                  disabled={!form.$('type').value}
                  value="medium"
                  control={
                    <Radio
                      size="small"
                      color="primary"
                      {...form.$('severity').bind({
                        value: AlertSeverity.MEDIUM,
                        checked: AlertSeverity.MEDIUM === form.$('severity').value,
                      })}
                    />
                  }
                  label="Medium"
                />
                <FormControlLabel
                  disabled={!form.$('type').value}
                  value="high"
                  control={
                    <Radio
                      size="small"
                      color="primary"
                      {...form.$('severity').bind({
                        value: AlertSeverity.HIGH,
                        checked: AlertSeverity.HIGH === form.$('severity').value,
                      })}
                    />
                  }
                  label="High"
                />
              </RadioGroup>
            </FormControl>
          </Box>
        )}

        <Box mb={3}>
          <OutlinedInput
            {...form.$('message').bind()}
            multiline
            maxRows={3}
            fullWidth
            disabled={!form.$('type').value}
          />
        </Box>

        {(!form.$('type').value || form.$('type').value === AlertType.ISSUE) && (
          <Box mb={3}>
            <OutlinedInput {...form.$('cause').bind()} fullWidth disabled={!form.$('type').value} />
          </Box>
        )}

        <Box mb={3}>
          <OutlinedInput {...form.$('link').bind()} fullWidth disabled={!form.$('type').value} />
          <Typography className={classes.leyendLabel}>
            You can leave link to external webpage with more information. This link will be shown as
            “Learn more” button.
          </Typography>
        </Box>

        <Box mb={3}>
          <Box display={'flex'}>
            <Switch
              {...form.$('dismissible').bind()}
              checked={form.$('dismissible').value}
              color="primary"
              inputProps={{ 'aria-label': 'secondary checkbox' }}
            />
            <Typography className={classes.formLabel} style={{ marginLeft: '8px' }}>
              Dismissible alert
            </Typography>
          </Box>
          <Typography className={classes.leyendLabel}>
            If dismissible alert option is on, user will have an option to dismiss the alert.
          </Typography>
        </Box>
      </Box>
      <Box className={classes.customDialogActions}>
        <DialogButton variant="outlined" onClick={handleCancelAlertDialog} color="primary">
          Cancel
        </DialogButton>
        <DialogButton
          variant="contained"
          onClick={() => {
            form.submit();
          }}
          color="primary"
          disabled={
            !form.$('type').value ||
            (form.$('type').value === AlertType.ISSUE && !form.$('severity').value) ||
            !form.$('message').value
          }>
          Next
        </DialogButton>
      </Box>
    </form>
  );
};
export default observer(AlertForm);
