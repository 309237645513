import { Theme, createStyles } from '@material-ui/core';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default (theme: Theme) =>
  createStyles({
    root: {},
    field: {
      '& .MuiFormHelperText-root': {
        fontSize: '11px',
      },
    },
    checkbox: {
      alignSelf: 'flex-start',
      padding: 0,
      paddingRight: theme.spacing(1),
    },
  });
