import { IStationPool, IStationUser, StationItem, ISelectedLocationPool } from 'models/Station';
import { ISelectedLocationUser } from '../../containers/LocationDetails/QrCodes/blUsers';

export class BlAddStation {
  static countStationUsers(items: any): number {
    if (!items || !items.length) return 0;
    return items.reduce((countedUsers: number, item: any) => {
      if (item.type === StationItem.USER) {
        return countedUsers + 1;
      } else {
        return countedUsers;
      }
    }, 0);
  }

  /**
   * This method maps selected items so that they are in
   * proper form for adding to state
   * @param items selected location pools or selected location users
   * @returns Array of type IStationUser or IStationPool
   */
  static mapItemsForAddingToState(items: ISelectedLocationPool[] | ISelectedLocationUser[]) {
    let selectedUsers = [];
    if (items[0].type === StationItem.POOL) {
      const _items = items as ISelectedLocationPool[];
      selectedUsers = _items!.filter((item: any) => item.selected);
    } else {
      const _items = items as ISelectedLocationUser[];
      selectedUsers = _items!.filter((item: any) => item.selected);
    }
    return selectedUsers!.map((item: ISelectedLocationUser | ISelectedLocationPool) => {
      const { type } = item;
      if (type === StationItem.POOL) {
        return { type: StationItem.POOL, poolId: item.id };
      }
      if (type === StationItem.USER) {
        return { type: StationItem.USER, userId: item.id };
      }
    }) as IStationUser[] | IStationPool[];
  }
}
