import { Theme, createStyles } from '@material-ui/core';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default (theme: Theme) =>
  createStyles({
    icon: {
      color: theme.palette.text.secondary,
      height: 18,
    },
    root: {
      color: theme.palette.grey[500],
      height: theme.spacing(5.375),
      padding: theme.spacing(0),
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    endAdornment: {
      padding: 0,
      '& .MuiSvgIcon-root': {
        marginRight: 0,
        height: 24,
        width: 24,
      },
    },
  });
