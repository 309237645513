import { Theme, createStyles } from '@material-ui/core';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default (theme: Theme) =>
  createStyles({
    emailDialog: {},
    dialogContent: {
      position: 'relative',
      paddingBottom: 0,
    },
    dialogActions: {
      padding: theme.spacing(3),
      paddingTop: theme.spacing(5),
    },
    root: {},
    linearProgressBarBackground: {
      backgroundColor: '#808080a1',
    },
    linearProgressCompled: {
      backgroundColor: '#419175',
    },
    linearProgressProcess: {
      backgroundColor: '#55BF9B',
    },
  });
