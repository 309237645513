/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { observable, action, flow, makeObservable, computed } from 'mobx';
import { observer } from 'mobx-react';
import { WithStyles, withStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';

import { paths } from 'routes';
import { inject, WithToastStore, WithUserStore } from 'stores';
import { User, Profession } from 'models';
import Api, { getErrorMsg } from 'api';
import ProfessionList from 'components/ProfessionPanel/ProfessionList';
import styles from './styles';
import CarouselScreenWrapper from 'components/CarouselScreenWrapper/CarouselScreenWrapper';
import { rootStore } from 'containers/App/App';
import SignupLayout, { TSignupLayoutSubAction } from 'containers/SignupLayout/SignupLayout';

type ProfessionChoiceProps = WithStyles<typeof styles> &
  RouteComponentProps &
  WithToastStore &
  WithUserStore;

/**
 * Displays the prompt to enter additional user data
 */
@inject('toastStore', 'userStore')
@observer
class ProfessionChoice extends React.Component<ProfessionChoiceProps> {
  constructor(props: ProfessionChoiceProps) {
    super(props);
    makeObservable(this);
  }

  /** Whether the info is currently being submitted */
  @observable public submitting = false;

  /** The action to submit the current personal info to the server */
  @action.bound public handleProfessionChange = flow(function* (
    this: ProfessionChoice,
    profession: Profession,
  ) {
    const userStore = this.props.userStore!;
    try {
      this.submitting = true;
      const resp = yield Api.core.updateUser(userStore.user!.id, {
        profession,
      });
      const user: User = resp && resp.data && resp.data.data;
      userStore.setUserData(user);
      this.props.history.push(paths.signUp().location());
    } catch (error: any) {
      this.props.toastStore!.push({
        type: 'error',
        message: getErrorMsg(error) || 'An error has occurred',
      });
    } finally {
      this.submitting = false;
    }
  });

  @computed public get signupLayoutProps() {
    const title = 'Profession';
    const subtitle = 'Choose a profession that best describes what you do';
    const subAction: TSignupLayoutSubAction = {
      back: {
        onClick: this.props.history.goBack,
        label: 'Back to previous step',
      },
    };
    return { title, subtitle, subAction };
  }

  render() {
    const user = this.props.userStore!.user!;
    const { classes } = this.props;
    const { mobileView } = rootStore.uiStore;
    return (
      <CarouselScreenWrapper>
        <SignupLayout {...this.signupLayoutProps}>
          <Box className={classes.scrollbar} maxHeight={mobileView ? 400 : 'auto'}>
            <ProfessionList selected={user.profession} onChange={this.handleProfessionChange} />
          </Box>
        </SignupLayout>
      </CarouselScreenWrapper>
    );
  }
}

export default withStyles(styles)(ProfessionChoice);
