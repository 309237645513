import { MenuItem } from '@material-ui/core';
import BillingCard from 'components/BillingCard';
import { IInvoiceProductData, IInvoiceUpdate, ICorrection, IBillingEntity } from 'models';
import { usdToNumericString } from 'services';
import { IFormFieldsConfig, FORM_SELECT, EFormFields, EFormFieldLabels } from 'utils/forms';

export const getInvoiceProducts = (
  prev: IInvoiceProductData[],
  { locationId, correctionId, isActive, quantity }: IInvoiceUpdate,
) => {
  const thisLoc = prev.find((it) => it.locationId === locationId);
  if (!thisLoc) {
    prev.push({
      locationId,
      products: [{ productId: correctionId, quantity }],
    });

    return [...prev];
  }

  if (!isActive) {
    thisLoc.products = thisLoc.products.filter((it) => it.productId !== correctionId);

    if (!thisLoc.products.length) {
      prev = prev.filter((it) => it.locationId !== locationId);
    }
    return [...prev];
  }

  const thisProd = thisLoc.products.find((it) => it.productId === correctionId);
  if (!thisProd) {
    thisLoc.products.push({
      productId: correctionId,
      quantity,
    });
  } else {
    thisProd.quantity = quantity;
  }

  return [...prev];
};

export const calcLocationPrice = (thisLoc: IInvoiceProductData, correctionsList: ICorrection[]) =>
  thisLoc.products.reduce((sum, product) => {
    const price: string | undefined = correctionsList.find(
      (it) => it.id === product.productId,
    )?.price;
    const numPrice = price ? Number(usdToNumericString(price)) : 0;

    return sum + product.quantity * numPrice;
  }, 0);

export const getPaymentMethodField = (billingEntitites: IBillingEntity[]): IFormFieldsConfig => {
  const options = billingEntitites.map((billingEntity: IBillingEntity) => (
    <MenuItem
      key={`billingEntity-${billingEntity.id}`}
      value={billingEntity.id}
      style={{
        flexDirection: 'column',
        alignItems: 'flex-start',
      }}>
      <BillingCard billingEntity={billingEntity} />
    </MenuItem>
  ));

  return {
    ...FORM_SELECT({
      name: EFormFields.BILLING_ENTITY_ID,
      label: EFormFieldLabels.BILLING_GROUP,
      optionsNodes: options,
      className: options.length ? 'card-holder' : '',
    }),
  };
};
