import React from 'react';
import useStyles from './styles';
import { Box, CardMedia, Typography } from '@material-ui/core';

import JumpingTippy from '../../images/jumping_tippy.gif';

interface SuccessBoxProps {
  color: string;
  text: string;
}

/** Displays a card for a single user */
function SuccessBox(props: SuccessBoxProps) {
  const classes = useStyles();

  const { color = 'green', text } = props;

  return (
    <Box>
      <Typography
        className={classes.text}
        style={{ color }}
        variant="h4"
        component="h1"
        gutterBottom>
        {text}
      </Typography>
      <CardMedia className={classes.tippy} image={JumpingTippy} title={`Jumping Tippy`} />
    </Box>
  );
}

export default SuccessBox;
