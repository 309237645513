import { useStores } from 'containers/App/App';
import { MenuGroup } from 'models/Menu';
import { Drawer } from 'stores';
import { filterMenuItemsByScope } from './helpers';
import { useMemo } from 'react';
import { IFeatureFlags, Industry } from 'models';
import { useFlags } from 'launchdarkly-react-client-sdk';

const useMenu = () => {
  const { uiStore, userStore, managerPermissionsStore } = useStores();
  const flags = useFlags<IFeatureFlags>();
  const { currentAccount, scope, isSp, isManager } = userStore;
  const { managerPermissions } = managerPermissionsStore;
  const { mobileView, drawer, toggleDrawerGroup } = uiStore;
  const industry =
    userStore?.currentAccount?.industry || userStore?.currentManagedLocation?.account?.industry;

  const scopedItems = useMemo(() => {
    const itemProps = {
      accountId: currentAccount?.id,
      hidePendingTips: industry !== Industry.HOSPITALITY,
      isSp,
      isManager,
    };
    return filterMenuItemsByScope(scope.kind, itemProps, flags);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentAccount?.id, flags, industry, scope.kind, isSp, isManager, managerPermissions]);

  const menuOpen = drawer.open;
  const showMenuIcon = !(mobileView || menuOpen);
  const toggleGroup = toggleDrawerGroup;
  const isGroupOpen = (group: MenuGroup) => drawer[group.toLowerCase() as unknown as keyof Drawer];

  return { showMenuIcon, mobileView, scopedItems, toggleGroup, isGroupOpen };
};

export default useMenu;
