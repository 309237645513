import ReactGA from 'react-ga';
import FacebookPixel from 'react-facebook-pixel';

import config from 'config';

const options = {
  autoConfig: true, // set pixel's autoConfig
  debug: false, // disable logs
};

/** Initialize Facebook Pixel tracking service */
export function initFbPixel() {
  if (config.tracking.fbPixelId) {
    FacebookPixel.init(config.tracking.fbPixelId, undefined, options);
  }
}

/** Initialize Google Analytics tracking service */
export function initGa() {
  if (config.tracking.gaId) {
    ReactGA.initialize(config.tracking.gaId);
  }
}
