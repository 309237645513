import { Box, DialogActions, InputAdornment, Tooltip, Typography } from '@material-ui/core';
import { CircularProgress } from '@material-ui/core';
import CreditCardIcon from 'components/CreditCardIcon';
import RefundReasonSelect from 'components/RefundReasonSelect';
import { tipRefundReasons } from 'services';

import React from 'react';
import { useRefundForm } from './useRefundForm';
import { observer } from 'mobx-react';
import { CreateRefundRequestData } from 'models/Refunds';
import Overlay from 'components/Overlay';
import { Information } from 'mdi-material-ui';
import useStyles from './styles';
import OutlinedInput from 'components/Input/OutlinedInput';
import { isRequired } from 'utils/formHelpers';
import Button from 'components/Button/Dialog/Button';

interface IRefundForm {
  name?: string;
  lastFour?: string;
  brand?: string;
  total: string;
  closeModal: () => void;
  onRefund?: (status: string) => void;
  refundRequest?: Partial<CreateRefundRequestData>;
}
export const RefundForm = observer(
  ({ name, total, lastFour, brand, closeModal, onRefund, refundRequest }: IRefundForm) => {
    const [form, refundDisabled, submitting, createRefund, updateAmount] = useRefundForm(
      total,
      closeModal,
      onRefund,
    );
    const classes = useStyles();

    return (
      <Box>
        <Overlay display={!form || submitting} transparent>
          <CircularProgress color="primary" />
        </Overlay>
        <form onSubmit={form.onSubmit}>
          <Box pb={2}>
            <OutlinedInput
              {...form.$('customer').bind()}
              value={`****${lastFour || 'XXXX'} - ${name || 'Cardholder'}`}
              disabled
              fullWidth
              InputProps={{
                startAdornment: <CreditCardIcon brand={brand || ''} />,
              }}
              required={isRequired(form.$('customer'))}
            />
          </Box>
          <Box pb={2}>
            <OutlinedInput
              {...form.$('amount').bind()}
              onChange={updateAmount}
              error={
                form.$('amount').validate({ showErrors: true }) && Boolean(form.$('amount').error)
              }
              helperText={
                form.$('amount').error ? (
                  form.$('amount').error
                ) : (
                  <Box display={'flex'} alignItems={'center'}>
                    {`For partial refunds at least $1 of tip must remain.`}
                    <Tooltip title="Partial refund is a refun where amount is lower than maximum amount">
                      <Information
                        color="primary"
                        style={{ fontSize: '1.1rem', marginLeft: '1rem' }}
                      />
                    </Tooltip>
                  </Box>
                )
              }
              fullWidth
              InputProps={{
                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                endAdornment: (
                  <InputAdornment position="end">
                    <Typography color="textSecondary">${total} Maximum</Typography>
                  </InputAdornment>
                ),
              }}
              required={isRequired(form.$('amount'))}
            />
          </Box>
          <RefundReasonSelect
            selectField={form.$('reason')}
            textField={form.has('customReason') && form.$('customReason')}
            reasons={tipRefundReasons}
          />
          <DialogActions className={classes.dialogActions}>
            <Button variant="outlined" onClick={closeModal}>
              Cancel
            </Button>
            <Button
              variant="contained"
              onClick={() => createRefund(refundRequest!)}
              type="submit"
              disabled={refundDisabled}>
              Refund
            </Button>
          </DialogActions>
        </form>
      </Box>
    );
  },
);
