import React, { Component } from 'react';
import { Box } from '@material-ui/core';
import config from 'config';
import { observable, action, makeObservable } from 'mobx';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const GoogleMapReact = require('google-map-react').default;

/** Here we define what kind of props this component takes */
interface GoogleMapProps {
  width: number | string;
  height: number | string;
  center?: {
    lat?: number;
    lng?: number;
  };
  zoom?: number;
  autoFit?: boolean;
  children?: React.ReactNode | React.ReactNode[];
}

class GoogleMap extends Component<GoogleMapProps> {
   constructor(props: GoogleMapProps) {
     super(props);
     makeObservable(this);
   }

  // This will be the google instance to use google map methods
  @observable private google?: any;

  @action private setGoogle = (google: any) => {
    this.google = google;
  };

  render() {
    const { width, height, center, zoom, children } = this.props;
    return (
      // Important! Always set the container height explicitly
      <Box style={{ width, height }}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: config.google.apiKey }}
          center={center}
          zoom={zoom}
          yesIWantToUseGoogleMapApiInternals
          onGoogleApiLoaded={this.setGoogle}>
          {children}
        </GoogleMapReact>
      </Box>
    );
  }
}

export default GoogleMap;
