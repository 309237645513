import { Backdrop, Box, Container, IconButton, Paper, Typography } from '@material-ui/core';
import clsx from 'clsx';
import { useStyles } from './styles';
import logo from 'images/pig_green_white.svg';
import { useEffect, useMemo } from 'react';
import { Close } from '@mui/icons-material';
import { CompletedCarts } from 'models';
import { InvoiceData } from './InvoiceData/InvoiceData';
import { InvoiceError } from './InvoiceError/InvoiceError';
import { InvoiceFooter } from './InvoiceFooter/InvoiceFooter';
import DP from 'components/DashPanel';

export interface InvoiceReviewProps {
  invoice?: CompletedCarts;
  error?: string;
  isLoading?: boolean;
  close?: () => void;
}

export default function InvoiceReview({ invoice, error, isLoading, close }: InvoiceReviewProps) {
  const classes = useStyles();

  // Prevent body scroll when modal is open
  useEffect(() => {
    document.body.classList.add(classes.bodyOverflow);
    return () => document.body.classList.remove(classes.bodyOverflow);
  }, [classes.bodyOverflow]);

  // Render Invoice Review content
  const renderContent = useMemo(() => {
    if (isLoading) {
      return <DP.Loading items={4} size="large" />;
    }

    return invoice ? <InvoiceData invoice={invoice} /> : <InvoiceError error={error} />;
  }, [error, invoice, isLoading]);

  return (
    <Backdrop className={classes.backdrop} open={true}>
      <Box className={classes.root}>
        {/* Logo here */}
        <Box className={clsx(classes.whiteText, classes.header)}>
          <img src={logo} alt="Tippy" className={classes.pig} />
        </Box>{' '}
        <Container className={classes.container}>
          <Paper>
            <Box className={classes.containerInner}>
              <Box width={'100%'} display={'flex'} justifyContent={'center'} position={'relative'}>
                {/* Header here */}
                <Typography component="h1" className={classes.title}>
                  Invoice Review
                </Typography>
                {close && (
                  <IconButton style={{ right: 0, position: 'absolute' }} onClick={close}>
                    <Close color="inherit" />
                  </IconButton>
                )}
              </Box>
              {/* Content here */}
              {renderContent}
            </Box>
          </Paper>
        </Container>{' '}
        {/* Footer here */}
        <InvoiceFooter />
      </Box>
    </Backdrop>
  );
}
