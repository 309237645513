import { RebateStatus } from 'containers/RevShare/Partners/PartnerDetails/PartnerDetailsDrawer';

/**
 * Represents partner type.
 */
export interface Partner {
  name: string;
  description: string;
  type: PARTNER_TYPE;
  developerId?: number;
  accountId?: number;
  processor?: string;
  payoutProcessor?: string;
  payoutDestination?: string;
  feeAmount?: number;
  feePercent?: number;
  payoutInterval: PAYOUT_INTERVAL;
  rebateStartDate?: string;
  rebateStatus?: RebateStatus;
  wallet?: { processor: string; destination: string };
}

/**
 * Represents fee schedule partner type.
 */
export enum PARTNER_TYPE {
  TIPPY = 'tippy',
  DEVELOPER = 'developer',
  ACCOUNT = 'account',
  PROCESSOR = 'processor',
  OTHER = 'other',
}

/**
 * Represents wallet payout interval.
 */
export enum PAYOUT_INTERVAL {
  NONE = 'none',
  // INSTANT = 'instant',
  DAILY = 'daily',
  WEEKLY = 'weekly',
  MONTHLY = 'monthly',
}
