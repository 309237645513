import React from 'react';
import { Link as RouterLink, Route, RouteComponentProps, Switch } from 'react-router-dom';
import { observer } from 'mobx-react';
import { withStyles, WithStyles } from '@material-ui/core';
import { inject, WithUserStore, WithToastStore, WithModalStore } from 'stores';
import styles from './styles';
import DashboardLayout from 'containers/DashboardLayout';
import { setTitle } from 'services';
import { paths } from 'routes';
import FeeSchedule from './FeeSchedule/FeeSchedule';
import Partners from './Partners/Partners';
import Title from 'components/Title';
import { computed, observable } from 'mobx';
import { ACL } from 'types';
import TabBar from 'components/TabBar/TabBar';

const PAGE_TITLE = {
  REVSHARE: 'Fee Schedule',
};
/** Define props for this component */
type RevShareProps = WithStyles<typeof styles> &
  WithUserStore &
  WithToastStore &
  WithModalStore &
  RouteComponentProps;

@inject('userStore', 'toastStore', 'modalStore')
@observer
class RevShare extends React.Component<RevShareProps> {
  @observable public canPerformActions = !!this.props.userStore?.hasPermission(ACL.REV_SHARE);

  @computed public get tabs() {
    const conversionPaths = paths.revshare();
    const { pathname } = this.props.location;

    return [
      {
        label: 'Fee Schedule',
        component: RouterLink,
        to: conversionPaths.feeSchedule(),
        selected: pathname === conversionPaths.feeSchedule(),
      },
      {
        label: 'Partners',
        component: RouterLink,
        to: conversionPaths.partners(),
        selected: pathname === conversionPaths.partners(),
      },
    ];
  }

  componentDidMount() {
    setTitle(PAGE_TITLE.REVSHARE, { noSuffix: false });
  }

  render() {
    const conversionPaths = paths.revshare();
    return (
      <DashboardLayout>
        <Title mb={3}>RevShare</Title>
        <TabBar mb={3} tabs={this.tabs} />
        <Switch>
          <Route
            path={conversionPaths.feeSchedule()}
            render={(props) => (
              <FeeSchedule {...props} canPerformActions={this.canPerformActions} />
            )}
          />
          <Route
            path={conversionPaths.partners()}
            render={(props) => <Partners {...props} canPerformActions={this.canPerformActions} />}
          />
        </Switch>
      </DashboardLayout>
    );
  }
}

export default withStyles(styles)(RevShare);
