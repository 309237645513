import { Box, Typography } from '@material-ui/core';
import Button from 'components/Button/Button';
import { AlertSeverity, AlertType, NewAlertRequest } from 'models';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBellExclamation } from '@fortawesome/pro-regular-svg-icons';
import { v4 as uuidv4 } from 'uuid';
import clsx from 'clsx';

/** Predefined alert templates for quick creation of a new alert */
const alertTemplates: NewAlertRequest[] = [
  {
    message:
      'Square has encountered unexpected errors. Tippy will be available to process tips as soon as Square resolves the issue.',
    type: AlertType.ISSUE,
    severity: AlertSeverity.HIGH,
    cause: 'square',
    dismissible: false,
  },
  {
    message:
      'Tippy is aware of a possible performance issue caused by Square and will have it resolved shortly.',
    type: AlertType.ISSUE,
    severity: AlertSeverity.MEDIUM,
    cause: 'square',
    dismissible: true,
  },
  {
    message:
      'Tippy is experiencing a possible performance issue and working to have it resolved as soon as possible.',
    type: AlertType.ISSUE,
    severity: AlertSeverity.MEDIUM,
    dismissible: true,
  },
];

const TemplateAlert = ({ alertFromTemplate, createCustomAlert, props }: any) => {
  const classes = props.classes;

  return (
    <Box display="flex" flexDirection="column" justifyContent="center" p={3}>
      <Box mb={2} display="flex" justifyContent="center">
        <Button
          className={classes.customAlertButton}
          onClick={createCustomAlert}
          variant="contained"
          color="primary"
          fullWidth>
          create a custom alert
        </Button>
      </Box>
      <Box mb={3}>
        <Typography className={classes.label} align="center">
          or use a template
        </Typography>
      </Box>
      {alertTemplates.map((tpl: NewAlertRequest, i: number) => {
        return (
          <Box key={uuidv4()} mb={3}>
            <Button
              fullWidth
              onClick={() => alertFromTemplate(alertTemplates[i])}
              className={classes.templateMessage}>
              <Box mr={1}>
                <FontAwesomeIcon
                  icon={faBellExclamation}
                  className={clsx(classes.alertIcon, tpl.severity || 'low')}
                />
              </Box>
              <Typography align="left">{tpl.message}</Typography>
            </Button>
          </Box>
        );
      })}
    </Box>
  );
};

export default TemplateAlert;
