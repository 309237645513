import RootStore from './RootStore';
import BaseStore from './BaseStore';
import { observable, action, makeObservable, computed, IReactionDisposer, autorun } from 'mobx';
import {
  AddStation,
  IStation,
  IStationGroup,
  IStationItem,
  StationItem,
  ISelectedLocationPool,
} from 'models/Station';
import Api, { getErrorMsg } from 'api';
import { Pool } from 'models';
import _, { isArray } from 'lodash';
import { BlPools } from 'containers/LocationDetails/QrCodes/blPools';
import { ISelectedLocationUser } from 'containers/LocationDetails/QrCodes/blUsers';
import validatorjs from 'validatorjs';
import { BlStations } from 'containers/LocationDetails/QrCodes/blStations';
import { BlSettings } from 'containers/LocationDetails/QrCodes/blSettings';

const MobxReactForm = require('mobx-react-form').default;
const dvr = require('mobx-react-form/lib/validators/DVR');

interface IRules {
  [key: string]: {
    function: (value: any, arg1?: any, arg2?: any) => boolean | Promise<boolean>;
    message: string;
  };
}

export interface ICustomLocationUser {
  id: number;
  fullName: string;
  email: string;
}

export enum FormType {
  GROUP = 'group',
  ADD = 'add',
}

/** This store contains info about stations */
export default class StationsStore extends BaseStore {
  constructor(rootStore: RootStore) {
    super(rootStore);
    makeObservable(this);

    this.disposers.push(
      autorun(() => {
        if (this.addStationState) {
          this.initializeForm();
        }
      }),
    );

    this.disposers.push(
      autorun(() => {
        this.resetForms();
        this.addStationState;
      }),
    );
  }

  /**
   * It's good practice to dispose of any autoruns that we set up during
   */
  private disposers: IReactionDisposer[] = [];

  @observable public locationId?: number;
  @observable public accountId?: number;

  @observable public stations?: Array<IStation>;
  @observable public station?: IStation;
  @observable public addStationState?: AddStation;
  /**
   * When we start editing a station, 'previousStationName' field is populated by its name and is used for name validation.
   * We should not show 'Station name already exists' error, if we don't change the station name to another
   * station name that already exists.
   */
  @observable public previousStationName?: string;

  @observable public locationUsers?: Array<ICustomLocationUser>;
  @observable public locationPools?: Array<Pool>;

  @observable public isPoolsEnabled = false; // get from settings
  @observable public selectedPools?: ISelectedLocationPool[];

  @observable public selectedGroup?: IStationGroup;
  @observable public previousGroupName?: string;

  @observable public selectedUsers?: ISelectedLocationUser[];

  @observable public loadingStation = false;

  @observable public groupForm?: typeof MobxReactForm;
  @observable public stationForm?: typeof MobxReactForm;
  @observable private initializedForms = { groupForm: false, stationForm: false };

  @computed get openAddStation() {
    if (!this.addStationState) {
      return false;
    }
    return true;
  }

  @computed get formFocus() {
    const state = this.addStationState;
    if (
      state === AddStation.ADD ||
      state === AddStation.POOLS_GROUPS ||
      state === AddStation.USERS ||
      state === AddStation.POOLS
    ) {
      return FormType.ADD;
    }
    return FormType.GROUP;
  }

  @computed get canInitializeGroupForm() {
    const state = this.addStationState;
    if (state === AddStation.GROUP && this.initializedForms.groupForm === false) {
      return true;
    } else {
      return false;
    }
  }

  @computed get canInitializeStationForm() {
    const state = this.addStationState;
    if (state === AddStation.ADD && this.initializedForms.stationForm === false) {
      return true;
    } else {
      return false;
    }
  }

  @computed get shouldResetGroupForm() {
    const state = this.addStationState;
    if (!this.initializedForms.groupForm) return false;
    if (
      state === AddStation.ADD ||
      state === AddStation.POOLS_GROUPS ||
      state === AddStation.USERS ||
      state === AddStation.POOLS ||
      state === undefined
    ) {
      return true;
    }
    return false;
  }

  @computed get shouldResetStationForm() {
    if (!this.initializedForms.stationForm) return false;
    if (!this.addStationState) return true;
    return false;
  }

  @action.bound setStations(stations: Array<IStation> | undefined) {
    this.stations = stations;
  }

  @action.bound setAddStationState(state?: AddStation) {
    this.addStationState = state;
  }

  @action.bound setPreviousGroupName(name: string) {
    this.previousGroupName = name;
  }

  @action.bound resetPreviousGroupName() {
    this.previousGroupName = undefined;
  }

  @action.bound async getLocationUsers() {
    if (!this.locationId) return;
    try {
      const { data } = await Api.core.getAllLocationUsers(this.locationId);
      if (data && data.data && data.data.length) {
        const locationUsers = data.data.map((locationUser: any) => {
          const user = locationUser.user;
          return { id: user.id, fullName: `${user.firstName} ${user.lastName}`, email: user.email };
        });
        this.locationUsers = _.orderBy(
          locationUsers,
          [(user) => user.fullName.toLowerCase()],
          ['asc'],
        );
      }
    } catch (e: any) {
      this.setAddStationState(undefined);
      this.rootStore.toastStore!.error(getErrorMsg(e));
    }
  }

  @action.bound async getPools() {
    try {
      const pools = await BlPools.getPools(this.accountId!, this.locationId!);
      this.locationPools = pools;
    } catch (e: any) {
      this.setAddStationState(undefined);
      this.rootStore.toastStore!.error(getErrorMsg(e));
    }
  }

  @action.bound setLocationUsers(
    locationUsers: Array<{ id: number; fullName: string; email: string }> | undefined,
  ) {
    this.locationUsers = locationUsers;
  }

  @action.bound initializeNewStation() {
    const newStation = {
      name: '',
      locationId: this.locationId!.toString(),
      items: [],
    };
    this.station = newStation;
  }

  @action.bound async initializeEditStation(station: IStation) {
    let _station = {
      id: undefined,
      uid: undefined,
      name: '',
      locationId: undefined,
      items: [],
    } as IStation;

    _station = { ..._station, ...station };
    this.previousStationName = _station.name;
    this.station = { ..._station! };
    try {
      this.loadingStation = true;
      const fetchedStation = await BlStations.getStation(station.id!);
      let items = BlStations.mapFetchedStationItems(fetchedStation!.items);
      const stationDetails = { ...fetchedStation, items } as IStation;
      await this.getPools();
      await this.getLocationUsers();
      this.station = { ...this.station, ...stationDetails };
      this.updateFormFieldAndValidate('items');
      this.loadingStation = false;
    } catch (e: any) {
      this.rootStore.toastStore.error(getErrorMsg(e));
    }
  }

  @action.bound initializeStationForQrCode(station: IStation) {
    this.station = station;
  }

  @action.bound resetStation() {
    this.previousStationName = undefined;
    this.station = undefined;
  }

  public init() {
    this.getPools();
    this.getLocationUsers();
  }

  @action.bound private initializeForm() {
    if (this.canInitializeGroupForm) {
      this.initializeGroupForm();
      this.initializedForms = { ...this.initializedForms, groupForm: true };
    }
    if (this.canInitializeStationForm) {
      this.initializeStationForm();
      this.initializedForms = { ...this.initializedForms, stationForm: true };
    }
  }

  @action.bound private resetForms() {
    if (this.shouldResetStationForm && this.stationForm) {
      this.resetStationForm();
    }

    if (this.shouldResetGroupForm && this.groupForm) {
      this.resetGroupForm();
    }
  }

  @action.bound initializeGroupForm() {
    const fields = [
      {
        name: 'groupName',
        label: 'Name',
        placeholder: 'Name',
        value: this.selectedGroup!.groupName,
        hooks: {
          onChange: (e: any) => {
            this.changeSelectedField(e, FormType.GROUP);
          },
        },
        rules: 'required|string|min:1|groupName',
      },
      {
        name: 'groupItems',
        label: 'Group Items',
        placeholder: 'Group Items',
        value: this.selectedGroup!.groupItems,
        hooks: {
          onChange: (e: any) => this.changeSelectedField(e, FormType.GROUP),
        },
        rules: 'groupItems',
      },
    ];

    const rules: IRules = {
      groupItems: {
        function: (value: ISelectedLocationPool[]) => {
          if (value.length) return true;
          return false;
        },
        message: `Select at least one ${this.isPoolsEnabled ? 'pool or ' : ''}user.`,
      },
      groupName: {
        function: (value: string, station: IStation, previousGroupName: string | undefined) => {
          if (!previousGroupName && isArray(station.items)) {
            const item = station.items.find(
              (item: IStationItem) => item.type === StationItem.GROUP && item.groupName === value,
            );
            if (item) return false;
            return true;
          }
          return true;
        },
        message: `Group with same name already exists`,
      },
    };

    const plugins = {
      dvr: dvr({
        package: validatorjs,
        extend: ({ validator, form }: { validator: any; form: any }) => {
          Object.keys(rules).forEach((key) => {
            if (key === 'groupName') {
              validator.register(
                key,
                (value: any) => rules[key].function(value, this.station, this.previousGroupName),
                rules[key].message,
              );
            } else {
              validator.register(
                key,
                (value: any) => rules[key].function(value, this.groupForm),
                rules[key].message,
              );
            }
          });
        },
      }),
    };

    this.groupForm = new MobxReactForm({ fields }, { plugins });
  }

  @action.bound initializeStationForm() {
    const fields = [
      {
        name: 'name',
        label: 'Name',
        placeholder: 'Name',
        value: this.station!.name,
        hooks: {
          onChange: (e: any) => {
            this.changeSelectedField(e, FormType.ADD);
          },
        },
        rules: 'name|required|string|min:1',
      },
      {
        name: 'items',
        label: 'Station Items',
        placeholder: 'Group Items',
        value: this.station!.items,
        hooks: {
          onChange: (e: any) => this.changeSelectedField(e, FormType.ADD),
        },
        rules: 'items',
      },
    ];

    const rules: IRules = {
      items: {
        function: (value: ISelectedLocationPool[], form: any) => {
          if (value.length) return true;
          return false;
        },
        message: `Select at least one ${this.isPoolsEnabled ? 'pool, ' : ''}group or user.`,
      },
      name: {
        function: (value: any, stations: IStation[], previousStationName: string | undefined) => {
          if (!stations || !isArray(stations)) return true;
          const sameStationNameExists = stations.some((station: IStation) => {
            return station.name === value && station.name !== previousStationName;
          });
          return sameStationNameExists ? false : true;
        },
        message: `Station with same name already exists`,
      },
    };

    const plugins = {
      dvr: dvr({
        package: validatorjs,
        extend: ({ validator, form }: { validator: any; form: any }) => {
          // here we can access the `validatorjs` instance (validator)
          // and we can add the rules using the `register()` method.
          Object.keys(rules).forEach((key) => {
            if (key === 'name') {
              validator.register(
                key,
                (value: any) => rules[key].function(value, this.stations, this.previousStationName),
                rules[key].message,
              );
            } else {
              validator.register(
                key,
                (value: any) => rules[key].function(value, this.stationForm),
                rules[key].message,
              );
            }
          });
        },
      }),
    };

    this.stationForm = new MobxReactForm({ fields }, { plugins });
  }

  @action.bound public changeSelectedField(e: any, formType: FormType) {
    const { name, value } = e;
    if (formType === FormType.GROUP) this.selectedGroup![name as keyof IStationGroup] = value;
    if (formType === FormType.ADD) this.station![name as keyof IStation] = value;
  }

  @action.bound public setStateField(name: string, value: any) {
    if (this.formFocus === FormType.GROUP) {
      let selectedGroup = { ...this.selectedGroup! };
      selectedGroup![name as keyof IStationGroup] = value;
      this.selectedGroup = selectedGroup;
    }
    if (this.formFocus === FormType.ADD) {
      let station = { ...this.station! };
      station![name as keyof IStation] = value;
      this.station = station;
    }
    this.updateFormFieldAndValidate(name);
  }

  @action.bound resetGroupForm() {
    this.groupForm.dispose();
    this.resetPreviousGroupName();
    this.groupForm = undefined;
    this.initializedForms = { ...this.initializedForms, groupForm: false };
  }

  @action.bound resetStationForm() {
    this.stationForm.dispose();
    this.stationForm = undefined;
    this.initializedForms = { ...this.initializedForms, stationForm: false };
  }

  @action.bound resetSelectedPools() {
    this.selectedPools = undefined;
  }

  @action.bound resetSelectedGroup() {
    this.selectedGroup = undefined;
  }

  @action.bound updateFormFieldAndValidate(name: string) {
    if (this.formFocus === FormType.ADD && this.stationForm) {
      this.stationForm.$(name).set(this.station![name as keyof IStation]);
      this.validateStationFormField(name);
    }
    if (this.formFocus === FormType.GROUP && this.groupForm) {
      this.groupForm.$(name).set(this.selectedGroup![name as keyof IStationGroup]);
      this.validateGroupFormField(name);
    }
  }

  @action.bound validateStationFormField(name: string) {
    this.stationForm.$(name).validate({ showErrors: true });
  }

  @action.bound validateStationForm() {
    this.stationForm.validate({ showErrors: true });
  }

  @action.bound validateGroupFormField(name: string) {
    this.groupForm.$(name).validate({ showErrors: true });
  }

  @action.bound async setLocationId(locationId: number) {
    this.locationId = locationId;
    this.setPoolsEnabled();
  }

  @action.bound private async setPoolsEnabled() {
    if (!this.locationId) return;
    const settings = await BlSettings.getSettings(this.locationId, 'location');
    this.isPoolsEnabled = settings.poolsEnabled;
  }

  @action.bound setAccountId(accountId: number) {
    this.accountId = accountId;
  }
}
export interface WithStationsStore {
  stationsStore?: StationsStore;
}
