import { Theme, createStyles, makeStyles } from '@material-ui/core';

const labelStyle = (theme: Theme) => ({
  padding: 0,
  margin: 0,
  marginTop: theme.spacing(4),
  alignItems: 'start',
  '& .MuiTypography-body1': {
    color: theme.typography.subtitle1.color,
    fontSize: theme.typography.subtitle1.fontSize,
  },
});

const settingsListStyle = (theme: Theme) => ({
  border: '1px solid',
  borderRadius: theme.shape.borderRadius,
  borderColor: theme.palette.grey[200],
  marginTop: theme.spacing(2),
});

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default (theme: Theme) =>
  createStyles({
    root: {
      '& .MuiDrawer-paperAnchorRight': {
        width: 400,
      },
    },
    drawerContent: {
      marginTop: theme.spacing(2),
      paddingBottom: theme.spacing(1),
      '& .MuiTableCell-sizeSmall': {
        padding: 0,
      },
      '& .MuiTableCell-root': {
        padding: 0,
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        borderColor: theme.palette.divider,
        minWidth: 40,
      },
    },
    settingList: settingsListStyle(theme),
    settingListItemKey: {
      textTransform: 'capitalize',
      marginRight: theme.spacing(6),
    },
    settingListItemKeyPrimary: {
      textTransform: 'capitalize',

      marginRight: theme.spacing(6),
      '& .MuiTypography-body1': {
        color: theme.palette.primary.main,
        fontWeight: 800,
      },
    },
    settingListItemValue: {
      display: 'inline',
      textTransform: 'capitalize',
      fontSize: 'small',
    },
    settingListItemIcon: {
      color: theme.palette.grey[500],
      marginLeft: theme.spacing(2),
    },
    inputBig: {
      width: theme.spacing(42),
      '& .MuiInput-root': {
        fontSize: theme.typography.h2.fontSize,
        fontWeight: theme.typography.h1.fontWeight,
        '& .MuiInput-input': {
          textAlign: 'center',
        },
      },
      '& .MuiInputAdornment-root': {
        '& .MuiTypography-root': {
          fontSize: theme.typography.h2.fontSize,
          fontWeight: theme.typography.h1.fontWeight,
          color: theme.palette.primary.main,
        },
      },
    },
    inputSettings: {
      border: `0px`,
      borderRadius: 0,
      borderBottom: `3px solid ${theme.palette.primary.main}`,
    },
    inputBreakpoint: {
      width: theme.spacing(9),
      '& .MuiInputLabel-root': {
        textTransform: 'uppercase',
      },
    },
    inputBreakpointType: {
      width: theme.spacing(5),
      '& .MuiInputLabel-root': {
        textTransform: 'uppercase',
      },
    },
    slider: {
      marginTop: theme.spacing(5),
      '& .MuiSlider-valueLabelCircle': {
        color: theme.palette.primary.main,
      },
      '& .PrivateValueLabel-circle': {
        color: theme.palette.primary.main,
      },
    },
    sliderLabels: {
      textTransform: 'uppercase',
      fontSize: 14,
      fontWeight: 900,
      color: '#111',
    },
    fixedAmountLabels: {
      textTransform: 'uppercase',
      fontSize: 16,
      fontWeight: 400,
      lineHeight: '20px',
      color: '#111',
    },
    tableHead: {
      '& .MuiTableCell-root': {
        fontSize: '14px',
        fontWeight: '400',
        textTransform: 'uppercase',
        padding: 0,
        paddingBottom: theme.spacing(2),
        textAlign: 'left',
      },
    },
    button: {
      padding: 0,
    },
    plusButton: {
      padding: 0,
      paddingBottom: theme.spacing(2),
    },
    suggestedSubmitButton: {
      marginTop: 'auto',
      marginBottom: theme.spacing(3),
      marginRight: theme.spacing(2),
      marginLeft: theme.spacing(2),
    },
    fontPrimary: {
      fontSize: 16,
    },
    fontSecondary: {
      fontSize: 16,
      color: '#111',
    },
    fixedAmountBox: {
      marginBottom: theme.spacing(5),
    },
    dialogActions: {
      padding: theme.spacing(3),
      paddingBottom: theme.spacing(2),
    },
    label: labelStyle(theme),
  });

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    settingList: {
      ...settingsListStyle(theme),
      '& .MuiFormControlLabel-root': {
        alignItems: 'center',
        height: '32px',
        justifyContent: 'space-between',
        marginTop: 0,
        width: '100%',
        '& .MuiFormControlLabel-label': {
          fontSize: '16px',
          color: theme.palette.text.primary,
        },
      },
    },
    splitTipsBox: {
      display: 'flex',
      justifyContent: 'space-between',
      paddingBottom: theme.spacing(1.5),
      paddingTop: theme.spacing(1.5),
      width: '100%',
    },
    splitTipsInfo: {
      alignItems: 'center',
      display: 'flex',
      gap: theme.spacing(2),
    },
    splitTipsStatus: {
      color: theme.palette.text.secondary,
      fontSize: '14px',
    },
    splitTipsIcon: {
      color: theme.palette.text.secondary,
      fontSize: '18px',
      position: 'relative',
      zIndex: 10,
    },
    label: labelStyle(theme),
  }),
);
