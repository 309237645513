import { Theme, createStyles } from '@material-ui/core';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default (theme: Theme) =>
  createStyles({
    root: {},
    searchBox: {
      maxWidth: '772px',
      minWidth: '350px',
    },
    filterDrawer: {
      height: '100%',
      width: '100%',
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
    },
    filterDrawerInner: {
      padding: theme.spacing(3),
      width: theme.spacing(50),
      flex: 1,
      overflow: 'auto',
    },
    actions: {
      padding: theme.spacing(3),
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-end',
    },
    message: {
      fontSize: theme.typography.subtitle1.fontSize,
      color: theme.palette.grey[600],
    },
    userItem: {
      '.MuiGrid-grid-xs-12': {
        flexGrow: 0,
        maxWidth: '100%',
        flexBasis: '100%',
      },
    },
  });
