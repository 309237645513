import React from 'react';

import { observer } from 'mobx-react';

import { IntegrationApp, IntegrationStatus } from 'models';
import { WithStyles, withStyles } from '@material-ui/core/styles';
import { copyToClipboard } from 'services';

import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Paper,
  Tooltip,
  Typography,
} from '@material-ui/core';

import { inject, WithToastStore } from 'stores';

import { ContentCopy, Eye, EyeOff } from 'mdi-material-ui';

import styles from './styles';
import { action, computed, observable, makeObservable } from 'mobx';
import { getErrorMsg } from 'api';

import DP from 'components/DashPanel';
import theme from 'containers/App/theme';

import { default as DialogButton } from 'components/Button/Dialog/Button';
import Button from 'components/Button/Button';
import OutlinedInput from 'components/Input/OutlinedInput';
import clsx from 'clsx';

interface AppCardProps extends WithStyles<typeof styles>, WithToastStore {
  app: IntegrationApp;
  blockIntegrationActions: boolean;
  onGenerateToken: (appId: number, properties: any) => void;
  openDrawer: (app: IntegrationApp) => void;
}

@inject('toastStore')
@observer
class AppCard extends React.Component<AppCardProps> {
  constructor(props: AppCardProps) {
    super(props);
    makeObservable(this);
  }
  @observable public integrationDialogOpen = false;

  @observable public maskToken = true;

  @computed public get isActive() {
    return Boolean(this.props.app.accountToken) && this.props.app.status === 'active';
  }

  @computed public get maskedToken() {
    if (this.props.app.accountToken) {
      const token = this.props.app.accountToken;
      const firstFour = token.substring(0, 4);
      const mask = new Array(token.length - 3).join('*');
      return `${firstFour}${mask}`;
    }
    return undefined;
  }

  @action.bound private toggleIntegrationDialog() {
    this.integrationDialogOpen = !this.integrationDialogOpen;
  }

  @action.bound private toggleDrawer() {
    this.props.openDrawer(this.props.app);
  }

  @action.bound private goToActivationLink() {
    window.open(this.props.app.activation!.activationLink, '_blank');
  }

  @action.bound private toggleTokenMask() {
    this.maskToken = !this.maskToken;
  }

  @observable private integrationProperties: Record<string, string> = {};

  @action.bound private async generateToken() {
    try {
      this.toggleIntegrationDialog();
      await this.props.onGenerateToken(this.props.app.id, this.integrationProperties);
      this.props.toastStore!.success('Token generated successfully');
    } catch (e: any) {
      this.props.toastStore!.error(getErrorMsg(e));
    }
  }

  @action.bound public copy() {
    if (this.props.app.accountToken) {
      copyToClipboard(this.props.app.accountToken);
      this.props.toastStore!.success('Token copied to clipboard');
    }
  }

  @computed get labels() {
    return this.props.app.labels;
  }

  @action.bound private ActionButton(status?: string) {
    const classes = this.props.classes;
    const disabled = this.props.blockIntegrationActions;
    let button;
    switch (status) {
      case 'active':
        button = (
          <Button
            className={clsx(classes.button, classes.manageButton)}
            disabled={disabled}
            variant="outlined"
            color="primary"
            onClick={this.toggleDrawer}>
            manage
          </Button>
        );
        break;
      case 'pending':
        button = (
          <Button disabled={disabled} variant="text" onClick={this.toggleDrawer} color="primary">
            activate
          </Button>
        );
        break;
      default:
        button = (
          <Button
            className={clsx(classes.button, classes.installButton)}
            disabled={disabled}
            variant="contained"
            onClick={this.toggleDrawer}
            color="primary">
            install
          </Button>
        );
        break;
    }

    if (disabled) {
      return (
        <Tooltip
          title={`Cannot perform integration actions because one or more integrations are paused`}>
          <Box component={'span'}>{button}</Box>
        </Tooltip>
      );
    }
    return button;
  }

  integrationPausedText = () => {
    const { app } = this.props;
    if (app.status && app.status === IntegrationStatus.PAUSED) {
      return (
        <Box mb={1} style={{ color: theme.palette.grey[500] }}>
          Paused integration
        </Box>
      );
    }
  };

  render() {
    const classes = this.props.classes;
    const app = this.props.app;
    return (
      <>
        <Paper className={classes.root}>
          <Box
            p={2}
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            width="100%"
            height="100%">
            <Box display="flex" flexDirection="column" alignItems="center">
              <Box className={classes.logoContainer}>
                <img src={app.logo} alt="app_logo" className={classes.logo} />
              </Box>
            </Box>
            <Typography variant="h6" className={classes.appTitle}>
              {app.name}
            </Typography>
            <Box display="flex" flexDirection="column" alignItems="center" mb={2}>
              {this.integrationPausedText()}
              {this.ActionButton(this.props.app.status)}
            </Box>
          </Box>
        </Paper>
        <Dialog open={this.integrationDialogOpen} onClose={this.toggleIntegrationDialog}>
          <Box minWidth={460}>
            <DialogTitle>
              <Typography style={{ fontSize: 28, fontWeight: 400 }}>
                {this.isActive ? 'Integration Details' : 'Integrate Application'}
              </Typography>
            </DialogTitle>
            <DialogContent>
              <DP.Row>
                <DP.Value>{app.name}</DP.Value>
                <DP.Label>name</DP.Label>
              </DP.Row>
              {this.labels &&
                Object.entries(this.labels!).map(([key, value]) => (
                  <Box key={key} mt={2}>
                    <OutlinedInput
                      label={value}
                      onChange={(e) => (this.integrationProperties[key] = e.target.value)}
                    />
                  </Box>
                ))}
              {app.accountToken && (
                <DP.Row>
                  <DP.Label>token</DP.Label>
                  <DP.Value>
                    {this.maskToken ? this.maskedToken : app.accountToken}
                    <Box>
                      {!this.maskToken && (
                        <Tooltip title="Copy to clipboard">
                          <IconButton size="small" color="primary" onClick={this.copy}>
                            <ContentCopy fontSize="small" />
                          </IconButton>
                        </Tooltip>
                      )}
                      <IconButton size="small" onClick={this.toggleTokenMask}>
                        {this.maskToken ? (
                          <Eye color="primary" fontSize="small" />
                        ) : (
                          <EyeOff color="primary" fontSize="small" />
                        )}
                      </IconButton>
                    </Box>
                  </DP.Value>
                </DP.Row>
              )}
            </DialogContent>
            <DialogActions className={classes.dialogActions}>
              <DialogButton onClick={this.toggleIntegrationDialog} color="primary">
                {this.isActive ? 'close' : 'cancel'}
              </DialogButton>
              {!this.isActive && (
                <div>
                  {this.props.app.integrationDirection === 'outbound' && (
                    <DialogButton variant="contained" onClick={this.generateToken} color="primary">
                      confirm
                    </DialogButton>
                  )}
                  {this.props.app.integrationDirection !== 'outbound' && (
                    <DialogButton variant="contained" onClick={this.generateToken} color="primary">
                      generate token
                    </DialogButton>
                  )}
                </div>
              )}
            </DialogActions>
          </Box>
        </Dialog>
      </>
    );
  }
}

export default withStyles(styles)(AppCard);
