import React from 'react';
import { observer } from 'mobx-react';
import { WithStyles, withStyles } from '@material-ui/core/styles';

import { inject, WithUserStore } from 'stores';

import DashboardLayout from 'containers/DashboardLayout';
import LeadsTable from 'components/LeadsTable';
import styles from './styles';
import { setTitle } from 'services';
import { RouteComponentProps } from 'react-router-dom';
import Title from 'components/Title';

const PAGE_TITLE = 'Leads';
/** Define props for this component */
type LeadsProps = WithStyles<typeof styles> & WithUserStore & RouteComponentProps;

/**
 * The Leads container, restricted to users with admin scope.
 */
@inject('userStore')
@observer
class Leads extends React.Component<LeadsProps> {
  public constructor(props: LeadsProps) {
    super(props);
  }
  componentDidMount() {
    setTitle(PAGE_TITLE, { noSuffix: false });
  }
  render() {
    return (
      <DashboardLayout>
        <Title mb={2}>{PAGE_TITLE}</Title>
        <LeadsTable {...this.props} />
      </DashboardLayout>
    );
  }
}

export default withStyles(styles)(Leads);
