import { Theme, createStyles } from '@material-ui/core';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default (theme: Theme) =>
  createStyles({
    formContainer: {
      flex: '1',
      '& .MuiListItem-root': {
        padding: 0,
        margin: 0,
      },
    },
    formSelect: {
      minWidth: '50%',
      marginLeft: '16px',
    },
    pendingTipsTable: {
      minWidth: 650,
    },
    boxLabel: {
      flex: 1,
      marginLeft: 16,
    },
    textField: {
      padding: 0,
      width: 180,
      background: theme.palette.common.white,
      marginLeft: 16,
      marginRight: 16,
      marginBottom: 16,
      borderBottom: `1px solid ${theme.palette.primary.main}`,
      // remove arrows at the end of input
      '& input[type=number]': {
        '-moz-appearance': 'textfield',
      },
      '& input[type=number]::-webkit-outer-spin-button': {
        '-webkit-appearance': 'none',
        margin: 0,
      },
      '& input[type=number]::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
        margin: 0,
      },
      '& .MuiFilledInput-root': {
        background: theme.palette.common.white,
      },
      '& .MuiFilledInput-underline:before': {
        borderBottom: `1px solid ${theme.palette.primary.main}`,
      },
      '& .MuiFilledInput-underline:after': {
        borderBottom: `1px solid ${theme.palette.primary.main}`,
      },
      '& .MuiInputBase-input': {
        fontSize: 24,
      },
      '& .MuiFilledInput-inputHiddenLabel': {
        paddingTop: 0,
        paddingBottom: 0,
      },
      '& .MuiFilledInput-adornedStart': {
        paddingLeft: 0,
      },
      '& .MuiTypography-body1': {
        fontSize: 20,
      },
      '& .MuiTypography-colorTextSecondary': {
        color: theme.palette.primary.main,
      },
    },
    inputAdornment: {
      color: theme.palette.primary.main,
      fontSize: 20,
    },
    selectInput: {
      minWidth: 200,
      height: 64,
      border: 'none',
      background: 'white',
      paddingLeft: 24,
      paddingTop: 17,
      paddingBottom: 16,
      paddingRight: 25,
      borderRadius: theme.shape.borderRadius,
      '& .MuiSelect-icon': {
        top: `calc(50% - 14px)`,
        right: 15,
      },
    },
    fontColor: {
      color: theme.palette.text.secondary,
    },
    filterbarWrapper: {
      flexGrow: 1,
      alignSelf: 'center',
      height: 64,
    },
    iconButton: {
      paddingRight: 0,
      height: 14,
      width: 14,
      color: theme.palette.common.black,
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
    recipientBox: {
      marginTop: theme.spacing(3),
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2.625),
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
  });
