import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import React, { ReactElement } from 'react';

interface ElevationScrollProps {
  children: ReactElement;
}

function ElevationScroll({ children }: ElevationScrollProps) {
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
}

export default ElevationScroll;
