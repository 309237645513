import { makeStyles, createStyles } from '@material-ui/core';

const useStyles = makeStyles(() =>
  createStyles({
    skeleton: {
      width: 100,
      height: 24,
      margin: 0,
    },
  }),
);

export default useStyles;
