import { makeStyles } from '@material-ui/core';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default makeStyles((theme) => ({
  root: {},
  mainFont: {
    textTransform: 'uppercase',
    fontSize: theme.typography.h3.fontSize,
  },
  secondaryFont: {
    textTransform: 'uppercase',
    fontSize: theme.typography.h6.fontSize,
    color: theme.typography.subtitle2.color,
  },
  statusFont: {
    textTransform: 'uppercase',
    fontSize: theme.typography.h3.fontSize,
    color: theme.palette.primary.main,
  },
}));
