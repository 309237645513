import { Theme, createStyles } from '@material-ui/core';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default (theme: Theme) =>
  createStyles({
    root: {},
    noUnderline: {
      '&:hover': {
        textDecoration: 'none',
      },
    },
  });
