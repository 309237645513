export interface IntegrationApp {
  id: number;
  name: string;
  description: string;
  tagline?: string;
  instructions?: string;
  logo: string;
  accountToken?: string;
  activationDate?: string;
  isActive: boolean;
  labels?: { externalDeviceId: string };
  status?: string;
  activation?: { activationCode?: string; activationLink?: string };
  integrationDirection: 'outbound' | 'inbound';
  integrationId?: number;
  type?: string;
}

export interface TalentIntegrationApp {
  id: number;
  name: string;
  identifier: string;
  processor: string;
  logo: string;
  account: { id: number; name: string };
  externalLoginMode?: string;
  loginRedirectUrl?: string;
}

export interface IPendingIntegration {
  appId: number;
  bankAccountId: number;
}

export enum IntegrationStatus {
  /**
   * Integration active
   */
  ACTIVE = 'active',
  /**
   * Integration pending (waiting for confirmation)
   */
  PENDING = 'pending',
  /**
   * Integration canceled
   */
  CANCELED = 'canceled',
  /**
   * Integration deleted
   */
  DELETED = 'deleted',
  /**
   * Integration paused
   */
  PAUSED = 'paused',
}
