import React from 'react';
import { observable, action, computed, makeObservable } from 'mobx';
import { observer } from 'mobx-react';
import { RouteComponentProps } from 'react-router-dom';
import Api, { RequestMetaData } from 'api';
import moment from 'moment-timezone';

import { WithStyles, withStyles } from '@material-ui/core/styles';
import { Link, Box, IconButton, Tooltip } from '@material-ui/core';
import { paths } from 'routes';

import { inject, WithUserStore, WithToastStore } from 'stores';

import { Payout } from 'models';

import DashboardLayout from 'containers/DashboardLayout';
import DP from 'components/DashPanel';

import styles from './styles';
import TransactionTable from 'containers/UserDetails/TransactionTable';
import { ContentCopy } from 'mdi-material-ui';
import { copyToClipboard, parseProcessorResponse } from 'services';

import { Filter } from 'components/FilterBar/FilterBar';
import FilterBar from 'components/FilterBar';
import Title from 'components/Title/Title';

/** The variables that are matched in the URL (match.params) */
interface PayoutTransactionsMatchParams {
  id: string;
}

/** Define props for this component */
type PayoutTransactionsProps = WithStyles<typeof styles> & // Adds the classes prop
  RouteComponentProps<PayoutTransactionsMatchParams> & // Adds the router props (history, match, location)
  WithToastStore &
  WithUserStore; // Adds the userStore prop

/**
 * Show list of transactions belonging to a single payment. Payment reference is
 * passed via match params and list is displayed with datagrid.
 */
@inject('userStore', 'toastStore')
@observer
class PayoutTransactions extends React.Component<PayoutTransactionsProps> {
  public constructor(props: PayoutTransactionsProps) {
    super(props);
    makeObservable(this);
    this.matchParams = this.props.match.params;
  }

  /** Active filters as returned by FilterBar */
  @observable private activeFilters: Record<string, unknown> = {};

  @observable public matchParams: PayoutTransactionsMatchParams;
  @observable public transactionId?: string;
  @observable public payout?: Payout;

  /** Get the payment reference from match params */
  @computed public get payoutId(): number {
    return +this.matchParams.id;
  }

  /** Fetches payout details */
  @action.bound public async fetchPayoutDetails() {
    const resp = await Api.tips.getPayoutDetails(this.payoutId);
    const payout = resp.data.data;
    this.transactionId = payout.transactionId;
    this.payout = payout;
  }

  /** Fetches payout transactions */
  @action.bound public async fetchTransactions(rmd: RequestMetaData) {
    return Api.tips.getPayoutTransactions(this.payoutId, {
      ...rmd,
      filters: { ...this.activeFilters },
    });
  }

  @computed public get shortenedTransactionId() {
    return this.transactionId && this.transactionId.slice(0, 8);
  }

  @computed public get processorResponse() {
    return this.payout && this.payout.processorResponse
      ? parseProcessorResponse(this.payout.processorResponse)
      : undefined;
  }

  @action.bound public copyTransactionIdToClipboard() {
    if (this.transactionId) {
      copyToClipboard(this.transactionId);
    }
    this.props.toastStore!.success('Full transaction ID copied to clipboard');
  }

  async componentDidMount() {
    this.fetchPayoutDetails();
  }

  filters: Filter[] = [{ display: 'Note', id: 'note', label: 'Contains', type: 'text' }];

  render() {
    const { classes } = this.props;
    return (
      <DashboardLayout>
        <Box mb={3}>
          <Title mb={3}>
            Payout{' '}
            <Link
              className={classes.noUnderline}
              component={'a'}
              href={this.transactionId ? paths.dwollaTransaction(this.transactionId) : '#'}>
              {this.shortenedTransactionId || 'N/A'}
            </Link>
            {this.transactionId && (
              <Tooltip title="Copy to clipboard">
                <IconButton color="primary" onClick={this.copyTransactionIdToClipboard}>
                  <ContentCopy />
                </IconButton>
              </Tooltip>
            )}
          </Title>
        </Box>
        <Box mt={3}>
          <FilterBar
            filters={this.filters}
            onChange={(filters: Record<string, unknown>) => {
              this.activeFilters = filters;
            }}
          />
        </Box>
        <Box mb={3}>
          {this.payout ? (
            <DP>
              <DP.Body>
                <DP.Row>
                  <DP.Label>Created on</DP.Label>
                  <DP.Value>
                    {moment(this.payout.createdAt).format('MMM Do YYYY, h:mm:ss a')}
                  </DP.Value>
                </DP.Row>
                <DP.Row>
                  <DP.Label>Status</DP.Label>
                  <DP.Value>{this.payout.status && this.payout.status.toUpperCase()}</DP.Value>
                </DP.Row>
                <DP.Row>
                  <DP.Label>Talent</DP.Label>
                  <DP.Value>
                    {this.payout.talent && this.payout.talent.firstName}{' '}
                    {this.payout.talent && this.payout.talent.lastName}
                  </DP.Value>
                </DP.Row>
                <DP.Row>
                  <DP.Label>Processor</DP.Label>
                  <DP.Value>
                    {this.payout.processor && this.payout.processor.toUpperCase()}
                  </DP.Value>
                </DP.Row>
                <DP.Row>
                  <DP.Label>Wallet</DP.Label>
                  <DP.Value>{this.payout.wallet && this.payout.wallet.address}</DP.Value>
                </DP.Row>
              </DP.Body>
            </DP>
          ) : (
            <DP>
              <DP.Body>
                <DP.Loading />
              </DP.Body>
            </DP>
          )}
        </Box>
        <Box mt={3}>
          <TransactionTable
            fetch={this.fetchTransactions}
            datagridRefetchKey={this.activeFilters}
            // filterable={false}
            // columns={['date', 'time', 'type', 'amount', 'note']}
            {...this.props}
          />
        </Box>
        {this.processorResponse && (
          <Box mt={3}>
            <DP>
              <DP.Header>
                <DP.Title>Processor response</DP.Title>
              </DP.Header>
              <DP.Body>
                {this.processorResponse.traceId && (
                  <DP.Row>
                    <DP.Label>Trace ID</DP.Label>
                    <DP.Value>{this.processorResponse.traceId}</DP.Value>
                  </DP.Row>
                )}
                {this.processorResponse.amount && (
                  <DP.Row>
                    <DP.Label>Amount</DP.Label>
                    <DP.Value>${this.processorResponse.amount}</DP.Value>
                  </DP.Row>
                )}
                {this.processorResponse.createdAt && (
                  <DP.Row>
                    <DP.Label>Created On</DP.Label>
                    <DP.Value>{this.processorResponse.createdAt}</DP.Value>
                  </DP.Row>
                )}
                {this.processorResponse.achId && (
                  <DP.Row>
                    <DP.Label>Individual ACH ID</DP.Label>
                    <DP.Value>{this.processorResponse.achId}</DP.Value>
                  </DP.Row>
                )}
                {this.processorResponse.status && (
                  <DP.Row>
                    <DP.Label>Status</DP.Label>
                    <DP.Value>{this.processorResponse.status}</DP.Value>
                  </DP.Row>
                )}
                {this.processorResponse.errors && (
                  <>
                    <DP.Row>
                      <DP.Label>Errors</DP.Label>
                      <DP.Value>
                        {this.processorResponse.errors[0].message} [code:{' '}
                        {this.processorResponse.errors[0].code}]
                      </DP.Value>
                    </DP.Row>
                    {this.processorResponse.errors.map((e, i) => {
                      if (i > 0) {
                        return (
                          <DP.Row key={i}>
                            <DP.Label>{''}</DP.Label>
                            <DP.Value>
                              {e.message} [code: {e.code}]
                            </DP.Value>
                          </DP.Row>
                        );
                      } else {
                        return null;
                      }
                    })}
                  </>
                )}
              </DP.Body>
            </DP>
          </Box>
        )}
      </DashboardLayout>
    );
  }
}

export default withStyles(styles)(PayoutTransactions);
