import React from 'react';
import { RouteComponentProps, Link as RouterLink } from 'react-router-dom';
import { observable, computed, action, makeObservable } from 'mobx';
import { observer } from 'mobx-react';

import { Box, Link, Paper } from '@material-ui/core';
import { WithStyles, withStyles } from '@material-ui/core/styles';

import { inject, WithUserStore, WithToastStore, WithAnalyticsStore } from 'stores';

import { paths } from 'routes';

import { AxiosResponse } from 'axios';
import Api, { ApiResponse, RequestMetaData, getErrorMsg } from 'api';
import { states, adaptForDataGridPro } from 'services';
import { v4 as uuidv4 } from 'uuid';
import { ChartData, LocationRevenue } from 'models';

import DashboardLayout from 'containers/DashboardLayout';

import { Filter } from 'components/FilterBar/FilterBar';
import FilterBar from 'components/FilterBar';
import DataGridInfiniteScroll from 'components/DataGridInfiniteScroll';
import * as DateRangeExternalPicker from 'components/DateRangeExternalPicker';

import Chart from 'components/Chart';

import styles from '../styles';
import Title from 'components/Title/Title';

interface StateRevenueMatchParams {
  stateLabel: string;
}

type StateRevenueProps = WithStyles<typeof styles> &
  RouteComponentProps<StateRevenueMatchParams> &
  WithUserStore &
  WithAnalyticsStore &
  WithToastStore;

/**
 * Container that displays revenue data for a specific state and selected
 * date range. Chart shows overall revenue for the state, while table data
 * is represented as a list of individual locations and their revenues
 * within that state. State label is passed through match params which
 * is then used as a filter on both api fetch calls (table and chart data)
 * along with selected date range.
 */
@inject('userStore', 'analyticsStore', 'toastStore')
@observer
class StateRevenue extends React.Component<StateRevenueProps> {
  public constructor(props: StateRevenueProps) {
    super(props);
    makeObservable(this);
    this.matchParams = this.props.match.params;
  }

  /** Active filters as returned by FilterBar */
  @observable private activeFilters: Record<string, unknown> = {};

  @observable public matchParams: StateRevenueMatchParams;

  @computed public get stateLabel(): string {
    return this.matchParams.stateLabel;
  }

  @computed public get stateName(): string {
    return states[this.matchParams.stateLabel];
  }

  /** Chart data */
  @observable private chartData?: ChartData;

  /** The selected date range */
  @observable public dateRange: DateRangeExternalPicker.DateRange = DateRangeExternalPicker.getDateRange();

  /**
   * Changes on date range value update and is used to signal
   * datagrid component to refetch the data using new date range
   */
  @computed public get dateRangeKey() {
    return JSON.stringify(this.dateRange && `${this.dateRange.fromDate}-${this.dateRange.toDate}`);
  }

  /** Annotate location revenues table data */
  @action.bound private annotateLocationRevenues = (locationRevenue: LocationRevenue) => {
    return {
      id: uuidv4(),
      ...locationRevenue,
      name: locationRevenue.location ? locationRevenue.location.name : '',
      locationId: locationRevenue.location ? locationRevenue.location.id : null,
      account: locationRevenue.account ? locationRevenue.account.name : '',
    };
  };

  /** Fetch location revenues for selected state */
  @action.bound public fetchRevenues(rmd: RequestMetaData) {
    // Add selected state to filters so we only get locations for that state
    const rmdWithStateFilter = {
      ...rmd,
      filters: { ...rmd.filters, state: this.stateLabel },
    };
    const extraData = {
      // fromDate: this.dateRange && this.dateRange.fromDate,
      // toDate: this.dateRange && this.dateRange.toDate,
    };
    return Api.analytics.getRevenues('location', rmdWithStateFilter, extraData);
  }

  // @action.bound public fetchRevenuesByLocation = adaptForDataGrid(
  //   this.fetchRevenues,
  //   this.annotateLocationRevenues,
  // );

  @action.bound public fetchRevenuesByLocation = adaptForDataGridPro(
    async (rmd: RequestMetaData) => {
      this.fetchChartData();
      return await Api.analytics.getRevenues('location', {
        ...rmd,
        filters: {
          // fromDate: this.dateRange.fromDate,
          // toDate: this.dateRange.toDate,
          state: this.stateLabel,
          ...this.activeFilters,
        },
      });
    },
    this.annotateLocationRevenues,
  );

  /** Sets the date range */
  @action.bound private updateDateRangeValue(range: DateRangeExternalPicker.DateRange) {
    this.dateRange = range;
    this.activeFilters = { ...this.activeFilters };
    this.chartData = undefined;
    this.fetchChartData();
  }

  /** Fetch aggregated data for tip analytics chart */
  @action.bound public async fetchChartData() {
    try {
      const extraReqData = {
        // fromDate: this.dateRange && this.dateRange.fromDate,
        // toDate: this.dateRange && this.dateRange.toDate,
        // Add selected state to filters so we only get chart data for that state
        state: this.stateLabel,
      };
      const resp: AxiosResponse<ApiResponse<ChartData>> = await Api.analytics.getRevenuesChartData(
        extraReqData,
      );
      this.chartData = resp.data && resp.data.data;
    } catch (e: any) {
      this.props.toastStore!.push({ type: 'error', message: getErrorMsg(e) });
    }
  }

  componentDidMount() {
    this.fetchChartData();
  }

  /** Render's the location name */
  renderLocation = ({ row }: any) => {
    return (
      <Link component={RouterLink} to={paths.analytics().locationRevenue(row.locationId)}>
        {row.name}
      </Link>
    );
  };

  gridColumns = [
    {
      headerName: 'Location',
      field: 'name',
      minWidth: 150,
      flex: 1,
      renderCell: this.renderLocation,
    },
    { headerName: 'Account', field: 'account', minWidth: 150, flex: 1 },
    { headerName: 'Gross', field: 'gross', minWidth: 150, flex: 1 },
    { headerName: 'Net', field: 'net', minWidth: 150, flex: 1 },
    {
      headerName: 'Subscription',
      field: 'subscription',
      minWidth: 200,
      flex: 1,
    },
    { headerName: 'Shipping', field: 'shipping', minWidth: 150, flex: 1 },
  ];

  filters: Filter[] = [
    { display: 'Account', id: 'account', label: 'Contains', type: 'text' },
    { display: 'Location', id: 'location', label: 'Contains', type: 'text' },
  ];

  render() {
    return (
      <DashboardLayout>
        <Title mb={3}>{this.stateName}</Title>
        <Box mt={3}>
          <FilterBar
            filters={this.filters}
            onChange={(filters: Record<string, unknown>) => {
              this.activeFilters = filters;
            }}
            // externalDateRange={{
            //   predefined: this.dateRange.type || 'all',
            //   onChange: this.updateDateRangeValue,
            // }}
          />
        </Box>
        <>
          <div style={{ maxWidth: '100%' }}>
            <Chart data={this.chartData} />
            <Box mt={3}>
              <Paper>
                <DataGridInfiniteScroll
                  columns={this.gridColumns}
                  fetch={this.fetchRevenuesByLocation}
                  refetchKey={this.activeFilters}
                  disableColumnMenu
                  pathname={this.props.location.pathname}
                />
              </Paper>
            </Box>
          </div>
        </>
      </DashboardLayout>
    );
  }
}

export default withStyles(styles)(StateRevenue);
