/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

import {
  observable,
  computed,
  action,
  makeObservable,
} from 'mobx';
import { observer } from 'mobx-react';

import Api, { getErrorMsg, RequestMetaData } from 'api';
import { inject, WithToastStore, WithAnalyticsStore, WithUserStore, WithSettingStore } from 'stores';
import { adaptForDataGridPro } from 'services';

import { WithStyles, withStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';

import { Filter } from 'components/FilterBar/FilterBar';
import FilterBar from 'components/FilterBar';
import DataGridInfiniteScroll from 'components/DataGridInfiniteScroll';
import { RouteComponentProps } from 'react-router-dom';
import styles from '../styles';
import * as DateRangeExternalPicker from 'components/DateRangeExternalPicker';
import { downloadCsvFile, formatGridTitleDate } from 'utils/helper';
import { ReconciliationByDate } from 'models';
import { v4 as uuidv4 } from 'uuid';
import { AxiosResponse } from 'axios';

/** Single employee conversion row */
/** Here we define what kind of props this component takes */
interface ByDateProps
  extends WithStyles<typeof styles>,
    WithToastStore,
    WithAnalyticsStore,
    RouteComponentProps,
    WithSettingStore,
    WithUserStore {}

@inject('toastStore', 'analyticsStore', 'userStore', 'settingStore')
@observer
class ByDate extends React.Component<ByDateProps> {
  constructor(props: ByDateProps) {
    super(props);
    makeObservable(this);
  }

  /** Active filters as returned by FilterBar */
  @observable private activeFilters: Record<string, unknown> = {};

  // /** The selected date range */
  // @observable public dateRange: DateRangeExternalPicker.DateRange =
  //   DateRangeExternalPicker.getDateRange('thisMonth');

    /** The selected date range */
    @observable public dateRange: DateRangeExternalPicker.DateRange =
    this.props.settingStore!.getDate(this.props.location.pathname, 'thisMonth');

  @computed get gridColumns() {
    const gridColumns = [
      {
        headerName: 'Date',
        field: 'createdAt',
        minWidth: 120,
        flex: 1,
        valueGetter: ({ value }: any) => value && formatGridTitleDate(value) 

      },
      {
        headerName: 'Location',
        field: 'location',
        minWidth: 120,
        flex: 1,
      },
      {
        headerName: 'Total Tips',
        field: 'tipsSum',
        minWidth: 150,
        flex: 1,
      },
      {
        headerName: 'Total Fees',
        field: 'feesSum',
        minWidth: 150,
        flex: 1,
      },
      {
        headerName: 'Total Draw',
        field: 'paymentTotal',
        minWidth: 150,
        flex: 1,
      },
    ];

    return gridColumns;
  }

  @action.bound downloadCSV = async (
    request: (rmd: RequestMetaData, extraData?: any) => any,
    name: string,
  ) => {
    const userStore = this.props.userStore;
    try {
      const accountId = userStore!.currentAccount && userStore!.currentAccount.id;
      const rmd = {
        filters: {
          fromDate: this.dateRange && this.dateRange.fromDate,
          toDate: this.dateRange && this.dateRange.toDate,
        },
      };
      const extraData = {
        accountId,
      };
      const resp: AxiosResponse<Blob> = await request(rmd, extraData);
      if (resp && resp.data) {
        downloadCsvFile(resp.data, name);
      }
    } catch (e) {
      this.props.toastStore!.push({
        message: getErrorMsg(e), //'Unable to download requested report',
        type: 'error',
      });
    }
  };


  /** Annotates with extra data */
  @action.bound private annotate = (reconciliate: ReconciliationByDate) => {
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    });

    return {
      id: uuidv4(),
      createdAt: reconciliate.createdAt,
      location: reconciliate.location,
      tipsSum: formatter.format(parseFloat(reconciliate.tipsSum)),
      feesSum: formatter.format(parseFloat(reconciliate.feesSum)),
      paymentTotal: formatter.format(parseFloat(reconciliate.paymentTotal)),
    };
  };

  @action.bound public fetchData = adaptForDataGridPro(async (rmd: RequestMetaData) => {
    const userStore = this.props.userStore;
    const accountId = userStore!.currentAccount && userStore!.currentAccount.id;
    const extraReqData = {
      fromDate: this.dateRange && this.dateRange.fromDate,
      toDate: this.dateRange && this.dateRange.toDate,
      ...this.activeFilters,
    };

    return await Api.analytics.getReconciliationByDate(accountId, rmd, extraReqData);
  }, this.annotate);

  // /** Sets the date range */
  // @action.bound private updateDateRangeValue(range: DateRangeExternalPicker.DateRange) {
  //   this.dateRange = range;
  //   this.activeFilters = { ...this.activeFilters };
  // }

  /** Sets the date range */
  @action.bound private updateDateRangeValue(range: DateRangeExternalPicker.DateRange) {
    this.props.settingStore!.setDate(this.props.location.pathname, range);
    this.dateRange = range;
    this.activeFilters = { ...this.activeFilters };
  }

  filters: Filter[] = [
  ];

  exportElements = [
    {
      name: 'Download as CSV',
      action: async  () => {
        await this.downloadCSV(Api.analytics.reports.downloadReconciliationByDateCsv, 'reconciliation-by-date.csv');
      },
    },
  ];

  render() {
    return (
      <>
        <Box>
          <FilterBar
            filters={this.filters}
            onChange={(filters: Record<string, unknown>) => {
              this.activeFilters = filters;
            }}
            externalDateRange={{
              predefined: this.dateRange,
              onChange: this.updateDateRangeValue,
            }}
          />
          <DataGridInfiniteScroll
            columns={this.gridColumns}
            fetch={this.fetchData}
            refetchKey={this.activeFilters}
            disableColumnMenu
            sortDirection="DESC"
            sortByField="createdAt"
            actions={{
              onExport: this.exportElements,
            }}
            pathname={this.props.location.pathname}
          />
        </Box>
      </>
    );
  }
}

export default withStyles(styles)(ByDate);
