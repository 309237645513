import { rootStore } from 'containers/App/App';
import validatorjs from 'validatorjs';
import Api, { getErrorMsg } from 'api';
const dvr = require('mobx-react-form/lib/validators/DVR');
const MobxReactForm = require('mobx-react-form').default;

const plugins = {
  dvr: dvr({
    package: validatorjs,
  }),
};

const hooks = {
  onSuccess: async (form: any) => {
    const { email } = form.values();
    const { toastStore, mfaDialogStore, userStore } = rootStore;
    const { withMfaDialog } = mfaDialogStore;
    const userId = userStore.user!.id;

    const callback = { verify: form.closePhoneDialog };
    const response = await withMfaDialog(() => Api.core.updateEmailV3(email, userId), {
      callback,
    });
    if (response instanceof Error) {
      toastStore.error(getErrorMsg(response));
    } else {
      form.closeEmailDialog();
    }
  },
  onClear: (form: any) => {
    form.clear();
  },
};

const getEmailField = () => {
  const email = rootStore?.userStore?.user?.email || '';
  return [
    {
      name: 'email',
      label: 'Email',
      type: 'email',
      rules: 'required|email|string',
      value: email,
    },
  ];
};

const createNewForm = () => {
  const form = new MobxReactForm(
    { fields: getEmailField() },
    {
      plugins,
      hooks: hooks,
    },
  );
  return form;
};

export default createNewForm;
