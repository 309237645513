import React from 'react';
import styles from '../styles';
import { observer } from 'mobx-react';
import { Typography, WithStyles, withStyles, Zoom } from '@material-ui/core';
import { observable, action, makeObservable, computed } from 'mobx';
import { inject, WithUserStore } from 'stores';
import { Link as RouterLink, RouteComponentProps } from 'react-router-dom';
import { adaptForDataGridPro } from 'services/datagrid';
import { RequestMetaData } from 'api';
import { Box, Link } from '@material-ui/core';
import { paths } from 'routes';
import Api from 'api';
import DashboardLayout from 'containers/DashboardLayout';
import { v4 as uuidv4 } from 'uuid';
import { Amount } from 'components/Amount/Amount';
import OverflowTooltip from 'components/OverflowTooltip';
import { DATE_TYPE, formatLocalDateTime } from 'utils/helper';
import { Filter } from 'components/FilterBar/FilterBar';
import * as DateRangeExternalPicker from 'components/DateRangeExternalPicker';
import DataGridInfiniteScroll from 'components/DataGridInfiniteScroll';
import DrawBalanceCorrectionModal from './DrawBalanceCorrectionModal';
import PlusFabButton from 'components/PlusFabButton/PlusFabButton';
import Title from 'components/Title/Title';

interface TransactionsMatchParams {
  date?: string;
  rawDate?: string;
  drawSourceId: string;
  locationId?: string;
  location?: string;
}

enum DRAW_REASON {
  PAYMENT_RECEIVED = 'Payment received',
  PAYMENT_REFUNDED = 'Payment refunded',
  DRAW_COMPLETED = 'Draw completed',
  DRAW_CORRECTED = 'Draw corrected',
  BALANCE_TRANSFERED_OUT = 'Balance transfered out',
  BALANCE_TRANSFERED_IN = 'Balance transfered in',
  BALANCE_CORRECTED = 'Balance corrected',
}

enum DRAW_TYPE {
  TIP = 'TIP',
  REFUND = 'REFUND',
  DRAW = 'DRAW',
}

type BalancesProps = WithStyles<typeof styles> &
  WithUserStore &
  RouteComponentProps<TransactionsMatchParams>;
@inject('userStore')
@observer
class Transactions extends React.Component<BalancesProps> {
  constructor(props: any) {
    super(props);
    makeObservable(this);
  }

  @observable isAdmin: boolean = this.props.userStore!.scope.kind === 'admin';

  @observable activeFilters: Record<string, unknown> = {};

  /** The selected date range */
  @observable public dateRange: DateRangeExternalPicker.DateRange = DateRangeExternalPicker.getDateRange();

  @observable datagridKey = Date.now();
  @observable processingPanelData:
    | { totalAmountDue: string; totalUpcomingDraws: string }
    | undefined = undefined;

  @observable public openCorrectionModal = false;

  @computed get canMakeCorrection() {
    return this.props.userStore!.isAdmin;
  }

  getType = (obj: any) => {
    if (obj.payment) return DRAW_TYPE.TIP;
    if (obj.refund) return DRAW_TYPE.REFUND;
    if (obj.draw) return DRAW_TYPE.DRAW;
  };

  getTalent = (obj: any) => {
    if (obj.payment)
      return obj.payment.tips.map((tip: any) => {
        return { userName: `${tip.user.firstName} ${tip.user.lastName}`, userId: tip.user.id };
      });
    else return null;
  };

  public fetchTransactions = adaptForDataGridPro(
    (rmd: RequestMetaData) => {
      const drawSourceId = (this.props.location.state as any).drawSourceId as string;
      const date = (this.props.location.state as any).rawDate as string;
      return Api.tips.getTransactions(drawSourceId, {
        ...rmd,
        filters: {
          ...this.activeFilters,
          // fromDate: this.dateRange.fromDate,
          // toDate: this.dateRange.toDate,
          drawSourceId,
          date,
        },
      });
    },
    (drawBalance: any) => {
      return {
        ...drawBalance,
        time: formatLocalDateTime(drawBalance.createdAt, DATE_TYPE.TIME),
        type: this.getType(drawBalance),
        reason: (DRAW_REASON as any)[drawBalance.reason.toUpperCase()],
        talent: this.getTalent(drawBalance),
      };
    },
  );

  /** Sets the date range */
  @action.bound private updateDateRangeValue(range: DateRangeExternalPicker.DateRange) {
    this.dateRange = range;
    this.activeFilters = { ...this.activeFilters };
  }

  @action.bound setOpenCorrectionDialog(): void {
    this.openCorrectionModal = true;
  }

  @action.bound setCloseCorrectionDialog(): void {
    this.openCorrectionModal = false;
  }

  @action.bound refetchTransactions() {
    this.datagridKey = Date.now();
  }

  renderLocation = ({ row }: any) => {
    return (
      <Link component={RouterLink} to={paths.locationDetails(row.locationId)}>
        {row.location}
      </Link>
    );
  };

  renderMoneyCell = ({ value }: any) => {
    return <Amount>{value}</Amount>;
  };

  renderGreenCell = ({ value }: any) => {
    return <Box className={this.props.classes.greenCell}>{value}</Box>;
  };

  renderTalent = ({ row }: any) => {
    if (!row.talent) return <></>;
    const numberOfTalents = row.talent.length;
    const talents = row.talent.map(
      (talent: { userName: string; userId: number }, index: number) => {
        const comma = index < numberOfTalents - 1 && <span style={{ color: 'black' }}>{', '}</span>;
        return (
          <Link
            key={uuidv4()}
            component={RouterLink}
            to={paths.userDetails(talent.userId).root()}
            underline="none">
            {`${talent.userName}`}
            {comma}
          </Link>
        );
      },
    );
    return <OverflowTooltip id={uuidv4()}>{talents}</OverflowTooltip>;
  };

  renderType = ({ row }: any) => {
    const path = row.payment
      ? paths.paymentTips(row.payment.reference)
      : {
          pathname: paths.draw().transactions(),
          state: this.props.location.state,
        };

    return (
      <Link component={RouterLink} to={path} underline="none">
        {row.type}
      </Link>
    );
  };

  gridColumns = [
    {
      headerName: 'Time',
      field: 'time',
      minWidth: 150,
      flex: 1,
    },
    {
      headerName: 'Type',
      field: 'type',
      minWidth: 200,
      flex: 1,
      renderCell: this.renderType,
    },
    {
      headerName: 'Employee',
      field: 'talent',
      minWidth: 200,
      flex: 1,
      renderCell: this.renderTalent,
    },
    {
      headerName: 'Amount',
      field: 'amount',
      minWidth: 200,
      flex: 1,
      renderCell: this.renderMoneyCell,
    },
    {
      headerName: 'Reason',
      field: 'reason',
      minWidth: 150,
      flex: 1,
    },
    {
      headerName: 'Balance',
      field: 'balance',
      minWidth: 120,
      flex: 1,
      renderCell: this.renderMoneyCell,
    },
  ];

  filters: Filter[] = [];

  render() {
    const { drawSourceId, locationId, location, date, rawDate } = this.props.location
      .state as TransactionsMatchParams;
    const { fab } = this.props.classes;
    return (
      <DashboardLayout>
        <Title mb={3}>
          {' '}
          Draw for{' '}
          <Link to={paths.locationDetails(locationId!)} component={RouterLink}>
            {location}
          </Link>{' '}
          on {date}
        </Title>
        <Box>
          {/*<FilterBar
            filters={this.filters}
            onChange={(filters: Record<string, unknown>) => {
              this.activeFilters = filters;
            }} 
            // externalDateRange={{
            //   predefined: this.dateRange.type || 'all',
            //   onChange: this.updateDateRangeValue,
            // }}
          // />*/}
          <DataGridInfiniteScroll
            key={this.datagridKey}
            columns={this.gridColumns}
            fetch={this.fetchTransactions}
            refetchKey={this.activeFilters}
            disableColumnMenu
            pathname={this.props.location.pathname}
          />
        </Box>

        <Box mt={1} ml={2}>
          <Typography variant="subtitle2">All times are displayed in EST timezone</Typography>
        </Box>
        {this.canMakeCorrection && (
          <Zoom in>
            <PlusFabButton onClick={this.setOpenCorrectionDialog} />
          </Zoom>
        )}
        <DrawBalanceCorrectionModal
          open={this.openCorrectionModal}
          drawSourceId={drawSourceId!}
          date={rawDate!}
          close={() => this.setCloseCorrectionDialog()}
          onCorrectionSuccess={() => this.refetchTransactions()}
        />
      </DashboardLayout>
    );
  }
}

export default withStyles(styles)(Transactions);
