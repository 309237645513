import { createStyles, makeStyles } from '@material-ui/core';
import { Theme } from '@mui/material';
import theme from 'containers/App/theme';
import { scrollbarStyles } from 'utils/commonStyles';

const gap = ({ center, gap }: IStyleProps2) => {
  if (!center) return theme.spacing(0);
  return theme.spacing(gap || 2);
};

interface IStyleProps2 {
  center?: boolean;
  gap?: number;
}

export const useStyles = makeStyles<Theme, IStyleProps2>((theme) =>
  createStyles({
    buttonGroup: {
      display: 'flex',
      backgroundColor: '#fff',
      borderRadius: '40px',
      position: 'relative',

      overflowX: 'auto',
      gap,
      justifyContent: ({ center }) => (center ? 'center' : ''),
      ...scrollbarStyles,
    },
    tabBase: {
      fontSize: theme.typography.fontSize,
      textAlign: 'center',
      fontWeight: 400,
      color: theme.palette.text.primary,
      fontFamily: 'EuclidCircularB',
      whiteSpace: 'nowrap',
    },
    contained: {
      padding: theme.spacing(2, 3),
      display: 'flex',
      flexGrow: 1,
      flexShrink: 0,
      flexDirection: 'row',
      lineHeight: '24px',
      letterSpacing: 0.5,
      textTransform: 'capitalize',
      borderRadius: '40px',
      height: '40px',
      color: theme.palette.text.primary,
      '&.selected': {
        borderRadius: '40px',
        backgroundColor: theme.palette.primary.main,
        fontWeight: 400,
      },
    },
    underline: {
      textTransform: 'uppercase',
      '&.selected': {
        fontWeight: 700,
        color: theme.palette.primary.main,
        '& > span': {
          position: 'relative',
          borderBottom: `2px solid ${theme.palette.primary.main}`,
        },
      },
    },
  }),
);
