import React from 'react';
import { inject, WithToastStore, WithUserStore } from '../../stores';
import { observer } from 'mobx-react';
import { WithStyles, withStyles } from '@material-ui/core/styles';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import Api, { getErrorMsg } from '../../api';
import { action, computed, makeObservable, observable } from 'mobx';
import { WithRouterStore } from '../../stores/RouterStore';
import qs from 'qs';
import { paths } from '../../routes';
import { Box, Button, CircularProgress, Icon, Typography } from '@material-ui/core';
import styles from './styles';

interface RosyCallbackMatchParams {
  accountId: string;
}

type RosyCallbackProps = WithStyles<typeof styles> &
  RouteComponentProps<RosyCallbackMatchParams> &
  WithUserStore &
  WithRouterStore &
  WithToastStore;

@inject('userStore', 'routerStore', 'toastStore')
@observer
class RosyCallback extends React.Component<RosyCallbackProps> {
  public constructor(props: RosyCallbackProps) {
    super(props);
    makeObservable(this);
  }

  @observable private merged = false;
  @observable private mergeFailed = false;

  @action.bound private setMerged() {
    this.merged = true;
  }

  async mergeUser(employeeId: number, accountId: string) {
    try {
      await Api.developer.mergeUser({
        accountId: parseInt(accountId),
        // @ts-ignore
        employeeId,
      });
      this.setMerged();
    } catch (e) {
      this.props.toastStore!.error(getErrorMsg(e));
      if (this.redirectWorkspace) {
        this.mergeFailed = true;
      }
    }
  }

  async componentDidMount() {
    const employeeId = this.qsObj.employeeId;
    const accountId = this.props.match.params.accountId;
    this.mergeUser(employeeId, accountId);
  }

  @computed get qsObj(): any {
    return qs.parse(this.props.location.search, { ignoreQueryPrefix: true });
  }

  @computed get redirectWorkspace(): string | undefined {
    return this.qsObj && this.qsObj.workspace ? this.qsObj.workspace : undefined;
  }

  @observable redirectingToWorkspace = false;
  async handleRedirectToWorkspace() {
    await this.integrationSuccessful();
    await this.redirectToWorkspace();
  }

  @action.bound async integrationSuccessful() {
    return new Promise((resolve) =>
      setTimeout(() => {
        this.redirectingToWorkspace = true;
        resolve(true);
      }, 2000),
    );
  }

  async redirectToWorkspace() {
    return new Promise((resolve) =>
      setTimeout(() => {
        this.props.history.push(paths.myAccount().workspace());
        resolve(true);
      }, 1000),
    );
  }

  renderMergeFailed() {
    return (
      <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" mt={6}>
        <Button
          color={'primary'}
          variant="contained"
          onClick={() => this.props.history.push(paths.myAccount().workspace())}>
          Navigate back to workspace
        </Button>
      </Box>
    );
  }

  renderSuccessScreen() {
    const classes = this.props.classes;
    if (this.redirectWorkspace) {
      this.handleRedirectToWorkspace();
    }
    if (this.redirectingToWorkspace) {
      return (
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          mt={6}>
          <CircularProgress size={'100px'} />
          <Box mt={6} />
          <Typography variant="h5">Redirecting to workspace</Typography>
        </Box>
      );
    }

    return (
      <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
        <Icon color="primary" className={classes.statusIcon}>
          check
        </Icon>
        <Typography variant="h5">Rosy integration successful</Typography>
        {!this.redirectWorkspace && (
          <Box mb={2} mt={6}>
            <Button
              size="large"
              variant="contained"
              color="primary"
              fullWidth
              onClick={() => {
                this.props.history.push(paths.signUp().profilePicture());
              }}>
              continue
            </Button>
          </Box>
        )}
      </Box>
    );
  }

  render() {
    if (this.merged) {
      return this.renderSuccessScreen();
    }
    if (this.mergeFailed) {
      return this.renderMergeFailed();
    }

    return <div />;
  }
}

export default withRouter(withStyles(styles)(RosyCallback));
