import config from "config";
import { IStation } from "models/Station";

export class BlQrCode {
  static createStationLink(station: IStation) {
    const code = station!.uid!;
    const typeAnnotation = 'S';
    const link = `${typeAnnotation}${code}`;
    return `${config.tippyGo.baseUrl}/${link}`;
  }
}
