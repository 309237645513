import React from 'react';
import { observable, action, computed, flow, makeObservable } from 'mobx';
import { observer } from 'mobx-react';
import { RouteComponentProps, Link as RouterLink } from 'react-router-dom';
import Api, { RequestMetaData } from 'api';
import { paths } from 'routes';
import { startCase } from 'lodash';

import moment from 'moment-timezone';

import { Payout, Chunk } from 'models';

import { WithStyles, withStyles } from '@material-ui/core/styles';
import { Typography, IconButton, Box, Tooltip, Link, Chip } from '@material-ui/core';
import { Eye } from 'mdi-material-ui';

import { inject, WithUserStore } from 'stores';

import { Initiation, InitiationStats } from 'models';

import { numericStringToUsd } from 'services/currency';

import DashboardLayout from 'containers/DashboardLayout';
import { adaptForDataGridPro } from 'services/datagrid';
import StatsPanel from 'components/StatsPanel';
import TabBar from 'components/TabBar/TabBar';

import DataGridInfiniteScroll from 'components/DataGridInfiniteScroll';
import { Filter } from 'components/FilterBar/FilterBar';
import FilterBar from 'components/FilterBar';
import * as DateRangeExternalPicker from 'components/DateRangeExternalPicker';
import styles from './styles';

/** The variables that are matched in the URL (match.params) */
interface InitiationDetailsMatchParams {
  id: string;
}

/** Define props for this component */
type InitiationDetailsProps = WithStyles<typeof styles> & // Adds the classes prop
  RouteComponentProps<InitiationDetailsMatchParams> & // Adds the router props (history, match, location)
  WithUserStore; // Adds the userStore prop

function annotatePayoutsList(payout: Payout) {
  return {
    ...payout,
    talentName: `${payout.talent.firstName} ${payout.talent.lastName}`,
    processor:
      payout.processor && payout.transactionId
        ? `${startCase(payout.processor)} - ${payout.transactionId.slice(0, 4)}`
        : '',
    amount: payout.amount && numericStringToUsd(payout.amount.toString()),
    status: payout.status.toUpperCase(),
    sentAt: moment(payout.createdAt).tz('America/New_York'),
  };
}

function annotateChunksList(chunk: Chunk) {
  return {
    ...chunk,
    status: chunk.status.toUpperCase(),
    amount: numericStringToUsd(chunk.amount.toString()),
  };
}

enum TAB {
  CHUNKS = 'chunks',
  PAYOUTS = 'payouts',
}

/**
 * Show list of payouts and chunks belonging to a single payment. Payment reference is
 * passed via match params and list is displayed with datagrid.
 */
@inject('userStore')
@observer
class InitiationDetails extends React.Component<InitiationDetailsProps> {
  public constructor(props: InitiationDetailsProps) {
    super(props);
    makeObservable(this);
    this.matchParams = this.props.match.params;
  }

  /** Active filters as returned by FilterBar */
  @observable private activeFilters: Record<string, unknown> = {};

  /** The selected date range */
  @observable public dateRange: DateRangeExternalPicker.DateRange =
    DateRangeExternalPicker.getDateRange();

  @observable public matchParams: InitiationDetailsMatchParams;

  @observable public initiationDetails?: Initiation;

  @observable public initiationStats?: InitiationStats;

  @observable public selectedTab: TAB.CHUNKS | TAB.PAYOUTS = TAB.CHUNKS;

  /** Get the payment reference from match params */
  @computed public get initiationId(): number {
    return parseInt(this.matchParams.id);
  }

  @action.bound public getInitiationStats = flow(function* (this: InitiationDetails) {
    if (this.initiationId) {
      const resp = yield Api.tips.getInitiationStats(this.initiationId);
      this.initiationStats = resp.data.data;
    }
  });

  @action.bound public getInitiationDetails = flow(function* (this: InitiationDetails) {
    const resp = yield Api.tips.getInitiationDetails(this.initiationId);
    this.initiationDetails = resp.data.data;
  });

  @action.bound public fetchInitiationChunks = adaptForDataGridPro(async (rmd: RequestMetaData) => {
    return await Api.tips.getInitiationChunks(this.initiationId, {
      ...rmd,
      filters: {
        ...this.activeFilters,
      },
    });
  }, annotateChunksList);

  @action.bound public fetchPayouts = adaptForDataGridPro(async (rmd: RequestMetaData) => {
    return await Api.tips.getInitiationPayouts(this.initiationId, {
      ...rmd,
      filters: {
        ...this.activeFilters,
      },
    });
  }, annotatePayoutsList);

  /** Set active tab */
  @action.bound public setActiveTab(tabname: TAB): void {
    this.selectedTab = tabname;
  }

  /** Sets the date range */
  @action.bound private updateDateRangeValue(range: DateRangeExternalPicker.DateRange) {
    this.dateRange = range;
    this.activeFilters = { ...this.activeFilters };
  }

  @computed public get tabs() {
    return [
      {
        label: 'Chunks',
        selected: this.selectedTab === TAB.CHUNKS,
        onClick: () => this.setActiveTab(TAB.CHUNKS),
      },
      {
        label: 'Payouts',
        selected: this.selectedTab === TAB.PAYOUTS,
        onClick: () => this.setActiveTab(TAB.PAYOUTS),
      },
    ];
  }

  componentDidMount() {
    this.getInitiationDetails();
    this.getInitiationStats();
  }

  renderTalentCell = ({ row }: any) => {
    return (
      <Link component={RouterLink} to={paths.userDetails(row.talent.id).root()}>
        {row.talent.firstName} {row.talent.lastName}
      </Link>
    );
  };

  renderProcessorCell = ({ row }: any) => (
    <Link component={'a'} href={paths.dwollaTransaction(row.transactionId || '')}>
      {row.processor}
    </Link>
  );

  renderActions = ({ row }: any) => {
    return (
      <>
        <IconButton color="primary" component={RouterLink} to={paths.payoutsTransactions(row.id)}>
          <Tooltip title="See transactions">
            <Eye fontSize="small" />
          </Tooltip>
        </IconButton>
      </>
    );
  };

  renderPayoutCompleted() {
    const date =
      this.initiationDetails &&
      moment(this.initiationDetails!.date).tz('America/New_York').format('MMM D, YYYY');
    const startHour =
      this.initiationDetails &&
      moment(this.initiationDetails!.createdAt).tz('America/New_York').format('H:mm A');
    const finishHour =
      this.initiationDetails &&
      moment(this.initiationDetails!.finishedAt).tz('America/New_York').format('H:mm A');

    const completedDate = `${date || 'N/A'} ${startHour || 'N/A'} - ${finishHour || 'N/A'}`;

    const status = this.initiationDetails ? this.initiationDetails.status : '';

    const total = {
      sum: this.initiationStats ? parseFloat(this.initiationStats.totalSum) : 0,
      count: this.initiationStats ? parseFloat(this.initiationStats.totalCount) : 0,
    };
    const processed = {
      sum: this.initiationStats ? parseFloat(this.initiationStats.processedSum) : 0,
      count: this.initiationStats ? parseFloat(this.initiationStats.processedCount) : 0,
    };
    const failed = {
      sum: this.initiationStats ? parseFloat(this.initiationStats.failedSum) : 0,
      count: this.initiationStats ? parseFloat(this.initiationStats.failedCount) : 0,
    };
    return (
      <StatsPanel>
        <StatsPanel.StaticCell mainText={status} auxText={completedDate} />
        <StatsPanel.CountupCell value={total.sum} auxText={`${total.count} total`} />
        <StatsPanel.CountupCell value={processed.sum} auxText={`${processed.count} sent`} />
        <StatsPanel.CountupCell value={failed.sum} auxText={`${failed.count} failed`} />
      </StatsPanel>
    );
  }

  renderLink = ({ value, row }: any) => {
    const { id } = this.props.match.params;
    return (
      <Link component={RouterLink} to={`${paths.deposits().chunkDetails(id, row.id)}`}>
        {value}
      </Link>
    );
  };

  renderStatus = ({ value, row }: any) => {
    const { chipCompleted, chipProcessed, chipPending, chipFailed } = this.props.classes;
    const colors: Record<any, any> = {
      COMPLETED: chipCompleted,
      PROCESSED: chipProcessed,
      PENDING: chipPending,
      FAILED: chipFailed,
    };

    return <Chip label={value} style={{ width: '100%' }} classes={{ root: colors[value] }} />;
  };

  gridPayoutsColumns = [
    {
      headerName: 'Talent',
      field: 'search',
      width: 200,
      flex: 1,
      renderCell: this.renderTalentCell,
    },

    {
      headerName: 'Sent At',
      field: 'sentAt',
      width: 120,
      flex: 1,
      valueGetter: ({ value }: any) => value && moment(new Date(value)).format('h:mm A'),
    },
    {
      headerName: 'Processor',
      field: 'processor',
      width: 200,
      flex: 1,
      renderCell: this.renderProcessorCell,
    },
    {
      headerName: 'Amount',
      field: 'amount',
      width: 150,
      flex: 1,
    },
    { headerName: 'Status', field: 'status', width: 180, renderCell: this.renderStatus },
    {
      headerName: 'Actions',
      field: 'actions',
      type: 'actions',
      width: 110,
      renderCell: this.renderActions,
    },
  ];

  gridChunksColumns = [
    {
      headerName: 'Date',
      field: 'createdAt',
      width: 210,
      flex: 1,
      valueGetter: ({ value }: any) => value && moment(new Date(value)).format('MMM DD, YYYY'),
    },
    {
      headerName: 'Identifier',
      field: 'identifier',
      width: 200,
      flex: 1,
    },
    {
      headerName: 'Amount',
      field: 'amount',
      width: 200,
      flex: 1,
      renderCell: this.renderLink,
    },
    { headerName: 'Count', field: 'count', width: 180, flex: 1 },
    { headerName: 'Status', field: 'status', width: 180, renderCell: this.renderStatus },
  ];

  filters: Filter[] = [
    {
      display: 'Status',
      id: 'status',
      label: 'One of',
      type: 'select',
      items: [
        { label: 'COMPLETED', value: 'completed' },
        { label: 'PROCESSED', value: 'processed' },
        { label: 'PENDING', value: 'pending' },
        { label: 'FAILED', value: 'failed' },
      ],
    },
  ];

  render() {
    return (
      <DashboardLayout>
        <Typography variant="h4" component="h1" gutterBottom>
          Initiation Stats
        </Typography>
        <Box>
          <TabBar mb={3} tabs={this.tabs} />
          <FilterBar
            filters={this.filters}
            onChange={(filters: Record<string, unknown>) => {
              this.activeFilters = filters;
            }}
            // externalDateRange={{
            //   predefined: this.dateRange.type || 'all',
            //   onChange: this.updateDateRangeValue,
            // }}
          />
        </Box>
        <Box mt={2} mb={2}>
          {this.renderPayoutCompleted()}
        </Box>

        {/* Payouts table */}
        {this.selectedTab === TAB.PAYOUTS && (
          <DataGridInfiniteScroll
            columns={this.gridPayoutsColumns}
            fetch={this.fetchPayouts}
            refetchKey={this.activeFilters}
            disableColumnMenu
            pathname={this.props.location.pathname}
          />
        )}

        {/* Chunks table */}
        {this.selectedTab === TAB.CHUNKS && (
          <DataGridInfiniteScroll
            columns={this.gridChunksColumns}
            fetch={this.fetchInitiationChunks}
            refetchKey={this.activeFilters}
            disableColumnMenu
            pathname={this.props.location.pathname}
          />
        )}
        {/* footer */}
        <Box mt={1} ml={2}>
          <Typography variant="subtitle2">All times are displayed in EST/DST timezone</Typography>
        </Box>
      </DashboardLayout>
    );
  }
}

export default withStyles(styles)(InitiationDetails);
