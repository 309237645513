import { Theme, createStyles } from '@material-ui/core';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default (theme: Theme) =>
  createStyles({
    root: {},
    negativeNumber: {
      color: theme.palette.error.main,
    },
    positiveNumber: {
      color: theme.palette.primary.main,
    },
  });
