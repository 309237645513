import theme from 'containers/App/theme';

export const referralTermsBtnStyles = {
  color: theme.palette.text.secondary,
  userSelect: 'none',
  '& .terms': {
    color: theme.palette.primary.main,
    fontWeight: 700,
    textDecoration: 'underline',
    '&:hover': {
      textDecoration: 'none',
    },
  },
};
