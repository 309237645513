import { Box, Typography } from '@material-ui/core';
import clsx from 'clsx';
import UsersAutocomplete from 'components/UsersAutocomplete';
import { useStyles } from './styles';
import { IUserTip, IUserTipList, Tip, User } from 'models';
import { useCallback, useEffect, useMemo, useState } from 'react';
import Api, { getErrorMsg } from 'api';
import { SplitTipSingle } from './SplitTipSingle/SplitTipSingle';
import { usdToNumericString } from 'services';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass } from '@fortawesome/pro-regular-svg-icons';
import { rootStore } from 'containers/App/App';

interface SplitTipNewProps {
  tip: Tip;
  setSplitTipsData: (data?: IUserTipList) => void;
}

const SplitTip = ({ tip, setSplitTipsData }: SplitTipNewProps) => {
  const classes = useStyles();
  const {
    root,
    subtitle,
    userItems,
    userItem,
    userLabels,
    userItemAmount,
    userItemAmountLabel,
    usersContainer,
  } = classes;

  const [locationUsers, setLocationUsers] = useState<User[]>([]);
  const [locationUsersSelected, setLocationUsersSelected] = useState<IUserTip[]>([
    {
      id: tip.user.id,
      name: `${tip.user.firstName} ${tip.user.lastName}`,
      amount: usdToNumericString(tip.net),
      avatar: tip.user.avatar,
    },
  ]);
  const { toastStore } = rootStore;

  const sendData = useCallback(
    (items: IUserTip[]) => {
      const users = items
        .filter((it) => Number(it.amount) > 0 && it.id !== tip.user.id)
        .map((that) => ({ targetSpId: that.id, amount: that.amount }));

      // If nothing is changed, send empty data
      if (!users.length) {
        setSplitTipsData(undefined);
        return;
      }

      setSplitTipsData({
        users,
      });
    },
    [setSplitTipsData, tip.user.id],
  );

  useEffect(() => {
    async function fetchData() {
      try {
        const locationId = tip.locationId;
        const userResp = await Api.tips.getActiveLocationUsers(locationId);
        const users: User[] = userResp?.data?.data;
        setLocationUsers(users || []);
      } catch (e: any) {
        toastStore.error(getErrorMsg(e));
      }
    }
    fetchData();
  }, [tip.locationId, toastStore]);

  const availableUsers: User[] = useMemo(() => {
    if (!locationUsers.length) {
      return [];
    }

    return locationUsers
      .filter((it) => !locationUsersSelected.some((that) => it.id === that.id))
      .sort(
        (a: User, b: User) =>
          (a.firstName || '').localeCompare(b.firstName || '') ||
          (a.lastName || '').localeCompare(b.lastName || ''),
      );
  }, [locationUsers, locationUsersSelected]);

  const setUser = (user: User | null) => {
    if (!user) {
      return;
    }

    setLocationUsersSelected((prev: IUserTip[]) => [
      ...prev,
      { id: user.id, avatar: user.avatar, name: `${user.firstName} ${user.lastName}`, amount: '' },
    ]);
  };

  const removeUserHandler = (userId: number) => {
    setLocationUsersSelected((prev: IUserTip[]) => {
      // Remove this user from the list of selected users
      const filteredPrev = [...prev.filter((it) => it.id !== userId)];
      const firstEl = filteredPrev[0];

      // Get sum of total splitted amounts
      let total = 0;
      for (const item of filteredPrev) {
        if (item.id === firstEl.id) {
          continue;
        }
        total = total + Number(item.amount);
      }

      // Recalculate available amount
      firstEl.amount = (
        Math.round(Number(Number(usdToNumericString(tip.net)) - total) * 100) / 100
      ).toFixed(2);

      sendData(filteredPrev);
      return [...filteredPrev];
    });
  };

  const setAmountHandler = (id: number, value: string) => {
    setLocationUsersSelected((prev: IUserTip[]) => {
      const firstEl = prev[0];
      const thisEl = prev.find((it) => it.id === id);

      if (!thisEl) {
        return prev;
      }

      if (value) {
        // Number with max 2 decimals
        const regex = new RegExp(/^\s*-?\d+(\.\d{1,2})?\s*$/);
        const test = regex.test(value);
        if (!test) {
          return prev;
        }
      }

      // Get sum of total splitted amounts
      let total = 0;
      for (const item of prev) {
        if (item.id === firstEl.id) {
          continue;
        }
        if (item.id === id) {
          total = total + Number(value);
          continue;
        }

        total = total + Number(item.amount);
      }

      // If there is no more amount left to be split
      if (total > Number(usdToNumericString(tip.net))) {
        return prev;
      }

      // Asign new amount to current element
      thisEl.amount = String(value);
      // Recalculate available amount
      firstEl.amount = (
        Math.round(Number(Number(usdToNumericString(tip.net)) - total) * 100) / 100
      ).toFixed(2);

      sendData(prev);
      return [...prev];
    });
  };

  return (
    <Box className={root}>
      <Box>
        <Typography className={subtitle} variant="h4" component="h1">
          You will split <span>{tip.net}</span> tip from{' '}
          {`${tip.user?.firstName} ${tip.user?.lastName}`}
        </Typography>

        <UsersAutocomplete
          users={availableUsers}
          startAdornment={<FontAwesomeIcon icon={faMagnifyingGlass} fontSize={24} />}
          clearValueOnSelect={true}
          onChange={setUser}
        />

        {/* Selected Users */}
        <Box className={userItems}>
          <Box className={clsx(userItem, userLabels)}>
            <Box>Talent</Box>
            <Box className={clsx(userItemAmount, userItemAmountLabel)}>Tip Amount</Box>
          </Box>

          <Box className={usersContainer}>
            {locationUsersSelected.map((it) => (
              <SplitTipSingle
                key={it.id}
                defaultUserTipId={tip.user.id}
                userTip={it}
                setAmount={setAmountHandler}
                removeUser={removeUserHandler}
              />
            ))}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default SplitTip;
