import theme from 'containers/App/theme';

export const referralShareStyles = {
  display: 'block',
  '& .share-subtitle': {
    color: theme.palette.text.secondary,
    marginBottom: theme.spacing(3),
  },
  '& .share-content': {
    display: 'flex',
    gap: theme.spacing(4),
  },
};
