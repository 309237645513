/* eslint-disable @typescript-eslint/no-unused-vars */
import { alpha } from '@material-ui/core/styles/colorManipulator';
import { Theme, createStyles, makeStyles } from '@material-ui/core';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: theme.palette.primary.main,
      minHeight: '100vh',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      padding: theme.spacing(4),
    },
    container: {},
    insideBox: {
      position: 'relative',
    },
    loader: {
      position: 'absolute',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: alpha(theme.palette.common.white, 0.5),
      borderRadius: theme.shape.borderRadius,
      zIndex: 200,
      top: 0,
      right: 0,
      left: 0,
      bottom: 0,
    },
    pig: {
      height: theme.spacing(12),
      marginBottom: theme.spacing(4),
    },
  });

export default styles;

export const useStyles = makeStyles(styles);
