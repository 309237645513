import { createStyles, makeStyles, Theme } from '@material-ui/core';

interface IStyleProps {
  spacing: number;
}

const useStyles = makeStyles<Theme, IStyleProps>((theme: Theme) =>
  createStyles({
    button: {
      bottom: theme.spacing(2.5),
      position: 'sticky',
      width: '100%',
      marginLeft: 0,
      [theme.breakpoints.up('md')]: {
        width: ({ spacing }) => `calc(50% - ${theme.spacing(spacing / 2)}px)`,
        marginLeft: ({ spacing }) => `calc(50% + ${theme.spacing(spacing / 2)}px)`,
      },
    },
  }),
);

interface IDialogStyleProps {
  hideChevron?: boolean;
}

export const useAccountDialogStyles = makeStyles<Theme, IDialogStyleProps>((theme: Theme) =>
  createStyles({
    account: {
      display: 'flex',
      alignContent: 'center',
      gap: theme.spacing(1),
      cursor: ({ hideChevron }) => (hideChevron ? 'auto' : 'pointer'),
    },
    openSection: {
      width: 20,
      height: 20,
    },
    closedSection: {
      width: 12,
      height: 20,
    },
    locationName: {
      display: 'flex',
      fontSize: theme.typography.body2.fontSize,
      color: theme.menu.main,
      gap: theme.spacing(1),
    },
    locationsWrapper: {
      padding: ({ hideChevron }) =>
        hideChevron ? theme.spacing(3, 1, 0, 0) : theme.spacing(0, 1, 0, 3.5),
    },
  }),
);
export default useStyles;
