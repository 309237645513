import { Theme, makeStyles, createStyles } from '@material-ui/core';
import theme from 'containers/App/theme';

interface IStyleProps {
  trigger: boolean;
  mobileView: boolean;
}

const backgroundColor = ({ trigger, mobileView }: IStyleProps) => {
  if (mobileView || !trigger) return theme.palette.common.white;
  return theme.palette.background.default;
};
const minWidth = ({ mobileView }: IStyleProps) => {
  if (mobileView) return 0;
  return 46;
};
const height = ({ mobileView }: IStyleProps) => {
  if (mobileView) return 'auto';
  return theme.spacing(5.875);
};
export const useStyles = makeStyles<Theme, IStyleProps>((theme) =>
  createStyles({
    wrapper: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      whiteSpace: 'nowrap',
      width: 'auto',
      minWidth,
      height,
      backgroundColor,
      borderRadius: theme.spacing(1),
    },
  }),
);
