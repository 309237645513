import React from 'react';
import { action, flow, computed, makeObservable } from 'mobx';
import { observer } from 'mobx-react';

import moment from 'moment-timezone';

import { inject, WithToastStore } from 'stores';
import NoteStore from './NoteStore';

import { ReminderListItem } from 'models';

import { Box, Typography, Divider, ListItemText, ListItem, IconButton } from '@material-ui/core';
import { Close } from 'mdi-material-ui';
import Skeleton from '@material-ui/lab/Skeleton';

import { WithStyles, withStyles } from '@material-ui/core/styles';

import DD from 'components/DashDrawer';

import styles from './styles';
import theme from 'containers/App/theme';

/** Props for this component */
interface ReminderListProps extends WithStyles<typeof styles>, WithToastStore {
  noteStore: NoteStore;
}

/**
 * Displays list fo reminders for a note.
 */
@inject('toastStore')
@observer
class ReminderList extends React.Component<ReminderListProps> {
  constructor(props: ReminderListProps) {
    super(props);
    makeObservable(this);
  }

  /** List of reminders */
  @computed get reminders(): ReminderListItem[] {
    return this.props.noteStore.reminders;
  }

  /** Drawer title. If note exists use its date, if creating a new note use todays date. */
  @computed public get drawerTitle(): string {
    return this.props.noteStore.note
      ? moment(this.props.noteStore.note.createdAt).format('MMM DD, YYYY')
      : moment().format('MMM DD, YYYY');
  }

  /** Delete reminder */
  @action.bound public deleteReminder = flow(function* (this: ReminderList, reminderId: number) {
    yield this.props.noteStore.deleteReminder(reminderId);
    this.props.toastStore!.success('Reminder successfully deleted');
  });

  renderLoadingSkeleton = () => (
    <>
      <Box mt={1} mb={1} display="flex" flexDirection="row" justifyContent="space-between">
        <Skeleton variant="text" width={180} height={40} />
        <Skeleton variant="text" width={100} height={40} />
      </Box>
      <Divider />
    </>
  );

  render() {
    const { classes } = this.props;
    return (
      <DD>
        <Box>
          <DD.Content>
            <DD.Title onBack={this.props.noteStore.goBack}>{this.drawerTitle}</DD.Title>
            <DD.List>
              {/* || No reminders for this note: */}
              {this.reminders.length === 0 && (
                <Box display="flex" justifyContent="center">
                  <Typography variant="h6" style={{ color: theme.palette.text.secondary }}>
                    Nothing here yet
                  </Typography>
                </Box>
              )}
              {/* || Reminders list: */}
              {this.reminders &&
                this.reminders.length > 0 &&
                this.reminders.map((reminder: ReminderListItem) => {
                  const formattedDay = moment
                    .tz(reminder.dateTime, 'America/New_York')
                    .format('MMM DD, YYYY');
                  const formattedTime = moment
                    .tz(reminder.dateTime, 'America/New_York')
                    .format('h:mmA');
                  const displayDate = `${formattedDay} @ ${formattedTime}`;
                  return (
                    <>
                      <ListItem>
                        <ListItemText className={classes.listItemKey} primary={displayDate} />
                        <IconButton size="small" onClick={() => this.deleteReminder(reminder.id)}>
                          <Close fontSize="small" />
                        </IconButton>
                      </ListItem>
                      <Divider />
                    </>
                  );
                })}
            </DD.List>
          </DD.Content>
          <DD.FabButton right={4} onClick={() => this.props.noteStore.routeTo('/addReminder')} />
        </Box>
      </DD>
    );
  }
}

export default withStyles(styles)(ReminderList);
