/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { action, computed, flow, observable, makeObservable } from 'mobx';
import { observer } from 'mobx-react';
import { WithStyles, withStyles } from '@material-ui/core/styles';
import {
  Box,
  CircularProgress,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Typography,
} from '@material-ui/core';
import { Link as RouterLink, RouteComponentProps } from 'react-router-dom';
import validatorjs from 'validatorjs';

import Api, { ApiResponse, getErrorMsg } from 'api';
import { inject, WithToastStore, WithUiStore, WithUserStore } from 'stores';
import { Account, AccountUser, Industry, Lead, Pos } from 'models';
import { Address } from 'types';
import { paths } from 'routes';
import { setTitle } from 'services/title';

import AddressField from 'components/AddressField';

import GC from './GraphicContainer';
import SignupStore, { SignupStep } from './SignupStore';
import { ReactComponent as AccountImg } from './account_new.svg';

import SubmitButton from './SubmitButton';

import styles from './styles';
import { Domain, Plus } from 'mdi-material-ui';
import { AxiosResponse } from 'axios';
import PoSSearch from 'components/PosSearch';
import Button from 'components/Button/Button';
import OutlinedInput from 'components/Input/OutlinedInput/OutlinedInput';
import AccountSignupLayout from './AccountSignupLayout/AccountSignupLayout';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const dvr = require('mobx-react-form/lib/validators/DVR');
const MobxReactForm = require('mobx-react-form').default;

interface FormHooks {
  onSuccess: (form: any) => void;
  onClear: (form: any) => void;
  onError: (form: any) => void;
}

/** Here we define what kind of props this component takes */
interface AccountStepProps
  extends WithStyles<typeof styles>, // Adds the classes prop
    WithUserStore, // Adds the userStore prop
    RouteComponentProps,
    WithUiStore,
    WithToastStore {
  signupStore: SignupStore;
  addOwner?: boolean; // Whether to add the current user as the owner when creating account
  ownerType?: string; // Which type of owner we should add the current user as
  nextRoute: (accountId: string | number) => string;
  noPaper?: boolean;
  noDataPreload?: boolean; // If true, will not preload cart and account data before going to next step
}

const plugins = {
  dvr: dvr({
    package: validatorjs,
  }),
};

/**
 * The account information for the account signup process
 */
@inject('userStore', 'toastStore', 'uiStore')
@observer
class AccountStep extends React.Component<AccountStepProps> {
  public signupStore = this.props.signupStore;
  public constructor(props: AccountStepProps) {
    super(props);
    makeObservable(this);
    const fields = [
      {
        name: 'name',
        label: 'Company Name',
        rules: 'required|min:2',
      },
      {
        name: 'address',
        label: 'Address',
        rules: 'required',
      },
    ];
    this.form = new MobxReactForm({ fields }, { plugins, hooks: this.hooks });
  }
  /** Whether we are currently submitting */
  @observable public submitting = false;

  @observable public industry?: Industry | null;

  /** The form */
  @observable private form: any;
  private hooks: FormHooks = {
    onSuccess: () => {
      this.submit();
    },
    onClear: (form: any) => {
      form.clear();
    },
    onError: () => {},
  };

  /** The user's accounts */
  @observable public accounts?: AccountUser[];

  /** Whether the admin wants to add a new account instead of choosing an existing one */
  @observable public addingNewAccount = false;

  /** Account's PoS system if any */
  @observable public posSystem?: Omit<Pos, 'id'> & { id?: number };

  /** Sets addingNewAccount to true, thus displaying the account form for admins */
  @action.bound public setAddNewAccountMode() {
    this.addingNewAccount = true;
  }

  /** Submits the form */
  @action.bound public submit = flow(function* (this: AccountStep) {
    const { signupStore, addOwner, history, userStore, ownerType } = this.props;
    try {
      this.submitting = true;
      const owner = signupStore.owner;
      if (!owner) {
        throw new Error('No owner has been selected');
      }
      // Create the account
      const resp: AxiosResponse<ApiResponse<Account>> = yield Api.core.createAccount({
        creationSource: this.creationSource,
        name: this.form.$('name').value,
        addOwner: addOwner,
        ownerType: ownerType,
        industry: this.industry,
        ...this.form.$('address').value,
      });
      const account = resp.data.data!;

      // yield Api.core.updateSettings('account', account.id, { industry: this.industry });

      // Set the account in the parent store so that we can refer to it
      // in the next steps
      signupStore.setAccount(account);
      // Set the account lat and long in the signup store
      signupStore.accountLatLong = {
        lat: this.form.$('address').value.lat,
        long: this.form.$('address').value.long,
      };

      // If PoS system field is not empty, we add it to this account
      if (this.posSystem) {
        // If this is a new PoS system, we first save it to list of PoS systems
        if (!this.posSystem.id) {
          const resp: AxiosResponse<ApiResponse<Pos>> = yield Api.core.createPosSystem(
            this.posSystem.name,
          );
          this.posSystem.id = resp.data.data!.id;
        }
        if (this.posSystem.id) {
          yield Api.core.createAccountPos(account.id, this.posSystem.id);
        }
      }

      // Add the user from the previous step to the account if an
      // admin is creating these accounts
      if (signupStore.adminSignup) {
        yield Api.core.addAccountOwner(account.id, owner.id);
        this.props.toastStore!.push({
          type: 'success',
          message: `Account ${account.name} created!`,
        });
      }
      // Preload the data needed to display the next step
      if (!this.props.noDataPreload) {
        yield signupStore.initAccount(account.id);
      }
      // If this account is being created by an affiliate, we can add the affiliate
      // right now
      if (signupStore.affiliateSignup && userStore!.affiliate) {
        signupStore.setAccountAffiliate(userStore!.affiliate);
      }
      // Go to the next step
      history.push(this.props.nextRoute(account.id));
    } catch (e: any) {
      this.props.toastStore!.push({ type: 'error', message: getErrorMsg(e) });
    } finally {
      this.submitting = false;
    }
  });

  /** Sets the address */
  @action.bound public updateAddress(addr: Address | null) {
    this.form.$('address').set(addr);
  }

  @action.bound public updateIndustry(industry: Industry | null) {
    this.industry = industry;
    //this.form.$('address').set(addr);
  }

  /** Initializes the component */
  @action.bound public init() {
    this.props.signupStore!.setStep(SignupStep.Account);

    const signupStore = this.props.signupStore!;
    // If somehow the admin came to this step without setting an owner first,
    // redirect them back. This only happens when admins are signing up accounts:
    if (!signupStore.owner && !signupStore.selfSignup) {
      this.props.history.replace(paths.adminAccountSignup().owner());
      return;
    }

    // Set the lead data
    const lead = this.signupStore.lead;
    if (lead) {
      this.setLeadData(lead);
    }

    // If it's a self-signup, call this.getUserAccounts to get accounts.
    if (this.props.signupStore.selfSignup) {
      this.getUserAccounts(this.props.userStore!.authUser.id);
    } else {
      if (this.props.signupStore.owner) {
        this.getUserAccounts(this.props.signupStore!.owner.id);
      }
    }
  }

  /**
   * Fetches the accounts for the current user and stores them
   */
  @action.bound public getUserAccounts = flow(function* (this: AccountStep, userId: number) {
    try {
      const resp = yield Api.core.getUserAccounts(userId);
      this.accounts = resp.data.data;
    } catch (e: any) {
      this.props.toastStore!.error(getErrorMsg(e));
    }
  });
  /** Whether to show the form */
  @computed public get showExistingAccount(): boolean {
    // This is required because the router happens with some delay, and
    // we don't want the existing account to flash briefly before going
    // to the next step.
    return Boolean(this.currentAccount && this.props.signupStore.maxStep >= SignupStep.Account);
  }

  /**
   * For self-signup only. The current account to display as the
   * existing account. If it's undefined it means that it's still
   * being loaded, null means there is no account.
   */
  @computed public get currentAccount(): Account | null | undefined {
    // If we have an account in the signup store, it takes precedence
    if (this.props.signupStore!.account) {
      return this.props.signupStore!.account;
    }
    // If we have an account chosen in the scope in the user store, and we're already
    // logged in, that's the one.
    if (this.props.userStore!.currentAccount) {
      return this.props.userStore!.currentAccount;
    }

    // If this.accounts is undefined, it's means it's still being loaded,
    // so we return undefined
    if (!this.accounts) {
      return undefined;
    }

    // If this.accounts is defined but is empty, it means there's no account,
    // so we return null
    if (this.accounts.length === 0) {
      return null;
    }

    // Finally, we know there is an account, so we return the first one from
    // accounts.
    return this.accounts[0]!.account!;
  }

  /** The creationSource to send when creating the account */
  @computed public get creationSource(): string {
    return this.signupStore.selfSignup ? 'registration' : 'dashboard';
  }

  @action private setLeadData = (lead: Lead) => {
    this.form.$('name').set(lead.accountName);
  };

  /** Handle change in the Autocomplete component */
  @action.bound public handlePoSFieldUpdate(pos: Omit<Pos, 'id'> & { id?: number }) {
    this.posSystem = pos.name ? pos : undefined;
  }

  componentDidMount() {
    if (this.signupStore.selfSignup) {
      setTitle(`Company Info`);
    }
    this.init();
  }

  renderExistingAccount() {
    const { classes } = this.props;
    const account = this.currentAccount;
    if (!account) {
      return null;
    }
    return (
      <Box height="100%" display="flex" flexDirection="column" justifyContent="space-between">
        <Box>
          <Typography variant="h4" component="h2" gutterBottom>
            {account.name}
          </Typography>
          <Typography color="textSecondary">
            <div>{account.address}</div>
            <div>{account.city}</div>
            <div>
              {account.zip} {account.state}
            </div>
          </Typography>
        </Box>
        <Button
          className={classes.nextStepButton}
          fullWidth
          disableElevation
          component={RouterLink}
          to={this.props.nextRoute(account.id)}
          variant="contained"
          color="primary">
          Next
        </Button>
      </Box>
    );
  }
  renderAccountForm() {
    const isAdmin = this.props.userStore!.isAdmin;
    const { classes } = this.props;
    const addressField = this.form.$('address');
    const { onBlur: addressOnBlur, onFocus: addressOnFocus } = addressField.bind();
    return (
      <form onSubmit={this.form.onSubmit} className={classes.accountStepForm}>
        <Box>
          <Box pb={2}>
            <OutlinedInput
              {...this.form.$('name').bind()}
              fullWidth
              error={Boolean(this.form.$('name').error)}
              helperText={this.form.$('name').error || ''}
              autoFocus
              InputProps={{
                disableUnderline: true,
                classes: { input: classes.resize },
              }}
              dataCy="company-name-input"
            />
          </Box>
          <Box pb={2}>
            <AddressField
              onChange={this.updateAddress}
              label="Address"
              error={Boolean(addressField.error)}
              helperText={addressField.error || 'Start with street number'}
              onBlur={addressOnBlur}
              onFocus={addressOnFocus}
              InputProps={{ dataCy: 'input-address' }}
              dataCy="input-address"
              enableCustomInput
            />
          </Box>
          <Box pb={2}>
            <OutlinedInput
              select
              label="Industry"
              fullWidth
              value={this.industry || ''}
              onChange={(e) => this.updateIndustry(e.target.value as Industry)}
              dataCy="industry-select">
              <MenuItem value={Industry.BEAUTY}>Beauty</MenuItem>
              <MenuItem value={Industry.HOSPITALITY}>Hospitality</MenuItem>
            </OutlinedInput>
          </Box>
          {isAdmin && (
            <Box pb={2}>
              <PoSSearch
                placeholder="POS System"
                InputProps={{ disableUnderline: true }}
                onChange={(pos) => {
                  if (pos) {
                    const { id, name } = pos;
                    this.handlePoSFieldUpdate({ id, name });
                  }
                }}
                onInput={(s) => {
                  const name = s;
                  this.handlePoSFieldUpdate({ name });
                }}
              />
            </Box>
          )}
        </Box>
        <SubmitButton
          data-cy="submit-button"
          style={{ marginTop: 'auto' }}
          className={classes.nextStepButton}
          inProgress={this.submitting}>
          Next
        </SubmitButton>
      </form>
    );
  }

  /**
   * Renders for admins, contains a form to choose existing account or
   * inputs to create one if none are chosen or exist
   */
  renderAdminContent() {
    // If no account is selected by the admin or the user doesn't have any accounts
    // yet, display the input form
    if (!this.accounts) {
      return null;
    }
    if (this.addingNewAccount || this.accounts.length === 0) {
      return this.renderAccountForm();
      // Otherwise, display the accounts chooser
    } else {
      const mobileView = this.props.uiStore?.mobileView;
      const align = mobileView ? 'center' : 'left';
      return (
        <Box>
          <GC.Title align={align}>Select Company</GC.Title>
          <Box mt={4}>
            <GC.Subtitle align={align}>This person is already an owner</GC.Subtitle>
          </Box>
          <List>
            {this.accounts.map((accountOwner) => (
              <ListItem
                button
                divider
                to={this.props.nextRoute(accountOwner.account!.id)}
                key={accountOwner.account!.id}
                component={RouterLink}>
                <ListItemIcon>
                  <Domain color="primary" />
                </ListItemIcon>
                <ListItemText
                  primary={accountOwner.account!.name}
                  secondary={accountOwner.account!.city}
                />
              </ListItem>
            ))}
            <ListItem button onClick={this.setAddNewAccountMode}>
              <ListItemIcon>
                <Plus color="primary" />
              </ListItemIcon>
              <ListItemText primary="Add new" />
            </ListItem>
          </List>
        </Box>
      );
    }
  }

  renderOwnerContent() {
    if (this.showExistingAccount) {
      return this.renderExistingAccount();
    } else {
      return this.renderAccountForm();
    }
  }

  render() {
    const { signupStore } = this.props;
    const content = signupStore.selfSignup ? this.renderOwnerContent() : this.renderAdminContent();
    return (
      <>
        <AccountSignupLayout
          title={{
            name: 'Company Info',
          }}
          subtitle={{
            name: 'Keep in mind that each franchise is a separate account',
            hide: !this.signupStore.adminSignup,
          }}
          image={{
            svg: <AccountImg />,
          }}
          contentRight={{
            children: (
              <>
                {this.accounts ? (
                  content
                ) : (
                  <Box display="flex" height="100%" justifyContent="center" alignItems="center">
                    <CircularProgress color="primary" />
                  </Box>
                )}
              </>
            ),
          }}
        />
      </>
    );
  }
}

export default withStyles(styles)(AccountStep);
