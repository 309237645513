import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { useStyles } from './styles';
import { observer } from 'mobx-react';
import useCompanyCode from './useCompanyCode';
import { ToolbarItemWrapper } from '../ElevatedAppBar.tsx/WebToolbar/WebToolbar';

export const CompanyCode = observer(() => {
  const { isOwner, accountCode, trigger, mobileView } = useCompanyCode();
  const { text, code, typography } = useStyles({ trigger, mobileView });
  if (!isOwner) return null;

  return (
    <ToolbarItemWrapper>
      <Typography component={'span'} className={typography}>
        <Box component="span" className={text}>
          Company code:
        </Box>
        <Box component="span" className={code}>
          {accountCode}
        </Box>
      </Typography>
    </ToolbarItemWrapper>
  );
});
