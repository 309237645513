import React from 'react';
import { Box, InputAdornment, TextField, TextFieldProps } from '@material-ui/core';
import useStyles from './styles';
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';

export type InputVariant = 'normal' | 'small';

type InputProps = Omit<TextFieldProps, 'margin' | 'variant'> & {
  variant?: InputVariant;
};

/**
 * Input component with solid border and border radius of 16. This component is built with MUI TextField component.
 * @param variant User for rendering small and normal size of the input. When using 'small' variant, label prop should be replaced with placeholder prop.
 */
const Input = observer(
  ({ variant = 'normal', label, error, helperText, style, fullWidth, ...rest }: InputProps) => {
    const startAdornment = rest.InputProps?.startAdornment;
    const { textField, textFieldWrapper, muiHelperText, startAdornmentWrapper } = useStyles({
      startAdornment: !!startAdornment,
      error: Boolean(error),
      disabled: rest.disabled,
      variant,
    });
    const inputProps = { ...rest.InputProps, startAdornment: undefined };
    return (
      <Box width={fullWidth ? '100%' : '222px'}>
        <Box className={textFieldWrapper} style={style}>
          <Box className={startAdornmentWrapper}>
            <InputAdornment position="start">{startAdornment}</InputAdornment>
          </Box>
          <TextField
            label={variant === 'small' ? undefined : label}
            className={textField}
            {...rest}
            InputProps={inputProps}
            fullWidth
          />
        </Box>
        <Box component="p" className={clsx(muiHelperText, 'MuiFormHelperText-root')}>
          {helperText || error}
        </Box>
      </Box>
    );
  },
);

export default Input;
