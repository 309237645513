import { IconDefinition } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Grid, GridSize, Typography } from '@material-ui/core';
import Button from 'components/Button/Button';
import { ReactNode } from 'react';
import { referralPanelStyles } from './styles';

export interface ReferralPanelProps {
  sm?: boolean | GridSize | undefined;
  lg?: boolean | GridSize | undefined;
  icon?: IconDefinition;
  title: string;
  text?: string;
  actionBtn?: {
    disabled?: boolean;
    text: string;
    action: () => void;
  };
  children?: ReactNode;
}

export const ReferralPanel = ({
  icon,
  title,
  text,
  actionBtn,
  children,
  sm,
  lg,
}: ReferralPanelProps) => {
  return (
    <Grid item key={title} xs={12} sm={sm} lg={lg}>
      <Box sx={referralPanelStyles}>
        {icon && (
          <Box className="referral-panel-icon">
            <FontAwesomeIcon icon={icon} className="referral-panel-icon-inner" />
          </Box>
        )}
        <Box>
          <Typography className="referral-panel-title">{title}</Typography>
        </Box>
        <Box className="referral-panel-content">
          <Box width="100%">
            {text && <Typography className="referral-panel-text">{text}</Typography>}
            {children}
          </Box>
          {actionBtn && (
            <Button
              disabled={actionBtn.disabled}
              className="referral-panel-action"
              variant="contained"
              onClick={actionBtn.action}>
              {actionBtn.text}
            </Button>
          )}
        </Box>
      </Box>
    </Grid>
  );
};
