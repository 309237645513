import React from 'react';
import { observer } from 'mobx-react';
import { inject, WithToastStore, WithModalStore } from 'stores';
import { Paper, Box, Typography } from '@material-ui/core';
import { WithStyles, withStyles } from '@material-ui/styles';
import styles from './styles';
import { Campaign } from 'models';
import moment from 'moment';

interface CampaignPanelProps extends WithStyles<typeof styles>, WithToastStore, WithModalStore {
  title: string;
  campaign?: Campaign;
}

@inject('toastStore', 'modalStore')
@observer
class CampaignPanel extends React.Component<CampaignPanelProps> {
  public constructor(props: CampaignPanelProps) {
    super(props);
  }

  render() {
    if (!this.props.campaign) {
      return (
        <Paper elevation={0} style={{ height: '100%' }}>
          <Box
            p={2}
            pb={3}
            display="flex"
            flexDirection="column"
            alignItems="center"
            style={{ minHeight: '171px', minWidth: '194px' }}>
            <Box display="flex" flexGrow={1} alignItems="flex-end">
              <Typography variant="h6" className={this.props.classes.notAvailableLabel}>
                Not available
              </Typography>
            </Box>
            <Box display="flex" flexGrow={1} alignItems="flex-end">
              <Typography color="textSecondary">{this.props.title}</Typography>
            </Box>
          </Box>
        </Paper>
      );
    } else {
      return (
        <Paper elevation={0}>
          <Box
            p={2}
            pb={3}
            display="flex"
            flexDirection="column"
            alignItems="center"
            style={{ minHeight: '171px', minWidth: '194px' }}>
            <Box display="flex" flexGrow={1} alignItems="center" flexDirection="column">
              <Typography color="textSecondary">{this.props.campaign.name}</Typography>
              <Typography color="textSecondary">
                {moment(this.props.campaign.accountCreatedAt).format('MMM DD, YYYY')}
              </Typography>
            </Box>
            <Box display="flex" flexGrow={1} alignItems="center" flexDirection="column">
              <Typography variant="h6" color="primary">
                {this.props.campaign.code}
              </Typography>
            </Box>
            <Box display="flex" flexGrow={1} alignItems="flex-end">
              <Typography color="textSecondary">{this.props.title}</Typography>
            </Box>
          </Box>
        </Paper>
      );
    }
  }
}

export default withStyles(styles)(CampaignPanel);
