import React from 'react';
import styles from '../styles';
import { observer } from 'mobx-react';
import { Box, IconButton, Link, WithStyles, withStyles, Grid } from '@material-ui/core';
import { action, observable, makeObservable, reaction, computed } from 'mobx';
import { inject, WithToastStore, WithUserStore, WithSettingStore, WithModalStore } from 'stores';
import { Link as RouterLink, RouteComponentProps } from 'react-router-dom';
import moment from 'moment-timezone';
import { adaptForDataGridPro } from 'services/datagrid';
import Api, { getErrorMsg, RequestMetaData } from 'api';
import { paths } from 'routes';
import {
  DATE_TYPE,
  downloadCsvFile,
  EDateFormat,
  formatLocalDateTimeInUTC,
  getFileNameFromResponseHeaders,
} from 'utils/helper';
import Eye from 'mdi-material-ui/Eye';
import { numericStringToUsd } from 'services';
import DataGridInfiniteScroll from 'components/DataGridInfiniteScroll';
import { v4 as uuidv4 } from 'uuid';
import * as DateRangeExternalPicker from 'components/DateRangeExternalPicker';
import FilterBar, { Filter } from 'components/FilterBar/FilterBar';
import { CurrencyExchange, Send } from '@mui/icons-material';
import { ActionCard, HorizontalStatCard, InfoCard } from 'containers/UserDetails/Stats/Stats';
import { Calendar, CurrencyUsd } from 'mdi-material-ui';
import ChipStatusTag, { ChipStatusColors } from 'components/ChipStatusTag';
import { ACL } from 'types';
import qs from 'qs';

type BalancesProps = WithStyles<typeof styles> &
  WithUserStore &
  WithSettingStore &
  WithModalStore &
  RouteComponentProps &
  WithToastStore;
@inject('userStore', 'toastStore', 'settingStore', 'modalStore')
@observer
class Balances extends React.Component<BalancesProps> {
  constructor(props: any) {
    super(props);
    makeObservable(this);

    reaction(
      () => this.activeFilters,
      () => this.getProcessingPanelData(),
    );
  }

  @observable activeFilters: Record<string, unknown> = {};

  /** The selected date range */
  @observable public dateRange: DateRangeExternalPicker.DateRange =
    this.getDateFilterFromPathname() ||
    this.props.settingStore!.getDate(this.props.location.pathname);

  @observable isAdmin: boolean = this.props.userStore!.scope.kind === 'admin';

  @observable datagridKey = Date.now();
  @observable processingPanelData:
    | { totalAmountDue: string; totalUpcomingDraws: string }
    | undefined = undefined;

  /** Is manual draw  payout initiation in progress? */
  @observable public manualInitiationInProgress = false;

  /**
   * Draw payout initiation can only be triggered if admin has the right
   * permission for this actions
   */
  @computed private get canInitiatePayouts() {
    return this.props.userStore!.hasPermission(ACL.INITIATE_DRAWS);
  }

  @action.bound public async downloadCsvReport() {
    try {
      const res = await Api.tips.downloadBalancesCsv({ ...this.activeFilters, ...this.dateRange });
      if (res && res.data) {
        const filename = getFileNameFromResponseHeaders(res);
        downloadCsvFile(res.data, filename);
      }
    } catch (error) {
      this.props.toastStore!.push({
        message: 'Unable to download requested report',
        type: 'error',
      });
    }
  }

  componentDidMount() {
    this.getProcessingPanelData();
  }

  /** Sets the date range */
  @action.bound private updateDateRangeValue(range: DateRangeExternalPicker.DateRange) {
    this.props.settingStore!.setDate(this.props.location.pathname, range);
    this.dateRange = range;
    this.activeFilters = { ...this.activeFilters };
  }

  /** Request manual draw payouts initiation */
  @action.bound public async manuallyInitiateDrawPayouts() {
    const confirmed = await this.confirmManualInitiation();
    if (!confirmed) return;
    try {
      this.manualInitiationInProgress = true;
      await Api.tips.initiateDrawPayouts();
      this.props.toastStore!.push({
        type: 'success',
        message: 'Draw initiation request sent successfully.',
      });
      await this.getProcessingPanelData();
    } catch (e) {
      this.props.toastStore!.push({ type: 'error', message: getErrorMsg(e) });
    } finally {
      this.manualInitiationInProgress = false;
    }
  }

  /** Triggers manual draw payout initiation confirmation dialog */
  @action.bound public async confirmManualInitiation() {
    const dialogTitle = 'Initiate ACH draws manually?';
    let drawCount;
    let totalSum;
    let hideDrawDetails = false;

    try {
      const { data } = await Api.tips.getTotalDrawBalances();
      const { totalAmountDue, totalUpcomingDraws } = data.data;

      drawCount = totalUpcomingDraws;
      totalSum = totalAmountDue;
    } catch (e) {
      hideDrawDetails = true;
    }

    const details = !hideDrawDetails && (
      <>
        for <b>{drawCount} draws </b>
        and <b>total amount of ${totalSum}</b>
      </>
    );

    const dialogBody = (
      <>
        You are about to manually initiate ACH draws {details}.<br />
        <br /> Please click <b>confirm</b> to proceed.
      </>
    );

    return await this.props.modalStore!.confirm(dialogTitle, dialogBody);
  }

  getProcessingPanelData = async () => {
    const { data } = await Api.tips.getTotalDrawBalances({
      filters: { ...this.activeFilters },
    });
    this.processingPanelData = data.data;
  };

  public fetchDrawBalances = adaptForDataGridPro(
    async (rmd: RequestMetaData) =>
      await Api.tips.getDrawBalances({
        ...rmd,
        filters: {
          ...this.activeFilters,
          fromDate: this.dateRange.fromDate,
          toDate: this.dateRange.toDate,
        },
      }),
    (drawBalance: any) => {
      return {
        id: uuidv4(),
        ...drawBalance,
        amount: numericStringToUsd(drawBalance.amount),
        balance: numericStringToUsd(drawBalance.balance),
        debited: numericStringToUsd(drawBalance.balance),
        date: formatLocalDateTimeInUTC(drawBalance.date),
        rawDate: formatLocalDateTimeInUTC(drawBalance.date, DATE_TYPE.DATE, EDateFormat.DATE_ISO),
        lastTransactionDate: formatLocalDateTimeInUTC(drawBalance.lastTransactionDate),
        lastDrawStatus: this.getDrawStatus(drawBalance),
      };
    },
  );

  getDrawStatus = (drawBalance: any) => {
    if (!drawBalance.lastDrawStatus) return 'OPEN';
    return drawBalance.lastDrawStatus.toUpperCase();
  };

  renderLocation = ({ row }: any) => {
    return (
      <Link component={RouterLink} to={paths.locationDetails(row.locationId)}>
        {row.location}
      </Link>
    );
  };

  renderMoneyCell = ({ value }: any) => {
    return <Box className={this.props.classes.moneyCell}>{value}</Box>;
  };

  renderDebited = ({ value, row }: any) => {
    const drawCompleted = this.isDrawCompleted(row.lastTransactionReason);
    return <Box className={this.props.classes.moneyCell}>{drawCompleted ? row.amount : ''}</Box>;
  };

  renderDrawDate = ({ value, row }: any) => {
    const drawCompleted = this.isDrawCompleted(row.lastTransactionReason);
    if (drawCompleted) return value;
    return '/';
  };

  isDrawCompleted = (draw: string) => {
    if (draw === 'draw_completed') return true;
    return false;
  };

  renderEye = ({ row }: any) => {
    const eyeButton = (
      <IconButton color="primary">
        <Eye fontSize="small" />
      </IconButton>
    );
    return this.withRouterLink(eyeButton, row);
  };

  withRouterLink = (eyeButton: JSX.Element, row: any) => {
    const { date, rawDate, locationId, drawSourceId, location } = row;
    return (
      <RouterLink
        to={{
          pathname: paths.draw().transactions(),
          state: { date, rawDate, locationId, drawSourceId, location },
        }}>
        {eyeButton}
      </RouterLink>
    );
  };

  renderStatus = ({ value }: any) => {
    const colors: Record<any, any> = {
      PROCESSED: ChipStatusColors.GREEN,
      FAILED: ChipStatusColors.RED,
      PENDING: ChipStatusColors.YELLOW,
      CREATED: ChipStatusColors.GREEN,
      CANCELED: ChipStatusColors.RED,
      VOIDED: ChipStatusColors.GREEN,
      OPEN: ChipStatusColors.GREY,
    };

    return <ChipStatusTag label={value} color={colors[value]} />;
  };

  exportElements = [
    {
      name: 'Download as CSV',
      action: () => {
        this.downloadCsvReport();
      },
    },
  ];

  gridColumns = [
    {
      headerName: 'Locations',
      field: 'location',
      minWidth: 200,
      flex: 1,
      renderCell: this.renderLocation,
    },
    {
      headerName: 'Date',
      field: 'date',
      minWidth: 150,
      flex: 1,
      valueGetter: ({ value }: any) => value && moment(new Date(value)).format('MMM DD, YYYY'),
    },
    {
      headerName: 'Amount Due',
      field: 'balance',
      minWidth: 200,
      flex: 1,
      renderCell: this.renderMoneyCell,
    },
    {
      headerName: 'Debited',
      field: 'debited',
      minWidth: 200,
      flex: 1,
      renderCell: this.renderDebited,
    },
    {
      headerName: 'Debited On',
      field: 'lastTransactionDate',
      minWidth: 200,
      flex: 1,
      renderCell: this.renderDrawDate,
    },
    {
      headerName: 'Status',
      field: 'lastDrawStatus',
      minWidth: 150,
      sortable: false,
      renderCell: this.renderStatus,
    },
    {
      headerName: 'Actions',
      field: 'ledgerId',
      minWidth: 120,
      sortable: false,
      renderCell: this.renderEye,
    },
  ];

  @computed get amount() {
    return this.processingPanelData ? parseFloat(this.processingPanelData.totalAmountDue) : 0;
  }

  @computed get upcomingDraws() {
    return this.processingPanelData ? parseInt(this.processingPanelData.totalUpcomingDraws) : 0;
  }

  //We could embed this functionality in FilterBar
  getDateFilterFromPathname() {
    const { fromDate, toDate } = qs.parse(this.props.location.search, { ignoreQueryPrefix: true });
    if (!fromDate || !toDate) return;
    return { fromDate, toDate, type: 'date-timer' };
  }

  renderPayoutProcessingPanel() {
    const md = this.canInitiatePayouts ? 6 : 4;
    return (
      <Grid container spacing={3}>
        <Grid item xs={12} md={md} lg style={{ flexGrow: 1 }}>
          <InfoCard icon={Calendar} title="Next Draw" loading={false}>
            {'WEEKDAYS @ 09:00 AM'}
          </InfoCard>
        </Grid>
        <Grid item xs={12} md={md} lg style={{ flexGrow: 1 }}>
          <HorizontalStatCard
            icon={CurrencyUsd}
            title="Total Amount Due"
            color="yellow"
            duration={2}
            decimals={2}
            prefix="$">
            {this.amount}
          </HorizontalStatCard>
        </Grid>
        <Grid item xs={12} md={md} lg style={{ flexGrow: 1 }}>
          <HorizontalStatCard
            icon={CurrencyExchange}
            title="Upcoming Draws"
            color="purple"
            duration={2}>
            {this.upcomingDraws}
          </HorizontalStatCard>
        </Grid>
        {this.canInitiatePayouts && (
          <Grid item xs={12} md={md} lg style={{ flexGrow: 1 }}>
            <ActionCard
              icon={Send}
              onClick={this.manuallyInitiateDrawPayouts}
              disabled={this.manualInitiationInProgress}
              color="primary">
              Initiate Draws
            </ActionCard>
          </Grid>
        )}
      </Grid>
    );
  }

  getOptions(fields: string[]) {
    const options: Filter[] = [
      { display: 'Locations', id: 'location', label: 'Contains', type: 'text' },
      { display: 'Location ID', id: 'locationId', label: 'Contains', type: 'text' },
      { display: 'Account', id: 'account', label: 'Contains', type: 'text' },
      { display: 'Account ID', id: 'accountId', label: 'Contains', type: 'text' },
      {
        display: 'Amount Due',
        id: 'hasBalanceDue',
        label: 'Select',
        type: 'select',
        items: [{ label: 'GREATER THAN $0', value: 'true' }],
      },
    ];

    return fields.reduce((acc: Filter[], field: string) => {
      const option = options.find((f) => f.id == field);
      if (option) {
        acc.push(option);
      }
      return acc;
    }, []);
  }

  filtersBalances: Filter[] = this.getOptions(
    this.isAdmin
      ? ['location', 'locationId', 'account', 'accountId', 'hasBalanceDue']
      : ['location', 'locationId', 'hasBalanceDue'],
  );

  render() {
    return (
      <>
        <Box mt={3} mb={3}>
          <FilterBar
            filters={this.filtersBalances}
            onChange={(filters: Record<string, unknown>) => {
              this.activeFilters = filters;
            }}
            externalDateRange={{
              predefined: this.dateRange,
              onChange: this.updateDateRangeValue,
            }}
          />
        </Box>
        {this.renderPayoutProcessingPanel()}
        <Box mt={3}>
          <DataGridInfiniteScroll
            columns={this.gridColumns}
            fetch={this.fetchDrawBalances}
            refetchKey={this.activeFilters}
            disableColumnMenu
            actions={{
              onExport: this.exportElements,
            }}
            pathname={this.props.location.pathname}
          />
        </Box>
      </>
    );
  }
}

export default withStyles(styles)(Balances);
