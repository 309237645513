import { makeStyles } from '@material-ui/core';

export const avatarSize = 16;
export const borderWidth = 0.5;
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default makeStyles((theme) => ({
  toolbar: {
    height: '100%',
    justifyContent: 'center',
  },
  logo: {
    height: '100%',
  },
}));
