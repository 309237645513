import * as models from 'models';
import { isPaymentMethodExpired } from 'utils/helper';

export function getSecondaryText(paymentMethod: models.PaymentMethod | undefined) {
  if (!paymentMethod) {
    return;
  }

  const isExpired = isPaymentMethodExpired(paymentMethod);

  return `${isExpired ? 'Expired' : 'Expires'} on ${paymentMethod.validThru}`;
}
