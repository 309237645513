import React from 'react';
import { Box, Chip, FormControl, FormLabel } from '@material-ui/core';
import { default as DialogButton } from 'components/Button/Dialog/Button';
import { v4 as uuidv4 } from 'uuid';

import { Alert, AlertStepsDialog, AlertTargetType, NewAlertRequest } from 'models';
import ChipStatusTag, { ChipStatusColors } from 'components/ChipStatusTag';
import clsx from 'clsx';
import { useCapitalizeFirstLetter } from 'hooks/useCapitalizeFirstLetter';
import { humanize } from 'utils/helper';

interface IBadgeConfig {
  LOW: string;
  MEDIUM: string;
  HIGH: string;
}

interface Props {
  classes: any;
  onUpdateAlertStepsDialog: Function;
  onSubmit: Function;
  currentAlert?: Partial<Alert> | Partial<NewAlertRequest>;
  editAction?: string;
  badgeConfig: IBadgeConfig;
  sending: boolean;
}

const SummaryAlert: React.FC<Props> = ({
  classes,
  onUpdateAlertStepsDialog,
  onSubmit,
  currentAlert,
  editAction,
  badgeConfig,
  sending,
}) => {
  const handleBack = () => {
    onUpdateAlertStepsDialog(AlertStepsDialog.TARGET);
  };

  const handleOnSubmit = () => {
    onSubmit(editAction);
  };

  const renderSeverity = (value: any) => {
    const background = badgeConfig[value as keyof IBadgeConfig] as ChipStatusColors;
    return <ChipStatusTag label={value} color={background} size="small" />;
  };

  return (
    <Box>
      <Box mt={1} mb={1}>
        <FormControl margin="none">
          <FormLabel className={classes.formLabel} component="legend">
            You will target devices with next conditions:
          </FormLabel>
        </FormControl>
      </Box>

      {currentAlert?.target?.targetType === AlertTargetType.ALL && (
        <Box mb={4} mt={1}>
          <Chip className={classes.chip} key={uuidv4()} label={' All devices'} />
        </Box>
      )}

      {currentAlert?.target?.kioskMode && (
        <Box mb={2}>
          <FormLabel className={classes.formLabel} component="legend">
            Kiosk Mode
          </FormLabel>
          {currentAlert.target?.kioskMode?.map((v: any) => {
            return <Chip className={classes.chip} key={uuidv4()} label={humanize(v.name)} />;
          })}
        </Box>
      )}
      {currentAlert?.target?.app?.length ? (
        <Box mb={2}>
          <FormLabel className={classes.formLabel} component="legend">
            Integration app
          </FormLabel>
          {currentAlert.target?.app?.map((v: any) => {
            return <Chip className={classes.chip} key={uuidv4()} label={humanize(v.name)} />;
          })}
        </Box>
      ) : null}

      {currentAlert?.target?.processor?.length ? (
        <Box mb={2}>
          <FormLabel className={classes.formLabel} component="legend">
            Processor
          </FormLabel>
          {currentAlert.target?.processor?.map((v: any) => {
            return <Chip className={classes.chip} key={uuidv4()} label={humanize(v.name)} />;
          })}
        </Box>
      ) : null}

      {currentAlert?.target?.accountId?.length ? (
        <Box mb={2}>
          <FormLabel className={classes.formLabel} component="legend">
            Account Name
          </FormLabel>
          {currentAlert.target?.accountId?.map((v: any) => {
            return <Chip className={classes.chip} key={uuidv4()} label={humanize(v.name)} />;
          })}
        </Box>
      ) : null}

      {currentAlert?.target?.locationId?.length ? (
        <Box mb={2}>
          <FormLabel className={classes.formLabel} component="legend">
            Location Name
          </FormLabel>
          {currentAlert.target?.locationId?.map((v: any) => {
            return <Chip className={classes.chip} key={uuidv4()} label={humanize(v.name)} />;
          })}
        </Box>
      ) : null}

      {currentAlert?.link ? (
        <Box mb={2}>
          <FormLabel className={classes.formLabel} component="legend">
            Link
          </FormLabel>
          <span>{currentAlert?.link}</span>
        </Box>
      ) : null}

      <Box mb={1} mt={4}>
        <FormLabel className={classes.formLabel} component="legend">
          Alert
        </FormLabel>
        <Box display={'flex'}>
          <span className={classes.alertType}>{currentAlert?.type}</span>
          <Box style={{ maxWidth: '150px' }}>
            {currentAlert?.severity && renderSeverity(String(currentAlert?.severity).toUpperCase())}
          </Box>
        </Box>
      </Box>
      <Box mb={1}>
        <span>{currentAlert?.message}</span>
      </Box>
      {currentAlert?.cause && (
        <Box mb={1}>
          <Box display={'flex'}>
            <FormLabel className={clsx(classes.formLabel, currentAlert?.cause)} component="legend">
              Cause:
            </FormLabel>
            <FormLabel className={clsx(classes.formLabel, classes.causeText)}>
              {currentAlert?.cause}
            </FormLabel>
          </Box>
        </Box>
      )}

      <Box mb={2}>
        <Box display={'flex'}>
          {!currentAlert?.dismissible && (
            <FormLabel className={clsx(classes.formLabel)} component="legend">
              Alert wont be dismissible.
            </FormLabel>
          )}
          {currentAlert?.dismissible && (
            <FormLabel className={clsx(classes.formLabel)} component="legend">
              Alert would be dismissible
            </FormLabel>
          )}
        </Box>
      </Box>

      <Box className={classes.customDialogActions}>
        <DialogButton variant="outlined" onClick={handleBack} color="primary">
          Back
        </DialogButton>
        <DialogButton
          variant="contained"
          onClick={handleOnSubmit}
          color="primary"
          disabled={sending}>
          {`${useCapitalizeFirstLetter(editAction! || '')} & Publish`}
        </DialogButton>
      </Box>
    </Box>
  );
};
export default SummaryAlert;
