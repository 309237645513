import { useScrollTrigger } from '@material-ui/core';
import { minSingleContentPageSize } from 'components/MobileView/useMobileView';
import { useStores } from 'containers/App/App';
import useSingleColumnContent from 'hooks/useSingleColumnContent';
import { useEffect, useRef, useState } from 'react';

const useDashboardLayout = () => {
  const { uiStore } = useStores();
  const [targetNode, setTargetNode] = useState<Node | Window | undefined>(undefined);
  const contentRef = useRef<HTMLElement | null>(null);
  const { initializeResize } = useSingleColumnContent();

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    target: targetNode,
    threshold: 100,
  });

  useEffect(() => {
    setTargetNode(contentRef.current || undefined);
    initializeResize({ ref: contentRef, minWidth: minSingleContentPageSize });
  }, []);

  useEffect(() => {
    if (uiStore.mobileView) return;
    uiStore.setTrigger(trigger);
  }, [trigger]);

  const mobileView = uiStore?.mobileView;
  const mobileMenuOpen = mobileView && uiStore.drawer.open;

  return { mobileView, mobileMenuOpen, contentRef };
};

export default useDashboardLayout;
