import { makeStyles, createStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      display: 'flex',
      alignItems: 'center',
      gap: theme.spacing(2),
    },
    button: {
      padding: 0,

      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
    icon: {
      height: 24,
      width: 24,
      color: theme.palette.primary.main,
    },
  }),
);

export default useStyles;
