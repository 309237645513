import { InputLabel, Box, FormControl, Select, MenuItem } from '@material-ui/core';
import { observer } from 'mobx-react';
import { humanize } from 'utils/helper';
import { WayToTip as WAY_TO_TIP } from 'models/TipSettings';
import React, { useState } from 'react';

interface IWayToTip {
  value: WAY_TO_TIP;
  onChange: (value: WAY_TO_TIP) => void;
}

export const WayToTip = observer(({ value, onChange }: IWayToTip) => {
  const [_value, setValue] = useState(value);

  function handleChange(event: any) {
    let _val = event.target.value as WAY_TO_TIP;
    setValue(_val as WAY_TO_TIP);
    onChange(_val as WAY_TO_TIP);
  }
  return (
    <Box width={'100%'}>
      <Box display="flex" justifyContent="center" mt={1}>
        <FormControl fullWidth>
          <InputLabel>Way to tip</InputLabel>
          <Select
            labelId={'wayToTip'}
            id="way-to-tip-select"
            name={'wayToTip'}
            value={_value}
            onChange={handleChange}>
            {Object.entries(WAY_TO_TIP).map(([key, value], i) => (
              <MenuItem key={`${value}-${i}`} value={value}>
                {humanize(key)}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
    </Box>
  );
});
