import { rootStore, useStores } from 'containers/App/App';
import { useEffect, useState } from 'react';
import validatorjs from 'validatorjs';
import Api, { getErrorMsg } from 'api';
import { OutlinedInputProps } from 'components/Input/OutlinedInput/OutlinedInput';
import MobxReactForm from 'mobx-react-form';
import { isPhone } from 'services';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const dvr = require('mobx-react-form/lib/validators/DVR');
interface IPhoneNumberHooks {
  onSuccess: (form: any, closePhoneDialog: any) => void;
  onClear: (form: any) => void;
}

/** The hooks for the form */
const hooks: IPhoneNumberHooks = {
  onSuccess: async (form: any) => {
    const { mfaDialogStore, userStore } = rootStore;
    const userId = userStore!.user!.id;
    const { withMfaDialog } = mfaDialogStore;
    const phone = form.$('phone').value;
    const callback = { verify: form.closePhoneDialog };
    const response = await withMfaDialog(() => Api.core.updateUserPhone(userId, phone), {
      callback,
    });
    if (response instanceof Error) {
      rootStore.toastStore.error(getErrorMsg(response));
    } else {
      form.closePhoneDialog();
    }
  },
  onClear: (form: any) => {
    form.clear();
  },
};

const plugins = {
  dvr: dvr({
    package: validatorjs,
    extend: ({ validator }: { validator: any; form: any }) => {
      const phoneFieldRule = {
        function: (value: any) => isPhone(value),
        message: 'The phone number is not a valid format.',
      };
      validator.register('phone', phoneFieldRule.function, phoneFieldRule.message);
      const customValidationMessages = {
        ...validator.getMessages('en'),
        required: 'This field is required',
      };
      validator.setMessages('en', customValidationMessages);
    },
  }),
};

type DefaultValue = OutlinedInputProps['defaultValue'];
const getPhoneField = (defaultValue: DefaultValue) => {
  return [
    {
      name: 'phone',
      label: 'Phone Number',
      type: 'tel',
      rules: ['string', 'phone', 'required'],
      value: defaultValue || '',
    },
  ];
};

const createNewForm = () => {
  const phone = rootStore?.userStore?.user?.phone;
  const form = new MobxReactForm({ fields: getPhoneField(phone) }, { plugins, hooks: hooks });
  return form;
};

interface IPhoneIndicatorProps {
  color: 'green' | 'red';
  tooltip: string;
}

const usePhoneNumberPanel = () => {
  const { userStore } = useStores();
  const [editPhone, setEditPhone] = useState(false);
  const { isPhoneConfirmed, user } = userStore;
  const [form, setForm] = useState<any>(createNewForm);

  const phoneField = form?.$('phone');
  const error = phoneField?.error;
  const phone = user?.phone;
  const submit = form?.onSubmit;
  const reset = form?.reset;
  const loading = form?.submitting;

  useEffect(() => {
    if (form) {
      form.closePhoneDialog = closePhoneDialog;
    }
  }, [form]);

  const phoneIndicatorProps = ((): IPhoneIndicatorProps => {
    return {
      color: isPhoneConfirmed ? 'green' : 'red',
      tooltip: isPhoneConfirmed ? 'Phone confirmed' : 'Phone unconfirmed',
    };
  })();

  const openPhoneDialog = () => {
    setEditPhone(true);
    setForm(createNewForm());
  };

  const closePhoneDialog = () => {
    setEditPhone(false);
  };

  const onExited = () => {
    setForm(null);
  };

  return {
    phoneField,
    submit,
    onExited,
    loading,
    error,
    editPhone,
    openPhoneDialog,
    closePhoneDialog,
    phoneIndicatorProps,
    phone,
  };
};

export default usePhoneNumberPanel;
