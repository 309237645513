import React, { useState } from 'react';
import useAddManagedLocationsDialog from './useAddManagedLocationsDialog';
import SearchBox from 'components/SearchBox';
import { Box, Typography } from '@material-ui/core';
import { Checkbox } from 'components/Checkbox/Checkbox';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronRight } from '@fortawesome/pro-regular-svg-icons';
import { User } from 'models';
import { isEmpty } from 'lodash';
import { useAccountDialogStyles } from './styles';
import clsx from 'clsx';
import { Skeleton } from '@mui/material';
import Dialog from 'components/Dialog/Dialog';

const AddManagedLocationsDialog = ({ user }: { user?: User }) => {
  const {
    open,
    filteredAccountLocations,
    hideChevron,
    disabled,
    loading,
    saving,
    handleClose,
    updateSearch,
    updateUsersLocation,
    handleCheck,
  } = useAddManagedLocationsDialog({ user });
  return (
    <Dialog
      open={open}
      title={'Associate Locations with Manager'}
      content={
        <>
          <Typography variant="body2">{`Select locations that ${user?.firstName || ''} ${
            user?.lastName || ''
          } can manage`}</Typography>
          <Box mt={3}>
            <SearchBox
              variant="normal"
              placeholder="Search location"
              outlinedInput
              onChange={updateSearch}
            />
          </Box>
          <Box mt={3} style={{ height: 320, overflow: 'auto' }}>
            {loading ? (
              <LoadingLocationSkeletons />
            ) : (
              filteredAccountLocations?.map((account) => (
                <AccountSection
                  key={account.accountCode}
                  account={account}
                  handleCheck={handleCheck}
                  hideChevron={hideChevron}
                />
              ))
            )}
          </Box>
        </>
      }
      loading={saving}
      disabled={disabled}
      onCancel={handleClose}
      onConfirm={updateUsersLocation}
      confirmActionName="Save"
    />
  );
};

type AccountSectionProps = {
  account: any;
  handleCheck: (accountCode: string, locationId: number) => void;
  hideChevron?: boolean;
};
const AccountSection = (props: AccountSectionProps) => {
  const [isSectionOpen, setIsSectionOpen] = useState(false);
  const { account, handleCheck, hideChevron } = props;
  const classes = useAccountDialogStyles({ hideChevron });
  const { accountName, accountCode } = account;
  const icon = isSectionOpen ? faChevronDown : faChevronRight;
  const handleOpen = () => {
    if (hideChevron) return;
    setIsSectionOpen(!isSectionOpen);
  };
  if (isEmpty(account.locations)) return null;
  return (
    <Box mb={2}>
      <Box onClick={handleOpen}>
        <Typography className={classes.account} variant="body2" component={'span'}>
          {!hideChevron && (
            <FontAwesomeIcon
              className={clsx(isSectionOpen ? classes.openSection : classes.closedSection)}
              icon={icon}
            />
          )}
          {accountName}
        </Typography>
      </Box>
      {(isSectionOpen || hideChevron) && (
        <Box className={classes.locationsWrapper}>
          {account.locations?.map((location: any) => (
            <Typography className={classes.locationName} key={location.uid} component={'span'}>
              <Checkbox
                disablePadding
                checked={location.isChecked}
                onChange={() => handleCheck(accountCode!, location.id)}
              />
              <Box component={'span'}>{location.name}</Box>
            </Typography>
          ))}
        </Box>
      )}
    </Box>
  );
};

const LoadingLocationSkeletons = () => {
  const style = { borderRadius: 4 };
  const body = Array.from({ length: 2 }).map((_, i) => (
    <Box key={i} mb={3}>
      <Box mb={3}>
        <Skeleton variant="rectangular" width={120} height={19} style={style} />
      </Box>
      {Array.from({ length: 4 }).map((_, i) => (
        <Box mb={1} key={i} height={19} display={'flex'} gridGap={1}>
          <Skeleton variant="rectangular" width={19} height={19} style={style} />
          <Box mr={1} />
          <Skeleton variant="rectangular" width={100} height={19} style={style} />
        </Box>
      ))}
    </Box>
  ));
  return <>{body}</>;
};

export default AddManagedLocationsDialog;
