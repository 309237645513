import Button from 'components/Button/Button';
import { TSignupLayoutAction } from '../SignupLayout';
import { Typography } from '@material-ui/core';
import theme from 'containers/App/theme';

const Action = ({ children, preText, subText, hide, ...rest }: TSignupLayoutAction) => {
  if (!children || hide) return null;

  const margin = theme.spacing(2);
  return (
    <>
      {preText && (
        <Typography style={{ ...theme.typography.subtitle1, marginBottom: margin }} align="center">
          {preText}
        </Typography>
      )}
      <Button data-cy={'continue-button'} fullWidth {...rest}>
        {children}
      </Button>
      {subText && (
        <Typography style={{ ...theme.typography.subtitle1, marginTop: margin }} align="center">
          {subText}
        </Typography>
      )}
    </>
  );
};

export default Action;
