import { Filter } from 'components/FilterBar';
import adaptForFilter from 'filters/adaptForFilter';
import Api from 'api';
import FILTER_MAPPERS from 'filters/filterMappers';

type TFilterKey = 'accountId' | 'locationId' | 'userId' | 'status';

type TFilterValue = (props: Partial<Filter>) => Filter;

let abortController = new AbortController();

const abortApiCallAndSetUpNewController = () => {
  abortController.abort();
  abortController = new AbortController();
};
const FILTERS: Record<TFilterKey, TFilterValue> = {
  accountId: ({ options }): Filter => {
    const getAccounts = (query: string) =>
      adaptForFilter(
        () => Api.core.getAccounts({}, { name: query }, abortController),
        FILTER_MAPPERS.accountId,
        abortApiCallAndSetUpNewController,
      );
    return {
      display: 'Account',
      id: 'accountId',
      label: 'Account Name',
      type: 'tags',
      options: {
        fetch: options?.fetch || getAccounts,
        displayField: {
          value: 'name',
          additional: {
            value: 'address',
          },
          keySearch: { name: 'id' },
        },
      },
    };
  },
  locationId: ({ options }): Filter => {
    const getLocations = (query: string) =>
      adaptForFilter(
        () => Api.core.searchAllLocations({}, { name: query }, abortController),
        FILTER_MAPPERS.locationId,
        abortApiCallAndSetUpNewController,
      );
    return {
      display: 'Location',
      id: 'locationId',
      label: 'Location Name',
      type: 'tags',
      options: {
        fetch: options?.fetch || getLocations,
        displayField: {
          value: 'name',
          additional: {
            value: 'address',
          },
          keySearch: { name: 'id' },
        },
      },
    };
  },
  userId: (props): Filter => {
    const getUsers = (query: string) =>
      adaptForFilter(
        () => Api.core.getUsers({}, { name: query }, abortController),
        FILTER_MAPPERS.userId,
        abortApiCallAndSetUpNewController,
      );
    let fetch = props?.options?.fetch;
    if (!fetch) {
      fetch = getUsers;
    }
    return {
      display: 'To User',
      id: 'userId',
      label: 'User Name',
      type: 'tags',
      options: {
        fetch,
        displayField: {
          value: 'name',
          additional: {
            value: 'email',
          },
          keySearch: { name: 'id' },
        },
      },
      ...props,
    };
  },
  status: (props): Filter => {
    const items = props?.items || [];
    return {
      display: 'Status',
      id: 'status',
      label: 'One of',
      type: 'select',
      items,
    };
  },
};

export default FILTERS;
