import { Theme } from '@material-ui/core/styles';
import { createStyles, makeStyles } from '@material-ui/styles';

interface IStylesProps {
  mobileView?: boolean;
}
export const useStyles = makeStyles<Theme, IStylesProps>((theme) =>
  createStyles({
    container: {
      padding: ({ mobileView }) =>
        mobileView ? theme.spacing(3, 2, 3, 2) : theme.spacing(6, 4, 3, 4),
    },
    innerContainer: {
      display: 'flex',
      flexWrap: 'wrap',
      gap: '5%',
    },
    column: {
      width: '47.5%',
      minWidth: 300,
      flexGrow: 1,
    },
  }),
);
