import { getErrorMsg } from 'api';
import { useStores } from 'containers/App/App';
import { useState } from 'react';
import * as models from 'models';
import Api from 'api';

const useDeleteBillingGroupDialog = (
  onCancel: VoidFunction,
  getBillingEntities: VoidFunction,
  billingEntity: models.IBillingEntity | null,
) => {
  const [deleting, setDeleting] = useState(false);
  const { toastStore } = useStores();

  const deleteBillingGroup = async () => {
    if (!billingEntity) {
      toastStore.error('Something went wrong when deleting billing group');
      return;
    }

    const { accountId, id } = billingEntity;
    try {
      setDeleting(true);
      await Api.billing.deleteBillingEntity(accountId, id);
      getBillingEntities();
      onCancel();
    } catch (error) {
      toastStore.error(getErrorMsg(error));
    } finally {
      setDeleting(false);
    }
  };

  let name = billingEntity
    ? billingEntity?.name
      ? `${billingEntity?.name} (${billingEntity.code})`
      : billingEntity?.code
    : 'N/A';

  return {
    name,
    deleting,
    deleteBillingGroup,
  };
};

export default useDeleteBillingGroupDialog;
