import { createStyles, makeStyles, Theme } from '@material-ui/core';

interface StyleProps {
  fontSize: number;
  width: number;
  variant: 'normal' | 'large';
}

const useStyles = makeStyles<Theme, StyleProps>((theme) =>
  createStyles({
    input: {
      border: `0px`,
      borderRadius: 0,
      width: ({ width }) => width,
      fontSize: ({ fontSize }) => fontSize,

      borderBottom: `1px solid ${theme.palette.primary.main}`,
      '& .MuiInputAdornment-root': {
        '& .MuiTypography-root': {
          fontSize: ({ fontSize, variant }) => (variant === 'large' ? 85 : fontSize),
          fontWeight: ({ variant }) => (variant === 'large' ? 300 : 400),
          color: theme.palette.primary.main,
        },
      },
    },
    fixedAmountLabels: {
      textTransform: 'uppercase',
      fontSize: 16,
      fontWeight: 400,
      lineHeight: '20px',
      color: theme.palette.text.primary,
    },
    inputAdornment: {
      marginRight: theme.spacing(4),
    },
  }),
);

export default useStyles;
