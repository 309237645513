import React from 'react';
import { observer } from 'mobx-react';
import { Avatar, Box, styled, Tooltip, TooltipProps } from '@material-ui/core';
import { useStyles } from './styles';
import Verified from '../../images/verified.svg';
import tooltipClasses from '@mui/material/Tooltip/tooltipClasses';

interface Props {
  src?: string;
  title?: string;
  type?: 'avatar' | 'logo';
  show?: boolean;
}

const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: `0px 3px 1px -1px ${theme.shadows[2]}`,
    fontSize: '14px',
    padding: '8px',
    borderRadius: '8px',
    lineHeight: '150%',
  },
}));

/**
 * Used to display account avatar. If there is no avatar path prop we
 * display a generic avatar using Account icon from 'mdi-material-ui' icon set
 * @param avatar URL formatted path to users avatar image
 */
const PrimaryBadge = observer(({ title = 'Primary', type = 'avatar', show = true }: Props) => {
  const classes = useStyles();

  if (show) {
    return (
      <LightTooltip title={title} className={classes.tooltip}>
        <Box className={classes.avatar}>
          <Avatar src={Verified} />
        </Box>
      </LightTooltip>
    );
  } else {
    return (
      <Box className={classes.avatar}>
        <Avatar src={Verified} />
      </Box>
    );
  }
});

export default PrimaryBadge;
