import { Box } from '@material-ui/core';
import theme from 'containers/App/theme';
import React from 'react';

enum SpacerVariant {
  HORIZONTAL = 'horizontal',
  VERTICAL = 'vertical',
}

interface ISpacerProps {
  variant?: SpacerVariant;
  space?: number;
}

const Spacer = ({ variant = SpacerVariant.HORIZONTAL, space = 1 }: ISpacerProps) => {
  let style;
  if (variant === SpacerVariant.HORIZONTAL) {
    style = { width: '100%', marginTop: theme.spacing(space) };
  } else {
    style = { height: '100%', marginRight: theme.spacing(space) };
  }
  return <Box style={style} />;
};

export default Spacer;
