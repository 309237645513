import { Theme, createStyles } from '@material-ui/core';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default (theme: Theme) =>
  createStyles({
    root: {
      width: '400px',
    },
    professionListRoot: {},
    listItem: {
      marginTop: theme.spacing(2),
      height: theme.spacing(7.5),
      border: `1px solid ${theme.palette.common.black}`,
      borderRadius: theme.shape.borderRadius,
      fontSize: theme.typography.body1.fontSize,
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      color: theme.palette.text.primary,
      '& .MuiIcon-root': {
        fontSize: theme.typography.body1.fontSize,
        color: 'inherit',
      },
    },
    dialog: {
      width: 400,
    },
    active: {
      fontWeight: 'bolder',
    },
  });
