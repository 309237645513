import React, { ReactNode } from 'react';
import { WithStyles, withStyles } from '@material-ui/core/styles';
import { Box, Container, CircularProgress } from '@material-ui/core';
import logo from 'images/tippy_logo_pig@3x.png';

import styles from './styles';

interface LayoutProps extends WithStyles<typeof styles> {
  children: ReactNode;
  inProgress?: boolean;
}
/**
 * The layout wrapper for the self signup process.
 */
function LayoutProps({ classes, children, inProgress = false }: LayoutProps) {
  return (
    <Box className={classes.root}>
      <img src={logo} alt="Tippy" className={classes.pig} />
      <Container>
        <Box className={classes.insideBox}>
          {inProgress && (
            <Box className={classes.loader}>
              <CircularProgress />
            </Box>
          )}
          {children}
        </Box>
      </Container>
    </Box>
  );
}

export default withStyles(styles)(LayoutProps);
