export enum DebitCardStatus {
  CREATING = 'creating',
  REJECTED = 'rejected',
  APPROVED = 'approved',
  CONDITIONAL = 'conditional',
  UPGRADED = 'upgraded',
  DELETED = 'deleted',
}

export interface Card {
  id: number;
  accountBrand: string;
  cardBrand: string;
  processor: string;
  status: DebitCardStatus;
  balance: string;
}

export interface CardPayout {
  id: number;
  walletId: number;
  ledgerId: number;
  transactionId: string;
  processor: string;
  amount: string;
  processorFee: string;
  status: string;
  errorCode?: number;
  errorMessage?: string;
  createdAt: string;
}
