import React, { ReactNode } from 'react';
import { observer } from 'mobx-react';
import { Box, ListItemAvatar, ListItemText, Popper } from '@material-ui/core';
import Avatar from 'components/ImageIcon';
import OutlinedInput from 'components/Input/OutlinedInput';
import { Autocomplete } from '@mui/material';
import { User } from 'models';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';
import { observable } from 'mobx';

const PopperContainer = (props: any): JSX.Element => (
  <Popper
    {...props}
    disablePortal={false}
    popperOptions={{
      modifiers: {
        offset: {
          offset: '0,15',
        },
      },
    }}
  />
);

const AutocompleteInput = (props: any, startAdornment: ReactNode): JSX.Element => (
  <OutlinedInput
    {...props}
    label={'Search User'}
    fullWidth
    InputProps={{
      ...props.InputProps,
      startAdornment,
    }}
  />
);

const AutocompleteOptions = function (
  props: any,
  { firstName, lastName, avatar, email }: User,
  { inputValue }: any,
): JSX.Element {
  const fullName = `${firstName} ${lastName}`;
  const matches = match(fullName, inputValue, { insideWords: true });
  const parts = parse(fullName, matches);

  return (
    <Box component="li" {...props}>
      <ListItemAvatar>
        <Avatar src={avatar} />
      </ListItemAvatar>
      <ListItemText primary={getAutocompleteParts(parts)} secondary={email || ''} />
    </Box>
  );
};

const getAutocompleteParts = function (parts: any): JSX.Element {
  return parts.map((part: any, index: number) => (
    <span
      key={index}
      style={{
        fontWeight: part.highlight ? 700 : 400,
      }}>
      {part.text}
    </span>
  ));
};

interface UsersAutocompleteProps {
  users: User[];
  startAdornment?: ReactNode;
  clearValueOnSelect?: boolean;
  onChange: (val: User | null) => void;
}

/**
 * Autocomplete users select with filters.
 */
@observer
class UsersAutocomplete extends React.Component<UsersAutocompleteProps> {
  @observable private inputValue = null;
  render() {
    const { users, startAdornment, clearValueOnSelect, onChange } = this.props;
    return (
      <Box overflow={'hidden'}>
        <Autocomplete
          value={clearValueOnSelect ? this.inputValue : undefined}
          disablePortal
          options={users}
          getOptionLabel={({ firstName, lastName }) => `${firstName} ${lastName}`}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          renderInput={(props) => AutocompleteInput(props, startAdornment)}
          renderOption={AutocompleteOptions}
          PopperComponent={PopperContainer}
          onChange={(_ev, val: User | null) => {
            if (clearValueOnSelect) {
              this.inputValue = null;
            }

            onChange(val);
          }}
        />
      </Box>
    );
  }
}

export default UsersAutocomplete;
