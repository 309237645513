import {
  faBadgeDollar,
  faChartLineDown,
  faCircleExclamation,
  faCreditCard,
} from '@fortawesome/pro-regular-svg-icons';
import { HorizontalStatCard } from 'containers/UserDetails';
import { IRefundHistoryStats } from '../RefundsHistory';
import { Grid } from '@mui/material';

interface IRefundStatsProps {
  loading: boolean;
  stats?: IRefundHistoryStats;
}

const RefundStats = ({ loading, stats }: IRefundStatsProps) => {
  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={6} md={6} lg={3}>
        <HorizontalStatCard
          fontAwesomeIcon={faCreditCard}
          duration={1}
          title="Gross"
          prefix="$"
          separator=","
          decimals={2}
          loading={loading}>
          {stats && +stats.grossSum}
        </HorizontalStatCard>
      </Grid>
      <Grid item xs={12} sm={6} md={6} lg={3}>
        <HorizontalStatCard
          fontAwesomeIcon={faBadgeDollar}
          duration={1}
          title="Net"
          color="yellow"
          prefix="$"
          separator=","
          decimals={2}
          loading={loading}>
          {stats && +stats.netSum}
        </HorizontalStatCard>
      </Grid>
      <Grid item xs={12} sm={6} md={6} lg={3}>
        <HorizontalStatCard
          fontAwesomeIcon={faChartLineDown}
          duration={1}
          prefix="$"
          separator=","
          title="Our loss"
          color="purple"
          decimals={2}
          loading={loading}>
          {stats && +stats.ourFeeSum}
        </HorizontalStatCard>
      </Grid>
      <Grid item xs={12} sm={6} md={6} lg={3}>
        <HorizontalStatCard
          fontAwesomeIcon={faCircleExclamation}
          duration={1}
          prefix="$"
          separator=","
          decimals={2}
          title="Processor loss"
          loading={loading}>
          {stats && +stats.processorFeeSum}
        </HorizontalStatCard>
      </Grid>
    </Grid>
  );
};
export default RefundStats;
