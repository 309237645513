import { Theme, createStyles } from '@material-ui/core';
import { statusColors } from '../App/theme';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default (theme: Theme) =>
  createStyles({
    root: {
      '& .MuiPaper-root': {
        width: '546px',
      },
    },
    circleDot: { marginBottom: 3 },
    settingListItemKey: {
      textTransform: 'capitalize',
      marginRight: theme.spacing(6),
      color: 'grey',
    },
    settingListItemValue: {
      display: 'inline',
    },
    explanation: {
      padding: 10,
    },
    progressBarWrapper: {
      display: 'flex',
      borderRadius: '4px',
      overflow: 'hidden',
      '& span': {
        paddingLeft: '8px',
        paddingRight: '8px',
        lineHeight: '40px',
      },
      width: '100%',
    },
    processedBar: {
      textAlign: 'left',
      backgroundColor: statusColors.green,
    },
    failedBar: {
      textAlign: 'right',
      backgroundColor: statusColors.red,
    },
    pendingBar: {
      textAlign: 'center',
      backgroundColor: statusColors.yellow,
    },
    drawerContent: {
      paddingLeft: theme.spacing(7),
      paddingRight: theme.spacing(7),
    },
    skeleton: {
      width: 100,
      height: 24,
      margin: 0,
    },
    secondItem: {
      marginLeft: 44,
    },
  });
