import { Theme, createStyles } from '@material-ui/core';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default (theme: Theme) =>
  createStyles({
    root: {},
    moneyCell: {
      color: theme.palette.primary.main,
    },
    greenCell: {
      color: theme.palette.primary.main,
    },
    talent: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    chipProcessed: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
    chipFailed: {
      backgroundColor: '#E01E5A',
      color: theme.palette.primary.contrastText,
    },
    chipPending: {
      backgroundColor: '#FEBE00',
      color: theme.palette.primary.contrastText,
    },
    chipCreated: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
    chipCanceled: {
      backgroundColor: '#E01E5A',
      color: theme.palette.primary.contrastText,
    },
    chipVoided: {
      backgroundColor: '#000000',
      color: theme.palette.primary.contrastText,
    },
    chipOpen: {
      backgroundColor: '#BDBDBD',
      color: theme.palette.primary.contrastText,
    },
    fab: {
      position: 'fixed',
      bottom: theme.spacing(3),
      right: theme.spacing(2),
    },
    modalActionsButtons: {
      marginRight: 12,
      height: 32,
      borderRadius: 8,
      padding: `6px 12px`,
      textTransform: 'none',
    },
    dialogActions: {
      paddingTop: theme.spacing(5),
      paddingBottom: theme.spacing(2),
      paddingRight: theme.spacing(3),
    },
  });
