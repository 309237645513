import React from 'react';
import { Box, IconButton, Tooltip, Typography } from '@material-ui/core';
import { Eye, EyeOff } from 'mdi-material-ui';
import ContentCopy from 'mdi-material-ui/ContentCopy';
import useAccountToken from './useAccountToken';
import { IntegrationApp } from 'models/Integration';
import useStyles from './styles';

interface AccountToken {
  app: IntegrationApp;
}

function AccountToken(props: AccountToken) {
  const { app } = props;

  const classes = useStyles();
  const [maskToken, toggleTokenMask, getMaskedToken, copy] = useAccountToken();

  return (
    <>
      <Box display="flex">
        <p className={classes.textMask}>
          {maskToken ? getMaskedToken(app.accountToken) : app.accountToken}
        </p>

        <Tooltip title="Copy token to clipboard">
          <IconButton
            style={{ marginLeft: '12px' }}
            className={classes.btnMask}
            size="small"
            onClick={() => copy(app.accountToken)}>
            <ContentCopy fontSize="small" />
          </IconButton>
        </Tooltip>

        <IconButton size="small" onClick={toggleTokenMask}>
          {maskToken ? (
            <Eye color="primary" fontSize="small" className={classes.btnMask} />
          ) : (
            <EyeOff color="primary" fontSize="small" className={classes.btnMask} />
          )}
        </IconButton>
      </Box>
      <Typography variant={'body2'}>
        Copy the above code and provide it to the integration provider. See instructions to learn
        more.
      </Typography>
    </>
  );
}

export default AccountToken;
