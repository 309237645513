import { observer } from 'mobx-react-lite';
import DD from 'components/DashDrawer';
import { Box, Button, Typography } from '@material-ui/core';
import { KeyboardArrowRight } from '@mui/icons-material';
import { useStyles } from './styles';
import React from 'react';
import { useAddStationPoolsOrGroups } from './useAddStationPoolsOrGroups';

export const AddStationPoolsOrGroups = observer(() => {
  const classes = useStyles();
  const [isPoolsEnabled, addPools, addGroup, goBack] = useAddStationPoolsOrGroups();

  function Title() {
    let title = 'Add Pools or Groups';
    if (!isPoolsEnabled) {
      title = 'Add Groups';
    }
    return <DD.Title onBack={goBack}>{title}</DD.Title>;
  }

  function Body() {
    if (isPoolsEnabled) {
      return (
        <Box
          minWidth={520}
          height="100%"
          style={{ display: 'flex', flexDirection: 'column', gap: '14px' }}>
          <Button
            onClick={addPools}
            style={{ display: 'flex', justifyContent: 'space-between' }}
            classes={{ root: classes.button }}
            endIcon={<KeyboardArrowRight style={{ fontSize: 25, opacity: 0.6 }} />}>
            <Typography className={classes.textButton}>Pools</Typography>
          </Button>
          {/* <Divider /> */}
          <Button
            onClick={addGroup} //useAddStationPoolsOrGroups --> addPools()
            style={{ display: 'flex', justifyContent: 'space-between' }}
            classes={{ root: classes.button }}
            endIcon={<KeyboardArrowRight style={{ fontSize: 25, opacity: 0.6 }} />}>
            <Typography className={classes.textButton}>Groups</Typography>
          </Button>
          {/* <Divider /> */}
        </Box>
      );
    } else {
      return (
        <Box minWidth={520} height="100%" style={{ display: 'flex', flexDirection: 'column' }}>
          <Button
            onClick={addGroup}
            style={{ display: 'flex', justifyContent: 'space-between' }}
            classes={{ root: classes.button }}
            endIcon={<KeyboardArrowRight style={{ fontSize: 25, opacity: 0.6 }} />}>
            <Typography className={classes.textButton}>Groups</Typography>
          </Button>
          {/* <Divider /> */}
        </Box>
      );
    }
  }

  return (
    <DD>
      <DD.Content>
        <Title />
        <Body />
      </DD.Content>
    </DD>
  );
});
