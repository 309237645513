import { useStores } from 'containers/App/App';
import { AddStation, IStation } from 'models/Station';

export function useStationNavigation() {
  const { stationsStore } = useStores();
  const { setAddStationState, initializeStationForQrCode, initializeEditStation } = stationsStore!;

  function resetStationState() {
    setAddStationState(undefined);
    stationsStore.previousStationName = undefined;
  }

  /**
   * Sets addStationState to AddStation.ADD
   */
  function openStation() {
    setAddStationState(AddStation.ADD);
  }

  /**
   * Sets addStationState to AddStation.GROUP
   */
  function openGroup() {
    setAddStationState(AddStation.GROUP);
  }

  /**
   * Sets addStationState to AddStation.POOLS_GROUPS
   */
  function openPoolsOrGroups() {
    setAddStationState(AddStation.POOLS_GROUPS);
  }

  /**
   * Sets addStationState to AddStation.USERS
   */
  function openUsers() {
    setAddStationState(AddStation.USERS);
  }

  /**
   * Sets addStationState to AddStation.GROUP_USERS
   */
  function openGroupUsers() {
    setAddStationState(AddStation.GROUP_USERS);
  }

  /**
   * Sets addStationState to AddStation.POOLS
   */
  function openPools() {
    setAddStationState(AddStation.POOLS);
  }

  /**
   * Sets addStationState to AddStation.GROUP_POOLS
   */
  function openGroupPools() {
    setAddStationState(AddStation.GROUP_POOLS);
  }

  /**
   * Sets addStationState to AddStation.GROUP_POOL
   */
  function openGroupPool() {
    setAddStationState(AddStation.GROUP_POOL);
  }

  /**
   * Sets addStationState to AddStation.POOL
   */
  function openPool() {
    setAddStationState(AddStation.POOL);
  }

  /**
   * Sets addStationState to AddStation.QR_CODE and sets station
   */
  function openQrCode(station: IStation) {
    initializeStationForQrCode(station);
    setAddStationState(AddStation.QR_CODE);
  }

  /**
   * Sets addStationState to AddStation.ADD and sets station.
   * This method is used for editing stations.
   */
  async function openStationAndInitialize(station: IStation) {
    initializeEditStation(station);
    setAddStationState(AddStation.ADD);
  }

  const stationNavigation = {
    resetStationState,
    openStation,
    openGroup,
    openPoolsOrGroups,
    openUsers,
    openGroupUsers,
    openPools,
    openGroupPools,
    openGroupPool,
    openPool,
    openQrCode,
    openStationAndInitialize
  };

  return { stationNavigation };
}
