import { Theme, createStyles } from '@material-ui/core';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default (theme: Theme) =>
  createStyles({
    root: {},
    avatar: {
      zIndex: 1,
      width: 187,
      height: 187,
      padding: 0,
      '& .MuiAvatar-root': {
        width: 187,
        height: 187,
      },
      '& .MuiAvatar-colorDefault': {
        backgroundColor: '#E8E8E8',
      },
    },
    avatarHover: {
      backgroundColor: theme.palette.primary.light,
    },
    typography: {
      textTransform: 'uppercase',
      fontSize: theme.typography.fontSize,
      fontWeight: 600,
      letterSpacing: '0.75px',
    },
    instructionText: {
      textAlign: 'center',
      color: `rgba(0, 0, 0, 0.38)`,
      lineHeight: '36px',
    },
    menuItem: {
      display: 'flex',
      alignItems: 'center',
      '& .MuiSvgIcon-root': {
        marginRight: '10px',
      },
    },
    draggingIcon: {
      color: `rgba(0, 0, 0, 0.12)`,
      width: 52,
      height: 52,
      position: 'absolute',
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
      margin: 'auto',
      backgroundColor: theme.palette.primary.main,
    },
    imageIcon: {
      color: theme.palette.common.black,
      height: 32,
    },
    cancelIconWrapper: {
      height: 38,
      width: 38,
      borderRadius: '50%',
      backgroundColor: theme.palette.primary.main,
      border: `3px solid white`,
      position: 'absolute',
      top: -10,
      right: -10,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      zIndex: 2,
      pointerEvents: 'auto',
    },
    dialog: {
      '& .MuiDialogContent-root': {
        padding: 0,
      },
      maxWidth: `calc(100% - ${theme.spacing(2)}px)`,
    },
    cameraIcon: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: '50%',
      border: '2px solid',
      color: theme.palette.primary.main,
      width: '70px',
      height: '70px',
      '& .MuiSvgIcon-root': {
        fontSize: '46px',
        color: theme.palette.primary.main,
      },
    },
    actionContainer: {
      '& .MuiGrid-item': {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
      },
    },
    actionItem: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
    },
    photoIconWrapper: {
      height: 187,
      width: '100%',
      border: `2px dashed`,
      borderColor: theme.palette.common.black,
      borderRadius: theme.shape.borderRadius,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      padding: theme.spacing(0, 3, 0, 3),
      paddingBottom: 'auto',
      position: 'relative',
      cursor: 'pointer',
      '& > *': {
        pointerEvents: 'none',
      },
    },
    avatarWrapper: {
      padding: 0,
      border: 0,
    },
    progress: {
      height: 18,
      position: 'absolute',
      bottom: 0,
      zIndex: 2,
      left: 0,
      right: 0,
      borderRadius: theme.spacing(0, 0, 16, 16),
    },
    fileDrop: {
      width: '100%',
      position: 'relative',
    },
    fileDropAvatar: {
      width: 187,
      height: 187,
    },
  });
