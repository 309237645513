import { faCircleX } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Chip } from '@material-ui/core';
import { Stack } from '@mui/material';
import React, { useCallback } from 'react';
import { useStyles } from './styles';
import { humanize } from 'utils/helper';

interface ChipsListProps {
  values: string[];
  onDelete: (v: string) => void;
  humanizedChip?: boolean;
}

export const ChipsList = ({ values, onDelete, humanizedChip }: ChipsListProps) => {
  const classes = useStyles();

  const toggleClass = useCallback(
    (event: any) => {
      event.target.parentElement.classList.toggle(classes.isSelected);
    },
    [classes.isSelected],
  );

  return values.length ? (
    <Stack direction="row" flexWrap="wrap" marginTop={0} marginBottom={1.5}>
      {values.map((value: string) => (
        <Chip
          clickable={false}
          className={classes.chip}
          key={value}
          label={humanizedChip ? humanize(value) : value}
          onClick={toggleClass}
          onDelete={() => {
            onDelete(value);
          }}
          deleteIcon={
            <FontAwesomeIcon
              onMouseDown={(event) => event.stopPropagation()}
              icon={faCircleX}
              className={classes.chipDeleteIcon}
            />
          }
        />
      ))}
    </Stack>
  ) : null;
};
