import React from 'react';
import { observable, action, computed, makeObservable } from 'mobx';
import { observer } from 'mobx-react';
import { WithStyles, withStyles } from '@material-ui/core/styles';
import { Link as RouterLink } from 'react-router-dom';

import { Box, Link, Dialog, Typography } from '@material-ui/core';
import { Circle } from 'mdi-material-ui';

import DP from 'components/DashPanel';
import AddLocation from 'containers/AddLocation';
import { Location } from 'models';
import { paths } from 'routes';
import debounce from 'lodash/debounce';

import styles, { useStyles } from './styles';
import { EmptyPanelMessage } from 'components/EmptyPanelMessage/EmptyPanelMessage';
import { faPlus } from '@fortawesome/pro-regular-svg-icons';
import { useStores } from 'containers/App/App';

/** Represents a single device in the list */
const LocationListItem = observer(({ children }: { children: Location }) => {
  const { userStore } = useStores();
  const { isAdmin } = userStore;

  const location = children;
  const secondaryText = `${location.city} ${location.state} | ${location.code}`;
  const menu = [
    {
      label: 'Coming soon',
      onClick: () => {},
    },
  ];

  const classes = useStyles();
  return (
    <DP.ListItem
      key={location.id}
      icon={
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          style={{ fontSize: '21px' }}>
          <Circle fontSize="inherit" color={location.isActive ? 'primary' : 'error'} />
        </Box>
      }
      primary={
        <Link component={RouterLink} to={paths.locationDetails(location.id)} color="textPrimary">
          {/* Admin will see "locationName", 
          Owner will see "name" (which can be "displayName" if it is set, or "locationName" if it isn't) */}
          {isAdmin ? location.locationName : location.name}
        </Link>
      }
      secondary={<Typography className={classes.textSecondary}>{secondaryText}</Typography>}
      menu={menu}
    />
  );
});

interface LocationsPanelProps extends WithStyles<typeof styles> {
  locations?: Location[];
  accountId: number;
  onLocationAdded?: () => Promise<void>;
  showAddButton?: boolean;
  fullHeight?: boolean;
  fullWidth?: boolean;
}

/**
 * Displays a scrollable list of locations for quick viewing. Includes
 * a filter.
 */
@observer
class LocationsPanel extends React.Component<LocationsPanelProps> {
  constructor(props: LocationsPanelProps) {
    super(props);
    makeObservable(this);
  }

  private panelName = 'Locations';

  /** The search text */
  @observable private searchText = '';

  /** The search term being filtered currently */
  @observable private filteringBy = '';

  /** Whether the modal for adding a location is open */
  @observable public addLocationModalOpen = false;

  @action.bound public openAddLocationModal() {
    this.addLocationModalOpen = true;
  }

  @action.bound public closeAddLocationModal() {
    this.addLocationModalOpen = false;
  }

  /** The locations from the props, filtered by the search string */
  @computed private get locations() {
    if (this.filteringBy === '') {
      return this.props.locations;
    }
    return (
      this.props.locations &&
      this.props.locations.filter((location) =>
        location.name.toLowerCase().includes(this.filteringBy.toLowerCase()),
      )
    );
  }

  /** A total count of the locations */
  @computed private get count() {
    return this.props.locations && this.props.locations.length;
  }

  @computed get numberOfLocations() {
    if (this.locations) {
      return this.locations.length;
    } else {
      return 0;
    }
  }

  /** Updates the search text */
  @action.bound private handleSearchOnChange(e: React.ChangeEvent<HTMLInputElement>) {
    this.searchText = e.target.value;
    this.updateFilterDebounced(e.target.value);
    if (e.target.value === '') {
      this.updateFilterDebounced &&
        this.updateFilterDebounced.flush &&
        this.updateFilterDebounced.flush();
    }
  }

  @action.bound private updateFilter(s: string) {
    this.filteringBy = s;
  }

  private updateFilterDebounced = debounce(this.updateFilter, 500);

  render() {
    const { showAddButton, accountId, onLocationAdded, classes, fullHeight, fullWidth } =
      this.props;

    if (!this.locations) {
      return (
        <DP fullHeight={fullHeight} fullWidth={fullWidth}>
          <DP.Header>
            <DP.Title panel>Locations</DP.Title>
          </DP.Header>
          <DP.Body>
            <DP.Loading items={4} />
          </DP.Body>
        </DP>
      );
    }
    return (
      <DP fullHeight={fullHeight} fullWidth={fullWidth}>
        <DP.Header>
          <Dialog open={this.addLocationModalOpen} onClose={this.closeAddLocationModal} fullWidth>
            <AddLocation
              accountId={accountId}
              close={this.closeAddLocationModal}
              onLocationAdded={onLocationAdded}
            />
          </Dialog>
          <DP.Title count={this.count} panel>
            {this.panelName}
          </DP.Title>
          {showAddButton && (
            <DP.Actions>
              <DP.IconButton
                fontAwesomeIcon={{
                  icon: faPlus,
                }}
                tooltip="Add locations"
                primary
                onClick={this.openAddLocationModal}
              />
            </DP.Actions>
          )}
        </DP.Header>
        {this.props.locations && this.props.locations.length > 0 ? (
          <Box>
            <DP.SearchInput value={this.searchText} onChange={this.handleSearchOnChange} />

            <DP.List height={this.numberOfLocations < 5 ? 'short' : 'normal'}>
              {this.locations.map((location) => (
                <LocationListItem key={location.id}>{location}</LocationListItem>
              ))}
            </DP.List>
          </Box>
        ) : (
          <DP.Body>
            <Box display={'flex'} alignContent={'center'}>
              <EmptyPanelMessage panelTitle={this.panelName} />
            </Box>
          </DP.Body>
        )}
      </DP>
    );
  }
}

export default withStyles(styles)(LocationsPanel);
