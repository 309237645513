import React from 'react';
import { observable, action, makeObservable, toJS } from 'mobx';
import { observer } from 'mobx-react';
import { WithStyles, withStyles } from '@material-ui/core/styles';
import { Grid, Box, Dialog, DialogContent } from '@material-ui/core';
import ActionsMenu, { PositionMenu } from 'components/ActionsMenu';
import { inject, WithUserStore, WithToastStore } from 'stores';
import Api, { RequestMetaData } from 'api';
import { adaptForDataGridPro } from 'services';
import HardwareEntry from 'components/HardwareEntry';
import InventoryEntry from 'components/InventoryEntry';
import InventoryQtyEntry from 'components/InventoryQtyEntry';
import { Filter } from 'components/FilterBar/FilterBar';
import FilterBar from 'components/FilterBar';
import DataGridInfiniteScroll from 'components/DataGridInfiniteScroll';
import styles from './styles';
import * as models from 'models';
import PlusFabButton from 'components/PlusFabButton/PlusFabButton';
import { RouteComponentProps } from 'react-router-dom';

interface HardwareProps extends WithStyles<typeof styles>, WithUserStore, WithToastStore, RouteComponentProps {
  handleSelectedTab?: any;
}

/**
 * The container for the hardware tab in the devices section.
 */
@inject('userStore', 'toastStore')
@observer
class Hardware extends React.Component<HardwareProps & RouteComponentProps> {
  constructor(props: HardwareProps) {
    super(props);
    makeObservable(this);
  }

  /** Active filters as returned by FilterBar */
  @observable private activeFilters: Record<string, unknown> = {};

  /** Whether the `Add/Edit Hardware` dialog menu is open */
  @observable private hardwareDialogState = false;

  /** Whether the `Add items Hardware` dialog is open */
  @observable private itemsDialogState = false;

  /** The App Integrations for this account */
  @observable public selectedHardware?: models.Hardware;

  /** The key for the datagrid, so that we can refresh it */
  @observable private dataGridKey = Date.now();

  /** Opens the `Add Hardware` dialog */
  @action.bound public handleAddHardwareDialogState(state: boolean) {
    this.selectedHardware = undefined;
    this.hardwareDialogState = state;
  }

  /** Opens the `Edit Hardware` menu */
  @action.bound public handleEditHardwareDialogState(row: models.Hardware) {
    this.selectedHardware = row;
    this.hardwareDialogState = true;
  }

  /** Opens the `Options` menu */
  @action.bound public handleItemsDialogState(row?: models.Hardware) {
    this.selectedHardware = row;
    this.itemsDialogState = !this.itemsDialogState;
  }

  /** Handles new items being added to the hardware */
  @action.bound public handleNewHardwareAdded() {
    this.selectedHardware = undefined;
    this.hardwareDialogState = false;
    this.dataGridKey = Date.now();
  }

  /** Handles new items being added to the hardware */
  @action.bound public handleNewItemsAdded() {
    this.selectedHardware = undefined;
    this.itemsDialogState = false;
    this.dataGridKey = Date.now();
  }

  /** Get hardware list */
  public getHardwareData = adaptForDataGridPro(
    async (rmd: RequestMetaData) =>
      await Api.fulfillment.getHardwareList({
        ...rmd,
        filters: {
          ...this.activeFilters,
        },
      }),
    (i: models.Hardware) => ({
      ...i,
    }),
  );

  renderActionsColumn = ({ row }: any) => {
    const options = [
      {
        label: 'Add Items',
        action: () => this.handleItemsDialogState(row),
      },
      {
        label: 'Edit',
        action: () => this.handleEditHardwareDialogState(row),
      },
      {
        label: ' View Items',
        action: () => this.props.handleSelectedTab('items', { manufacturer: row.manufacturer }),
      },
      {
        label: 'View Shipments',
        action: () => this.props.history.push(`/devices-shipments/${row.id}`),
      },
    ];
    return <ActionsMenu options={options} position={PositionMenu.VERTICAL} />;
  };

  gridColumns = [
      { headerName: 'Manufacturer', field: 'manufacturer', minWidth: 200, flex: 1 },
      { headerName: 'Model', field: 'model', minWidth: 200, flex: 1 },
      { headerName: 'Type', field: 'type', minWidth: 200, flex: 1 },
      { headerName: 'Qty', field: 'quantity', minWidth: 50, flex: 1 },
      { headerName: 'Available', field: 'availableQuantity', minWidth: 50, flex: 1 },
      {
        headerName: ' ',
        field: 'link',
        minWidth: 20,
        filterable: false,
        renderCell: this.renderActionsColumn,
      },
    ];
    
  filters: Filter[] = [
    { display: 'Manufacturer', id: 'manufacturer', label: 'Contains', type: 'text' },
    { display: 'Model', id: 'model', label: 'Contains', type: 'text' },
    {
      display: 'Type',
      id: 'type',
      label: 'hardware type',
      type: 'select',
      items: [
        { label: 'TABLET', value: models.HardwareType.TABLET },
        { label: 'READER', value: models.HardwareType.READER },
      ],
    },
  ];

  render() {
    const { classes } = this.props;
    return (
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Dialog
            open={this.hardwareDialogState}
            onClose={() => this.handleAddHardwareDialogState(false)}
            maxWidth="md">
            <DialogContent>
              <Box className={classes.addInventoryBox}>
                <HardwareEntry
                  onComplete={this.handleNewHardwareAdded}
                  data={toJS(this.selectedHardware)}
                />
              </Box>
            </DialogContent>
          </Dialog>
          <Dialog
            open={this.itemsDialogState}
            onClose={() => this.handleItemsDialogState()}
            maxWidth="md">
            <DialogContent>
              <Box className={classes.addInventoryBox}>
                {this.selectedHardware?.requiresInventory && (
                  <InventoryEntry
                    onComplete={this.handleNewItemsAdded}
                    data={toJS(this.selectedHardware)}
                  />
                )}
                {!this.selectedHardware?.requiresInventory && (
                  <InventoryQtyEntry
                    onComplete={this.handleNewItemsAdded}
                    data={toJS(this.selectedHardware)}
                  />
                )}
              </Box>
            </DialogContent>
          </Dialog>
          <Box mt={3} pb={10}>
            <FilterBar
              filters={this.filters}
              onChange={(filters: Record<string, unknown>) => {
                this.activeFilters = filters;
              }}
            />
            {/* <Box mb={3}>
              <Title count={4365} size={'small'}>
                Kiosks:
              </Title>
            </Box> */}

            <DataGridInfiniteScroll
              columns={this.gridColumns}
              fetch={this.getHardwareData}
              refetchKey={{ ...this.activeFilters, key: this.dataGridKey }}
              pathname={this.props.location.pathname}
            />
          </Box>
          <PlusFabButton onClick={() => this.handleAddHardwareDialogState(true)} />
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(Hardware);
