import { Box, useMediaQuery, useTheme } from '@material-ui/core';
import React, { useState } from 'react';
import Carousel from 'react-material-ui-carousel';
import useStyles from './styles';

import reviewImg1 from '../../images/promo/Dashboard_01.jpg';
import reviewImg2 from '../../images/promo/Dashboard_02.jpg';
import reviewImg3 from '../../images/promo/Dashboard_03.jpg';
import reviewImg4 from '../../images/promo/Dashboard_04.jpg';

import reviewImg6 from '../../images/promo/Dashboard_06.jpg';

import CoverImage from 'components/CoverImage/CoverImage';
import ReviewCard, { IReview } from 'components/ReviewCard/ReviewCard';
import _ from 'lodash';
import { rootStore } from 'containers/App/App';
import { observer } from 'mobx-react';
import { TAnonLayoutIcon } from 'components/AnonLayout/AnonLayout';

interface ICarouselScreenWrapper {
  submitting?: boolean;
  anonLayoutIcon?: TAnonLayoutIcon;
  children: React.ReactNode;
}

interface IReviewData {
  src: string;
  review: IReview;
}

const reviewData: Array<IReviewData> = [
  {
    src: reviewImg1,
    review: {
      message: `Tippy has been a game-changer for my grooming business, ensuring every tail wag and purr of satisfaction is aptly rewarded!`,
      customer: `Joanne B`,
      rating: 5,
    },
  },
  {
    src: reviewImg2,
    review: {
      message: `Tippy has made my tips go up tremendously! It is very easy to use and our clients even love it!"`,
      customer: `Megan Fitzgerald, Salon Sanity Inc`,
      rating: 5,
    },
  },
  {
    src: reviewImg3,
    review: {
      message: `"Getting my tips in real time has helped out a lot between checks."`,
      customer: ` Larissa J.`,
      rating: 5,
    },
  },
  {
    src: reviewImg4,
    review: {
      message: `"Tippy has completely revolutionized my tip earnings. Now every cheer, every pour, and every smile earns me what they truly deserve!"`,
      customer: `Jeremy B.`,
      rating: 5,
    },
  },
  {
    src: reviewImg6,
    review: {
      message: `"My salon loves Tippy! It's great to see how much your clients appreciate and love you with the gift of a tip!"`,
      customer: `Angie Lashbrook, KJ & Kompany`,
      rating: 5,
    },
  },
];

function CarouselScreenWrapper(props: ICarouselScreenWrapper) {
  const classes = useStyles();
  const theme = useTheme();
  const [reviews] = useState(_.shuffle(reviewData));
  const mobileView = rootStore?.uiStore.mobileView;
  const hideCarousel = useMediaQuery(theme.breakpoints.down('md'));

  const { submitting, anonLayoutIcon } = props;
  const style = mobileView ? { minHeight: '100dvh' } : { height: '100dvh' };

  return (
    <Box className={classes.container} style={style}>
      <Box className={classes.content} style={style}>
        {props.children}
      </Box>
      {!hideCarousel && (
        <Carousel
          navButtonsAlwaysInvisible={true}
          stopAutoPlayOnHover={false}
          className={classes.carousel}
          indicators={false}
          timeout={400}
          interval={4000}>
          {reviews.map((item: IReviewData, i: number) => (
            <CoverImage key={i} imgPath={item.src}>
              <Box className={classes.ratingsCardWrapper}>
                <ReviewCard review={item.review} />
              </Box>
            </CoverImage>
          ))}
        </Carousel>
      )}
    </Box>
  );
}

export default observer(CarouselScreenWrapper);
