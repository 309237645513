import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { observer } from 'mobx-react';
import { Route, Switch } from 'react-router-dom';

import { inject, WithUserStore } from 'stores';
import { paths } from 'routes';

import DashboardLayout from 'containers/DashboardLayout';

import SignupStore from './SignupStore';
import Owner from './Owner';
import AccountStep from './AccountStep';
import LocationsStep from './LocationsStep';
import CouponsStep from './CouponsStep';
import ReviewStep from './ReviewStep';
import ProductStep from './ProductStep';
import Stepper from './Stepper';
import { Box } from '@material-ui/core';
import Title from 'components/Title';

/** Here we define what kind of props this component takes */
type AccountSignupProps = RouteComponentProps<{ accountId?: string }> & // Adds the router props (history, match, location)
  WithUserStore; // Adds the userStore prop

/**
 * The dashboard home page, displayed when you log in.
 */

const PAGE_TITLE = 'Account signup';
@inject('userStore')
@observer
class AccountSignup extends React.Component<AccountSignupProps> {
  public constructor(props: AccountSignupProps) {
    super(props);
  }
  public signupStore = new SignupStore(this.props.userStore!.rootStore);
  componentDidMount() {
    this.signupStore.init();
  }
  render() {
    const path = this.props.match.path;
    const as = paths.adminAccountSignup();
    return (
      <DashboardLayout>
        <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
          <Title mb={3}>{PAGE_TITLE}</Title>
        </Box>
        <Box pb={10}>
        <Stepper showProductStep currentStep={this.signupStore.currentStep} />
        <Switch>
          <Route
            path={path}
            exact
            render={(props: any) => (
              <Owner {...props} signupStore={this.signupStore} nextRoute={as.account} />
            )}></Route>
          <Route
            path={as.owner()}
            exact
            render={(props: any) => (
              <Owner {...props} signupStore={this.signupStore} nextRoute={as.account} />
            )}></Route>
          <Route
            path={as.account()}
            exact
            render={(props: any) => (
              <AccountStep {...props} signupStore={this.signupStore} nextRoute={as.product} />
            )}></Route>
          <Route
            path={as.product(`:accountId`)}
            exact
            render={(props: any) => (
              <ProductStep {...props} signupStore={this.signupStore} nextRoute={as.locations} />
            )}
          />
          <Route
            path={as.locations(`:accountId`)}
            exact
            render={(props: any) => (
              <LocationsStep {...props} signupStore={this.signupStore} nextRoute={as.coupons} />
            )}></Route>
          <Route
            path={as.coupons(`:accountId`)}
            exact
            render={(props: any) => (
              <CouponsStep {...props} signupStore={this.signupStore} nextRoute={as.review} />
            )}
          />
          <Route
            path={as.review(`:accountId`)}
            render={(props: any) => <ReviewStep {...props} signupStore={this.signupStore} />}
            exact
          />
        </Switch>

        </Box>
        
      </DashboardLayout>
    );
  }
}

export default AccountSignup;
