import React from 'react';
import Api, { getErrorMsg } from 'api';
import { observable, action, flow, computed, makeObservable } from 'mobx';
import { observer } from 'mobx-react';
import { WithStyles, withStyles } from '@material-ui/core/styles';

import { inject, WithUserStore, WithToastStore } from 'stores';
import { RouteComponentProps } from 'react-router-dom';

import FacebookPixel from 'react-facebook-pixel';
import ReactGA from 'react-ga';

import theme from 'containers/App/theme';
import styles from './styles';
import InquiryForm from './InquiryForm';
import { Box } from '@material-ui/core';
import LoadingSpinner from 'components/LoadingSpinner';

/** The variables that are matched in the URL (match.params) */
interface CampaignMatchParams {
  code: string;
}

/** Define props for this component */
type CampaignProps = WithStyles<typeof styles> &
  RouteComponentProps<CampaignMatchParams> &
  WithUserStore &
  WithToastStore;

/**
 * Display dynamically built campaign page.
 */
@inject('userStore', 'toastStore')
@observer
class Campaign extends React.Component<CampaignProps> {
  public constructor(props: CampaignProps) {
    super(props);
    makeObservable(this);
    this.matchParams = this.props.match.params;

    /** Initialize trackers for this page */
    this.configFacebookPixel();
    this.configGooglePixel();
  }

  /** Breakpoint for mobile display expressed in pixels */
  private breakpoint: number = theme.breakpoints.values.lg;

  /** We store the campaign code from the router into this observable */
  @observable public matchParams: CampaignMatchParams;

  @observable private campaign: any = {};

  @observable private loading = true;

  @observable private width: number = document.body.clientWidth;

  @action.bound private configFacebookPixel = () => {
    FacebookPixel.pageView();
  };

  @action.bound private configGooglePixel = () => {
    ReactGA.pageview(window.location.pathname);
  };

  @computed public get campaignCode(): string {
    return this.matchParams.code.toUpperCase();
  }

  @computed public get isMobile() {
    return this.width < this.breakpoint;
  }

  @action.bound public fetchCampaign = flow(function* (this: Campaign) {
    const code = this.matchParams.code;
    try {
      if (!code) throw new Error('Missing campaign code');
      const resp = yield Api.marketing.getCampaignByCode(code);
      this.campaign = resp.data.data;
    } catch (e: any) {
      this.props.toastStore!.error(getErrorMsg(e));
    } finally {
      this.loading = false;
    }
  });

  @action.bound public handleResize = () => {
    this.width = document.body.clientWidth;
  };

  componentDidMount() {
    window.addEventListener('resize', this.handleResize);
    this.fetchCampaign();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  render() {
    const { classes } = this.props;

    const { campaign } = this;
    const background = this.isMobile ? campaign.bgImageMobile : campaign.bgImage;

    const {
      id: campaignId,
      code: campaignCode,

      // Page Props
      title,
      description,
      fontColor,
      logo,

      // Button Props
      buttonLabel,
      buttonColor,
      buttonHoverColor,

      // Input Props
      inputUnderlineColor,
      inputUnderlineHoverColor,
    } = campaign;

    const backgroundStyles = {
      backgroundImage: `url(${background})`,
    };

    const pageProps = {
      title,
      description,
      fontColor,
      logo,
    };

    const buttonProps = {
      label: buttonLabel,
      color: buttonColor,
      hoverColor: buttonHoverColor,
    };

    const inputProps = {
      underlineColor: inputUnderlineColor,
      underlineHoverColor: inputUnderlineHoverColor,
    };

    if (this.loading) {
      return (
        <Box
          width="100%"
          height="100vh"
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center">
          <LoadingSpinner />
        </Box>
      );
    }

    return (
      <Box
        display="flex"
        flexDirection="column"
        className={classes.campaign}
        style={backgroundStyles}>
        <Box className={classes.logo}>{logo && <img alt={'logo'} src={pageProps.logo} />}</Box>
        <Box className={classes.textAndForm}>
          <Box className={classes.text}>
            <h1 style={{ color: fontColor }}>{pageProps.title}</h1>
            <p style={{ color: fontColor }}>{pageProps.description}</p>
          </Box>
          <Box className={classes.form}>
            <InquiryForm
              buttonProps={buttonProps}
              inputProps={inputProps}
              campaignId={campaignId}
              campaignCode={campaignCode}
            />
          </Box>
        </Box>
      </Box>
    );
  }
}

export default withStyles(styles)(Campaign);
