import React from 'react';
import { inject, observer } from 'mobx-react';
import { action, flow, makeObservable } from 'mobx';
import { WithToastStore, WithModalStore } from 'stores';
import Api, { getErrorMsg } from 'api';
import DP from 'components/DashPanel';

import { WithStyles, withStyles, IconButton } from '@material-ui/core';
import { Plus, Qrcode } from 'mdi-material-ui';
import styles from './styles';
import { DrawerType } from 'types';
import { Pool } from 'models';
import { humanize } from '../../utils/helper';
import { numericStringToUsd } from 'services/currency';
import { EmptyPanelMessage } from 'components/EmptyPanelMessage/EmptyPanelMessage';

/** Represents a single pool in the list */
const PoolListItem = observer(
  ({
    children,
    clickHandler,
    activationPoolHandler,
  }: {
    children: Pool;
    activationPoolHandler: (pool: Pool) => void;
    clickHandler: (type: DrawerType, poolId: number) => void;
  }) => {
    const pool = children;
    const secondaryText = `${humanize(pool.tippingMode)} 
    | ${numericStringToUsd(pool.lowAmount.toString())}
    | ${numericStringToUsd(pool.mediumAmount.toString())}
    | ${numericStringToUsd(pool.highAmount.toString())}
    `;
    const menu = [
      {
        label: pool.isActive ? 'Disable' : 'Enable',
        onClick: () => activationPoolHandler(pool),
      },
      {
        label: 'Edit',
        onClick: () => clickHandler(DrawerType.EDIT, pool.id),
      },
    ];
    const actions = (
      <IconButton
        disableFocusRipple
        disableRipple
        onClick={() => clickHandler(DrawerType.QR, pool.id)}>
        <Qrcode />
      </IconButton>
    );
    return (
      <DP.ListItem
        key={pool.id}
        primary={pool.name}
        secondary={secondaryText}
        indicator={{
          color: pool.isActive ? 'green' : 'red',
          tooltip: 'Active',
        }}
        menu={menu}
        rightContent={actions}
      />
    );
  },
);

interface PoolsPanelProps extends WithStyles<typeof styles>, WithToastStore, WithModalStore {
  pools?: Pool[];
  accountId?: number;
  openDrawer: (type: DrawerType, poolId?: number) => void;
  fetchPools: () => void;
}
@inject('toastStore', 'modalStore')
@observer
class PoolsPanel extends React.Component<PoolsPanelProps> {
  constructor(props: PoolsPanelProps) {
    super(props);
    makeObservable(this);
  }
  @action.bound public handleActivation = flow(function* (this: PoolsPanel, pool: Pool) {
    const actionText = `${pool.isActive ? 'Disable' : 'Enable'}`;
    const modalTitle = `${actionText} ${pool.name}`;
    const modalMessage = `Are you sure you want to ${actionText.toLowerCase()} ${pool.name}`;
    const confirmed = yield this.props.modalStore!.confirm(modalTitle, modalMessage, {
      confirmLabel: actionText,
    });
    const api = pool.isActive ? Api.core.deactivatePool : Api.core.activatePool;

    if (confirmed) {
      try {
        yield api(pool.id);
        this.props.toastStore!.success(`${pool.name} successfully ${actionText.toLowerCase()}d`);
        this.props.fetchPools();
      } catch (e: any) {
        this.props.toastStore!.error(getErrorMsg(e));
      }
    }
  });

  componentDidMount() {
    if (!this.props.pools) {
      this.props.fetchPools();
    }
  }

  renderPools() {
    return (
      <>
        {this.props.pools && this.props.pools.length > 0 ? (
          <DP.List height="short">
            {this.props.pools.map((pool) => (
              <PoolListItem
                key={pool.id}
                activationPoolHandler={this.handleActivation}
                clickHandler={this.props.openDrawer}>
                {pool}
              </PoolListItem>
            ))}
          </DP.List>
        ) : (
          <DP.Body>
            <EmptyPanelMessage panelTitle="Pools" />
          </DP.Body>
        )}
      </>
    );
  }

  render() {
    return (
      <DP>
        <DP.Header>
          <DP.Title panel>Pools</DP.Title>
          <DP.IconButton
            icon={Plus}
            primary
            tooltip="Add pool"
            onClick={() => this.props.openDrawer(DrawerType.ADD)}
          />
        </DP.Header>
        {this.props.pools && this.renderPools()}
      </DP>
    );
  }
}

export default withStyles(styles)(PoolsPanel);
