import { createMemoryHistory, History } from 'history';
import { observable, action, flow, makeObservable } from 'mobx';
import RootStore from 'stores/RootStore';
import { ToastStore } from 'stores';
import { Note, ReminderListItem, Topic } from 'models';
import Api, { getErrorMsg } from 'api';

/** Definition of possible paths for note drawer */
export type Path =
  | '/'
  | '/noteList'
  | '/noteDetails'
  | '/editNote'
  | '/addReminder'
  | '/reminderList';

/**
 * Note drawer specific store responsible for note drawer routing, managing ui state and
 * api communication. It implements history for accessibility and custom router.
 *
 * @param accountId Account reference
 */
export default class NoteStore {
  // @ts-ignore
  private rootStore: RootStore = window.rootStore;
  private toastStore: ToastStore;
  constructor(accountId: number) {
    this.accountId = accountId;
    this.toastStore = this.rootStore.toastStore;
    this.history = createMemoryHistory();
    this.destroyHistory = this.history.listen(this.listener);

    makeObservable(this);
  }

  /** Router history */
  @observable public history: History;

  /** Destroys history listener, called when note drawer unmounts */
  public destroyHistory: () => void;

  /** Drawer router current location */
  @observable public location: Path = '/';

  /** Account id */
  @observable private accountId: number;

  /** List of notes */
  @observable public notes?: Note[];

  /** Note object */
  @observable public note?: Note;

  /** Observable topic select value */
  @observable public topic?: Topic = this.note ? this.note.topic : undefined;

  /** Observable content input value */
  @observable public content?: string = this.note ? this.note.note : undefined;

  /** list of reminders */
  @observable public reminders: ReminderListItem[] = [];

  /** History listener */
  @action.bound public listener = ({ pathname }: any) => {
    this.location = pathname as Path;
  };
  /** Update topic select value */
  @action.bound public updateTopic = (event: React.ChangeEvent<{ value: unknown }>) => {
    this.topic = event.target.value as Topic;
  };

  /** Update content input value */
  @action.bound public updateContent = (event: React.ChangeEvent<{ value: unknown }>) => {
    this.content = event.target.value as string;
  };

  /** Clear all references to previously selected note if any */
  @action.bound public clearCurrentNote() {
    this.note = undefined;
    this.topic = undefined;
    this.content = undefined;
    this.reminders = [];
  }

  /** Set new path in history */
  @action.bound public routeTo = (path: Path) => {
    this.history.push(path);
  };

  /** Go back in history */
  @action.bound public goBack = () => {
    this.history.goBack();
  };

  /** Fetch all notes for current account */
  @action.bound public getNotes = flow(function* (this: NoteStore) {
    const resp = yield Api.core.getAccountNotes(this.accountId);
    this.notes = resp.data.data;
  });

  /** Fetch the note with corresponding id */
  @action.bound public getNote = flow(function* (this: NoteStore, noteId: number) {
    try {
      const resp = yield Api.core.getNoteDetails(noteId);
      this.note = resp.data.data;
      this.reminders = this.note!.reminders;
      this.topic = this.note!.topic;
      this.content = this.note!.note;
    } catch (e: any) {
      this.toastStore!.error(getErrorMsg(e));
    }
  });

  /** Save note by either creating a new note or patching an existing note */
  @action.bound public saveNote = flow(function* (
    this: NoteStore,
    note: Pick<Note, 'topic' | 'note'>,
  ) {
    try {
      // If note object is set it means we're updating an existing note ...
      if (this.note) {
        yield Api.core.updateNote(this.note.id, note);
      }
      // ... otherwise we're creating a new one
      else {
        // Create the new note ...
        const resp = yield Api.core.createNote(this.accountId, note);
        const noteId = resp.data.data.id;
        // ... and any reminders that might have been added to the note
        for (const reminder of this.reminders) {
          yield Api.core.createNoteReminder(noteId, reminder.dateTime);
        }
      }
      // Get the updated list of notes ...
      this.getNotes();
      // ... and redirect back to note list
      this.routeTo('/noteList');
    } catch (e: any) {
      this.toastStore!.error(getErrorMsg(e));
    }
  });

  /** Save a reminder */
  @action.bound public saveReminder = flow(function* (this: NoteStore, dateTime: string) {
    try {
      // If this is a reminder for an existing note, just send the reminder via api
      // POST and refetch the note so we get the updated reminders list for that note ...
      if (this.note) {
        yield Api.core.createNoteReminder(this.note.id, dateTime);
        this.getNote(this.note.id);
      }
      // ... or if this is a reminder for a new note, save the reminder to local state, because
      // note does not exist yet on the backend so we have to send it after we send the note.
      else {
        this.reminders = [...this.reminders, { id: Date.now(), dateTime }];
      }
      this.goBack();
    } catch (e: any) {
      this.toastStore!.error(getErrorMsg(e));
    }
  });

  /** Delete a reminder */
  @action.bound public deleteReminder = flow(function* (this: NoteStore, reminderId: number) {
    try {
      // If this is a reminder for an existing note, just request the reminder api DELETE
      // and refetch the note so we get the updated reminders list for that note ...
      if (this.note) {
        yield Api.core.deleteNoteReminder(reminderId);
        this.getNote(this.note.id);
      }
      // ... or if this is a reminder for a new note, delete it from local state
      else {
        this.reminders = this.reminders.filter(
          (reminder: ReminderListItem) => reminder.id !== reminderId,
        );
      }
    } catch (e: any) {
      this.toastStore!.error(getErrorMsg(e));
    }
  });

  /** Initialize the store by fetching all the relevant data */
  @action.bound public init() {
    return this.getNotes();
  }

  /** On cleanup destroy the routing history and remove the listener */
  @action.bound public clean() {
    this.destroyHistory();
  }
}
