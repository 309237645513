/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { observable, action, flow, makeObservable, computed } from 'mobx';
import validatorjs from 'validatorjs';
import { observer } from 'mobx-react';
import { WithStyles, withStyles } from '@material-ui/core/styles';
import { Box, Typography } from '@material-ui/core';

import { paths } from 'routes';
import { inject, WithToastStore, WithUserStore } from 'stores';
import { User } from 'models';
import Api, { getErrorMsg } from 'api';

import styles from './styles';
import CarouselScreenWrapper from 'components/CarouselScreenWrapper/CarouselScreenWrapper';
import Button from 'components/Button/Button';
import OutlinedInput from 'components/Input/OutlinedInput/OutlinedInput';
import SignupLayout, { TSignupLayoutAction } from 'containers/SignupLayout/SignupLayout';
import theme from 'containers/App/theme';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const dvr = require('mobx-react-form/lib/validators/DVR');
const MobxReactForm = require('mobx-react-form').default;

type PersonalInfoProps = WithStyles<typeof styles> &
  RouteComponentProps &
  WithToastStore &
  WithUserStore;

const plugins = {
  dvr: dvr({
    package: validatorjs,
    extend: ({ validator }: { validator: any; form: any }) => {
      validator.setMessages('en', {
        ...validator.getMessages('en'),
        firstName: 'Please input a valid first name',
        lastName: 'Please input a valid last name',
      });
    },
  }),
};

const formId = 'personal-info-form';

interface PersonalInfoHooks {
  onSuccess: (form: any) => void;
  onClear: (form: any) => void;
}

/**
 * Displays the prompt to enter first name, last name and nickname
 */
@inject('toastStore', 'userStore')
@observer
class PersonalInfo extends React.Component<PersonalInfoProps> {
  /** Whether the info is currently being submitted */
  @observable public submitting = false;

  /** The action to submit the current personal info to the server */
  @action.bound public submit = flow(function* (this: PersonalInfo) {
    const userStore = this.props.userStore!;
    try {
      this.submitting = true;
      const resp = yield Api.core.updateUser(userStore.user!.id, {
        firstName: this.form.$('firstName').value,
        lastName: this.form.$('lastName').value,
        nickname: this.form.$('nickname').value || undefined,
      });
      const user: User = resp && resp.data && resp.data.data;
      userStore.setUserData(user);
      const locationState: any = this.props.history.location.state;
      this.props.history.push({
        pathname: paths.signUp().phoneNumber(),
        state: {
          // Make the next step component aware of the manager
          // onboarding process so it can route accordingly:
          managerOnboarding: locationState.managerOnboarding,
        },
      });
    } catch (error: any) {
      this.props.toastStore!.push({
        type: 'error',
        message: getErrorMsg(error) || 'An error has occurred',
      });
    } finally {
      this.submitting = false;
    }
  });

  @computed public get signupLayoutProps() {
    const title = 'Personal Info';
    const subtitle = `Let's get to know each other!`;
    const action: TSignupLayoutAction = {
      loading: this.submitting,
      children: 'Continue',
      type: 'submit',
      form: formId,
    };

    return { title, subtitle, action };
  }

  public constructor(props: PersonalInfoProps) {
    super(props);
    makeObservable(this);
    const user = props.userStore!.user!;
    const fields = [
      {
        name: 'firstName',
        label: 'Legal First Name',
        rules: 'required|string|min:2',
        value: user.firstName || '',
      },
      {
        name: 'lastName',
        label: 'Legal Last Name',
        rules: 'required|string|min:2',
        value: user.lastName || '',
      },
      {
        name: 'nickname',
        label: 'Nickname (Optional)',
        rules: 'string',
        value: user.nickname || '',
      },
    ];
    this.form = new MobxReactForm({ fields }, { plugins, hooks: this.hooks });
  }

  /** The form object */
  private form: any;

  /** The hooks for the form */
  private hooks: PersonalInfoHooks = {
    onSuccess: () => {
      this.submit();
    },
    onClear: (form: any) => {
      form.clear();
    },
  };

  isRequired(name: string) {
    if (!this.form.$(name).rules) return false;

    return this.form.$(name).rules.includes('required');
  }

  render() {
    const userStore = this.props.userStore!;
    const fields = {
      firstName: this.form.$('firstName'),
      lastName: this.form.$('lastName'),
      nickname: this.form.$('nickname'),
    };
    const { classes } = this.props;
    return (
      <CarouselScreenWrapper submitting={this.submitting}>
        <SignupLayout {...this.signupLayoutProps}>
          <form id={formId} onSubmit={this.form.onSubmit}>
            <Box display={'flex'} flexDirection={'column'} gridGap={theme.spacing(2)}>
              <OutlinedInput
                {...fields.firstName.bind()}
                autoFocus
                error={fields.firstName.error}
                required={this.isRequired('firstName')}
                fullWidth
              />
              <OutlinedInput
                {...fields.lastName.bind()}
                error={fields.lastName.error}
                required={this.isRequired('lastName')}
                fullWidth
              />
              {!userStore.affiliate && (
                <OutlinedInput
                  {...fields.nickname.bind()}
                  error={fields.nickname.error}
                  required={this.isRequired('nickname')}
                  fullWidth
                />
              )}
            </Box>
          </form>
        </SignupLayout>
      </CarouselScreenWrapper>
    );
  }
}

export default withStyles(styles)(PersonalInfo);
