import { Theme, createStyles } from '@material-ui/core';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default (theme: Theme) =>
  createStyles({
    root: {},
    cursorPointer: {
      cursor: 'pointer',
    },
    paymentDrawer: {
      width: '419px !important',
      '&.MuiDrawer-paper': {
        width: 419,
      },
    },
  });
