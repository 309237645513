import React from 'react';
import { observable, action, computed, flow, makeObservable, reaction } from 'mobx';
import { observer } from 'mobx-react';
import { WithStyles, withStyles } from '@material-ui/core/styles';
import logo from 'images/pig_green_white.svg';
import { StripeProvider, Elements } from 'react-stripe-elements';
import {
  Typography,
  Box,
  Paper,
  CircularProgress,
  Container,
  Grid,
  Fade,
  Link,
  Dialog,
} from '@material-ui/core';
import { AxiosResponse } from 'axios';
import clsx from 'clsx';

import { inject, WithToastStore, WithUserStore } from 'stores';

import Api, { ApiResponse, getErrorMsg } from 'api';
import { RouteComponentProps } from 'react-router-dom';
import config from 'config';
import * as models from 'models';
import CartPreview from 'components/CartPreview';
import Overlay from 'components/Overlay';
import { createStripe } from 'services/stripe';
import { setTitle } from 'services/title';
import { paths } from 'routes';
import CheckoutForm from './CheckoutForm';
import NextSteps from './NextSteps';
import fail from './fail.svg';
import styles from './styles';
import moment from 'moment';
import LoginForm from 'containers/Login/LoginForm';
import qs from 'qs';
import ContactInfo from 'components/ContactInfo';

const ConfettiGenerator = require('confetti-js').default;

interface PaymentResponse {
  accountCode: string;
  confirmationNumber: string;
}

interface CheckoutProps
  extends WithStyles<typeof styles>,
    WithUserStore,
    WithToastStore,
    RouteComponentProps<{ cartId: string }> {
  hidePayment?: boolean;
}

/**
 * The Accounts container component. Only users with admin scope can access this view.
 * It uses AccountsTable component which gets Account[] for rendering list of accounts.
 */
@inject('userStore', 'toastStore')
@observer
class Checkout extends React.Component<CheckoutProps> {
  constructor(props: CheckoutProps) {
    super(props);
    makeObservable(this);

    // Check and set reset password data if exists
    const { resetPasswordToken, confirmEmailToken } = qs.parse(this.props.location.search, {
      ignoreQueryPrefix: true,
    });
    this.resetPasswordToken = resetPasswordToken as string;
    this.confirmEmailToken = confirmEmailToken as string;

    reaction(
      () => this.props.userStore?.loggedIn,
      () => {
        this.openLogin = false;
        this.getPaymentMethodsIfLoggedIn();
      },
    );
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public confetti: any;

  @observable private paymentMethods: models.PaymentMethod[] = [];

  /** Whether Stripe has been loaded */
  @observable public stripeLoaded = false;

  /** The cart that we fetched */
  @observable public cart?: models.Cart;

  /** Whether the form is submitting */
  @observable public submitting = false;

  /** The account code */
  @observable public accountCode?: string;

  /** The confirmation code */
  @observable public confirmationNumber?: string;

  /** The current error string */
  @observable public error?: string;

  @observable public inProgress = false;

  @observable private openLogin = false;

  @observable private isOwnerPayment = false;

  @observable private resetPasswordToken = '';
  @observable private confirmEmailToken = '';

  @action.bound public handleSetProgress = (status: boolean) => {
    this.inProgress = status;
  };

  handleOpenLogin = (status: boolean) => {
    this.openLogin = status;
  };

  /** Sets stripeLoaded to true */
  @action.bound public setStripeLoaded() {
    this.stripeLoaded = true;
  }

  @action.bound private getPaymentMethods = async (accountId: number) => {
    try {
      this.isOwnerPayment = true;
      const { data } = await Api.billing.getPaymentMethods(accountId);
      const paymentMethods = data?.data || [];
      const activePaymentMethods = paymentMethods?.filter(({ isActive }) => isActive);
      this.paymentMethods = activePaymentMethods;
    } catch (e: any) {
      this.props.toastStore!.error(getErrorMsg(e));
    } finally {
      this.isOwnerPayment = false;
    }
  };

  /** Fetches the cart from the API */
  @action.bound public getCart = async () => {
    const cartId = this.props.match.params.cartId;
    try {
      const resp: AxiosResponse<ApiResponse<models.Cart>> = await Api.billing.getCartByUuid(cartId);
      this.cart = resp.data.data;
    } catch (e: any) {
      this.props.toastStore!.error('Unable to get cart details');
      this.props.history.goBack();
    }
  };

  /** Completes the payment by calling the API with the payment source */
  @action.bound public completePayment = flow(function* (
    this: Checkout,
    sourceId: Record<string, string>,
  ) {
    try {
      this.submitting = true;
      const resp: AxiosResponse<ApiResponse<PaymentResponse>> = yield Api.billing.pay(
        this.cart!.accountId,
        sourceId,
        this.cart!.gross,
      );
      this.accountCode = resp.data.data && resp.data.data.accountCode;
      this.confirmationNumber = resp.data.data && resp.data.data.confirmationNumber;
      this.startConfetti();
    } catch (e: any) {
      this.error = getErrorMsg(e);
    } finally {
      this.submitting = false;
    }
  });

  /** Starts the confetti! 🎉 */
  @action.bound public startConfetti() {
    this.confetti = new ConfettiGenerator({ target: 'confetti-canvas', max: 80 });
    this.confetti.render();
  }

  /**
   * Fetches the related data of the user (accounts, etc.) and
   * goes to the dashboard
   */
  @action.bound public toDashboard() {
    // Get related data if logged in
    if (this.props.userStore!.loggedIn) {
      this.props.userStore!.getRelatedData();
    }
    if (this.resetPasswordToken && this.confirmEmailToken) {
      // If we have reset and confirm token we proceed to set password screen for owner
      this.props.history.push(
        paths.resetPasswordAndConfirmEmail(this.resetPasswordToken, this.confirmEmailToken),
      );
    } else {
      // Else proceed to dashboard root url
      this.props.history.push(paths.root());
    }
  }

  @action.bound public getPaymentMethodsIfLoggedIn() {
    if (this.props.userStore!.loggedIn && !this.props.hidePayment) {
      this.cart && this.getPaymentMethods(this.cart?.accountId);
    }
  }

  @computed public get loading() {
    return !this.cart || !this.stripeLoaded;
  }

  @computed public get expirationDatePassed() {
    if (!this.cart) {
      return false;
    }

    return moment(this.cart!.expiresAt).unix() < moment(Date.now()).unix();
  }

  @computed public get done() {
    return Boolean(this.confirmationNumber) && Boolean(this.accountCode);
  }
  /** Include the Stripe script when this component mounts */
  componentDidMount() {
    setTitle(`Checkout`);
    createStripe().then(this.setStripeLoaded);
    this.getCart().then(() => {
      this.getPaymentMethodsIfLoggedIn();
    });
  }
  componentWillUnmount() {
    this.confetti && this.confetti.clear();
  }
  renderCanvas() {
    return (
      <canvas
        id="confetti-canvas"
        style={{ position: 'absolute', top: 0, right: 0, left: 0, bottom: 0 }}></canvas>
    );
  }
  render() {
    const classes = this.props.classes;
    const { hidePayment } = this.props;
    if (this.loading) {
      return (
        <Box className={clsx(classes.root, classes.centerVertical, classes.blackText)}>
          {this.renderCanvas()}
          <CircularProgress color="primary" />
        </Box>
      );
    }

    return (
      <>
        <Dialog
          className={classes.noShadow}
          open={this.openLogin}
          onClose={() => this.handleOpenLogin(false)}>
          <Container className={classes.container}>
            <Box className={classes.insideBox}>
              {this.inProgress && (
                <Box className={classes.loader}>
                  <CircularProgress />
                </Box>
              )}
              <LoginForm {...this.props} onStatusSubmit={this.handleSetProgress} />
            </Box>
          </Container>
        </Dialog>
        <Box className={classes.root}>
          {this.renderCanvas()}
          <Box>
            <img src={logo} alt="Tippy" className={classes.pig} />
          </Box>
          {!this.loading && (
            <Container maxWidth={hidePayment && 'sm'}>
              <Fade in>
                <Paper elevation={0} className={classes.paper}>
                  <Overlay display={this.submitting || this.done}>
                    <Box pb={2}>
                      <CircularProgress />
                    </Box>
                    <Typography variant="h5" component="div">
                      Processing your order ...
                    </Typography>
                  </Overlay>
                  <Overlay display={this.done}>
                    <NextSteps
                      accountCode={this.accountCode!}
                      confirmationNumber={this.confirmationNumber!}
                      toDashboard={this.toDashboard}
                    />
                  </Overlay>
                  <Overlay display={Boolean(this.error)}>
                    <Box pb={2} className={classes.img}>
                      <img src={fail} alt="success!" />
                    </Box>
                    <Typography variant="h5" component="div" gutterBottom>
                      Uh oh!
                    </Typography>
                    <Typography component="div" gutterBottom>
                      {`It seems like something went wrong.`}
                    </Typography>
                    <Typography component="div" color="textSecondary" align="center">
                      {`Don't worry - your card hasn't been charged. Please contact`} <br />
                      {`our sales team for assistance with retrying your payment.`}
                    </Typography>
                  </Overlay>
                  {this.expirationDatePassed && (
                    <Box
                      p={6}
                      display="flex"
                      flexDirection="column"
                      justifyContent="center"
                      alignContent={'center'}
                      alignItems={'center'}>
                      <Box pb={2} className={classes.img}>
                        <img src={fail} alt="success!" height="200em" />
                      </Box>
                      <Box pb={2}>
                        <Typography variant="h5" component="h1" align="center">
                          Sorry,
                        </Typography>
                      </Box>
                      <Typography align="center">
                        this offer has expired. <br /> Please contact us on{' '}
                        <Link href="mailto:support@meettippy.com">support@meettippy.com</Link>
                      </Typography>
                    </Box>
                  )}
                  {!this.expirationDatePassed && (
                    <Box p={0}>
                      <Grid container spacing={6}>
                        {hidePayment && (
                          <Typography
                            style={{ fontSize: 28, width: '100%', marginBottom: 48 }}
                            align="center"
                            component="h1">
                            Checkout
                          </Typography>
                        )}
                        <Grid style={{ paddingTop: 84 }} item xs={12} md={hidePayment ? 12 : 6}>
                          <CartPreview cart={this.cart!} />
                        </Grid>

                        {!hidePayment && (
                          <Grid style={{ marginTop: '0px' }} item xs={12} md={6}>
                            <StripeProvider apiKey={config.stripe.publicKey}>
                              <Elements>
                                <CheckoutForm
                                  {...this.props}
                                  onPayment={this.completePayment}
                                  paymentMethods={this.paymentMethods}
                                  cart={this.cart}
                                  isOwner={this.isOwnerPayment}
                                  onOpenLogin={this.handleOpenLogin}
                                />
                              </Elements>
                            </StripeProvider>
                          </Grid>
                        )}
                      </Grid>
                    </Box>
                  )}
                </Paper>
              </Fade>
              <Box pt={3}>
                <Typography>
                  <ContactInfo />
                </Typography>
              </Box>
            </Container>
          )}
        </Box>
      </>
    );
  }
}

export default withStyles(styles)(Checkout);
