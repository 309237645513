import { useStyles } from './styles';
import SignupStore from './SignupStore';
import React from 'react';
import { Observer } from 'mobx-react';
import {
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Typography,
  CircularProgress,
  IconButton,
} from '@material-ui/core';
import { Close } from 'mdi-material-ui';
import { Checkbox } from 'components/Checkbox/Checkbox';
import theme from 'containers/App/theme';

/** Displays a list of promotions and allows the user to toggle them */
export default function CouponsList({ signupStore }: { signupStore: SignupStore }) {
  const { couponItem } = useStyles();
  return (
    <Observer>
      {() => {
        // If we're in a self signup process, we want to display a close icon
        // instead of a checkbox, because in this case removing a promo will
        // completely remove it from the list.
        const CheckElement = ({
          onClick,
          disabled,
          checked,
        }: {
          onClick: () => void;
          disabled?: boolean;
          checked?: boolean;
        }) =>
          signupStore.selfSignup ? (
            <IconButton onClick={onClick}>
              <Close />
            </IconButton>
          ) : (
            <Checkbox onClick={onClick} disabled={disabled} checked={checked} />
          );
        if (!signupStore.promotionsAnnotated) {
          return <div></div>;
        }
        if (signupStore.promotionsAnnotated.length === 0 && !signupStore.selfSignup) {
          return (
            <Typography color="textSecondary" style={{ marginBottom: theme.spacing(2) }}>
              There are currently no promotions to display
            </Typography>
          );
        }
        return (
          <List disablePadding>
            {signupStore.promotionsAnnotated.map((promotion, index) => (
              <ListItem
                data-cy={`list-item-${index}`}
                key={promotion.id}
                disabled={promotion.disabled}
                classes={{ root: couponItem }}>
                <ListItemText primary={promotion.code} secondary={promotion.description} />
                <ListItemSecondaryAction>
                  {signupStore.applyingPromotion !== promotion.id ? (
                    <CheckElement
                      onClick={() => signupStore.togglePromotion(promotion)}
                      disabled={promotion.disabled}
                      checked={promotion.active}
                    />
                  ) : (
                    <CircularProgress color="primary" />
                  )}
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
        );
      }}
    </Observer>
  );
}
