import { Theme, createStyles } from '@material-ui/core';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default (theme: Theme) =>
  createStyles({
    root: {},
    payoutAmount: {
      fontSize: '26px',
    },
    payoutTrxId: {
      fontSize: '14px',
    },
    payoutDate: {
      fontSize: '18px',
    },
    payoutStatus: {
      fontSize: '14px',
    },
    payoutError: {
      fontSize: '16px',
    },
    secondaryText: {
      display: 'flex',
      alignItems: 'center',
      color: theme.palette.text.secondary,
      fontStyle: 'italic',
      fontSize: '14px',
      width: '100%',
    },
    regularText: {
      fontSize: '14px',
      fontStyle: 'normal',
      display: 'flex',
      alignItems: 'center',
      color: theme.palette.text.secondary,
      padding: theme.spacing(0.75),
    },
    verticalLine: {
      borderLeft: '1px solid',
      height: '1em',
      color: theme.palette.text.secondary,
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(0.25),
    },
    faInfoCircle: {
      color: theme.palette.warning.main,
      width: '24px',
      height: '24px',
    },
    menuItemEnabled: {
      color: theme.palette.text.primary,
    },
  });
