import DashboardLayout from 'containers/DashboardLayout';
import Title from 'components/Title';
import { Box, Grid } from '@material-ui/core';
import { ReferralPanel, ReferralPanelProps } from './ReferralPanel/ReferralPanel';
import { faLink, faMedal, faUserPlus } from '@fortawesome/pro-regular-svg-icons';
import { useCallback, useEffect, useState } from 'react';
import { useStores } from 'containers/App/App';
import { BecomeAffiliate } from './BecomeAffiliate/BecomeAffiliate';
import { ShareReferral } from './ShareReferral/ShareReferral';
import Api, { ApiResponse, getErrorMsg } from 'api';
import config from '../../config';
import { referralsSkeletonStyles, referralsTitleContainerStyles } from './styles';
import { Skeleton } from '@mui/material';
import { AxiosResponse } from 'axios';
import { Affiliate, IReferralCreate } from 'models';
import { InviteFriend } from './InviteFriend/InviteFriend';
import { ReferralsTable } from './ReferralsTable/ReferralsTable';

interface IWithSkeleton {
  children: JSX.Element;
  loading: boolean;
}

const infoItems: ReferralPanelProps[] = [
  {
    lg: 4,
    icon: faLink,
    title: 'Refer a business',
    text: 'Either share your referral link or invite them by SMS or email.',
  },
  {
    lg: 4,
    icon: faUserPlus,
    title: 'That business signs up & activates Tippy',
    text: 'They will receive an intro offer to get started and are considered an active account once they process 60 tips in 30 days.',
  },
  {
    lg: 4,
    icon: faMedal,
    title: 'Earn rewards',
    text: "You'll receive $100 for each active business that signs up!",
  },
];

const createReferralLink = (code: string): string =>
  `${config.meetTippy.baseUrl}/referral-meet-tippy?code=${code}`;

const WithSkeleton = ({ children, loading }: IWithSkeleton): JSX.Element =>
  !loading ? (
    children
  ) : (
    <Skeleton width="100%" sx={referralsSkeletonStyles}>
      {children}
    </Skeleton>
  );

const Referrals = () => {
  const { modalStore, toastStore, userStore } = useStores();
  const { user, getRelatedData } = userStore;

  const [affiliate, setAffiliate] = useState<Affiliate>();
  const [isLoading, setIsLoading] = useState(false);

  const fetchReferralLinkApiCall = useCallback(
    async (fetch: (userid: number) => Promise<AxiosResponse<ApiResponse<Affiliate>>>) => {
      const userId = user?.id;
      if (!userId) {
        return;
      }

      setIsLoading(true);
      try {
        const { data } = await fetch(userId);
        const resp = data?.data;
        resp && Object.keys(resp)?.length !== 0 && setAffiliate(resp);
      } catch (error) {
        toastStore.error(getErrorMsg(error));
      } finally {
        setIsLoading(false);
      }
    },
    [toastStore, user?.id],
  );

  const submitReferralHandler = useCallback(
    async (request: IReferralCreate) => {
      setIsLoading(true);
      try {
        await Api.marketing.inviteReferral(request);
        toastStore.success(`Referral sent successfully to ${request.email ?? request.phone}`);
      } catch (error) {
        toastStore.error(getErrorMsg(error));
      } finally {
        setIsLoading(false);
      }
    },
    [toastStore],
  );

  // Get Affiliate on Init
  useEffect(() => {
    fetchReferralLinkApiCall(Api.marketing.getAffiliateForUser);
  }, [fetchReferralLinkApiCall]);

  // Generate code
  const handleUpdateAffiliate = useCallback(async () => {
    await fetchReferralLinkApiCall(Api.marketing.generateAffiliateCode);
    getRelatedData();
  }, [fetchReferralLinkApiCall, getRelatedData]);

  return (
    <DashboardLayout>
      <Box sx={referralsTitleContainerStyles}>
        <Title mb={0}>Referrals</Title>
        <Box className="referrals-status">Get paid $100 per sign up!</Box>
      </Box>

      <Grid container spacing={2} alignItems="stretch">
        {infoItems.map((it) => (
          <ReferralPanel {...it} key={it.title}>
            {it.children}
          </ReferralPanel>
        ))}

        {!affiliate ? (
          // There is no Affiliate
          <WithSkeleton loading={isLoading}>
            <BecomeAffiliate modalStore={modalStore} updateAffiliate={handleUpdateAffiliate} />
          </WithSkeleton>
        ) : (
          //  There is Affiliate
          <>
            <ShareReferral url={createReferralLink(affiliate.code)} />
            <InviteFriend submitReferral={submitReferralHandler} />
          </>
        )}
      </Grid>

      {/* <ReferralsList /> */}
      {affiliate && <ReferralsTable affiliateId={affiliate.id} />}
    </DashboardLayout>
  );
};

export default Referrals;
