/** This file contains utility functions for stripe.js */

/**
 * Creates the stripe script and includes it in the
 * document head. Returns a promise that resolves once
 * the stripe.js script is loaded.
 * */
export function createStripe(): Promise<void> {
  return new Promise((resolve) => {
    const script = document.createElement('script');
    script.async = true;
    script.src = 'https://js.stripe.com/v3/';
    script.addEventListener('load', () => resolve());
    document.head.appendChild(script);
  });
}
