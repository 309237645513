/* eslint-disable @typescript-eslint/no-unused-vars */
import { AxiosResponse } from 'axios';
import config from 'config';
import { Device, Hardware, Inventory, InventoryStatusReq } from 'models';
import { PagedApiResponse, RequestMetaData } from './interface';
import { devices } from './mocks';
import { createApi, makeQS } from './utils';

interface CreateShipmentRequest {
  fulfillmentId: number;
  locationId: number;
  trackingNumber: string;
  inventoryIds: number[];
}

const api = {
  v1: createApi({ baseURL: `${config.api.fulfillment}/v1` }),
};

export function getInventoryItems(rmd: RequestMetaData) {
  return api.v1.get(`/inventory?${makeQS(rmd)}`);
}

export async function updateInventoryStatus(item: InventoryStatusReq) {
  return api.v1.patch(`/inventory/${item.id}`, { status: item.status });
}

export function createPingRequest(id: number) {
  return api.v1.post(`/inventory/${id}/ping`);
}

/** Currently mocked */
export async function addInventoryItems(items: Partial<Inventory>[]) {
  return api.v1.post(`/inventory`, items);
}

export function getKioskItems(rmd: RequestMetaData) {
  return Promise.resolve(devices as unknown as AxiosResponse<PagedApiResponse<Device>>);
}

export function getInventoryBySerial(serial: string) {
  return api.v1.get(`/inventory/by-serial/${serial}`);
}

export function getShipments(qsObj?: Record<string, unknown>) {
  return api.v1.get(`/shipments?${makeQS({}, qsObj)}`);
}

export function getShipment(shipmentId: number) {
  return api.v1.get(`/shipments/${shipmentId}`);
}

/** Currently mocked */
export function searchHardware(s: string) {
  return api.v1.get(`/hardware?search=${s}`);
}

export function createShipment(r: CreateShipmentRequest) {
  return api.v1.post(`/shipments`, r);
}

export function getInventoryForShipment(shipmentId: number) {
  return api.v1.get(`/shipments/${shipmentId}/inventory`);
}

export function getShipmentProgress(shipmentId: number) {
  return api.v1.get(`/shipments/${shipmentId}/progress`);
}

/** Get Hardware list */
export function getHardwareList(rmd: RequestMetaData) {
  return api.v1.get(`/hardware?${makeQS(rmd)}`);
}

/** Get Hardware item */
export function getHardware(hardwareId: number) {
  return api.v1.get(`/hardware/${hardwareId}`);
}

/** Add Hardware item */
export function addHardware(hardwareData: Partial<Hardware>) {
  return api.v1.post(`/hardware`, hardwareData);
}

/** Update Hardware item */
export function updateHardware(id: number, hardwareData: Partial<Hardware>) {
  return api.v1.patch(`/hardware/${id}`, hardwareData);
}

/** Get Hardware shipment list */
export function getHardwareShipments(rmd: RequestMetaData) {
  return api.v1.get(`/inventory/shipments?${makeQS(rmd)}`);
}

/** Update Hardware quantities */
export async function updateHardwareQuantities(id: number, quantity: number) {
  return api.v1.patch(`/hardware/${id}/update-quantities`, { quantity: quantity });
}

/** Get Shipments status stats*/
export async function getShipmentsStatusStats(qsObj?: Record<string, unknown>) {
  return api.v1.get(`/stats/inventory-shipments?${makeQS({}, qsObj)}`);
}

/** Get inventory costs stats */
export async function getInventoryCostStats(qsObj?: Record<string, unknown>) {
  return api.v1.get(`/stats/inventory-costs?${makeQS({}, qsObj)}`);
}


