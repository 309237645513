import React from 'react';
import { Grid } from '@material-ui/core';
import { HorizontalStatCard, Stat, VerticalStatCard } from '../Stats';
import {
  AccountBalanceOutlined,
  AttachMoney,
  CreditCardOutlined,
  LowPriorityOutlined,
} from '@mui/icons-material';
import { Coins } from 'mdi-material-ui';
import { faUpFromLine, faDownFromLine } from '@fortawesome/pro-regular-svg-icons';

export interface StatsProps {
  children?: {
    balance: number;
    tips: Stat;
    payouts: Stat;
    splitIn: Stat;
    inTransfer: Stat;
    splitOut: Stat;
    outTransfer: Stat;
    cashTips: Stat;
    refunds: Stat;
  };
}

export function Stats(props: StatsProps) {
  const { children } = props;
  const tips = children && children.tips;
  const splitIn = children && children.splitIn;
  const inTransfer = children && children.inTransfer;
  const splitOut = children && children.splitOut;
  const outTransfer = children && children.outTransfer;
  const cashTips = children && children.cashTips;
  const refunds = children && children.refunds;
  const payouts = children && children.payouts;

  return (
    <Grid container spacing={3}>
      <Grid item md={3} sm={12}>
        <VerticalStatCard
          icon={AttachMoney}
          color="yellow"
          title={'Balance'}
          prefix="$"
          decimals={2}>
          {children?.balance}
        </VerticalStatCard>
      </Grid>
      <Grid item md={9} sm={12}>
        <Grid container spacing={3}>
          <Grid item md={4} sm={12}>
            <HorizontalStatCard
              displaySmall
              icon={CreditCardOutlined}
              title={`${tips?.count} tips`}
              prefix={'$'}
              decimals={2}>
              {tips?.amount}
            </HorizontalStatCard>
          </Grid>
          <Grid item md={4} sm={12}>
            <HorizontalStatCard
              displaySmall
              fontAwesomeIcon={faDownFromLine}
              color={'yellow'}
              title={`${inTransfer?.count} transfer in`}
              prefix={'$'}
              decimals={2}>
              {inTransfer?.amount}
            </HorizontalStatCard>
          </Grid>
          <Grid item md={4} sm={12}>
            <HorizontalStatCard
              displaySmall
              fontAwesomeIcon={faUpFromLine}
              color="purple"
              title={`${outTransfer?.count} transfer out`}
              prefix={'$'}
              decimals={2}>
              {outTransfer?.amount}
            </HorizontalStatCard>
          </Grid>
          <Grid item md={4} sm={12}>
            <HorizontalStatCard
              displaySmall
              icon={AccountBalanceOutlined}
              title={`${payouts?.count} payouts`}
              prefix={'$'}
              decimals={2}>
              {payouts?.amount}
            </HorizontalStatCard>
          </Grid>
          <Grid item md={4} sm={12}>
            <HorizontalStatCard
              displaySmall
              icon={Coins}
              color={'yellow'}
              title={`${cashTips?.count} cash tips`}
              prefix={'$'}
              decimals={2}>
              {cashTips?.amount}
            </HorizontalStatCard>
          </Grid>
          <Grid item md={4} sm={12}>
            <HorizontalStatCard
              displaySmall
              icon={LowPriorityOutlined}
              color={'purple'}
              title={`${refunds?.count} refunds`}
              prefix={'$'}
              decimals={2}>
              {refunds?.amount}
            </HorizontalStatCard>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
