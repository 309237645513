import Api, { getErrorMsg } from 'api';
import { rootStore } from 'containers/App/App';
import { isPhone } from 'services/validators';
import validatorjs from 'validatorjs';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const dvr = require('mobx-react-form/lib/validators/DVR');
const MobxReactForm = require('mobx-react-form').default;

const plugins = {
  dvr: dvr({
    package: validatorjs,
    extend: ({ validator }: { validator: any; form: any }) => {
      const phoneFieldRule = {
        function: (value: any) => isPhone(value),
        message: 'The phone number is not a valid format.',
      };
      validator.register('phone', phoneFieldRule.function, phoneFieldRule.message);
      validator.setMessages('en', {
        ...validator.getMessages('en'),
        phone: 'Please input a valid phone',
      });
    },
  }),
};

interface IPhoneNumberHooks {
  onSuccess: (form: any) => void;
  onClear: (form: any) => void;
}
/** The hooks for the form */
const hooks: IPhoneNumberHooks = {
  onSuccess: async (form: any) => {
    try {
      const userId = rootStore.userStore!.user!.id;
      const phone = form.$('phone').value;
      await Api.core.updateUserPhone(userId, phone);
    } catch (error: any) {
      if (error.response?.status === 901) return;
      rootStore.toastStore.error(getErrorMsg(error));
    }
  },
  onClear: (form: any) => {
    form.clear();
  },
};

export const createNewForm = (phoneRef: React.MutableRefObject<string | null>) => {
  const { user } = rootStore.userStore;
  const fields = [
    {
      name: 'phone',
      label: 'Phone Number',
      type: 'tel',
      rules: ['string', 'phone', 'required'],
      value: user?.phone || phoneRef.current || '',
      hooks: {
        onChange: (field: any) => {
          field.validate({ showErrors: true });
          phoneRef.current = field.value;
        },
        onBlur: (field: any) => {
          field.validate({ showErrors: true });
        },
      },
    },
  ];
  return new MobxReactForm({ fields }, { plugins, hooks: hooks });
};
