import { Drawer, DrawerProps, Link } from '@mui/material';
import DD from 'components/DashDrawer';
import SkeletonOrComponent from 'components/SkeletonOrComponent';
import { formatDateTimeInEST, getFullName, humanize } from 'utils/helper';
import useStyles from './styles';
import * as models from 'models';
import { Typography } from '@material-ui/core';
import { paths } from 'routes';
import { Link as RouterLink } from 'react-router-dom';

interface ITipDetailsDrawerProps extends DrawerProps {
  tip?: models.Tip;
  error?: boolean;
  closeDrawer: VoidFunction;
}

const TipDetailsDrawer = ({ open, anchor, tip, error, closeDrawer }: ITipDetailsDrawerProps) => {
  const { skeleton } = useStyles();

  const labels = ['createdAt', 'net', 'source', 'status', 'user'];

  let values: Array<{ label: string; value: string; path?: string }> = Object.entries(
    tip || {},
  )?.map(([key, value]) => {
    if (key === 'createdAt') return { label: key, value: formatDateTimeInEST(value) };

    if (['gross', 'net', 'serviceAmount'].includes(key)) return { label: key, value: `$${value}` };

    if (key === 'user' && tip?.user) {
      const { firstName, lastName, id } = tip.user;
      return {
        label: key,
        value: getFullName(firstName, lastName),
        path: paths.userDetails(id).info(),
      };
    }

    return { label: key, value };
  });
  values = values?.filter(({ label }) => labels.includes(label));

  const formatLabel = (label: string) => {
    return humanize(label.split(/(?=[A-Z])/).join(' '));
  };

  return (
    <Drawer open={open} anchor={anchor} onClose={closeDrawer}>
      <DD>
        <DD.Title showDivider padding onClose={closeDrawer}>
          Tip Details
        </DD.Title>
        <DD.Content>
          {error ? (
            <Typography variant="body2">Seems like there was an error loading data</Typography>
          ) : (
            labels.map((label, index) => {
              const _values = [values[index * 2], values[index * 2 + 1]];
              return (
                <DD.Row key={index}>
                  {_values.map((value) => {
                    if (!value) return null;
                    return (
                      <DD.Item key={index}>
                        <DD.Value>
                          <SkeletonOrComponent className={skeleton} rangeWidth={[80, 120]}>
                            {value.path ? (
                              <Link
                                sx={{ textDecoration: 'none' }}
                                component={RouterLink}
                                to={value.path}>
                                {value.value}
                              </Link>
                            ) : (
                              value.value
                            )}
                          </SkeletonOrComponent>
                        </DD.Value>
                        <DD.Label>{formatLabel(value.label)}</DD.Label>
                      </DD.Item>
                    );
                  })}
                </DD.Row>
              );
            })
          )}
        </DD.Content>
      </DD>
    </Drawer>
  );
};

export default TipDetailsDrawer;
