import React, { useEffect, useState } from 'react';
import { getErrorMsg } from 'api/utils';
import { useStores } from 'containers/App/App';
import { Account } from 'models';
import { IntegrationApp, IntegrationStatus } from 'models/Integration';
import Api from 'api';
import { ACL } from 'types';

type UseToggleIntegrationButton = [boolean, string, boolean, () => void];

export default function useToggleIntegrationButton(
  app: IntegrationApp,
  account: Account,
): UseToggleIntegrationButton {
  const { toastStore, userStore, modalStore } = useStores();
  const [integrationActive, setIntegrationActive] = useState(false);
  const [displayButton, setDisplayButton] = useState(false);
  const [buttonText, setButtonText] = useState('');

  useEffect(() => initializeIntegrationButton(), []);
  useEffect(() => getButtonText(), [integrationActive]);

  function getButtonText() {
    if (!userStore.isAdmin && integrationActive === true) {
      return setButtonText('');
    }

    if (integrationActive) {
      setButtonText('Active integration');
    } else {
      setButtonText('Paused integration');
    }
  }

  function initializeIntegrationButton() {
    if (!userStore.isAdmin || !userStore.hasPermission(ACL.MANAGE_INTEGRATIONS)) {
      setDisplayButton(false);
    } else {
      setDisplayButton(true);
    }

    const { status } = app;
    if (status === IntegrationStatus.ACTIVE) {
      setIntegrationActive(true);
    } else if (status === IntegrationStatus.PAUSED) {
      setIntegrationActive(false);
    } else {
      setDisplayButton(false);
    }
  }

  async function activateOrPauseIntegration() {
    const confirm = await confirmManualInitiation();
    if (!confirm || !app.integrationId) return;
    try {
      if (integrationActive) {
        await Api.developer.pauseIntegration(app.integrationId);
        setIntegrationActive(!integrationActive);
      } else {
        await Api.developer.resumeIntegration(app.integrationId);
      }
      setIntegrationActive(!integrationActive);
    } catch (error) {
      toastStore.error(getErrorMsg(error));
    }
  }

  async function confirmManualInitiation() {
    const dialogTitle = integrationActive ? 'Pause integration' : 'Resume integration';
    const dialogBody = (
      <>
        Are you sure you want to {integrationActive ? 'pause' : 'resume'} for account {account.name}
        ?
      </>
    );
    return await modalStore!.confirm(dialogTitle, dialogBody);
  }

  return [integrationActive, buttonText, displayButton, activateOrPauseIntegration];
}
