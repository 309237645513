import { useStores } from 'containers/App/App';
import { getErrorMsg } from 'api';
import { useEffect, useState } from 'react';
import { BlUsers, ISelectedLocationUser } from '../../containers/LocationDetails/QrCodes/blUsers';
import { AddStation, IGroupItem, IStationItem, IStationUser, StationItem } from 'models/Station';
import { BlAddStation } from '../AddStation/blAddStation';
import { useStationNavigation } from 'containers/LocationDetails/QrCodes/useStationNavigation';

type UseAddStationUsersReturn = [
  ISelectedLocationUser[] | undefined,
  (user: ISelectedLocationUser) => void,
  () => void,
  () => void,
];

export function useAddStationUsers(): UseAddStationUsersReturn {
  const { toastStore, stationsStore } = useStores();
  const { stationNavigation } = useStationNavigation();
  const { locationId, updateFormFieldAndValidate, setStateField } = stationsStore!;
  const [selectedUsers, setSelectedUsers] = useState(stationsStore.selectedUsers);

  useEffect(() => {
    setSelectedUsers(stationsStore.selectedUsers);
  }, [stationsStore.selectedUsers]);

  useEffect(() => {
    init();
  }, []);

  async function init() {
    await getLocationUsers();
    await getSelectedUsers();
  }

  function toggleSelectedUser(user: ISelectedLocationUser) {
    if (!selectedUsers) return;
    let _selectedUsers = selectedUsers.map(
      (selectedUser: ISelectedLocationUser): ISelectedLocationUser => {
        if (user.id === selectedUser.id) {
          return { ...user, selected: !user.selected };
        } else {
          return selectedUser;
        }
      },
    );
    setSelectedUsers(_selectedUsers);
  }

  function getSelectedUsers() {
    let items = stationsStore.station!.items;
    if (stationsStore.addStationState === AddStation.GROUP_USERS) {
      items = stationsStore.selectedGroup!.groupItems;
    }

    const selectedUsers = BlUsers.getSelectedLocationUsers(stationsStore.locationUsers!, items);
    setSelectedUsers(selectedUsers);
  }

  async function getLocationUsers() {
    if (!locationId) return;
    try {
      const locationUsers = await BlUsers.getAllLocationUsers(locationId);
      stationsStore.setLocationUsers(locationUsers);
    } catch (e: any) {
      toastStore.error(getErrorMsg(e));
    }
  }

  function addSelectedUsers() {
    const users = BlAddStation.mapItemsForAddingToState(selectedUsers!) as IStationUser[];
    if (stationsStore.addStationState === AddStation.GROUP_USERS) {
      addToGroup(users);
    } else {
      addToStation(users);
    }
    handleAddUsers();
  }

  function addToStation(users: IStationUser[]) {
    let stationItems = [] as IStationItem[];
    if (stationsStore.station!.items && stationsStore.station!.items.length) {
      stationsStore.station!.items!.forEach((item: IStationItem) => {
        if (item.type !== StationItem.USER) stationItems.push(item);
      });
    }
    stationItems = [...stationItems, ...users];
    setStateField('items', stationItems);
  }

  function addToGroup(users: IStationUser[]) {
    let groupItems = [] as IGroupItem[];
    stationsStore.selectedGroup!.groupItems!.forEach((item: IGroupItem) => {
      if (item.type !== StationItem.USER) groupItems.push(item);
    });
    groupItems = [...groupItems, ...users];
    setStateField('groupItems', groupItems);
  }

  function handleAddUsers() {
    resetSelectedUsers();
    if (stationsStore.addStationState === AddStation.GROUP_USERS) {
      addGroup();
    } else {
      stationNavigation.openStation();
    }
  }

  function addGroup() {
    stationNavigation.openGroup();
  }

  function resetSelectedUsers() {
    stationsStore.selectedUsers = undefined;
  }

  function goBack() {
    if (stationsStore.addStationState === AddStation.GROUP_USERS) {
      updateFormFieldAndValidate('groupItems');
      stationNavigation.openGroup();
    } else {
      updateFormFieldAndValidate('items');
      stationNavigation.openStation();
    }
    stationsStore.selectedUsers = undefined;
  }

  return [selectedUsers, toggleSelectedUser, addSelectedUsers, goBack];
}
