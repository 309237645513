import React from 'react';
import { RouteComponentProps, Link as RouterLink } from 'react-router-dom';
import { observable, action, makeObservable } from 'mobx';
import { observer } from 'mobx-react';
import { WithStyles, withStyles } from '@material-ui/core/styles';
import { Link, Box, Typography, Drawer } from '@material-ui/core';
import { AxiosResponse } from 'axios';

import { paths } from 'routes';
import { RequestMetaData, PagedApiResponse, getErrorMsg } from 'api';
import { inject, WithToastStore } from 'stores';
import moment from 'moment-timezone';
import { IntegrationApp, Payment } from 'models';

import { adaptForDataGridPro } from 'services/datagrid';

import CreditCardIcon from 'components/CreditCardIcon';
import styles from './styles';
import PaymentDetailsDrawer from 'components/PaymentDetailsDrawer';

import DataGridInfiniteScroll from 'components/DataGridInfiniteScroll';
import Api from 'api';
import ChipStatusTag, { ChipStatusColors } from 'components/ChipStatusTag';

interface Colors {
  [key: string]: string;
}

const colors: Colors = {
  CAPTURED: ChipStatusColors.GREEN,
  DUPLICATE: ChipStatusColors.GREY,
  REFUNDED: ChipStatusColors.ORANGE,
  VERIFYING: ChipStatusColors.YELLOW,
  FAILED: ChipStatusColors.RED,
  CANCELLED: ChipStatusColors.PURPLE,
  VOID: ChipStatusColors.GREY,
  NOT_FOUND: ChipStatusColors.RED,
  CREATED: ChipStatusColors.BLUE,
};

/** Define props for this component */
interface PaymentsProps extends WithStyles<typeof styles>, WithToastStore, RouteComponentProps {
  fetch: (rmd: RequestMetaData) => Promise<AxiosResponse<PagedApiResponse<Payment>>>;
  refetch?: () => void;
  refetchKey?: Record<string, unknown>;
}

/**
 * Displays a datagrid table that lists payments.
 */
@inject('toastStore')
@observer
class PaymentsTable extends React.Component<PaymentsProps> {
  constructor(props: PaymentsProps) {
    super(props);
    makeObservable(this);
  }

  @observable public paymentRef?: string;

  @observable private sources?: Map<string, string>;

  @action.bound private openPaymentDetailsDrawer = (paymentRef: string) => {
    this.paymentRef = paymentRef;
  };

  @action.bound private closePaymentDetailsDrawer = () => {
    this.paymentRef = undefined;
  };

  @action.bound private async getPaymentSources() {
    let data;
    try {
      data = await Promise.all([Api.tips.getProcessors(), Api.developer.getAllApps()]);
      this.mapAppsAndProcessors(data);
    } catch (e: any) {
      this.props.toastStore!.error(getErrorMsg(e));
    }
  }

  @action.bound mapAppsAndProcessors(data: any) {
    if (!data || !data.length) return;

    let sourceMap = new Map();
    const processors = data[0].data.data;
    if (processors && processors.length) {
      processors.forEach((processor: string) => {
        sourceMap.set(processor, this.processorName(processor));
      });
    }

    const apps = data[1].data.data as IntegrationApp[];
    if (apps && apps.length) {
      apps.forEach((app: IntegrationApp) => {
        sourceMap.set(`developer_app_${app.id}`, app.name);
      });
    }
    this.sources = sourceMap;
  }

  @action.bound onCloseAction() {
    this.props.refetch && this.props.refetch();
  }

  processorName(processor: string) {
    let processorName = processor.toLowerCase().replace(/-/g, ' ').split(' ');
    return processorName.map((word: string) => this.capitalizeFirstLetter(word)).join(' ');
  }

  capitalizeFirstLetter(value: string) {
    let word = value.toLowerCase();
    return `${word.slice(0, 1).toUpperCase()}${word.slice(1, word.length)}`;
  }

  /** Annotates payment with extra data */
  annotate = (payment: Payment) => {
    const location = payment.location;
    const locationName = location && location.name;
    const lastFour = payment.customerCard ? payment.customerCard.lastFour : '';
    const chargeAmount = parseFloat(payment.chargeAmount || '0').toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD',
    });
    const source = this.sources ? this.sources.get(payment.processor) : 'N/A';
    return {
      ...payment,
      status: payment.status && payment.status.toUpperCase(),
      chargeAmount,
      location: locationName,
      lastFour,
      source,
    };
  };

  renderCellReference = ({ value, row }: any) => {
    const { classes } = this.props;
    const paymentRef = value;
    // display part before '-'
    let modifiedValue;
    if (paymentRef.includes('-')) {
      modifiedValue = paymentRef.split('-')[0];
    }
    return (
      <Link
        className={classes.cursorPointer}
        onClick={() => this.openPaymentDetailsDrawer(paymentRef)}>
        {modifiedValue ? modifiedValue : row.reference}
      </Link>
    );
  };

  renderCellCard = ({ row }: any) => {
    return (
      <>
        {row.customerCard ? (
          <Box display="flex" flexDirection="row" alignItems="center">
            <CreditCardIcon style={{ marginTop: 3 }} brand={row.customerCard.brand} />
            <Box ml={1}>
              <Typography>{row.lastFour}</Typography>
            </Box>
          </Box>
        ) : (
          <Typography>N/A</Typography>
        )}
      </>
    );
  };

  renderCellLocation = ({ row }: any) => {
    return (
      <Link component={RouterLink} to={paths.locationDetails(row.locationId)}>
        {row.location}
      </Link>
    );
  };

  renderStatus = ({ value, row }: any) => {
    const color = colors[value.toUpperCase() as keyof Colors] as ChipStatusColors;
    return <ChipStatusTag label={value} color={color} />;
  };

  gridColumns = [
    {
      headerName: 'Reference #',
      field: 'reference',
      minWidth: 210,
      flex: 1,
      renderCell: this.renderCellReference,
    },
    { headerName: 'Amount', field: 'chargeAmount', minWidth: 150, flex: 1 },
    {
      headerName: 'Card',
      field: 'lastFour',
      minWidth: 150,
      flex: 1,
      renderCell: this.renderCellCard,
    },
    {
      headerName: 'Location',
      field: 'location',
      minWidth: 150,
      flex: 1,
      renderCell: this.renderCellLocation,
    },
    {
      headerName: 'Date',
      field: 'createdAt',
      minWidth: 150,
      flex: 1,
      valueGetter: ({ value }: any) => value && moment(new Date(value)).format('MMM DD, YYYY'),
    },
    {
      headerName: 'Source',
      field: 'source',
      minWidth: 150,
      flex: 1,
    },
    {
      headerName: 'Transaction id',
      field: 'transactionId',
      minWidth: 150,
      flex: 1,
    },
    {
      headerName: 'Status',
      field: 'status',
      minWidth: 150,
      flex: 1,
      renderCell: this.renderStatus,
    },
  ];

  public fetchPayments = adaptForDataGridPro(this.props.fetch, this.annotate);

  componentDidMount() {
    this.getPaymentSources();
  }

  render() {
    return (
      <>
        <Box>
          <DataGridInfiniteScroll
            columns={this.gridColumns}
            fetch={this.fetchPayments}
            refetchKey={this.props.refetchKey || {}}
            sortDirection="DESC"
            disableColumnMenu
            pathname={this.props.location.pathname}
          />
        </Box>
        <Drawer
          open={Boolean(this.paymentRef)}
          onClose={this.closePaymentDetailsDrawer}
          anchor="right"
          variant="temporary">
          <PaymentDetailsDrawer
            onClose={this.closePaymentDetailsDrawer}
            onCloseAction={this.props.refetch}
            paymentReference={this.paymentRef}
          />
        </Drawer>
      </>
    );
  }
}

export default withStyles(styles)(PaymentsTable);
