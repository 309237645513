import {
  Box,
  Button,
  DialogContent,
  DialogTitle,
  Divider,
  Drawer,
  IconButton,
  Typography,
} from '@material-ui/core';
import { Close, ContentCopy, Printer } from 'mdi-material-ui';
import { useStationQrCode } from './useStationQrCode';
import React, { useCallback, useState } from 'react';
import QRCode from 'qrcode.react';
import { useStyles } from './styles';
import config from 'config';
import { IStation } from 'models/Station';

interface StationQrCodeProps {
  station: IStation;
  isOpen: boolean;
}

export const StationQrCode = ({ station, isOpen }: StationQrCodeProps) => {
  const [open, setOpen] = useState<boolean>(isOpen);
  const [close, printPageLink, handlePrint, handleCopy] = useStationQrCode(station);
  const classes = useStyles();

  const closeDrawer = useCallback(() => {
    setOpen(false);
    close();
  }, [close]);

  return (
    <Drawer anchor="right" open={open}>
      <DialogTitle>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Typography style={{ fontSize: '28px' }} component="h1" display="inline">
            Station QR Code
          </Typography>
          <IconButton onClick={() => closeDrawer()}>
            <Close color="inherit" />
          </IconButton>
        </Box>
      </DialogTitle>
      <Divider />
      <DialogContent className={classes.dialogWrapper}>
        <Box mt={3}>
          <Box mt={3} display="flex" justifyContent="center">
            <QRCode
              style={{ height: '208px', width: '208px' }}
              value={`${config.tippyGo.baseUrl}/P${station!.uid!}`}
            />
          </Box>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            mt={4}
            style={{ gap: '24px' }}>
            <Button
              variant="contained"
              startIcon={<ContentCopy />}
              onClick={handleCopy}
              color="primary"
              className={classes.button}>
              Copy
            </Button>
            <Button
              variant="contained"
              startIcon={<Printer />}
              onClick={handlePrint}
              color="primary"
              className={classes.button}>
              Print
            </Button>
          </Box>
          <Box mt={4} display="flex" flexDirection="column">
            <Typography variant="body2" className={classes.text}>
              {station.name}
            </Typography>
            <Typography variant="subtitle1">Station name</Typography>
          </Box>
          <Box mt={4} display="flex" flexDirection="column">
            <Typography variant="body2" className={classes.text}>
              {station.uid!}
            </Typography>
            <Typography variant="subtitle1">Station code</Typography>
          </Box>
        </Box>
      </DialogContent>
    </Drawer>
  );
};
