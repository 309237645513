import React from 'react';
import { observable, action, toJS, runInAction, makeObservable } from 'mobx';
import { observer } from 'mobx-react';
import { WithStyles, withStyles } from '@material-ui/core/styles';
import {
  Box,
  Radio,
  RadioGroup,
  Divider,
  FormControl,
  FormGroup,
  FormControlLabel,
  Checkbox,
  DialogTitle,
  IconButton,
} from '@material-ui/core';
import { Account, ELocationUserStatus, Location, LocationUser } from 'models';
import DP from 'components/DashPanel';

import DD from 'components/DashDrawer';
import AccountSearch from 'components/AccountSearch';
import { WithUserStore, inject } from 'stores';

import styles from './styles';
import LocationSearch from 'components/LocationSearch';
import Title from 'components/Title/Title';
import TDrawer from 'components/Title/Dialog';

import Close from 'mdi-material-ui/Close';

export interface UserFilters {
  roles: {
    owners: boolean;
    managers: boolean;
    talent: boolean;
    basic: boolean;
  };
  isActive: boolean;
  account?: Account;
  location?: Location;
  locationUser?: LocationUser;
  locationUserStatus?: ELocationUserStatus;
}

interface UserFilterDrawerProps extends WithStyles<typeof styles>, WithUserStore {
  filters: UserFilters;
  onChange: (uf: UserFilters) => void;
  onClose: () => void;
  onReset: () => void;
  showAccountSearch?: boolean;
  hideOwnersCheckbox?: boolean;
  showInvitationStatuses?: boolean; // TODO: Always show the once we migrate admin user search to v2
  showReset: boolean;
  showUserProfileActivity: boolean;
}

/**
 * Displays a filter drawer for filtering user search results
 */
@inject('userStore')
@observer
class UserFilterDrawer extends React.Component<UserFilterDrawerProps> {
  constructor(props: UserFilterDrawerProps) {
    super(props);
    makeObservable(this);
  }
  /** The user filters */
  @observable filters: UserFilters = this.props.filters;

  /** Calls props.onChange with the current filters */
  @action.bound public updateFilters() {
    this.props.onChange(toJS(this.filters));
  }

  /** Handler for the role checkboxes */
  handleRoleChange =
    (name: keyof UserFilters['roles']) => (event: React.ChangeEvent<HTMLInputElement>) =>
      runInAction(() => {
        this.filters.roles[name] = event.target.checked;
      });

  /** Handler for the status change button */
  @action.bound public handleIsActiveChange(
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean,
  ) {
    this.filters.isActive = checked;
  }

  /** Handler for the status change radio button for LocationUsers search */
  @action.bound public handleLocationUserChange(event: React.ChangeEvent<HTMLInputElement>) {
    this.filters.locationUserStatus = event.target.value as UserFilters['locationUserStatus'];
  }

  @action.bound public updateAccount(account: Account | null) {
    this.filters.account = account ? account : undefined;
    this.filters.location = undefined;
  }

  @action.bound public updateLocation(location: Location | null) {
    this.filters.location = location ? location : undefined;
  }

  @action.bound public updateLocationUsers(locationUser: LocationUser | null) {
    this.filters.locationUser = locationUser ? locationUser : undefined;
  }

  render() {
    const isAdmin = this.props.userStore!.authUser.isAdmin;
    const { onClose, onReset, showReset, showAccountSearch, showUserProfileActivity } = this.props;

    return (
      <>
        <DialogTitle style={{ paddingBottom: 16 }}>
          <Box display="flex" alignItems="center" justifyContent="space-between">
            <TDrawer>Filters</TDrawer>
            <IconButton onClick={onClose}>
              <Close color="inherit" />
            </IconButton>
          </Box>
        </DialogTitle>
        <Divider />
        <DD>
          <DD.Content>
            <Box>
              <Box mb={2}>
                <Title size="small" drawer mb={2}>
                  Role
                </Title>
                <Box mb={2}>
                  <FormControl component="fieldset">
                    <FormGroup>
                      {!this.props.hideOwnersCheckbox && (
                        <FormControlLabel
                          control={
                            <Checkbox
                              color="primary"
                              checked={this.filters.roles.owners}
                              onChange={this.handleRoleChange('owners')}
                              value="owners"
                            />
                          }
                          label="Owners"
                        />
                      )}
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="primary"
                            checked={this.filters.roles.managers}
                            onChange={this.handleRoleChange('managers')}
                            value="managers"
                          />
                        }
                        label="Managers"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="primary"
                            checked={this.filters.roles.talent}
                            onChange={this.handleRoleChange('talent')}
                            value="talent"
                          />
                        }
                        label="Employee"
                      />
                      {isAdmin && (
                        <FormControlLabel
                          control={
                            <Checkbox
                              color="primary"
                              checked={this.filters.roles.basic}
                              onChange={this.handleRoleChange('basic')}
                              value="basic"
                            />
                          }
                          label="Basic"
                        />
                      )}
                    </FormGroup>
                  </FormControl>
                </Box>
                <Divider />

                {showUserProfileActivity && (
                  <Box mt={2} mb={2}>
                    <Title size="small" drawer mb={2}>
                      User Profile Activity
                    </Title>
                    <FormControlLabel
                      control={
                        <Box ml={1.2} mr={1.2}>
                          <DP.Switch
                            checked={this.filters.isActive}
                            onChange={this.handleIsActiveChange}
                          />
                        </Box>
                      }
                      label="Active"
                      style={{ paddingBlockEnd: '8px' }}
                      labelPlacement="end"
                    />
                  </Box>
                )}
                <Divider />
                <Box mt={2} mb={2}>
                  <Title size="small" drawer mb={2}>
                    User Location Activity
                  </Title>
                  <FormControl component="fieldset">
                    <RadioGroup
                      name="locationUserStatus"
                      value={this.filters.locationUserStatus}
                      onChange={this.handleLocationUserChange}>
                      <FormControlLabel
                        value="all"
                        control={<Radio color="primary" />}
                        label="All"
                      />
                      <FormControlLabel
                        value={ELocationUserStatus.ACTIVE}
                        control={<Radio color="primary" />}
                        label="Active"
                      />
                      <FormControlLabel
                        value={ELocationUserStatus.INACTIVE}
                        control={<Radio color="primary" />}
                        label="Inactive"
                      />
                      {this.props.showInvitationStatuses && (
                        <>
                          <FormControlLabel
                            value="pending_approval"
                            control={<Radio color="primary" />}
                            label="Pending Approval"
                          />
                          <FormControlLabel
                            value="rejected"
                            control={<Radio color="primary" />}
                            label="Rejected"
                          />
                        </>
                      )}
                    </RadioGroup>
                  </FormControl>
                </Box>
                <Divider />
                {showAccountSearch && (
                  <Box mt={2} mb={2}>
                    <Title size="small" drawer mb={3}>
                      Account
                    </Title>
                    <AccountSearch value={this.filters.account} onChange={this.updateAccount} />
                  </Box>
                )}
                {this.filters.account && (
                  <Box mt={2} mb={2}>
                    <Title size="small" drawer mb={3}>
                      Location
                    </Title>
                    <LocationSearch
                      accountId={this.filters.account.id}
                      label="Enter name"
                      value={this.filters.location}
                      onChange={this.updateLocation}
                    />
                  </Box>
                )}
              </Box>
            </Box>
          </DD.Content>
          <DD.Actions drawer>
            {showReset && <DD.ResetButton onClick={onReset}>Reset filters</DD.ResetButton>}
            <DD.ApplyButton onClick={this.updateFilters}>Apply</DD.ApplyButton>
          </DD.Actions>
        </DD>
      </>
    );
  }
}

export default withStyles(styles)(UserFilterDrawer);
