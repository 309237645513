import React from 'react';
import { observable, action, computed, reaction, makeObservable } from 'mobx';
import { observer } from 'mobx-react';
import { WithStyles, withStyles } from '@material-ui/core/styles';
import { Paper, IconButton, ListItem, Badge } from '@material-ui/core';
import { Tune } from 'mdi-material-ui';
import debounce from 'lodash/debounce';

import styles from './styles';
import OutlinedInput, { OutlinedInputVariant } from 'components/Input/OutlinedInput/OutlinedInput';
import Input from 'components/Input/Input/index';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass } from '@fortawesome/pro-regular-svg-icons';
import clsx from 'clsx';

interface SearchBoxProps extends WithStyles<typeof styles> {
  onChange: (s: string) => void;
  initialValue?: string;
  debounce?: number;
  placeholder?: string;
  withPaper?: boolean;
  autoFocus?: boolean;
  toggleFilters?: () => void;
  filtersActive?: boolean;
  disableGutters?: boolean;
  className?: string;
  outlinedInput?: boolean;
  variant?: OutlinedInputVariant;
}

/**
 * A box for inputting search terms.
 */
@observer
class SearchBox extends React.Component<SearchBoxProps> {
  constructor(props: SearchBoxProps) {
    super(props);
    makeObservable(this);
    this.r = reaction(
      () => this.search,
      (searchTerm) => {
        this.onChangeDebounced(searchTerm);
        if (searchTerm === '') {
          this.onChangeDebounced.flush();
        }
      },
    );
  }
  static defaultProps = {
    withPaper: true,
  };
  /** The search term */
  @observable public search = this.props.initialValue || '';
  /** Whether the search is empty */
  @computed public get searchEmpty() {
    return this.search === '';
  }
  /** The event handler for updating the search term */
  @action.bound public updateSearch(e: React.ChangeEvent<HTMLInputElement>) {
    this.search = e.target.value;
  }
  /** Clears the current search */
  @action.bound public clear() {
    this.search = '';
  }
  /** The debounced version of this.props.onChange */
  onChangeDebounced = debounce(this.props.onChange, this.props.debounce || 0);
  /** Whenever the search term is changed, call onChange or the debuonced onChange */
  r;
  /** Clear the reactions */
  componentWillUnmount() {
    this.r();
  }

  renderComponent() {
    const {
      classes,
      className,
      placeholder,
      toggleFilters,
      filtersActive,
      autoFocus,
      outlinedInput,
      variant,
    } = this.props;
    return (
      <>
        {outlinedInput ? (
          <OutlinedInput
            variant={variant || 'small'}
            id="search-form"
            autoFocus={autoFocus}
            onChange={this.updateSearch}
            fullWidth
            value={this.search}
            placeholder={placeholder}
            InputProps={{
              disableUnderline: true,
              startAdornment: <FontAwesomeIcon className={classes.icon} icon={faMagnifyingGlass} />,
              endAdornment: toggleFilters && (
                <IconButton className={classes.endAdornment} onClick={toggleFilters}>
                  <Badge variant="dot" color="primary" invisible={!filtersActive}>
                    <Tune color="inherit" fontSize="small" />
                  </Badge>
                </IconButton>
              ),
            }}
          />
        ) : (
          <Input
            variant="small"
            id="search-form"
            autoFocus={autoFocus}
            onChange={this.updateSearch}
            fullWidth
            value={this.search}
            placeholder={placeholder}
            InputProps={{
              disableUnderline: true,
              startAdornment: <FontAwesomeIcon className={classes.icon} icon={faMagnifyingGlass} />,
              endAdornment: toggleFilters && (
                <IconButton className={classes.endAdornment} onClick={toggleFilters}>
                  <Badge variant="dot" color="primary" invisible={!filtersActive}>
                    <Tune color="inherit" fontSize="small" />
                  </Badge>
                </IconButton>
              ),
            }}
          />
        )}
      </>
    );
  }

  render() {
    const { classes, className, disableGutters } = this.props;
    return this.props.withPaper ? (
      <Paper className={clsx(classes.root, className)}>{this.renderComponent()}</Paper>
    ) : (
      <ListItem className={className} disableGutters={disableGutters}>
        {this.renderComponent()}
      </ListItem>
    );
  }
}

export default withStyles(styles)(SearchBox);
