import React from 'react';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { Link as RouterLink, Redirect, Route, RouteComponentProps } from 'react-router-dom';
import { action, computed, flow, observable, makeObservable } from 'mobx';
import { observer } from 'mobx-react';
import { WithStyles, withStyles } from '@material-ui/core/styles';
import { Box, Drawer } from '@material-ui/core';

import Api from 'api';
import { inject, UserScopes, WithModalStore, WithToastStore, WithUserStore } from 'stores';
import { paths } from 'routes';
import { Account } from 'models';

import DashboardLayout from 'containers/DashboardLayout';

import EditableTitle from 'components/EditableTitle/EditableTitle/EditableTitle';

import TipSettingsDrawer from 'components/TipSettingsDrawer';
import TabBar from 'components/TabBar/TabBar';
import NoteDrawer from 'components/NoteDrawer';

import styles from './styles';
import Info from './Info';
import Billing from './Billing';
import IncorporationData from './IncorporationData';
import Transactions from './Transactions';
import ActionsMenu, { PositionMenu } from 'components/ActionsMenu';
import { isValueInArray } from 'utils/helper';
import AccountLogo from 'components/AccountLogo/AccountLogo';

/** The variables that are matched in the URL (match.params) */
interface AccountDetailsMatchParams {
  accountId: string;
}

/** Here we define what kind of props this component takes */
type AccountDetailsProps = WithStyles<typeof styles> & // Adds the classes prop
  RouteComponentProps<AccountDetailsMatchParams> & // Adds the router props (history, match, location)
  WithModalStore & // Adds modalStore prop
  WithToastStore & //Adds toastStore prop
  WithUserStore; // Adds the userStore prop

/**
 * Container for account details (Info, Billing and Devices tabs).
 */
@inject('modalStore', 'userStore', 'toastStore')
@observer
class AccountDetails extends React.Component<AccountDetailsProps> {
  public constructor(props: AccountDetailsProps) {
    super(props);
    makeObservable(this);
    // Make the params from the URL match observable. We do that so that
    // we can reference the accountId from compted values. We do this in
    // the constructor so that it's available before the first render.
    this.matchParams = this.props.match.params;
  }

  /** We store the account id from the router into this observable */
  @observable public matchParams: AccountDetailsMatchParams;

  /** The current account */
  @observable public account?: Account;

  /** Is settings drawer open */
  @observable public settingsOpen = false;

  /** Is settings drawer open */
  @observable public notesOpen = false;

  /** Is current user an admin */
  @computed public get isAdmin(): boolean {
    return this.props.userStore!.isAdmin;
  }

  /** Is current user owner */
  @computed public get isOwner(): boolean {
    return isValueInArray(
      [UserScopes.OWNER, UserScopes.GLOBAL_OWNER],
      this.props.userStore!.scope.kind,
    );
  }

  /** The account id as captured by the URL params */
  @computed public get accountId(): number {
    return parseInt(this.matchParams.accountId);
  }

  /** Whether the user can view the current account */
  @computed public get accountValid(): boolean {
    const userStore = this.props.userStore!;
    // If the current user is an admin, it's OK
    if (userStore.authUser.isAdmin) {
      return true;
    } else {
      // If the current user isn't an admin, the accountId must match the current scope.
      return userStore.scope.kind === 'owner' && userStore.scope.accountId === this.accountId;
    }
  }

  @action.bound private openSettingsModal() {
    this.settingsOpen = true;
  }

  @action.bound private closeSettingsModal() {
    this.settingsOpen = false;
  }

  // TODO rok try with one line arrow function
  @action.bound private openNotesModal() {
    this.notesOpen = true;
  }

  @action.bound private closeNotesModal() {
    this.notesOpen = false;
  }

  @action.bound public getAccount = flow(function* (this: AccountDetails) {
    const resp = yield Api.core.getAccount(this.accountId);
    this.account = resp.data.data;
  });

  /** Change account name */
  @action.bound public changeAccountName = async (newTitle: string) => {
    return await Api.core.updateAccount(this.accountId, { name: newTitle });
  };

  @action.bound public onSuccessfulNameChange = (response: any) => {
    const account = response?.data?.data;
    if (response) {
      this.account = account;
      this.props.toastStore?.success('Account name updated successfully');
    }
  };

  @computed public get showSettingsButton() {
    return this.props.userStore!.isAdmin;
  }

  @computed public get showNotesButton() {
    return this.props.userStore!.isAdmin;
  }

  @computed public get tabs() {
    const accountDetailsPath = paths.accountDetails(this.accountId);
    const { pathname } = this.props.location;
    return [
      {
        label: 'General',
        component: RouterLink,
        to: accountDetailsPath.info(),
        selected: pathname === accountDetailsPath.info(),
      },
      {
        label: 'Billing',
        component: RouterLink,
        to: accountDetailsPath.billing(),
        selected:
          pathname === accountDetailsPath.billing() ||
          pathname === accountDetailsPath.transactions(),
      },
    ];
  }

  componentDidMount() {
    // Fetch the account
    this.getAccount();
  }

  renderActions = ({ row }: any) => {
    let options = [
      {
        label: 'Settings',
        action: () => this.openSettingsModal(),
      },
      {
        label: 'Tips',
        action: () =>
          this.isAdmin
            ? this.props.history.push(`/tips/${this.accountId}`)
            : this.props.history.push(`/tips/`),
      },
      {
        label: 'Notes',
        action: () => this.openNotesModal(),
      },
    ];

    options = options.filter((option) => (option.label === 'Notes' && this.isOwner ? false : true));
    this.isAdmin &&
      options.push(
        {
          label: 'Payments',
          action: () => this.props.history.push(`/payments/${this.accountId}`),
        },
        {
          label: 'Activity',
          action: () =>
            this.props.history.push(
              `/analytics/activity/accounts/tips?accountId=${this.accountId}`,
            ),
        },
      );

    return <ActionsMenu options={options} position={PositionMenu.VERTICAL} />;
  };

  render() {
    // const userStore = this.props.userStore!;
    // This is the account id that's matched in the URL.
    // If the account id doesn't match the current scope and the user
    // isn't an admin, redirect to an error screen or something.
    if (!this.accountValid) {
      // TODO: Redirect to something other than root
      return <Redirect to={paths.root()} />;
    }

    const accountDetailsPath = paths.accountDetails(this.accountId);

    return (
      <DashboardLayout>
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          mb={3}
          justifyContent={'space-between'}>
          <Box display="flex" alignItems={'center'} flexDirection="row" gridGap={2}>
            {this.account && (
              <>
                <AccountLogo
                  logoEditable={true}
                  account={this.account}
                  refreshAccount={this.getAccount}
                />
                <EditableTitle
                  editable={true}
                  submit={this.changeAccountName}
                  onSuccess={this.onSuccessfulNameChange}
                  title={this.account.name}
                />
              </>
            )}
          </Box>
          <Box>{this.renderActions}</Box>
        </Box>
        <TabBar mb={3} tabs={this.tabs} />
        <Route
          path={accountDetailsPath.info()}
          exact
          render={(props: any) => (
            <Info
              {...props}
              refetchAccount={this.getAccount}
              account={this.account}
              accountId={this.accountId}
            />
          )}
        />
        <Route
          path={accountDetailsPath.billing()}
          exact
          render={(props: any) => (
            <Billing {...props} account={this.account} accountId={this.accountId} />
          )}
        />
        <Route
          path={accountDetailsPath.incorporationData()}
          exact
          render={(props: any) => (
            <IncorporationData {...props} account={this.account} accountId={this.accountId} />
          )}
        />
        <Route
          path={accountDetailsPath.transactions()}
          exact
          render={(props: any) => (
            <Transactions {...props} account={this.account} accountId={this.accountId} />
          )}
        />
        {this.account && (
          <TipSettingsDrawer
            entity="account"
            id={this.account.id}
            account={this.account}
            isOpen={this.settingsOpen}
            onClose={this.closeSettingsModal}
          />
        )}
        {this.isAdmin && this.account && (
          <Drawer
            open={this.notesOpen}
            onClose={this.closeNotesModal}
            anchor="right"
            variant="temporary">
            <NoteDrawer accountId={this.account.id} onClose={this.closeNotesModal} />
          </Drawer>
        )}
      </DashboardLayout>
    );
  }
}

export default withStyles(styles)(AccountDetails);
