import { Theme } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/styles';

interface IStyleProps {
  noWrap?: boolean;
}

const useStyles = makeStyles<Theme, IStyleProps>((theme) =>
  createStyles({
    fontWeight: { fontWeight: 700 },
    dynamicStyles: {
      overflow: 'scroll',
      color: theme.palette.text.primary,
      whiteSpace: ({ noWrap }) => (noWrap ? 'normal' : 'nowrap'),
      '& span': {
        color: theme.palette.text.primary,
        overflow: 'scroll',
        whiteSpace: ({ noWrap }) => (noWrap ? 'normal' : 'nowrap'),
        '-ms-overflow-style': 'none', // IE and Edge
        'scrollbar-width': 'none', // Firefox
        '&::-webkit-scrollbar': {
          display: 'none',
        },
      },
      '-ms-overflow-style': 'none', // IE and Edge
      'scrollbar-width': 'none', // Firefox
      '&::-webkit-scrollbar': {
        display: 'none',
      },
    },
  }),
);

export default useStyles;
