import React, { useEffect } from 'react';
import { rootStore } from 'containers/App/App';
import { observer } from 'mobx-react';

const IntercomWidgetInitializer = () => {
  const user = rootStore.userStore.user;

  useEffect(() => {
    if (!user) return;
    window.Intercom('boot', {
      app_id: window.INTERCOM_APP_ID,
      name: rootStore.userStore.fullName,
      email: user.email,
      phone: user.phone,
      user_id: user.id.toString(),
      created_at: Date.now() / 1000,
      custom_launcher_selector: '#intercom_chat_widget',
      hide_default_launcher: true,
      avatar: {
        type: 'avatar',
        image_url: user.avatar || '',
      },
    });

    return () => {
      window.Intercom('shutdown');
    };
  }, [user]);

  return null;
};

export default observer(IntercomWidgetInitializer);
