import React from 'react';
import { action, computed, flow, makeObservable, observable } from 'mobx';
import { observer } from 'mobx-react';
import { WithStyles, withStyles } from '@material-ui/core/styles';

import styles from './styles';
import {
  inject,
  WithModalStore,
  WithPayoutSourceStore,
  WithToastStore,
  WithUserStore,
} from 'stores';

import { BankAccount, LocationUser } from 'models';
import { AlertCircleOutline } from 'mdi-material-ui';
import { Backdrop, Box, Chip, Typography } from '@material-ui/core';
import AnonLayout from '../../components/AnonLayout/AnonLayout';
import { VerificationStatus } from '../../types';
import Api, { ApiResponse, getErrorMsg } from '../../api';
import { AxiosResponse } from 'axios';
import TippyPlaidLink from '../../components/PlaidLink';
import { WithRouterStore } from '../../stores/RouterStore';
import { PlaidLinkOnSuccessMetadata } from 'react-plaid-link';
import { BankAccountStatus } from 'services/banks';
import Button from 'components/Button/Button';
import { OverlayLoader } from 'components/Loader/OverlayLoader/OverlayLoader';
import OutlinedInput from 'components/Input/OutlinedInput';

export interface BankAccountWizzardProps
  extends WithStyles<typeof styles>,
    WithModalStore,
    WithToastStore,
    WithUserStore,
    WithPayoutSourceStore,
    WithRouterStore {
  open: boolean;
  accountId?: number;
  hideIcon?: boolean;
  onClose: () => void;
  onAdd?: (success: boolean, bankAccount: BankAccount) => void;
  onMultipleLocations?: (bankAccount: BankAccount) => void;
}

interface AccountPrograms {
  ach: boolean;
  instant: boolean;
}

type PromptState = 'initial' | VerificationStatus;

export interface PlaidAuthResponse {
  accountId: number;
  entityName?: string;
  ein?: string;
  authorization: {
    _links: PlaidAuthLinks;
    bodyText: string;
    buttonText: any;
  };
}

export interface PlaidAuthLinks {
  self: { href: string; type: string; 'resource-type': string };
}

@inject('modalStore', 'toastStore', 'userStore', 'payoutSourceStore', 'routerStore')
@observer
class BankAccountWizzard extends React.Component<BankAccountWizzardProps> {
  constructor(props: BankAccountWizzardProps) {
    super(props);
    makeObservable(this);
  }
  @observable private open = false;
  @observable public creatingBankAccount = false;

  @observable public errorMsg?: string;
  @observable public promptState: PromptState = 'initial';

  /** Array of user's bank accounts || undefined if still loading || null if no accounts */
  @observable public bankAccounts?: null | BankAccount[];

  /**
   * Can user enroll for NetSpend? If user has no cards and is
   * location user on an account that has realtime payments enabled
   */
  @observable public canEnrollForNetspend?: boolean;

  @observable public primaryCardId?: number;
  @observable public primaryBankAccountId?: number;

  @observable public loadingCards = true;
  @observable public loadingPrograms = true;
  @observable public loadingEntityName = true;

  @observable private entityTextFieldValue = '';
  @observable private einTextFieldValue?: string = undefined;

  @observable private locations: any[] = [];

  @observable
  private entityResponse?: PlaidAuthResponse = undefined;

  /** Wait for all api calls and only then set loading to false */
  // @computed get loading() {
  //   return this.loadingAccounts || this.loadingPrograms;
  // }

  @computed get entityName() {
    if (this.entityResponse) {
      return this.entityResponse!.entityName;
    }

    return undefined;
  }

  @computed get einNumber() {
    if (this.entityResponse) {
      return this.entityResponse!.ein;
    }

    return undefined;
  }

  /** Prompt state for linking bank account */
  @action.bound setPromptState(verificationStatus?: string) {
    if (
      verificationStatus === 'verified' ||
      verificationStatus === 'pending_manual_verification' ||
      verificationStatus === 'pending_automatic_verification'
    ) {
      this.promptState = verificationStatus as PromptState;
    } else {
      throw new Error('An unknown error has occurred');
    }
  }

  /** Prompt state for linking bank account */
  @action.bound toggleLinkBankAccountModal(open: boolean) {
    this.open = open;

    if (open) {
      this.promptState = 'initial';
      this.fetchAuthorizationData();
    } else {
      this.props.onClose();
    }
  }

  @action.bound closePlaid(errorMsg?: string) {
    if (errorMsg) {
      this.errorMsg = errorMsg;
    }
    this.toggleLinkBankAccountModal(false);
  }

  @action.bound public fetchCanEnrollForNetspend = flow(function* (this: BankAccountWizzard) {
    const locationUsers: LocationUser[] = this.props.userStore!.locationUsers;
    try {
      // If user is on at least one location ...
      if (locationUsers && locationUsers.length > 0) {
        // ... iterate over those locations and fetch account programs for each one
        let programsForAccounts: (AccountPrograms | undefined)[] = yield Promise.all(
          locationUsers.map(
            (user) => user.location && this.fetchAccountPrograms(user.location.accountId),
          ),
        );
        // If at least one has realtime payments enabled allow the user to enrol for the NetSpend
        this.canEnrollForNetspend =
          programsForAccounts.filter((programs?: AccountPrograms) => {
            return programs && programs.instant;
          }).length > 0;
      }
    } catch (e) {
      this.props.toastStore!.push({ type: 'error', message: getErrorMsg(e) });
    } finally {
      this.loadingPrograms = false;
    }
  });

  @action.bound public fetchPrimaryWallet = flow(function* (this: BankAccountWizzard) {
    try {
      const user = this.props.userStore!.user!;
      const resp = yield Api.tips.getPrimaryWallet(user.id);
      const primaryWallet = resp.data.data;
      if (primaryWallet.debitCardId) {
        this.primaryCardId = primaryWallet.debitCardId;
      } else {
        this.primaryCardId = undefined;
      }
      if (primaryWallet.bankAccountId) {
        this.primaryBankAccountId = primaryWallet.bankAccountId;
      } else {
        this.primaryBankAccountId = undefined;
      }
    } catch (e) {
      this.props.toastStore!.push({ type: 'error', message: getErrorMsg(e) });
    }
  });

  @action.bound public fetchAuthorizationData = flow(function* (this: BankAccountWizzard) {
    try {
      this.loadingEntityName = true;
      const accountId = this.props.accountId!;
      const resp = yield Api.core.getBankAccountAuthorization(accountId);

      this.entityResponse = resp.data.data;

      if (this.entityResponse) {
        window.localStorage.setItem(
          'plaid_links',
          JSON.stringify(this.entityResponse.authorization._links),
        );
        if (this.props) {
          window.localStorage.setItem(
            'plaid_businessAccountId',
            JSON.stringify(this.props.accountId),
          );
        }
      }
    } catch (e) {
      this.props.toastStore!.push({ type: 'error', message: getErrorMsg(e) });
    } finally {
      this.loadingEntityName = false;
    }
  });

  @action.bound public attachSingleLocationToBank = flow(function* (
    this: BankAccountWizzard,
    bankAccountId: number,
    locationId: number,
  ) {
    try {
      const accountId = this.props.accountId!;
      yield Api.core.updateAccountBankLocations(accountId, bankAccountId, [locationId]);
    } catch (e) {
      this.props.toastStore!.push({ type: 'error', message: getErrorMsg(e) });
      // eslint-disable-next-line no-empty
    } finally {
    }
  });

  /**
   * Get account programs but return the result since we need to call this function
   * dynamically when checking if any of users accounts supports realtime payments
   */
  @action.bound public fetchAccountPrograms = flow(function* (
    this: BankAccountWizzard,
    accountId: number,
  ) {
    const resp: AxiosResponse<ApiResponse<AccountPrograms>> =
      yield Api.core.getAccountPayoutMethods(accountId);
    return resp.data.data;
  });

  /** Create bank account and set this bank account's verification status */
  @action.bound createBankAccount = flow(function* (
    this: BankAccountWizzard,
    publicToken: string,
    metadata: PlaidLinkOnSuccessMetadata,
  ) {
    let success = false;
    let resp;
    try {
      this.creatingBankAccount = true;

      const _links = JSON.parse(window.localStorage.getItem('plaid_links') || '') as PlaidAuthLinks;
      const businessAccountId = parseInt(
        JSON.parse(window.localStorage.getItem('plaid_businessAccountId') || ''),
      );

      window.localStorage.removeItem('plaid_links');
      window.localStorage.removeItem('plaid_businessAccountId');

      // Create a bank account
      resp = yield this.props.payoutSourceStore!.attachV3BankAccount(
        publicToken,
        metadata,
        _links,
        businessAccountId,
      );
      if (!resp) {
        throw new Error('An unknown error has occurred');
      }
      if (resp.error) {
        this.errorMsg = resp.error.message;
      }

      if (resp.id) {
        const verificationStatus = resp.verificationStatus;
        verificationStatus && this.setPromptState(verificationStatus);
        this.toggleLinkBankAccountModal(false);
      }
      success = true;
    } catch (e: any) {
      this.errorMsg = e.message;
    } finally {
      this.creatingBankAccount = false;
      if (this.props.onAdd) {
        this.props.onAdd(success, resp as BankAccount);
      }
    }
  });

  @action.bound submitEntityName = async () => {
    const name = this.entityTextFieldValue;
    const ein = this.einTextFieldValue;
    await Api.core.updateAccountIncorporationData(this.props.accountId!, {
      name,
      ein,
    });
    await this.fetchAuthorizationData();
  };

  @action.bound changeEIN(value: any) {
    let ein = value.target.value;
    if (isNaN(+ein) || ein.length > 9) {
      ein = ein.slice(0, -1);
    }
    this.einTextFieldValue = ein;
  }

  @action.bound fetchLocations = async () => {
    try {
      const resp = await Api.core.getAllLocationsByAccount(this.props.accountId!);
      this.locations = resp.data.data;
    } catch (e) {
      this.props.toastStore!.push({ type: 'error', message: getErrorMsg(e) });
    }
  };

  @computed get isEinValid() {
    if (!this.einTextFieldValue) return false;
    if (this.einTextFieldValue.length === 9 && !isNaN(+this.einTextFieldValue)) return true;
    return false;
  }

  @computed get isEntityNameValid() {
    if (!this.entityTextFieldValue) return false;
    return true;
  }

  isBankAccountVerified = (bankAccount: BankAccount) => {
    const verificationStatus = bankAccount.verificationStatus;
    if (
      verificationStatus === BankAccountStatus.AUTOMATICALLY_VERIFIED ||
      verificationStatus === BankAccountStatus.MANUALLY_VERIFIED ||
      verificationStatus === BankAccountStatus.VERIFIED
    ) {
      return true;
    }
    return false;
  };

  async componentDidMount() {
    this.fetchCanEnrollForNetspend();
    this.fetchPrimaryWallet();
    this.fetchLocations();
  }

  renderEntityNameStep() {
    if (this.entityName && !this.entityTextFieldValue) this.entityTextFieldValue = this.entityName;
    if (this.einNumber && !this.einTextFieldValue) this.einTextFieldValue = this.einNumber;
    return (
      <Box>
        <Typography variant="h4" component="h1" align="center" gutterBottom>
          Bank Account
        </Typography>

        <Box mt={4}>
          <Typography component="h1" variant="subtitle1" gutterBottom>
            In order to continue, we require an official name of business entity registered with the
            secretary of state and your Employer Identification Number (EIN).
          </Typography>
        </Box>

        <Box mt={3}>
          <OutlinedInput
            value={this.entityTextFieldValue}
            onChange={(e) => (this.entityTextFieldValue = e.target.value)}
            label={'Entity name'}
            fullWidth
          />
        </Box>

        <Box mt={2}>
          <OutlinedInput
            value={this.einTextFieldValue}
            onChange={this.changeEIN}
            label={'EIN'}
            fullWidth
          />
        </Box>

        <Typography
          className={this.props.classes.rebateTextSpacingSmall}
          component="h1"
          variant="subtitle1"
          gutterBottom>
          {this.entityResponse!.authorization.bodyText}
        </Typography>

        <Box mt={4}>
          <Button
            size="large"
            color="primary"
            variant="contained"
            fullWidth
            onClick={() => {
              this.submitEntityName();
            }}
            disabled={!this.isEinValid || !this.isEntityNameValid}>
            {this.entityResponse!.authorization.buttonText}
          </Button>
        </Box>

        <Box mt={3.75}>
          <Button variant="text" fullWidth onClick={() => this.toggleLinkBankAccountModal(false)}>
            Cancel
          </Button>
        </Box>
      </Box>
    );
  }

  renderLinkBankStep() {
    return (
      <Box style={{ minWidth: '20vw' }}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignContent: 'center',
            alignItems: 'center',
          }}>
          <Typography variant="h5">{this.entityResponse!.entityName}</Typography>
        </div>
        <Box mt={7}>
          {!this.errorMsg && (
            <Typography variant="subtitle1">
              {this.props.payoutSourceStore!.identityVerificationCode ? (
                <p>
                  Thanks for verifying that it{`'`}s really you! You can now link your bank account
                </p>
              ) : (
                <>
                  <p>Tippy uses Plaid to link and verify your bank account.</p>
                  <p>
                    You can link and instantly verify your bank account by signing into your online
                    banking with your credentials.
                  </p>
                  <p>
                    {`If you can't find your bank, don’t worry. You will have the option to link your bank
              account with account and routing numbers.`}
                  </p>
                </>
              )}
            </Typography>
          )}
          {this.errorMsg && (
            <Typography color="textSecondary">
              <p>{this.errorMsg}</p>
            </Typography>
          )}
        </Box>

        <Box mb={3} mt={6}>
          {this.renderPlaidLink()}
        </Box>

        <Button
          style={{ fontSize: 16 }}
          variant="text"
          fullWidth
          onClick={() => this.toggleLinkBankAccountModal(false)}>
          Cancel
        </Button>
      </Box>
    );
  }

  renderBankInitial() {
    const classes = this.props.classes;

    if (this.loadingEntityName) {
      return (
        <Box mb={3} mt={3} className={classes.containerBox}>
          <OverlayLoader display />
        </Box>
      );
    }

    if (!this.entityResponse) return;
    const { entityName, ein } = this.entityResponse;
    if (entityName && ein) {
      return this.renderLinkBankStep();
    } else {
      return this.renderEntityNameStep();
    }
  }

  renderLoadingButton() {
    return <Button size="large" color="primary" variant="contained" fullWidth loading={true} />;
  }

  renderPlaidLink() {
    if (this.props.payoutSourceStore!.requiresIdentityVerification) {
      return (
        <Button
          style={{ fontSize: 16 }}
          variant="contained"
          fullWidth
          onClick={this.props.payoutSourceStore!.verifyIdentity}>
          Verify Identity
        </Button>
      );
    }
    return (
      <TippyPlaidLink
        onExit={this.closePlaid}
        onSuccess={this.createBankAccount}
        fallback={this.renderLoadingButton()}>
        <Button variant="contained" fullWidth>
          Link Bank Account
        </Button>
      </TippyPlaidLink>
    );
  }

  /**
   * What the user sees when they've successfully set up
   * their account and it's verified.
   */
  renderVerified() {
    return (
      <>
        <Typography variant="h4" component="h1" align="center" gutterBottom>
          Groovy!
        </Typography>
        <Typography color="textSecondary">
          <p>{`You've successfully set up your account! Wasn't that easy?`}</p>
          <p>You can now transfer the tips that you receive straight to your account.</p>
        </Typography>
        <Box mt={6}>
          <Button
            onClick={() => this.toggleLinkBankAccountModal(false)}
            size="large"
            color="primary"
            variant="contained"
            fullWidth>
            Close
          </Button>
        </Box>
      </>
    );
  }

  /**
   * What the user sees when they've connected their account, but
   * it's still pending automatic verification.
   */
  renderAutomaticVerification() {
    return (
      <>
        <Typography variant="h4" component="h1" align="center" gutterBottom>
          Almost Done!
        </Typography>
        <Typography color="textSecondary">
          <p>
            Your bank account is almost set up! It will take 1-2 business days for Plaid to confirm
            it.
          </p>
          <p>
            You don{`'`}t have to take any action, we{`'`}ll notify you once your account is ready.
          </p>
        </Typography>
        <Box mt={6}>
          <Button
            onClick={() => this.toggleLinkBankAccountModal(false)}
            size="large"
            color="primary"
            variant="contained"
            fullWidth>
            Close
          </Button>
        </Box>
      </>
    );
  }

  /**
   * What the user sees once they've set their bank account up
   * but it still requires manual verification.
   */
  renderManualVerification() {
    const { classes } = this.props;
    return (
      <>
        <Typography variant="h4" component="h1" align="center" gutterBottom>
          Almost Done!
        </Typography>
        <Box display="flex" alignItems="center" justifyContent="center">
          <Chip
            variant="outlined"
            label="IMPORTANT"
            icon={<AlertCircleOutline />}
            color="secondary"
          />
        </Box>
        <Box mb={6}>
          <Typography color="textSecondary">
            <ul className={classes.stepList}>
              <li>Plaid will send two small deposits to your account within 2-3 business days</li>
              <li>Check your bank account for deposits with amounts less than $1</li>
              <li>Click on your bank account to enter the amounts.</li>
            </ul>
          </Typography>
        </Box>
        <Button
          size="large"
          color="primary"
          variant="contained"
          fullWidth
          onClick={() => this.toggleLinkBankAccountModal(false)}>
          Close
        </Button>
      </>
    );
  }

  openCloseWizzard = () => {
    if (this.open === this.props.open) return;
    this.toggleLinkBankAccountModal(this.props.open);
  };

  render() {
    const classes = this.props.classes;
    this.openCloseWizzard();
    return (
      <>
        <Backdrop classes={{ root: classes.noBlur }} className={classes.backdrop} open={this.open}>
          <AnonLayout
            hideIcon={this.props.hideIcon}
            inProgress={this.creatingBankAccount}
            padding={3}>
            <Box className={classes.root}>
              {this.promptState === 'initial' && this.renderBankInitial()}
              {this.promptState === 'pending_manual_verification' &&
                this.renderManualVerification()}
              {this.promptState === 'pending_automatic_verification' &&
                this.renderAutomaticVerification()}
              {this.promptState === 'verified' && this.renderVerified()}
            </Box>
          </AnonLayout>
        </Backdrop>
      </>
    );
  }
}

export default withStyles(styles)(BankAccountWizzard);
