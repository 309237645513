import { useEffect, useState } from 'react';
import Api, { getErrorMsg } from 'api';
import { rootStore } from 'containers/App/App';
import { LocationUser } from 'models/LocationUser';
import * as models from 'models';
import { useWorkspaceContext } from './WorkspaceContext';
import { useDialogContext } from './WorkspaceDialogContext';
import { debounce, isEmpty } from 'lodash';

type Location = Pick<models.Location, 'id' | 'uid' | 'name'> & { isChecked?: boolean };
interface AccountLocation {
  accountId: number | undefined;
  accountCode: string | undefined;
  accountName: string | undefined;
  locations: Location[] | undefined;
}
interface ModifiedLocation {
  accountCode: string;
  locationId: number;
}

let abortController = new AbortController();

const useAddManagedLocationsDialog = ({ user }: { user?: models.User }) => {
  const [accountLocations, setAccountLocations] = useState<AccountLocation[] | null>(null);
  const [modifiedLocations, setModifiedLocations] = useState<ModifiedLocation[]>([]);
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);

  const [search, setSearch] = useState('');
  const { accountSections, locationUsers, getUserLocations } = useWorkspaceContext();
  const { open, setOpenDialog } = useDialogContext();

  const { toastStore } = rootStore;
  const hideChevron = !accountSections || accountSections?.length <= 1;
  const disabled = isEmpty(modifiedLocations);

  useEffect(() => {
    if (!accountLocations) {
      getAccountLocations();
    }
  }, [open]);

  const handleSetOpen = (open: boolean) => {
    setOpenDialog && setOpenDialog(open);
  };

  const handleClose = async () => {
    if (saving) {
      abortController.abort();
    }
    setModifiedLocations([]);
    setSearch('');
    handleSetOpen(false);
    setSaving(false);
  };

  const getAccountLocations = async () => {
    if (!accountSections) return;
    setLoading(true);
    const _accountLocations = await Promise.all(
      accountSections.map(async (accountSection) => {
        let res;
        try {
          res = await Api.core.getAccountLocations(accountSection.account.id);
        } catch (error: any) {
          if (error.response.status !== 403) {
            toastStore.error(getErrorMsg(error));
          }
        }
        const { id: accountId, name: accountName, code: accountCode } = accountSection.account;
        const accounData = {
          accountId,
          accountName,
          accountCode,
          locations: res?.data.data.map((location: Record<string, string | number>) => {
            return {
              id: location.id,
              uid: location.uid,
              name: location.name,
            };
          }),
        };
        return accounData;
      }),
    );
    if (_accountLocations) {
      setAccountLocations(_accountLocations.filter((account) => account.locations));
    }
    setLoading(false);
  };

  const findLocationUser = (location: Location) => {
    return locationUsers?.find((locationUser) => locationUser.locationId === location.id);
  };

  const findModifiedLocation = (location: Location) => {
    return modifiedLocations?.find(
      (modifiedLocation) => modifiedLocation.locationId === location.id,
    );
  };

  const checkIfLocationSelected = (user?: LocationUser, modifiedLocation?: ModifiedLocation) => {
    let isChecked = false;
    if (modifiedLocation) {
      if (user && user?.isManager) {
        isChecked = false;
      } else {
        isChecked = true;
      }
    } else if (!modifiedLocation && user?.isManager) {
      isChecked = true;
    }
    return isChecked;
  };

  const mapCheckboxPropertiesToLocations = (locations: Location[] | undefined) => {
    if (!locations) return;
    return locations.map((location) => {
      const user = findLocationUser(location);
      const modifiedLocation = findModifiedLocation(location);
      const isChecked = checkIfLocationSelected(user, modifiedLocation);
      return {
        ...location,
        isChecked,
      };
    });
  };

  const filterAndMapLocations = () => {
    return accountLocations?.map((account) => {
      let locations = account.locations?.filter((location) =>
        location.name.toLocaleLowerCase().includes(search.toLocaleLowerCase()),
      );
      locations = mapCheckboxPropertiesToLocations(locations);
      return {
        ...account,
        locations,
      };
    });
  };
  const filteredAccountLocations = filterAndMapLocations();

  const handleCheck = (accountCode: string, locationId: number) => {
    if (!Array.isArray(modifiedLocations)) return;
    const modifiedLocation = { accountCode, locationId };
    const _modifiedLocations = [...modifiedLocations];
    const existingIndex = modifiedLocations.findIndex(
      (item) => item.locationId === modifiedLocation.locationId,
    );
    if (existingIndex > -1) {
      _modifiedLocations.splice(existingIndex, 1);
    } else {
      _modifiedLocations.push(modifiedLocation);
    }
    setModifiedLocations(_modifiedLocations);
  };

  const updateUsersLocation = async () => {
    if (modifiedLocations.length) {
      try {
        setSaving(true);
        await Promise.all(
          modifiedLocations.map((modifiedLocation, i) => {
            const userLocation = locationUsers?.find(
              (loc) => loc.locationId === modifiedLocation.locationId,
            );
            // Modify existing location
            if (userLocation) {
              const updatedUserData = {
                userId: userLocation.userId,
                isManager: !userLocation.isManager,
              };
              return Api.core.updateLocationUser(
                modifiedLocation.locationId,
                updatedUserData,
                abortController,
              );
            }
            // Add user to new location
            else {
              return Api.core.addMeToLocation(
                modifiedLocation.locationId,
                modifiedLocation.accountCode,
                user!.id,
                abortController,
              );
            }
          }),
        );
        setSaving(false);
        toastStore!.success(`Locations successfully updated`);
      } catch (error: any) {
        if (error?.message === 'canceled') {
          toastStore!.success(`Location(s) update aborted`);
          resetAbortController();
        } else {
          toastStore!.error(`At least some location(s) could not be updated`);
        }
      } finally {
        getUserLocations!();
        handleClose();
      }
    }
  };

  const resetAbortController = () => {
    abortController = new AbortController();
  };

  const updateSearch = debounce((value: string) => {
    setSearch(value);
  }, 500);

  return {
    open,
    filteredAccountLocations,
    hideChevron,
    disabled,
    loading,
    saving,
    handleClose,
    handleCheck,
    updateUsersLocation,
    updateSearch,
  };
};

export default useAddManagedLocationsDialog;
