import { Inventory } from 'models';
import { useState } from 'react';
import validatorjs from 'validatorjs';
import { IAddItem } from './AddItem';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const dvr = require('mobx-react-form/lib/validators/DVR');
const MobxReactForm = require('mobx-react-form').default;

interface Rules {
  [key: string]: {
    function: (value: any) => boolean;
    message: string;
  };
}

const rules: Rules = {
  decimal: {
    function: (value: any) => {
      if (/^\d*\.?\d*$/.test(value)) return true;
      return false;
    },
    message: 'Number must be an integer or decimal number.',
  },
  date: {
    function: (value: any) => {
      if (/^\d{4}(-\d{2}){2}$/.test(value)) return true;
      return false;
    },
    message: 'Date must be in YYYY-MM-DD format.',
  },
};

const plugins = {
  dvr: dvr({
    package: validatorjs,
    extend: ({ validator, form }: { validator: any; form: any }) => {
      Object.keys(rules).forEach((key) =>
        validator.register(key, rules[key].function, rules[key].message),
      );
    },
  }),
};

export const useAddItem = ({ fields, hardware, submit }: IAddItem) => {
  const hooks = {
    onSuccess,
  };

  const [form] = useState(new MobxReactForm({ fields }, { plugins, hooks }));

  async function onSuccess() {
    let item = { hardwareId: hardware!.id } as Inventory;
    fields.forEach((field: any) => {
      const value = form.$(field.name).value;
      if (value) {
        item = { ...item, [field.name]: value };
      }
    });
    await submit([item]);
  }

  return { form };
};
