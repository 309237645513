import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { observable, action, flow, computed, makeObservable } from 'mobx';
import { observer } from 'mobx-react';
import { WithStyles, withStyles } from '@material-ui/core/styles';
import { Box, Typography } from '@material-ui/core';

import { paths } from 'routes';
import { inject, WithUserStore } from 'stores';
import Api from 'api';
import PasswordField from 'components/form/PasswordField';
import qs from 'qs';

import styles from './styles';
import CarouselScreenWrapper from 'components/CarouselScreenWrapper/CarouselScreenWrapper';
import withAndroidRedirect from 'components/WithAndroidRedirect/withAndroidRedirect';
import SignupLayout, { ISignupLayoutProps } from 'containers/SignupLayout/SignupLayout';
import theme from 'containers/App/theme';
import CircularIconFrame from 'components/CircularIconFrame/CircularIconFrame';
import { faCheck, faFaceThinking } from '@fortawesome/pro-regular-svg-icons';

type ResetPasswordProps = WithStyles<typeof styles> & RouteComponentProps & WithUserStore;

type PasswordResetState = 'START' | 'ERROR' | 'SUCCESS';

type ResetPasswordField = 'newPassword' | 'newPasswordConfirm';

/**
 * The router's match params should adhere to this interface
 */
interface MatchParams {
  token: string;
}

const formId = 'reset-password-form';

/**
 * The login screen container component.
 * TODO: User a general solution for form validation.
 */
@inject('userStore')
@observer
class ResetPassword extends Component<ResetPasswordProps> {
  constructor(props: ResetPasswordProps) {
    super(props);
    makeObservable(this);
  }
  /** The password reset token provided in the URL */
  @observable public token!: string;

  /** The user's id  */
  @observable public userId?: number;

  /** The value of the new password field */
  @observable public newPassword = '';

  /** The value of the new password confirm field */
  @observable public newPasswordConfirm = '';

  @observable private confirmEmailToken = '';

  /** Whether the reset password API call is in progress */
  @observable public inProgress = false;

  /** The current state of the screen */
  @observable public currentState: PasswordResetState = 'START';

  /** The current error */
  @observable public errorMsg = '';

  /** Indicates which fields have been touched */
  @observable public touchedFields: Map<ResetPasswordField, boolean> = new Map([
    ['newPassword', false],
    ['newPasswordConfirm', false],
  ]);

  /**
   * Marks the field name as touched, so that we know to display validation errors on it.
   * @param fieldName The name of the field
   */
  @action.bound public markTouched(fieldName: ResetPasswordField) {
    this.touchedFields.set(fieldName, true);
  }

  @action.bound public markAllTouched() {
    this.touchedFields.forEach((value, key) => {
      this.markTouched(key);
    });
  }
  /** Handle the new password text field change event */
  @action.bound public handleNewPasswordChange(e: React.ChangeEvent<HTMLInputElement>) {
    this.newPassword = e.target.value;
  }

  /** Handle the new password confirm change event */
  @action.bound public handeNewPasswordConfirmChange(e: React.ChangeEvent<HTMLInputElement>) {
    this.newPasswordConfirm = e.target.value;
  }

  /**
   * Handles the main form submission.
   * @param e The form submitted event
   */
  @action.bound public handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    this.markAllTouched();
    if (this.formValid) {
      this.resetPassword();
    }
  }

  /**
   * Initiates the reset password api call.
   */
  @action.bound public resetPassword = flow(function* (this: ResetPassword) {
    try {
      this.inProgress = true;
      yield Api.core.changePassword(this.token, this.newPassword);
      // Check if we need to confirm the email
      if (this.confirmEmailToken) {
        yield Api.core.confirmEmail(this.confirmEmailToken);
      }
      this.currentState = 'SUCCESS';
    } catch (e: any) {
      this.currentState = 'ERROR';
      this.errorMsg = 'Something went wrong. Please try again.';
    } finally {
      this.inProgress = false;
    }
  });

  /** Checks if the password reset token supplied in the URL is valid and record that in the state */
  @action.bound public getPasswordResetToken = flow(function* (this: ResetPassword) {
    try {
      // Do the API call
      const response = yield Api.core.getPasswordResetToken(this.token);
      const expiresAt: string = response.data.data.expiresAt;
      this.userId = response.data.data.userId;
      // If the token is expired, set the currentState to ERROR
      if (new Date() > new Date(expiresAt)) {
        this.currentState = 'ERROR';
        this.errorMsg = 'The password reset token is expired.';
      }
    } catch (err: any) {
      // Set the current state to ERROR and set the appropriate error message
      this.currentState = 'ERROR';
      const notFound = err.response.status === 404;
      this.errorMsg = notFound ? 'The token does not exist.' : 'An unknown error has occurred.';
    }
  });

  /** The current error on the newPassword field */
  @computed public get newPasswordError(): string | undefined {
    return (
      (this.touchedFields.get('newPassword') &&
        this.newPassword.length < 8 &&
        'Your password is too short') ||
      undefined
    );
  }

  /** The current error on the newPasswordConfirm field */
  @computed public get newPasswordConfirmError(): string | undefined {
    return (
      (this.touchedFields.get('newPasswordConfirm') &&
        this.newPasswordConfirm !== this.newPassword &&
        `Please input the same password again`) ||
      undefined
    );
  }

  /** Returns whether the form is valid */
  @computed public get formValid(): boolean {
    return !this.newPasswordError && !this.newPasswordConfirmError;
  }

  @computed public get signupLayoutProps() {
    const toSignIn = () => this.props.history.push(paths.signIn());
    let props: Omit<ISignupLayoutProps, 'children'> = {
      title: 'Set Password',
      action: {
        type: 'submit',
        form: formId,
        children: 'Set password',
        loading: this.inProgress,
      },
      subAction: {
        center: {
          onClick: toSignIn,
          label: 'Sign In',
        },
      },
    };

    switch (this.currentState) {
      case 'ERROR':
        props = {
          title: 'Ooops!',
          action: {
            onClick: toSignIn,
            children: 'Try again',
          },
        };
        break;
      case 'SUCCESS':
        props = {
          title: 'Success',
          action: {
            onClick: toSignIn,
            children: 'Sign in',
          },
        };
        break;
    }

    return props;
  }

  componentDidMount() {
    const params = this.props.match.params as MatchParams;
    this.token = params.token;
    // When the component mounts, check if the password reset token is still valid
    this.getPasswordResetToken();

    const { confirmEmailToken } = qs.parse(this.props.location.search, { ignoreQueryPrefix: true });
    this.confirmEmailToken = confirmEmailToken as string;
  }

  renderResetPasswordView() {
    const { classes } = this.props;
    return (
      <Box display={'flex'} flexDirection={'column'} gridGap={theme.spacing(4)}>
        <Typography variant="body2" align="center">
          Almost there. Enter your new password, confirm it and you&apos;re good to go.
        </Typography>
        <form id={formId} onSubmit={this.handleSubmit}>
          <PasswordField
            autoFocus
            value={this.newPassword}
            error={this.newPasswordError}
            onChange={this.handleNewPasswordChange}
            onBlur={() => this.markTouched('newPassword')}
            id="new-password"
            required
          />
          <Box mt={2}>
            <PasswordField
              value={this.newPasswordConfirm}
              onChange={this.handeNewPasswordConfirmChange}
              error={this.newPasswordConfirmError}
              onBlur={() => this.markTouched('newPasswordConfirm')}
              id="new-password-confirm"
              label="Confirm password"
              required
            />
          </Box>
        </form>
      </Box>
    );
  }

  renderErrorView() {
    const { classes } = this.props;
    return (
      <Box display={'flex'} flexDirection={'column'} gridGap={theme.spacing(8)}>
        <CircularIconFrame icon={faFaceThinking} color="red" />
        <Typography className={classes.greyText} align="center">
          {this.errorMsg}
        </Typography>
      </Box>
    );
  }

  renderSuccessView() {
    const { classes } = this.props;
    return (
      <Box display={'flex'} flexDirection={'column'} gridGap={theme.spacing(8)}>
        <CircularIconFrame iconHeight={77} icon={faCheck} />
        <Typography className={classes.greyText} align="center">
          Password changed successfully! You can now log in to the Tippy app.
        </Typography>
      </Box>
    );
  }

  render() {
    return (
      <CarouselScreenWrapper>
        <SignupLayout {...this.signupLayoutProps}>
          <>
            {this.currentState === 'START' && this.renderResetPasswordView()}
            {this.currentState === 'ERROR' && this.renderErrorView()}
            {this.currentState === 'SUCCESS' && this.renderSuccessView()}
          </>
        </SignupLayout>
      </CarouselScreenWrapper>
    );
  }
}

export default withAndroidRedirect(withStyles(styles)(ResetPassword));
