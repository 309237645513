import React from 'react';
import styles from '../styles';
import { WithStyles, withStyles } from '@material-ui/core/styles';
import DP from '../../../../components/DashPanel/DashPanel';
import { Grid, Link } from '@material-ui/core';
import { Pencil } from 'mdi-material-ui';
import { computed, observable, action, makeObservable } from 'mobx';
import { paths } from 'routes';
import Api, { RequestMetaData } from '../../../../api';
import { adaptForDataGridPro, setTitle, EllipsizedValue } from '../../../../services';
import { Link as RouterLink, RouteComponentProps } from 'react-router-dom';
import { observer } from 'mobx-react';
import PartnerDetailsDrawer from './PartnerDetailsDrawer';
import DataGridInfiniteScroll from 'components/DataGridInfiniteScroll';
import moment from 'moment-timezone';
import { numericStringToUsd } from 'services/currency';
const MobxReactForm = require('mobx-react-form').default;

const payoutsFields: any = {
  bankStatus: {
    label: 'Bank status',
    type: 'text',
  },
  payoutInterval: {
    label: 'Payout interval',
    type: 'text',
  },
  rebateStartDate: {
    label: 'Rebate start date',
    type: 'text',
  },
};

const ACHFields: any = {
  processor: {
    label: 'Processor',
    type: 'text',
  },
  destination: {
    label: 'Destination',
    type: 'text',
  },
};

interface PartnerDetailsMatchParams {
  partnerId: string;
}

type PartnersDetailsProps = WithStyles<typeof styles> &
  RouteComponentProps<PartnerDetailsMatchParams>;

@observer
class PartnerDetailsGeneral extends React.Component<PartnersDetailsProps> {
  constructor(props: PartnersDetailsProps) {
    super(props);
    makeObservable(this);
  }

  @computed public get partnerId(): number {
    return parseInt(this.props.match.params.partnerId);
  }

  /** Active filters as returned by FilterBar */
  @observable private activeFilters: Record<string, unknown> = {};

  @observable private editingDetails = false;
  // @observable private editingACH = false;

  @observable private partner?: any;
  @observable private wallet?: any;

  @observable public dataGridKey = Date.now();

  renderPartnerFee = ({ value }: any) => {
    return <>{this.getFee(value.feeAmount, value.feePercent)}</>;
  };

  gridColumns = [
    { headerName: 'Name', field: 'name', minWidth: 150, flex: 1 },
    { headerName: 'Total Fee', field: 'totalFee', minWidth: 150, flex: 1 },
    {
      headerName: 'Partner Fee',
      field: 'partnerFee',
      minWidth: 150,
      flex: 1,
      renderCell: this.renderPartnerFee,
    },
    { headerName: 'Assigned To', field: 'assignedTo', minWidth: 150, flex: 1 },
  ];

  private async fetchPartner() {
    const resp = await Api.tips.getPartner(this.partnerId);
    this.partner = resp.data.data;

    if (this.partner) {
      setTitle(this.partner.name, { noSuffix: false });
    }
  }

  private async fetchWallet() {
    if (this.partner && this.partner.walletId) {
      const resp = await Api.tips.getWallet(this.partner.walletId);
      this.wallet = resp.data.data;
    }
  }

  @action.bound public getFees = adaptForDataGridPro(
    async (rmd: RequestMetaData) => {
      return await Api.tips.getFeeSchedulesForPartner(this.partnerId, rmd);
    },
    (schedule: any) => {
      const processor = schedule.processor ? true : false;
      return {
        ...schedule,
        totalFee: this.getFee(schedule.feeAmount, schedule.feePercent),
        partnerFee: this.partnerFee(schedule.rules, this.partnerId),
        assignedTo: processor ? schedule.processor : schedule.app.name,
      };
    },
  );

  renderCellAccount = ({ name, id }: any) => {
    if (name && id) {
      return (
        <Link component={RouterLink} to={paths.accountDetails(id).root()} style={{ width: '100%' }}>
          <EllipsizedValue value={`${name} (${id})`} max={20} />
        </Link>
      );
    }
    return undefined;
  };

  partnerFee = (rules: any[], partnerId: number) => {
    const _rules = rules.filter((rule) => rule.partnerId === partnerId);
    if (_rules.length) return _rules[0];
  };

  componentDidMount() {
    this.fetchPartner().then(() => this.fetchWallet());
  }

  getFee = (feeAmount: string, feePercent: number | string) => {
    if (feeAmount === null && feePercent === null) return 'N/A';
    feeAmount = feeAmount !== null ? feeAmount : '0';
    feePercent = feePercent !== null ? feePercent : '0';
    return `${feePercent}% + ${numericStringToUsd(feeAmount)}`;
  };

  detailsFields: any = {
    name: {
      label: 'Name',
      type: 'text',
    },
    description: {
      label: 'Description',
      type: 'text',
    },
    fee: {
      label: 'Default fee',
      type: 'text',
    },
    type: {
      label: 'Type',
      type: 'text',
    },
    account: {
      label: 'Account',
      type: 'text',
    },
    joinedOn: {
      label: 'Joined On',
      type: 'text',
    },
  };

  renderDetailsPanel = () => (
    <DP fullHeight>
      <DP.Header>
        <DP.Title panel>Details</DP.Title>
        <DP.Actions>
          {this.partner && (
            <DP.IconButton
              primary
              onClick={() => {
                this.editingDetails = true;
              }}
              icon={Pencil}
              tooltip="Edit"
            />
          )}
        </DP.Actions>
      </DP.Header>
      <DP.Body>
        {Object.entries(this.detailsFields).map(([name, field]: any) => {
          return this.getDetailsRow(name, field);
        })}
      </DP.Body>
    </DP>
  );

  getDetailsRow = (name: string, field: any) => {
    let value: any = 'N/A';
    if (this.partner) {
      if (name === 'fee') value = this.getFee(this.partner.feeAmount, this.partner.feePercent);
      else if (name === 'account' && this.partner[name])
        value = this.renderCellAccount(this.partner[name]);
      else if (
        name === 'joinedOn' &&
        this.partner['account'] &&
        this.partner['account']['createdAt']
      )
        value = moment(this.partner['account']['createdAt']).format('LL');
      else if (this.partner[name]) value = this.partner[name];
    }

    return (
      <DP.Row key={name}>
        {this.partner && <DP.Value>{value}</DP.Value>}
        <DP.Label>{field.label}</DP.Label>
      </DP.Row>
    );
  };

  renderACHPanel = () => (
    <DP fullHeight>
      <DP.Header>
        <DP.Title panel>Payouts</DP.Title>
        {/*<DP.Actions>
          <DP.IconButton
            primary
            onClick={() => {
              this.editingACH = true;
            }}
            icon={Pencil}
            tooltip="Edit"
          />
        </DP.Actions>*/}
      </DP.Header>
      <DP.Body>
        {Object.entries(payoutsFields).map(([name, field]: any) => {
          let value = this.partner && this.partner[name] ? this.partner[name] : 'N/A';
          if (name === 'rebateStartDate' && value !== 'N/A') value = moment(value).format('ll');
          return (
            <DP.Row key={name}>
              <DP.Value>{value}</DP.Value>
              <DP.Label>{field.label}</DP.Label>
            </DP.Row>
          );
        })}
        {Object.entries(ACHFields).map(([name, field]: any) => {
          const value = this.wallet && this.wallet[name] ? this.wallet[name] : 'N/A';
          return (
            <DP.Row key={name}>
              <DP.Value>{value}</DP.Value>
              <DP.Label>{field.label}</DP.Label>
            </DP.Row>
          );
        })}
      </DP.Body>
    </DP>
  );

  render() {
    return (
      <div>
        <Grid container direction={'row'} spacing={3}>
          <Grid item sm={6}>
            {this.renderDetailsPanel()}
          </Grid>
          <Grid item sm={6}>
            {this.renderACHPanel()}
          </Grid>
          <Grid item sm={12}>
            <DataGridInfiniteScroll
              columns={this.gridColumns}
              fetch={this.getFees}
              refetchKey={this.activeFilters}
              disableColumnMenu
              pathname={this.props.location.pathname}
            />
          </Grid>
        </Grid>
        {this.partner && (
          <PartnerDetailsDrawer
            partnerData={this.partner}
            walletId={this.partner.walletId}
            partnerId={this.partnerId}
            isOpen={this.editingDetails}
            closeDrawer={() => {
              this.editingDetails = false;
              this.fetchPartner();
              this.fetchWallet();
              this.dataGridKey = Date.now();
            }}
          />
        )}
      </div>
    );
  }
}

export default withStyles(styles)(PartnerDetailsGeneral);
