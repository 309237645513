import { getErrorMsg } from 'api';
import _ from 'lodash';
import { Inventory } from 'models';
import Papa from 'papaparse';
import { useRef, useState } from 'react';
import { IAddItems } from './AddItems';

export const useAddItems = ({
  fields,
  hardware,
  setHardwareError,
  toastStore,
  submit,
}: IAddItems) => {
  const [dragging, setDragging] = useState(false);
  const [inventoryItems, setInventoryItems] = useState<Inventory[]>([]);
  /** The ref for the area in which we can drop files */
  const dragFrameRef = useRef() as React.MutableRefObject<HTMLInputElement>;

  function handleCsv(fileList: FileList | null) {
    // Do this to disable the dotted outline on the dragFrameRef
    setDragging(false);

    // Sanity check
    if (!fileList || setHardwareError()) {
      return;
    }

    const contentArray: string[] = [];
    const reader = new FileReader();
    let currentFileIndex = 0;

    // Reads a file with the current file index from the fileList
    const readFile = () => {
      // If we've reached the end of the file list, go over the
      // contentArray and add the serials to the map
      if (currentFileIndex >= fileList.length) {
        contentArray.forEach((content) => {
          const parsedFile = Papa.parse(content, { header: true });
          try {
            if (!parsedFile.meta || !parsedFile.meta.fields) {
              throw new Error('CSV file should contain a header');
            } else {
              fields.forEach((field: any) => {
                const metaField = parsedFile.meta.fields?.find(
                  (metaField: string) => metaField === field.name,
                );
                if (!metaField) {
                  throw new Error('CSV file in wrong format or missing a header');
                }
              });
            }
            const items = (parsedFile.data as unknown as Inventory[]).map((item: Inventory) => {
              return { ...item, hardware: hardware! };
            });
            setInventoryItems([...inventoryItems, ...items]);
          } catch (error) {
            toastStore.error(getErrorMsg(error));
          }
        });

        // If we haven't, read the next file
      } else {
        const file = fileList.item(currentFileIndex);
        if (!file) {
          return;
        }
        reader.readAsText(file);
        // When this is done, reader.onload will be triggered
        // and take over
      }
    };

    // Once a file has been read via invoking reader.readAsText,
    // this handler is called
    reader.onload = (e) => {
      // Get the contents of the file
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const contents = e.target && (e.target as any).result;
      // Get the file to read the type
      const file = fileList.item(currentFileIndex);
      // Has to be text/plain or text/csv
      if (file && file.type.startsWith('text')) {
        contentArray[currentFileIndex] = contents;
      } else {
        toastStore!.error(`File ${file && file.name} must be in text format`);
      }
      // Increase the current file index and read the next file
      currentFileIndex++;
      readFile();
    };
    readFile();
  }

  function submitItems(items: Array<Inventory>) {
    items = items.map(({ hardware, ...rest }: Inventory) => {
      return { ...rest, hardwareId: hardware!.id };
    });
    submit(items);
  }

  function deleteItem(item: Inventory) {
    const items = inventoryItems.filter((_item: Inventory) => !_.isEqual(_item, item));
    setInventoryItems(items);
  }

  return {
    dragging,
    inventoryItems,
    dragFrameRef,
    setDragging,
    handleCsv,
    deleteItem,
    submitItems,
  };
};
