import { Theme, createStyles } from '@material-ui/core';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default (theme: Theme) =>
  createStyles({
    root: {},
    loadingWrapper: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100vh',
    },
    loadingContent: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
    tippyLogo: {
      width: 90,
      height: 150,
      margin: 'auto',
    },
    animatedItem: {
      animation: `$myEffect 1000ms infinite linear`,
    },
    cardItem: {
      [theme.breakpoints.up(905)]: {
        width: '50%',
      },
      [theme.breakpoints.up(1179)]: {
        width: '33%',
      },
      width: '100%',
      minWidth: '274px',
    },
    '@keyframes myEffect': {
      from: {
        transform: 'translateY(-70%)',
      },
      '40%': {
        transform: 'scale(1,1)',
      },
      '50%': {
        transform: 'translateY(0) scale(1,0.8)',
        animationTimingFunction: theme.transitions.easing.easeOut,
      },
      to: {
        transform: 'translateY(-70%) scale(1,1)',
        animationTimingFunction: theme.transitions.easing.easeIn,
      },
    },
  });
