import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { observable, action, flow, computed, makeObservable } from 'mobx';
import { observer } from 'mobx-react';
import { WithStyles, withStyles } from '@material-ui/core/styles';
import {
  Box,
  Typography,
  IconButton,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
} from '@material-ui/core';
import { ChevronLeft, ChevronRight } from 'mdi-material-ui';

import { paths } from 'routes';
import {
  Account,
  AffiliateOrganization,
  Affiliate,
  Location,
  Invitation,
  TalentIntegrationApp,
} from 'models';
import { inject, WithToastStore, WithUserStore } from 'stores';
import Api, { ApiResponse, getErrorMsg, PagedApiResponse } from 'api';
import { AxiosResponse } from 'axios';

import CodeInput from 'components/CodeInput';
import SearchBox from 'components/SearchBox';

import styles from './styles';
import CarouselScreenWrapper from 'components/CarouselScreenWrapper/CarouselScreenWrapper';
import clsx from 'clsx';
import { rootStore } from 'containers/App/App';
import SignupLayout, { ISignupLayoutProps } from 'containers/SignupLayout/SignupLayout';

type LocationSelectionProps = WithStyles<typeof styles> &
  RouteComponentProps &
  WithToastStore &
  WithUserStore;

/**
 * Displays the screen for the user to add themselves to a location
 * This can be achieved with accepting pending invitation or entering correct company code
 */
@inject('toastStore', 'userStore')
@observer
class LocationSelection extends React.Component<LocationSelectionProps> {
  constructor(props: LocationSelectionProps) {
    super(props);
    makeObservable(this);
  }
  /** The confirmation code */
  @observable code = '';

  /** The current error */
  @observable error?: string;

  /** The fetched account */
  @observable account?: Account;

  /** The fetched affiliate organization */
  @observable organization?: AffiliateOrganization;

  /** Should component render loading state */
  @observable public loading = false;

  /** The search text */
  @observable public searchText = '';

  /** Updates the search text */
  @action.bound public updateSearchText(s: string) {
    this.searchText = s;
  }

  /**
   * On mount we fetch invitations for this user. If any are returned we show the
   * first one and shift it when user selects invitation action (i.e.: join or skip)
   */
  @observable private invitations?: Invitation[];

  /** Location select value for account scoped invitations */
  @observable private locationSelectValue = '';

  /** Update location select value */
  @action.bound public updateLocationSelectValue(event: React.ChangeEvent<{ value: unknown }>) {
    this.locationSelectValue = event.target.value as string;
  }

  /** Fetch invitations */
  @action.bound public getInvitations = async () => {
    try {
      this.loading = true;
      const res: AxiosResponse<PagedApiResponse<Invitation>> | undefined =
        await Api.core.getUserInvitations(this.props.userStore!.user!.id);
      if (res) this.invitations = res.data.data;
    } catch (e: any) {
      this.props.toastStore!.error(getErrorMsg(e));
    } finally {
      this.loading = false;
    }
  };

  /** Accept the invitation */
  @action.bound public acceptInvitation = async (locationId?: number) => {
    try {
      this.loading = true;
      if (!this.invitations) {
        throw new Error('An unknown error has occurred');
      }
      const invitationId = this.invitations[0].id;
      await Api.core.acceptInvitation(invitationId, locationId);

      // After user accepts an invitation we need to fetch his new location user data:
      this.props.userStore!.getRelatedData();
      this.nextInvitation();
    } catch (e: any) {
      this.props.toastStore!.error(getErrorMsg(e));
    } finally {
      this.loading = false;
    }
  };

  /**
   * Continue with the sign-up process
   */
  @action.bound public goToNextRoute = async (accountId: number) => {
    try {
      this.loading = true;
      // Fetch all apps that talent has not made integration with:
      const { data } = await Api.developer.getNonIntegratedApps();
      if (data?.data?.length) {
        // Save non-integrated apps to userStore
        const response = data.data.map((app: TalentIntegrationApp) => {
          return { ...app, accountId };
        });
        this.props.userStore!.setNonIntegratedApps(response);
        // Navigate to POS login route
        this.props.history.push(paths.externalAppLogin());
      } else {
        // navigate to profile picture
        this.props.history.push(paths.signUp().profilePicture());
      }
    } catch (error: any) {
      // navigate to profile picture or throw error?
      this.props.history.push(paths.signUp().profilePicture());
    } finally {
      this.loading = false;
    }
  };

  /**
   * If there are more pending invitations, shift invitations array and reset location
   * select value. If last invitation, redirect to next step in the sign up process.
   */
  @action.bound public nextInvitation() {
    if (!this.invitations) return;

    const accountId = this.invitations[0].account.id;
    this.invitations.length === 1 ? this.goToNextRoute(accountId) : this.invitations.shift();
    this.locationSelectValue = '';
  }

  /** Submits the code to the server */
  @action.bound public submitCompanyCode = flow(function* (this: LocationSelection) {
    try {
      this.loading = true;
      const resp: AxiosResponse<ApiResponse<Account>> = yield Api.core.getAccountByCode(
        this.codeUpper,
      );
      // The account has been found, so we unset the error
      this.error = undefined;
      // Check if the returned account has everything that we need to continue to the next screen
      const account = resp?.data?.data;
      if (!account) {
        this.error = `Location with code ${this.codeUpper} not found`;
        return;
      }
      // If the account has only one location, select that location
      if (account.locations!.length === 1) {
        yield this.submitLocation(account.locations![0].id, account.id);
        // Otherwise, set this.account to the account so that we proceed
        // to the location select screen
      } else {
        this.account = account;
      }
    } catch (e: any) {
      this.error = getErrorMsg(e);
    } finally {
      this.loading = false;
    }
  });

  /** Submits the organization code */
  @action.bound public submitOrganizationCode = flow(function* (this: LocationSelection) {
    try {
      this.loading = true;
      // Fetch the affiliate organization
      const resp: AxiosResponse<ApiResponse<AffiliateOrganization>> =
        yield Api.marketing.getAffiliateOrganization(this.codeUpper);
      this.error = undefined;
      if (!resp.data.data) {
        this.error = 'An unknown error has occurred';
      }
      const organization = resp.data.data!;
      if (organization.regions.length === 1) {
        yield this.submitRegion(organization.regions[0]!.id, organization);
      }
      this.organization = organization;
    } catch (e: any) {
      this.error = getErrorMsg(e);
    } finally {
      this.loading = false;
    }
  });

  /** Submits the location with the provided id and adds the user to it */
  @action.bound public submitLocation = flow(function* (
    this: LocationSelection,
    locationId: number,
    accountId: number,
  ) {
    try {
      this.loading = true;
      // Initiate the API call to add the current user to the selected location and get the
      // payment methods for the account
      const [, payoutMethodsResp] = yield Promise.all([
        Api.core.addMeToLocation(locationId, this.codeUpper, this.props.userStore!.user!.id),
        Api.core.getAccountPayoutMethods(accountId),
      ]);
      // This tells us whether instant payments are enabled
      const instant = Boolean(payoutMethodsResp.data && payoutMethodsResp.data.data.instant);
      // Fetch the user's related data again
      this.props.userStore!.getRelatedData();
      // If account supports instant payments store it in userStore,
      // because the BankPrompt component will read that info
      this.props.userStore!.setBankInstant(instant);
      this.goToNextRoute(accountId);
    } catch (e: any) {
      this.error = getErrorMsg(e);
      this.props.toastStore!.push({
        type: 'error',
        message: getErrorMsg(e) || 'An unknown error has occurred',
      });
    } finally {
      this.loading = false;
    }
  });

  /** Submits the region and continues to the next step */
  @action.bound public submitRegion = flow(function* (
    this: LocationSelection,
    regionId: number,
    organization: AffiliateOrganization,
  ) {
    try {
      this.loading = true;
      yield Api.marketing.updateAffiliate({
        id: this.affiliate!.id,
        regionId,
      });
      // If the affiliate organization has B2B payments, skip straight to the success
      // screen, otherwise continue to the bank screen
      if (organization!.hasB2BPayments) {
        this.props.history.push(paths.signUp().success());
      } else {
        this.props.history.push(paths.signUp().bank());
      }
    } catch (e: any) {
      this.error = getErrorMsg(e);
      this.props.toastStore!.error(getErrorMsg(e));
    } finally {
      this.loading = false;
    }
  });
  /** Clears the current account, taking to the first screen */
  @action.bound public clearAccount() {
    this.account = undefined;
  }

  /** Clears the current organization */
  @action.bound public clearOrganization() {
    this.organization = undefined;
  }
  /** Event handler to update the code */
  @action.bound public updateCode(e: React.ChangeEvent<HTMLInputElement>) {
    this.code = e.target.value;
  }

  /**
   * If an affiliate organization code is present in the user store, automatically
   * enters it and goes to the region selection.
   */
  @action.bound public enterAffiliateOrgCode() {
    if (this.affiliate && this.props.userStore!.affiliateSignupData.orgCode) {
      this.code = this.props.userStore!.affiliateSignupData.orgCode;
      this.submitOrganizationCode();
    }
  }

  /** The form submit handler */
  @action.bound public handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    if (this.codeEmpty) {
      this.error = 'Please enter a code';
      return;
    }
    if (this.affiliate) {
      this.submitOrganizationCode();
    } else {
      this.submitCompanyCode();
    }
  }

  /** The affiliate object of the current logged-in user */
  @computed public get affiliate(): Affiliate | undefined {
    return this.props.userStore!.affiliate;
  }

  /** Whether the code is empty */
  @computed public get codeEmpty(): boolean {
    return this.code === '';
  }

  /** The code, in uppercase */
  @computed public get codeUpper(): string {
    return this.code.toUpperCase();
  }

  /** The instruction text to display */
  @computed public get instructionText(): string {
    return this.props.userStore!.affiliate
      ? `If you are part of any sales company, enter their code below`
      : 'Enter the company code provided to you by your employer';
  }

  /** Whether to show the code input */
  @computed public get showCodeInput(): boolean {
    return !this.organization && !this.account;
  }

  /** The max number of locations to display at once */
  public maxLocationsToDisplay = 10;

  /** The locations in the account that was found */
  @computed public get locations(): Location[] | undefined {
    return this.account && this.account.locations;
  }

  /** Whether to show the search box */
  @computed public get showSearchBox(): boolean {
    return true;
  }

  /** The locations filtered by the search */
  @computed public get filteredLocations(): Location[] | undefined {
    if (!this.locations) {
      return undefined;
    }
    let filtered = this.locations;
    if (this.searchText !== '') {
      filtered = this.locations.filter((l) =>
        l.name.toLowerCase().includes(this.searchText.toLowerCase()),
      );
    }
    return filtered.slice(0, this.maxLocationsToDisplay);
  }

  /** Whether to display the message that no search results were found */
  @computed public get noSearchResults(): boolean {
    return Boolean(
      this.locations &&
        this.locations.length > 0 &&
        this.filteredLocations &&
        this.filteredLocations.length === 0,
    );
  }

  componentDidMount() {
    this.getInvitations();
    this.enterAffiliateOrgCode();
  }

  renderSelectionList() {
    if (this.organization) {
      return this.renderRegionSelect();
    } else {
      return this.renderLocationSelect();
    }
  }

  /** Renders the code input screen. This is displayed when this.account is undefined */
  renderCodeInput() {
    const classes = this.props.classes;
    const formId = 'company-code-form';
    const signupLayoutProps: Omit<ISignupLayoutProps, 'children'> = {
      title: 'Company',
      action: {
        type: 'submit',
        form: formId,
        children: 'Continue',
        loading: this.loading,
      },
      subAction: {
        back: {
          onClick: this.props.history.goBack,
        },
        skip: {
          onClick: () => this.props.history.push(paths.signUp().profilePicture()),
        },
      },
    };
    return (
      <SignupLayout {...signupLayoutProps}>
        <Box className={classes.root}>
          <form id={formId} onSubmit={this.handleSubmit}>
            <Typography className={classes.greyText} align="center" variant="body1" gutterBottom>
              {this.instructionText}
            </Typography>
            <Box mt={3}>
              <CodeInput
                value={this.code}
                onChange={this.updateCode}
                error={Boolean(this.error)}
                helperText={this.error}
              />
            </Box>
          </form>
        </Box>
      </SignupLayout>
    );
  }

  /** Renders the region selection for affiliates */
  renderRegionSelect() {
    const classes = this.props.classes;
    return (
      <Box className={classes.root}>
        <IconButton className={classes.button} onClick={this.clearOrganization}>
          <ChevronLeft />
        </IconButton>
        <Typography variant="h4" component="h1" align="center" gutterBottom>
          Region
        </Typography>
        <Typography align="center" variant="subtitle2" gutterBottom>
          {`Select the region in which you operate`}
        </Typography>
        <List className={classes.list}>
          {this.organization!.regions.map((region, i) => (
            <ListItem
              button
              onClick={() => this.submitRegion(region.id, this.organization!)}
              key={region.id}
              divider={i !== this.organization!.regions.length - 1}>
              <ListItemText primary={region.name} />
              <ListItemSecondaryAction>
                <ChevronRight color="action" />
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
      </Box>
    );
  }

  /** Renders the location selection screen, this is displayed when there is an account in this.account */
  renderLocationSelect() {
    const classes = this.props.classes;
    const { mobileView } = rootStore.uiStore;
    const signupLayoutProps: Omit<ISignupLayoutProps, 'children'> = {
      title: 'Location',
      subtitle: this.noSearchResults
        ? `No locations matching your search were found`
        : `Select your primary location. You can add more later.`,
      subAction: {
        back: {
          onClick: this.clearAccount,
          label: 'Back',
        },
      },
    };
    return (
      <SignupLayout {...signupLayoutProps}>
        <Box display={'flex'} flexDirection={'column'} gridGap={16}>
          {this.showSearchBox && (
            <SearchBox
              className={classes.searchBox}
              classes={{ root: classes.searchBox }}
              withPaper={false}
              onChange={this.updateSearchText}
              debounce={500}
              autoFocus
              disableGutters={true}
              placeholder="Search"
            />
          )}
          <List className={clsx(classes.scrollbar)}>
            {this.filteredLocations &&
              this.filteredLocations.map((location, i) => (
                <ListItem
                  className={classes.listItem}
                  button
                  onClick={() => this.submitLocation(location.id, this.account!.id)}
                  key={location.id}>
                  <ListItemText primary={location.name} />
                  <ListItemSecondaryAction>
                    <ChevronRight className={classes.chevronRight} />
                  </ListItemSecondaryAction>
                </ListItem>
              ))}
          </List>
        </Box>
      </SignupLayout>
    );
  }

  /** Renders invitation prompt screen. Displayed if user with this is email has pending invitations */
  renderInvitation() {
    if (!this.invitations) return;
    const invitation = this.invitations[0];
    const signupLayoutProps: Omit<ISignupLayoutProps, 'children'> = {
      title: 'Company',
      action: {
        onClick: () => this.acceptInvitation(),
        children: 'Join',
        loading: this.loading,
        disabled: !invitation.location && !this.locationSelectValue,
        hide: !invitation.location,
      },
      subAction: {
        back: {
          onClick: this.props.history.goBack,
        },
        skip: {
          onClick: this.nextInvitation,
        },
      },
    };

    return (
      <SignupLayout {...signupLayoutProps}>
        <>
          <Typography
            style={{ fontWeight: '700 !important' }}
            align="center"
            variant="body2"
            gutterBottom>
            {`You have been invited to join ${
              !invitation.location ? `${invitation.account.name}. Choose a location to join` : ''
            }`}
          </Typography>
          {invitation.location && (
            <Box mt={4} mb={13.25}>
              <Typography align="center" variant="h3" gutterBottom>
                {invitation.location.name}
              </Typography>
              <Box mt={3}>
                <Typography align="center" variant="body2" gutterBottom>
                  {invitation.location.address}
                </Typography>
              </Box>
            </Box>
          )}
          <Box mt={4} mb={4} maxHeight={250} overflow={'auto'}>
            {!invitation.location && (
              <List>
                {invitation &&
                  invitation.locations &&
                  invitation.locations.map((location, i) => (
                    <ListItem
                      key={location.id}
                      button
                      divider={i !== invitation.locations!.length - 1}
                      onClick={() => this.acceptInvitation(location.id)}>
                      <ListItemText primary={location.name} />
                      <ListItemSecondaryAction>
                        <ChevronRight color="action" />
                      </ListItemSecondaryAction>
                    </ListItem>
                  ))}
              </List>
            )}
          </Box>
        </>
      </SignupLayout>
    );
  }

  render() {
    return (
      <CarouselScreenWrapper submitting={this.loading}>
        {this.invitations && this.invitations.length > 0
          ? this.renderInvitation()
          : this.showCodeInput
          ? this.renderCodeInput()
          : this.renderSelectionList()}
      </CarouselScreenWrapper>
    );
  }
}

export default withStyles(styles)(LocationSelection);
