import React from 'react';
import { observable, action, computed, flow, makeObservable } from 'mobx';
import { observer } from 'mobx-react';
import { WithStyles, withStyles } from '@material-ui/core/styles';
import {
  Divider,
  Typography,
  Button,
  Box,
  List,
  CircularProgress,
  TextField,
} from '@material-ui/core';

import { inject, WithToastStore } from 'stores';
import Api, { getErrorMsg } from 'api';
import { Inventory, HardwareType } from 'models';

import InventoryItem from 'components/InventoryItem';
import Overlay from 'components/Overlay';

import styles from './styles';
import OutlinedInput from 'components/Input/OutlinedInput';

interface LinkDeviceProps extends WithStyles<typeof styles>, WithToastStore {
  deviceId: number;
  onComplete: () => void;
}

/**
 * The container for the inventory tab in the devices section.
 */
@inject('userStore', 'toastStore')
@observer
class LinkDevice extends React.Component<LinkDeviceProps> {
  constructor(props: LinkDeviceProps) {
    super(props);
    makeObservable(this);
  }
  /** The ref for the button */
  public linkButtonRef: React.RefObject<HTMLButtonElement> = React.createRef();

  /** The reader input ref */
  public readerRef: React.RefObject<HTMLInputElement> = React.createRef();

  /** The tablet input ref */
  public tabletRef: React.RefObject<HTMLInputElement> = React.createRef();

  /** Whether the linking process is in progress */
  @observable public linking = false;

  /** The reader serial input */
  @observable public readerSerial = '';

  /** The tablet serial input */
  @observable public tabletSerial = '';

  /** The reader inventory item */
  @observable public readerInventory: Inventory | null = null;

  /** The tablet inventory item */
  @observable public tabletInventory: Inventory | null = null;

  /** Whether we are currently fethcing inventory items */
  @observable public fetchingInventory = false;

  /** Clears the reader inventory item */
  @action.bound public clearReaderInventory() {
    this.readerInventory = null;
    this.readerSerial = '';
    this.focusReaderField();
  }

  /** Focuses the reader field */
  @action.bound public focusReaderField() {
    window.requestAnimationFrame(() => {
      this.readerRef.current && this.readerRef.current.focus();
    });
  }

  /** Handler for updating the reader serial */
  @action.bound public updateReaderSerial(e: React.ChangeEvent<HTMLInputElement>) {
    this.readerSerial = e.target.value;
  }

  /** Handler for when enter is pressed in the reader serial field */
  @action.bound public handleReaderSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    this.readerSubmit();
  }

  /** Submits the reader serial and fetches the corresponding inventory item */
  @action.bound public readerSubmit = flow(function* (this: LinkDevice) {
    try {
      // Fetch the inventory item
      this.fetchingInventory = true;
      const resp = yield Api.fulfillment.getInventoryBySerial(this.readerSerial);
      // If it's not a tablet, throw an error
      const tabletInventory: Inventory = resp.data.data;
      if (tabletInventory.hardware && tabletInventory.hardware.type !== HardwareType.READER) {
        throw new Error('Scanned item is not a reader');
      }
      // Set it as the current tablet
      this.readerInventory = resp.data.data;
      // Focus on the link button
      window.requestAnimationFrame(() => {
        this.linkButtonRef.current && this.linkButtonRef.current.focus();
      });
    } catch (e: any) {
      this.props.toastStore!.error(getErrorMsg(e));
      // Clear the current tablet serial
      this.readerSerial = '';
      // Focus it
      this.focusReaderField();
    } finally {
      // Clear the loading state
      this.fetchingInventory = false;
    }
  });

  /** Clears the tablet inventory item */
  @action.bound public clearTabletInventory() {
    this.tabletInventory = null;
    this.tabletSerial = '';
    this.focusTabletField();
  }

  /** Focuses the tablet field */
  @action.bound public focusTabletField() {
    window.requestAnimationFrame(() => {
      this.tabletRef.current && this.tabletRef.current.focus();
    });
  }

  /** Handler for updating the tablet serial */
  @action.bound public updateTabletSerial(e: React.ChangeEvent<HTMLInputElement>) {
    this.tabletSerial = e.target.value;
  }

  /** Handler for when enter is pressed in the tablet serial field */
  @action.bound public handleTabletSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    this.tabletSubmit();
  }

  /** Submits the tablet serial and fetches the corresponding inventory item */
  @action.bound public tabletSubmit = flow(function* (this: LinkDevice) {
    try {
      // Fetch the inventory item
      this.fetchingInventory = true;
      const resp = yield Api.fulfillment.getInventoryBySerial(this.tabletSerial);
      // If it's not a tablet, throw an error
      const tabletInventory: Inventory = resp.data.data;
      if (tabletInventory.hardware && tabletInventory.hardware.type !== HardwareType.TABLET) {
        throw new Error('Scanned item is not a tablet');
      }
      // Set it as the current tablet
      this.tabletInventory = resp.data.data;
      // Focus on the next field
      this.focusReaderField();
    } catch (e: any) {
      this.props.toastStore!.error(getErrorMsg(e));
      // Clear the current tablet serial
      this.tabletSerial = '';
      // Focus it
      this.focusTabletField();
    } finally {
      // Clear the loading state
      this.fetchingInventory = false;
    }
  });

  /** Finishes the linking process by submitting the reader and tablet data to the api */
  @action.bound public link = flow(function* (this: LinkDevice) {
    try {
      this.linking = true;
      if (!this.readerInventory && !this.tabletInventory) {
        throw new Error('Reader or tablet missing');
      }
      const deviceData: { readerId?: number; tabletId?: number } = {};
      if (this.readerInventory) {
        deviceData.readerId = this.readerInventory.id;
      }
      if (this.tabletInventory) {
        deviceData.tabletId = this.tabletInventory.id;
      }
      yield Api.core.linkKiosk(this.props.deviceId, deviceData);
      this.props.toastStore!.success('Device successfully linked!');
      this.props.onComplete();
    } finally {
      this.linking = false;
    }
  });

  /** Whether to show the list of inventory items */
  @computed public get showInventoryItems() {
    return Boolean(this.tabletInventory || this.readerInventory);
  }

  /** Whether the link button is disabled */
  @computed public get linkButtonDisabled() {
    return !this.tabletInventory && !this.readerInventory;
  }

  render() {
    const { classes } = this.props;
    return (
      <Box className={classes.root}>
        <Overlay display={this.linking}>
          <CircularProgress />
        </Overlay>
        <Box mb={2}>
          <Typography variant="h4" component="h1">
            Link Device
          </Typography>
          <Divider />
        </Box>
        <Box mb={2}>
          <Typography color="textSecondary">
            Link installed app with a tablet and card reader
          </Typography>
        </Box>
        <Box mb={2}>
          <form onSubmit={this.handleTabletSubmit}>
            <OutlinedInput
              inputRef={this.tabletRef}
              onChange={this.updateTabletSerial}
              autoFocus
              disabled={this.fetchingInventory || Boolean(this.tabletInventory)}
              fullWidth
              value={this.tabletSerial}
              label="Tablet serial number"
            />
            <Box display="none">
              <Button type="submit">submit</Button>
            </Box>
          </form>
        </Box>
        <Box mb={2}>
          <form onSubmit={this.handleReaderSubmit}>
            <TextField
              inputRef={this.readerRef}
              onChange={this.updateReaderSerial}
              fullWidth
              disabled={this.fetchingInventory || Boolean(this.readerInventory)}
              value={this.readerSerial}
              label="Reader serial number"
            />
            <Box display="none">
              <Button type="submit">submit</Button>
            </Box>
          </form>
        </Box>
        {this.showInventoryItems && (
          <Box mb={2}>
            <List>
              {this.tabletInventory && (
                <InventoryItem onDelete={this.clearTabletInventory}>
                  {this.tabletInventory}
                </InventoryItem>
              )}
              {this.readerInventory && (
                <InventoryItem onDelete={this.clearReaderInventory}>
                  {this.readerInventory}
                </InventoryItem>
              )}
            </List>
          </Box>
        )}
        <Button
          variant="contained"
          color="primary"
          disabled={this.linkButtonDisabled}
          fullWidth
          onClick={this.link}
          ref={this.linkButtonRef}>
          Link
        </Button>
      </Box>
    );
  }
}

export default withStyles(styles)(LinkDevice);
