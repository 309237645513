export enum EInvoiceItemType {
  NEW_ACCOUNT = 'new_account',
  ADDITIONAL_LOCATION = 'additional_location',
  OWNERSHIP_CHANGE = 'ownership_change',
  ADDITIONAL_EQUIPMENT = 'additional_equipment',
}

export const INVOICE_ITEM_TYPES: Record<string, string> = {
  [EInvoiceItemType.NEW_ACCOUNT]: 'New Account',
  [EInvoiceItemType.ADDITIONAL_LOCATION]: 'Additional Location',
  [EInvoiceItemType.OWNERSHIP_CHANGE]: 'Change of Ownership',
  [EInvoiceItemType.ADDITIONAL_EQUIPMENT]: 'Additional Equipment',
};
