/**
 * Regex to match a 5-digit ZIP code.
 * - Matches: 12345
 * - Does not match: 1234, 123456, abcde
 */
export const ZIP_CODE_REGEX = /^\d{0,5}$/;

/**
 * Regex to match a string containing only alphabetic characters (case insensitive).
 * - Matches: abc, ABC, AbcDe
 * - Does not match: abc123, abc!, 123
 */
export const ADDRESS_REGEX = /[^a-zA-Z0-9 , ]/g;

/**
 * Regex to match a numeric string without leading zeros (for ZIP codes where leading zero is not allowed).
 * - Matches: 12345, 67890
 * - Does not match: 01234, 00000
 */
export const NON_ZERO_NUMERIC_REGEX = /^(?!0{5})\d{5}$/;

/**
 * Check if its numbers.
 */

export const CHECK_NUM_REGEX = /[^0-9]/g;

/**
 * Only for city - aplhabetic without numbers
 */

export const CITY_REGEX = /[^a-zA-Z]'/g;

/**
 * This file contains various validation functions used in the dashboard
 */

/** The US phone regex */
export const US_PHONE_NUMBER_PATTERN =
  /^((\+1|1)?( |-)?)?(\([2-9][0-9]{2}\)|[2-9][0-9]{2})( |-)?([2-9][0-9]{2}( |-)?[0-9]{4})$/;

/** The SI phone regex */
export const SI_PHONE_NUMBER_PATTERN = /^\+386\s?([1-9][0-9])\s?([0-9]{3})\s?([0-9]{3})$/;

/** The US zip code regex */
export const ZIP_REGEX = /^[0-9]{5}(?:-[0-9]{4})?$/;

/** The US currency regex */
export const US_CURRENCY_REGEX = /^[+-]?[0-9]{1,9}(?:\.[0-9]{1,2})?$/;

/** Email regex */
export const EMAIL_REGEX = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
