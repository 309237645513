import { Theme, createStyles } from '@material-ui/core';

export default (theme: Theme) =>
  createStyles({
    container: {
      maxWidth: 600,
    },
    halfWidthLeft: {
      width: '47.5%',
    },
    halfWidthRight: {
      width: '47.5%',
      marginLeft: '5%',
    },
    fullWidth: {
      width: '100%',
    },
    titleColor: {
      color: '#f79a95',
    },
  });
