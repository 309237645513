import { IMenuGroup, IMenuItem, MenuItemType, MenuItems } from 'models/Menu';
import menuItems, { IPathProps } from './items';
import { isValueInArray } from 'utils/helper';
import { ScopeType, UserScopes } from 'stores';
import { rootStore } from 'containers/App/App';
import { IFeatureFlags } from 'models';

export const filterMenuItemsByScope = (
  currentScope: ScopeType,
  pathProps: IPathProps,
  flags?: IFeatureFlags,
) => {
  const _items = menuItems({ ...pathProps, ...flags });

  const scopedItems = _items.reduce<MenuItems>((newItems, item): MenuItems => {
    if (item.type === MenuItemType.GROUP) {
      if (item.scopes && !showMenuGroup(item, currentScope)) {
        return newItems;
      }
      const { items } = item;
      const filteredItems = items.filter((item) => showItem(item, currentScope));
      if (!filteredItems.length) return newItems;
      newItems.push({ ...item, items: filteredItems });
      return newItems;
    }
    if (showItem(item, currentScope)) {
      newItems.push(item);
    }
    return newItems;
  }, []);
  return scopedItems;
};

const showItem = (item: IMenuItem, currentScope: ScopeType) => {
  if (item.hide) return false;
  /**
   * hasPermissions needs to be checked first.
   * If user does not have permission to view the item, we do not check other conditions.
   */
  return (
    hasPermission(item, currentScope) && (!item.scopes || isValueInArray(item.scopes, currentScope))
  );
};

const showMenuGroup = (group: IMenuGroup, currentScope: ScopeType) => {
  if (group.hide) return false;
  return !group.scopes || isValueInArray(group.scopes, currentScope);
};

/**
 * Check if user has any permissions listed in roles array.
 * @param item Menu item
 * @returns true if user has any permissions or if role array does not exist / is empty, otherwise false
 */
const hasPermission = (item: IMenuItem, currentScope: ScopeType) => {
  if (currentScope === UserScopes.ADMIN) {
    if (!item?.roles?.length) return true;
    return item.roles.some((permission) => rootStore.userStore.hasPermission(permission));
  }
  if (currentScope === UserScopes.MANAGER) {
    if (!item?.permissions?.length) return true;
    return item.permissions.some((permission) =>
      rootStore.managerPermissionsStore.hasPermission(permission),
    );
  }
  return true;
};
