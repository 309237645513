import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { IntegrationApp } from 'models/Integration';
import useStyles from './styles';

interface Description {
  active?: boolean;
  title?: string;
  body?: IntegrationApp['description'] | IntegrationApp['instructions'];
}

function Description(props: Description) {
  const { active, title, body } = props;
  const { statusBox } = useStyles({ active });

  // if (!props.body) {
  //   return <></>;
  // }

  const status = active ? 'INSTALLED' : 'AVAILABLE';
  return (
    <>
      {title && <Typography variant={'body1'}>{props.title}</Typography>}
      <Typography variant={'body2'}>
        {body && <Box component={'span'}>{props.body}</Box>}
        <Box component={'span'} className={statusBox} ml={!body ? 0 : 2}>
          {status}
        </Box>
      </Typography>
    </>
  );
}

export default Description;
