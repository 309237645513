import { useStores } from 'containers/App/App';

const useCompanyCode = () => {
  const { uiStore, userStore } = useStores();
  const isOwner = userStore?.scope.kind === 'owner';
  const accountCode = userStore?.currentAccount?.code;
  const trigger = uiStore?.appBar.trigger;
  const mobileView = uiStore?.mobileView;

  return { isOwner, accountCode, trigger, mobileView };
};

export default useCompanyCode;
