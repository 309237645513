import React from 'react';
import { observer } from 'mobx-react';
import { WithStyles, withStyles } from '@material-ui/core/styles';

import { inject, WithUserStore } from 'stores';

import DashboardLayout from 'containers/DashboardLayout';
import ConversionsTable from 'components/ConversionsTable';
import styles from './styles';
import { setTitle } from 'services';
import { RouteComponentProps } from 'react-router-dom';
import Title from 'components/Title/Title';

const PAGE_TITLE = 'Conversions';
/** Define props for this component */
type ConversionsProps = WithStyles<typeof styles> & WithUserStore & RouteComponentProps;

/**
 * The Conversions container, restricted to admin users.
 */
@inject('userStore')
@observer
class Conversions extends React.Component<ConversionsProps> {
  public constructor(props: ConversionsProps) {
    super(props);
  }
  componentDidMount() {
    setTitle(PAGE_TITLE, { noSuffix: false });
  }
  render() {
    return (
      <DashboardLayout>
        <Title mb={3}>{PAGE_TITLE}</Title>
        <ConversionsTable {...this.props} />
      </DashboardLayout>
    );
  }
}

export default withStyles(styles)(Conversions);
