import { WithStyles, withStyles } from '@material-ui/core/styles';
import DP from 'components/DashPanel';
import { autorun, IReactionDisposer, observable, toJS, makeObservable, computed } from 'mobx';
import { inject, observer } from 'mobx-react';
import React from 'react';
import * as models from 'models';

import styles from './styles';
import { Link as RouterLink } from 'react-router-dom';
import { Box, Link } from '@material-ui/core';
import { paths } from 'routes';
import { Qrcode } from 'mdi-material-ui';
import { Account, Industry, Location } from 'models';
import config from 'config';
import { WithToastStore } from 'stores';
import { faPaste, faQrcode } from '@fortawesome/pro-regular-svg-icons';

interface LocationGeneralPanelProps extends WithStyles<typeof styles>, WithToastStore {
  // children?: models.Location;
  title?: string;
  account?: Account;
  location?: Location;
  code: string;
  type: 'account' | 'location' | 'talent' | 'pool';
  disabled: boolean;
  industry?: Industry;
  fullHeight?: boolean;
}

/**
 * Displays a general location; code, kiosk link, account Name.
 * @param title Optional title, `Details` by default
 * @param account Optional account to display
 * @param location Optional location to display
 * @param code Optional code to display
 * @param type Optional type of location, `account` by default
 * @param disabled Optional flag to disable all buttons
 * @param industry Optional industry to display
 */
@inject('toastStore', 'userStore', 'modalStore')
@observer
class LocationGeneralPanel extends React.Component<LocationGeneralPanelProps> {
  static defaultProps = {
    title: 'General',
  };
  constructor(props: LocationGeneralPanelProps) {
    super(props);
    makeObservable(this);
    // Set up an autorun so that when the account/location in the props changes, we copy it
    // to this component's state. This achieves the same thing as implementing a
    // componentDidUpdate and seeing if the account prop has changed.
    // We push the autorun onto a disposers array so that we can dispose of all
    // of them before the component unmounts.
    this.disposers.push(autorun(() => (this.location = toJS(this.props.location))));
    this.disposers.push(autorun(() => (this.account = toJS(this.props.account))));
  }

  /**
   * It's good practice to dispose of any autoruns that we set up during
   */
  private disposers: IReactionDisposer[] = [];

  /** The account object is copied to this property */
  @observable private location?: models.Location = this.props.location;
  @observable private account?: models.Account = this.props.account;

  @computed get link(): string {
    const code = this.props.code;

    let typeAnnotation = '';
    if (this.props.type === 'account') typeAnnotation = 'A';
    if (this.props.type === 'location') typeAnnotation = 'L';
    if (this.props.type === 'talent') typeAnnotation = 'T';
    if (this.props.type === 'pool') typeAnnotation = 'P';
    const fullCode = `${typeAnnotation}${code}`;
    return `${config.tippyGo.baseUrl}/${fullCode}`;
  }

  // componentDidMount() {
  //   if (this.props.type === 'pool') {
  //     window.print();
  //   }
  // }

  handleCopyClick = () => {
    // @ts-ignore
    if (window.clipboardData && window.clipboardData.setData) {
      // @ts-ignore
      return window.clipboardData.setData('Text', this.link);
    } else if (document.queryCommandSupported && document.queryCommandSupported('copy')) {
      const textarea = document.createElement('textarea');
      textarea.textContent = this.link;
      textarea.style.position = 'fixed';
      document.body.appendChild(textarea);
      textarea.select();
      try {
        document.execCommand('copy');
        this.props.toastStore!.push({ type: 'info', message: 'Link copied!' });
      } catch (ex) {
        this.props.toastStore!.push({ type: 'error', message: 'Copying failed!' });
        alert('Copying failed!');
        return false;
      } finally {
        document.body.removeChild(textarea);
      }
    }
  };

  render() {
    const { title, classes } = this.props;
    // If the account/location is undefined, that means it's still loading, so we display a loading skeleton.
    if (!this.location && !this.account) {
      return (
        <DP>
          <DP.Header>
            <DP.Title>{title}</DP.Title>
          </DP.Header>
          <DP.Body>
            <DP.Loading items={4} />
          </DP.Body>
        </DP>
      );
    }
    return (
      <DP fullHeight={this.props.fullHeight}>
        <DP.Header>
          <DP.Title panel>{title}</DP.Title>
        </DP.Header>
        <DP.Body>
          <DP.Row>
            <DP.Value>
              <Box className={classes.textPurple}>{this.location?.code}</Box>
            </DP.Value>
            <DP.Label>Location Code</DP.Label>
          </DP.Row>
          <DP.Row>
            <DP.Value>
              <Box className={classes.textCapitalize}>
                {this.account ? (
                  <Link component={RouterLink} to={paths.accountDetails(this.account?.id).info()}>
                    {this.account?.name}
                  </Link>
                ) : null}
              </Box>
            </DP.Value>
            <DP.Label>Account</DP.Label>
          </DP.Row>
          <DP.Row>
            <DP.Value>
              <Box display="flex" alignItems="center">
                <DP.IconButton
                  primary
                  onClick={this.handleCopyClick}
                  fontAwesomeIcon={{ icon: faPaste }}
                  tooltip="Copy Kiosk Link"
                />
                {!this.props.disabled ? (
                  <Link
                    component={RouterLink}
                    to={`${paths.QRCodeForPrint()}?type=${this.props.type}&code=${
                      this.props?.code
                    }&industry=${this.props.industry}`}>
                    <DP.IconButton
                      primary
                      fontAwesomeIcon={{ icon: faQrcode }}
                      tooltip="Kiosk QR Code"
                    />
                  </Link>
                ) : (
                  <Qrcode fontSize="small" />
                )}
              </Box>
            </DP.Value>
            <DP.Label>Kiosk Link</DP.Label>
          </DP.Row>
        </DP.Body>
      </DP>
    );
  }
}

export default withStyles(styles)(LocationGeneralPanel);
