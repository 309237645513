import React from 'react';
import { observer } from 'mobx-react';
import { WithStyles, withStyles } from '@material-ui/core/styles';
import { RouteComponentProps } from 'react-router-dom';
import { inject, WithUserStore } from 'stores';

import styles from './styles';
import DashboardLayout from 'containers/DashboardLayout';
import Tips from 'containers/UserDetails/Tips';

type UserDetailsProps = WithStyles<typeof styles> & WithUserStore & RouteComponentProps;

/** Displays a user's details to admins, owners or managers */
@inject('userStore')
@observer
class TipsContainer extends React.Component<UserDetailsProps> {
  render() {
    const userId = this.props.userStore!.user!.id;
    return (
      <DashboardLayout>
        <Tips userId={userId} customerShortName={true} {...this.props}/>
      </DashboardLayout>
    );
  }
}

export default withStyles(styles)(TipsContainer);
