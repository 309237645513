import { useStores } from 'containers/App/App';
import { getErrorMsg } from 'api';
import { Pool } from 'models/Pool';
import { BlPools } from '../../containers/LocationDetails/QrCodes/blPools';
import { AddStation } from 'models/Station';
import { useStationNavigation } from 'containers/LocationDetails/QrCodes/useStationNavigation';

type useStationDrawerReturn = [
  boolean,
  AddStation | undefined,
  (pool: Pool) => Promise<void>,
  (poolId: number, pool: Pool) => Promise<void>,
  (state?: AddStation | undefined) => void,
  (locationId: number) => void,
  (accountId: number) => void,
];

export function useStationDrawer(): useStationDrawerReturn {
  const { toastStore, stationsStore } = useStores();
  const { stationNavigation } = useStationNavigation();
  const {
    accountId,
    locationId,
    openAddStation,
    addStationState,
    setAddStationState,
    setLocationId,
    setAccountId,
  } = stationsStore!;
  async function createPool(pool: any) {
    try {
      const createdPool = {
        accountId: accountId,
        locationId: locationId,
        ...pool,
      };
      await BlPools.createPool(createdPool);
      toastStore!.success('Pool created!');
      goBack();
    } catch (e: any) {
      toastStore!.error(getErrorMsg(e));
    }
  }

  async function updatePool(poolId: number, pool: Pool) {
    try {
      await BlPools.getPools(accountId!, locationId!);
    } catch (e: any) {
      toastStore!.error(getErrorMsg(e));
    }
  }

  function goBack() {
    if (stationsStore!.addStationState === AddStation.GROUP_POOL) {
      stationNavigation.openGroupPools();
    } else {
      stationNavigation.openPools();
    }
  }

  return [
    openAddStation,
    addStationState,
    createPool,
    updatePool,
    goBack,
    setLocationId,
    setAccountId,
  ];
}
