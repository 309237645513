/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { observable, action, flow, makeObservable } from 'mobx';
import { RouteComponentProps } from 'react-router-dom';
import { observer } from 'mobx-react';
import { WithStyles, withStyles } from '@material-ui/core/styles';
import { Grid, Box, Paper, TextField } from '@material-ui/core';
import validatorjs from 'validatorjs';

import Api, { getErrorMsg } from 'api';
import { inject, WithUserStore, WithToastStore } from 'stores';
import { isPhone } from 'services/validators';
import { setTitle } from 'services/title';

import PhoneInput from 'components/form/PhoneInput';

import GC from './GraphicContainer';
import { ReactComponent as OwnerImg } from './owner.svg';
import SignupStore, { SignupStep } from './SignupStore';
import SubmitButton from './SubmitButton';

import styles from './styles';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const dvr = require('mobx-react-form/lib/validators/DVR');
const MobxReactForm = require('mobx-react-form').default;

interface PersonalInfoFormHooks {
  onSuccess: (form: any) => void;
  onClear: (form: any) => void;
  onError: (form: any) => void;
}

/** Here we define what kind of props this component takes */
interface PersonalInfoProps
  extends WithStyles<typeof styles>, // Adds the classes prop
    RouteComponentProps,
    WithUserStore, // Adds the userStore prop
    WithToastStore {
  signupStore: SignupStore;
  noPaper?: boolean;
  nextRoute: () => string;
}

const plugins = {
  dvr: dvr({
    package: validatorjs,
    extend: ({ validator }: { validator: any; form: any }) => {
      /* Add custom rule for validating US phone numbers */
      const phoneFieldRule = {
        function: isPhone,
        message: 'The phone number is invalid.',
      };
      validator.register('phone', phoneFieldRule.function, phoneFieldRule.message);
    },
  }),
};

/**
 * The owner screen of the account signup for admins
 */
@inject('userStore', 'toastStore')
@observer
class PersonalInfo extends React.Component<PersonalInfoProps> {
  toastStore = this.props.toastStore!;
  userStore = this.props.userStore!;
  public constructor(props: PersonalInfoProps) {
    super(props);
    makeObservable(this);
    const user = props.userStore!.user!;
    const fields = [
      {
        name: 'firstName',
        label: 'First Name',
        rules: 'required|min:2',
        value: user.firstName,
      },
      {
        name: 'lastName',
        label: 'Last Name',
        rules: 'required|min:2',
        value: user.lastName,
      },
      {
        name: 'phone',
        label: 'Phone',
        type: 'tel',
        rules: 'required|string|phone',
        value: user.phone,
        extra: {
          editable: false,
        },
      },
    ];
    this.form = new MobxReactForm({ fields }, { plugins, hooks: this.hooks });
  }
  /** Whether we are currently submitting */
  @observable public submitting = false;
  /** Submits the form, updating the user data */
  @action.bound public submit = flow(function* (this: PersonalInfo) {
    const user = this.userStore.user!;
    const firstName = this.form.$('firstName').value;
    const lastName = this.form.$('lastName').value;
    const phone = this.form.$('phone').value;
    try {
      this.submitting = true;
      // Update the user data on the back end
      yield Api.core.updateUser(user.id, {
        firstName,
        lastName,
        phone,
      });
      // Update the user object in the userStore with the
      // entered values
      user.firstName = firstName;
      user.lastName = lastName;
      user.phone = phone;
      // Set the owner in the signup store
      this.props.signupStore.setOwner(user);
      // Go to the next step
      this.props.history.push(this.props.nextRoute());
    } catch (e: any) {
      this.toastStore.error(getErrorMsg(e));
    } finally {
      this.submitting = false;
    }
  });

  private hooks: PersonalInfoFormHooks = {
    onSuccess: () => {
      this.submit();
    },
    onClear: (form: any) => {
      form.clear();
    },
    onError: () => {},
  };
  @observable private form: any;

  componentDidMount() {
    setTitle(`Personal Info`);
    this.props.signupStore!.setStep(SignupStep.Owner);
  }

  render() {
    const { classes, noPaper } = this.props;
    const Wrap = noPaper ? React.Fragment : Paper;
    return (
      <Wrap>
        <Box p={6}>
          <Grid container spacing={6}>
            <Grid item xs={12} md={6}>
              <GC>
                <GC.Title>{`Tell us more about yourself`}</GC.Title>
                <GC.SvgImage>
                  <OwnerImg />
                </GC.SvgImage>
              </GC>
            </Grid>
            <Grid item xs={12} md={6}>
              <form onSubmit={this.form.onSubmit} className={classes.fullHeight}>
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="space-between"
                  height="100%">
                  <Box>
                    <Box pb={4}>
                      <TextField
                        {...this.form.$('firstName').bind()}
                        error={Boolean(this.form.$('firstName').error)}
                        helperText={this.form.$('firstName').error}
                        autoFocus
                        fullWidth
                      />
                    </Box>
                    <Box pb={4}>
                      <TextField
                        {...this.form.$('lastName').bind()}
                        error={Boolean(this.form.$('lastName').error)}
                        helperText={this.form.$('lastName').error}
                        fullWidth
                      />
                    </Box>
                    <Box pb={4}>
                      <TextField
                        label="Phone"
                        {...this.form.$('phone').bind()}
                        error={this.form.$('phone').error}
                        helperText={this.form.$('phone').error}
                        fullWidth
                        InputProps={{ inputComponent: PhoneInput }}
                      />
                    </Box>
                  </Box>
                  <Box>
                    <SubmitButton inProgress={this.submitting}>Next</SubmitButton>
                  </Box>
                </Box>
              </form>
            </Grid>
          </Grid>
        </Box>
      </Wrap>
    );
  }
}

export default withStyles(styles)(PersonalInfo);
