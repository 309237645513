import {
  Box,
  ButtonBase,
  Paper,
  Typography,
  List,
  ListItem,
  ListItemText,
} from '@material-ui/core';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { withStyles } from '@material-ui/core/styles';
import React, { memo, useState } from 'react';
import styles from './styles';
import { v4 as uuidv4 } from 'uuid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faCheck } from '@fortawesome/pro-regular-svg-icons';

import { optionSearchFilter } from 'types';
import Divider from '@mui/material/Divider';

export interface OptionMenu {
  label: string;
  value: optionSearchFilter;
}

interface Props {
  onChange: (option: optionSearchFilter) => void;
  position?: 'left' | 'right';
  classes: any;
}

const StringSearchPicker = ({ onChange, position = 'left', classes }: Props) => {
  const listOptions: OptionMenu[] = [
    { label: 'By Name', value: optionSearchFilter.NAME },
    { label: 'By Email', value: optionSearchFilter.EMAIL },
    { label: 'By Phone', value: optionSearchFilter.PHONE },
  ];

  const [dropdownOpened, setDropdownOpened] = React.useState(false);
  const [searchOption, setSearchOption] = useState<OptionMenu>({
    label: listOptions[0].label,
    value: listOptions[0].value,
  });

  const handleSetOptionValue = (index: number) => {
    setSearchOption(listOptions[index]);
    onChange(listOptions[index].value);
    handleOpened(false);
  };

  const handleOpened = (status: boolean) => {
    setDropdownOpened(status);
  };

  const handleClickOutside = (e: React.ChangeEvent<EventTarget>) => {
    const clickedElement = e.target as HTMLInputElement;
    const datePickerDialog = clickedElement.closest('.MuiDialog-root');
    if (!datePickerDialog) handleOpened(false);
  };

  const renderOptions = () => {
    return (
      <List>
        {listOptions.map(({ label, value }: OptionMenu, index) => (
          <>
            <Box key={uuidv4()} display={'flex'}>
              <ListItem button onClick={() => handleSetOptionValue(index)}>
                <ListItemText primary={label} />
                {searchOption.value === value && (
                  <FontAwesomeIcon icon={faCheck} className={classes.icon} />
                )}
              </ListItem>
            </Box>
            {listOptions.length - 1 !== index && (
              <Divider orientation="horizontal" className={classes.divider} />
            )}
          </>
        ))}
      </List>
    );
  };

  const { root, dateDisplay, dropdownLeft, dropdownRight, selectedLabel } = classes;
  const dropdown = {
    left: dropdownLeft,
    right: dropdownRight,
  };

  return (
    <ClickAwayListener onClickAway={(e) => handleClickOutside(e)}>
      <Box className={root}>
        <Box>
          <ButtonBase
            disableRipple
            disableTouchRipple
            className={dateDisplay}
            onClick={() => handleOpened(!dropdownOpened)}>
            <Typography className={selectedLabel}>{searchOption.label}</Typography>
            <FontAwesomeIcon icon={faCaretDown} className={classes.icon} />
          </ButtonBase>
        </Box>
        {dropdownOpened && <Paper className={dropdown[position]}>{renderOptions()}</Paper>}
      </Box>
    </ClickAwayListener>
  );
};

export default withStyles(styles)(memo(StringSearchPicker));
