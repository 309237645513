import { makeStyles, createStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      minHeight: '100dvh',
      width: '100dvw',
      display: 'flex',
      backgroundColor: theme.palette.common.white,
    },
    content: {
      width: '50%',
      minHeight: '100%',
      display: 'flex',
      justifyContent: 'center',
      [theme.breakpoints.down('md')]: {
        width: '100%',
      },
    },
    carousel: {
      minHeight: '100%',
      width: '50%',
      '& div': {
        height: '100%',
      },
    },
    ratingsCardWrapper: {
      height: 'auto !important',
      width: '100%',
    },
  }),
);

export default useStyles;
