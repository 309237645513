import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import theme from 'containers/App/theme';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    endIconStyles: {
      color: theme.palette.text.disabled,
    },
    darkIcon: {
      color: theme.palette.text.primary,
    },
  }),
);

export const styles = {
  listItemStyles: {
    border: `1px solid ${theme.palette.text.disabled}`,
    borderRadius: 4,
    height: 56,
    cursor: 'pointer',
  },
  startIconStyles: {
    width: 21,
    maxWidth: 21,
    minWidth: 21,
    marginRight: 2,
    textAlign: 'center',
    color: theme.palette.text.primary,
  },
  endIconStyles: {
    fontSize: 18,
    color: theme.palette.text.disabled,
  },
  secondaryActionStyles: {
    display: 'flex',
    alignItems: 'center',
  },
  listItemTextStyles: {
    margin: 0,
    '& .MuiTypography-root': {
      height: 20,
      ...theme.typography.body1,
      display: 'inline-block',
    },
  },
};

export default useStyles;
