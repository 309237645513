import React from 'react';
import { Redirect, RouteComponentProps } from 'react-router-dom';
import { observable, action, makeObservable } from 'mobx';
import { observer } from 'mobx-react';
import { WithStyles, withStyles } from '@material-ui/core/styles';
import styles from './styles';
import LoginForm from './LoginForm';
import CarouselScreenWrapper from 'components/CarouselScreenWrapper/CarouselScreenWrapper';
import { rootStore } from 'containers/App/App';
import MFAForm from 'components/MFAForm/MFAForm';
import { paths } from 'routes';

export interface LocationState {
  from: Location;
}

type LoginProps = WithStyles<typeof styles> & RouteComponentProps;

@observer
class Login extends React.Component<LoginProps> {
  constructor(props: LoginProps) {
    super(props);
    makeObservable(this);
  }

  /** Whether we're currently logging in */
  @observable public submitting = false;

  @action.bound public handleSetSubmit = (status: boolean) => {
    this.submitting = status;
  };

  render() {
    const showMfaStep = rootStore.mfaStore.showMfaStep;
    const { user } = rootStore.userStore;
    const redirectToPhoneNumber = user && !user.isPhoneConfirmed;
    if (redirectToPhoneNumber) {
      return (
        <Redirect
          to={{
            pathname: paths.signUp().phoneNumber(),
            state: { redirectLogin: true },
          }}
        />
      );
    }
    return (
      <CarouselScreenWrapper submitting={this.submitting}>
        {showMfaStep ? (
          <MFAForm title={`Let's Confirm it's You`} continueButton={'Confirm'} />
        ) : (
          <LoginForm {...this.props} onStatusSubmit={this.handleSetSubmit} />
        )}
      </CarouselScreenWrapper>
    );
  }
}

export default withStyles(styles)(Login);
