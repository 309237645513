import { useStores } from 'containers/App/App';
import { useState } from 'react';
import { copyTextToClipboard } from 'services/clipboard';

type UseAccountToken = [
  boolean,
  () => void,
  (accountToken: string | undefined) => string | undefined,
  (accountToken: string | undefined) => void,
];

function useAccountToken(): UseAccountToken {
  const [maskToken, setMaskToken] = useState(true);
  const { toastStore } = useStores();

  function toggleTokenMask() {
    setMaskToken(!maskToken);
  }

  function getMaskedToken(accountToken: string | undefined) {
    if (accountToken) {
      const token = accountToken;
      const firstFour = token.substring(0, 4);
      const mask = new Array(token.length - 3).join('*');
      return `${firstFour}${mask}`;
    }
    return accountToken;
  }

  function copy(accountToken: string | undefined) {
    if (accountToken) {
      if (copyTextToClipboard(accountToken)) {
        toastStore!.success('Token copied to clipboard');
      } else {
        toastStore!.error('Copying failed!');
      }
    }
  }

  return [maskToken, toggleTokenMask, getMaskedToken, copy];
}

export default useAccountToken;
