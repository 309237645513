import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { observer } from 'mobx-react';
import { Route, Switch } from 'react-router-dom';
import { inject, WithUserStore } from 'stores';
import { paths } from 'routes';

import Layout from './Layout';

import SignupStore from '../SignupStore';
import PersonalInfo from '../PersonalInfo';
import PromoCodes from './PromoCodes';

import AccountStep from '../AccountStep';
import LocationsStep from '../LocationsStep';


/** Here we define what kind of props this component takes */
type SelfSignupProps = RouteComponentProps<{ accountId?: string }> & // Adds the router props (history, match, location)
  WithUserStore; // Adds the userStore prop

export enum SignupStep {
  Owner,
  Account,
  Locations,
  Coupons,
  Review,
}

/**
 * The base container for the self-signup process. It's similar to the
 * AcocuntSignup.tsx file, as it orchestrates the components for each
 * step and defines the routes. For some steps, the components from
 * AccountSignup are used, and for some steps, new ones are defined.
 */
@inject('userStore')
@observer
class SelfSignup extends React.Component<SelfSignupProps> {
  constructor(props: SelfSignupProps) {
   super(props)
  }
  public signupStore = new SignupStore(this.props.userStore!.rootStore, { selfSignup: true });
  componentDidMount() {
    this.signupStore.init();
  }
  render() {
    const signupPath = paths.register();
    return (
      <Layout>
        <Switch>
          <Route
            path={signupPath.personalInfo()}
            exact
            render={(props: any) => (
              <PersonalInfo
                {...props}
                signupStore={this.signupStore}
                nextRoute={signupPath.companyInfo}
              />
            )}></Route>
          <Route
            path={signupPath.companyInfo()}
            exact
            render={(props: any) => (
              <AccountStep
                {...props}
                signupStore={this.signupStore}
                nextRoute={signupPath.locations}
                addOwner
              />
            )}
          />
          <Route
            path={signupPath.locations(':accountId')}
            exact
            render={(props: any) => (
              <LocationsStep
                {...props}
                signupStore={this.signupStore}
                nextRoute={signupPath.promotions}
              />
            )}
          />
          <Route
            path={signupPath.promotions(':accountId')}
            exact
            render={(props: any) => <PromoCodes {...props} signupStore={this.signupStore} />}
          />
        </Switch>
      </Layout>
    );
  }
}

export default SelfSignup;
