import { useStores } from 'containers/App/App';
import { useEffect, useState } from 'react';
import { valueOrNA } from 'utils/helper';
import createNewForm from './helpers';

interface IEmailIndicatorProps {
  color: 'green' | 'red';
  tooltip: string;
}

const useEmailPanel = () => {
  const [open, setOpen] = useState(false);
  const [form, setForm] = useState<any>(createNewForm);
  const { userStore } = useStores();
  const { user } = userStore;
  const emailConfirmed = user?.isEmailConfirmed;

  useEffect(() => {
    if (form) {
      form.closeEmailDialog = handleClose;
    }
  }, [form]);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
    setForm(createNewForm());
  };

  const onExited = () => {
    setForm(null);
  };

  const indicatorProps = ((): IEmailIndicatorProps => {
    return {
      color: emailConfirmed ? 'green' : 'red',
      tooltip: emailConfirmed ? 'Email confirmed' : 'Email unconfirmed',
    };
  })();

  const email = valueOrNA(user?.email);
  const emailField = form?.$('email');
  const error = emailField?.error;
  const submit = form?.onSubmit;
  const submitting = !!form?.submitting;

  return {
    email,
    indicatorProps,
    open,
    submit,
    submitting,
    emailField,
    error,
    form,
    handleOpen,
    handleClose,
    onExited,
  };
};

export default useEmailPanel;
