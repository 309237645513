import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { observable, action, flow, makeObservable, computed } from 'mobx';
import { observer } from 'mobx-react';
import { WithStyles, withStyles } from '@material-ui/core/styles';
import { Box, Typography } from '@material-ui/core';

import { paths } from 'routes';
import Api from 'api';

import styles from './styles';
import CarouselScreenWrapper from 'components/CarouselScreenWrapper/CarouselScreenWrapper';
import OutlinedInput from 'components/Input/OutlinedInput';
import SignupLayout, {
  TSignupLayoutAction,
  TSignupLayoutSubAction,
} from 'containers/SignupLayout/SignupLayout';
import theme from 'containers/App/theme';
import CircularIconFrame from 'components/CircularIconFrame/CircularIconFrame';
import { faCheck } from '@fortawesome/pro-regular-svg-icons';
import qs from 'qs';

type ForgotPasswordProps = WithStyles<typeof styles> & RouteComponentProps;

const formId = 'reset-password-email-form';

/**
 * The login screen container component.
 */
@observer
class ForgotPassword extends React.Component<ForgotPasswordProps> {
  constructor(props: ForgotPasswordProps) {
    super(props);
    makeObservable(this);

    const email = qs.parse(this.props.location.search, { ignoreQueryPrefix: true })?.email as
      | string
      | undefined;
    this.email = email || '';
  }
  /** The email input value */
  @observable public email = '';

  /** Whether the forgot password API call is in progress */
  @observable public inProgress = false;

  /** Whether the passowrd API call is done */
  @observable public done = false;

  /** The current error */
  @observable public error = '';

  /**
   * Handles the email change from an input component
   * @param e The onChange event
   */
  @action.bound public handleEmailChange(e: React.ChangeEvent<HTMLInputElement>) {
    this.email = e.target.value;
  }

  /**
   * Handles the main form submission.
   * @param e The form submitted event
   */
  @action.bound public handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    this.postForgotPassword();
  }

  @action.bound public postForgotPassword = flow(function* (this: ForgotPassword) {
    try {
      this.error = '';
      this.inProgress = true;
      yield Api.core.resetPasswordV3(this.email);
      this.done = true;
    } catch (err: any) {
      const errMsg = err?.response?.data?.error?.message || 'An error has occurred.';
      this.error = errMsg;
    } finally {
      this.inProgress = false;
    }
  });

  @computed public get signupLayoutProps() {
    const toSignIn = () => this.props.history.push(paths.signIn());
    const title = this.done ? 'Check your Email' : 'Reset Password';
    const action: TSignupLayoutAction = this.done
      ? { onClick: toSignIn, children: 'Sign In' }
      : { type: 'submit', form: formId, children: 'Send me a link' };
    const subAction: TSignupLayoutSubAction = this.done
      ? {
          back: {
            label: 'Back to reset password',
            onClick: () => (this.done = false),
          },
        }
      : {
          back: {
            label: 'Back to sign in',
            onClick: toSignIn,
          },
        };
    return {
      title,
      action,
      subAction,
    };
  }

  renderForgotPasswordView() {
    return (
      <Box display={'flex'} flexDirection={'column'} gridGap={theme.spacing(4)}>
        <Typography variant="body2" align="center">
          Enter your email address and we&apos;ll send you a link to reset your password.
        </Typography>
        <form id={formId} onSubmit={this.handleSubmit}>
          <OutlinedInput
            label={'Email'}
            autoFocus
            required
            error={this.error.length > 0}
            type="email"
            id="email-input"
            value={this.email}
            onChange={this.handleEmailChange}
            fullWidth
          />
        </form>
      </Box>
    );
  }

  renderSuccessView() {
    return (
      <Box display={'flex'} flexDirection={'column'} gridGap={theme.spacing(8)}>
        <CircularIconFrame iconHeight={77} icon={faCheck} />
        <Typography variant="body2" align="center">
          An email has been sent to{' '}
          <Typography component={'span'} color="primary">
            {this.email}
          </Typography>{' '}
          with a link to reset your password. If you didn't receive the email, try sending the link
          again.
        </Typography>
      </Box>
    );
  }

  render() {
    return (
      <CarouselScreenWrapper>
        <SignupLayout {...this.signupLayoutProps}>
          {this.done ? this.renderSuccessView() : this.renderForgotPasswordView()}
        </SignupLayout>
      </CarouselScreenWrapper>
    );
  }
}

export default withStyles(styles)(ForgotPassword);
