import { Theme, createStyles } from '@material-ui/core';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default (theme: Theme) =>
  createStyles({
    root: {},
    reportCard: {
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'start',
      height: '100%',
      width: '100%',
    },
    reportIcon: {
      width: theme.spacing(12),
    },
    payrollRunDialog: {
      overflow: 'visible',
      '& .MuiDialog-root': {
        overflowY: 'visible',
      },
      '& .MuiPaper-root': {
        overflowY: 'visible',
      },
      '& .MuiDialogContent-root': {
        overflowY: 'visible',
      },
      '& .MuiDialog-container': {
        overflowY: 'auto',
      },
    },
    dateRangeField: {
      maxWidth: theme.spacing(31),
    },
    address: {
      display: 'block',
      opacity: '50%',
      fontSize: '0.8em',
    },
    button: {
      height: 32,
      paddingLeft: theme.spacing(1.5),
      paddingRight: theme.spacing(1.5),
      paddingTop: theme.spacing(0.75),
      paddingBottom: theme.spacing(0.75),
      textTransform: 'none',
      borderRadius: 8,
      fontWeight: 500,
      fontSize: 14,

      '&:hover': {
        backgroundColor: theme.palette.primary.hover,
      },
    },
    downloadButton: {
      color: theme.palette.common.white,
    },
    cancelButton: {
      color: theme.palette.primary.main,
      border: `1px solid ${theme.palette.primary.main}`,

      '&:hover': {
        backgroundColor: theme.palette.common.white,
        border: `1px solid ${theme.palette.primary.hover}`,
        color: theme.palette.primary.hover,
      },
    },
    dialogActions: {
      paddingTop: theme.spacing(5),
      paddingRight: theme.spacing(3),
      paddingLeft: theme.spacing(3),
      paddingBottom: theme.spacing(3),
    },
    hardwareTypeSelect: {
      color: theme.palette.common.black,
    },
    icon: {
      width: 24,
      height: 32,
    },
  });
