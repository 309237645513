import moment from 'moment-timezone';

interface ParsedProcessorResponse {
  traceId?: string;
  amount?: string;
  createdAt?: string;
  achId?: string;
  status?: string | number;
  errors?: { code: string | number; message: string }[];
}

/**
 * Takes a json object that contains nested processor response information
 * and parses it to a new object containing relevant properties
 *
 * Possible paths for each property are defined as an arrays of string arrays
 */
export function parseProcessorResponse(r: any): ParsedProcessorResponse {
  /** Return object's value at a specified path passed as an array of properties */
  const getNested = (obj: any, ...args: string[]) =>
    args.reduce((obj, level) => obj && obj[level], obj);

  /** Takes multiple paths and returns the first value it finds or undefined if path does not exist */
  const findValue = (paths: string[][]) =>
    paths.map((path) => getNested(r, ...path)).filter((value) => value !== undefined)[0];

  /** List of paths for wanted processor response properties */
  const traceIdPaths = [
    ['body', 'achDetails', 'destination', 'traceId'],
    ['achDetails', 'destination', 'traceId'],
  ];
  const amountPaths = [
    ['body', 'amount', 'value'],
    ['amount', 'value'],
  ];
  const createdAtPaths = [['body', 'created'], ['created']];
  const achIdPaths = [
    ['body', 'achDetails', 'destination', 'traceId'],
    ['achDetails', 'destination', 'traceId'],
  ];
  const statusPaths = [['body', 'status'], ['status']];
  const errorsArrayPaths = [
    ['body', '_embedded', 'errors'],
    ['_embedded', 'errors'],
  ];

  return {
    traceId: findValue(traceIdPaths),
    amount: findValue(amountPaths),
    createdAt: findValue(createdAtPaths)
      ? moment(findValue(createdAtPaths)).format('MMM Do YYYY, h:mm:ss a')
      : undefined,
    achId: findValue(achIdPaths),
    status: findValue(statusPaths),
    errors: findValue(errorsArrayPaths),
  };
}
