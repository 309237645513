import React from 'react';
import { observer } from 'mobx-react';
import {
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  SvgIcon,
} from '@material-ui/core';
import { Close, Apple } from 'mdi-material-ui';
import { ReactComponent as SquareIcon } from 'images/square.svg';

import { Inventory } from 'models';

type InventoryWithSerial = Partial<Inventory> & Pick<Inventory, 'serial'>;

interface InventoryItemProps {
  children: InventoryWithSerial;
  onDelete?: (i: InventoryWithSerial) => void;
}

/** Displays a single inventory item in a list of inventory items */
const InventoryItem: React.FC<InventoryItemProps> = observer(({ children, onDelete }) => {
  const manufacturer = children.hardware && children.hardware.manufacturer;
  let icon: React.ReactElement | undefined = undefined;
  if (manufacturer && manufacturer.toLowerCase() === 'apple') {
    icon = <Apple />;
  }
  if (manufacturer && manufacturer.toLowerCase() === 'square') {
    icon = (
      <SvgIcon>
        <SquareIcon />
      </SvgIcon>
    );
  }
  const modelString =
    children &&
    children.hardware &&
    children.hardware.manufacturer &&
    children.hardware.model &&
    `${children.hardware.manufacturer} ${children.hardware.model}`;
  return (
    <ListItem disableGutters dense divider>
      {icon && <ListItemIcon>{icon}</ListItemIcon>}
      <ListItemText primary={children.serial} secondary={modelString} />
      {onDelete && (
        <ListItemSecondaryAction>
          <IconButton
            edge="end"
            aria-label="delete"
            size="small"
            onClick={() => {
              onDelete && onDelete(children);
            }}>
            <Close fontSize="small" />
          </IconButton>
        </ListItemSecondaryAction>
      )}
    </ListItem>
  );
});

export default InventoryItem;
