import { useAddStation } from './useAddStation';
import { Box, FormHelperText } from '@material-ui/core';
import { observer } from 'mobx-react';
import React, { ChangeEvent } from 'react';
import DD from 'components/DashDrawer';
import theme from 'containers/App/theme';
import { IStation } from 'models/Station';
import { AddStationPoolsOrGroupsSection } from 'components/AddStationPoolsOrGroupsSection/AddStationPoolsOrGroupsSection';
import { AddStationUsersSection } from 'components/AddStationUsersSection/AddStationUsersSection';
import OutlinedInput from 'components/Input/OutlinedInput';
import Button from 'components/Button/Button';

export const AddStation = observer(() => {
  const [
    isLoading,
    savingStation,
    form,
    station,
    isPoolsEnabled,
    validate,
    onSubmit,
    closeStationDrawer,
    setStationName,
    setWayToTip,
    countStationUsers,
    openAddStationUsers,
    openAddStationPoolsAndGroups,
  ] = useAddStation();
  return (
    <DD>
      <DD.Content>
        <DD.Title fontSize={28} onClose={closeStationDrawer}>
          Add Station
        </DD.Title>
        <Box minWidth={520}>
          {station && form && (
            <form
              id="stationsForm"
              onSubmit={onSubmit}
              style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
              <StationName
                isLoading={isLoading}
                form={form}
                station={station}
                setStationName={setStationName}
              />
              <StationItems isLoading={isLoading} countStationUsers={countStationUsers} />
              {/* <Divider /> */}
            </form>
          )}
        </Box>
      </DD.Content>
      <DD.Actions>
        {form && (
          <FormHelperText error={form.$('items').hasError}>{form.$('items').error}</FormHelperText>
        )}
        <AddButton form={form} loading={savingStation} />
      </DD.Actions>
    </DD>
  );
});

const StationName = ({
  isLoading,
  form,
  station,
  setStationName,
}: {
  isLoading: boolean;
  form: any;
  station: IStation;
  setStationName: (e: ChangeEvent<HTMLInputElement>) => void;
}) => {
  return (
    <OutlinedInput
      {...form.$('name').bind()}
      error={Boolean(form.$('name').error)}
      helperText={form.$('name').error}
      disabled={isLoading}
      value={station?.name}
      onChange={(e: ChangeEvent<HTMLInputElement>) => setStationName(e)}
      onBlur={(e: any) => setStationName(e)}
      fullWidth
      // autoFocus
    />

    // <FormControl fullWidth key={'name'}>
    //   <InputLabel htmlFor={form.$('name').id} required>
    //     {form.$('name').label}
    //   </InputLabel>
    //   <Input
    //     disabled={isLoading}
    //     value={station!.name}
    //     onChange={(e: ChangeEvent<HTMLInputElement>) => setStationName(e)}
    //     onBlur={(e: any) => setStationName(e)}
    //   />

    //   <FormHelperText error={form.$('name').hasError}>{form.$('name').error}</FormHelperText>
    // </FormControl>
  );
};
interface IAddButton {
  form: any;
  loading: boolean;
}
const AddButton = ({ form, loading }: IAddButton) => {
  return (
    <Button
      form="stationsForm"
      type="submit"
      variant="contained"
      style={{ borderRadius: theme.shape.borderRadius }}
      fullWidth
      loading={loading}
      disabled={(form && form.hasError) || loading}>
      ADD
    </Button>
  );
};

interface IStationItemsProps {
  isLoading: boolean;
  countStationUsers: () => number;
}
const StationItems = ({ isLoading, countStationUsers }: IStationItemsProps) => {
  return (
    <Box minWidth={520}>
      <AddStationPoolsOrGroupsSection isLoading={isLoading} />
      <AddStationUsersSection isLoading={isLoading} countUsers={countStationUsers} />
    </Box>
  );
};
