import React from 'react';
import { WithStyles, withStyles } from '@material-ui/core/styles';
import { Typography, Box, CardMedia } from '@material-ui/core';

import styles from './styles';
import TippyMarkerIcon from '../../images/tippy_marker.svg';
import { default as CustomMarker, MarkerProps } from '../CustomMarker';
import { Location } from 'models';

/** Here we define what kind of props this component takes */
type TippyMarkerProps = WithStyles<typeof styles> &
  MarkerProps & {
    location: Location;
  };

class TippyMaker extends React.Component<TippyMarkerProps> {
  renderTooltip = () => {
    const { classes, location } = this.props;
    return (
      <Box p={2}>
        <Box pb={1}>
          <Typography className={classes.popupTitle} style={{ fontWeight: 'bold' }}>
            {location.account!.name} - {location.name}
          </Typography>
        </Box>
        <Box>
          <Typography color="textSecondary" className={classes.popupText}>
            {location.address}
          </Typography>
        </Box>
        <Box>
          <Typography color="textSecondary" className={classes.popupText}>
            {location.city}, {location.state} {location.zip}
          </Typography>
        </Box>
      </Box>
    );
  };

  render() {
    const { classes } = this.props;
    return (
      <CustomMarker fixedPos={{ width: -15, height: -15 }} tooltip={this.renderTooltip()}>
        <CardMedia className={classes.markerSize} component="image" image={TippyMarkerIcon} />
      </CustomMarker>
    );
  }
}
export default withStyles(styles)(TippyMaker);
