import { Theme, createStyles } from '@material-ui/core';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default (theme: Theme) =>
  createStyles({
    root: {},
    doubleStat: {
      background: `linear-gradient(180deg, #66B6E4 0%, ${theme.palette.secondary.main} 100%)`,
      borderRadius: theme.shape.borderRadius,
    },
    leftG: {
      flex: 1,
      borderRight: `1px solid white`,
      borderRadius: 0,
    },
    rightG: {
      flex: 1,
      borderRadius: 0,
    },
    underPerformed: {
      display: 'flex',
      alignItems: 'center',
    },
    underPerformedIcon: {
      marginRight: '16px',
    },
  });
