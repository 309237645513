import { useStores } from 'containers/App/App';
import { useStationNavigation } from 'containers/LocationDetails/QrCodes/useStationNavigation';
import { StationItem } from 'models/Station';

type IUseAddStationPoolsOrGroupsReturn = [boolean, () => void, () => void, () => void];

export function useAddStationPoolsOrGroups(): IUseAddStationPoolsOrGroupsReturn {
  const { stationsStore } = useStores();
  const { stationNavigation } = useStationNavigation();
  const { isPoolsEnabled, updateFormFieldAndValidate } = stationsStore!;

  function addPools() {
    stationNavigation.openPools();
  }

  function addGroup() {
    stationsStore.selectedGroup = {
      type: StationItem.GROUP,
      groupName: '',
      groupItems: [],
    };
    stationNavigation.openGroup();
  }

  function goBack() {
    stationNavigation.openStation();
    updateFormFieldAndValidate('items');
  }

  return [isPoolsEnabled, addPools, addGroup, goBack];
}
