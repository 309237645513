import { Paper, Typography } from '@material-ui/core';
import useStyles from './styles';
import Button from 'components/Button/Dialog/Button';
import CircularIconFrame, {
  ECircularIconFrameVariant,
  TCircularIconFrameIcon,
} from 'components/CircularIconFrame/CircularIconFrame';
import { CardIconColor } from 'components/CircularIconFrame/useIconWrapper';

type Icon = JSX.Element;
export interface ReportCardProps {
  name: string;
  description: string;
  onClick: () => void;
  icon: TCircularIconFrameIcon;
  iconColor?: CardIconColor;
}

function ReportCard(props: ReportCardProps) {
  const classes = useStyles();

  const { name, icon, iconColor, description, onClick } = props;
  return (
    <Paper className={classes.reportCard}>
      <CircularIconFrame icon={icon} color={iconColor} variant={ECircularIconFrameVariant.MEDIUM} />
      <Typography className={classes.title} variant="h6">
        {name}
      </Typography>
      <Typography variant="subtitle1" className={classes.description}>
        {description}
      </Typography>
      <Button className={classes.downloadButton} variant="contained" onClick={onClick}>
        Download
      </Button>
    </Paper>
  );
}

export default ReportCard;
