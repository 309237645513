import { makeStyles } from '@material-ui/core';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default makeStyles((theme) => ({
  root: {
    height: '100%',
    width: '100%',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
  },
  applyButton: {
    color: theme.palette.text.primary,
    fontWeight: 400,
  },
  filterDrawerInner: {
    padding: theme.spacing(3),
    minWidth: theme.spacing(50),
    flex: 1,
    overflow: 'visible',
  },
  actions: {
    padding: theme.spacing(3),
    paddingBottom: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
  },
  drawerActions: {
    paddingTop: '24px',
    paddingRight: '24px',
    paddingBottom: '16px',
    paddingLeft: '24px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
  },
  listItemKey: {
    textTransform: 'capitalize',
    marginRight: theme.spacing(6),
  },
  listItemValue: {
    display: 'inline',
  },
  listItemIcon: {
    color: theme.palette.common.black,
    marginLeft: theme.spacing(2),
    fontSize: 18,
  },
  subdrawer: {
    position: 'absolute',
    top: 0,
    right: 0,
    left: 0,
    bottom: 0,
    zIndex: 10000,
    backgroundColor: theme.palette.common.white,
  },
  fabButton: {
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(4),
  },
  item: {
    width: '50%',
    overflowWrap: 'break-word',
    wordWrap: 'break-word',
    overflow: 'visible',
    whiteSpace: 'initial',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    '&:last-child': {
      marginBottom: 0,
    },
  },
  row: {
    display: 'flex',
    marginBottom: theme.spacing(3),
    justifyContent: 'space-between',
    width: '100%',
  },
  value: {
    display: 'flex',
    alignItems: 'center',
    whiteSpace: 'normal',
    flexGrow: 0,
    width: '100%',
    fontSize: '18px',
    fontWeight: 400,
  },
  titleWrapper: {
    paddingTop: theme.spacing(3),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingBottom: theme.spacing(2),
  },
}));
