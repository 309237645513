import { Box, CardMedia, Typography } from '@material-ui/core';
import TippyLogo from 'images/tippy_logo.png';
import React from 'react';
import useStyles from './styles';

/** Displays a card for a single user */
function InquiryFooter() {
  const classes = useStyles();

  return (
    <Box className={classes.footer}>
      <Box className={classes.backgroundColor}>
        <Typography className={classes.oink}>Oink</Typography>
        <Box className={classes.leftPeak}></Box>
        <Box className={classes.rightPeak}></Box>
        <Box>
          <CardMedia className={classes.tippyLogo} image={TippyLogo} title={`Tippy logo`} />
        </Box>
        <Box>
          <Typography className={classes.copy}>
            © 2019. DirectTIPS Operations, LLC. All Rights Reserved.
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}

export default InquiryFooter;
