import { Theme, createStyles } from '@material-ui/core';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default (theme: Theme) =>
  createStyles({
    root: {},
    chipCompleted: {
      backgroundColor: '#4EAE8D',
      color: theme.palette.primary.contrastText,
    },
    chipProcessed: {
      backgroundColor: '#4EAE8D',
      color: theme.palette.primary.contrastText,
    },
    chipPending: {
      backgroundColor: '#FFD700',
      color: theme.palette.primary.contrastText,
    },
    chipFailed: {
      backgroundColor: '#E01E5A',
      color: theme.palette.primary.contrastText,
    },
  });
