/* eslint-disable @typescript-eslint/no-explicit-any */

import React from 'react';
import { observer } from 'mobx-react';
import { Box, Grid, Typography, Paper } from '@material-ui/core';
import { SvgIconProps, SvgIconTypeMap } from '@material-ui/core/SvgIcon';
import Countup from 'react-countup';
import clsx from 'clsx';
import { CreditCard, CurrencyUsd, BankOutline, Coins } from 'mdi-material-ui';

import RefreshOutlinedIcon from '@mui/icons-material/RefreshOutlined';

import moment from 'moment-timezone';

import LoadingSpinner from 'components/LoadingSpinner';
import useStyles from './styles';
import { OverridableComponent } from '@material-ui/core/OverridableComponent';
import { CardIconColor, useStats } from './useStats';
import { DynamicFont } from 'components/DynamicFont/DynamicFont';
import { Skeleton } from '@mui/material';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpFromLine, faDownFromLine } from '@fortawesome/pro-regular-svg-icons';
/** Represents a single stat, which has a count and an amount */
export interface Stat {
  count?: number;
  amount?: number;
}

type Color = 'primary' | 'secondary' | 'warning';

interface BigStatPropsOld {
  children?: number | string;
  icon: OverridableComponent<SvgIconTypeMap<any>>;
  fontAwesomeIcon?: IconDefinition;
  title: string;
  dateRange?: { fromDate?: string; toDate?: string };
  duration?: number;
  decimals?: number;
  prefix?: string;
  suffix?: string;
  separator?: string;
  noBg?: boolean;
  className?: string;
  displayTax?: boolean;
  applyTax?: boolean;
  footerData?: any;
  loading?: boolean;
  color?: Color;
}

const BigStatDateRange = ({ children }: { children: { fromDate?: string; toDate?: string } }) => {
  const { fromDate, toDate } = children;
  if (fromDate && toDate) {
    return (
      <span>
        {moment(fromDate).format('MM/DD/YY')} - {moment(toDate).format('MM/DD/YY')}
      </span>
    );
  } else if (fromDate) {
    return <span>from {moment(fromDate).format('MM/DD/YY')}</span>;
  } else if (toDate) {
    return <span>to {moment(fromDate).format('MM/DD/YY')}</span>;
  } else {
    return null;
  }
};

/** Displays the big rectangle that shows the total balance */
export const BigStat = observer(
  ({
    children,
    icon: Icon,
    fontAwesomeIcon,
    duration,
    dateRange,
    title,
    decimals,
    prefix,
    suffix,
    separator,
    noBg,
    className,
    footerData,
    applyTax,
    displayTax,
    loading = false,
    color = 'primary',
  }: BigStatPropsOld) => {
    const [getColorClass] = useStats();
    const { balance, balanceBase, balanceTitle, ...classes } = useStyles({});
    const mainBoxProps = noBg
      ? { className: clsx(className, balanceBase) }
      : { className: clsx(className, balance, balanceBase), boxShadow: 1 };

    return (
      <Box className={classes.statsContainer}>
        <Typography className={classes.textDate}>
          {dateRange && <BigStatDateRange>{dateRange}</BigStatDateRange>}
        </Typography>
        <Box marginLeft={'0.5rem'} flexGrow={1}>
          <Box className={classes.iconContainer}>
            <Box className={getColorClass(color)}>
              {Icon && <Icon fontSize="large" />}{' '}
              {fontAwesomeIcon && <FontAwesomeIcon icon={fontAwesomeIcon} size="lg" />}
            </Box>
            <Box flexGrow={1}>
              {children !== undefined && loading === false ? (
                <>
                  {typeof children === 'string' ? (
                    <Box>{children}</Box>
                  ) : (
                    <Countup
                      redraw
                      className={classes.countUp}
                      end={children}
                      duration={duration}
                      decimals={decimals}
                      prefix={prefix || ''}
                      suffix={suffix || ''}
                      separator={separator || ','}
                    />
                  )}
                </>
              ) : (
                <LoadingSpinner color="inherit" />
              )}
              <Typography className={classes.text}>{title}</Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    );
  },
);

interface IBigStatBase {
  children?: number;
  icon?: OverridableComponent<SvgIconTypeMap<any>>;
  fontAwesomeIcon?: IconDefinition;
  iconSize?: number;
  title: string;
  duration?: number;
  decimals?: number;
  prefix?: string;
  suffix?: string;
  separator?: string;
  loading?: boolean;
  color?: CardIconColor;
}

type HorizontalStatCardProps = {
  dateRange?: { fromDate?: string; toDate?: string };
  displaySmall?: boolean;
  noBg?: boolean;
  className?: string;
  displayTax?: boolean;
  applyTax?: boolean;
  footerData?: any;
} & IBigStatBase;

/** Displays the big rectangle that shows the total balance */
export const HorizontalStatCard = observer(
  ({
    children,
    icon: Icon,
    fontAwesomeIcon,
    duration,
    dateRange,
    title,
    decimals,
    prefix,
    suffix,
    separator,
    displaySmall,
    loading = false,
    color = 'primary',
  }: HorizontalStatCardProps) => {
    const { balance, balanceBase, balanceTitle, ...classes } = useStyles({ displaySmall });
    const [getColorClass] = useStats();

    let iconSize = fontAwesomeIcon ? 32 : 40;
    if (displaySmall) {
      iconSize = fontAwesomeIcon ? 24 : 28;
    }
    const iconWrapper = displaySmall ? classes.iconWrapperSmall : classes.iconWrapperBig;

    return (
      <Box className={clsx(classes.statsContainerBase, classes.bigStatsContainer)}>
        <Box
          className={clsx(getColorClass(color), iconWrapper, classes.iconWrapperBase)}
          alignSelf={'center'}>
          {Icon && <Icon style={{ fontSize: iconSize }} />}
          {fontAwesomeIcon && (
            <FontAwesomeIcon icon={fontAwesomeIcon} style={{ height: iconSize }} />
          )}
        </Box>
        <Box className={classes.dynamicFontWrapper}>
          <Typography variant="subtitle1" className={classes.bigStatTextDate}>
            {dateRange && <BigStatDateRange>{dateRange}</BigStatDateRange>}
          </Typography>
          <Box ml={2.5} className={clsx(classes.bigStatHorizontalCounter, classes.flexCounterBase)}>
            {children !== undefined && loading === false ? (
              <DynamicFont hasCountup>
                <Countup
                  redraw
                  className={classes.countUp}
                  end={children}
                  duration={duration}
                  decimals={decimals}
                  prefix={prefix || ''}
                  suffix={suffix || ''}
                  separator={separator || ','}
                />
              </DynamicFont>
            ) : (
              <Skeleton height={30} />
            )}
          </Box>
          <DynamicFont maxFontSize={14} className={classes.bigStatHorizontalTitle}>
            {title}
          </DynamicFont>
        </Box>
      </Box>
    );
  },
);

type VerticalStatCardProps = {} & IBigStatBase;

export const VerticalStatCard = observer((props: VerticalStatCardProps) => {
  const classes = useStyles({});
  const [getColorClass] = useStats();

  const {
    children,
    icon: Icon,
    fontAwesomeIcon,
    title,
    duration,
    prefix,
    suffix,
    decimals,
    separator,
    loading = false,
    color,
  } = props;
  let { iconSize } = props;
  iconSize = iconSize ? iconSize : 28;
  return (
    <Box className={clsx(classes.statsContainerBase, classes.statsVerticalContainer)}>
      <Box
        className={clsx(getColorClass(color), classes.iconWrapperSmall, classes.iconWrapperBase)}>
        {Icon && <Icon style={{ fontSize: iconSize }} />}
        {fontAwesomeIcon && (
          <FontAwesomeIcon icon={fontAwesomeIcon} style={{ fontSize: iconSize }} />
        )}
      </Box>
      <Box mt={2} className={clsx(classes.bigStatVerticalCounter, classes.flexCounterBase)}>
        {children !== undefined && loading === false ? (
          <DynamicFont hasCountup>
            <Countup
              redraw
              className={classes.countUp}
              end={children}
              duration={duration}
              decimals={decimals}
              prefix={prefix || ''}
              suffix={suffix || ''}
              separator={separator || ','}
            />
          </DynamicFont>
        ) : (
          <Skeleton height={30} />
        )}
      </Box>
      <Box mt={1}>
        <Typography variant="subtitle1">{title}</Typography>
      </Box>
    </Box>
  );
});

type DenseStatCardProps = {
  applyTax?: boolean;
  displayTax?: boolean;
  additionalData?: any;
} & IBigStatBase;
export const DenseStatCard = observer((props: DenseStatCardProps) => {
  const classes = useStyles({});
  const [getColorClass] = useStats();
  const {
    children,
    icon: Icon,
    fontAwesomeIcon,
    title,
    duration,
    prefix,
    suffix,
    decimals,
    separator,
    loading = false,
    color,
    applyTax,
    displayTax,
    additionalData,
  } = props;
  let { iconSize } = props;
  iconSize = iconSize ? iconSize : 28;
  return (
    <Box className={clsx(classes.statsContainerBase, classes.statsDenseContainer)}>
      <Box display={'flex'} justifyContent={'space-between'} style={{ position: 'relative' }}>
        <Box
          className={clsx(getColorClass(color), classes.iconWrapperSmall, classes.iconWrapperBase)}
          style={{ width: 50, height: 50 }}>
          {Icon && <Icon style={{ fontSize: iconSize }} />}
          {fontAwesomeIcon && (
            <FontAwesomeIcon icon={fontAwesomeIcon} style={{ fontSize: iconSize }} />
          )}
        </Box>
        <Box className={classes.denseStatCardAdditionalData}>{additionalData}</Box>
      </Box>
      <Box mt={2} className={clsx(classes.bigStatDenseCounter, classes.flexCounterBase)}>
        {children !== undefined && loading === false ? (
          <DynamicFont hasCountup>
            <Countup
              redraw
              className={classes.countUp}
              end={children}
              duration={duration}
              decimals={decimals}
              prefix={prefix || ''}
              suffix={suffix || ''}
              separator={separator || ','}
            />
          </DynamicFont>
        ) : (
          <Skeleton height={30} />
        )}
      </Box>
      <Box mt={1} display={'flex'} justifyContent={'space-between'}>
        <Typography variant="subtitle1">{title}</Typography>
        {displayTax && (
          <Typography variant="subtitle1">{applyTax ? `includes tax` : `+ taxes`}</Typography>
        )}
      </Box>
    </Box>
  );
});

type InfoCardProps = {
  children: string | React.ReactNode;
  title: string;
  loading?: boolean;
  iconSize?: number;
  icon: OverridableComponent<SvgIconTypeMap<any>>;
  color?: Color;
};
export const InfoCard = observer((props: InfoCardProps) => {
  const classes = useStyles({});
  const [getColorClass] = useStats();

  let { iconSize, color } = props;
  const { icon: Icon, children, title, loading } = props;

  iconSize = iconSize ? iconSize : 40;
  return (
    <Box className={clsx(classes.statsContainerBase, classes.bigStatsContainer)}>
      <Box display={'flex'} alignSelf={'center'}>
        <Box
          className={clsx(getColorClass(color), classes.iconWrapperBig, classes.iconWrapperBase)}>
          <Icon style={{ fontSize: iconSize }} />
        </Box>
      </Box>
      <Box className={classes.dynamicFontWrapper}>
        <Box ml={2.5} className={clsx(classes.bigStatInfoCounter, classes.flexCounterBase)}>
          {children !== undefined && loading === false ? (
            <DynamicFont noWrap vertical className={classes.bigStatInfoDynamicFont}>
              {children}
            </DynamicFont>
          ) : (
            <Skeleton height={30} />
          )}
        </Box>
        <DynamicFont maxFontSize={14} className={classes.bigStatHorizontalTitle}>
          {title}
        </DynamicFont>
      </Box>
    </Box>
  );
});

type ActionCardProps = {
  children: string | React.ReactNode;
  title?: string;
  disabled?: boolean;
  iconSize?: number;
  icon: OverridableComponent<SvgIconTypeMap<any>>;
  fontAwesomeIcon?: IconDefinition;
  onClick: () => void;
  color?: Color;
};
export const ActionCard = observer((props: ActionCardProps) => {
  const classes = useStyles({});
  const [getColorClass] = useStats();

  let { iconSize, color } = props;
  const { icon: Icon, children, title, disabled, onClick, fontAwesomeIcon } = props;

  function handleClick() {
    if (disabled) return;
    onClick();
  }

  iconSize = iconSize ? iconSize : 40;
  return (
    <Box className={clsx(classes.statsContainerBase, classes.bigStatsContainer)}>
      <Box display={'flex'} alignSelf={'center'}>
        <Box
          className={clsx(getColorClass(color), classes.iconWrapperBig, classes.iconWrapperBase)}>
          {Icon && <Icon fontSize="large" />}{' '}
          {fontAwesomeIcon && <FontAwesomeIcon icon={fontAwesomeIcon} size="lg" />}
        </Box>
      </Box>
      <Box className={classes.dynamicFontWrapper}>
        <Box ml={2.5} className={clsx(classes.flexCounterBase, classes.dynamicFontInnerWrapper)}>
          {children !== undefined ? (
            <Box
              onClick={handleClick}
              style={{
                cursor: !disabled ? 'pointer' : '',
              }}>
              <DynamicFont
                maxFontSize={20}
                className={clsx(
                  classes.bigStatActionFont,
                  disabled && classes.bigStatActionFontDisabled,
                )}>
                {children}
              </DynamicFont>
            </Box>
          ) : (
            <Skeleton height={30} />
          )}
        </Box>
        {title && (
          <DynamicFont maxFontSize={14} className={classes.bigStatActionTitle}>
            {title}
          </DynamicFont>
        )}
      </Box>
    </Box>
  );
});

/** Displays the big rectangle that shows the total balance */
export const DashStat = observer(
  ({
    children,
    icon: Icon,
    fontAwesomeIcon,
    duration,
    title,
    decimals,
    prefix,
    separator,
    loading = false,
  }: HorizontalStatCardProps) => {
    const { balancePaper, balanceTitle, countUp } = useStyles({});
    return (
      <Paper className={clsx(balancePaper)}>
        <Box display="flex" flexDirection="column" alignItems="center">
          {Icon && <Icon fontSize="large" />}
          {fontAwesomeIcon && <FontAwesomeIcon icon={fontAwesomeIcon} size="lg" />}
          <Typography className={balanceTitle}>{title}</Typography>
        </Box>
        {children !== undefined && loading === false ? (
          <Typography variant="h4" component={Box}>
            {typeof children === 'string' ? (
              <Box>{children}</Box>
            ) : (
              <Countup
                redraw
                className={countUp}
                end={children}
                duration={duration}
                decimals={decimals}
                prefix={prefix}
                separator={separator}
              />
            )}
          </Typography>
        ) : (
          <LoadingSpinner color="inherit" />
        )}
      </Paper>
    );
  },
);

interface StatItemProps {
  children?: Stat;
  icon: React.ComponentType<SvgIconProps>;
  label: string;
  variant: 'top' | 'bottom';
  prefix?: boolean;
  decimals?: number;
}

/**
 * Displays a small square which shows a single stat, its count and its amount
 */
export const StatItem = observer(
  ({
    children,
    icon: IconComponent,
    label,
    variant,
    decimals = 2,
    prefix = true,
  }: StatItemProps) => {
    const styles = useStyles({});
    return (
      <Box
        className={clsx(styles.stat, styles[variant])}
        boxShadow={1}
        display="flex"
        flexDirection="column">
        <Box
          className={styles.statItemTop}
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center">
          <IconComponent fontSize="small" />
          <Box>
            {children?.count} {label}
          </Box>
        </Box>
        <Box className={styles.statItemBottom}>
          {children?.amount ? (
            <Typography variant="h5" component={Box}>
              <Countup
                redraw
                className={styles.countUp}
                end={children.amount}
                duration={3}
                decimals={decimals}
                prefix={prefix ? '$' : ''}
                separator=","
              />
            </Typography>
          ) : (
            <LoadingSpinner color="inherit" />
          )}
        </Box>
      </Box>
    );
  },
);

/**
 * The Stats component takes as its children an object which contains the counts
 * and amounts for each stat
 */
export interface StatsProps {
  children?: {
    balance?: number;
    tips?: Stat;
    payouts?: Stat;
    splitIn?: Stat;
    inTransfer?: Stat;
    splitOut?: Stat;
    outTransfer?: Stat;
    cashTips?: Stat;
    refunds?: Stat;
  };
}

/**
 * Displays the stats for a certain user in a visually appealing way.
 */
const Stats = observer(({ children }: StatsProps) => {
  const balance = children?.balance;
  const tips = children?.tips;
  const splitIn = children?.splitIn;
  const inTransfer = children?.inTransfer;
  const splitOut = children?.splitOut;
  const outTransfer = children?.outTransfer;
  const payouts = children?.payouts;
  const cashTips = children?.cashTips;
  const refunds = children?.refunds;
  const styles = useStyles({});

  const concatTitle = (title: string, value?: number) => {
    return `${value || ''} ${title}`.trim();
  };

  return (
    <Box>
      <Grid container spacing={3}>
        <Grid item lg={3} md={12} xs={12}>
          <VerticalStatCard
            iconSize={40}
            icon={CurrencyUsd}
            title={concatTitle('Balance')}
            prefix="$"
            color="yellow"
            duration={2}
            decimals={2}>
            {balance}
          </VerticalStatCard>
        </Grid>
        <Grid item lg={9} md={12} xs={12}>
          <Grid container spacing={3} justifyContent="space-around">
            <Grid item className={styles.cardItem}>
              <HorizontalStatCard
                displaySmall
                icon={CreditCard}
                title={concatTitle('Tips', tips?.count)}
                color="primary"
                duration={2}
                decimals={2}
                prefix="$">
                {tips?.amount}
              </HorizontalStatCard>
            </Grid>
            <Grid item className={styles.cardItem}>
              <HorizontalStatCard
                displaySmall
                fontAwesomeIcon={faDownFromLine}
                color={'yellow'}
                title={concatTitle('transfer in', inTransfer?.count)}
                prefix={'$'}
                decimals={2}>
                {inTransfer?.amount}
              </HorizontalStatCard>
            </Grid>
            <Grid item className={styles.cardItem}>
              <HorizontalStatCard
                displaySmall
                fontAwesomeIcon={faUpFromLine}
                color="purple"
                title={concatTitle('transfer out', outTransfer?.count)}
                prefix={'$'}
                decimals={2}>
                {outTransfer?.amount}
              </HorizontalStatCard>
            </Grid>
            <Grid item className={styles.cardItem}>
              <HorizontalStatCard
                displaySmall
                icon={BankOutline}
                title={concatTitle('Payouts', payouts?.count)}
                color="primary"
                duration={2}
                decimals={2}
                prefix="$">
                {payouts?.amount}
              </HorizontalStatCard>
            </Grid>
            <Grid item className={styles.cardItem}>
              <HorizontalStatCard
                displaySmall
                icon={Coins}
                title={concatTitle('Cash tips', cashTips?.count)}
                color="yellow"
                duration={2}
                decimals={2}
                prefix="$">
                {cashTips?.amount}
              </HorizontalStatCard>
            </Grid>
            <Grid item className={styles.cardItem}>
              <HorizontalStatCard
                displaySmall
                icon={RefreshOutlinedIcon}
                title={concatTitle('Refunds', refunds?.count)}
                color="secondary"
                duration={2}
                decimals={2}
                prefix="$">
                {refunds?.amount}
              </HorizontalStatCard>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
});
export default Stats;
