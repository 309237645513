/* eslint-disable @typescript-eslint/no-unused-vars */
import { Theme, createStyles, makeStyles } from '@material-ui/core';

const styles = (theme: Theme) =>
  createStyles({
    root: {},
    fullHeight: {
      height: '100%',
    },
    promoCodeApplyBtn: {
      width: theme.spacing(20),
      marginLeft: theme.spacing(3),
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
    },
    affiliateThanks: {
      backgroundColor: theme.palette.grey[100],
      padding: theme.spacing(1),
      marginBottom: theme.spacing(2),
    },
  });

export default styles;

export const useStyles = makeStyles(styles);
