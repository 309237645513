import { Box, Typography } from '@material-ui/core';
import { ILiabilityLedgerSummaryData, LiabilityLedgerSummary } from 'models';
import { liabilityBoxStyling, liabilityContainerStyling } from './styles';
import { decimalize } from 'utils/helper';
import { DynamicFont } from 'components/DynamicFont/DynamicFont';
import { numericStringToUsd } from 'services';
import clsx from 'clsx';

interface LiabiltySummaryProps {
  summaryData: LiabilityLedgerSummary;
}

export const LiabiltySummary = ({
  summaryData: { startBalance, summary = {} as ILiabilityLedgerSummaryData, endBalance },
}: LiabiltySummaryProps) => {
  const summaryObj = {
    startBalance,
    ...summary,
    endBalance,
  };

  return (
    <Box sx={liabilityContainerStyling}>
      <Typography component="h4" variant="h4" className="title">
        Summary
      </Typography>

      <Box sx={liabilityBoxStyling}>
        {summaryObj &&
          Object.keys(summaryObj).map((key: string) => {
            const value = summaryObj[key as keyof ILiabilityLedgerSummaryData];
            const isBold = ['startBalance', 'endBalance'].includes(key) && 'bold';

            return (
              <Box key={key} className="item">
                <DynamicFont
                  maxFontSize={24}
                  className={clsx('value', isBold, Number(value) < 0 ? 'negative' : 'positive')}>
                  {numericStringToUsd(value ?? 0)}
                </DynamicFont>
                <Typography className={clsx('label', isBold)}>
                  {key === 'instantPayouts'
                    ? 'RT'
                    : key === 'instantFailedPayouts'
                    ? 'Failed RT'
                    : decimalize(key, ' ')}
                </Typography>
              </Box>
            );
          })}
      </Box>
    </Box>
  );
};
