import * as models from 'models';
import UAParser from 'ua-parser-js';

/**
 * Function that checks user agent's OS string.
 * @returns true if user agent's OS string is android otherwise returns false
 */
export function isOsAndroid() {
  const uaString = navigator.userAgent;
  let parser = new UAParser(uaString);
  return parser.getOS().name?.toLocaleLowerCase().includes(models.EOsType.ANDROID);
}

/**
 * Function that checks user agent's OS string.
 * @returns true if user agent's OS string is iOS otherwise returns false
 */
export function isOsIOS() {
  const uaString = navigator.userAgent;
  let parser = new UAParser(uaString);
  return parser.getOS().name?.toLocaleLowerCase().includes(models.EOsType.IOS);
}

/**
 * Function that checks user agent's OS string.
 * @returns true if user agent's OS string is mac os otherwise returns false
 */
export function isMacOS() {
  const uaString = navigator.userAgent;
  let parser = new UAParser(uaString);
  return parser.getOS().name?.toLocaleLowerCase().includes(models.EOsType.MAC_OS);
}

/**
 * Function that returns OS type based on user agent string
 * @returns OS name
 */
export const initializeOS = () => {
  const isAndroid = isOsAndroid();
  if (isAndroid) return models.EOsType.ANDROID;

  const isIOS = isOsIOS();
  if (isIOS) return models.EOsType.IOS;

  const isMacOs = isMacOS();
  if (isMacOs) return models.EOsType.MAC_OS;

  return models.EOsType.OTHER;
};
