import { inject } from 'mobx-react';
import { observer } from 'mobx-react-lite';
import DD from 'components/DashDrawer';
import {
  Avatar,
  Box,
  Button,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  Typography,
} from '@material-ui/core';
import React from 'react';
import { ListItemText, Skeleton, Stack } from '@mui/material';
import { Check } from '@mui/icons-material';
import { useAddStationUsers } from './useAddStationUsers';
import DP from 'components/DashPanel';
import { ISelectedLocationUser } from 'containers/LocationDetails/QrCodes/blUsers';
import { useStyles } from './styles';

export const AddStationUsers = inject('stationsStore')(
  observer(() => {
    const [selectedUsers, toggleSelectedUser, addSelectedUsers, goBack] = useAddStationUsers();
    const isSelectedUser = selectedUsers ? selectedUsers.some((user) => user.selected) : false;

    return (
      <DD overflow="hidden">
        <DD.Content>
          <DD.Title fontSize={28} onBack={goBack}>
            Add Users
          </DD.Title>
          <Box
            minWidth={520}
            maxHeight={'75vh'}
            style={{ display: 'flex', flexDirection: 'column', overflowY: 'auto' }}>
            <UsersList selectedUsers={selectedUsers} toggleSelectedUser={toggleSelectedUser} />
          </Box>
        </DD.Content>
        <DD.Actions>
          {' '}
          <Button
            onClick={addSelectedUsers}
            fullWidth
            color={'primary'}
            variant="contained"
            disabled={!isSelectedUser}>
            ADD{' '}
          </Button>
        </DD.Actions>
      </DD>
    );
  }),
);

const ListItemSkeleton = () => {
  return (
    <DP.ListItem
      primary={
        <Box display={'flex'} flexDirection={'row'} justifyContent={'center'} alignItems={'center'}>
          <Skeleton variant="circular" width={20} height={20} style={{ marginRight: '10px' }} />
          <Stack spacing={1} flexGrow={1}>
            <Skeleton variant="text" width="30%" height="20px" />
            <Skeleton variant="text" width={'50%'} height="20px" />
          </Stack>
        </Box>
      }
    />
  );
};

interface IUsersList {
  selectedUsers?: Array<ISelectedLocationUser>;
  toggleSelectedUser: (user: ISelectedLocationUser) => void;
}
const UsersList = ({ selectedUsers, toggleSelectedUser }: IUsersList) => {
  const classes = useStyles();
  if (!selectedUsers || selectedUsers.length === 0) {
    if (!selectedUsers) {
      const numberOfSkeletons = 10;
      const skeletons = [];
      for (let i = 0; i < numberOfSkeletons; i++) {
        skeletons.push(<ListItemSkeleton key={i} />);
      }
      return <List style={{ padding: 0 }}>{skeletons}</List>;
    }
    return (
      <Typography align="center" variant="body2">
        No users to display
      </Typography>
    );
  }
  return (
    <List style={{ padding: 0 }}>
      {selectedUsers.map((user: any) => {
        const icon = <Avatar src={user.avatar} className={classes.avatar} />;
        return (
          <ListItem
            key={user.id}
            className={user.selected ? classes.selectedItem : classes.unselectedItem}
            onClick={() => toggleSelectedUser(user)}>
            {icon && (
              <ListItemAvatar className={classes.listItemAvatar}>
                <>{icon}</>
              </ListItemAvatar>
            )}
            <ListItemText
              className={user.selected ? classes.selectedItemText : classes.listItemText}
              primary={user.fullName}
            />
            <IconButton className={classes.checkIcon}>
              {user.selected && <Check fontSize="small" />}
            </IconButton>
          </ListItem>
        );
      })}
    </List>
  );
};
