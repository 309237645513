import React from 'react';
import { Avatar, Button, Box, Menu as MuiMenu, Typography, MenuItem } from '@material-ui/core';
import { useStores } from 'containers/App/App';
import { useStyles } from './styles';
import theme from 'containers/App/theme';
import clsx from 'clsx';
import ScopeSwitcher from 'containers/ScopeSwitcher/ScopeSwitcher';
import { useScopeSwitcher } from './useScopeSwitcher';
import { observer } from 'mobx-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faEarthAmericas } from '@fortawesome/pro-regular-svg-icons';
import MenuIcon from '../Menu/MenuIcon/MenuIcon';

interface IScopeSwitcherButton {
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  scopeSwitcherEl?: HTMLElement | null;
  handleScopeSwitcherClose?: () => void;
  drawerWidth?: number;
  smallDrawerWidth?: number;
}
function ScopeSwitcherButton(props: IScopeSwitcherButton) {
  const { userStore, uiStore } = useStores();
  const { mobileView } = uiStore;
  const menuOpen = uiStore.drawer.open;
  const showScopeItems = mobileView ? true : menuOpen ? true : false;
  const classes = useStyles({ mobileView, menuOpen });

  const {
    showScopeSwitcher,
    scopeSwithcerEl,
    top,
    handleScopeSwitcherClick,
    handleScopeSwitcherClose,
  } = useScopeSwitcher();

  function getLogo() {
    const scope = userStore.scope.kind;
    // Location or account logo
    let logo;
    // Location or account name
    let name;
    if (scope === 'global_owner') {
      return <MenuIcon icon={faEarthAmericas} />;
    }
    if (scope === 'talent') {
      logo = userStore.user?.avatar;
    }
    if (scope === 'owner') {
      const account = userStore.currentAccount;
      logo = account?.logo;
      name = account?.name;
    }
    if (scope === 'manager' && location) {
      const location = userStore.currentManagedLocation;
      logo = location?.account?.logo;
      name = location?.name;
    }

    const backgroundColor = logo ? theme.palette.common.white : theme.palette.common.black;
    return (
      <Avatar
        className={clsx(classes.avatarButton, {
          [classes.avatarSmallDrawerAnimation]: !menuOpen && !mobileView,
        })}
        style={{
          backgroundColor,
          backgroundImage: `url(${logo})`,
          backgroundSize: 'contain',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center center',
        }}
        alt="avatar">
        {!logo && <Box component={'span'}>{name?.substring(0, 2)}</Box>}
      </Avatar>
    );
  }

  let { drawerWidth, smallDrawerWidth } = props;
  drawerWidth = drawerWidth || theme.menu.drawer.width.open;
  smallDrawerWidth = smallDrawerWidth || theme.menu.drawer.width.closed;

  if (!showScopeSwitcher) {
    return <></>;
  }

  return (
    <>
      <Button
        data-cy={'scope-switcher-button'}
        endIcon={
          showScopeItems ? (
            <FontAwesomeIcon icon={faChevronDown} className={classes.chevron} />
          ) : undefined
        }
        onClick={handleScopeSwitcherClick}
        color="primary"
        size="large"
        className={classes.scopeSwitcherButton}>
        {getLogo()}
        {showScopeItems && (
          <Typography className={classes.scopeName}>{userStore.scopeName}</Typography>
        )}
      </Button>
      <MuiMenu
        elevation={0}
        className={classes.scopeSwitcherMenu}
        id="scope-switcher-menu"
        anchorEl={scopeSwithcerEl}
        anchorReference={'anchorPosition'}
        anchorPosition={{
          top,
          left: menuOpen ? drawerWidth : smallDrawerWidth,
        }}
        open={Boolean(scopeSwithcerEl)}
        onClose={handleScopeSwitcherClose}>
        <MenuItem disableRipple className={classes.menuItem}>
          <ScopeSwitcher close={handleScopeSwitcherClose} />
        </MenuItem>
      </MuiMenu>
    </>
  );
}

export default observer(ScopeSwitcherButton);
