import DP from 'components/DashPanel';
import { EmptyPanelMessage } from 'components/EmptyPanelMessage/EmptyPanelMessage';
import { observable, makeObservable, action, computed } from 'mobx';
import { observer } from 'mobx-react';
import { Card } from 'models';
import React from 'react';
import { inject, WithToastStore, WithUserStore } from 'stores';
import CardListItem from './CardListItem';
import Dialog from 'components/Dialog/Dialog';
import { Typography, Box, Tooltip } from '@material-ui/core';
import withStyles, { WithStyles } from '@material-ui/styles/withStyles';
import styles from './styles';
import { IKycStatus } from 'models/Kyc';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/pro-regular-svg-icons';
import OptionsMenu from 'components/OptionsMenu';
import { IOptionsMenuProps } from 'components/OptionsMenu/OptionsMenu';

interface CardsPanelProps extends WithStyles<typeof styles>, WithToastStore, WithUserStore {
  children?: Card[];
  kycStatus?: IKycStatus;
  updateKycStatus: () => void;
  primaryCardId?: number;
  payoutInterval: string;
  userId: number;
  loading: boolean;
  refreshCards: () => void;
}

@inject('toastStore', 'userStore')
@observer
class CardsPanel extends React.Component<CardsPanelProps> {
  constructor(props: CardsPanelProps) {
    super(props);
    makeObservable(this);
  }

  @observable public primaryCardId?: number;
  @observable public inProgress = false;
  @observable public showModal = false;

  @observable public menuAnchorEl: null | HTMLElement = null;

  @action.bound public resetKycAttempsLeft = async () => {
    this.showModal = false;
    this.closePanelMenu();
    this.inProgress = true;
    this.props.updateKycStatus();
    this.inProgress = false;
  };

  @action.bound public closePanelMenu = () => {
    this.menuAnchorEl = null;
  };

  @action.bound renderAttemptsLeft = () => {
    return this.props?.kycStatus?.attemptsLeft ? (
      <Box mr={2}>
        <Typography variant="body2">
          ( {this.props?.kycStatus?.attemptsLeft} KYC attempts left )
        </Typography>
      </Box>
    ) : (
      <Tooltip
        title={
          'User used all KYC attempts or has KYC blocked for some other reason. You can find reset button in panel’s menu.'
        }
        placement="top"
        enterDelay={500}>
        <Box mr={2}>
          <FontAwesomeIcon
            onMouseDown={(event) => event.stopPropagation()}
            icon={faInfoCircle}
            className={this.props.classes.faInfoCircle}
          />
        </Box>
      </Tooltip>
    );
  };

  @action.bound public openPanelMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    this.menuAnchorEl = event.currentTarget;
  };

  @computed public get menuItems(): IOptionsMenuProps['items'] {
    return [
      {
        label: 'Reset KYC Attempts',
        tooltipTitle: 'Conditions for KYC attempts reset are not met',
        disabled: !!this.props.kycStatus?.resetBlocked,
        onClick: () => (this.showModal = true),
      },
    ];
  }

  render() {
    const { children: cards, loading } = this.props;

    return (
      <>
        <DP>
          <DP.Header>
            <DP.Title panel>Cards</DP.Title>
            <DP.Actions>
              {this.inProgress ? (
                <DP.LoadSpinner />
              ) : (
                <>
                  {this.renderAttemptsLeft()}
                  <OptionsMenu items={this.menuItems} />
                </>
              )}
            </DP.Actions>
          </DP.Header>
          {!loading ? (
            <DP.List>
              {cards ? (
                cards.map((card) => (
                  <CardListItem
                    key={card.id}
                    isPrimary={this.props.primaryCardId === card.id}
                    payoutInterval={this.props.payoutInterval}
                    refreshCards={this.props.refreshCards}>
                    {card}
                  </CardListItem>
                ))
              ) : (
                <DP.Body>
                  <EmptyPanelMessage panelTitle="Cards" />
                </DP.Body>
              )}
            </DP.List>
          ) : (
            <DP.Loading items={3} size="large" />
          )}
        </DP>
        <Dialog
          open={this.showModal}
          title={'Rest KYC Attempts'}
          content={
            <Box>
              <Typography variant="body2">You will add 3 more attempts.</Typography>
            </Box>
          }
          onCancel={() => (this.showModal = false)}
          onConfirm={this.resetKycAttempsLeft}
          cancelActionName={'Cancel'}
          confirmActionName={'Add'}
          loading={this.inProgress}
        />
      </>
    );
  }
}

// export default CardsPanel;
export default withStyles(styles)(CardsPanel);
