import React, { Fragment } from 'react';
import { observable, action, flow, computed, makeObservable } from 'mobx';
import { observer } from 'mobx-react';
import { WithStyles, withStyles } from '@material-ui/core/styles';
import { Grid, Divider } from '@material-ui/core';

import Api from 'api';
import { inject, WithUserStore } from 'stores';
import { ScopeType } from 'stores/UserStore';
import { TalentIntegrationApp, User } from 'models';

import { LocationUser } from 'models';

import EmploymentPanel from 'components/EmploymentPanel';
import UserLocationPanel from 'components/UserLocationPanel';

import styles from './styles';

interface WorkspaceProps extends WithStyles<typeof styles>, WithUserStore {
  user?: User;
}

/** Displays the workspace settings for the user with :userId */
@inject('userStore')
@observer
class Workspace extends React.Component<WorkspaceProps> {
  public constructor(props: WorkspaceProps) {
    super(props);
    makeObservable(this);
  }

  componentDidMount() {
    this.getLocationUsers();
  }

  /** Store all location users for current user */
  @observable private locationUsers: LocationUser[] | null = null;

  @observable private missingAuthInfo?: TalentIntegrationApp[];

  @observable private usersScope: ScopeType = this.props.userStore!.scope.kind;

  /** Reduce getLocationUsers API response to accommodate render logic */
  @computed private get accountsForDisplay() {
    return (
      this.locationUsers &&
      this.locationUsers.reduce((accounts: any, locationUser: any) => {
        const accountId = locationUser.location.account.id;
        const accountLocations = accounts[accountId] ? { ...accounts[accountId].locations } : {};
        return {
          ...accounts,
          [accountId]: {
            ...locationUser.location.account,
            locations: {
              ...accountLocations,
              [locationUser.location.id]: { ...locationUser.location },
            },
          },
        };
      }, {})
    );
  }

  /** Fetches all location users for current user */
  @action.bound public getLocationUsers = flow(function* (this: Workspace) {
    const userStore = this.props.userStore!;
    const resp = yield Api.core.getUserLocationsV1(userStore.user!.id);

    const { data: missingAuthInfo } = yield Api.developer.getNonIntegratedApps();
    if (missingAuthInfo && missingAuthInfo.data) {
      this.missingAuthInfo = missingAuthInfo.data;
    }

    this.locationUsers = resp.data && resp.data.data;
  });

  getMissingAuthInfoForAccount(id: number): TalentIntegrationApp | undefined {
    if (!this.missingAuthInfo || !this.missingAuthInfo.length) return;
    return this.missingAuthInfo.find((info: TalentIntegrationApp) => info.account.id === id);
  }

  render() {
    const classes = this.props.classes;
    const userId = this.props.userStore!.user!.id;
    return (
      <>
        {this.accountsForDisplay &&
          Object.values(this.accountsForDisplay).map((account: any) => {
            const missingAuthInfo = this.getMissingAuthInfoForAccount(account.id);
            return (
              <Fragment key={account.id}>
                <Grid container direction={'row'} spacing={3}>
                  <Grid item md={6} sm={12}>
                    <Grid container direction={'column'} spacing={3}>
                      <Grid item sm={12}>
                        <EmploymentPanel
                          editable={false}
                          userId={userId}
                          account={account}
                          accountUser={null}
                          missingAuthInfo={missingAuthInfo}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item md={6} sm={12}>
                    <Grid container direction={'column'} spacing={3}>
                      {Object.values(account.locations).map((location: any) => {
                        const locationUser = this.locationUsers!.filter(
                          (locationUser) => locationUser.locationId === location.id,
                        )[0];
                        return (
                          <Grid key={location.id} item sm={12}>
                            <UserLocationPanel editable={false}>{locationUser}</UserLocationPanel>
                          </Grid>
                        );
                      })}
                    </Grid>
                  </Grid>
                </Grid>
                <Divider className={classes.divider} variant="middle" />
              </Fragment>
            );
          })}
      </>
    );
  }
}

export default withStyles(styles)(Workspace);
