import { createStyles, makeStyles } from '@material-ui/core';
import { Theme } from '@material-ui/core/styles';

const useStyles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    iconWrapperBase: {
      borderRadius: '50%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    iconWrapperLarge: {
      width: 171,
      height: 171,
    },
    iconWrapperMedium: {
      padding: 0,
      width: '67px',
      height: '67px',
    },
    iconWrapperSmall: {
      width: '50px',
      height: '50px',
    },
    iconGreen: {
      color: theme.palette.primary.main,
      backgroundColor: theme.palette.primary.light,
    },
    iconYellow: {
      color: theme.palette.warning.main,
      backgroundColor: theme.palette.warning.light,
    },
    iconPurple: {
      color: theme.palette.secondary.main,
      backgroundColor: theme.palette.secondary.light,
    },
    iconRed: {
      color: theme.palette.error.main,
      backgroundColor: theme.palette.error.light,
    },
  }),
);

export default useStyles;
