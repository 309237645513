import React from 'react';
import { Typography } from '@material-ui/core';
import { Invitation } from 'models';
import NotificationItem from '../NotificationItem/NotificationItem';
import useInvitationItem from './useInvitationItem';
import { observer } from 'mobx-react';
import { ILocationInvitationDialogProps } from './LocationInvitationDialog/LocationInvitationDialog';

export interface IInvitationItemProps {
  invitation: Invitation;
  setDialogProps: React.Dispatch<React.SetStateAction<ILocationInvitationDialogProps | null>>;
}

const InvitationItem = ({ invitation, setDialogProps }: IInvitationItemProps) => {
  const { accept, reject, message, expiration, loading } = useInvitationItem({
    invitation,
    setDialogProps,
  });
  return (
    <NotificationItem
      title="Invitation"
      body={
        <>
          <Typography variant="body1">{message}</Typography>
          <Typography variant="body2">{expiration}</Typography>
        </>
      }
      buttons={[
        { label: 'Accept', onClick: accept, loading },
        { label: 'Reject', onClick: reject },
      ]}
    />
  );
};

export default observer(InvitationItem);
