import React from 'react';
import Api from 'api';
import { action, makeObservable } from 'mobx';
import { observer } from 'mobx-react';
// Material UI
import {
  Box,
  Paper,
  Avatar,
  IconButton,
  Typography,
  TextField,
  InputAdornment,
} from '@material-ui/core';
import { WithStyles, withStyles } from '@material-ui/core/styles';
import { Close } from 'mdi-material-ui';
// Components, Containers
import UserSearch from 'components/UserSearch';
// Style, Models, API, Utils
import styles from './styles';
import { User } from 'models';
import { distribute } from '../../utils/helper';
import theme from 'containers/App/theme';

enum InputName {
  AMOUNT = 'amount',
  PERCENT = 'percent',
}
interface PendingTipsRecipientsProps extends WithStyles<typeof styles> {
  totalAmount: number;
  users: {
    userId: number;
    name: string;
    avatar: string;
    tipValue: number;
    tipPercent: number;
    touched: boolean;
  }[];
  filters:
    | {
        locationId: string;
        poolId?: string;
      }
    | Record<string, unknown>;
  accountId?: number;
  locationId?: number;

  distributionHandler: (d: Record<string, unknown>[]) => void;
}
@observer
class PendingTipsRecipients extends React.Component<PendingTipsRecipientsProps> {
  constructor(props: PendingTipsRecipientsProps) {
    super(props);
    makeObservable(this);
  }
  @action.bound addUser(u: User | null) {
    if (u !== null) {
      const user = {
        userId: u.id,
        name: `${u.firstName} ${u.lastName}`,
        avatar: u.avatar as string,
        tipValue: 0,
        tipPercent: 0,
        touched: false,
      };
      // if user is already displayed on the screen then do not push it into array
      const userExist: boolean = this.props.users.some((user) => user!.userId === u.id);
      if (userExist) {
        return;
      } else {
        this.props.users.push(user);
        this.calculateInputValues();
      }
    }
  }

  handleChange = (name: string, idx: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value as unknown as number;
    const { totalAmount } = this.props;
    this.props.users[idx].touched = true;

    if (name === InputName.AMOUNT) {
      this.props.users[idx].tipValue = parseFloat(value.toString());
      this.props.users[idx].tipPercent = distribute(2, 1, (value / totalAmount) * 100)[0];
    }

    if (name === InputName.PERCENT) {
      this.props.users[idx].tipValue = distribute(2, 1, totalAmount * (value / 100))[0];
      this.props.users[idx].tipPercent = parseFloat(value.toString());
    }

    this.calculateInputValues();
  };

  calculateInputValues(): void {
    const { totalAmount } = this.props;
    let editedUserCount = 0;
    let editedAmount = 0;
    let editedPercents = 0;

    this.props.users.forEach((user) => {
      if (user.touched) {
        editedPercents += user.tipPercent;
        editedAmount += user.tipValue;
        editedUserCount++;
      }
    });
    // recalculate
    const divider = this.props.users.length - editedUserCount;
    const distributions = distribute(2, divider, totalAmount - editedAmount);
    const distributionsPercents = distribute(2, divider, 100 - editedPercents);
    let index = 0;
    this.props.users.forEach((user) => {
      if (!user.touched) {
        user.tipValue = distributions[index];
        user.tipPercent = distributionsPercents[index];
        index++;
      }
    });

    // update distributions
    this.props.distributionHandler(this.props.users);
  }

  removeHandler(idx: number): void {
    this.props.users.splice(idx, 1);
    this.calculateInputValues();
  }

  renderRecipientBox(u: Record<string, any>, index: number) {
    const { classes } = this.props;
    const avatarSrc = u.avatar;
    const name = u.name;
    return (
      <Box key={`${u.id}-${index}`} className={classes.recipientBox} component={Paper}>
        <Avatar style={{borderRadius: 8}} variant="rounded" alt={'dfdf'} src={`${avatarSrc}`} />
        <Typography className={classes.boxLabel} align="left">
          {`${name}`}
        </Typography>

        <form noValidate autoComplete="off">
          <TextField
            style={{ marginRight: theme.spacing(6) }}
            error={u.tipValue < 0}
            type="number"
            size="small"
            id="filled-amount"
            hiddenLabel
            className={classes.textField}
            value={u.tipValue}
            onChange={this.handleChange(InputName.AMOUNT, index)}
            margin="normal"
            variant="filled"
            InputProps={{
              startAdornment: (
                <InputAdornment className={classes.inputAdornment} position="start">
                  $
                </InputAdornment>
              ),
            }}
          />
          <TextField
            style={{ marginRight: theme.spacing(10.125) }}
            error={u.tipPercent < 0 || u.tipPercent > 100}
            type="number"
            size="small"
            id="filled-percent"
            hiddenLabel
            className={classes.textField}
            value={u.tipPercent}
            onChange={this.handleChange(InputName.PERCENT, index)}
            margin="normal"
            variant="filled"
            InputProps={{
              startAdornment: (
                <InputAdornment className={classes.inputAdornment} position="start">
                  %
                </InputAdornment>
              ),
            }}
          />
        </form>
        <IconButton
          disableRipple
          classes={{ root: classes.iconButton }}
          onClick={() => this.removeHandler(index)}>
          <Close fontSize="small" />
        </IconButton>
      </Box>
    );
  }

  render() {
    const { filters, accountId, locationId } = this.props;
    const locId = filters && filters.locationId ? (filters.locationId as number) : locationId;
    return (
      <>
        <Box>
          <UserSearch
            onChange={this.addUser}
            fetch={{ method: Api.core.getDistributeTipsUsers }}
            autoFocus
            placeholder="Enter username, email, nickname"
            accountId={accountId}
            locationId={locId}
            displayAvatar
            displayOnlyTalents
          />
          {this.props.users &&
            this.props.users.map((user, idx) => this.renderRecipientBox(user, idx))}
        </Box>
      </>
    );
  }
}
export default withStyles(styles)(PendingTipsRecipients);
