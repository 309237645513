import { useState } from 'react';
import { IAccountLogo } from './AccountLogo';

const useAccountLogo = ({
  account,
  refreshAccount,
}: Omit<IAccountLogo, 'logo' | 'logoEditable'>) => {
  const [open, setOpen] = useState(false);
  const logo = account?.logo;

  const handleDialogOpen = () => {
    setOpen(true);
  };
  const handleDialogClose = () => {
    setOpen(false);
  };

  const logoDialogProps = {
    open,
    account,
    refreshAccount,
    onClose: handleDialogClose,
  };
  return {
    logo,
    logoDialogProps,
    handleDialogOpen,
  };
};

export default useAccountLogo;
