import { BankAccount } from 'models';

/**
 * This file defines some utility functions for dealing with banks.
 * They were extracted here in order to avoid code duplication in
 * components.
 */

/**
 * An interface for tracking bank statuses on the front-end. We can't
 * get all the information from the verificationStatus field because
 * the status can depend on other fields.
 */
export type BankStatus =
  | 'active'
  | 'pending_manual_verification'
  | 'pending_automatic_verification'
  | 'action_required'
  | 'inactive';

export enum BankAccountStatus {
  VERIFICATION_EXPIRED = 'verification_expired',
  PENDING_MANUAL_VERIFICATION = 'pending_manual_verification',
  PENDING_AUTOMATIC_VERIFICATION = 'pending_automatic_verification',
  VERIFIED = 'verified',
  MANUALLY_VERIFIED = 'manually_verified',
  AUTOMATICALLY_VERIFIED = 'automatically_verified',
  MANUALLY_ENTERED = 'manually_entered',
  DUPLICATE = 'duplicate',
  DELETED = 'deleted',
  UNKNOWN = 'unknown',
  /**
   * DISABLED => Used for active bank accounts after branch card successfully added
   */
  DISABLED = 'disabled',
}

/** A mapping from bank statuses to messages */
export const statusMessages: { [k in BankStatus]: { msg: string; tooltip: string } } = {
  active: { msg: 'Active', tooltip: 'Bank account is active' },
  pending_automatic_verification: {
    msg: 'Waiting for verification',
    tooltip: 'Your account should be automatically verified soon',
  },
  pending_manual_verification: {
    msg: 'Awaiting micro-deposits',
    tooltip: 'Enter the verification amounts to activate your account',
  },
  inactive: { msg: 'Inactive', tooltip: 'Bank account is inactive' },
  action_required: { msg: 'Action required', tooltip: 'Some action is required' },
};

/**
 * Takes a bank and returns a status
 */
export function getStatus(bankAccount: BankAccount): BankStatus {
  if (bankAccount.verificationStatus === 'pending_manual_verification') {
    return 'pending_manual_verification';
  }
  if (bankAccount.verificationStatus === 'pending_automatic_verification') {
    return 'pending_automatic_verification';
  }
  if (bankAccount.errorCode === 'ITEM_LOGIN_REQUIRED') {
    return 'action_required';
  }
  if (!bankAccount.isActive) {
    return 'inactive';
  }
  return 'active';
}

export function parseStatus(bankAccount: BankAccount): string {
  const status = bankAccount.verificationStatus || BankAccountStatus.UNKNOWN;
  return status
    .split('_')
    .map((e) => e.charAt(0).toUpperCase() + e.slice(1))
    .join(' ');
}
