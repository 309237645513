import { Theme, createStyles, makeStyles } from '@material-ui/core';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const styles = (theme: Theme) =>
  createStyles({
    dialog: {
      '& .MuiDialogContent-root': {
        width: 576,
      },
    },
    dialogTitle: {
      padding: theme.spacing(3),
      paddingBottom: 0,
    },
    dialogActions: {
      display: 'flex',
      padding: theme.spacing(3),
      marginTop: theme.spacing(2),
    },
    dialogContent: {
      padding: 0,
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
      marginTop: theme.spacing(4),
    },
  });

export default styles;

export const useStyles = makeStyles(styles);
