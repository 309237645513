/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import {
  Box,
  Divider,
  IconButton,
  Button,
  Typography,
  Icon,
  List,
  ListItem as MuiListItem,
  ListItemText,
  Slide,
  CircularProgress,
} from '@material-ui/core';
import { ButtonProps } from '@material-ui/core/Button';
import { Close, ChevronLeft } from 'mdi-material-ui';
import { observer } from 'mobx-react';
import useStyles from './styles';
import useDashPanelStyles from '../DashPanel/styles';
import PlusFabButton from 'components/PlusFabButton/PlusFabButton';
import clsx from 'clsx';
import { default as TippyButton } from 'components/Button/Button';

/**
 * A component that is used to build drawer interfaces so w
 * don't have to repeat a lot of tedious css.
 */
const DashDrawer = ({
  children,
  overflow,
}: {
  children: React.ReactNode;
  overflow?: 'hidden' | 'visible' | 'scroll' | 'auto';
}) => {
  const styles = useStyles();
  return (
    <Box className={styles.root} style={{ overflow }}>
      {children}
    </Box>
  );
};

/**
 * The content for the drawer
 */
DashDrawer.Content = observer(
  ({ children, className }: { children: React.ReactNode; className?: string }) => {
    const styles = useStyles();
    return <Box className={clsx(styles.filterDrawerInner, className)}>{children}</Box>;
  },
);

DashDrawer.Actions = observer(
  ({ children, drawer }: { children: React.ReactNode; drawer?: boolean }) => {
    const styles = useStyles();
    const classes = drawer ? styles.drawerActions : styles.actions;
    return <Box className={classes}>{children}</Box>;
  },
);

/**
 * @param padding Use this parameter to set padding of the title to
 */
DashDrawer.Title = observer(
  ({
    onClose,
    children,
    onBack,
    loading,
    fontSize,
    showDivider,
    padding,
  }: {
    children: React.ReactNode;
    onClose?: () => void;
    onBack?: () => void;
    loading?: boolean;
    fontSize?: number;
    showDivider?: boolean;
    padding?: boolean;
  }) => {
    const styles = useStyles();
    return (
      <Box mb={2}>
        <Box
          className={clsx(padding && styles.titleWrapper)}
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center">
          <Box display="flex" flexDirection="row" alignItems="center">
            {onBack && (
              <IconButton onClick={onBack}>
                <ChevronLeft />
              </IconButton>
            )}
            <Typography component="h1" style={{ fontSize: fontSize || 28 }}>
              {children}
            </Typography>
          </Box>
          <Box>
            {onClose && (
              <IconButton onClick={onClose}>
                <Close />
              </IconButton>
            )}
            {loading && <CircularProgress />}
          </Box>
        </Box>
        {showDivider && <Divider />}
      </Box>
    );
  },
);

/** Used to display a list of DashDrawer.ListItem */
DashDrawer.List = observer(({ children }: { children: React.ReactNode }) => {
  return <List>{children}</List>;
});

DashDrawer.Row = observer(
  ({ children, className }: { children: React.ReactNode; className?: string }) => {
    const styles = useStyles();
    return <Box className={clsx(styles.row, className)}>{children}</Box>;
  },
);

DashDrawer.Item = observer(
  ({
    children,
    className,
    fullWidth,
  }: {
    children: React.ReactNode;
    className?: string;
    fullWidth?: boolean;
  }) => {
    const styles = useStyles();
    const width = fullWidth ? '100%' : 183;
    return (
      <Box style={{ width }} className={clsx(styles.item, className)}>
        {children}
      </Box>
    );
  },
);

/** Used to display a label of a field. Best used as a direct child of DashPanel.Row */
DashDrawer.Label = observer(({ children }: { children: React.ReactNode }) => {
  const { label } = useDashPanelStyles();
  return <Box className={label}>{children}</Box>;
});

/** Used to display a value of a field. Best used as a direct child of DashPanel.Row */
DashDrawer.Value = observer(
  ({ children, color }: { children: React.ReactNode; color?: string }) => {
    const { value } = useStyles();
    color = color ? color : '';

    return (
      <Box style={{ color }} className={value}>
        {children}
      </Box>
    );
  },
);

interface ListItemProps {
  /** The primary text to display, can be any react node */
  primary: React.ReactNode;
  /** The secondary text */
  secondary?: React.ReactNode;
  /** OnClick action */
  onClick?(): void;
}

/** Used to display a dash drawer list item */
DashDrawer.ListItem = observer(({ primary, secondary, onClick }: ListItemProps) => {
  const { listItemKey, listItemValue, listItemIcon } = useStyles();

  const muiListItemContent = (
    <>
      <ListItemText className={listItemKey} primary={primary} />
      <Typography variant={'body2'} className={listItemValue}>
        {secondary}
      </Typography>
      <Icon className={listItemIcon}>keyboard_arrow_right</Icon>
    </>
  );

  if (onClick) {
    return (
      <>
        <MuiListItem style={{ padding: 0 }} button onClick={onClick}>
          {muiListItemContent}
        </MuiListItem>
      </>
    );
  }

  return (
    <>
      <MuiListItem>{muiListItemContent}</MuiListItem>
    </>
  );
});

DashDrawer.Subtitle = observer(
  ({
    children,
    color,
  }: {
    children: React.ReactNode;
    color?:
      | 'initial'
      | 'inherit'
      | 'primary'
      | 'secondary'
      | 'textPrimary'
      | 'textSecondary'
      | 'error';
  }) => {
    return (
      <Box mb={2}>
        <Typography variant="h5" component="h5" color={color ? color : 'textPrimary'}>
          {children}
        </Typography>
      </Box>
    );
  },
);

/** A sub-drawer for displaying overlaying content in the drawer */
DashDrawer.SubDrawer = observer(
  ({
    children,
    display,
    hiddienBoxShadow,
  }: {
    children: React.ReactNode;
    display: boolean;
    hiddienBoxShadow?: boolean;
  }) => {
    const { subdrawer } = useStyles();
    return (
      <Slide in={display} mountOnEnter unmountOnExit direction="left">
        <Box className={subdrawer} boxShadow={hiddienBoxShadow ? 'none' : 1}>
          {children}
        </Box>
      </Slide>
    );
  },
);

DashDrawer.ApplyButton = observer((props: ButtonProps) => {
  return <TippyButton variant="contained" fullWidth color="primary" {...props} />;
});

DashDrawer.ResetButton = observer((props: ButtonProps) => {
  return (
    <Box mb={2}>
      <Button variant="text" fullWidth color="primary" {...props} />
    </Box>
  );
});

DashDrawer.FabButton = observer((props: any) => {
  return <PlusFabButton {...props} />;
});

export default DashDrawer;
