import React from 'react';
import { Button as MuiButton, ButtonProps } from '@material-ui/core';
import { useStyles } from './styles';
import clsx from 'clsx';
import { CircularProgress } from '@mui/material';

export type TButtonProps = ButtonProps & {
  component?: React.ElementType;
  to?: string;
  loading?: boolean;
  underline?: boolean;
  textTransform?: React.CSSProperties['textTransform'];
};

export default function Button({
  children,
  loading,
  variant = 'contained',
  textTransform,
  underline,
  style,
  ...rest
}: TButtonProps) {
  const { font, progress, button, outlinedButton, textButton, underlineStyles } = useStyles({
    variant,
    disabled: rest.disabled,
  });
  const outlined = !!(variant === 'outlined');
  let styles = button;
  switch (variant) {
    case 'outlined':
      styles = outlinedButton;
      break;
    case 'text':
      styles = textButton;
      rest.disableTouchRipple = true;
      rest.focusRipple = true;
      break;
    default:
      styles = button;
  }
  return (
    <MuiButton
      disableElevation
      {...rest}
      style={{ textTransform, ...style, cursor: 'pointer' }}
      variant={variant}
      className={clsx(styles, font, underline && underlineStyles, rest.className)}>
      {loading ? <CircularProgress classes={{ circle: progress }} /> : children}
    </MuiButton>
  );
}
