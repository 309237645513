import { Theme, createStyles, makeStyles } from '@material-ui/core';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const styles = (theme: Theme) =>
  createStyles({
    root: {},
    secondaryItemsWrapper: {
      fontSize: '12px',
      fontStyle: 'italic',
      letterSpacing: '0.1rem',
      color: theme.palette.grey[600],
    },
    removeButton: {
      height: '0px',
      textTransform: 'none',
      '&.MuiButton-root:hover': {
        backgroundColor: 'transparent',
      },
    },
    editButton: {
      textTransform: 'none',
      '&.MuiIconButton-root:hover': {
        backgroundColor: 'transparent',
      },
    },
  });

export default styles;

export const useStyles = makeStyles(styles);
