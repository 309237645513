import { inject, observer } from 'mobx-react';
import React from 'react';
import { Avatar, Backdrop, Box, Typography, WithStyles } from '@material-ui/core';
import { action, makeObservable, observable } from 'mobx';
import AnonLayout from 'components/AnonLayout';
import styles from './styles';
import { withStyles } from '@material-ui/styles';
import { ChevronLeft, ChevronRight } from 'mdi-material-ui';
import { BankAccount } from 'models';
import { WithEnrollStore, WithToastStore } from 'stores';
import Api, { getErrorMsg } from '../../api';
import { parseStatus } from 'services/banks';
import { RebateStatus } from 'models/RebatePartners';
import clsx from 'clsx';
import { DATE_TYPE, formatLocalDateTime } from 'utils/helper';
import BankAccountWizzard from 'components/BankAccountWizzard/BankAccountWizzard';
import Button from 'components/Button/Button';
import { OverlayLoader } from 'components/Loader/OverlayLoader/OverlayLoader';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

type EnrollmentWizzardProps = WithStyles<typeof styles> &
  WithEnrollStore &
  WithToastStore & {
    accountId: number;
    partnerId: number;
    initState?: EnrollState;
    exitEnrollmentWizzard: () => void;
  };

export interface IEnrolmentWizzard {
  accountId: number;
  partnerId: number;
  initState?: EnrollState;
  exitEnrollmentWizzard: () => void;
}

export enum EnrollState {
  INIT = 'init',
  BANK_ACCOUNT = 'bankAccount',
  ADD_BANK_ACCOUNT = 'addBankAccount',
  ENROLLING = 'enrolling',
  SUCCESS = 'success',
}

@inject('toastStore', 'enrollStore')
@observer
class EnrollmentWizzard extends React.Component<EnrollmentWizzardProps> {
  constructor(props: EnrollmentWizzardProps) {
    super(props);
    makeObservable(this);
  }
  @observable bankAccounts?: BankAccount[];
  @observable enrolledBankAccount?: BankAccount;
  @observable enrollState: EnrollState = this.props.initState
    ? this.props.initState
    : EnrollState.INIT;
  @observable changeBankAccount: boolean = this.props.initState === EnrollState.BANK_ACCOUNT;
  @observable rebateStartDate?: string;

  @action.bound setEnrollState(enrollState: EnrollState) {
    this.enrollState = enrollState;
  }

  @action.bound cancelAddBankAccount = () => {
    this.setEnrollState(EnrollState.BANK_ACCOUNT);
  };

  @action.bound addNewBankAccount = () => {
    this.setEnrollState(EnrollState.ADD_BANK_ACCOUNT);
  };

  componentDidMount() {
    this.fetchBankAccounts();
  }

  getVerifiedBankAccounts = (bankAccounts: BankAccount[]) => {
    const verifiedAccounts = bankAccounts.filter(
      (bankAccount: BankAccount) =>
        bankAccount.verificationStatus === 'verified' ||
        bankAccount.verificationStatus === 'manually_verified',
    );
    return verifiedAccounts;
  };

  @action.bound private fetchBankAccounts = async () => {
    try {
      const accountId = this.props.accountId;
      const { data } = await Api.core.getAccountBanks(accountId);
      let bankAccounts = data.data;
      bankAccounts = this.getVerifiedBankAccounts(bankAccounts);
      if (bankAccounts) this.bankAccounts = bankAccounts.length === 0 ? null : bankAccounts;
    } catch (e) {
      this.props.toastStore!.push({ type: 'error', message: getErrorMsg(e) });
    }
  };

  handleAddBankAccount = (success: boolean) => {
    if (success) {
      this.fetchBankAccounts();
    }
  };

  addBankAccountDialog = () => {
    //expose errors from bank account wizzard
    return (
      <BankAccountWizzard
        open={this.enrollState === EnrollState.ADD_BANK_ACCOUNT}
        accountId={this.props.accountId}
        onClose={this.cancelAddBankAccount}
        onAdd={this.handleAddBankAccount}
      />
    );
  };

  renderBankAccountCard = () => {
    const changeBankAccount = this.changeBankAccount;
    const displayChevron = changeBankAccount ? 'hidden' : 'visible';
    const { cardActions, cancelButton, bankAccountCardBody, subtitle3, addBankButton } =
      this.props.classes;
    const variant =
      this.bankAccounts && this.enrollState !== EnrollState.ENROLLING ? 'contained' : 'text';
    const hasBankAccounts = this.bankAccounts && this.enrollState !== EnrollState.ENROLLING;
    return (
      <>
        <Box width="100%" display="flex" alignItems={'center'}>
          <ChevronLeft
            style={{ cursor: 'pointer', visibility: displayChevron }}
            onClick={() => this.setEnrollState(EnrollState.INIT)}
            textAnchor="left"
          />
          <Typography
            style={{ position: 'absolute', left: 0, right: 0, fontSize: 28, fontWeight: 400 }}
            align="center">
            Bank account
          </Typography>
        </Box>
        <Box mt={4}>
          <Typography variant="subtitle1" align="center">
            Choose a bank account on which you want to receive you rebates.
          </Typography>
        </Box>
        <Box mt={hasBankAccounts ? 5 : 4} className={bankAccountCardBody}>
          {hasBankAccounts ? (
            this.renderBankAccounts()
          ) : this.bankAccounts === null ? (
            <Typography className={subtitle3} align="center">
              You have no bank accounts yet. Please add a bank account to continue.
            </Typography>
          ) : (
            <OverlayLoader display={true} />
          )}
        </Box>
        <Box className={cardActions}>
          <Button
            className={clsx(hasBankAccounts && addBankButton)}
            color="primary"
            onClick={this.addNewBankAccount}
            variant={variant}
            fullWidth>
            Add new bank account
          </Button>
          <Button
            className={cancelButton}
            onClick={this.props.exitEnrollmentWizzard}
            variant="text"
            color="inherit"
            fullWidth>
            Cancel
          </Button>
        </Box>
      </>
    );
  };

  getBankAccountAvatar = (bankAccount: BankAccount) => {
    return (
      <Avatar
        src={
          bankAccount.institutionLogo
            ? `data:image/png;base64,${bankAccount.institutionLogo}`
            : undefined
        }
        alt={bankAccount.institutionName ? bankAccount.institutionName : 'Unknown bank name'}
      />
    );
  };

  rebateStartDateMessage = () => {
    const date = formatLocalDateTime(this.rebateStartDate, DATE_TYPE.DATE);
    if (date) return date;
    return 'date unavailable';
  };

  enrollWithSelectedBankAccount = async (bankAccountId: number) => {
    this.setEnrollState(EnrollState.ENROLLING);
    const { accountId, partnerId } = this.props;
    const rebateStatus = this.changeBankAccount ? undefined : RebateStatus.ACTIVATED;
    const _data = { bankAccountId, rebateStatus };
    try {
      const { data } = await Api.tips.updateRebatePartner(accountId, partnerId, _data);
      this.rebateStartDate = data.data.rebateStartDate;
      this.enrolledBankAccount = this.bankAccounts!.find((bankAccount: BankAccount) => {
        if (bankAccount.id === bankAccountId) return bankAccount;
      });
      this.setEnrollState(EnrollState.SUCCESS);
    } catch (e) {
      this.props.toastStore!.push({ type: 'error', message: getErrorMsg(e) });
      this.setEnrollState(EnrollState.BANK_ACCOUNT);
    }
  };

  renderBankAccounts = () => {
    const { bankAccountItem } = this.props.classes;
    const bankAccounts = this.bankAccounts!.map((bankAccount: BankAccount) => {
      const icon = this.getBankAccountAvatar(bankAccount);
      return (
        <Box
          className={bankAccountItem}
          onClick={() => this.enrollWithSelectedBankAccount(bankAccount.id)}
          key={bankAccount.accountId}>
          {icon}
          <Box ml={2}>
            <Typography variant="body1">
              {bankAccount.institutionName} - {bankAccount.accountName} - {bankAccount.accountMask}
            </Typography>
            <Typography style={{ fontStyle: 'italic' }} variant="subtitle1">
              {parseStatus(bankAccount)}
            </Typography>
          </Box>
          <Box ml={'auto'}>
            <ChevronRight />
          </Box>
        </Box>
      );
    });
    return bankAccounts;
  };

  formatBankAccountData = () => {
    const { institutionName, accountName, accountMask } = this.enrolledBankAccount!;

    const bankAccountData = `
      ${institutionName} - ${accountName} - ${accountMask}
    `;
    return bankAccountData;
  };

  renderSuccessCard = () => {
    return (
      <>
        <Typography style={{ textAlign: 'center', fontSize: 28, fontWeight: 400 }}>
          Success
        </Typography>
        <Box mt={5}>
          <CheckCircleIcon className={this.props.classes.successIcon} />
        </Box>
        <Box mt={3}>
          <Typography variant="subtitle1" align="center">
            {`You are enrolled for rebate and your bank account ${this.formatBankAccountData()} was succesfully
          added. Rebate will start on ${this.rebateStartDateMessage()}`}
          </Typography>
        </Box>
        <Box mt={4} width={'100%'}>
          <Button onClick={this.goToDashboard} variant="contained" color="primary" fullWidth>
            GO TO DASHBOARD
          </Button>
        </Box>
      </>
    );
  };

  goToDashboard = () => {
    this.props.enrollStore!.updateEnrollRebateKey();
    this.rebateStartDate = undefined;
    this.props.exitEnrollmentWizzard();
  };

  renderEnrollCard = () => {
    return (
      <>
        <Typography style={{ textAlign: 'center', fontSize: 28, fontWeight: 400 }}>
          Tippy Rebate Program
        </Typography>
        <Box className={this.props.classes.rebateSpacingMedium} style={{ maxHeight: '400px' }}>
          <Typography align="justify" variant="subtitle1">
            With Tippy’s rebate program, you earn 2% back on every tip payment that is processed
            through your Tippy + POS Integration. Your rebate will be automatically paid to you on a
            monthly basis! Check out your dashboard to track your rebate.
          </Typography>
        </Box>
        <Box mt={4} style={{width: '100%'}}>
          <Button
            fullWidth
            color="primary"
            variant="contained"
            onClick={() => this.setEnrollState(EnrollState.BANK_ACCOUNT)}>
            ENROLL
          </Button>
          <Button
            className={this.props.classes.cancelButton}
            onClick={this.props.exitEnrollmentWizzard}
            variant="text"
            color="inherit"
            fullWidth>
            Cancel
          </Button>
        </Box>
      </>
    );
  };

  renderWizzardBody = () => {
    const enrollState = this.enrollState;
    let card = this.renderEnrollCard;
    switch (enrollState) {
      case EnrollState.INIT:
        card = this.renderEnrollCard;
        break;
      case EnrollState.BANK_ACCOUNT:
        card = this.renderBankAccountCard;
        break;
      case EnrollState.ADD_BANK_ACCOUNT:
        return this.addBankAccountDialog();
      case EnrollState.ENROLLING:
        return this.renderEnrollingCard();
      case EnrollState.SUCCESS:
        card = this.renderSuccessCard;
        break;
      default:
        card = this.renderEnrollCard;
    }
    return <Box className={this.props.classes.cardWrapper}>{card()}</Box>;
  };

  renderEnrollingCard = () => {
    return (
      <Box
        style={{ width: '300px', height: '500px' }}
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"></Box>
    );
  };

  render() {
    const body = this.renderWizzardBody();
    return (
      <Backdrop className={this.props.classes.backdrop} open={true}>
        <AnonLayout inProgress={this.enrollState === EnrollState.ENROLLING} applyFooter={true}>
          {body}
        </AnonLayout>
      </Backdrop>
    );
  }
}

export default withStyles(styles)(EnrollmentWizzard);
