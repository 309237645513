import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { IAlertProps } from './Alert';

interface IStyleProps {
  buttonsPosition?: IAlertProps['buttonsPosition'];
}

const useStyles = makeStyles<Theme, IStyleProps>((theme: Theme) =>
  createStyles({
    dialogTitle: {
      paddingBottom: 0,
    },
    dialogActions: {
      display: 'flex',
      justifyContent: ({ buttonsPosition }) => (buttonsPosition ? buttonsPosition : 'space-evenly'),
      padding: theme.spacing(3),
      marginTop: theme.spacing(2),
    },
    dialogContent: {
      padding: 0,
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
      marginTop: theme.spacing(4),
    },
  }),
);

export default useStyles;
