import { Theme, createStyles, makeStyles } from '@material-ui/core';

interface IStyleProps {
  width?: number;
}

const useStyles = makeStyles<Theme, IStyleProps>((theme: Theme) =>
  createStyles({
    link: {
      width: ({ width }) => (width ? width : 132),
    },
  }),
);

export default useStyles;
