import { Theme, createStyles } from '@material-ui/core';
import theme from 'containers/App/theme';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default (theme: Theme) =>
  createStyles({
    root: {},
    loadingSkeleton: {
      margin: '6px 0',
    },
    userLink: {
      width: '100%',
    },
    tooltip: {
      textTransform: 'capitalize',
    },
    noDataText: {
      color: theme.palette.text.secondary,
    },
    dialogActions: {
      padding: 0,
      paddingBottom: theme.spacing(1),
      paddingTop: theme.spacing(5),
    },
  });

export const invoiceStyling = {
  display: 'flex',
  alignItems: 'center',
  '& .invoice-number': {
    marginRight: theme.spacing(0.5),
  },
  '& .invoice-icon': {
    color: theme.palette.primary.main,
    fontSize: 18,
  },
};
