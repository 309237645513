import theme from 'containers/App/theme';

export const referralPanelStyling = {
  margin: theme.spacing(3, 0),
  '& .card': {
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
      alignItems: 'center',
      flexWrap: 'wrap',
      gap: theme.spacing(2),
      justifyContent: 'space-between',
    },
  },
  '& .info': {
    alignItems: 'center',
    display: 'flex',
    gap: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      marginBottom: theme.spacing(2),
    },
  },
  '& .subtitle': {
    fontSize: 24,
    fontWeight: 700,
  },
  '& .text': {
    color: theme.palette.text.secondary,
  },
  '& .info-icon-box': {
    alignItems: 'center',
    backgroundColor: theme.palette.primary.light,
    borderRadius: '50%',
    display: 'inline-flex',
    height: 56,
    justifyContent: 'center',
    minWidth: 56,
    width: 56,
  },
  '& .info-icon': {
    color: theme.palette.primary.main,
  },
  '& .refer-link': {
    textTransform: 'none',
    height: 57,
    width: 310,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
};
