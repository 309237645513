import { makeStyles, Theme, createStyles } from '@material-ui/core';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      width: '100%',
      height: '100%',
      padding: theme.spacing(4),
    },
    header: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    title: {
      fontSize: 28,
      marginBottom: theme.spacing(5),
    },
    itemAmount: {
      textTransform: 'uppercase',
      fontWeight: '700 !important',
    } as any,
    backdrop: {
      backgroundColor: theme.palette.background.default,
      zIndex: theme.zIndex.drawer + 1,
      overflow: 'auto',
    },
    container: {
      maxWidth: '576px',
    },
    containerInner: {
      padding: theme.spacing(5, 4),
    },
    bodyOverflow: {
      overflow: 'hidden',
    },
    upper: {
      textTransform: 'uppercase',
    },
    whiteText: {
      color: theme.palette.common.white,
    },
    miniText: {
      fontSize: 12,
    },
    pig: {
      height: theme.spacing(12),
      marginBottom: theme.spacing(4),
    },
    img: {
      height: '50%',
    },
    errorBox: {
      display: 'flex',
      flexDirection: 'column',
    },
    errorMsg: {
      color: theme.palette.error.main,
      margin: theme.spacing(8, 0),
    },
  });

export default styles;

export const useStyles = makeStyles(styles);
