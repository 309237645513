import { alpha, createTheme, Theme } from '@material-ui/core/styles';
import { createStyles, makeStyles } from '@material-ui/styles';
import theme from 'containers/App/theme';
import theme_new from 'containers/App/theme_new';

const defaultTheme = createTheme();
export const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      root: {
        '&.MuiDataGrid-root': {
          border: 'none',
          borderRadius: theme.shape.borderRadius,
          '&>.MuiDataGrid-footerContainer': {
            '&>.MuiBox-root': {
              color: theme.typography.body2.color,
              display: 'flex',
              justifyContent: 'space-between',
              '-ms-flex-pack': 'space-between',
              '-webkit-justify-content': 'space-between',
            },
          },
        },
        '&>.MuiDataGrid-main': {
          '&>.MuiDataGrid-columnHeaders': {
            ...theme.typography.body2,
          },
          '&>.MuiDataGrid-virtualScroller': {
            '&>.MuiDataGrid-virtualScrollerContent': {
              '&>.MuiDataGrid-virtualScrollerRenderZone': {
                '&>.MuiDataGrid-row': {
                  '&>.MuiDataGrid-withBorderColor': {
                    borderTop: `1px solid ${alpha(theme.palette.divider, 0.1)}`,

                    borderBottom: `1px solid ${alpha(theme.palette.divider, 0.1)}`,
                  },
                },
              },
            },
          },
        },
      },
      icon: {
        fontSize: 24,
        color: theme.palette.text.secondary,
      },
      pinToLeftIcon: {
        transform: 'rotate(45deg)',
      },
      pinToRightIcon: {
        transform: 'rotate(-45deg)',
      },
      columnMenu: {
        '& .MuiMenuItem-root': {
          gap: 16,
          '& .MuiListItemIcon-root': {
            minWidth: 30,
            width: 30,
            display: 'flex',
            justifyContent: 'center',
          },
        },
      },
      switch: {
        marginRight: theme.spacing(1),
      },
      button: {
        paddingTop: theme.spacing(1.1),
        paddingBottom: theme.spacing(0.65),
        textTransform: 'uppercase',
      },
      manageColumnsWrapper: {
        padding: theme.spacing(2, 0),
      },
      manageColumnsContent: {
        marginTop: theme.spacing(3),
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(1.5),
        padding: theme.spacing(0, 2),
        overflow: 'auto',
        flexGrow: 1,
        maxHeight: 260,
      },
      manageColumnsButtons: {
        marginTop: theme.spacing(3),
        display: 'flex',
        justifyContent: 'flex-end',
        gap: theme.spacing(2),
        padding: theme.spacing(0, 2, 0, 2),
      },
    }),
  { defaultTheme },
);

export const manageColumnsButtons = {
  border: `1px solid ${theme.palette.primary.main}`,
  borderRadius: theme_new.spacing(1),
  height: 'auto',
  padding: theme_new.spacing(0.25, 2),
  textTransform: 'none',
  '&:hover': {
    backgroundColor: 'transparent',
    color: theme.palette.primary.hover,
  },
};

export const datagridStyles = (disableToolbar?: boolean) => {
  return {
    width: '100%',
    borderRadius: theme_new.spacing(2),
    border: 'none',
    background: theme.palette.background.paper,
    '& .MuiDataGrid-withBorderColor': {
      borderTop: disableToolbar ? 'none' : `1px solid ${alpha(theme.palette.divider, 0.1)}`,
      borderBottom: `1px solid ${alpha(theme.palette.divider, 0.1)}`,
    },
    '& .MuiDataGrid-virtualScrollerRenderZone': {
      '& > div:hover, & > div.Mui-hovered': {
        backgroundColor: theme.palette.background.default,
        transition: 'none',
      },

      '.MuiDataGrid-row > :first-child': {
        paddingLeft: 2,
      },
      '.MuiDataGrid-row > :last-child': {
        paddingRight: 2,
      },
    },
    '& .MuiDataGrid-columnHeader, & .MuiDataGrid-cell': {
      outline: 'none !important',
    },
    '.MuiDataGrid-columnHeadersInner > :first-child': {
      '& > :first-child': {
        paddingLeft: 2,
      },
      '& > :last-child': {
        paddingLeft: 2,
      },
    },
    '& .MuiDataGrid-toolbarContainer': {
      padding: 2,
      gap: theme_new.spacing(2),
    },
    '& .MuiDataGrid-columnHeader': {
      '&.MuiDataGrid-columnHeaderTitle': {
        color: theme.palette.text.secondary,
        fontWeight: 400,
      },
      '&.with-action': {
        '& .MuiButtonBase-root': {
          height: '26px',
          padding: theme.spacing(0.5, 1.75),
        },
      },
    },
    '& .MuiDataGrid-cell': {
      color: theme.palette.text.primary,
    },
    '& .MuiDataGrid-footerContainer': {
      width: '100%',
      padding: 0,
      height: 'auto',
      border: 'none',
      '& .MuiBox-root': {
        color: theme.palette.text.secondary,
        fontSize: '16px',
        lineHeight: '20.29px',
        justifyContent: 'flex-end',
      },
    },
    '& .capitalize': {
      textTransform: 'capitalize',
    },
    // TODO: Plugin expired message hidden
    '& .MuiDataGrid-virtualScroller': {
      minHeight: '80px',
      '& .no-rows': {
        backgroundColor: theme.palette.background.default,
        height: '40px',
        alignItems: 'center',
        justifyContent: 'center',
      },
      '& + div': {
        display: 'none',
      },
    },
  };
};
