/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { RouteComponentProps, Link as RouterLink } from 'react-router-dom';
import { observable, action, flow, computed, makeObservable } from 'mobx';
import qs from 'qs';
import * as JWT from 'jwt-decode';

import { observer } from 'mobx-react';
import { WithStyles, Box, Link, withStyles } from '@material-ui/core';
import validatorjs from 'validatorjs';

import { paths } from 'routes';
import Api, { getErrorMsg } from 'api';
import { User } from 'models';
import { WithToastStore, WithUserStore, inject } from 'stores';
import { setTitle } from 'services/title';

import PasswordField from 'components/form/PasswordField';

import styles from './styles';
import CarouselScreenWrapper from 'components/CarouselScreenWrapper/CarouselScreenWrapper';
import OutlinedInput from 'components/Input/OutlinedInput/OutlinedInput';
import SignupLayout, { TSignupLayoutAction } from 'containers/SignupLayout/SignupLayout';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const dvr = require('mobx-react-form/lib/validators/DVR');
const MobxReactForm = require('mobx-react-form').default;

const plugins = {
  dvr: dvr({
    package: validatorjs,
    extend: ({ validator }: { validator: any; form: any }) => {
      const customValidationMessages = {
        ...validator.getMessages('en'),
        email: 'The email format is invalid',
        same: 'Please input the same password again',
        required: 'This field is required',
        min: ':attribute must be at least :min characters long',
      };
      validator.setMessages('en', customValidationMessages);
    },
  }),
};

interface SignUpProps
  extends WithStyles<typeof styles>,
    WithToastStore,
    WithUserStore,
    RouteComponentProps {
  affiliate?: boolean;
  accountSignup?: boolean;
  managerSignup?: boolean;
}

interface SignUpFormHooks {
  onSuccess: (form: any) => void;
  onClear: (form: any) => void;
}

// When signing up manager via invitation link we use the following jwt interface
interface JWTPayload {
  requestedById: number;
  email: string;
  locationId: number;
  addAsManager: boolean;
  iat: number;
  exp: number;
}

const formId = 'sign-up-form';

/**
 * The signup screen container component.
 */
@inject('toastStore', 'userStore')
@observer
class SignUp extends React.Component<SignUpProps> {
  public fields = [
    {
      name: 'email',
      label: this.emailLabel,
      rules: 'required|email|string',
    },
    {
      name: 'password',
      label: 'Password',
      type: 'password',
      rules: 'required|string|min:8',
    },
    {
      name: 'passwordConfirm',
      label: 'Confirm password',
      type: 'password',
      rules: 'required|string|same:password',
    },
  ];
  public constructor(props: SignUpProps) {
    super(props);
    makeObservable(this);
    // Initialize the form
    this.form = new MobxReactForm({ fields: this.fields }, { plugins, hooks: this.hooks });
  }
  /** Set the title on mount */
  componentDidMount() {
    setTitle(`Sign Up`);
    // If this is a simplified manager signup flow (/manager/sign-up):
    if (this.props.managerSignup) {
      // Grab the JWT from url
      this.inviteToken = this.params.data;
      // Try to decode it and populate the email field
      try {
        const decoded: JWTPayload = JWT.default(this.inviteToken);
        this.form.$('email').value = decoded.email;
      } catch (e: any) {
        this.props.toastStore!.error(getErrorMsg(e));
      }
    }
    if (this.params.email) {
      this.form.$('email').value = this.params.email;
    }
  }

  /** The query string params */
  public params: Record<string, any> = qs.parse(this.props.location.search, {
    ignoreQueryPrefix: true,
  });

  /** Whether the form is currently being submitted */
  @observable public submitting = false;

  /** The user object */
  @observable public user?: User;

  /** Invite JW token parsed from manager invite URL 'data' query string */
  @observable public inviteToken?: string | any;

  /**
   * If sign up was initiated using invite link, url will include email confirm
   * token that was generated with the invitation as a query string parameter.
   * If it's set we call 'POST users/create' with 'skipConfirmEmail' to tell
   * the core service not to send the initial email with the token
   */
  @computed public get emailConfirmToken(): string | undefined {
    return this.params.emailConfirmToken;
  }

  @action.bound public createAffiliate = flow(function* (this: SignUp) {
    try {
      yield Api.marketing.createAffiliate(this.user!.id);
    } catch (e: any) {
      this.props.toastStore!.error(getErrorMsg(e));
    }
  });

  /** Creates a user and affiliate */
  @action.bound public submit = flow(function* (this: SignUp) {
    try {
      this.submitting = true;
      let continueTo = paths.signUp().confirmEmailScreen();
      if (this.props.accountSignup) continueTo = paths.register().confirmEmailScreen();
      // Create the user
      yield this.createUser(continueTo);
      if (this.props.affiliate) {
        yield this.createAffiliate();
      }

      // If we're onboarding a manager via invitation link, we can skip confirm the email
      // step and continue to PersonalInfo, otherwise continue to confirm the email screen
      if (this.props.managerSignup && this.user) {
        continueTo = paths.signUp().personalInfo();
        // But we need to login the manager right away, because PersonalInfo is an authorized route
        yield this.props.userStore!.login({
          email: this.user.email,
          password: this.form.$('password').value,
        });
      }

      this.props.history.push({
        pathname: continueTo,
        state: {
          email: this.form.$('email').value,
          // Make the next step component aware of the manager
          // onboarding process so it can route accordingly:
          managerOnboarding: this.props.managerSignup,
          // Send token to the next route so it can confirm the email on
          // mount and skip that step if user started sign up process
          // using invitation link that includes confirm email token
          emailConfirmToken: this.emailConfirmToken,
        },
        search: this.props.location.search,
      });
    } catch (e: any) {
      const { message, code } = e.response && e.response.data && e.response.data.error;
      if (code === 400) {
        this.form.$('email').invalidate(message);
      } else {
        this.props.toastStore!.error(getErrorMsg(e));
      }
    } finally {
      this.submitting = false;
    }
  });

  /** Does the POST call to create the user */
  @action.bound public createUser = flow(function* (this: SignUp, confirmEmailPath?: string) {
    // If the accountSignup props is true, we have to send accountSignup: true
    // to the API, so that it gives the correct email confirmation link. We will
    // also temporarily set the firstName and lastName to empty strings, so that
    // the user doesn't get redirected to the screen where they have to set
    // their first name, last name and nickname.
    const accountSignupData = this.props.accountSignup
      ? {
          firstName: '',
          lastName: '',
        }
      : {};
    const extraData = {
      skipConfirmEmail: Boolean(this.emailConfirmToken),
      params: this.params,
      registrationToken: this.inviteToken,
      confirmEmailPath,
      ...accountSignupData,
    };
    const resp = yield Api.core.createUser(
      this.form.$('email').value,
      this.form.$('password').value,
      extraData,
    );
    this.user = resp.data.data;
  });

  /** The mobx-react-form instance */
  private form: any;
  /** The hooks for the form */
  private hooks: SignUpFormHooks = {
    onSuccess: () => {
      this.submit();
    },
    onClear: (form: any) => {
      form.clear();
    },
  };

  /** The label of the email field */
  @computed public get emailLabel(): string {
    return this.props.accountSignup ? `Owner's Email` : `Email`;
  }

  @computed public get singupLayoutProps() {
    const title = 'Sign Up';
    const action: TSignupLayoutAction = {
      form: formId,
      type: 'submit',
      loading: this.submitting,
      children: 'Sign Up',
      subText: (
        <>
          {`Already have an account? `}
          <Link
            component={RouterLink}
            to={paths.signIn()}
            underline="always"
            data-cy={'signin-link'}>
            Sign in
          </Link>
        </>
      ),
      preText: (
        <>
          By signing up you agree to <br />
          <Link href="https://www.meettippy.com/service-professionals-terms-of-service">
            &nbsp; Tippy Terms of Service
          </Link>
          &nbsp; and
          <Link href="https://www.meettippy.com/privacy-policy">&nbsp; Privacy Policy</Link>
        </>
      ),
    };
    return { title, action };
  }

  render() {
    const { classes } = this.props;
    // Get the fields from the form
    const emailField = this.form.$('email');
    const passwordField = this.form.$('password');
    const passwordConfirmField = this.form.$('passwordConfirm');
    // We need to pass these to onBlur manually because the onBlur for PasswordField
    // is not working. Probably has something to do with refs. TODO: Fix that in PasswordField
    const validatePasswordField = () => passwordField.validate({ showErrors: true });
    const validateConfirmPasswordField = () => passwordField.validate({ showErrors: true });
    return (
      <CarouselScreenWrapper submitting={this.submitting}>
        <SignupLayout {...this.singupLayoutProps}>
          <form id={formId} onSubmit={this.form.onSubmit}>
            <Box mt={5}>
              <OutlinedInput
                dataCy={'email-input'}
                id={emailField.id}
                {...emailField.bind()}
                disabled={this.props.managerSignup}
                autoFocus
                fullWidth
                error={emailField.error}
              />
            </Box>
            <Box mt={2}>
              <PasswordField
                dataCy={'password-input'}
                id={passwordField.id}
                {...passwordField.bind()}
                error={passwordField.error}
                onBlur={validatePasswordField}
              />
            </Box>
            <Box mt={2} mb={2}>
              <PasswordField
                dataCy={'password-confirm-input'}
                id={passwordConfirmField.id}
                {...passwordConfirmField.bind()}
                error={passwordConfirmField.error}
                onBlur={validateConfirmPasswordField}
              />
            </Box>
          </form>
        </SignupLayout>
      </CarouselScreenWrapper>
    );
  }
}

export default withStyles(styles)(SignUp);
