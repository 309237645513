import React from 'react';
import { Button, ButtonProps } from '@material-ui/core';
import useStyles from './styles';

const SkipButton = (props: Omit<ButtonProps, 'className'>) => {
  const { root } = useStyles();
  return (
    <Button {...props} className={root}>
      SKIP
    </Button>
  );
};

export default SkipButton;
