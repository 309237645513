import { Typography } from '@mui/material';
import Dialog from 'components/Dialog/Dialog';
import * as models from 'models';
import useDeleteBillingGroupDialog from './useDeleteBillingGroupDialog';
import { ELicenseBillingGeneralDialog, ILicenseBillingGeneralDialogProps } from '../models';

interface IDeleteBillingGroupProps extends ILicenseBillingGeneralDialogProps {
  billingEntity: models.IBillingEntity | null;
  getBillingEntities: VoidFunction;
}

const DeleteBillingGroup = ({
  open,
  type,
  title,
  billingEntity,
  onCancel,
  TransitionProps,
  getBillingEntities,
}: IDeleteBillingGroupProps) => {
  const { name, deleting, deleteBillingGroup } = useDeleteBillingGroupDialog(
    onCancel,
    getBillingEntities,
    billingEntity,
  );

  const _open = open && type === ELicenseBillingGeneralDialog.DELETE_BILLING_GROUP;

  return (
    <Dialog
      open={_open}
      title={title}
      loading={deleting}
      content={<Typography variant={'body2'}>Are you sure you want to delete {name}</Typography>}
      confirmActionName="Delete"
      onConfirm={deleteBillingGroup}
      onCancel={onCancel}
      TransitionProps={{
        onExited: (node) => TransitionProps?.onExited && TransitionProps.onExited(node),
      }}
    />
  );
};

export default DeleteBillingGroup;
