import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { observer } from 'mobx-react';
import { Route, Switch } from 'react-router-dom';

import { inject, WithUserStore } from 'stores';
import { paths } from 'routes';

import DashboardLayout from 'containers/DashboardLayout';

import SignupStore from '../SignupStore';
import Owner from '../Owner';
import AccountStep from '../AccountStep';
import LocationsStep from '../LocationsStep';
import CouponsStep from '../CouponsStep';
import ReviewStep from '../ReviewStep';
import Stepper from '../Stepper';


/** Here we define what kind of props this component takes */
type AffiliateSignupProps = RouteComponentProps<{ accountId?: string }> & // Adds the router props (history, match, location)
  WithUserStore; // Adds the userStore prop

export enum SignupStep {
  Owner,
  Account,
  Locations,
  Coupons,
  Review,
}

/**
 * The dashboard home page, displayed when you log in.
 */
@inject('userStore')
@observer
class AffiliateSignup extends React.Component<AffiliateSignupProps> {
  public constructor(props: AffiliateSignupProps) {
    super(props);
  }
  public signupStore = new SignupStore(this.props.userStore!.rootStore, { affiliateSignup: true });
  componentDidMount() {
    this.signupStore.init();
  }
  render() {
    const as = paths.affiliateAccountSignup();
    return (
      <DashboardLayout>
        <Stepper currentStep={this.signupStore.currentStep} />
        <Switch>
          <Route
            path={as.owner()}
            exact
            render={(props: any) => (
              <Owner
                {...props}
                signupStore={this.signupStore}
                nextRoute={as.account}
                disableExistingUserLookup
              />
            )}></Route>
          <Route
            path={as.account()}
            exact
            render={(props: any) => (
              <AccountStep
                {...props}
                signupStore={this.signupStore}
                nextRoute={as.locations}
                addOwner
                ownerType="temporary"
              />
            )}></Route>
          <Route
            path={as.locations(`:accountId`)}
            exact
            render={(props: any) => (
              <LocationsStep {...props} signupStore={this.signupStore} nextRoute={as.coupons} />
            )}></Route>
          <Route
            path={as.coupons(`:accountId`)}
            exact
            render={(props: any) => (
              <CouponsStep
                {...props}
                signupStore={this.signupStore}
                hideAffilliateSelection
                nextRoute={as.review}
              />
            )}
          />
          <Route
            path={as.review(`:accountId`)}
            render={(props: any) => <ReviewStep {...props} signupStore={this.signupStore} />}
            exact
          />
        </Switch>
      </DashboardLayout>
    );
  }
}

export default AffiliateSignup;
