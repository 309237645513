import { Masonry, MasonryProps } from '@mui/lab';
import useTippyMasonry from './useTippyMasonry';
import { observer } from 'mobx-react';

type TTippyMasonry = MasonryProps;

const TippyMasonry = ({ children, ...rest }: TTippyMasonry) => {
  const { columns } = useTippyMasonry();
  return (
    <Masonry columns={columns} spacing={3} style={{ margin: 0 }} {...rest}>
      {children}
    </Masonry>
  );
};

export default observer(TippyMasonry);
