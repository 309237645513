import React, { useEffect, useState } from 'react';
import { faComment } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ToolbarItemWrapper } from '../ElevatedAppBar.tsx/WebToolbar/WebToolbar';
import { observer } from 'mobx-react';
import useStyles from './styles';
import { useStyles as useNotificationStyles } from '../Notifications/styles';

import { Badge, IconButton, Tooltip } from '@material-ui/core';
import { rootStore } from 'containers/App/App';

const IntercomChat = () => {
  const { icon, badge } = useStyles();
  const { iconButton } = useNotificationStyles();
  const [numberOfNewMessages, setNumberOfNewMessages] = useState(0);

  useEffect(() => {
    Intercom('onUnreadCountChange', function (unreadCount: number) {
      setNumberOfNewMessages(unreadCount);
    });
  }, []);

  if (rootStore?.userStore?.isAdmin) return null;

  return (
    <ToolbarItemWrapper>
      <Tooltip title={'contact support'}>
        <IconButton id="intercom_chat_widget" className={iconButton}>
          <Badge className={badge} badgeContent={numberOfNewMessages} color="primary">
            <FontAwesomeIcon className={icon} icon={faComment} />{' '}
          </Badge>
        </IconButton>
      </Tooltip>
    </ToolbarItemWrapper>
  );
};

export default observer(IntercomChat);
