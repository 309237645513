import { Partner } from './Partner';

export interface IDistributions {
  id?: number;
  partnerId: number;
  partner?: Partner;
  feeAmount: number;
  feePercent: number;
}

export interface DistributionRule {
  feeAmount: string;
  feePercent: string;
  feeScheduleId: number;
  partnerId: number;
  isActive: boolean;
  feeSchedule?: IFeeSchedule;
  partner?: Partner;
}

export interface IFeeSchedule {
  name: string;
  processor: string | undefined;
  appId: number | undefined;
  accountId: number;
  locationId: number;
  distributions: IDistributions[];
  type: FeeScheduleType;
}

export enum FeeScheduleType {
  DEFAULT = 'default',
  FLAT_FEES = 'flat_fees',
  OFFLINE_FEES = 'offline_fees',
  PRE_REBATE = 'pre_rebate',
}

export interface IFeeScheduleResponse {
  id: number;
  name: string;
  processor: string | undefined;
  appId: number | undefined;
  accountId: number;
  locationId: number;
  feeAmount: string;
  feePercent: string;
  status: FeeScheduleStatus;
  app: { id: number; name: string };
  account?: { id: number; name: string };
  location?: { id: number; name: string };
  rules: IDistributions[];
  type: FeeScheduleType;
}

export enum FeeScheduleStatus {
  DRAFT = 'draft',
  PUBLISHED = 'published',
  DEACTIVATED = 'deactivated',
  DELETED = 'deleted',
}

export enum ASSIGN_TO {
  PROCESSOR = 'processor',
  INTEGRATION = 'integration',
}
