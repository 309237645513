/** Lists of refund reasons */

/** Payment refund reasons */
export const paymentRefundReasons: Record<string, string> = {
  DUPLICATE_TRANSACTION: 'Duplicate tip transactions',
  INCORRECT_AMOUNT: 'Tipped incorrect desired amount',
  INCORRECT_PROFESSIONAL: 'Tipped incorrect service professional',
  REQUEST_BY_SALON: 'Requested by salon owner/manager',
  REQUEST_BY_CLIENT: 'Requested by client',
  OTHER: 'Other:',
};

/** Tip refund reasons */
export const tipRefundReasons: Record<string, string> = {
  DUPLICATE_TRANSACTION: 'Duplicate tip transactions',
  INCORRECT_AMOUNT: 'Tipped incorrect desired amount',
  INCORRECT_PROFESSIONAL: 'Tipped incorrect service professional',
  REQUEST_BY_SALON: 'Requested by salon owner/manager',
  REQUEST_BY_CLIENT: 'Requested by client',
  OTHER: 'Other:',
};

/** Invoice refund reasons */
export const invoiceRefundReasons: Record<string, string> = {
  CANCELLED_SUBSCRIPTION: 'Cancelled subscription',
  APPLIED_COUPON: 'Applied coupon',
  BUSINESS_CLOSED: 'Business closed',
  PANDEMIC: 'Pandemic',
  TECH_ERROR: 'Tech error',
  OTHER: 'Other:',
};
