import { Theme, createStyles, makeStyles } from '@material-ui/core';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const styles = (theme: Theme) =>
  createStyles({
    dialogWrapper: {
      minWidth: theme.spacing(50),
    },
    font32: {
      fontSize: theme.spacing(4),
    },
    inputField: {
      width: 120,
      fontSize: 32,
    },
    button: {
      height: '40px',
      width: '91px',
      padding: '8px 12px',
      borderRadius: '8px',
      color: theme.palette.text.primary,
      textTransform: 'capitalize',
    },
    text: {
      color: theme.palette.text.primary,
      fontSize: 18
    },
    label: {
      fontWeight: 400
   }
  });

export default styles;

export const useStyles = makeStyles(styles);
