import React from 'react';
import { observable, action, computed, flow, makeObservable } from 'mobx';
import { observer } from 'mobx-react';
import { WithStyles, withStyles } from '@material-ui/core/styles';
import {
  Box,
  Grid,
  Paper,
  Typography,
  CircularProgress,
  Link,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import Title from 'components/Title';

import Api, { getErrorMsg } from 'api';
import { User } from 'models';

import { inject, WithUserStore, WithModalStore, WithToastStore } from 'stores';
import DashboardLayout from 'containers/DashboardLayout';
import UserSearch from 'components/UserSearch';
import UserDetailsPanel from 'components/UserDetailsPanel';
import Overlay from 'components/Overlay';
import { paths } from 'routes';

import styles from './styles';

import { ArrowRightBold, Check } from 'mdi-material-ui';
import Button from 'components/Button/Button';

type MergeUsersProps = WithStyles<typeof styles> & WithUserStore & WithModalStore & WithToastStore;

const fullName = (u?: User | null) => (u ? `${u.firstName} ${u.lastName}` : ``);

/**
 * The container that allows admins to merge users
 */
@inject('userStore', 'modalStore', 'toastStore')
@observer
class MergeUsers extends React.Component<MergeUsersProps> {
  constructor(props: MergeUsersProps) {
    super(props);
    makeObservable(this);
  }
  /** The user in the from field */
  @observable public from: User | null = null;

  /** Whether submission of merge request is in progress */
  @observable public inProgress = false;

  /** Whether the merge process has been completed */
  @observable public completed = false;

  /** Sets the from user */
  @action.bound public setFrom(u: User | null) {
    this.from = u;
  }

  /** The destination user */
  @observable public to: User | null = null;

  /** Whether to delete the source user */
  @observable public deleteSource = false;

  /** Set delete source user */
  @action.bound public setDeleteSource(e: React.ChangeEvent<HTMLInputElement>) {
    this.deleteSource = e.target.checked;
  }

  /** Sets the to user */
  @action.bound public setTo(u: User | null) {
    this.to = u;
  }

  /** Whether both users have been selected */
  @computed public get bothUsersSelected() {
    return Boolean(this.from && this.to);
  }

  /** We show the overlay if it's in progress or if it's done */
  @computed public get showOverlay() {
    return this.inProgress || this.completed;
  }

  /** Submits and sends the API request to merge users */
  @action.bound public submit = flow(function* (this: MergeUsers) {
    // If one of the users is missing, do nothing
    if (!this.from || !this.to) {
      return;
    }
    // Determine the text to present in the confirmation modal
    const fromName = fullName(this.from);
    const toName = fullName(this.to);
    const confirmText = (
      <>
        <p>
          This will copy personal data, workplace info, bank accounts and debit cards from{' '}
          {fromName} to {toName}.
        </p>
        {this.deleteSource && <p>Additionally, {fromName} will be deleted.</p>}
        <p>You cannot undo this action.</p>
      </>
    );
    // If the user doesn't confirm, do nothing
    const confirmed = yield this.props.modalStore!.confirm(`Are you sure?`, confirmText);
    if (!confirmed) {
      return;
    }
    try {
      // Send the API request
      this.inProgress = true;
      yield Api.core.mergeUsers({
        sourceUserId: this.from.id,
        destUserId: this.to.id,
        deleteSource: this.deleteSource,
      });
      // We have completed the API request successfully, so we show the
      // success text by setting completed to true
      this.completed = true;
    } catch (e: any) {
      this.props.toastStore!.error(getErrorMsg(e));
    } finally {
      this.inProgress = false;
    }
  });

  render() {
    const { root } = this.props.classes;
    return (
      <DashboardLayout>
        <Title>Merge User Data</Title>
        <Paper>
          <Box p={3} className={root}>
            <Overlay display={this.showOverlay}>
              {this.completed ? (
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  justifyContent="center">
                  <Title>Success!</Title>
                  <Box pb={4}>
                    <Check style={{ width: 64, height: 64 }} color="primary" />
                  </Box>
                  <Typography>
                    <Link
                      component="a"
                      href={paths.userDetails(this.from!.id).root()}
                      rel="noopener noreferrer"
                      target="blank">
                      {fullName(this.from)}
                    </Link>{' '}
                    has been copied to{' '}
                    <Link
                      component="a"
                      href={paths.userDetails(this.to!.id).root()}
                      rel="noopener noreferrer"
                      target="blank">
                      {fullName(this.to)}
                    </Link>
                  </Typography>
                </Box>
              ) : (
                <CircularProgress />
              )}
            </Overlay>
            <Grid container spacing={3}>
              <Grid item lg={5} xs={12}>
                <Typography variant="h5" component="h2">
                  From
                </Typography>
                <Box mb={3}>
                  <Typography color="textSecondary">
                    {`This user's data will be copied to another user`}
                  </Typography>
                </Box>
                <UserSearch label="User" onChange={this.setFrom} autoFocus value={this.from} />
                {this.from && (
                  <Box pt={3}>
                    <UserDetailsPanel
                      editable={false}
                      title={fullName(this.from)}
                      displayAvatar
                      displayLink>
                      {this.from!}
                    </UserDetailsPanel>
                  </Box>
                )}
              </Grid>
              <Grid item lg={2} xs={12} alignItems="center" direction="column" justify="center">
                <Box
                  width="100%"
                  height="100%"
                  alignItems="center"
                  flexDirection="column"
                  justifyContent="flex-start"
                  pt={3}
                  display="flex">
                  <ArrowRightBold color="disabled" style={{ width: 48, height: 48 }} />
                </Box>
              </Grid>
              <Grid item lg={5} xs={12}>
                <Typography variant="h5" component="h2">
                  To
                </Typography>
                <Box mb={3}>
                  <Typography color="textSecondary">
                    {`The data from the source will be copied here`}
                  </Typography>
                </Box>
                <UserSearch label="User" onChange={this.setTo} value={this.to} />
                {this.to && (
                  <Box pt={3}>
                    <UserDetailsPanel
                      editable={false}
                      title={fullName(this.to)}
                      displayAvatar
                      displayLink>
                      {this.to!}
                    </UserDetailsPanel>
                  </Box>
                )}
              </Grid>
              {this.bothUsersSelected && (
                <Grid item xs={12}>
                  <Box pb={2}>
                    <FormControlLabel
                      control={
                        <Checkbox checked={this.deleteSource} onChange={this.setDeleteSource} />
                      }
                      label={`Delete ${this.from && this.from.firstName}`}
                    />
                  </Box>
                  <Button fullWidth color="primary" variant="contained" onClick={this.submit}>
                    Copy data
                  </Button>
                </Grid>
              )}
            </Grid>
          </Box>
        </Paper>
      </DashboardLayout>
    );
  }
}

export default withStyles(styles)(MergeUsers);
