import { Theme, createStyles } from '@material-ui/core';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default (theme: Theme) =>
  createStyles({
    root: {},
    notAvailableLabel:{
      fontSize: '20px',
      color: theme.palette.text.primary,
      fontWeight: 700,
      lineHeight: '23,36px'
    }
  });
