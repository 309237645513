import { Theme, createStyles } from '@material-ui/core';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default (theme: Theme) =>
  createStyles({
    root: {
      position: 'relative',
    },
    leftButton: {
      padding: 0,
      color: theme.palette.text.secondary,
      fontSize: theme.typography.body1.fontSize,
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
    email: {
      fontWeight: 400,
      color: theme.palette.primary.main,
    },
    title: {
      color: theme.palette.text.primary,
      marginBottom: 0,
      fontSize: '40px',
    },
    scrollbar: {
      maxHeight: '500px',
      '-ms-overflow-style': 'none',
      scrollbarWidth: 'none',
      overflowY: 'scroll',
      '&::-webkit-scrollbar': {
        display: 'none',
        width: '0 !important',
      },
    },
  });
