import { makeStyles, createStyles, Theme } from '@material-ui/core';
import { EErrorFallbackVariant } from './ErrorFallback';

interface IStylesProps {
  variant?: EErrorFallbackVariant;
}

const iconSize = (variant?: EErrorFallbackVariant) => {
  switch (variant) {
    case EErrorFallbackVariant.DEFAULT:
      return 72;
    case EErrorFallbackVariant.SMALL:
      return 24;
    default:
      return 72;
  }
};

const wrapperSize = (variant?: EErrorFallbackVariant) => {
  switch (variant) {
    case EErrorFallbackVariant.DEFAULT:
      return 172;
    case EErrorFallbackVariant.SMALL:
      return 40;
    default:
      return 40;
  }
};

const messageMarginTop = (variant?: EErrorFallbackVariant) => {
  switch (variant) {
    case EErrorFallbackVariant.DEFAULT:
      return 5;
    case EErrorFallbackVariant.SMALL:
      return 2;
    default:
      return 5;
  }
};

const useStyles = makeStyles<Theme, IStylesProps>((theme: Theme) =>
  createStyles({
    wrapper: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyItems: 'center',
    },
    iconWrapper: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: ({ variant }) => wrapperSize(variant),
      height: ({ variant }) => wrapperSize(variant),
      borderRadius: '50%',
      backgroundColor: theme.palette.divider,
    },
    icon: {
      width: ({ variant }) => iconSize(variant),
      height: ({ variant }) => iconSize(variant),
      color: theme.palette.text.disabled,
    },
    message: {
      marginTop: ({ variant }) => theme.spacing(messageMarginTop(variant)),
    },
  }),
);

export default useStyles;
