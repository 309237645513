/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { computed, makeObservable } from 'mobx';

import { observer } from 'mobx-react';
import { WithStyles, withStyles } from '@material-ui/core/styles';
import { Box, Button } from '@material-ui/core';
import { ContentCopy, Printer } from 'mdi-material-ui';
import QRCode from 'qrcode.react';
import qs from 'qs';
import styles from './styles';
import { inject, WithToastStore } from 'stores';
import config from 'config';

interface KioskPrintMatchParams {
  type: string;
  code: string;
}

// type KioskPrintProps = WithStyles<typeof styles> & RouteComponentProps;
type KioskPrintProps = WithStyles<typeof styles> &
  RouteComponentProps<KioskPrintMatchParams> &
  WithToastStore;
/**
 * Displays a clean QR code for print
 */
@inject('toastStore')
@observer
class KioskPrint extends React.Component<KioskPrintProps> {
  constructor(props: KioskPrintProps) {
    super(props);
    makeObservable(this);
  }
  @computed get qsObj(): any {
    return qs.parse(this.props.location.search, { ignoreQueryPrefix: true });
  }

  @computed get type(): string {
    return this.qsObj.type;
  }

  @computed get link(): string {
    const code = this.qsObj.code;

    let typeAnnotation = '';
    if (this.type === 'account') typeAnnotation = 'A';
    if (this.type === 'location') typeAnnotation = 'L';
    if (this.type === 'talent') typeAnnotation = 'T';
    if (this.type === 'pool') typeAnnotation = 'P';
    if (this.type === 'station') typeAnnotation = 'S';

    const fullCode = `${typeAnnotation}${code}`;

    return `${config.tippyGo.baseUrl}/${fullCode}`;
  }

  componentDidMount() {
    if (this.type === 'pool' || this.type === 'station') {
      window.print();
    }
  }

  handleCopyClick = () => {
    // @ts-ignore
    if (window.clipboardData && window.clipboardData.setData) {
      // @ts-ignore
      return window.clipboardData.setData('Text', this.link);
    } else if (document.queryCommandSupported && document.queryCommandSupported('copy')) {
      const textarea = document.createElement('textarea');
      textarea.textContent = this.link;
      textarea.style.position = 'fixed';
      document.body.appendChild(textarea);
      textarea.select();
      try {
        document.execCommand('copy');
        this.props.toastStore!.push({ type: 'info', message: 'Link copied!' });
      } catch (ex) {
        this.props.toastStore!.push({ type: 'error', message: 'Copying failed!' });
        return false;
      } finally {
        document.body.removeChild(textarea);
      }
    }
  };

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.wrapper}>
        <Box display="flex" alignItems="center" justifyContent="center" p={1}>
          <QRCode value={this.link} style={{ height: '256px', width: '256px' }} />
        </Box>
        {this.type !== 'pool' && (
          <Box display="flex" alignItems="center" justifyContent="center" p={1}>
            <Button
              variant="contained"
              startIcon={<ContentCopy />}
              onClick={this.handleCopyClick}
              color="primary"
              className={classes.button}>
              Copy
            </Button>
            <Button
              variant="contained"
              startIcon={<Printer />}
              onClick={() => window.print()}
              color="primary">
              Print
            </Button>
          </Box>
        )}
      </div>
    );
  }
}

export default withStyles(styles)(KioskPrint);
