import { useMediaQuery } from '@material-ui/core';

export const mobileSize = 670;
export const minSingleContentPageSize = 600;

const useMobileView = () => {
  const mobile = useMediaQuery(`(max-width: ${mobileSize}px)`);
  return { mobile };
};

export default useMobileView;
