import React from 'react';
import { observer } from 'mobx-react';
import MfaDialog from 'components/GlobalDialogs/MfaDialog/MfaDialog';

const GlobalDialogs = observer(() => {
  return (
    <>
      <MfaDialog />
    </>
  );
});

export default GlobalDialogs;
