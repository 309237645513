import { isAmount, isEmail, isPhone } from 'services';
import {
  EFormFieldLabels,
  IFormFieldsConfig,
  EFormFields,
  ErrorMessagesForms,
} from './FormsConfig';
import { EInput } from 'models/Input';

/**
 * Text form field config
 */
export const FORM_TEXT = (
  required = true,
  disabled = false,
  label = EFormFieldLabels.NAME,
  name = EFormFields.NAME,
): IFormFieldsConfig => ({
  name,
  rules: {
    required: required && ErrorMessagesForms.FIELD_REQUIRED(label),
  },
  label,
  required,
  disabled,
  fullWidth: true,
  variant: 'normal',
});

/**
 * Email form field config
 */
export const FORM_EMAIL = (
  required = true,
  disabled = false,
  label = EFormFieldLabels.EMAIL,
): IFormFieldsConfig => ({
  name: EFormFields.EMAIL,
  rules: {
    required: required && ErrorMessagesForms.FIELD_REQUIRED(label),
    validate: (val: string) => isEmail(val) || ErrorMessagesForms.EMAIL_NOT_VALID,
  },
  label,
  required,
  disabled,
  fullWidth: true,
  variant: 'normal',
});

/**
 * Phone form field config
 */
export const FORM_PHONE = (
  required = true,
  disabled = false,
  label = EFormFieldLabels.PHONE,
): IFormFieldsConfig => ({
  name: EFormFields.PHONE,
  rules: {
    required: required && ErrorMessagesForms.FIELD_REQUIRED(label),
    validate: (val: string) => isPhone(val) || ErrorMessagesForms.PHONE_NOT_VALID,
  },
  label,
  required,
  disabled,
  fullWidth: true,
  variant: 'phone',
});

/**
 * Select field config
 */
export const FORM_SELECT = ({
  name,
  label,
  optionsNodes,
  optionsList,
  required = true,
  disabled = false,
  fullWidth = true,
  className,
}: IFormFieldsConfig) => ({
  name,
  rules: {
    required: ErrorMessagesForms.FIELD_REQUIRED(label),
  },
  label,
  select: true,
  required,
  optionsNodes,
  optionsList,
  disabled,
  fullWidth,
  className,
});

/**
 * Autocomplete field config
 */
export const FORM_AUTOCOMPLETE = ({
  name,
  label,
  fetch,
  getLabel,
  required = true,
  disabled = false,
  fullWidth = true,
}: IFormFieldsConfig) => ({
  name,
  rules: {
    required: ErrorMessagesForms.FIELD_REQUIRED(label),
  },
  label,
  required,
  type: EInput.AUTOCOMPLETE,
  fetch,
  getLabel,
  disabled,
  fullWidth,
});

/**
 * Tags Autocomplete field config
 */
export const FORM_TAGS_AUTOCOMPLETE = ({
  name,
  label,
  fetch,
  getLabel,
  tagsSelected,
  required = true,
  disabled = false,
  fullWidth = true,
}: IFormFieldsConfig) => ({
  name,
  rules: {
    required: ErrorMessagesForms.FIELD_REQUIRED(label),
  },
  label,
  required,
  type: EInput.TAGS_AUTOCOMPLETE,
  fetch,
  getLabel,
  tagsSelected,
  disabled,
  fullWidth,
});

export const FORM_AMOUNT = ({
  name,
  label,
  required = true,
  disabled = false,
  note,
}: IFormFieldsConfig) => ({
  name,
  rules: {
    required: required && ErrorMessagesForms.FIELD_REQUIRED(label),
    validate: (val: string) => isAmount(val) || ErrorMessagesForms.AMOUNT_NOT_VALID,
  },
  label,
  required,
  disabled,
  fullWidth: true,
  note,
});
