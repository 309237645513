import { Theme, createStyles, makeStyles } from '@material-ui/core';

interface IStyleProps {
  hasError?: boolean;
}

const useStyles = makeStyles<Theme, IStyleProps>((theme: Theme) =>
  createStyles({
    input: {
      borderRadius: 10,
      border: ({ hasError }) =>
        hasError
          ? `1px solid ${theme.palette.error.main}`
          : `1px solid ${theme.palette.common.black}`,
      width: 40,
      height: 53,
      fontSize: 32,
      textAlign: 'center',
      padding: 0,

      /* Chrome, Safari, Edge, Opera */
      '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
        margin: 0,
      },

      /* Firefox */
      '&[type=number]': {
        '-moz-appearance': 'textfield',
      },

      '&:focus-visible': {
        border: `none`,
        outline: ({ hasError }: { hasError?: boolean }) =>
          hasError ? `4px solid ${theme.palette.error.main}` : `4px solid ${theme.palette.divider}`,
      },
    },
    container: {
      display: 'flex',
      justifyContent: 'center',
      gap: theme.spacing(3),
    },
  }),
);

export default useStyles;
