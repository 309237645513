/**
 * Takes a string that contains a numeric value
 * and converts it to a dollar currency representation.
 */
export function numericStringToUsd(v: string) {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  }).format(parseFloat(v));
}

/**
 * Takes a string and removes all non numeric characters excluding
 * dot (decimal delimiter) and minus (negative prefix) (.-)
 */
export function usdToNumericString(v: string) {
  return v.replace(/[^0-9.-]+/g, '');
  // return v.replace(/[^0-9.]+/g, '');
}
