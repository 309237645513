import { Theme, createStyles } from '@material-ui/core';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default (theme: Theme) =>
  createStyles({
    root: {
      position: 'relative',
    },
    leftButton: {
      position: 'absolute',
      left: 0,
    },
    smallText: {
      fontSize: 12,
    },
  });
