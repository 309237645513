import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { observer } from 'mobx-react';
import { observable, action, flow, computed, makeObservable } from 'mobx';
import { WithStyles, withStyles } from '@material-ui/core/styles';
import { Box, Typography } from '@material-ui/core';

import { paths } from 'routes';
import Api, { ApiResponse, getErrorMsg } from 'api';
import {
  inject,
  WithToastStore,
  WithUserStore,
  WithModalStore,
  WithPayoutSourceStore,
} from 'stores';

import AnonLayout from 'components/AnonLayout';

import styles from './styles';
import { BankAccount } from 'models';
import { AxiosResponse } from 'axios';
import CarouselScreenWrapper from 'components/CarouselScreenWrapper/CarouselScreenWrapper';
import { faFaceThinking } from '@fortawesome/pro-regular-svg-icons';
import SignupLayout, { TSignupLayoutAction } from 'containers/SignupLayout/SignupLayout';
import CircularIconFrame from 'components/CircularIconFrame/CircularIconFrame';
import theme from 'containers/App/theme';

type BankPromptProps = WithStyles<typeof styles> &
  RouteComponentProps &
  WithToastStore &
  WithModalStore &
  WithUserStore &
  WithPayoutSourceStore;

@inject('toastStore', 'userStore', 'modalStore', 'payoutSourceStore')
@observer
class BankPrompt extends React.Component<BankPromptProps> {
  constructor(props: BankPromptProps) {
    super(props);
    makeObservable(this);
  }

  @observable public loadingBranch = true;
  @observable private branchData: any;

  /** The current error message */
  @observable public errorMsg?: string;

  @action.bound public fetchBranchData = flow(function* (this: BankPrompt) {
    try {
      this.loadingBranch = true;
      const resp: AxiosResponse<ApiResponse<BankAccount[]>> = yield Api.core.getUserBranchData(
        this.props.userStore!.user!.id,
      );
      this.branchData = resp.data.data;
    } catch (e) {
      this.props.toastStore!.push({ type: 'error', message: getErrorMsg(e) });
    } finally {
      this.loadingBranch = false;
    }
  });

  @computed public get branchConnected() {
    return this.branchData && this.branchData.status;
  }

  @computed get inProgress() {
    return this.loadingBranch;
  }

  @computed public get signupLayoutProps() {
    const title = this.branchConnected ? 'Groovy!' : 'Sorry!';
    const action: TSignupLayoutAction = {
      onClick: () => this.props.history.push(paths.signUp().success()),
      children: 'Continue',
    };
    return {
      title,
      action,
    };
  }

  componentDidMount() {
    // this.fetchBranchData();
  }

  render() {
    const { classes } = this.props;

    if (this.branchConnected) {
      return (
        <AnonLayout inProgress={false}>
          <Box className={classes.root}>{this.renderFinished()}</Box>
        </AnonLayout>
      );
    }

    return (
      <CarouselScreenWrapper>
        <SignupLayout {...this.signupLayoutProps}>
          {this.branchConnected ? (
            this.renderFinished()
          ) : (
            <Box display={'flex'} flexDirection={'column'} gridGap={theme.spacing(8)}>
              <CircularIconFrame icon={faFaceThinking} color="red" />

              <Typography variant="body2" align="center">
                Branch signup is currently disabled. Please try later.
              </Typography>
            </Box>
          )}
        </SignupLayout>
      </CarouselScreenWrapper>
    );
  }

  /**
   * What the user sees when they've successfully set up
   * their account and it's verified.
   */
  renderFinished() {
    return (
      <>
        <Typography color="textSecondary">
          <p>{`You've successfully set up your account! Wasn't that easy?`}</p>
          <p>You can now transfer the tips that you receive straight to your account </p>
        </Typography>
      </>
    );
  }
}

export default withStyles(styles)(BankPrompt);
