import theme from 'containers/App/theme';

export const referralsTitleContainerStyles = {
  display: 'inline-flex',
  alignItems: 'center',
  marginBottom: theme.spacing(3),
  '& .referrals-status': {
    backgroundColor: theme.palette.primary.light,
    borderRadius: theme.shape.borderRadius * 2,
    color: theme.palette.primary.dark,
    fontSize: 16,
    fontWeight: 700,
    lineHeight: '20.29px',
    marginLeft: theme.spacing(2),
    padding: theme.spacing(1),
  },
};

export const referralFormStyles = {
  display: 'flex',
  gap: theme.spacing(1),
  justifyContent: 'space-between',
  marginTop: theme.spacing(2),
  [theme.breakpoints.down('xs')]: {
    flexDirection: 'column',
  },
  '& .MuiBox-root': {
    '& [class*="startAdornmentWrapper"]': {
      marginRight: 0,
    },
    '& .MuiButtonBase-root': {
      color: theme.palette.primary.main,
      top: theme.spacing(0.5),
    },
  },
  '& .referral-form-action': {
    alignSelf: 'flex-end',
    textTransform: 'none',
    minWidth: 98,
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
    [theme.breakpoints.up('sm')]: {
      marginBottom: theme.spacing(0.75),
    },
  },
};

export const referralsSkeletonStyles = {
  borderRadius: `${theme.shape.borderRadius}px`,
  marginLeft: `${theme.spacing(1)}px`,
  marginRight: `${theme.spacing(1)}px`,
};

export const formSwitchStyles = {
  color: theme.palette.primary.main,
  cursor: 'pointer',
  textDecoration: 'underline',
  '&:hover': {
    textDecoration: 'none',
  },
};

export const referralsTableActionStyles = {
  alignItems: 'center',
  display: 'flex',
  width: '100%',
  '& .btn-disabled': {
    '&:hover': {
      backgroundColor: 'transparent',
      cursor: 'default',
    },
  },
};
