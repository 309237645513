import theme from 'containers/App/theme';

export const referralPanelStyles = {
  backgroundColor: '#fff',
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(2),
  height: '100%',
  '& .referral-panel-icon': {
    backgroundColor: theme.palette.primary.light,
    height: 50,
    width: 50,
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
  },
  '& .referral-panel-icon-inner': {
    fontSize: 24,
    color: theme.palette.primary.main,
  },
  '& .referral-panel-title': {
    fontSize: 24,
    fontWeight: 700,
    lineHeight: '30.43px',
    marginBottom: theme.spacing(1),
  },
  '& .referral-panel-content': {
    display: 'flex',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      gap: theme.spacing(2),
    },
    justifyContent: 'space-between',
    gap: theme.spacing(2),
  },
  '& .referral-panel-text': {
    color: theme.palette.text.secondary,
    fontSize: 16,
    lineHeight: '20.29px',
  },
  '& .referral-panel-action': {
    textTransform: 'unset',
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      maxWidth: 310,
    },
  },
};
