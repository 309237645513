import { Box, Link } from '@material-ui/core';
import { withStyles, WithStyles } from '@material-ui/core/styles';
import React from 'react';
import { observable, action, makeObservable } from 'mobx';
import styles from './styles';
import { RouteComponentProps, Link as RouterLink } from 'react-router-dom';
import { paths } from '../../../routes';
import { observer } from 'mobx-react';
import PartnerDrawer from './PartnerDrawer';
import Api, { RequestMetaData } from 'api';
import { v4 as uuidv4 } from 'uuid';
import { adaptForDataGridPro } from 'services';
import { Filter } from 'components/FilterBar/FilterBar';
import FilterBar from 'components/FilterBar';
import DataGridInfiniteScroll from 'components/DataGridInfiniteScroll';
import * as DateRangeExternalPicker from 'components/DateRangeExternalPicker';
import { numericStringToUsd } from 'services/currency';
import { DATE_TYPE, formatLocalDateTime } from 'utils/helper';
import PlusFabButton from 'components/PlusFabButton/PlusFabButton';
import ChipStatusTag, { ChipStatusColors } from 'components/ChipStatusTag';

type PartnersProps = WithStyles<typeof styles> &
  RouteComponentProps & { canPerformActions: boolean };

interface IStatusConfig {
  LINKED: string;
  NO_WALLET: string;
  NOT_LINKED: string;
}

const statusConfig: IStatusConfig = {
  LINKED: ChipStatusColors.GREEN, //'#4EAE8D',
  NO_WALLET: ChipStatusColors.GREY,
  NOT_LINKED: ChipStatusColors.RED,
};

enum PartnerStatus {
  LINKED = 'linked',
  NO_WALLET = 'no_wallet',
  NOT_LINKED = 'not_linked',
}

@observer
class Partners extends React.Component<PartnersProps> {
  constructor(props: any) {
    super(props);
    makeObservable(this);
  }

  @observable private isOpen = false;

  /** Active filters as returned by FilterBar */
  @observable private activeFilters: Record<string, unknown> = {};

  /** The selected date range */
  @observable public dateRange: DateRangeExternalPicker.DateRange =
    DateRangeExternalPicker.getDateRange();

  /** The key for the datagrid table */
  @observable public dataGridKey = Date.now();

  /**
   * Adapts the api fetch function for datagrid consumption.
   */

  public getPartnersData = adaptForDataGridPro(
    async (rmd: RequestMetaData) =>
      await Api.tips.getPartners({
        ...rmd,
        filters: {
          ...this.activeFilters,
        },
        sort: { sortBy: 'id', sortOrder: 'DESC' },
      }),
    (partner: any) => ({
      id: uuidv4(),
      ...partner,
      bankStatus: partner?.bankStatus ? partner.bankStatus.toUpperCase() : 'N/A',
      rebateStartDate: formatLocalDateTime(partner.rebateStartDate, DATE_TYPE.DATE),
    }),
  );

  /** Sets the date range */
  @action.bound private updateDateRangeValue(range: DateRangeExternalPicker.DateRange) {
    this.dateRange = range;
    this.activeFilters = { ...this.activeFilters };
  }

  private updatePartners = () => {
    this.activeFilters = { ...this.activeFilters };
  };

  getFee = (feeAmount: string, feePercent: number | string) => {
    feeAmount = feeAmount !== null ? feeAmount : '0';
    feePercent = feePercent !== null ? feePercent : '0';
    return `${feePercent}% + ${numericStringToUsd(feeAmount)}`;
  };

  badgeConfig = {
    LINKED: '#4EAE8D',
    NO_WALLET: '#BDBDBD',
    NOT_LINKED: '#E01E5A',
  };

  openDrawer = () => {
    this.isOpen = true;
  };

  closeDrawer = () => {
    this.isOpen = false;
  };

  renderPartnerCell = ({ row }: any) => (
    <Link component={RouterLink} to={paths.partnerDetails(row.id).general()}>
      {row.name}
    </Link>
  );

  renderCellStatus = ({ row }: any) => {
    let bankStatus = row.bankStatus.split('_').join(' ');
    const background = statusConfig[row.bankStatus as keyof IStatusConfig] as ChipStatusColors;
    return <ChipStatusTag label={bankStatus} color={background} />;
  };

  renderDefaultFee = ({ row }: any) => {
    const defaultFee = this.getFee(row.feeAmount, row.feePercent);
    return <Box component="span">{defaultFee}</Box>;
  };

  gridColumns = [
    {
      headerName: 'Name',
      field: 'name',
      minWidth: 320,
      flex: 1,
      renderCell: this.renderPartnerCell,
    },
    { headerName: 'Type', field: 'type', minWidth: 320, flex: 1 },
    {
      headerName: 'Bank Status',
      field: 'bankStatus',
      minWidth: 320,
      flex: 1,
      renderCell: this.renderCellStatus,
    },
    {
      headerName: 'Rebate Start Date',
      field: 'rebateStartDate',
      minWidth: 320,
      flex: 1,
    },
  ];

  filters: Filter[] = [
    { display: 'Name', id: 'search', label: 'Contains', type: 'text' },
    {
      display: 'Status',
      id: 'bankStatus',
      label: 'One of',
      type: 'select',
      items: [
        { label: 'LINKED', value: PartnerStatus.LINKED },
        { label: 'NOT_LINKED', value: PartnerStatus.NOT_LINKED },
        { label: 'NO_WALLET', value: PartnerStatus.NO_WALLET },
      ],
    },
  ];

  render() {
    return (
      <>
        <Box mt={3}>
          <FilterBar
            filters={this.filters}
            onChange={(filters: Record<string, unknown>) => {
              this.activeFilters = filters;
            }}
            // externalDateRange={{
            //   predefined: this.dateRange.type || 'all',
            //   onChange: this.updateDateRangeValue,
            // }}
          />
          <DataGridInfiniteScroll
            columns={this.gridColumns}
            fetch={this.getPartnersData}
            refetchKey={this.activeFilters}
            disableColumnMenu
            pathname={this.props.location.pathname}
          />
        </Box>
        <PlusFabButton hide={!this.props.canPerformActions} onClick={this.openDrawer} />
        <PartnerDrawer
          closeDrawer={this.closeDrawer}
          isOpen={this.isOpen}
          updatePartners={this.updatePartners}
        />
      </>
    );
  }
}

export default withStyles(styles)(Partners);
