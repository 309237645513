import useStyles from './styles';

export type CardIconColor =
  | 'primary'
  | 'secondary'
  | 'warning'
  | 'green'
  | 'red'
  | 'purple'
  | 'yellow';

export function useIconWrapper() {
  const classes = useStyles();
  const getColorClass = (color?: CardIconColor): any => {
    switch (color) {
      case 'yellow':
        return classes.iconYellow;
      case 'secondary':
      case 'warning':
      case 'purple':
        return classes.iconPurple;
      case 'red':
        return classes.iconRed;
      default:
        return classes.iconGreen;
    }
  };

  return getColorClass;
}
