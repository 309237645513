import { makeStyles, createStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    reportCard: {
      minHeight: 337,
      height: '100%',
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(3),
      paddingLeft: theme.spacing(1.5),
      paddingRight: theme.spacing(1.5),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    title: {
      fontSize: 20,
      color: theme.palette.text.primary,
      fontWeight: 600,
      marginTop: theme.spacing(2),
      textAlign: 'center',
    },
    description: {
      marginTop: theme.spacing(3.375),
      marginBottom: 'auto',
      textAlign: 'center',
      lineHeight: '18px',
    },
    reportIcon: {
      width: 65,
      height: 80,
    },
    downloadButton: {
      marginTop: theme.spacing(1.5),
    },
  }),
);

export default useStyles;
