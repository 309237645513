import React from 'react';
import Api from 'api';
import { observable, action, flow, makeObservable } from 'mobx';
import { observer } from 'mobx-react';
import { WithStyles, withStyles } from '@material-ui/core/styles';

import { inject, WithUserStore, WithToastStore } from 'stores';

import styles from './styles';

import validatorjs from 'validatorjs';
import { Paper, Typography, FormHelperText, Box } from '@material-ui/core';
import Overlay from 'components/Overlay';
import { Check } from 'mdi-material-ui';
import { isPhone } from 'services';
import { LeadFormData } from 'models';
import ReCAPTCHA from 'react-google-recaptcha';
import Button from 'components/Button/Button';
import OutlinedInput from 'components/Input/OutlinedInput';
// eslint-disable-next-line @typescript-eslint/no-var-requires
const dvr = require('mobx-react-form/lib/validators/DVR');
const MobxReactForm = require('mobx-react-form').default;

/** Define props for this component */
interface InquiryFormProps extends WithStyles<typeof styles>, WithUserStore, WithToastStore {
  buttonProps?: any;
  inputProps?: any;
  campaignId?: number;
  campaignCode?: string;
}

const plugins = {
  dvr: dvr({
    package: validatorjs,
    extend: ({ validator }: { validator: any; form: any }) => {
      validator.register('phone', isPhone, 'The phone number is not a valid format');
    },
  }),
};

interface PersonalInfoHooks {
  onSuccess: (form: any) => void;
}

@inject('userStore', 'toastStore')
@observer
class InquiryForm extends React.Component<InquiryFormProps> {
  public constructor(props: InquiryFormProps) {
    super(props);
    makeObservable(this);
    const fields = [
      {
        name: 'companyName',
        label: 'Company name',
        rules: 'required|string',
      },
      {
        name: 'firstName',
        label: 'First name',
        rules: 'required|string',
      },
      {
        name: 'lastName',
        label: 'Last name',
        rules: 'required|string',
      },
      {
        name: 'email',
        label: 'Email',
        type: 'email',
        rules: 'required|email',
      },
      {
        name: 'phone',
        label: 'Phone',
        rules: 'required|phone',
      },
      {
        name: 'captcha',
        rules: 'required',
      },
    ];
    this.form = new MobxReactForm({ fields }, { plugins, hooks: this.hooks });
  }

  /** react-mobx-form event hooks*/
  private hooks: PersonalInfoHooks = {
    onSuccess: () => {
      this.handleSubmit();
    },
  };

  /** The form object */
  @observable private form: any;

  /** TODO: connect loading spinner */
  @observable private submitting = false;

  @observable private submitted = false;

  @action.bound public sendInquiry = flow(function* (this: InquiryForm, formData: LeadFormData) {
    const toastStore = this.props.toastStore!;
    this.submitting = true;
    try {
      yield Api.marketing.createLead(formData);
      toastStore.push({ type: 'success', message: `Thank you for submitting the form` });
      this.submitted = true;
    } catch (e: any) {
      toastStore.push({
        type: 'error',
        message: `There was a problem with form submission, please try again later`,
      });
    }
    this.submitting = false;
  });

  private handleSubmit = () => {
    const { campaignId, campaignCode } = this.props;
    const data = {
      accountName: this.form.$('companyName').value,
      firstName: this.form.$('firstName').value,
      lastName: this.form.$('lastName').value,
      email: this.form.$('email').value,
      phone: this.form.$('phone').value,
    };

    this.sendInquiry({
      campaignId,
      campaignCode,
      page: 'CAMPAIGN',
      ...data,
    });
  };

  handleCaptchaToken = (token: string | null | undefined) => {
    if (token) {
      this.form.$('captcha').set('value', token);
      this.form.$('captcha').validate({ showErrors: false });
    }
  };

  isRequired(name: string) {
    if (!this.form.$(name).rules) return false;

    return this.form.$(name).rules.includes('required');
  }

  render() {
    const fields = {
      companyName: this.form.$('companyName'),
      firstName: this.form.$('firstName'),
      lastName: this.form.$('lastName'),
      email: this.form.$('email'),
      phone: this.form.$('phone'),
      captcha: this.form.$('captcha'),
    };

    const { classes } = this.props;

    const paperCSS = {
      padding: 40,
      borderRadius: 8,
      position: 'relative',
      width: '100%',
    } as React.CSSProperties;

    return (
      <Paper style={paperCSS}>
        <form onSubmit={this.form.onSubmit}>
          <Overlay transparent={true} display={this.submitted}>
            <Box pb={2} className={classes.img}>
              <Check
                style={{
                  width: 180,
                  height: 180,
                }}
                color="primary"
                height="100%"
              />
            </Box>
            <Box pb={2}></Box>
          </Overlay>
          <Typography className={classes.formTitle} variant="h5" align="center" gutterBottom>
            {`Fill out the form to receive more information!`}
          </Typography>
          <Box mt={2}>
            <OutlinedInput
              {...fields.companyName.bind()}
              autoFocus
              fullWidth
              label={fields.companyName.label}
              error={fields.companyName.error}
              required={this.isRequired('companyName')}
            />
          </Box>
          <Box mt={2}>
            <OutlinedInput
              {...fields.firstName.bind()}
              autoFocus
              fullWidth
              label={fields.firstName.label}
              error={fields.firstName.error}
              required={this.isRequired('firstName')}
            />
          </Box>
          <Box mt={2}>
            <OutlinedInput
              {...fields.lastName.bind()}
              fullWidth
              label={fields.lastName.label}
              error={fields.lastName.error}
              required={this.isRequired('lastName')}
            />
          </Box>
          <Box mt={2}>
            <OutlinedInput
              {...fields.email.bind()}
              fullWidth
              label={fields.email.label}
              error={fields.email.error}
              required={this.isRequired('email')}
            />
          </Box>
          <Box mt={2}>
            <OutlinedInput
              variant="phone"
              {...fields.phone.bind()}
              fullWidth
              label={fields.phone.label}
              error={fields.phone.error}
              required={this.isRequired('phone')}
            />
          </Box>
          <Box mt={4}>
            <ReCAPTCHA
              sitekey={`${process.env.REACT_APP_RECAPTCHA_SITE_KEY}`}
              onChange={this.handleCaptchaToken}
            />
            {Boolean(fields.captcha.error) && (
              <FormHelperText error>Please verify that you are a human</FormHelperText>
            )}
          </Box>
          <Box>
            <Button
              className={this.props.classes.button}
              size="large"
              type="submit"
              variant="contained"
              color="primary"
              fullWidth>
              {this.props.buttonProps.label ? this.props.buttonProps.label : 'Continue'}
            </Button>
          </Box>
        </form>
      </Paper>
    );
  }
}

export default withStyles(styles)(InquiryForm);
