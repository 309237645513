import { Box, Typography } from '@material-ui/core';
import AnonLayout from 'components/AnonLayout';
import { Link as RouterLink, useHistory, useLocation } from 'react-router-dom';
import { paths } from 'routes';
import notFound from '../../images/404NotFound.svg';
import theme from 'containers/App/theme';
import Button from 'components/Button/Button';
import { useStores } from 'containers/App/App';
import { useCallback } from 'react';

const PageNotFound = () => {
  const location = useLocation();
  const history = useHistory();
  const { userStore } = useStores();

  const handleLogout = useCallback(() => {
    userStore.logout();
    history.push({
      pathname: paths.root(),
    });
  }, [history, userStore]);

  return (
    <Box height={'100dvh'} width={'100%'} style={{ backgroundColor: theme.palette.common.white }}>
      <AnonLayout iconMargin={10}>
        <Box display={'flex'} flexDirection={'column'} alignItems={'center'}>
          <Typography component="h1" align="center" style={{ fontSize: 28 }}>
            Oops!
          </Typography>
          <img style={{ marginTop: theme.spacing(3) }} src={notFound} />
          <Typography style={{ marginTop: theme.spacing(1) }} variant="body2">
            We can't seem to find the page you're looking for.
          </Typography>
          <Box mt={3} width={'100%'}>
            {location.pathname === paths.notFoundLogout ? (
              <Button fullWidth onClick={handleLogout}>
                Logout
              </Button>
            ) : (
              <Button fullWidth component={RouterLink} to={paths.root()}>
                Back to dashboard
              </Button>
            )}
          </Box>
        </Box>
      </AnonLayout>
    </Box>
  );
};

export default PageNotFound;
