import Button from 'components/Button/Button';
import { TSignupLayoutSubAction } from '../SignupLayout';
import { Box } from '@material-ui/core';
import theme from 'containers/App/theme';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft } from '@fortawesome/pro-regular-svg-icons';
import SkipButton from 'components/Button/SkipButton';
import useStyles from './styles';

const SubAction = (subAction: TSignupLayoutSubAction) => {
  const classes = useStyles();
  const back = subAction?.back;
  const skip = subAction?.skip;
  const center = subAction?.center;
  if (!(back || skip || center)) return null;

  return (
    <Box
      display={'flex'}
      alignItems={'center'}
      position={'relative'}
      height={theme.spacing(3)}
      mt={2}>
      {back && (
        <Button
          onClick={back.onClick}
          textTransform="none"
          data-cy="back-button"
          style={{ ...theme.typography.body2 }}
          variant="text"
          startIcon={<FontAwesomeIcon icon={faAngleLeft} />}>
          {back?.label || 'Back'}
        </Button>
      )}
      {skip && (
        <SkipButton
          data-cy={'skip-button'}
          onClick={skip.onClick}
          style={{ ...theme.typography.body2 }}>
          {skip?.label || 'SKIP'}
        </SkipButton>
      )}
      {center && (
        <Button
          data-cy={'center-button'}
          onClick={center.onClick}
          textTransform="none"
          className={classes.center}
          variant="text">
          {center?.label}
        </Button>
      )}
    </Box>
  );
};

export default SubAction;
