import Api from 'api';

export class BlSettings {
  static async getSettings(locationId: number, entity: 'station' | 'location' | 'account') {
    if (!locationId) {
      return;
    }

    const { data } = await Api.core.getSettings(entity, locationId);
    if (data && data.data) {
      return data.data;
    }
  }
}
