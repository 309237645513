import { Pool, TippingMode } from './Pool';
import { User } from './User';

export enum AddStation {
  ADD = 'add',
  POOLS_GROUPS = 'pools_groups',
  GROUP = 'group',
  GROUP_POOLS = 'group_pools',
  USERS = 'users',
  GROUP_USERS = 'group_users',
  POOLS = 'pools',
  POOL = 'pool',
  GROUP_POOL = 'group_pool',
  QR_CODE = 'qr_code',
}

export enum StationItem {
  USER = 'user',
  POOL = 'pool',
  GROUP = 'group',
}

export interface IFetchStation extends Omit<IStation, 'items'> {
  items: Array<IFetchStationItem>;
}

export interface IFetchStationPool {
  name: string;
  pool: Pool;
  type: StationItem.POOL;
}

export interface IFetchStationUser {
  name: string;
  user: User;
  type: StationItem.USER;
}

export interface IFetchStationGroup {
  name: string;
  group: Group;
  type: StationItem.GROUP;
}
export interface Group {
  id: number;
  items: Array<IFetchGroupItem>;
  name: string;
}

export type IGroupItem = IStationUser | IStationPool;

export type IFetchGroupItem = IFetchStationUser | IFetchStationPool;

export type IFetchStationItem = IFetchStationPool | IFetchStationUser | IFetchStationGroup;

export type IStationItem = IStationUser | IStationPool | IStationGroup;

export interface IStationUser {
  type: StationItem.USER;
  userId: number;
}

export interface IStationPool {
  type: StationItem.POOL;
  poolId: number;
}

export interface IStationGroup {
  type: StationItem.GROUP;
  groupName: string;
  groupItems: Array<IGroupItem>;
}

export interface IStation {
  id?: string;
  uid?: string;
  locationId?: string;
  name: string;
  items: Array<IStationItem>;
}

export interface ICustomLocationUser {
  id: number;
  fullName: string;
  email: string;
}

export interface ISelectedLocationPool {
  id: number;
  name: string;
  selected: boolean;
  type: StationItem;
}

export enum FormType {
  GROUP = 'group',
  ADD = 'add',
}

export interface IStationSettings {
  wayToTip: TippingMode;
}
