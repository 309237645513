import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { observable, action, computed, makeObservable } from 'mobx';
import { observer } from 'mobx-react';
import { WithStyles, withStyles } from '@material-ui/core/styles';
import { Box, Drawer } from '@material-ui/core';

import Api, { RequestMetaData } from 'api';
import { inject, WithUserStore } from 'stores';

import DashboardLayout from 'containers/DashboardLayout';
import CreditCardIcon from 'components/CreditCardIcon';
import PaymentsTable from 'components/PaymentsTable';
import styles from './styles';
import PaymentMethodsDrawer from 'components/PaymentMethodsDrawer';
import Title from 'components/Title';
interface PaymentsMatchParams {
  id: string;
}

/** Define props for this component */
type CustomerPaymentsProps = WithStyles<typeof styles> & // Adds the classes prop
  RouteComponentProps<PaymentsMatchParams> & // Adds the router props (history, match, location)
  WithUserStore; // Adds the userStore prop

/**
 * The payments container, restricted to admin users.
 */
@inject('userStore')
@observer
class CustomerPayments extends React.Component<CustomerPaymentsProps> {
  @observable public isPaymentMethodDrawerOpen? = false;

  public constructor(props: CustomerPaymentsProps) {
    super(props);
    makeObservable(this);
    this.matchParams = this.props.match.params;
  }

  @observable public matchParams: PaymentsMatchParams;

  /** Get the customer id from match params */
  @computed public get customerId(): number {
    return parseInt(this.matchParams.id);
  }

  /** Customer name passed as 'state' field using react-router-dom Link component */
  @computed public get customerName(): string {
    const state: any = this.props.location.state;
    return state ? state.customerName : 'Customer';
  }

  /** Fetches the payments for this customer */
  @action.bound public fetchPayments(rmd: RequestMetaData) {
    return Api.tips.getCustomerPayments(this.customerId, rmd);
  }

  @action.bound private openPaymentMethodsDrawer = () => {
    this.isPaymentMethodDrawerOpen = true;
  };

  @action.bound private onClosePaymentMethodsDrawer = () => {
    this.isPaymentMethodDrawerOpen = false;
  };

  render() {
    return (
      <DashboardLayout>
        <Box display="flex" flexDirection="row" justifyContent="space-between" mt={3}>
          <Box display="flex" justifyContent="flex-start">
          <Title mb={2}>{this.customerName}</Title>
          </Box>
          <Box display="flex" justifyContent="flex-end">
            <CreditCardIcon brand="" color="primary" onClick={this.openPaymentMethodsDrawer} />
          </Box>
        </Box>
        <PaymentsTable fetch={this.fetchPayments} {...this.props} />

        <Drawer
          open={this.isPaymentMethodDrawerOpen}
          onClose={this.onClosePaymentMethodsDrawer}
          anchor="right"
          variant="temporary">
          <PaymentMethodsDrawer
            onClose={this.onClosePaymentMethodsDrawer}
            customerId={this.customerId}
          />
        </Drawer>
      </DashboardLayout>
    );
  }
}

export default withStyles(styles)(CustomerPayments);
