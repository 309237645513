import { Box, Paper } from '@material-ui/core';
import DashboardLayout from 'containers/DashboardLayout';
import { Observer, observer } from 'mobx-react';
import React from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import { UserStore, WithManagerPermissionsStore, inject } from 'stores';
import { ScopeType, WithUserStore } from 'stores/UserStore';
import { ACL, EManagerPermission } from 'types';
import * as paths from './paths';
import qs from 'qs';
import LoadingPage from './LoadingPage';
import jwtDecode from 'jwt-decode';
import { ISsoToken } from 'models/Sso';
import { LocationState, LoginAction, getPhoneActionNeeded } from './helpers';
import { Location } from 'history';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import { EFeatureFlags, IFeatureFlags } from 'models';

interface AuthRouteProps extends RouteProps, WithUserStore, WithManagerPermissionsStore {
  scopes?: ScopeType[];
  location?: Location<LocationState>;
  permissions?: ACL[];
  managerPermissions?: EManagerPermission[];
  featureFlagConfig?: EFeatureFlags;
  flags?: IFeatureFlags;
}

async function handleLogin(userStore: UserStore, email: string, token: string) {
  await userStore.login({ email, token });
}

/**
 * Same as Route, only makes sure that the current user is logged in.
 * If they're not, redirects to '/login' or to a custom route.
 */
@inject('userStore', 'managerPermissionsStore')
@observer
class AuthRoute extends React.Component<AuthRouteProps> {
  render() {
    return (
      <Observer>
        {() => {
          const {
            component: Component,
            render,
            userStore,
            managerPermissionsStore,
            scopes,
            path,
            permissions,
            managerPermissions,
            location,
            featureFlagConfig,
            flags,
            ...rest
          } = this.props;
          let isSsoLogin = false;
          let search;
          let internalRedirect;
          // Check if there are email and token query params present and login if they are
          if (location?.search) {
            const { token, redirect, redirectTo } = qs.parse(location.search, {
              ignoreQueryPrefix: true,
            }) as {
              token: string;
              email: string;
              redirect: string;
              redirectTo: string;
            };
            if (token) {
              isSsoLogin = true;
              if (!userStore?.loggedIn) {
                const { email } = jwtDecode(token) as ISsoToken;
                userStore?.getSsoProvider(email);
                handleLogin(userStore!, email, token);
              }
              location.search = '';
            } else if (redirect) {
              search = redirect;
            } else if (redirectTo) {
              internalRedirect = redirectTo;
              const _search = new URLSearchParams(location?.search);
              _search.delete('redirectTo');
              search = _search.toString();
            }
          }

          if (userStore?.loggingIn) {
            return <LoadingPage />;
          }

          // Check if the user has all the permissions that are requested in the route
          const permissionsOk =
            !permissions || permissions.every((p) => userStore!.hasPermission(p));
          // Check if the manager has all the permissions that are requested in the route
          const managerPermissionsOk =
            userStore!.scope.kind !== 'manager' ||
            !managerPermissions ||
            managerPermissions.every((p) => managerPermissionsStore!.hasPermission(p));
          // If a scopes array is provided, make sure that the current scope
          // is one of the allowed scopes for this route.
          const scopeOk = !scopes || scopes.includes(userStore!.scope.kind);
          const authOk = userStore!.loggedIn;
          const nameOk = userStore!.hasName;
          const phoneAction = getPhoneActionNeeded(isSsoLogin, location);
          if (userStore?.loggingIn && isSsoLogin) {
            return <LoadingPage />;
          }

          // If user is not logged in, redirect to sign-in
          if (!authOk) {
            return (
              <Redirect push to={{ pathname: paths.signIn(), search, state: { from: location } }} />
            );
          }

          if (internalRedirect) {
            return (
              <Redirect
                push
                to={{ pathname: internalRedirect, search, state: { from: location } }}
              />
            );
          }

          // If user does not have phone number or has unconfirmed phone number, redirect to add phone screen
          if (phoneAction) {
            let state = { loginAction: LoginAction.ADD_PHONE, from: location };
            if (phoneAction === LoginAction.VERIFY_PHONE) {
              state = { ...state, loginAction: LoginAction.VERIFY_PHONE };
            }
            return (
              <Redirect
                push
                exact
                to={{
                  pathname: paths.phoneNumber().root(),
                  search,
                  state,
                }}
              />
            );
          }
          if (!nameOk && path !== paths.signUp().personalInfo()) {
            // If the user doesn't have a first and last name, redirect them to that screen
            return (
              <Redirect
                push
                to={{ pathname: paths.signUp().personalInfo(), search, state: { from: location } }}
              />
            );
          }
          // If the permissions aren't OK, display a message
          if (!permissionsOk) {
            const missingPermissions = permissions
              ? permissions.filter((p) => !userStore!.hasPermission(p))
              : [];
            return (
              <DashboardLayout>
                <Paper>
                  <Box p={3}>
                    You do not have permission to view this page. You are missing the following
                    permissions:
                    <ul>
                      {missingPermissions.map((mp) => (
                        <li key={mp}>{mp.toUpperCase()}</li>
                      ))}
                    </ul>
                  </Box>
                </Paper>
              </DashboardLayout>
            );
          }

          if (!managerPermissionsOk) {
            return <Redirect to={{ pathname: paths.root(), search, state: { from: location } }} />;
          }

          // If the scope is not ok, redirect to home screen
          if (!scopeOk) {
            return <Redirect to={{ pathname: paths.root(), search, state: { from: location } }} />;
          }

          /**
           * If REFERRAL feature flag is not yet loaded, show Loading page
           */
          if (this.props.flags?.[EFeatureFlags.REFERRAL] === undefined) {
            return <LoadingPage />;
          }
          /**
           * If REFERRAL feature flag is set for this route and API response is "false"
           */
          if (
            featureFlagConfig === EFeatureFlags.REFERRAL &&
            this.props.flags?.tip3925ReferAppHomescreen === false
          ) {
            return <Redirect to={{ pathname: paths.root(), search, state: { from: location } }} />;
          }

          return (
            <Route
              {...rest}
              render={(props) => (Component ? <Component {...props} /> : render!(props))}
            />
          );
        }}
      </Observer>
    );
  }
}

export default withLDConsumer()(AuthRoute);
