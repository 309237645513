import { KioskMode } from 'models';

export const KIOSK_TEXT = {
  [KioskMode.CUSTOM_ONLY]:
    'The kiosk is enabled only for custom tip amounts. It will not ask user to enter service amount and it will not present tip suggestions.',
  [KioskMode.POOLS]:
    'The kiosk suggests pool and station groups for tipping, not individual users. Tip suggestions follow the Basic and Fixed Amounts Proposal Strategy. Transparency options are limited to Full or Optional. Tips remain pending until allocated by an owner or manager; no automatic deposits.',
  [KioskMode.INTEGRATED]:
    'The kiosk suggests tip amounts based on the service amount provided by the POS software. Various Proposal Strategies can be applied excluding Dynamic and Breakpoint. Transparency options are limited to Full only.',
  [KioskMode.STANDARD]:
    'The kiosk suggests tip amounts based on the service amount entered by the user. Various Proposal Strategies can be applied. Transparency options are limited to Full or None.',
};
