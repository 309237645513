import React from 'react';
import { observer } from 'mobx-react';
import NotificationItem from '../NotificationItem/NotificationItem';
import { rootStore } from 'containers/App/App';
import { paths } from 'routes';
import { Typography } from '@material-ui/core';
import { IRefundRequestNotificationItem } from 'stores';

interface IRefundRequestItemProps {
  refundRequests: IRefundRequestNotificationItem;
}

function goToRefundRequests() {
  const { notificationStore, routerStore } = rootStore;

  notificationStore!.setForceClose(true);
  notificationStore!.setOpenNotification(false);
  routerStore!.history.push(`${paths.refunds().root()}?showCreated=true`);
}

const RefundRequestItem = ({ refundRequests }: IRefundRequestItemProps) => {
  const { numberOfRequests: length } = refundRequests;
  return (
    <NotificationItem
      title={'Refund request'}
      body={
        <Typography variant="body1">
          {`${length} refund request${length > 1 ? 's are' : ' is'} in need of your attention.`}
        </Typography>
      }
      buttons={[
        { label: 'Manage', dataCy: 'manage-refund-requests-button', onClick: goToRefundRequests },
      ]}
    />
  );
};

export default observer(RefundRequestItem);
