import { Typography, Box, CircularProgress } from '@mui/material';
import OutlinedInput from 'components/Input/OutlinedInput/OutlinedInput';
import * as models from 'models';
import Api, { getErrorMsg } from 'api';
import { useStores } from 'containers/App/App';
import Dialog from 'components/Dialog/Dialog';
import { useEffect, useMemo, useState } from 'react';
import { ELicenseBillingGeneralDialog, ILicenseBillingGeneralDialogProps } from '../models';

interface IChangeProductDialogProps extends ILicenseBillingGeneralDialogProps {
  license: models.License | null;
  onProductChange: VoidFunction;
}

const ChangeProductDialog = ({
  open,
  type,
  title,
  license,
  TransitionProps,
  onCancel,
  onProductChange,
}: IChangeProductDialogProps) => {
  const { toastStore } = useStores();

  const [products, setProducts] = useState<models.Product[]>([]);

  const [selectedProductId, setSelectedProductId] = useState<number>();

  const [changingProduct, setChangingProduct] = useState(false);

  const [loadingProducts, setLoadingProducts] = useState(false);

  const options = useMemo(() => {
    return products.map((p) => {
      return { label: p.name, value: p.id };
    });
  }, [products]);

  useEffect(() => {
    if (!license || type !== ELicenseBillingGeneralDialog.CHANGE_PRODUCT) return;

    const fetchProductsAvailableForLicense = async () => {
      try {
        setLoadingProducts(true);
        const { data } = await Api.billing.getProductsAvailableByLicense(license!.id);
        setProducts(data?.data || []);
      } catch (e) {
        toastStore!.error(getErrorMsg(e));
      } finally {
        setLoadingProducts(false);
      }
    };
    fetchProductsAvailableForLicense();
  }, [license, type, toastStore, setProducts, setSelectedProductId]);

  const handleConfirm = async () => {
    try {
      setChangingProduct(true);
      await Api.billing.ReplaceLicenseProduct(license!.id, selectedProductId!);
      toastStore!.success('Product updated successfully');
      onProductChange();
      onCancel();
    } catch (e) {
      toastStore!.error(getErrorMsg(e));
    } finally {
      setChangingProduct(false);
    }
  };

  const handleChangeProductId = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedProductId(e.target.value as unknown as number);
  };

  const disabled = !license?.id || !selectedProductId || changingProduct;

  const _open = open && type === ELicenseBillingGeneralDialog.CHANGE_PRODUCT;

  return (
    <Dialog
      open={_open}
      title={title}
      disabled={disabled}
      loading={changingProduct}
      content={
        <Box>
          <Typography variant="body2">
            Do you need to change the product for the license?
          </Typography>

          <Box mb={3} mt={2}>
            <OutlinedInput
              select
              fullWidth
              label={'Select Product'}
              disabled={loadingProducts || changingProduct}
              InputProps={{
                endAdornment: loadingProducts && <CircularProgress size={27} />,
              }}
              value={selectedProductId || ''}
              onChange={handleChangeProductId}
              options={options}
            />
          </Box>
        </Box>
      }
      onConfirm={handleConfirm}
      onCancel={onCancel}
      onClose={onCancel}
      TransitionProps={{
        onExited: (node) => {
          TransitionProps?.onExited && TransitionProps.onExited(node);
          setProducts([]);
          setSelectedProductId(undefined);
        },
      }}
    />
  );
};

export default ChangeProductDialog;
