import { ApiResponse } from 'api';
import { AxiosResponse } from 'axios';

type TFetchFunction<T> = () => Promise<AxiosResponse<ApiResponse<T[]>>>;
export type TFilterMapperFunction<T> = (data: T) => any;

type TAdaptForFilterFunction = <T>(
  fetch: TFetchFunction<T>,
  mapper: TFilterMapperFunction<T>,
  abortController?: () => void,
) => Promise<any[]>;

const adaptForFilter: TAdaptForFilterFunction = async (fetch, mapper, abortController) => {
  abortController && abortController();
  try {
    const { data } = await fetch();
    if (!data?.data?.length) return [];
    return data.data.map((item) => mapper(item));
  } catch (error) {
    return [];
  }
};

export default adaptForFilter;
