import { Fulfillment } from './Fulfillment';
import { Account } from './Account';
import { Product } from './Product';
import { ShippingAddress } from 'models';
import { EInvoiceItemType } from 'types';

export enum EInvoiceSource {
  CHECKOUT = 'checkout',
  JOB = 'job',
  INACTIVITY = 'inactivity',
}

export interface Invoice {
  id: number;
  parentId: number | null;
  createdAt: string;
  accountId: number;
  number: string;
  dueOn: string;
  captureOn: string | null;
  net: string;
  tax: string;
  discount: string;
  gross: string;
  grossValue: string;
  availableRefundAmount: string;
  availableRefundAmountValue: string;
  refundedAmount: string;
  refundedAmountValue: string;
  status: string;
  taxProcessor: string;
  taxProcessorId: string;
  taxDocumentStatus: string;
  isShippingOnly: boolean;
  fulfillment: Fulfillment;
  account: Account;
  identifier: string;
  creationSource?: EInvoiceSource;
}
export interface IInvoiceItem {
  id: number;
  invoiceId: number;
  productId: number;
  shippingAddressId?: number;
  item: string;
  quantity: number;
  discount: string;
  net: string;
  tax: string;
  gross: string;
  taxIdentifier: string;
  locationId?: number;
  invoice?: Invoice;
  product?: Product;
  location?: Location;
  shippingAddress?: ShippingAddress;
  invoiceItemType: EInvoiceItemType;
}
interface Location {
  id?: number;
  name?: string;
  address?: string;
  address2?: string;
  city?: string;
  state?: string;
  country?: string;
  zip?: string;
  isActive?: boolean;
}
