import React from 'react';
import { observable, makeObservable } from 'mobx';

import { observer } from 'mobx-react';
import {
  Box,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Drawer,
  IconButton,
  MenuItem,
  Typography,
} from '@material-ui/core';
import { WithStyles, withStyles } from '@material-ui/core/styles';
import styles from '../styles';
import Api, { getErrorMsg } from '../../../../api';
import {
  Developer,
  Partner,
  PARTNER_TYPE,
  PAYOUT_INTERVAL,
} from '../../../../models';
import { Close } from 'mdi-material-ui';
import { capitalize } from 'lodash';
import validatorjs from 'validatorjs';
import { inject, WithToastStore } from '../../../../stores';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import moment from 'moment-timezone';
import OutlinedInput from 'components/Input/OutlinedInput';
import OutlinedDatePicker from 'components/Input/OutlinedDatePicker';
import Button from 'components/Button/Button';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const dvr = require('mobx-react-form/lib/validators/DVR');
const MobxReactForm = require('mobx-react-form').default;

type PartnerDetailsDrawerProps = WithStyles<typeof styles> &
  WithToastStore & {
    partnerData: Partner;
    partnerId: number;
    walletId?: number;
    isOpen: boolean;
    closeDrawer: () => void;
  };

interface Rules {
  [key: string]: {
    function: (value: any) => boolean;
    message: string;
  };
}

function getDeveloperRule(fieldName: string) {
  return {
    function: (value: Developer) => {
      if (
        !Object.prototype.hasOwnProperty.call(value, 'id') ||
        !Object.prototype.hasOwnProperty.call(value, 'name')
      )
        return false;
      const { id, name } = value;
      if (typeof id === 'number' && typeof name === 'string') return true;
      return false;
    },
    message: `${fieldName} must be valid.`,
  };
}

const rules: Rules = {
  developer: getDeveloperRule('Developer'),
  account: getDeveloperRule('Account'),
  decimal: {
    function: (value: any) => {
      const mask = RegExp('^[0-9]*(.[0-9]{1,2})?$');
      if (mask.test(value)) return true;
      return false;
    },
    message: 'Number must be an integer or decimal number with 2 decimal places.',
  },
};

const plugins = {
  dvr: dvr({
    package: validatorjs,
    extend: ({ validator, form }: { validator: any; form: any }) => {
      Object.keys(rules).forEach((key) =>
        validator.register(key, rules[key].function, rules[key].message),
      );
    },
  }),
};

export enum RebateStatus {
  NO_REBATES = 'no_rebates',
  ACTIVATED = 'activated',
  DEACTIVATED = 'deactivated',
  REQUIRE_SELF_ACTIVATION = 'require_self_activation',
}

interface IFormState {
  name?: string;
  description?: string;
  type?: string;
  account?: Developer;
  developer?: Developer;
  processor?: string;
  payoutProcessor?: string;
  payoutDestination?: string;
  feeAmount?: number;
  feePercent?: number;
  payoutInterval?: string;
  rebateStartDate?: string;
  rebateStatus?: string;
  wallet?: { processor: string; destination: string };
}

@inject('toastStore')
@observer
class PartnerDetailsDrawer extends React.Component<PartnerDetailsDrawerProps> {
  constructor(props: any) {
    super(props);
    makeObservable(this);
    this.form = new MobxReactForm({ fields: this.fields }, { plugins, hooks: this.hooks });
  }

  componentDidMount() {
    this.checkIfFormValid(true);
  }

  @observable private isNameDuplicated = false;
  @observable private isFormValid = false;
  @observable private isSaving = false;

  private form: any;

  private defaultFormState: IFormState = {
    name: undefined,
    description: undefined,
    type: 'account',
    account: undefined,
    developer: undefined,
    processor: undefined,
    payoutProcessor: undefined,
    payoutDestination: undefined,
    feeAmount: undefined,
    feePercent: undefined,
    rebateStartDate: undefined,
  };

  getRebateStatus = (status?: RebateStatus) => {
    if (!status) return undefined;
    return status.replace(/_/g, ' ');
  };

  @observable private formState: IFormState = {
    ...this.defaultFormState,
    ...this.props.partnerData,
    rebateStatus: this.getRebateStatus(this.props.partnerData.rebateStatus),
    payoutProcessor: this.props.partnerData.wallet
      ? this.props.partnerData.wallet.processor
      : undefined,
    payoutDestination: this.props.partnerData.wallet
      ? this.props.partnerData.wallet.destination
      : undefined,
  };

  checkIfFormValid = async (showErrors: boolean, name?: string) => {
    let isNameDuplicated = this.isNameDuplicated;
    if (name === 'name' && this.formState.name !== this.props.partnerData.name) {
      this.enableSave(true);
      const duplicate = await this.checkIfDuplicateName(this.formState.name);
      if (duplicate) {
        isNameDuplicated = true;
      } else isNameDuplicated = false;
      if (this.isNameDuplicated !== isNameDuplicated) this.isNameDuplicated = isNameDuplicated;
    }
    const isValid = await this.form.validate({ showErrors });
    const hasError = isValid.hasError || isNameDuplicated;
    this.enableSave(hasError);
  };

  enableSave = (hasError: boolean) => {
    if (hasError) return (this.isFormValid = false);
    if (!hasError) return (this.isFormValid = true);
  };

  checkIfDuplicateName = async (name?: string) => {
    if (!name) return;
    const { data } = await Api.tips.searchPartnerByName(name);
    if (data && data.data.length) {
      this.form.$('name').invalidate('Partner name already exists.');
      return true;
    }
    return false;
  };

  private changeSelectedField = (e: any) => {
    const { name, value } = e;
    this.formState[name as keyof IFormState] = value;
    this.checkIfFormValid(false, name);
  };

  private changeDateField = (e: any) => {
    const { name, value } = e;
    this.formState[name as keyof IFormState] = value;
    this.checkIfFormValid(false, name);
  };

  @observable private fields = [
    {
      name: 'name',
      label: 'Name',
      value: this.formState.name,
      rules: 'required|string',
      hooks: {
        onChange: this.changeSelectedField,
      },
    },
    {
      name: 'description',
      label: 'Description',
      value: this.formState.description,
      rules: 'string',
      hooks: {
        onChange: this.changeSelectedField,
      },
    },
    {
      name: 'type',
      label: 'Type',
      type: 'select',
      value: this.formState.type,
      rules: 'required|string',
      hooks: {
        onChange: this.changeSelectedField,
      },
    },
    {
      name: 'account',
      label: 'Account',
      value: this.formState.account,
      rules: `required_if:type,${PARTNER_TYPE.ACCOUNT}|account`,
    },
    {
      name: 'developer',
      label: 'Developer',
      value: this.formState.developer,
      rules: `required_if:type,${PARTNER_TYPE.DEVELOPER}|developer`,
    },
    {
      name: 'processor',
      label: 'Processor',
      value: this.formState.processor,
      rules: `required_if:type,${PARTNER_TYPE.PROCESSOR}|string`,
    },
    {
      name: 'payoutProcessor',
      label: 'Payout processor',
      value: this.formState.payoutProcessor,
      rules: `required_if:type,${PARTNER_TYPE.OTHER}|string`,
      hooks: {
        onChange: this.changeSelectedField,
      },
    },
    {
      name: 'payoutDestination',
      label: 'Payout destination',
      value: this.formState.payoutDestination,
      rules: `required_if:type,${PARTNER_TYPE.OTHER}|string`,
      hooks: {
        onChange: this.changeSelectedField,
      },
    },
    {
      name: 'feeAmount',
      label: 'Amount',
      value: this.formState.feeAmount,
      rules: 'decimal',
      hooks: {
        onChange: this.changeSelectedField,
      },
    },
    {
      name: 'feePercent',
      label: 'Percent',
      value: this.formState.feePercent,
      rules: 'decimal',
      hooks: {
        onChange: this.changeSelectedField,
      },
    },
    {
      name: 'payoutInterval',
      label: 'Payout interval',
      type: 'select',
      value: this.formState.payoutInterval,
      rules: 'string',
      hooks: {
        onChange: this.changeSelectedField,
      },
    },
    {
      name: 'rebateStartDate',
      label: 'Rebate start date',
      type: 'select',
      value: this.formState.rebateStartDate,
      hooks: {
        onChange: this.changeDateField,
      },
    },
    {
      name: 'rebateStatus',
      label: 'Rebate status',
      type: 'select',
      value: this.formState.rebateStatus,
      hooks: {
        onChange: this.changeDateField,
      },
    },
  ];

  private preparePartnerData = () => {
    let { name, description, type, feeAmount, feePercent, payoutInterval, rebateStartDate } =
      this.formState;

    let partner: Partial<Partner> = { feeAmount, feePercent };
    if (name) {
      partner.name = name;
    }
    if (description) {
      partner.description = description;
    }
    if (type) {
      partner.type = type as PARTNER_TYPE;
    }
    if (!partner.feeAmount) delete partner.feeAmount;
    else partner.feeAmount = Number(partner.feeAmount);
    if (!partner.feePercent) delete partner.feePercent;
    else partner.feePercent = Number(partner.feePercent);
    if (payoutInterval) partner.payoutInterval = payoutInterval as PAYOUT_INTERVAL;
    if (rebateStartDate) partner.rebateStartDate = moment(rebateStartDate).toISOString();
    if ((type as string) === PARTNER_TYPE.ACCOUNT) {
      const account = this.formState.account;
      partner = { ...partner, accountId: account!.id };
      return partner;
    }
    if ((type as string) === PARTNER_TYPE.DEVELOPER) {
      const developer = this.formState.developer;
      partner = { ...partner, developerId: developer!.id };
      return partner;
    }
    if ((type as string) === PARTNER_TYPE.PROCESSOR) {
      const processor = this.formState.processor;
      partner = { ...partner, processor };
      return partner;
    }
    if ((type as string) === PARTNER_TYPE.OTHER) {
      const payoutProcessor = this.formState.payoutProcessor;
      const payoutDestination = this.formState.payoutDestination;
      partner = { ...partner, payoutProcessor, payoutDestination };
      return partner;
    }
    return undefined;
  };

  private async updatePartner() {
    const data: any = { ...this.preparePartnerData() };
    const partnerResp = await Api.tips.updatePartner(this.props.partnerId, data);
    if (this.props.walletId) {
      const walletResp = await Api.tips.updateWallet(this.props.walletId, {
        payoutProcessor: data.payoutProcessor,
        payoutDestination: data.payoutDestination,
      });
    }
    return partnerResp;
  }

  isRequired(name: string) {
    if (!this.form.$(name).rules) return false;

    if (name === 'account') return this.formState.type === PARTNER_TYPE.ACCOUNT;
    if (name === 'developer') return this.formState.type === PARTNER_TYPE.DEVELOPER;
    if (name === 'processor') return this.formState.type === PARTNER_TYPE.PROCESSOR;
    if (name === 'payoutProcessor') return this.formState.type === PARTNER_TYPE.OTHER;
    if (name === 'payoutDestination') return this.formState.type === PARTNER_TYPE.OTHER;

    return this.form.$(name).rules.includes('required');
  }

  private hooks = {
    onSuccess: async () => {
      try {
        this.isSaving = true;
        const { data } = await this.updatePartner();
        this.formState = data.data;
        this.props.closeDrawer();
        this.props.toastStore!.push({ type: 'success', message: 'Partner updated successfully' });
      } catch (error: any) {
        this.props.toastStore!.push({ type: 'error', message: getErrorMsg(error) });
      } finally {
        this.isSaving = false;
      }
    },
  };

  resetFormAndClose = async () => {
    this.props.closeDrawer();
    this.formState = {
      ...this.defaultFormState,
      ...this.props.partnerData,
      payoutProcessor: this.props.partnerData.wallet
        ? this.props.partnerData.wallet.processor
        : undefined,
      payoutDestination: this.props.partnerData.wallet
        ? this.props.partnerData.wallet.destination
        : undefined,
    };
    this.form = new MobxReactForm({ fields: this.fields }, { plugins, hooks: this.hooks });
  };

  render() {
    const { dialogTitle, partnerDrawerDialogActions } = this.props.classes;
    return (
      <Drawer
        title="Details"
        className={this.props.classes.root}
        anchor="right"
        open={this.props.isOpen}>
        <DialogTitle className={dialogTitle}>
          <Box display="flex" alignItems="center" justifyContent="space-between">
            <Typography style={{ fontSize: 28 }} component="h1" display="inline">
              Update Partner
            </Typography>
            <IconButton onClick={this.resetFormAndClose}>
              <Close color="inherit" />
            </IconButton>
          </Box>
        </DialogTitle>
        <Divider />
        <DialogContent>
          <form onSubmit={this.form.onSubmit} id="partner-form">
            <Box mt={2}>
              <OutlinedInput
                {...this.form.$('name').bind()}
                helperText={
                  this.isNameDuplicated ? 'Partner name already exists' : this.form.$('name').error
                }
                error={this.form.$('name').error || this.isNameDuplicated}
                required={this.isRequired('name')}
                fullWidth
              />
            </Box>
            <Box mt={2}>
              <OutlinedInput
                {...this.form.$('description').bind()}
                error={this.form.$('description').error}
                required={this.isRequired('description')}
                fullWidth
              />
            </Box>
            <Box mt={2}>
              <OutlinedInput
                disabled={true}
                value={this.formState.type ? this.formState.type : undefined}
                InputProps={{ readOnly: true }}
                error={this.form.$('type').error}
                required={this.isRequired('type')}
                fullWidth
              />
            </Box>
            {this.formState.type === PARTNER_TYPE.ACCOUNT && (
              <Box mt={2}>
                <OutlinedInput
                  value={this.formState.account ? this.formState.account.name : undefined}
                  disabled={true}
                  required={this.isRequired('account')}
                  fullWidth
                />
              </Box>
            )}
            {this.formState.type === PARTNER_TYPE.DEVELOPER && (
              <Box mt={2}>
                <OutlinedInput
                  value={this.formState.developer ? this.formState.developer.name : undefined}
                  InputProps={{ readOnly: true }}
                  disabled={true}
                  required={this.isRequired('developer')}
                  fullWidth
                />
              </Box>
            )}
            {this.formState.type === PARTNER_TYPE.PROCESSOR && (
              <Box mt={2}>
                <OutlinedInput
                  value={this.formState.processor ? this.formState.processor : undefined}
                  InputProps={{ readOnly: true }}
                  disabled={true}
                  required={this.isRequired('processor')}
                  fullWidth
                />
              </Box>
            )}
            {this.formState.type === PARTNER_TYPE.OTHER && (
              <>
                {' '}
                <Box mt={2}>
                  <OutlinedInput
                    value={
                      this.formState.payoutProcessor ? this.formState.payoutProcessor : undefined
                    }
                    error={this.form.$('payoutProcessor').error}
                    InputProps={{ readOnly: true }}
                    disabled={true}
                    required={this.isRequired('payoutProcessor')}
                    fullWidth
                  />
                </Box>
                <Box mt={2}>
                  <OutlinedInput
                    value={
                      this.formState.payoutDestination
                        ? this.formState.payoutDestination
                        : undefined
                    }
                    error={this.form.$('payoutDestination').error}
                    InputProps={{ readOnly: true }}
                    required={this.isRequired('payoutDestination')}
                    disabled={true}
                    fullWidth
                  />
                </Box>
              </>
            )}
            <Box mt={2}>
              <OutlinedInput
                {...this.form.$('feeAmount').bind()}
                error={this.form.$('feeAmount').error}
                required={this.isRequired('feeAmount')}
                fullWidth
              />
            </Box>
            <Box mt={2}>
              <OutlinedInput
                {...this.form.$('feePercent').bind()}
                error={this.form.$('feePercent').error}
                required={this.isRequired('feePercent')}
                fullWidth
              />
            </Box>
            <Box mt={2}>
              <OutlinedInput
                {...this.form.$('payoutInterval').bind()}
                error={this.form.$('payoutInterval').error}
                required={this.isRequired('payoutInterval')}
                select
                fullWidth>
                {Object.values(PAYOUT_INTERVAL).map((interval, index) => (
                  <MenuItem key={`${interval}-${index}`} value={interval}>
                    {capitalize(interval)}
                  </MenuItem>
                ))}
              </OutlinedInput>
            </Box>
            <Box mt={2}>
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <OutlinedDatePicker
                  {...this.form.$('rebateStartDate').bind()}
                  value={this.formState.rebateStartDate ? this.formState.rebateStartDate : null}
                  animateYearScrolling
                  format={'L'}
                  autoOk
                  clearable
                  disablePast={true}
                  error={this.form.$('rebateStartDate').error}
                  required={this.isRequired('rebateStartDate')}
                  fullWidth
                />
              </MuiPickersUtilsProvider>
            </Box>
            <Box mt={2}>
              <OutlinedInput
                disabled={true}
                value={this.formState.rebateStatus ? this.formState.rebateStatus : undefined}
                InputProps={{ readOnly: true }}
                error={this.form.$('rebateStatus').error}
                required={this.isRequired('rebateStatus')}
                fullWidth
              />
            </Box>
          </form>
        </DialogContent>
        <DialogActions className={partnerDrawerDialogActions}>
          <Button
            form="partner-form"
            disabled={!this.isFormValid || this.isSaving}
            size="large"
            type="submit"
            variant="contained"
            color="primary"
            loading={this.isSaving}
            fullWidth>
            Save
          </Button>
        </DialogActions>
      </Drawer>
    );
  }
}

export default withStyles(styles)(PartnerDetailsDrawer);
