import React from 'react';
import { observer } from 'mobx-react';
import { observable, action, flow, computed, makeObservable } from 'mobx';
import SettingsStore from './SettingsStore';
import { Threshold } from 'models';
import { WayToTip as WAY_TO_TIP } from 'models/TipSettings';

import {
  DialogContent,
  DialogTitle,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Divider,
  Typography,
  Icon,
  Box,
  Checkbox,
  FormControlLabel,
  DialogActions,
} from '@material-ui/core';

import { ChevronLeft } from 'mdi-material-ui';
import { WithStyles, withStyles } from '@material-ui/core/styles';

import DP from 'components/DashPanel';

import styles from './styles';
import TippyGoProposedAmounts from './TippyGoProposedAmounts';
import { WayToTip } from 'components/WayToTip/WayToTip';
import TippyGoSuggestedAmount from './TippyGoSuggestedAmount';
import { inject, WithUserStore } from 'stores';
import Button from 'components/Button/Button';

type TippyGoSettingsProps = WithStyles<typeof styles> &
  WithUserStore & {
    settingsStore: SettingsStore;
    goBack: () => void;
  };

type TippyGoSettingsNavItem = undefined | 'proposals' | 'suggested';

@inject('userStore')
@observer
class TippyGoSettingsDrawer extends React.Component<TippyGoSettingsProps> {
  constructor(props: TippyGoSettingsProps) {
    super(props);
    makeObservable(this);
  }
  /** Track users location inside proposal strategy settings modal */
  @observable public currentSetting: TippyGoSettingsNavItem;

  @observable private wayToTip: WAY_TO_TIP = this.props.settingsStore.tippyGoSettings!.wayToTip;

  @computed public get isAdmin() {
    return this.props.userStore && this.props.userStore.isAdmin;
  }

  @computed public get updating(): boolean {
    return this.props.settingsStore.updating;
  }

  @action.bound private onSave = () => {
    this.saveSettings();
  };

  @action.bound public saveSettings = flow(function* (this: TippyGoSettingsDrawer) {
    yield this.props.settingsStore.updateGeneralSettings({
      onlinePaymentsEnabled: this.props.settingsStore.tippyGoSettings!.onlinePaymentsEnabled,
      goReview: this.props.settingsStore.tippyGoSettings!.goReview,
      goNote: this.props.settingsStore.tippyGoSettings!.goNote,
      goProfilePicture: this.props.settingsStore.tippyGoSettings!.goProfilePicture,
      wayToTip: this.wayToTip,
      poolsEnabled: this.props.settingsStore.tippyGoSettings!.poolsEnabled,
    });
    this.props.goBack();
  });

  /** Handle modal navigation */
  @action.bound private handleSettingChange = (setting: TippyGoSettingsNavItem) => () => {
    this.currentSetting = setting;
  };

  @action.bound private onOnlinePaymentsCheckbox(checked: boolean) {
    this.props.settingsStore.tippyGoSettings!.onlinePaymentsEnabled = checked;
  }

  @action.bound private onProfilePictureCheckbox(checked: boolean) {
    this.props.settingsStore.tippyGoSettings!.goProfilePicture = checked;
  }

  @action.bound private onReviewCheckbox(checked: boolean) {
    this.props.settingsStore.tippyGoSettings!.goReview = checked;
  }

  @action.bound private onNoteCheckbox(checked: boolean) {
    this.props.settingsStore.tippyGoSettings!.goNote = checked;
  }

  @action.bound private onPoolsEnabled(checked: boolean) {
    this.props.settingsStore.tippyGoSettings!.poolsEnabled = checked;
  }

  @computed public get thresholdProposalStrategySettings(): Threshold | undefined {
    if (this.props.settingsStore.generalSettings) {
      return this.props.settingsStore.thresholdProposalStrategySettings;
    }
    return undefined;
  }

  @action.bound private handleChangeWayToTip(value: WAY_TO_TIP) {
    this.wayToTip = value;
  }

  @computed get renderProposedAmounts() {
    return this.wayToTip === WAY_TO_TIP.LOW_MEDIUM_HIGH;
  }
  render() {
    const {
      drawerContent,
      settingListItemKey,
      settingListItemValue,
      settingListItemIcon,
      dialogActions,
    } = this.props.classes;

    const { settingsObject } = this.props.settingsStore;
    const renderPoolsEnabled = settingsObject.kind !== 'station' && this.isAdmin;

    if (this.currentSetting === 'proposals') {
      return (
        <TippyGoProposedAmounts
          settingsStore={this.props.settingsStore}
          goBack={this.handleSettingChange(undefined)}
        />
      );
    }

    if (this.currentSetting === 'suggested') {
      return (
        <TippyGoSuggestedAmount
          settingsStore={this.props.settingsStore}
          wayToTip={this.wayToTip}
          goBack={this.handleSettingChange(undefined)}
        />
      );
    }

    return (
      <>
        <DialogTitle>
          <Box display="flex" alignItems="center" justifyContent="space-start">
            <Box mr={2}>
              <IconButton onClick={this.props.goBack}>
                <ChevronLeft />
              </IconButton>
            </Box>
            <Typography variant="h4" component="h1" display="inline">
              Tippy Go
            </Typography>
            <Box width="40px" ml="auto" display="flex" flexDirection="row" alignItems="center">
              {this.updating && <DP.LoadSpinner />}
            </Box>
          </Box>
        </DialogTitle>
        <Divider />
        <DialogContent className={drawerContent}>
          <Box display="flex" flexDirection="column" height="100%">
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  checked={this.props.settingsStore.tippyGoSettings!.onlinePaymentsEnabled}
                  onChange={(e, checked) => this.onOnlinePaymentsCheckbox(checked)}
                  name="onOnlinePaymentsCheckbox"
                />
              }
              label="Online payments enabled"
            />
            {renderPoolsEnabled && (
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    checked={this.props.settingsStore.tippyGoSettings!.poolsEnabled}
                    onChange={(e, checked) => this.onPoolsEnabled(checked)}
                    name="poolsEnabled"
                  />
                }
                label="Pools enabled"
              />
            )}
            {this.props.settingsStore.tippyGoSettings!.onlinePaymentsEnabled && (
              <Box display="flex" flexDirection="column">
                {/* <FormControlLabel
                  control={
                    <Checkbox
                      color="primary"
                      checked={this.props.settingsStore.tippyGoSettings!.goReview}
                      onChange={(e, checked) => this.onReviewCheckbox(checked)}
                      name="goReviewEnabled"
                    />
                  }
                  label="Review enabled"
                />*/}
                <FormControlLabel
                  control={
                    <Checkbox
                      color="primary"
                      checked={this.props.settingsStore.tippyGoSettings!.goNote}
                      onChange={(e, checked) => this.onNoteCheckbox(checked)}
                      name="goNoteEnabled"
                    />
                  }
                  label="Note enabled"
                />
              </Box>
            )}
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  checked={this.props.settingsStore.tippyGoSettings!.goProfilePicture}
                  onChange={(e, checked) => this.onProfilePictureCheckbox(checked)}
                  name="onProfilePictureCheckbox"
                />
              }
              label="Show profile picture"
            />
            {this.props.settingsStore.tippyGoSettings!.onlinePaymentsEnabled && (
              <Box display="flex" flexDirection="column">
                <Divider />
                <List>
                  <ListItem>
                    <WayToTip
                      value={this.wayToTip}
                      onChange={(value: WAY_TO_TIP) => this.handleChangeWayToTip(value)}
                    />
                  </ListItem>
                  {this.renderProposedAmounts ? (
                    <ListItem button onClick={this.handleSettingChange('proposals')}>
                      <ListItemText className={settingListItemKey}>Proposed amounts</ListItemText>
                      {this.props.settingsStore.tippyGoSettings && (
                        <Typography className={settingListItemValue}>
                          {`$${this.props.settingsStore.tippyGoSettings.goLow} `}
                          {`$${this.props.settingsStore.tippyGoSettings.goMedium} `}
                          {`$${this.props.settingsStore.tippyGoSettings.goHigh} `}
                        </Typography>
                      )}
                      <Icon className={settingListItemIcon}>keyboard_arrow_right</Icon>
                    </ListItem>
                  ) : (
                    <ListItem button onClick={this.handleSettingChange('suggested')}>
                      <ListItemText className={settingListItemKey}>Suggested amount</ListItemText>
                      {this.props.settingsStore.tippyGoSettings && (
                        <Typography className={settingListItemValue}>
                          {`$${this.props.settingsStore.tippyGoSettings.suggestedAmount} `}
                        </Typography>
                      )}
                      <Icon className={settingListItemIcon}>keyboard_arrow_right</Icon>
                    </ListItem>
                  )}
                </List>
              </Box>
            )}
          </Box>
        </DialogContent>
        <DialogActions className={dialogActions}>
          <Button
            variant="contained"
            color="primary"
            onClick={this.onSave}
            disabled={this.updating}
            fullWidth>
            Save
          </Button>
        </DialogActions>
      </>
    );
  }
}

export default withStyles(styles)(TippyGoSettingsDrawer);
