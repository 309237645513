import Api from 'api';
import _ from 'lodash';
import {
  IStationGroup,
  IStationItem,
  IStationPool,
  IStationUser,
  StationItem,
} from 'models/Station';

export interface ISelectedLocationUser {
  id: number;
  fullName: string;
  email: string;
  selected: boolean;
  type: StationItem;
}

interface ILocationUserItem {
  id: number;
  fullName: string;
  email: string;
}

export class BlUsers {
  static async getAllLocationUsers(locationId: number) {
    const { data } = await Api.core.getAllLocationUsers(locationId);
    if (data && data.data && data.data.length) {
      let locationUsers = data.data.map((locationUser: any) => {
        const user = locationUser.user;
        return {
          id: user.id,
          fullName: `${user.firstName} ${user.lastName}`,
          email: user.email,
          avatar: user.avatar,
        };
      });
      locationUsers = _.orderBy(locationUsers, [(user) => user.fullName.toLowerCase()], ['asc']);
      return locationUsers;
    }
  }

  static getSelectedLocationUsers(
    locationUsers: Array<ILocationUserItem>,
    stationItems: Array<IStationItem>,
  ): Array<ISelectedLocationUser> {
    const stationUsers = this.getStationUsers(stationItems);
    if (!stationUsers || !stationUsers.length) {
      if (!locationUsers) return [];
      const _locationUsers = locationUsers.map((locationUser: any) => ({
        ...locationUser,
        type: StationItem.USER,
        selected: false,
      }));

      return _locationUsers;
    }
    const _locationUsers = locationUsers.map((locationUser: ILocationUserItem) => {
      const stationUser = stationUsers.find(
        (stationUser: IStationUser) => stationUser.userId === locationUser.id,
      );
      const type = StationItem.USER;
      const selected = stationUser ? true : false;
      return { ...locationUser, selected, type };
    });

    return _locationUsers;
  }

  static getStationUsers(
    items: Array<IStationUser | IStationPool | IStationGroup>,
  ): Array<IStationUser> | [] {
    if (items && items.length) {
      return items.filter(
        (item: IStationItem) => item.type === StationItem.USER,
      ) as Array<IStationUser>;
    }
    return [];
  }

  static selectedUsersToTop(selectedUsers: ISelectedLocationUser[]) {
    return [
      ...selectedUsers.filter((user: any) => user.selected),
      ...selectedUsers.filter((user: any) => !user.selected),
    ];
  }
}
