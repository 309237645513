/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { observable, action, flow, computed, makeObservable } from 'mobx';
import { observer } from 'mobx-react';
import { WithStyles, withStyles } from '@material-ui/core/styles';
import {
  Box,
  List,
  ListItem,
  Radio,
  ListItemText,
  ListItemIcon,
  ListItemSecondaryAction,
} from '@material-ui/core';
import { RouteComponentProps } from 'react-router-dom';

import Api, { ApiResponse, getErrorMsg } from 'api';
import { AxiosResponse } from 'axios';

import { inject, WithUserStore, WithToastStore, WithUiStore } from 'stores';
import * as models from 'models';

import SignupStore, { SignupStep } from './SignupStore';
import { ReactComponent as ProductImg } from './illustration_product.svg';

import styles from './styles';
import AccountSignupLayout from './AccountSignupLayout/AccountSignupLayout';
import Button from 'components/Button/Button';
import { Promotion, Affiliate } from 'models';

/** Here we define what kind of props this component takes */
interface ProductStepProps
  extends WithStyles<typeof styles>, // Adds the classes prop
    WithUserStore, // Adds the userStore prop
    RouteComponentProps<{ accountId: string }>,
    WithUiStore,
    WithToastStore {
  signupStore: SignupStore;
  nextRoute: (accountId: string | number) => string;
}

/** 50% off promotion code */
const promotionCode50PercentOff = 'TIP50OFF';

/**
 * The account information for the account signup process
 */
@inject('userStore', 'toastStore', 'uiStore')
@observer
class ProductStep extends React.Component<ProductStepProps> {
  public signupStore = this.props.signupStore;
  public constructor(props: ProductStepProps) {
    super(props);
    makeObservable(this);
  }
  /** Whether we are currently submitting */
  @observable public submitting = false;

  /** The available products */
  @observable public products?: models.Product[];

  /** The selected product code */
  @observable public selectedProduct: models.Product | null = null;

  /** Products which the user can choose from */
  @computed public get choosableProducts(): models.Product[] | undefined {
    if (!this.products) {
      return undefined;
    }
    return this.products.filter((product) => !product.isShipping);
  }

  @computed public get accountId(): number {
    return parseInt(this.props.match.params.accountId);
  }

  /** Submits the form */
  @action.bound public submit = flow(function* (this: ProductStep) {
    try {
      if (!this.selectedProduct) {
        throw new Error('Please select a product');
      }
      this.props.signupStore.setSelectedProduct(this.selectedProduct);
      this.props.history.push(this.props.nextRoute(this.accountId));
      // Add 50% off if there is a lead and affiliate
      if (this.props.signupStore.lead) {
        const promotion = yield this.fetch50PercentPromotion();
        if (promotion) {
          const appliedPromotions = this.props.signupStore.cart?.promotions || [];
          const applied50PercentPromotion = appliedPromotions.find(
            (p) => p.promotionId === promotion.id,
          );
          // Check if 50% promotion is already applied
          if (!applied50PercentPromotion) {
            const isOwnerAffiliate = yield this.checkIfOwnerIsAffiliate();
            if (isOwnerAffiliate) {
              yield Api.billing.applyPromotion(this.accountId, promotion.id);
            }
          }
        }
      }
      yield Promise.resolve();
    } catch (e: any) {
      this.props.toastStore!.push({ type: 'error', message: getErrorMsg(e) });
    } finally {
      this.submitting = false;
    }
  });

  @action.bound public getProducts = flow(function* (this: ProductStep) {
    try {
      let products: models.Product[];
      const resp = yield Api.billing.getProducts();
      products = resp?.data?.data || [];
      // Set current product. If we have a product in the cart, use that. Otherwise,
      // use the currently selected product. If we don't have that, use kiosk yearly.
      if (this.signupStore.cartProductId) {
        this.products = products.filter((p) => p.id === this.signupStore.cartProductId);
        this.selectedProduct = this.products[0]!;
        return;
      }
      this.products = products;
      this.selectedProduct =
        this.products.find(
          (p) => p.id === (this.signupStore.selectedProduct && this.signupStore.selectedProduct.id),
        ) || null;
      return;
    } catch (e: any) {
      this.props.toastStore!.error(getErrorMsg(e));
    }
  });

  @action.bound public selectProduct(p: models.Product) {
    this.selectedProduct = p;
  }

  /** Initializes the component */
  @action.bound public init = flow(function* (this: ProductStep) {
    this.props.signupStore!.setStep(SignupStep.Product);
    yield this.props.signupStore.initAccount(this.accountId);
    yield this.getProducts();
  });

  /** Fetch the 50% promotion by code. If the endpoint throws an error, return null */
  @action.bound public fetch50PercentPromotion: () => Promise<Promotion | undefined> = async () => {
    try {
      const resp: AxiosResponse<ApiResponse<Promotion>> = await Api.billing.getPromotionByCode(
        promotionCode50PercentOff,
      );
      return resp.data?.data;
    } catch (e: any) {
      this.props.toastStore!.error(getErrorMsg(e));
    }
  };

  /** Check if owner is affiliate */
  @action.bound public checkIfOwnerIsAffiliate = flow(function* (this: ProductStep) {
    const ownerEmail = this.props.signupStore.owner?.email;
    if (!ownerEmail) {
      return;
    }
    try {
      const resp: AxiosResponse = yield Api.marketing.checkIfAffiliate(ownerEmail);
      const hasAffiliate: AxiosResponse<ApiResponse<Affiliate>> | undefined = resp.data.data;
      return !!hasAffiliate;
    } catch (e: unknown) {
      this.props.toastStore!.error(getErrorMsg(e));
      return false;
    }
  });

  componentDidMount() {
    this.init();
  }

  renderAdminContent() {
    if (!this.choosableProducts) {
      return null;
    }
    return (
      <Box
        display="flex"
        flexDirection="column"
        flex={1}
        justifyContent="space-between"
        alignItems="stretch"
        height="100%">
        <List>
          {this.choosableProducts.map((product) => (
            <ListItem
              key={product.id}
              button
              onClick={() => this.selectProduct(product)}
              data-cy="Data">
              <ListItemIcon>
                <Radio color="primary" checked={this.selectedProduct === product} />
              </ListItemIcon>
              <ListItemText primary={product.name} secondary={product.description} />
              <ListItemSecondaryAction>{product.price}</ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
      </Box>
    );
  }

  render() {
    const content = this.renderAdminContent();
    return (
      <AccountSignupLayout
        title={{
          name: 'Product',
        }}
        subtitle={{
          name: 'Choose which product to use',
        }}
        image={{ svg: <ProductImg /> }}
        contentRight={{
          children: <>{this.choosableProducts && content}</>,
          mb: 'auto',
        }}
        actionsRight={
          <Button
            type="submit"
            variant="contained"
            data-cy="submit-button"
            fullWidth
            onClick={this.submit}
            loading={!this.choosableProducts}>
            NEXT
          </Button>
        }
      />
    );
  }
}

export default withStyles(styles)(ProductStep);
