import { useStores } from 'containers/App/App';
import { useStationNavigation } from 'containers/LocationDetails/QrCodes/useStationNavigation';
import { Industry } from 'models';
import { IStation } from 'models/Station';
import { paths } from 'routes';
import { BlQrCode } from './blQrCode';

type UseStationQrCode = [
  () => void,
  (uid: string, industry: Industry) => void,
  () => void,
  () => void,
];

export const useStationQrCode = (station: IStation): UseStationQrCode => {
  const { stationsStore, routerStore, toastStore } = useStores();
  const { stationNavigation } = useStationNavigation();
  const { resetStation } = stationsStore!;

  function close() {
    stationNavigation.resetStationState();
    resetStation();
  }

  function handleCopy() {
    const link = BlQrCode.createStationLink(station);
    navigator.clipboard.writeText(link).then();
    toastStore!.success('Copied');
  }

  async function handlePrint() {
    const link = await printPageLink();
    if (!link) return;
    routerStore!.history.push(link);
    stationNavigation.resetStationState();
  }

  async function printPageLink() {
    return `${paths.QRCodeForPrint()}?type=station&code=${station.uid}`;
  }

  return [close, printPageLink, handlePrint, handleCopy];
};
