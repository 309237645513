import React from 'react';
import { observable, action, flow, makeObservable } from 'mobx';
import { observer } from 'mobx-react';

import Api from 'api';
import { Shipment } from 'models';

import { WithStyles, withStyles } from '@material-ui/core/styles';

import DD from 'components/DashDrawer';
import CreateShipment from 'components/CreateShipment';
import ShipmentDetails from './ShipmentDetails';

import styles from './styles';

/** Props for this component */
interface ShipmentsDrawerProps extends WithStyles<typeof styles> {
  fulfillmentId: number;
  accountId: number;
  onClose: () => void;
}

/**
 * Displays a shipment drawer for a single sale
 */
@observer
class ShipmentsDrawer extends React.Component<ShipmentsDrawerProps> {
  constructor(props: ShipmentsDrawerProps) {
    super(props);
    makeObservable(this);
  }
  /** Are shipments loading? */
  @observable public loading = false;

  /** Whether the create shipment dialog is open */
  @observable public showingCreateShipment = false;

  /** The id of the shipment that we're currently viewing */
  @observable public currentShipment: Shipment | null = null;

  /** List of shipments */
  @observable public shipments?: Shipment[];

  /** Get shipments */
  @action.bound public fetchShipments = flow(function* (this: ShipmentsDrawer) {
    try {
      this.loading = true;
      const qsObj = { fulfillmentId: this.props.fulfillmentId };
      const resp = yield Api.fulfillment.getShipments(qsObj);
      this.shipments = resp.data && resp.data.data;
    } finally {
      this.loading = false;
    }
  });

  /** Opens the create shipment dialog */
  @action.bound public showCreateShipment() {
    this.showingCreateShipment = true;
  }

  /** Closes the create shipment dialog */
  @action.bound public hideCreateShipment() {
    this.showingCreateShipment = false;
  }

  /** Closes the create shipment dialog and reloads the shipments */
  @action.bound public hideCreateShipmentAndReload() {
    this.hideCreateShipment();
    this.fetchShipments();
  }

  /** Views a single shipment by setting currentShipmentId */
  @action.bound public setCurrentShipment(shipment: Shipment) {
    this.currentShipment = shipment;
  }

  /** Stops viewing a single shipment */
  @action.bound public clearCurrentShipment() {
    this.currentShipment = null;
  }

  componentDidMount() {
    this.fetchShipments();
  }

  render() {
    const { onClose, accountId, fulfillmentId } = this.props;
    return (
      <DD>
        <DD.SubDrawer display={this.showingCreateShipment}>
          <CreateShipment
            accountId={accountId}
            fulfillmentId={fulfillmentId}
            onComplete={this.hideCreateShipmentAndReload}
            onBack={this.hideCreateShipment}
          />
        </DD.SubDrawer>
        <DD.SubDrawer display={Boolean(this.currentShipment)}>
          {this.currentShipment && (
            <ShipmentDetails
              shipmentId={this.currentShipment.id}
              onBack={this.clearCurrentShipment}
              onProgressFetched={this.fetchShipments}
            />
          )}
        </DD.SubDrawer>
        <DD.Content>
          <DD.Title onClose={onClose}>Shipments</DD.Title>
          <DD.List>
            {this.shipments &&
              this.shipments.map((shipment: Shipment) => {
                const formattedForDisplay = shipment.status.replace(/_/g, ' ');
                return (
                  <DD.ListItem
                    key={shipment.id}
                    primary={shipment.trackingNumber}
                    secondary={formattedForDisplay}
                    onClick={() => this.setCurrentShipment(shipment)}
                  />
                );
              })}
          </DD.List>
        </DD.Content>
        <DD.FabButton onClick={this.showCreateShipment} />
      </DD>
    );
  }
}

export default withStyles(styles)(ShipmentsDrawer);
