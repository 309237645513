// Material UI
import { Box, CircularProgress, MenuItem, Select, Typography } from '@material-ui/core';
import { WithStyles, withStyles } from '@material-ui/core/styles';
import Api, { RequestMetaData, getErrorMsg } from 'api';
import DP from 'components/DashPanel';
import FilterBar from 'components/FilterBar';
import { FilterItem } from 'components/FilterBar/FilterBar';
// Components/Containers
import DashboardLayout from 'containers/DashboardLayout';
import { isEmpty, uniqBy } from 'lodash';
// Third party
import { action, computed, observable, toJS, makeObservable } from 'mobx';
import { observer } from 'mobx-react';
import { Location, UndistributedPoolTip } from 'models';
import moment from 'moment';
import React from 'react';
import { Redirect, RouteComponentProps } from 'react-router-dom';
import { numericStringToUsd } from 'services/currency';
// Services, Models, API, Store
import { inject, WithToastStore, WithUserStore } from 'stores';
import { v4 as uuidv4 } from 'uuid';
import { pendingTips } from '../../routes/paths';
import { WithRouterStore } from '../../stores/RouterStore';
import PendingTipsRecipients from './PendingTipsRecipients';
import PendingTipsReview from './PendingTipsReview';
import * as DateRangeExternalPicker from 'components/DateRangeExternalPicker';
import DataGridInfiniteScroll from 'components/DataGridInfiniteScroll';
// Style
import styles from './styles';
import Title from 'components/Title/Title';
import clsx from 'clsx';
import Button from 'components/Button/Button';

type PendingTipsProps = WithStyles<typeof styles> &
  WithUserStore &
  WithToastStore &
  RouteComponentProps &
  WithRouterStore;
@inject('userStore', 'toastStore', 'routerStore')
@observer
class PendingTips extends React.Component<PendingTipsProps> {
  constructor(props: PendingTipsProps) {
    super(props);
    makeObservable(this);
  }

  /** Active filters as returned by FilterBar */
  @observable private activeFilters: Record<string, unknown> = {};

  /** The selected date range */
  @observable public dateRange: DateRangeExternalPicker.DateRange =
    DateRangeExternalPicker.getDateRange();

  // List of current user locations
  @observable private locationUsers?: Location[];
  @observable private undistributedTips?: UndistributedPoolTip[];
  // Array of pool tip ids for distribution
  @observable private poolTipIds: number[] = [];
  @observable private distributions: Record<string, number>[] = [];
  @observable private inProgress = false;
  @observable private fetchingData = false;
  @observable private totalAmount = 0;
  @observable private totalSelectAmount = 0;
  @observable private totalDistributedToUsers = 0;
  @observable private grupedTipsByDate: any;
  @observable private selectedLocationId?: number;

  /** Fetches the LocationUser objects for this user */
  @action.bound public getUserLocations = async () => {
    const resp = await Api.core.getUserLocationsV2(this.userId);
    this.locationUsers = resp.data && resp!.data!.data;
  };

  // /** Fetches list of undistributed tips */
  @action.bound async getUndistributedTips(rmd: RequestMetaData) {
    let extraData;
    this.totalSelectAmount = 0;

    const isOwner = this.props.routerStore?.rootStore.userStore.isOwnerScope;
    if (isOwner && !this.selectedLocationId)
      return {
        rows: [],
        totalElements: 0,
      };

    if (this.isManager) {
      extraData = { locationId: this.locationId };
    } else {
      // Owner
      extraData = { locationId: this.selectedLocationId };
    }

    const res = await Api.tips.getUndistributedPoolTips(
      {
        ...rmd,
        filters: {
          ...this.activeFilters,
        },
      },
      extraData,
    );

    this.undistributedTips = res!.data && res!.data!.data;
    this.onSelectHandler(res.data.data || []);

    let totalElements = 0;
    if (res!.data!.data?.length) {
      totalElements = res!.data!.data?.length;
    }

    return {
      rows: res?.data?.data || [],
      totalElements,
    };
  }

  /** Sets the date range */
  @action.bound private updateDateRangeValue(range: DateRangeExternalPicker.DateRange) {
    this.dateRange = range;
    this.activeFilters = { ...this.activeFilters };
  }

  // /** Fetches list of undistributed tips */
  @action.bound public distributeTips = async () => {
    try {
      this.fetchingData = true;
      const body = {
        poolTipIds: this.poolTipIds,
        distributions: this.distributions,
      };
      await Api.tips.distributePoolTips(body);
      this.props.toastStore!.success(`Tips successfully distributed`);
    } catch (e: any) {
      this.props.toastStore!.error(getErrorMsg(e));
    } finally {
      this.goToUndistributedRoute();
      this.resetData();
      this.fetchingData = false;
    }
  };

  @computed private get accountId(): number {
    const userStore = this.props.userStore!;
    const scope = userStore.scope;
    // @ts-ignore
    return scope.accountId;
  }
  @computed private get locationId(): number {
    // only managers
    const userStore = this.props.userStore!;
    const scope = userStore.scope;
    // @ts-ignore
    return scope.locationId;
  }

  /** Fetches list of undistributed tips */
  @action.bound public distributionHandler = (users: Record<string, unknown>[]) => {
    this.distributions = [];
    let totalDistributed = 0;
    users.forEach((usr) => {
      totalDistributed += Number(usr.tipValue);
      this.distributions.push({ userId: Number(usr.userId), amount: Number(usr.tipValue) });
    });
    this.totalDistributedToUsers = totalDistributed;
  };

  @action.bound private goToUndistributedRoute = () => {
    this.props.routerStore!.history.push(pendingTips().undistributed());
  };

  @action.bound private goToRecipientsRoute = () => {
    this.props.routerStore!.history.push(pendingTips().recipients());
  };

  @action.bound private goToReviewRoute = () => {
    this.props.routerStore!.history.push(pendingTips().review());
  };

  @action.bound private resetData = () => {
    this.totalAmount = 0;
    this.distributions = [];
    this.users = [];
  };

  @observable public userId: number = this.props.userStore!.user!.id;
  @observable public isOwner: boolean = this.props.userStore!.scope.kind === 'owner';
  @observable public isManager: boolean = this.props.userStore!.scope.kind === 'manager';

  @observable private users: {
    userId: number;
    name: string;
    avatar: string;
    tipValue: number;
    tipPercent: number;
    touched: boolean;
  }[] = [];

  @computed public get poolFilterItems(): FilterItem[] {
    const filters = (this.undistributedTips &&
      this.undistributedTips.map((tip) => ({
        value: tip.poolId.toString(),
        label: tip.poolName,
      }))) || [{ label: '', value: '' }];
    return uniqBy(filters, 'label');
  }

  /** The LocationUser objects filtered by scope */
  @computed get filteredLocationUsers(): Location[] | undefined {
    const userStore = this.props.userStore!;
    const scope = userStore.scope;

    // If the current logged-in user is an owner, show all the locationUser
    // objects that are in the current scope's account
    if (scope.kind === 'owner' && this.locationUsers) {
      let locationUsersMap = new Map();
      let locUsers = this.locationUsers.filter(
        (locationUser) => locationUser.accountId === scope.accountId,
      );
      locUsers.forEach((user) => {
        locationUsersMap.set(user.id, user);
      });

      return Array.from(locationUsersMap.values());
    }
    // If the current logged-in user is a manager, show the locationUser
    // objects that are in the current scope's location (there should only be 1)
    if (scope.kind === 'manager' && this.locationUsers) {
      return this.locationUsers.filter((locationUser) => locationUser.id === scope.locationId);
    }
    // Otherwise, if the logged-in user is an admin, show all of them
    return this.locationUsers;
  }

  @computed public get submitDisabled(): boolean {
    let disabled = true;
    if (this.pathname === pendingTips().undistributed()) {
      disabled = this.totalAmount <= 0;
    } else if (this.pathname === pendingTips().recipients()) {
      disabled = this.distributions.length < 1;
    } else if (this.pathname === pendingTips().review()) {
      disabled = this.totalAmount <= 0 || this.distributions.length < 1;
    }
    return disabled;
  }

  @computed get pathname(): string | undefined {
    return this.props.routerStore!.location ? this.props.routerStore!.location.pathname : undefined;
  }

  componentDidMount() {
    // Owners should pick a location before API call
    if (this.isOwner) {
      this.getUserLocations();
    }
  }

  onSelectHandler = (rows: any[]) => {
    // reset amount
    let total = 0;
    // reset poolTipIds
    this.poolTipIds = [];
    rows &&
      rows.forEach((row: any) => {
        total += parseFloat(row.amount);
        this.poolTipIds.push(row.id);
      });

    if (total > this.totalSelectAmount) {
      this.totalSelectAmount = total;
    }

    if (total == 0) {
      this.totalAmount = this.totalSelectAmount;
    } else {
      this.totalAmount = total;
    }

    if (this.users.length > 0) {
      this.users = [];
    }
  };

  onSubmitHandler = () => {
    if (this.pathname === pendingTips().undistributed()) {
      this.goToRecipientsRoute();
    } else if (this.pathname === pendingTips().recipients()) {
      this.goToReviewRoute();
    } else if (this.pathname === pendingTips().review()) {
      this.distributeTips().then();
    }
  };

  locationSelectHandler = (event: React.ChangeEvent<{ value: unknown }>) => {
    const value = event.target.value as unknown as number;
    this.selectedLocationId = value;
    this.activeFilters = { ...this.activeFilters };
  };

  renderPendingTipsDetails() {
    const { selectInput, fontColor, filterbarWrapper } = this.props.classes;
    const marginRight = this.isOwner && this.filteredLocationUsers ? 3 : 0;
    return (
      <>
        {this.fetchingData && (
          <Box display="flex" justifyContent="center">
            {' '}
            <DP.LoadSpinner />
          </Box>
        )}

        <>
          <Box
            mb={3}
            style={{
              display: 'flex',
              alignItems: 'center',
              alignContent: 'center',
              justifyContent: 'space-between',
            }}>
            <Box mr={marginRight} className={filterbarWrapper}>
              {(this.selectedLocationId || this.isManager) && (
                <FilterBar
                  filters={[
                    {
                      display: 'Pool',
                      id: 'poolId',
                      label: 'Starts with',
                      type: 'autocomplete',
                      items: this.poolFilterItems,
                    },
                  ]}
                  onChange={(filters: Record<string, unknown>) => {
                    this.activeFilters = filters;
                  }}
                />
              )}
            </Box>
            {this.isOwner && this.filteredLocationUsers && (
              <Select
                className={clsx(selectInput, !this.selectedLocationId && fontColor)}
                labelId="owner-locations-select-label"
                id="owner-locations-select"
                value={this.selectedLocationId || ''}
                onChange={this.locationSelectHandler}
                renderValue={!this.selectedLocationId ? () => 'Select location' : undefined}
                native={false}
                displayEmpty={true}>
                {this.filteredLocationUsers?.map((loc) => (
                  <MenuItem key={uuidv4()} value={loc.id}>
                    {loc.name}
                  </MenuItem>
                ))}
              </Select>
            )}
          </Box>
          <DataGridInfiniteScroll
            columns={this.gridColumns}
            fetch={this.getUndistributedTips}
            refetchKey={this.activeFilters}
            getSelectedRows={this.onSelectHandler}
            disableColumnMenu
            checkboxSelection
            pathname={this.props.location.pathname}
          />
        </>
      </>
    );
  }

  gridColumns = [
    {
      headerName: 'Date',
      field: 'createdAt',
      minWidth: 150,
      flex: 1,
      valueGetter: ({ value }: any) => value && moment(new Date(value)).format('MMM DD, YYYY'),
    },
    {
      headerName: 'Amount',
      field: 'amount',
      minWidth: 130,
      flex: 1,
      renderCell: ({ value }: any) => <span>{parseFloat(value).toFixed(2)}</span>,
    },
    { headerName: 'Pool', field: 'poolName', minWidth: 120, flex: 1 },
    { headerName: 'Room', field: 'roomNumber', minWidth: 120, flex: 1 },
    { headerName: 'Name', field: 'customerName', minWidth: 120, flex: 1 },
    { headerName: 'Review', field: 'review', minWidth: 120, flex: 1 },
  ];

  render() {
    if (this.props.routerStore && this.props.routerStore!.history) {
      if (this.pathname === pendingTips().recipients()) {
        if (this.totalAmount <= 0) {
          return <Redirect to={pendingTips().undistributed()} />;
        }
      } else if (this.pathname === pendingTips().review()) {
        if (this.totalAmount <= 0 || this.distributions.length < 1) {
          return <Redirect to={pendingTips().undistributed()} />;
        }
      }
    }

    return (
      <DashboardLayout>
        <Box display="flex" flexDirection="row" justifyContent="space-between">
          {this.pathname === pendingTips().undistributed() && <Title mb={2}>Pending tips</Title>}
          {this.pathname === pendingTips().recipients() && <Title mb={2}>Select recipients</Title>}
          {this.pathname === pendingTips().review() && <Title mb={3}>Review</Title>}
        </Box>
        <Box mb={3}>
          <Typography
            style={{ fontWeight: 600 }}
            color="primary"
            variant="h4"
            component="h1"
            gutterBottom>
            {numericStringToUsd(`${this.totalAmount}`)}
          </Typography>
        </Box>
        <Box
          style={{ minHeight: '80vh' }}
          display="flex"
          justifyContent="space-between"
          flexDirection="column">
          <Box pb={10}>
            {this.pathname === pendingTips().undistributed() && this.renderPendingTipsDetails()}
            {this.pathname === pendingTips().recipients() && (
              <PendingTipsRecipients
                totalAmount={this.totalAmount}
                users={this.users}
                filters={toJS(this.activeFilters)}
                accountId={this.accountId || undefined}
                locationId={this.locationId || this.selectedLocationId}
                distributionHandler={this.distributionHandler}
              />
            )}
            {this.pathname === pendingTips().review() && (
              <PendingTipsReview
                totalAmount={this.totalAmount}
                totalDistributed={this.totalDistributedToUsers}
                users={this.users}
              />
            )}
          </Box>
          {!isEmpty(this.undistributedTips) && (
            <Box mb={5}>
              <Button
                fullWidth
                variant="contained"
                color="primary"
                onClick={this.onSubmitHandler}
                disabled={this.submitDisabled}
                endIcon={
                  this.inProgress ? <CircularProgress size={25} color="primary" /> : undefined
                }>
                Next
              </Button>
              {this.pathname === pendingTips().review() && (
                <Typography variant="body2" align="center" style={{ marginTop: 16 }}>
                  This action is permanent. When you confirm tips they can NOT be edited
                </Typography>
              )}
            </Box>
          )}
        </Box>
      </DashboardLayout>
    );
  }
}

export default withStyles(styles)(PendingTips);
