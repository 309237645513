import { faCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Typography } from '@mui/material';
import TextButton from 'components/Button/TextButton/TextButton';
import { default as DP } from 'components/DashPanel';
import { IOptionsMenuProps } from 'components/OptionsMenu/OptionsMenu';
import themeNew from 'containers/App/theme_new';
import * as models from 'models';
import { ELicenseBillingGeneralDialog } from '../models';

const BillingGroupItem = ({
  menu,
  isAdmin,
  billingEntity,
  onShowLicenses,
}: {
  menu: IOptionsMenuProps['items'];
  isAdmin: boolean;
  billingEntity: models.IBillingEntity;
  onShowLicenses: (
    type: ELicenseBillingGeneralDialog,
    billingEntity: models.IBillingEntity,
  ) => void;
}) => {
  const { name, code, licenses, paymentMethod } = billingEntity;
  const numberOfLicenses = licenses?.length || 0;
  const disabled = !numberOfLicenses;
  const secondaryFont = {
    color: disabled ? themeNew.typography.subtitle1.color! : themeNew.palette.primary.main,
  };

  const icon = (
    <FontAwesomeIcon
      icon={faCircle}
      fontSize={18}
      style={{ color: themeNew.palette.primary.main }}
    />
  );

  const primary = (
    <>
      {name || code}&nbsp;
      {name && (
        <Typography component={'span'} variant={'body2'}>
          ({code})
        </Typography>
      )}
    </>
  );

  const secondary = (
    <Typography component={'span'} variant={'subtitle1'}>
      <TextButton
        sx={secondaryFont}
        onClick={() =>
          onShowLicenses(ELicenseBillingGeneralDialog.BILLING_GROUP_LICENSES, billingEntity)
        }
        label={`Licenses (${numberOfLicenses})`}
        disableUnderline={true}
        disabled={disabled}
      />{' '}
      | {paymentMethod?.brand} {paymentMethod.lastFour}
    </Typography>
  );

  return (
    <DP.ListItem
      icon={icon}
      primary={primary}
      secondary={secondary}
      menu={isAdmin ? menu : undefined}
    />
  );
};

export default BillingGroupItem;
