export enum ProposalStrategy {
  BASIC = 'basic',
  DYNAMIC = 'dynamic',
  BREAKPOINT = 'breakpoint',
}

export enum KioskMode {
  STANDARD = 'standard',
  CUSTOM_ONLY = 'custom_only',
  POOLS = 'pools',
  INTEGRATED = 'integrated',
}

export enum Processor {
  SQUARE = 'square',
  SQUARE_NETSPEND = 'square-netspend',
  SQUARE_WEB = 'square-web',
  GLOBAL_PAYMENTS = 'global-payments',
  GLOBAL_PAYMENTS_WEB = 'global-payments-web',
  STX = 'stx',
  ENVISION = 'envision',
  SALON_ULTIMATE = 'salon-ultimate',
  ROSY = 'rosy',
  MEEVO = 'meevo',
}

export enum AccountTransparency {
  FULL = 'full',
  OPTIONAL = 'optional',
  NONE = 'none',
}
// export enum Industry {
//   HOSPITALITY = 'hospitality',
//   BEAUTY = 'beauty',
//   NONE = 'none',
// }

export enum WayToTip {
  LOW_MEDIUM_HIGH = 'low_medium_high',
  SUGGESTED_AMOUNT = 'suggested_amount',
}

export interface Threshold {
  threshold: number;
  amountLow: number;
  amountMedium: number;
  amountHigh: number;
  percentLow: number;
  percentMedium: number;
  percentHigh: number;
}

export interface Breakpoint {
  breakpoint: number | string;
  type: 'amount' | 'percent';
  low: number | string;
  medium: number | string;
  high: number | string;
}

export interface TippyGoSettings {
  onlinePaymentsEnabled: boolean;
  goReview: boolean;
  goNote: boolean;
  goProfilePicture: boolean;
  goLow: number;
  goMedium: number;
  goHigh: number;
  poolsEnabled: boolean;
  wayToTip: WayToTip;
  suggestedAmount: number;
  accountSplitTipEnabled: boolean;
}

export interface ITipOptionLabels {
  labelLow: string;
  labelMedium: string;
  labelHigh: string;
}

/** Represents tip proposal general settings */
export interface TipSettings {
  mode: KioskMode;
  strategy?: ProposalStrategy;
  processor: Processor;
  strategyParameters?: Threshold | Breakpoint[];
  transparency?: AccountTransparency;
  // industry?: Industry;
  tippyGo?: TippyGoSettings;
  poolsEnabled?: boolean;
  tipOptionLabels?: ITipOptionLabels;
}
