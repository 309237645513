import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { useStyles } from './styles';
import theme from 'containers/App/theme';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/pro-regular-svg-icons';
import { Link as RouterLink } from 'react-router-dom';

/** A container for the graphic and title on the left hand side of the signup screens */
function GraphicContainer(props: { children: React.ReactNode; className?: string }) {
  return (
    <Box
      className={props.className}
      display="flex"
      flexDirection="column"
      alignItems="center"
      position="relative">
      {props.children}
    </Box>
  );
}

function Title(props: {
  children: React.ReactNode;
  width?: string;
  align?: 'center' | 'left' | 'right';
  backPath?: string;
}) {
  const { children, align, backPath } = props;
  return (
    <Box display={'flex'} alignItems={'flex-start'} width={'100%'} mb={3}>
      {backPath && (
        <RouterLink to={backPath}>
          <FontAwesomeIcon
            style={{
              height: 24,
              padding: theme.spacing(0.75, 0.75, 0.75, 0),
              color: theme.palette.text.secondary,
            }}
            icon={faChevronLeft}
          />
        </RouterLink>
      )}
      <Typography
        style={{ display: 'inline', width: '100%', margin: 0 }}
        variant="h5"
        component="h1"
        align={align || 'center'}
        gutterBottom>
        {children}
      </Typography>
    </Box>
  );
}

function Subtitle(props: {
  children: React.ReactNode;
  align?: 'center' | 'left' | 'right';
  mb?: number;
}) {
  const classes = useStyles();
  const { children, align, mb } = props;

  return (
    <Typography
      className={classes.subtitle}
      align={align || 'center'}
      style={{ marginBottom: theme.spacing(mb || 0) }}>
      {children}
    </Typography>
  );
}

function Image(props: { src: string; alt: string }) {
  return (
    <Box mt={3}>
      <img src={props.src} alt={props.alt} />
    </Box>
  );
}

function SvgImage(props: { children: React.ReactNode }) {
  const classes = useStyles();
  return <Box mt={3}>{props.children}</Box>;
}

GraphicContainer.Title = Title;
GraphicContainer.Subtitle = Subtitle;
GraphicContainer.Image = Image;
GraphicContainer.SvgImage = SvgImage;

export default GraphicContainer;
