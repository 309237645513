import React, { ReactNode } from 'react';
import { observer } from 'mobx-react';

import { inject, WithUserStore } from 'stores';

import CssBaseline from '@material-ui/core/CssBaseline';
import { WithStyles, withStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';
import Snackbar from 'containers/Snackbar';
import ConfirmDialog from 'components/ConfirmDialog';
import IdentityConfirmDialog from 'components/IdentityConfirmDialog';

import styles from './styles';
import GlobalDialogs from 'components/GlobalDialogs/GlobalDialogs';

interface LayoutProps extends WithUserStore, WithStyles<typeof styles> {
  children: ReactNode;
}
/**
 * The main layout component.
 */
const Layout = inject('userStore')(
  observer(({ classes, children }: LayoutProps) => {
    return (
      <Box className={classes.root}>
        <CssBaseline />
        {children}
        <Snackbar />
        <ConfirmDialog />
        <IdentityConfirmDialog />
        <GlobalDialogs />
      </Box>
    );
  }),
);

export default withStyles(styles)(Layout);
