import React from 'react';
import { observable, action, toJS, makeObservable } from 'mobx';
import { observer } from 'mobx-react';
import { WithStyles, withStyles } from '@material-ui/core/styles';
import { Box, FormControl, Fade, TextField, Typography } from '@material-ui/core';
import { Account } from 'models';

import DD from 'components/DashDrawer';
import AccountSearch from 'components/AccountSearch';

import styles from './styles';

export interface LocationFilters {
  proximity: {
    zip?: string;
    radius?: string;
  };
  account?: Account;
}

interface UserFilterDrawerProps extends WithStyles<typeof styles> {
  filters: LocationFilters;
  onChange: (lf: LocationFilters) => void;
  onClose: () => void;
  onReset: () => void;
  showReset: boolean;
}

/**
 * Displays a filter drawer for filtering user search results
 */
@observer
class UserFilterDrawer extends React.Component<UserFilterDrawerProps> {
  constructor(props: UserFilterDrawerProps) {
    super(props);
    makeObservable(this);
  }
  /** The user filters */
  @observable filters: LocationFilters = this.props.filters;

  /** The current edited title */
  @observable public source?: string;

  /** The current edited title */
  @observable public radius?: string;

  /** Calls props.onChange with the current filters */
  @action.bound public updateFilters() {
    this.props.onChange(toJS(this.filters));
  }

  @action.bound public updateAccount(account: Account | null) {
    this.filters.account = account ? account : undefined;
  }

  @action.bound private updateZip(event: React.ChangeEvent<HTMLInputElement>) {
    this.filters.proximity.zip = event.target.value;
  }

  @action.bound private updateRadius(event: React.ChangeEvent<HTMLInputElement>) {
    this.filters.proximity.radius = event.target.value;
  }

  render() {
    const { onClose, onReset, showReset } = this.props;
    return (
      <DD>
        <DD.Content>
          <DD.Title onClose={onClose}>Filters</DD.Title>
          <Box mt={2}>
            <Box mb={2}>
              <Box mt={6} mb={8}>
                <DD.Subtitle>By Account</DD.Subtitle>
                <Box mt={4}>
                  <AccountSearch
                    placeholder="Enter name"
                    value={this.filters.account}
                    onChange={this.updateAccount}
                  />
                </Box>
              </Box>
              <DD.Subtitle>By Proximity</DD.Subtitle>
              <FormControl>
                <Box display="flex" flexDirection="row" alignItems="flex-end">
                  <Typography>Locations around</Typography>
                  <Box ml={2}>
                    <TextField
                      margin="none"
                      size="small"
                      label="ZIP Code"
                      onChange={this.updateZip}
                      value={this.filters.proximity.zip}
                    />
                  </Box>
                </Box>
                <Box display="flex" flexDirection="row" alignItems="flex-end">
                  <Typography>with radius of</Typography>
                  <Box ml={2}>
                    <TextField
                      margin="none"
                      size="small"
                      label="Miles"
                      onChange={this.updateRadius}
                      value={this.filters.proximity.radius}
                    />
                  </Box>
                </Box>
              </FormControl>
            </Box>
          </Box>
        </DD.Content>
        <DD.Actions>
          <Fade in={showReset}>
            <DD.ResetButton onClick={onReset}>Reset filters</DD.ResetButton>
          </Fade>
          <DD.ApplyButton onClick={this.updateFilters}>Apply</DD.ApplyButton>
        </DD.Actions>
      </DD>
    );
  }
}

export default withStyles(styles)(UserFilterDrawer);
