import { Theme, makeStyles, createStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    iconButton: {
      padding: 0,
      color: theme.palette.common.black
    },
    notificationsIcon: {
      color: theme.palette.text.primary,
      height: 20,
    },
  }),
);
