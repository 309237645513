import { Theme } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      height: 32,
      paddingLeft: theme.spacing(1.5),
      paddingRight: theme.spacing(1.5),
      paddingTop: theme.spacing(0.75),
      paddingBottom: theme.spacing(0.75),
      textTransform: 'none',
      borderRadius: 8,
      fontWeight: 500,
      fontSize: 14,

      '&:hover': {
        backgroundColor: theme.palette.primary.hover,
      },
    },
    disabledButton: {
      backgroundColor: theme.palette.divider,
      color: theme.palette.text.secondary,
    },
    confirmButton: {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.primary.main,
      '& .Mui-disabled': {
        color: 'red',
        backgroundColor: 'red',
      },
    },
    cancelButton: {
      color: theme.palette.primary.main,
      border: `1px solid ${theme.palette.primary.main}`,

      '&:hover': {
        backgroundColor: theme.palette.common.white,
        border: `1px solid ${theme.palette.primary.hover}`,
        color: theme.palette.primary.hover,
      },
    },
    dialogActions: {
      marginTop: theme.spacing(5),
      paddingBottom: theme.spacing(3),
    },
  }),
);

export default useStyles;
