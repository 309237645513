import { Theme, createStyles, makeStyles } from '@material-ui/core';
import { alpha } from '@material-ui/core/styles/colorManipulator';

interface IStyleProps {
  mobileView?: boolean;
  pt?: number;
  iconMargin?: number;
}

const useStyles = makeStyles<Theme, IStyleProps>((theme) =>
  createStyles({
    root: {
      backgroundColor: 'transparent',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: ({ mobileView, pt }) =>
        mobileView ? theme.spacing(2) : theme.spacing(pt || 12, 4, 4, 4),
    },
    container: {
      maxWidth: ({ mobileView }) => (mobileView ? theme.spacing(45) : theme.spacing(50)),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      [theme.breakpoints.down('sm')]: {
        paddingLeft: 0,
        paddingRight: 0,
      },
    },
    insideBox: {
      position: 'relative',
      minWidth: 330,
      margin: '0 auto',
      [theme.breakpoints.down('sm')]: {
        borderRadius: `0 0 0 0`,
      },
    },
    paper: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    loader: {
      position: 'absolute',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: alpha(theme.palette.common.white, 0.5),
      borderRadius: theme.shape.borderRadius,
      zIndex: 200,
      top: 0,
      right: 0,
      left: 0,
      bottom: 0,
    },
    pig: {
      height: ({ mobileView }) => (mobileView ? theme.spacing(8) : theme.spacing(12)),
      marginBottom: ({ iconMargin }) => theme.spacing(iconMargin || 3),
    },
    footer: {
      marginTop: 'auto',
      color: theme.palette.common.white,
      letterSpacing: '.5px',
    },
    miniText: {
      fontSize: 12,
    },
  }),
);

export default useStyles;
