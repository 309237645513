import { getErrorMsg } from 'api';
import { useStores } from 'containers/App/App';
import {
  AddStation,
  IGroupItem,
  IStationItem,
  IStationPool,
  StationItem,
  ISelectedLocationPool,
} from 'models/Station';
import { useEffect, useState } from 'react';
import { BlAddStation } from '../AddStation/blAddStation';
import { BlPools } from '../../containers/LocationDetails/QrCodes/blPools';
import { useStationNavigation } from 'containers/LocationDetails/QrCodes/useStationNavigation';

type UseAddPoolsReturn = [
  ISelectedLocationPool[] | undefined,
  () => void,
  (state?: AddStation | undefined) => void,
  (pool: ISelectedLocationPool) => void,
  () => void,
];

export function useAddStationPools(): UseAddPoolsReturn {
  const { toastStore, stationsStore } = useStores();
  const { stationNavigation } = useStationNavigation();
  const { locationId, accountId, updateFormFieldAndValidate, setStateField } = stationsStore!;
  const [selectedPools, setSelectedPools] = useState(stationsStore.selectedPools);

  useEffect(() => {
    setSelectedPools(stationsStore.selectedPools);
  }, [stationsStore.selectedPools]);

  useEffect(() => {
    init();
  }, []);

  async function init() {
    await getPools();
    await getSelectedPools();
  }

  async function getPools() {
    try {
      const pools = await BlPools.getPools(accountId!, locationId!);
      stationsStore!.locationPools = pools;
    } catch (e: any) {
      toastStore!.error(getErrorMsg(e));
    }
  }

  function toggleSelectedPool(pool: ISelectedLocationPool) {
    if (!selectedPools) return;
    stationsStore.selectedPools = selectedPools.map(
      (selectedPool: ISelectedLocationPool): ISelectedLocationPool => {
        if (pool.id === selectedPool.id) {
          return { ...pool, selected: !pool.selected };
        } else {
          return selectedPool;
        }
      },
    );
  }

  function addSelectedPools() {
    const pools = BlAddStation.mapItemsForAddingToState(selectedPools!) as IStationPool[];
    if (stationsStore.addStationState === AddStation.GROUP_POOLS) {
      addToGroup(pools);
    } else {
      addToStation(pools);
    }
    handleAddPools();
  }

  function addToStation(pools: IStationPool[]) {
    let stationItems = [] as IStationItem[];
    stationsStore.station!.items!.forEach((item: IStationItem) => {
      if (item.type !== StationItem.POOL) stationItems.push(item);
    });
    stationItems = [...stationItems, ...pools];
    setStateField('items', stationItems);
  }

  function addToGroup(pools: IStationPool[]) {
    let groupItems = [] as IGroupItem[];
    stationsStore.selectedGroup!.groupItems!.forEach((item: IGroupItem) => {
      if (item.type !== StationItem.POOL) groupItems.push(item);
    });
    groupItems = [...groupItems, ...pools];
    setStateField('groupItems', groupItems);
  }

  function mapPoolsForAddingToState(): IStationPool[] | [] {
    const _selectedPools = selectedPools!.filter((pool: ISelectedLocationPool) => pool.selected);
    return _selectedPools!.map((pool: ISelectedLocationPool) => {
      return { type: StationItem.POOL, poolId: pool.id };
    });
  }

  function getSelectedPools() {
    let items = stationsStore.station!.items;
    if (stationsStore.addStationState === AddStation.GROUP_POOLS) {
      items = stationsStore.selectedGroup!.groupItems;
    }

    if (!stationsStore.selectedPools || !stationsStore.selectedPools.length) {
      const selectedPools = BlPools.getSelectedLocationPools(stationsStore.locationPools!, items);
      stationsStore.selectedPools = selectedPools;
      setSelectedPools(selectedPools);
    } else {
      const selectedPools = BlPools.getUpdatedSelectedLocationPools(
        stationsStore.selectedPools!,
        stationsStore.locationPools!,
      );
      stationsStore.selectedPools = selectedPools;
      setSelectedPools(selectedPools);
    }
  }

  function goBack() {
    if (stationsStore.addStationState === AddStation.GROUP_POOLS) {
      stationNavigation.openGroup();
      updateFormFieldAndValidate('groupItems');
    } else {
      stationNavigation.openPoolsOrGroups();
    }
    stationsStore.selectedPools = undefined;
  }

  function addPool() {
    if (stationsStore.addStationState === AddStation.GROUP_POOLS) {
      stationNavigation.openGroupPool();
    } else {
      stationNavigation.openPool();
    }
  }

  function addGroup() {
    stationNavigation.openGroup();
  }

  function resetSelectedPools() {
    stationsStore.selectedPools = undefined;
  }

  async function handleAddPools() {
    resetSelectedPools();
    if (stationsStore.addStationState === AddStation.GROUP_POOLS) {
      updateFormFieldAndValidate('groupItems');
      addGroup();
    } else {
      stationNavigation.openStation();
      updateFormFieldAndValidate('items');
    }
  }

  return [selectedPools, addPool, goBack, toggleSelectedPool, addSelectedPools];
}
