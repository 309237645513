import { AxiosResponse } from 'axios';
import config from 'config';
import {
  ChartData,
  TipAmountMode,
  TipLevel,
  UndistributedPoolTip,
  UpcomingPayout,
  Partner,
  IUserTipRequest,
  Tip,
  TipDetailsResponseRefunds,
  ITransferRequest,
} from 'models';
import { ApiResponse, RequestMetaData, PagedData, PagedApiResponse } from './interface';
import { createApi, makeQS } from './utils';
import { IFeeSchedule, IFeeScheduleResponse } from 'models/FeeSchedule';
import { RebateStatus } from 'models/RebatePartners';
import { ICreateRefundRequest, IRefundRequest } from 'models/Refunds';

const api = {
  v1: createApi({ baseURL: `${config.api.tips}/v1` }),
  v2: createApi({ baseURL: `${config.api.tips}/v2` }),
};

export const getTips = (rmd?: RequestMetaData, extraData?: Record<string, unknown>) =>
  api.v1.get(`tips?${makeQS(rmd, extraData)}`);

export const getTipsForLocation = (rmd?: RequestMetaData, extraData?: Record<string, unknown>) =>
  api.v1.get(`/locations/managed-location-tips?${makeQS(rmd, extraData)}`);

export const getGloballyOwnedTips = (rmd?: RequestMetaData, extraData?: Record<string, unknown>) =>
  api.v1.get(`/globally-owned/tips?${makeQS(rmd, extraData)}`);

export const getGloballyOwnedTipsReport = (
  rmd?: RequestMetaData,
  extraData?: Record<string, unknown>,
) => api.v1.get(`/globally-owned/tips/download?${makeQS(rmd, extraData)}`);

/** Account based tip list for owners */
export const getTipsForAccount = (accountId: number, rmd?: RequestMetaData, extraData?: any) => {
  return api.v1.get(`accounts/${accountId}/tips?${makeQS(rmd, extraData)}`);
};

export const getDrawSourcesForAccount = (accountId: number) => {
  return api.v1.get(`/accounts/${accountId}/draw-sources`);
};

/** Talents tips summary report for admins; gets all */

export const getTipsSummaryReport = (
  rmd?: RequestMetaData,
  extraData?: Record<string, unknown>,
) => {
  return api.v1.get(`table-report?${makeQS(rmd, extraData)}`);
};

/* TODO: Remove endpoint after everything moved to analytics*/
/** Talents tips chart data */
export function getTipsChartData(
  extraData?: Record<string, unknown>,
): Promise<AxiosResponse<ApiResponse<ChartData>>> {
  return api.v1.get(`chart-report?${makeQS({}, extraData)}`);
}

/** Locations/accounts report download link */
export const downloadCsvReport = (rmd: RequestMetaData, extraData: any) => {
  return api.v1.get(`reports/csv?${makeQS(rmd, extraData)}`);
};

export const downloadBalancesCsv = (extraData?: Record<string, unknown>) => {
  return api.v1.get(`/draws/balances-report/download?${makeQS(undefined, extraData)}`);
};

/** Fetches a user's primary wallet */
export const getPrimaryWallet = (userId: number) => api.v1.get(`users/${userId}/primary-wallet`);

/** Fetches a wallet by id */
export const getWallet = (walletId: number) => api.v1.get(`/wallets/${walletId}`);

export const updateWallet = (walletId: number, data: any) =>
  api.v1.patch(`/wallets/${walletId}`, { ...data });

/** Connects a Plaid bank account to a user's wallet */
export const walletPlaidConnect = (walletId: number, bankAccountId: number) =>
  api.v1.post(`wallets/${walletId}/dwolla-connect`, {
    bankAccountId,
  });

/** Get proposal strategy settings */
export const getProposalStrategySettings = (
  entity: 'account' | 'location' | 'station',
  id: number,
  strategy: string,
) => api.v1.get(`${entity}s/${id}/settings/${strategy}`);

/** Set proposal strategy settings */
export const updateProposalStrategySettings = (
  entity: 'account' | 'location' | 'station',
  id: number,
  strategy: string,
  settings: any,
) => api.v1.post(`${entity}s/${id}/settings/${strategy}`, settings);

/** Send email with link to tips report download url for given userId and date range */
export const sendTipsReportDownloadLinkEmail = (id: number, fromDate: string, toDate: string) => {
  const extraData = { fromDate, toDate };
  return api.v1.get(`users/${id}/tips-report/email?${makeQS({}, extraData)}`);
};

/** Request CSV formatted tips report data */
export const downloadTipsReport = (id: number, fromDate: string, toDate: string) => {
  const extraData = { fromDate, toDate };
  return api.v1.get(`users/${id}/tips-report/download?${makeQS({}, extraData)}`, {
    responseType: 'blob',
  });
};

export const getWalletStats = (walletId: number, extraData?: Record<string, unknown>) =>
  api.v1.get(`wallets/${walletId}/stats?${makeQS({}, extraData)}`);

export const getWalletTxns = (walletId: number, rmd?: RequestMetaData) => {
  return api.v1.get(`/wallets/${walletId}/transactions?${makeQS(rmd)}`);
};

/** Post a dollar amount correction to a specific wallet */
export const createWalletCorrection = (
  walletId: number,
  amount: number,
  note: string,
  locationId?: number,
) => {
  return api.v1.post(`/wallets/${walletId}/correction`, {
    locationId,
    amount,
    note,
  });
};

export const getDashboardStats = (args: {
  accountId?: number;
  from?: string;
  to?: string;
  origin?: string;
}) => {
  const { accountId, from, to, origin, ...restProps } = args;
  return api.v1.get(
    `/tips/dashboard-stats?${makeQS(
      {},
      { accountId, fromDate: from, toDate: to, origin, ...restProps },
    )}`,
  );
};

export const requestTipRefund = (
  id: number,
  uniqueKey: string,
  amount?: string,
  reason?: string,
) => {
  return api.v1.post(`/tips/${id}/refund`, {
    amount,
    reason,
    uniqueKey,
  });
};

export const transferTip = (id: number, toUserId: number) => {
  return api.v1.post(`/tips/transfer-tip`, {
    id,
    toUserId,
  });
};

export const getTransferTipRequests = (
  rmd?: RequestMetaData,
  controller?: AbortController,
): Promise<AxiosResponse<PagedApiResponse<ITransferRequest>>> => {
  return api.v1.get(`/transfer-requests?${makeQS(rmd)}`, {
    signal: controller?.signal,
  });
};

export const getTransferTipRequest = (
  tipId: string | number,
): Promise<AxiosResponse<ApiResponse<ITransferRequest>>> => {
  return api.v1.get(`/transfer-requests/by-tip/${tipId}`);
};

export const createTransferTipRequest = (
  tipId: number,
  body: { toUserId: number; reason: string },
) => {
  return api.v1.post(`tips/${tipId}/transfer-request`, body);
};

export const approveTransferTipRequest = (requestId: ITransferRequest['id']) => {
  return api.v1.post(`/transfer-requests/${requestId}/approve`);
};

export const rejectTransferTipRequest = (
  requestId: ITransferRequest['id'],
  body: { rejectionReason: string },
) => {
  return api.v1.post(`/transfer-requests/${requestId}/reject`, body);
};

export const getInfoDisplay = () => {
  return api.v1.get(`/info`);
};

/** Get payouts for a card */
export const getCardPayouts = (cardId: number, rmd?: RequestMetaData) => {
  return api.v1.get(`cards/${cardId}/payouts?${makeQS(rmd)}`);
};

export const getPayoutStats = () => {
  return api.v1.get(`payouts/stats`);
};

export const getPartnerPayoutStats = () => {
  return api.v1.get(`payouts/partner-stats`);
};

export const getCustomerDetails = (customerId: number) => {
  return api.v1.get(`/customers/${customerId}`);
};

export const getPayoutDetails = (id: number) => {
  return api.v1.get(`payouts/${id}?${makeQS({}, { includeProcessorResponse: true })}`);
};

export const getPayoutTransactions = (id: number | string, rmd?: RequestMetaData) => {
  return api.v1.get(`payouts/${id}/transactions?${makeQS(rmd)}`);
};

export const getRefunds = (rmd?: RequestMetaData) => {
  return api.v1.get(`/refunds?${makeQS(rmd)}`);
};

export const getRefundStats = (options: Record<string, unknown>) => {
  return api.v1.get(`/refunds/stats?${makeQS(undefined, options)}`);
};

export const createRefundRequest = (body: ICreateRefundRequest) => {
  return api.v1.post(`/refund-requests`, body);
};

export const getRefundRequests = (
  rmd: RequestMetaData,
): Promise<AxiosResponse<PagedApiResponse<IRefundRequest>>> => {
  return api.v1.get(`/refund-requests?${makeQS(rmd)}`);
};

export const approveRefundRequest = (id: number) => {
  return api.v1.post(`refund-requests/${id}/approve`);
};

export const cancelRefundRequest = (id: number) => {
  return api.v1.post(`refund-requests/${id}/cancel`);
};

export const rejectRefundRequest = (id: number, rejectionReason?: string) => {
  return api.v1.post(`refund-requests/${id}/reject`, { rejectionReason });
};

export const searchCustomers = (rmd?: RequestMetaData) => {
  return api.v1.get(`/customers/?${makeQS(rmd)}`);
};

export const getCustomerPayments = (id: number, rmd?: RequestMetaData) => {
  return api.v1.get(`/customers/${id}/payments?${makeQS(rmd)}`);
};

export const getPayments = (rmd?: RequestMetaData) => {
  return api.v1.get(`/payments?${makeQS(rmd)}`);
};

export const getPaymentTips = (paymentReference: string, rmd?: RequestMetaData) => {
  return api.v1.get(`payments/${paymentReference}/tips?${makeQS(rmd)}`);
};

export const getPaymentDetails = (paymentReference: string) => {
  return api.v1.get(`payments/${paymentReference}`);
};

export const requestRefund = (
  paymentRef: string,
  transactionId: string,
  amount: string,
  uniqueKey: string,
  reason: string,
) => api.v1.post(`payments/${paymentRef}/refund`, { transactionId, amount, uniqueKey, reason });

export const getInitiations = (rmd?: RequestMetaData, extraData?: any) => {
  return api.v1.get(`/initiations?${makeQS(rmd, extraData)}`);
};

export const getInitiationStats = (id: number) => {
  return api.v1.get(`/initiations/${id}/stats`);
};

export const getInitiationDetails = (id: number) => {
  return api.v1.get(`initiations/${id}`);
};

export const getInitiationPayouts = (id: number, rmd?: RequestMetaData) => {
  return api.v1.get(`initiations/${id}/payouts?${makeQS(rmd)}`);
};

export const getInitiationChunks = (id: number, rmd?: RequestMetaData) => {
  return api.v1.get(`initiations/${id}/chunks?${makeQS(rmd)}`);
};

export const getChunkById = (id: number | string, chunkId: number | string) => {
  return api.v1.get(`initiations/${id}/chunks/${chunkId}`);
};

export const getFeeCalculation = (body: {
  processor: string;
  amount: string;
  mode: TipAmountMode;
}) => {
  return api.v1.post(`proposals/calculate-fees`, body);
};

/** Create a new tip payment */
export const createPayment = (body: {
  deviceId: string;
  processor: string;
  intent?: string;
  scheduledPaymentIntentCode?: string;
  source: string;
  nameOnCard: string;
  postalCode?: string;
  tips: {
    userUid: string;
    amount: string;
    level: TipLevel;
  }[];
}) => {
  return api.v1.post(`payments/qr`, body);
};

export const getReceipt = (reference: string) => {
  return api.v1.get(`payments/receipts/${reference}`);
};

// TODO: Remove endpoint when after everything moved to analytics
export const getCashStats = (userId: number) => {
  return api.v1.get(`users/${userId}/cash-stats`);
};

export const forcePaymentVerification = (reference: string) => {
  return api.v1.post(`payments/${reference}/forceVerification`);
};

/** Send encoded payment intent with device id, returns payment intent object */
export const getPaymentIntent = (
  deviceId: string,
  intent?: string,
  scheduledPaymentIntentCode?: string,
) => {
  return api.v1.post(`payments/intent`, {
    deviceId,
    intent,
    scheduledPaymentIntentCode,
  });
};

/** Send request for payout initiation */
export const initiatePayouts = () => {
  return api.v1.post(`payouts/initiate`);
};

/** Fetch all upcoming payouts */
export const getTalentsInUpcomingPayout = (
  rmd?: RequestMetaData,
  extraData?: Record<string, unknown>,
): Promise<AxiosResponse<ApiResponse<UpcomingPayout>>> => {
  return api.v1.get(`payouts/upcoming-ach-payouts?${makeQS(rmd, extraData)}`);
};

export const getPartnersInUpcomingPayout = (): Promise<
  AxiosResponse<ApiResponse<UpcomingPayout>>
> => {
  return api.v1.get(`payouts/upcoming-partner-ach-payouts?interval=monthly`);
};

export const getUndistributedPoolTips = (
  rmd?: RequestMetaData,
  extraData?: Record<string, unknown>,
): Promise<AxiosResponse<ApiResponse<UndistributedPoolTip[]>>> => {
  return api.v1.get(`/tips/undistributed-pool-tips?${makeQS(rmd, extraData)}`);
};

export const distributePoolTips = (body: {
  poolTipIds: number[];
  distributions: Record<string, number>[];
}) => {
  return api.v1.post(`/tips/distribute-pool-tips`, body);
};

export const getCardTransfers = (rmd?: RequestMetaData) => {
  return api.v1.get(`/card-transfers?${makeQS(rmd)}`);
};

/*If rmd object contains search parameter, endpoint will search partners by name with iLike
If it contains name, it will search exact name.
*/

export const getPartners = (
  rmd?: RequestMetaData,
): Promise<AxiosResponse<PagedData<Partner[]>>> => {
  return api.v1.get(`/partners?${makeQS(rmd)}`);
};

// iLike name search
export const searchPartnersByName = (name: string) => {
  return api.v1.get(`/partners?search=${name}`);
};

// exact name search
export const searchPartnerByName = (name: string) => {
  return api.v1.get(`/partners?${makeQS({ filters: { name } })}`);
};

export const getProcessors = () => {
  return api.v1.get('/payments/processors');
};

export const createPartner = (partner: Partner) => {
  return api.v1.post('/partners', { ...partner });
};

export const getPartner = (id: number) => {
  return api.v1.get(`/partners/${id}`);
};

export const getWalletTransactions = (id: number, rmd?: RequestMetaData) => {
  return api.v1.get(`/wallets/${id}/transactions?${makeQS(rmd)}`);
};

export const updatePartner = (id: number, partner: Partial<Partner>) => {
  return api.v1.patch(`/partners/${id}`, { ...partner });
};

export const getFeeSchedulesForPartner = (partnerId: number, rmd?: RequestMetaData) => {
  return api.v1.get(`/fee-schedules?partnerId=${partnerId}&${makeQS(rmd)}`);
};

export const createFeeSchedule = (feeSchedule: IFeeSchedule) => {
  return api.v1.post('fee-schedules', { ...feeSchedule });
};

export const getFeeSchedules = (
  rmd?: RequestMetaData,
): Promise<AxiosResponse<PagedData<IFeeScheduleResponse[]>>> => {
  return api.v1.get(`/fee-schedules?${makeQS(rmd)}`);
};

export const getFeeScheduleDetails = (id: number) => {
  return api.v1.get(`fee-schedules/${id}`);
};

export const updateFeeSchedule = (id: number, data: { name?: string; status?: string }) => {
  return api.v1.patch(`/fee-schedules/${id}`, data);
};

export const deleteFeeSchedule = (id: number) => {
  return api.v1.delete(`/fee-schedules/${id}`);
};

export const getDrawBalances = (rmd?: RequestMetaData) => {
  return api.v1.get(`/draws/balances?${makeQS(rmd)}`);
};

export const getTotalDrawBalances = (filters?: any) => {
  return api.v1.get(`/draws/total-balance?${makeQS(filters)}`);
};

export const createDrawBalanceCorrection = (
  drawSourceId: string,
  date: string,
  amount: string,
  note: string,
): Promise<AxiosResponse<ApiResponse<any>>> => {
  return api.v1.post(`/draw-sources/balance-correction`, {
    drawSourceId,
    date,
    amount,
    note,
  });
};

export const getDraws = (rmd?: RequestMetaData) => {
  return api.v1.get(`/draws?${makeQS(rmd)}`);
};

export const getTransactions = (drawSourceId: string, rmd?: RequestMetaData) => {
  return api.v1.get(`/draw-sources/${drawSourceId}/transactions?${makeQS(rmd)}`);
};

export const getRebatePartners = (accountId: number) => {
  return api.v1.get(`/accounts/${accountId}/rebate-partners`);
};

export const updateRebatePartner = (
  accountId: number,
  partnerId: number,
  data: {
    rebateStatus?: RebateStatus;
    bankAccountId?: number;
  },
) => {
  return api.v1.patch(`/accounts/${accountId}/rebate-partners/${partnerId}`, data);
};

export const initiateDrawPayouts = () => {
  return api.v1.post(`/draws/initiate`);
};

/** Split Tips */
export const splitTips = (tipId: number, request: IUserTipRequest) =>
  api.v2.post(`tips/${tipId}/split`, {
    ...request,
  });

/** Get active users for a location */
export const getActiveLocationUsers = (id: number) => api.v1.get(`locations/${id}/users`);

export const getTipDetails = (
  id: Tip['id'],
): Promise<AxiosResponse<ApiResponse<TipDetailsResponseRefunds>>> =>
  api.v2.get(`tips/tip-details/${id}`);
