import axios, { AxiosResponse } from 'axios';
import config from 'config';
import * as LocalStorage from 'services/localStorage';
import { RefreshToken } from 'models/Authentication';
import { ApiResponse } from 'api';
import getOrCreateFingerprint, { createFingerprint } from 'components/Fingerprint/bl';
import { rootStore } from 'containers/App/App';
import { LoginData } from 'stores';

let api = axios.create({ baseURL: `${config.api.core}/v2` });
api.interceptors.request.use(async (c: any) => {
  const jwt = LocalStorage.getJwt();
  if (jwt !== undefined) {
    c.headers['Authorization'] = `Bearer ${jwt}`;
  }
  c.headers['X-Fingerprint'] = await getOrCreateFingerprint();

  return c;
});
api.interceptors.response.use(undefined, async (error) => {
  rootStore.userStore.logout();
});

let isRefreshingToken = false;
let refreshPromise: Promise<AxiosResponse<ApiResponse<RefreshToken>>> | null = null;

export async function refreshJwtToken(email?: string) {
  if (isRefreshingToken) {
    // Return existing promise if refresh token call is already in progress
    return refreshPromise;
  }

  isRefreshingToken = true;
  createFingerprint();

  const user = localStorage.getItem('userStore/user');
  const refreshToken = rootStore.userStore.refreshToken;
  email = email || (user && JSON.parse(user).email);

  try {
    refreshPromise = api.post('auth/refresh', {
      email,
      refreshToken,
    });

    const response = await refreshPromise;
    if (!response?.data?.data?.jwt) {
      throw new Error();
    }
    rootStore.userStore.setAuthData(response.data.data as unknown as LoginData);

    refreshPromise = null;
    isRefreshingToken = false;

    return response;
  } catch (error) {
    console.error('Failed to refresh JWT token', error);

    refreshPromise = null;
    isRefreshingToken = false;

    throw error;
  }
}
