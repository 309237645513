import React from 'react';
import { action, computed, flow, observable, makeObservable } from 'mobx';
import { observer } from 'mobx-react';
import { WithStyles, withStyles } from '@material-ui/core/styles';

import DP from 'components/DashPanel';

import styles from './styles';
import {
  inject,
  WithAccountInfoStore,
  WithBankAccountWizzardStore,
  WithModalStore,
  WithPayoutSourceStore,
  WithToastStore,
  WithUserStore,
} from 'stores';

import { BankAccount, BankAccountType, IPendingIntegration, Location } from 'models';
import { DotsVertical, Plus } from 'mdi-material-ui';
import {
  Box,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Drawer,
  Grid,
  IconButton,
  List,
  ListItem,
  Menu,
  MenuItem,
  Typography,
} from '@material-ui/core';
import { VerificationStatus } from '../../types';

import Api, { ApiResponse, getErrorMsg } from '../../api';
import { AxiosResponse } from 'axios';
import qs from 'qs';
import { paths } from '../../routes';
import { WithRouterStore } from '../../stores/RouterStore';
import BanksPanelItem from './BanksPanelItem';
import { BankAccountWizzardProps } from 'components/BankAccountWizzard/BankAccountWizzard';
import { BankAccountStatus } from 'services/banks';
import { getPendingIntegrations, setPendingIntegrations } from 'services/localStorage';
import Overlay from 'components/Overlay';
import { RouteComponentProps } from 'react-router-dom';
import BankEvents from 'components/BankEvents';
import { EmptyPanelMessage } from 'components/EmptyPanelMessage/EmptyPanelMessage';
import Button from 'components/Button/Dialog/Button';
import { Checkbox } from 'components/Checkbox/Checkbox';
import theme from 'containers/App/theme';
import clsx from 'clsx';
import { OverlayLoader } from 'components/Loader/OverlayLoader/OverlayLoader';

interface BankAttachPanelProps
  extends WithStyles<typeof styles>,
    RouteComponentProps,
    WithModalStore,
    WithToastStore,
    WithUserStore,
    WithPayoutSourceStore,
    WithAccountInfoStore,
    WithBankAccountWizzardStore,
    WithRouterStore {
  onAdd?: (sourceId: string) => void;
  accountId?: number;
}

interface AccountPrograms {
  ach: boolean;
  instant: boolean;
}

type PromptState = 'initial' | VerificationStatus;

export interface PlaidAuthResponse {
  accountId: number;
  entityName?: string;
  authorization: {
    _links: PlaidAuthLinks;
    bodyText: string;
    buttonText: any;
  };
}

export interface PlaidAuthLinks {
  self: { href: string; type: string; 'resource-type': string };
}

@inject(
  'modalStore',
  'toastStore',
  'userStore',
  'payoutSourceStore',
  'routerStore',
  'accountInfoStore',
  'bankAccountWizzardStore',
)
@observer
class BankAttachPanel extends React.Component<BankAttachPanelProps> {
  constructor(props: BankAttachPanelProps) {
    super(props);
    makeObservable(this);
  }

  private panelName = 'Banks';

  @observable private linkBankAccountModalOpen = false;
  @observable private confirmLocationModalOpen = false;

  @observable public creatingBankAccount = false;

  @observable public errorMsg?: string;
  @observable public promptState: PromptState = 'initial';

  @computed get qsObj(): any {
    if (this.props.routerStore && this.props.routerStore.location) {
      return qs.parse(this.props.routerStore!.location.search, { ignoreQueryPrefix: true });
    }

    return undefined;
  }

  @computed get openplaid(): string | undefined {
    return this.qsObj && this.qsObj.openplaid ? this.qsObj.openplaid : undefined;
  }

  /** Array of user's bank accounts || undefined if still loading || null if no accounts */
  @observable public bankAccounts?: null | BankAccount[];

  /**
   * Can user enroll for NetSpend? If user has no cards and is
   * location user on an account that has realtime payments enabled
   */
  @observable public canEnrollForNetspend?: boolean;

  @observable public primaryCardId?: number;
  @observable public primaryBankAccountId?: number;

  @observable public loadingAccounts = true;
  @observable public loadingCards = true;
  @observable public loadingPrograms = true;
  @observable public loadingEntityName = true;

  @observable private locationsDialogOpen = false;
  @observable private selectedBankAccount?: BankAccount;
  @observable private locations: any[] = [];
  @observable private selectedLocationIds = new Set();
  @observable private updatingLocations = false;
  @observable private loadingDrawSources = false;
  @observable private drawSources?: any[];

  @observable private attachedLocations = new Set();
  @observable private availableLocations = new Set();
  @observable private locationsOnOtherBanks = new Set();
  @observable private overridedLocations = new Set();

  @observable public menuAnchorEl: null | HTMLElement = null;
  @observable public eventsOpen = false;

  menuItems = [{ label: 'View Logs', onClick: () => this.handleEventsOpen() }];

  @action.bound openLocationsDialog(bankAccount: BankAccount) {
    this.locationsDialogOpen = true;
    this.selectedLocationIds = new Set();
    this.attachedLocations = new Set();
    this.availableLocations = new Set();
    this.locationsOnOtherBanks = new Set();
    this.overridedLocations = new Set();

    this.locations.forEach((location) => {
      this.availableLocations.add(location);
    });

    this.selectedBankAccount = bankAccount;
    this.fetchDrawSourcesForAccount().then(() => {
      if (this.drawSources) {
        this.drawSources.forEach((drawSource) => {
          if (drawSource.locationId) {
            const location = this.locations.find((el: any) => el.id === drawSource.locationId);
            if (location) {
              if (drawSource.bankAccountId === bankAccount.id) {
                this.attachedLocations.add(location);
                this.availableLocations.delete(location);
              } else {
                this.locationsOnOtherBanks.add(location);
              }
            }
          }
        });
      }
    });
  }

  @action.bound closeLocationsDialog() {
    this.locationsDialogOpen = false;
  }

  @action.bound toggleConfirmLocationDialog(open: boolean) {
    this.confirmLocationModalOpen = open;
  }

  @action.bound handleAttachLocationsConfirm() {
    this.finishPendingIntegration(this.selectedBankAccount!);
    if (this.overridedLocations.size === 0) {
      this.attachLocationsToBankAccount();
    } else {
      this.closeLocationsDialog();
      this.toggleConfirmLocationDialog(true);
    }
  }

  @action.bound attachLocationsToBankAccount = flow(function* (this: BankAttachPanel) {
    try {
      this.updatingLocations = true;

      yield Api.core.updateAccountBankLocations(
        this.props.accountId!,
        this.selectedBankAccount!.id,
        Array.from(this.selectedLocationIds) as number[],
      );
      const multipleLocations = Array.from(this.selectedLocationIds).length > 1;
      this.props.toastStore!.push({
        type: 'success',
        message: `Location${
          multipleLocations ? 's' : ''
        } successfully added for bank account draws`,
      });
    } catch (e: any) {
      this.props.toastStore!.push({ type: 'error', message: getErrorMsg(e) });
    } finally {
      this.updatingLocations = false;
      this.closeLocationsDialog();
      this.toggleConfirmLocationDialog(false);
    }
  });

  @action.bound fetchDrawSourcesForAccount = flow(function* (this: BankAttachPanel) {
    try {
      this.loadingDrawSources = true;
      const resp = yield Api.tips.getDrawSourcesForAccount(this.props.accountId!);
      this.drawSources = resp.data.data;
    } catch (e: any) {
      this.props.toastStore!.push({ type: 'error', message: getErrorMsg(e) });
      this.closeLocationsDialog();
    } finally {
      this.loadingDrawSources = false;
    }
  });

  @action.bound toggleLocationSelection(locationId: number, selected: boolean) {
    const otherLocation = [...this.locationsOnOtherBanks].find((el: any) => el.id === locationId);
    if (selected) {
      this.selectedLocationIds.add(locationId);
      if (otherLocation) {
        this.overridedLocations.add(otherLocation);
      }
    } else {
      this.selectedLocationIds.delete(locationId);
      this.overridedLocations.delete(otherLocation);
    }
  }

  @action.bound selectAllAvailableLocations(selected: boolean) {
    [...this.availableLocations].forEach((location: any) => {
      this.toggleLocationSelection(location.id, selected);
    });
  }

  @action.bound handleEventsOpen() {
    this.eventsOpen = true;
  }

  @computed get loadingLocationsDialog() {
    return this.updatingLocations || this.loadingDrawSources;
  }

  /** Wait for all api calls and only then set loading to false */
  @computed get loading() {
    return this.loadingAccounts || this.loadingPrograms;
  }

  /** Prompt state for linking bank account */
  @action.bound toggleLinkBankAccountModal(open: boolean) {
    this.linkBankAccountModalOpen = open;
    const props = {
      accountId: this.props.accountId!,
      open,
      onClose: this.closeBankAccountWizzard,
      onAdd: this.handleAddBank,
      onMultipleLocations: this.openLocationsDialog,
    } as unknown as BankAccountWizzardProps;
    this.props.bankAccountWizzardStore?.setProps(props);
  }

  @action.bound public fetchBanks = flow(function* (this: BankAttachPanel) {
    try {
      const accountId = this.props.accountId!;
      const resp: AxiosResponse<ApiResponse<BankAccount[]>> = yield Api.core.getAccountBanks(
        accountId,
      );
      const bankAccounts = resp.data.data;
      if (bankAccounts) this.bankAccounts = bankAccounts.length === 0 ? null : bankAccounts;
    } catch (e: any) {
      this.props.toastStore!.push({ type: 'error', message: getErrorMsg(e) });
    } finally {
      this.loadingAccounts = false;
    }
  });

  @computed get numberOfBanks() {
    if (this.bankAccounts) {
      return this.bankAccounts.length;
    } else {
      return 0;
    }
  }

  @action.bound fetchLocations = async () => {
    try {
      const resp = await Api.core.getAllLocationsByAccount(this.props.accountId!);
      this.locations = resp.data.data;
    } catch (e: any) {
      this.props.toastStore!.push({ type: 'error', message: getErrorMsg(e) });
    }
  };

  @action.bound closeBankAccountWizzard = () => {
    this.linkBankAccountModalOpen = false;
  };

  @action.bound public attachSingleLocationToBank = async (
    bankAccountId: number,
    locationId: number,
  ) => {
    try {
      const accountId = this.props.accountId!;
      await Api.core.updateAccountBankLocations(accountId, bankAccountId, [locationId]);
    } catch (e) {
      this.props.toastStore!.push({ type: 'error', message: getErrorMsg(e) });
      // eslint-disable-next-line no-empty
    } finally {
    }
  };

  @action.bound public closePanelMenu = () => {
    this.menuAnchorEl = null;
  };

  @action.bound public openPanelMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    this.menuAnchorEl = event.currentTarget;
  };

  redirectToPlaid(appId?: number) {
    let _appId = appId ? appId : this.openplaid;
    this.props.routerStore!.history.push(`${paths.integrations()}?openplaid=${_appId}`);
  }

  attachAllBankAccounts = async (bankAccount: BankAccount) => {
    const locationIds = this.locations.map((location: Location) => location.id);
    try {
      await Api.core.updateAccountBankLocations(this.props.accountId!, bankAccount.id, locationIds);
    } catch (e: any) {
      this.props.toastStore!.push({
        type: 'error',
        message: 'Locations could not be added to account',
      });
    }
  };

  isBankAccountVerified = (bankAccount: BankAccount) => {
    const verificationStatus = bankAccount.verificationStatus;
    if (
      verificationStatus === BankAccountStatus.AUTOMATICALLY_VERIFIED ||
      verificationStatus === BankAccountStatus.MANUALLY_VERIFIED ||
      verificationStatus === BankAccountStatus.VERIFIED
    ) {
      return true;
    }
    return false;
  };

  handleAddBank = async (success: boolean, bankAccount: BankAccount) => {
    if (!success) {
      if (this.openplaid) {
        this.redirectToPlaid();
      }
      return;
    }

    await this.fetchBanks();
    await this.handleAddLocation(bankAccount);
    if (this.openplaid) {
      this.setPendingIntegration(bankAccount);
      if (this.isBankAccountVerified(bankAccount)) {
        this.redirectToPlaid();
      } else {
        this.props.history.replace(`${paths.accountDetails(this.props.accountId!).billing()}`);
      }
    }
  };

  /**
   * If bank account is not verified,
   * we save this pending integration to localstorage
   */
  setPendingIntegration = (bankAccount: BankAccount) => {
    if (this.isBankAccountVerified(bankAccount)) return;
    setPendingIntegrations({ appId: this.qsObj.openplaid, bankAccountId: bankAccount.id });
  };

  handleAddLocation = async (newBankAccount: BankAccount) => {
    await this.fetchBanks();

    const bankAccount: BankAccount = await this.getBankAccount(newBankAccount.id);
    if (!bankAccount) return;

    const _newBankAccount = bankAccount;
    if (!this.isBankAccountVerified(_newBankAccount)) return;

    if (this.isFirstVerifiedBankAccount(_newBankAccount)) {
      await this.attachAllBankAccounts(_newBankAccount);
    } else {
      if (this.locations && this.locations.length) {
        this.openLocationsDialog(_newBankAccount);
      }
    }
  };

  async getBankAccount(id: number) {
    try {
      const { data } = await Api.core.getBankAccount(id);
      return data?.data;
    } catch (e) {
      console.error(getErrorMsg(e));
    }
  }

  onManualVerificationSuccess = async (bankAccount: BankAccount) => {
    await this.handleAddLocation(bankAccount);
    if (!this.locationsDialogOpen) {
      this.finishPendingIntegration(bankAccount);
    }
  };

  finishPendingIntegration = (bankAccount: BankAccount) => {
    const pendingIntegration = this.findPendingIntegrationForBankAccount(bankAccount);
    if (!pendingIntegration) return;
    this.redirectToPlaid(pendingIntegration.appId);
  };

  findPendingIntegrationForBankAccount = (
    bankAccount: BankAccount,
  ): IPendingIntegration | undefined => {
    const pendingIntegrations = getPendingIntegrations() as IPendingIntegration[];
    if (!pendingIntegrations) return;

    const pendingIntegration = pendingIntegrations.find(
      (pendingIntegration: IPendingIntegration) =>
        pendingIntegration.bankAccountId === bankAccount.id,
    );
    if (pendingIntegration) return pendingIntegration;
    return;
  };

  isFirstVerifiedBankAccount = (bankAccount: BankAccount) => {
    if (this.bankAccounts && this.bankAccounts.length === 1) return true;
    if (this.bankAccounts) {
      const verifiedBankAccounts = this.bankAccounts.filter((_bankAccount: BankAccount) => {
        if (_bankAccount.id !== bankAccount.id) {
          return this.isBankAccountVerified(_bankAccount);
        }
      });
      return verifiedBankAccounts.length ? false : true;
    }
  };

  locationDialogTitle = () => {
    const message = 'Select locations to attach to bank account';
    let bankAccount;
    if (this.selectedBankAccount) {
      let { institutionName, accountName, accountMask } = this.selectedBankAccount;
      institutionName = institutionName ? institutionName : '';
      accountName = accountName ? accountName : '';
      accountMask = accountMask ? accountMask : '';

      bankAccount = `${institutionName}${institutionName && ' - '}${accountName}${
        accountName && ' - '
      }${accountMask}`;
    } else {
      ('Select locations to attach to bank account');
    }
    return (
      <Typography variant="body1">
        {`${message}${bankAccount && ':'}`}
        <br />
        {bankAccount}
      </Typography>
    );
  };

  @action.bound public closeEvents = () => {
    this.eventsOpen = false;
  };

  async componentDidMount() {
    this.fetchBanks();
    this.fetchLocations().then(() => {
      if (this.openplaid) {
        this.toggleLinkBankAccountModal(true);
      }
    });
  }

  render() {
    const classes = this.props.classes;
    const { dialogActions, dialogTitle, dialogContent, pl0, checkbox, availableItem } = classes;
    const checkingForPendingIntegrations =
      this.props.accountInfoStore!.checkingForPendingIntegrations;
    return (
      <>
        <>
          <Dialog
            open={checkingForPendingIntegrations}
            className={classes.pendingIntegrationsDialog}>
            <DialogTitle>Checking for pending integrations</DialogTitle>
            <DialogContent className={classes.pendingIntegrationsDialogContent}>
              <CircularProgress />
            </DialogContent>
          </Dialog>
          <DP>
            <DP.Header>
              <DP.Title count={this.numberOfBanks} light>
                {this.panelName}
              </DP.Title>
              <DP.Actions>
                {this.props.onAdd && !(this.props.userStore && this.props.userStore.isAdmin) ? (
                  <DP.IconButton
                    icon={Plus}
                    primary
                    tooltip="Attach bank"
                    onClick={() => this.toggleLinkBankAccountModal(true)}
                  />
                ) : (
                  <>
                    <IconButton size="small" onClick={this.openPanelMenu}>
                      <DotsVertical fontSize="small" />
                    </IconButton>
                    <Menu
                      anchorEl={this.menuAnchorEl}
                      open={Boolean(this.menuAnchorEl)}
                      onClose={() => this.closePanelMenu()}>
                      {this.menuItems.map((menuItem, i) => (
                        <MenuItem dense key={i} onClick={() => menuItem.onClick!()}>
                          {menuItem.label}
                        </MenuItem>
                      ))}
                    </Menu>
                  </>
                )}
              </DP.Actions>
            </DP.Header>
            {this.numberOfBanks > 0 ? (
              <DP.List height={this.numberOfBanks < 5 ? 'short' : 'normal'}>
                {this.bankAccounts && (
                  <Grid container>
                    {this.bankAccounts.map((bankAccount) => (
                      <Grid item key={bankAccount.id} xs={12} md={12}>
                        <BanksPanelItem
                          bankAccount={bankAccount}
                          onClickManage={(bankAccount) => this.openLocationsDialog(bankAccount)}
                          onManualVerificationSuccess={(bankAccount: BankAccount) =>
                            this.onManualVerificationSuccess(bankAccount)
                          }
                          refetchAccounts={() => this.fetchBanks()}
                        />
                      </Grid>
                    ))}
                  </Grid>
                )}
              </DP.List>
            ) : (
              <DP.Body>
                <Box display={'flex'} alignContent={'center'}>
                  <EmptyPanelMessage panelTitle={this.panelName} />
                </Box>
              </DP.Body>
            )}
          </DP>
          <Dialog
            open={this.locationsDialogOpen}
            onClose={() => {
              this.locationsDialogOpen = false;
            }}>
            <>
              <Overlay display={this.loadingLocationsDialog}>
                <CircularProgress color="inherit" size={24} />
              </Overlay>
              <>
                <DialogTitle className={dialogTitle}>
                  <Typography style={{ fontSize: 28, fontWeight: 400 }}>
                    Attach locations to bank account
                  </Typography>
                </DialogTitle>
                <DialogContent className={dialogContent}>
                  <Box mt={4}>{this.locationDialogTitle()}</Box>
                  <Box mt={3}>
                    <Typography variant="body2">Attached locations</Typography>
                    <List>
                      {[...this.attachedLocations].map((location: any, idx: number) => (
                        <ListItem className={pl0} key={`${location.id}_${idx}`}>
                          <Typography variant="body1">{location.name}</Typography>
                        </ListItem>
                      ))}
                    </List>
                  </Box>
                  <Box mt={3}>
                    <Typography variant={'body2'}>Available locations</Typography>
                    <List>
                      <ListItem className={pl0} style={{ marginBottom: theme.spacing(2.25) }}>
                        <Checkbox
                          className={clsx(checkbox)}
                          onChange={(e) => this.selectAllAvailableLocations(e.target.checked)}
                          name="Select all"
                        />
                        <Typography variant="body1">Select all</Typography>
                      </ListItem>
                      {[...this.availableLocations].map((location: any, idx: number) => (
                        <ListItem
                          key={`${location.id}_${idx}`}
                          className={clsx(pl0, availableItem)}>
                          <Checkbox
                            className={checkbox}
                            checked={this.selectedLocationIds.has(location.id)}
                            onChange={(e) =>
                              this.toggleLocationSelection(location.id, e.target.checked)
                            }
                          />
                          <Typography variant="body1">{location.name}</Typography>
                        </ListItem>
                      ))}
                    </List>
                  </Box>
                </DialogContent>
                <DialogActions className={dialogActions}>
                  <Button onClick={this.closeLocationsDialog}>cancel</Button>
                  <Button
                    variant="contained"
                    onClick={this.handleAttachLocationsConfirm}
                    disabled={this.selectedLocationIds.size === 0}>
                    confirm
                  </Button>
                </DialogActions>
              </>
            </>
          </Dialog>
          <Dialog open={this.confirmLocationModalOpen}>
            <div style={{ minWidth: '15vw' }}>
              <DialogTitle className={dialogTitle}>
                <Typography style={{ fontSize: 28, fontWeight: 400 }}>Are you sure?</Typography>
              </DialogTitle>
              <DialogContent className={dialogContent}>
                <OverlayLoader display={this.loadingLocationsDialog} />
                <Box mt={4}>
                  <Typography variant="body1">Override following locations:</Typography>
                </Box>
                <Box mt={3}>
                  {this.overridedLocations &&
                    [...this.overridedLocations].map((location: any) => (
                      <Typography variant="body1" style={{ fontWeight: 700 }} key={location.id}>
                        {location.name}
                      </Typography>
                    ))}
                </Box>
              </DialogContent>
              <DialogActions className={dialogActions}>
                <Button
                  onClick={() => this.toggleConfirmLocationDialog(false)}
                  className={classes.greyText}>
                  cancel
                </Button>
                <Button
                  variant="contained"
                  onClick={() => this.attachLocationsToBankAccount()}
                  disabled={this.selectedLocationIds.size === 0}>
                  confirm
                </Button>
              </DialogActions>
            </div>
          </Dialog>
        </>
        <Drawer
          open={this.eventsOpen}
          onClose={() => this.closeEvents()}
          anchor="right"
          variant="temporary">
          <BankEvents
            businessAccountId={this.props.accountId}
            bankAccountType={BankAccountType.BUSINESS}
            onClose={() => this.closeEvents()}
          />
        </Drawer>
      </>
    );
  }
}

export default withStyles(styles)(BankAttachPanel);
