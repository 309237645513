/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { Route, RouteComponentProps } from 'react-router-dom';
import { computed, makeObservable } from 'mobx';
import { observer } from 'mobx-react';

import { Box } from '@material-ui/core';
import { WithStyles, withStyles } from '@material-ui/core/styles';

import { inject, WithAnalyticsStore } from 'stores';
import { paths } from 'routes';
import { setTitle } from 'services';

import DashboardLayout from 'containers/DashboardLayout';
import * as DateRangeExternalPicker from 'components/DateRangeExternalPicker';


// import ActivityAccounts from './ActivityAccounts';
// import ActivityDevices from './ActivityDevices';
import ActivityAccountsTips from './ActivityAccountsTips';

import styles from './styles';
import Title from 'components/Title';

const PAGE_TITLE = 'Activity';

/** Here we define what kind of props this component takes */
type ActivityProps = WithStyles<typeof styles> & // Adds the classes prop
  WithAnalyticsStore &
  RouteComponentProps;
/**
 * Container for displaying and downloading tip reports.
 * Accessible to admin, owner and manager scope.
 */
@inject('analyticsStore')
@observer
class Activity extends React.Component<ActivityProps> {
  constructor(props: ActivityProps) {
    super(props);
    makeObservable(this);
  }

  @computed public get dateRange(): DateRangeExternalPicker.DateRange {
    return this.props.analyticsStore!.dateRange;
  }

  componentDidMount() {
    setTitle(PAGE_TITLE, { noSuffix: false });
  }

  render() {
    const analyticsPath = paths.analytics();
    const { pathname } = this.props.location;
    return (
      <DashboardLayout>
        <Box
          width="100%"
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center">
          <Title mb={3}>{PAGE_TITLE}</Title>
        </Box>
        {/* <TabBar mb={3}> */}
        {/* <TabBar.Tab
            component={RouterLink}
            to={analyticsPath.accounts()}
            selected={pathname === analyticsPath.accounts()}>
            Accounts
          </TabBar.Tab>
          <TabBar.Tab
            component={RouterLink}
            to={analyticsPath.devices()}
            selected={pathname === analyticsPath.devices()}>
            Devices
          </TabBar.Tab> */}

        {/* <TabBar.Tab
            component={RouterLink}
            to={analyticsPath.tips()}
            selected={pathname === analyticsPath.tips()}>
            tips
          </TabBar.Tab> */}

        {/* </TabBar> */}

        {/* <Route
          path={analyticsPath.accounts()}
          exact
          render={(props: any) => <ActivityAccounts {...props} />}
        />
        <Route
          path={analyticsPath.devices()}
          exact
          render={(props: any) => <ActivityDevices {...props} />}
        /> */}
        <Route
          path={analyticsPath.tips()}
          render={(props: any) => <ActivityAccountsTips {...props} />}
        />
      </DashboardLayout>
    );
  }
}

export default withStyles(styles)(Activity);
