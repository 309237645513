import { useStores } from 'containers/App/App';
import { useEffect, useRef, useState } from 'react';
import { IMFAFactor, MFAChannel } from 'models';
import {
  getFactorByName,
  mapCodesToFactors,
  resendMfaCode,
  setupMfaComponentForm,
} from './helpers';

interface IUseMfaComponentProps {
  onFormValid?: () => void;
}

export declare type AuthCodeRef = {
  focus: () => void;
  clear: () => void;
};

const useMfaComponent = (props: IUseMfaComponentProps) => {
  const { onFormValid } = props;
  const [form, setForm] = useState<any>();
  const AuthInputRef = useRef<AuthCodeRef>(null);
  const { mfaStore } = useStores();
  const { factors } = mfaStore;
  const loading = !form;

  if (!form && factors?.length) {
    const form = setupMfaComponentForm(factors);
    setForm(form);
  }

  const wrongCode = (name: MFAChannel) => {
    const factor = getFactorByName(factors, name);
    return factor?.error;
  };

  const mfaFieldError = (factor: IMFAFactor) => {
    const { channel: fieldName } = factor;
    const message = wrongCode(fieldName) || form?.$(fieldName).error;
    const error = !!message;
    return { error, message };
  };

  const sendMfaCode = async (name: MFAChannel) => {
    form?.$(name).clear();
    await resendMfaCode(name);
  };

  const errorMessage = (name: MFAChannel): string | null => {
    if (!form) return null;
    return form!.$(name).error;
  };

  const onResendMfaCode = (name: MFAChannel) => {
    form!.$(name).clear();
  };

  useEffect(() => {
    if (form) {
      if (form.hasError) {
        mfaStore.mfaBody && mfaStore.resetMfaBody();
      } else {
        const factors = mapCodesToFactors(form);
        mfaStore.setMfaBody(factors);
        onFormValid && onFormValid();
      }
    }
  });

  return {
    form,
    factors,
    mfaFieldError,
    sendMfaCode,
    errorMessage,
    onResendMfaCode,
    loading,
  };
};

export default useMfaComponent;
