import React, { Fragment, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { observable, action, flow, computed, makeObservable } from 'mobx';
import { Link, RouteComponentProps } from 'react-router-dom';
import moment from 'moment-timezone';
import DP from 'components/DashPanel';

import { WithStyles, withStyles } from '@material-ui/core/styles';
import {
  Checkbox,
  Divider,
  Grid,
  ListItem,
  ListItemIcon,
  ListItemText,
  Radio,
  Typography,
} from '@material-ui/core';
import { CreditCard, Wallet } from 'mdi-material-ui';

import Api, { getErrorMsg } from 'api';
import {
  inject,
  WithEnrollStore,
  WithToastStore,
  WithUserStore,
  WithAccountInfoStore,
  WithBankAccountWizzardStore,
} from 'stores';

import * as models from 'models';
import { paths } from 'routes';
import { usdToNumericString } from 'services';

import InvoicesPanel from 'components/InvoicesPanel';
import PaymentsPanel from 'components/PaymentsPanel';
import PaymentMethodsPanel from 'components/PaymentMethodsPanel';
import LoadingSpinner from 'components/LoadingSpinner';
import { DenseStatCard } from 'containers/UserDetails/Stats';

import styles from './styles';
import BankAttachPanel from './BankAttachPanel';
import EnrollRebate from './EnrollRebate';
import { DATE_TYPE, formatLocalDateTimeInUTC } from 'utils/helper';
import { useStores } from 'containers/App/App';
import CreditCardIcon from 'components/CreditCardIcon';
import _ from 'lodash';
import BankAccountWizzard from 'components/BankAccountWizzard/BankAccountWizzard';
import EnrollmentWizzard, * as EnrollmentWizzard_1 from './EnrollmentWizzard';
import TippyMasonry from 'components/TippyMasonry/TippyMasonry';
import { faMoneyBillWave, faWallet } from '@fortawesome/pro-regular-svg-icons';
import BillingGeneralPanel from 'components/BillingGeneralPanel/BillingGeneralPanel';
import { Box } from '@mui/material';
import { WithRouterStore } from 'stores/RouterStore';
import Button from 'components/Button/Button';
import Dialog from 'components/Dialog/Dialog';

/** Here we define what kind of props this component takes */
interface BillingProps
  extends WithStyles<typeof styles>,
    RouteComponentProps,
    WithToastStore,
    WithRouterStore,
    WithEnrollStore,
    WithAccountInfoStore,
    WithBankAccountWizzardStore,
    WithUserStore {
  accountId: number;
  account?: models.Account;
}
/**
 * Container for account licenses, payments, payment methods and invoices panels.
 */
@inject(
  'userStore',
  'routerStore',
  'toastStore',
  'enrollStore',
  'routerStore',
  'accountInfoStore',
  'bankAccountWizzardStore',
)
@observer
class Billing extends React.Component<BillingProps> {
  constructor(props: BillingProps) {
    super(props);
    makeObservable(this);
    this.paymentsPanelRef = React.createRef();
  }

  @observable private paymentsPanelRef: React.RefObject<HTMLDivElement> | null = null;

  @observable private nextPayments?: models.NextPayment;

  @observable private lastTransaction?: models.Transaction;

  @observable private billingEntities: models.IBillingEntity[] = [];

  @observable public loadingBillingEntities = false;

  @observable private paymentMethods: models.PaymentMethod[] = [];

  @observable private charges: models.Charge[] = [];

  /** State of dialog */
  @observable public dialogOpen = false;

  /** Submitting state */
  @observable private submitting = false;

  @observable private renderEnroll = false;

  @observable private rebateBalance?: number;

  @observable private showRebateBalance = false;

  @observable enrolmentWizzardProps?: EnrollmentWizzard_1.IEnrolmentWizzard;

  @observable refetchLicensesKey = Date.now();

  @observable fetchingInvoices = false;

  @observable invoices: models.Invoice[] = [];

  /** Fetches the charges for this account */
  @action.bound public getCharges = flow(function* (this: Billing) {
    try {
      const accountId = this.props.accountId;
      const resp = yield Api.billing.getCharges(accountId);
      this.charges = resp.data && resp.data.data;
    } catch (e: any) {
      this.props.toastStore!.error(getErrorMsg(e));
    }
  });

  @action.bound private getNextPayments = flow(function* (this: Billing) {
    try {
      const resp = yield Api.billing.getNextPayment(this.props.accountId);
      // @ts-ignore
      this.nextPayments = resp.data && resp.data.data;
    } catch (e: any) {
      console.log(e);
    }
  });

  @action.bound private getLastTransactions = flow(function* (this: Billing) {
    try {
      const resp = yield Api.billing.getLastTransaction(this.props.accountId);
      // @ts-ignore
      this.lastTransaction = resp.data && resp.data.data[0];
    } catch (e: any) {
      this.props.toastStore!.error(getErrorMsg(e));
    }
  });

  @action.bound private getBillingEntities = async () => {
    try {
      this.loadingBillingEntities = true;
      const { data } = await Api.billing.getBillingEntities(this.props.accountId);
      this.billingEntities = data.data || [];
    } catch (error) {
      this.props.toastStore!.error(getErrorMsg(error));
    } finally {
      this.loadingBillingEntities = false;
    }
  };

  @action.bound private getPaymentMethods = flow(function* (this: Billing) {
    try {
      const { data } = yield Api.billing.getPaymentMethods(this.props.accountId);
      this.paymentMethods = data?.data || [];
    } catch (e: any) {
      this.props.toastStore!.error(getErrorMsg(e));
    }
  });

  @action.bound private makePayment = flow(function* (this: Billing, paymentMethodId?: number) {
    try {
      this.submitting = true;
      const resp = yield Api.billing.chargeBalance(this.props.accountId, paymentMethodId);
      if (resp) {
        this.props.toastStore!.success(`Payment successful`);
        // Refetch data
        this.init();
      }
      this.getInvoices();
    } catch (e: any) {
      this.props.toastStore!.error(getErrorMsg(e));
    } finally {
      this.submitting = false;
      this.closeDialog();
    }
  });

  // /** Calls the API to add a payment method to the account */
  @action.bound public addPaymentMethod = flow(function* (this: Billing, sourceId: string) {
    const accountId = this.props.accountId;

    try {
      // Add the payment method
      yield Api.billing.addPaymentMethod(sourceId, accountId);
      // Fetch the payment methods
      yield this.getPaymentMethods();
      // Trigger success toast and close the modal
      this.props.toastStore!.success('Payment method successfully added');
    } catch (e: any) {
      this.props.toastStore!.error(getErrorMsg(e));
    }
  });

  /** Inits the component */
  @action.bound public init = flow(function* (this: Billing) {
    yield this.getNextPayments();
    yield this.getLastTransactions();
    yield this.getPaymentMethods();
    yield this.getCharges();
  });

  /** Fetches the invoices from the API */
  @action.bound public getInvoices = async () => {
    try {
      this.fetchingInvoices = true;
      const { data } = await Api.billing.getInvoices(this.props.accountId);
      this.invoices = data?.data || [];
    } catch (e: any) {
      this.props.toastStore!.error(getErrorMsg(e));
    } finally {
      this.fetchingInvoices = false;
    }
  };

  @action.bound private async setRenderEnroll() {
    try {
      const { data } = await Api.tips.getRebatePartners(this.props.accountId);
      this.renderEnroll = !!data?.data?.length;
    } catch (error) {
      this.renderEnroll = false;
      this.props.toastStore?.error(getErrorMsg(error));
    }
  }

  @action.bound openEnrollmentWizzard(props: EnrollmentWizzard_1.IEnrolmentWizzard) {
    const _props = {
      ...props,
      exitEnrollmentWizzard: () => {
        props.exitEnrollmentWizzard();
        this.enrolmentWizzardProps = undefined;
      },
    };
    this.enrolmentWizzardProps = _props;
  }

  @action.bound handleSuccessfullVoid() {
    this.refetchLicensesKey = Date.now();
    this.getNextPayments();
    this.getLastTransactions();
  }

  handleScrollIntoView = () => {
    const searchParams = new URLSearchParams(this.props.routerStore!.location.search);
    const shouldFocusPayments = searchParams.has('focusPayments');
    if (shouldFocusPayments) {
      this.paymentsPanelRef?.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };

  componentDidMount() {
    if (this.props.accountId) {
      this.init();
      this.getBillingEntities();
    }
    this.setRenderEnroll();

    this.handleScrollIntoView();
  }

  updateRebateBalance = (showBalance: boolean, sumWalletBalance?: number) => {
    if (showBalance) {
      this.showRebateBalance = true;
      this.rebateBalance = sumWalletBalance;
    } else {
      this.showRebateBalance = false;
    }
  };

  openDialog = () => (this.dialogOpen = true);

  closeDialog = () => (this.dialogOpen = false);

  renderLinkButton() {
    const { accountId } = this.props;
    const accountDetailsPath = paths.accountDetails(accountId);
    const userStore = this.props.userStore!;
    const isOwner = userStore.scope.kind === 'owner';
    const hasNegativeBalance =
      this.lastTransaction && this.parseCurrencyToNumber(this.lastTransaction.balance) < 0;
    return (
      <Fragment>
        <Button
          color="primary"
          component={Link}
          variant="text"
          underline
          size="small"
          to={accountDetailsPath.transactions()}>
          View all
        </Button>
        {hasNegativeBalance && (userStore.isAdmin || isOwner) && (
          <Button color="inherit" size="small" variant="text" underline onClick={this.openDialog}>
            {userStore.isAdmin ? 'Charge' : 'Pay'}
          </Button>
        )}
      </Fragment>
    );
  }

  renderDate() {
    if (this.nextPayments && this.nextPayments.date)
      return moment(this.nextPayments.date).format('MMM, DD YYYY');
  }

  parseCurrencyToNumber = (currency?: string): number => {
    if (currency) {
      return parseFloat(usdToNumericString(currency));
    } else return 0;
  };

  renderSpinner() {
    const { classes } = this.props;
    return (
      <Box className={classes.spinner}>
        <LoadingSpinner />
      </Box>
    );
  }

  nextPayout = () => {
    let date = new Date(Date.now()).getUTCDate();
    let payoutThisMonth = true;
    if (date > 5) payoutThisMonth = false;

    let displayDate: moment.Moment | string = moment();
    if (!payoutThisMonth) {
      displayDate = displayDate.add(1, 'months');
    }
    displayDate = displayDate.toISOString();

    const month = formatLocalDateTimeInUTC(displayDate, DATE_TYPE.DATE, 'MMMM')!.toUpperCase();
    const year = formatLocalDateTimeInUTC(displayDate, DATE_TYPE.DATE, 'YYYY');
    return `${month} 05, ${year}`;
  };

  @computed get loadingRebateBalance() {
    return this.rebateBalance === undefined ? true : false;
  }

  render() {
    const { accountId } = this.props;
    const isAdmin = this.props.userStore!.isAdmin;
    return (
      <Grid container direction={'row'} spacing={3}>
        {this.showRebateBalance && (
          <Grid item xs={12}>
            <Grid container direction={'row'} spacing={3}>
              <Grid item md={12} lg={6} xl={4} style={{ flexGrow: 1 }}>
                <DenseStatCard
                  icon={Wallet}
                  duration={1}
                  decimals={2}
                  prefix="$"
                  separator=","
                  title="Balance"
                  additionalData={this.renderLinkButton()}>
                  {this.lastTransaction
                    ? this.parseCurrencyToNumber(this.lastTransaction.balance)
                    : 0}
                </DenseStatCard>
              </Grid>
              <Grid item md={12} lg={6} xl={4} style={{ flexGrow: 1 }}>
                <DenseStatCard
                  icon={CreditCard}
                  duration={1}
                  decimals={2}
                  prefix="$"
                  separator=","
                  title="Next Payment"
                  displayTax
                  applyTax={this.nextPayments && this.nextPayments.isTaxapplied}
                  additionalData={this.nextPayments && this.renderDate()}>
                  {this.nextPayments ? this.parseCurrencyToNumber(this.nextPayments.amount) : 0}
                </DenseStatCard>
              </Grid>
              <Grid item md={12} lg={12} xl={4} style={{ flexGrow: 1 }}>
                <DenseStatCard
                  icon={Wallet}
                  duration={1}
                  decimals={2}
                  prefix="$"
                  loading={this.loadingRebateBalance}
                  separator=","
                  title="Rebate Balance"
                  additionalData={this.nextPayments && `Next payout ${this.nextPayout()}`}>
                  {this.rebateBalance ? this.rebateBalance : 0}
                </DenseStatCard>
              </Grid>
            </Grid>
          </Grid>
        )}

        <TippyMasonry>
          {!this.showRebateBalance && (
            <Box display={'flex'} flexWrap={'wrap'} style={{ gap: '24px' }}>
              <Box className={this.props.classes.gridItem}>
                <DenseStatCard
                  iconSize={18}
                  fontAwesomeIcon={faWallet}
                  duration={1}
                  decimals={2}
                  prefix="$"
                  separator=","
                  title="Balance"
                  additionalData={this.renderLinkButton()}>
                  {this.lastTransaction
                    ? this.parseCurrencyToNumber(this.lastTransaction.balance)
                    : 0}
                </DenseStatCard>
              </Box>

              <Box className={this.props.classes.gridItem}>
                <DenseStatCard
                  iconSize={18}
                  fontAwesomeIcon={faMoneyBillWave}
                  duration={1}
                  decimals={2}
                  prefix="$"
                  separator=","
                  title="Next Payment"
                  displayTax
                  applyTax={this.nextPayments && this.nextPayments.isTaxapplied}
                  additionalData={this.nextPayments && this.renderDate()}>
                  {this.nextPayments ? this.parseCurrencyToNumber(this.nextPayments.amount) : 0}
                </DenseStatCard>
              </Box>
            </Box>
          )}

          <BillingGeneralPanel
            accountId={accountId}
            billingEntities={this.billingEntities}
            paymentMethods={this.paymentMethods}
            refreshNextPayments={this.getNextPayments}
            refetchLicensesKey={this.refetchLicensesKey}
            getBillingEntities={this.getBillingEntities}
            loadingBillingEntities={this.loadingBillingEntities}
          />

          {this.renderEnroll && (
            <EnrollRebate
              key={this.props.enrollStore!.updateEnrollRebate}
              fullWidth
              accountId={accountId}
              updateRebateBalance={this.updateRebateBalance}
              openEnrolmentWizzard={this.openEnrollmentWizzard}
            />
          )}

          <InvoicesPanel
            invoices={this.invoices}
            fetchingInvoices={this.fetchingInvoices}
            getInvoices={this.getInvoices}
            accountId={accountId}
            onVoidSuccess={this.handleSuccessfullVoid}
          />

          <Box ref={this.paymentsPanelRef}>
            <PaymentMethodsPanel
              accountId={accountId}
              paymentMethods={this.paymentMethods}
              onAdd={this.addPaymentMethod}
              isAdmin={isAdmin}
              refetchPaymentMethods={this.getPaymentMethods}
            />
          </Box>

          <BankAttachPanel {...this.props} onAdd={this.addPaymentMethod} accountId={accountId} />

          {this.charges && this.charges.length > 0 ? (
            <PaymentsPanel charges={this.charges} />
          ) : null}
        </TippyMasonry>

        <PaymentDialog
          open={this.dialogOpen}
          submitting={this.submitting}
          close={this.closeDialog}
          billingEntities={this.billingEntities}
          lastTransaction={this.lastTransaction}
          parseCurrency={this.parseCurrencyToNumber}
          makePayment={this.makePayment}
        />
        {this.props.bankAccountWizzardStore?.props && (
          <BankAccountWizzard {...this.props.bankAccountWizzardStore.props} />
        )}
        {this.enrolmentWizzardProps && <EnrollmentWizzard {...this.enrolmentWizzardProps} />}
      </Grid>
    );
  }
}

interface IPaymentDialogProps {
  open: boolean;
  submitting: boolean;
  lastTransaction?: models.Transaction;
  billingEntities: models.IBillingEntity[];
  close: () => void;
  parseCurrency: (currency?: string) => number;
  makePayment: (paymentMethodId?: number) => void;
}
const PaymentDialog = ({
  open,
  submitting,
  lastTransaction,
  billingEntities,
  close,
  parseCurrency,
  makePayment,
}: IPaymentDialogProps) => {
  const [selectedEntity, setSelectedEntity] = useState<models.IBillingEntity | undefined>(
    undefined,
  );
  const [confirm, setConfirm] = useState(false);
  const [activeEntities, setActiveEntities] = useState<models.IBillingEntity[] | undefined>();
  const { userStore } = useStores();
  const isAdmin = userStore.user!.isAdmin;

  useEffect(() => {
    const activeEntities = billingEntities.filter(
      (entity: models.IBillingEntity) => !isExpired(entity.paymentMethod),
    );
    setActiveEntities(activeEntities);
  }, [billingEntities]);

  const amountToCharge = lastTransaction && Math.abs(parseCurrency(lastTransaction.balance!));
  const info = `${isAdmin ? `User` : 'Selected'} card will be charged for $${
    lastTransaction && amountToCharge
  }`;

  function icon(brand: string | undefined) {
    brand = brand || 'Other';
    return (
      <Box display="flex" alignItems="center" justifyContent="center" style={{ fontSize: '30px' }}>
        <CreditCardIcon style={{ width: 45, height: 30 }} brand={brand.toLowerCase()} />
      </Box>
    );
  }

  function secondaryText(isExpired: boolean, brand: string, paymentMethod: models.PaymentMethod) {
    const { lastFour, validThru } = paymentMethod;
    const primaryText = `${brand} ${lastFour}`.trim();
    const secondaryText = `${isExpired ? 'Expired' : 'Expires'} on ${
      validThru ? validThru : 'N/A'
    }`;
    return (
      <Box display={'flex'} justifyContent={'space-between'}>
        <Box component={'span'}>{primaryText}</Box>
        <Box component={'span'} style={{ opacity: '0.7', fontSize: '0.8rem' }}>
          {' '}
          {secondaryText}
        </Box>
      </Box>
    );
  }

  function isExpired(paymentMethod: models.PaymentMethod): boolean {
    const [month, year] = (paymentMethod.validThru || '').split('/');
    let isExpired = false;
    if (month && year) {
      const expirationDate = new Date();
      expirationDate.setMonth(parseInt(month) - 1);
      expirationDate.setFullYear(parseInt(year));

      isExpired = new Date() > expirationDate;
    }
    return isExpired;
  }

  function toggleConfirm() {
    setConfirm(!confirm);
  }

  function setSelectedEntityAndClearConfirm() {
    setSelectedEntity(undefined);
    setConfirm(false);
  }

  function paymentDisabled() {
    if (selectedEntity && !confirm) {
      return true;
    } else false;
  }

  function pay() {
    if (paymentDisabled()) return;
    if (!selectedEntity) {
      return makePayment();
    }
    makePayment(selectedEntity.paymentMethod.id);
  }

  return (
    <>
      <Dialog
        title={'Confirm payment'}
        disabled={paymentDisabled()}
        loading={submitting}
        open={open}
        content={
          isAdmin ? (
            <Typography variant="body2">{info}</Typography>
          ) : (
            <>
              <DP.List height="short">
                <ListItem key={'default'}>
                  <Radio
                    checked={!selectedEntity}
                    color="primary"
                    onClick={setSelectedEntityAndClearConfirm}
                  />
                  <ListItemIcon>{icon('default')}</ListItemIcon>
                  <ListItemText>{'Default card'}</ListItemText>
                </ListItem>
                <Divider />
                {activeEntities &&
                  activeEntities.map((entity: models.IBillingEntity) => {
                    const paymentMethod = entity.paymentMethod;
                    let { brand } = paymentMethod;
                    brand = !brand ? 'Other' : brand;
                    const expired = isExpired(paymentMethod);
                    return (
                      <React.Fragment key={`${paymentMethod.id}-Fragment`}>
                        <ListItem key={paymentMethod.id}>
                          <Radio
                            checked={_.isEqual(entity, selectedEntity)}
                            disabled={expired}
                            color="primary"
                            onClick={() => setSelectedEntity(entity)}
                          />
                          <ListItemIcon>{icon(brand)}</ListItemIcon>
                          <ListItemText>
                            {secondaryText(expired, brand, paymentMethod)}
                          </ListItemText>
                        </ListItem>
                        <Divider />
                      </React.Fragment>
                    );
                  })}
              </DP.List>
              <Box style={{ visibility: selectedEntity ? 'visible' : 'hidden' }}>
                <Checkbox checked={confirm} onClick={toggleConfirm} color="primary" /> Whole
                negative balance for all licences will be charged to this credit card.
              </Box>
            </>
          )
        }
        confirmActionName={'Confirm'}
        onConfirm={pay}
        onCancel={close}
      />
    </>
  );
};

export default withStyles(styles)(Billing);
