import { mobileSize } from 'components/MobileView/useMobileView';
import { rootStore } from 'containers/App/App';
import { useEffect, useState } from 'react';

interface IUseSingleColumnContent {
  ref: React.MutableRefObject<HTMLElement | null>;
  minWidth?: number;
}

const useSingleColumnContent = (props?: IUseSingleColumnContent) => {
  const [elementRef, setElementRef] = useState(props?.ref || null);
  const [singleColumnContentThreshold, setSingleColumnThreshold] = useState(false);
  const [minWidth, setMinWidth] = useState(mobileSize);

  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      for (let entry of entries) {
        const width = entry.contentRect.width;
        if (width <= minWidth) {
          handleSetColumnThreshold(true);
        }
        if (width > minWidth) {
          handleSetColumnThreshold(false);
        }
      }
    });

    if (elementRef?.current) {
      resizeObserver.observe(elementRef.current);
    }

    return () => {
      resizeObserver.disconnect();
    };
  }, [elementRef]);

  const handleSetColumnThreshold = (val: boolean) => {
    setSingleColumnThreshold(val);
    rootStore?.uiStore?.setSingleColumnPageContent(val);
  };

  const initializeResize = (props: IUseSingleColumnContent) => {
    setElementRef(props.ref);
    setMinWidth(props?.minWidth || mobileSize);
  };

  return { singleColumnContentThreshold, initializeResize };
};

export default useSingleColumnContent;
