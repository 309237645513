import React from 'react';
import { observer } from 'mobx-react';
import { observable, action, computed, makeObservable, IReactionDisposer, autorun } from 'mobx';

import { inject, WithStationsStore, WithUserStore } from 'stores';
import SettingsStore, { SettingsObject } from './SettingsStore';

import { Account, TipSettings } from 'models';

import {
  DialogContent,
  DialogTitle,
  Drawer,
  List,
  ListItem,
  Icon,
  IconButton,
  Divider,
  Box,
  Typography,
  ListItemText,
  FormControlLabel,
} from '@material-ui/core';

import { WithStyles, withStyles } from '@material-ui/core/styles';

import SettingSelect from './SettingSelect';
import ProposalStrategy from './ProposalStrategy';

import styles from './styles';
import { Close } from 'mdi-material-ui';
import TippyGoSettingsDrawer from './TippyGoSettingsDrawer';
import TipOptionLabels from './TipOptionLabels';
import { Switch } from 'components/Switch/Switch';
import { ACL } from 'types';
import { SplitTipSetting } from './SplitTipSetting';

interface TipSettingsDrawerProps
  extends WithStyles<typeof styles>,
    WithUserStore,
    WithStationsStore {
  entity: 'account' | 'location' | 'station';
  id?: number;
  account?: Account;
  isOpen: boolean;
  onClose: () => void;
  isDormant?: boolean;
  setDormant?: (isDormant: boolean) => void;
  /** If drawer can be called with multiple different settings on single page,
   * this property is used in order to reinitialize settings store when entity
   * and id change */
  reinitializeOnOpen?: boolean;
}

/**
 * Sidebar drawer for displaying and editing all tip related settings for either account
 * or location. For easier setting state management we instantiate and use SettingsStore
 * that we also pass down to all sub-components.
 *
 * @param entity Used to instantiate SettingsStore that calls correct API endpoints
 * @param id Id of the entity, also used by SettingsStore for API calls
 * @param onClose Event handler that gets called on close event
 * @param isOpen State of the component
 */
@inject('userStore')
@observer
class TipSettingsDrawer extends React.Component<TipSettingsDrawerProps> {
  constructor(props: TipSettingsDrawerProps) {
    super(props);
    makeObservable(this);
    const { entity, id } = this.props;
    this.settingsStore = new SettingsStore(entity, id!);

    this.disposers.push(
      autorun(() => {
        if (this.props.isOpen) {
          this.reinitialize();
        }
      }),
    );
  }

  private disposers: IReactionDisposer[] = [];

  @computed public get isAdmin() {
    return this.props.userStore && this.props.userStore.isAdmin;
  }

  componentDidMount() {
    this.settingsStore.init();
  }

  /** Settings store. Gets passed down to sub-components */
  @observable public settingsStore: SettingsStore;

  /** Settings for currently selected account or location */
  @computed public get settings(): TipSettings | undefined {
    return this.settingsStore.generalSettings;
  }

  /** Track users location inside settings modal. If 'undefined' then current location is main settings menu */
  @observable public currentSetting?: keyof TipSettings;

  /** Handle drawer navigation and update current location */
  @action.bound private handleSettingChange = (setting?: keyof TipSettings) => () => {
    this.currentSetting = setting;
  };

  @action.bound private closeModal = () => {
    this.currentSetting = undefined;
    this.props.onClose();
  };

  @action.bound private reinitialize = async () => {
    let { isOpen, reinitializeOnOpen, entity, id } = this.props;
    const { shouldReinitialize } = this.settingsStore;

    if (isOpen && reinitializeOnOpen) {
      const newSettingsObject: SettingsObject = { kind: entity, id: id! };
      if (shouldReinitialize(newSettingsObject)) {
        this.settingsStore = new SettingsStore(entity, id!);
        this.settingsStore.init();
      }
      return true;
    }
  };

  @observable initializing = false;

  @computed public get isDisabledProcessor() {
    return !this.props?.userStore?.isAdmin;
  }

  render() {
    const { root, settingListItemKey, settingListItemValue, settingListItemIcon, label } =
      this.props.classes;
    const { isOpen, entity } = this.props;
    const { initializing } = this.settingsStore;
    const renderDormant = this.isAdmin && entity !== 'station';
    const [renderProcessor, renderIndustry, renderProposalStrategy] = Array(3).fill(
      entity !== 'station',
    );

    return (
      <Drawer
        anchor="right"
        className={root}
        open={isOpen && !initializing}
        PaperProps={{
          style: {
            width: '400px',
          },
        }}>
        {/* IF (currentSetting is undefined) THEN {render main settings menu} */}
        {!this.currentSetting && (
          <>
            <DialogTitle>
              <Box display="flex" alignItems="center" justifyContent="space-between">
                <Typography variant="h5" style={{ fontSize: 28, fontWeight: 400 }} display="inline">
                  Settings
                </Typography>
                <IconButton onClick={this.closeModal}>
                  <Close color="inherit" />
                </IconButton>
              </Box>
            </DialogTitle>
            <Divider />
            <DialogContent>
              {this.settings && (
                <List>
                  <ListItem style={{ padding: 0, marginLeft: 0 }}>
                    {renderDormant && (
                      <FormControlLabel
                        className={label}
                        control={<Switch color="primary" />}
                        labelPlacement="bottom"
                        label="Dormant"
                        checked={this.props.isDormant}
                        onChange={(e: any) => {
                          if (this.props.setDormant) {
                            this.props.setDormant(e.target.checked);
                          }
                        }}
                      />
                    )}
                  </ListItem>
                  {renderProposalStrategy && (
                    <>
                      <ListItem
                        button
                        onClick={this.handleSettingChange('strategy')}
                        className={this.props.classes.settingList}>
                        <ListItemText
                          className={settingListItemKey}
                          primary={`proposal strategy`}
                        />
                        <Typography className={settingListItemValue}>
                          {this.settings.strategy}
                        </Typography>
                        <Icon className={settingListItemIcon}>keyboard_arrow_right</Icon>
                      </ListItem>
                    </>
                  )}
                  <ListItem
                    button
                    onClick={this.handleSettingChange('mode')}
                    className={this.props.classes.settingList}>
                    <ListItemText className={settingListItemKey} primary={`kiosk mode`} />
                    <Typography className={settingListItemValue}>{this.settings.mode}</Typography>
                    <Icon className={settingListItemIcon}>keyboard_arrow_right</Icon>
                  </ListItem>
                  {renderProcessor && (
                    <>
                      <ListItem
                        disabled={this.isDisabledProcessor}
                        button
                        onClick={this.handleSettingChange('processor')}
                        className={this.props.classes.settingList}>
                        <ListItemText className={settingListItemKey} primary={`processor`} />
                        <Typography className={settingListItemValue}>
                          {this.settings.processor}
                        </Typography>
                        {!this.isDisabledProcessor && (
                          <Icon className={settingListItemIcon}>keyboard_arrow_right</Icon>
                        )}
                      </ListItem>
                    </>
                  )}
                  {this.props.userStore!.isAdmin && (
                    <>
                      <ListItem
                        button
                        onClick={this.handleSettingChange('transparency')}
                        className={this.props.classes.settingList}>
                        <ListItemText className={settingListItemKey} primary={`transparency`} />
                        <Typography className={settingListItemValue}>
                          {this.settings.transparency}
                        </Typography>
                        <Icon className={settingListItemIcon}>keyboard_arrow_right</Icon>
                      </ListItem>
                      {/* {renderIndustry && (
                        <>
                          {' '}
                          <ListItem
                            button
                            onClick={this.handleSettingChange('industry')}
                            className={this.props.classes.settingList}>
                            <ListItemText className={settingListItemKey} primary={`industry`} />
                            <Typography className={settingListItemValue}>
                              {this.settings.industry}
                            </Typography>
                            <Icon className={settingListItemIcon}>keyboard_arrow_right</Icon>
                          </ListItem>
                        </>
                      )} */}
                    </>
                  )}
                  {this.props.userStore!.isAdmin &&
                    this.props.userStore!.hasPermission(ACL.MANAGE_TIPPY_GO) && (
                      <ListItem
                        button
                        onClick={this.handleSettingChange('tippyGo')}
                        className={this.props.classes.settingList}>
                        <ListItemText className={settingListItemKey} primary={`tippyGo`} />
                        <Icon className={settingListItemIcon}>keyboard_arrow_right</Icon>
                      </ListItem>
                    )}
                  {(this.props.userStore!.isAdmin || this.props.userStore!.isOwner) && (
                    <ListItem
                      button
                      onClick={this.handleSettingChange('tipOptionLabels')}
                      className={this.props.classes.settingList}>
                      <ListItemText className={settingListItemKey} primary={`Tip Option Labels`} />
                      <Icon className={settingListItemIcon}>keyboard_arrow_right</Icon>
                    </ListItem>
                  )}
                  {(this.props.userStore!.isAdmin || this.props.userStore!.isOwner) && (
                    <SplitTipSetting
                      settingsStore={this.settingsStore}
                      isAdmin={this.props.userStore!.isAdmin}
                    />
                  )}
                </List>
              )}
            </DialogContent>
          </>
        )}
        {/* IF (currentSettings is not strategy) THEN {render list of options for that setting} */}
        {this.currentSetting &&
          this.currentSetting !== 'strategy' &&
          this.currentSetting !== 'tippyGo' &&
          this.currentSetting !== 'tipOptionLabels' && (
            <SettingSelect
              setting={this.currentSetting}
              settingsStore={this.settingsStore}
              goBack={this.handleSettingChange(undefined)}
            />
          )}
        {/* IF (currentSettings is strategy) THEN {render strategy component} */}
        {this.settingsStore && this.currentSetting === 'strategy' && (
          <ProposalStrategy
            goBack={this.handleSettingChange(undefined)}
            settingsStore={this.settingsStore}
          />
        )}
        {this.settingsStore && this.currentSetting === 'tippyGo' && (
          <TippyGoSettingsDrawer
            goBack={this.handleSettingChange(undefined)}
            settingsStore={this.settingsStore}
          />
        )}
        {this.currentSetting === 'tipOptionLabels' && (
          <TipOptionLabels
            goBack={this.handleSettingChange(undefined)}
            settingsStore={this.settingsStore}
          />
        )}
      </Drawer>
    );
  }
}

export default withStyles(styles)(TipSettingsDrawer);
