import DP from 'components/DashPanel';
import { Box, Link, Tooltip, Typography, useTheme } from '@mui/material';
import { observer } from 'mobx-react';
import React from 'react';
import { paths } from 'routes';
import { Link as RouterLink } from 'react-router-dom';
import { Circle } from 'mdi-material-ui';
import listItemStyles from './styles';
import * as models from 'models';
import moment from 'moment';
import { ELicenseBillingGeneralDialog } from '../models';
import { EDateFormat } from 'utils/helper';

const LicenseItem = observer(
  ({
    isAdmin,
    license,
    canExtendLicenses,
    onLicenseDialogAction,
  }: {
    isAdmin: boolean;
    license: models.License;
    canExtendLicenses: boolean;
    onLicenseDialogAction: (license: models.License, type: ELicenseBillingGeneralDialog) => void;
  }) => {
    const theme = useTheme();

    const {
      textSecondary,
      textSecondaryHighlight,
      textTertirayHighlight,
      textFirst,
      textTertiary,
    } = listItemStyles(theme);

    const formattedDate = moment(license.expiresAt).format(EDateFormat.DEFAULT);

    const hasPaymentMethod = !!license?.paymentMethod;

    const noPaymentMethodAssignedText = license.isBillable
      ? 'No payment method assigned'
      : 'Free product';

    const paymentMethodText = hasPaymentMethod
      ? `${license.paymentMethod!.brand} ${license.paymentMethod!.lastFour}`
      : noPaymentMethodAssignedText;

    const billingEntity = license.billingEntity;
    const billingGroupName = billingEntity?.name || billingEntity?.code || '';
    const hasBillingGroupName = !!billingEntity?.name;
    const billingGroupTooltip = `Billing group ${hasBillingGroupName ? 'name' : 'code'}`;

    const tooltip =
      hasPaymentMethod || !license.isBillable
        ? ''
        : 'Please attach this license to a billing group: select "Billing Groups" tab and attach the license to one of the billing groups';

    const productText = license?.product?.name || 'Product not assigned';

    const secondaryText = (
      <>
        {productText} |
        <Tooltip title={tooltip}>
          <Typography
            sx={{ ...textSecondary, ...(hasPaymentMethod ? {} : textSecondaryHighlight) }}
            component={'span'}>
            &nbsp;{paymentMethodText}
          </Typography>
        </Tooltip>
        {!!billingGroupName && (
          <>
            &nbsp;|&nbsp;
            <Tooltip title={billingGroupTooltip}>
              <Typography sx={textSecondary} component={'span'}>
                {billingGroupName}
              </Typography>
            </Tooltip>
          </>
        )}
      </>
    );

    const isLicenseExpired = new Date() > new Date(license.expiresAt);

    const expiryText = isLicenseExpired ? 'Expired on' : 'Expires on';

    const tertiaryText = `License ${expiryText} ${formattedDate}`;

    const isActiveLicense = license.status === 'active';

    const canLicenseBeActivated = !isActiveLicense;
    // TODO: Remove if no complaints for new canLicenseBeActivated rule
    // const canLicenseBeActivated =  !isLicenseExpired && hasPaymentMethod && !isActiveLicense;

    const menu: Array<{ label: string | React.ReactNode; onClick: VoidFunction }> = [
      {
        label: 'Change product',
        onClick: () => onLicenseDialogAction(license, ELicenseBillingGeneralDialog.CHANGE_PRODUCT),
      },
    ];

    if (isActiveLicense) {
      if (canExtendLicenses) {
        menu.push({
          label: 'Extend',
          onClick: () =>
            onLicenseDialogAction(license, ELicenseBillingGeneralDialog.EXTEND_LICENSE),
        });
      }
      menu.push({
        label: (
          <Typography variant="body1" color="error">
            Cancel
          </Typography>
        ),
        onClick: () => onLicenseDialogAction(license, ELicenseBillingGeneralDialog.CANCEL_LICENSE),
      });
    }

    if (canLicenseBeActivated) {
      menu.push({
        label: 'Activate',
        onClick: () =>
          onLicenseDialogAction(license, ELicenseBillingGeneralDialog.ACTIVATE_LICENSE),
      });
    }

    const { locationName, displayName } = license.location;

    return (
      <DP.ListItem
        key={license.id}
        icon={
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            style={{ fontSize: '21px' }}>
            <Circle fontSize="inherit" color={license.status === 'active' ? 'primary' : 'error'} />
          </Box>
        }
        primary={
          <Link
            component={RouterLink}
            to={paths.locationDetails(license.location.id!)}
            color="textPrimary"
            underline={'none'}
            sx={textFirst}>
            {locationName}&nbsp;
            {displayName && (
              <Tooltip title={`Location's display name`}>
                <Typography component={'span'} variant="body2">
                  | {displayName}
                </Typography>
              </Tooltip>
            )}
          </Link>
        }
        secondary={
          <React.Fragment>
            <Typography sx={textSecondary}>{secondaryText}</Typography>
            <Typography
              sx={{ ...textTertiary, ...(isLicenseExpired ? textTertirayHighlight : {}) }}>
              {tertiaryText}
            </Typography>
          </React.Fragment>
        }
        menu={isAdmin && menu.length ? menu : undefined}
      />
    );
  },
);

export default LicenseItem;
