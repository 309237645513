import { action, computed, makeObservable, observable } from 'mobx';
import BaseStore from './BaseStore';
import RootStore from './RootStore';
import type IMFAResult from 'models/Mfa';
import type { IMFARequest } from 'models/Mfa';
import { IMFAFactorRequest } from 'models/Mfa';
import { mergeNewMfaObject } from 'components/MfaComponent/helpers';

export default class MfaStore extends BaseStore {
  constructor(props: RootStore) {
    super(props);
    makeObservable(this);
  }

  // MFA result we get in error body of request
  @observable public MfaResult?: IMFAResult;

  // MFA data we add to the body of failed request
  @observable public mfaBody?: IMFARequest;

  // Request config so we can repeat failed request with MFA data
  @observable public config?: any;

  // Login config so we can repeat login request to resend MFA codes
  @observable public loginConfig?: any;

  // This is set to true when mfa request is being verified
  @observable public verifyingMfaData = false;

  // This is set to true when we request a new mfa code
  @observable public sendingMfaCode = false;

  @computed public get factors() {
    return this.MfaResult?.factors;
  }

  @computed public get showMfaStep() {
    return !!this.MfaResult;
  }

  @computed public get mfaBodyValid() {
    return !!this.mfaBody;
  }

  @computed public get loading() {
    return this.verifyingMfaData || this.sendingMfaCode;
  }

  @action.bound setConfig(config: any) {
    this.config = config;
    if (!this.loginConfig) {
      this.loginConfig = config;
    }
  }

  @action.bound setMfaResult(result: IMFAResult) {
    const newMfaData = mergeNewMfaObject(result);
    this.MfaResult = newMfaData;
  }

  @action.bound resetMfaResult() {
    this.MfaResult = undefined;
  }

  @action.bound setMfaBody(factors: IMFAFactorRequest[]) {
    this.mfaBody = { type: this.MfaResult!.type, factors };
  }

  @action.bound resetMfaBody() {
    this.mfaBody = undefined;
  }

  @action.bound resetConfig() {
    this.config = undefined;
    this.loginConfig = undefined;
  }

  @action.bound resetStore() {
    this.resetMfaResult();
    this.resetMfaBody();
    this.resetConfig();
  }

  @action.bound setVerifyingMfaData() {
    this.verifyingMfaData = true;
  }

  @action.bound resetVerifyingMfaData() {
    this.verifyingMfaData = false;
  }

  @action.bound setSendingMfaCode() {
    this.sendingMfaCode = true;
  }

  @action.bound resetSendingMfaCode() {
    this.sendingMfaCode = false;
  }

  public init() {}
}

export interface WithMfaStore {
  mfaStore?: MfaStore;
}
