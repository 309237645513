import InvoiceReview from 'components/InvoiceReview/InvoiceReview';
import { CompletedCarts } from 'models';
import { useEffect, useState } from 'react';
import Api, { ApiResponse, getErrorMsg } from 'api';
import { useParams } from 'react-router-dom';
import { AxiosResponse } from 'axios';

const InvoiceReviewPage = () => {
  const { invoiceId } = useParams<{ invoiceId: string }>();
  const [invoice, setInvoice] = useState<CompletedCarts>();
  const [error, setError] = useState<string>();
  const [isLoading, setIsLoading] = useState<boolean>();

  useEffect(() => {
    async function fetchData() {
      setIsLoading(true);
      try {
        const { data }: AxiosResponse<ApiResponse<CompletedCarts>> =
          await Api.billing.getCompletedCartsById(invoiceId);
        setInvoice(data.data);
      } catch (error) {
        setError(getErrorMsg(error));
      } finally {
        setIsLoading(false);
      }
    }

    fetchData();
  }, [invoiceId]);

  return <InvoiceReview invoice={invoice} error={error} isLoading={isLoading} />;
};

export default InvoiceReviewPage;
