import { DialogTitle, Typography, DialogContent, DialogActions, Box } from '@material-ui/core';
import LoadingSpinner from 'components/LoadingSpinner';
import { WithoutInvoiceForm } from './WithoutInvoiceForm';
import { useCallback, useState } from 'react';
import { CorrectionTypeSelector } from '../WithInvoice/components/CorrectionTypeSelector';
import Api, { getErrorMsg } from 'api';
import { useStores } from 'containers/App/App';
import { IBillingEntity, ECorrectionType } from 'models';
import Button from 'components/Button/Dialog/Button';

interface WithoutInvoiceProps {
  accountId: number;
  billingEntityId?: number;
  paymentMethods: IBillingEntity[];
  dialogClose: () => void;
  refreshData: () => void;
  setCorrectionType: (type: ECorrectionType) => void;
}

export const WithoutInvoice = ({
  accountId,
  billingEntityId,
  paymentMethods,
  dialogClose,
  refreshData,
  setCorrectionType,
}: WithoutInvoiceProps) => {
  const { toastStore } = useStores();

  const [formValues, setFormValues] =
    useState<{ amount: number; reason: string; billingEntityId: number }>();
  const [formIsValid, setFormIsValid] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = useCallback(async () => {
    if (!formValues) {
      return;
    }

    try {
      setIsLoading(true);
      const { amount, reason, billingEntityId } = formValues;
      await Api.billing.createLedgerCorrection(accountId, billingEntityId, amount, reason);

      toastStore.push({
        type: 'success',
        message: `Correction for ${amount} dollars was successfully applied`,
      });
    } catch (e: unknown) {
      toastStore.error(getErrorMsg(e));
    } finally {
      // After success we need some time for DB to be update and to trigger refresh data endpoints
      setTimeout(() => {
        dialogClose();
        setIsLoading(false);
        refreshData();
      }, 1000);
    }
  }, [accountId, dialogClose, formValues, refreshData, toastStore]);

  return (
    <>
      <DialogTitle>
        <Box display="flex" flexDirection="row" justifyContent="space-between">
          <Typography style={{ fontSize: 28, fontWeight: 400 }}>Create Correction</Typography>
          {isLoading && <LoadingSpinner size={24} />}
        </Box>
      </DialogTitle>

      <DialogContent>
        <CorrectionTypeSelector
          correctionType={ECorrectionType.WITHOUT_INVOICE}
          setCorrectionType={setCorrectionType}
        />

        <WithoutInvoiceForm
          validityChanged={setFormIsValid}
          billingEntityId={billingEntityId}
          paymentMethods={paymentMethods}
          getFormValues={(amount: number, reason: string, billingEntityId: number) =>
            setFormValues({ amount, reason, billingEntityId })
          }
        />
      </DialogContent>

      <DialogActions className="dialogActions">
        <Button color="primary" onClick={dialogClose}>
          Cancel
        </Button>
        <Button variant="contained" color="primary" disabled={!formIsValid} onClick={handleSubmit}>
          Create
        </Button>
      </DialogActions>
    </>
  );
};
