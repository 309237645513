import { Theme, createStyles } from '@material-ui/core';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default (theme: Theme) =>
  createStyles({
    root: {},
    drawer: {
      height: '100%',
      width: '100%',
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
    },
    drawerInner: {
      padding: theme.spacing(3),
      width: theme.spacing(50),
      flex: 1,
      overflow: 'auto',
    },
    actions: {
      padding: theme.spacing(3),
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-end',
    },
    skeleton: {
      width: 100,
      height: 24,
      margin: 0,
    },
    label: {
      marginLeft: 5,
    },
    value: {
      fontSize: 18,
    },
    circleDot: {
      marginTop: 5,
      marginRight: 3,
    },
    marginLeft: {
      marginLeft: 3,
    },
  });
