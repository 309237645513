import { Typography, Box, MenuItem } from '@mui/material';
import Dialog from 'components/Dialog/Dialog';
import OutlinedInput from 'components/Input/OutlinedInput/OutlinedInput';
import * as models from 'models';
import React, { useState } from 'react';
import Api, { getErrorMsg } from 'api';
import { useStores } from 'containers/App/App';
import { ELicenseBillingGeneralDialog, ILicenseBillingGeneralDialogProps } from '../models';

interface ICancelLicenseDialogProps extends ILicenseBillingGeneralDialogProps {
  license: models.License | null;
  onCancelLicense: VoidFunction;
}

const CancelLicenseDialog = ({
  open,
  title,
  type,
  license,
  TransitionProps,
  onCancel,
  onCancelLicense,
}: ICancelLicenseDialogProps) => {
  const { toastStore } = useStores();
  const [reason, setReason] = useState('');
  const [customReason, setCustomReason] = useState('');
  const [cancellingLicense, setCancellingLicense] = useState(false);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setReason(e.target.value);
  };

  const handleChangeCustomReason = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCustomReason(e.target.value);
  };

  const handleClose = () => {
    onCancel();
  };

  const handleCancelLicense = async () => {
    setCancellingLicense(true);
    try {
      await Api.billing.cancelLicense(license!.id, reason);
      onCancelLicense();
      toastStore!.success('License canceled successfully');
      handleClose();
    } catch (err: any) {
      toastStore!.error(getErrorMsg(err));
    } finally {
      setCancellingLicense(false);
    }
  };

  const isOtherReason = reason === models.CancellationReason.OTHER;

  const isCustomReasonValid = customReason.length > 3 && customReason.length <= 150;

  const disabled = isOtherReason ? !isCustomReasonValid : !reason;

  const _open = open && type === ELicenseBillingGeneralDialog.CANCEL_LICENSE;

  return (
    <Dialog
      title={title}
      open={_open}
      loading={cancellingLicense}
      disabled={disabled}
      content={
        <Box>
          <Typography variant="body2">What is the reason for canceling?</Typography>
          <Box mt={2}>
            <OutlinedInput
              label={'Reason'}
              disabled={cancellingLicense}
              select
              value={reason}
              onChange={handleChange}
              fullWidth>
              {Object.values(models.CancellationReason).map((reason) => (
                <MenuItem key={reason} value={reason}>
                  {reason}
                </MenuItem>
              ))}
            </OutlinedInput>
          </Box>
          {isOtherReason && (
            <Box mt={2}>
              <OutlinedInput
                value={customReason}
                disabled={cancellingLicense}
                label="Custom Reason"
                onChange={handleChangeCustomReason}
                multiline
                maxRows={2}
                inputProps={{
                  maxLength: 150,
                }}
                fullWidth
              />
            </Box>
          )}
        </Box>
      }
      onConfirm={handleCancelLicense}
      onCancel={handleClose}
      onClose={handleClose}
      TransitionProps={{
        onExited: (node) => {
          TransitionProps?.onExited && TransitionProps.onExited(node);
          setReason('');
          setCustomReason('');
        },
      }}
    />
  );
};

export default CancelLicenseDialog;
