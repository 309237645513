import { Box } from '@material-ui/core';
import { inject, observer } from 'mobx-react';
import { StationsPanel } from './StationsPanel/StationsPanel';
import PoolsPanel from 'components/PoolsPanel';
import { StationDrawer } from '../../../components/StationDrawer/StationDrawer';
import { usePoolsDashPanel } from './usePoolsDashPanel';
import { WithToastStore } from 'stores';
import PoolsDrawer from 'components/PoolsPanel/PoolsDrawer';
import theme from 'containers/App/theme';

type LocationQrCodesProps = {
  locationId?: number;
  accountId?: number;
} & WithToastStore;

export const LocationQrCodes = inject(
  'notificationStore',
  'toastStore',
)(
  observer((props: LocationQrCodesProps) => {
    const { locationId, accountId } = props;
    const [
      isPoolsEnabled,
      pool,
      pools,
      poolsOpen,
      poolsDrawerType,
      industry,
      openStationQrCodeDrawer,
      setOpenStationQrCodeDrawer,
      updatePool,
      createPool,
      closePoolsModal,
      openPoolsDrawer,
      getPools,
    ] = usePoolsDashPanel(accountId, locationId);

    return (
      <>
        <Box display={'flex'} flexWrap={'wrap'} style={{ gap: theme.spacing(3) }}>
          {isPoolsEnabled && (
            <Box flexGrow={1}>
              <PoolsPanel openDrawer={openPoolsDrawer} fetchPools={getPools} pools={pools} />
            </Box>
          )}
          <Box flexGrow={1}>
            <StationsPanel />
          </Box>
        </Box>
        <StationDrawer accountId={props.accountId} locationId={props.locationId} />
        {poolsDrawerType && (
          <PoolsDrawer
            industry={industry}
            isOpen={poolsOpen}
            onClose={closePoolsModal}
            createPool={createPool}
            updatePool={updatePool}
            type={poolsDrawerType}
            pool={pool}
          />
        )}
      </>
    );
  }),
);
