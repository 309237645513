import { makeStyles, createStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    icon: {
      width: 24,
      height: 24,
      color: theme.palette.text.primary,
    },
    badge: {
      '& .MuiBadge-colorPrimary': {
        color: theme.palette.text.primary,
      },
    },
  }),
);

export default useStyles;
