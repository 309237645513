import { getErrorMsg } from 'api';
import { AxiosResponse } from 'axios';
import { IDialogProps } from 'components/Dialog/Dialog';
import { OutlinedInputProps } from 'components/Input/OutlinedInput/OutlinedInput';
import { rootStore } from 'containers/App/App';
import * as models from 'models';
import { useMemo, useState } from 'react';

export const MAX_TRANSFER_REQUEST_REASON_LENGTH = 512;

interface IUseTransferDialogsProps {
  action: models.ETransferRequestAction | undefined;
  onConfirm: () => void;
  approveRequest: (requestId: number) => Promise<AxiosResponse<models.ITransferRequest, any>>;
  rejectRequest: (
    requestId: number,
    body: {
      rejectionReason: string;
    },
  ) => Promise<AxiosResponse<models.ITransferRequest, any>>;
}

const useTransferDialogs = ({
  action,
  onConfirm,
  approveRequest,
  rejectRequest,
}: IUseTransferDialogsProps) => {
  const [reason, setReason] = useState('');

  const [focused, setFocused] = useState(false);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setReason(e.target.value);
  };

  const [loading, setLoading] = useState(false);

  const [openDialog, setOpenDialog] = useState(false);

  const [transferTipRequest, setTransferTipRequest] = useState<models.ITransferRequest | undefined>(
    undefined,
  );

  const { toastStore } = rootStore;

  const isApproveAction = action === models.ETransferRequestAction.APPROVE;

  const resetReason = () => {
    setReason('');
    setFocused(false);
  };

  const closeDialog = () => {
    setTransferTipRequest(undefined);
    if (!isApproveAction) resetReason();
  };

  const confirmDialog = () => {
    confirmAction();
  };

  const confirmAction = async () => {
    try {
      setLoading(true);
      if (isApproveAction) {
        await approveRequest(transferTipRequest!.id);
      } else {
        await rejectRequest(transferTipRequest!.id, { rejectionReason: reason });
      }
      onConfirm();
      toastStore.success(`Request ${isApproveAction ? 'approved' : 'rejected'} successfully`);
    } catch (error) {
      toastStore.error(getErrorMsg(error));
    } finally {
      closeDialog();
      setLoading(false);
    }
  };

  const getRejectionReasonError = () => {
    if (!focused) return '';
    if (!reason || reason.length < 5) return 'Reason field is required';
    if (reason.length > MAX_TRANSFER_REQUEST_REASON_LENGTH) return 'Reason too long';
    return '';
  };

  const actionDialogProps: Omit<IDialogProps, 'content'> = useMemo(() => {
    let title = 'Approve Tip Transfer';
    let disabled = false;
    if (action === models.ETransferRequestAction.REJECT) {
      title = 'Reject Tip Transfer';
      disabled = !!getRejectionReasonError() || !reason;
    }

    return {
      open: !!transferTipRequest,
      title,
      loading,
      disabled,
      onConfirm: confirmDialog,
      onCancel: closeDialog,
      TransitionProps: {
        onExited: () => (action = undefined),
      },
    };
  }, [openDialog, reason, loading, getRejectionReasonError]);

  const rejectionReasonProps: Partial<OutlinedInputProps> = {
    value: reason,
    error: !!getRejectionReasonError(),
    helperText: getRejectionReasonError(),
    onChange: handleChange,
    onFocus: () => setFocused(true),
    inputProps: { maxLength: MAX_TRANSFER_REQUEST_REASON_LENGTH },
  };

  return {
    actionDialogProps,
    rejectionReasonProps,
    openDialog: setOpenDialog,
    setTransferTipRequest,
  };
};

export default useTransferDialogs;
