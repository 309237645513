import { Theme, createStyles, makeStyles } from '@material-ui/core';
//import { fade } from '@material-ui/core/styles/colorManipulator';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const styles = (theme: Theme) =>
  createStyles({
    root: {
      position: 'relative',
    },
    loadingContainer: {
      minHeight: theme.spacing(48),
    },
    withPointer: {
      cursor: 'pointer',
    },
  });

export default styles;

export const useStyles = makeStyles(styles);
