import React from 'react';
import { Redirect, RouteComponentProps } from 'react-router-dom';
import { inject, WithUserStore } from 'stores';
import { observer } from 'mobx-react';
import qs from 'qs';

type WebViewRedirectProps = RouteComponentProps & WithUserStore;

@inject('userStore')
@observer
class WebViewRedirect extends React.Component<WebViewRedirectProps> {
  componentDidMount() {
    this.props.userStore!.setWebView();
  }
  render() {
    const query = qs.parse(this.props.location.search, { ignoreQueryPrefix: true });
    const redirecTo: any = query.to;
    if (this.props.userStore!.loggedIn) {
      return <Redirect to={redirecTo} />;
    } else {
      return (
        <div
          id="web-view-container"
          style={{
            position: 'absolute',
            top: 0,
            right: 0,
            left: 0,
            bottom: 0,
            backgroundColor: 'white',
          }}></div>
      );
    }
  }
}

export default WebViewRedirect;
