import { Theme, createStyles, alpha, makeStyles } from '@material-ui/core';
import { mobileSize } from 'components/MobileView/useMobileView';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default (theme: Theme) =>
  createStyles({
    root: {
      position: 'relative',
    },
    minSize: {
      minWidth: theme.spacing(20),
    },
    inactiveMinSize: {
      minWidth: theme.spacing(20),
    },
    activeMinSize: {
      minWidth: theme.spacing(20),
    },
    dateDisplay: {
      position: 'relative',
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
    },
    icon: {
      color: theme.palette.text.primary,
      fontSize: 18,
    },
    typography: {
      width: 'max-content',
      paddingRight: theme.spacing(1),
      paddingLeft: theme.spacing(1),
      display: 'inlince-block',
      verticalAlign: 'middle',
    },
    dropdownLeft: {
      right: '0px',
      top: '40px',
      position: 'absolute',
    },
    dropdownMobile: {
      top: 0,
      left: 0,
      width: '100vw',
      height: '100vh',
      position: 'fixed',
    },
    dropdownRight: {
      position: 'absolute',
      top: '40px',
    },
    dropdownBase: {
      minWidth: theme.spacing(30),
      marginRight: theme.spacing(2),
      zIndex: 1300,
      color: theme.palette.grey[600],
      filter: 'drop-shadow(0px 6px 24px #DDDDDD)',
      '& .MuiMenuItem-root': {
        fontSize: '16px',
        height: '26px',
        minHeight: '20px',
      },
    },
    datePicker: {
      width: '80px',
      fontSize: '8px',
      '& .MuiInputBase-input': {
        fontSize: '16px',
        height: '20px',
        minHeight: '20px',
      },
    },
    dateRange: {
      fontSize: 20,
      '& .PrivatePickersToolbar-root': {
        display: 'none',
      },
      '& .Mui-selected': {
        color: theme.palette.common.black,
        backgroundColor: `${theme.palette.primary.main} !important`,
      },
      '& .MuiDateRangePickerDay-rangeIntervalDayHighlight': {
        backgroundColor: alpha(theme.palette.primary.main, 0.2),
      },
      '& .MuiDateRangePickerDay-root': {
        '& .Mui-selected': {
          color: 'black',
        },
      },
    },
    addFilterChip: {
      backgroundColor: 'transparent',
      '& .MuiChip-icon': {
        color: 'grey',
      },
    },
    filterChip: {
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
    },
    selectLabel: {
      color: 'grey',
    },
    timerPicker: {
      display: 'flex',
      flexDirection: 'column',
    },
    timerPickerContainer: {
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      [theme.breakpoints.up(mobileSize)]: {
        paddingRight: 36.425,
      },
      [theme.breakpoints.down(mobileSize)]: {
        flexWrap: 'wrap',
        gap: theme.spacing(2),
      },
    },
    actionMenuContainer: {
      [theme.breakpoints.up(mobileSize)]: {
        display: 'flex',
        alignItems: 'center',
      },
    },
    buttonAction: {
      '&.MuiButton-root': {
        padding: '6px 13px',
        height: 'auto',
        borderRadius: '8px',
        textTransform: 'none',
      },
    },
    buttonText: {
      fontWeight: 500,
      fontSize: 14,
      textTransform: 'none',
    },
    containerAction: {
      display: 'flex',
      gap: '0.5rem',
      padding: '16px',
      justifyContent: 'center',
    },
    calendarIcon: {
      fontSize: 24,
      color: theme.palette.text.disabled,
    },
  });

export const useCustomPickerStyles = makeStyles((theme: Theme) =>
  createStyles({
    listItemStyles: {
      backgroundColor: 'transparent !important',
    },
    text: {
      color: theme.palette.primary.main,
    },
    checkIcon: {
      width: 14,
      color: theme.palette.primary.main,
    },
    chevronIcon: {
      width: 9,
      color: theme.palette.text.primary,
    },
    dateRange: {
      color: theme.palette.primary.main,
      fontSize: theme.typography.subtitle1.fontSize,
      marginRight: theme.spacing(1),
      display: 'inline',
    },
  }),
);
