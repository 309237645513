import Api, { ApiResponse, getErrorMsg } from 'api';
import { useStores } from 'containers/App/App';
import { useCallback, useEffect, useState } from 'react';
import { IBillingGeneralPanelProps } from '../BillingGeneralPanel';
import * as models from 'models';
import { AxiosResponse } from 'axios';
import { ILicenseBillingGeneralDialogProps } from '../models';
import useAddLicenseDialogForm from './useAddLicenseDialogForm';

type TUseAddLicenseDialogProps = Pick<IBillingGeneralPanelProps, 'accountId'> &
  Pick<ILicenseBillingGeneralDialogProps, 'onClose'> & {
    sendPurchaseOrder: boolean;
  };

const _useAddLicenseDialog = ({
  accountId,
  onClose,
  sendPurchaseOrder: canSendPurchaseOrder,
}: TUseAddLicenseDialogProps) => {
  const { toastStore } = useStores();
  const [cart, setCart] = useState<models.Cart>();
  const [numberOfKiosks, setNumberOfKiosks] = useState(1);
  const [loadingCart, setLoadingCart] = useState(false);
  const [deletingCartItem, setDeletingCartItem] = useState(false);
  const [sendingPurchaseOrder, setSendingPurchaseOrder] = useState(false);

  const { form, fields, addingLicenseToCart, addLicenseToCart, handleFormReset } =
    useAddLicenseDialogForm({
      setCart,
      accountId,
      numberOfKiosks,
    });

  const initCart = useCallback(async () => {
    try {
      setLoadingCart(true);
      const { data } = await Api.billing.getCart(accountId);
      setCart(data.data);
    } catch (e) {
      toastStore!.error(getErrorMsg(e));
    } finally {
      setLoadingCart(false);
    }
  }, [accountId, toastStore, setLoadingCart, setCart]);

  useEffect(() => {
    initCart();
  }, [initCart]);

  /** Deletes a cart item */
  const deleteCartItem = async (item: models.CartItem) => {
    try {
      // Cart should be defined at this point, but we still check to avoid
      // possible runtime errors
      if (!cart) return;

      setDeletingCartItem(true);
      const { data } = await Api.billing.deleteFromCart(cart.accountId, item.id);
      setCart(data.data);
    } catch (e: any) {
      toastStore!.error(getErrorMsg(e));
    } finally {
      setDeletingCartItem(false);
    }
  };

  /** Sends the purchase order */
  const sendPurchaseOrder = async () => {
    try {
      setSendingPurchaseOrder(true);
      const { data }: AxiosResponse<ApiResponse<models.Owner[]>> = await Api.core.getAccountOwners(
        accountId,
      );
      const primaryOwner = data?.data?.find(
        (owner) => owner.owners[0] && owner.owners[0].type === 'primary',
      );
      if (!primaryOwner) {
        throw new Error('Account does not have primary owner');
      }
      await Api.billing.finalizeCart(accountId, primaryOwner.email, primaryOwner.firstName!);
      toastStore!.success(`Purchase order sent to ${primaryOwner.email}`);
      onClose && onClose();
    } catch (e: any) {
      toastStore!.error(getErrorMsg(e));
    } finally {
      setSendingPurchaseOrder(false);
    }
  };

  const addKiosk = () => {
    setNumberOfKiosks(numberOfKiosks + 1);
  };

  const removeKiosk = () => {
    if (!numberOfKiosks) return;
    setNumberOfKiosks(numberOfKiosks - 1);
  };

  const cartInProgress = deletingCartItem || addingLicenseToCart;

  const confirmButtonLabel = canSendPurchaseOrder ? 'Send purchase order' : 'Go to checkout';

  const canSubmitLicenses = cart && cart.items.length > 0;

  return {
    form,
    fields,
    cart,
    loadingCart,
    cartInProgress,
    deletingCartItem,
    sendingPurchaseOrder,
    confirmButtonLabel,
    canSubmitLicenses,
    numberOfKiosks,
    initCart,
    deleteCartItem,
    sendPurchaseOrder,
    addLicenseToCart,
    handleFormReset,
    addKiosk,
    removeKiosk,
  };
};

export default _useAddLicenseDialog;
