import CreditCardIcon from 'components/CreditCardIcon';
import { IBillingEntity } from 'models';
import { Box } from '@material-ui/core';
import { cardMenuItemStyling } from './styles';

interface BillingCardPops {
  billingEntity?: IBillingEntity;
}

const BillingCard = ({ billingEntity }: BillingCardPops) => {
  if (!billingEntity) {
    return <></>;
  }

  return (
    <Box sx={cardMenuItemStyling} className="menu-item-card">
      <CreditCardIcon
        className="card-icon"
        brand={billingEntity.paymentMethod.brand!.toLowerCase()}
      />
      <Box className="card-info">
        <Box>{billingEntity.displayName}</Box>
        <Box className="sub-text">
          {`${billingEntity.paymentMethod.brand} ${billingEntity.paymentMethod.lastFour} | ${billingEntity.paymentMethod.validThru}`}
        </Box>
      </Box>
    </Box>
  );
};

export default BillingCard;
