import React from 'react';
import { observable, action, makeObservable } from 'mobx';
import { observer } from 'mobx-react';
import { WithStyles, withStyles } from '@material-ui/core/styles';
import { Grid, Box, IconButton, Tooltip, Dialog } from '@material-ui/core';
import { Android, Link } from 'mdi-material-ui';
import { Device } from 'models';

import { inject, WithUserStore, WithToastStore } from 'stores';
import Api, { RequestMetaData } from 'api';
import { adaptForDataGridPro } from 'services';
import LinkDevice from './LinkDevice';
import { Filter } from 'components/FilterBar/FilterBar';
import FilterBar from 'components/FilterBar';
import DataGridInfiniteScroll from 'components/DataGridInfiniteScroll';
import * as DateRangeExternalPicker from 'components/DateRangeExternalPicker';
import AppleIcon from '@mui/icons-material/Apple';

import styles from './styles';
import moment from 'moment';
import { RouteComponentProps } from 'react-router-dom';

interface KiosksProps
  extends WithStyles<typeof styles>,
    // RouteComponentProps,
    WithUserStore,
    WithToastStore, RouteComponentProps {}

/**
 * The container for the devices page kiosks tab.
 */
@inject('userStore', 'toastStore')
@observer
class Kiosk extends React.Component<KiosksProps> {
  constructor(props: KiosksProps) {
    super(props);
    makeObservable(this);
  }

  /** Active filters as returned by FilterBar */
  @observable private activeFilters: Record<string, unknown> = {};

  /** The selected date range */
  @observable public dateRange: DateRangeExternalPicker.DateRange = DateRangeExternalPicker.getDateRange();

  /** The key for the datagrid table */
  @observable public dataGridKey = Date.now();

  public getKioskItemsData = adaptForDataGridPro(
    async (rmd: RequestMetaData) =>
      await Api.core.getKiosks({
        ...rmd,
        filters: {
          // fromDate: this.dateRange.fromDate,
          // toDate: this.dateRange.toDate,
          ...this.activeFilters,
        },
      }),
    (device: Device) => ({
      ...device,
      accountName: device.location && device.location.account && device.location.account.name,
      locationName: device.location && device.location.name,
      locationAddress:
        device.location &&
        device.location.address +
          ' ' +
          device.location.city +
          ' ' +
          device.location.state +
          ' ' +
          device.location.zip,
      tablet: device.tablet && device.tablet.serial,
      reader: device.reader && device.reader.serial,
      updatedAt: device.updatedAt && moment(device.updatedAt).format('MMM DD, YYYY h:mm:ss A')
    }),
  );

  // /** Sets the date range */
  // @action.bound private updateDateRangeValue(range: DateRange) {
  //   this.dateRange = range;
  //   this.activeFilters = { ...this.activeFilters };
  // }

  /** The device being linked, if not null then the dialog is open */
  @observable public linkingDeviceId: number | null = null;

  /** Opens the dialog for linking device with id `deviceId` */
  @action.bound public startLinkingDevice(deviceId: number) {
    this.linkingDeviceId = deviceId;
  }

  /** Closes the linking dialog */
  @action.bound public stopLinkingDevice() {
    this.linkingDeviceId = null;
  }

  /** Closes the dialog and refreshes the datagrid data */
  @action.bound public finishLinking() {
    this.dataGridKey = Date.now();
    this.stopLinkingDevice();
  }

  @action.bound public handleClick = (event: React.MouseEvent<HTMLElement>) => {
    this.setState(event.currentTarget);
  };

  @action.bound public handleClose = () => {
    this.setState(null);
  };

  renderCellLink = ({ row }: any) => {
    return (
      <>
        <IconButton color="primary" onClick={() => this.startLinkingDevice(row.id)}>
          <Tooltip title="Link">
            <Link fontSize="small" />
          </Tooltip>
        </IconButton>
      </>
    );
  };

  /** Renders link icon and click action */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  renderLink = (rowIndex: any, name: any, value: any, row: any) => {
    return (
      <>
        <IconButton color="primary" onClick={() => this.startLinkingDevice(row.id)}>
          <Tooltip title="Link">
            <Link fontSize="small" />
          </Tooltip>
        </IconButton>
      </>
    );
  };

  renderOS = (row: any) => {
    return (
      <>
        <IconButton color="primary" onClick={() => null}>
          <Tooltip title="OS">
            {row.row.os === 'ios' ? <AppleIcon /> : <Android fontSize="small" />}
          </Tooltip>
        </IconButton>
      </>
    );
  };

  gridColumns = [
      { headerName: 'Account', field: 'accountName', minWidth: 200, flex: 1 },
      { headerName: 'Location', field: 'locationName', minWidth: 200, flex: 1 },
      { headerName: 'Address', field: 'locationAddress', minWidth: 200, flex: 1 },
      { headerName: 'Tablet', field: 'tablet', minWidth: 100, flex: 1, filterable: false },
      // { headerName: 'Reader', field: 'reader', minWidth: 150, flex: 1 },
      { headerName: 'OS', field: 'os', minWidth: 50, flex: 1, filterable: false,  renderCell: this.renderOS },
      { headerName: 'OS Version', field: 'osVersion', minWidth: 130, flex: 1, filterable: false },
      { headerName: 'Last Update ', field: 'updatedAt', minWidth: 130, flex: 1, filterable: false },
  
      // {
      //   headerName: 'Actions',
      //   field: 'link',
      //   type: 'actions',
      //   minWidth: 100,
      //   filterable: false,
      //   renderCell: this.renderCellLink,
      // },
    ];
    
  filters: Filter[] = [
    { display: 'Account', id: 'accountName', label: 'Contains', type: 'text' },
    { display: 'Location', id: 'locationName', label: 'Contains', type: 'text' },
    { display: 'OS', id: 'os', label: 'Contains', type: 'text' },
    { display: 'OS Version', id: 'osVersion', label: 'Contains', type: 'text' },
  ];

  /** Display results in datagrid */
  render() {
    const { classes } = this.props;
    return (
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Dialog open={Boolean(this.linkingDeviceId)} onClose={this.stopLinkingDevice}>
            <Box className={classes.linkDeviceRoot}>
              <LinkDevice deviceId={this.linkingDeviceId!} onComplete={this.finishLinking} />
            </Box>
          </Dialog>
          <Box mt={3}>
            <FilterBar
              filters={this.filters}
              onChange={(filters: Record<string, unknown>) => {
                this.activeFilters = filters;
              }}
              // externalDateRange={{
              //   predefined: this.dateRange.type || 'all',
              //   onChange: this.updateDateRangeValue,
              // }}
            />
            <DataGridInfiniteScroll
              columns={this.gridColumns}
              fetch={this.getKioskItemsData}
              refetchKey={this.activeFilters}
              disableColumnMenu
              pathname={this.props.location.pathname}
            />
          </Box>
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(Kiosk);
