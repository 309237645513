import { rootStore } from 'containers/App/App';
import Api, { getErrorMsg } from 'api';
import MobxReactForm from 'mobx-react-form';
import validatorjs from 'validatorjs';
import { tokenRule } from 'components/MfaComponent/helpers';
const dvr = require('mobx-react-form/lib/validators/DVR');

export const cancelPendingUpdate = async () => {
  try {
    await Api.core.cancelEmailChange();
    rootStore.userStore!.refreshTokenAndUpdateUserData();
    rootStore.toastStore!.success('Email change request canceled');
  } catch (e) {
    rootStore.toastStore!.error(getErrorMsg(e));
  }
};

type MobxForm = any;
const fieldName = 'token';

const plugins = {
  dvr: dvr({
    package: validatorjs,
    extend: ({ validator, form }: { validator: validatorjs.ValidatorStatic; form: MobxForm }) => {
      const token: any = tokenRule('text', 6, 'email');
      validator.register('token', token.function, token.message);
    },
  }),
};

const hooks = {
  onSuccess: async (form: any) => {
    await confirmEmail(form);
    form?.reset();
  },
};

export const confirmEmail = async (form: any) => {
  const { toastStore, userStore } = rootStore;
  const token = form?.$(fieldName).value;
  try {
    const resp = await Api.core.confirmEmail(token);
    userStore!.refreshTokenAndUpdateUserData(resp.data.data.user.email);
    form.closeDialog();
    toastStore!.push({
      type: 'success',
      message: `New email address was successfully confirmed`,
    });
  } catch (e: any) {
    form?.$(fieldName).invalidate(getErrorMsg(e));
  }
};

export const resendEmailCode = async () => {
  try {
    await Api.core.resendEmailChangeConfirmationEmail();
  } catch (e) {
    rootStore.toastStore.error(getErrorMsg(e));
  }
};

const newEmailConfirmForm = (code?: string) => {
  const fields = [
    {
      name: 'token',
      rules: 'required|token',
      value: code || '',
      hooks: {
        onChange: (field: any) => {
          field.validate({ showErrors: true });
        },
      },
    },
  ];
  const form = new MobxReactForm({ fields }, { plugins, hooks });
  return form;
};

export default newEmailConfirmForm;
