import React from 'react';
import { Avatar, Box, List } from '@material-ui/core';
import { observer } from 'mobx-react';

import { inject, UserScopes, WithUserStore } from 'stores';
import ScopeItem from './ScopeItem';
import useStyles from './styles';
import PublicIcon from '@mui/icons-material/Public';
import * as models from 'models';
import theme from 'containers/App/theme';
// import GlobalOwnerSearch from './GlobalOwnerSearch/GlobalOwnerSearch';
import GlobalScopeSearch from './GlobalOwnerSearch/GlobalScopeSearch';
import { action, computed, makeObservable, observable, reaction } from 'mobx';
import { useStores } from 'containers/App/App';
import { isValueInArray } from 'utils/helper';

interface ScopeSwitcherProps extends WithUserStore {
  close: () => void; // The callback to close the scope switcher
  classes: any;
}

type TScopeItem =
  | (models.Account & { kind: UserScopes })
  | (models.Location & { kind: UserScopes });

/**
 * The scope switcher component. It lets the user change their current scope.
 * Someone's scope can be:
 * `user`: Viewing the dashboard as an SP
 * `location`: Viewing the dashboard as the manager of a certain location
 * `account`: Viewing the dashboard as the owner of a certain account
 */
@inject('userStore')
@observer
class ScopeSwitcher extends React.Component<ScopeSwitcherProps> {
  constructor(props: ScopeSwitcherProps) {
    super(props);
    makeObservable(this);

    reaction(
      () => this.props.userStore!.scope,
      (scope) => {
        this.scopeSelected = scope;
      },
    );
  }

  @observable private scopesToDisplay: (models.Account | models.Location)[] = [];

  @action.bound public setScopesToDisplay(scopes: (models.Account | models.Location)[]) {
    this.scopesToDisplay = scopes;
  }

  @observable private scopeSelected = this.props.userStore!.scope;

  @computed private get scopes(): TScopeItem[] {
    const userStore = this.props.userStore;
    const accounts =
      userStore?.accountsToDisplay?.map((account) => {
        return { ...account, kind: UserScopes.OWNER };
      }) || [];
    const locations =
      userStore?.managedLocations?.map((location) => {
        return { ...location, kind: UserScopes.MANAGER };
      }) || [];
    return [...accounts, ...locations];
  }

  getIcon(
    scope: string,
    account: models.Account | undefined = undefined,
    location: models.Location | undefined = undefined,
  ) {
    // Location or account logo
    let logo;
    // Location or account name
    let name;
    if (scope === 'owner' && account) {
      logo = account.logo;
      name = account.name;
    }
    if (scope === 'manager' && location) {
      logo = location.account?.logo;
      name = location.name;
    }
    if (scope === 'talent') {
      logo = this.props.userStore?.user?.avatar;
    }

    return (
      <Avatar
        className={this.props.classes.scopeSwitcherAvatar}
        style={{
          backgroundColor: logo ? theme.palette.common.white : theme.palette.common.black,
        }}>
        {logo ? (
          <img src={logo} width={'100%'} height={'100%'} />
        ) : (
          <Box component={'span'} style={{ fontSize: '10px' }}>
            {name?.substring(0, 2)}
          </Box>
        )}
      </Avatar>
    );
  }
  render() {
    const { classes } = this.props;
    const userStore = this.props.userStore!;
    const showUserScope = userStore.isSp;
    const showAccountScopes = isValueInArray(
      [UserScopes.OWNER, UserScopes.GLOBAL_OWNER, UserScopes.TALENT],
      userStore.scope.kind,
    );
    const showGlobalOwnerScope = userStore.accounts.length > 1;
    const showLocationScopes =
      userStore.scope.kind !== 'owner' &&
      userStore.scope.kind !== 'global_owner' &&
      userStore.isManager;
    const showScopeSwitcher = showAccountScopes || showLocationScopes;

    if (!showScopeSwitcher) {
      return null;
    }

    return (
      <>
        <Box px={3} pt={2} width={'100%'}>
          <GlobalScopeSearch
            userStore={userStore}
            scopes={this.scopes}
            setScope={this.setScopesToDisplay}
            selected={this.scopeSelected}
            onClose={this.props.close}
          />
        </Box>

        <List className={classes.list} disablePadding>
          {showUserScope && (
            <ScopeItem
              icon={this.getIcon('talent', undefined, undefined)}
              scope={{ kind: 'talent' }}
              active={userStore.scope.kind === 'talent'}
              setScope={userStore.setScope}
              dataCy={`${userStore.fullName}scopeitem`.toLowerCase().replaceAll(' ', '-')}>
              {userStore.fullName}
            </ScopeItem>
          )}
          {showGlobalOwnerScope && (
            <ScopeItem
              scope={{ kind: 'global_owner' }}
              active={userStore.scope.kind === 'global_owner'}
              setScope={userStore.setScope}
              icon={<PublicIcon />}
              key={'global_owner'}
              dataCy="global-scopeitem">
              Global
            </ScopeItem>
          )}
          <Box className={this.props.classes.scopeItemWrapper}>
            {this.scopesToDisplay.map((scope: any, index) => {
              const showOwnerScopes = scope.kind === UserScopes.OWNER && showAccountScopes;
              const showManagerScopes = scope.kind === UserScopes.MANAGER && showLocationScopes;
              return (
                <React.Fragment key={index}>
                  {showOwnerScopes ? (
                    <ScopeItem
                      icon={this.getIcon('owner', scope, undefined)}
                      scope={{ kind: 'owner', accountId: scope.id }}
                      active={
                        userStore.scope.kind === 'owner' && userStore.scope.accountId === scope.id
                      }
                      setScope={userStore.setScope}
                      key={scope.id}
                      dataCy={`${scope.name}scopeitem`.toLowerCase().replaceAll(' ', '-')}>
                      {scope.name}
                    </ScopeItem>
                  ) : showManagerScopes ? (
                    <ScopeItem
                      icon={this.getIcon('manager', undefined, scope)}
                      scope={{ kind: 'manager', locationId: scope.id }}
                      active={
                        userStore.scope.kind === 'manager' &&
                        userStore.scope.locationId === scope.id
                      }
                      setScope={userStore.setScope}
                      key={scope.id}
                      dataCy={`${scope.name}scopeitem`.toLowerCase().replaceAll(' ', '-')}>
                      {scope.name}
                    </ScopeItem>
                  ) : (
                    <></>
                  )}
                </React.Fragment>
              );
            })}
          </Box>
        </List>
      </>
    );
  }
}

const StyledScopeSwitcher = (props: Omit<ScopeSwitcherProps, 'classes'>) => {
  const { uiStore } = useStores();
  const { mobileView } = uiStore;
  return <ScopeSwitcher {...props} classes={useStyles({ mobileView })} />;
};

export default StyledScopeSwitcher;
