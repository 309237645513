import React from 'react';

import { Link as RouterLink } from 'react-router-dom';
import { computed, makeObservable } from 'mobx';
import { observer } from 'mobx-react';
import { WithStyles, withStyles } from '@material-ui/core/styles';
import { paths } from 'routes';
import { Industry } from 'models';
import { Paper, Box, Typography, Link } from '@material-ui/core';

import QRCode from 'qrcode.react';
import config from 'config';

import styles from './styles';
interface KioskLinkPanelProps extends WithStyles<typeof styles> {
  title: string;
  code: string;
  type: 'account' | 'location' | 'talent' | 'pool';
  disabled: boolean;
  industry?: Industry;
}

/**
 * Shows a panel containing link to kiosk, with one button for copying
 * copying link to clipboard and second button to open the actual page
 * @param title Panel title
 * @param link Kiosk link
 */
@observer
class KioskLinkPanel extends React.Component<KioskLinkPanelProps> {
  constructor(props: KioskLinkPanelProps) {
    super(props);
    makeObservable(this);
  }

  @computed get link(): string {
    let typeAnnotation = '';
    if (this.props.type === 'account') typeAnnotation = 'A';
    if (this.props.type === 'location') typeAnnotation = 'L';
    if (this.props.type === 'talent') typeAnnotation = 'T';
    if (this.props.type === 'pool') typeAnnotation = 'P';
    const fullCode = `${typeAnnotation}${this.props.code}`;

    return `${config.tippyGo.baseUrl}/${fullCode}`;
  }

  render() {
    return (
      <Paper elevation={0} style={{ height: '100%' }}>
        <Box
          p={2}
          pb={3}
          display="flex"
          flexDirection="column"
          alignItems="center"
          style={{ minHeight: '171px', minWidth: '194px' }}>
          <Box display="flex" flexGrow={1} alignItems="flex-end">
            {!this.props.disabled ? (
              <Box display="flex" flexGrow={1} alignItems="flex-end">
                <Link
                  component={RouterLink}
                  to={`${paths.QRCodeForPrint()}?type=${this.props.type}&code=${
                    this.props.code
                  }&industry=${this.props.industry}`}>
                  <QRCode
                    type={this.props.type}
                    value={this.link}
                    style={{ height: '84px', width: '84px' }}
                  />
                </Link>
              </Box>
            ) : (
              <Box display="flex" flexDirection="column" flexGrow={1} alignItems="center">
                <Typography className={this.props.classes.notAvailableLabel}>
                  Online Payments
                </Typography>
                <Typography className={this.props.classes.notAvailableLabel}>Disabled</Typography>
              </Box>
            )}
          </Box>
          <Box display="flex" flexGrow={1} alignItems="flex-end">
            <Typography color="textSecondary">{this.props.title}</Typography>
          </Box>
        </Box>
      </Paper>
    );
  }
}

export default withStyles(styles)(KioskLinkPanel);
