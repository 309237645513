import { Location } from './Location';
import { Customer } from './Customer';
import { DistributionRule } from './FeeSchedule';

export enum PaymentStatus {
  CREATED = 'created',
  VERIFYING = 'verifying',
  CAPTURED = 'captured',
  FAILED = 'failed',
  CANCELLED = 'cancelled',
  REFUNDED = 'refunded',
  VOID = 'void',
  NOT_FOUND = 'not_found',
}

type CreditCardBrand = 'visa' | 'mastercard' | 'american express' | 'discover';

export interface CustomerCard {
  id: number;
  lastFour: string;
  brand: CreditCardBrand;
}

export interface Payment {
  id: number;
  createdAt: string;
  updatedAt: string;
  legacyDbId?: string;
  processor: string;
  transactionId?: string;
  processorReceipt?: string;
  receipt?: string;
  tipsCount: number;
  chargeAmount: string;
  gross: string;
  net: string;
  tippyFee: string;
  processorFee: string;
  fees?: PaymentFee[];
  reference: string;
  localTime: string;
  deviceTime: string;
  status: PaymentStatus;
  deviceId: string;
  locationId: number;
  location: Location;
  customer?: Customer;
  customerId?: number;
  customerCard?: CustomerCard;
  customerCardId?: number;
}

export interface PaymentFee {
  fee: string;
  distributionRuleId: number;
  paymentId: number;
  distributionRule?: DistributionRule;
  payment?: Payment;
}

export interface NextPayment {
  amount: string;
  date?: string;
  isTaxapplied?: boolean;
}
