/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { observable, makeObservable } from 'mobx';
import validatorjs from 'validatorjs';
import { observer } from 'mobx-react';
import { WithStyles, withStyles } from '@material-ui/core/styles';
import { Box, Typography } from '@material-ui/core';

import { inject, WithToastStore } from 'stores';

import styles from './styles';
import OutlinedInput from 'components/Input/OutlinedInput';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const dvr = require('mobx-react-form/lib/validators/DVR');
const MobxReactForm = require('mobx-react-form').default;

type AffiliateEmailFormProps = {
  getForm(fields: any): any;
  color: string;
} & WithStyles<typeof styles> &
  WithToastStore;

const plugins = {
  dvr: dvr({
    package: validatorjs,
    extend: ({ validator }: { validator: any; form: any }) => {
      validator.setMessages('en', {
        ...validator.getMessages('en'),
        email: 'Please input a valid email',
      });
    },
  }),
};

@inject('toastStore')
@observer
class AffiliateEmailForm extends React.Component<AffiliateEmailFormProps> {
  /** Whether the info is currently being submitted */
  @observable public submitting = false;

  public constructor(props: AffiliateEmailFormProps) {
    super(props);
    makeObservable(this);
    const fields = [
      {
        name: 'email',
        label: 'Affiliate Email',
        rules: 'required|string|email',
        value: '',
      },
    ];
    this.form = new MobxReactForm({ fields }, { plugins });
    props.getForm && props.getForm(this.form);
  }

  /** The form object */
  private form: any;

  render() {
    const { classes, color } = this.props;
    const fields = {
      email: this.form.$('email'),
    };
    return (
      <>
        <Typography
          className={classes.titleColor}
          style={{ color }}
          variant="h4"
          component="h1"
          gutterBottom>
          Affiliate Email
        </Typography>

        <Box mt={4}>
          <OutlinedInput
            autoFocus
            {...fields.email.bind()}
            label={fields.email.label}
            error={fields.email.error}
            required={fields.email.rules.includes('required')}
          />
        </Box>
      </>
    );
  }
}

export default withStyles(styles)(AffiliateEmailForm);
