import { DateRange } from 'components/DateRangeExternalPicker';
import { rootStore } from 'containers/App/App';
import { useState } from 'react';

interface IUseExternalDateRangeProps {
  identifier: string;
  defaultValue?: DateRange;
}

/**
 * @param identifier pathname + current tab name or other identifier string;
 */
const useExternalDateRange = ({ identifier, defaultValue }: IUseExternalDateRangeProps) => {
  const [dateRange, setDateRange] = useState(
    defaultValue || rootStore.settingStore!.getDate(identifier),
  );

  const onDateRangeChange = (range: DateRange) => {
    rootStore.settingStore!.setDate(`${identifier}`, range);
    setDateRange(range);
  };

  return {
    dateRange,
    onDateRangeChange,
  };
};

export default useExternalDateRange;
