import React from 'react';
import { observable, action, flow, computed, makeObservable } from 'mobx';
import { observer } from 'mobx-react';

import { inject, WithToastStore } from 'stores';

import { Note, Topic } from 'models';
import moment from 'moment-timezone';

import { WithStyles, withStyles } from '@material-ui/core/styles';
import { Box, TextField } from '@material-ui/core';

import NoteStore from './NoteStore';

import DD from 'components/DashDrawer';
import DP from 'components/DashPanel';

import styles from './styles';
import Button from 'components/Button/Button';

/** Props for this component */
interface EditNoteProps extends WithStyles<typeof styles>, WithToastStore {
  noteStore: NoteStore;
}

/**
 * Displays add/edit view for a note.
 */
@inject('toastStore')
@observer
class EditNote extends React.Component<EditNoteProps> {
  constructor(props: EditNoteProps) {
    super(props);
    makeObservable(this);
  }
  /** Generate list of topic values from Topics enum */
  private topicList = Object.values(Topic);

  /** Note object if editing an existing note */
  @computed get note(): undefined | Note {
    return this.props.noteStore.note;
  }

  /** Whether the submission is currently in progress */
  @observable public submitting = false;

  /** Is form valid */
  @computed get isFormValid(): boolean {
    // If topic or content are not set, form not valid
    if (!this.props.noteStore.topic || !this.props.noteStore.content) {
      return false;
    }
    // If user is editing an existing note and didn't change any of the input values, form not valid
    if (
      this.note &&
      this.note.topic === this.props.noteStore.topic &&
      this.note.note === this.props.noteStore.content
    ) {
      return false;
    }
    // Otherwise we're good to save
    return true;
  }

  /** Navigate to reminders list */
  @action.bound private showReminders = () => {
    this.props.noteStore.routeTo('/reminderList');
  };

  /** Save note by either creating a new note or patching an existing note */
  @action.bound public saveNote = flow(function* (this: EditNote) {
    try {
      this.submitting = true;
      const postData = {
        topic: this.props.noteStore.topic as Topic,
        note: this.props.noteStore.content as string,
      };
      yield this.props.noteStore.saveNote(postData);
      this.props.toastStore!.success('Note saved successfully');
    } finally {
      this.submitting = false;
    }
  });

  render() {
    const { classes } = this.props;
    return (
      <DD>
        <DD.Content>
          <DD.Title fontSize={28} onBack={this.props.noteStore.goBack} loading={this.submitting}>
            {this.note
              ? moment(this.note.createdAt).format('MMM DD, YYYY')
              : moment().format('MMM DD, YYYY')}
          </DD.Title>
          <Box mt={4}>
            <DP.Select
              fullWidth
              inputLabel={'Topic'}
              items={this.topicList}
              value={this.props.noteStore.topic ? this.props.noteStore.topic : ''}
              onChange={this.props.noteStore.updateTopic}
            />
          </Box>
          <Box mt={2}>
            <TextField
              className={classes.textField}
              placeholder="Note content"
              multiline
              fullWidth
              value={this.props.noteStore.content}
              onChange={this.props.noteStore.updateContent}
              rowsMax={1000}
              rows={10}
            />
          </Box>
        </DD.Content>
        <DD.Actions>
          <Box mb={2}>
            <Button
              fullWidth
              variant="outlined"
              color="primary"
              disabled={this.submitting}
              onClick={this.showReminders}>
              Reminders
            </Button>
          </Box>
          <Box>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              disabled={!this.isFormValid || this.submitting}
              onClick={this.saveNote}>
              Save
            </Button>
          </Box>
        </DD.Actions>
      </DD>
    );
  }
}

export default withStyles(styles)(EditNote);
