import { ChipStatusColors } from 'components/ChipStatusTag';
import { Affiliate } from './Affiliate';
import { Location } from './Location';
import { Promotion } from 'models';

/** Represents a conversion item */
export interface Conversion {
  id: number;
  affiliateId: number;
  affiliate: Affiliate;
  locationId: number;
  location: Location;
  leadId: number;
  promotionId: number;
  promotion: Promotion;
  amount?: string;
  status: ConversionStatus;
  message?: string;
  paidAt: string;
  createdAt: string;
  updatedAt: string;
  isClaimable?: boolean;
}

export enum ConversionStatus {
  PENDING_LICENSE_PAYMENT = 'pending_license_payment',
  COUNTING_TIPS = 'counting_tips',
  READY_FOR_PAYMENT = 'ready_for_payment',
  PAID = 'paid',
  THRESHOLD_REACHED = 'threshold_reached',
  THRESHOLD_NOT_REACHED = 'threshold_not_reached',
}

interface IConversionStatusColors {
  [key: string]: ChipStatusColors;
}

export const conversionStatusColors: IConversionStatusColors = {
  [ConversionStatus.PENDING_LICENSE_PAYMENT]: ChipStatusColors.YELLOW,
  [ConversionStatus.COUNTING_TIPS]: ChipStatusColors.YELLOW,
  [ConversionStatus.READY_FOR_PAYMENT]: ChipStatusColors.PURPLE,
  [ConversionStatus.PAID]: ChipStatusColors.GREEN,
  [ConversionStatus.THRESHOLD_REACHED]: ChipStatusColors.YELLOW,
  [ConversionStatus.THRESHOLD_NOT_REACHED]: ChipStatusColors.RED,
};
