import React from 'react';
import { observer } from 'mobx-react';
import {
  action,
  observable,
  reaction,
  computed,
  toJS,
  IReactionDisposer,
  flow,
  makeObservable,
} from 'mobx';
import { User, Profession } from 'models';
import { WithStyles, withStyles } from '@material-ui/core/styles';
import { Box, Dialog, DialogActions, DialogContent, DialogTitle, Button } from '@material-ui/core';
import { Pencil } from 'mdi-material-ui';

import Api from 'api';
import { WithToastStore, WithUserStore, inject } from 'stores';

import DP from 'components/DashPanel';
import Title from 'components/Title';
import ProfessionList from './ProfessionList';

import styles from './styles';

interface ProfessionPanelProps extends WithStyles<typeof styles>, WithToastStore, WithUserStore {
  children?: User;
  title?: string;
}
/**
 * Displays and edits user's profession.
 * @param children The user object to display. Pass undefined if it's still loading
 * @param title Optional title, `Profession` by default
 */
@inject('toastStore', 'userStore')
@observer
class ProfessionPanel extends React.Component<ProfessionPanelProps> {
  static defaultProps = {
    title: 'Profession',
  };
  constructor(props: ProfessionPanelProps) {
    super(props);
    makeObservable(this);
    this.disposers.push(
      reaction(
        () => this.props.children,
        (newUser) => {
          this.user = toJS(newUser);
        },
      ),
    );
  }

  /** It's good practice to dispose of any autoruns that we set up during */
  private disposers: IReactionDisposer[] = [];

  /** The user object is copied to this property */
  @observable private user?: User = toJS(this.props.children);

  /** Users profession computed from User object */
  @computed private get profession(): Profession | undefined {
    return this.user ? (this.user.profession as Profession) : undefined;
  }

  /** Is component in editing state */
  @observable private editing = false;

  /** Is component updating API */
  @observable private updating = false;

  /** Starts editing and opens the dialog */
  @action.bound private edit() {
    this.editing = true;
  }

  /** Closes the dialog */
  @action.bound private closeDialog() {
    this.editing = false;
  }

  /** If selected profession is not the same as current update user with new profession */
  @action.bound private updateProfession = flow(function* (this: ProfessionPanel, profession) {
    if (profession !== this.profession) {
      this.updating = true;
      const apiUserData = { profession };
      yield Api.core.updateUser(this.user!.id, apiUserData as User);
      this.props.userStore?.refreshTokenAndUpdateUserData();
      this.props.toastStore!.push({
        type: 'success',
        message: `Your profession was successfully set to ${profession}`,
      });
    }
    this.editing = false;
    this.updating = false;
  });

  /** Before unmounting the component, dispose of all autoruns created */
  componentWillUnmount() {
    this.disposers.map((disposer) => disposer());
  }

  render() {
    const { title, classes } = this.props;
    if (!this.user) {
      return (
        <DP>
          <DP.Header>
            <DP.Title>{title}</DP.Title>
          </DP.Header>
          <DP.Body>
            <DP.Loading items={1} />
          </DP.Body>
        </DP>
      );
    }
    return (
      <>
        <DP>
          <DP.Header>
            <DP.Title panel>{title}</DP.Title>
            <DP.Actions>
              <DP.IconButton primary onClick={this.edit} icon={Pencil} tooltip="Edit" />
            </DP.Actions>
          </DP.Header>
          <DP.Body>
            <DP.Row>
              <DP.Value>{this.user.profession}</DP.Value>
              <DP.Label>Profession</DP.Label>
            </DP.Row>
          </DP.Body>
        </DP>
        <Dialog open={this.editing} onClose={this.closeDialog} classes={{ paper: classes.dialog }}>
          {this.editing && (
            <>
              <DialogTitle>
                <Box
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                  justifyContent="space-between">
                  <Title size="small">Choose Profession</Title>
                  {this.updating && <DP.LoadSpinner />}
                </Box>
              </DialogTitle>
              <DialogContent>
                <ProfessionList selected={this.profession} onChange={this.updateProfession} />
                <DialogActions>
                  <Button onClick={this.closeDialog} color="primary">
                    Cancel
                  </Button>
                </DialogActions>
              </DialogContent>
            </>
          )}
        </Dialog>
      </>
    );
  }
}

export default withStyles(styles)(ProfessionPanel);
