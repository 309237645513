import React, { useEffect, useState } from 'react';
import LoadingPage from 'routes/LoadingPage';
import { generateDeepLink, getQueryParams } from 'utils/helper';
import OpenAppPage from './OpenAppPage/OpenAppPage';
import { isOsAndroid } from 'utils/operatingSystem';

enum ESignupSource {
  WEB = 'web',
  APP = 'app',
}

const withAndroidRedirect = <P extends object>(WrappedComponent: React.ComponentType<P>) => {
  const WithAndroidRedirect: React.FC<P> = (props) => {
    const [isLoading, setIsLoading] = useState(true);
    const [appRedirect, setAppRedirect] = useState({
      redirect: false,
      deepLink: '',
      error: false,
    });

    useEffect(() => {
      redirectToApp();
    }, []);

    const redirectToApp = () => {
      const params = getQueryParams(['signupSource']);
      if (params.signupSource === ESignupSource.APP) {
        const isAndroid = isOsAndroid();
        const url = window.location.href;
        const newUrl = generateDeepLink(url, 'tippy:/');
        const error = !isAndroid ? true : false;
        setAppRedirect({
          redirect: true,
          deepLink: newUrl,
          error,
        });
      }

      setIsLoading(false);
    };

    if (appRedirect.redirect) {
      return <OpenAppPage {...appRedirect} />;
    }

    if (isLoading) {
      return <LoadingPage />;
    }

    return <WrappedComponent {...props} />;
  };

  return WithAndroidRedirect;
};

export default withAndroidRedirect;
