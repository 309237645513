import { Box, Link, Tooltip } from '@mui/material';
import ChipStatusTag, { ChipStatusColors } from 'components/ChipStatusTag';
import OptionsMenu from 'components/OptionsMenu';
import { IOptionsMenuProps } from 'components/OptionsMenu/OptionsMenu';
import { rootStore } from 'containers/App/App';
import { observer } from 'mobx-react';
import { Link as RouterLink } from 'react-router-dom';
import { formatDateTimeInEST, valueOrNA } from 'utils/helper';
import { textTooltipStyles } from './styles';

type TPlaceholder = string;

const placeholderOrNull = (placeholder?: TPlaceholder) => {
  return placeholder ? <>{placeholder}</> : null;
};

const Route = ({
  value,
  path,
  placeholder,
}: {
  value: string;
  path: string;
  placeholder?: TPlaceholder;
}) => {
  if (!value) {
    placeholderOrNull(placeholder);
  }
  return (
    <Link component={RouterLink} to={path} style={{ textDecoration: 'none' }}>
      {value}
    </Link>
  );
};

const ActionMenu = (props: IOptionsMenuProps) => {
  return <OptionsMenu {...props} />;
};

const Date = ({ value, placeholder }: { value: string; placeholder?: string }) => {
  if (!value) {
    placeholderOrNull(placeholder);
  }
  return valueOrNA(formatDateTimeInEST(value));
};

const Amount = ({
  amount,
  placeholder,
  onClick,
}: {
  amount?: string;
  placeholder?: TPlaceholder;
  onClick?: () => void;
}) => {
  if (!amount) {
    placeholderOrNull(placeholder);
  }
  amount = ['$', amount].join('');
  if (onClick) {
    return (
      <Box sx={{ cursor: 'pointer', color: 'primary.main' }} onClick={onClick}>
        {amount}
      </Box>
    );
  }
  return <>{amount}</>;
};

const _Button = ({
  value,
  placeholder,
  onClick,
}: {
  value: string;
  placeholder?: TPlaceholder;
  onClick: () => void;
}) => {
  if (!value) {
    placeholderOrNull(placeholder);
  }
  return (
    <Box sx={{ cursor: 'pointer', color: 'primary.main' }} onClick={onClick}>
      {value}
    </Box>
  );
};

const Status = ({ label, background }: { label: string; background: ChipStatusColors }) => {
  label = label.toUpperCase();
  return <ChipStatusTag label={label} color={background} />;
};

const TextTooltip = observer(({ value }: { value: string }) => {
  const title = rootStore.uiStore.showTooltip ? value : '';

  return (
    <Tooltip title={title}>
      <Box style={textTooltipStyles} onMouseEnter={rootStore.uiStore.handleMouseEnter}>
        {value}
      </Box>
    </Tooltip>
  );
});

const DataGridCell = {
  Route,
  Date,
  Amount,
  Status,
  Button: _Button,
  ActionMenu,
  TextTooltip,
};

export default DataGridCell;
