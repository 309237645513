import themeNew from 'containers/App/theme_new';
import { CSSProperties } from 'react';
import theme from 'containers/App/theme';
import { IStatusOptions } from 'theme';

export const menuStyles: CSSProperties & { [key: string]: any } = {
  '& .MuiPaper-root': {
    borderRadius: themeNew.spacing(2),
    minWidth: 100,
  },
};

export const itemStyles: (
  color?: keyof IStatusOptions,
) => CSSProperties & { [key: string]: any } = (color) => {
  return {
    color: color ? theme.palette.status[color] : theme.palette.text.primary,
  };
};
