import { User } from 'models';

export interface BankAccount {
  id: number;
  name?: string | null;
  itemId: string;
  accountId: string;
  verificationStatus: string | null;
  createdAt: string;
  accountName: string | null;
  accountMask: string | null;
  institutionName: string | null;
  institutionId: string | null;
  institutionLogo: string | null;
  errorDisplayMessage: string | null;
  errorCode: string | null;
  errorMessage: string | null;
  errorType: string | null;
  isActive: boolean;
  isPrimary: boolean;
  linkToken?: string;
  logo?: string;
  user?: User;
}

export enum BankAccountType {
  BUSINESS = 'business',
  PERSONAL = 'personal'
}
