import React from 'react';
import { observable, action, flow, computed, makeObservable } from 'mobx';
import { observer } from 'mobx-react';
import { WithStyles, withStyles } from '@material-ui/core/styles';
import {
  Box,
  IconButton,
  DialogTitle,
  DialogContent,
  CircularProgress,
  Typography,
} from '@material-ui/core';
import validatorjs from 'validatorjs';
import { Close } from 'mdi-material-ui';

import Api, { getErrorMsg } from 'api';
import { inject, WithUserStore, WithToastStore } from 'stores';
import { Location } from 'models';
import { Address } from 'types';
import { getTimezone } from 'services';

import AddressField from 'components/AddressField';
import Overlay from 'components/Overlay';

import styles from './styles';
import Title from 'components/Title/Dialog/Title';
import OutlinedInput from 'components/Input/OutlinedInput';
import Button from 'components/Button/Button';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const dvr = require('mobx-react-form/lib/validators/DVR');
const MobxReactForm = require('mobx-react-form').default;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type MobxForm = any;
interface FormHooks {
  onSuccess: (form: MobxForm) => void;
  onClear?: (form: MobxForm) => void;
  onError?: (form: MobxForm) => void;
}

const plugins = {
  dvr: dvr({
    package: validatorjs,
  }),
};

/** Here we define what kind of props this component takes */
interface AddLocationProps
  extends WithStyles<typeof styles>, // Adds the classes prop
    WithToastStore,
    WithUserStore {
  // Adds the userStore prop
  onLocationAdded?: () => Promise<void>;
  close?: () => void;
  accountId: number;
}

const fields = [
  {
    name: 'name',
    label: 'Location Name',
    rules: 'required|min:2',
  },
  {
    name: 'address',
    label: 'Address',
    rules: 'required',
    value: null,
  },
];

/**
 * Container that displays the screen for adding a location
 */
@inject('userStore', 'toastStore')
@observer
class AddLocation extends React.Component<AddLocationProps> {
  constructor(props: AddLocationProps) {
    super(props);
    makeObservable(this);
  }

  hooks: FormHooks = {
    onSuccess: () => this.submit(),
  };

  form: MobxForm = new MobxReactForm({ fields }, { plugins, hooks: this.hooks });

  /** The address that's used for this location */
  @computed public get address(): Address | undefined {
    const addr = this.form.$('address').value;
    if (!addr) {
      return undefined;
    }
    return {
      address: addr.address,
      city: addr.city,
      state: addr.state,
      zip: addr.zip,
      lat: addr.lat,
      long: addr.long,
    };
  }

  /** The location that's being added */
  @computed public get location(): Partial<Location> {
    return {
      accountId: this.props.accountId,
      name: this.form.$('name').value,
      timezone: 'Unknown',
      address: this.address!.address,
      address2: this.address!.address2,
      city: this.address!.city,
      zip: this.address!.zip,
      state: this.address!.state,
      lat: this.address!.lat,
      long: this.address!.long,
    };
  }

  /** Whether it's in progress */
  @observable public inProgress = false;

  /** Updates the address */
  @action.bound public updateAddress(a: Address | null) {
    this.form.$('address').set(a);
  }

  @action.bound public submit = flow(function* (this: AddLocation) {
    try {
      this.inProgress = true;
      const timezone: string = yield getTimezone({ ...this.address });
      yield Api.core.createLocation({ ...this.location, timezone });
      if (this.props.onLocationAdded) {
        yield this.props.onLocationAdded();
      }
      this.props.toastStore!.success('Location successfully created!');
      if (this.props.close) {
        this.props.close();
      }
    } catch (e: any) {
      this.props.toastStore!.error(getErrorMsg(e));
    } finally {
      this.inProgress = false;
    }
  });
  render() {
    const { classes, close } = this.props;
    const addressField = this.form.$('address');
    const nameField = this.form.$('name');
    return (
      <Box className={classes.root}>
        <form onSubmit={this.form.onSubmit}>
          <DialogTitle>
            <Title mb={0}>
              <Typography style={{ fontSize: 28, fontWeight: 400 }}>Create Location</Typography>
            </Title>
          </DialogTitle>
          <Overlay display={this.inProgress}>
            <CircularProgress />
          </Overlay>
          {close && (
            <IconButton onClick={close} className={classes.closeBtn}>
              <Close />
            </IconButton>
          )}
          <DialogContent>
            <Box mb={3}>
              <OutlinedInput
                {...nameField.bind()}
                error={Boolean(nameField.error)}
                helperText={nameField.error}
                fullWidth
                autoFocus
              />
            </Box>
            <Box mb={3}>
              <AddressField
                onChange={this.updateAddress}
                error={Boolean(addressField.error)}
                label="Address"
                helperText={addressField.error}
                onBlur={addressField.onBlur}
                onFocus={addressField.onFocus}
              />
            </Box>
            <Box pb={2}>
              <Button type="submit" variant="contained" color="primary" fullWidth>
                Create location
              </Button>
            </Box>
          </DialogContent>
        </form>
      </Box>
    );
  }
}

export default withStyles(styles)(AddLocation);
