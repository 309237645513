import React from 'react';
import { computed, makeObservable } from 'mobx';
import { observer } from 'mobx-react';
import { WithStyles, withStyles } from '@material-ui/core/styles';

import { Box, Button, Popover, Typography } from '@material-ui/core';
import { Circle } from 'mdi-material-ui';

import DP from 'components/DashPanel';

import styles from './styles';
import { inject, WithToastStore } from 'stores';

import { Charge } from 'models';
import moment from 'moment-timezone';

import theme, { colors } from 'containers/App/theme';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleInfo } from '@fortawesome/pro-regular-svg-icons';
import { capitalized } from 'utils/helper';

/** Represents a single device in the list */
const PaymentsPanelItem = observer(
  ({ children, classes }: { children: Charge; classes: { [key: string]: string } }) => {
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
    const charge = children;
    const menu = [
      {
        label: 'Coming soon',
        onClick: () => {},
      },
    ];

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const getCircleIcon = (status: string) => {
      switch (status) {
        case 'captured':
          return <Circle fontSize="inherit" color="primary" />;
        case 'failed':
          return <Circle fontSize="inherit" color="error" />;
        default:
          return (
            <div style={{ color: colors.amber }}>
              <Circle fontSize="inherit" color="inherit" />
            </div>
          );
      }
    };
    const renderChargeDeclinedNoteIcon = (note: string) => {
      return (
        <Box>
          <Button
            style={{ padding: 0, height: 'auto', width: 'auto' }}
            className={classes.infoIconButton}
            aria-describedby={id}
            onClick={handleClick}
            aria-label="delete">
            <FontAwesomeIcon
              fontSize={24}
              color={theme.palette.text.disabled}
              icon={faCircleInfo}
            />
          </Button>
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}>
            <Typography>{note}</Typography>
          </Popover>
        </Box>
      );
    };

    const getPrimaryText = (invoiceNumber: string) => ` (${invoiceNumber || 'N/A'})`;

    const getSecondaryText = (status: string, brand: string, lastFour: string, createdAt: string) =>
      `${capitalized(status) || 'N/A'}: ${brand || 'N/A'} ${lastFour || 'N/A'} on ${
        createdAt || 'N/A'
      }`;

    const amount = charge.amount;

    const status = charge.status || '';

    const createdAt = charge.chargeDate ? moment(charge.chargeDate).format('ll').toString() : '';

    const invoice = charge.invoice;
    const paymentMethod = charge.paymentMethod;

    let invoiceNumber = '';
    if (invoice) {
      invoiceNumber = invoice.number || '';
    }

    let brand = '';
    let lastFour = '';
    if (paymentMethod) {
      brand = paymentMethod.brand || '';
      lastFour = paymentMethod.lastFour || '';
    }

    return (
      <DP.ListItem
        key={charge.id}
        icon={
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            style={{ fontSize: '21px' }}>
            {getCircleIcon(status)}
          </Box>
        }
        rightContent={
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            style={{ fontSize: '21px', marginRight: 16 }}>
            {charge.note ? renderChargeDeclinedNoteIcon(charge.note) : null}
          </Box>
        }
        primary={
          <Typography variant="inherit">
            {amount || 'N/A'}
            <Typography variant="inherit" color={'textSecondary'}>
              {getPrimaryText(invoiceNumber)}
            </Typography>
          </Typography>
        }
        secondary={getSecondaryText(status, brand, lastFour, createdAt)}
        menu={menu}
      />
    );
  },
);

interface PaymentsPanelProps extends WithStyles<typeof styles>, WithToastStore {
  charges?: Charge[];
}

/**
 * Displays a scrollable list of charges for quick viewing. Includes
 * a filter.
 */
@inject('toastStore')
@observer
class PaymentsPanel extends React.Component<PaymentsPanelProps> {
  constructor(props: PaymentsPanelProps) {
    super(props);
    makeObservable(this);
  }

  private panelName = 'Payments';
  /** A total count of the charges */
  @computed private get count() {
    return this.props.charges && this.props.charges.length;
  }

  @computed get numberOfCharges() {
    if (this.props.charges) {
      return this.props.charges.length;
    } else {
      return 0;
    }
  }

  render() {
    if (!this.props.charges) {
      return (
        <DP>
          <DP.Header>
            <DP.Title panel>{this.panelName}</DP.Title>
          </DP.Header>
          <DP.Body>
            <DP.Loading items={4} />
          </DP.Body>
        </DP>
      );
    }
    return (
      <DP>
        <DP.Header>
          <DP.Title count={this.count} light>
            {this.panelName}
          </DP.Title>
        </DP.Header>
        <DP.List height={this.numberOfCharges < 5 ? 'short' : 'normal'}>
          {this.props.charges.map((charge) => (
            <PaymentsPanelItem key={charge.id} classes={this.props.classes}>
              {charge}
            </PaymentsPanelItem>
          ))}
        </DP.List>
      </DP>
    );
  }
}

export default withStyles(styles)(PaymentsPanel);
