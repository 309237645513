import { useCallback, useState } from 'react';

import { KioskMode } from 'models';

import { ListItem, Typography, Box, Tooltip, FormControlLabel } from '@material-ui/core';
import { Switch } from 'components/Switch/Switch';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleInfo } from '@fortawesome/pro-regular-svg-icons';
import clsx from 'clsx';
import { useStyles } from './styles';
import SettingsStore from './SettingsStore';
import { default as DialogComponent } from 'components/Dialog/Dialog';

interface SplitTipSettingProps {
  settingsStore: SettingsStore;
  isAdmin: boolean;
}

const enableMsg = 'By enabling this setting, owners and managers will be able to split tips.';
const disableMsg = 'By disabling this setting, owners and managers won’t be able to split tips.';

export const SplitTipSetting = ({ settingsStore, isAdmin }: SplitTipSettingProps) => {
  const { generalSettings, tippyGoSettings, updateGeneralSettings } = settingsStore;
  const classes = useStyles();
  const { label, settingList, splitTipsBox, splitTipsIcon, splitTipsInfo, splitTipsStatus } =
    classes;

  const [accountSplitTipEnabled, setAccountSplitTipEnabled] = useState(
    tippyGoSettings?.accountSplitTipEnabled,
  );
  const [dialogOpened, setDialogOpened] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const saveSettings = useCallback(async () => {
    setIsLoading(true);
    const newState = !accountSplitTipEnabled;
    await updateGeneralSettings({
      accountSplitTipEnabled: newState,
    });
    setAccountSplitTipEnabled(newState);
    setDialogOpened(false);
    setIsLoading(false);
  }, [accountSplitTipEnabled, updateGeneralSettings]);

  return (
    <>
      {isAdmin && generalSettings?.mode === KioskMode.INTEGRATED ? (
        <ListItem button className={settingList}>
          <FormControlLabel
            className={label}
            control={<Switch color="primary" />}
            labelPlacement="start"
            label="Split Tips"
            checked={accountSplitTipEnabled}
            onClick={() => setDialogOpened(true)}
          />
        </ListItem>
      ) : (
        <ListItem className={clsx(settingList, splitTipsBox)}>
          <Typography component="div">Split Tips</Typography>
          <Box className={splitTipsInfo}>
            <Typography component="div" className={splitTipsStatus}>
              {accountSplitTipEnabled ? 'Enabled' : 'Disabled'}
            </Typography>
            <Tooltip
              placement="bottom"
              title="If you want to change this setting, please contact our support team.">
              <FontAwesomeIcon icon={faCircleInfo} fontSize={23} className={splitTipsIcon} />
            </Tooltip>
          </Box>
        </ListItem>
      )}

      <DialogComponent
        open={dialogOpened}
        title="Split Tip Setting"
        content={<Box>{accountSplitTipEnabled ? disableMsg : enableMsg}</Box>}
        onCancel={() => setDialogOpened(false)}
        confirmActionName={accountSplitTipEnabled ? 'Disable' : 'Enable'}
        onConfirm={saveSettings}
        loading={isLoading}
      />
    </>
  );
};
