import { Box, Button, List, Typography } from '@material-ui/core';
import { useStores } from 'containers/App/App';
import { useStyles } from 'containers/LocationDetails/styles';
import { observer } from 'mobx-react';
import { AddStation } from 'models/Station';
import DD from 'components/DashDrawer';
import { StationPoolsAndGroupsList } from 'containers/LocationDetails/QrCodes/StationPoolsAndGroupsList/StationPoolsAndGroupsList';
import { Add } from '@mui/icons-material';
import React from 'react';
import { Skeleton, Stack } from '@mui/material';
import DP from 'components/DashPanel';

export const AddStationPoolsOrGroupsSection = observer(({ isLoading }: { isLoading?: boolean }) => {
  const classes = useStyles();
  const { stationsStore } = useStores();
  const { setAddStationState, addStationState, isPoolsEnabled } = stationsStore!;

  let title = 'Add Groups';
  let open = AddStation.GROUP_POOLS;
  if (addStationState === AddStation.ADD) {
    title = isPoolsEnabled ? 'Add Pools or Groups' : title;
    open = AddStation.POOLS_GROUPS;
  }

  if (addStationState === AddStation.GROUP) {
    if (!isPoolsEnabled) {
      return <></>;
    } else {
      title = 'Add Pools';
    }
  }

  if (isLoading) {
    return (
      <Box mt={3}>
        <DD.Subtitle>
          {' '}
          <Box component={'span'} style={{ color: 'black' }}>
            {title}
          </Box>
        </DD.Subtitle>
        <List>
          <ListItemSkeleton />
          <ListItemSkeleton />
          <ListItemSkeleton />
        </List>
        <Box display={'flex'} flexDirection={'column'}>
          <Button
            onClick={() => setAddStationState(open)}
            classes={{ root: classes.buttonFont }}
            color={'primary'}
            variant="text"
            style={{ width: '100px', alignSelf: 'center' }}>
            <Skeleton variant="rectangular" width="100px" height="25px"></Skeleton>
          </Button>
        </Box>
      </Box>
    );
  }

  return (
    <Box mt={3}>
      <DD.Subtitle>
        {' '}
        <Box component={'span'} style={{ color: 'black' }}>
          <Typography variant="body2">{title}</Typography>
        </Box>
      </DD.Subtitle>
      <StationPoolsAndGroupsList />
      <Box display={'flex'}>
        <Button
          onClick={() => setAddStationState(open)}
          classes={{ root: classes.buttonFont }}
          color={'primary'}
          variant="text"
          startIcon={<Add />}
          style={{ alignSelf: 'center', height: 20 }}>
          Add new
        </Button>
      </Box>
    </Box>
  );
});

const ListItemSkeleton = () => {
  return (
    <DP.ListItem
      primary={
        <Box
          display={'flex'}
          flexDirection={'row'}
          justifyContent={'space-between'}
          alignItems={'center'}>
          <Stack spacing={1} flexGrow={1}>
            <Skeleton variant="text" width="30%" height="20px" />
            <Skeleton variant="text" width={'50%'} height="20px" />
          </Stack>
          <Box display={'flex'} flexDirection={'row'} justifyContent={'space-between'}>
            <Skeleton variant="rectangular" width="15px" height="15px" />
          </Box>
        </Box>
      }
    />
  );
};
