import React from 'react';
import { observable, action, makeObservable } from 'mobx';
import { observer } from 'mobx-react';
import { WithStyles, withStyles } from '@material-ui/core/styles';

import Api, { getErrorMsg, PagedApiResponse } from 'api';
import { Invitation } from 'models';

import { inject, WithUserStore, WithModalStore, WithToastStore } from 'stores';

import styles from './styles';

import { AxiosResponse } from 'axios';
import InvitationsListPanel from 'components/InvitationsListPanel';
import { RouteComponentProps } from 'react-router-dom';
import ReferralPanel from 'components/ReferralPanel';
type TalentHomeProps = WithStyles<typeof styles> &
  WithUserStore &
  WithModalStore &
  WithToastStore &
  RouteComponentProps;

/**
 * For logged in users that are not affiliates or part of any account/location.
 */
@inject('userStore', 'modalStore', 'toastStore')
@observer
class TalentHome extends React.Component<TalentHomeProps> {
  constructor(props: TalentHomeProps) {
    super(props);
    makeObservable(this);
  }
  /** List of invitations */
  @observable private invitations?: Invitation[];

  @action.bound public getInvitations = async () => {
    try {
      const res: AxiosResponse<PagedApiResponse<Invitation>> | undefined =
        await Api.core.getUserInvitations(this.props.userStore!.user!.id);
      if (res) this.invitations = res.data.data;
    } catch (e: any) {
      this.props.toastStore!.error(getErrorMsg(e));
    }
  };

  componentDidMount() {
    this.getInvitations();
  }

  render() {
    return (
      <>
        <InvitationsListPanel
          showOwn={true}
          invitations={this.invitations}
          onUpdate={this.getInvitations}
        />
        {/* Referral Panel (LD flags) */}
        <ReferralPanel />
      </>
    );
  }
}

export default withStyles(styles)(TalentHome);
