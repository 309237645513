export interface ICorrection {
  id: number;
  name: string;
  description: string;
  code: string;
  price: string;
  taxCode: string;
  isSubscription: boolean;
  isCorrection: boolean;
  subscriptionInterval: number;
  isShipping: boolean;
  shippingProductId: number;
  isActive: boolean;
}

export interface ICreateCorrectionProduct {
  productId: number;
  quantity: number;
}

export interface ICreateCorrectionInvoiceProduct {
  products: ICreateCorrectionProduct[];
  locationId: number;
}

export interface ICreateCorrectionWithInvoiceRequest {
  billingEntityId: number;
  invoiceItems: ICreateCorrectionInvoiceProduct[];
}

export enum ECorrectionType {
  WITHOUT_INVOICE = 'withoutInvoice',
  WITH_INVOICE = 'withInvoice',
}

export interface IInvoiceUpdate {
  locationId: number;
  correctionId: number;
  isActive: boolean;
  quantity: number;
}

export interface IInvoiceProduct {
  productId: number;
  quantity: number;
}

export interface IInvoiceProductData {
  products: IInvoiceProduct[];
  locationId: number;
}
