import { Theme, createStyles, makeStyles } from '@material-ui/core';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default (theme: Theme) =>
  createStyles({
    root: {},
    addPaymentMethodForm: {
      position: 'relative',
    },
    dialogTitle: {
      '& .MuiDialogTitle-root': {
        padding: '24px',
      },
    },
  });

export const useStyles = makeStyles((theme: Theme) => ({
  listTextSecondaryFlex: {
    display: 'flex',
    justifyContent: 'space-between',
  },
}));
