export enum HardwareType {
  TABLET = 'tablet',
  READER = 'reader',
  ETHERNET = 'ethernet',
  CHARGER = 'charger',
}

/** Represents a type of hardware device, such as an iPad 11 or whatever */
export interface Hardware {
  id: number;
  manufacturer: string;
  model: string;
  type: HardwareType;
  isAvailable: boolean;
  quantity?: number;
  availableQuantity?: number;
  requiresInventory?: boolean;
}
