import React from 'react';
import { RouteComponentProps, Link as RouterLink } from 'react-router-dom';
import { action, observable, flow, makeObservable } from 'mobx';
import validatorjs from 'validatorjs';
import { observer } from 'mobx-react';

import config from 'config';
import { AccountType, BankInfo } from 'models';

import { inject, WithUserStore, WithToastStore } from 'stores';
import { paths } from 'routes';

import { WithStyles, withStyles } from '@material-ui/core/styles';
import {
  Box,
  FormControl,
  Typography,
  MenuItem,
  FormControlLabel,
  Link,
  Icon,
} from '@material-ui/core';

import Api from 'api';
import AnonLayout from 'components/AnonLayout';

import styles from './styles';
import LoadingSpinner from 'components/LoadingSpinner';
import OutlinedInput from 'components/Input/OutlinedInput';
import { Checkbox } from 'components/Checkbox/Checkbox';
import Button from 'components/Button/Button';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const dvr = require('mobx-react-form/lib/validators/DVR');
const MobxReactForm = require('mobx-react-form').default;

const fields = [
  {
    name: 'routingNumber',
    label: 'Routing Number',
    rules: 'required|digits:9',
    value: '',
  },
  {
    name: 'accountNumber',
    label: 'Account Number',
    rules: 'required|digits_between:4,17',
    value: '',
  },
  {
    name: 'accountNumberConfirm',
    label: 'Confirm Account Number',
    rules: 'required|same:accountNumber',
    value: '',
  },
  {
    name: 'bankName',
    label: 'Bank Name',
    rules: 'required|string',
    value: '',
  },
  {
    name: 'accountType',
    label: 'Account Type',
    rules: 'required|string',
    options: Object.values(AccountType),
  },
  {
    name: 'consent',
    label: 'Consent',
    type: 'checkbox',
    value: false,
  },
];

const plugins = {
  dvr: dvr({
    package: validatorjs,
    extend: ({ validator }: { validator: any; form: any }) => {
      validator.setMessages('en', {
        ...validator.getMessages('en'),
        same: 'Account numbers do not match',
        required: 'This field is required',
      });
    },
  }),
};

/**
 * Promise wrapper around callback based Dwolla's fundingSources.create() function
 * https://developers.dwolla.com/resources/dwolla-js/add-a-bank-account.html#dwolla-fundingsources-create
 */
function createFundingSources(dwollaClient: any, dwollaToken: string, bankInfo: BankInfo) {
  return new Promise((resolve, reject) => {
    dwollaClient.fundingSources.create(dwollaToken, bankInfo, (err: any, res: any) => {
      if (err) return reject(err);
      return resolve(res);
    });
  });
}

interface LinkBankHooks {
  onSuccess: (form: any) => void;
  onClear: (form: any) => void;
}

type LinkBankProps = WithStyles<typeof styles> &
  WithUserStore &
  WithToastStore &
  RouteComponentProps;

interface MatchParams {
  encodedUserData: string;
}

/**
 * Displays form for linking bank account directly with Dwolla. Has to have valid
 * base64 encoded JSON string containing basic user information in RouteComponentProps.
 * It works by:
 *  1. Validating and decoding base64 encoded user data from URL
 *  2. Downloading and injecting Dwolla frontend client from Dwolla's official CDN
 *  3. When Dwolla client is loaded and initialized, calling our API for the Dwolla funding source token
 *  4. Waiting for user to submit bank account data form and using Dwolla client to create funding sources
 *  5. Using Dwolla's createFundingSources() response to update user's bank data
 */
@inject('userStore', 'toastStore')
@observer
class LinkBank extends React.Component<LinkBankProps> {
  public constructor(props: LinkBankProps) {
    super(props);
    makeObservable(this);
    this.form = new MobxReactForm({ fields }, { plugins, hooks: this.hooks });
  }

  /** The form object */
  private form: any;

  /** The hooks for the form */
  private hooks: LinkBankHooks = {
    onSuccess: () => {
      if (this.dwollaToken) {
        this.handleSubmit();
      } else {
        this.props.toastStore!.push({
          type: 'error',
          message: 'Missing Dwolla funding source token',
        });
      }
    },
    onClear: () => {
      this.form.clear();
    },
  };

  /** Dwolla token returned by our backend when Dwolla CDN client is loaded */
  public dwollaToken?: string;

  /** Communicating with Dwolla */
  @observable public inProgress = false;

  /** Linking successful */
  @observable public done = false;

  /** Creates the dwolla script and includes it */
  @action.bound public createDwolla() {
    const script = document.createElement('script');
    script.async = true;
    script.src = 'https://cdn.dwolla.com/1/dwolla.min.js';
    // Fetch Dwolla token when CDN script loads
    script.addEventListener('load', this.getDwollaToken);
    document.head.appendChild(script);
  }

  /** Handles the main form submission */
  @action.bound public async handleSubmit() {
    try {
      this.inProgress = true;

      // Grab the values form the form
      const fields = this.form.values();
      const bankInfo: BankInfo = {
        routingNumber: fields.routingNumber,
        accountNumber: fields.accountNumber,
        type: fields.accountType,
        name: fields.bankName,
      };

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const dwollaResp: any = await this.createDwollaFundingSources(bankInfo, this.dwollaToken!);

      // Parse funding source number from Dwolla's response
      const fundingSourceUrl = dwollaResp['_links']['funding-source']['href'];
      const fundingSourceArray = fundingSourceUrl.split('/');
      const fundingSource = fundingSourceArray[fundingSourceArray.length - 1];

      // Get last 4 digits of the account number:
      const accNumber = bankInfo.accountNumber;
      const accountMask = accNumber.substring(accNumber.length - 4, accNumber.length);

      const institutionName = fields.bankName;
      const accountType = fields.accountType;

      await this.updateUsersBankAccount(fundingSource, institutionName, accountMask, accountType);
      this.done = true;
    } catch (err: any) {
      this.props.toastStore!.push({ type: 'error', message: err.message });
    } finally {
      this.inProgress = false;
    }
  }

  /** Get Dwolla's funding source token from core service for this user */
  @action.bound public getDwollaToken = flow(function* (this: LinkBank) {
    const userId = this.props.userStore!.user!.id;
    try {
      const { data } = yield Api.core.getDwollaToken(userId);
      this.dwollaToken = data.data;
    } catch (e: any) {
      const errMsg = e.response ? e.response.data.error.message : 'An error has occurred.';
      // Throw the error and let this.handleSubmit() handle all possible API errors:
      throw new Error(errMsg);
    }
  });

  /** Create Dwolla's funding sources using provided bank info */
  @action.bound public createDwollaFundingSources = flow(function* (
    this: LinkBank,
    bankInfo: BankInfo,
    dwollaToken: string,
  ) {
    // @ts-ignore
    const dwolla = window.dwolla;
    if (config.dwolla.env === 'sandbox') {
      dwolla.configure('sandbox');
    }
    try {
      return yield createFundingSources(dwolla, dwollaToken, bankInfo);
    } catch (e: any) {
      // Dwolla error response is structured differently based on type of error:
      //  - 'err.message' for invalid token error
      //  - 'err['_embedded'].errors' for invalid field(s) error array
      const errMsg = e['_embedded'] ? e['_embedded'].errors[0].message : e.message;
      // Throw the error and let this.handleSubmit() handle all possible API errors:
      throw new Error(errMsg);
    }
  });

  /** Creates a new bank account entry for this user using Dwolla's funding source */
  @action.bound public updateUsersBankAccount = flow(function* (
    this: LinkBank,
    fundingSource: string,
    institutionName: string,
    accountMask: string,
    accountType: string,
  ) {
    const userId = this.props.userStore!.user!.id;
    try {
      yield Api.core.updateBankAccount(
        userId,
        fundingSource,
        institutionName,
        accountMask,
        accountType,
      );
    } catch (e: any) {
      const errMsg = e.response ? e.response.data.error.message : 'An error has occurred.';
      // Throw the error and let this.handleSubmit() handle all possible API errors:
      throw new Error(errMsg);
    }
  });

  componentDidMount() {
    const params = this.props.match.params as MatchParams;
    try {
      // Decode URL string and parse it to JSON
      const userData = JSON.parse(atob(params.encodedUserData));
      const userId = this.props.userStore!.user!.id;
      this.createDwolla();
      // Authenticate current user
      if (userData.id !== userId) {
        throw new Error('User not authorized');
      }
    } catch {
      // URL string cannot be JSON parsed || user is not authenticated
      // Either way, redirect to dashboard and don't worry about error messages.
      this.props.history.push(paths.root());
    }
  }

  isRequired(name: string) {
    if (!this.form.$(name).rules) return false;

    return this.form.$(name).rules.includes('required');
  }

  renderLinkBankForm() {
    const fields = {
      routingNumber: this.form.$('routingNumber'),
      accountNumber: this.form.$('accountNumber'),
      accountNumberConfirm: this.form.$('accountNumberConfirm'),
      bankName: this.form.$('bankName'),
      accountType: this.form.$('accountType'),
      consent: this.form.$('consent'),
    };

    return (
      <>
        <form onSubmit={this.form.onSubmit}>
          <Typography variant="h3" component="h1" align="center" gutterBottom>
            Link Bank
          </Typography>
          <Typography variant="subtitle2" align="center" gutterBottom>
            {`We use Dwolla to send ACH deposits`}
          </Typography>
          {this.inProgress ? (
            <Box width="100%" mt={26} mb={26} display="flex" justifyContent="center">
              <LoadingSpinner />
            </Box>
          ) : (
            <>
              <Box mt={2}>
                <OutlinedInput
                  autoFocus
                  fullWidth
                  {...fields.routingNumber.bind()}
                  label={fields.routingNumber.label}
                  required={this.isRequired('routingNumber')}
                  error={fields.routingNumber.error}
                />
              </Box>
              <Box mt={2}>
                <OutlinedInput
                  autoFocus
                  fullWidth
                  {...fields.accountNumber.bind()}
                  label={fields.accountNumber.label}
                  required={this.isRequired('accountNumber')}
                  error={fields.accountNumber.error}
                />
              </Box>
              <Box mt={2}>
                <OutlinedInput
                  autoFocus
                  fullWidth
                  {...fields.accountNumberConfirm.bind()}
                  label={fields.accountNumberConfirm.label}
                  required={this.isRequired('accountNumberConfirm')}
                  error={fields.accountNumberConfirm.error}
                />
              </Box>
              <Box mt={2}>
                <OutlinedInput
                  autoFocus
                  fullWidth
                  {...fields.bankName.bind()}
                  label={fields.bankName.label}
                  required={this.isRequired('bankName')}
                  error={fields.bankName.error}
                />
              </Box>
              <Box mt={2}>
                <OutlinedInput
                  autoFocus
                  fullWidth
                  {...fields.accountType.bind()}
                  label={fields.accountType.label}
                  required={this.isRequired('accountType')}
                  error={fields.accountType.error}
                  select>
                  {fields.accountType.options.map((option: AccountType) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </OutlinedInput>
              </Box>
              <Box mt={2} mb={4}>
                <FormControl component="fieldset">
                  <FormControlLabel
                    control={<Checkbox {...fields.consent.bind()} color="primary" />}
                    label={
                      <Typography variant="subtitle2" gutterBottom>
                        By linking bank account you agree to Dwolla’s{' '}
                        <Link
                          target="_blank"
                          href="https://www.dwolla.com/legal/tos/"
                          color="primary"
                          rel="noopener">
                          Terms of Service
                        </Link>{' '}
                        and{' '}
                        <Link
                          target="_blank"
                          href="https://www.dwolla.com/legal/privacy/"
                          color="primary"
                          rel="noopener">
                          Privacy Policy
                        </Link>
                        .
                      </Typography>
                    }
                    labelPlacement="end"
                  />
                </FormControl>
              </Box>
            </>
          )}

          <Box mt={4}>
            <Button
              size="large"
              type="submit"
              variant="contained"
              color="primary"
              disabled={this.inProgress || !this.form.$('consent').value}
              fullWidth
              onClick={this.form.onSubmit}>
              link bank
            </Button>
          </Box>
        </form>
      </>
    );
  }

  renderSuccessView() {
    const { classes } = this.props;
    return (
      <Box textAlign="center">
        <Typography variant="h3" component="h1" align="center">
          {'Success'}
        </Typography>
        <Box textAlign="center" mt={3} mb={3}>
          <Icon color={'primary'} className={classes.statusIcon}>
            {'check'}
          </Icon>
        </Box>
        <Typography align="center">Bank linked successfully</Typography>
        <Box mt={6}>
          <Typography align="left" component="div">
            <Link component={RouterLink} to={paths.root()}>
              ← Back to dashboard
            </Link>
          </Typography>
        </Box>
      </Box>
    );
  }

  render() {
    return (
      <AnonLayout>{this.done ? this.renderSuccessView() : this.renderLinkBankForm()}</AnonLayout>
    );
  }
}

export default withStyles(styles)(LinkBank);
