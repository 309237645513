import { rootStore } from 'containers/App/App';
import { ISignupLayoutProps, TSignupLayoutIcon } from './SignupLayout';
import theme from 'containers/App/theme';

type TUseSignupLayoutProps = Pick<
  ISignupLayoutProps,
  'icon' | 'subtitle' | 'action' | 'contentWrapperStyles'
>;

const useSignupLayout = ({
  icon,
  subtitle,
  action,
  contentWrapperStyles,
}: TUseSignupLayoutProps) => {
  const { mobileView } = rootStore.uiStore;
  const iconHeight = mobileView ? 56 : 100;
  const padding = getPadding(mobileView);
  const subtitleMt = mobileView ? 4 : 2;
  const iconMb = getIconMb({ mobileView, icon });
  const contentStyles = getContentStyles({ subtitle, action, contentWrapperStyles });

  return {
    iconHeight,
    padding,
    subtitleMt,
    iconMb,
    contentStyles,
  };
};

export default useSignupLayout;

const getPadding = (mobileView: boolean) => {
  let pt = 13;
  let p = 0.5;
  if (mobileView) {
    pt = 2;
    p = pt;
  }
  return theme.spacing(pt, p, 0);
};

const getIconMb = ({ mobileView, icon }: { mobileView: boolean; icon?: TSignupLayoutIcon }) => {
  let mb = 7;
  if (mobileView) {
    mb = 3;
  }
  return icon ? 6 : mb;
};

const getContentStyles = ({
  subtitle,
  action,
  contentWrapperStyles,
}: Pick<TUseSignupLayoutProps, 'subtitle' | 'action' | 'contentWrapperStyles'>) => {
  const contentProps = {
    marginBottom: !action ? theme.spacing(0) : theme.spacing(4),
    ...contentWrapperStyles,
  };
  if (subtitle) return { marginTop: theme.spacing(4), ...contentProps };
  return { marginTop: theme.spacing(11), ...contentProps };
};
