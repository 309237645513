export interface Pool {
  id: number;
  accountId: number;
  locationId: number;
  name: string;
  description: string;
  isActive: boolean;
  requiresCustomerName: boolean;
  requiresRoomNumber: boolean;
  requiresNote: boolean;
  requiresReview: boolean;
  tippingMode: TippingMode;
  lowAmount: number;
  mediumAmount: number;
  highAmount: number;
  customerInstructions: string;
  uid: string;
}
/**
 * Tipping mode
 */
export enum TippingMode {
  ONCE = 'once',
  PER_NIGHT = 'per_night',
  // PER_STAY = 'per_stay',
}
