import { ApiResponse, getErrorMsg } from 'api';
import { rootStore } from 'containers/App/App';
import { IMFAFactor, IMFAFactorRequest, MFAChannel, MFAType } from 'models';
import api from 'api';
import axios, { AxiosResponse } from 'axios';
import validatorjs from 'validatorjs';
import { capitalize } from 'utils/helper';
import _ from 'lodash';
import IMFAResult from 'models/Mfa';
import { AuthInputProps } from 'components/Input/AuthInput/AuthInput';

const dvr = require('mobx-react-form/lib/validators/DVR');
const MobxReactForm = require('mobx-react-form').default;

export const isMfaTypeLogin = () => {
  const { mfaStore } = rootStore;
  return mfaStore.MfaResult && mfaStore.MfaResult.type === MFAType.USER_LOGIN;
};

const extractConfigBody = () => {
  const { config } = rootStore.mfaStore;
  if (_.isObject(config.data)) {
    return config.data;
  }
  const body = JSON.parse(config.data);
  return body;
};

const addMfaDataToConfig = () => {
  const { config, mfaBody: mfa } = rootStore.mfaStore;
  const originalBody = extractConfigBody();
  config.data = { ...originalBody, mfa };
  return config;
};

type VerifyMfaData = (
  onSuccessCallback?: (response?: any) => void,
) => Promise<AxiosResponse<ApiResponse<any>> | null>;
export const verifyMfaData: VerifyMfaData = async (onSuccessCallback) => {
  const { resetStore, setVerifyingMfaData, resetVerifyingMfaData } = rootStore.mfaStore;
  const { setCloseMfaDialog, showMfaDialog } = rootStore.globalDialogStore;

  setVerifyingMfaData();
  try {
    const config = addMfaDataToConfig();
    const response = await axios(config);
    onVerifyDataSuccess(response.data.data);
    if (onSuccessCallback) {
      onSuccessCallback(response);
    }
    if (!showMfaDialog) {
      resetStore();
    } else {
      setCloseMfaDialog();
    }
    return response;
  } catch (error) {
    return null;
  } finally {
    resetVerifyingMfaData();
  }
};

export const onVerifyDataSuccess = (response: any) => {
  const { MfaResult } = rootStore.mfaStore;
  const { toastStore, userStore } = rootStore;
  if (!MfaResult) return;

  const { loginWithData, setUserData } = rootStore.userStore;
  const { type } = MfaResult;
  if (type === MFAType.USER_LOGIN) {
    return loginWithData(response);
  }
  if (type === MFAType.CONFIRM_PHONE) {
    toastStore.success('Phone updated successfully');
    return setUserData(response);
  }
  if (type === MFAType.CONFIRM_EMAIL) {
    toastStore.success('Email updated successfully');
    userStore!.refreshTokenAndUpdateUserData(response.email);
  }
  if (type === MFAType.CHANGE_PASSWORD) {
    return toastStore.success('Password updated successfully');
  }
};

export const resendMfaCode = async (name: MFAChannel) => {
  const { toastStore, mfaStore } = rootStore;
  const { setSendingMfaCode, resetSendingMfaCode } = mfaStore;
  setSendingMfaCode();
  try {
    const isMfaLogin = isMfaTypeLogin();
    if (isMfaLogin) {
      const { loginConfig } = mfaStore;
      await axios.request(loginConfig);
    } else {
      const body = {
        type: mfaStore.MfaResult!.type,
        channels: [name],
      };
      const { data: mfa } = await api.core.sendMfaCode(body);
      mfaStore.setMfaResult(mfa.data!);
    }
  } catch (e: any) {
    if (e.response.status !== 901) {
      toastStore.error(getErrorMsg(e));
    } else {
      mfaStore.setMfaResult(e.response.data.error.body.mfa);
    }
  } finally {
    resetSendingMfaCode();
  }
};

export const getMinutesAndSeconds = (_seconds: number) => {
  const minutes = Math.floor(_seconds / 60);
  const seconds = Math.floor(_seconds % 60);
  return { minutes, seconds };
};

export const mapCodesToFactors = (form: MobxForm): IMFAFactorRequest[] => {
  const factors = Array.from(form.fields).map(([name, field]: any) => {
    return {
      channel: name,
      code: field.value,
    };
  });
  return factors;
};

/**
 *
 * @param time object with minutes and seconds properties
 * @returns Minutes and seconds object with stringified values. Seconds are prefixed with 0 in order to display 2 digit value
 */
export const formatTime = (time: { minutes: number; seconds: number }) => {
  let _time = { minutes: `${time.minutes}`, seconds: `${time.seconds}` };
  if (time.seconds < 10) {
    _time.seconds = `0${time.seconds}`;
  }
  return _time;
};

type MobxForm = any;

interface IRules {
  [key: string]: {
    function: (value: any) => boolean;
    message: string;
  };
}

let rules: IRules = {};
const plugins = {
  dvr: dvr({
    package: validatorjs,
    extend: ({ validator, form }: { validator: validatorjs.ValidatorStatic; form: MobxForm }) => {
      Object.keys(rules).forEach((key) => {
        validator.register(key, rules[key].function, rules[key].message);
      });
    },
  }),
};

export const setupMfaComponentForm = (factors?: IMFAFactor[]) => {
  const channels = extractChannels(factors);
  if (!channels) return;

  let fields = channels.map((field: MFAChannel) => {
    const name = field;
    rules[`${name}_token`] = tokenRule('digits', 6, name);
    return {
      name,
      rules: `required|${name}_token`,
      hooks: {
        onChange: (field: any) => {
          if (!field.isPristine) {
            field.validate({ showErrors: true });
            removeMfaChannelError(field.name);
          }
        },
      },
    };
  });
  return new MobxReactForm({ fields }, { plugins });
};

export const tokenRule = (type: AuthInputProps['type'], length: number, name: string) => {
  return {
    function: (value: string) => {
      let regex;
      switch (type) {
        case 'digits':
          regex = new RegExp(`^\\d{${length}}$`);
          break;
        case 'text':
          regex = new RegExp(`^[\\w\\d]{${length}}$`);
          break;
        default:
          regex = new RegExp(`^\\d{${length}}$`);
          break;
      }
      return regex.test(value);
    },
    message: `${capitalize(name)} code is required.`,
  };
};

export const extractChannels = (factors?: IMFAFactor[]) => {
  if (!factors) return null;
  const channels = factors?.map((factor: IMFAFactor) => {
    return factor.channel;
  });
  return channels;
};

export const getFactorByName = (factors?: IMFAFactor[], name?: MFAChannel) => {
  if (!factors || !name) return null;
  const factor = factors?.find((factor) => factor.channel === name);
  return factor;
};

export const mergeNewMfaObject = (newMfaData: IMFAResult) => {
  const { mfaStore } = rootStore;
  if (!mfaStore.MfaResult) return addExpiresAtToMfaResult(newMfaData);

  const { factors, expiresAt } = newMfaData;
  const newFactors = mfaStore.MfaResult.factors.map((factor) => {
    const newFactor = factors.find((f) => f.channel === factor.channel);
    if (newFactor) {
      if (expiresAt) {
        newFactor.expiresAt = expiresAt;
      }
      newFactor.error = newFactor.error || undefined;
      return { ...factor, ...newFactor };
    }
    return factor;
  });
  return { ...mfaStore.MfaResult, factors: newFactors };
};

const addExpiresAtToMfaResult = (mfaResult: IMFAResult) => {
  let { factors, expiresAt, type } = mfaResult;
  factors = mfaResult.factors.map((factor) => {
    return { ...factor, expiresAt };
  });
  return { type, factors, expiresAt };
};

const removeMfaChannelError = (channel: IMFAFactor['channel']) => {
  let mfaResult = rootStore.mfaStore?.MfaResult;
  if (!mfaResult) return;
  mfaResult = { ...(rootStore.mfaStore.MfaResult as IMFAResult) };
  const factors = mfaResult.factors.map((factor) => {
    if (factor.channel === channel && factor.error) {
      delete factor.error;
      return factor;
    }
    return factor;
  });
  rootStore.mfaStore.setMfaResult({ ...mfaResult, factors: [...factors] });
};

export const getTokenExpiration = (channel: MFAChannel) => {
  return rootStore.mfaStore.MfaResult?.factors.find((f) => f.channel === channel)!.expiresAt;
};
