import { Theme, createStyles } from '@material-ui/core';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default (theme: Theme) =>
  createStyles({
    root: {},
    cursorPointer: {
      cursor: 'pointer',
    },
    paymentSourceForm: {
      minWidth: theme.spacing(15),
      color: theme.palette.grey[500],
    },
    paymentSourceLabel: {
      marginRight: theme.spacing(1),
    },
    pageTitle: {
      fontWeight: 600,
      fontSize: '34px',
      color: theme.palette.text.primary
    }
  });
