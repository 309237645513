import { Theme, createStyles, makeStyles } from '@material-ui/core';
//import { fade } from '@material-ui/core/styles/colorManipulator';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const styles = (theme: Theme) =>
  createStyles({
    root: {},
    avatar: {
      '& .MuiAvatar-root': {
        width: 22,
        height: 22,
      },
    },
    tooltip: {
      '& .MuiTooltip-popper': {
        '& .MuiTooltip-tooltip': {
          backgroundColor: theme.palette.common.white,
        },
      },
    },
  });

export default styles;

export const useStyles = makeStyles(styles);
