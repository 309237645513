import { Theme, createStyles } from '@material-ui/core';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default (theme: Theme) =>
  createStyles({
    root: {},
    markerSize: {
      width: 30,
      height: 30,
    },
    popupTitle: {
      fontSize: 20,
      fontWeight: 'bold',
    },
    popupText: {
      fontSize: 16,
      color: 'secondary',
    },
  });
