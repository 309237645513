import React from 'react';
import { action, makeObservable } from 'mobx';
import { observer } from 'mobx-react';
import { Box, Typography } from '@material-ui/core';

import Api, { getErrorMsg, PagedApiResponse, RequestMetaData } from 'api';
import { inject, WithToastStore } from 'stores';
import { User } from 'models';

import AutocompleteSearch from 'components/AutocompleteSearch';
import ImageIcon from 'components/ImageIcon';
import { AxiosResponse } from 'axios';

interface UserSearchProps extends WithToastStore {
  onChange: (a: User | null) => void;
  fetch?: {
    method: (
      rmd?: RequestMetaData,
      extraData?: Record<string, unknown>,
    ) => Promise<AxiosResponse<PagedApiResponse<User>>>;
    request?: { rmd: RequestMetaData; extraData?: Record<string, unknown> };
  };
  value?: User | null;
  label?: string;
  placeholder?: string;
  autoFocus?: boolean;
  disabled?: boolean;
  accountId?: number;
  locationId?: number;
  displayAvatar?: boolean;
  displayOnlyTalents?: boolean;
}

/** A component that displays a field for searching for users */
@inject('toastStore')
@observer
class UserSearch extends React.Component<UserSearchProps> {
  constructor(props: UserSearchProps) {
    super(props);
    makeObservable(this);
  }
  /** Gets the label for an affiliate in the autocomplete search */
  private getUserOptionLabel(user?: User): string {
    if (!user) {
      // Sometimes we get undefined here, not sure why
      return '';
    } else {
      return `${user.firstName} ${user.lastName}`;
    }
  }

  /** Renders the option for affiliates */
  @action.bound private renderUserOption(user: User): React.ReactNode {
    const { displayAvatar } = this.props;
    return (
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        width="100%">
        <Box display="flex" flexDirection="row" alignItems="center">
          {displayAvatar && (
            <Box pr={2}>
              <ImageIcon src={user.avatar} />
            </Box>
          )}
          <Box>
            {user.firstName} {user.lastName}
          </Box>
        </Box>
        <Box>
          <Typography color="primary">{user.email}</Typography>
        </Box>
      </Box>
    );
  }

  /** Fetch the affiliates by search string */
  @action.bound public async searchUsers(search: string): Promise<User[]> {
    const { accountId, locationId, displayOnlyTalents } = this.props;
    try {
      let queryParams = { search, accountId, locationId };
      if (displayOnlyTalents) {
        queryParams = {
          // @ts-ignore
          talent: true,
          isActive: true,
          isVisible: true,
          locationId,
          accountId,
          search,
        };
      }

      let resp = undefined;
      const fetch = this.props.fetch;
      if (fetch) {
        const request = fetch.request;
        const rmd = request ? request.rmd : {};
        const extraData = request && request.extraData ? request.extraData : {};
        resp = await fetch.method(
          { ...rmd, pagination: { take: 10, skip: 0 }, filters: { ...queryParams } },
          { ...extraData },
        );
      } else {
        resp = await Api.core.getUsers({ pagination: { take: 10, skip: 0 } }, { ...queryParams });
      }

      if (resp.data) {
        return resp.data.data!;
      } else {
        return [];
      }
    } catch (e: any) {
      this.props.toastStore!.push({ type: 'error', message: getErrorMsg(e) });
      return [];
    }
  }
  render() {
    const { onChange, label, autoFocus, placeholder, value, disabled } = this.props;
    return (
      <AutocompleteSearch
        fetch={this.searchUsers}
        onChange={onChange}
        label={label}
        value={value}
        autoFocus={autoFocus}
        disabled={disabled}
        getOptionLabel={this.getUserOptionLabel}
        getOptionSelected={(o, v) => o.id === v.id}
        renderOption={this.renderUserOption}
        placeholder={placeholder}
      />
    );
  }
}

export default UserSearch;
