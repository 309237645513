import React from 'react';
import { computed, observable, action, flow, toJS, makeObservable } from 'mobx';
import { observer } from 'mobx-react';
import { Route } from 'react-router-dom';
import { RouteComponentProps, Redirect, Link as RouterLink } from 'react-router-dom';
import { WithStyles, withStyles } from '@material-ui/core/styles';

import {
  inject,
  WithUserStore,
  WithToastStore,
  WithModalStore,
  UserScopes,
  WithManagerPermissionsStore,
} from 'stores';
import Api, { ApiError } from 'api';
import { User } from 'models';
import { paths } from 'routes';
import DashboardLayout from 'containers/DashboardLayout';
import Title from 'components/Title';

import styles from './styles';
import Info from './Info';
import Wallet from './Wallet/Wallet';
import Tips from './Tips';
import { Box, Switch, Tooltip } from '@material-ui/core';
import { setTitle } from '../../services';
import Workspace from './Workspace/Workspace';
import WorkspaceProvider from './Workspace/WorkspaceContext';
import WorkspaceDialogProvider from './Workspace/WorkspaceDialogContext';
import TabBar from 'components/TabBar/TabBar';
import { EManagerPermission } from 'types';

interface UserDetailsMatchParams {
  id: string;
}
type UserDetailsProps = WithStyles<typeof styles> &
  RouteComponentProps<UserDetailsMatchParams> & // Adds the router props (history, match, location)
  WithManagerPermissionsStore &
  WithToastStore & // Adds the toastStore prop
  WithUserStore & // Adds the userStore prop
  WithModalStore; // Adds the modalStore prop

/** Displays a user's details to admins, owners or managers */
@inject('userStore', 'toastStore', 'managerPermissionsStore', 'modalStore')
@observer
class UserDetails extends React.Component<UserDetailsProps> {
  constructor(props: UserDetailsProps) {
    super(props);
    makeObservable(this);
  }
  /** Current scope */
  @observable private scope = this.props.userStore!.scope;

  /** The user object that we fetch */
  @observable public user?: User;

  /** The error that occurred, if any */
  @observable public error?: ApiError;

  /** Is this user activated? */
  @observable public isActive? = false;

  /** The id of the user we're currently viewing */
  @computed public get userId(): number {
    return parseInt(this.props.match.params.id);
  }
  /** The loaded user's full name */
  @computed public get fullName(): string | undefined {
    return this.user && `${this.user.firstName} ${this.user.lastName}`;
  }

  /** Whether this user's wallet can be viewed */
  @computed public get canViewWallet(): boolean {
    return this.scope.kind === 'admin';
  }

  /** Whether this user's wallet can be viewed */
  @computed public get canToggleUsersStatus(): boolean {
    return this.scope.kind === 'admin';
  }

  /** Whether this user's tips can be viewed */
  @computed public get canViewTips(): boolean {
    const scope = this.scope.kind;
    if ([UserScopes.OWNER, UserScopes.GLOBAL_OWNER, UserScopes.ADMIN].includes(scope as UserScopes))
      return true;

    if (this.props.managerPermissionsStore?.hasPermission(EManagerPermission.VIEW_USER_TIPS))
      return true;

    return false;
  }

  @computed public get tabs() {
    const userDetailsPath = paths.userDetails(this.userId);
    const { pathname } = this.props.location;
    return [
      {
        label: 'Info',
        component: RouterLink,
        to: userDetailsPath.info(),
        selected: pathname === userDetailsPath.info(),
      },
      {
        label: 'Workspace',
        component: RouterLink,
        to: userDetailsPath.workspace(),
        selected: pathname === userDetailsPath.workspace(),
      },
      {
        label: 'Wallet',
        component: RouterLink,
        to: userDetailsPath.wallet(),
        selected: pathname === userDetailsPath.wallet(),
        hide: !this.canViewWallet,
      },
      {
        label: 'Tips',
        component: RouterLink,
        to: userDetailsPath.tips(),
        selected: pathname === userDetailsPath.tips(),
        hide: !this.canViewTips,
      },
    ];
  }

  /** Fetches the user with id equal to this.userId */
  @action.bound public getUser = flow(function* (this: UserDetails) {
    try {
      const resp = yield Api.core.getUser(this.userId);
      this.user = resp.data && resp.data.data;
      this.isActive = this.user && this.user.isActive;
    } catch (e: any) {
      // If there was an error getting the user, display an error in the snackbar
      if (e.response && e.response.data && e.response.data.error) {
        this.error = e.response.data.error;
        this.props.toastStore!.push({ type: 'error', message: this.error!.message });
      } else {
        throw e;
      }
    }
  });

  /** Toggle user's status (user.isActive: true || false) */
  @action.bound public toggleUserStatus = flow(function* (this: UserDetails) {
    const modalTitle = `${this.isActive ? `Deactivate` : `Activate`} User`;
    const modalMessage = `Are you sure you want to ${
      this.isActive ? `deactivate` : `activate`
    } this user?`;

    // Request additional action confirmation via confirmation dialog
    const confirmed = yield this.props.modalStore!.confirm(modalTitle, modalMessage, {
      confirmLabel: this.isActive ? `deactivate` : `activate`,
    });

    if (confirmed) {
      try {
        const resp = yield Api.core.updateUser(this.userId, { isActive: !this.isActive });
        this.user = resp.data && resp.data.data;
        this.isActive = this.user && this.user.isActive;
        this.props.toastStore!.push({
          type: 'success',
          message: `User successfully ${this.isActive ? `activated` : `deactivated`}`,
        });
      } catch (e: any) {
        // If there was an error getting the user, display an error in the snackbar
        if (e.response && e.response.data && e.response.data.error) {
          this.error = e.response.data.error;
          this.props.toastStore!.push({ type: 'error', message: this.error!.message });
        } else {
          throw e;
        }
      }
    }
  });

  /**
   * Pass to other child components that change the user,
   * so that this component can be notified of the changes.
   */
  @action.bound public handleUserChanged(user: User) {
    this.user = toJS(user);
  }
  async componentDidMount() {
    await this.getUser();
    setTitle(String(this.fullName), { noSuffix: false });
  }
  render() {
    const userDetailsPath = paths.userDetails(this.userId);
    const { pathname } = this.props.location;
    if (this.error) {
      // TODO: We might want to redirect to something other than the root?
      return <Redirect to={paths.root()} />;
    }
    return (
      <DashboardLayout>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          justifyItems="center">
          <Title mb={3} showLogo={true} src={this.user && this.user.avatar}>
            {this.fullName}
          </Title>
          {this.canToggleUsersStatus && (
            <Tooltip title={this.isActive ? 'Active' : 'Inactive'}>
              <Switch checked={this.isActive} onChange={this.toggleUserStatus} color="primary" />
            </Tooltip>
          )}
        </Box>
        <TabBar mb={3} tabs={this.tabs} />
        <Route
          path={userDetailsPath.info()}
          exact
          render={() => (
            <Info
              user={this.user}
              userId={this.userId}
              handleUserChanged={this.handleUserChanged}
              canViewWallet={this.canViewWallet}
            />
          )}
        />
        <Route
          path={userDetailsPath.workspace()}
          exact
          render={() => (
            <WorkspaceProvider userId={this.userId}>
              <WorkspaceDialogProvider>
                <Workspace user={this.user} userId={this.userId} />
              </WorkspaceDialogProvider>
            </WorkspaceProvider>
          )}
        />
        {this.canViewWallet && (
          <Route
            path={userDetailsPath.wallet()}
            exact
            render={() => <Wallet userId={this.userId} {...this.props} />}
          />
        )}
        {this.canViewTips && (
          <Route
            path={userDetailsPath.tips()}
            exact
            render={() => <Tips userId={this.userId} {...this.props} />}
          />
        )}
      </DashboardLayout>
    );
  }
}

export default withStyles(styles)(UserDetails);
