import React from 'react';
import {
  action,
  computed,
  observable,
  flow,
  reaction,
  toJS,
  makeObservable,
  IReactionDisposer,
} from 'mobx';
import { observer } from 'mobx-react';
import { Link as RouteComponentProps } from 'react-router-dom';

import { paths } from 'routes';

import { WithStyles, withStyles } from '@material-ui/core/styles';
import {
  Box,
  Drawer,
  FormControl,
  FormGroup,
  Fade,
  Link,
  List,
  ListItem,
  ListItemText,
  FormLabel,
  Paper,
  CircularProgress,
  Button,
} from '@material-ui/core';

import Api, { getErrorMsg, PagedApiResponse } from 'api';
import { inject, WithUserStore, WithToastStore } from 'stores';
import { Customer, Account } from 'models';

import DashboardLayout from 'containers/DashboardLayout';
import SearchBox from 'components/SearchBox';
import AccountSearch from 'components/AccountSearch';
import DD from 'components/DashDrawer';
import DateRangePicker, * as DateRangeExternalPicker from 'components/DateRangeExternalPicker';
import Title from 'components/Title';
import styles from './styles';
import { AxiosResponse } from 'axios';
import { setTitle } from 'services';

const PAGE_TITLE = 'Customers';

type CustomersProps = WithStyles<typeof styles> & // Adds the classes prop
  RouteComponentProps & // Adds the router props (history, match, location)
  WithToastStore &
  WithUserStore; // Adds the userStore prop

interface CustomersFilters {
  account?: Account;
  dateRange: {
    from?: string;
    to?: string;
  };
}

const initialFilters = {
  dateRange: {},
};

/**
 * Enables the user to search customers and view information about them
 */
@inject('userStore', 'toastStore')
@observer
class Customers extends React.Component<CustomersProps> {
  constructor(props: any) {
    super(props);
    makeObservable(this);

    this.disposers.push(
      reaction(
        () => [this.skip, this.search, this.appliedFilters],
        () => this.getSearchResults(),
      ),
    );
  }

  private disposers: IReactionDisposer[] = [];

  @observable public dateRange: DateRangeExternalPicker.DateRange = DateRangeExternalPicker.getDateRange('all');

  /** The search string */
  @observable public search = '';

  /** The page size */
  public pageSize = 40;

  /** The total number of results, in all pages */
  @observable public count?: number;

  /** How many items to skip on the next request */
  @observable public skip = 0;

  /** How many times we've fetched the users */
  @observable public fetchCount = 0;

  /** Whether the search results are being fetched */
  @observable public fetchingResults = false;

  /** The search results */
  @observable public customers?: Customer[];

  /** The filters that are currently selected int he drawer */
  @observable public drawerFilters: CustomersFilters = toJS(initialFilters);

  /** Once drawerFilters are applied, they are copied here */
  @observable public appliedFilters = toJS(this.drawerFilters);

  /** Whether the filters drawer is currently being shown */
  @observable public showingFilterDrawer = false;

  /** Show the filter drawer */
  @action.bound public showFilterDrawer() {
    this.showingFilterDrawer = true;
  }

  /** Hide the filter drawer */
  @action.bound public hideFilterDrawer() {
    // Reset the drawer filters to the applied ones
    this.showingFilterDrawer = false;
    this.drawerFilters = toJS(this.appliedFilters);
  }

  /** Applies the filters and closes the drawer */
  @action.bound public applyFilters() {
    this.reset();
    this.appliedFilters = toJS(this.drawerFilters);
    this.showingFilterDrawer = false;
  }

  /** Resets the filters */
  @action.bound public resetFilters() {
    this.reset();
    this.appliedFilters = toJS(initialFilters);
    this.drawerFilters = toJS(this.appliedFilters);
    this.showingFilterDrawer = false;
  }

  /** SearchBox calls this to update the search string */
  @action.bound public updateSearch(s: string) {
    this.reset();
    this.search = s;
  }

  /** Resets the search results */
  @action.bound public reset() {
    this.customers = undefined;
    this.skip = 0;
    this.fetchCount = 0;
    this.count = undefined;
  }

  /** Calls the API to search for customers */
  @action.bound public getSearchResults = flow(function* (this: Customers) {
    try {
      // If the search string is empty, just reset everything and don't call the API
      if (this.search.length === 0) {
        this.reset();
        return;
      }
      this.fetchingResults = true;
      const resp: AxiosResponse<PagedApiResponse<Customer>> = yield Api.tips.searchCustomers({
        pagination: {
          take: this.pageSize,
          skip: this.skip,
        },
        filters: {
          search: this.search,
          accountId: this.appliedFilters.account && this.appliedFilters.account.id,
          startDate: this.appliedFilters.dateRange.from,
          endDate: this.appliedFilters.dateRange.to,
        },
      });
      if (resp.data.data) {
        // If customers hasn't been loaded yet, set it to
        // an empty array
        if (this.customers === undefined) {
          this.customers = [];
        }
        // Push the results to the existing set of customers
        this.customers.push(...resp.data.data);
        this.count = resp.data.count;
      }
    } catch (e: any) {
      this.props.toastStore!.error(getErrorMsg(e));
    } finally {
      this.fetchingResults = false;
    }
  });

  /** Sets the account in the drawers filters */
  @action.bound public updateAccount(acc: Account | null) {
    this.drawerFilters.account = acc ? acc : undefined;
  }

  /** Sets the date range */
  @action.bound public updateDateRange(range: DateRangeExternalPicker.DateRange) {
    this.dateRange = range;
    this.drawerFilters.dateRange.from = range.fromDate;
    this.drawerFilters.dateRange.to = range.toDate;
  }

  /** Whether any filters have been modified */
  @computed public get filtersActive() {
    return (
      Boolean(this.appliedFilters.account) ||
      Boolean(this.appliedFilters.dateRange.from) ||
      Boolean(this.appliedFilters.dateRange.to)
    );
  }

  /**
   * Loads the next page of customers. There's a reaction on
   * this.skip that reruns fetchUsers every time this.skip
   * changes
   */
  @action.bound public loadMore() {
    this.skip = this.skip + this.pageSize;
  }

  /** Whether to display the load more button */
  @computed public get showLoadMore() {
    return Boolean(
      !this.fetchingResults && this.count && this.customers && this.count > this.customers.length,
    );
  }

  /** Set up a reaction to fetch the search results whenever this.skip or this.search changes */
  disposeGetSearchResults = reaction(
    () => [this.skip, this.search, this.appliedFilters],
    () => this.getSearchResults(),
  );

  componentDidMount() {
    setTitle(PAGE_TITLE, { noSuffix: false });
  }
  /** Dispose of reactions */
  componentWillUnmount() {
    this.disposeGetSearchResults();
  }

  render() {
    const { classes } = this.props;
    return (
      <DashboardLayout>
        <Title mb={3}>{PAGE_TITLE}</Title>
        <Box mb={3}>
          <SearchBox
            onChange={this.updateSearch}
            debounce={500}
            placeholder="Enter name"
            toggleFilters={this.showFilterDrawer}
            filtersActive={this.filtersActive}
            className={classes.textFieldInput}
            autoFocus
          />
        </Box>
        {this.customers && this.customers.length === 0 && (
          <Box className={classes.message}>No results matching your criteria</Box>
        )}
        {this.customers && this.customers.length > 0 && (
          <Box mb={3}>
            <Paper>
              <List>
                {this.customers.map((customer) => (
                  <Link
                    key={customer.id}
                    component={RouteComponentProps}
                    to={{
                      pathname: paths.customerPayments(customer.id),
                      state: { customerName: `${customer.firstName} ${customer.lastName}` },
                    }}>
                    <ListItem button divider>
                      <ListItemText
                        primaryTypographyProps={{ color: 'primary' }}
                        primary={`${customer.firstName} ${customer.lastName}`}
                      />
                    </ListItem>
                  </Link>
                ))}
              </List>
            </Paper>
          </Box>
        )}
        {this.showLoadMore && (
          <Box display="flex" justifyContent="center">
            <Button onClick={this.loadMore} color="primary" variant="text">
              Load more
            </Button>
          </Box>
        )}
        {this.fetchingResults && (
          <Box display="flex" justifyContent="center" alignItems="center" mb={2} mt={2}>
            <CircularProgress />
          </Box>
        )}
        <Drawer
          open={this.showingFilterDrawer}
          onClose={this.hideFilterDrawer}
          anchor="right"
          className={classes.drawer}
          variant="temporary">
          <DD>
            <DD.Content>
              <DD.Title onClose={this.hideFilterDrawer}>Filters</DD.Title>
              <Box mb={2}>
                <AccountSearch
                  label="Account"
                  placeholder="Enter name"
                  value={this.drawerFilters.account}
                  onChange={this.updateAccount}
                />
              </Box>
              <FormControl component="fieldset" fullWidth>
                <FormLabel>With payments made</FormLabel>
                <Box mt={1}>
                  <FormGroup>
                    <DateRangePicker
                      onChange={this.updateDateRange}
                      predefinedRange={this.dateRange}
                      isFormField
                    />
                  </FormGroup>
                </Box>
              </FormControl>
            </DD.Content>
            <DD.Actions>
              <Fade in={this.filtersActive}>
                <DD.ResetButton onClick={this.resetFilters}>Reset</DD.ResetButton>
              </Fade>
              <DD.ApplyButton onClick={this.applyFilters}>Apply</DD.ApplyButton>
            </DD.Actions>
          </DD>
        </Drawer>
      </DashboardLayout>
    );
  }
}

export default withStyles(styles)(Customers);
