import { Theme, createStyles } from '@material-ui/core';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default (theme: Theme) =>
  createStyles({
    root: {
      cursor: 'pointer',
      position: 'relative',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: `${theme.spacing(2)}px ${theme.spacing(3)}px`,
      marginBottom: theme.spacing(2),
    },
    addInventoryBox: {
      width: theme.breakpoints.values.sm,
    },
    addHardwareBox: {
      width: theme.breakpoints.values.md,
    },
    fab: {
      position: 'fixed',
      bottom: theme.spacing(3),
      right: theme.spacing(2),
    },
    linkDeviceRoot: {
      position: 'relative',
      padding: theme.spacing(3),
      width: theme.breakpoints.values.sm,
    },
    chipAvailable: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
    chipReturned: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
    chipLost: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
    chipBroken: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
    chipInUse: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
    chipReturning: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
  });
