import React from 'react';
import { observable, makeObservable } from 'mobx';
import { observer } from 'mobx-react';

import { WithStyles, withStyles } from '@material-ui/core/styles';
import { Box, Typography } from '@material-ui/core';

import { inject, WithToastStore } from 'stores';

import validatorjs from 'validatorjs';
import { isPhone, isZip } from 'services/validators';

import styles from './styles';
import OutlinedInput from 'components/Input/OutlinedInput';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const dvr = require('mobx-react-form/lib/validators/DVR');
const MobxReactForm = require('mobx-react-form').default;

type PersonalInfoProps = {
  getForm(fields: any): any;
  color: string;
} & WithStyles<typeof styles> &
  WithToastStore;

const plugins = {
  dvr: dvr({
    package: validatorjs,
    extend: ({ validator }: { validator: any; form: any }) => {
      validator.register('zip', isZip, 'Please input a valid zip code');
      validator.register('phone', isPhone, 'The phone number is not a valid format');
      validator.setMessages('en', {
        ...validator.getMessages('en'),
        firstName: 'Please input a valid first name',
        lastName: 'Please input a valid last name',
        email: 'Please input a valid email',
        phone: 'Please input a valid phone',
      });
    },
  }),
};

/**
 * Displays the prompt to enter first name, last name and nickname
 */
@inject('toastStore')
@observer
class PersonalInfo extends React.Component<PersonalInfoProps> {
  /** Whether the info is currently being submitted */
  @observable public submitting = false;

  public constructor(props: PersonalInfoProps) {
    super(props);
    makeObservable(this);
    const fields = [
      {
        name: 'accountName',
        label: 'Salon Name',
        rules: 'required|string|min:2',
        value: '',
      },
      {
        name: 'firstName',
        label: 'Owner First Name',
        rules: 'required|string|min:2',
        value: '',
      },
      {
        name: 'lastName',
        label: 'Owner Last Name',
        rules: 'required|string|min:2',
        value: '',
      },
      {
        name: 'email',
        label: 'Owner Email',
        rules: 'required|string|email',
        value: '',
      },
      {
        name: 'phone',
        label: 'Owner Phone',
        rules: 'required|phone|string',
        value: '',
      },
      {
        name: 'zip',
        label: 'Owner Zip',
        rules: ['zip', 'required'],
        value: '',
      },
    ];
    this.form = new MobxReactForm({ fields }, { plugins });
    props.getForm && props.getForm(this.form);
  }

  isRequired(name: string) {
    if (!this.form.$(name).rules) return false;

    return this.form.$(name).rules.includes('required');
  }

  /** The form object */
  private form: any;

  render() {
    const { classes, color } = this.props;
    const fields = {
      accountName: this.form.$('accountName'),
      firstName: this.form.$('firstName'),
      lastName: this.form.$('lastName'),
      email: this.form.$('email'),
      phone: this.form.$('phone'),
      zip: this.form.$('zip'),
    };
    return (
      <>
        <Typography
          className={classes.titleColor}
          style={{ color }}
          variant="h4"
          component="h1"
          gutterBottom>
          Salon Info
        </Typography>

        <Box mt={2}>
          <OutlinedInput
            {...fields.accountName.bind()}
            error={fields.accountName.error}
            label={fields.accountName.label}
            required={this.isRequired('accountName')}
          />
        </Box>

        <Box mt={2}>
          <Box className={classes.halfWidthLeft}>
            <OutlinedInput
              {...fields.firstName.bind()}
              fullWidth
              label={fields.firstName.label}
              error={fields.firstName.error}
              required={this.isRequired('firstName')}
            />
          </Box>

          <Box className={classes.halfWidthRight}>
            <OutlinedInput
              {...fields.lastName.bind()}
              fullWidth
              label={fields.lastName.label}
              error={fields.lastName.error}
              required={this.isRequired('lastName')}
            />
          </Box>
        </Box>

        <Box mt={2}>
          <OutlinedInput
            {...fields.email.bind()}
            fullWidth
            label={fields.email.label}
            error={fields.email.error}
            required={this.isRequired('email')}
          />
        </Box>

        <Box mt={2}>
          <Box className={classes.halfWidthLeft}>
            <OutlinedInput
              variant="phone"
              {...fields.phone.bind()}
              fullWidth
              error={fields.phone.error}
              label={fields.phone.label}
              required={this.isRequired('phone')}
            />
          </Box>

          <Box className={classes.halfWidthRight}>
            <OutlinedInput
              {...fields.zip.bind()}
              fullWidth
              error={fields.zip.error}
              label={fields.zip.label}
              required={this.isRequired('zip')}
            />
          </Box>
        </Box>
      </>
    );
  }
}

export default withStyles(styles)(PersonalInfo);
