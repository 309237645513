import { Box, Divider, FormHelperText } from '@material-ui/core';
import DD from 'components/DashDrawer';
import { inject, observer } from 'mobx-react';
import { useAddStationGroup } from './useAddStationGroup';
import React, { ChangeEvent } from 'react';
import { IStationGroup } from 'models/Station';
import { AddStationPoolsOrGroupsSection } from 'components/AddStationPoolsOrGroupsSection/AddStationPoolsOrGroupsSection';
import { AddStationUsersSection } from 'components/AddStationUsersSection/AddStationUsersSection';
import OutlinedInput from 'components/Input/OutlinedInput';
import Button from 'components/Button/Button';

export const AddStationGroup = observer(() => {
  const [
    form,
    hasError,
    group,
    isPoolsEnabled,
    setGroupName,
    countGroupUsers,
    addGroupToStation,
    goBack,
  ] = useAddStationGroup();
  return (
    <DD>
      <DD.Content>
        <DD.Title fontSize={28} onBack={goBack}>
          Add Group
        </DD.Title>
        <Box minWidth={520}>
          {group && form && (
            <form
              id={'groupForm'}
              onSubmit={form.onSubmit}
              style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
              <GroupName form={form} setGroupName={setGroupName} group={group} />
              <AddStationPoolsOrGroupsSection />
              <AddStationUsersSection countUsers={countGroupUsers} />
              <Divider />
            </form>
          )}
        </Box>
      </DD.Content>
      <DD.Actions>
        {form && (
          <FormHelperText error={form.$('groupItems').hasError}>
            {form.$('groupItems').error}
          </FormHelperText>
        )}
        <Button
          onClick={addGroupToStation}
          size="large"
          variant="contained"
          color="primary"
          fullWidth
          disabled={hasError}>
          ADD
        </Button>
      </DD.Actions>
    </DD>
  );
});

const GroupName = inject()(
  observer(
    ({
      form,
      group,
      setGroupName,
    }: {
      form: any;
      group: IStationGroup | undefined;
      setGroupName: (e: ChangeEvent<HTMLInputElement>) => void;
    }) => {
      return (
        <OutlinedInput
          id={form.$('groupName').id}
          label={form.$('groupName').label}
          error={Boolean(form.$('groupName').error)}
          helperText={form.$('groupName').error}
          value={group?.groupName}
          onChange={(e: ChangeEvent<HTMLInputElement>) => setGroupName(e)}
          onBlur={(e: any) => setGroupName(e)}
          required
          fullWidth
        />

        // <FormControl fullWidth key={'groupName'}>
        //   <InputLabel htmlFor={form.$('groupName').id} required>
        //     {form.$('groupName').label}
        //   </InputLabel>
        //   <Input
        //     value={group!.groupName}
        //     onChange={(e: ChangeEvent<HTMLInputElement>) => setGroupName(e)}
        //     onBlur={(e: any) => setGroupName(e)}
        //   />

        //   <FormHelperText error={form.$('groupName').hasError}>
        //     {form.$('groupName').error}
        //   </FormHelperText>
        // </FormControl>
      );
    },
  ),
);
