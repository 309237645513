import { action, makeObservable, observable } from 'mobx';
import BaseStore from './BaseStore';
import RootStore from './RootStore';
import { verifyMfaData } from 'components/MfaComponent/helpers';

interface IDialogData {
  title: string;
  callback?: {
    cancel?: () => void;
    verify?: () => void;
  };
}
export default class MfaDialogStore extends BaseStore {
  public readonly globalDialogStore;
  constructor(props: RootStore) {
    super(props);
    makeObservable(this);

    this.globalDialogStore = this.rootStore.globalDialogStore;
  }

  @observable dialogData: IDialogData = {
    title: 'Verification',
  };

  @action.bound cancel(): void {
    this.globalDialogStore.setCloseMfaDialog();
    const cancel = this.dialogData.callback?.cancel;
    cancel && cancel();
    this.rootStore.mfaStore.resetStore();
  }

  @action.bound async verify(): Promise<void> {
    const { callback } = this.dialogData;
    const resp = await verifyMfaData(callback?.verify);
    resp && this.globalDialogStore.setCloseMfaDialog();
  }

  @action.bound setDialogData(dialogData: Partial<IDialogData>): void {
    this.dialogData = { ...this.dialogData, ...dialogData };
  }

  @action.bound async withMfaDialog(
    fn: () => void,
    dialogData?: Partial<IDialogData>,
  ): Promise<any> {
    dialogData && this.setDialogData(dialogData);
    this.globalDialogStore.setOpenMfaDialog();
    try {
      const resp = await fn();
      return resp;
    } catch (error: any) {
      if (error.response?.status !== 901) {
        this.globalDialogStore.setCloseMfaDialog();
        return error;
      }
      this.globalDialogStore.setOpenMfaDialog();
    }
  }

  public init(): void {}
}
