import { Theme, createStyles } from '@material-ui/core';
import { alpha } from '@material-ui/core/styles/colorManipulator';
import logo from 'images/tippy_logo_pig@3x.png';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default (theme: Theme) => {
  return createStyles({
    root: {
      height: '100vh',
      width: '100%',
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
      display: 'flex',
      backgroundImage: logo,
      flexDirection: 'row',
      flexWrap: 'wrap',
    },
    section: {
      padding: theme.spacing(4),
      height: '50%',
      width: '50%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    sectionSub: {
      textAlign: 'center',
    },
    section1: {
      backgroundColor: alpha(theme.palette.common.white, 0.1),
    },
    section4: {
      backgroundColor: alpha(theme.palette.common.white, 0.1),
    },
    number: {
      fontSize: '6vw',
    },
    title: {
      textTransform: 'uppercase',
      fontWeight: 400,
      fontSize: '3vw',
      color: alpha(theme.palette.common.white, 0.7),
    },
  });
};
