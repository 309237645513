import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { observer } from 'mobx-react';
import styles from './styles';
import { WithStyles, withStyles } from '@material-ui/core/styles';
import { Box, Link } from '@material-ui/core';
import { Circle } from 'mdi-material-ui';
import { paths } from 'routes';
import { ITransferDetails } from './Cards';
import { inject } from 'stores';
import SkeletonOrComponent from 'components/SkeletonOrComponent';
import DD from 'components/DashDrawer';
import theme from 'containers/App/theme';

interface ICardDrawerProps extends WithStyles {
  row: ITransferDetails;
  closeModal: () => void;
}

@inject('userStore')
@observer
class CardsDrawer extends React.Component<ICardDrawerProps, {}> {
  statusColor(status: string) {
    switch (status) {
      case 'PROCESSED':
        return theme.palette.status.green;
      case 'FAILED':
        return theme.palette.status.red;
      case 'PENDING':
        return theme.palette.status.yellow;
      case 'CREATED':
        return theme.palette.status.purple;
      case 'CANCELED':
        return theme.palette.status.red;
      case 'VOIDED':
        return theme.palette.status.grey;
      default:
        return theme.palette.status.grey;
    }
  }
  render() {
    const { closeModal, classes, row } = this.props;
    const { secondItem, skeleton, circleDot, drawerContent } = classes;
    const { date, amount, transactionId, name, processor, destination, status, time, user } = row;

    const color = this.statusColor(status);
    return (
      <DD>
        <DD.Title showDivider onClose={closeModal} padding>
          <SkeletonOrComponent className={skeleton} width={200} height={50}>
            Card Transfer
          </SkeletonOrComponent>
        </DD.Title>
        <DD.Content>
          <DD.Row>
            <DD.Item>
              <DD.Value>
                <SkeletonOrComponent className={skeleton} rangeWidth={[80, 120]}>
                  {date}
                </SkeletonOrComponent>
              </DD.Value>
              <DD.Label>Date</DD.Label>
            </DD.Item>
            <DD.Item className={secondItem}>
              <DD.Value>
                <SkeletonOrComponent className={skeleton} rangeWidth={[80, 120]}>
                  {time}
                </SkeletonOrComponent>
              </DD.Value>
              <DD.Label>Time</DD.Label>
            </DD.Item>
          </DD.Row>

          <DD.Row>
            <DD.Item>
              <DD.Value>
                <SkeletonOrComponent className={skeleton} rangeWidth={[80, 120]}>
                  {amount}
                </SkeletonOrComponent>
              </DD.Value>
              <DD.Label>Amount</DD.Label>
            </DD.Item>
            <DD.Item className={secondItem}>
              <DD.Value>
                <Link component={RouterLink} to={paths.userDetails(user.id).root()}>
                  {name}
                </Link>
              </DD.Value>
              <DD.Label>User</DD.Label>
            </DD.Item>
          </DD.Row>

          <DD.Row>
            <DD.Item>
              <DD.Value>
                <SkeletonOrComponent className={skeleton} rangeWidth={[80, 120]}>
                  {processor}
                </SkeletonOrComponent>
              </DD.Value>
              <DD.Label>Processor</DD.Label>
            </DD.Item>
            <DD.Item className={secondItem}>
              <DD.Value>
                <SkeletonOrComponent className={skeleton} rangeWidth={[80, 120]}>
                  {destination}
                </SkeletonOrComponent>
              </DD.Value>
              <DD.Label>Destination</DD.Label>
            </DD.Item>
          </DD.Row>

          <DD.Row>
            <DD.Item>
              <DD.Value>
                <Box display="flex" alignItems="center" style={{ gap: 8 }}>
                  {status} <Circle className={circleDot} htmlColor={color} fontSize="inherit" />
                </Box>
              </DD.Value>
              <DD.Label>Status</DD.Label>
            </DD.Item>
          </DD.Row>

          <DD.Row>
            <DD.Item>
              <DD.Value>
                <SkeletonOrComponent className={skeleton} rangeWidth={[80, 120]}>
                  {transactionId}
                </SkeletonOrComponent>
              </DD.Value>
              <DD.Label>Transaction Id</DD.Label>
            </DD.Item>
          </DD.Row>
        </DD.Content>
      </DD>
    );
  }
}
export default withStyles(styles)(CardsDrawer);
