import { alpha, Theme } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/styles';

interface IStyleProps {
  active?: boolean;
}

const useStyles = makeStyles<Theme, IStyleProps>((theme: Theme) =>
  createStyles({
    statusBox: {
      display: ({ active }) => (active === undefined ? 'none' : 'inlince'),
      // marginLeft: 16,
      fontSize: 12,
      color: ({ active }) => (active ? theme.palette.status.green : theme.palette.status.purple),
      borderRadius: 16,
      padding: '4px 10px 4px 10px',
      fontWeight: 700,
      backgroundColor: ({ active }) =>
        active ? alpha(theme.palette.status.green, 0.2) : alpha(theme.palette.status.purple, 0.2),
    },
  }),
);

export default useStyles;
