import React from 'react';
import { computed, makeObservable } from 'mobx';
import { observer } from 'mobx-react';
import { Redirect, RouteComponentProps } from 'react-router-dom';
import { WithStyles, withStyles } from '@material-ui/core/styles';

import { inject, WithUserStore, WithModalStore, WithToastStore } from 'stores';
import { paths } from 'routes';

import DashboardLayout from 'containers/DashboardLayout';
import TippyLogo from 'images/pig_green_white.svg';

import AdminHome from './AdminHome';
import OwnerHome from './OwnerHome';
import ManagerHome from './ManagerHome';
import TalentHome from './TalentHome';
import NoRoleHome from './NoRoleHome';

import { setTitle } from 'services';

import styles from './styles';
import { Box, CardMedia, Typography } from '@material-ui/core';
import clsx from 'clsx';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import { EFeatureFlags } from 'models';

type DashboardHomeProps = WithStyles<typeof styles> &
  WithUserStore &
  WithModalStore &
  WithToastStore &
  RouteComponentProps & { flags: Record<EFeatureFlags, boolean> };

/**
 * The dashboard home page, displayed when you log in.
 */
@inject('userStore')
@observer
class DashboardHome extends React.Component<DashboardHomeProps> {
  constructor(props: DashboardHomeProps) {
    super(props);
    makeObservable(this);
  }
  componentDidMount() {
    setTitle(`Tippy Dashboard`, { noSuffix: true });
  }
  /** Whether the user is an affiliate but not a talent */
  @computed public get isJustAffiliate() {
    return this.props.userStore!.scope.kind === 'none' && Boolean(this.props.userStore!.affiliate);
  }
  render() {
    const { classes } = this.props;
    if (!this.props.userStore || this.props.userStore!.loggingIn) {
      return (
        <Box className={classes.loadingWrapper}>
          <Box className={classes.loadingContent}>
            <CardMedia
              className={clsx(classes.tippyLogo, classes.animatedItem)}
              image={TippyLogo}
              title={`Tippy logo`}
            />
            <Typography color="primary" variant="h5">
              Loading dashboard...
            </Typography>
          </Box>
        </Box>
      );
    }
    const scope = this.props.userStore!.scope.kind;
    if (scope === 'admin') {
      return (
        <DashboardLayout>
          <AdminHome {...this.props} />
        </DashboardLayout>
      );
    } else if (scope === 'owner') {
      return (
        <DashboardLayout>
          <OwnerHome {...this.props} />
        </DashboardLayout>
      );
    } else if (scope === 'talent') {
      return (
        <DashboardLayout>
          <TalentHome {...this.props} />
        </DashboardLayout>
      );
    } else if (scope === 'manager') {
      return (
        <DashboardLayout>
          <ManagerHome {...this.props} />
        </DashboardLayout>
      );
    } else if (scope === 'affiliate') {
      /**
       * Affiliate has access only to Referrals page,
       * If feature flag is OFF then they get redirected to "not found" page with option to log out,
       * If feature flag is ON then they get redirected to Referrals page
       */
      if (!this.props.flags?.[EFeatureFlags.REFERRAL]) {
        return <Redirect to={paths.notFoundLogout} />;
      }
      return <Redirect to={paths.referrals().root()} />;
    } else {
      return (
        <DashboardLayout>
          <NoRoleHome {...this.props} />
        </DashboardLayout>
      );
    }
  }
}

const exportWithStyles = withStyles(styles)(DashboardHome);

export default withLDConsumer()(exportWithStyles);
