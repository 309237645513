import { Theme, createStyles } from '@material-ui/core';
import { textFieldInput } from 'styles';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default (theme: Theme) =>
  createStyles({
    root: { backgroundColor: 'navajowhite' },
    textFieldInput,
    title: {
      marginBottom: theme.spacing(12.625),
      fontSize: theme.spacing(5),
    },
    backButton: {
      textTransform: 'none',
      color: theme.palette.text.secondary,
      fontSize: 16,
      height: 20,
    },
  });
