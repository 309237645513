import React from 'react';
import { Snackbar as MuiSnackbar, SnackbarContent, Button, IconButton } from '@material-ui/core';
import { WithStyles, withStyles } from '@material-ui/styles';
import { inject, WithToastStore } from 'stores';
import { observer } from 'mobx-react';

import styles from './styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCircleCheck,
  faCircleExclamation,
  faCircleInfo,
  faCircleXmark,
  faXmarkLarge,
} from '@fortawesome/pro-regular-svg-icons';
import theme from 'containers/App/theme';
import { Box } from '@mui/material';

type SnackbarProps = WithToastStore & WithStyles<typeof styles>;

/**
 * The component for displaying the snackbar, where the toast messages
 * are displayed.
 */
@inject('toastStore')
@observer
class Snackbar extends React.Component<SnackbarProps> {
  render() {
    const { classes } = this.props;
    const toastStore = this.props.toastStore!;
    if (!toastStore.current) {
      return null;
    }
    const icon = {
      error: faCircleXmark,
      success: faCircleCheck,
      info: faCircleInfo,
      warning: faCircleExclamation,
    };
    return (
      <MuiSnackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        open={Boolean(toastStore.current)}
        onClose={toastStore.dismiss}
        ContentProps={{
          'aria-describedby': 'message-id',
        }}>
        <SnackbarContent
          message={
            <Box component={'span'} id={`${toastStore.current.id}`}>
              <FontAwesomeIcon
                style={{ marginRight: theme.spacing(1) }}
                icon={icon[toastStore.current.type]}
                fontSize={16}
              />
              {toastStore.current.message}
            </Box>
          }
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          className={classes[toastStore.current.type] as any}
          action={[
            toastStore.current && toastStore.current.undo && (
              <Button key="undo" color="secondary" size="small" onClick={toastStore.undo}>
                UNDO
              </Button>
            ),
            <IconButton
              key="close"
              aria-label="close"
              color="inherit"
              onClick={toastStore.dismiss}
              className={classes.close}>
              <FontAwesomeIcon icon={faXmarkLarge} fontSize={16} />
            </IconButton>,
          ]}
        />
      </MuiSnackbar>
    );
  }
}

export default withStyles(styles)(Snackbar);
