import React from 'react';
import { observable, action, flow, makeObservable } from 'mobx';
import { observer } from 'mobx-react';
import { WithStyles, withStyles } from '@material-ui/core/styles';
import { Box, Typography } from '@material-ui/core';
import { startCase, toLower } from 'lodash';

import Api from 'api';

import styles from './styles';
import { WithToastStore, inject, WithUserStore } from 'stores';
import Skeleton from '@material-ui/lab/Skeleton';
import { CustomerCard, Customer } from 'models';

import CreditCardIcon from 'components/CreditCardIcon';
import { getErrorMsg } from 'api';
import DD from 'components/DashDrawer';
import SkeletonOrComponent from 'components/SkeletonOrComponent';

interface PaymentMethodsDrawerProps
  extends WithStyles<typeof styles>,
    WithToastStore,
    WithUserStore {
  customerId: number;

  onClose: () => void;
}

/**
 * Displays a filter drawer for filtering user search results
 */
@inject('toastStore', 'userStore')
@observer
class PaymentMethodsDrawer extends React.Component<PaymentMethodsDrawerProps> {
  constructor(props: PaymentMethodsDrawerProps) {
    super(props);
    makeObservable(this);
  }
  
  @observable public customer?: Customer;
  @observable public loading = false;

  @action.bound public fetchCustomerDetails = flow(function* (this: PaymentMethodsDrawer) {
    try {
      this.loading = true;

      const resp = yield Api.tips.getCustomerDetails(this.props.customerId);

      this.customer = resp.data.data;
    } catch (err: any) {
      this.props.toastStore!.error(getErrorMsg(err));
    } finally {
      this.loading = false;
    }
  });

  // Inclusive
  private randomRange(min: number, max: number) {
    return Math.floor(Math.random() * (max - min + 1) + min);
  }

  private getSkeleton(component?: React.ReactNode): React.ReactNode {
    const { classes } = this.props;
    const customer = this.customer;
    if (!component || !customer) {
      const width = this.randomRange(80, 120);
      return <Skeleton variant="text" width={width} className={classes.skeleton} />;
    } else {
      return component;
    }
  }

  componentDidMount() {
    this.fetchCustomerDetails();
  }

  private paymentMethodItem = (card?: CustomerCard) => {
    const { classes } = this.props;

    return (
      <Box
        key={(card && card.id) || Math.random()}
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        mt={3}>
        <Box display="flex" justifyContent="flex-start">
          <SkeletonOrComponent className={classes.skeleton} rangeWidth={[80, 120]}>
            {card && card.brand && (
              <CreditCardIcon style={{ marginTop: 3 }} brand={card.brand || ''} />
            )}
          </SkeletonOrComponent>
          <SkeletonOrComponent className={classes.skeleton} rangeWidth={[80, 120]}>
            {card && card.brand && (
              <Typography className={classes.label}>
                {startCase(toLower(card.brand || 'unknown'))}
              </Typography>
            )}
          </SkeletonOrComponent>
        </Box>
        <Box display="flex" justifyContent="flex-end" alignSelf="flex-end">
          <SkeletonOrComponent className={classes.skeleton} rangeWidth={[80, 120]}>
            {card && card.lastFour && (
              <Typography className={classes.value}>****{card.lastFour}</Typography>
            )}
          </SkeletonOrComponent>
        </Box>
      </Box>
    );
  };

  private renderPaymenMethodList = () => {
    const customer = this.customer;

    if (!customer || !customer.cards || !customer.cards.length) {
      return [undefined, undefined, undefined, undefined].map(this.paymentMethodItem);
    } else {
      return customer.cards.map(this.paymentMethodItem);
    }
  };

  render() {
    const { classes, onClose } = this.props;

    const customer = this.customer;

    return (
      <DD>
        <DD.Content>
          <DD.Title onClose={onClose}>
            <SkeletonOrComponent className={classes.skeleton} width={200} height={50}>
              {customer && (
                <Typography variant="h4" component="h4">
                  {`${customer.firstName} ${customer.lastName}`}
                </Typography>
              )}
            </SkeletonOrComponent>
          </DD.Title>
          <DD.Subtitle>Payment Methods</DD.Subtitle>
          {this.renderPaymenMethodList()}
        </DD.Content>
      </DD>
    );
  }
}

export default withStyles(styles)(PaymentMethodsDrawer);
