import { faXmarkLarge } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Avatar from 'components/ImageIcon';
import { Box, IconButton, TextField } from '@material-ui/core';
import { IUserTip } from 'models';
import { useStyles } from './../styles';

interface SplitTipSingleProps {
  defaultUserTipId: number;
  userTip: IUserTip;
  setAmount: (id: number, value: string) => void;
  removeUser: (id: number) => void;
}

export const SplitTipSingle = ({
  defaultUserTipId,
  userTip,
  setAmount,
  removeUser,
}: SplitTipSingleProps) => {
  const classes = useStyles();
  const { userItem, userItemInfo, userItemAmount, userItemRemove, userItemActions, amountPrefix } =
    classes;

  return (
    <Box className={userItem}>
      <Box className={userItemInfo}>
        <Avatar src={userTip.avatar} />
        <Box>{userTip.name}</Box>
      </Box>
      <Box className={userItemActions}>
        <Box className={userItemAmount}>
          <TextField
            disabled={userTip.id === defaultUserTipId}
            InputProps={{
              startAdornment: <Box className={amountPrefix}>$</Box>,
            }}
            type="number"
            value={userTip.amount}
            color="primary"
            onChange={(e) => setAmount(userTip.id, e.target.value)}
            autoFocus
          />
        </Box>
        {userTip.id !== defaultUserTipId && (
          <IconButton className={userItemRemove} onClick={() => removeUser(userTip.id)}>
            <FontAwesomeIcon icon={faXmarkLarge} />
          </IconButton>
        )}
      </Box>
    </Box>
  );
};
