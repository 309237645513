import { Theme, createStyles, makeStyles } from '@material-ui/core';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
    },
    subtitle: {
      color: theme.palette.text.secondary,
      fontSize: '16px',
      marginBottom: theme.spacing(4),
      '& span': {
        color: theme.palette.primary.main,
        fontWeight: 700,
      },
    },
    userItems: {
      marginTop: theme.spacing(2),
    },
    userItem: {
      alignItems: 'center',
      borderBottom: `1px solid ${theme.palette.divider}`,
      display: 'flex',
      justifyContent: 'space-between',
      padding: theme.spacing(1.5, 0),
    },
    userItemInfo: {
      alignItems: 'center',
      display: 'flex',
      gap: theme.spacing(2),
    },
    userLabels: {
      color: theme.palette.text.secondary,
    },
    usersContainer: {
      overflowY: 'auto',
      overflowX: 'hidden',
      maxHeight: '220px',
    },
    userItemAmount: {
      marginRight: theme.spacing(1),
    },
    userItemAmountLabel: {
      paddingRight: theme.spacing(6),
    },
    userItemRemove: {
      fontSize: '16px',
    },
    userItemActions: {
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'space-between',
      paddingRight: theme.spacing(1.5),
      width: '138px',

      /* Chrome, Safari, Edge, Opera */
      '& input::-webkit-outer-spin-button': {
        '-webkit-appearance': 'none',
        margin: 0,
      },

      '& input::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
        margin: 0,
      },

      /* Firefox */
      '& input[type=number]': {
        '-moz-appearance': 'textfield',
      },

      '& .MuiTextField-root': {
        borderBottom: `1px solid ${theme.palette.primary.main}`,
        width: '92px',
        '& .MuiInputBase-root': {
          fontSize: '24px',
        },
        '& .MuiInputBase-input': {
          padding: 0,
        },
      },
    },
    amountPrefix: {
      color: theme.palette.primary.main,
      fontSize: '20px',
      marginRight: theme.spacing(0.5),
      alignSelf: 'flex-end',
      lineHeight: '26px',
    },
  }),
);
