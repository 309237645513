import { Theme, createStyles } from '@material-ui/core';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default (theme: Theme) =>
  createStyles({
    root: {
      marginBottom: theme.spacing(3),
    },
    campaign: {
      width: "100%",
      height: "100vh",
      backgroundSize: "cover",
      backgroundPositionX: "center",
    },
  });